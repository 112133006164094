/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import Shimmer from 'react-shimmer-effect';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import { useTheme } from 'emotion-theming';
import Text from '../../../../shared/components/Text/Text';
import {
  setFilterInfoFromPieChart,
} from '../../ducks/wearablesDashboardActions';

const DonutChart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const isAthletesToWatchLoading = useSelector((state) => state.wearablesDashboard.ui.isAthletesToWatchLoading);
  const donutChartLevelsArray = useSelector((state) => state.wearablesDashboard.data?.athletesToWatch?.levels);
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);

  /** total athletes to watch count, counts the total number of athletes with an exertion score available for that day, not just the
   * athletes to keep an eye on, which is returned as a seperate property on this endpoint
   */
  const totalAthletesToWatchCount = useSelector((state) => state.wearablesDashboard.data.athletesToWatch?.total);
  /** This endpoint returns the number of active athetes who are connected and sharing */
  const activeAthleteParticipationCount = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.active?.count);

  const formattedArrayToPassToDonutChart = donutChartLevelsArray?.map((level) => ({
    name: level.label, value: level.count,
  }));

  const DonutChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 27px;
    min-width: 245px;
    min-height: 259px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
  `;

  const PieChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 189px;
    height: 170px;
    margin-bottom: 30px;
    padding-top: 15px;
  `;

  const DonutChartContainerPlaceholderShimmer = styled('div')`
    min-width: 245px;
    min-height: 259px;
    border-radius: 24px;
  `;

  const LegendContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 42px;
    width: 225px;
    gap: 15px;
    margin: 15px;
    margin-top: 30px;
  `;

  const Column = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `;
  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 5px;
  `;

  const LegendCircleIcon = styled('div')`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  `;

  const TextContainer = styled('span')`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    width: 225px;
  `;

  const CustomLabel = styled('div')`
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.08);
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

  const LabelText = styled('div')`
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: bold;
`;

  const data02 = formattedArrayToPassToDonutChart;

  const defaultData = [
    { name: 'A1', value: 1 },
    { name: 'A2', value: 1 },
    { name: 'B1', value: 1 },
    { name: 'B2', value: 1 },

  ];

  const COLORS = ['#3fbfe1', '#0DCC8A', '#FFCB47', '#FF8533', '#FF0505'];

  const setFilterObject = (entry) => {
  /** set filter object in global state */
    let fillColor;
    let maxScore;
    let minScore;
    if (entry.name === 'Well Rested') {
      minScore = 0;
      maxScore = 2;
      fillColor = '#3fbfe1';
    }
    if (entry.name === 'Rested') {
      minScore = 2.1;
      maxScore = 4;
      fillColor = '#0DCC8A';
    }
    if (entry.name === 'Slight Fatigue') {
      minScore = 4.1;
      maxScore = 6;
      fillColor = '#FFCB47';
    }
    if (entry.name === 'Moderate Fatigue') {
      minScore = 6.1;
      maxScore = 8;
      fillColor = '#FF8533';
    }
    if (entry.name === 'Heavy Fatigue') {
      minScore = 8.1;
      maxScore = 10;
      fillColor = '#FF0505';
    }

    const obj = {
      status: entry.name,
      numberOfAthletes: entry.value,
      group: activeGroup?.name,
      minScore,
      maxScore,
      fillColor,
    };
    dispatch(setFilterInfoFromPieChart(obj));
  };

  const renderCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, value,
  // eslint-disable-next-line consistent-return
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value !== 0) {
      return (
        <foreignObject style={{ pointerEvents: 'none' }} x={x} y={y} width='50' height='50'>
          <CustomLabel>
            <LabelText>
              {value}
            </LabelText>
          </CustomLabel>
        </foreignObject>
      );
    }
  };

  const CustomizedLegend = () => (
    <LegendContainer>
      <Column>
        <Row>
          <LegendCircleIcon color='#3fbfe1' />
          <Text fontSize={12} fontWeight={700}>Well Rested</Text>
        </Row>
        <Row>
          <LegendCircleIcon color='#0DCC8A' />
          <Text fontSize={12} fontWeight={700}>Rested</Text>
        </Row>
        <Row>
          <LegendCircleIcon color='#FFCB47' />
          <Text fontSize={12} fontWeight={700}>Slight Fatigue</Text>
        </Row>
      </Column>
      <Column>
        <Row>
          <LegendCircleIcon color='#FF8533' />
          <Text fontSize={12} fontWeight={700}>Moderate Fatigue </Text>
        </Row>
        <Row>
          <LegendCircleIcon color='#FF0505' />
          <Text fontSize={12} fontWeight={700}>Heavy Fatigue</Text>
        </Row>
      </Column>
    </LegendContainer>
  );

  return (
    <DonutChartContainer>
      {isAthletesToWatchLoading ? (
        <Shimmer>
          <DonutChartContainerPlaceholderShimmer />
        </Shimmer>
      ) : (
        <>
          {donutChartLevelsArray && totalAthletesToWatchCount > 0 ? (
            <>
              <PieChartContainer>
                <Text fontSize={12} fontWeight={700}>Group Exertion Status</Text>
                <Text fontSize={12} fontWeight={700}>{moment(activeWearablesDashDate).format('MMM D, YYYY')}</Text>
                <PieChart
                  width={200}
                  height={200}
                >
                  <Pie
                    isAnimationActive={false}
                    data={data02}
                    dataKey='value'
                    cx='50%'
                    cy='50%'
                    innerRadius={35}
                    outerRadius={60}
                    fill='#82ca9d'
                    label={renderCustomizedLabel}
                    labelLine={false}
                  >
                    {/* {data02.map((entry, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))} */}
                    {data02.map((entry, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} onClick={() => setFilterObject(entry)} style={{ cursor: 'pointer' }} />
                    ))}
                  </Pie>
                </PieChart>
              </PieChartContainer>
              <CustomizedLegend />
            </>
          ) : (
            <>
              <PieChartContainer>
                <PieChart width={200} height={200}>
                  <Pie data={defaultData} dataKey='value' cx='50%' cy='50%' innerRadius={35} outerRadius={60} fill='grey' />
                </PieChart>
              </PieChartContainer>
              {activeAthleteParticipationCount > 0 ? (
                <TextContainer>
                  <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>Oops! No exertion score data available for selected date.</Text>
                </TextContainer>
              ) : (
                <TextContainer>
                  <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>Oops! No active athletes.</Text>
                </TextContainer>
              )}
            </>
          )}
        </>
      )}
    </DonutChartContainer>
  );
};

export default DonutChart;
