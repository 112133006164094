import React from 'react';
import { useDispatch } from 'react-redux';

import AthleteSearch from '../presentational/AthleteSearch';
import { searchAthlete } from '../../ducks/panelActions';

const WeightroomSearch = (ownProps) => {
  const dispatch = useDispatch();

  const srchAthlete = (athlete, namespace) => {
    dispatch(searchAthlete(athlete, namespace));
  };

  return (
    <AthleteSearch
      searchAthlete={srchAthlete}
      namespace={ownProps.namespace}
    />
  );
};

export default WeightroomSearch;
