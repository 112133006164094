import React from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Program from './components/program/Program';
import ProgramsLandingContainer from './ProgramsLanding';

const ProgramsContainer = styled('div')`
`;

const Programs = () => {
  const { path } = useRouteMatch();

  return (
    <ProgramsContainer>
      <Helmet>
        <title>Program | TeamBuildr</title>
      </Helmet>
      <Switch>
        <Route exact path={path}>
          <h1>Programs</h1>
        </Route>
        <Route path={`${path}/:accountName`}>
          <Switch>
            <Route exact path={`${path}/:accountName`}>
              <ProgramsLandingContainer />
            </Route>
            <Route path={`${path}/:resellerSlug/:programSlug`}>
              <Program />
            </Route>
          </Switch>
        </Route>
      </Switch>
    </ProgramsContainer>
  );
};

export default Programs;
