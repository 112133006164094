import { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeGlobalModal,
  toggleSupersets,
  toggleDarkTheme,
  toggleWeightValue,
  setScreens,
} from '../../ducks/weightroomActions';

const useWeightroomSettings = () => {
  const isDarkThemeEnabled = useSelector(state => state.weightRoom.isDarkThemeEnabled);
  const isGlobalModalOpen = useSelector(state => state.weightRoom.isGlobalModalOpen);
  const isSupersetsEnabled = useSelector(state => (
    state.weightRoom.currentUser.settings.wrv_supersets_enabled
  ));
  const isWeightValueEnabled = useSelector(state => state.weightRoom.isWeightValueEnabled);
  const currentUser = useSelector(state => (state.weightRoom.currentUser));
  const activeScreens = useSelector(state => state.weightRoom.activeScreens);

  const dispatch = useDispatch();

  const modalClose = () => {
    dispatch(closeGlobalModal());
  };

  const toggleSuperset = () => {
    dispatch(toggleSupersets());
  };

  const setScreenAmount = (screenAmount) => {
    dispatch(setScreens(screenAmount));
  };

  const toggleDark = () => {
    dispatch(toggleDarkTheme());
  };

  const toggleWeight = () => {
    dispatch(toggleWeightValue());
  };

  return {
    activeScreens,
    currentUser,
    isDarkThemeEnabled,
    isGlobalModalOpen,
    isSupersetsEnabled,
    isWeightValueEnabled,
    modalClose,
    setScreenAmount,
    toggleDark,
    toggleSuperset,
    toggleWeight,
  };
};

export default useWeightroomSettings;
