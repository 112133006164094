/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import moment from 'moment';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import SharedAthleteTileCard from './SharedAthleteTileCard';
import Text from '../../../../shared/components/Text/Text';

const AthletesToWatchComponent = ({ containerWidth }) => {
  const theme = useTheme();
  const ref = React.createRef();
  const isAthletesToWatchLoading = useSelector((state) => state.wearablesDashboard.ui.isAthletesToWatchLoading);
  const AthletesToWatch = useSelector((state) => state.wearablesDashboard.data.athletesToWatch);
  const peopleToKeepAnEyeOnArray = AthletesToWatch?.peopleToKeepAnEyeOn?.sort((a, b) => b.value - a.value) || [];
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);

  const [numberOfAthleteCardsToDisplay, setNumberOfAthleteCardsToDisplay] = useState(null);

  const [displayExpandButton, setDisplayExpandButton] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);

  // const [isFullScreen, setIsFullScreen] = useState(window.innerWidth >= 912);
  const [isFullScreen, setIsFullScreen] = useState(containerWidth >= 912);

  const getNumberOfAthleteCardsToDisplay = (width) => {
    if (isFullScreen) {
      if (width <= 795 && width > 671) {
        return 4;
      }
      if (width <= 671) {
        return 3;
      }
      return 5;
    }
    if (!isFullScreen) {
      if (width <= 829 && width > 731) {
        return 6;
      }
      if (width <= 731 && width > 632) {
        return 5;
      }
      if (width <= 632 && width > 534) {
        return 4;
      }
      if (width <= 534) {
        return 3;
      }
      return 7;
    }
    return 0;
  };

  useEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;
      setNumberOfAthleteCardsToDisplay(getNumberOfAthleteCardsToDisplay(width));
      setDisplayExpandButton(peopleToKeepAnEyeOnArray?.length > getNumberOfAthleteCardsToDisplay(width));
      // setIsFullScreen(window.innerWidth >= 912);
      setIsFullScreen(containerWidth >= 912);
    }
  }, []);

  function reportWindowSize() {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width } = boundingRect;
      // setIsFullScreen(window.innerWidth >= 912);
      setIsFullScreen(containerWidth >= 912);
      setNumberOfAthleteCardsToDisplay(getNumberOfAthleteCardsToDisplay(width));
      setDisplayExpandButton(peopleToKeepAnEyeOnArray?.length > getNumberOfAthleteCardsToDisplay(width));
      setIsExpanded(false);
    }
  }

  window.onresize = reportWindowSize;

  const peopleToKeepAnEyeOnArrayFormatedForExpand = displayExpandButton ? peopleToKeepAnEyeOnArray?.filter((athlete, index) => index <= (numberOfAthleteCardsToDisplay - 1)) : peopleToKeepAnEyeOnArray;

  const Container = styled('div')`
    display: flex;
    flex-direction: ${(props) => ((props.containerWidth >= 912 && isExpanded) ? 'column' : (props.containerWidth >= 930 && !isExpanded) ? 'row' : 'column')};
    justify-content: ${(props) => ((props.containerWidth >= 912 && isExpanded) ? 'center' : (props.containerWidth >= 912 && !isExpanded) ? 'flex-start' : 'center')};
    align-items: flex-start;
    box-sizing: border-box;
    padding: 25px;
    background: #F7F7F7;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    margin-bottom: 30px;
    width: ${(props) => (props.containerWidth <= 767 ? '100%' : '97%')};
  `;

  const ShimmerLoadingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: #F7F7F7;
  border-radius: 24px;
  margin-bottom: 30px;
  height: 114px;
  width: 97%;

  flex-direction: ${(props) => props.containerWidth >= 912 && 'row'};
  justify-content: ${(props) => props.containerWidth >= 912 && 'flex-start'};

  width: ${(props) => props.containerWidth <= 767 && '100%'};
  
  `;

  const CardContainer = styled('div')`
    display: flex;
    flex-direction: Row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: ${(props) => props.containerWidth <= 767 && 'center'};
`;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: ${(props) => props.containerWidth <= 767 && 'center'};
  `;

  const TextContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;

  ${(props) => props.containerWidth >= 912 && isExpanded && `    
      padding-top: 20px;
      padding-bottom: 20px;
      padding-right: 20px;
      margin-right: 0px;
      width: 255px;
      min-width: 254px;
      text-align: center;
      align-self: flex-start;
    `}

    ${(props) => props.containerWidth >= 930 && !isExpanded && `
    /* Styles for non-expanded state on larger container width */
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 0px;
      margin-right: 20px;
      width: 126px;
      min-width: 125px;
      text-align: center;
      align-self: center;
  `}

  ${(props) => props.containerWidth <= 767 && `    
    padding-right: 0px;
    text-align: center;
    align-self: center;
  `}
`;

  const ExpandContentContainer = styled('div')`
    display: ${peopleToKeepAnEyeOnArray?.length <= numberOfAthleteCardsToDisplay ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    width: 83px;
  `;

  const IconContainer = styled('div')`
    display: flex;
    justify-content: center;
    align-itmes: center;
    background-color: ${theme.colors.orange};
    width: fit-content;
    padding: 5px;
    border-radius: 50%;
    margin-bottom: 5px;
  `;

  return (
    <>
      {isAthletesToWatchLoading ? (
        <Shimmer>
          <ShimmerLoadingContainer containerWidth={containerWidth} />
        </Shimmer>
      ) : (
        <Container ref={ref} containerWidth={containerWidth}>
          <TextContainer containerWidth={containerWidth}>
            <Text fontSize={15} fontWeight={700}>{`Athletes to watch (${moment(activeWearablesDashDate).format('MMM D, YYYY')}):`}</Text>
          </TextContainer>
          <Row containerWidth={containerWidth}>
            {!peopleToKeepAnEyeOnArray?.length && <TextContainer><Text>No athletes to watch.</Text></TextContainer>}
            <CardContainer containerWidth={containerWidth}>
              {peopleToKeepAnEyeOnArrayFormatedForExpand?.map((athlete) => (
                <SharedAthleteTileCard
                  key={athlete.user?.id}
                  athleteId={athlete.user?.id}
                  value={athlete.value}
                  userFirstName={athlete.user?.first}
                  userLastName={athlete.user?.last}
                  userPic={athlete.user?.pic}
                  gradientColors={athlete.user?.gradientColors}
                  userEmail={athlete.user?.email}
                />
              ))}
              <ExpandContentContainer onClick={() => {
                setIsExpanded(!isExpanded);
                setDisplayExpandButton(!displayExpandButton);
              }}
              >
                <IconContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    icon={displayExpandButton ? 'plus' : 'alt-left-arrow'}
                    color='white'
                  />
                </IconContainer>
                <Text fontSize={13} fontWeight={700}>{displayExpandButton ? 'Expand' : 'Minimize'}</Text>
              </ExpandContentContainer>
            </CardContainer>
          </Row>
        </Container>

      )}
    </>
  );
};

export default AthletesToWatchComponent;
