import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import CaptureModal from './ProfilePicMediaUploadModals/CaptureModal';
import RecordImageModal from './ProfilePicMediaUploadModals/RecordImageModal';
import {
  setSubActiveReduxModal,
} from '../../modules/profile/ducks/profileActions';

const ProfilePicSubModalHandler = ({ isLogo }) => {
  const dispatch = useDispatch();
  const activeSubModal = useSelector((state) => state.profile.data.activeSubModal);
  const closeSubModal = () => {
    dispatch(setSubActiveReduxModal(''));
  };

  const MainContainer = styled('main')`
  `;
  /**
   * Modal handler responds to what the activeModal hook is currently set to.
   * if the activeModal hook is set, it will display the appropriate modal
   */
  const subModalHandler = () => {
    let returnModal = null;
    if (activeSubModal === 'record-image-modal') {
      returnModal = (
        <CaptureModal
          isOpen={!!activeSubModal}
          onRequestClose={closeSubModal}
        >
          <RecordImageModal
            onRequestClose={closeSubModal}
            isLogo={isLogo}
          />
        </CaptureModal>
      );
    }
    return returnModal;
  };
  return (
    <MainContainer>
      {subModalHandler()}
    </MainContainer>
  );
};
ProfilePicSubModalHandler.propTypes = {
  isLogo: PropTypes.bool,
};

ProfilePicSubModalHandler.defaultProps = {
  isLogo: false,
};
export default ProfilePicSubModalHandler;
