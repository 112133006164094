/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import iconSet from '../../images/teambuildr-selection.json';
import Text from '../Text/Text';

const ItemContainer = styled('div')`
  display: flex;
  padding: 10px;
  height: 55px;
  width: 280px;
  max-width:  ${(props) => (props.itemPickerContainerMaxWidth ? `${props.itemPickerContainerMaxWidth}px` : '280px')};
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    // padding: 10px 20px!important;
  }
`;

const ItemNameContainer = styled('div')`
`;

const Wrapper = styled('div')`
display: flex;
width: 100%;
max-width: 280px;
background-color: #e8e8e8;
border-radius: 6px;
align-items: center;
padding: 5px;
padding-left: 10px;
padding-right: 10px;
gap: 5px;
`;

const SearchBarContainer = styled('div')`
display: flex;
width: 100%;
width: 280px;
max-width:  ${(props) => (props.itemPickerContainerMaxWidth ? `${props.itemPickerContainerMaxWidth}px` : '280px')};
padding: 10px;
border-radius: inherit;
cursor: pointer;
position: sticky;
top: 0;
background: white;
h3 {
color: black;
}
@media screen and (orientation: portrait) {
// padding: 10px 20px!important;
}
`;
const StyledInput = styled('input')`
width: 90%;
background-color: #e8e8e8;
border-width: 0px;
::placeholder {
  font-family: 'Nunito Sans';
  font-size: 16px;
}
`;

const ComponentWrapper = styled('div')`
max-width: 280px;
max-height: 400px;
border-radius: inherit;
overflow: auto;
`;

const SearchBarAndListComponent = ({ placeholder, listdata, reduxFunction, itemPickerContainerMaxWidth }) => {
  const [filteredArray, setFilteredArray] = useState(listdata);
  const [searchWord, setSearchWord] = useState('');

  const handleFilter = (event) => {
    const localsearchWord = event.target.value;
    setSearchWord(event.target.value);
    const filteredData = listdata?.filter((item) => item.displayText?.toLowerCase().includes(localsearchWord));
    setFilteredArray(filteredData);
  };

  const resetList = () => {
    setSearchWord('');
    setFilteredArray(listdata);
  };

  return (
    <ComponentWrapper>
      <SearchBarContainer itemPickerContainerMaxWidth={itemPickerContainerMaxWidth}>
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <IcomoonReact
            iconSet={iconSet}
            size={14}
            icon='magnifying-glass-alt'
            color='#444444'
          />
          <StyledInput key='searchinput' type='search' placeholder={placeholder} onChange={handleFilter} value={searchWord} autoFocus='autoFocus' />
          {searchWord && (
            <IcomoonReact
              onClick={() => resetList()}
              iconSet={iconSet}
              size={14}
              icon='remove'
              color='#444444'
            />
          )}
        </Wrapper>
      </SearchBarContainer>
      <ul style={{ listStyle: 'none', padding: '0px', marginBottom: '0px' }}>
        {filteredArray.map((item) => (
          <ItemContainer
            key={item.id}
            onClick={() => reduxFunction(item)}
            id={item.id}
            itemPickerContainerMaxWidth={itemPickerContainerMaxWidth}
          >
            <ItemNameContainer>
              <Text fontSize={16} fontWeight={700}>
                {item.displayText}
              </Text>
            </ItemNameContainer>
          </ItemContainer>

        ))}
      </ul>
    </ComponentWrapper>
  );
};

SearchBarAndListComponent.propTypes = {
  placeholder: PropTypes.string.isRequired,
  listdata: PropTypes.arrayOf(PropTypes.object).isRequired,
  reduxFunction: PropTypes.func.isRequired,
  itemPickerContainerMaxWidth: PropTypes.number,
};

SearchBarAndListComponent.defaultProps = {
  itemPickerContainerMaxWidth: 280,
};

export default SearchBarAndListComponent;
