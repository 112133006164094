// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import IcomoonReact from 'icomoon-react';
import { openPopupWidget } from 'react-calendly';
import { useTheme } from 'emotion-theming';
import Text from '../../../../../shared/components/Text/Text';
import Button from '../../../../../shared/components/Button/Button';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
    background-color: #0dcc8a;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationWrapperFolder = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  .thanks-text {
    text-align: center;
  }
  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .schedule-call-button {
    margin-top: 30px;
  }
`;

const ModalVerifyReseller = ({
  onRequestClose,
}) => {
  const theme = useTheme();

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <NavigationWrapperFolder>
          <Text
            className='back-arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='remove'
            />
          </Text>
        </NavigationWrapperFolder>
        <OuterFolderFormWrapper>
          <FolderTextWrapper>
            <Text className='calendar-icon-text'>Online Payments Account</Text>
          </FolderTextWrapper>
          <div className='divider-line' />
          <InnerFolderFormWrapper>
            <Text className='thanks-text'>Thanks for submitting that information. Before you can start writing programs, you will need to schedule a call with us to verify a few pieces of information. Please use the button below to schedule your call. We look forward to speaking with you!</Text>
          </InnerFolderFormWrapper>
          <Button
            noBorder
            className='schedule-call-button'
            customColor={theme.colors.green}
            primary
            onClick={() => {
              openPopupWidget({
                url: 'https://calendly.com/teambuildr/15-minute-payments',
              });
            }}
            cta='Schedule Call'
          />
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

ModalVerifyReseller.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalVerifyReseller;
