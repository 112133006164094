/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Dropdown from 'react-bootstrap/Dropdown';
import SubText from '../../../../../../shared/components/SubText/SubText';
import iconSet from '../../../../../../shared/images/teambuildr-selection.json';
import TBButton from '../../../../../../shared/components/Button/Button';

import useNotifications from '../../../hooks/useNotifications';
import useConversations from '../../../hooks/useConversations';

const Container = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 0px 0px 5px;

  .topbar-back-button {
    display: none;
    
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
`;

const DropDownMenuWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  margin-bottom: -5px;
  margin-right: 20px;
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 15px;
    span {
      color: #999999;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
    }
    :hover {
      transition: linear 0s;
      background: #006a65;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }
`;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <div
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='dot-box'
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={30}
      icon='dots'
      color='grey'
    />
  </div>
));

const notificationDropdown = (
  handleDeleteNotifications, handleUpdateNotification, activeNotification, currentUser,
) => (
  <DropDownMenuWrapper>
    <Dropdown
      bsPrefix='trick-fix'
    >
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          const values = [parseInt(activeNotification.id, 10)];
          handleDeleteNotifications(currentUser.accountCode, values);
        }}
        >
          <>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='trashcan'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Delete
            </SubText>
          </>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {
          const values = {
            read: false,
          };
          handleUpdateNotification(
            currentUser.accountCode, parseInt(activeNotification.id, 10), values,
          );
        }}
        >
          <>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='alert-on'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Unread
            </SubText>
          </>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </DropDownMenuWrapper>
);

const NotificationTopBar = ({ activeNotification }) => {
  const {
    handleDeleteNotifications,
    handleUpdateNotification,
    handleSetActiveNotification,
  } = useNotifications();

  const {
    handleSetIsMobileSidebarOpen,
  } = useConversations();

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const handleBackButton = () => {
    handleSetIsMobileSidebarOpen(true);
    setTimeout(() => {
      handleSetActiveNotification({});
    }, 500);
  };

  return (
    <Container>
      <TBButton
        iconOnly
        icon='left-arrow'
        size='60'
        className='topbar-back-button'
        onClick={() => handleBackButton()}
      />
      {notificationDropdown(
        handleDeleteNotifications, handleUpdateNotification, activeNotification, currentUser,
      )}
    </Container>
  );
};

NotificationTopBar.propTypes = {
  activeNotification: PropTypes.instanceOf(Object).isRequired,
};

export default NotificationTopBar;
