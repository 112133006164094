/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import TableToExcel from '@linways/table-to-excel';
import SelectBox from '../../../../shared/_TBComponents/SelectBox';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/components/Button/Button';
import GroupFilter from './GroupFilter';
import {
  setGroup,
  fetchGroupOverview,
  fetchGroupHabits,
  fetchHabitsForAccount,
  resetGroupOverviewData,
  setSelectedGroupDropdownOptions,
  fetchGroups,
  fetchAthletesHabitDashboard,
} from '../../ducks/habitTrackingDashboardActions';
import GroupAnalysisTableNoData from './GroupAnalysisTableNoData';
import GroupAnalysisTable from './GroupAnalysisTable';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

// Custom styles for react-select components
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#007B55' : '#ced4da',
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 85, 0.25)' : null,
    '&:hover': {
      borderColor: '#007B55',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#E0F7E0' : state.isFocused ? '#F1F1F1' : 'white',
    color: state.isSelected ? '#007B55' : '#444444',
    padding: '10px 15px',
    '&:hover': {
      backgroundColor: '#E0F7E0',
      color: '#007B55',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
};

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button {
    :hover {
      cursor: pointer;
    }
  }
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const GroupAnalysis = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const group = useSelector((state) => state.habitTrackingDashboard.data.group);
  const groups = useSelector((state) => state.habitTrackingDashboard.data.groups);
  const selectedGroupDropdownOptions = useSelector((state) => state.habitTrackingDashboard.data.selectedGroupDropdownOptions);
  const groupOverviewData = useSelector((state) => state.habitTrackingDashboard.data.groupOverview);
  const isGroupOverviewLoading = useSelector((state) => state.habitTrackingDashboard.ui.isGroupOverviewLoading);
  const athleteList = useSelector((state) => state.habitTrackingDashboard.data.athletes);

  const [selectedOption, setSelectedOption] = useState(null);
  const [secondSelectBoxOptions, setSecondSelectBoxOptions] = useState([]);
  const [isSecondSelectBoxOpen, setIsSecondSelectBoxOpen] = useState(false);
  const [key, setKey] = useState(0);
  const [previousSecondOption, setPreviousSecondOption] = useState(null); // Store previous second dropdown selection

  const athleteDropdownList = athleteList?.map((athlete) => ({
    name: `${athlete.first} ${athlete.last}`,
    id: athlete.id,
  })) ?? [];

  const dispatch = useDispatch();

  // Open the second SelectBox if an option is already selected
  useEffect(() => {
    if (selectedGroupDropdownOptions[1]) {
      setIsSecondSelectBoxOpen(true);
      if (selectedGroupDropdownOptions[0]?.name === 'Group') {
        setSecondSelectBoxOptions(groups.map((g) => ({ name: g.name, id: g.id })));
      } else {
        setSecondSelectBoxOptions(athleteDropdownList);
      }
    }
  }, []);

  const fetchGroupAnalysisData = async (groupType, groupId, optionName, forceFetchHabits = false) => {
    let newHabitsArray = [];

    // Fetch habits if necessary
    if (forceFetchHabits || !newHabitsArray.length) {
      let habitsResult;
      if (groupType === 'Group') {
        habitsResult = await dispatch(fetchGroupHabits(currentUser.accountCode, groupId, 'GroupOverview'));
      } else if (groupType === 'Individual') {
        habitsResult = await dispatch(fetchHabitsForAccount(currentUser.accountCode, groupId, 'GroupOverview'));
      }

      if (habitsResult) {
        const combinedHabits = [...habitsResult.assignedHabits, ...habitsResult.universalHabits];
        newHabitsArray = combinedHabits.map((habit) => habit.id);
      }
    }

    // Set selected group and fetch group overview
    dispatch(setGroup({ groupType, groupname: optionName, groupid: groupId }));
    dispatch(fetchGroupOverview(currentUser.accountCode, groupType === 'Group' ? 2 : 1, groupId, newHabitsArray));
  };

  const handleSecondSelectBoxChange = (option, isInitial = false, firstOption = selectedOption) => {
    if (!firstOption) firstOption = selectedGroupDropdownOptions[0];

    // Check if the newly selected option is the same as the previous one
    if (previousSecondOption?.id === option.id) {
      return; // Do nothing if the same value is selected
    }

    // Update previous second dropdown selection
    setPreviousSecondOption(option);

    dispatch(setSelectedGroupDropdownOptions([firstOption, option]));
    if (!isInitial) fetchGroupAnalysisData(firstOption.name, option.id, option.name);

    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Group/Athlete Dropdown', 'Individual athlete or group selected');
  };

  // Fetch groups and athletes if not already loaded
  useEffect(() => {
    if (currentUser && (!athleteList?.length || !groups?.length)) {
      dispatch(fetchGroups(currentUser.accountCode));
      dispatch(fetchAthletesHabitDashboard(currentUser.accountCode));
    }
  }, [currentUser, athleteList.length, groups.length, dispatch]);

  const handleFirstSelectBoxChange = (option, isInitial = false) => {
    setSelectedOption(option);
    setIsSecondSelectBoxOpen(false);
    dispatch(resetGroupOverviewData());

    let newOptions = [];
    if (option.name === 'Group') {
      newOptions = groups.map((g) => ({ name: g.name, id: g.id }));
    } else if (option.name === 'Individual') {
      newOptions = athleteDropdownList;
    }

    setSecondSelectBoxOptions(newOptions);

    // Clear the second dropdown option if needed
    if (selectedGroupDropdownOptions[1]) {
      dispatch(setSelectedGroupDropdownOptions([option, null]));
    }

    if (newOptions.length) {
      setIsSecondSelectBoxOpen(true);
      if (isInitial && group) {
        const matchingOption = newOptions.find((opt) => opt.id === group.groupid);
        if (matchingOption) {
          handleSecondSelectBoxChange(matchingOption, true, option);
        }
      }
    }

    setKey((prevKey) => prevKey + 1);

    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Type Dropdown', `Type of ${option.name} selected`);
  };

  const handleDownload = () => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Download Clicked', 'Download button clicked on Group Analysis screen');

    // Clone the table and remove unwanted elements
    const originalTable = document.getElementById('reportTable');
    const clonedTable = originalTable.cloneNode(true);

    const headerRow = clonedTable.querySelector('thead tr');
    if (headerRow) {
      const headerCells = headerRow.querySelectorAll('th');
      headerCells.forEach((cell) => {
        let headerText = cell.innerText;
        if (headerText.includes('Thresholds<%>%Clear thresholds')) {
          headerText = headerText.replace('Thresholds<%>%Clear thresholds', '').trim();
        } else if (headerText.includes('Set Threshold')) {
          headerText = headerText.replace('Set Threshold', '').trim();
        }
        cell.innerText = headerText;
      });
    }

    // Remove unwanted body cells
    const rows = clonedTable.querySelectorAll('tbody tr');
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td');
      cells.forEach((cell) => {
        if (cell.innerText.includes('Set Threshold')) {
          cell.remove();
        }
      });
    });

    TableToExcel.convert(clonedTable, {
      name: 'Group_Analysis.xlsx',
      sheet: { name: 'Sheet 1' },
    });
  };

  return (
    <DashboardContainer>
      <OptionsHeader>
        <MenuOptionsContainer>
          <SelectBox
            options={[
              { name: 'Group', id: 1 },
              { name: 'Individual', id: 2 },
            ]}
            onChangeFunction={handleFirstSelectBoxChange}
            width='175px'
            customStyles={customStyles}
            placeholder='Select an option'
            isClearable={false}
            defaultValue={selectedGroupDropdownOptions[0] || { name: 'Select an option' }}
          />
          {(isSecondSelectBoxOpen || selectedGroupDropdownOptions[1]) && (
            <SelectBox
              options={secondSelectBoxOptions}
              onChangeFunction={handleSecondSelectBoxChange}
              width='175px'
              customStyles={customStyles}
              placeholder={secondSelectBoxOptions.length ? `Select ${selectedOption?.name.toLowerCase()}` : `No ${selectedOption?.name.toLowerCase()} available`}
              isClearable={false}
              defaultValue={selectedGroupDropdownOptions[1] || { name: 'Select an option' }}
              key={key}
            />
          )}
          <GroupFilter />
        </MenuOptionsContainer>
        <Button
          className='downloadBtn'
          disabled={!groupOverviewData?.results}
          iconOnly
          icon='download'
          onClick={handleDownload}
        />
      </OptionsHeader>

      {isGroupOverviewLoading ? (
        <Spinner />
      ) : !groupOverviewData?.results ? (
        <>
          <GroupAnalysisTableNoData containerWidth={1000} dataRows={['', '', '', '', '']} rowIsScrollable />
          <NoDataModal>
            <ModalTitle>
              {!groupOverviewData ? 'Select a Group' : 'Select a group or individual athlete.'}
            </ModalTitle>
            <ModalMessage>
              {!groupOverviewData
                ? 'Select a group using the dropdown above to get started'
                : 'There is no data available, please adjust your selection above and set thresholds.'}
            </ModalMessage>
          </NoDataModal>
        </>
      ) : (
        <GroupAnalysisTable dataRows={groupOverviewData.results} rowIsScrollable />
      )}
    </DashboardContainer>
  );
};

export default GroupAnalysis;
