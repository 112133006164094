import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  setTriOptionModal,
} from '../../ducks/sportCoachDashboardActions';

const MenuContainer = styled('div')`
  position: absolute;
  bottom: 0px;
  right: 0px;
  margin-bottom: 10px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #1787FB;
  width: 55px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.isBlurred ? '' : 'pointer')};
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
`;

const OverviewAnalysisMenu = () => {
  const chartData = useSelector((state) => state.sportCoachDashboard.data.chartData);

  const dispatch = useDispatch();

  const hasData = chartData.length > 0;

  const handleMenuClick = () => {
    if (hasData) {
      dispatch(setTriOptionModal(true));
    }
  };

  return (
    <MenuContainer isBlurred={!hasData} onClick={handleMenuClick}>
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        icon='dots'
        color='#FFFFFF'
        style={{ rotate: '90deg' }}
      />
    </MenuContainer>
  );
};

export default OverviewAnalysisMenu;
