/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  resetWorkoutProgram,
  setIsResetProgramModalShowing,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1004;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 225px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  padding: 20px;
`;

const ResetWorkoutModal = () => {
  const dispatch = useDispatch();

  const isResetProgramModalShowing = useSelector(
    (state) => state.workouts.ui.isResetProgramModalShowing,
  );
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const workoutProgramWeekIndex = useSelector(
    (state) => state.workouts.data.workoutProgramWeekIndex,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );
  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  return (
    <ModalContainer
      isActive={isResetProgramModalShowing}
    >
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <div style={{
            marginLeft: 10,
          }}
          >
            <ModalTextTop>Reset This Program?</ModalTextTop>
          </div>
        </div>
        <div className='remove-div-icon' onClick={() => dispatch(setIsResetProgramModalShowing(false))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        All of your data will be saved and you will be able to complete this program again.
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='RESET'
        className='print-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          dispatch(resetWorkoutProgram(
            currentUser,
            userId,
            currentSelectedProgram,
            currentNonDateDay,
            workoutProgramWeekIndex,
          ));
        }}
      />

    </ModalContainer>
  );
};

export default ResetWorkoutModal;
