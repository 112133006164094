import {
  FETCH_REPORTABLE_GROUPS_HT_START,
  FETCH_REPORTABLE_GROUPS_HT_FULFILLED,
  FETCH_REPORTABLE_GROUPS_HT_REJECTED,
  FETCH_HABITS_FOR_ACCOUNT_START,
  FETCH_HABITS_FOR_ACCOUNT_FULFILLED,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_CATEGORIES_FOR_ACCOUNT_START,
  FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED,
  FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED,
  FETCH_ATHLETES_FULFILLED_HT,
  FETCH_ATHLETES_START_HT,
  FETCH_ATHLETES_REJECTED_HT,
  CREATE_NEW_HABIT_START,
  CREATE_NEW_HABIT_FULFILLED,
  CREATE_NEW_HABIT_REJECTED,
  FETCH_HABIT_FOR_ACCOUNT_START,
  FETCH_HABIT_FOR_ACCOUNT_FULFILLED,
  FETCH_HABIT_FOR_ACCOUNT_REJECTED,
  EDIT_HABIT_START,
  EDIT_HABIT_FULFILLED,
  EDIT_HABIT_REJECTED,
  DELETE_HABIT_START,
  DELETE_HABIT_FULFILLED,
  DELETE_HABIT_REJECTED,
  CREATE_NEW_CATEGORY_START,
  CREATE_NEW_CATEGORY_FULFILLED,
  CREATE_NEW_CATEGORY_REJECTED,
  EDIT_CATEGORY_START,
  EDIT_CATEGORY_FULFILLED,
  EDIT_CATEGORY_REJECTED,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_FULFILLED,
  DELETE_CATEGORY_REJECTED,
  CHECK_5_HABIT_MAX_START,
  CHECK_5_HABIT_MAX_FULFILLED,
  CHECK_5_HABIT_MAX_REJECTED,
} from './actionTypes';

const initialState = {
  isReportableGroupsLoading: true,
  isAthletesLoading: true,
  isHabitsLoading: true,
  isCategoriesLoading: true,
  isNewHabitLoading: false,
  isSingleHabitLoading: false,
  isUpdateHabitLoading: false,
  isDeleteHabitLoading: false,
  isCheck5HabitMaxLoading: false,
  isNewCategoryLoading: false,
  isUpdateCategoryLoading: false,
  isDeleteCategoryLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_HT_START: {
      return {
        ...state,
        isReportableGroupsLoading: true,
      };
    }
    case FETCH_REPORTABLE_GROUPS_HT_FULFILLED: {
      return {
        ...state,
        isReportableGroupsLoading: false,
      };
    }
    case FETCH_REPORTABLE_GROUPS_HT_REJECTED: {
      return {
        ...state,
        isReportableGroupsLoading: false,
      };
    }
    case FETCH_ATHLETES_START_HT: {
      return {
        ...state,
        isAthletesLoading: true,
      };
    }
    case FETCH_ATHLETES_FULFILLED_HT: {
      return {
        ...state,
        isAthletesLoading: false,
      };
    }
    case FETCH_ATHLETES_REJECTED_HT: {
      return {
        ...state,
        isAthletesLoading: false,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_START: {
      return {
        ...state,
        isHabitsLoading: true,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isHabitsLoading: false,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        isHabitsLoading: false,
      };
    }
    case FETCH_CATEGORIES_FOR_ACCOUNT_START: {
      return {
        ...state,
        isCategoriesLoading: true,
      };
    }
    case FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isCategoriesLoading: false,
      };
    }
    case FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        isCategoriesLoading: false,
      };
    }
    case CREATE_NEW_HABIT_START: {
      return {
        ...state,
        isNewHabitLoading: true,
      };
    }
    case CREATE_NEW_HABIT_FULFILLED: {
      return {
        ...state,
        isNewHabitLoading: false,
      };
    }
    case CREATE_NEW_HABIT_REJECTED: {
      return {
        ...state,
        isNewHabitLoading: false,
      };
    }
    case FETCH_HABIT_FOR_ACCOUNT_START: {
      return {
        ...state,
        isSingleHabitLoading: true,
      };
    }
    case FETCH_HABIT_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isSingleHabitLoading: false,
      };
    }
    case FETCH_HABIT_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        isSingleHabitLoading: false,
      };
    }
    case EDIT_HABIT_START: {
      return {
        ...state,
        isUpdateHabitLoading: true,
      };
    }
    case EDIT_HABIT_FULFILLED: {
      return {
        ...state,
        isUpdateHabitLoading: false,
      };
    }
    case EDIT_HABIT_REJECTED: {
      return {
        ...state,
        isUpdateHabitLoading: false,
      };
    }
    case DELETE_HABIT_START: {
      return {
        ...state,
        isDeleteHabitLoading: true,
      };
    }
    case DELETE_HABIT_FULFILLED: {
      return {
        ...state,
        isDeleteHabitLoading: false,
      };
    }
    case DELETE_HABIT_REJECTED: {
      return {
        ...state,
        isDeleteHabitLoading: false,
      };
    }
    case CHECK_5_HABIT_MAX_START: {
      return {
        ...state,
        isCheck5HabitMaxLoading: true,
      };
    }
    case CHECK_5_HABIT_MAX_FULFILLED: {
      return {
        ...state,
        isCheck5HabitMaxLoading: false,
      };
    }
    case CHECK_5_HABIT_MAX_REJECTED: {
      return {
        ...state,
        isCheck5HabitMaxLoading: false,
      };
    }
    case CREATE_NEW_CATEGORY_START: {
      return {
        ...state,
        isNewCategoryLoading: true,
      };
    }
    case CREATE_NEW_CATEGORY_FULFILLED: {
      return {
        ...state,
        isNewCategoryLoading: false,
      };
    }
    case CREATE_NEW_CATEGORY_REJECTED: {
      return {
        ...state,
        isNewCategoryLoading: false,
      };
    }
    case DELETE_CATEGORY_START: {
      return {
        ...state,
        isDeleteCategoryLoading: true,
      };
    }
    case DELETE_CATEGORY_FULFILLED: {
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    }
    case DELETE_CATEGORY_REJECTED: {
      return {
        ...state,
        isDeleteCategoryLoading: false,
      };
    }
    case EDIT_CATEGORY_START: {
      return {
        ...state,
        isUpdateCategoryLoading: true,
      };
    }
    case EDIT_CATEGORY_FULFILLED: {
      return {
        ...state,
        isUpdateCategoryLoading: false,
      };
    }
    case EDIT_CATEGORY_REJECTED: {
      return {
        ...state,
        isUpdateCategoryLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
