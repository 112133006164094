/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBRangeSlider from '../../../../shared/components/TBRangeSlider/TBRangeSlider';
import {
  submitWorkoutItem, setQuestionnaireTotal, setMultiQuestionModal, submitProgramWorkoutItem,
} from '../../ducks/workoutsActions';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${BoxShadow};
  border-radius: 12px;
  flex-direction: column;
  padding: 15px;
  background: white;
  min-height: 100px;
  margin-top: ${(props) => (props.isFirst ? '0px' : '15px')};
  white-space: normal;
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
  }
`;

const FieldAndSlider = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;

const StyledInput = styled('input')`
	font-family: 'Nunito Sans';
	font-weight: 600;
	font-size: 11px;
	border: 1px solid #ccc;
	text-align: center;
  width: 30%;
  border-radius: 4px;
  height: 30px;
`;

const SliderContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

const RangeBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 10px;
  font-family: 'Nunito Sans';
  flex: 1;
`;

const TitleAndDescription = styled('div')`
  width: 100%;
  display: flex;
  font-size: 10px;
  font-family: 'Nunito Sans';
  flex-direction: column;
  margin-bottom: 10px;
`;

const Title = styled('div')`
  width: 100%;
  display: flex;
  font-size: 12px;
  font-family: 'Nunito Sans';
  white-space: normal;
`;

const Description = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;

const YesOption = styled('div')`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  align-items: center;
`;

const YesNoCircle = styled('div')`
  height: 30px;
  width: 30px;
  border: ${(props) => (props.isActive ? '1px solid #ff9a44' : '1px solid lightgrey')};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const YesNoText = styled('div')`
  display: flex;
  font-size: 14px;
  font-family: 'Nunito Sans';
  white-space: normal;
`;

const QuestionSeparator = styled('div')`
  width: 100%;
  height: 1px;
  background: lightgrey;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const TagSelectTrigger = styled('div')`
  font-weight: 500;
  color: grey;
  background: #ffffff;
	user-select: none;
  border-radius: 4px;
	cursor: pointer;
	min-height: 38px;
	padding: 8px;
	width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  border: none;
  flex: 1;
`;

const TriggerAndIcon = styled('div')`
  width: 100%;
  display: flex;
  :hover {
		border-color: #C0C0C0;
	}
	border-radius: 4px;
  border: 1px solid #D8D8D8;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`;

const HeaderDivider = styled('div')`
  border: 0.5px solid lightgrey;
  height: 60%;
`;

const IconContainer = styled('div')`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Question = ({
  question,
  first,
  workoutObject,
  index,
  outerIndex,
  sessionIndex,
}) => {
  const [sliderValue, setSliderValue] = useState(parseInt(question.response.value, 10) || 0);
  const [textInput, setTextInput] = useState(question.response.value);
  const [isYesNoYes, setIsYesNoYes] = useState(question.response.displayValue);
  const [isYesNoSubmitting, setIsYesNoSubmitting] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const multiQuestionModalResponse = useSelector(
    (state) => state.workouts.data.multiQuestionModalResponse,
  );
  const questionWorkoutId = useSelector(
    (state) => state.workouts.data.questionWorkoutId,
  );
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const programDayIndex = useSelector(
    (state) => state.workouts.data.programDayIndex,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(multiQuestionModalResponse).length
    && multiQuestionModalResponse.questionId === question.id
    && (workoutObject.assignedId === questionWorkoutId
      || (workoutObject.saveDataId === questionWorkoutId))) {
      const submissionObject = { questionId: question.id };
      submissionObject.value = multiQuestionModalResponse.value;
      if (workoutObject.assignedId) {
        dispatch(submitWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId,
          [submissionObject],
          index,
          outerIndex,
          sessionIndex,
        ));
      } else {
        dispatch(
          submitProgramWorkoutItem(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            workoutObject.saveDataId,
            [submissionObject],
            index,
            outerIndex,
            sessionIndex,
            currentSelectedProgram.dayBasedProgramId,
            programDayIndex,
          ),
        );
      }
      dispatch(setMultiQuestionModal({}, null));
    }
  }, [multiQuestionModalResponse]);

  useEffect(() => {
    if (question.inputType === 'RANGE') {
      if (question?.response?.value || question?.value) {
        setSliderValue(question?.response?.value || question?.value);
      } else if (question?.response?.value === null || question?.value === null) {
        setSliderValue(0);
      }
    } else if (question.inputType === 'INPUT') {
      if (question?.response?.value || question?.value) {
        setTextInput(question?.response?.value || question?.value);
      } else if (question?.response?.value === null || question?.value === null) {
        setTextInput('');
      }
    }
  }, [question]);

  useEffect(() => {
    if (question.inputType === 'YES-NO') {
      setIsYesNoSubmitting(false);
    }
  }, [question]);

  useEffect(() => {
    if (workoutObject.questionnaireTotalEnabled) {
      const QTCopy = {};
      if (workoutObject.assignedId) {
        QTCopy[workoutObject.assignedId] = {};
      } else {
        QTCopy[workoutObject.saveDataId] = {};
      }
      workoutObject.questions.forEach((questionOb) => {
        if (questionOb.inputType === 'MULTIPLE-CHOICE' || questionOb.inputType === 'YES-NO') {
          if (QTCopy[workoutObject.assignedId]) {
            QTCopy[workoutObject.assignedId][questionOb.id] = parseInt(
              questionOb.response.numericalWeight || 0,
              10,
            );
          } else {
            QTCopy[workoutObject.saveDataId][questionOb.id] = parseInt(
              questionOb.response.numericalWeight || 0,
              10,
            );
          }
        }
        if (parseInt(questionOb.value, 10)) {
          if (QTCopy[workoutObject.assignedId]) {
            QTCopy[workoutObject.assignedId][questionOb.id] = parseInt(questionOb.value, 10);
          } else {
            QTCopy[workoutObject.saveDataId][questionOb.id] = parseInt(questionOb.value, 10);
          }
        }
      });
      dispatch(setQuestionnaireTotal(QTCopy));
    }
  }, [workoutObject]);

  const fieldBlurFunction = () => {
    const submissionObject = { questionId: question.id };
    if (question.inputType === 'RANGE') {
      submissionObject.value = parseInt(sliderValue, 10);
    } else if (question.inputType === 'INPUT') {
      submissionObject.value = textInput;
    }
    if (workoutObject.assignedId) {
      dispatch(submitWorkoutItem(
        currentUser,
        sharedAthlete.id,
        currentUser.accountCode,
        activeWorkoutDate,
        workoutObject.assignedId,
        [submissionObject],
        index,
        outerIndex,
        sessionIndex,
      ));
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          [submissionObject],
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          programDayIndex,
        ),
      );
    }
  };

  const changeProcess = (value) => {
    setSliderValue(value);
  };

  const boxChangeProcess = (e) => {
    const newValue = e.target.value;
    setSliderValue(newValue);
  };

  const boxBlurFunction = (e) => {
    const newValue = e.target.value;
    const submissionObject = { questionId: question.id };
    if (newValue >= question.maxRangeValue) {
      setSliderValue(question.maxRangeValue);
      submissionObject.value = parseInt(question.maxRangeValue, 10);
    } else if (newValue <= question.minRangeValue) {
      setSliderValue(question.minRangeValue);
      submissionObject.value = parseInt(question.minRangeValue, 10);
    } else {
      submissionObject.value = e.target.value;
    }
    if (workoutObject.assignedId) {
      dispatch(submitWorkoutItem(
        currentUser,
        sharedAthlete.id,
        currentUser.accountCode,
        activeWorkoutDate,
        workoutObject.assignedId,
        [submissionObject],
        index,
        outerIndex,
        sessionIndex,
      ));
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          [submissionObject],
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          programDayIndex,
        ),
      );
    }
  };

  const setYesNo = (value, displayValue) => {
    if (!isYesNoSubmitting) {
      setIsYesNoSubmitting(true);
      const submissionObject = { questionId: question.id, value };
      setIsYesNoYes(displayValue);
      if (workoutObject.assignedId) {
        dispatch(submitWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId,
          [submissionObject],
          index,
          outerIndex,
          sessionIndex,
        ));
      } else {
        dispatch(
          submitProgramWorkoutItem(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            workoutObject.saveDataId,
            [submissionObject],
            index,
            outerIndex,
            sessionIndex,
            currentSelectedProgram.dayBasedProgramId,
            programDayIndex,
          ),
        );
      }
    }
  };

  const mouseUp = () => {
    const submissionObject = { questionId: question.id };
    if (question.inputType === 'RANGE') {
      submissionObject.value = parseInt(sliderValue, 10);
    } else if (question.inputType === 'INPUT') {
      submissionObject.value = textInput;
    }
    if (workoutObject.assignedId) {
      dispatch(submitWorkoutItem(
        currentUser,
        sharedAthlete.id,
        currentUser.accountCode,
        activeWorkoutDate,
        workoutObject.assignedId,
        [submissionObject],
        index,
        outerIndex,
        sessionIndex,
      ));
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          [submissionObject],
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          programDayIndex,
        ),
      );
    }
  };

  const yesNoGrubler = (choices) => {
    const parsedYesNoArray = [];
    choices.forEach((choice, idx) => {
      parsedYesNoArray.push(
        <YesOption onClick={() => {
          if (!isYesNoSubmitting) {
            setYesNo(choice.value, choice.displayValue);
          }
        }}
        >
          <YesNoText>{choice.displayValue}</YesNoText>
          <YesNoCircle isActive={choice.displayValue === isYesNoYes}>
            {(choice.displayValue === isYesNoYes) ? (
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color='#ff9a44'
              />
            ) : null}
          </YesNoCircle>
        </YesOption>,
      );
      if (idx === 0) {
        parsedYesNoArray.push(<QuestionSeparator />);
      }
    });
    return parsedYesNoArray;
  };

  const questionSurfer = (questionItem) => {
    if (questionItem.inputType === 'MULTIPLE-CHOICE') {
      return (
        <TriggerAndIcon onClick={() => dispatch(setMultiQuestionModal(
          questionItem, workoutObject.assignedId || workoutObject.saveDataId,
        ))}
        >
          <TagSelectTrigger>
            {questionItem?.response?.displayValue || 'Select an answer'}
          </TagSelectTrigger>
          <HeaderDivider />
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='down-arrow'
              color='lightgrey'
            />
          </IconContainer>
        </TriggerAndIcon>
      );
    }
    // yes/no question test
    if (questionItem.inputType === 'YES-NO') {
      return yesNoGrubler(questionItem.choices);
    }
    if (questionItem.inputType === 'RANGE') {
      return (
        <>
          <FieldAndSlider>
            <StyledInput
              id='input-field'
              value={sliderValue}
              type='number'
              onChange={boxChangeProcess}
              onBlur={boxBlurFunction}
            />
            <SliderContainer>
              <TBRangeSlider
                minValue={questionItem.minRangeValue}
                maxValue={questionItem.maxRangeValue}
                onChange={changeProcess}
                value={sliderValue}
                onBlurFunction={fieldBlurFunction}
                onMouseUpFunction={mouseUp}
              />
            </SliderContainer>
          </FieldAndSlider>
          <RangeBox>
            {(question.minRangeValue || question.minRangeValue === 0) && question.maxRangeValue ? `Range: ${question.minRangeValue} - ${question.maxRangeValue}` : null}
          </RangeBox>
        </>
      );
    }
    if (questionItem.inputType === 'INPUT') {
      return <textarea value={textInput} onBlur={fieldBlurFunction} onChange={(e) => setTextInput(e.target.value)} className='note-text-area' />;
    }
    return null;
  };

  return (
    <Container isFirst={first}>
      <TitleAndDescription>
        <Title>
          {question.title}
        </Title>
        <Description>
          {question.description}
        </Description>
      </TitleAndDescription>
      {questionSurfer(question)}
    </Container>
  );
};

Question.defaultProps = {
  sessionIndex: null,
};

Question.propTypes = {
  question: PropTypes.instanceOf(Object).isRequired,
  first: PropTypes.bool.isRequired,
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default Question;
