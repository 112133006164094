import React, { useState } from 'react';
import SharedModal from '../../shared/_TBComponents/Modal';
import Button from '../../shared/_TBComponents/Button';

const Playground = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SharedModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} />
      <Button type='button' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
    </>
  );
};

Playground.propTypes = { };
export default Playground;
