import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';

import NotificationSettingsModal from './NotificationSettingsModal';
import ButtonRound from './ButtonRound';
import { switchNotificationTypeSetting } from '../ducks/notificationsActions';
import TBToggleSwitch from '../../../shared/components/TBToggleSwitch/TBToggleSwitch';
import { Nunito } from '../../../shared/GlobalStyles';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import getWindowDimensions from '../../../shared/utils/getWindowDimensions';
import Text from '../../../shared/components/Text/Text';

const SettingsRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .select__value-container {
    padding: 2px 16px 2px 12px;
  }
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const TextLabel = styled('p')`
  font-weight: 600;
  margin-bottom: 0px;
  color: ${(props) => props.theme.textColor};
`;

const SubText = styled('span')`
  font-size: 12px;
  margin-top: 5px;
  color: ${(props) => props.theme.textColor};
`;

const InnerContentWrapper = styled('span')`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  max-height: 65vh;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  } 
`;

const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title-icon {
    margin-top: 20px;
  };
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgrey;
`;

const TitleText = styled('h2')`
  font-family: ${Nunito};
  color: black;
  margin-top: 10px;
`;

const IconAndGroupNameRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-left: 10px;
  }
`;

const handleIconNameing = (groupName) => {
  let iconName;
  switch (groupName) {
    case 'Feed':
      iconName = 'feed';
      break;
    case 'Journal':
      iconName = 'journal';
      break;
    case 'Workout':
      iconName = 'lift';
      break;
    case 'Payments':
      iconName = 'credit-card';
      break;
    case 'Wearables':
      iconName = 'watch-sync';
      break;
    default:
      iconName = 'sub-arrow';
  }
  return iconName;
};

/**
 * @param {Array} notificationTypes array of possible notification types
 * @param {Function} dispatch Redux dispatch function
 * @returns an array of settings rows that the user can interact with.  Maps over
 * the notification types and produces a component that includes text indicating
 * what the component does, and which notification type it will effect.  Component
 * installs a function that differs based on the notification type insofar that it
 * it is different based on type and notificationShortName.
 * The component includes a custom toggle component that's default toggle state is
 * set by whether or not the type's isEnabled property is true or false.
 * onChange fires a dispatch that interacts with the back end to change the database's
 * entry for whether that notification type's isEnabled property is true or false
 */

const SettingsItemMapper = (notificationTypes, notificationTypeGroups, dispatch) => {
  const notificationTypeGroupArrays = notificationTypeGroups
    && Object.entries(notificationTypeGroups);

  return notificationTypeGroupArrays?.map((notificationTypeGroupArray) => (
    <div key={notificationTypeGroupArray[0]}>
      <IconAndGroupNameRow>
        <IcomoonReact
          iconSet={iconSet}
          size={18}
          icon={handleIconNameing(notificationTypeGroupArray[0])}
        />
        <Text fontSize={19} fontWeight='700' ml={15}>
          {' '}
          {notificationTypeGroupArray[0]}
        </Text>
      </IconAndGroupNameRow>
      {notificationTypeGroupArray[1]?.map((type, idx) => (
        <SettingsRow>
          <TextContainer>
            <TextLabel>
              {type?.notificationName}
            </TextLabel>
            <SubText>
              Toggle whether to receive
              {' '}
              {type?.notificationName}
              {' '}
              notifications
            </SubText>
          </TextContainer>
          <TBToggleSwitch
            checked={type.isEnabled}
            onChange={() => {
              const { notificationShortName } = type;
              const dispatchableObject = {};
              dispatchableObject[notificationShortName] = !type.isEnabled;
              dispatch(
                switchNotificationTypeSetting(
                  dispatchableObject, idx, !type.isEnabled, notificationTypeGroupArray[0],
                ),
              );
            }}
          />
        </SettingsRow>
      ))}
    </div>
  ));
};

/**
 *
 * @param {Boolean} isOpen for determining whether or not the modal is currently open
 * @param {Function} onRequestClose function to call when user clicks outside of the modal
 * or otherwise intends to close the modal
 * @returns
 */

const NotificationSettingsModalContent = ({ isOpen, onRequestClose }) => {
  // Array of all possible notification types
  const notificationTypes = useSelector(
    (state) => state.notifications.data.notificationTypes,
  );
  const notificationTypeGroups = useSelector(
    (state) => state.notifications.data.notificationTypeGroups,
  );

  const dispatch = useDispatch();
  return (
    // general styled modal component that produces the overlay and general wrapper
    <NotificationSettingsModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {getWindowDimensions().height > 500 ? (
        <TitleContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={40}
            icon='settings'
            color='black'
          />
          <TitleText>
            Notification Settings
          </TitleText>
        </TitleContainer>
      ) : null}
      <InnerContentWrapper>
        {/**
         * Component that accepts a title and a string to display at the top of the modal
         */}
        {/**
         * SettingsItemMapper maps over the notification types
         * and uses each type to create a component that the user
         * can intereact with
         */}
        {SettingsItemMapper(notificationTypes, notificationTypeGroups, dispatch)}
      </InnerContentWrapper>
      {/**
       * Button that calls the onRequest close function when pressed
       */}
      <ButtonRound
        bottom
        fullWidth
        cta='Close'
        className='modal-button'
        large
        noBorder
        primary
        square
        onClick={onRequestClose}
      />
    </NotificationSettingsModal>
  );
};

NotificationSettingsModalContent.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default NotificationSettingsModalContent;
