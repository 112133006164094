import React from 'react';
import styled from '@emotion/styled';

import { useSelector } from 'react-redux';
// import logger from 'js-logger';
import SubText from '../../../../../shared/components/SubText/SubText';

const TitleArea = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid rgb(232, 232, 232);
  height: 160px;
  align-items: center;
  padding: 30px 80px 5px 80px;
  justify-content: center;

  img {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    height: 135px;
  }
`;

const CustomLoginFormHeader = () => {
  const customLoginInfo = useSelector((state) => state.auth.data.customLoginData);
  const customLogo = customLoginInfo?.logo;

  return (
    <TitleArea>
      <img
        style={{
          resizeMode: 'cover',
          maxHeight: 80,
          maxWidth: 200,
        }}
        src={customLogo}
        alt='Teambuildr'
        className='img-fluid'
      />
      <SubText color='text'>Sign In</SubText>
    </TitleArea>
  );
};

export default CustomLoginFormHeader;
