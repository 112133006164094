import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import { componentWillAppendToBody } from 'react-append-to-body';

import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Table from '../../../../../shared/components/Table/Table';
import Text from '../../../../../shared/components/Text/Text';
import SubText from '../../../../../shared/components/SubText/SubText';
import ActionButton from '../../../../../shared/components/ActionButton/ActionButton';
import useReseller from '../../hooks/useReseller';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import ModalHandler from '../reseller/ModalHandler';
import ResellerAlertBanner from '../reseller/ResellerAlertBanner';
import Button from '../../../../../shared/components/Button/Button';
import ResellerProgramsFilter from '../reseller/ResellerProgramsFilter';
import ResellerOverlay from '../reseller/ResellerOverlay';

import {
  setActiveReduxModal,
} from '../../../ducks/resellerActions';

const MainContainer = styled('main')`
  flex: 10;
  overflow: scroll;
`;

const GridContainer = styled('div')`
  margin-left: 30px;
  margin-right: 30px;
`;

const FilterContainer = styled('div')`
  margin-left: 30px;
  margin-right: 30px;
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 30px;

  h1 {
    text-transform: capitalize;
  }
`;

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 60%;
  justify-content: center;
  width: 100%;
  margin-left: -150px;
`;

const CreateButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
`;

const DropdownItemContainer = styled('div')`

`;

const SlugGlassWrapper = styled('div')`
  display: flex;
  .mag-glass {
    margin-top: -3px;
    margin-left: 5px;
  }
`;

const createProgramUrl = (resellerSlug, programSlug) => {
  /*
  Use the market subdomain for the programs url, unless we're on localhost
  */
  if (process.env.ENVIRONMENT === 'LOCAL') {
    return `/programs/${resellerSlug}/${programSlug}`;
  }
  return `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${resellerSlug}/${programSlug}`;
};

const customNoRecordComponent = (theme, setActiveModal) => {
  const TextBox = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 10px;
  font-size: 20px;
  button {
    margin-top: 15px;
    &:hover {
      color: white;
      opacity: .7;
    }
  }
`;
  const TextAndIcon = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 100px;
`;
  return (
    <TextAndIcon>
      <TextBox>
        <Text fontSize={theme.textFontSizes[3]}>
          There are currently no programs that match your filter.
        </Text>
        <Button
          onClick={() => {
            setActiveModal('create_program_modal');
          }}
          cta='Create A Program'
          customColor={theme.colors.green}
          noBorder
          primary
          large
          rounded
          noHover
        />
      </TextBox>
    </TextAndIcon>
  );
};

const DropdownMenu = ({
  dispatch,
  row,
  setActiveModal,
  setCurrentRow,
  setRowProgram,
  setProgramToArchive,
  currentReseller,
}) => (
  <>
    {/* Added a global component from emotion to inject css classes to the global html elements. */}
    <Global
      styles={css`
        .dropdown-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
          span {
            color: #999999;
          }
          .drop-icon-text {
            margin-left: 10px;
            padding-top: 2px;
          }
          :hover {
            transition: linear 0s;
            background: #006a65;
            color: white;
            span {
              color: white;
            }
            svg {
              fill: white;
            }
          }
        }

        .dropdown-menu {
          padding: 0px;
        }

        .dropdown-divider {
          margin: 0px;
        }
      `}
    />
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => {
        setCurrentRow(row);
      }}
      >
        <DropdownItemContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='pencil'
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            Update
          </SubText>
        </DropdownItemContainer>
      </Dropdown.Item>
      <Dropdown.Divider />
      <DropdownItemContainer>
        <Dropdown.Item onClick={() => {
          setActiveModal('program_url_modal');
          setRowProgram(row);
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='magnifying-glass-alt'
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            Preview
          </SubText>
        </Dropdown.Item>
      </DropdownItemContainer>
      <Dropdown.Divider />
      <DropdownItemContainer>
        <Dropdown.Item onClick={() => {
          setProgramToArchive(row);
          dispatch(setActiveReduxModal('archive_program_modal'));
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='trashcan'
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            Delete
          </SubText>
        </Dropdown.Item>
      </DropdownItemContainer>
    </Dropdown.Menu>
  </>
);

const AppendDropdownList = componentWillAppendToBody(DropdownMenu);

const ProgramsGrid = ({
  connectionComplete,
}) => {
  const {
    currentUser,
    currentReseller,
    userGroups,
    programs,
    isProgramsLoading,
    handleFetchUserGroups,
    handleFetchCalendars,
    handleFetchPrograms,
  } = useReseller();

  const dispatch = useDispatch();

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const [currentRow, setCurrentRow] = useState(null);
  const [rowProgram, setRowProgram] = useState(null);
  const [ProgramToArchive, setProgramToArchive] = useState(null);

  // const isProgramCreating = useSelector((state) => state.reseller.ui.isProgramCreating);

  // re-fetches calendars whenever currentUser changes
  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !programs && !isProgramsLoading) {
      handleFetchPrograms(accountCode);
    }
  }, [currentUser]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
      handleFetchCalendars(accountCode);
    }
  }, [currentUser]);

  const theme = useTheme();

  const customTableStyle = {
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        icon='dots'
      />
    </Link>
  ));

  /**
   * this array is passed to the react data table component, and styles the rows
   * differently depending on whether they meet certain criteria.  currently, I'm giving
   * child calendars sharp edges and clumping them together under the parent calendar
   */
  const conditionalRowStyles = [
    {
      when: (row) => row.id,
      style: {
        paddingLeft: '10px',
      },
    },
  ];

  /**
   * cells for the table:
   * orientation arrow, icon, name, user count, created date, workout visability, dropdown toggle
   */
  const columns = [
    {
      selector: 'programName',
      name: 'Name',
      sortable: true,
      style: {
        'font-weight': 'bold',
      },
    },
    {
      selector: 'urlSlug',
      name: 'Program URL',
      cell: (row) => (
        <SlugGlassWrapper>
          <Text
            style={{ fontSize: '12px' }}
            onClick={() => {
              // window.open(createProgramUrl(currentReseller.urlSlug, row.urlSlug), '_blank');
              setActiveModal('program_url_modal');
              setRowProgram(row);
            }}
          >
            {row.urlSlug}
          </Text>
          <Text
            className='mag-glass'
            onClick={() => {
              // window.open(createProgramUrl(currentReseller.urlSlug, row.urlSlug), '_blank');
              setActiveModal('program_url_modal');
              setRowProgram(row);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='magnifying-glass'
            />
          </Text>
        </SlugGlassWrapper>
      ),
      sortable: true,
      style: {
        'font-weight': 'bold',
        'margin-left': '-10px',
      },
      center: true,
    },
    {
      cell: (row) => {
        if (row.status === 1) {
          return 'Active';
        } if (row.status === 2) {
          return 'Archived';
        }
        return 'Inactive';
      },
      name: 'Status',
      selector: 'status',
      sortable: true,
      center: true,
    },
    {
      cell: (row) => {
        const newMoment = moment(row.createdAt);
        return newMoment.format('MMMM D, YYYY');
      },
      name: 'Date Created',
      sortable: true,
      selector: 'createdAt',
      center: true,
      style: {
        'margin-right': '17px',
      },
    },
    {
      cell: (row) => {
        if (row.id) {
          return (
            <DropDownMenuWrapper>
              <Dropdown
                bsPrefix='trick-fix'
              >
                <Dropdown.Toggle as={CustomToggle} />
                <AppendDropdownList
                  row={row}
                  setCurrentRow={setCurrentRow}
                  setRowProgram={setRowProgram}
                  setProgramToArchive={setProgramToArchive}
                  dispatch={dispatch}
                  currentReseller={currentReseller}
                  setActiveModal={setActiveModal}
                />
              </Dropdown>
            </DropDownMenuWrapper>

          );
        }
        return null;
      },
      right: true,
      width: '65px',
    },
  ];

  // custom button for creating a single calendar that only shows
  // when in the active calendars view
  const createProgramButton = (setActiveModal) => (
    <ActionButton
      icon='plus'
      customColor={theme.colors.green}
      onClick={() => {
        setActiveModal('create_program_modal');
      }}
    />
  );

  return (
    <MainContainer>
      <ResellerOverlay />
      {!currentReseller.stripeChargesEnabled && (
        <ResellerAlertBanner />
      )}
      <HeaderRow>
        <HeaderText
          fontWeight={300}
          letterSpacing='normal'
        >
          Programs
        </HeaderText>
      </HeaderRow>
      <FilterContainer>
        <ResellerProgramsFilter />
      </FilterContainer>
      <GridContainer>
        {isProgramsLoading ? (<SpinnerWrapper><Spinner /></SpinnerWrapper>) : (
          <Table
            columns={columns}
            data={programs}
            highlightOnHover
            pointerOnHover
            noHeader
            responsive
            onRowClicked={(row) => {
              setCurrentRow(row);
            }}
            style={customTableStyle}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={
              !isProgramsLoading
                ? customNoRecordComponent(theme, setActiveModal)
                : null
            }
            pagination
          />
        )}
      </GridContainer>
      <CreateButtonWrapper>
        <ModalHandler
          actionButton={createProgramButton}
          currentRow={currentRow}
          rowProgram={rowProgram}
          setCurrentRow={setCurrentRow}
          ProgramToArchive={ProgramToArchive}
          connectionComplete={connectionComplete}
        />
      </CreateButtonWrapper>
    </MainContainer>
  );
};

DropdownMenu.propTypes = {
  dispatch: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
  setCurrentRow: PropTypes.func.isRequired,
  setProgramToArchive: PropTypes.func.isRequired,
  currentReseller: PropTypes.instanceOf(Object).isRequired,
};

export default ProgramsGrid;
