/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import SelectionModal from './SelectionModal';

import {
  setEditEvalModal1,
  setEvalOverlay,
} from '../../ducks/evaluationsActions';
import NewEvalModal1 from './NewEvalModal1';
import SelectExistingEvalModal1 from './SelectExistingEvalModal1';
import EditEvalModal1 from './EditEvalModal1';
import DeleteConfirmationModalContainer from './DeleteConfirmationModalContainer';
import AutoSaveInfoModalContainer from './AutoSaveInfoModalContainer';

const SelectionModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  margin-top: 49px;

  @media only screen and (max-width : 768px) {
    position: relative;
    height: auto;
  }
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const SelectionModalContainer = () => {
  const evalOverlayOpen = useSelector((state) => state.evaluations.ui.evalOverlayOpen);
  const editEvalModal1Object = useSelector(
    (state) => state.evaluations.data.editEvalModal1Object,
  );
  const dispatch = useDispatch();

  const closeOverlays = () => {
    dispatch(setEvalOverlay(false));
    dispatch(setEditEvalModal1({}));
  };

  return (
    <>
      <SelectionModalOverlay
        isActive={evalOverlayOpen || Object.keys(editEvalModal1Object).length}
        onClick={() => {
          closeOverlays();
        }}
      />
      <SelectionModal />
      <NewEvalModal1 />
      <EditEvalModal1 />
      <DeleteConfirmationModalContainer />
      <SelectExistingEvalModal1 />
      <AutoSaveInfoModalContainer />
    </>
  );
};

SelectionModalContainer.propTypes = { };

export default SelectionModalContainer;
