/**
 * file for importing actions and wrapping them in functions for export to calendars.js
 * also for importing various slices of state for use in calendars.js
 */

import { useDispatch, batch } from 'react-redux';
import {
  fetchConversations,
  uploadMedia,
  fetchGroups,
  fetchCalendars,
  fetchUsers,
  fetchConversationMessages,
  fetchMoreMessages,
  setActiveConversation,
  clearActiveConversation,
  createNewMessage,
  setInitialMessages,
  createConversation,
  markAsRead,
  deleteConversations,
  muteNotifications,
  createIndividualConversations,
  createSilentConversation,
  sendExternalMessageOnly,
  submitSupport,
  setIsMobileSidebarOpen,
} from '../../ducks/conversationsActions';

const useConversations = () => {
  const dispatch = useDispatch();

  const handleFetchConversations = (maintainActiveConversation, refreshPage) => {
    dispatch(fetchConversations(maintainActiveConversation, refreshPage));
  };

  const handleFetchConversationMessages = (accountCode, conversationId) => {
    dispatch(fetchConversationMessages(accountCode, conversationId));
  };

  const handleFetchMoreMessages = (accountCode, conversationId) => {
    dispatch(fetchMoreMessages(accountCode, conversationId));
  };

  const handleSetActiveConversation = (conversation) => {
    dispatch(setActiveConversation(conversation));
  };

  const handleClearActiveConversation = () => {
    dispatch(clearActiveConversation());
  };

  const handleCreateNewMessage = (newMessage, accountCode, conversationId) => {
    dispatch(createNewMessage(newMessage, accountCode, conversationId));
  };

  const handleSetInitialMessages = (initialMessages) => {
    dispatch(setInitialMessages(initialMessages));
  };

  const handleFetchGroups = (accountCode) => {
    dispatch(fetchGroups(accountCode));
  };

  const handleFetchCalendars = (accountCode) => {
    dispatch(fetchCalendars(accountCode));
  };

  const handleFetchUsers = (accountCode) => {
    dispatch(fetchUsers(accountCode));
  };

  const handleUploadMedia = (values) => {
    dispatch(uploadMedia(values));
  };

  const handleCreateConversation = (values) => {
    dispatch(createConversation(values));
  };

  const handleMarkAsRead = (values) => {
    dispatch(markAsRead(values));
  };

  const handleDeleteConversations = (values) => {
    dispatch(deleteConversations(values));
  };

  const handleMuteNotifications = (values) => {
    dispatch(muteNotifications(values));
  };

  const handleCreateSilentConversation = (values) => {
    dispatch(createSilentConversation(values));
  };

  const handleCreateIndividualConversations = (values) => {
    dispatch(createIndividualConversations(values));
  };

  const handleSendExternalMessageOnly = (newMessage, accountCode, conversationId) => {
    dispatch(sendExternalMessageOnly(newMessage, accountCode, conversationId));
  };

  const handleSupportSubmission = (values) => {
    dispatch(submitSupport(values));
  };

  const handleSetIsMobileSidebarOpen = (isOpen) => {
    dispatch(setIsMobileSidebarOpen(isOpen));
  };

  return {
    handleFetchConversations,
    handleFetchGroups,
    handleFetchUsers,
    handleFetchCalendars,
    handleUploadMedia,
    handleFetchConversationMessages,
    handleFetchMoreMessages,
    handleSetActiveConversation,
    handleClearActiveConversation,
    handleCreateNewMessage,
    handleSetInitialMessages,
    handleCreateConversation,
    handleMarkAsRead,
    handleDeleteConversations,
    handleMuteNotifications,
    handleCreateIndividualConversations,
    handleCreateSilentConversation,
    handleSendExternalMessageOnly,
    handleSupportSubmission,
    handleSetIsMobileSidebarOpen,
  };
};

export default useConversations;
