/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const MaxesAndPRCard = ({
  maxObject,
  small,
}) => {
  const theme = useTheme();

  const calculateFontSize = (textString, baseFontSize) => {
    const maxCharacters = 4; // Set the maximum number of characters before decreasing font size
    const fontSizeIncrement = 4; // Set the font size decrement for each character beyond the limit

    const charactersCount = textString.length;
    let fontSize = baseFontSize;

    if (charactersCount > maxCharacters) {
      const extraCharacters = charactersCount - maxCharacters;
      fontSize -= extraCharacters * fontSizeIncrement;
    }
    if (fontSize < 12) {
      fontSize = 12;
    }
    return fontSize;
  };

  /** styles */
  const Wrapper = styled('div')`
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.10);
    min-width: ${(props) => (props.small ? '160px' : '250px')};
    max-width: ${(props) => (props.small ? '160px' : '250px')};
    min-height: ${(props) => (props.small ? '160px' : '250px')};
    max-height: ${(props) => (props.small ? '160px' : '250px')};
    flex-direction: column;
    background-color: white;
`;

  const Header = styled('div')`
    background: linear-gradient(270deg, #764CA3 0%, #677EEA 100%);
    padding: ${(props) => (props.small ? '5px' : '20px')};
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${(props) => (props.small ? '36px' : '62px')};
    max-height: ${(props) => (props.small ? '36px' : '62px')};
`;

  const Container = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  `;
  const PRNumberContainer = styled('div')`
    display: flex;
    flex: 1;
    align-items: center;
    gap: ${(props) => (props.small ? '10px' : '20px')};
  `;

  const PreviousContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 50%;
    width: 100%;
    align-items: center;
  `;
  const Line = styled('div')`
    display: flex;
    height: 1px;
    width: 80%;
    background: ${(props) => props.color};
    margin-bottom: ${(props) => (props.small ? '10px' : '10px')};
  `;

  const Title = styled('p')`

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-family: 'Nunito Sans';
    margin-bottom: 0px;
    font-size: ${(props) => (props.small ? '10px' : '15px')};
    font-weight: 800;
    color: white;
  
  `;

  return (
    <Wrapper small={small}>
      <Header small={small}>
        <Title small={small}>
          {maxObject?.name}
        </Title>
      </Header>
      <Container>
        <PRNumberContainer small={small}>
          <IcomoonReact
            iconSet={iconSet}
            size={small ? 12 : 24}
            icon='play-filled'
            color={theme.colors.green}
            style={{ transform: 'rotate(-90deg)' }}
          />
          <Text style={{ maxWidth: small ? '118px' : '182px', overflow: 'auto' }} fontSize={small ? calculateFontSize(maxObject?.current.toString(), 36) : calculateFontSize(maxObject?.current.toString(), 50)} fontWeight='800' color={theme.colors.text}>{maxObject?.current}</Text>
        </PRNumberContainer>
        <PreviousContainer>
          <Line color='#BDBDBD' small={small} />
          <Text fontSize={small ? '8px' : '14px'} fontWeight='600' color='#BDBDBD'>Previous</Text>
          <Text fontSize={small ? '14px' : '20px'} fontWeight='800' color='#BDBDBD'>{maxObject?.previous}</Text>
        </PreviousContainer>
      </Container>
    </Wrapper>
  );
};

MaxesAndPRCard.propTypes = {
  maxObject: PropTypes.instanceOf(Object).isRequired,
  small: PropTypes.bool,
};
MaxesAndPRCard.defaultProps = {
  small: false,
};

export default MaxesAndPRCard;
