import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Slider from 'react-rangeslider';
import { FastField } from 'formik';

import { ColorLightGray, ColorGreen } from '../../../../shared/GlobalStyles';

const ScienceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 51px;
`;

const ScienceInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ScienceRange = styled('div')`
  display: flex;
  flex-direction: column;
  
  .rangeslider-horizontal {
    height: 7px;
    flex-grow: 1;
    margin-left: 30px;
    .rangeslider__fill {
      background-color: ${ColorGreen};
    }
  }

  .rangeslider__handle {
    height: 20px;
    width: 20px;
    &:focus {
      outline: none;
    }
    &:after {
      display: none;
    }
  }
`;

const Text = styled('p')`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${props => props.theme.lightTextColor2};

  ${props => (
    props.small ? `
      font-size: 12px;
    ` : ''
  )}

`;

const FormContainer = styled('div')`
  display: flex;
  margin: 15px 0px;
  padding-right: 10px;
  .form-group {
    margin-left: 0px;
    width: 35px!important;
  }
`;

const ScienceEntries = ({
  description,
  index,
  inputType,
  handleChange,
  values,
  rangeStart,
  rangeEnd,
  setFieldValue,
}) => (
  <ScienceContainer id='science-container'>
    <ScienceRange id='science-range'>
      <ScienceInfo>
        <Fragment>
          <Text>{description}</Text>
          {inputType === 0 && (
            <Text small>{`Range ${rangeStart} - ${rangeEnd}`}</Text>
          )}
        </Fragment>
      </ScienceInfo>
      <FormContainer>
        {inputType === 0 ? (
          <Fragment>
            <div className='form-group'>
              <input
                name={`result[${index}].value[0]`}
                className='form-control'
                onChange={handleChange}
                value={values.result[index].value[0]}
                type='number'
              />
            </div>
            <Slider
              value={values.result[index].value[0]}
              min={rangeStart}
              max={rangeEnd}
              name={`result[${index}].value[0]`}
              orientation='horizontal'
              onChange={e => setFieldValue(`result[${index}].value[0]`, e, false)}
            />
          </Fragment>
        ) : (
          <input
            name={`result[${index}].value[0]`}
            className='form-control'
            onChange={handleChange}
            type='text'
            value={values.result[index].value[0]}
          />
        )}
      </FormContainer>
    </ScienceRange>
  </ScienceContainer>
);

ScienceEntries.propTypes = {
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inputType: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  rangeStart: PropTypes.number.isRequired,
  rangeEnd: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default ScienceEntries;
