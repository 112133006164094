import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ColorPrimary, ColorSecondary } from '../../../../shared/GlobalStyles';

const DropdownListContainer = styled('div')`
  display: flex;
  /* flex-wrap: wrap; */
  position: relative;
  flex-direction: column;
  height: 300px;
  overflow: auto;
  padding: 10px 0px;
  background-color: ${props => props.theme.backgroundColorL2};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  margin-left: 15px;
  
  & span {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const DropdownItem = styled('div')`
  min-width: 200px;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 900;
  letter-spacing: 1.1px;
  color: ${props => props.theme.textColor};
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 20px;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: ${ColorSecondary};
    opacity: 1;
    color: white;
  }
`;

class DropdownList extends Component {
  constructor(props) {
    super(props);
    this.handleDropdownClick = this.handleDropdownClick.bind(this);
  }

  handleDropdownClick(group) {
    const { handleActive } = this.props;
    handleActive(group);
  }

  render() {
    const { data } = this.props;
    return (
      <DropdownListContainer>
        <DropdownItem
          data-name=''
          onClick={() => this.handleDropdownClick({
            id: '',
            name: 'All Athletes',
          })}
        >
          All Athletes
        </DropdownItem>
        {data.map(group => (
          <DropdownItem
            key={group.id}
            data-name={group.name}
            data-id={group.id}
            data-group={{
              name: group.name,
              id: group.id,
            }}
            onClick={() => this.handleDropdownClick(group)}
          >
            {group.name}
          </DropdownItem>
        ))}
      </DropdownListContainer>
    );
  }
}

DropdownList.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleActive: PropTypes.instanceOf(Function).isRequired,
};

export default DropdownList;
