import { useDispatch } from 'react-redux';
import {
  closeGlobalModal,
  toggleSupersets,
  toggleDarkTheme,
  toggleWeightValue,
  setScreens,
} from '../../ducks/weightroomActions';

const useWeightroomSettings = () => {
  const dispatch = useDispatch();

  const modalClose = () => {
    dispatch(closeGlobalModal());
  };

  const toggleSuperset = () => {
    dispatch(toggleSupersets());
  };

  const setScreenAmount = (screenAmount) => {
    dispatch(setScreens(screenAmount));
  };

  const toggleDark = () => {
    dispatch(toggleDarkTheme());
  };

  const toggleWeight = () => {
    dispatch(toggleWeightValue());
  };

  return {
    modalClose,
    setScreenAmount,
    toggleDark,
    toggleSuperset,
    toggleWeight,
  };
};

export default useWeightroomSettings;
