/* eslint-disable max-len */
import React from 'react';
import Helmet from 'react-helmet';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Layout from './Layout';
import Sidebar from './SideBar';

/** when you are importing LayoutWithsidebarNav...
 * you should pass in a prop that is an array of objects
 * that will reference your your NavLinks for the SideBar,
 * each object contains the info for a specific navLink,
 * you need to pass in the name of the route and the text to be displayed
 * for example: navLinkArrayOfObjects =

  [ {routeName: settings, text: General Settings},
    {routeName: settings/account, text: Account Settings}
  ]
* you will pass navLinkArrayOfObjects as a prop to the LayoutWithSidebarNav component
* with prop drilling you will then pass it to the SideBar component
  that is a child of the LayoutWithSideBarNav Compnent
* so you will define this array of objects in the file where you are importing LayoutWithSideBarNav
* or you can define it in the constants file
*/

/** sideBarTitle and sideBarTitleIcon are optional props if you want the sideBar to have a title */

/** lastley, you also have optional props to define when content is loading (isContentLoading) and
 to define the helmet text for the page
 */

const LayoutWithSidebarNav = ({
  children, navLinkArrayOfObjects, sideBarTitle, sideBarTitleIcon, helmetText,
}) => {
  const theme = useTheme();

  const PageWrapper = styled('div')`
    display: flex;
    flex: 1;
    height: 100%;
    margin-top: 50px;
    justify-content: center;
  `;

  const WrapperColumn1 = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
    
      @media (max-width: 800px) {
          display: none;
      }
  `;

  const WrapperColumn2 = styled('div')`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 120px 40px;
    @media(max-width: 900px){
     
    }
      @media (max-width: 800px) {
          flex: 1;
      }
      @media (max-width: 460px) {
          padding: 10px;
      }
      @media (max-width: 385px) {
        padding: 0px;
    }
  `;

  const InnerWrapperColoumn2 = styled('div')`
    display: block;
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 80px;
    background-color: ${theme.colors.background[0]};
    border-radius: 24px;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    max-width: 950px;

    @media(max-width: 900px){
      padding: 25px;
    }
    @media (max-width: 300px) {
      padding: 10px;
      border-radius: 0px;
      width: 100%;
    }
  `;

  return (
    <Layout fullWidth>
      <PageWrapper>
        <Helmet>
          <title>
            {' '}
            {helmetText}
            {' '}
            | TeamBuildr
          </title>
        </Helmet>
        <>
          <WrapperColumn1>
            <Sidebar
              navLinkArrayOfObjects={navLinkArrayOfObjects}
              sideBarTitle={sideBarTitle}
              sideBarTitleIcon={sideBarTitleIcon}
            />
          </WrapperColumn1>
          <WrapperColumn2>
            <InnerWrapperColoumn2>
              { children }
            </InnerWrapperColoumn2>
          </WrapperColumn2>
        </>
      </PageWrapper>
    </Layout>
  );
};

LayoutWithSidebarNav.propTypes = {
  children: PropTypes.element.isRequired,
  navLinkArrayOfObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  helmetText: PropTypes.string,
  sideBarTitle: PropTypes.string,
  sideBarTitleIcon: PropTypes.string,

};

LayoutWithSidebarNav.defaultProps = {
  helmetText: 'TeamBuildr',
  sideBarTitle: '',
  sideBarTitleIcon: '',
};

export default LayoutWithSidebarNav;
