import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_START,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_IS_METRIC_INFO_MODAL_SHOWING,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_HABIT_TRACKING_VIEW,
  FETCH_GROUP_ANALYSIS_START,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  SET_SELECTED_ATHLETE_GROUP,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  FETCH_ATHLETES_START_HT_DASHBOARD,
  FETCH_ATHLETES_FULFILLED_HT_DASHBOARD,
  FETCH_ATHLETES_REJECTED_HT_DASHBOARD,
  FETCH_HABITS_FOR_ACCOUNT_START,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_GROUP_OVERVIEW_START,
  FETCH_GROUP_OVERVIEW_REJECTED,
  FETCH_GROUP_OVERVIEW_FULFILLED,
  SET_IS_GROUP_FILTER_SHOWING,
  SET_IS_CHART_OPAQUE,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_ONBOARDING_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  habitTrackingView: 'management',
  isGroupAnalysisLoading: false,
  isGroupsLoading: false,
  isLegendInfoModalShowing: false,
  isMetricFiltersGroupShowing: false,
  isMetricFiltersPerformanceShowing: false,
  isMetricInfoModalShowing: false,
  isPerformanceLoading: false,
  isAthleteLoading: false,
  isAthleteHabitsLoading: false,
  isGroupOverviewLoading: false,
  isGroupFilterShowing: false,
  isChartOpaque: false,
  isOnboardingModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ONBOARDING_MODAL_OPEN: {
      return {
        ...state,
        isOnboardingModalOpen: action.payload,
      };
    }
    case SET_IS_FP_LEGEND_INFO_MODAL_SHOWING: {
      return {
        ...state,
        isLegendInfoModalShowing: action.payload,
      };
    }
    case SET_IS_CHART_OPAQUE: {
      return {
        ...state,
        isChartOpaque: action.payload,
      };
    }
    case SET_IS_GROUP_FILTER_SHOWING: {
      return {
        ...state,
        isGroupFilterShowing: action.payload,
      };
    }
    case FETCH_GROUP_OVERVIEW_START: {
      return {
        ...state,
        isGroupOverviewLoading: true,
      };
    }
    case FETCH_GROUP_OVERVIEW_FULFILLED: {
      return {
        ...state,
        isGroupOverviewLoading: false,
      };
    }
    case FETCH_GROUP_OVERVIEW_REJECTED: {
      return {
        ...state,
        isGroupOverviewLoading: false,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_START: {
      return {
        ...state,
        isAthleteHabitsLoading: true,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        isAthleteHabitsLoading: false,
      };
    }
    case FETCH_ATHLETES_START_HT_DASHBOARD: {
      return {
        ...state,
        isAthleteLoading: true,
      };
    }
    case FETCH_ATHLETES_FULFILLED_HT_DASHBOARD: {
      return {
        ...state,
        isAthleteLoading: false,
      };
    }
    case FETCH_ATHLETES_REJECTED_HT_DASHBOARD: {
      return {
        ...state,
        isAthleteLoading: false,
      };
    }
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_START: {
      return {
        ...state,
        isPerformanceLoading: true,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_REJECTED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_START: {
      return {
        ...state,
        isGroupAnalysisLoading: true,
      };
    }
    case FETCH_GROUP_ANALYSIS_FULFILLED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_REJECTED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case SET_IS_METRIC_INFO_MODAL_SHOWING: {
      return {
        ...state,
        infoModalMetric: action.payload.metric,
        isMetricInfoModalShowing: action.payload.bool,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        isAthleteSelectedPerformance: action.payload.bool,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        isAthleteSelectedGroup: action.payload.bool,
      };
    }
    case SET_HABIT_TRACKING_VIEW: {
      return {
        ...state,
        habitTrackingView: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING: {
      return {
        ...state,
        isMetricFiltersPerformanceShowing: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_GROUP_SHOWING: {
      return {
        ...state,
        isMetricFiltersGroupShowing: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
