/** @jsx jsx */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import optimizeImage from '../../../../shared/utils/imageOptimizer';

const Logo = styled('a')`
  margin-right: 25px;
`;

const MainHeader = styled('header')`
  background-color: ${(props) => props.theme.colorPrimary};
  display: flex;
  position: fixed;
  z-index: 1000;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  box-sizing: border-box;
  justify-content: center;

  svg {
    vertical-align: unset!important;
  }
`;

const IconWrapper = styled('span')`
  display: flex;
  height: 100%;
  
  &.screens {
    margin-top: -6px;
  }
`;

const ScreenIcon = styled('span')`
  background-color: ${(props) => props.theme.colorAccent};
  color: ${(props) => (props.theme.isAccentDarkTheme ? '#FFFFFF' : '#444444')};
  font-size: 8px;
  height: 13px;
  width: 13px;
  border-radius: 10px;
  display: block;
  text-align: center;
`;

const RightIcons = styled('div')`
  display: flex;
  width: 60px;
  justify-content: space-between;
`;

const Header = ({
  closeGlobalModal,
  handleScreenToggle,
  isPrimaryDarkTheme,
  logo,
  openGlobalModal,
  screens,
}) => {
  const isGlobalModalOpen = useSelector((state) => state.weightRoomView.ui.isGlobalModalOpen);

  const props = useSpring({
    to: { transform: isGlobalModalOpen ? 'rotate(360deg)' : 'rotate(0deg)' },
    from: { transform: 'rotate(0deg)' },
  });

  const toggleScreen = () => {
    handleScreenToggle();
  };

  const handleOpenGlobalModal = () => {
    openGlobalModal();
  };

  const handleCloseGlobalModal = () => {
    closeGlobalModal();
  };

  const optimizedLogo = optimizeImage(
    logo,
    {
      resize: {
        width: 70,
        height: 70,
        fit: 'cover',
      },
    },
  );

  return (
    <MainHeader>
      <Logo href='/' id='logo' className='align-self-start'>
        <img
          css={css`
            width: 32px;
            cursor: pointer;
          `}
          src={optimizedLogo}
          alt=''
        />
      </Logo>

      <button
        id='change-screen'
        css={css`
        align-self: center;
        height: 26px;
        border: 0px;
        background: none;
        padding: 0px;
        cursor: pointer;
        & svg {
          align-self: flex-end;
        }
        `}
        onClick={toggleScreen}
        type='button'
      >
        <IconWrapper
          className='screens'
        >
          <IcomoonReact
            iconSet={iconSet}
            color={isPrimaryDarkTheme ? '#FFFFFF' : '#444444'}
            size={20}
            icon='window'
            className='align-self-end'
          />
          <ScreenIcon
            id='screen-icon'
          >
            {screens}
          </ScreenIcon>
        </IconWrapper>
      </button>

      <RightIcons
        className='align-self-end'
      >
        <button
          id='settings'
          css={css`
          align-self: center;
          height: 20px;
          border: 0px;
          background: none;
          padding: 0px;
          cursor: pointer;
          & svg {
            align-self: flex-end;
          }
          `}
          onClick={handleOpenGlobalModal}
          type='button'
        >
          <IconWrapper>
            <animated.div style={props}>
              <IcomoonReact
                iconSet={iconSet}
                color={isPrimaryDarkTheme ? '#FFFFFF' : '#444444'}
                size={20}
                icon='settings'
                className='align-self-end'
              />
            </animated.div>
          </IconWrapper>
        </button>
        <a
          href={`${process.env.WEBAPP_URL}/workouts`}
          id='exit'
          className='d-flex align-self-center'
        >
          <IcomoonReact
            iconSet={iconSet}
            color={isPrimaryDarkTheme ? '#FFFFFF' : '#444444'}
            size={15}
            icon='remove'
          />
        </a>
      </RightIcons>
    </MainHeader>
  );
};

Header.propTypes = {
  closeGlobalModal: PropTypes.func.isRequired,
  handleScreenToggle: PropTypes.func.isRequired,
  isPrimaryDarkTheme: PropTypes.bool.isRequired,
  logo: PropTypes.string.isRequired,
  openGlobalModal: PropTypes.func.isRequired,
  screens: PropTypes.number.isRequired,
};

export default Header;
