/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  useTable, useExpanded, useSortBy, usePagination,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import TableToExcel from '@linways/table-to-excel';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';

const Styles = styled.div`
  text-align: center;

  height: max-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .pagination {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 10px
  }
  .paginationButton {
    padding: 5px;
    display: flex;
    justify-content: center;
    border: 1px solid #D7D7D7;
    border-radius: 4px;
    background: #EBEBEB;
  }

  .scrollAndBoxShadowDiv {
    box-shadow: -95px 0px 16px -95px rgb(48 49 51 / 5%), 95px 0px 16px -95px rgb(48 49 51 / 5%);
    border-bottom-left-radius: 24px;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;

  }

  & .sticky {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;

     tbody {
      position: relative;
      z-index: 0;
    } 

    /** code for seudo element scroll indicator arrow */ 
    thead {
      @keyframes blink {
        0% {
          opacity: 0;
        }
        50% {
          opacity: ${(props) => (props.isScrollable ? 1 : 0)};
        }
        100% {
          opacity: 0;
        }
      }
      ::after {
        content: '';
        border: solid 2em transparent;
        // border-top-color: #EBEBEB;
        // border-top-color: grey;
        border-top-color: white;
        bottom: -1.5em;
        color: grey;
        opacity: ${(props) => (props.isScrollable ? '.7' : '0')};
        height: 0;
        top: 0;
        // top: ${(props) => (props.headerAndSubHeaderHeight ? `${props.headerAndSubHeaderHeight - 20}px` : 0)};
        pointer-events: none;
        position: absolute;
        right: 0;
        text-align: center;
        -webkit-transform: translate(-50%,0);
        -ms-transform: translate(-50%,0);
        transform: translate(-50%,0);
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out;
        width: 0;
       // animation: ${(props) => (props.isScrollable && 'blink 2s infinite')};
        rotate: 90degrees;
        rotate: -90deg;
      }
    }

    tfoot {
      td:first-child {
         position: sticky;
         z-index: 1;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky; 
    }
  }

  .athleteNameRow {
    background-color: black;
    color: white;
      td {
        padding: 0px;
        padding-left: 10px;
        padding-right: 5px;
        height: 27px;
        text-align: center;
      }
  }

  .athleteColumnHeaderCell {
    z-index: 3;
    background: white;
    color: white;
    opacity: 1;
    box-shadow: -18px 49px 6px white;

    border-bottom: none;
    border-right: none;
    border-right: 8px solid #E0E0E0;
  }

  .categoryColumnTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-right: 8px solid #E0E0E0;
    min-width: 270px;
    max-width: 270px;
  }

  .categoryColumnTotalScoresTitleCell {
    background-color: #424242;
    color: white;
    font-size: 16px;
    font-weight: 900;
    border-top-right-radius: 20px;
    min-width: 270px;
    max-width: 270px;
  }
  .title {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #444444;
    text-align: center;
  }

  .disabledTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #BDBDBD;
    text-align: center;
    cursor: auto;
  }

  .rangeAverageTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #444444;
    text-align: center;
    border-right: 8px solid #E0E0E0;
  }

  .disabledRangeAverageTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #EBEBEB;
    color: #BDBDBD;
    text-align: center;
    border-right: 8px solid #E0E0E0;
    cursor: auto;
  }

  .rangeAverageRowSpanCell {
    border: 1px solid #E0E0E0;
    border-bottom: none;
  }

  .rawValueTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D7D7D7;
    color: #444444;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;
    max-width: 90px;
  }

  .disabledRawValueTitle {
    font-family: nunito sans;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: #D7D7D7;
    color: #BDBDBD;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 90px;
    max-width: 90px;
  }

  .athleteNameTitle {
    font-family: nunito sans;
    font-size: 15px;
    font-weight: 900;
    background-color: #EBEBEB;
    text-align: center;
    border-top-left-radius: 24px;
    border-right: 8px solid #E0E0E0;
    color: #444444;
    font-size: 12px;
    min-width: 180px;
    max-width: 180px;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tbody {
      box-shadow: 0px 0px 16px rgb(48 49 51 / 5%);
      border-bottom-right-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    thead {
      tr {
        :nth-child(2){
          height: 67px;
          border-top-left-radius: 24px;
        }
      }
    }

    tr {
      :last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        td {
          border-bottom: none;
          :last-child {
            border-bottom: none;
          }
        }

      }
  
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const Table = ({
  containerWidth,
  columns: userColumns,
  data,
  getCellProps,
}) => {
  const getDefaultExpandedRows = () => {
    const defaultExpandedRows = data.map((element, index) => ({ [index]: true }));
    return defaultExpandedRows;
  };

  const getPageLength = () => {
    const numberOfAthletes = data.length;
    const numberOfDatesForEachAthlete = data[0].responses.length;
    const numberofRowThatOneAthletePlusTheirResponsesRequire = data[0].responses.length + 1;
    const totalRows = (numberOfAthletes * numberOfDatesForEachAthlete) + numberOfAthletes;
    /** if total rows is greater than 50, paginate by returning 5 athletes per page */
    if (totalRows > 50) {
      return (numberOfDatesForEachAthlete * 5) + 5;
    }
    /** if total rows is less than 50 we don't need to paginate */
    return totalRows;
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page, /** Instead of using 'rows', we'll use page, */
    /** which has only the rows for the active page */

    /** The rest of these things are super handy, too ;) */
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: { expanded: getDefaultExpandedRows(), pageIndex: 0, pageSize: getPageLength() },
      // initialState: { expanded: getDefaultExpandedRows(), pageIndex: 0, pageSize: calculatePageLength() },
      getSubRows: (row) => row.responses,
    },
    useSortBy,
    useSticky,
    useExpanded,
    usePagination,
  );

  const showAll = () => {
    const numberOfAthletes = data.length;
    const numberOfDatesForEachAthlete = data[0].responses.length;
    const totalRows = (numberOfAthletes * numberOfDatesForEachAthlete) + numberOfAthletes;
    setPageSize(totalRows);
  };

  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const isModalOpen = useSelector((state) => state.reporting.ui.isModalOpen);

  React.useEffect(() => {
    if (activeModal === 'downloadQuestionnaireReport' && isModalOpen) {
      showAll();
    }
  }, [activeModal, isModalOpen]);

  return (
    <>
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageSize,
              pageCount,
              canNextPage,
              canPreviousPage,
            },
            null,
            2,
          )}
        </code>
      </pre> */}
      <div className='scrollAndBoxShadowDiv'>
        <div id='betaQuestionnaireReportTable' style={{ width: 'fit-content', maxWidth: '1530px' }} className={containerWidth < 485 ? null : 'sticky'}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        {
                          className: column.className,
                        },
                      ])}
                      data-a-wrap
                      data-f-bold
                      data-fill-color={column?.style?.fillColor || 'FF424242'}
                      data-b-a-s='thin'
                      data-b-a-c='#000000'
                      data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                      data-a-h='center'
                      data-a-v='center'
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps([{ className: row.subRows ? 'athleteNameRow' : null }])}>
                    {row.cells.map((cell) => {
                      const subRowLength = data[0]?.responses?.length;
                      if (cell.column.Header === 'Range Average' && (cell.value || cell.value === 0)) {
                        if (cell.getCellProps().key.includes('.0')) {
                          return (
                            <td
                              rowSpan={subRowLength}
                              {...cell.getCellProps([
                                {
                                  className: 'rangeAverageRowSpanCell',
                                  style: cell.column.style,
                                },
                                getCellProps(cell),
                              ])}
                              data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                              data-b-a-s='thin'
                              data-b-a-c='FF000000'
                              data-a-wrap
                            >
                              {cell.render('Cell')}

                            </td>
                          );
                        }
                        return null;
                      }
                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              style: cell.column.style,
                            },
                            getCellProps(cell),
                          ])}
                          data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                          data-f-color={getCellProps(cell)?.style?.fontColor}
                          data-b-a-s='thin'
                          data-b-a-c='FF000000'
                          data-a-wrap
                        >
                          {cell.render('Cell')}

                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      {pageCount > 1 ? (
        <div className='pagination'>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage} className='paginationButton'>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='left-arrow'
              style={{}}
            />
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='left-arrow'
              style={{}}
            />
          </button>
          {' '}
          <button onClick={() => previousPage()} disabled={!canPreviousPage} className='paginationButton'>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='left-arrow'
              style={{}}
            />
          </button>
          {' '}
          <button onClick={() => nextPage()} disabled={!canNextPage} className='paginationButton'>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='right-arrow'
              style={{}}
            />
          </button>
          {' '}
          <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} className='paginationButton'>
            {/* {'>>'} */}
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='right-arrow'
              style={{}}
            />
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='right-arrow'
              style={{}}
            />
          </button>
          {' '}
          <span>
            Page
            {' '}
            <strong>
              {pageIndex + 1}
              {' '}
              of
              {' '}
              {pageOptions.length}
            </strong>
            {' '}
          </span>
          <button
            type='button'
            onClick={() => showAll()}
            className='paginationButton'
            style={{
              fontSize: '13px',
              fontWeight: 700,
              padding: '3px',
              minWidth: '90px',
              maxWidth: '90px',
              alignItems: 'center',
              gap: '7px',
            }}
          >
            <span>Show All </span>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              color='black'
              icon='down-arrow'
              style={{}}
            />
          </button>
        </div>
      ) : (
        <>
          {pageSize > getPageLength() && (
            <button
              type='button'
              onClick={() => {
                setPageSize(getPageLength());
                // dispatch(setShowAllForDownload(false));
              }}
              className='paginationButton'
              style={{
                fontSize: '13px',
                fontWeight: 700,
                padding: '3px',
                minWidth: '90px',
                maxWidth: '100px',
                alignItems: 'center',
                gap: '7px',
                marginTop: '10px',
              }}
            >
              <span>Show Less </span>
              <IcomoonReact
                iconSet={iconSet}
                size={12}
                color='black'
                icon='up-arrow'
              />
            </button>
          )}
        </>
      )}
    </>
  );
};

const MultiDayGridWithSubRows = ({ containerWidth, pageIndex }) => {
  const dataRows = useSelector((state) => state.reporting.data.questionnaireReport?.dataRows);
  const apiUnformatedData = useSelector((state) => state.reporting.data.questionnaireReport?.data);
  const questionnaireReportFormResponses = useSelector((state) => state.reporting.data.questionnaireReportForm);
  const showAverages = questionnaireReportFormResponses?.showRangeAverages;
  const showTotals = questionnaireReportFormResponses?.showTotal;

  const multiDayChartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = multiDayChartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = multiDayChartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = multiDayChartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  // React.useEffect(() => {
  //   if (multiDayChartRef.current) {
  //     setHeaderAndSubHeaderHeight(multiDayChartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
  //     const scrollWidth = multiDayChartRef.current.childNodes[0]?.scrollWidth;
  //     const offsetWidth = multiDayChartRef.current.childNodes[0]?.offsetWidth;
  //     const scrollLeft = multiDayChartRef.current.childNodes[0]?.scrollLeft;
  //     setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  //   }
  // }, []);

  React.useLayoutEffect(() => {
    if (multiDayChartRef.current) {
      setHeaderAndSubHeaderHeight(multiDayChartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
      const scrollWidth = multiDayChartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = multiDayChartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = multiDayChartRef.current.childNodes[0]?.scrollLeft;

      setIsScrollable(!(offsetWidth + scrollLeft <= scrollWidth));
    }
  }, []);

  const handleResize = () => {
    if (multiDayChartRef.current) {
      setHeaderAndSubHeaderHeight(multiDayChartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
      const scrollWidth = multiDayChartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = multiDayChartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = multiDayChartRef.current.childNodes[0]?.scrollLeft;

      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  };

  React.useEffect(() => {
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const exportFile = () => {
    const tb = document.getElementById('reportTable');
    TableToExcel.convert(tb, {
      name: 'Multi-Day Questionnaire Report.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
  };

  const formatColumns = () => {
    const formattedData = apiUnformatedData[0]?.responses[0]?.questions?.map((question, i) => ({
      Header: question.title,
      id: i,
      className: 'categoryColumnTitleCell',
      columns: [
        {
          Header: question.abbr,
          accessor: (row) => ((row.questions || row.questions === 0) ? row.questions[i].value : null),
          Cell: ({ row }) => (
            <TBTooltip
              textToDisplay={(row.original.questions || row.original.questions === 0) ? row.original.questions[i].value : null}
              textToHover={(row.original.questions || row.original.questions === 0) ? row.original.questions[i].value : null}
              textContainerPadding='0px'
              textContainerMaxWidth='90px'
              fontSize={row.original.questions && (typeof row.original?.questions[i]?.value === 'string' ? '12px' : '17px')}
              fontColor={row.original.questions && row.original.questions[i].textColor}
            />
          ),
          id: question.id,
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            fontSize: '17px',
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFD7D7D7',
          },
        },
        {
          Header: 'Range Average',
          accessor: (row) => ((row.questions || row.questions === 0) ? row.questions[i].average : null),
          id: `${question.id} Range Average`,
          className: !showAverages ? 'disabledRangeAverageTitle' : 'rangeAverageTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '8px solid #E0E0E0',
            fontSize: '32px',
            fillColor: 'FFD7D7D7',
          },
          Cell: ({ row }) => (
            <span style={{ color: (row.original.questions && row.original.questions[i].average !== '-') ? row.original.questions[i].averageTextColor : '' }}>
              {(row.original.questions || row.original.questions === 0) ? row.original.questions[i].average : null}
            </span>
          ),
        },
      ],
    }));
    return formattedData;
  };

  const athleteNameAndDateColumn = [
    {
      Header: 'Name',
      id: 'name',
      sticky: containerWidth < 485 ? () => null : 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          /** Build our expander column */
          id: 'expander', /** Make sure it has an ID */
          Header: 'Athlete',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '8px solid #E0E0E0',
            fillColor: 'FFD7D7D7',
          },
          Cell: ({ row }) => (row.canExpand ? (
          /** might want to use this toggle functionality in the future for subrows */
            /** <div
            {...row.getToggleRowExpandedProps({
                 style: {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                 },
              })}
             > */
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <TBTooltip
                textToDisplay={row.isExpanded ? `${row.original.name}` : `${row.original.name}`}
                textToHover={row.isExpanded ? `${row.original.name}` : `${row.original.name}`}
                textContainerPadding='0px'
                textContainerMaxWidth='175px'
                fontSize='12px'
                fontColor='white'
              />
            </div>
          ) : <span style={{ color: '#000000', fontWeight: 700, fontSize: '14px' }}>{moment(row.original.date).format('MMM DD, YYYY')}</span>)
          ,
        },
      ],
    },
  ];

  const totalScoresColumn = [{
    Header: 'Total Scores',
    id: 'totalScores',
    className: 'categoryColumnTotalScoresTitleCell',
    columns: [
      {
        Header: 'Total Score',
        accessor: (row) => ((row.totalScore || row.totalScore === 0) ? row.totalScore : null),
        id: 'totalScoreRawValue',
        className: !showTotals ? 'disabledRawValueTitle' : 'rawValueTitle',
        style: {
          fontWeight: 700,
          fontSize: '17px',
          minWidth: '90px',
          maxWidth: '90px',
          fillColor: 'FFD7D7D7',
        },
      },
      {
        Header: 'Range Average',
        accessor: (row) => ((row.totalScoreAverage || row.totalScoreAverage === 0) ? row.totalScoreAverage : null),
        id: 'totalScoreRangeAverage',
        className: (!showAverages || !showTotals) ? 'disabledTitle' : 'title',
        style: {
          fontSize: '32px',
          fillColor: 'FFD7D7D7',
        },
        Cell: ({ row }) => (
          <span style={{ color: (row.original.totalScoreAverageTextColor && row.original.totalScoreAverage !== '-') ? row.original.totalScoreAverageTextColor : '' }}>
            {(row.original.totalScoreAverage || row.original.totalScoreAverage === 0) ? row.original.totalScoreAverage : null}
          </span>
        ),
      },
    ],
  }];

  const formattedMiddleColumns = formatColumns();

  const columns2 = [...athleteNameAndDateColumn, ...formattedMiddleColumns, ...totalScoresColumn];

  const memoizedColumns = React.useMemo(
    () => columns2,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  const getCellColor = (cellInfo) => {
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter((question) => question.id === columnId);
    const cellBackGroundColor = columnId === 'totalScoreRawValue' ? cellInfo.row.original.totalScoreBackgroundColor
      : currentCellResponseInfoArray[0]?.backgroundColor;
    return cellBackGroundColor;
  };

  const getCellTextColor = (cellInfo) => {
    if (cellInfo.column.Header === 'Range Average' && cellInfo.value === '-' && !showAverages) {
      return '#E0E0E0';
    }
    if (cellInfo.column.Header === 'Total Score' && cellInfo.value === '-' && !showTotals) {
      return '#E0E0E0';
    }
    if (cellInfo.column.id === 'totalScoreRangeAverage' && cellInfo.value === '-' && !showTotals) {
      return '#E0E0E0';
    }
    const columnId = cellInfo?.column?.id;
    const rowQuestionArray = cellInfo?.row?.original.questions;
    const currentCellResponseInfoArray = rowQuestionArray?.filter((question) => question.id === columnId);
    const cellTextColor = columnId === 'totalScoreRawValue' ? cellInfo.row.original.totalScoreTextColor : currentCellResponseInfoArray[0]?.textColor;
    return cellTextColor;
  };

  const getCellFillColor = (cellInfo) => {
    const cellBackGroundColor = getCellColor(cellInfo);
    // Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7'
    return cellBackGroundColor?.toUpperCase().replace('#', 'FF');
  };

  const getFontColor = (cellInfo) => {
    const cellTextColor = getCellTextColor(cellInfo);
    // Convert RGB -> ARGB so '#d7d7d7' becomes 'FFD7D7D7'
    return cellTextColor?.toUpperCase().replace('#', 'FF');
  };

  return (
    <>
      <Styles ref={multiDayChartRef} onScroll={handleScroll} isScrollable={isScrollable} headerAndSubHeaderHeight={headerAndSubHeaderHeight}>
        <Table
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={(cellInfo) => ({
          /** onClick: () => {
            console.log('cellInfo:', cellInfo);
          }, */
            style: {
              backgroundColor: !cellInfo.value && cellInfo.row.subRows ? '#424242' : getCellColor(cellInfo),
              color: !cellInfo.value && cellInfo.row.subRows ? 'white' : getCellTextColor(cellInfo),
              fillColor: !cellInfo.value && cellInfo.row.subRows ? 'FF424242' : getCellFillColor(cellInfo),
              fontColor: !cellInfo.value && cellInfo.row.subRows ? 'FFFFFFFF' : getFontColor(cellInfo),
            },
          })}
        />
      </Styles>
      {/* <Button
        cta='Download Report'
        className='modal-button'
        type='button'
        color='green'
        onClick={exportFile}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      /> */}
    </>
  );
};

export default MultiDayGridWithSubRows;
