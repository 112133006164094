import React from 'react';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import Wrapper from './Wrapper';
import AtomicText from './AtomicText';

/**
 * Text Component for standardized text display
 *
 * @param {Node} children - The text passed down from the instantiated component
 * @param {String} height - Custom height of wrapper
 * @param {Object} styledTextProps - Custom props for Atomic Text
 * @param {Object} styledWrapperProps - Custom props for wrapper
 * @param {Boolean} upperCase - Boolean to determine if text should be upper case
 * @param {String} width - Custom width of wrapper
 * @param {String} tooltip - Tooltip text to display on hover
 * @param {Function} onClick - Click event handler
 * @param {Boolean} truncate - Whether to truncate long text with ellipsis
 * @returns {JSX.Element} - A component for standardized text
 */
const Text = ({
  children,
  height = '',
  styledTextProps = {},
  styledWrapperProps = {},
  upperCase = false,
  width = '',
  tooltip = '',
  onClick = null,
  truncate = false,
}) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip();

  return (
    <Wrapper
      {...styledWrapperProps}
      width={width}
      height={height}
      onClick={onClick}
      style={{ cursor: onClick ? 'pointer' : 'inherit' }}
      ref={setTriggerRef}
    >
      <AtomicText
        {...styledTextProps}
        style={{
          whiteSpace: truncate ? 'nowrap' : 'normal',
          overflow: truncate ? 'hidden' : 'visible',
          textOverflow: truncate ? 'ellipsis' : 'clip',
        }}
      >
        {upperCase ? children.toUpperCase() : children}
      </AtomicText>
      {visible && tooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {tooltip}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </Wrapper>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  styledTextProps: PropTypes.instanceOf(Object),
  styledWrapperProps: PropTypes.instanceOf(Object),
  upperCase: PropTypes.bool,
  width: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
  truncate: PropTypes.bool,
};

Text.defaultProps = {
  height: '',
  styledTextProps: {},
  styledWrapperProps: {},
  upperCase: false,
  width: '',
  tooltip: '',
  onClick: null,
  truncate: false,
};

export default Text;
