/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import WorkoutsListBreak from './WorkoutsListBreak';
import WorkoutsBreakListItem from './WorkoutsBreakListItem';

const WorkoutSectionDiv = styled('div')`
  display: flex;
  flex-direction: column;
  border: 1px solid #34495E;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 15px;
`;

const ItemNumberContainer = styled('div')`
  height: 50px;
  display: flex;
  align-items: center;
`;

const ItemNumber = styled('div')`
  display: flex;
  width: 60px;
  display: flex;
  justify-content: center;
  border: 1px solid grey;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 11px;
  font-family: 'Nunito Sans';
  color: grey;
`;

const ExpandSection = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: hidden;
  max-height: ${(props) => (props.expand ? `${props.heightCalc}px` : '0px')};
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
  margin-left: 15px;
`;

const sectionMapper = (array, itemDictionary) => array.map((workoutItem, idx) => (
  <WorkoutsBreakListItem
    workoutItem={workoutItem}
    index={itemDictionary[workoutItem.assignedId]
      ? itemDictionary[workoutItem.assignedId]
      : itemDictionary[workoutItem.saveDataId]}
    showVerticalLine={idx < array.length - 1}
    isLast={idx === array.length - 1}
  />
));

const WorkoutBreakSection = ({
  section, sectionHeader, itemDictionary,
}) => {
  const [isExpanded, setIsExpanded] = useState(sectionHeader.showSessionBreakItems);
  return (
    <WorkoutSectionDiv>
      <WorkoutsListBreak
        setIsExpanded={setIsExpanded}
        workoutItem={sectionHeader}
        index={itemDictionary[section.assignedId]
          ? itemDictionary[section.assignedId]
          : itemDictionary[section.saveDataId]}
        sectionHeader
        isExpanded={isExpanded}
        section={section}
      />
      <ItemNumberContainer>
        <ItemNumber>
          {section?.length
            ? (section?.length > 1 ? `${section?.length} items` : `${section?.length} item`)
            : '0 items'}
        </ItemNumber>
      </ItemNumberContainer>
      <ExpandSection heightCalc={section.length * 55} expand={isExpanded}>
        {sectionMapper(section, itemDictionary)}
      </ExpandSection>
    </WorkoutSectionDiv>
  );
};

WorkoutBreakSection.propTypes = {
  section: PropTypes.instanceOf(Object).isRequired,
  sectionHeader: PropTypes.instanceOf(Object).isRequired,
  itemDictionary: PropTypes.instanceOf(Object).isRequired,
};

export default WorkoutBreakSection;
