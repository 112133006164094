import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';

import BigModal from './BigModal';
import ModalCreateReseller3 from './ModalCreateReseller3';
import CustomCreateResellerWrapModal2 from './CustomCreateResellerWrapModal2';
import CreateProgramForm from '../programs/CreateProgramForm';
import ModalCreateCoupon from '../coupons/ModalCreateCoupon';
import UpdateProgramForm from '../programs/UpdateProgramForm';
import SubscriberDetailModal from '../subscribers/SubscriberDetailModal';
import ModalConfirmCancel from '../subscribers/ModalConfirmCancel';
import DeleteModal from '../subscribers/DeleteModal';
import TightModal from '../subscribers/TightModal';
import TightModal2 from '../subscribers/TightModal2';
import BackwardsTightModal from '../subscribers/BackwardsTightModal';
import AllCharges from '../subscribers/AllCharges';
import ModalAddToNewProgram from '../subscribers/AddToNewProgramModal';
import ConfirmRefund from '../subscribers/ConfirmRefund';
import ModalArchiveProgram from '../programs/ModalArchiveProgram';
import ModalProgramURL from '../programs/ModalProgramURL';
import Modal from '../../../../../shared/components/Modal/Modal';

import {
  setActiveReduxModal,
} from '../../../ducks/resellerActions';

const ModalHandler = ({
  actionButton,
  currentRow,
  rowProgram,
  setCurrentRow,
  setCurrentSubscription,
  currentSubscription,
  setCurrentChargeItem,
  currentChargeItem,
  currentSubscriber,
  setCurrentSubscriber,
  ProgramToArchive,
  connectionComplete,
}) => {
  const dispatch = useDispatch();
  const activeModal = useSelector((state) => state.reseller.data.activeModal);
  const location = useSelector((state) => state.router.location.pathname);

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  useEffect(() => {
    if (currentRow) {
      setActiveModal('update_program_modal');
    }
  }, [currentRow]);

  useEffect(() => {
    if (currentSubscriber) {
      setActiveModal('subscriber_detail_modal');
    }
  }, [currentSubscriber]);

  useEffect(() => {
    if (connectionComplete) {
      setActiveModal('connection_complete_modal');
    }
  }, [connectionComplete]);

  const MainContainer = styled('main')`
  `;

  const ButtonWrapper = styled('div')`
    margin-top: 50px;
  `;

  /**
   * Modal handler responds to what the activeModal hook is currently set to.
   * if the activeModal hook is set, it will display the appropriate modal
   */
  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'archive_program_modal') {
      returnModal = (
        <DeleteModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalArchiveProgram
            programId={ProgramToArchive.id}
            programName={ProgramToArchive.programName}
          />
        </DeleteModal>
      );
    } else if (activeModal === 'create_program_modal') {
      returnModal = (
        <BigModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <CreateProgramForm
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
          />
        </BigModal>
      );
    } else if (activeModal === 'program_url_modal') {
      returnModal = (
        <Modal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalProgramURL
            program={rowProgram}
          />
        </Modal>
      );
    } else if (activeModal === 'create_coupon_modal') {
      returnModal = (
        <Modal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateCoupon
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            program={currentRow}
          />
        </Modal>
      );
    } else if (activeModal === 'update_program_modal') {
      returnModal = (
        <BigModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          setCurrentRow={setCurrentRow}
          currentRow={currentRow}
          customButton
        >
          <UpdateProgramForm
            onRequestClose={closeModal}
            setCurrentRow={setCurrentRow}
            setActiveModal={setActiveModal}
            program={currentRow}
          />
        </BigModal>
      );
    } else if (activeModal === 'subscriber_detail_modal') {
      returnModal = (
        <TightModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
          customButton
        >
          <SubscriberDetailModal
            onRequestClose={closeModal}
            setCurrentSubscriber={setCurrentSubscriber}
            setActiveModal={setActiveModal}
            currentSubscriber={currentSubscriber}
            setCurrentSubscription={setCurrentSubscription}
          />
        </TightModal>
      );
    } else if (activeModal === 'backwards_subscriber_detail_modal') {
      returnModal = (
        <BackwardsTightModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
          customButton
        >
          <SubscriberDetailModal
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            currentSubscriber={currentSubscriber}
            setCurrentSubscription={setCurrentSubscription}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </BackwardsTightModal>
      );
    } else if (activeModal === 'confirm_cancel_modal') {
      returnModal = (
        <DeleteModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
        >
          <ModalConfirmCancel
            onRequestClose={closeModal}
            currentSubscriber={currentSubscriber}
            currentSubscription={currentSubscription}
            setActiveModal={setActiveModal}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </DeleteModal>
      );
    } else if (activeModal === 'all_charges_modal') {
      returnModal = (
        <TightModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
        >
          <AllCharges
            onRequestClose={closeModal}
            currentSubscriber={currentSubscriber}
            currentSubscription={currentSubscription}
            setCurrentChargeItem={setCurrentChargeItem}
            setActiveModal={setActiveModal}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </TightModal>
      );
    } else if (activeModal === 'backwards_all_charges_modal') {
      returnModal = (
        <BackwardsTightModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
        >
          <AllCharges
            onRequestClose={closeModal}
            currentSubscriber={currentSubscriber}
            currentSubscription={currentSubscription}
            setCurrentChargeItem={setCurrentChargeItem}
            setActiveModal={setActiveModal}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </BackwardsTightModal>
      );
    } else if (activeModal === 'confirm_refund_modal') {
      returnModal = (
        <TightModal2
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
        >
          <ConfirmRefund
            onRequestClose={closeModal}
            currentSubscriber={currentSubscriber}
            currentSubscription={currentSubscription}
            setActiveModal={setActiveModal}
            currentChargeItem={currentChargeItem}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </TightModal2>
      );
    } else if (activeModal === 'connection_complete_modal') {
      returnModal = (
        <CustomCreateResellerWrapModal2
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateReseller3
            onRequestClose={closeModal}
          />
        </CustomCreateResellerWrapModal2>
      );
    } else if (activeModal === 'add_cancelled_subscriber_to_program_modal') {
      returnModal = (
        <TightModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setCurrentSubscriber={setCurrentSubscriber}
          currentSubscriber={currentSubscriber}
        >
          <ModalAddToNewProgram
            onRequestClose={closeModal}
            currentSubscriber={currentSubscriber}
            currentSubscription={currentSubscription}
            setCurrentChargeItem={setCurrentChargeItem}
            setActiveModal={setActiveModal}
            setCurrentSubscriber={setCurrentSubscriber}
          />
        </TightModal>
      );
    } 
    return returnModal;
  };

  return (
    <MainContainer>
      {location === '/reseller/programs'
      || location === '/reseller/coupons' ? (
        <ButtonWrapper>
          {actionButton(setActiveModal)}
        </ButtonWrapper>
        )
        : null}
      {modalHandler()}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
  currentRow: PropTypes.instanceOf(Object),
  currentChargeItem: PropTypes.instanceOf(Object),
  currentSubscriber: PropTypes.instanceOf(Object),
  currentSubscription: PropTypes.instanceOf(Object),
  ProgramToArchive: PropTypes.instanceOf(Object),
  setCurrentRow: PropTypes.func,
  actionButton: PropTypes.func,
  setCurrentSubscription: PropTypes.func,
  setCurrentChargeItem: PropTypes.func,
  setCurrentSubscriber: PropTypes.func,
};

ModalHandler.defaultProps = {
  currentRow: null,
  currentChargeItem: undefined,
  currentSubscriber: undefined,
  currentSubscription: undefined,
  ProgramToArchive: undefined,
  setCurrentSubscription: undefined,
  setCurrentChargeItem: undefined,
  setCurrentSubscriber: undefined,
  setCurrentRow: undefined,
  actionButton: undefined,
};

export default ModalHandler;
