import Logger from 'js-logger';

import { axiosReset } from '../../../shared/utils/setCommonHeaders';
import { SUBMIT_LOGOUT_FULFILLED } from '../../login/ducks/actionTypes';
import { saveAuthTokens, fetchLogin } from '../../login/ducks/loginActions';

import {
  SUBMIT_EMAIL_START,
  SUBMIT_EMAIL_FULFILLED,
  SUBMIT_EMAIL_REJECTED,
  SUBMIT_RESET_START,
  SUBMIT_RESET_FULFILLED,
  SUBMIT_RESET_REJECTED,
  VALIDATE_TOKEN_START,
  VALIDATE_TOKEN_FULFILLED,
  VALIDATE_TOKEN_REJECTED,
} from './actionTypes';

// Submit email to send reset password link
export const submitForgot = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_EMAIL_START });
    axiosReset.post('/public/forgot-password', values)
      .then((response) => {
        const { data } = response.data;
        setTimeout(() => {
          dispatch({
            type: SUBMIT_EMAIL_FULFILLED,
            payload: data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_EMAIL_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Submit reset password details in order to save the new password,
// get oauth token, and login/redirect to dashboard
export const submitReset = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_RESET_START });
    axiosReset.post('/public/reset-password', values)
      .then((response) => {
        const { data } = response;
        setTimeout(() => {
          dispatch({
            type: SUBMIT_RESET_FULFILLED,
            payload: response.data,
          });
        }, 800);
        saveAuthTokens(data.v2_token, data.accessToken, data.refreshToken, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_RESET_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Validate the temporary token before allowing password reset
export const validateToken = (values) => (
  (dispatch) => {
    dispatch({ type: VALIDATE_TOKEN_START });
    axiosReset.post('/public/validate-reset-token', values)
      .then((response) => {
        const { data } = response.data;
        setTimeout(() => {
          dispatch({
            type: VALIDATE_TOKEN_FULFILLED,
            payload: data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: VALIDATE_TOKEN_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);
