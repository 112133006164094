import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import {
  setModalOpen,
} from '../../ducks/leaderboardActions';

const useLeaderboardModal = () => {
  const activeModal = useSelector(state => state.leaderboard.ui.activeModal, shallowEqual);
  const activeBoardId = useSelector(state => state.leaderboard.ui.activeBoardId, shallowEqual);
  const isModalOpen = useSelector(state => state.leaderboard.ui.isModalOpen, shallowEqual);

  const dispatch = useDispatch();

  const handleSetModalOpen = (modalOpen, modal, boardId) => {
    dispatch(setModalOpen(modalOpen, modal, boardId));
  };

  const openModal = (modal, boardId) => {
    handleSetModalOpen(true, modal, boardId);
  };

  return {
    activeBoardId,
    activeModal,
    handleSetModalOpen,
    isModalOpen,
    openModal,
  };
};

export default useLeaderboardModal;
