import React, { useRef, useEffect, useState } from 'react';
import { animated } from 'react-spring';
import styled from '@emotion/styled';
import { useTransition } from 'react-spring';
import moment from 'moment';
import { useTheme, useSpring } from 'emotion-theming';
import { useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Logger from 'js-logger';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  openMessageVideo,
  unscheduleMessage,
} from '../../ducks/conversationsActions';

import { TransitionGlobal } from '../../../../shared/GlobalStyles';

const imageOptimizer = (friendPic) => optimizeImage(
  friendPic,
  {
    resize: {
      width: 60,
      height: 60,
      fit: 'contain',
    },
  },
);

const UserMessage = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: ${props => `url(${props.userProfilePic})`};
    background-size: contain;
    margin-right: 10px;
    margin-top: -20px;
  }
  .message-text {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text};
    background: #f1f1f1;
    padding: 9px;
    border-radius: 20px;
    margin-right: 10px;
    overflow-wrap: anywhere;
    margin-left: 65px;
    max-width: 500px;
    white-space: break-spaces; 
  }
`;

const MessageText = styled('div')`
  display: flex;
  align-items: center;
  color: transparent;
  background: ${props => (props.friend ? '#77A7FF' : '#ff6600')};
  padding: 9px;
  border-radius: 20px;
  margin-right: ${props => (props.friend ? '50px' : '10px')};
  overflow-wrap: anywhere;
  margin-left: ${props => (props.friend ? '10px' : '50px')};

  max-width: 500px;
  white-space: break-spaces;
`;

const UserPic = styled('div')`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-image: ${props => `url(${props.userProfilePic})`};
  background-size: contain;
  ${props => (props.friend ? 'margin-left: 10px;' : 'margin-right: 10px;')}
`;

const FriendMessage = styled('div')`
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`};
    background-size: contain;
    margin-left: 10px;
  }
  .message-text {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.background[5]};
    /* background: #77A7FF; */
    padding: 9px;
    border-radius: 20px;
    margin-left: 10px;
    overflow-wrap: anywhere;
    margin-right: 65px;
    max-width: 500px;
    white-space: break-spaces; 
  }
`;

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.friendMessage ? 'flex-start' : 'flex-end')};
  .latest-time {
    ${(props) => (props.friendMessage ? (
      'margin-left: 10px;'
    ) : (
      'margin-right: 10px;'
    ))}
    margin-top: 5px;
  }
`;

const UserMessageMedia = styled('div')`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* margin-top: ${(props) => {
    if (!props.props.messageBody) {
      return '-40px';
    }
    return '5px';
  }}; */
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const FriendMessageMedia = styled('div')`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const ActionButtonContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: ${TransitionGlobal};

  button {
    border-radius: 50%;
    background-color: #f1f1f1;
    padding: 6px;
    margin-right: 5px;
  }
`;

const MessageBoxWithButtons = styled('div')`
  display: block;
  position: relative;
  
  .action-button-container {
    opacity: 0;
  }

  &:hover {
    .action-button-container {
      opacity: 1;
    }
  }
`;

const ScheduledMessagesContainer = styled('div')`
  display: flex;
  flex-direction: column-reverse;
`;

const MessagesScheduled = ({
  activeConversation,
  currentUser,
  day,
  dayMessages,
  isInitialMessagesFetch,
  messageFormRef,
  scheduledMessagesRef,
  setIsEditing,
  setEditingMessageId,
}) => {
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [editingMessage, setEditingMessage] = useState(false);

  const messagesRef = useRef([]);

  const dispatch = useDispatch();

  const theme = useTheme();

  const handleEditMessage = (messageId, value) => {
    messageFormRef.current.setFieldValue('messageBody', value);
    setIsEditing(true);
    setEditingMessageId(messageId);
  };

  const handleUnscheduleMessage = (messageId) => {
    dispatch(unscheduleMessage(currentUser.accountCode, activeConversation.id, messageId));
  };

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      const pic = optimizeImage(
        currentUser.pic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(pic);
    }
  }, [currentUser]);

  // To add the dynamic height of each message to the animation, we rebuild
  // the conversationMessages array with an index key value and use that to access
  // the correct message ref from the messagesRef array to get the clientHeight.
  // We also use async await next to make sure the clientHeight isn't undefined before
  // we access it. The refs are assigned in the currentMessageMapper.
  const textAnimation = useTransition(
    dayMessages.map((message, index) => ({ ...message, index: index })),
    (item) => (item.key || item.id),
    {
      from: { height: 0, opacity: 0, flexShrink: 0, margin: '10px 0px' },
      enter: ({ index }) => async (next) => (
        await next({
          height: messagesRef.current[index].clientHeight,
          opacity: 1,
          margin: '10px 0px'
        })
      ),
      leave: { height: 0, opacity: 0 },
    },
  );

  return (
    <ScheduledMessagesContainer
      ref={scheduledMessagesRef}
    >
      {textAnimation.map(({ item, key, props }, i) => {
        const newMoment = moment.tz(item.sentAt, currentUser.userTimeZone || currentUser.userSettings.timeZone);
        const returnMoment = newMoment.format('hh:mm A');

        const scheduledMoment = moment.tz(item.scheduledAt, currentUser.userTimeZone || currentUser.userSettings.timeZone).format('MMMM D hh:mm A');

        // messagesByDay[dayMessages].map(({ item, key, props }, index) => {
        return (
          // Each message assigns their ref in the messagesRef array.
          <animated.div style={isInitialMessagesFetch ? null : props}>
            <UserMessage
              userProfilePic={item.userPic}
              ref={el => messagesRef.current[i] = el}
              props={item}
            >
              <MessageContainer>
                <MessageBoxWithButtons>
                  <ActionButtonContainer className='action-button-container'>
                    <Button
                      icon='pencil'
                      iconOnly
                      rounded
                      onClick={() => handleEditMessage(item.id, item.messageBody)}
                    />
                    <Button
                      icon='remove'
                      iconOnly
                      rounded
                      onClick={() => handleUnscheduleMessage(item.id)}
                    />
                  </ActionButtonContainer>
                  {item.messageBody && !editingMessage ? <div className='message-text'>{item.messageBody}</div> : null}
                </MessageBoxWithButtons>
                {item.media ? (
                  <UserMessageMedia
                    props={item}
                    onClick={() => {
                      if (item.media.type === 'IMAGE') {
                        window.open(item.media.mediaUrl, '_blank');
                      }
                    }}
                  >
                    {item.media.type === 'VIDEO' ? (
                      <div
                        onClick={() => {
                          dispatch(openMessageVideo(item.media.mediaUrl));
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '50%',
                          width: '50%',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          background: 'rgba(0, 0, 0, 0.4)',
                        }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={50}
                          icon='altrightarrow'
                        />
                      </div>
                    ) : null}
                  </UserMessageMedia>
                ) : null}
                <Text
                  color={theme.colors.lightText[0]}
                  fontSize={10}
                  className='latest-time'
                >
                  Scheduled to be sent on {item.sentAt !== null
                    ? returnMoment
                    : scheduledMoment}
                </Text>
              </MessageContainer>
              <div className='userpic' />
            </UserMessage>
          </animated.div>
        );
      })}
    </ScheduledMessagesContainer>
  );
};

export default MessagesScheduled;
