import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import IcomoonReact from 'icomoon-react';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import Text from '../../../../shared/components/Text/Text';
import getAllUrlParams from '../../../../shared/utils/getAllUrlParams';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const AthletePickerContainer = styled('div')`
  display: flex;
  background: white;
  z-index: 100;
  position: absolute;
  box-shadow: ${BoxShadow};
	background: white;
  border-radius: 12px;
  top: 70px;
  flex-direction: column;
  color: '#444';
  right: ${(props) => (props.shouldRight ? 0 : 'auto')};
  left: 0;
  .fixed-list::-webkit-scrollbar {
    width: 8px;
  }
  .fixed-list::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 0.5);
    border-radius: 10px;
    transition: background-color 0.3s ease; 
  }
  .fixed-list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0); 
  }
  .fixed-list:hover::-webkit-scrollbar-thumb {
    background-color: rgba(136, 136, 136, 1); 
  }
  .fixed-list {
    scrollbar-width: thin; 
    scrollbar-color: rgba(136, 136, 136, 0.5) transparent;
  }
`;

const AthleteContainer = styled('div')`
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
`;

const SearchContainer = styled('input')`
  display: flex;
  padding: 10px;
  cursor: text;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
  border: none;
`;

const SelectedAthleteContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: #444;
  }
  svg {
    /* visibility: hidden; */
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AthleteAvatar = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '35px')};
    width: ${(props) => (
    props.size ? `${props.size}px` : '35px')};
    border-radius: 50%;
  }
  display: flex;
  align-items: center;
`;

const ListAthleteAvatar = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '25px')};
    width: ${(props) => (
    props.size ? `${props.size}px` : '25px')};
    border-radius: 50%;
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
`;

const AthleteNameContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const AthleteNameBase = styled('div')`
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-direction: column;
  margin-bottom: 35px;
`;

const AthleteName = styled('h3')`
  font-family: 'Nunito Sans';
  color: #444;
  margin-bottom: 0px;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
`;

const ListAthleteName = styled('h3')`
  font-family: 'Nunito Sans';
  color: #444;
  margin-bottom: 0px;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 16px;
  @media screen and (orientation: portrait) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${(props) => (props.small ? '85px;' : 'none')}
  }
`;

const AthleteSelectorContainer = styled('div')`
	display: flex;
  width: 200px;
  position: relative;
	border-radius: 24px;
	background: white;
  /* background-color: ${(props) => (props.isSelectorShowing ? '#214eb1' : 'none')}; */
  button {
    transition: all 0.5s ease;
    margin-top: 10px;
    background-size: 300px;  

    svg {
      path {
        fill: white;
      }

      @media screen and (max-width: 1160px) {
        display: none!important;
      }
    }
    @media screen and (max-width: 1233px) {
      font-size: 10px;
    }
  }
  button:hover {
    background: #214eb1;
    background-position: -100px;  
    color: white;
    opacity: 1!important;

    svg {
      path {
        color: white;
      }
    }
  }
  &:hover button {
    background: #214eb1;
    background-position: -100px;  
    color: white;

    svg {
      path {
        color: white;
      }
    }
  }
  cursor: pointer;
  transition: background-color 0.5s ease;
`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

/**
 * @param {Object} data an object that's passed in from the 'itemData' prop in the list component
 * seen below
 * @param {Integer} index the index of the current data object in the passed array
 * @param {Object} style necessary object that styles each individual container in accordance with
 * the requirements of the list
 * @returns a formatted list of dynamically rendered athlete rows
 */
const AthleteRenderer = ({
  data, index, style,
}) => {
  const {
    list, onOptionClicked, setSearchedList, setIsUserSearching, visibleContainerItem,
  } = data;
  const athlete = list[index];
  const image = optimizeImage(
    athlete.pic,
    {
      resize: {
        width: 70,
        height: 70,
        fit: 'cover',
      },
    },
  );

  return (
    <AthleteContainer
      onClick={() => {
        if (athlete.fake !== true) {
          if (visibleContainerItem) {
            if (visibleContainerItem.userId !== athlete.userId) {
              onOptionClicked(athlete);
              setSearchedList([]);
              setIsUserSearching(false);
            }
          } else {
            onOptionClicked(athlete);
            setSearchedList([]);
            setIsUserSearching(false);
          }
        } else {
          setSearchedList([]);
          setIsUserSearching(false);
        }
      }}
      id={athlete.userId}
      style={style}
    >
      {athlete.pic ? (
        <ListAthleteAvatar>
          <img src={image} alt='' />
        </ListAthleteAvatar>
      ) : null}
      <AthleteNameContainer>
        <ListAthleteName>
          <Text>
            {`${athlete.firstName} ${athlete.lastName}`}
          </Text>
        </ListAthleteName>
      </AthleteNameContainer>
    </AthleteContainer>
  );
};

/**
 *
 * @param {Array} list the list of athletes to be passed into a generated windowed list
 * @param {Function} reduxFunction the function to be called when the user clicks on
 * an athlete row in the windowed list
 * @param {Object} visibleContainerItem the item that is generated in the selector container
 * i.e. the currently selected item
 * @returns
 */
const ReportAthletePicker = ({
  list,
  reduxFunction,
  visibleContainerItem,
  shouldRight = false,
}) => {
  // state that manages whether the dropdown athlete list is showing
  const [isSelectorShowing, setIsSelectorShowing] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [adaptiveDropDownHeight, setAdaptiveDropDownHeight] = useState(300);

  useEffect(() => {
    if (isUserSearching && searchedList.length <= 4) {
      setAdaptiveDropDownHeight(searchedList.length * 50);
    } else if (isUserSearching && searchedList.length === 0) {
      setAdaptiveDropDownHeight(50);
    } else if (list.length <= 4) {
      setAdaptiveDropDownHeight(list.length * 50);
    } else {
      setAdaptiveDropDownHeight(300);
    }
  }, [searchedList, list]);

  useEffect(() => {
    const params = getAllUrlParams(window.location.href, false);
    if (list?.length) {
      const matchedUser = list.find((element) => element.id === parseInt(params.userid, 10));
      if (matchedUser) {
        reduxFunction(matchedUser);
      }
    }
  }, [list]);

  const onOptionClicked = (clickedOption) => {
    reduxFunction(clickedOption);
  };

  /**
   * Determines if a given element or its ancestors has a specific ID.
   *
   * @param {HTMLElement} element - The element to start the search from.
   * @param {string} id - The ID to search for.
   * @returns {boolean} - Returns true if the element or any of its ancestors has the given ID
   */
  const isClickInsideElementWithId = (element, id) => {
    let el = element;
    // Iterate over the element and its parent elements.
    while (el) {
      if (el.id === id) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  };

  /**
   *
   * @param {Object} shownItem the object that appears as the item that's selected
   * from the list
   * @returns a formatted item for the selector container
   */
  const generateSelectedAthlete = (shownItem) => {
    let image;
    if (Object.keys(shownItem).length) {
      image = optimizeImage(
        shownItem.pic,
        {
          resize: {
            width: 70,
            height: 70,
            fit: 'cover',
          },
        },
      );
    }

    return (
      <SelectedAthleteContainer
        onClick={() => setIsSelectorShowing(!isSelectorShowing)}
      >
        {Object.keys(visibleContainerItem).length ? (
          <>
            <AthleteAvatar>
              <img src={image} alt='' />
            </AthleteAvatar>
            <AthleteNameContainer>
              <AthleteName>
                {`${shownItem.firstName} ${shownItem.lastName}`}
              </AthleteName>
            </AthleteNameContainer>
          </>
        ) : (
          <AthleteNameBase>
            <IcomoonReact
              iconSet={iconSet}
              size={40}
              icon='user'
              color='#444'
            />
          </AthleteNameBase>
        )}
      </SelectedAthleteContainer>
    );
  };

  const listMaker = () => {
    if (isUserSearching) {
      if (searchedList.length) {
        return searchedList;
      }
    }
    return list;
  };

  return (
    <>
      <AthleteSelectorContainer
        onClick={(e) => {
          if (!isClickInsideElementWithId(e.target, 'list-searcher')
              && !isClickInsideElementWithId(e.target, 'hidden-workouts-toggle')) {
            setIsSelectorShowing(!isSelectorShowing);
          }
        }}
        isSelectorShowing={isSelectorShowing}
      >
        {generateSelectedAthlete(visibleContainerItem)}
        {isSelectorShowing ? (
          <AthletePickerContainer shouldRight={shouldRight}>
            <SearchContainer
              id='list-searcher'
              placeholder='search'
              autoFocus
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setIsUserSearching(true);
                  const newList = list.filter(
                    (item) => item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
                      || item.lastName.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  if (!newList.length) {
                    setSearchedList([{
                      firstName: 'No Athletes Found',
                      fake: true,
                      lastName: '',
                      pic: '',
                    }]);
                  } else {
                    setSearchedList(newList);
                  }
                } else {
                  setIsUserSearching(false);
                  setSearchedList([]);
                }
              }}
            />
            <List
              height={adaptiveDropDownHeight}
              itemData={{
                list: listMaker(),
                onOptionClicked,
                setSearchedList,
                setIsUserSearching,
                visibleContainerItem,
              }}
              itemCount={!isUserSearching ? list.length : searchedList.length}
              itemSize={55}
              width={300}
              className='fixed-list'
            >
              {AthleteRenderer}
            </List>
          </AthletePickerContainer>
        ) : null}
      </AthleteSelectorContainer>
      {isSelectorShowing
        ? (
          <Overlay onClick={() => {
            setIsSelectorShowing(false);
          }}
          />
        )
        : null}
    </>
  );
};

AthleteRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  visibleContainerItem: PropTypes.instanceOf(Object).isRequired,
};

ReportAthletePicker.defaultProps = {
  shouldRight: false,
};

ReportAthletePicker.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  reduxFunction: PropTypes.func.isRequired,
  visibleContainerItem: PropTypes.instanceOf(Object).isRequired,
  shouldRight: PropTypes.bool,
};

export default ReportAthletePicker;
