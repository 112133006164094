const habitPresets = [
  {
    id: 1,
    name: '30 minutes of moderate physical activity',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 3,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 2,
    name: 'One serving of fruits or vegetables at every meal',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 3,
    name: 'Minimum 8 glasses of water per day',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 4,
    name: '8 hours of sleep',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 4,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 5,
    name: 'Make your bed',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 2,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 6,
    name: '30 minutes reading or learning a new skill',
    description: 'reading, learning a new language, learning an instrument etc.',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 5,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 7,
    name: 'Minimim 30 minutes of leasure time',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 4,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 8,
    name: 'Practice a sport related skill',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 3,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 9,
    name: 'Hit dietary protein intake goal',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 10,
    name: 'Hit dietary carbohydrate intake goal',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 11,
    name: 'Hit dietary fat intake goal',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 12,
    name: 'Complete homework',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 2, /** Enum: 2 - weekly, 1 - daily */
    frequency: 5, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 2,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 13,
    name: 'Attend all classes',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 2, /** Enum: 2 - weekly, 1 - daily */
    frequency: 5, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 2,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 15,
    name: 'Eat lunch',
    description: '',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 1,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },
  {
    id: 16,
    name: 'Uninterupted sleep',
    description: 'try to keep your room cool for optimal sleeping conditions, try not to drink too much water close to bed time to avoid getting up to use the bathroom in the middle of the night, stay off your phone and limit screen time before bed.',
    type: 1, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
    cadence: 1, /** Enum: 2 - weekly, 1 - daily */
    frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
    categoryId: 4,
    groupIds: [], /** array[integer] */
    userIds: [], /** array[integer] */
    creationType: 'template',
  },

];

export default habitPresets;
