/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import RecordWorkoutsImageModal from './modals/RecordWorkoutsImageModal';
import RecordQuickImageModal from './modals/RecordQuickImageModal';

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const RecordWorkoutsImageModalContainer = () => {
  const captureImageModal = useSelector((state) => state.workouts.data.captureImageModal);
  const quickImageWorkoutsJournalModal = useSelector(
    (state) => state.workouts.data.quickImageWorkoutsJournalModal,
  );
  if (captureImageModal) {
    return <RecordWorkoutsImageModal />;
  } if (quickImageWorkoutsJournalModal) {
    return <RecordQuickImageModal />;
  }
  return null;
};

RecordWorkoutsImageModalContainer.propTypes = { };

export default RecordWorkoutsImageModalContainer;
