import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import ButtonRound from './ButtonRound';

const CompletionContainer = styled('div')`
  padding: 0px;
`;

const CompletionCopy = styled('p')`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.textColor};
  cursor: pointer;
  white-space: pre-line;  
`;

const CompletionEntry = ({
  handleInfoClick,
  info,
  lifts,
  setFieldValue,
  subHeader,
  values,
}) => {
  const handleInformationClick = () => {
    handleInfoClick('info');
  };

  return (
    <CompletionContainer>
      {subHeader && (
        <CompletionCopy
          onClick={lifts ? null : handleInformationClick}
        >
          {lifts ? '' : `${subHeader}`}
        </CompletionCopy>
      )}
      <ButtonRound
        cta='Complete'
        complete={values.body.complete === '1'}
        icon='checkmark'
        onClick={(e) => setFieldValue('body.complete', values.body.complete === '1' ? '0' : '1', e)}
        type='submit'
      />
    </CompletionContainer>
  );
};

CompletionEntry.propTypes = {
  handleInfoClick: PropTypes.func,
  info: PropTypes.string,
  lifts: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

CompletionEntry.defaultProps = {
  handleInfoClick: null,
  info: '',
  lifts: false,
};

export default CompletionEntry;
