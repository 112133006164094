import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BorderRadius, ColorLightestGray } from '../../../../shared/GlobalStyles';

const TableWrapper = styled('div')`
  border: 1px solid ${ColorLightestGray};
  border-radius: ${BorderRadius};
  display: inline-flex;
  width: 100%;
  
  table {
    border-spacing: 0;
    border-collapse: collapse;
    border-style: hidden;
    width: 100%;
    color: ${props => props.theme.textColor};
  }

  th, td {
    border-right: 1px solid ${ColorLightestGray};
    text-align: center;
    padding: 5px;
  }

  th { 
    border-bottom: 1px solid ${ColorLightestGray};
  }

  tfoot {
    border-top: 1px solid ${ColorLightestGray};
    td {
      font-size: 13px;
    }
  }
`;

const Table = ({
  data,
  isNoteAdded,
  isOptedOut,
  noteDetail,
  optOutReason,
}) => {
  return (
    <TableWrapper className='table-wrapper'>
      <table>
        <thead>
          <tr>
            {data.map((set, i) => (
              <th key={i}>
                {i + 1}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {data.map((set, i) => (
              <td key={i}>
                {set ? set : 'N/A'}
              </td>
            ))}
          </tr>
        </tbody>
        {(isNoteAdded && !isOptedOut) ? (
          <tfoot>
            <tr>
              <td colSpan={data.length}>
                {`Note: ${noteDetail}`}
              </td>
            </tr>
          </tfoot>
        ) : ('')}
        {isOptedOut ? (
          <tfoot>
            <tr>
              <td colSpan={data.length}>
                {`Opted Out: ${optOutReason} - ${noteDetail}`}
              </td>
            </tr>
          </tfoot>
        ) : ('')}
      </table>
    </TableWrapper>
  );
};

export default Table;
