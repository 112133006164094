// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Spinner from '../../../shared/components/Spinner/Spinner';
import useConversations from '../components/hooks/useConversations';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .modal-button {
    display: flex;
    align-items: center;
  }
  .message-number {
    text-align: center;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
  }
  .note {
    margin-top: 30px;
    text-align: center;
    font-size: 11px;
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const CreateProgramSlide3 = ({
  formProps,
}) => {
  const {
    handleUploadMedia,
  } = useConversations();

  const conversationMessageNumber = useSelector(
    (state) => state.conversations.data.conversationMessageNumber,
  );
  const isIndividualConversationsCreating = useSelector(
    (state) => state.conversations.ui.isIndividualConversationsCreating,
  );

  const dispatch = useDispatch();

  console.log(conversationMessageNumber);

  return (
    <ModalWrapper>
      {isIndividualConversationsCreating ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : (
        <>
          <div className='message-number'>
            {`${conversationMessageNumber.messagesSent} individual Conversation Messages Sent`}
          </div>
          <div className='note'>
            **NOTE: We will not be making any updates to the conversations
            in the sidebar, even if they have existing ones, to avoid a
            large amount of updates at once. If a user replies to the message,
            it will then update the conversation as normal.
          </div>
        </>
      )}
    </ModalWrapper>
  );
};

CreateProgramSlide3.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default CreateProgramSlide3;
