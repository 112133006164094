export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';

export const FETCH_PROGRAM_START = 'FETCH_PROGRAM_START';
export const FETCH_PROGRAM_FULFILLED = 'FETCH_PROGRAM_FULFILLED';
export const FETCH_PROGRAM_REJECTED = 'FETCH_PROGRAM_REJECTED';

export const FETCH_WORKOUT_PREVIEW_START = 'FETCH_WORKOUT_PREVIEW_START';
export const FETCH_WORKOUT_PREVIEW_FULFILLED = 'FETCH_WORKOUT_PREVIEW_FULFILLED';
export const FETCH_WORKOUT_PREVIEW_REJECTED = 'FETCH_WORKOUT_PREVIEW_REJECTED';

export const FETCH_MORE_PROGRAMS_START = 'FETCH_MORE_PROGRAMS_START';
export const FETCH_MORE_PROGRAMS_FULFILLED = 'FETCH_MORE_PROGRAMS_FULFILLED';
export const FETCH_MORE_PROGRAMS_REJECTED = 'FETCH_MORE_PROGRAMS_REJECTED';

export const FETCH_STRIPE_DETAILS_START = 'FETCH_STRIPE_DETAILS_START';
export const FETCH_STRIPE_DETAILS_FULFILLED = 'FETCH_STRIPE_DETAILS_FULFILLED';
export const FETCH_STRIPE_DETAILS_REJECTED = 'FETCH_STRIPE_DETAILS_REJECTED';

export const SUBMIT_PAYMENT_START = 'SUBMIT_PAYMENT_START';
export const SUBMIT_PAYMENT_FULFILLED = 'SUBMIT_PAYMENT_FULFILLED';
export const SUBMIT_PAYMENT_REJECTED = 'SUBMIT_PAYMENT_REJECTED';

export const CLEAR_PAYMENT_DATA = 'CLEAR_PAYMENT_DATA';

export const FETCH_RESELLER_START = 'FETCH_RESELLER_START';
export const FETCH_RESELLER_FULFILLED = 'FETCH_RESELLER_FULFILLED';
export const FETCH_RESELLER_REJECTED = 'FETCH_RESELLER_REJECTED';

export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_FULFILLED = 'FETCH_PROGRAMS_FULFILLED';
export const FETCH_PROGRAMS_REJECTED = 'FETCH_PROGRAMS_REJECTED';
export const FETCH_PUBLIC_COUPON_START = 'FETCH_PUBLIC_COUPON_START';
export const FETCH_PUBLIC_COUPON_FULFILLED = 'FETCH_PUBLIC_COUPON_FULFILLED';
export const FETCH_PUBLIC_COUPON_REJECTED = 'FETCH_PUBLIC_COUPON_REJECTED';

export const CLEAR_COUPON_ERROR = 'CLEAR_COUPON_ERROR';
export const SET_COUPON_ERROR = 'SET_COUPON_ERROR';
export const CLEAR_COUPON = 'CLEAR_COUPON';

export const SET_PAYMENT_LOADING = 'SET_PAYMENT_LOADING';
