/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import Logger from 'js-logger';
import {
  Route,
  Link,
  Switch,
  useRouteMatch,
  useParams,
  Redirect,
} from 'react-router-dom';
import useLogin from './components/hooks/useLogin';
import CustomLoginWrapper from './components/presentational/customLogin/customLoginWrapper';

import LoginImage from '../../shared/images/tb_sign_in_3.jpg';
import useScript from '../../shared/hooks/useScript';

const LoginPageContainer = styled('div')`
  display: flex;
  background: ${(props) => props.theme.colors.darkBlue};
  height: 100%!important;
  width: 100%;
  align-items: center;
  justify-content: center;
  a {
    align-self: center;
  }
  .login-image-container {
    /* background-image: url(${LoginImage}); */
    background-image: ${(props) => props.theme.colors.gradientOverlay}, url('https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_1.jpg');
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    /* left: -160%;
    margin-top: -15%; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const PortalWrapper = ({ path }) => (
  <>
    <Switch>
      <Route path={`${path}/:portalSlug`}>
        <CustomLoginWrapper />
      </Route>
      <Route path={`${path}/*`}>
        <Redirect to='/login' />
      </Route>
    </Switch>
  </>
);

export default PortalWrapper;
