import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_FULFILLED,
  FETCH_NOTIFICATIONS_REJECTED,
  FETCH_NOTIFICATION_ASSET_START,
  FETCH_NOTIFICATION_ASSET_FULFILLED,
  FETCH_NOTIFICATION_ASSET_REJECTED,
  SET_ACTIVE_NOTIFICATION,
  FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED,
  FETCH_NOTIFICATIONS_NEXT_PAGE_START,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED,
  CLEAR_ACTIVE_NOTIFICATION_FILTERS,
  SET_IS_FILTERS_CHANGING,
} from './actionTypes';

const initialState = {
  isNotificationsLoading: false,
  isNotificationAssetLoading: false,
  isMarkAsReadLoading: false,
  isMarkAsDeletedLoading: false,
  isNextPageLoading: false,
  isFiltersChanging: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_NEXT_PAGE_START: {
      return {
        ...state,
        isNextPageLoading: true,
      };
    }
    case FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED: {
      return {
        ...state,
        isNextPageLoading: false,
      };
    }
    case FETCH_NOTIFICATIONS_START: {
      return {
        ...state,
        isNotificationsLoading: true,
        isFiltersChanging: true,
      };
    }
    case FETCH_NOTIFICATIONS_FULFILLED: {
      return {
        ...state,
        isNotificationsLoading: false,
        isFiltersChanging: false,
      };
    }
    case FETCH_NOTIFICATIONS_REJECTED: {
      return {
        ...state,
        isNotificationsLoading: false,
        isFiltersChanging: false,
      };
    }
    case FETCH_NOTIFICATION_ASSET_START: {
      return {
        ...state,
        isNotificationAssetLoading: true,
      };
    }
    case FETCH_NOTIFICATION_ASSET_REJECTED: {
      return {
        ...state,
        isNotificationAssetLoading: false,
      };
    }
    case FETCH_NOTIFICATION_ASSET_FULFILLED: {
      return {
        ...state,
        isNotificationAssetLoading: false,
      };
    }
    case SET_ACTIVE_NOTIFICATION: {
      return {
        ...state,
        isNotificationAssetLoading: true,
      };
    }
    case FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START: {
      return {
        ...state,
        isNotificationsLoading: true,
      };
    }
    case FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED: {
      return {
        ...state,
        isNotificationsLoading: false,
      };
    }
    case FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED: {
      return {
        ...state,
        isNotificationsLoading: false,
      };
    }
    case SET_IS_FILTERS_CHANGING: {
      return {
        ...state,
        isFiltersChanging: action.payload,
      };
    }
    case CLEAR_ACTIVE_NOTIFICATION_FILTERS: {
      return {
        ...state,
        isFiltersChanging: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
