/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDropDown from '../../../force-plate-dashboard/components/ForcePlateDropDown';
import ForcePlateDatePicker from '../../../force-plate-dashboard/components/presentational/ForcePlateDatePicker';
import SCOverviewChart from './SCOverviewChart';
import ExerciseMetricsContainers from './ExerciseMetricsContainer';
import ExerciseFilter from './ExerciseFilter';
import {
  setGroup,
  setSelectedAthletePerformance,
  setSelectedAthleteGroup,
  setStartDateCal,
  setStartDateData,
  setEndDateCal,
  setEndDateData,
  setIsLegendInfoModalShowing,
  fetchSCDashData,
} from '../../ducks/sportCoachDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const LegendAndIcon = styled('div')`
  display: flex;
  width: 230px;
  margin-left: 17px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  align-items: center;

  button {
    background: transparent!important;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const LegendTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
`;

const OverviewComponent = () => {
  const athleteList = useSelector((state) => state.sportCoachDashboard.data.athleteList);
  const cardData = useSelector((state) => state.sportCoachDashboard.data.cardData);
  const chartData = useSelector((state) => state.sportCoachDashboard.data.chartData);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dateType = useSelector((state) => state.sportCoachDashboard.data.dateType);
  const endDateCal = useSelector((state) => state.sportCoachDashboard.data.endDateCal);
  const endDateData = useSelector((state) => state.sportCoachDashboard.data.endDateData);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const groups = useSelector((state) => state.sportCoachDashboard.data.groups);
  const isSCDashDataLoading = useSelector(
    (state) => state.sportCoachDashboard.ui.isSCDashDataLoading,
  );
  const maxType = useSelector((state) => state.sportCoachDashboard.data.maxType);
  const selectedAthletePerformance = useSelector(
    (state) => state.sportCoachDashboard.data.selectedAthletePerformance,
  );
  const startDateCal = useSelector((state) => state.sportCoachDashboard.data.startDateCal);
  const startDateData = useSelector((state) => state.sportCoachDashboard.data.startDateData);

  const dispatch = useDispatch();

  const hasCardData = cardData.length > 0;
  const hasChartData = chartData.length > 0;

  const athleteDropdownList = hasChartData && [...athleteList];
  athleteDropdownList.length > 0 && athleteDropdownList.unshift({ fullName: 'View Group', userId: null });

  const exerIds = exercises.length > 0 && exercises.map((item) => item.id);

  const setStartDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Date Picker', 'Start date selected');
    batch(() => {
      dispatch(setStartDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setStartDateCal(moment(date)));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          moment(date).format('YYYY-MM-DD'),
          endDateData,
          group?.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const setEndDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Date Picker', 'End date selected');
    batch(() => {
      dispatch(setEndDateData(moment(date).format('YYYY-MM-DD')));
      dispatch(setEndDateCal(moment(date)));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          startDateData,
          moment(date).format('YYYY-MM-DD'),
          group?.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const setSelectedGroup = (option) => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Group Picker', 'Group selected');
    batch(() => {
      dispatch(setGroup(option));
      dispatch(setSelectedAthletePerformance(null, false));
      dispatch(setSelectedAthleteGroup(null, false));
      dispatch(
        fetchSCDashData(
          currentUser.accountCode,
          startDateData,
          endDateData,
          option.id,
          exerIds,
          dateType,
          maxType,
        ),
      );
    });
  };

  const setAthlete = (option) => {
    if (option.fullName === 'View Group') {
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Athlete Picker', 'Athlete selection reset');
      dispatch(setSelectedAthletePerformance(null, false));
    } else {
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Athlete Picker', 'Athlete selected');
      dispatch(setSelectedAthletePerformance(option, true));
    }
  };

  const handleLegendInfoClick = () => {
    // Amplitude tracking
    dashboardTracker('Sport Coach Dashboard', 'Performance Overview - Key Performance Exercise Legend', 'Legend info button clicked');
    dispatch(setIsLegendInfoModalShowing(true));
  };

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              list={groups}
              label='name'
              selectItemFunction={setSelectedGroup}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {hasChartData && (
            <ForcePlateDropDown
              list={athleteDropdownList}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthletePerformance || {}}
            />
            )}
            <ExerciseFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <SCOverviewChart />
        {!hasChartData && !isSCDashDataLoading && (
        <NoDataModal>
          <ModalTitle>
            {!group && !hasChartData ? 'Select a Group' : 'No Data Available'}
          </ModalTitle>
          <ModalMessage>
            {!group && !hasChartData
              ? 'Select a group using the dropdown above to get started'
              : 'There is no data available, please adjust your selection above'}
          </ModalMessage>
        </NoDataModal>
        )}
      </DashboardContainer>
      <LegendAndIcon isBlurred={!hasCardData}>
        <LegendTitle>
          Key Performance Exercises
        </LegendTitle>
        <Button
          iconOnly
          icon='info'
          customColor='#00000000'
          noBorder
          onClick={handleLegendInfoClick}
          rounded
          disabled={!hasCardData}
        />
      </LegendAndIcon>
      <ExerciseMetricsContainers />
    </>
  );
};

export default OverviewComponent;
