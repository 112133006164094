// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';

import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import Button from '../../../../../shared/components/Button/Button';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import useReseller from '../../hooks/useReseller';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
    background-color: #0dcc8a;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationWrapperFolder = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;
  .thanks-text {
    text-align: center;
  }
  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .link-stripe-button {
    margin-top: 15px;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  .divider-line-vertical {
    height: 100%;
    width: 4px;
    background-color: rgb(232,232,232);
  }
`;

const ExistingAccountButtonWrapper = styled('div')`
  margin-right: 20px;
  text-align: center;
  flex-basis: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

const NewAccountButtonWrapper = styled('div')`
  margin-left: 20px;
  text-align: center;
  flex-basis: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
`;

// const rootBaseUrl = () => {
//   if (process.env.ENVIRONMENT === 'PRODUCTION') {
//     return 'https://app-v3.teambuildr.com';
//   } if (process.env.ENVIRONMENT === 'STAGING') {
//     return 'https://staging.app-v3.teambuildr.com';
//   }
//   return 'http://localhost:8080';
// };

const ModalCreateReseller2 = ({
  onRequestClose,
  currentUser,
  token,
}) => {
  const {
    handleRequestStripeConnectLink,
  } = useReseller();
  const theme = useTheme();

  const stripeAccountConnectLink = useSelector(
    (state) => state.reseller.data.stripeAccountConnectLink,
  );

  useEffect(() => {
    if (stripeAccountConnectLink) {
      window.open(stripeAccountConnectLink.url, '_self');
    }
  }, [stripeAccountConnectLink]);

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <NavigationWrapperFolder>
          <Text
            className='back-arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='remove'
            />
          </Text>
        </NavigationWrapperFolder>
        <OuterFolderFormWrapper>
          <FolderTextWrapper>
            <Text className='calendar-icon-text'>Online Payments Account</Text>
          </FolderTextWrapper>
          <div className='divider-line' />
          <InnerFolderFormWrapper>
            <Text className='thanks-text'>Thanks for submitting that information.  Now, all you need to do is link your account to Stripe and you&apos;ll be all set:</Text>
          </InnerFolderFormWrapper>
          <ButtonWrapper>
            <ExistingAccountButtonWrapper>
              <Text fontSize={18} fontWeight={600}>Have an existing Stripe account?</Text>
              <Button
                customColor={theme.colors.green}
                primary
                noBorder
                className='link-stripe-button'
                onClick={() => {
                  handleRequestStripeConnectLink(currentUser.accountCode, 'standard');
                }}
                cta='Connect Stripe Account'
              />
            </ExistingAccountButtonWrapper>
            <div className='divider-line-vertical' />
            <NewAccountButtonWrapper>
              <Text fontSize={18} fontWeight={600}>Need to create a Stripe account?</Text>
              <Button
                customColor={theme.colors.green}
                primary
                noBorder
                className='link-stripe-button'
                onClick={() => {
                  handleRequestStripeConnectLink(currentUser.accountCode);
                }}
                cta='Create Stripe Account'
              />
            </NewAccountButtonWrapper>
          </ButtonWrapper>

        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

ModalCreateReseller2.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalCreateReseller2;
