import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { createCellValue } from '../../ducks/evaluationsActions';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const RowValue = styled('div')`
  display: flex;
  justify-content: center;
  min-width: ${(props) => (props.doubleWidth ? '440px' : '220px')};
  height: 48px;
  box-shadow: ${BoxShadow};
  flex: ${(props) => props.flexValue};
  margin-left: 10px;
  border-radius: 12px;
  padding: 8px;
  background: #FFFFFF;
  max-width: ${(props) => (props.doubleWidth ? '440px' : '220px')};

  @media (max-width: 768px) {
    max-width: ${(props) => (props.doubleWidth ? '300px' : '150px')};
    min-width: ${(props) => (props.doubleWidth ? '300px' : '150px')};
  }
`;

const SingleInputLeftRightBox = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const LeftRightInputBox = styled('input')`
  width: 100%;
  background: #FFFFFF;
  padding-left: 10px;
  border: none;
`;

const InputAndUnitBox = styled('div')`
  display: flex;
  width: 48%;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  font-size: 12px;
`;

const UnitBox = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-family: 'Nunito Sans';
  font-size: 12px;
  color: #444444;
`;

const SingleInputLeftRightComponent = ({
  item,
  valuesArray,
  evaluation,
  athleteIdx,
  inputIdx,
  doubleWidth,
}) => {
  const [rightInput, setRightInput] = useState('');
  const [leftInput, setLeftInput] = useState('');

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    setRightInput(valuesArray[0][1].value);
    setLeftInput(valuesArray[0][0].value);
  }, [valuesArray]);

  return (
    <RowValue flexValue={2} doubleWidth={doubleWidth}>
      <SingleInputLeftRightBox>
        <InputAndUnitBox>
          <LeftRightInputBox
            value={leftInput}
            onChange={(e) => setLeftInput(e.target.value)}
            onBlur={() => {
              const inputValue = valuesArray[0][0];
              let submissionObject;
              if (inputValue.id || inputValue.id === 0) {
                submissionObject = {
                  id: inputValue.id,
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: leftInput.toString(),
                  resultNumber: 1,
                };
              } else {
                submissionObject = {
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: leftInput.toString(),
                  resultNumber: 1,
                };
              }
              dispatch(createCellValue(
                currentUser.accountCode,
                submissionObject,
                evaluation.id,
                athleteIdx,
                inputIdx,
                0,
              ));
            }}
            placeholder={`${item.unit1.toLowerCase()} (left)`}
          />
          {leftInput ? (
            <UnitBox>
              {item.unit1.toLowerCase()}
            </UnitBox>
          ) : null}
        </InputAndUnitBox>
        <InputAndUnitBox>
          <LeftRightInputBox
            value={rightInput}
            placeholder={`${item.unit1.toLowerCase()} (right)`}
            onChange={(e) => setRightInput(e.target.value)}
            onBlur={() => {
              const inputValue = valuesArray[0][1];
              let submissionObject;
              if (inputValue.id || inputValue.id === 0) {
                submissionObject = {
                  id: inputValue.id,
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: rightInput?.toString(),
                  resultNumber: 2,
                };
              } else {
                submissionObject = {
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: rightInput?.toString(),
                  resultNumber: 2,
                };
              }
              dispatch(createCellValue(
                currentUser.accountCode,
                submissionObject,
                evaluation.id,
                athleteIdx,
                inputIdx,
                1,
              ));
            }}
          />
          {rightInput ? (
            <UnitBox>
              {item.unit1.toLowerCase()}
            </UnitBox>
          ) : null}
        </InputAndUnitBox>
      </SingleInputLeftRightBox>
    </RowValue>
  );
};

SingleInputLeftRightComponent.defaultProps = {

};

SingleInputLeftRightComponent.propTypes = {
  valuesArray: PropTypes.instanceOf(Array).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  evaluation: PropTypes.instanceOf(Object).isRequired,
  athleteIdx: PropTypes.number.isRequired,
  inputIdx: PropTypes.number.isRequired,
  doubleWidth: PropTypes.bool.isRequired,
};

export default SingleInputLeftRightComponent;
