/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import InstantPaymentFormModal from './InstantPaymentFormModal';
import { setIsInstantPaymentModalShowing } from '../../ducks/sportsScienceActions';

const InstantPaymentModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const InstantPaymentModalContainer = () => {
  const dispatch = useDispatch();
  const isInstantPaymentModalShowing = useSelector(
    (state) => state.sportsScience.data.isInstantPaymentModalShowing,
  );
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <>
      <InstantPaymentFormModal activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
      <InstantPaymentModalContainerOverlay
        isActive={isInstantPaymentModalShowing}
        onClick={
          () => {
            setActiveSlide(0);
            dispatch(setIsInstantPaymentModalShowing(false));
          }
        }
      />
    </>
  );
};

InstantPaymentModalContainer.propTypes = { };

export default InstantPaymentModalContainer;
