/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';

const AthleteAveragesCard = () => {
  const theme = useTheme();
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const athleteAveragesObject = useSelector((state) => state.wearablesDashboard.data.athleteAverages);
  const isAthleteAveragesLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteAveragesLoading);

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    gap: 30px;
    width: 218px;
    height: 392px;
  
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
  
  `;
  const LoadingShimmer = styled('div')`
    width: 218px;
    height: 392px;
    border-radius: 24px;
    
  `;

  const SmallDataCard = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 132px;
    height: 70px;

    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    border-radius: 12px;
    background-color: ${theme.colorPrimary};
    color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')};
  `;

  return (
    <>
      {isAthleteAveragesLoading ? (
        <Shimmer>
          <LoadingShimmer />
        </Shimmer>
      ) : (
        <Wrapper>
          <Text fontSize={15} fontWeight={700}>
            {activeAthlete?.name ? 'Group' : 'Athlete'}
            {' '}
            Averages
          </Text>
          <SmallDataCard>
            <Text fontSize={14} fontWeight={700} color='white'> Daily Average </Text>
            <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.dailyScore || 0}</Text>
          </SmallDataCard>
          <SmallDataCard>
            <Text fontSize={14} fontWeight={700} color='white'> 7 Day Average </Text>
            <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.sevenDayAverage || 0}</Text>
          </SmallDataCard>
          <SmallDataCard>
            <Text fontSize={14} fontWeight={700} color='white'> 28 Day Average </Text>
            <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.twentyEightDayAverage || 0}</Text>
          </SmallDataCard>

        </Wrapper>
      )}
    </>
  );
};

const AthleteAveragesCardHortizontal = ({ containerWidth }) => {
  const theme = useTheme();
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const athleteAveragesObject = useSelector((state) => state.wearablesDashboard.data.athleteAverages);
  const isAthleteAveragesLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteAveragesLoading);

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    height: 218px;
    width: ${(props) => (props.containerWidth <= 767 ? '100%' : '97%')};
  
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
  
  `;
  const LoadingShimmer = styled('div')`
    width: ${(props) => (props.containerWidth <= 767 ? '100%' : '97%')};
    height: 218px;
    border-radius: 24px;
    
  `;

  const SmallDataCard = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    border-radius: 12px;
    background-color: ${theme.colors.orange};

    flex: 1;
    flex-basis: 0;
    padding: 14px;
    text-align: center;
  `;

  const ColWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const Row = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.containerWidth <= 550 ? '12px' : '20px')};
  margin-top: 20px;
`;

  return (
    <>
      {isAthleteAveragesLoading ? (
        <Shimmer>
          <LoadingShimmer containerWidth={containerWidth} />
        </Shimmer>
      ) : (
        <Wrapper containerWidth={containerWidth}>
          <ColWrapper>
            <Text fontSize={15} fontWeight={700}>
              {activeAthlete?.name ? 'Group' : 'Athlete'}
              {' '}
              Averages
            </Text>
            <Row containerWidth={containerWidth}>
              <SmallDataCard>
                <Text fontSize={14} fontWeight={700} color='white'> Daily Average </Text>
                <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.dailyScore || 0}</Text>
              </SmallDataCard>
              <SmallDataCard>
                <Text fontSize={14} fontWeight={700} color='white'> 7 Day Average </Text>
                <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.sevenDayAverage || 0}</Text>
              </SmallDataCard>
              <SmallDataCard>
                <Text fontSize={14} fontWeight={700} color='white'> 28 Day Average </Text>
                <Text fontSize={20} fontWeight={700} color='white'>{athleteAveragesObject?.twentyEightDayAverage || 0}</Text>
              </SmallDataCard>
            </Row>
          </ColWrapper>

        </Wrapper>
      )}
    </>
  );
};

export { AthleteAveragesCard, AthleteAveragesCardHortizontal };
