/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { setDeleteDashboardModal, deleteDashboard } from '../../ducks/sportCoachDashboardActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  width: 60vw;
  max-width: 600px;
  position: absolute; 
  left: 50%;
  top: 50%;
  right: 50%;
  border-radius: 12px;
  background: white;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  .opt-out-button {
    background: #CC0404;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  box-shadow: ${BoxShadow};
  scroll-behavior: smooth;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
`;

const TopBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MiddleBoxText = styled('div')`
  font-family: 'Nunito Sans';
  margin-top: 20px;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 1px solid lightgrey;
`;

const TopBoxText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-top: 15px;
  color: black;
`;

const DeleteDashboardModal = () => {
  const deleteDashboardModal = useSelector(
    (state) => state.sportCoachDashboard.ui.deleteDashboardModal,
  );
  const templates = useSelector((state) => state.sportCoachDashboard.data.templates);
  const currentUser = useSelector(
    (state) => state.auth.data.currentUser,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(deleteDashboardModal).length) {
      setIsSubmitting(false);
    }
  }, [deleteDashboardModal]);

  const dispatch = useDispatch();

  return (
    <ModalContainer isActive={Object.keys(deleteDashboardModal).length}>
      <div className='remove-div' onClick={() => dispatch(setDeleteDashboardModal({}))}>
        <div className='remove-div-icon'>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <TopBox>
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={40}
              icon='trashcan'
              color='black'
            />
          </IconContainer>
        </TopBox>
        <TopBoxText>
          Delete Dashboard
        </TopBoxText>
        <MiddleBoxText>
          Are you sure you want to delete this dashboard?
          Any coaches who have had access to this dashboard will no longer be able to view it.
        </MiddleBoxText>
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='Delete'
        className='opt-out-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          setIsSubmitting(true);
          const templateIndex = templates.findIndex(
            (template) => template.templateId === deleteDashboardModal.templateId,
          );
          dispatch(deleteDashboard(
            currentUser.accountCode,
            deleteDashboardModal.templateId,
            templateIndex,
          ));
          // Amplitude tracking
          dashboardTracker('Sport Coach Dashboard', 'Delete Saved Dashboard', 'Dashboard deleted');
        }}
        disabled={isSubmitting}
      />
    </ModalContainer>
  );
};

export default DeleteDashboardModal;
