import React, { useState, useEffect, useRef, Fragment } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';
import Logger from 'js-logger';

import Avatar from '../../../shared/components/Avatar/Avatar';
import Table from '../../../shared/components/Table/Table';
import Text from '../../../shared/components/Text/Text';

const TableWrapper = styled('div')`
    padding: 0px 20px;
    margin-top: 30px;
  .rdt_TableBody {
    padding: 0px 5px;
  }
`;

const NameCell = styled('div')`
  display: flex;
  align-items: center;

  p {
    margin-left: ${props => (props.activeBoards !== 3 ? '15px' : '0px')};
  }
  @media screen and (max-width: 768px) {
    p {
      margin-left: 0px;
    }
    div {
      display: none;
    }
  }
`;

const ChangeCell = styled('div')`
  display: flex;
  align-items: center;
  p {
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

const LeaderboardTable = ({
  activeBoards,
  handleLoadMore,
  leaderboardData,
  leaderboardForm,
  id,
  tableEnd,
  tableRef,
  setTableHeight,
  setCurrentScroll,
  tableContainerHeight,
  tableHeight,
  windowSize,
}) => {
  const theme = useTheme();

  const columns = [
    {
      name: 'Rank',
      selector: 'rank',
      width: '65px',
      center: true,
      cell: row => (
        <Text
          fontWeight={900}
        >
          {row.rank}
        </Text>
      ),
    },
    {
      name: 'Name',
      selector: 'userName',
      style: {
        fontWeight: '700',
      },
      cell: row => (
        <NameCell
          activeBoards={activeBoards}
        >
          {(activeBoards !== 3) && (
            <Avatar
              avatar={row.userPic}
            />
          )}
          <Text>{row.userName}</Text>
        </NameCell>
      ),
    },
    {
      name: 'Date',
      selector: 'dateSet',
      omit: windowSize.width <= 768,
      center: true,
      style: {
        color: theme.colors.lightText[0],
      },
    },
    {
      name: 'Result',
      selector: 'displayValue',
      width: activeBoards === 1 ? '150px' : '70px',
      center: true,
      cell: row => (
        <Text
          fontWeight={600}
        >
          {row.displayValue}
        </Text>
      ),
    },
    {
      name: 'Change',
      selector: 'changeDisplayValue',
      width: activeBoards === 1 ? '150px' : '70px',
      omit: leaderboardForm.compareTo === 0 || activeBoards === 3,
      center: true,
      cell: row => (
        <ChangeCell>
          <Text
            fontWeight={600}
            fontSize={row.changeDirection === 2 ? 12 : 10}
            color={row.changeDirection === 2 ? theme.colors.green : 'red'}
          >
            {row.changeDirection === 2 && ('▲ ')}
            {row.changeDirection === 1 && ('▼ ')}
          </Text>
          <Text
            fontWeight={600}
          >
            {row.changeDisplayValue === null || row.changeDisplayValue === '' || row.changeDisplayValue === 0 ? '-' : row.changeDisplayValue}
          </Text>
        </ChangeCell>
      ),
    },
  ];

  if (activeBoards >= 2) {
    columns.splice(2, 1);
  }

  const loadMore = () => {
    handleLoadMore();
  };

  return (
    <TableWrapper
      ref={tableRef}
    >
      {/*
        Instead of using a scroll event listener which can be expensive, we're using
        the resize observer api which determines changes to the dimensions and position information
        of an element. By placing it within the TableWrapper we are observing the leaderboard table
        and can use the rect.top data for scroll location.
      */}
      <ResizeObserver
        onResize={(rect) => {
          setTableHeight(rect.height);
        }}
        onPosition={(rect) => {
          // If rect.top from observer api is less than tableEnd from
          // the above variable, then load more data.
          if (rect.top <= tableEnd) {
            loadMore();
            setCurrentScroll(rect.top);
            console.log('Loading more posts...');
          }
          // Logger.debug(`Leaderboard ${id} location:`, rect.top, `height: ${tableHeight}`, `Table End: ${tableEnd}`, `Table Container Height ${tableContainerHeight}`, 'Are we at the end of the table?', rect.top <= tableEnd);
        }}
      />
      <Table
        center
        columns={columns}
        data={leaderboardData}
        highlightOnHover
        noHeader
      />
    </TableWrapper>
  );
};

LeaderboardTable.propTypes = {
  activeBoards: PropTypes.number.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  leaderboardData: PropTypes.instanceOf(Array).isRequired,
  tableEnd: PropTypes.number.isRequired,
  tableRef: PropTypes.instanceOf(Object).isRequired,
  setTableHeight: PropTypes.func.isRequired,
  setCurrentScroll: PropTypes.func.isRequired,
};

export default React.memo(LeaderboardTable);
