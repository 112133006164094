import { connect } from 'react-redux';
import WorkoutEntryView from '../presentational/WorkoutEntryView';

import {
  closeModal,
  fetchHistory,
  fetchDocument,
  fetchExercises,
  fetchSimilarExercises,
  openModal,
  updateWorkout,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeDate: state[ownProps.namespace].activeDate,
  activeModal: state[ownProps.namespace].activeModal,
  activeScreens: state.weightRoom.activeScreens,
  activeCalendar: state[ownProps.namespace].activeDate,
  athleteId: state[ownProps.namespace].athleteId,
  data: state[ownProps.namespace].workouts[ownProps.index],
  exerId: state[ownProps.namespace].activeExerId,
  isModalOpen: state[ownProps.namespace].isModalOpen,
  isWorkoutLoading: state[ownProps.namespace].isWorkoutLoading,
  isWeightValueEnabled: state.weightRoom.isWeightValueEnabled,
  settings: state.weightRoom.currentUser.settings,
  workoutsBySuperset: state[ownProps.namespace].workoutsBySuperset,
});

const mapDispatchToProps = dispatch => ({
  fetchHistory: (namespace, exerId, userId) => {
    dispatch(fetchHistory(namespace, exerId, userId));
  },
  fetchDocument: (namespace, docId) => {
    dispatch(fetchDocument(namespace, docId));
  },
  fetchExercises: (namespace) => {
    dispatch(fetchExercises(namespace));
  },
  fetchSimilarExercises: (namespace, exerId) => {
    dispatch(fetchSimilarExercises(namespace, exerId));
  },
  closeModal: (namespace) => {
    dispatch(closeModal(namespace));
  },
  openModal: (namespace, modal) => {
    dispatch(openModal(namespace, modal));
  },
  updateWorkout: (index, namespace, values) => {
    dispatch(updateWorkout(index, namespace, values));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkoutEntryView);
