import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';

import { ColorGreen, BoxShadow } from '../../../../shared/GlobalStyles';
import Toggle from '../../../../shared/components/Toggle/Toggle';
import { FormGroup } from '../../../../shared/components/FormHandler/FormHandler';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';
import IconRound from '../../../../shared/components/IconRound/IconRound';

const DatePickerContainer = styled('div')`
  tr:first-of-type {
    .CalendarDay, .CalendarDay__selected {
      border-top: 1px solid #e4e7e7;
    }
    .CalendarDay__outside {
      border-top: 0px;
      border-bottom: 1px solid #e4e7e7;
    }
  }
  & tr:last-of-type {
    border-right: 0px;
    .CalendarDay__outside {
      border-left: 1px solid #e4e7e7;
    }
    .CalendarDay__outside ~ .CalendarDay__outside{
      border-left: 0px solid #e4e7e7;
    }
  }

  .CalendarDay__selected {
    border-top: 0!important;
    border-right: 0px!important;
    border-color: #e4e7e7;
    .day-container {
      background-color: #0dcc8a!important;
    }
  }

  .DateRangePickerInput, .DateInput_input, .DateInput {
    border-radius: 4px;
  }

  .DateInput_input {
    padding: 5px 8px;
  }
  
  & button {
    border: 1px solid ${(props) => props.theme.noBorderColor};
    color: ${(props) => props.theme.lightTextColor};
    font-weight: 600;
  }

  & .CalendarMonth_caption {
    font-family: 'Nunito Sans';
    font-weight: 300;
  }
`;

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  label {
    margin-bottom: 0px;
    width: 60%;
    p {
      font-size: 10px;
    }
  }

  .react-toggle {
    width: 40%;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  padding: 0px 20px 10px 0px;
  min-width: 50%;
  max-width: 50%;
`;

const rangeTypeOptions = [
  { value: 0, label: 'Current Max' },
  { value: 1, label: 'All-Time' },
  { value: 2, label: 'Last 30 Days' },
  { value: 3, label: 'Today' },
  { value: 4, label: 'Custom' },
];

const MaxModalFormSlideTwo = ({ formProps }) => {
  const [focusedRangeDateInput, setFocusedRangeDateInput] = useState(null);

  const defaultRangeTypeOption = rangeTypeOptions.filter(
    (rangeType) => rangeType.value === formProps.values.rangeType,
  );

  return (
    <>
      <FormLabel>Select Report Options</FormLabel>
      <FormColumns>
        <ColumnContent>
          <FormLabel>Include Manually Entered Maxes</FormLabel>
          <Toggle
            checked={formProps.values.includeManualMaxes}
            // defaultChecked={!formProps.values.includeManualMaxes && true}
            // disabled={!formProps.values.mustBeInAllGroups}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'includeManualMaxes',
                formProps.values.includeManualMaxes === false && true,
              );
            }}
            value={!formProps.values.includeManualMaxes && true}
          />
        </ColumnContent>
        <ColumnContent>
          <FormLabel>Include Workout Generated Maxes</FormLabel>
          <Toggle
            defaultChecked={formProps.values.includeGeneratedMaxes}
            // disabled={formProps.values.includeGeneratedMaxes}
            // checked={formProps.values.includeGeneratedMaxes}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'includeGeneratedMaxes',
                formProps.values.includeGeneratedMaxes === false && true,
              );
            }}
            value={formProps.values.includeGeneratedMaxes}
          />
        </ColumnContent>
        <ColumnContent>
          <FormLabel>Show Average Row (All Athletes)</FormLabel>
          <Toggle
            checked={formProps.values.average}
            // defaultChecked={!formProps.values.average && true}
            // disabled={!formProps.values.average}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'average',
                formProps.values.average === false && true,
              );
            }}
            value={!formProps.values.average && true}
          />
        </ColumnContent>
        <ColumnContent>
          <FormLabel>Show Total Column (Each Athlete)</FormLabel>
          <Toggle
            // defaultChecked={formProps.values.total}
            // disabled={formProps.values.total}
            checked={formProps.values.total}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'total',
                formProps.values.total === false && true,
              );
            }}
            value={formProps.values.total}
          />
        </ColumnContent>
        <ColumnContent>
          <FormLabel>Calculate Relative Strength</FormLabel>
          <Toggle
            checked={formProps.values.relativeStrength}
            // defaultChecked={!formProps.values.relativeStrength && true}
            // disabled={!formProps.values.relativeStrength}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'relativeStrength',
                formProps.values.relativeStrength === false && true,
              );
            }}
            value={!formProps.values.relativeStrength && true}
          />
        </ColumnContent>
        <ColumnContent>
          <FormLabel>Show Body Weight Column</FormLabel>
          <Toggle
            // defaultChecked={formProps.values.bodyWeight}
            // disabled={formProps.values.bodyWeight}
            checked={formProps.values.bodyWeight}
            icons={false}
            onChange={() => {
              formProps.setFieldValue(
                'bodyWeight',
                formProps.values.bodyWeight === false && true,
              );
            }}
            value={formProps.values.bodyWeight}
          />
        </ColumnContent>
      </FormColumns>
      <FormGroup>
        <FormLabel>Time Frame</FormLabel>
        <Select
          defaultValue={defaultRangeTypeOption.length !== 0
            ? ({ ...defaultRangeTypeOption[0] })
            : ''}
          options={rangeTypeOptions}
          onChange={(options) => { formProps.setFieldValue('rangeType', options.value); }}
        />
      </FormGroup>
      {formProps.values.rangeType === 4 && (
        <FormGroup>
          <FormLabel>Pick a Date Range</FormLabel>
          <DatePickerContainer>
            <DateRangePicker
              // customInputIcon={() => (
              //   <IconRound
              //     icon='calendar'
              //     noBorder
              //   />
              // )}
              disabled={formProps.values.rangeType !== 4}
              startDate={formProps.values.rangeDates.startDate} // momentPropTypes.momentObj or null,
              startDateId='startDate' // PropTypes.string.isRequired,
              endDate={formProps.values.rangeDates.endDate} // momentPropTypes.momentObj or null,
              endDateId='endDate' // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => formProps.setFieldValue('rangeDates', { startDate, endDate })} // PropTypes.func.isRequired,
              focusedInput={focusedRangeDateInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={(focusedInput) => setFocusedRangeDateInput(focusedInput)} // PropTypes.func.isRequired,
              hideKeyboardShortcutsPanel
              isOutsideRange={() => false}
            />
          </DatePickerContainer>
        </FormGroup>
      )}
    </>
  );
};

export default MaxModalFormSlideTwo;
