import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const LoadingDotsContainer = styled('div')`
  div {
    background-color: ${(props) => props.theme.colors.text}!important;
  }
`;

const LoadingDots = () => {
  const [displayMessage, setDisplayMessage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(setDisplayMessage(true), 5000);
    return (clearTimeout(timer));
  }, []);

  if (!displayMessage) {
    return null;
  }
  if (displayMessage) {
    return (
      <LoadingDotsContainer className='saving'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
      </LoadingDotsContainer>
    );
  }
};

export default LoadingDots;