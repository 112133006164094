/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../shared/components/Text/Text';
import SessionInfoCard from '../../../../shared/components/SessionInfoCard';
import PRInfoCard from '../../../../shared/components/PRInfoCard';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const LatestSessionWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
  padding-bottom: 50px;
  position: relative;
  min-height: 400px;
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100%;
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  @media screen and (max-width: 400px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

const NewPRTitleArea = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

const PRInfoCardArea = styled('div')`
  margin-top: 25px;
  width: 470px;

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

const Area = styled('div')`
  @media screen and (max-width: 540px) {
    margin-top: 25px;
    width: 90%;
  }
`;

const LatestSession = ({ tab }) => {
  const latestSession = useSelector(
    (state) => state.profile.data.latestSession,
  );

  const isLatestSessionLoading = useSelector((state) => state.profile.ui.isLatestSessionLoading);

  // if (!latestSession
  //   || latestSession.hasData === undefined
  //   || isLatestSessionLoading === undefined) {
  //   return null;
  // }

  return (
    <LatestSessionWrapper>
      {isLatestSessionLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )
        : (
          <>
            <div style={{ marginBottom: 15 }}>
              <Text fontSize={18} fontWeight='800' style={{ textAlign: 'center' }}>
                {tab.toUpperCase()}
              </Text>
            </div>
            <Area>
              <Row>
                <SessionInfoCard
                  bottom={
              typeof latestSession?.setsCompleted === 'number'
                ? ''
                : `average: ${latestSession?.setsCompleted?.average || 0}`
            }
                  desc={
              typeof latestSession?.setsCompleted === 'number'
                ? latestSession?.setsCompleted
                : latestSession?.setsCompleted?.sum || 0
            }
                  icon='list'
                  title='Sets Completed'
                />
                <SessionInfoCard
                  bottom={
              typeof latestSession?.repsCompleted === 'number'
                ? ''
                : `average: ${latestSession?.repsCompleted?.average || 0}`
            }
                  desc={
              typeof latestSession?.repsCompleted === 'number'
                ? latestSession?.repsCompleted
                : latestSession?.repsCompleted?.sum || 0
            }
                  icon='cardio'
                  title='Total Reps'
                />
              </Row>
              <Row>
                <SessionInfoCard
                  bottom={
              typeof latestSession?.tonnage === 'number'
                ? 'Total Weight'
                : `average: ${latestSession?.tonnage?.average || 0}`
            }
                  desc={
              typeof latestSession?.tonnage === 'number'
                ? latestSession?.tonnage
                : latestSession?.tonnage?.sum || 0
            }
                  icon='lift'
                  title='Tonnage'
                />
                <SessionInfoCard
                  bottom={
              typeof latestSession?.sessionDurationDescription === 'string'
                ? ''
                : `average: ${latestSession?.sessionDurationDescription?.average || 0}`
            }
                  desc={
              typeof latestSession?.sessionDurationDescription === 'string'
                ? latestSession?.sessionDurationDescription
                : latestSession?.sessionDurationDescription?.sum || 0
            }
                  icon='clock'
                  title='Session Duration'
                />
              </Row>
            </Area>
            {latestSession?.newPRs && (
              <Area>
                <NewPRTitleArea>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={24}
                    icon='trophy'
                  />
                  <Text fontSize={16} fontWeight='700' style={{ marginLeft: 10 }}>
                    New PR's
                  </Text>
                </NewPRTitleArea>
                <PRInfoCardArea>
                  {latestSession?.newPRs.map((newPR) => (
                    <PRInfoCard
                      key={newPR.exerciseId}
                      name={newPR.exerciseName}
                      value={newPR.maxValue}
                    />
                  ))}
                  {latestSession?.newPRs.length === 0 && (
                  <Text fontSize={16} fontWeight='700'>
                    No new PRs from
                    {' '}
                    {tab.toLowerCase()}
                  </Text>
                  )}
                </PRInfoCardArea>
              </Area>
            )}
          </>
        )}
    </LatestSessionWrapper>
  );
};

LatestSession.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default LatestSession;
