// component that receives a workout item and transcribes the associated info
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { useDispatch } from 'react-redux';

import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  setIsWorkoutsCarouselShowing,
} from '../../ducks/workoutsActions';

const WorkoutItemDiv = styled('div')`
	display: flex;
	cursor: pointer;
  margin-bottom: ${(props) => (props.isLast ? '10px' : '0px')};
`;

const IconTitle = styled('div')`
	display: flex;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
`;

const TitleDesc = styled('div')`
	max-width: 100%;
  width: 95%;
  white-space: nowrap;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    margin-left: 1px;
  }
`;

const Title = styled('div')`
	font-size: 14px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconContainer = styled('div')`
	display: flex;
  position: relative;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
	width: 25px;
	height: 25px;
  background: ${(props) => (props.completed ? '#0dcc8a' : 'white')};
`;

const IconAndLine = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	align-items: center;
`;

const GroupingLetterContainer = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 50px;
  border-Width: 1px;
  height: 15px;
  justify-content: center;
  padding: 1.5px;
  position: absolute;
  width: 15px;
  border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
  background: ${(props) => (props.borderColor)};
  right: -3px;
  bottom: -3px;
`;

const GroupingLetter = styled('div')`
	color: ${(props) => props.color};
  font-size: 9px;
  font-weight: 500;
`;

const WorkoutsBreakListItem = ({
  workoutItem,
  showVerticalLine,
  isLast,
  index,
}) => {
  const theme = useTheme();

  const getMediaEntries = () => {
    let media = [];
    if (workoutItem.journalEntries && workoutItem.journalEntries.length) {
      workoutItem.journalEntries.forEach((journalEntry) => {
        if (journalEntry.media && journalEntry.media.length) {
          media = media.concat(journalEntry.media);
        }
      });
    }
    return media;
  };

  const getJournalText = () => (
    workoutItem.journalEntries
      && workoutItem.journalEntries.length
      && workoutItem.journalEntries[0].body
      ? workoutItem.journalEntries[0].body.replace(/[\r\n]+/g, ' ')
      : ''
  );

  // const getTopLineHeight = () => {
  //   const hasMedia = getMediaEntries(workoutItem).length;
  //   const journalText = getJournalText();
  //   // console.log(journalText);
  //   // /**
  //   //  * Because there are so many different variations of things that we show
  //   //  * for any given workout item, we are defining custom heights for the vertical separator
  //   //  * line for each scenario here. We can show the following in a workout item: media,
  //   //  * journal text, subtitle, title. We will base the vertical line height from those.
  //   //  */
  //   // if (hasMedia) {
  //   //   if (journalText) {
  //   //     if (!workoutItem.subTitle) {
  //   //       // has media + journal text, but no sub title
  //   //       return 120;
  //   //     }
  //   //     // has media + journal text + sub title
  //   //     return 120;
  //   //   }
  //   //   // has media but no journal text
  //   //   return 110;
  //   // }
  //   // if (journalText) {
  //   //   // no media, but has journal text
  //   //   return 40;
  //   // }
  //   // default
  //   return 0;
  // };

  const getLineHeight = () => {
    const hasMedia = getMediaEntries(workoutItem).length;
    const journalText = getJournalText();
    // console.log(journalText);
    // /**
    //  * Because there are so many different variations of things that we show
    //  * for any given workout item, we are defining custom heights for the vertical separator
    //  * line for each scenario here. We can show the following in a workout item: media,
    //  * journal text, subtitle, title. We will base the vertical line height from those.
    //  */
    // if (hasMedia) {
    //   if (journalText) {
    //     if (!workoutItem.subTitle) {
    //       // has media + journal text, but no sub title
    //       return 120;
    //     }
    //     // has media + journal text + sub title
    //     return 120;
    //   }
    //   // has media but no journal text
    //   return 110;
    // }
    // if (journalText) {
    //   // no media, but has journal text
    //   return 40;
    // }
    // default
    return 25;
  };

  const dispatch = useDispatch();

  return (
    <WorkoutItemDiv
      isLast={isLast}
      onClick={() => {
        dispatch(setIsWorkoutsCarouselShowing(true, index));
      }}
    >
      <IconAndLine>
        {/* {getMediaEntries(workoutItem).length || workoutItem?.subTitle ? (
          <div style={{
            // height: `${getTopLineHeight()}px`, width: '2px', background: 'lightgrey', opacity: index === 0 ? 0 : 1,
          }}
          />
        ) : null} */}
        <IconContainer borderColor={workoutItem?.groupingColorCode || 'grey'} completed={workoutItem?.fullyCompleted}>
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon={workoutItem.fullyCompleted ? 'checkmark' : workoutItem.icon}
            color={workoutItem.fullyCompleted ? 'white' : 'black'}
          />
          {workoutItem.groupingColorCode && workoutItem.groupingLetter && (
          <GroupingLetterContainer borderColor={workoutItem?.groupingColorCode || 'black'} completed={workoutItem?.fullyCompleted}>
            <GroupingLetter
              color={theme.colors.background[0]}
            >
              {workoutItem.groupingLetter}
            </GroupingLetter>
          </GroupingLetterContainer>
          )}
        </IconContainer>
        <div style={{
          height: `${getLineHeight()}px`, width: showVerticalLine ? '2px' : '0px solid #444', background: 'lightgrey',
        }}
        />
      </IconAndLine>
      <IconTitle>
        <TitleDesc>
          <Title>
            {workoutItem.title}
          </Title>
          <SubText fontSize={11}>
            {workoutItem?.subTitle?.toUpperCase()}
          </SubText>
        </TitleDesc>
      </IconTitle>
    </WorkoutItemDiv>
  );
};

WorkoutsBreakListItem.propTypes = {
  workoutItem: PropTypes.instanceOf(Object).isRequired,
  showVerticalLine: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default WorkoutsBreakListItem;
