import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';
import Text from '../../../../shared/components/Text/Text';
import QuestionnaireReportDatePicker from '../QuestionnaireReport/QuestionnaireReportDatePicker';

const DateSelectAndTextContainer = styled('div')`
display: flex;
flex-direction: column;
gap: 10px;
`;

const EvaluationReportFormSlideTwo = ({ formProps }) => {
  const setFirstStartDate = (date) => {
    formProps.setFieldValue('firstStartDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
    formProps.setFieldValue('firstEndDate', {
      date: null,
      queryFormat: '',
    });
  };

  const setFirstEndDate = (date) => {
    formProps.setFieldValue('firstEndDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  const setSecondStartDate = (date) => {
    formProps.setFieldValue('secondStartDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
    formProps.setFieldValue('secondEndDate', {
      date: null,
      queryFormat: '',
    });
  };

  const setSecondEndDate = (date) => {
    formProps.setFieldValue('secondEndDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  return (
    <>
      <DateSelectAndTextContainer>
        <Text fontSize={14} fontWeight={700}>Select Date Ranges to Compare</Text>
      </DateSelectAndTextContainer>
      <QuestionnaireReportDatePicker
        formProps={formProps}
        setStartDate={setFirstStartDate}
        setEndDate={setFirstEndDate}
        startDate={formProps.values.firstStartDate.date}
        endDate={formProps.values.firstEndDate.date}
        reportType='multiDay'
        labelText='Evaluation 1'
      />
      <QuestionnaireReportDatePicker
        formProps={formProps}
        setStartDate={setSecondStartDate}
        setEndDate={setSecondEndDate}
        startDate={formProps.values.secondStartDate.date}
        endDate={formProps.values.secondEndDate.date}
        reportType='multiDay'
        labelText='Evaluation 2 (optional)'
      />
    </>
  );
};

EvaluationReportFormSlideTwo.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default EvaluationReportFormSlideTwo;
