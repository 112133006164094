/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Slider from 'react-rangeslider';

import ButtonRound from './ButtonRound';
import { ColorGreen } from '../../../../shared/GlobalStyles';

const ScienceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 51px;
`;

const ScienceInfo = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ScienceRange = styled('div')`
  display: flex;
  flex-direction: column;
  
  .rangeslider-horizontal {
    height: 7px;
    flex-grow: 1;
    margin-left: 30px;
    .rangeslider__fill {
      background-color: ${ColorGreen};
    }
  }

  .rangeslider__handle {
    height: 20px;
    width: 20px;
    &:focus {
      outline: none;
    }
    &:after {
      display: none;
    }
  }
`;

const Text = styled('p')`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.lightTextColor2};

  ${(props) => (
    props.small ? `
      font-size: 12px;
    ` : ''
  )}

`;

const FormContainer = styled('div')`
  display: flex;
  margin: 15px 0px;
  padding-right: 10px;
  .form-group {
    margin-left: 0px;
    width: 35px!important;
  }
`;

const YesNoContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .yesNoOption {
    width: 115px;
    margin-right: 25px;
  }
`;

const MCContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .mcOption {
    margin-top: 5px;
  }
`;

const ScienceEntries = ({
  choices,
  description,
  index,
  inputType,
  handleChange,
  values,
  rangeStart,
  rangeEnd,
  setFieldValue,
}) => {
  // Workaround for creating a touched variable to check that each question was
  // touched before submitting
  const setTouched = () => {
    if (!values.body[index].touched) {
      setTimeout(() => {
        setFieldValue(`body[${index}].touched`, true, false);
      }, 250);
    }
  };

  return (
    <ScienceContainer id='science-container'>
      <ScienceRange id='science-range'>
        <ScienceInfo>
          <>
            <Text>{description}</Text>
            {inputType === 'RANGE' && (
            <Text small>{`Range ${rangeStart} - ${rangeEnd}`}</Text>
            )}
          </>
        </ScienceInfo>
        <FormContainer>
          {inputType === 'RANGE' ? (
            <>
              <div className='form-group'>
                <input
                  name={`body[${index}].value`}
                  className='form-control'
                  onChange={(e) => {
                    // Control to ensure that only values within the range get saved
                    if (Number(e.target.value) < rangeStart) {
                      e.target.value = rangeStart;
                    }
                    if (Number(e.target.value) > rangeEnd) {
                      e.target.value = rangeEnd;
                    }
                    setTouched();
                    handleChange(e);
                  }}
                  value={values.body[index].value}
                  type='number'
                />
              </div>
              <Slider
                value={values.body[index].value}
                min={rangeStart}
                max={rangeEnd}
                name={`body[${index}].value`}
                orientation='horizontal'
                onChange={(e) => {
                  setFieldValue(`body[${index}].value`, e, false);
                  setFieldValue(`body[${index}].total`, e, false);
                  setTouched();
                }}
              />
            </>
          ) : inputType === 'INPUT' ? (
            <input
              name={`body[${index}].value`}
              className='form-control'
              onChange={(e) => {
                setTouched();
                handleChange(e);
              }}
              type='text'
              value={values.body[index].value}
            />
          ) : inputType === 'YES-NO' ? (
            <YesNoContainer>
              {choices.map((option) => (
                <ButtonRound
                  cta={option.displayValue}
                  className='yesNoOption'
                  complete={option.displayValue === values.body[index].displayValue}
                  icon='checkmark'
                  onClick={() => {
                    setTouched();
                    setFieldValue(`body[${index}].displayValue`, option.displayValue, false);
                    setFieldValue(`body[${index}].value`, option.value, false);
                    if (option.numericalWeight === null) {
                      setFieldValue(`body[${index}].total`, 0, false);
                    } else {
                      setFieldValue(`body[${index}].total`, option.numericalWeight, false);
                    }
                  }}
                  type='button'
                />
              ))}
            </YesNoContainer>
          ) : (
            <MCContainer>
              {choices.map((option) => (
                <ButtonRound
                  cta={option.displayValue}
                  className='mcOption'
                  complete={option.displayValue === values.body[index].displayValue}
                  onClick={() => {
                    setTouched();
                    setFieldValue(`body[${index}].displayValue`, option.displayValue, false);
                    setFieldValue(`body[${index}].value`, option.value, false);
                    if (option.numericalWeight === null) {
                      setFieldValue(`body[${index}].total`, 0, false);
                    } else {
                      setFieldValue(`body[${index}].total`, option.numericalWeight, false);
                    }
                  }}
                  type='button'
                />
              ))}
            </MCContainer>
          )}
        </FormContainer>
      </ScienceRange>
    </ScienceContainer>
  );
};

ScienceEntries.propTypes = {
  choices: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inputType: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  rangeStart: PropTypes.number.isRequired,
  rangeEnd: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default ScienceEntries;
