/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Dropdown from 'react-bootstrap/Dropdown';
import Dropzone from 'react-dropzone';
import Linkify from 'react-linkify';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import SubText from '../../../../shared/components/SubText/SubText';
import WorkoutNotes from './WorkoutNotes';
import SessionBreak from './SessionBreak';
import Questionnaire from './Questionnaire';
import HealthCard from './HealthCard';
import ProgressionCard from './ProgressionCard';
import TagCard from './TagCard';
import TimerCard from './TimerCard';
import VideoCard from './VideoCard';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import WorkoutEntryTable from './WorkoutEntryTable';
import {
  setActiveWorkoutsMedia,
  setOptOutWorkout,
  setIsWorkoutsMediaUploaded,
  setJournalEntryWorkout,
  setQuickImageWorkoutsJournalModal,
  setQuickVideoWorkoutsJournalModal,
  setQuickWorkoutsJournal,
  uploadQuickJournalMedia,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import WorkoutDocumentComponent from './WorkoutDocumentComponent';

const Wrapper = styled('div')`
  width: 100%;
	height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito Sans';
  background: #F8F8F8;
  border-radius: 12px;
  overflow: hidden;
  .fade-div {
    pointer-events: none;
    position: absolute;
    bottom: 0;
    height: 50px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const UpperBox = styled('div')`
	width: 100%;
  display: 'flex';
  flex-direction: column;
  justify-content: ${(props) => (props.background ? 'space-between' : 'center')};
  padding: 15px;
  cursor: ${(props) => (props.isVideo ? 'pointer' : 'default')};
  position: relative;
  opacity: ${(props) => (props.headerHider ? '0' : '1')};
  background: #F8F8F8;
  height: 30%;
  @media only screen and (max-height : 724px) {
    min-height: 150px;
    display: ${(props) => (props.headerHider ? 'none' : 'flex')};
  }
`;

const Video = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.isVideo ? 'pointer' : 'default')};
  background-image: ${(props) => `url(${props.background})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const VideoContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.isVideo ? 'pointer' : 'default')};
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const VideoOverlay = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: ${(props) => (props.isVideo ? 'pointer' : 'default')};
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: ${(props) => (props.videoTrue ? 'rgba(0, 0, 0, 0.7)' : 'none')};
`;

const ExerciseName = styled('div')`
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  color: ${(props) => (props.videoTrue ? 'white' : 'black')}; 
  margin-top: ${(props) => (!props.videoTrue ? '20px' : '5px')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: center;
`;

const ButtonAddWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 70%;
`;

const InteriorWrap = styled('div')`
  display: ${(props) => (props.headerHider ? 'none' : 'flex')};
  flex-direction: column;
`;

const MaxTempBox = styled('div')`
  display: flex;
  justify-content: space-between;
  color: ${(props) => (props.videoTrue ? 'white' : 'grey')};
  width: 100%;
`;

const UpperBoxBottom = styled('div')`
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Max = styled('div')`
  font-size: 12px;
`;

const Tempo = styled('div')`
  font-size: 12px;
`;

const LowerBox = styled('div')`
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  height: 100%;
  padding-bottom: 25px;
  overflow: auto;
`;

const PlayButton = styled('div')`
  width: 33px;
  height: 33px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 2px;
    margin-top: 1px;
  }
`;

const CardLine = styled('div')`
  display: flex;
  position: absolute;
  height: 3px;
  width: 100%;
  background: linear-gradient(#ff9a44, #fc6076);
  bottom: 0;
  left: 0;
`;

const GreenCardLine = styled('div')`
  display: flex;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #0dcc8a;
  bottom: 0;
  left: 0;
`;

const AdditionalInformationText = styled('div')`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  flex-direction: column;
  font-family: 'Nunito Sans';
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  white-space: pre-wrap;
  a {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Nunito Sans';
  }
`;

const ErrorText = styled('div')`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Nunito Sans';
  text-align: center;
  color: #ff6600;
`;

const AdditionalInformation = styled('div')`
  display: flex;
  border: none;
  background: white;
  justify-content: center;
  border-radius: 12px;
  width: 100%;
  align-items: center;
  box-shadow: ${BoxShadow};
  padding: 10px;
  white-space: normal;
  margin-bottom: 15px;
`;

const OptOutWrapper = styled('div')`
  display: flex;
  border: none;
  background: white;
  align-items: center;
  border-radius: 12px;
  width: 100%;
  flex-direction: column;
  box-shadow: ${BoxShadow};
  margin-top: ${(props) => (props.isButtonsShowing ? '15px' : '0px')};
  padding: 5px;
  white-space: normal;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const OptOutText = styled('div')`
  color: red;
  font-size: 10px;
`;

const MiddleButtons = styled('div')`
  display: flex;
  width: 100%;
  height: 33px;
  justify-content: space-evenly;
  width: 100%;
  min-height: 33px;
  height: 100%;
  margin-bottom: 15px;
`;

const NameAndPlay = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconTextWrapper = styled('div')`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;

const MiddleButton = styled('button')`
  display: flex;
  border: none;
  background: white;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  flex: 3;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: ${BoxShadow};
`;

const MiddleJournalButton = styled('button')`
  display: flex;
  border: none;
  background: white;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  flex: 3;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: ${BoxShadow};
  position: relative;
`;

const MiddleButtonImage = styled('button')`
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  flex: 3;
  margin-left: 5px;
`;

const DropDownMiddleButton = styled('button')`
  display: flex;
  border: none;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    margin-left: 1px;
  }
`;

const IconAndCardLine = styled('div')`
  
`;

const SmallSpinnerWrapper = styled('div')`
  display: flex;
  height: 50%;
  justify-content: center;
  width: 50%;
`;

const QuickMediaSpinnerWrapper = styled('div')`
  display: flex;
  height: 25%;
  justify-content: center;
  width: 25%;

  svg {
    height: 20px;
  }
`;

const IconContainer = styled('div')`
	display: flex;
  position: absolute;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
  height: 25px;
  width: 25px;
  background: ${(props) => (props.completed ? '#0dcc8a' : 'white')};
  bottom: -10px;
  z-index: 1;
`;

const GroupingLetterContainer = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 50px;
  border-Width: 1px;
  height: 12px;
  justify-content: center;
  padding: 1.5px;
  position: absolute;
  width: 12px;
  border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
  background: ${(props) => (props.borderColor)};
  right: -4px;
  top: -4px;
`;

const GroupingLetter = styled('div')`
	color: ${(props) => props.color};
  font-size: 9px;
  font-weight: 500;
`;

const DropDownMenuWrapper = styled('div')`
  display: flex;
  border: none;
  background: white;
  justify-content: center;
  border-radius: 8px;
  align-items: center;
  flex: 3;
  box-shadow: ${BoxShadow};
  position: relative;
  z-index: 101;

  &.giphy-dropdown {
    position: absolute;
    top: 0px;
    right: 20px;
    height: 100%;
    z-index: 100;
    cursor: pointer;

  }  

  &.emoji-dropdown {
    position: absolute;
    top: 0px;
    right: 55px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
     
    @media screen and (max-width: 767px) {
      display: none;
    }
  }  

  .dropdown-container {
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);
    border: 0px;

    &.giphy {
      padding: 20px;
      z-index: 100;
    }

    &.emoji {
      padding: 0px;
      z-index: 100;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px 12px;
    transition: linear 0s;

    span {
      color: #444444;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0px;
      font-size: 12px;
    }
    :hover {
      background: ${(props) => props.theme.colors.green};
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }

  &.notification-dropdown {
    position: absolute;
    right: 15px;
  }
`;

const EmptyDropZoneWrapper = styled('div')`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 75px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 75px;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const DropZoneWrapper = styled('div')`
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }
  .update-button {
    width: 180px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;
    &:hover {
      opacity: .7;
    }
  }
`;

const MediaCountIcon = styled('div')`
  margin-left: 3px;
  margin-top: -3px;
  margin-right: 2px;
`;

const MediaCount = styled('div')`
  position: absolute;
  width: 35px;
  height: 20px;
  right: 0;
  top: 0;
  border-radius: 25px;
  margin-top: -10px;
  margin-right: -8px;
  background: #b8e8f9;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  z-index: 1;
`;

const CarouselCard = (
  {
    workoutObject,
    activeIndex = undefined,
    setActiveIndex,
    isFinalIndex,
    outerIndex,
    index,
    sessionIndex = null,
  },
) => {
  const videoTrue = workoutObject?.exercise?.media;
  const theme = useTheme();
  const mediaIsUploading = useSelector((state) => state.workouts.ui.isMediaUploading);
  const isMediaUploaded = useSelector((state) => state.workouts.data.isMediaUploaded);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const uploadedMedia = useSelector((state) => state.workouts.data.uploadMedia);
  const isWorkoutCompleting = useSelector((state) => state.workouts.ui.isWorkoutCompleting);
  const workoutItemIndex = useSelector((state) => state.workouts.ui.workoutItemIndex);
  const quickJournalMediaError = useSelector((state) => state.workouts.data.quickJournalMediaError);

  const isQuickJournalMediaUploading = useSelector(
    (state) => state.workouts.ui.isQuickJournalMediaUploading,
  );

  const AddMediaToggle = React.forwardRef(({ onClick }, ref) => (
    <DropDownMiddleButton
      href=''
      icon='plus'
      small
      onClick={(e) => {
        if (!isQuickJournalMediaUploading) {
          e.preventDefault();
          onClick(e);
        }
      }}
      customColor='rgb(240, 240, 240)'
      ref={ref}
    >
      {isQuickJournalMediaUploading && (activeIndex === index) ? (
        <QuickMediaSpinnerWrapper>
          <Spinner />
        </QuickMediaSpinnerWrapper>
      ) : (
        <>
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='video-add'
            color='rgb(184, 232, 249)'
          />
          <div style={{
            display: 'flex',
            marginLeft: 4,
          }}
          >
            <SubText fontSize='8px' fontWeight='700' letterSpacing='1px'>
              Media
            </SubText>
          </div>
        </>

      )}
    </DropDownMiddleButton>
  ));

  const urlSnapper = () => {
    if (uploadedMedia && isMediaUploaded) {
      if (uploadedMedia.mediaUrl) {
        if (uploadedMedia.thumbnailUrl) {
          return uploadedMedia.thumbnailUrl;
        }
        return uploadedMedia.mediaUrl;
      }
    }
    return '';
  };

  const DropZoneDiv = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 0;
    margin-left: 0px;
    left: 0px;
    height: 25px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    width: 25px;
    color: #495057;
    background-image: url(${urlSnapper()});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid #ced4da;
    border-radius: 50%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
  `;

  const SpinnerWrapper = styled('div')`
    position: absolute;
    display: flex;
    height: 50%;
    justify-content: center;
    width: 100%;
  `;

  const dispatch = useDispatch();

  const mediaSlipper = (bool, workOb) => {
    if (bool) {
      if (workOb?.video?.thumbnailUrl) {
        return workOb?.video?.thumbnailUrl;
      }
      if (workoutObject?.exercise?.media) {
        return workoutObject?.exercise?.media[0].thumbnailUrl;
      }
    }
    return null;
  };

  /**
   * Renders the main content form of workout entry, based on the form type
   */
  const renderChopper = (workout) => {
    if (!workout) {
      return null;
    }
    if (workout.formType === 'Questions') {
      return (
        <Questionnaire
          workoutObject={workout}
          index={index}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      );
    }
    if (workoutObject.formType === 'TagSelect') {
      return (
        <TagCard
          workoutObject={workout}
          index={index}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      );
    }
    if (workout.formType === 'TextOnly') {
      return (
        <WorkoutNotes workoutObject={workout} />
      );
    }
    if (workout.type === 'BR') {
      return <SessionBreak workoutObject={workout} />;
    }
    if (workout.type === 'H') {
      return (
        <HealthCard
          workoutObject={workout}
          index={index}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      );
    }
    if (workout.type === 'P') {
      return (
        <ProgressionCard
          workoutObject={workout}
          index={index}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      );
    }
    if (workout.type === 'RT') {
      return <TimerCard workoutObject={workout} index={index} />;
    }
    if (workout.type === 'V') {
      return <VideoCard workoutObject={workout} index={index} />;
    }
    return workout?.tableHeaders
      ? (
        <WorkoutEntryTable
          workoutObject={workout}
          index={index}
          isFinalIndex={isFinalIndex}
          setActiveIndex={setActiveIndex}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      )
      : null;
  };

  const handleDroppedFile = (acceptedFile) => {
    const type = acceptedFile[0].type.split('/').shift();
    // dispatch(uploadWorkoutsMedia({ accountCode: currentUser.accountCode, acceptedFile, type }));
    dispatch(uploadQuickJournalMedia(
      { accountCode: currentUser.accountCode, acceptedFile, type },
    ));
    dispatch(setQuickWorkoutsJournal({ index: outerIndex, workoutObject, sessionIndex }));
  };

  const fileAccepterSwitch = () => (
    <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
      {({ getRootProps, getInputProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DropZoneWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <Dropdown.Item>
            <>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='attachment'
              />
              <SubText
                className='drop-icon-text'
                fontSize={14}
              >
                Add Saved Media
              </SubText>
            </>
          </Dropdown.Item>
        </DropZoneWrapper>
      )}
    </Dropzone>
  );

  const addMediaDropdown = () => (
    <DropDownMenuWrapper>
      <Dropdown style={{
        height: '100%',
        width: '100%',
      }}
      >
        <Dropdown.Toggle
          as={AddMediaToggle}
          isQuickJournalMediaUploading={isQuickJournalMediaUploading}
        />
        <Dropdown.Menu className='dropdown-container emoji'>
          <Dropdown.Item
            className='dropdown-item'
            onClick={() => {
              dispatch(setQuickWorkoutsJournal({ index: outerIndex, workoutObject, sessionIndex }));
              dispatch(setQuickImageWorkoutsJournalModal(true));
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='camera'
            />
            <SubText
              className='drop-icon-text'
              fontSize={12}
            >
              Add Live Photo
            </SubText>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => {
            dispatch(setQuickWorkoutsJournal({ index: outerIndex, workoutObject, sessionIndex }));
            dispatch(setQuickVideoWorkoutsJournalModal(true));
          }}
          >
            <>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='video'
              />
              <SubText
                className='drop-icon-text'
                fontSize={12}
              >
                Add Live Video
              </SubText>
            </>
          </Dropdown.Item>
          {fileAccepterSwitch(dispatch, handleDroppedFile, isMediaUploaded)}
        </Dropdown.Menu>
      </Dropdown>
    </DropDownMenuWrapper>
  );

  const buttonSnacker = (item) => (
    <>
      {item.optOutEnabled ? (
        <MiddleButton
          onClick={() => dispatch(setOptOutWorkout({
            index: outerIndex,
            workoutObject,
            sessionIndex,
          }))}
          style={{
            marginLeft: 0,
            marginRight: 5,
          }}
        >
          <IconTextWrapper>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='opt-out'
              color='red'
            />
            <div style={{
              display: 'flex',
              marginBottom: 1,
            }}
            >
              <SubText fontSize='8px' fontWeight='700' letterSpacing='1px'>
                Opt Out
              </SubText>
            </div>
          </IconTextWrapper>
        </MiddleButton>
      ) : null}
      {item.journalEntriesEnabled ? (
        <>
          <MiddleJournalButton
            onClick={() => dispatch(
              setJournalEntryWorkout({
                index: outerIndex,
                sessionIndex,
                workoutObject,
              }),
            )}
            style={{
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <IconTextWrapper>
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='journal'
                color='#0dcc8a'
              />
              <div style={{
                display: 'flex',
                marginBottom: 1,
              }}
              >
                <SubText fontSize='8px' fontWeight='700' letterSpacing='1px'>
                  Journal
                </SubText>
              </div>
            </IconTextWrapper>
            {workoutObject?.journalEntries?.length && workoutObject?.journalEntries[0]?.media
            && workoutObject?.journalEntries[0]?.media?.length ? (
              <MediaCount>
                <MediaCountIcon>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    icon='video'
                    color='white'
                  />
                </MediaCountIcon>
                {workoutObject?.journalEntries[0]?.media?.length}
              </MediaCount>
              ) : null}
          </MiddleJournalButton>
          {mediaIsUploading || isMediaUploaded ? (
            mediaIsUploading ? (
              <EmptyDropZoneWrapper>
                <SpinnerWrapper>
                  <Spinner
                    saving
                    darkTheme
                  />
                </SpinnerWrapper>
              </EmptyDropZoneWrapper>
            ) : (
              <>
                <MiddleButtonImage>
                  <DropZoneDiv onClick={() => dispatch(setIsWorkoutsMediaUploaded(false))} />
                </MiddleButtonImage>
              </>
            )
          ) : (
            addMediaDropdown(handleDroppedFile, isMediaUploaded, dispatch)
          )}
        </>
      ) : null}
    </>
  );

  const headerHider = workoutObject.type === 'BR';

  const showButtons = (object) => {
    if (object.formType === 'TextOnly' || object.type === 'RT' || object.type === 'V') {
      return false;
    }
    return true;
  };

  return (
    <>
      <Wrapper>
        <UpperBox
          onClick={() => {
            if (videoTrue && !headerHider) {
              if (workoutObject?.exercise?.media) {
                dispatch(setActiveWorkoutsMedia(workoutObject?.exercise?.media[0]));
              }
            }
            return null;
          }}
          isVideo={videoTrue}
          headerHider={headerHider}
        >
          <VideoContainer>
            <UpperBoxBottom>
              <NameAndPlay>
                {videoTrue ? (
                  <PlayButton>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='play-filled'
                      color='white'
                    />
                  </PlayButton>
                ) : <div />}
                <ExerciseName videoTrue={videoTrue}>{workoutObject.title}</ExerciseName>
              </NameAndPlay>
              <MaxTempBox videoTrue={videoTrue}>
                {workoutObject.workingMax ? (
                  <Max>
                    Max:
                    {' '}
                    { workoutObject.workingMax.value}
                  </Max>
                ) : <div />}
                {workoutObject.tempo ? (
                  <Tempo>
                    Tempo:
                    {' '}
                    {workoutObject.tempo}
                  </Tempo>
                ) : <div />}
              </MaxTempBox>
            </UpperBoxBottom>
            <VideoOverlay videoTrue={videoTrue} />
            <Video background={mediaSlipper(videoTrue, workoutObject)} />
          </VideoContainer>
          <IconAndCardLine>
            <IconContainer borderColor={workoutObject?.groupingColorCode || 'grey'} completed={workoutObject?.fullyCompleted}>
              {isWorkoutCompleting && index === workoutItemIndex ? (
                <SmallSpinnerWrapper>
                  <Spinner />
                </SmallSpinnerWrapper>
              ) : (
                <>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon={workoutObject.fullyCompleted ? 'checkmark' : workoutObject.icon}
                    color={workoutObject.fullyCompleted ? 'white' : 'black'}
                  />
                  {workoutObject.groupingColorCode && workoutObject.groupingLetter && (
                  <GroupingLetterContainer borderColor={workoutObject?.groupingColorCode || 'black'} completed={workoutObject?.fullyCompleted}>
                    <GroupingLetter
                      color={theme.colors.background[0]}
                    >
                      {workoutObject.groupingLetter}
                    </GroupingLetter>
                  </GroupingLetterContainer>
                  )}
                </>
              )}
            </IconContainer>
            {!workoutObject.fullyCompleted ? <CardLine /> : <GreenCardLine />}
          </IconAndCardLine>
        </UpperBox>
        <ButtonAddWrapper>
          <LowerBox>
            {showButtons(workoutObject) ? (
              <InteriorWrap headerHider={headerHider}>
                <MiddleButtons>
                  {buttonSnacker(workoutObject)}
                </MiddleButtons>
                {quickJournalMediaError && index === activeIndex ? (
                  <AdditionalInformation isButtonsShowing={showButtons(workoutObject)}>
                    <ErrorText>
                      {quickJournalMediaError}
                    </ErrorText>
                  </AdditionalInformation>
                ) : null}
                {workoutObject.optedOut && (
                <OptOutWrapper>
                  <OptOutText>
                    <strong>Opted out:</strong>
                    {' '}
                    {workoutObject.optedOutReason}
                  </OptOutText>
                  {workoutObject.substitutedExercise && (
                  <OptOutText>
                    <strong>Substituted from:</strong>
                    {' '}
                    {workoutObject.substitutedExercise.name}
                  </OptOutText>
                  )}
                </OptOutWrapper>
                )}
              </InteriorWrap>
            ) : null}
            {workoutObject?.documents.length
              ? <WorkoutDocumentComponent workoutObject={workoutObject} />
              : null}
            {workoutObject.additionalInformation && workoutObject.formType !== 'Questions' ? (
              <AdditionalInformation isButtonsShowing={showButtons(workoutObject)}>
                <AdditionalInformationText>
                  {workoutObject.additionalInformation}
                </AdditionalInformationText>
              </AdditionalInformation>
            ) : null}
            {workoutObject.text && workoutObject.formType === 'Questions' ? (
              <AdditionalInformation isButtonsShowing={showButtons(workoutObject)}>
                <AdditionalInformationText>
                  {workoutObject.text}
                </AdditionalInformationText>
              </AdditionalInformation>
            ) : null}
            {renderChopper(workoutObject)}
            {workoutObject.additionalInformation && workoutObject.formType === 'Questions' ? (
              <AdditionalInformation isButtonsShowing>
                <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                    <a target='blank' href={decoratedHref} key={key}>
                      {decoratedText}
                    </a>
                  )}
                >
                  <AdditionalInformationText>
                    {workoutObject.additionalInformation}
                  </AdditionalInformationText>
                </Linkify>
              </AdditionalInformation>
            ) : null}
          </LowerBox>
        </ButtonAddWrapper>
        {activeIndex === index ? <div className='fade-div' /> : null}
      </Wrapper>
    </>
  );
};

CarouselCard.defaultProps = {
  activeIndex: undefined,
};

CarouselCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  activeIndex: PropTypes.number,
};

export default CarouselCard;
