import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ColorBlack, BoxShadow } from '../../../../shared/GlobalStyles';

export const ButtonStyled = styled('button')`
  display: ${(props) => (props.isShowing ? 'flex' : 'none')};
  flex-direction: row;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.large ? '14px' : '12px')};
  border: none !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  font-weight: 900;
  width: 100%;
  height: 40px;
  align-items: center;
  height: ${(props) => (props.large && '48px')};
  background: white;
  padding-left: 10px;
  color: ${(props) => {
    const isAccentDarkTheme = props.theme.isAccentDarkTheme ? ColorBlack : 'white';
    return (
      props.primary || props.complete ? isAccentDarkTheme : props.theme.textColor
    );
  }};
  transition: all 250ms ease-in-out;
  box-shadow: ${BoxShadow};
  cursor: pointer;
  white-space: nowrap;
`;

const ButtonRound = ({
  bottom,
  buttonRef,
  cta,
  className,
  complete,
  fullWidth,
  icon,
  large,
  noBorder,
  onClick,
  primary,
  square,
  isShowing,
}) => (
  <ButtonStyled
    bottom={bottom}
    className={className}
    complete={complete}
    fullWidth={fullWidth}
    icon={icon}
    large={large}
    noBorder={noBorder}
    onClick={onClick}
    primary={primary}
    ref={buttonRef}
    square={square}
    isShowing={isShowing}
  >
    {cta}
  </ButtonStyled>
);

ButtonRound.propTypes = {
  bottom: PropTypes.bool,
  buttonRef: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  complete: PropTypes.bool,
  cta: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  large: PropTypes.bool,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  square: PropTypes.bool,
};

ButtonRound.defaultProps = {
  bottom: false,
  buttonRef: {},
  className: '',
  complete: false,
  fullWidth: false,
  icon: '',
  large: false,
  noBorder: false,
  primary: false,
  square: false,
};

export default ButtonRound;
