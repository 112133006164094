import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useTransition, animated, config } from 'react-spring';

import ForgotContainer from './components/presentational/ForgotContainer';
import optimizeImage from '../../shared/utils/imageOptimizer';
import useScript from '../../shared/hooks/useScript';

const ForgotPageContainer = styled('div')`
  display: flex;
  background: ${(props) => props.theme.colors.darkBlue};
  height: 100%!important;
  width: 100%;
  align-items: center;
  justify-content: center;
  a {
    align-self: center;
  }    
  .forgot-image-container {
    background-image: ${(props) => props.theme.colors.gradientOverlay}, url('https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_1.jpg');
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const ForgotWrapper = styled('div')`
  height: auto;
  display: flex;
  z-index: 1000;
`;

const slides = Array(12).fill(0);

slides.forEach((slide, index, slidesArray) => {
  const slidesArrayObject = slidesArray;

  slidesArrayObject[index] = {
    id: index,
    url: optimizeImage(
      `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_${index + 1}.jpg`,
      {
        resize: {
          width: 1500, height: 1500, fit: 'inside',
        },
      },
    ),
  };
});

const ForgotPassword = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const imageTransition = useTransition(slides[imageIndex], (item) => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useScript('https://cdn.ywxi.net/js/1.js');

  useEffect(() => (
    setInterval(() => (
      setImageIndex((state) => (state + 1) % slides.length)
    ), 6000)
  ), []);

  return (
    <ForgotPageContainer>
      <Helmet>
        <title>Forgot Password | Teambuildr</title>
      </Helmet>
      <ForgotWrapper>
        <ForgotContainer />
      </ForgotWrapper>
      {imageTransition.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className='forgot-image-container'
          style={{ ...props, backgroundImage: `linear-gradient(-20deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 154, 68, .5) 100%), url(${item.url})` }}
        />
      ))}
    </ForgotPageContainer>
  );
};

export default ForgotPassword;
