import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';
import WeightroomDatePicker from '../container/WeightroomDatePicker';

const WorkoutPanelHeader = styled('div')`
  display: block;
  padding: 20px 30px;
  @media screen and (orientation: portrait) {
    padding: 20px 20px;
  }
`;

const PanelHeaderWrapper = styled('div')`
  .panel-header-animation {
    overflow: hidden;
  }
`;

const AnimatedWorkoutHeader = ({
  namespace,
}) => {
  const isWorkoutActive = useSelector(state => state[namespace].isWorkoutActive);
  const isWorkoutComplete = useSelector(state => state[namespace].isWorkoutComplete);

  const props = useSpring({
    to: { height: isWorkoutActive || isWorkoutComplete ? 0 : 76 },
    from: { height: 76 },
  });

  return (
    <Fragment>
      <PanelHeaderWrapper>
        <animated.div className='panel-header-animation' style={props}>
          <WorkoutPanelHeader>
            <WeightroomDatePicker
              namespace={namespace}
            />
          </WorkoutPanelHeader>
        </animated.div>
      </PanelHeaderWrapper>
    </Fragment>
  );
};

AnimatedWorkoutHeader.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default AnimatedWorkoutHeader;
