import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import Text from '../Text/Text';

const FormLabel = ({ children, size }) => {
  const theme = useTheme();
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label size={size}>
      <Text
        fontSize={size}
      >
        {children}
      </Text>
    </label>
  );
};

FormLabel.propTypes = {
  size: PropTypes.number,
};

FormLabel.defaultProps = {
  size: 12,
};

export default FormLabel;
