import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Switch, Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import Layout from '../../../../../shared/components/Layout/Layout';
import Text from '../../../../../shared/components/Text/Text';
import ResellerSidebar from './ResellerSidebar';
import ProgramsGrid from '../programs/programsGrid';
import SubscribersGrid from '../subscribers/subscribersGrid';
import IntegrationsGrid from '../integrations/integrationsGrid';
import CouponsGrid from '../coupons/couponsGrid';
import GeneralSettingsGrid from '../generalSettings/generalSettingsGrid';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;

  .filter-button {
    position: absolute;
    bottom: 30px;
    align-self: center;
    display: none;
    @media screen and (max-width: 540px) {
      display: flex;
    }
  }
  @media screen and (max-width: 540px) {
    justify-content: center;
  }
`;

const AdminPanel = ({
  match,
  connectionComplete,
}) => (
  <Layout
    fullWidth
  >
    <PageWrapper>
      <Helmet>
        <title>Reseller | TeamBuildr</title>
      </Helmet>
      <ResellerSidebar />
      <Switch>
        <Route path={`${match.path}/programs`}>
          <ProgramsGrid
            connectionComplete={connectionComplete}
          />
        </Route>
        <Route path={`${match.path}/subscribers`}>
          <SubscribersGrid />
        </Route>
        <Route path={`${match.path}/integrations`}>
          <IntegrationsGrid />
        </Route>
        <Route path={`${match.path}/coupons`}>
          <CouponsGrid />
        </Route>
        <Route path={`${match.path}/settings`}>
          <GeneralSettingsGrid />
        </Route>
        <Route path={match.path}>
          <ProgramsGrid
            connectionComplete={connectionComplete}
          />
        </Route>
      </Switch>
    </PageWrapper>
  </Layout>
);

AdminPanel.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default AdminPanel;
