import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTransition, animated } from 'react-spring/web.cjs';
import IcomoonReact from 'icomoon-react';
import { componentWillAppendToBody } from 'react-append-to-body';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import ProgramMenuList from './ProgramMenuList';
import { ColorPrimary } from '../../../../shared/GlobalStyles';

const ProgramMenuContainer = styled('div')`
  display: block;
  overflow: hidden;
`;

const ProgramMenuLink = styled('a')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 1.4px;
  color: ${(props) => props.theme.textColor}!important;
  text-transform: Uppercase;
  cursor: pointer;
  display: block;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  svg {
    margin-left: 10px;
    rotate: 90deg;
    path {
      fill: ${(props) => props.theme.textColor};
    }
  }
`;

const ProgramMenuListWrapper = styled('div')`
  position: absolute;
  z-index: 10000;
  top: 0px;
  left: 0px;
  min-width: 300px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const AppendProgramMenuList = componentWillAppendToBody(ProgramMenuListWrapper);

const ProgramMenu = ({
  namespace,
}) => {
  const [activeProgramMenu, setActiveProgramMenu] = useState(false);

  const buttonRef = React.useRef();

  const handleActiveProgramMenu = () => {
    setActiveProgramMenu(activeProgramMenu === false);
  };

  const isProgramMenuActive = activeProgramMenu === true;

  const transition = useTransition(isProgramMenuActive, null, {
    from: { opacity: 0, position: 'absolute', zIndex: '50000' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <ProgramMenuContainer
      className='drowpdown-container'
    >
      <ProgramMenuLink
        onClick={handleActiveProgramMenu}
        className='dropdown-link'
        ref={buttonRef}
      >
        <IcomoonReact
          iconSet={iconSet}
          color={ColorPrimary}
          size={20}
          icon='dots'
        />
      </ProgramMenuLink>
      {transition.map(({ item, props }) => item && (
        <AppendProgramMenuList
          top={
            buttonRef.current && (
              buttonRef.current.getBoundingClientRect().bottom + 5
            )
          }
          left={buttonRef.current && (
            buttonRef.current.getBoundingClientRect().left - 220
          )}
        >
          <animated.div style={props}>
            <ProgramMenuList
              namespace={namespace}
              style={props}
              setActiveProgramMenu={setActiveProgramMenu}
            />
          </animated.div>
        </AppendProgramMenuList>
      ))}
    </ProgramMenuContainer>
  );
};

ProgramMenu.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default ProgramMenu;
