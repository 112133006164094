import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { createCellValue } from '../../ducks/evaluationsActions';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const RowValue = styled('div')`
  display: flex;
  justify-content: center;
  min-width: 220px;
  max-width: 220px;  height: 48px;
  box-shadow: ${BoxShadow};
  flex: ${(props) => props.flexValue};
  margin-left: 10px;
  border-radius: 12px;
  padding: 8px;
  background: #FFFFFF;

  @media (max-width: 768px) {
    min-width: 150px;
    max-width: 150px;

  }
`;

const TwoInputBox = styled('div')`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

const FirstTwoInput = styled('input')`
  width: 80%;
  background: #FFFFFF;
  padding-left: 10px;
  border: none;
`;

const SecondTwoInput = styled('input')`
  width: 80%;
  background: #FFFFFF;
  padding-left: 10px;
  border: none;
`;

const InputAndUnitBox = styled('div')`
  display: flex;
  width: 48%;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  font-size: 12px;
`;

const UnitBox = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-family: 'Nunito Sans';
  font-size: 12px;
  color: #444444;
`;

const DualInputComponent = ({
  item,
  valuesArray,
  evaluation,
  athleteIdx,
  inputIdx,
}) => {
  const [firstInput, setFirstInput] = useState('');
  const [secondInput, setSecondInput] = useState('');

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    setFirstInput(valuesArray[0][0].value);
    setSecondInput(valuesArray[0][1].value);
  }, [valuesArray]);

  return (
    <RowValue flexValue={2}>
      <TwoInputBox>
        <InputAndUnitBox>
          <FirstTwoInput
            value={firstInput}
            placeholder={`${item.unit1.toLowerCase()}`}
            onChange={(e) => setFirstInput(e.target.value)}
            onBlur={() => {
              const inputValue = valuesArray[0][0];
              let submissionObject;
              if (inputValue.id || inputValue.id === 0) {
                submissionObject = {
                  id: inputValue.id,
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: firstInput?.toString().trim(),
                  resultNumber: 1,
                };
              } else {
                submissionObject = {
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: firstInput?.toString().trim(),
                  resultNumber: 1,
                };
              }
              dispatch(createCellValue(
                currentUser.accountCode,
                submissionObject,
                evaluation.id,
                athleteIdx,
                inputIdx,
                0,
              )).then(({ response }) => {
                if (response && response?.status === 400) {
                  setFirstInput('');
                }
              });
            }}
          />
          {firstInput ? (
            <UnitBox>
              {item.unit1.toLowerCase() !== 'no unit' ? item.unit1.toLowerCase() : ''}
            </UnitBox>
          ) : null}
        </InputAndUnitBox>
        <InputAndUnitBox>
          <SecondTwoInput
            value={secondInput}
            placeholder={`${item.unit2.toLowerCase()}`}
            onChange={(e) => setSecondInput(e.target.value)}
            onBlur={() => {
              const inputValue = valuesArray[0][1];
              let submissionObject;
              if (inputValue.id || inputValue.id === 0) {
                submissionObject = {
                  id: inputValue.id,
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: secondInput?.toString().trim(),
                  resultNumber: 2,
                };
              } else {
                submissionObject = {
                  evalId: inputValue.evalId,
                  itemId: inputValue.itemId,
                  userId: inputValue.userId,
                  value: secondInput?.toString(),
                  resultNumber: 2,
                };
              }
              dispatch(createCellValue(
                currentUser.accountCode,
                submissionObject,
                evaluation.id,
                athleteIdx,
                inputIdx,
                1,
              )).then(({ response }) => {
                if (response && response?.status === 400) {
                  setSecondInput('');
                }
              });
            }}
          />
          {secondInput ? (
            <UnitBox>
              {item.unit2.toLowerCase() !== 'no unit' ? item.unit2.toLowerCase() : ''}
            </UnitBox>
          ) : null}
        </InputAndUnitBox>
      </TwoInputBox>
    </RowValue>

  );
};

DualInputComponent.defaultProps = {

};

DualInputComponent.propTypes = {
  valuesArray: PropTypes.instanceOf(Array).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  evaluation: PropTypes.instanceOf(Object).isRequired,
  athleteIdx: PropTypes.number.isRequired,
  inputIdx: PropTypes.number.isRequired,
};

export default DualInputComponent;
