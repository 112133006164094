import {
  FETCH_CONVERSATIONS_START,
  FETCH_CONVERSATIONS_FULFILLED,
  FETCH_CONVERSATIONS_REJECTED,
  FETCH_CONVERSATION_MESSAGES_START,
  FETCH_CONVERSATION_MESSAGES_FULFILLED,
  FETCH_CONVERSATION_MESSAGES_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  CREATE_NEW_MESSAGE_START,
  SET_INCOMING_ACTIVE_MESSAGE,
  CREATE_CONVERSATION_START,
  CREATE_CONVERSATION_FULFILLED,
  CREATE_CONVERSATION_REJECTED,
  FETCH_CONVERSATIONS_NEXT_PAGE_START,
  FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED,
  SET_IS_SCHEDULED_OPEN,
  SET_ACTIVE_CONVERSATION,
  CLEAR_ACTIVE_CONVERSATION,
  CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED,
  CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED,
  CREATE_INDIVIDUAL_CONVERSATIONS_START,
  SET_ACTIVE_REDUX_MODAL,
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_MORE_MESSAGES_START,
  FETCH_MORE_MESSAGES_FULFILLED,
  FETCH_MORE_MESSAGES_REJECTED,
  FETCH_MORE_MESSAGES_EMPTY,
  UPDATE_MOBILE_SIDEBAR,
} from './actionTypes';

const initialState = {
  isConversationsLoading: false,
  isMessagesLoading: false,
  isMoreMessagesLoading: false,
  isMoreMessagesEmpty: false,
  isMediaUploading: false,
  isInitialMessagesFetch: false,
  isNextPageLoading: false,
  isScheduledOpen: false,
  isMobileSidebarOpen: true,
  isConversationCreated: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONVERSATIONS_NEXT_PAGE_START: {
      return {
        ...state,
        isNextPageLoading: true,
      };
    }
    case FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED: {
      return {
        ...state,
        isNextPageLoading: false,
      };
    }
    case FETCH_CONVERSATIONS_START: {
      return {
        ...state,
        isConversationsLoading: true,
      };
    }
    case FETCH_CONVERSATIONS_FULFILLED: {
      return {
        ...state,
        isConversationsLoading: false,
      };
    }
    case FETCH_CONVERSATIONS_REJECTED: {
      return {
        ...state,
        isConversationsLoading: false,
      };
    }
    case FETCH_CONVERSATION_MESSAGES_START: {
      return {
        ...state,
        isMessagesLoading: true,
        isInitialMessagesFetch: true,
      };
    }
    case FETCH_CONVERSATION_MESSAGES_FULFILLED: {
      return {
        ...state,
        isMessagesLoading: false,
      };
    }
    case FETCH_CONVERSATION_MESSAGES_REJECTED: {
      return {
        ...state,
        isMessagesLoading: false,
      };
    }
    case FETCH_MORE_MESSAGES_START: {
      return {
        ...state,
        isMoreMessagesLoading: true,
        isInitialMessagesFetch: true,
      };
    }
    case FETCH_MORE_MESSAGES_FULFILLED: {
      return {
        ...state,
        isMoreMessagesLoading: false,
      };
    }
    case FETCH_MORE_MESSAGES_REJECTED: {
      return {
        ...state,
        isMoreMessagesLoading: false,
      };
    }
    case FETCH_MORE_MESSAGES_EMPTY: {
      return {
        ...state,
        isMoreMessagesLoading: false,
        isMoreMessagesEmpty: true,
      };
    }
    case UPLOAD_MEDIA_START: {
      return {
        ...state,
        isMediaUploading: true,
      };
    }
    case UPLOAD_MEDIA_FULFILLED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case UPLOAD_MEDIA_REJECTED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case CREATE_CONVERSATION_START: {
      return {
        ...state,
        isCreatingConversation: true,
      };
    }
    case CREATE_CONVERSATION_FULFILLED: {
      return {
        ...state,
        isCreatingConversation: false,
        isConversationCreated: true,
        isMobileSidebarOpen: false,
        isMoreMessagesEmpty: false,
      };
    }
    case CREATE_CONVERSATION_REJECTED: {
      return {
        ...state,
        isCreatingConversation: false,
      };
    }
    case CREATE_NEW_MESSAGE_START: {
      return {
        ...state,
        isInitialMessagesFetch: false,
      };
    }
    case SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        isScheduledOpen: false,
        isMobileSidebarOpen: false,
        isMoreMessagesEmpty: false,
      };
    }
    case UPDATE_MOBILE_SIDEBAR: {
      return {
        ...state,
        isMobileSidebarOpen: action.payload,
      };
    }
    case CLEAR_ACTIVE_CONVERSATION: {
      return {
        ...state,
        isScheduledOpen: false,
        isMobileSidebarOpen: true,
        isMoreMessagesEmpty: false,
      };
    }
    case SET_INCOMING_ACTIVE_MESSAGE: {
      return {
        ...state,
        isInitialMessagesFetch: false,
      };
    }
    case SET_IS_SCHEDULED_OPEN: {
      return {
        ...state,
        isScheduledOpen: !state.isScheduledOpen && true,
      };
    }
    case CREATE_INDIVIDUAL_CONVERSATIONS_START: {
      return {
        ...state,
        isIndividualConversationsCreating: true,
      };
    }
    case CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED: {
      return {
        ...state,
        isIndividualConversationsCreating: false,
      };
    }
    case CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED: {
      return {
        ...state,
        isIndividualConversationsCreating: false,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        isConversationCreated: false,
      };
    }
    case SUBMIT_SUPPORT_START: {
      return {
        ...state,
        isSupportSubmitting: true,
      };
    }
    case SUBMIT_SUPPORT_FULFILLED: {
      return {
        ...state,
        isSupportSubmitting: false,
      };
    }
    case SUBMIT_SUPPORT_REJECTED: {
      return {
        ...state,
        isSupportSubmitting: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
