/* eslint-disable max-len */
/* eslint-disable arrow-spacing */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import Shimmer from 'react-shimmer-effect';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import { updateCommunicationSettings } from '../../../../login/ducks/loginActions';

const CommunicationSettings = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const globalCommunicationSettings = useSelector((state) => state.auth.data.communicationSettings);
  const isCommunicationSettingsLoading = useSelector((state)=> state.auth.ui.isCommunicationSettingsLoading);

  const [communicationSettings, setCommunicationSettings] = useState({
    coachEmails: Boolean(globalCommunicationSettings?.coachEmails),
    feedPrPosts: Boolean(globalCommunicationSettings?.feedPrPosts),
    notificationsEnabled: Boolean(globalCommunicationSettings?.notificationsEnabled),
    textMessages: Boolean(globalCommunicationSettings?.textMessages),
    workoutEmails: Boolean(globalCommunicationSettings?.workoutEmails),
  });

  const debouncedSetCommunicationSettings = useDebouncedCallback(
    (newSettings) => {
      dispatch(updateCommunicationSettings(newSettings));
    },
    1000,
  );

  const handleSwitches = (field, v) => {
    setCommunicationSettings((prev) => ({
      ...prev,
      [field]: v,
    }));
    debouncedSetCommunicationSettings({
      ...communicationSettings,
      [field]: v,
    });
  };

  const CommunicationSettingsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-toggle .react-toggle-track {
    background-color: #E9E9EA;
  }
  .react-toggle--checked .react-toggle-track {
    background-color: ${theme.colors.green};
  }
  .react-toggle-thumb {
    border: none;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #E9E9EA;
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${theme.colors.green};
  }
`;

  const LoadingShimmerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  border-radius: 24px;
`;

  const CommunicationSettingsRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  cursor: pointer;
`;

  const HeaderContainer = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: #E0E0E0 1px solid;

`;

  return (
    <>
      {isCommunicationSettingsLoading ? (
        <Shimmer>
          <LoadingShimmerContainer />
        </Shimmer>
      ) : (
        <CommunicationSettingsWrapper>
          <HeaderContainer>
            <Text fontSize={containerWidth && containerWidth < 400 ? 24 : 28} fontWeight='700'>
              Communication Settings
            </Text>
            <Text fontSize={18} fontWeight='400'>Get emails or messages to keep you informed when you are not online.</Text>
          </HeaderContainer>
          <>
            <CommunicationSettingsRow onClick={() => {
              handleSwitches('workoutEmails', !communicationSettings.workoutEmails);
            }}
            >
              <Text fontSize={18} fontWeight='700'>
                Daily Workout Emails
              </Text>
              <Toggle
                checked={communicationSettings.workoutEmails}
                icons={false}
                onChange={() => {
                  handleSwitches('workoutEmails', !communicationSettings.workoutEmails);
                }}
              />
            </CommunicationSettingsRow>

            <CommunicationSettingsRow onClick={() => {
              handleSwitches('coachEmails', !communicationSettings.coachEmails);
            }}
            >
              <Text fontSize={18} fontWeight='700'>
                Coach Emails
              </Text>
              <Toggle
                checked={communicationSettings.coachEmails}
                icons={false}
                onChange={() => {
                  handleSwitches('coachEmails', !communicationSettings.coachEmails);
                }}
              />
            </CommunicationSettingsRow>

            <CommunicationSettingsRow onClick={() => {
              handleSwitches('textMessages', !communicationSettings.textMessages);
            }}
            >
              <Text fontSize={18} fontWeight='700'>
                Coach Text Messages
              </Text>
              <Toggle
                checked={communicationSettings.textMessages}
                icons={false}
                onChange={() => {
                  handleSwitches('textMessages', !communicationSettings.textMessages);
                }}
              />
            </CommunicationSettingsRow>

            <CommunicationSettingsRow onClick={() => {
              handleSwitches('feedPrPosts', !communicationSettings.feedPrPosts);
            }}
            >
              <Text fontSize={18} fontWeight='700'>
                Post PRs to Feed
              </Text>
              <Toggle
                checked={communicationSettings.feedPrPosts}
                icons={false}
                onChange={() => {
                  handleSwitches('feedPrPosts', !communicationSettings.feedPrPosts);
                }}
              />
            </CommunicationSettingsRow>
          </>
          {/* Removed since only applicable on mobile. */}
          {/* <CommunicationSettingsRow onClick={() => {
            handleSwitches('notificationsEnabled', !communicationSettings.notificationsEnabled);
          }}
          >
            <Text fontSize={18} fontWeight='700'>
              PUSH Notification
            </Text>
            <Toggle
              checked={communicationSettings.notificationsEnabled}
              icons={false}
              onChange={() => {
                handleSwitches('notificationsEnabled', !communicationSettings.notificationsEnabled);
              }}
            />
          </CommunicationSettingsRow> */}
        </CommunicationSettingsWrapper>
      )}
    </>
  );
};

CommunicationSettings.propTypes = {
  containerWidth: PropTypes.number,
};

CommunicationSettings.defaultProps = {
  containerWidth: '',
};

export default CommunicationSettings;
