import download from 'downloadjs';
import { batch } from 'react-redux';
import moment from 'moment';

import {
  SET_MODAL_OPEN,
  FETCH_PROGRAM_START,
  FETCH_PROGRAM_FULFILLED,
  FETCH_PROGRAM_REJECTED,
  FETCH_STRIPE_DETAILS_START,
  FETCH_STRIPE_DETAILS_FULFILLED,
  FETCH_STRIPE_DETAILS_REJECTED,
  SUBMIT_PAYMENT_START,
  SUBMIT_PAYMENT_FULFILLED,
  SUBMIT_PAYMENT_REJECTED,
  FETCH_WORKOUT_PREVIEW_START,
  FETCH_WORKOUT_PREVIEW_FULFILLED,
  FETCH_WORKOUT_PREVIEW_REJECTED,
  FETCH_MORE_PROGRAMS_START,
  FETCH_MORE_PROGRAMS_FULFILLED,
  FETCH_MORE_PROGRAMS_REJECTED,
  CLEAR_PAYMENT_DATA,
  FETCH_RESELLER_START,
  FETCH_RESELLER_FULFILLED,
  FETCH_RESELLER_REJECTED,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_PUBLIC_COUPON_START,
  FETCH_PUBLIC_COUPON_FULFILLED,
  FETCH_PUBLIC_COUPON_REJECTED,
  CLEAR_COUPON_ERROR,
  CLEAR_COUPON,
  SET_COUPON_ERROR,
  SET_PAYMENT_LOADING,
} from './actionTypes';

import { axiosAuthed, axiosUnauthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const setModalOpen = (isModalOpen, activeModal) => ({
  type: SET_MODAL_OPEN,
  payload: {
    isModalOpen,
    activeModal,
  },
});

export const fetchProgram = (resellerSlug, programSlug) => (
  (dispatch) => {
    // const usersQuery = 'users?userType=0';
    // const groupsQuery = 'groups?reporting=1';
    // const calendarsQuery = 'calendars?archived=0';
    // const isGroupsOrCalendars = selectionType === '2' ? groupsQuery : calendarsQuery;
    // const activeQuery = selectionType === '1' ? usersQuery : isGroupsOrCalendars;

    dispatch({ type: FETCH_PROGRAM_START });
    axiosUnauthed.get(`/resellers/${resellerSlug}/programs/${programSlug}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_PROGRAM_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PROGRAM_REJECTED,
        });
      });
  }
);

export const fetchWorkoutPreview = (resellerSlug, programSlug) => (
  (dispatch) => {
    dispatch({ type: FETCH_WORKOUT_PREVIEW_START });
    axiosUnauthed.get(`/resellers/${resellerSlug}/programs/${programSlug}/workout-preview`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_WORKOUT_PREVIEW_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_WORKOUT_PREVIEW_REJECTED,
        });
      });
  }
);

export const fetchMorePrograms = (resellerSlug) => (
  (dispatch) => {
    dispatch({ type: FETCH_MORE_PROGRAMS_START });
    axiosUnauthed.get(`/resellers/${resellerSlug}/programs`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_MORE_PROGRAMS_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MORE_PROGRAMS_REJECTED,
        });
      });
  }
);

export const fetchStripeDetails = () => (
  (dispatch) => {
    dispatch({ type: FETCH_STRIPE_DETAILS_START });
    axiosAuthed.get('/me/stripe')
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_STRIPE_DETAILS_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_STRIPE_DETAILS_REJECTED,
        });
      });
  }
);

export const submitSubscriptionPayment = (accountCode, values) => (
  (dispatch, getState) => {
    dispatch({ type: SUBMIT_PAYMENT_START });
    axiosAuthed.post(`/me/accounts/${accountCode}/subscriptions`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: SUBMIT_PAYMENT_FULFILLED,
            payload: response.data,
          });
        }, 800);

        const { redirectUrl } = getState().programs.data.programData;
        const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
        const domain = process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}app.teambuildr.com`;
        const defaultRedirectUrl = `${domain}/workouts`;

        // Relocate to redirectUrl if redirectUrl is not empty.
        window.location = redirectUrl || defaultRedirectUrl;
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_PAYMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const clearPaymentData = () => ({
  type: CLEAR_PAYMENT_DATA,
});

export const fetchReseller = (resellerSlug) => (
  (dispatch) => {
    dispatch({ type: FETCH_RESELLER_START });
    axiosUnauthed.get(`/resellers/${resellerSlug}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_RESELLER_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_RESELLER_REJECTED,
        });
      });
  }
);

export const fetchCouponDetails = (accountSlug, couponId) => (
  (dispatch) => {
    dispatch({ type: FETCH_PUBLIC_COUPON_START });
    axiosUnauthed.get(`/resellers/${accountSlug}/coupons/${couponId}`)
      .then((response) => {
        dispatch({
          type: FETCH_PUBLIC_COUPON_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        let errorMessage = err.response.headers.message;
        if (errorMessage.includes('Error: ')) {
          errorMessage = errorMessage.split('Error: ')[0];
        }
        dispatch({
          type: FETCH_PUBLIC_COUPON_REJECTED,
          payload: errorMessage || 'Couldn\'t find coupon with the above code',
        });
      });
  }
);

export const clearCouponError = () => (
  (dispatch) => {
    dispatch({ type: CLEAR_COUPON_ERROR });
  }
);

export const setCouponError = (err) => (
  (dispatch) => {
    dispatch({ type: SET_COUPON_ERROR, payload: err });
  }
);
export const clearCoupon = () => (
  (dispatch) => {
    dispatch({ type: CLEAR_COUPON });
  }
);

export const setPaymentLoading = (value) => ({
  type: SET_PAYMENT_LOADING,
  payload: value,
});
