import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ModalContentWrapper } from './ModalNote';
import DateStacked from './DateStacked';
import Ribbon from './Ribbon';
import Title from './Title';
import Table from './Table';
import Spinner from './Spinner';

const Info = styled('span')`
  display: inline-flex;
  width: 140px;
  justify-content: center;
  align-self: center;
  color: ${props => props.theme.textColor};
`;

const HistoryRow = styled('div')`
  display: flex;
  margin-bottom: 30px;

  .date-stacked {
    margin-left: 20px;
    align-self: center;
  }
  
`;

const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class ModalHistory extends PureComponent {
  render() {
    const {
      name,
      isLoadingHistory,
      workoutHistory,
    } = this.props;

    const isWorkoutHistoryEmpty = workoutHistory.result
      && (workoutHistory.result.history.length === 0);

    return (
      <ModalContentWrapper>
        <Title
          icon='clock'
        >
          {name}
        </Title>
        {
          !isLoadingHistory ? (
            isWorkoutHistoryEmpty || workoutHistory.response ? (
              <p className='text-center'>No History Available</p>
            ) : (
              workoutHistory.result.history.map(date => (
                <HistoryRow>
                  <Ribbon
                    date={date.date.substring(0, 8)}
                  />
                  <DateStacked date={date.date.substring(0, 8)} />
                  <Info>
                    {date.info}
                  </Info>
                  <TableContainer>
                    <Table 
                      data={date.data} 
                      isNoteAdded={date.note_added}
                      noteDetail={date.note_detail}
                      isOptedOut={date.opt_out}
                      optOutReason={date.opt_out_reason}
                    />
                  </TableContainer>
                </HistoryRow>
              ))
            )
          ) : (
            <Spinner />
          )
        }
      </ModalContentWrapper>
    );
  }
}

ModalHistory.propTypes = {
  isLoadingHistory: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  workoutHistory: PropTypes.instanceOf(Object).isRequired,
};

export default ModalHistory;
