import {
  SUBMIT_LOGIN_START,
  SUBMIT_LOGIN_FULFILLED,
  SUBMIT_LOGIN_REJECTED,
  SUBMIT_LOGOUT_FULFILLED,
  SUBMIT_GOOGLE_START,
  SUBMIT_GOOGLE_FULFILLED,
  SUBMIT_GOOGLE_REJECTED,
  FETCH_USER_START,
  FETCH_USER_FULFILLED,
  SUBMIT_REGISTRATION_START,
  SUBMIT_REGISTRATION_FULFILLED,
  SUBMIT_REGISTRATION_REJECTED,
  SUBMIT_REFRESH_START,
  SUBMIT_REFRESH_FULFILLED,
  SUBMIT_REFRESH_REJECTED,
  CHECK_SSO_START,
  CHECK_SSO_FULFILLED,
  CHECK_SSO_REJECTED,
  LOGIN_WITH_SSO_TOKEN_START,
  LOGIN_WITH_SSO_TOKEN_REJECTED,
  LOGIN_WITH_SSO_TOKEN_FULFILLED,
  UPDATE_CURRENT_USER_PROFILE_START,
  UPDATE_CURRENT_USER_PROFILE_FULFILLED,
  UPDATE_CURRENT_USER_PROFILE_REJECTED,
  UPDATE_PROFILE_IMAGE_START,
  UPDATE_PROFILE_IMAGE_FULFILLED,
  UPDATE_PROFILE_IMAGE_REJECTED,
  UPDATE_LOGO_IMAGE_START,
  UPDATE_LOGO_IMAGE_FULFILLED,
  UPDATE_LOGO_IMAGE_REJECTED,
  UPDATE_BACKGROUND_IMAGE_START,
  UPDATE_BACKGROUND_IMAGE_FULFILLED,
  UPDATE_BACKGROUND_IMAGE_REJECTED,
  FETCH_ORGANIZATION_SETTINGS_START,
  FETCH_ORGANIZATION_SETTINGS_FULFILLED,
  FETCH_ORGANIZATION_SETTINGS_REJECTED,
  UPDATE_ORGANIZATION_SETTINGS_START,
  UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  UPDATE_ORGANIZATION_SETTINGS_REJECTED,
  FETCH_NOTIFICATION_SETTINGS_START,
  FETCH_NOTIFICATION_SETTINGS_FULFILLED,
  FETCH_NOTIFICATION_SETTINGS_REJECTED,
  UPDATE_NOTIFICATION_SETTINGS_START,
  UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
  UPDATE_NOTIFICATION_SETTINGS_REJECTED,
  FETCH_NOTIFICATION_TYPES_START,
  FETCH_NOTIFICATION_TYPES_FULFILLED,
  FETCH_NOTIFICATION_TYPES_REJECTED,
  GET_CUSTOM_LOGIN_DATA_START,
  GET_CUSTOM_LOGIN_DATA_FULFILLED,
  GET_CUSTOM_LOGIN_DATA_REJECTED,
  SET_MAINTENANCE_MODE_ALERT,
} from './actionTypes';

const initialState = {
  isAuthenticated: false,
  isCustomLoginDataLoading: false,
  isLoginLoading: false,
  isRegistrationLoading: false,
  isRegisteredInClient: false,
  loginError: null,
  googleError: null,
  imageError: null,
  isSubmittingRefresh: false,
  isRefreshRejected: false,
  isSSOCheckLoading: false,
  isSSOLoginLoading: false,
  ssoLoginError: false,
  isProfileUpdateLoading: false,
  isProfileImageUpdateLoading: false,
  isLogoImageUpdating: false,
  isBackgroundImageUpdating: false,
  isOrganizationSettingsLoading: false,
  isUpdateOrganizationSettigsLoading: false,
  isNotificationSettingsLoading: false,
  isUpdateNotificationSettingsLoading: false,
  isNotificationTypesLoading: false,
  showMaintenanceModeAlert: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOM_LOGIN_DATA_START: {
      return {
        ...state,
        isCustomLoginDataLoading: true,
      };
    }
    case GET_CUSTOM_LOGIN_DATA_FULFILLED: {
      return {
        ...state,
        isCustomLoginDataLoading: false,
      };
    }
    case GET_CUSTOM_LOGIN_DATA_REJECTED: {
      return {
        ...state,
        isCustomLoginDataLoading: false,
      };
    }
    case SUBMIT_LOGIN_START: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case SUBMIT_LOGIN_FULFILLED: {
      return {
        ...state,
        loginError: null,
      };
    }
    case SUBMIT_LOGIN_REJECTED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
        loginError: action.payload,
      };
    }
    case SUBMIT_GOOGLE_START: {
      return {
        ...state,
        isLoginLoading: true,
      };
    }
    case SUBMIT_GOOGLE_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: true,
        googleError: null,
      };
    }
    case SUBMIT_GOOGLE_REJECTED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
        googleError: action.payload,
      };
    }
    case FETCH_USER_START: {
      return {
        ...state,
        isLoginLoading: true,
        isAuthenticated: false,
      };
    }
    case FETCH_USER_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: true,
        imageError: null,
      };
    }
    case SUBMIT_LOGOUT_FULFILLED: {
      return {
        ...state,
        isLoginLoading: false,
        isAuthenticated: false,
        isRegisteredInClient: false,
      };
    }
    case SUBMIT_REGISTRATION_START: {
      return {
        ...state,
        isRegistrationLoading: true,
      };
    }
    case SUBMIT_REGISTRATION_FULFILLED: {
      return {
        ...state,
        isRegistrationLoading: false,
        isRegisteredInClient: true,
      };
    }
    case SUBMIT_REGISTRATION_REJECTED: {
      return {
        ...state,
        isRegistrationLoading: false,
        isRegisteredInClient: false,
      };
    }
    case SUBMIT_REFRESH_START: {
      return {
        ...state,
        isSubmittingRefresh: true,
        isRefreshRejected: false,
      };
    }
    case SUBMIT_REFRESH_FULFILLED: {
      return {
        ...state,
        isSubmittingRefresh: false,
        isRefreshRejected: false,
      };
    }
    case SUBMIT_REFRESH_REJECTED: {
      return {
        ...state,
        isSubmittingRefresh: false,
        isRefreshRejected: true,
      };
    }
    case CHECK_SSO_START: {
      return {
        ...state,
        isSSOCheckLoading: true,
      };
    }
    case CHECK_SSO_FULFILLED: {
      return {
        ...state,
        isSSOCheckLoading: false,
      };
    }
    case CHECK_SSO_REJECTED: {
      return {
        ...state,
        isSSOCheckLoading: false,
      };
    }
    case LOGIN_WITH_SSO_TOKEN_START: {
      return {
        ...state,
        isSSOLoginLoading: true,
        ssoLoginError: false,
      };
    }
    case LOGIN_WITH_SSO_TOKEN_FULFILLED: {
      return {
        ...state,
        isSSOLoginLoading: false,
        ssoLoginError: false,
      };
    }
    case LOGIN_WITH_SSO_TOKEN_REJECTED: {
      return {
        ...state,
        isSSOLoginLoading: false,
        ssoLoginError: action.payload,
      };
    }
    case UPDATE_CURRENT_USER_PROFILE_START: {
      return {
        ...state,
        isProfileUpdateLoading: true,
      };
    }
    case UPDATE_CURRENT_USER_PROFILE_FULFILLED: {
      return {
        ...state,
        isProfileUpdateLoading: false,
      };
    }
    case UPDATE_CURRENT_USER_PROFILE_REJECTED: {
      return {
        ...state,
        isProfileUpdateLoading: false,
      };
    }
    case UPDATE_PROFILE_IMAGE_START: {
      return {
        ...state,
        isProfileImageUpdateLoading: true,
      };
    }
    case UPDATE_PROFILE_IMAGE_FULFILLED: {
      return {
        ...state,
        isProfileImageUpdateLoading: false,
        imageError: null,
      };
    }
    case UPDATE_PROFILE_IMAGE_REJECTED: {
      return {
        ...state,
        isProfileImageUpdateLoading: false,
        imageError: action.payload,
      };
    }
    case UPDATE_LOGO_IMAGE_START: {
      return {
        ...state,
        isLogoImageUpdating: true,
      };
    }
    case UPDATE_LOGO_IMAGE_FULFILLED: {
      return {
        ...state,
        isLogoImageUpdating: false,
        imageError: null,
      };
    }
    case UPDATE_LOGO_IMAGE_REJECTED: {
      return {
        ...state,
        isLogoImageUpdating: false,
        imageError: action.payload,
      };
    }
    case UPDATE_BACKGROUND_IMAGE_START: {
      return {
        ...state,
        isBackgroundImageUpdating: true,
      };
    }
    case UPDATE_BACKGROUND_IMAGE_FULFILLED: {
      return {
        ...state,
        isBackgroundImageUpdating: false,
        imageError: null,
      };
    }
    case UPDATE_BACKGROUND_IMAGE_REJECTED: {
      return {
        ...state,
        isBackgroundImageUpdating: false,
        imageError: action.payload ?? 'Error uploading image',
      };
    }
    case FETCH_ORGANIZATION_SETTINGS_START: {
      return {
        ...state,
        isOrganizationSettingsLoading: true,
      };
    }
    case FETCH_ORGANIZATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        isOrganizationSettingsLoading: false,
        imageError: null,
      };
    }
    case FETCH_ORGANIZATION_SETTINGS_REJECTED: {
      return {
        ...state,
        isOrganizationSettingsLoading: false,
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS_START: {
      return {
        ...state,
        isUpdateOrganizationSettingsLoading: true,
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        isUpdateOrganizationSettingsLoading: false,
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS_REJECTED: {
      return {
        ...state,
        isUpdateOrganizationSettingsLoading: false,
      };
    }
    case FETCH_NOTIFICATION_SETTINGS_START: {
      return {
        ...state,
        isNotificationSettingsLoading: true,
      };
    }
    case FETCH_NOTIFICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        isNotificationSettingsLoading: false,
      };
    }
    case FETCH_NOTIFICATION_SETTINGS_REJECTED: {
      return {
        ...state,
        isNotificationSettingsLoading: false,
      };
    }
    case UPDATE_NOTIFICATION_SETTINGS_START: {
      return {
        ...state,
        isUpdateNotificationSettingsLoading: true,
      };
    }
    case UPDATE_NOTIFICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        isUpdateNotificationSettingsLoading: false,
      };
    }
    case UPDATE_NOTIFICATION_SETTINGS_REJECTED: {
      return {
        ...state,
        isUpdateNotificationSettingsLoading: false,
      };
    }
    case FETCH_NOTIFICATION_TYPES_START: {
      return {
        ...state,
        isNotificationTypesLoading: true,
      };
    }
    case FETCH_NOTIFICATION_TYPES_FULFILLED: {
      return {
        ...state,
        isNotificationTypesLoading: false,
      };
    }
    case FETCH_NOTIFICATION_TYPES_REJECTED: {
      return {
        ...state,
        isNotificationTypesLoading: false,
      };
    }
    case SET_MAINTENANCE_MODE_ALERT: {
      return {
        ...state,
        showMaintenanceModeAlert: true,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
