export const SUBMIT_LOGIN_START = 'SUBMIT_LOGIN_START';
export const SUBMIT_LOGIN_FULFILLED = 'SUBMIT_LOGIN_FULFILLED';
export const SUBMIT_LOGIN_REJECTED = 'SUBMIT_LOGIN_REJECTED';

export const SUBMIT_LOGOUT_START = 'SUBMIT_LOGOUT_START';
export const SUBMIT_LOGOUT_FULFILLED = 'SUBMIT_LOGOUT_FULFILLED';
export const SUBMIT_LOGOUT_REJECTED = 'SUBMIT_LOGOUT_REJECTED';

export const SUBMIT_REGISTRATION_START = 'SUBMIT_REGISTRATION_START';
export const SUBMIT_REGISTRATION_FULFILLED = 'SUBMIT_REGISTRATION_FULFILLED';
export const SUBMIT_REGISTRATION_REJECTED = 'SUBMIT_REGISTRATION_REJECTED';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_FULFILLED = 'FETCH_USER_FULFILLED';
export const FETCH_USER_REJECTED = 'FETCH_USER_REJECTED';

export const FETCH_USER_DATA_START = 'FETCH_USER_DATA_START';
export const FETCH_USER_DATA_FULFILLED = 'FETCH_USER_DATA_FULFILLED';
export const FETCH_USER_DATA_REJECTED = 'FETCH_USER_DATA_REJECTED';

export const UPDATE_CURRENT_USER_PROFILE_START = 'UPDATE_CURRENT_USER_PROFILE_START';
export const UPDATE_CURRENT_USER_PROFILE_FULFILLED = 'UPDATE_CURRENT_USER_PROFILE_FULFILLED';
export const UPDATE_CURRENT_USER_PROFILE_REJECTED = 'UPDATE_CURRENT_USER_PROFILE_REJECTED';

export const FETCH_COMMUNICATION_SETTINGS_START = 'FETCH_COMMUNICATION_SETTINGS_START';
export const FETCH_COMMUNICATION_SETTINGS_FULFILLED = 'FETCH_COMMUNICATION_SETTINGS_FULFILLED';
export const FETCH_COMMUNICATION_SETTINGS_REJECTED = 'FETCH_COMMUNICATION_SETTINGS_REJECTED';

export const UPDATE_COMMUNICATION_SETTINGS_START = 'UPDATE_COMMUNICATION_SETTINGS_START';
export const UPDATE_COMMUNICATION_SETTINGS_FULFILLED = 'UPDATE_COMMUNICATION_SETTINGS_FULFILLED';
export const UPDATE_COMMUNICATION_SETTINGS_REJECTED = 'UPDATE_COMMUNICATION_SETTINGS_REJECTED';

export const FETCH_NOTIFICATION_SETTINGS_START = 'FETCH_NOTIFICATION_SETTINGS_START';
export const FETCH_NOTIFICATION_SETTINGS_FULFILLED = 'FETCH_NOTIFICATION_SETTINGS_FULFILLED';
export const FETCH_NOTIFICATION_SETTINGS_REJECTED = 'FETCH_NOTIFICATION_SETTINGS_REJECTED';

export const FETCH_NOTIFICATION_TYPES_START = 'FETCH_NOTIFICATION_TYPES_START';
export const FETCH_NOTIFICATION_TYPES_FULFILLED = 'FETCH_NOTIFICATION_TYPES_FULFILLED';
export const FETCH_NOTIFICATION_TYPES_REJECTED = 'FETCH_NOTIFICATION_TYPES_REJECTED';

export const UPDATE_NOTIFICATION_SETTINGS_START = 'UPDATE_NOTIFICATION_SETTINGS_START';
export const UPDATE_NOTIFICATION_SETTINGS_FULFILLED = 'UPDATE_NOTIFICATION_SETTINGS_FULFILLED';
export const UPDATE_NOTIFICATION_SETTINGS_REJECTED = 'UPDATE_NOTIFICATION_SETTINGS_REJECTED';

export const FETCH_ORGANIZATION_SETTINGS_START = 'FETCH_ORGANIZATION_SETTINGS_START';
export const FETCH_ORGANIZATION_SETTINGS_FULFILLED = 'FETCH_ORGANIZATION_SETTINGS_FULFILLED';
export const FETCH_ORGANIZATION_SETTINGS_REJECTED = 'FETCH_ORGANIZATION_SETTINGS_REJECTED';

export const UPDATE_ORGANIZATION_SETTINGS_START = 'UPDATE_ORGANIZATION_SETTINGS_START';
export const UPDATE_ORGANIZATION_SETTINGS_FULFILLED = 'UPDATE_ORGANIZATION_SETTINGS_FULFILLED';
export const UPDATE_ORGANIZATION_SETTINGS_REJECTED = 'UPDATE_ORGANIZATION_SETTINGS_REJECTED';

export const UPDATE_PROFILE_IMAGE_START = 'UPDATE_PROFILE_IMAGE_START';
export const UPDATE_PROFILE_IMAGE_FULFILLED = 'UPDATE_PROFILE_IMAGE_FULFILLED';
export const UPDATE_PROFILE_IMAGE_REJECTED = 'UPDATE_PROFILE_IMAGE_REJECTED';

export const REMOVE_PROFILE_IMAGE_START = 'REMOVE_PROFILE_IMAGE_START';
export const REMOVE_PROFILE_IMAGE_FULFILLED = 'REMOVE_PROFILE_IMAGE_FULFILLED';
export const REMOVE_PROFILE_IMAGE_REJECTED = 'REMOVE_PROFILE_IMAGE_REJECTED';

export const UPDATE_LOGO_IMAGE_START = 'UPDATE_LOGO_IMAGE_START';
export const UPDATE_LOGO_IMAGE_FULFILLED = 'UPDATE_LOGO_IMAGE_FULFILLED';
export const UPDATE_LOGO_IMAGE_REJECTED = 'UPDATE_LOGO_IMAGE_REJECTED';

export const UPDATE_BACKGROUND_IMAGE_START = 'UPDATE_BACKGROUND_IMAGE_START';
export const UPDATE_BACKGROUND_IMAGE_FULFILLED = 'UPDATE_BACKGROUND_IMAGE_FULFILLED';
export const UPDATE_BACKGROUND_IMAGE_REJECTED = 'UPDATE_BACKGROUND_IMAGE_REJECTED';

export const FETCH_V2USER_START = 'FETCH_V2USER_START';
export const FETCH_V2USER_FULFILLED = 'FETCH_V2USER_FULFILLED';
export const FETCH_V2USER_REJECTED = 'FETCH_V2USER_REJECTED';

export const SUBMIT_REFRESH_START = 'SUBMIT_REFRESH_START';
export const SUBMIT_REFRESH_FULFILLED = 'SUBMIT_REFRESH_FULFILLED';
export const SUBMIT_REFRESH_REJECTED = 'SUBMIT_REFRESH_REJECTED';

export const SUBMIT_GOOGLE_START = 'SUBMIT_GOOGLE_START';
export const SUBMIT_GOOGLE_FULFILLED = 'SUBMIT_GOOGLE_FULFILLED';
export const SUBMIT_GOOGLE_REJECTED = 'SUBMIT_GOOGLE_REJECTED';

export const SUBMIT_V2EXCHANGE_START = 'SUBMIT_V2EXCHANGE_START';
export const SUBMIT_V2EXCHANGE_FULFILLED = 'SUBMIT_V2EXCHANGE_FULFILLED';
export const SUBMIT_V2EXCHANGE_REJECTED = 'SUBMIT_V2EXCHANGE_REJECTED';

export const CLEAR_USER = 'CLEAR_USER';

export const CHECK_SSO_START = 'CHECK_SSO_START';
export const CHECK_SSO_FULFILLED = 'CHECK_SSO_FULFILLED';
export const CHECK_SSO_REJECTED = 'CHECK_SSO_REJECTED';

export const LOGIN_WITH_SSO_TOKEN_START = 'LOGIN_WITH_SSO_TOKEN_START';
export const LOGIN_WITH_SSO_TOKEN_FULFILLED = 'LOGIN_WITH_SSO_TOKEN_FULFILLED';
export const LOGIN_WITH_SSO_TOKEN_REJECTED = 'LOGIN_WITH_SSO_TOKEN_REJECTED';

export const GET_CUSTOM_LOGIN_DATA_START = 'GET_CUSTOM_LOGIN_DATA_START';
export const GET_CUSTOM_LOGIN_DATA_FULFILLED = 'GET_CUSTOM_LOGIN_DATA_FULFILLED';
export const GET_CUSTOM_LOGIN_DATA_REJECTED = 'GET_CUSTOM_LOGIN_DATA_REJECTED';

export const SET_MAINTENANCE_MODE_ALERT = 'SET_MAINTENANCE_MODE_ALERT';
