/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import WorkoutHistoryModal from '../../../../shared/components/WorkoutHistoryModal/WorkoutHistoryModal';

import {
  setHistoryWorkout,
  fetchWorkoutHistory,
  fetchMoreWorkoutHistory,
  setActiveWorkoutsMedia,
} from '../../ducks/workoutsActions';

const WorkoutHistoryOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const WorkoutHistoryModalContainer = () => {
  const historyWorkout = useSelector(
    (state) => state.workouts.data.historyWorkout,
  );
  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isEndOfWorkoutHistoryList = useSelector(
    (state) => state.workouts.data.isEndOfWorkoutHistoryList,
  );
  const isWorkoutHistoryLoading = useSelector(
    (state) => state.workouts.ui.isWorkoutHistoryLoading,
  );
  const historyData = useSelector(
    (state) => state.workouts.data.historyData,
  );
  const historyPage = useSelector(
    (state) => state.workouts.data.historyPage,
  );

  const dispatch = useDispatch();

  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  const useEffectFunction = () => {
    if (Object.keys(historyWorkout).length && historyWorkout.exercise) {
      dispatch(fetchWorkoutHistory(historyWorkout.exercise.id, currentUser, userId));
    }
  };

  const setIsModalActiveFunction = () => {
    dispatch(setHistoryWorkout({}));
  };

  const mediaDispatchFunction = (mediaOb) => {
    dispatch(setActiveWorkoutsMedia(mediaOb));
  };

  const fetchMoreWorkoutHistoryFunction = () => {
    dispatch(fetchMoreWorkoutHistory(historyWorkout.exercise.id, currentUser, userId, historyPage));
  };

  return (
    <>
      <WorkoutHistoryOverlay
        isActive={Object.keys(historyWorkout).length}
        onClick={() => dispatch(setHistoryWorkout({}))}
      />
      <WorkoutHistoryModal
        closeModalFunction={setIsModalActiveFunction}
        useEffectFunction={useEffectFunction}
        modalTitle={historyWorkout?.exercise?.name}
        isHistoryFetching={isWorkoutHistoryLoading}
        reduxHistoryData={historyData}
        isModalActive={Object.keys(historyWorkout).length}
        mediaDispatchFunction={mediaDispatchFunction}
        fetchMoreHistoryFunction={fetchMoreWorkoutHistoryFunction}
        isEndOfWorkoutHistoryList={isEndOfWorkoutHistoryList}
      />
    </>
  );
};

WorkoutHistoryModalContainer.propTypes = { };

export default WorkoutHistoryModalContainer;
