/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { setIsPrintingOptionsShowing } from '../../ducks/workoutsActions';
import PrintingOptionsModal from './PrintingOptionsModal';

const PrintingOptionsModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const PrintingOptionsModalContainer = () => {
  const isPrintingOptionsShowing = useSelector(
    (state) => state.workouts.data.isPrintingOptionsShowing,
  );

  const dispatch = useDispatch();

  return (
    <>
      <PrintingOptionsModal />
      <PrintingOptionsModalContainerOverlay
        isActive={isPrintingOptionsShowing}
        onClick={
          () => dispatch(setIsPrintingOptionsShowing(false))
          }
      />
    </>
  );
};

PrintingOptionsModalContainer.propTypes = { };

export default PrintingOptionsModalContainer;
