import React, { Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import GoogleLogin from 'react-google-login';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';
import { Global, css } from '@emotion/core';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { setModalOpen } from '../../ducks/programsActions';
import Button from '../../../../shared/components/Button/Button';
import Checkmark from '../../../../shared/components/Checkmark/Checkmark';
import Text from '../../../../shared/components/Text/Text';
import Link from '../../../../shared/components/Link/Link';
import GoogleIcon from '../../../../shared/images/google_icon.svg';
import encode from '../../../../shared/utils/encodeToUrl';
import useLogin from '../../../login/components/hooks/useLogin';
import Spinner from '../../../../shared/components/Spinner/Spinner';

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .checkbox {
    margin-top: 2px;
    margin-right: 10px;
  }

  .spinner-container {
    min-height: 60px;
  }
  
  z-index: 1;

  &.d-flex {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  margin-left: -54px;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
  }
`;

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  :first-of-type {
    margin-right: 30px;
  }
`;

const LoginContainer = styled('div')`
  position: relative;
`;

const LoginSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
  password: Yup.string()
    .required('Password Required'),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  agreementTerms: Yup.mixed().oneOf([true], 'Field must be checked'),
});

const ModalCreateAccount = ({
  programSlug,
  resellerSlug,
}) => {
  const {
    handleGoogleAuth,
    handleRegistration,
    handleLogin,
    isLoginLoading,
    isAuthenticated,
    loginError,
    googleError,
  } = useLogin();

  const registrationError = useSelector(state => state.auth.data.registrationError);
  const isRegistrationLoading = useSelector(state => state.auth.ui.isRegistrationLoading);

  const accountCode = 1;

  const formAnimation = useSpring({
    opacity: isLoginLoading || isAuthenticated ? 0 : 1,
    zIndex: 1,
    position: 'relative',
  });

  const spinnerAnimation = useSpring({ opacity: isLoginLoading ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isAuthenticated ? 1 : 0 });

  const theme = useTheme();

  // const handleGoogleResponse = (response) => {
  //   const values = {
  //     grant_type: 'google',
  //     client_id: process.env.CLIENT_ID,
  //     client_secret: process.env.CLIENT_SECRET,
  //     tokenid: response.tokenId,
  //     scope: 'offline_access',
  //   };

  //   handleGoogleAuth(encode(values));
  // };

  return (
    <Formik
      initialValues={{
        accountCode: 1,
        firstName: '',
        lastName: '',
        emailAddress: '',
        password: '',
        confirmedPassword: '',
        mobileNumber: '',
        authenticationMethod: 'PASSWORD',
        registrationType: 'EXISTING_ORGANIZATION',
        resellerSlug,
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        scope: 'offline_access',
        agreementTerms: false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleRegistration(encode(values));
          // alert(JSON.stringify(encode(values), null, 2));
          setSubmitting(false);
        });
      }}
      validationSchema={LoginSchema}
      render={({
        errors,
        touched,
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <LoginContainer>
          <Global
            styles={css`
              .ReactModal__Content {
                width: 420px!important;
              }
            `}
          />
          <Fragment>
            <animated.div style={formAnimation}>
              <Form
                name='login'
                id='login'
              >
                <FormGroup className='form-group'>
                  <FormColumns>
                    <ColumnContent>
                      <label>
                        <Text
                          fontSize={theme.textFontSizes[0]}
                        >
                          First
                        </Text>
                        <Field
                          className='form-control'
                          id='firstName'
                          type='text'
                          name='firstName'
                          placeholder='First Name'
                        />
                      </label>
                      {/* {errors.username && touched.username && <FieldError className='text-center'>{errors.username}</FieldError>} */}
                    </ColumnContent>
                    <ColumnContent>
                      <label>
                        <Text
                          fontSize={theme.textFontSizes[0]}
                        >
                          Last
                        </Text>
                        <Field
                          className='form-control'
                          id='lastName'
                          type='text'
                          name='lastName'
                          placeholder='Last Name'
                        />
                      </label>
                      {/* {errors.username && touched.username && <FieldError className='text-center'>{errors.username}</FieldError>} */}
                    </ColumnContent>
                  </FormColumns>
                </FormGroup>
                <FormGroup className='form-group'>
                  <label>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      Email Address
                    </Text>
                    <Field
                      className='form-control'
                      id='email-field'
                      type='email'
                      name='emailAddress'
                      placeholder='Email'
                    />
                  </label>
                  {errors.emailAddress && touched.emailAddress && <FieldError className='text-center'>{errors.emailAddress}</FieldError>}
                </FormGroup>
                <FormGroup className='form-group'>
                  <label>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      Password
                    </Text>
                    <Field
                      className='form-control'
                      type='password'
                      name='password'
                      placeholder='Password'
                    />
                  </label>
                  {errors.password && touched.password && <FieldError className='text-center'>{errors.password}</FieldError>}
                </FormGroup>
                <FormGroup className='form-group'>
                  <label>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      Re-enter Password
                    </Text>
                    <Field
                      className='form-control'
                      type='password'
                      name='confirmedPassword'
                      placeholder='Password'
                    />
                  </label>
                  {errors.confirmedPassword && touched.confirmedPassword && <FieldError className='text-center'>{errors.confirmedPassword}</FieldError>}
                </FormGroup>
                <FormGroup className='form-group'>
                  <label>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      Phone
                    </Text>
                    <Field
                      className='form-control'
                      id='email-field'
                      type='phone'
                      name='mobileNumber'
                      placeholder='Phone Number'
                    />
                  </label>
                  {registrationError && <FieldError className='text-center'>{registrationError.message}</FieldError>} 
                </FormGroup>
                <FormGroup className='d-flex'>
                  <label className='d-flex'>
                    <input
                      className='checkbox'
                      type='checkbox'
                      name='agreementTerms'
                      onChange={() => {
                        setFieldValue('agreementTerms', !values.agreementTerms && true);
                        Logger.debug(values)
                      }}
                      checked={values.agreementTerms}
                    />
                    <Text fontSize={theme.textFontSizes[0]}>
                      I acknowledge and agree to the <Link href='https://www.teambuildr.com/user-agreement' target='_blank'>TeamBuildr User Agreement</Link>      
                    </Text>
                  </label>
                </FormGroup>
                <FormGroup className='form-group'>
                  {errors.agreementTerms && touched.agreementTerms && <FieldError className='text-center'>Must Accept Terms Before Creating Account.</FieldError>}
                </FormGroup>
                <FormGroup className='text-center'>
                  {isRegistrationLoading ? (
                    <Spinner saving darkTheme />
                  ) : (
                    <Button
                      cta='Submit'
                      customColor='#ff6600'
                      disabled={errors.password || errors.emailAddress || errors.confirmedPassword || errors.agreementTerms}
                      name='submit'
                      id='submit'
                      onClick={handleSubmit}
                      fullWidth
                      large
                      noBorder
                      rounded
                      primary
                    />
                  )}

                  {/* <GoogleLogin
                    clientId='849504945663-2a20rmtkv13kkrs7idoafi82fvoko52l.apps.googleusercontent.com'
                    buttonText='Login with Google'
                    onSuccess={handleGoogleResponse}
                    onFailure={handleGoogleResponse}
                    cookiePolicy='single_host_origin'
                    render={renderProps => (
                      <Button
                        cta='Login with Google'
                        customColor='#ffffff'
                        customIcon={GoogleIcon}
                        disabled={renderProps.disabled}
                        noHover
                        onClick={renderProps.onClick}
                        fullWidth
                        large
                        rounded
                      />
                    )}
                  /> */}
                  {/* <Link className='easy-join' allCaps color='lightText.1' href='https://app.teambuildr.com/signup?ej=true'>
                    Already have an account? Click Here to login.
                  </Link> */}
                  {/* <Link allCaps color='lightText.2' href='https://app.teambuildr.com/forgot'>
                    Forgot Your Password?
                  </Link> */}
                </FormGroup>
              </Form>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>
            <animated.div style={completeAnimation}>
              <SpinnerContainer>
                <Checkmark />
              </SpinnerContainer>
            </animated.div>
          </Fragment>
        </LoginContainer>
      )}
    />
  );
};

export default ModalCreateAccount;
