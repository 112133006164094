/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import PropTypes from 'prop-types';

import iconSet from '../../images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  @media only screen and (max-width : 768px) {
    width: 90vw;
    max-width: 100vw;
  }
  width: 50vw;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  right: 50%;
  top: 50%;
  height: 75vh;
  transition: all 0.3s linear;
  max-width: 500px;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
`;

const Container = styled('div')`
  width: 100%;
  flex-direction: column;
  transition: all 1s ease;
  overflow: hidden;
  user-select: none;
  height: 100%;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  height: 100%;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const ProgramCard = styled('div')`
  border-radius: 8px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${BoxShadow};
  color: ${(props) => (props.isDefault ? '#444444' : 'white')};;
  position: relative;
  overflow: hidden;
  min-height: 160px;
`;

const BackgroundImage = styled('div')`
  background-color: #F5F5F5;
  background-image: ${(props) => (props.isDefault ? '' : `url(${props.backgroundImage})}`)};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  position: absolute;
  opacity: 0.7;
`;

const ProgramUnderlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: ${(props) => (props.isDefault ? '' : 'black')};;
`;

const ColumnsAndStatusFirst = styled('div')`
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px;
  width: 100%;
  height: 100%;
`;

const ProgramWeekCard = styled('div')`
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${BoxShadow};
  min-height: 131px;
  background: ${(props) => (props.isSelected ? 'linear-gradient(90deg,#667EEA 0%,#764BA2 100%)' : 'white')};
  color: ${(props) => (props.isSelected ? 'white' : 'black')};
`;

const ProgramDayCard = styled('div')`
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${BoxShadow};
  background: ${(props) => (props.isNextSession ? 'linear-gradient(90deg, #11998e 0%, #38ef7d 100%)' : 'white')};
  color: ${(props) => (props.isNextSession ? 'white' : 'black')};
`;

const ShimmerProgramCard = styled('div')`
  border-radius: 8px;
  padding: 16px;
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${BoxShadow};
  min-height: 110px;
`;

const ProgramCards = styled('div')`
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 100px;
  scrollbar-width: none;
  transition: margin 0.3s linear;
  margin-top: ${(props) => (props.addMargin ? props.fullList ? '90px' : '40px' : '0px')};
`;

const EmptySessionDiv = styled('div')`
  display: flex;
  width: 100%;
  font-size: 18px;
  font-family: 'Nunito Sans';
  height: 100px;
  justify-content: center;
  align-items: center;
`;

const ProgramCardTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 24px;
  display: -webkit-box; 
  white-space: normal;
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis;
`;

const StackedWeekTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  display: flex;
  flex-direction: column;
`;

const ProgramCardColumn1 = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const ProgramCardColumn2 = styled('div')`
  display: flex;
  min-width: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => (props.isNextSession ? '24px' : '0px')};
`;

const ProgramCardColumns = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ProgramCardFooter = styled('div')`
  display: flex;
  flex-direction: column;
`;
const SessionNumber = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
  height: 21px;
`;

const ProgramListHeader = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 28px;
  margin-left: 20px;
  margin-top: 10px;
  width: 95%;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NavigationHeader = styled('div')`
  margin-left: 10px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  max-height: ${(props) => (props.showHeader ? '50px' : '0px')};
  transition: max-height 0.3s ease;
  overflow: hidden;
`;

const IconRotator = styled('div')`
  cursor: pointer;
  padding: 10px;
`;

const IconContainer = styled('div')`
  cursor: pointer;
  padding: 10px;
  transform: rotate(180deg);
`;

const ProgramStatusCircle = styled('div')`
  border-radius: 50%;
  background: ${(props) => props.color};
  border: ${(props) => (!props.started ? '1px solid grey' : `1px solid ${props.color}`)};
  width: 14px;
  height: 14px;
`;

const CustomProgramStatusCircle = styled('div')`
  border-radius: 50%;
  background: ${(props) => props.color};
  border: ${(props) => (!props.started ? '1px solid white' : `1px solid ${props.color}`)};
  width: 14px;
  height: 14px;
`;

const ProgramStatus = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
`;

const ColumnsAndStatus = styled('div')`
  display: flex;
  flex-direction: column;
`;

const DropdownOption = styled('div')`
  display: flex;
  min-height: 40px;
  align-items: center;
  padding-left: 30px;
  :hover {
    background: #F5F5F5;
  }
  cursor: pointer;
`;

const DropdownOptionText = styled('div')`
  font-size: 15px;
  font-family: 'Nunito Sans';
  margin-left: 10px;
`;

const StatusAndText = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 5px;
  height: 21px;
`;

const NavigationHeaderAndProgramOptions = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ProgramOptions = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.isActive ? '210px' : '0px')};
  transition: max-height 0.3s linear;
  overflow: hidden;
  box-shadow: ${BoxShadow};
  position: absolute;
  width: 100%;
  background: white;
  z-index: 100;
  top: 80px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const OptionDiv = styled('div')`
  background: grey;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 5px;
  align-self: center;
  border: 1px solid lightgrey;
`;

const HeaderAndWeeks = styled('div')`
  display: flex;
  flex-direction: column;
`;

const WeeksInProgram = styled('div')`
  color: #9e9e9e;
  font-size: 16px;
  font-family: 'Nunito Sans';
  margin-top: 5px;
  margin-left: 20px;
`;

const NextWorkoutDay = styled('div')`
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: ${BoxShadow};
  background: linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
  color: ${(props) => (props.isSelected ? 'white' : 'black')};
`;

const NextDayProgramCardTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 24px;
  white-space: normal;
  max-width: 290px;
  color: white;
`;

const NextDayProgramStatus = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
  color: white;
  margin-top: 2px;
`;

const NextDaySessionNumber = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
  height: 21px;
  color: white;
`;

const NextSessionDayText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
`;

const NextWorkoutSeparator = styled('div')`
  height: 30px;
  align-self: stretch;
  flex-grow: 0;
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #424242;
  margin-top: 20px;
  margin-left: 20px;
`;

const NoProgramsDiv = styled('div')`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 20px;
  text-align: center;
  padding: 20px;
  white-space: normal;
`;

const ToolTip = styled('div')`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  padding: 5px;
  font-size: 14px;
  border-radius: 3px;
`;

const HeaderToolTip = styled('div')`
  background: rgba(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  padding: 5px;
  font-size: 14px;
  border-radius: 3px;
  left: 10px;
`;

const ToolTipContainer = styled('div')`
  position: relative;
`;

const CustomDayTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 24px;
  white-space: normal;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomDaySubTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 14px;
`;

const CustomDayActiveDayHeader = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 19px;
`;

const InfoIconCircle = styled('div')`
  width: 15px;
  height: 15px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// placeholder card with shimmering properties for load mapping
const ShimmerCard = () => (
  <Shimmer>
    <ShimmerProgramCard>
      <ColumnsAndStatus>
        <ProgramCardColumns>
          <ProgramCardColumn1>
            <ProgramCardTitle style={{
              opacity: 0,
            }}
            >
              Shimmer Title
            </ProgramCardTitle>
            <SessionNumber style={{ opacity: 0 }}>
              Shimmer Session Number
              {' '}
              Sessions
            </SessionNumber>
            <StatusAndText>
              <ProgramStatusCircle style={{ opacity: 0 }} />
              <ProgramStatus style={{
                opacity: 0,
              }}
              >
                Shimmer Status
              </ProgramStatus>
            </StatusAndText>
          </ProgramCardColumn1>
          <ProgramCardColumn2 style={{ opacity: 0 }}>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='right-arrow'
              color='black'
            />
          </ProgramCardColumn2>
        </ProgramCardColumns>
      </ColumnsAndStatus>
    </ShimmerProgramCard>
  </Shimmer>
);

const ToolTipName = ({
  programName,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  let timeout;

  const handleMouseEnter = () => {
    timeout = setTimeout(() => setShowToolTip(true), 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeout);
    setShowToolTip(false);
  };

  return (
    <ToolTipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showToolTip ? (
        <ToolTip>{programName}</ToolTip>
      ) : null}
      <ProgramCardTitle>{programName}</ProgramCardTitle>
    </ToolTipContainer>
  );
};

const ToolTipHeader = ({
  programName,
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  let timeout;

  const handleMouseEnter = () => {
    timeout = setTimeout(() => setShowToolTip(true), 500);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeout);
    setShowToolTip(false);
  };

  return (
    <ToolTipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {showToolTip ? (
        <HeaderToolTip>{programName}</HeaderToolTip>
      ) : null}
      <ProgramListHeader>
        {programName}
      </ProgramListHeader>
    </ToolTipContainer>
  );
};

/**
 *
 * @param {bool} isModalActive simple boolean to
 * determine whether the modal should slide up or slide down
 * @param {func} viewScheduledWorkoutsClickFunction function that gets called
 * when the 'view scheduled workouts' button in the dropdown is pressed
 * @param {func} programCardClickFunction function that gets called when
 * a program card is clicked - this function receives both the program object itself
 * and the index of the program object in the list
 * @param {Object} currentSelectedProgram an object that indicates which program had
 * been selected - ideally should be passed in from Redux but local state should also work
 * @param {func} programWeekDayCardClickFunction function that gets called when a week within
 * a program has been clicked - receives the week object that has been selected along
 * with its index
 * @param {Object} nextSession optional parameter that can be passed in - should be whatever
 * has been received from calling this endpoint
 * @param {Array} workoutProgramsList the list of workout programs
 * @param {Array} workoutProgramWeeksList the array of weeks received from specifying
 * the workout program
 * @param {Array} workoutProgramDayList the array of days received from specifing the
 * workout week
 * @param {func} nextWorkoutDayClickFunction function that gets called when the 'next workout'
 * card is displayed (should only be visible/clickable when 'showNextSession' bool is true)
 * @param {bool} showNextSessionOption boolean that determines if the next session card is
 * displayed/clickable
 * @param {func} resetProgramButtonClickFunction function that gets called when the 'reset
 * program' button in the dropdown is clicked
 * @param {func} infoButtonClickFunction function that gets called when the info button from
 * the dropdown is clicked
 * @param {func} completeButtonClickFunction function that gts called when the complete
 * button from the dropdown is clicked
 * @param {bool} isItemsLoading boolean that refers to whether either the list of weeks,
 * days, or programs are currently loading.  The boolean refers to all three
 * @param {bool} isAllowedReset boolean that allows resetting of the program
 * @param {bool} isNonDateProgramsActive boolean that tracks whether non date programs is active
 * @param {func} onLoadFunctions function called in useEffect on modal load
 * @returns
 */
const ProgramListBoxModal = ({
  isModalActive,
  viewScheduledWorkoutsClickFunction,
  programCardClickFunction,
  programWeekCardClickFunction,
  currentSelectedProgram,
  programWeekDayCardClickFunction,
  nextSession = {},
  workoutProgramsList,
  workoutProgramWeeksList,
  workoutProgramDaysList,
  nextWorkoutDayClickFunction,
  showNextSessionOption = false,
  resetProgramButtonClickFunction,
  infoButtonClickFunction,
  completeButtonClickFunction,
  isItemsLoading = false,
  showInfoButton = false,
  isAllowedReset = false,
  isNonDateProgramsActive,
  onLoadFunction = null,
  defaultProgramImage,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [selectedProgramWeekNumber, setSelectedProgramWeekNumber] = useState(0);
  const [isProgramOptionsActivated, setIsProgramOptionsActivated] = useState(false);

  // mapping function for returning an array of shimmering cards
  const shimmerMapper = () => {
    const shimmeredArray = [];
    for (let i = 0; i < 2; i += 1) {
      shimmeredArray.push(<ShimmerCard />);
    }
    return shimmeredArray;
  };

  useEffect(() => {
    onLoadFunction();
  }, [isModalActive]);

  useEffect(() => {
    if (!isNonDateProgramsActive) {
      setActiveSlide(0);
      setSelectedProgramWeekNumber(0);
    }
  }, [isNonDateProgramsActive, isModalActive]);

  const colorCoder = (status) => {
    if (status === 'In Progress') {
      return '#FFCB47';
    }
    if (status === 'Completed') {
      return '#0dcc8a';
    }
    if (status === 'Skipped') {
      return '#ff0024';
    }
    if (status === 'Not Started') {
      return '';
    }
    return '';
  };

  return (
    <ModalContainer isActive={isModalActive}>
      <Container>
        <NavigationHeaderAndProgramOptions>
          <NavigationHeader showHeader={activeSlide !== 0}>
            <IconRotator onClick={() => {
              if (activeSlide !== 0) {
                setActiveSlide(activeSlide - 1);
              }
            }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='left-arrow'
                color='black'
              />
            </IconRotator>
            <IconContainer onClick={() => setIsProgramOptionsActivated(!isProgramOptionsActivated)}>
              <IcomoonReact
                iconSet={iconSet}
                size={30}
                icon='dots'
                color='black'
              />
            </IconContainer>
          </NavigationHeader>
          <ProgramOptions isActive={isProgramOptionsActivated && activeSlide !== 0}>
            <DropdownOption onClick={() => {
              viewScheduledWorkoutsClickFunction();
            }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='calendar'
                color='black'
              />
              <DropdownOptionText>View Scheduled Workouts</DropdownOptionText>
            </DropdownOption>
            <OptionDiv />
            {isAllowedReset ? (
              <DropdownOption onClick={() => {
                resetProgramButtonClickFunction();
              }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='restart'
                  color='black'
                />
                <DropdownOptionText>Reset Program</DropdownOptionText>
              </DropdownOption>
            ) : null}
            <DropdownOption
              onClick={() => completeButtonClickFunction()}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color='black'
              />
              <DropdownOptionText>Mark as Complete</DropdownOptionText>
            </DropdownOption>
            {showInfoButton ? (
              <DropdownOption
                onClick={() => infoButtonClickFunction()}
                style={{
                  marginBottom: 10,
                }}
              >
                <InfoIconCircle>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={9}
                    icon='info'
                    color='black'
                  />
                </InfoIconCircle>
                <DropdownOptionText>Program Info</DropdownOptionText>
              </DropdownOption>
            ) : null}
          </ProgramOptions>
        </NavigationHeaderAndProgramOptions>
        <SlidesContainer>
          <Slides activeSlide={activeSlide}>
            <Slide>
              <ProgramListHeader>
                Program Library
              </ProgramListHeader>
              {isItemsLoading && activeSlide === 0 ? shimmerMapper() : (
                !isItemsLoading && workoutProgramsList.length !== 0 ? (
                  <ProgramCards>
                    {workoutProgramsList.map((program, idx) => (
                      <ProgramCard
                        isDefault={!program?.media?.mediaUrl}
                        onClick={() => {
                          if (activeSlide < 1) {
                            setActiveSlide(activeSlide + 1);
                          }
                          programCardClickFunction(program, idx);
                        }}
                      >
                        <ColumnsAndStatusFirst>
                          <ProgramCardColumns>
                            <ProgramCardColumn1>
                              <ToolTipName programName={program.programName} />
                              <ProgramCardFooter>
                                <SessionNumber>
                                  {program.numberOfActiveDays}
                                  {' '}
                                  {program.numberOfActiveDays === 1 ? 'Week' : 'Weeks'}
                                </SessionNumber>
                                <StatusAndText>
                                  {program?.media?.mediaUrl || defaultProgramImage ? (
                                    <CustomProgramStatusCircle started={program.status !== 'Not Started'} color={program.statusColorCode} />
                                  ) : (
                                    <ProgramStatusCircle started={program.status !== 'Not Started'} color={program.statusColorCode} />
                                  )}
                                  <ProgramStatus>
                                    {program.status}
                                  </ProgramStatus>
                                </StatusAndText>
                              </ProgramCardFooter>
                            </ProgramCardColumn1>
                            <ProgramCardColumn2>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={20}
                                icon='right-arrow'
                                color={program?.media?.mediaUrl ? 'white' : '#444444'}
                              />
                            </ProgramCardColumn2>
                          </ProgramCardColumns>
                        </ColumnsAndStatusFirst>
                        <ProgramUnderlay isDefault={!program?.media?.mediaUrl}>
                          <BackgroundImage
                            backgroundImage={program?.media?.mediaUrl
                              || defaultProgramImage || ''}
                            isDefault={!program?.media?.mediaUrl}
                          />
                        </ProgramUnderlay>
                      </ProgramCard>
                    ))}
                  </ProgramCards>
                ) : (
                  <NoProgramsDiv>
                    Program Library allows athletes to access
                    programs and complete them at their own pace.
                    <br />
                    <br />
                    You do not currently have access to any programs.
                  </NoProgramsDiv>
                )
              )}
            </Slide>
            <Slide>
              {' '}
              <Slide>
                <HeaderAndWeeks>
                  <ToolTipHeader programName={currentSelectedProgram.programName} />
                  <WeeksInProgram>
                    {currentSelectedProgram?.numberOfWeeks}
                    {' '}
                    {currentSelectedProgram?.numberOfWeeks === 1 ? 'Week' : 'Weeks'}
                  </WeeksInProgram>
                </HeaderAndWeeks>
                {isItemsLoading && activeSlide === 1 ? shimmerMapper()
                  : (
                    <ProgramCards
                      fullList={showInfoButton}
                      addMargin={isProgramOptionsActivated && activeSlide !== 0}
                    >
                      {showNextSessionOption
                        ? (
                          <>
                            <NextWorkoutSeparator>Next Workout</NextWorkoutSeparator>
                            <NextWorkoutDay onClick={() => {
                              nextWorkoutDayClickFunction(nextSession);
                            }}
                            >
                              <ColumnsAndStatus>
                                <ProgramCardColumns>
                                  <ProgramCardColumn1>
                                    <NextDayProgramCardTitle>
                                      {nextSession.dayTitle === nextSession.title
                                        ? (
                                          <>
                                            {' '}
                                            Week
                                            {' '}
                                            {nextSession.trueWeekNum}
                                            {' '}
                                            -
                                            {' '}
                                            Day
                                            {' '}
                                            {nextSession.relativeDisplayDayNum}
                                          </>
                                        ) : (
                                          <StackedWeekTitle>
                                            <CustomDayActiveDayHeader>
                                              Next Session
                                            </CustomDayActiveDayHeader>
                                            <CustomDayTitle>{nextSession.title}</CustomDayTitle>
                                            <CustomDaySubTitle>
                                              {' '}
                                              Week
                                              {' '}
                                              {nextSession.trueWeekNum}
                                              {' '}
                                              -
                                              {' '}
                                              Day
                                              {' '}
                                              {nextSession.relativeDisplayDayNum}
                                            </CustomDaySubTitle>
                                          </StackedWeekTitle>
                                        )}
                                    </NextDayProgramCardTitle>
                                    <NextDaySessionNumber>
                                      {nextSession.numberOfItems
                                        > 0 ? `${nextSession.numberOfItems} ${nextSession.numberOfItems === 1 ? 'Item' : 'Items'}`
                                        : 'No Items'}
                                    </NextDaySessionNumber>
                                    <StatusAndText>
                                      <CustomProgramStatusCircle started={nextSession.status !== 'Not Started'} color={nextSession.statusColorCode} />
                                      <NextDayProgramStatus>
                                        {nextSession.status}
                                      </NextDayProgramStatus>
                                    </StatusAndText>
                                  </ProgramCardColumn1>
                                  <ProgramCardColumn2>
                                    <IcomoonReact
                                      iconSet={iconSet}
                                      size={20}
                                      icon='right-arrow'
                                      color='white'
                                    />
                                  </ProgramCardColumn2>
                                </ProgramCardColumns>
                              </ColumnsAndStatus>
                            </NextWorkoutDay>
                            <NextWorkoutSeparator>All Workouts</NextWorkoutSeparator>
                          </>
                        ) : null}
                      {workoutProgramWeeksList.map((programWeek, idx) => (
                        <ProgramWeekCard onClick={() => {
                          if (activeSlide < 2) {
                            setActiveSlide(activeSlide + 1);
                          }
                          programWeekCardClickFunction(programWeek, idx);
                          setSelectedProgramWeekNumber(idx + 1);
                        }}
                        >
                          <ColumnsAndStatus>
                            <ProgramCardColumns>
                              <ProgramCardColumn1>
                                <ProgramCardTitle>
                                  <StackedWeekTitle>
                                    <CustomDayTitle>{programWeek.weekTitle}</CustomDayTitle>
                                  </StackedWeekTitle>
                                </ProgramCardTitle>
                                <SessionNumber>
                                  {programWeek.numberOfActiveDays > 0 ? `${programWeek.numberOfActiveDays} Sessions` : 'No Sessions'}
                                </SessionNumber>
                                <StatusAndText>
                                  {programWeek.numberOfActiveDays ? (
                                    <>
                                      <ProgramStatusCircle started={programWeek.status !== 'Not Started'} color={colorCoder(programWeek.status)} />
                                      <ProgramStatus>
                                        {programWeek.status}
                                      </ProgramStatus>
                                    </>
                                  ) : null}
                                </StatusAndText>
                              </ProgramCardColumn1>
                              <ProgramCardColumn2>
                                <IcomoonReact
                                  iconSet={iconSet}
                                  size={20}
                                  icon='right-arrow'
                                  color='black'
                                />
                              </ProgramCardColumn2>
                            </ProgramCardColumns>
                          </ColumnsAndStatus>
                        </ProgramWeekCard>
                      ))}
                    </ProgramCards>
                  )}
              </Slide>
            </Slide>
            <Slide>
              <Slide>
                <HeaderAndWeeks>
                  <ToolTipHeader programName={currentSelectedProgram.programName} />
                  <WeeksInProgram>
                    Week
                    {' '}
                    {selectedProgramWeekNumber}
                  </WeeksInProgram>
                </HeaderAndWeeks>
                {!isItemsLoading ? (
                  workoutProgramDaysList.length ? (
                    <ProgramCards
                      fullList={showInfoButton}
                      addMargin={isProgramOptionsActivated && activeSlide !== 0}
                    >
                      {workoutProgramDaysList.map((day, idx) => (
                        <ProgramDayCard
                          isNextSession={day.trueDayNum === nextSession.trueDayNum}
                          onClick={() => {
                            if (activeSlide === 2) {
                              programWeekDayCardClickFunction(day, idx, day.zeroBasedDayNum);
                            }
                          }}
                        >
                          <ColumnsAndStatus>
                            <ProgramCardColumns>
                              <ProgramCardColumn1>
                                {day.trueDayNum === nextSession.trueDayNum
                                  ? <NextSessionDayText>Next Session</NextSessionDayText> : null}
                                <ProgramCardTitle>
                                  {day.dayTitle === day.title
                                    ? day.title : (
                                      <StackedWeekTitle>
                                        <CustomDayTitle>{day.title}</CustomDayTitle>
                                        <CustomDaySubTitle>{day.dayTitle}</CustomDaySubTitle>
                                      </StackedWeekTitle>
                                    )}
                                </ProgramCardTitle>
                                <SessionNumber>
                                  {day.numberOfItems
                                   > 0 ? `${day.numberOfItems} ${day.numberOfItems === 1 ? 'Item' : 'Items'}`
                                    : 'No Items'}
                                </SessionNumber>
                                <StatusAndText>
                                  {day.trueDayNum !== nextSession.trueDayNum ? <ProgramStatusCircle started={day.status !== 'Not Started'} color={day.statusColorCode} />
                                    : <CustomProgramStatusCircle started={day.status !== 'Not Started'} color={day.statusColorCode} />}
                                  <ProgramStatus>
                                    {day.status}
                                  </ProgramStatus>
                                </StatusAndText>
                              </ProgramCardColumn1>
                              <ProgramCardColumn2
                                isNextSession={day.trueDayNum === nextSession.trueDayNum}
                              >
                                <IcomoonReact
                                  iconSet={iconSet}
                                  size={20}
                                  icon='right-arrow'
                                  color={day.trueDayNum === nextSession.trueDayNum ? 'white' : 'black'}
                                />
                              </ProgramCardColumn2>
                            </ProgramCardColumns>
                          </ColumnsAndStatus>
                        </ProgramDayCard>
                      ))}
                    </ProgramCards>
                  ) : <EmptySessionDiv>No Sessions This Week</EmptySessionDiv>
                ) : shimmerMapper()}
              </Slide>
            </Slide>
          </Slides>
        </SlidesContainer>
      </Container>
    </ModalContainer>
  );
};

ProgramListBoxModal.defaultProps = {
  nextSession: {},
  showNextSessionOption: false,
  isItemsLoading: false,
  showInfoButton: false,
  isAllowedReset: false,
  onLoadFunction: null,
};

ToolTipName.propTypes = {
  programName: PropTypes.string.isRequired,
};

ToolTipHeader.propTypes = {
  programName: PropTypes.string.isRequired,
};

ProgramListBoxModal.propTypes = {
  isModalActive: PropTypes.bool.isRequired,
  viewScheduledWorkoutsClickFunction: PropTypes.func.isRequired,
  programCardClickFunction: PropTypes.func.isRequired,
  currentSelectedProgram: PropTypes.instanceOf(Object).isRequired,
  programWeekCardClickFunction: PropTypes.func.isRequired,
  nextWorkoutDayClickFunction: PropTypes.func.isRequired,
  programWeekDayCardClickFunction: PropTypes.func.isRequired,
  nextSession: PropTypes.instanceOf(Object),
  workoutProgramsList: PropTypes.instanceOf(Array).isRequired,
  workoutProgramWeeksList: PropTypes.instanceOf(Array).isRequired,
  workoutProgramDaysList: PropTypes.instanceOf(Array).isRequired,
  showNextSessionOption: PropTypes.bool,
  resetProgramButtonClickFunction: PropTypes.func.isRequired,
  infoButtonClickFunction: PropTypes.func.isRequired,
  completeButtonClickFunction: PropTypes.func.isRequired,
  isItemsLoading: PropTypes.bool,
  showInfoButton: PropTypes.bool,
  isAllowedReset: PropTypes.bool,
  isNonDateProgramsActive: PropTypes.bool.isRequired,
  onLoadFunction: PropTypes.func,
  defaultProgramImage: PropTypes.string.isRequired,
};

export default ProgramListBoxModal;
