import update from 'immutability-helper';

import {
  SET_CONNECTED,
  SET_TEST_MESSAGE,
  SET_SOCKET_AUTHENTICATED,
  SET_RECONNECT_ATTEMPTS,
} from './actionTypes';

const initialState = {
  isConnected: false,
  isAuthenticated: false,
  isReconnected: false, // helps us know if we're reconnecting or this is the first connection made
  reconnectAttempts: 0,
  wasConnected: false,
  testMessages: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CONNECTED: {
      return {
        ...state,
        isConnected: action.payload,
        wasConnected: true,
      };
    }
    /*
      if the socket is authenticated (action.payload = true),
      then we reset the # of reconnection attempts if there were any.
      We also will set a variable called isReconnected to true if
      this is at least the first reconnect attempt, and the reconnection
      was successful. There is a listener for this variable which triggers
      re-fetch of conversations when isReconnected becomes true.
    */
    case SET_SOCKET_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticated: action.payload,
        reconnectAttempts: action.payload ? 0 : state.reconnectAttempts,
        isReconnected: state.reconnectAttempts >= 1 && action.payload ? state.wasConnected : false,
      };
    }
    case SET_RECONNECT_ATTEMPTS: {
      return {
        ...state,
        reconnectAttempts: action.payload,
      };
    }
    case SET_TEST_MESSAGE: {
      return update(state, {
        testMessages: {
          $push: [action.payload],
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
