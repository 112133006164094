import styled from '@emotion/styled';
import {
  space, color, layout, flexbox,
} from 'styled-system';

const Wrapper = styled('div')`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${space}
  ${color}
  ${layout}
  ${flexbox}
`;

export default Wrapper;
