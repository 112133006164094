/* eslint-disable no-nested-ternary */
import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Form, FieldArray } from 'formik';
import 'react-rangeslider/lib/index.css';
import moment from 'moment';

import Question from './Question';

const QuestionListContainer = styled('div')`
  width: 100%;
  margin-left: -7px;
  padding-right: 15px;
  margin-bottom: 10px;

  form {
    display: flex;
    flex-direction: column;
  }
`;

const InfoContainer = styled('div')`
  margin-left: 36px;
  padding: 12px 20px;
  border: 1px solid rgba(104,104,104,0.2);
`;

const InfoTitle = styled('span')`
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: .6px;
`;

const InfoCopy = styled('p')`
  font-size: 13px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.textColor};
`;

class QuestionList extends PureComponent {
  constructor(props) {
    super(props);
    this.handleActive = this.handleActive.bind(this);
    this.checkTotal = this.checkTotal.bind(this);
    this.question = [];
  }

  componentDidMount() {
    const { formikRef } = this.props;
    formikRef.current.validateForm();
  }

  handleActive(id, newIndex) {
    const {
      formikRef,
      selectQuestion,
      namespace,
      scrollQuestions,
      activeQuestionIndex,
    } = this.props;
    selectQuestion(id, newIndex, namespace);
    setTimeout(() => scrollQuestions(activeQuestionIndex, newIndex), 800);
    formikRef.current.submitForm();
  }

  checkTotal(values) {
    const { namespace, storeFormikValues } = this.props;
    const numbers = values.body.map((body, i) => (
      values.body[i].total > 0 ? parseInt(values.body[i].total, 10) : 0
    ));
    const add = (a, b) => (
      (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b)
    );
    const totalNumber = numbers.reduce(add);
    const totalDefaultNumber = totalNumber === '' ? 0 : totalNumber;
    storeFormikValues(namespace, totalDefaultNumber);
  }

  render() {
    const {
      accountCode,
      activeDate,
      activeQuestionIndex,
      currentNonDateDay,
      currentSelectedProgram,
      athleteId,
      data,
      formikRef,
      index,
      isProgramView,
      namespace,
      updateProgramWorkout,
      updateWorkout,
      workout,
    } = this.props;

    const hasInfo = workout.additionalInformation;

    return (
      <QuestionListContainer
        id='question-list-container'
        data-sciencelength={data.length}
      >
        <Formik
          key={workout.assignedId || workout.saveDataId}
          ref={formikRef}
          enableReinitialize
          isInitialValid
          initialValues={{
            accountCode,
            assignedDate: moment(activeDate).format('YYYY-MM-DD'),
            assignedId: workout.assignedId || workout.saveDataId,
            userId: athleteId,
            body:
              workout.questions.map((set, i) => {
                const isNotRangeInput = workout.questions[i].inputType !== 'RANGE';
                const isMCYesNo = workout.questions[i].inputType === 'YES-NO' || workout.questions[i].inputType === 'MULTIPLE-CHOICE';
                return (
                  {
                    questionId: set.id,
                    touched: false,
                    type: workout.questions[i].inputType,
                    value:
                      (set.response.value === null || set.response.value === '') && isNotRangeInput
                        ? ''
                        : set.response.value,
                    // total is used to help calculate questionnaire total when enabled
                    total: isMCYesNo ? set.response.numericalWeight || 0 : set.response.value || 0,
                    // displayValue is being used to make sure
                    // that the button click fills in the MC option immediately
                    displayValue: set.response.displayValue,
                  }
                );
              }),
          }}
          onSubmit={(values, actions) => {
            const submittableValues = { ...values };
            /*
            Only submit responses for questions that were changed/touched
            */
            submittableValues.body = submittableValues.body.filter((item) => item.touched);
            if (submittableValues.body?.length) {
              if (isProgramView) {
                updateProgramWorkout(
                  index,
                  namespace,
                  currentSelectedProgram.dayBasedProgramId,
                  currentNonDateDay.trueDayNum,
                  currentNonDateDay.relativeDisplayDayNum - 1,
                  submittableValues,
                );
              } else {
                updateWorkout(index, namespace, submittableValues);
              }
            }
            actions.setSubmitting(false);
          }}
          validate={workout.questionnaireTotalEnabled ? this.checkTotal : ''}
          render={(props) => (
            <Form>
              <FieldArray
                name='body'
                render={() => (
                  <>
                    {hasInfo ? (
                      <InfoContainer>
                        <InfoTitle>Additional Info:</InfoTitle>
                        <InfoCopy>{workout.additionalInformation}</InfoCopy>
                      </InfoContainer>
                    ) : ('')}
                    {data.map((question, i) => {
                      const isQuestionActive = activeQuestionIndex === i;
                      return (
                        <Fragment
                          key={question.id}
                        >
                          <Question
                            choices={question.choices}
                            description={question.description}
                            handleActive={this.handleActive}
                            handleChange={props.handleChange}
                            key={question.id}
                            inputType={question.inputType}
                            isQuestionActive={isQuestionActive}
                            questionId={question.id}
                            questionIndex={i}
                            questionRef={(el) => { this.question[i] = el; }}
                            questionTitle={question.title}
                            rangeStart={question.minRangeValue}
                            rangeEnd={question.maxRangeValue}
                            setFieldValue={props.setFieldValue}
                            values={props.values}
                          />
                        </Fragment>
                      );
                    })}
                  </>
                )}
              />
            </Form>
          )}
        />
      </QuestionListContainer>
    );
  }
}

QuestionList.propTypes = {
  activeQuestionIndex: PropTypes.number.isRequired,
  athleteId: PropTypes.number.isRequired,
  currentNonDateDay: PropTypes.instanceOf(Object).isRequired,
  currentSelectedProgram: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  isProgramView: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  storeFormikValues: PropTypes.func.isRequired,
  updateProgramWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default QuestionList;
