/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { push } from 'connected-react-router';
import { Formik } from 'formik';
import ResizeObserver from 'react-resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import { updateCurrentUserProfile, getOrganizationSettings } from '../../../../login/ducks/loginActions';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import LayoutWithSidebarNav from '../../../../../shared/components/Layout/LayoutWithSidebarNav';
import { generalSettingsSideBarNavArrayOfObjects } from '../../../../../shared/constants';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const TacticalSettingsPage = () => {
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const accountCode = useSelector((state) => state.auth.data.currentUser?.accountCode);

  const isMeLoading = useSelector((state) => state.auth.ui.isLoginLoading);

  const isProfileUpdateLoading = useSelector((state) => state.auth.ui.isProfileUpdateLoading);

  const usernamesEnabled = useSelector((state) => state.auth.data.organizationSettings?.generalSettings?.usernamesEnabled);

  const tacticalSettings = useSelector((state) => state.auth.data.organizationSettings?.tacticalSettings);

  const isContentLoading = isMeLoading || isProfileUpdateLoading
  || Object.keys(currentUser).length === 0;

  const helmetText = 'Account Settings';

  const navLinkArrayOfObjects = generalSettingsSideBarNavArrayOfObjects;

  useEffect(() => {
    if (accountCode) {
      dispatch(getOrganizationSettings(accountCode));
    }
  }, [accountCode]);

  const Container = styled('div')`
    position: relative;
      input:focus {
        outline: 5px dotted green;
      }
        .error-message {
        margin-top: 10px;
        font-size: 14px;
        color: #ff6600;
      }
      @media (max-width: 800px) {
        padding-top: 50px;
      }
`;

  const IconContainer = styled('div')`
    position: absolute;
    top: 0px;
    right: 0px;
      @media (min-width: 800px) {
        display: none;
      }
      @media (max-width: 800px) {
        top: 8px;
        right: 10px;
      }
`;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 46px;
  `;

  const PageTitleContainer = styled('div')`
  text-align: ${containerWidth && containerWidth < 450 ? 'center' : 'start'};
  padding-bottom: 30px;
`;

  const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100vh;
`;

  const tacticalSettingsObject = {
    usernamesEnabled,
    ...tacticalSettings,
  };

  const tacticalSettingsLabels = {
    usernamesEnabled: 'Usernames Enabled',
    restrictEmailChanges: 'Restrict Email Changes',
    restrictPasswordChanges: 'Restrict Password Changes',
  };

  return (
    <LayoutWithSidebarNav
      helmetText={helmetText}
      navLinkArrayOfObjects={navLinkArrayOfObjects}
      sideBarTitle='Settings'
      sideBarTitleIcon='settings'
    >
      {isContentLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Formik
            initialValues={{
              countryCode: currentUser?.phoneCountryCode || '',
              emailAddress: currentUser?.email,
              firstName: currentUser?.first,
              gender: currentUser?.gender,
              lastName: currentUser?.last,
              mobileNumber: currentUser?.phone,
              timeZone: currentUser?.userTimeZone ? currentUser.userTimeZone
                : currentUser?.userSettings?.timeZone,
            }}
            onSubmit={(values, { setSubmitting }) => {
              // When button submits form and form is in the process of submitting,
              // submit button is disabled
              setSubmitting(true);
              if (!values.mobileNumber) {
                values.mobileNumber = null;
              }
              if (values.mobileNumber) {
                values.mobileNumber = values.mobileNumber.split(' ').join('');
              }
              dispatch(updateCurrentUserProfile(values));
              setSubmitting(false);
            }}
          >
            {/* Callback function containing Formik state and
             helpers that handle common form actions... destructured from props */}
            {({
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Container>
                  <ResizeObserver
                    onResize={(rect) => {
                      setContainerWidth(rect.width);
                    }}
                  />
                  <IconContainer onClick={() => { dispatch(push('/settings')); }}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={28}
                      icon='settings'
                      color='#444444'
                    />
                  </IconContainer>
                  <PageTitleContainer>
                    <Text fontSize={containerWidth && containerWidth < 450 ? 28 : 34} fontWeight='400'>Enhanced Security (Tactical) Settings</Text>
                    <Text>Please contact your TeamBuildr rep to change any of the below settings.</Text>
                  </PageTitleContainer>

                  {Object.keys(tacticalSettingsObject).map((tacticalSetting) => (
                    <Row>
                      <Text fontSize={containerWidth && containerWidth < 375 ? 15 : 18} fontWeight='700'>
                        {tacticalSettingsLabels[tacticalSetting] || tacticalSetting}
                      </Text>
                      <div>
                        <Text>
                          {tacticalSettingsObject[tacticalSetting] ? 'Enabled' : 'Disabled'}
                        </Text>
                      </div>
                    </Row>
                  ))}
                </Container>
              </form>
            )}
          </Formik>
        </>
      )}
    </LayoutWithSidebarNav>
  );
};
export default TacticalSettingsPage;
