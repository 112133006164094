import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AthleteAvatarContainer = styled('div')`
display: flex;
justify-content: center;
align-items: center;
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    width: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    border-radius: 50%;
  }
height: ${(props) => (
    props.size ? `${props.size + 2}px` : '37px'
  )};
width: ${(props) => (
    props.size ? `${props.size + 2}px` : '37px'
  )};
background: ${(props) => (`linear-gradient(134deg, ${props.gradientColors[0]}, ${props.gradientColors[1]})`)};
border-radius: 50%;
`;

const BackgroundGradient = styled('div')`
border-radius: 50%;
border-width: 1px;
border-style: solid;
border-color: white;

`;

const Avatar = ({
  avatar,
  size,
  gradientColors,
}) => (
  <BackgroundGradient gradientColors={gradientColors} size={size}>
    <AthleteAvatarContainer size={size} gradientColors={gradientColors}>
      <img src={avatar} alt='' />
    </AthleteAvatarContainer>
  </BackgroundGradient>
);

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  size: PropTypes.number,
  gradientColors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Avatar.defaultProps = {
  size: null,
};

export default Avatar;
