/* eslint-disable no-tabs */
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import {
  setActiveWorkoutsMedia,
} from '../../ducks/workoutsActions';

const Container = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
  background: white;
	margin-top: 20px;
	height: 200px;
	cursor: pointer;
`;

const MediaContainer = styled('div')`
	background-image: ${(props) => `url(${props.background})`};
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	height: 100%;
  width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ContentContainer = styled('div')`
	display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
	width: 100%;
	height: 100%;
`;

const PlayButton = styled('div')`
  width: 33px;
  height: 33px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 2px;
    margin-top: 1px;
  }
`;

const VideoCard = ({ workoutObject }) => {
  const dispatch = useDispatch();
  return (
    <Container onClick={() => dispatch(setActiveWorkoutsMedia(workoutObject?.video))}>
      <ContentContainer>
        <MediaContainer background={workoutObject.video.thumbnailUrl}>
          <PlayButton>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='play-filled'
              color='white'
            />
          </PlayButton>
        </MediaContainer>
      </ContentContainer>
    </Container>
  );
};

VideoCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default VideoCard;
