import { connect } from 'react-redux';

import PanelWorkouts from '../presentational/PanelWorkouts';
import {
  closePanel,
  closeWorkout,
  selectDate,
  selectWorkout,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeScreens: state.weightRoom.activeScreens,
  // activeWorkoutIndex: state[ownProps.namespace].activeWorkoutIndex,
  isLoading: state[ownProps.namespace].isLoading,
  isWorkoutComplete: state[ownProps.namespace].isWorkoutComplete,
});

const mapDispatchToProps = dispatch => ({
  closePanel: (namespace) => {
    dispatch(closePanel(namespace));
  },
  closeWorkout: (namespace) => {
    dispatch(closeWorkout(namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelWorkouts);
