/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { createPortal } from 'react-dom';
// import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  fetchEvaluation,
  fetchEvaluations,
  setEvalGroupOptionRedux,
  setEvalGroupTypeOptionRedux,
  setSelectedEvalDate,
  setSelectExistingEvalModal1,
} from '../../ducks/evaluationsActions';
import Button from '../../../../shared/components/Button/Button';
import SelectEvalDatePicker from './SelectEvalDatePicker';

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 90%;
  @media only screen and (max-width : 768px) {
    width: 90vw;
    min-width: 400px;
  }
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #10cd8c;
  }
  overflow: auto;
  transition: all 0.5s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  min-width: 700px;
  max-width: 900px;
`;

const PortalContainer = styled('div')`
  width: 75%;
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 12px;
  overflow: hidden;
`;

const RelativeContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 800px;
  min-width: 500px;
  max-width: 700px;
  min-height: 600px;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
`;

const PrimaryContent = styled('div')`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  min-width: 430px;
  user-select: none;
  @media only screen and (max-width : 768px) {
    min-width: auto;
  }
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 28px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const Container = styled('div')`
  width: 100%;
  flex-direction: column;
  transition: all 1s ease;
  overflow: hidden;
  user-select: none;
  height: 100%;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  height: 100%;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  width: 90%;
`;

const SearchAndIcon = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  border: ${(props) => (props.isFocused ? '1px solid   #5F8BDC' : '1px solid #EEEEEE')};
  border-radius: 50px;
  margin-top: 5px;
`;

const IconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const ResultContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => (props.activeSlide ? 'hidden' : 'scroll')};

  scrollbar-color: lightgrey transparent;
  scrollbar-width: thin;

  .scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  .scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    position: absolute;
    right: -20px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,.3);
    background-color: grey;
  }
  margin-top: 20px;
  height: 250px;
`;

const SelectedEvaluationDiv = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  width: 100%;
  margin-top: 10px;
  width: 100%;
  transition: all 0.5s ease;

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const DatePickerDiv = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  width: 250px;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
`;

const Slide2Block = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Slide2BlockRow = styled('div')`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const Slide2Block3 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 800;
`;

const EndBlock = styled('div')`
  width: 40px;
  background: #444444;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EvalSelectEndBlock = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.selected ? '#444444' : 'none')};
`;

const DatePickerText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const EvaluationSelectText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  color: #EEEEEE;
`;

const SelectedEvaluationGroupText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const SelectEvaluationGroupBox = styled('div')`
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: ${(props) => (props.active ? '1px solid #5F8BDC' : '1px solid #EEEEEE')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding-left: 12px;
  overflow: hidden;
  cursor: pointer;
`;

const FloatingEvalGroupTypeSelector = styled('div')`
  width: 50%;
  z-index: 1;
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 6px;
  min-width: 450px;
  padding: 16px;
  max-height: 400px; 
  overflow-y: auto;
`;

const FloatingEvalGroupSelector = styled('div')`
  width: 50%;
  z-index: 1;
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 6px;
  min-width: 450px;
  max-width: 600px; 
  max-height: 300px;
  overflow-y: auto; 
  padding: 16px;
`;

const FloatingEvalOption = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: #EEEEEE;
  }
`;

const FloatingEvalGroupOption = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: #EEEEEE;
  }
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const ListResult = styled('div')`
  min-height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: ${(props) => (props.selected ? BoxShadow : 'none')};
  background: ${(props) => (props.selected ? 'rgba(207, 245, 232, 0.8)' : 'none')};
  justify-content: space-between;
  :hover {
    background: ${(props) => (!props.selected ? 'rgba(238,238,238, 0.5)' : 'rgba(207, 245, 232, 0.8)')};
  }
  cursor: pointer;
`;

const SelectExistingEvalModal1 = () => {
  const selectExistingEvalModal1 = useSelector(
    (state) => state.evaluations.ui.selectExistingEvalModal1,
  );
  const evaluations = useSelector(
    (state) => state.evaluations.data.evaluations,
  );
  const evaluation = useSelector(
    (state) => state.evaluations.data.evaluation,
  );
  const isEvaluationLoading = useSelector(
    (state) => state.evaluations.ui.isEvaluationLoading,
  );
  const isEvaluationContentTable = useSelector(
    (state) => state.evaluations.ui.isEvaluationContentTable,
  );
  const groups = useSelector(
    (state) => state.evaluations.data.groups,
  );
  const calendars = useSelector(
    (state) => state.evaluations.data.calendars,
  );
  const currentUser = useSelector(
    (state) => state.auth.data.currentUser,
  );
  const evalGroupTypeOption = useSelector(
    (state) => state.evaluations.data.evalGroupTypeOption,
  );
  const evalGroupOption = useSelector(
    (state) => state.evaluations.data.evalGroupOption,
  );
  const selectedEvalDate = useSelector(
    (state) => state.evaluations.data.selectedEvalDate,
  );

  const [searchedList, setSearchedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [selectEvalTypeBoxActive, setSelectEvalTypeBoxActive] = useState(false);
  const [selectEvalGroupBoxActive, setSelectEvalGroupBoxActive] = useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [errors, setErrors] = useState({
    errorsExist: true,
    errors: [{
      error: 'date error',
      msg: 'Please select an evaluation date',
      isShowing: false,
    },
    ],
  });
  const evalTypeBoxRef = useRef(null);
  const evalGroupBoxRef = useRef(null);
  const [floatingSelectorStyle, setFloatingSelectorStyle] = useState({
    position: 'absolute',
  });
  const [dscElement, setDscElement] = useState(null);
  const [escElement, setEscElement] = useState(null);
  const [gElement, setgElement] = useState(null);

  const dispatch = useDispatch();

  const setEvalGroupTypeOption = (date) => {
    dispatch(setEvalGroupTypeOptionRedux(date));
  };

  const setEvalGroupOption = (date) => {
    dispatch(setEvalGroupOptionRedux(date));
  };

  const setSelectedDate = (date) => {
    dispatch(setSelectedEvalDate(date));
  };

  useEffect(() => {
    const dsc = document.getElementById('date-selector-container');
    if (dsc) {
      setDscElement(dsc);
    }
    const esc = document.getElementById('eval-selector-container');
    if (esc) {
      setEscElement(dsc);
    }
    const g = document.getElementById('eval-group-selector-container');
    if (g) {
      setgElement(g);
    }
  }, []);

  useEffect(() => {
    if (errors.errors[0].isShowing) {
      const newErrors = { ...errors };
      newErrors.errors[0].isShowing = false;
      setErrors(newErrors);
    }
  }, [selectedEvalDate]);

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchEvaluations(currentUser.accountCode));
    }
  }, [currentUser]);

  useEffect(() => {
    const searchedArray = [];
    evaluations.forEach((evaluation) => {
      if (evaluation.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(evaluation);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList(['No results to show']);
    }
  }, [searchTerm]);

  const openFloatingSelector = (ref) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY + 8;
      const left = rect.left + window.scrollX;
      setFloatingSelectorStyle({ top: `${top}px`, left: `${left}px` });
    }
  };

  useEffect(() => {
    if (selectEvalTypeBoxActive && evalTypeBoxRef.current) {
      const rect = evalTypeBoxRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY - 40;

      setFloatingSelectorStyle({
        position: 'absolute',
        top: `${top}px`,
        left: '0px',
        zIndex: 1000,
      });
    }
  }, [selectEvalTypeBoxActive, evalTypeBoxRef.current]);

  useEffect(() => {
    if (selectEvalGroupBoxActive && evalGroupBoxRef.current) {
      const rect = evalGroupBoxRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY - 40;

      setFloatingSelectorStyle({
        position: 'absolute',
        top: `${top}px`,
        left: '0px',
        zIndex: 1000,

      });
    }
  }, [selectEvalGroupBoxActive, evalGroupBoxRef.current]);

  const [activeSlide, setActiveSlide] = useState(0);

  const evalSelectOptions = [
    'Select By Athlete Group',
    'Select By Calendars',
    'All Athletes',
  ];

  const resetModalState = () => {
    setSelectedOption({});
    setSearchTerm('');
    setSelectedEvalDate(null);
    setActiveSlide(0);
  };

  useEffect(() => {
    if (!selectExistingEvalModal1) {
      resetModalState();
    }
  }, [selectExistingEvalModal1]);

  const filterTypeResolver = () => {
    if (evalGroupTypeOption === 'Select By Athlete Group') {
      return 2;
    } if (evalGroupTypeOption === 'Select By Calendars') {
      return 3;
    }
    return 1;
  };

  useEffect(() => {
    if (isEvaluationContentTable) {
      setActiveSlide(1);
      setSelectedOption(evaluation);
    }
  }, [selectExistingEvalModal1]);

  return (
    <ModalContainer
      isActive={selectExistingEvalModal1}
      onClick={(e) => {
        if (e.target !== 'floating-eval-selector-box' && selectEvalTypeBoxActive) {
          setSelectEvalTypeBoxActive(false);
        }
        if (e.target !== 'floating-eval-group-selector-box' && selectEvalGroupBoxActive) {
          setSelectEvalGroupBoxActive(false);
        }
        if (e.target !== 'floating-date-picker-comp' && pickerOpen) {
          setPickerOpen(false);
        }
      }}
    >
      <RelativeContainer>
        <div id='date-selector-container' />
        <div id='eval-selector-container' />
        <div id='eval-group-selector-container' />
        <PortalContainer>
          <RemoveDiv>
            <div
              className='remove-div-icon'
              onClick={() => {
                if (activeSlide === 1) {
                  setActiveSlide(0);
                }
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon='left-arrow'
                color={activeSlide === 1 ? 'black' : 'white'}
              />
            </div>
            <ModalHeader>
              <HeaderIconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='select-all'
                />
              </HeaderIconContainer>
              <ModalHeaderText>Select Evaluation</ModalHeaderText>
              <ErrorBox>
                {errors.errors[0].isShowing && isButtonPressed
                  ? <MappedError>{errors.errors[0].msg}</MappedError> : null}
              </ErrorBox>
            </ModalHeader>
            <div
              className='remove-div-icon'
              onClick={() => {
                dispatch(setSelectExistingEvalModal1(false));
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='remove'
                color='black'
              />
            </div>
          </RemoveDiv>
          <ContentContainer>
            <PrimaryContent>
              <Container>
                <SlidesContainer>
                  <Slides activeSlide={activeSlide}>
                    <Slide>
                      <SearchAndIcon isFocused={isSearchFocused}>
                        <IconContainer>
                          <IcomoonReact
                            iconSet={iconSet}
                            size={20}
                            icon='magnifying-glass-alt'
                          />
                        </IconContainer>
                        <SearchContainer
                          placeholder='Search for Eval'
                          value={searchTerm}
                          onFocus={() => setIsSearchFocused(true)}
                          onBlur={() => setIsSearchFocused(false)}
                          onChange={(inputValue) => {
                            setSearchTerm(inputValue.target.value);
                          }}
                        />
                      </SearchAndIcon>
                      <ResultContainer activeSlide={activeSlide}>
                        {searchTerm.length ? searchedList.map((evaluation) => (
                          <ListResult
                            onClick={() => {
                              if (evaluation.id === selectedOption.id) {
                                setSelectedOption({});
                              } else {
                                setSelectedOption(evaluation);
                              }
                            }}
                            selected={selectedOption.id === evaluation.id}
                          >
                            <div>
                              {evaluation.title}
                            </div>
                            {evaluation.id === selectedOption.id ? (
                              <div>
                                <IcomoonReact
                                  iconSet={iconSet}
                                  size={15}
                                  icon='checkmark'
                                  color='#0DCC8A'
                                />
                              </div>
                            ) : null}
                          </ListResult>
                        )) : evaluations.map((evaluation) => (
                          <ListResult
                            onClick={() => {
                              if (evaluation.id === selectedOption.id) {
                                setSelectedOption({});
                              } else {
                                setSelectedOption(evaluation);
                              }
                            }}
                            selected={selectedOption.id === evaluation.id}
                          >
                            <div>
                              {evaluation.title}
                            </div>
                            {evaluation.id === selectedOption.id ? (
                              <div>
                                <IcomoonReact
                                  iconSet={iconSet}
                                  size={15}
                                  icon='checkmark'
                                  color='#0DCC8A'
                                />
                              </div>
                            ) : null}
                          </ListResult>
                        ))}
                      </ResultContainer>
                    </Slide>
                    <Slide>
                      <Slide2Block>
                        <BlockText>
                          Selected Evaluation
                        </BlockText>
                        <SelectedEvaluationDiv onClick={() => setActiveSlide(0)}>
                          {selectedOption.title}
                        </SelectedEvaluationDiv>
                      </Slide2Block>
                      <Slide2BlockRow>
                        <BlockText>
                          Select Evaluation Date
                        </BlockText>
                        <DatePickerDiv onClick={() => setPickerOpen(!pickerOpen)}>
                          <DatePickerText>
                            {selectedEvalDate ? selectedEvalDate.format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')}
                          </DatePickerText>
                          {dscElement ? createPortal(
                            <SelectEvalDatePicker
                              id='floating-date-picker-comp'
                              isDayPickerOpen={pickerOpen}
                              setIsDayPickerOpen={setPickerOpen}
                              activeDate={selectedEvalDate}
                              selectDate={setSelectedDate}
                            />, dscElement,
                          ) : null}
                          <EndBlock>
                            <IcomoonReact
                              iconSet={iconSet}
                              size={15}
                              icon='calendar'
                              color='white'
                            />
                          </EndBlock>
                        </DatePickerDiv>
                      </Slide2BlockRow>
                      <Slide2Block2>
                        <BlockText>
                          Evaluation Group
                        </BlockText>
                        <SelectEvaluationGroupBox
                          active={selectEvalTypeBoxActive}
                          onClick={() => {
                            setSelectEvalTypeBoxActive(true);
                            openFloatingSelector(evalGroupBoxRef);
                          }}
                          ref={evalTypeBoxRef}
                        >
                          {!evalGroupTypeOption ? (
                            <EvaluationSelectText>
                              i.e. Select All Athletes
                            </EvaluationSelectText>
                          ) : (
                            <SelectedEvaluationGroupText>
                              {evalGroupTypeOption}
                            </SelectedEvaluationGroupText>
                          )}
                          <EvalSelectEndBlock selected={evalGroupTypeOption}>
                            <IcomoonReact
                              iconSet={iconSet}
                              size={15}
                              icon={evalGroupTypeOption ? 'swap' : 'trashcan'}
                              color={evalGroupTypeOption ? 'white' : 'black'}
                            />
                          </EvalSelectEndBlock>
                        </SelectEvaluationGroupBox>
                        {escElement ? createPortal(
                          <FloatingEvalGroupTypeSelector
                            id='floating-eval-selector-box'
                            active={selectEvalTypeBoxActive}
                            style={floatingSelectorStyle}
                          >
                            {evalSelectOptions.map(
                              (selectOption) => (
                                <FloatingEvalOption
                                  onClick={() => setEvalGroupTypeOption(selectOption)}
                                >
                                  {selectOption}
                                </FloatingEvalOption>
                              ),
                            )}
                          </FloatingEvalGroupTypeSelector>, escElement,
                        ) : null}
                      </Slide2Block2>
                      {evalGroupTypeOption === 'Select By Athlete Group' || evalGroupTypeOption === 'Select By Calendars' ? (
                        <Slide2Block3>
                          <SelectEvaluationGroupBox
                            active={selectEvalGroupBoxActive}
                            onClick={() => setSelectEvalGroupBoxActive(true)}
                            ref={evalGroupBoxRef}
                          >
                            {!evalGroupOption ? (
                              <EvaluationSelectText>
                                i.e. Select
                              </EvaluationSelectText>
                            ) : (
                              <SelectedEvaluationGroupText>
                                {evalGroupOption.name}
                              </SelectedEvaluationGroupText>
                            )}
                            <EvalSelectEndBlock selected={evalGroupOption}>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={15}
                                icon={evalGroupOption ? 'swap' : 'trashcan'}
                                color={evalGroupOption ? 'white' : 'black'}
                              />
                            </EvalSelectEndBlock>
                          </SelectEvaluationGroupBox>
                          {gElement ? createPortal(
                            <FloatingEvalGroupSelector
                              id='floating-eval-group-selector-box'
                              active={selectEvalGroupBoxActive}
                              style={floatingSelectorStyle}
                            >
                              {evalGroupTypeOption === 'Select By Calendars' ? calendars.map(
                                (groupTypeOption) => (
                                  <FloatingEvalGroupOption
                                    onClick={() => setEvalGroupOption(groupTypeOption)}
                                  >
                                    {groupTypeOption.name}
                                  </FloatingEvalGroupOption>
                                ),
                              ) : groups.map(
                                (groupTypeOption) => (
                                  <FloatingEvalGroupOption
                                    onClick={() => setEvalGroupOption(groupTypeOption)}
                                  >
                                    {groupTypeOption.name}
                                  </FloatingEvalGroupOption>
                                ),
                              )}
                            </FloatingEvalGroupSelector>, gElement,
                          ) : null}
                        </Slide2Block3>
                      ) : null}
                    </Slide>
                  </Slides>
                </SlidesContainer>
              </Container>
            </PrimaryContent>
          </ContentContainer>
          <SlideIndicators>
            <SlideIndicator isActive={activeSlide === 0} />
            <SlideIndicator isActive={activeSlide === 1} />
          </SlideIndicators>
          <Button
            bottom
            fullWidth
            cta={activeSlide === 0 ? 'Next' : 'Start'}
            className='print-button'
            large
            square
            noBorder
            isLoading={isEvaluationLoading}
            primary
            disabled={!Object.keys(selectedOption).length || isEvaluationLoading}
            onClick={() => {
              if (activeSlide === 0) {
                setActiveSlide(1);
              } else if (activeSlide === 1) {
                if (Object.keys(selectedOption).length) {
                  dispatch(fetchEvaluation({
                    accountCode: currentUser.accountCode,
                    evaluationId: selectedOption.id,
                    selectedDate: selectedEvalDate?.format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'),
                    filterType: filterTypeResolver(),
                    filterId: evalGroupOption.id,
                  }));
                } else {
                  setIsButtonPressed(true);
                  const newErrors = { ...errors };
                  newErrors.errors[0].isShowing = true;
                  setErrors(newErrors);
                }
              }
            }}
          />
        </PortalContainer>
      </RelativeContainer>
    </ModalContainer>
  );
};

SelectExistingEvalModal1.defaultProps = {

};

SelectExistingEvalModal1.propTypes = {

};

export default SelectExistingEvalModal1;
