import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'emotion-theming';
import { animated, useSpring } from 'react-spring';

import { updateProgram } from '../../../ducks/resellerActions';
import Text from '../../../../../shared/components/Text/Text';
import Button from '../../../../../shared/components/Button/Button';
import useReseller from '../../hooks/useReseller';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding: 30px;

  .modal-button {
    display: flex;
    align-items: center;
    background-color: #f24540;
  }

  .error-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
`;

const TextandButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  
  svg {
    height: 22px;
    width: 22px;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  display: flex;
  justify-content: center;
`;

const URLContainer = styled('div')`
  padding: 15px;
  background: ${props => props.theme.colors.background[3]};
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;

  p {
    word-break: break-word;
  }
`;
const ModalProgramURL = ({
  programId,
  programName,
  program,
  closeModal,
}) => {
  const theme = useTheme();
  const urlRef = useRef(null);

  const {
    currentReseller,
  } = useReseller();

  const dispatch = useDispatch();
  const updateProgramError = useSelector((state) => state.reseller.data.updateProgramError);
  const isProgramUpdating = useSelector((state) => state.reseller.ui.isProgramUpdating);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyCheckoutSuccess, setCopyCheckoutSuccess] = useState(false);

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(
      `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${currentReseller ? currentReseller.urlSlug : ''}/${program ? program.urlSlug : ''}
    `);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const copyCheckoutToClipboard = (e) => {
    navigator.clipboard.writeText(
      `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${currentReseller ? currentReseller.urlSlug : ''}/${program ? program.urlSlug : ''}/checkout
    `);
    setCopyCheckoutSuccess(true);
    setTimeout(() => {
      setCopyCheckoutSuccess(false);
    }, 3000);
  };

  const createProgramUrl = (resellerSlug, programSlug) => {
    /*
    Use the market subdomain for the programs url, unless we're on localhost
    */
    if (process.env.ENVIRONMENT === 'LOCAL') {
      return `/programs/${resellerSlug}/${programSlug}`;
    }
    return `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${resellerSlug}/${programSlug}`;
  };

  const openTab = (url) => {
    window.open(url, '_blank');
  };

  const copyAnimation = useSpring({ opacity: copySuccess ? 1 : 0 });
  const copyCheckoutAnimation = useSpring({ opacity: copyCheckoutSuccess ? 1 : 0 });

  return (
    <TextWrapper>
      <Text
        fontWeight='600'
        fontSize={16}
      >
        Program URL
      </Text>
      <TextandButtonContainer>
        <URLContainer>
          <Text
            ref={urlRef}
            fontSize={18}
            onClick={() => openTab(
              createProgramUrl(currentReseller.urlSlug, program.urlSlug),
            )}
          >
            {`https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/`}
            {currentReseller ? currentReseller.urlSlug : ''}
            {`/${program ? program.urlSlug : ''}`}
          </Text>
        </URLContainer>
        <Button
          className='update-button'
          onClick={copyToClipboard}
          primary
          noHover
          noBorder
          type='button'
          iconOnly
          icon='copy'
          large
        />
      </TextandButtonContainer>
      <div className='d-flex'>
        <animated.div style={copyAnimation}>
          <Text fontSize={18} style={{ margin: '20px 0px 20px 0px' }}>Copied Program URL!</Text>
        </animated.div>
      </div>

      <Text
        fontWeight='600'
        fontSize={16}
      >
        External Checkout Page URL
      </Text>
      <TextandButtonContainer>
        <URLContainer>
          <Text
            ref={urlRef}
            fontSize={18}
            onClick={() => openTab(
              `${createProgramUrl(currentReseller.urlSlug, program.urlSlug)}/checkout`,
            )}
          >
            {`https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${currentReseller ? currentReseller.urlSlug : ''}${`/${program ? program.urlSlug : ''}/checkout`}`}
          </Text>
        </URLContainer>
        <Button
          className='update-button'
          onClick={copyCheckoutToClipboard}
          primary
          noHover
          noBorder
          type='button'
          iconOnly
          icon='copy'
          large
        />
      </TextandButtonContainer>
      <div className='d-flex'>
        <animated.div style={copyCheckoutAnimation}>
          <Text fontSize={18} style={{ margin: '20px 0px 20px 0px' }}>Copied Checkout URL!</Text>
        </animated.div>
      </div>
    </TextWrapper>
  );
};

ModalProgramURL.propTypes = {
  programId: PropTypes.instanceOf(Number).isRequired,
  programName: PropTypes.instanceOf(string).isRequired,
};

export default ModalProgramURL;
