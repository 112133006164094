/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import Text from '../../../../shared/components/Text/Text';
import TrainingResultsWorkoutItem from './trainingResultsWorkoutItem';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';

const TrainingResultsCard = ({
  containerWidth, workoutItems, sessionDate, sessionTime,
}) => {
  const theme = useTheme();

  const sectionMapper = (array) => array.map((workoutItem, idx) => (
    <TrainingResultsWorkoutItem
      key={generateUniqueId()}
      workoutItem={workoutItem}
      showVerticalLine={idx < array.length - 1}
      isLast={idx === array.length - 1}
      containerWidth={containerWidth}
    />
  ));

  const NoData = () => (
    <NoDataContainer>
      <Text color={theme.colors.lightText[2]}>No Items Completed</Text>
    </NoDataContainer>
  );

  /** styles */
  const WorkoutCardDiv = styled('div')`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.10);
  border-radius: 10px;
  max-width: 500px;
  min-width: ${(props) => (`${props.minWidth}px`)};
  min-height: 300px;
  padding-bottom: 15px;
  background: white;
`;

  const ItemsContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 20px;
  background: white;
  max-height: ${containerWidth < 1045 ? '315px' : '530px'};
  overflow-y: scroll;
`;

  const HeaderContainer = styled('div')`
  background-color: #424242;
  //min-height: 70px;
  min-height: ${containerWidth < 1045 ? '40px' : '70px'};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

  const InnerTextContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
`;

  const TimeContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 15px;
`;

  const NoDataContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  background: white;
`;
  return (
    <WorkoutCardDiv minWidth={containerWidth < 520 ? containerWidth - 30 : containerWidth >= 520 && containerWidth <= 1045 ? 360 : 450}>
      <HeaderContainer>
        <InnerTextContainer>
          <Text fontSize={containerWidth > 1045 ? '16px' : '13px'} style={{ marginRight: 20 }} color='white'>{moment(sessionDate).format('MMMM DD, YYYY')}</Text>
          <TimeContainer>
            <Text fontSize={containerWidth > 1045 ? '13px' : '10px'} color='white'>Total Workout Time:</Text>
            <Text fontSize={containerWidth > 1045 ? '16px' : '13px'} color='white'>{sessionTime}</Text>
          </TimeContainer>
        </InnerTextContainer>
      </HeaderContainer>
      <ItemsContainer heightCalc={workoutItems?.length * 65}>
        {!workoutItems?.length ? (<NoData />) : sectionMapper(workoutItems)}
      </ItemsContainer>
    </WorkoutCardDiv>
  );
};

TrainingResultsCard.propTypes = {
  sessionDate: PropTypes.string.isRequired,
  sessionTime: PropTypes.string.isRequired,
  containerWidth: PropTypes.number.isRequired,
  workoutItems: PropTypes.instanceOf(Array).isRequired,
};

export default TrainingResultsCard;
