import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import Text from '../../Text/Text';
 
const MessagesList = () => {
  const [refMap] = useState(() => new Map());
  const testMessages = useSelector((state) => state.socketTester.ui.testMessages);

  const textAnimation = useTransition(testMessages, (item) => item.key, {
    from: { height: 0, opacity: 0, flexShrink: 0 },
    enter: { height: 40, opacity: 1 },
    // enter: item => ({ height: refMap.get(item.key).value.clientHeight, opacity: 1 }),
    // enter: item => async next => await next({ height: refMap.get(item).value.clientHeight, opacity: 1 }),
    leave: { height: 0, opacity: 0 },
    onRest: (item) => {
      Logger.debug('Whoa!', item, refMap['{Object => p.css-h8g94y-Text.elcu0vz0}']);
    },
  });

  const MessageContainer = styled('div')`
    height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
  /* 
    &:first-child {
      margin-top: auto;
    } */
    .message-bubble {
      flex-shrink: 1;
      padding: 5px 8px;
      background-color: #ff6600;
      border-radius: 10px;
      margin-right: auto;
      margin-bottom: 10px;
      p {
        color: white;
      }
    }

  `;

  useEffect(() => {
    Logger.debug(refMap);
  }, [testMessages]);

  const FlexEndFix = styled('div')`
    flex: 1 1 auto;
  `;

  return (
    <MessageContainer className='message-container'>
      {/* <FlexEndFix /> */}
      {textAnimation.slice().reverse().map(({ item, key, props }) => (
        <animated.div key={key} style={props} className='message-bubble'>
          <Text
            ref={(ref) => ref && refMap.set(item, ref)}
          >
            {item.message}
          </Text>
        </animated.div>
      ))}
    </MessageContainer>
  );
}

export default MessagesList;
