import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsGroupAccessModalShowing } from '../../ducks/weightroomActions';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0px 0px -250px;
  background-color: #FFFFFF;
  width: 500px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Header = styled('div')`
  font-size: 16px;
  color: #9E9E9E;
`;

const Description = styled('div')`
  font-size: 20px;
  text-align: center;
`;

const GroupAccessModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsGroupAccessModalShowing(false));
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <Header>Group Access Error</Header>
        <Button
          icon='remove'
          iconOnly
          onClick={handleClose}
        />
      </HeaderContainer>
      <Description>
        This account does not have group access enabled. Please contact your admin.
      </Description>
    </ModalContainer>
  );
};

export default GroupAccessModal;
