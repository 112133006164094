import React, { useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSpring, useChain, animated } from 'react-spring';
import { useTheme } from 'emotion-theming';
import Color from 'color';
import Logger from 'js-logger';

import Avatar from '../Avatar/Avatar';
import Text from '../Text/Text';
import IconRound from '../IconRound/IconRound';

const PodiumContainer = styled('div')`
  display: flex;
  height: auto;
  width: ${props => (props.small ? '100%' : '266px')};
  flex-direction: ${props => (props.small ? 'row' : 'column')};
  border-radius: ${props => (props.small || props.minimized ? '0px' : '5px')};
  /* background-image: linear-gradient(134deg, #ff9a44 -8%, #fc6076 200%); */
  background: ${props => (`linear-gradient(134deg, ${props.colorAccent} -8%, ${props.darkerColorAccent} 200%)`)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: ${props => (props.small ? '30px' : '0px')};
`;

const PodiumAvatar = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: ${props => (props.small ? '0px' : '54px')};
`;

const PodiumCopy = styled('div')`
  display: flex;
  width: 100%;
  margin-top: ${props => (props.small ? '0px' : '16px')};
  padding: ${props => (props.small ? '0px' : '0px 20px 30px 20px')};
  align-items: center;
  .result {
    align-self: ${props => (props.small ? 'none' : 'flex-end')};
    margin-left: auto;
    padding-right: ${props => (props.small && !props.minimized ? '30px' : '0px')}
  }
`;

const NameContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: ${props => (props.minimized ? '10px' : '20px')};

  .date {
    opacity: .5;
  }
`;

const Ribbon = styled('div')`
  position: fixed;
  align-self: center;
`;

const PodiumCard = ({
  athlete,
  className,
  avatar,
  date,
  minimized,
  place,
  result,
  small,
}) => {
  const theme = useTheme();
  const themeColor = theme.colorAccent ? theme.colorAccent : '#FFFFFF';
  const colorAccent = Color(themeColor);
  const darkerColorAccent = colorAccent.darken(0.5);
  
  const resultNumber = parseFloat(result);

  const ribbonRef = useRef();
  const animatedRibbon = useSpring({ from: { transform: 'scale(0)' }, to: { transform: 'scale(1)' }, ref: ribbonRef });

  const avatarRef = useRef();
  const fadeInAvatar = useSpring({ from: { opacity: 0, translate: 'translate3d(0, 100px, 0)' }, to: { opacity: 1, translate: 'translate3d(0px, 0px, 0px)' }, ref: avatarRef });

  const textRef = useRef();
  const fadeInText = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, ref: textRef });

  const numberRef = useRef();
  const animatedNumbers = useSpring({ from: { val: 0 }, to: { val: resultNumber }, ref: numberRef });

  useChain([ribbonRef, avatarRef, textRef, numberRef], [0, 0.5, 0.7, 0.8]);

  return (
    <PodiumContainer
      className={className}
      colorAccent={colorAccent}
      darkerColorAccent={darkerColorAccent}
      small={small}
    >
      <Ribbon>
        <animated.div style={animatedRibbon}>
          {/* If it's not small, show the ribbon. */}
          {(place === 1 && !small) && (
            // Gold Ribbon
            <IconRound
              icon='ribbon'
              customBackground='#f9b242'
              customBorder='#c98600'
              customColor='white'
              xLarge
            />
          )}
          {(place === 2 && !small) && (
            // Silver Ribbon
            <IconRound
              icon='ribbon'
              customBackground='#dadada'
              customBorder='#979797'
              customColor='white'
              xLarge
            />
          )}
          {(place === 3 && !small) && (
            // Bronze Ribbon
            <IconRound
              icon='ribbon'
              customBackground='#d0740d'
              customBorder='#9c4e00'
              customColor='white'
              xLarge
            />
          )}
        </animated.div>
      </Ribbon>
      {/* If it's not small, show this avatar component */}
      {!small && (
        <PodiumAvatar
          small={small}
        >
          <animated.div
            style={{
              ...fadeInAvatar,
            }}
          >
            <Avatar
              avatar={avatar}
              size={place === 1 ? 80 : 65}
            />
          </animated.div>
        </PodiumAvatar>
      )}
      <animated.div
        style={{
          display: 'flex',
          width: '100%',
          ...fadeInText,
        }}
      >
        <PodiumCopy
          minimized={minimized}
          small={small}
        >
          {minimized && (
            <IconRound
              customBackground='transparent'
              customColor='white'
              icon='trophy'
              noBorder
              xLarge
            />
          )}
          {!minimized && (
            <Text
              color='white'
              fontWeight='900'
              fontSize='20pt'
            >
              {place}
            </Text>
          )}
          {/* If it is small or minimized show this avatar component */}
          {(small || minimized) && (
            // <PodiumAvatar
            //   small={small}
            // >
            <animated.div
              style={{
                marginLeft: minimized ? '15px' : '20px',
                ...fadeInAvatar,
              }}
            >
              <Avatar
                avatar={avatar}
                size={minimized ? 40 : 55}
              />
            </animated.div>
            // </PodiumAvatar>
          )}
          <NameContainer
            minimized={minimized}
          >
            <Text
              color='white'
              fontWeight='600'
              fontSize='10pt'
            >
              {athlete}
            </Text>
            <Text
              className='date'
              color='white'
              fontWeight='600'
              fontSize='8pt'
            >
              {date}
            </Text>
          </NameContainer>
          <Text
            className='result'
            color='white'
            fontSize='20pt'
          >
            {isNaN(result) ? (
              result
            ) : (
              <animated.div>
                {/* Interpolate numbers but check if its a decimal using modulus,
                add toFixed if it is to limit decimal places in animation. */}
                {resultNumber % 1 !== 0 ? (
                  animatedNumbers.val.interpolate((val) => {
                    const decimals = resultNumber.toString().split('.')[1];
                    const decimalPlaces = decimals.length;

                    return (
                      val.toFixed(decimalPlaces)
                    );
                  })
                ) : (
                  animatedNumbers.val.interpolate(val => Math.floor(val))
                )}
              </animated.div>
            )}
          </Text>
          {/* If it is small but not minimized, show this component */}
          {(small && !minimized) && (
            <IconRound
              customBackground='transparent'
              customColor='white'
              icon='trophy'
              noBorder
              xLarge
            />
          )}
        </PodiumCopy>
      </animated.div>
    </PodiumContainer>
  );
};

PodiumCard.propTypes = {
  athlete: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  minimized: PropTypes.bool,
  small: PropTypes.bool,
  place: PropTypes.number.isRequired,
  result: PropTypes.number.isRequired,
};

PodiumCard.defaultProps = {
  minimized: false,
  small: false,
};

export default PodiumCard;
