import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import Select from 'react-select';
import { Form } from 'formik';

import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../shared/components/Text/Text';
import FormHandler, { FormGroup } from '../../../../shared/components/FormHandler/FormHandler';

const MainContainer = styled('main')`
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  @media screen and (max-height: 920px) {
    margin: 0px;
  }

  form {
    display: flex;
    width: 100%;
  }

  .form-group {
    max-width: 500px;
    min-width: 200px;
    margin-right: 20px;
    display: flex;
    width: 100%;
    align-items: center;

    & > div:nth-child(2) {
      flex: 1;
    }
  }
  
  .react-select__control, .search-container {
    background-color: transparent!important;
    box-shadow: none;
    border-color: ${props => props.theme.colors.border};
    transition: ${TransitionGlobal};
    &:hover {
      /* border-color: white; */
    }
  }

  .css-dvua67-singleValue, .react-select__placeholder {
    color: ${props => props.theme.colors.text};
    font-size: 13px;
  }

  .react-select__single-value--is-disabled {
    color: gray;
    font-size: 13px;
  }
`;

// const exerciseOptions = [
//   { value: 'exer1', label: 'Barbell Backsquat' },
//   { value: 'exer2', label: 'Barbell Benchpress' },
// ];

// const athleteOptions = [
//   { value: 'athlete1', label: 'Rocky Balboa' },
//   { value: 'athlete2', label: 'Jess Bharma' },
// ];

const MaxChartFilter = ({
  activeAthlete,
  setActiveAthlete,
  tableData,
}) => {
  const [updateFilter, setUpdateFilter] = useState();
  const sidebarFilter = useSelector(state => state.reporting.data.sidebarFilter);

  const defaultAthleteOption = tableData.filter(
    athlete => athlete.userId === activeAthlete,
  );

  useEffect(() => {
    setUpdateFilter(0);
    Logger.debug('Active Athlete:', activeAthlete);
    Logger.debug('Default Athlete Option:', defaultAthleteOption);
  }, [activeAthlete]);

  return (
    <MainContainer>
      <FormHandler
        enableReinitialize
        initialValues={
          {
            activeAthlete: defaultAthleteOption[0],
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            Logger.debug('Form Values:', values);
          });
        }}
      >
        {(formProps) => {
          const tableDataNoAverage = tableData.filter(row => row.userFirst !== 'Average');

          return (
            <Form>
              <FormGroup>
                <Text fontSize='13pt' fontWeight='600' textTransform='uppercase' style={{ marginRight: 10 }}>
                  {`Compare current ${sidebarFilter.filterType.indicator} selection to `}
                </Text>
                <Select
                  // isDisabled={isLoadingSelections}
                  defaultValue={
                    defaultAthleteOption.length !== 0
                      ? ({ ...defaultAthleteOption[0] })
                      : tableDataNoAverage[0]
                  }
                  getOptionValue={option => (parseInt(option.userId, 10))}
                  getOptionLabel={option => `${option.userFirst} ${option.userLast}`}
                  classNamePrefix='react-select'
                  options={tableDataNoAverage}
                  onChange={(option) => {
                    formProps.setFieldValue('activeAthlete', option);
                    setActiveAthlete(parseInt(option.userId, 10));
                    // handleFetchSelections(accountCode, option.value);
                    // Logger.debug('Fetch Selections Params:', accountCode, option.value);
                  }}
                  value={formProps.values.activeAthlete}
                  // menuIsOpen
                />
              </FormGroup>
              {/* <FormGroup>
                <Select
                  // isDisabled={isLoadingSelections}
                  classNamePrefix='react-select'
                  defaultValue={exerciseOptions[0]}
                  options={exerciseOptions}
                  onChange={(option) => {
                    formProps.setFieldValue('activeExercise', option);
                    // handleFetchSelections(accountCode, option.value);
                    Logger.debug('Fetch Selections Params:', option.value, formProps.values);
                  }}
                  value={formProps.values.activeExercise}
                />
              </FormGroup> */}
            </Form>
          );
        }}
      </FormHandler>
    </MainContainer>
  );
};

MaxChartFilter.propTypes = {
  // chartWidth: PropTypes.number.isRequired,
};

export default MaxChartFilter;
