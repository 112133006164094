/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Dropdown from '../../../../shared/_TBComponents/Dropdown';
import Text from '../../../../shared/components/Text/Text';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { setHabitTrackingPageWrapperScrollTriggered, setDeleteModalData, setEditCategoryInfo } from '../../ducks/habitTrackingActions';
import { setModalOpen } from '../../../sports-science/ducks/sportsScienceActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ButtonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-basis: 280px;
  min-width: 280px;
  flex-grow: 1;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #3031331A;
  max-width: 750px;
  max-height: 346px;
  margin-top: ${(props) => (props.marginTop ? '77px' : '0px')};
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled('div')`
  border-radius: 8px;
  background: #FFFFFF;
  padding: 20px;
  width: 100%;
`;
const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  overflow-y: auto;
  height: 227px;
  margin-top: 15px;
`;

const CategoryRowWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px 8px 12px;
  width: 100%;
  align-items: center;
`;
const IconAndNameContainer = styled('div')`
  display: flex;
  gap: 10px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 50px;
  background: white;
  overflow: hidden;
  width: 100%;
  min-height: 32px;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  height: 40x;
`;

const SearchContainer = styled('input')`
  display: flex;
  border: none;
  background: none;
  padding-left: 5px;
  width: 100%;
  height: 40x;
`;

const AddNewCategoryButton = styled('button')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #444444;
  padding: 6px 16px 6px 16px;
  height: 32px;
  gap: 8px;
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 250ms ease-in-out;
  :hover {
    opacity: .7
  }
`;

const IconCircle = styled('div')`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: ${(props) => props.color};
  border: solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CategoriesCard = ({
  categories, marginTop, scrollTriggered,
}) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const categoryRefs = useRef({});
  const [containerWidth, setContainerWidth] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  const searchedCategories = categories?.filter((category) => category.name?.toLowerCase().includes(searchTerm.toLowerCase()));
  const editCategoryInfo = useSelector((state) => state.habitTracking.data.editCategoryInfo);
  const recentlyCreatedCategory = useSelector((state) => state.habitTracking.data.recentlyCreatedCategory);

  /** track if new category was added and scroll to bottom of category card */
  useEffect(() => {
    if (recentlyCreatedCategory) {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current?.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [recentlyCreatedCategory]);

  /** setting useEffect here to make sure edit info inset in state before
   * I open the edit modal that needs that data to display
   */
  useEffect(() => {
    if (shouldOpenModal && editCategoryInfo) {
      dispatch(setModalOpen(true, 'habitTracking-edit-category'));
      setShouldOpenModal(false); /** Reset state after opening the modal */
    }
  }, [editCategoryInfo, shouldOpenModal, dispatch]);

  const options = [
    {
      text: 'Edit',
      function: () => {
        const category = categories.find((clickedCategory) => clickedCategory.id === openDropdown);
        dispatch(setEditCategoryInfo(category));
        setShouldOpenModal(true);
      },
      icon: 'pencil',
    },
    {
      text: 'Delete',
      function: () => {
        const category = categories.find((clickedCategory) => clickedCategory.id === openDropdown);
        dispatch(setModalOpen(true, 'habitTracking-delete-category'));
        dispatch(setDeleteModalData({
          type: 'category',
          id: category?.id,
          name: category?.name,
        }));
      },
      icon: 'trashcan',
    },
  ];
  const handleDropdownToggle = (categoryId) => {
    setOpenDropdown(openDropdown === categoryId ? null : categoryId);
  };
  const handleScroll = () => {
    if (openDropdown) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    if (openDropdown && scrollTriggered) {
      setOpenDropdown(null);
    }
  }, [scrollTriggered]);

  useEffect(() => {
    if (openDropdown) {
      dispatch(setHabitTrackingPageWrapperScrollTriggered(false));
    }
  }, [openDropdown]);

  /** id's of default categories that sould not have the dropdown with options to delete/edit */
  const excludedIds = [1, 2, 3, 4, 5];

  return (
    <ButtonWrapper marginTop={marginTop}>
      <Wrapper>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <SearchAndIcon
          id='search-container'
          onClick={() => {
            setIsSearchFocused(true);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Category Search Clicked', 'User focused on search bar to search habit categories');
          }}
          isFocused={isSearchFocused}
        >
          <SearchIconContainer id='search-icon-container'>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='magnifying-glass-alt'
              color='#9E9E9E'
            />
          </SearchIconContainer>
          <SearchContainer
            placeholder='Search categories'
            id='search-container-input'
            value={searchTerm}
            onFocus={() => setIsSearchFocused(true)}
            onChange={(inputValue) => {
              setSearchTerm(inputValue.target.value);
            }}
          />
        </SearchAndIcon>
        <Container onScroll={handleScroll} ref={scrollContainerRef}>
          {searchedCategories?.map((category) => (
            <CategoryRowWrapper
              key={category.id}
              ref={(el) => { categoryRefs.current[category.id] = el; }}
            >
              <IconAndNameContainer>
                <IconCircle color={category?.color}>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={16}
                    icon={category?.icon || 'question-mark'}
                  />
                </IconCircle>
                <TBTooltip
                  textToDisplay={category?.name}
                  textToHover={category?.name}
                  fontSize='16px'
                  toolTipFontSize='13px'
                  toolTipFontWeight={400}
                  textContainerPadding='0px'
                  textContainerMaxWidth={`${containerWidth - 130}px`}
                  fontWeight={400}
                />
              </IconAndNameContainer>
              {/* <Dropdown
                key={category.id}
                options={options}
                isOpen={openDropdown === category.id}
                setIsOpen={() => handleDropdownToggle(category.id)}
                transform='rotate(90deg)'
                menuAbsolutePosition={-70}
                menuWidth='144px'
                menuBorderRadius='4px'
              /> */}
              {!excludedIds.includes(category.id) && (
              <Dropdown
                key={category.id}
                options={options}
                isOpen={openDropdown === category.id}
                setIsOpen={() => handleDropdownToggle(category.id)}
                transform='rotate(90deg)'
                menuAbsolutePosition={-70}
                menuWidth='144px'
                menuBorderRadius='4px'
              />
              )}
            </CategoryRowWrapper>
          ))}
          {searchTerm && !searchedCategories?.length && (
            <Text color='grey' textAlign='center' mt={30}>No options</Text>
          )}
        </Container>
      </Wrapper>
      <AddNewCategoryButton
        onClick={() => {
          dispatch(setModalOpen(true, 'habitTracking-create-category'));
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'Add New Category Clicked', 'User clicked add new category button');
        }}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={16}
          icon='plus'
          color='white'
        />
        <Text color='white' fontSize='13px' fontWeight={700}>Add new category</Text>
      </AddNewCategoryButton>
    </ButtonWrapper>
  );
};

export default CategoriesCard;

CategoriesCard.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  marginTop: PropTypes.bool,
  scrollTriggered: PropTypes.bool,
};

CategoriesCard.defaultProps = {
  marginTop: false,
  scrollTriggered: false,
};
