// imports action types and dispatches action to the back end for processessing and db

import axios from 'axios';
import {
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_REJECTED,
  FETCH_NOTIFICATIONS_FULFILLED,
  SET_ACTIVE_NOTIFICATION,
  SET_IS_FILTERS_CHANGING,
  SET_NOTIFICATION_FILTER,
  CLEAR_NOTIFICATION_FILTER,
  FETCH_NOTIFICATION_ASSET_START,
  FETCH_NOTIFICATION_ASSET_REJECTED,
  FETCH_NOTIFICATION_ASSET_FULFILLED,
  SET_SELECTED_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ_START,
  MARK_NOTIFICATION_AS_READ_REJECTED,
  MARK_NOTIFICATION_AS_READ_FULFILLED,
  MARK_NOTIFICATION_AS_DELETED_START,
  MARK_NOTIFICATION_AS_DELETED_REJECTED,
  MARK_NOTIFICATION_AS_DELETED_FULFILLED,
  SET_ACTIVE_REDUX_MODAL,
  SET_MESSAGE_MEDIA_URL,
  UPDATE_NOTIFICATION_START,
  UPDATE_NOTIFICATION_FULFILLED,
  UPDATE_NOTIFICATION_REJECTED,
  FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED,
  FETCH_NOTIFICATIONS_NEXT_PAGE_START,
  FETCH_UNREAD_NOTIFICATIONS_START,
  FETCH_UNREAD_NOTIFICATIONS_FULFILLED,
  FETCH_UNREAD_NOTIFICATIONS_REJECTED,
  SET_LOCAL_NOTIFICATION_FILTERS,
  SET_ACTIVE_NOTIFICATION_FILTERS,
  CLEAR_ACTIVE_NOTIFICATION_FILTERS,
  SET_NOTIFICATION_FILTER_COUNT,
  CLEAR_NOTIFICATION_FILTER_COUNT,
  FETCH_NOTIFICATION_TYPES_START,
  FETCH_NOTIFICATION_TYPES_REJECTED,
  FETCH_NOTIFICATION_TYPES_FULFILLED,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED,
  SET_IS_FILTER_DROPDOWN_SHOWING,
  SWITCH_NOTIFICATION_TYPE_SETTING_START,
  SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED,
  SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchNotifications = (
  accountCode, refreshAll = false,
) => (
  (dispatch, getState) => {
    const { activeNotificationFilters } = getState().notifications.data;
    const currentPage = getState().notifications.data.page;
    const page = currentPage && !refreshAll ? currentPage + 1 : 1;
    if (page === 1) {
      dispatch({ type: FETCH_NOTIFICATIONS_START });
    } else {
      dispatch({ type: FETCH_NOTIFICATIONS_NEXT_PAGE_START });
    }
    const pageSize = 50;
    let url = `/me/accounts/${accountCode}/notifications?page=${page}&pageSize=${pageSize}`;
    // Check to see if any filters are active
    const filterTypes = [];
    Object.keys(activeNotificationFilters).forEach((filterTypeKey) => {
      if (activeNotificationFilters[filterTypeKey]) {
        const filterTypeInt = parseInt(filterTypeKey, 10);
        if (!Number.isNaN(filterTypeInt)) {
          filterTypes.push(filterTypeInt);
        }
      }
    });
    if (filterTypes.length) {
      url += `&type=${filterTypes.join(',')}`;
    }
    axiosAuthed.get(url)
      .then((response) => {
        if (page === 1) {
          dispatch({
            type: FETCH_NOTIFICATIONS_FULFILLED,
            payload: {
              data: response.data,
              page,
            },
          });
        } else {
          dispatch({
            type: FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED,
            payload: {
              data: response.data,
              page,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveNotification = (notificationId) => (
  {
    type: SET_ACTIVE_NOTIFICATION,
    payload: notificationId,
  }
);

export const setNotificationFilter = (notificationType) => (
  (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_FILTER,
      payload: notificationType,
    });
  }
);

export const clearNotificationFilter = () => (
  {
    type: CLEAR_NOTIFICATION_FILTER,
  }
);

export const setLocalNotificationFilters = (filterTypes) => (
  (dispatch) => {
    dispatch({
      type: SET_LOCAL_NOTIFICATION_FILTERS,
      payload: filterTypes,
    });
  }
);

export const setActiveToLocalNotificationFilters = () => (dispatch, getState) => {
  const { activeNotificationFilters } = getState().notifications.data;
  dispatch({
    payload: activeNotificationFilters,
    type: SET_LOCAL_NOTIFICATION_FILTERS,
  });
};

export const setLocalToActiveNotificationFilters = () => (dispatch, getState) => {
  const { localNotificationFilters } = getState().notifications.data;
  dispatch({
    payload: localNotificationFilters,
    type: SET_ACTIVE_NOTIFICATION_FILTERS,
  });
  // Getting the count of active filters to update the filter badge
  const currentFilterCount = Object.entries(
    localNotificationFilters || {},
  ).filter(
    // array pos 0 is the filter id and 1 is a boolean (active or not)
    // eslint-disable-next-line no-restricted-globals
    (filterEntry) => !isNaN(filterEntry[0]) && filterEntry[1] === true,
  ).length;
  dispatch({
    payload: currentFilterCount,
    type: SET_NOTIFICATION_FILTER_COUNT,
  });
};

export const clearActiveNotificationFilters = () => (dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_NOTIFICATION_FILTERS });
};

export const setIsFiltersChanging = (bool) => (dispatch) => {
  dispatch({ type: SET_IS_FILTERS_CHANGING, payload: bool });
};

export const setNotificationFilterCount = (count) => (dispatch) => {
  dispatch({
    payload: count,
    type: SET_NOTIFICATION_FILTER_COUNT,
  });
};

export const clearNotificationFilterCount = (count) => (dispatch) => {
  dispatch({
    payload: count,
    type: CLEAR_NOTIFICATION_FILTER_COUNT,
  });
};

export const fetchNotificationAssetDetails = (assetApi, assetUrlPath) => (
  (dispatch) => {
    const axiosToUse = assetApi === 'PHP' ? axios : axiosAuthed;
    dispatch({ type: FETCH_NOTIFICATION_ASSET_START });
    axiosToUse.get(assetUrlPath)
      .then((response) => {
        dispatch({
          type: FETCH_NOTIFICATION_ASSET_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATION_ASSET_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchNotificationAssetDetailsNew = (activeNotification) => (
  (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION_ASSET_START });
    axiosAuthed.get(`/me/accounts/${activeNotification.accountCode}/notifications/${activeNotification.id}`)
      .then((response) => {
        dispatch({
          type: FETCH_NOTIFICATION_ASSET_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATION_ASSET_REJECTED,
          payload: err,
        });
      });
  }
);

export const setSelectedNotificationsRedux = (selections) => (
  {
    type: SET_SELECTED_NOTIFICATIONS,
    payload: selections,
  }
);

export const setIsFilterDropdownShowing = (bool) => (
  {
    type: SET_IS_FILTER_DROPDOWN_SHOWING,
    payload: bool,
  }
);

export const markNotificationsAsRead = (accountCode, notificationIds) => (
  (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_AS_READ_START });
    axiosAuthed.patch(`/me/accounts/${accountCode}/notifications/read`, notificationIds)
      .then((response) => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MARK_NOTIFICATION_AS_READ_REJECTED,
          payload: err,
        });
      });
  }
);

export const deleteNotifications = (accountCode, notificationIds) => (
  (dispatch) => {
    dispatch({ type: MARK_NOTIFICATION_AS_DELETED_START });
    axiosAuthed.patch(`/me/accounts/${accountCode}/notifications/delete`, notificationIds)
      .then((response) => {
        dispatch({
          type: MARK_NOTIFICATION_AS_DELETED_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MARK_NOTIFICATION_AS_DELETED_REJECTED,
          payload: err,
        });
      });
  }
);

export const openNotificationVideo = (mediaUrl) => (
  (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MEDIA_URL,
      payload: mediaUrl,
    });
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'play-message-video-modal',
    });
  }
);

export const setNotificationSettingsModal = () => (
  (dispatch) => {
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'settings-modal',
    });
  }
);

export const updateNotification = (accountCode, notificationId, updates) => (
  (dispatch) => {
    dispatch({ type: UPDATE_NOTIFICATION_START });
    axiosAuthed.put(`/me/accounts/${accountCode}/notifications/${notificationId}`, updates)
      .then((response) => {
        dispatch({
          type: UPDATE_NOTIFICATION_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_NOTIFICATION_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchUnreadNotifications = () => (
  (dispatch) => {
    dispatch({ type: FETCH_UNREAD_NOTIFICATIONS_START });
    axiosAuthed.get('/me/messages/unread')
      .then((response) => {
        dispatch({
          type: FETCH_UNREAD_NOTIFICATIONS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_UNREAD_NOTIFICATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchNotificationTypes = () => (
  (dispatch) => {
    dispatch({ type: FETCH_NOTIFICATION_TYPES_START });
    axiosAuthed.get('/me/notifications/types')
      .then((response) => {
        dispatch({
          type: FETCH_NOTIFICATION_TYPES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATION_TYPES_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchNotificationsByFilterType = (accountCode) => (
  (dispatch, getState) => {
    const { localNotificationFilters } = getState().notifications.data;
    const filterTypes = [];
    Object.keys(localNotificationFilters).forEach((filterTypeKey) => {
      if (localNotificationFilters[filterTypeKey]) {
        const filterTypeInt = parseInt(filterTypeKey, 10);
        if (!Number.isNaN(filterTypeInt)) {
          filterTypes.push(filterTypeInt);
        }
      }
    });
    let uri = `/me/accounts/${accountCode}/notifications`;
    if (filterTypes.length) {
      uri += `?type=${filterTypes}`;
    }
    dispatch({ type: FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START });
    axiosAuthed.get(uri)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED,
            payload: response.data,
          });
        }, 750);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED,
          payload: err,
        });
      });
  }
);

export const openImageLightbox = (mediaUrl) => (
  (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MEDIA_URL,
      payload: mediaUrl,
    });
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'image-lightbox',
    });
  }
);

export const switchNotificationTypeSetting = (dispatchedObject, typeIndex, bool, activeGroup) => (
  (dispatch) => {
    dispatch({ type: SWITCH_NOTIFICATION_TYPE_SETTING_START });
    axiosAuthed.put('/me/settings/notifications', dispatchedObject)
      .then((response) => {
        dispatch({
          type: SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED,
          payload: {
            data: response.data,
            typeIndex,
            bool,
            activeGroup,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED,
          payload: err,
        });
      });
  }
);
