import {
  FETCH_PROFILE_START,
  FETCH_PROFILE_FULFILLED,
  FETCH_PROFILE_REJECTED,
  FETCH_LATEST_SESSION_START,
  FETCH_LATEST_SESSION_FULFILLED,
  FETCH_LATEST_SESSION_REJECTED,
  FETCH_MULTIDAY_SESSION_START,
  FETCH_MULTIDAY_SESSION_FULFILLED,
  FETCH_MULTIDAY_SESSION_REJECTED,
  FETCH_SESSIONS_START,
  FETCH_SESSIONS_FULFILLED,
  FETCH_SESSIONS_REJECTED,
  UPDATE_PROFILE_IMAGE_START,
  UPDATE_PROFILE_IMAGE_FULFILLED,
  UPDATE_PROFILE_IMAGE_FAILED,
  // FETCH_ORGANIZATION_SETTINGS_START,
  // FETCH_ORGANIZATION_SETTINGS_FULFILLED,
  // FETCH_ORGANIZATION_SETTINGS_REJECTED,
  // UPDATE_ORGANIZATION_SETTINGS_START,
  // UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  // UPDATE_ORGANIZATION_SETTINGS_REJECTED,
  // UPDATE_NOTIFICATION_SETTINGS_START,
  // UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
  // UPDATE_NOTIFICATION_SETTINGS_REJECTED,
} from './actionTypes';

const initialState = {
  isLatestSessionLoading: undefined,
  isMeLoading: undefined,
  isSessionsLoading: undefined,
  isProfileImageLoading: false,
  isNotificationSettingsLoading: false,
  // isOrganizationSettingsLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_START: {
      return {
        ...state,
        isMeLoading: true,
      };
    }
    case FETCH_PROFILE_FULFILLED: {
      return {
        ...state,
        isMeLoading: false,
      };
    }
    case FETCH_PROFILE_REJECTED: {
      return {
        ...state,
        isMeLoading: false,
      };
    }
    case FETCH_LATEST_SESSION_START: {
      return {
        ...state,
        isLatestSessionLoading: true,
      };
    }
    case FETCH_LATEST_SESSION_FULFILLED: {
      return {
        ...state,
        isLatestSessionLoading: false,
      };
    }
    case FETCH_LATEST_SESSION_REJECTED: {
      return {
        ...state,
        isLatestSessionLoading: false,
      };
    }
    case FETCH_MULTIDAY_SESSION_START: {
      return {
        ...state,
        isLatestSessionLoading: true,
      };
    }
    case FETCH_MULTIDAY_SESSION_FULFILLED: {
      return {
        ...state,
        isLatestSessionLoading: false,
      };
    }
    case FETCH_MULTIDAY_SESSION_REJECTED: {
      return {
        ...state,
        isLatestSessionLoading: false,
      };
    }
    case FETCH_SESSIONS_START: {
      return {
        ...state,
        isSessionsLoading: true,
      };
    }
    case FETCH_SESSIONS_FULFILLED: {
      return {
        ...state,
        isSessionsLoading: false,
      };
    }
    case FETCH_SESSIONS_REJECTED: {
      return {
        ...state,
        isSessionsLoading: false,
      };
    }
    case UPDATE_PROFILE_IMAGE_START: {
      return {
        ...state,
        isProfileImageLoading: true,
      };
    }
    case UPDATE_PROFILE_IMAGE_FULFILLED: {
      return {
        ...state,
        isProfileImageLoading: false,
      };
    }
    case UPDATE_PROFILE_IMAGE_FAILED: {
      return {
        ...state,
        isProfileImageLoading: false,
      };
    }
    // case UPDATE_NOTIFICATION_SETTINGS_START: {
    //   return {
    //     ...state,
    //     isNotificationSettingsLoading: true,
    //   };
    // }
    // case UPDATE_NOTIFICATION_SETTINGS_FULFILLED: {
    //   return {
    //     ...state,
    //     isNotificationSettingsLoading: false,
    //   };
    // }
    // case UPDATE_NOTIFICATION_SETTINGS_REJECTED: {
    //   return {
    //     ...state,
    //     isNotificationSettingsLoading: false,
    //   };
    // }
    // case FETCH_ORGANIZATION_SETTINGS_START: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: true,
    //   };
    // }
    // case FETCH_ORGANIZATION_SETTINGS_FULFILLED: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: false,
    //   };
    // }
    // case FETCH_ORGANIZATION_SETTINGS_REJECTED: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: false,
    //   };
    // }
    // case UPDATE_ORGANIZATION_SETTINGS_START: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: true,
    //   };
    // }
    // case UPDATE_ORGANIZATION_SETTINGS_FULFILLED: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: false,
    //   };
    // }
    // case UPDATE_ORGANIZATION_SETTINGS_REJECTED: {
    //   return {
    //     ...state,
    //     isOrganizationSettingsLoading: false,
    //   };
    // }
    default: {
      return {
        ...state,
      };
    }
  }
}
