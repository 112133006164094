import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTheme } from 'emotion-theming';
import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import useReseller from '../../hooks/useReseller';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-update-button {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #0dcc8a;
    position: absolute;
    bottom: 0;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const ModalUpdateCoupon = (
  {
    onRequestClose,
    coupon,
  },
) => {
  const {
    currentUser,
    handleUpdateCoupon,
  } = useReseller();
  const theme = useTheme();
  const couponsError = useSelector((state) => state.reseller.data.couponsError);
  const isCouponUpdating = useSelector((state) => state.reseller.ui.isCouponUpdating);
  const [submitClicked, setSubmitClicked] = useState(false);
  const handleSubmit = (
    values,
  ) => {
    setSubmitClicked(true);
    handleUpdateCoupon(currentUser.accountCode, coupon.id, values);
  };

  const couponSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
  });

  return (
    <Formik
      initialValues={{
        name: coupon.name,
      }}
      validationSchema={couponSchema}
      onSubmit={(values) => {
        setTimeout(() => {
          handleSubmit(values);
        });
      }}
      render={({
        values,
        setFieldValue,
        setSubmitting,
        submitForm,
        errors,
      }) => (
        <ModalWrapper>
          <NavigationWrapper>
            <Text
              className='close arrow-div'
              onClick={() => {
                onRequestClose();
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={13}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <AnotherWrapper>
            <OuterFolderFormWrapper>
              <FolderTextWrapper>
                <Text className='calendar-icon-text'>
                  Update Coupon
                </Text>
                {couponsError && submitClicked ? <TitleError className='error-text'>{couponsError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <InnerFolderFormWrapper>
                <Form className='formik-form'>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Description</strong></Text>
                      <FieldError className='error-text'>{errors.name}</FieldError>
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='name-field'
                      name='name'
                      placeholder='Coupon Description'
                    />
                  </FormGroup>
                </Form>
              </InnerFolderFormWrapper>
            </OuterFolderFormWrapper>
          </AnotherWrapper>
          <NoHoverButton
            bottom
            fullWidth
            cta='Update Coupon'
            className='modal-update-button'
            customColor={theme.colors.green}
            large
            noBorder
            primary
            square
            isLoading={isCouponUpdating}
            loadingMessage='Updating...'
            onClick={() => {
              setSubmitting(true);
              submitForm();
            }}
          />
        </ModalWrapper>
      )}
    />
  );
};

ModalUpdateCoupon.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  coupon: PropTypes.instanceOf(Object).isRequired,
};

export default ModalUpdateCoupon;
