/* eslint-disable no-nested-ternary */
// modal for confirming deletion of selected documents

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';

import NoHoverButton from './NoHoverButton';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    display: flex;
    align-items: center;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
`;

const SubApplication = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: -40px;
`;

const ModalConfirmArchive = ({
  setSelectedRows,
  setSelectedChildRows,
  selectedRows,
  currentUser,
  handleArchiveCalendars,
  handleUnarchiveCalendars,
  selectedChildRows,
  singleArchive,
  isArchivedShowing,
  setSingleAction,
  onRequestClose,
  isSingleAction,
  activeSearchString,
}) => {
  const isCalendarSubmitting = useSelector(
    (state) => state.calendars.ui.isCalendarSubmitting,
  );

  const selectedChildRowsKeys = Object.keys(selectedChildRows);
  const selectedRowsKeys = Object.keys(selectedRows);

  const archiveCalendar = () => {
    let finalRows;
    if (selectedChildRowsKeys.length) {
      finalRows = selectedRowsKeys.concat(selectedChildRowsKeys);
    } else {
      finalRows = selectedRowsKeys;
    }
    const finalFinalRows = finalRows.map((string) => parseInt(string, 10));
    !isArchivedShowing ? handleArchiveCalendars(currentUser.accountCode, finalFinalRows)
      : handleUnarchiveCalendars(currentUser.accountCode, finalFinalRows);
  };
  const theme = useTheme();

  const languageMaker = () => {
    let languageString = ' ';
    if (
      (selectedRowsKeys.length + selectedChildRowsKeys.length) === 1) {
      languageString += 'this';
    } else {
      languageString += 'these';
    }
    languageString += ' ';
    if (
      (selectedRowsKeys.length + selectedChildRowsKeys.length) !== 1) {
      languageString += (selectedRowsKeys.length + selectedChildRowsKeys.length).toString();
    }
    languageString += ' ';
    if (
      (selectedRowsKeys.length + selectedChildRowsKeys.length) === 1) {
      languageString += 'calendar?';
    } else {
      languageString += 'calendars?';
    }
    return languageString;
  };

  return (
    <ModalWrapper>
      <ModalWrapper>
        <AnotherWrapper>
          <NavigationWrapper>
            <Text
              className='back-arrow-div'
              onClick={() => {
                onRequestClose();
                if (isSingleAction) {
                  setSelectedRows({});
                  setSelectedChildRows({});
                  setSingleAction(false);
                }
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <TextWrapper>
            <Text
              fontWeight='600'
              fontSize={theme.textFontSizes[2]}
              style={{
                marginTop: '-10px',
              }}
            >
              Are you sure you want to
              {' '}
              {isArchivedShowing ? 'unarchive' : 'archive'}
              {singleArchive ? ' this calendar?' : languageMaker()}
            </Text>
            {(activeSearchString && selectedChildRowsKeys.length && selectedRowsKeys.length) ? (
              <SubApplication>
                (Including
                {' '}
                {selectedChildRowsKeys.length}
                {' '}
                sub-calendars)
              </SubApplication>
            ) : null}
          </TextWrapper>
        </AnotherWrapper>
        <NoHoverButton
          bottom
          fullWidth
          cta={
            (!isCalendarSubmitting
              ? `${isArchivedShowing
                ? 'Unarchive' : 'Archive'} ${(selectedRowsKeys.length + selectedChildRowsKeys.length) > 1
                ? 'calendars' : 'calendar'}`
              : (isArchivedShowing
                ? 'Unarchiving...' : 'Archiving...')
            )
          }
          customColor='#428BCA'
          className='modal-button'
          large
          noBorder
          primary
          square
          onClick={() => (archiveCalendar())}
          disabled={isCalendarSubmitting}
          noHover
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalConfirmArchive.propTypes = {
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  handleArchiveCalendars: PropTypes.func.isRequired,
  handleUnarchiveCalendars: PropTypes.func.isRequired,
  singleArchive: PropTypes.bool.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
  setSelectedChildRows: PropTypes.func.isRequired,
  selectedChildRows: PropTypes.instanceOf(Array).isRequired,
  setSingleAction: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  isSingleAction: PropTypes.bool.isRequired,
};

export default ModalConfirmArchive;
