/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Avatar from '../../../../../shared/components/Avatar/Avatar';

const OrganizationSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const isOrganizationSettingsLoading = useSelector((state) => state.auth.ui.isOrganizationSettingsLoading);
  const useLightTextColor = organizationSettings?.skin?.useLightTextColor;

  const AccountInfo = styled('div')`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
 `;

  const Container = styled('div')`
    display: flex;
    height: 134px;
    width: 100%;
    border-radius: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 23px 27px 23px 40px;
    background-color: ${organizationSettings?.skin?.primaryColor ? `${organizationSettings?.skin?.primaryColor}` : `${theme.colors.black}`};
    margin-bottom: 20px;
    cursor: pointer;
    border-width: ${!useLightTextColor && '1px'};
    border-style: ${!useLightTextColor && 'solid'};
    border-color: ${!useLightTextColor && '#444444'};
  `;

  const LoadingShimmerContainer = styled('div')`
  display: flex;
  height: 134px;
  width: 100%;
  border-radius: 24px;
  padding: 23px 27px 23px 40px;
  margin-bottom: 20px;
  `;

  const InfoWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  return (
    <>
      { Object.keys(organizationSettings).length === 0 || isOrganizationSettingsLoading ? (
        <Shimmer>
          <LoadingShimmerContainer />
        </Shimmer>
      ) : (
        <Container onClick={() => { dispatch(push('/settings/organization')); }}>
          <ResizeObserver
            onResize={(rect) => {
              setContainerWidth(rect.width);
            }}
          />
          <InfoWrapper>
            <Avatar avatar={organizationSettings?.skin?.logo} size={containerWidth && containerWidth < 450 ? 90 : 90} borderWidth={2} borderColor='white' backgroundColor={organizationSettings?.skin?.primaryColor} scaled />
            <AccountInfo>
              <Text color={useLightTextColor ? 'white' : '#444444'} fontSize={containerWidth && containerWidth < 450 ? 20 : 28} fontWeight='700'>
                {organizationSettings?.organization?.name}
              </Text>
              <Text color={useLightTextColor ? 'white' : '#444444'} fontSize={containerWidth && containerWidth < 450 ? 16 : 18} fontWeight='400'>
                Organization Settings
              </Text>
            </AccountInfo>
          </InfoWrapper>
          <IcomoonReact
            iconSet={iconSet}
            size={24}
            icon='right-arrow'
            color={useLightTextColor ? 'white' : '#444444'}
          />
        </Container>
      )}
    </>
  );
};

export default OrganizationSettings;
