import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ModalLogin from './ModalLogin';
import ModalDownload from './ModalDownload';
import { sharedWorkoutModal } from '../ducks/sharedWorkoutActions';

const ModalOverlay = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalHandler = () => {
  const activeModal = useSelector((state) => state.sharedWorkout.ui.activeModal);
  const isDownloadButtonClicked = useSelector(
    (state) => state.sharedWorkout.ui.isDownloadButtonClicked,
  );

  const dispatch = useDispatch();

  const setModal = (modal) => {
    setTimeout(() => {
      dispatch(sharedWorkoutModal(modal));
    }, 800);
  };

  const closeModal = () => {
    dispatch(sharedWorkoutModal(''));
  };

  if (activeModal) {
    return (
      <>
        {activeModal === 'login' && (
          <>
            <ModalOverlay onClick={closeModal} />
            <ModalLogin setModal={setModal} />
          </>
        )}
        {activeModal === 'download' && isDownloadButtonClicked
          ? (
            <>
              <ModalOverlay onClick={closeModal} />
              <ModalDownload setModal={setModal} />
            </>
          )
          : null}
      </>
    );
  }
  return null;
};
export default ModalHandler;
