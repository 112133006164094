import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Avatar from '../../../../../shared/components/Avatar/Avatar';

const AccountSettings = () => {
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const me = useSelector((state) => state.auth.data.currentUser);
  const isMeLoading = useSelector((state) => state.auth.ui.isLoginLoading);
  const isContentLoading = isMeLoading || Object.keys(me).length === 0;

  const AccountInfo = styled('div')`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
 `;

  const Container = styled('div')`
    display: flex;
    height: 134px;
    width: 100%;
    border-radius: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 23px 27px 23px 40px;
    background-image: ${me?.userSkin?.gradientColors ? `linear-gradient(45deg, ${me?.userSkin?.gradientColors[0]}, ${me?.userSkin?.gradientColors[1]})` : 'none'};
    margin-bottom: 20px;
    cursor: pointer;
  }
  `;

  const LoadingShimmerContainer = styled('div')`
  display: flex;
  height: 134px;
  width: 100%;
  border-radius: 24px;
  padding: 23px 27px 23px 40px;
  margin-bottom: 20px;
  `;

  const InfoWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;  
  `;

  return (
    <>
      {isContentLoading ? (
        <Shimmer>
          <LoadingShimmerContainer />
        </Shimmer>
      ) : (
        <Container onClick={() => { dispatch(push('/settings/account')); }}>
          <ResizeObserver
            onResize={(rect) => {
              setContainerWidth(rect.width);
            }}
          />
          <InfoWrapper>
            <Avatar avatar={me?.pic} size={containerWidth && containerWidth < 450 ? 90 : 90} borderWidth={2} borderColor='white' />
            <AccountInfo>
              <Text color='white' fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
                {me?.first}
                {' '}
                {me?.last}
              </Text>
              <Text color='white' fontSize={containerWidth && containerWidth < 450 ? 16 : 18} fontWeight='400'>
                Account Settings
              </Text>
            </AccountInfo>
          </InfoWrapper>
          <IcomoonReact
            iconSet={iconSet}
            size={24}
            icon='right-arrow'
            color='white'
          />
        </Container>
      )}
    </>
  );
};

export default AccountSettings;
