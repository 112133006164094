/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  Form, FieldArray,
} from 'formik';
import { useTheme } from 'emotion-theming';
import Logger from 'js-logger';
import ResizeObserver from 'react-resize-observer';
import Select from 'react-select';
import Link from '../../../../shared/components/Link/Link';
import Text from '../../../../shared/components/Text/Text';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';
import Button from '../../../../shared/components/Button/Button';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    width: 100%;
  }
  .first-stage-select {
    width: 100%;
  }

  .maxes-select {
    width: 70%;
  }

  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }

  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 10px;
  margin-left: 8px;
`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 40px;
  gap: 10px;
`;

const TextBoxContainer = styled('div')`
  border-color: ${(props) => props.borderColor};
  border-width: ${(props) => props.borderWidth};
  border-style: solid;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  box-shadow: 0px 8px 16px rgba(48, 49, 51, 0.1);
  border-radius: 10px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #D9D9D9;
  max-width: 278.55px;
  min-width: 100px;
  height: 168.12px;
`;

const TextBoxTitle = styled('div')`
  font-size: ${(props) => (props.containerWidth < 320 ? '11px' : '16px')};
  font-weight: 700;
  line-height: 36px;
  text-transform: uppercase;
  padding: 12px;
`;

const LinkContainer = styled('div')`
  width: 100%;
  margin-top: 20px;
  margin-left: 15px;
  
  @media screen and (max-width: 540px) {
    button {
      margin-left: 0px!important;
    }
  }
`;

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 15px 15px 15px;
  min-width: 50%;
  max-width: 50%;
  position: relative;

  .remove-link {
    position: absolute;
    right: 0;
    margin-right: 16px
  }

  @media screen and (max-width: 540px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const FieldErrorAndQuestionsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const QuestionnaireModalFormSlideOne = ({
  formProps,
  questions,
  questionnaires,
}) => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState({});
  useEffect(() => {
    /** whatch for updates in the questions being chosen and deleted, update array of questions
     * based on which question id objects have values, those are the values to pass to the api
     * and those are the values we are validating
     */
    if (formProps.values.selectionType === 'selectIndividualQuestions') {
      const filteredArray = formProps.values.questionIds.filter((question) => question.id !== '');
      formProps.setFieldValue('arrayOfQuestions', filteredArray);
    }
  }, [formProps.values.questionIds]);

  const questionLimit = questions.length >= 9 ? 9 : questions.length;
  /** filter the options to make sure a user can't select the same quesiton 2x */
  // const filteredOptionsArray = questions.filter((val) => !formProps.values.arrayOfQuestions.includes(val));
  /** filter the options to make sure a user can't select the same quesiton 2x */
  const filteredOptionsArray = questions.filter((question) => !formProps.values.questionIds.includes(question));

  const onClickTextBox = (selectionType) => {
    if (selectionType === 'selectIndividualQuestions') {
      formProps.setFieldValue('selectionType', 'selectIndividualQuestions');
    } else {
      formProps.setFieldValue('selectionType', 'selectAllQuestions');
    }
  };

  return (
    <>
      <ModalWrapper>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <InnerFolderFormWrapper>
          <Form className='formik-form'>
            <FormGroup>
              <TitlePlusErrorWrapper>
                <Text>How would you like to select the questions for this report?</Text>
              </TitlePlusErrorWrapper>
              <TextBoxOuterWrapper>
                <TextBoxContainer
                  onClick={() => {
                    const selectionType = 'selectAllQuestions';
                    onClickTextBox(selectionType);
                    if (formProps.values.arrayOfQuestions.length) {
                      formProps.setFieldValue('arrayOfQuestions', []);
                      formProps.setFieldValue('questionIds', ['']);
                    }
                    formProps.setErrors({});
                    formProps.setTouched({});
                  }}
                  borderColor={formProps.values.selectionType === 'selectAllQuestions' ? theme.colors.green : 'hsl(0,0%,80%)'}
                  borderWidth={formProps.values.selectionType === 'selectAllQuestions' ? '2px' : '0px'}
                  marginRight='10px'
                >
                  <TextBoxTitle containerWidth={containerWidth}>
                    Select All Questions From Questionnaire
                  </TextBoxTitle>
                </TextBoxContainer>
                <TextBoxContainer
                  onClick={() => {
                    const selectionType = 'selectIndividualQuestions';
                    onClickTextBox(selectionType);
                    formProps.setErrors({});
                    formProps.setTouched({});
                    if (formProps.values.arrayOfQuestions.length) {
                      formProps.setFieldValue('arrayOfQuestions', []);
                      formProps.setFieldValue('questionnaireIds', ['']);
                      formProps.setFieldValue('questionnaire', null);
                    }
                  }}
                  borderColor={formProps.values.selectionType === 'selectIndividualQuestions' ? theme.colors.green : 'hsl(0,0%,80%)'}
                  borderWidth={formProps.values.selectionType === 'selectIndividualQuestions' ? '2px' : '0px'}
                  marginRight='10px'
                >
                  <TextBoxTitle containerWidth={containerWidth}>
                    Select Individual Questions
                  </TextBoxTitle>
                </TextBoxContainer>
              </TextBoxOuterWrapper>
            </FormGroup>
            {formProps.values.selectionType === 'selectIndividualQuestions' ? (
              <FormGroup>
                <FormLabel>Select Questions</FormLabel>
                <FieldErrorAndQuestionsContainer>
                  <FieldArray
                    name='questionIds'
                    render={(arrayHelpers) => (
                      <>
                        <FormColumns>
                          {formProps.values.questionIds.length > 0 && (
                            formProps.values.questionIds?.map((question, index) => (
                              <ColumnContent>
                                <FormLabel>
                                  Question #
                                  {index + 1}
                                </FormLabel>
                                {index !== 0 && (
                                <Link
                                  className='remove-link'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    arrayHelpers.remove(index);
                                  }}
                                  type='button'
                                >
                                  &#10005;
                                </Link>
                                )}
                                <Select
                                  classNamePrefix='react-select'
                                  defaultValue={formProps.values.arrayOfQuestions[index]}
                                  options={filteredOptionsArray}
                                  getOptionLabel={(option) => option.title}
                                  getOptionValue={(option) => option.id}
                                  name='question'
                                  onChange={(option) => {
                                    formProps.setFieldValue(`questionIds.${index}`, option);
                                    Logger.debug('Questions Array', formProps.values.questionIds);
                                  }}
                                  isOptionSelected={(option) => formProps.values.questionId === option.id}
                                  value={formProps.values.questionIds[index].id === '' ? null : formProps.values.questionIds[index]}
                                />
                              </ColumnContent>
                            ))
                          )}
                        </FormColumns>
                        {formProps.values.questionIds.length <= (questionLimit) && (
                        <LinkContainer>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              // arrayHelpers.push('');
                              arrayHelpers.push({ title: '', id: '' });
                            }}
                            cta='Add Question'
                            icon='plus'
                          />
                        </LinkContainer>
                        )}

                      </>
                    )}
                  />
                  {formProps.errors.arrayOfQuestions && formProps.touched.arrayOfQuestions && <FieldError className='text-center'>{formProps.errors.arrayOfQuestions}</FieldError>}
                </FieldErrorAndQuestionsContainer>
              </FormGroup>
            ) : (
              <>
                <Select
                  classNamePrefix='react-select'
                  defaultValue={formProps.values.arrayOfQuestions}
                  options={questionnaires}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  name='question'
                  onChange={(option) => {
                    formProps.setFieldValue('arrayOfQuestions', option.questions);
                    formProps.setFieldValue('questionnaireIds', [option.id]);
                    formProps.setFieldValue('questionnaire', option);
                    Logger.debug('Questions Array', formProps.values.questionIds);
                  }}
                  value={formProps.values.questionnaire}
                />
                {formProps.errors.arrayOfQuestions && formProps.touched.arrayOfQuestions && <FieldError className='text-center'>Please select a questionnaire</FieldError>}
              </>
            )}
          </Form>
        </InnerFolderFormWrapper>
      </ModalWrapper>
    </>
  );
};

QuestionnaireModalFormSlideOne.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  questions: PropTypes.instanceOf(Array),
  questionnaires: PropTypes.instanceOf(Array),
};

QuestionnaireModalFormSlideOne.defaultProps = {
  questions: [],
  questionnaires: [],
};

export default QuestionnaireModalFormSlideOne;
