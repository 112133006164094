/* eslint-disable react/jsx-filename-extension */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Webcam from 'react-webcam';

import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import NoHoverButton from './NoHoverButton';

import {
  setCaptureImageModal,
  uploadWorkoutsMedia,
  uploadWorkoutJournalMedia,
} from '../../../ducks/workoutsActions';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
    color: white!important;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
`;

const AnotherWrapper = styled('div')`
  position: relative;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  margin-bottom: 30px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const SpinnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

const WebcamContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 26px;
    font-weight: 300;
  }
  .capture-button {
    width: 180px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const MediaModalContent = () => {
  const uploadedWorkoutsMedia = useSelector((state) => state.workouts.data.uploadedWorkoutsMedia);
  const uploadedWorkoutJournalMediaObject = useSelector(
    (state) => state.workouts.data.uploadedWorkoutJournalMediaObject,
  );
  const [isCaptured, setIsCaptured] = useState(false);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const journalEntryWorkout = useSelector(
    (state) => state.workouts.data.journalEntryWorkout,
  );

  const webcamRef = useRef(null);

  const dispatch = useDispatch();

  const playerMasher = () => {
    if (isCaptured) {
      if ((Object.keys(uploadedWorkoutsMedia).length
      && uploadedWorkoutsMedia.mediaUrl)
      || (Object.keys(uploadedWorkoutJournalMediaObject).length
      && uploadedWorkoutJournalMediaObject.mediaUrl)) {
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <img
              alt=''
              src={uploadedWorkoutsMedia.mediaUrl || uploadedWorkoutJournalMediaObject.mediaUrl}
            />
          </div>
        );
      }
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }
    return (
      <Webcam
        audio={false}
        width='100%'
        height='100%'
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        screenshotQuality={1}
      />
    );
  };

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const imageBuff = Buffer.from(imageSrc.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const image = new File([imageBuff], 'jpg', {
        type: 'image/jpeg',
      });
      const imgArray = [image];
      setIsCaptured(true);
      if (!Object.keys(journalEntryWorkout).length) {
        dispatch(uploadWorkoutsMedia({ accountCode: currentUser.accountCode, acceptedFile: imgArray, type: 'image' }));
      } else {
        dispatch(uploadWorkoutJournalMedia(
          { accountCode: currentUser.accountCode, acceptedFile: imgArray, type: 'image' },
        ));
      }
    },
    [webcamRef],
  );

  const buttonMasher = () => {
    if (!isCaptured) {
      return (
        <NoHoverButton
          className='modal-button'
          onClick={capture}
          cta='Take Photo'
          customColor='#10cd8c'
          noHover
          noBorder
          bottom
          fullWidth
          large
          primary
          square
        />
      );
    }
    return (
      <NoHoverButton
        bottom
        fullWidth
        cta='Done'
        customColor='#10cd8c'
        className='modal-button'
        onClick={() => dispatch(setCaptureImageModal(false))}
        large
        noBorder
        primary
        square
        noHover
      />
    );
  };

  return (
    <ModalWrapper>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            dispatch(setCaptureImageModal(false));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
            color='white'
          />
        </Text>
      </NavigationWrapper>
      <InnerWrapper>
        <AnotherWrapper>
          <WebcamContainer>
            {playerMasher()}
            {buttonMasher()}
          </WebcamContainer>
        </AnotherWrapper>
      </InnerWrapper>
    </ModalWrapper>
  );
};

MediaModalContent.propTypes = {

};

export default MediaModalContent;
