export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_START';

export const FETCH_PERFORMANCE_OVERVIEW_START = 'FETCH_PERFORMANCE_OVERVIEW_START';
export const FETCH_PERFORMANCE_OVERVIEW_FULFILLED = 'FETCH_PERFORMANCE_OVERVIEW_FULFILLED';
export const FETCH_PERFORMANCE_OVERVIEW_REJECTED = 'FETCH_PERFORMANCE_OVERVIEW_REJECTED';

export const FETCH_METRIC_CARD_DATA_START = 'FETCH_METRIC_CARD_DATA_START';
export const FETCH_METRIC_CARD_DATA_FULFILLED = 'FETCH_METRIC_CARD_DATA_FULFILLED';
export const FETCH_METRIC_CARD_DATA_REJECTED = 'FETCH_METRIC_CARD_DATA_REJECTED';

export const FETCH_GROUP_ANALYSIS_START = 'FETCH_GROUP_ANALYSIS_START';
export const FETCH_GROUP_ANALYSIS_FULFILLED = 'FETCH_GROUP_ANALYSIS_FULFILLED';
export const FETCH_GROUP_ANALYSIS_REJECTED = 'FETCH_GROUP_ANALYSIS_REJECTED';

export const SET_IS_METRIC_INFO_MODAL_SHOWING = 'SET_IS_METRIC_INFO_MODAL_SHOWING';

export const SET_IS_FP_LEGEND_INFO_MODAL_SHOWING = 'SET_IS_FP_LEGEND_INFO_MODAL_SHOWING';

export const SET_SELECTED_ATHLETE_PERFORMANCE = 'SET_SELECTED_ATHLETE_PERFORMANCE';
export const SET_SELECTED_ATHLETE_GROUP = 'SET_SELECTED_ATHLETE_GROUP';

export const SET_IS_METRIC_FILTERS_GROUP_SHOWING = 'SET_IS_METRIC_FILTERS_GROUP_SHOWING';
export const SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING = 'SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING';

export const SET_METRIC_FILTERS_PERFORMANCE = 'SET_METRIC_FILTERS_PERFORMANCE';
export const SET_METRIC_FILTERS_GROUP = 'SET_METRIC_FILTERS_GROUP';

export const SET_GROUP_FILTER_RADIO = 'SET_GROUP_FILTER_RADIO';

export const SET_START_DATE_DATA = 'SET_START_DATE_DATA';
export const SET_END_DATE_DATA = 'SET_END_DATE_DATA';


export const SET_GROUP = 'SET_GROUP';


export const SET_HABIT_TRACKING_VIEW = 'SET_HABIT_TRACKING_VIEW';

export const SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE = 'SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE';
export const SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP = 'SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP';

export const SET_FLAGGED_ATHLETES_VIEW = 'SET_FLAGGED_ATHLETES_VIEW';

export const SET_FLAGGED_ATHLETES = 'SET_FLAGGED_ATHLETES';
export const SET_FLAGGED_ATHLETES_PERFORMANCE = 'SET_FLAGGED_ATHLETES_PERFORMANCE';
export const SET_FLAGGED_ATHLETES_GROUP = 'SET_FLAGGED_ATHLETES_GROUP';




export const FETCH_ATHLETES_START_HT_DASHBOARD = 'FETCH_ATHLETES_START_HT_DASHBOARD';
export const FETCH_ATHLETES_FULFILLED_HT_DASHBOARD = 'FETCH_ATHLETES_FULFILLED_HT_DASHBOARD';
export const FETCH_ATHLETES_REJECTED_HT_DASHBOARD = 'FETCH_ATHLETES_REJECTED_HT_DASHBOARD';


export const FETCH_HABITS_FOR_ACCOUNT_START = 'FETCH_HABITS_FOR_ACCOUNT_START';
export const FETCH_HABITS_FOR_ACCOUNT_FULFILLED = 'FETCH_HABITS_FOR_ACCOUNT_FULFILLED';
export const FETCH_HABITS_FOR_ACCOUNT_REJECTED = 'FETCH_HABITS_FOR_ACCOUNT_REJECTED';


export const FETCH_OVERVIEW_START = 'FETCH_OVERVIEW_START';
export const FETCH_OVERVIEW_FULFILLED = 'FETCH_OVERVIEW_FULFILLED';
export const FETCH_OVERVIEW_REJECTED = 'FETCH_OVERVIEW_REJECTED';


export const FETCH_GROUP_OVERVIEW_START = 'FETCH_GROUP_OVERVIEW_START';
export const FETCH_GROUP_OVERVIEW_FULFILLED = 'FETCH_GROUP_OVERVIEW_FULFILLED';
export const FETCH_GROUP_OVERVIEW_REJECTED = 'FETCH_GROUP_OVERVIEW_REJECTED';

export const FETCH_GROUP_HABITS_START = 'FETCH_GROUP_HABITS_START';
export const FETCH_GROUP_HABITS_FULFILLED = 'FETCH_GROUP_HABITS_FULFILLED';
export const FETCH_GROUP_HABITS_REJECTED = 'FETCH_GROUP_HABITS_REJECTED';

export const SET_SELECTED_HABITS = 'SET_SELECTED_HABITS';
export const SET_SELECTED_GROUP_HABITS = 'SET_SELECTED_GROUP_HABITS';

export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';

export const RESET_OVERVIEW_DATA = 'RESET_OVERVIEW_DATA';
export const RESET_GROUP_OVERVIEW_DATA = 'RESET_GROUP_OVERVIEW_DATA';

export const SET_WEEK_RANGE = 'SET_WEEK_RANGE';

export const SET_SELECTED_GROUP_DROPDOWN_OPTIONS = 'SET_SELECTED_GROUP_DROPDOWN_OPTIONS';
export const SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS = 'SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS';

export const SET_IS_GROUP_FILTER_SHOWING = 'SET_IS_GROUP_FILTER_SHOWING';

export const SET_IS_CHART_OPAQUE = 'SET_IS_CHART_OPAQUE';

export const SET_ONBOARDING_MODAL_OPEN = 'SET_ONBOARDING_MODAL_OPEN';

export const FETCH_VIEWED_FEATURES = 'FETCH_VIEWED_FEATURES';

export const SET_HABITS = 'SET_HABITS';

export const FETCH_GROUP_OVERVIEW_HABITS_START = 'FETCH_GROUP_OVERVIEW_HABITS_START';
export const FETCH_GROUP_OVERVIEW_HABITS_FULFILLED = 'FETCH_GROUP_OVERVIEW_HABITS_FULFILLED';
export const FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED = 'FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED';
