/* eslint-disable react/prop-types */
import React, {
  useEffect, useState, useRef,
} from 'react';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import { toast } from 'react-toastify';
import ResizeObserver from 'react-resize-observer';
import { batch, useSelector, useDispatch } from 'react-redux';
import { animated, useTransition, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { Form } from 'formik';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import FormHandler from '../../../../shared/components/FormHandler/FormHandler';
import {
  fetchEvaluations,
  fetchEvaluationReportGA,
  fetchEvaluationReportOverview,
  resetEvaluationReportGAError,
} from '../../ducks/reportingActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import EvaluationReportFormSlideOne from './EvaluationReportFormSlideOne';
import EvaluationReportFormSlideTwo from './EvaluationReportFormSlideTwo';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';
import { reportInteractionTracker } from '../../../../shared/utils/amplitudeHelper';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 135px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 30px;
  margin: 0px auto;
  margin-top: 30px;
  z-index: 10;
  position: relative;

  // To prevent two-dot nav from overlapping bottom button
  @media (width < 768px) {
    margin: 0px auto 15px auto;
  }
  @media (width < 581px) {
    margin: 0px auto -40px auto;
  }
`;

const NavCircle = styled('div')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const EvaluationReportForm = ({
  closeModal,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();
  const theme = useTheme();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const evaluationReportGAError = useSelector(
    (state) => state.reporting.data.evaluationReportGAError,
  );
  const evaluations = useSelector((state) => state.reporting.data.evaluations);
  const isLoadingEvaluationReport = useSelector(
    (state) => state.reporting.ui.isLoadingEvaluationReport,
  );
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);

  const { accountCode } = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEvaluations(accountCode));
    dispatch(resetEvaluationReportGAError());
  }, []);

  const fetchReport = (values) => {
    Logger.debug(sidebarFilter);
    if (sidebarFilter.filterIds.length) {
      batch(() => {
        dispatch(fetchEvaluationReportGA(accountCode, values, sidebarFilter));
        dispatch(fetchEvaluationReportOverview(accountCode, values, sidebarFilter));
      });
    } else {
      toast.error('You must select filters before continuing');

      // Amplitude tracker.
      reportInteractionTracker('Report Error', 'Evaluation', 'Reporting Filter has not been selected.');
    }
  };

  const formAnimation = useSpring({
    opacity: isLoadingEvaluationReport ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingEvaluationReport ? 1 : 0, zIndex: 0 });

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(-25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(25%, 0, 0)' },
  });

  return (
    <>
      <NavigationWrapper>
        {activeSlide === 1 && (
          <Text
            className='back arrow-div'
            onClick={() => {
              setActiveSlide(0);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='left-arrow'
            />
          </Text>
        )}
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
            {
              evaluation: '',
              firstStartDate: {
                date: null,
                queryFormat: '',
              },
              firstEndDate: {
                date: null,
                queryFormat: '',
              },
              secondStartDate: {
                date: null,
                queryFormat: '',
              },
              secondEndDate: {
                date: null,
                queryFormat: '',
              },
            }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            Logger.debug('Form Values:', values);
            fetchReport(values);
          });
        }}
      >
        {(formProps) => (
          <Form
            style={{ width: '100%' }}
          >
            <animated.div style={formAnimation}>
              {evaluationReportGAError && (
              <FieldError className='text-center'>
                {evaluationReportGAError === '\'evaluationId\' should be integer' ? 'Please select an evaluation' : evaluationReportGAError}
              </FieldError>
              )}
              {formProps.errors.exercise && formProps.touched.exercise ? (
                <FieldError className='text-center'>{formProps.errors.exercise}</FieldError>
              ) : null}
              <SlideContainer
                height={slideDimensions.height}
              >
                {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                {slideTransition.map(({ item, props }) => (
                  item === 0 ? (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <ResizeObserver
                        onResize={(rect) => {
                          setSlideDimensions(rect);
                        }}
                      />
                      <EvaluationReportFormSlideOne
                        formProps={formProps}
                        evaluations={evaluations}
                      />
                    </animated.div>
                  ) : (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <ResizeObserver
                        onResize={(rect) => {
                          setSlideDimensions(rect);
                        }}
                      />
                      <EvaluationReportFormSlideTwo
                        formProps={formProps}
                      />
                    </animated.div>
                  )))}
              </SlideContainer>
              <SliderNav>
                <NavCircle
                  className={activeSlide === 0 ? 'active' : ''}
                  onClick={() => {
                    setActiveSlide(0);
                  }}
                />
                <NavCircle
                  className={activeSlide === 1 ? 'active' : ''}
                  onClick={() => setActiveSlide(1)}
                />
              </SliderNav>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>
            {activeSlide !== 0 && (
            <Button
              cta='Run Report'
              className='modal-button'
              type='submit'
              customColor={theme.colors.green}
              fullWidth
              noBorder
              large
              square
              primary
              bottom
            />
            )}
          </Form>
        )}
      </FormHandler>
      {activeSlide === 0 && (
        <Button
          onClick={() => setActiveSlide(1)}
          className='modal-button'
          cta='next'
          type='button'
          customColor={theme.colors.green}
          fullWidth
          noBorder
          large
          square
          primary
          bottom
        />
      )}
    </>
  );
};

export default EvaluationReportForm;
