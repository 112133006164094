import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../../../shared/components/Text/Text';
import iconSet from '../../../../../../shared/images/teambuildr-selection.json';

const Dropdown = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  top: 100%;
  margin-top: 15px;
  margin-left: -16px;
  height: max-content;
  width: 232px;
  border-radius: 12px;
  padding: 6px 0;
`;

const DropdownOption = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const FilterDropdown = ({
  filters, setFilters, filterOptions, setShowDropdown, setActiveDropdown,
}) => (
  <Dropdown>
    { filterOptions.map((filterOption) => (
      <DropdownOption
        key={filterOption.id}
        onClick={() => {
          const existingFilterOption = filters.find((filter) => filter.id === filterOption.id);
          if (!existingFilterOption) {
            setFilters([
              ...filters,
              filterOption,
            ]);
          }
          setShowDropdown(false);
          setActiveDropdown(filterOption.dropdown);
          return filters;
        }}
      >
        <Text>{filterOption.label}</Text>
        <IcomoonReact iconSet={iconSet} size={15} icon='right-arrow' color='#FF6600' />
      </DropdownOption>
    ))}
  </Dropdown>
);

FilterDropdown.propTypes = {
  filters: PropTypes.instanceOf(Object).isRequired,
  setFilters: PropTypes.func.isRequired,
  filterOptions: PropTypes.instanceOf(Object).isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  setActiveDropdown: PropTypes.func.isRequired,
};

export default FilterDropdown;
