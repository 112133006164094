import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_FULFILLED,
  FETCH_DOCUMENTS_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_FOLDER_START,
  FETCH_FOLDER_FULFILLED,
  FETCH_FOLDER_REJECTED,
  FETCH_SEARCH_DOCUMENTS_START,
  FETCH_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_SEARCH_DOCUMENTS_REJECTED,
  CREATE_DOCUMENT_START,
  CREATE_DOCUMENT_FULFILLED,
  CREATE_DOCUMENT_REJECTED,
  UPLOAD_DOCUMENT_START,
  UPLOAD_DOCUMENT_FULFILLED,
  UPLOAD_DOCUMENT_REJECTED,
  DELETE_DOCUMENTS_START,
  DELETE_DOCUMENTS_FULFILLED,
  DELETE_DOCUMENTS_REJECTED,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_FULFILLED,
  UPDATE_DOCUMENT_REJECTED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  FETCH_FILE_START,
  FETCH_FILE_FULFILLED,
  FETCH_FILE_REJECTED,
  FETCH_FOLDERS_START,
  FETCH_FOLDERS_FULFILLED,
  FETCH_FOLDERS_REJECTED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_START,
  FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  SET_CURRENT_ROW_REDUX,
  SET_MODAL_SHARE_LINK_REDUX,
  SET_SELECTED_ROWS_REDUX,
  SET_ACTIVE_SEARCH_STRING_REDUX,
  SET_SINGLE_ACTION_REDUX,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchDocuments = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_DOCUMENTS_START });
    axiosAuthed.get(`/accounts/${accountCode}/documents`)
      .then((response) => {
        dispatch({
          type: FETCH_DOCUMENTS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_DOCUMENTS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchDocument = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_DOCUMENT_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/documents/${values.docId}`)
      .then((response) => {
        dispatch({
          type: FETCH_DOCUMENT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_DOCUMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchFolder = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_FOLDER_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/documents/?folderId=${values.folderId}`)
      .then((response) => {
        dispatch({
          type: FETCH_FOLDER_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FOLDER_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchDocumentsWithSearch = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_SEARCH_DOCUMENTS_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/documents/?search=${values.values.search}`)
      .then((response) => {
        dispatch({
          type: FETCH_SEARCH_DOCUMENTS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SEARCH_DOCUMENTS_REJECTED,
          payload: err,
        });
      });
  }
);

export const createDocument = (accountCode, body) => (
  (dispatch) => {
    dispatch({ type: CREATE_DOCUMENT_START });
    axiosAuthed.post(`/accounts/${accountCode}/documents/`, body)
      .then((response) => {
        dispatch({
          type: CREATE_DOCUMENT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_DOCUMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const uploadDocument = (accountCode, file) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append('file', file[0]);
    dispatch({ type: UPLOAD_DOCUMENT_START });
    axiosAuthed.post(`/accounts/${accountCode}/documents/upload`, formData)
      .then((response) => {
        dispatch({
          type: UPLOAD_DOCUMENT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPLOAD_DOCUMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const deleteDocuments = (accountCode, docIdsArray) => (
  (dispatch) => {
    dispatch({ type: DELETE_DOCUMENTS_START });
    axiosAuthed.patch(`/accounts/${accountCode}/documents/delete`, { documentIds: docIdsArray })
      .then((response) => {
        dispatch({
          type: DELETE_DOCUMENTS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_DOCUMENTS_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateDocument = (accountCode, documentId, body, indexOb) => (
  (dispatch) => {
    dispatch({ type: UPDATE_DOCUMENT_START });
    axiosAuthed.put(`/accounts/${accountCode}/documents/${documentId}`, body)
      .then((response) => {
        dispatch({
          type: UPDATE_DOCUMENT_FULFILLED,
          payload: { responseData: response.data, indices: indexOb },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_DOCUMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchUserGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups`)
      .then((response) => {
        dispatch({
          type: FETCH_USER_GROUPS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_GROUPS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchFile = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_FILE_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/documents/${values.docId}`)
      .then((response) => {
        dispatch({
          type: FETCH_FILE_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FILE_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchFolders = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_FOLDERS_START });
    axiosAuthed.get(`/accounts/${accountCode}/documents/?type=0`)
      .then((response) => {
        dispatch({
          type: FETCH_FOLDERS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FOLDERS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchDocumentsWithSearchAndFolder = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_FOLDER_SEARCH_DOCUMENTS_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/documents/?folderId=${values.currentFolder.id}&search=${values.values.search}`)
      .then((response) => {
        dispatch({
          type: FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveReduxModal = (activeModal) => (
  {
    type: SET_ACTIVE_REDUX_MODAL,
    payload: activeModal,
  }
);

export const setCurrentRowRedux = (activeModal) => (
  {
    type: SET_CURRENT_ROW_REDUX,
    payload: activeModal,
  }
);

export const setModalShareLinkRedux = (activeModal) => (
  {
    type: SET_MODAL_SHARE_LINK_REDUX,
    payload: activeModal,
  }
);

export const setSelectedRowsRedux = (selectedRows) => ({
  type: SET_SELECTED_ROWS_REDUX,
  payload: selectedRows,
});

export const setActiveSearchStringRedux = (selectedRows) => ({
  type: SET_ACTIVE_SEARCH_STRING_REDUX,
  payload: selectedRows,
});

export const setSingleActionRedux = (bool) => (
  {
    type: SET_SINGLE_ACTION_REDUX,
    payload: bool,
  }
);
