/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setEditDashboardModal } from '../../ducks/sportCoachDashboardActions';
import EditDashboardModal from './EditDashboardModal';

const EditDashboardModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const EditDashboardModalContainer = () => {
  const dispatch = useDispatch();
  const editTemplate = useSelector(
    (state) => state.sportCoachDashboard.data.editTemplate,
  );

  return (
    <>
      <EditDashboardModal />
      <EditDashboardModalOverlay
        isActive={Object.keys(editTemplate).length}
        onClick={
          () => dispatch(setEditDashboardModal({}))
          }
      />
    </>
  );
};

EditDashboardModalContainer.propTypes = { };

export default EditDashboardModalContainer;
