import React from 'react';
import styled from '@emotion/styled';
import Modal from './Modal';
import ModalSupport from './ModalSupport';
import ModalNew from './ModalNew';
import ModalSupportNew from './ModalSupportNew';
import ModalAccountSwitcher from './ModalAccountSwitcher';

const HeaderModals = ({
  activeModal,
  closeModal,
  isModalOpen,
  organizationId,
  path,
}) => (
  <ModalNew
    isOpen={isModalOpen}
    onRequestClose={closeModal}
  >
    {activeModal === 'Support' && (
    <ModalSupportNew
      path={path}
      onRequestClose={closeModal}
    />
    )}
    {activeModal === 'Switch Accounts' && (
    <ModalAccountSwitcher
      organizationId={organizationId}
    />
    )}
  </ModalNew>
);

export default HeaderModals;
