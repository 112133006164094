/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';

import Layout from '../../shared/components/Layout/Layout';
import PayGate from '../../shared/components/PayGate';
import SelectionModalContainer from './components/presentational/SelectionModalContainer';
import {
  fetchCalendars,
  fetchTrackedExercises,
  fetchGroups,
  fetchTrackedItemUnits,
} from './ducks/evaluationsActions';
import EvaluationsContentWrapper from './components/presentational/EvaluationsContentWrapper';
import Spinner from '../../shared/components/Spinner/Spinner';
import GroupAccessEvalModal from './components/presentational/GroupAccessEvalModal';

const PageWrapper = styled('div')`
  display: block;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  @media only screen and (max-width : 768px) {
    height: calc(100%);
  }
  background: #F5F5F5;
  justify-content: center;
`;

const MarginComponent = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`;

const SpinnerContainer = styled('div')`
  height: 100vh;
`;

const Evaluations = ({ match }) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const fetchedGroups = useSelector((state) => state.evaluations.ui.fetchedGroups);
  const fetchedCalendars = useSelector((state) => state.evaluations.ui.fetchedCalendars);
  const isGroupAccessEvalModalShowing = useSelector(
    (state) => state.evaluations.ui.isGroupAccessEvalModalShowing,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      batch(() => {
        dispatch(fetchGroups(currentUser.accountCode));
        dispatch(fetchCalendars(currentUser.accountCode));
        dispatch(fetchTrackedItemUnits(currentUser.accountCode));
        dispatch(fetchTrackedExercises(currentUser.accountCode));
      });
    }
  }, [currentUser]);

  const hasAccess = currentUser && (currentUser.organizationAccessLevel >= 4);
  const isFetchComplete = (fetchedGroups === true && fetchedCalendars === true);

  return (
    <Layout fullWidth>
      <Helmet>
        <title>Evaluations | TeamBuildr</title>
      </Helmet>
      {isFetchComplete ? (
        <>
          <SelectionModalContainer />
          <PageWrapper>
            <MarginComponent>
              <EvaluationsContentWrapper />
            </MarginComponent>
          </PageWrapper>
          {!hasAccess && (
            <PayGate
              accountRequired='Gold'
            />
          )}
          {isGroupAccessEvalModalShowing && (
            <GroupAccessEvalModal />
          )}
        </>
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
    </Layout>
  );
};
Evaluations.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default Evaluations;
