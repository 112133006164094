/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import useNotifications from '../hooks/useNotifications';
import SubText from '../../../../shared/components/SubText/SubText';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import NotificationBoxContent from './NoteBoxContent';
import { setIsFiltersChanging } from '../../ducks/notificationsActions';

const NotificationBoxContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  }
`;

const LoadMoreContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
`;

const NotificationContent = styled('div')`
  padding-bottom: 100px;
`;

const NotificationBox = ({
  setSelectedNotifications,
  isCheckboxesOpen,
}) => {
  const {
    handleFetchNotifications,
    handleFetchNotificationTypes,
  } = useNotifications();
  const notificationContainerRef = useRef();
  const notificationListRef = useRef();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [containerHeight, setContainerHeight] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  // Initial Scroll location using react refs.
  const initialScrollLocation = notificationContainerRef.current
    ? notificationContainerRef.current.offsetTop : 0;

  const isFiltersChanging = useSelector((state) => state.notifications.ui.isFiltersChanging);

  const dispatch = useDispatch();

  // containerEnd is the location of the end of the notification list
  // by subtracting the initial scroll location
  // from the notification list height, then adding the outer container height.
  const containerEnd = (initialScrollLocation - listHeight)
    + containerHeight;

  const isEndOfList = useSelector(
    (state) => state.notifications.data.isEndOfList,
  );

  const isNextPageLoading = useSelector(
    (state) => state.notifications.ui.isNextPageLoading,
  );

  // Load More function which fetches the next notification page if it's not the end of the list.
  const loadMore = () => {
    if (!isEndOfList) {
      handleFetchNotifications(currentUser.accountCode, false);
    }
  };

  useEffect(() => {
    handleFetchNotifications(currentUser.accountCode, true);
    handleFetchNotificationTypes();
  }, [currentUser]);

  useEffect(() => {
    setContainerHeight(
      notificationContainerRef.current && notificationContainerRef.current.offsetHeight,
    );
  }, [notificationContainerRef.current && notificationContainerRef.current.offsetHeight]);

  useEffect(() => {
    if (isFiltersChanging) {
      notificationContainerRef.current.scroll({ top: 0, behavior: 'smooth' });
      dispatch(setIsFiltersChanging(false));
    }
  }, [isFiltersChanging]);

  // React Spring animations for loading more, and auto scrolling.
  // Scrolling animation works by setting the scroll location from the tableScroll state
  const loadTransition = useSpring({
    position: 'absolute',
    opacity: isNextPageLoading ? 1 : 0,
  });

  const endTransition = useSpring({
    position: 'absolute',
    opacity: isEndOfList ? 1 : 0,
  });

  return (
    <NotificationBoxContainer
      ref={notificationContainerRef}
    >
      <NotificationContent
        ref={notificationListRef}
      >
        {/*
          This is the same method of implementing auto-fetch
          on page scroll as we use on Leaderboard. We use ResizeObserver
          to track when the position of the page changes. When the user has
          scrolled to the bottom of the list, we call loadMore() to fetch
          the next page of notifications. Important variables such as containerEnd,
          etc. are defined above.
        */}
        <ResizeObserver
          onResize={(rect) => {
            setListHeight(rect.height);
          }}
          onPosition={(rect) => {
            if (rect.top <= containerEnd && !isEndOfList) {
              loadMore();
            }
          }}
        />
        <NotificationBoxContent
          setSelectedNotifications={setSelectedNotifications}
          isCheckboxesOpen={isCheckboxesOpen}
        />
        <LoadMoreContainer>
          <animated.div style={loadTransition}>
            <Spinner
              saving
              darkTheme
            />
          </animated.div>
          <animated.div style={endTransition}>
            <SubText>
              End of Notifications
            </SubText>
          </animated.div>
        </LoadMoreContainer>
      </NotificationContent>
    </NotificationBoxContainer>
  );
};

NotificationBox.propTypes = {
  setSelectedNotifications: PropTypes.func.isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
};

export default NotificationBox;
