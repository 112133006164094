import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Dropzone from 'react-dropzone';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTheme } from 'emotion-theming';
import debounce from 'lodash.debounce';
import moment from 'moment';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useCookies } from 'react-cookie';

import NoConversationComponent from './NoConversationComponent';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import SubText from '../../../../shared/components/SubText/SubText';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import SendButton from './SendButton';
// import ActionButton from '../../../../shared/components/ActionButton/ActionButton';
import MessageBox from './MessageBox';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBButton from '../../../../shared/components/Button/Button';
import socket from '../../../../shared/utils/setSocketConfig';

import useConversations from '../hooks/useConversations';
import {
  setActiveReduxModal,
  setIsMediaUploaded,
  setIsInitialMessages,
  fetchConversationUsers,
  fetchScheduledMessages,
  fetchConversationMessages,
  setIsScheduledOpen,
  uploadMediaUrl,
  fetchConversations,
} from '../../ducks/conversationsActions';
import MessagesLoading from './MessagesLoading';
import { TransitionGlobal, ColorGreen } from '../../../../shared/GlobalStyles';
import lightenDarkenColor from '../../../../shared/utils/lightenDarkenColor';
import MessagesSendAsRow from './MessagesSendAsRow';

const PageWrapper = styled('div')`
  padding-top: 75px;
  padding-bottom: 145px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const BlankBox = styled('div')`
  position: fixed;
  width: calc(100% - 400px);
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 25px;
  padding-right: 40px;
  background: #fbfbfb;
  height: 108px;
  border-top: 1px solid lightgrey;
  font-style: italic;

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: calc(100% - 300px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const NewMessageBox = styled('div')`
  position: fixed;
  width: calc(100% - 400px);
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-right: 40px;
  background: #fbfbfb;
  .upper-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-bottom: ${(props) => (props.isUserAdmin ? '0px' : '30px')}; 
  }
  .lower-box {
    width: 100%;
    height: 50px;
    padding-left: 110px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: 541px) and (max-width: 1200px) {
      padding-left: 15px;
    }

    @media screen and (max-width: 540px) {
      padding-left: 0px;
    }
  }

  .send-button {
    display: flex;
    width: 80px;
    margin-bottom: 2px;
  }

  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: calc(100% - 300px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;

    .upper-box {
      .send-button {
        margin-right: ${(props) => (props.isMobileButtonOpen ? '0px' : '-80px')};
        opacity: ${(props) => (props.isMobileButtonOpen ? '1' : '0')};
      }
    }
  }
`;

const FormGroup = styled('div')`
  height: 100%;
  width: 100%;
  margin-right: 25px;
  margin-left: 25px;
  position: relative;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    /* margin-top: 30px; */
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  #messageBody-field {
    font-family: 'Open Sans';
    color: ${(props) => props.theme.colors.text};
    padding: 0px 80px 0px 0px;
    font-size: 14px;
  }

  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    height: 35px;
    width: 100%;
  }
  .folder-text {
    font-weight: bold;
  }
  .group-access-text {
    font-weight: bold;
    margin-top: 8px;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const DropZoneWrapper = styled('div')`
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }

  .update-button {
    width: 180px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const EmptyDropZoneWrapper = styled('div')`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  /* margin-top: -85px;
  margin-left: 23px; */
  height: 75px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 75px;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const TopBar = styled('div')`
  position: fixed;
  width: calc(100% - 400px);
  top: 0;
  display: flex;
  justify-content: center;
  height: 75px;
  margin-top: 49px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  /* padding-right: 15px; */
  background: #fbfbfb;
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    width: calc(100% - 300px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .topbar-back-button {
    position: absolute;
    left: 15px;
    background: transparent;
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 0px 65px;
  }

  .dot-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;

    cursor: pointer;
    :hover {
      background: rgba(0,0,0,0.1);
    }
  }
`;

const DropDownMenuWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;

  &.giphy-dropdown {
    position: absolute;
    top: 0px;
    right: 20px;
    height: 100%;
    z-index: 100;
    cursor: pointer;

    
  }  

  &.emoji-dropdown {
    position: absolute;
    top: 0px;
    right: 55px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
     
    @media screen and (max-width: 767px) {
      display: none;
    }
  }  

  .dropdown-container {
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);
    border: 0px;

    &.giphy {
      padding: 20px;
      z-index: 100;
    }

    &.emoji {
      padding: 0px;
      z-index: 100;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    transition: linear 0s;

    span {
      color: #444444;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0px;
      /* color: #444444; */
      font-size: 16px;
    }
    :hover {
      background: ${props => props.theme.colors.green};
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }

  .add-gif-button {
    height: 32px;
    width: 32px;
    color: #444444;
    background-color: transparent;
    font-weight: 800;
    letter-spacing: -.5px;

    &:hover {
      opacity: .7;
      background-color: transparent;
    }
  }

  &.notification-dropdown {
    position: absolute;
    right: 15px;
  }
`;

const ActionButton = styled('div')`
  display: flex;
  height: ${(props) => (!props.small ? '56px' : '40px')};
  width: ${(props) => (!props.small ? '56px' : '40px')};
  background-color: ${(props) => (props.customColor ? props.customColor : ColorGreen)};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${TransitionGlobal};

  @media screen and (max-width: 767px) {
    width: 35px;
    height: 35px;
  }

  :hover {
    /* background-color: ${(props) => (lightenDarkenColor(props.customColor ? props.customColor : ColorGreen, -25))} */
    background-color: lightgray;
  }
  
  svg {
    path {
      fill: white;
    }
  }
`;

const SmileyContainer = styled('div')`
  margin-right: 5px;
`;

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <div
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='dot-box'
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={30}
      icon='dots'
      color='grey'
    />
  </div>
));

const notificationDropdown = (
  dispatch,
  handleDeleteConversations,
  activeConversation,
  currentUser,
  handleMuteNotifications,
) => (
  <DropDownMenuWrapper className='notification-dropdown'>
    <Dropdown
      bsPrefix='trick-fix'
    >
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className='dropdown-container'>
        <Dropdown.Item
          className='dropdown-item'
          onClick={() => {
            handleMuteNotifications(
              {
                accountCode: currentUser.accountCode,
                muted: !activeConversation.muted,
                conversationId: activeConversation.id,
              },
            );
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon={!activeConversation.muted ? 'alert-on' : 'alert-off'}
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            {activeConversation.muted ? 'Notifications Off' : 'Notifications On'}
          </SubText>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          dispatch(setActiveReduxModal('conversation-details'));
        }}
        >
          <>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='group'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Participants
            </SubText>
          </>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => {
          const values = [parseInt(activeConversation.id, 10)];
          handleDeleteConversations({ readArray: values, accountCode: currentUser.accountCode });
        }}
        >
          <>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='trashcan'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Delete
            </SubText>
          </>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </DropDownMenuWrapper>
);

const fileAccepterSwitch = (dispatch, handleDroppedFile, isMediaUploaded) => {
  if (!isMediaUploaded) {
    return (
      <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
        {({ getRootProps, getInputProps }) => (
          <DropZoneWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <Dropdown.Item
              onClick={() => dispatch(setIsMediaUploaded(false))}
            >
              <>
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='folder'
                />
                <SubText
                  className='drop-icon-text'
                  fontSize={14}
                >
                  Add Saved Media
                </SubText>
              </>
            </Dropdown.Item>
          </DropZoneWrapper>
        )}
      </Dropzone>
    );
  }
  return (
    <Dropdown.Item onClick={() => dispatch(setIsMediaUploaded(false))}>
      <>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='folder'
        />
        <SubText
          className='drop-icon-text'
          fontSize={14}
        >
          Add Saved Media
        </SubText>
      </>
    </Dropdown.Item>
  );
};
const AddMediaToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  // <div
  //   href=''
  //   ref={ref}
  //   onClick={(e) => {
  //     e.preventDefault();
  //     onClick(e);
  //   }}
  //   className='dot-box'
  // >
  //   {children}
  //   <IcomoonReact
  //     iconSet={iconSet}
  //     size={30}
  //     icon='dots'
  //     color='grey'
  //   />
  // </div>
  <ActionButton
    href=''
    icon='plus'
    small
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    customColor='rgb(240, 240, 240)'
    ref={ref}
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={20}
      icon='attachment'
      color='rgb(68, 68, 68)'
    />
  </ActionButton>
));

const AddGifToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='dot-box'
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={30}
      icon='gif'
      color='rgb(68, 68, 68)'
    />
  </div>
));

const AddEmojiToggle = React.forwardRef(({ children, onClick }, ref) => (
  <SmileyContainer
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className='dot-box'
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={25}
      icon='smiley'
      color='rgb(68, 68, 68)'
    />
  </SmileyContainer>
));

const MediaDropdownContainer = styled('div')`
  display: flex;
  height: 75px;
  width: 75px;

  .remove-button {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 30px;
    z-index: 100;
    top: -5px;
    background: #d6d6d6;
  }
  @media screen and (max-width: 767px) {
    width: auto;
  }
`;

const addMediaDropdown = (
  handleDroppedFile,
  isMediaUploaded,
  dispatch,
) => (
  <DropDownMenuWrapper>
    <Dropdown
      bsPrefix='trick-fix'
    >
      <Dropdown.Toggle as={AddMediaToggle} />
      <Dropdown.Menu className='dropdown-container emoji'>
        <Dropdown.Item
          className='dropdown-item'
          onClick={() => dispatch(setActiveReduxModal('record-image-modal'))}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='camera'
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            Add Live Photo
          </SubText>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => dispatch(setActiveReduxModal('record-video-modal'))}>
          <>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='video'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Add Live Video
            </SubText>
          </>
        </Dropdown.Item>
        {fileAccepterSwitch(dispatch, handleDroppedFile, isMediaUploaded)}
      </Dropdown.Menu>
    </Dropdown>
  </DropDownMenuWrapper>
);

const addGiphyDropdown = (
  dispatch,
  giphyButtonRef,
  isGiphyDropdownVisible,
  isMediaUploaded,
) => (
  <DropDownMenuWrapper className='giphy-dropdown'>
    <Dropdown
      bsPrefix='trick-fix'
      id='dropdown-giphy'
    >
      {!isMediaUploaded ? (
        <Dropdown.Toggle ref={giphyButtonRef} as={AddGifToggle} />
      ) : null}
      <Dropdown.Menu
        className='dropdown-container giphy'
        show={isGiphyDropdownVisible}
      >
        <ReactGiphySearchbox
          apiKey='ziYjGfzM7zcDTV5DUPGWffgD9g8oEmiR'
          // onSelect={item => dispatch(uploadMedia({
          //   image: item.
          // }))}
          onSelect={(item) => {
            dispatch(uploadMediaUrl({ mediaUrl: item.images.downsized_large.url }));
            giphyButtonRef.current.click();
          }}
          masonryConfig={[
            { columns: 2, imageWidth: 180, gutter: 5 },
            {
              mq: '700px', columns: 2, imageWidth: 180, gutter: 5,
            },
          ]}
        />
      </Dropdown.Menu>
    </Dropdown>
  </DropDownMenuWrapper>
);

const addEmojiDropdown = (
  emojiButtonRef,
  values,
  setFieldValue,
) => (
  <DropDownMenuWrapper className='emoji-dropdown'>
    <Dropdown
      bsPrefix='trick-fix'
    >
      <Dropdown.Toggle ref={emojiButtonRef} as={AddEmojiToggle} />
      <Dropdown.Menu className='dropdown-container giphy'>
        <Picker
          set='apple'
          onSelect={(emoji) => {
            setFieldValue('messageBody', values.messageBody + emoji.native);
            emojiButtonRef.current.click();
          }}
          style={{
            border: 0,
          }}
          title=''
          emoji=''
        />
      </Dropdown.Menu>
    </Dropdown>
  </DropDownMenuWrapper>
);

const SpinnerWrapper = styled('div')`
    display: flex;
    height: 50%;
    justify-content: center;
    width: 100%;

`;

const MessagesPanel = () => {
  const {
    handleUploadMedia,
    handleCreateNewMessage,
    handleSetInitialMessages,
    handleDeleteConversations,
    handleMuteNotifications,
    handleClearActiveConversation,
    updateMessage,
  } = useConversations();
  const dispatch = useDispatch();

  const theme = useTheme();

  const messageBodyRef = useRef();
  const messageFormRef = useRef();
  const giphyButtonRef = useRef(null);
  const emojiButtonRef = useRef(null);

  const [cookies, setCookie] = useCookies(['accessToken']);

  const conversationMessages = useSelector(
    (state) => state.conversations.data.conversationMessages,
  );
  const activeConversation = useSelector((state) => state.conversations.data.activeConversation);
  const conversationUsers = useSelector((state) => state.conversations.data.conversationUsers);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const uploadedMedia = useSelector((state) => state.conversations.data.uploadMedia);
  // const uploadedMediaError = useSelector((state) => state.conversations.data.uploadMediaError);
  const mediaIsUploading = useSelector((state) => state.conversations.ui.isMediaUploading);
  const isMessagesLoading = useSelector((state) => state.conversations.ui.isMessagesLoading);
  const isMediaUploaded = useSelector((state) => state.conversations.data.isMediaUploaded);
  const isInitialMessages = useSelector((state) => state.conversations.data.isInitialMessages);
  const isScheduledOpen = useSelector((state) => state.conversations.ui.isScheduledOpen);
  const scheduledMessage = useSelector((state) => state.conversations.data.scheduledMessage);
  const scheduledMessages = useSelector((state) => state.conversations.data.scheduledMessages);
  const isConnected = useSelector((state) => state.socketTester.ui.isConnected);
  const isReconnected = useSelector((state) => state.socketTester.ui.isReconnected);
  const [selectedOptions, setSelectedOptions] = useState({ em: false, sms: false });
  const [isEditing, setIsEditing] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(0);
  const [isGiphyDropdownVisible, setIsGiphyDropdownVisible] = useState(false);
  const [localIsTyping, setLocalIsTyping] = useState(false);
  // In order to send isTyping variables during a setTimeout we need to store the variables in a ref
  // so the callback function has access during it's lexical context (or when it runs). We use the
  // useEffect hook to update the ref when those variables change.

  const isTypingRef = useRef({
    conversationId: activeConversation.id,
    conversationUsers,
    currentUserId: currentUser.id,
  });

  useEffect(() => {
    isTypingRef.current = {
      conversationId: activeConversation.id,
      conversationUsers,
      currentUserId: currentUser.id,
    };
  }, [activeConversation, conversationUsers, currentUser]);

  const handleSubmit = (values) => {
    const newMessage = values;
    newMessage.userId = currentUser.id;
    if (isMediaUploaded) {
      newMessage.mediaId = uploadedMedia.id;
    }
    if (selectedOptions.em) {
      newMessage.sendEmail = true;
    }
    if (selectedOptions.sms) {
      newMessage.sendSMS = true;
    }
    if (Object.keys(scheduledMessage).length) {
      newMessage.scheduledDeliveryTime = moment(scheduledMessage).unix();
    }
    if (newMessage.messageBody.length || newMessage.mediaId) {
      handleCreateNewMessage(
        newMessage,
        currentUser.accountCode,
        parseInt(activeConversation.id, 10),
      );
      setSelectedOptions({ em: false, sms: false });
    }
  };

  const handleUpdateMessage = (messageId, values) => {
    dispatch(updateMessage(currentUser.accountCode, activeConversation.id, messageId, values));
  };

  // Is Typing Logic
  let timer;
  const timeoutVal = 3000;

  // When the user triggers on change we call this debounced function
  // that sends the server an isTyping socket event with the right data
  const handleIsTyping = debounce(() => {
    if (!localIsTyping) {
      socket.emit('isTyping', {
        conversationId: activeConversation.id,
        conversationUsers,
        userId: currentUser.id,
        isTyping: true,
      });
      setLocalIsTyping(true);
    }
  }, 1000, {
    leading: true,
    trailing: false,
  });

  // When the user has stopped pressing on keys, set the timeout
  // if the user presses on keys before the timeout is reached, then this timeout is cancelled
  const handleKeyUp = () => {
    window.clearTimeout(timer); // prevent errant multiple timeouts from being generated
    timer = window.setTimeout(() => {
      setLocalIsTyping(false);
      socket.emit('isTyping', {
        conversationId: isTypingRef.current.conversationId,
        conversationUsers: isTypingRef.current.conversationUsers,
        userId: isTypingRef.current.currentUserId,
        isTyping: false,
      });
    }, timeoutVal);
  };

  // We setup listeners for only keyup since we use onChange for keydown.
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keypress', handleKeyUp);
    };
  }, []);

  const handleDroppedFile = (acceptedFile) => {
    const type = acceptedFile[0].type.split('/').shift();
    handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile, type });
  };

  /*
  useEffect for whenever the socket gets reconnected. We'll attempt to
  re-fetch any conversations, messages, etc.
  */
  useEffect(() => {
    if (isConnected && isReconnected) {
      batch(() => {
        // pass in true to not reset the activeConversation once fetch finishes
        dispatch(fetchConversations(true, true));
        if (activeConversation.id) {
          dispatch(fetchConversationMessages(currentUser.accountCode, activeConversation.id));
          dispatch(fetchConversationUsers(currentUser.accountCode, activeConversation.id));
          if (currentUser.admin === 1) {
            dispatch(fetchScheduledMessages(currentUser.accountCode, activeConversation.id));
          }
        }
      });
    }
  }, [isReconnected]);

  useEffect(() => {
    if (currentUser.accountCode !== undefined && activeConversation.id) {
      batch(() => {
        dispatch(fetchConversationMessages(currentUser.accountCode, activeConversation.id));
        dispatch(fetchConversationUsers(currentUser.accountCode, activeConversation.id));
        if (currentUser.admin === 1) {
          dispatch(fetchScheduledMessages(currentUser.accountCode, activeConversation.id));
        }
      });
    }
  }, [activeConversation]);

  useEffect(() => {
    if (currentUser.accountCode !== undefined && !conversationMessages.length) {
      handleSetInitialMessages(conversationMessages);
    }
  }, [conversationMessages]);

  const urlSnapper = () => {
    if (uploadedMedia && isMediaUploaded) {
      if (uploadedMedia.mediaUrl) {
        if (uploadedMedia.thumbnailUrl) {
          return uploadedMedia.thumbnailUrl;
        }
        return uploadedMedia.mediaUrl;
      }
    }
    return '';
  };

  const DropZoneDiv = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 0;
    margin-left: 0px;
    left: 0px;
    height: 75px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 75px;
    color: #495057;
    background-image: url(${urlSnapper()});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid #ced4da;
    border-radius: 50%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  const EditHeaderWrapper = styled('div')`
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;

    button {
      background: transparent;
      margin-left: 80px;
      margin-right: 5px;
    }
  `;

  const EditHeader = styled('p')`
    justify-self: flex-start;
    margin-bottom: 0px;
    color: ${(props) => theme.colors.lightText[0]};
    font-size: 14px;
  `;

  const getTopMembers = () => (
    `${activeConversation.members.slice(0, 3).map((member, i) => (
      `${member.primaryName} ${member.secondaryName.charAt(0)}., `
    )).join('')} and More...`
  );

  if (Object.keys(activeConversation).length > 0) {
    return (
      <PageWrapper>
        <TopBar>
          <TBButton
            iconOnly
            icon='left-arrow'
            size='40'
            className='topbar-back-button'
            onClick={() => handleClearActiveConversation({})}
          />
          <HeaderText
            fontSize='14pt'
            className='conversation-header'
            onClick={() => dispatch(setActiveReduxModal('conversation-details'))}
          >
            {activeConversation.title}
          </HeaderText>
          {notificationDropdown(
            dispatch,
            handleDeleteConversations,
            activeConversation,
            currentUser,
            handleMuteNotifications,
          )}
        </TopBar>
        {isMessagesLoading ? (
          <MessagesLoading />
        ) : (
          <>
            <MessageBox
              conversationMessages={conversationMessages}
              messageFormRef={messageFormRef}
              scheduledMessages={scheduledMessages}
              setIsEditing={setIsEditing}
              setEditingMessageId={setEditingMessageId}
            />
          </>
        )}
        <Formik
          ref={messageFormRef}
          initialValues={{
            messageBody: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (isEditing) {
              setTimeout(() => {
                handleUpdateMessage(editingMessageId, values);
                setSubmitting(false);
                setIsEditing(false);
                setEditingMessageId(0);
              });
            } else {
              setTimeout(() => {
                handleSubmit(values);
                setSubmitting(false);
              });
            }
            resetForm();
          }}
          render={({
            submitForm,
            setFieldValue,
            values,
          }) => (
            <Form className='formik-form'>
              {(activeConversation.readOnly && currentUser.admin === 0) ? (
                <BlankBox>This conversation is read-only and cannot be replied to</BlankBox>
              ) : (
                <NewMessageBox
                  isUserAdmin={currentUser.admin === 1}
                  isMobileButtonOpen={(isMediaUploaded || values.messageBody !== '') && true}
                >
                  {isEditing ? (
                    <EditHeaderWrapper>
                      <TBButton
                        icon='remove'
                        iconOnly
                        rounded
                        onClick={() => {
                          setIsEditing(false);
                          setEditingMessageId(0);
                          setFieldValue('messageBody', '');
                        }}
                      />
                      <EditHeader>
                        Editing scheduled message
                      </EditHeader>
                    </EditHeaderWrapper>
                  ) : null}
                  <div className='upper-box'>
                    <MediaDropdownContainer>
                      {mediaIsUploading || isMediaUploaded ? (
                        mediaIsUploading ? (
                          <EmptyDropZoneWrapper>
                            <SpinnerWrapper>
                              <Spinner
                                saving
                                darkTheme
                              />
                            </SpinnerWrapper>
                          </EmptyDropZoneWrapper>
                        ) : (
                          <>
                            <TBButton
                              icon='remove'
                              iconOnly
                              square
                              onClick={() => dispatch(setIsMediaUploaded(false))}
                              className='remove-button'
                            />
                            <DropZoneDiv />
                          </>
                        )
                      ) : (
                        addMediaDropdown(handleDroppedFile, isMediaUploaded, dispatch)
                      )}
                    </MediaDropdownContainer>
                    <FormGroup>
                      <TextField
                        id='messageBody-field'
                        className='message-body'
                        name='messageBody'
                        ref={messageBodyRef}
                        onKeyPress={(e) => {
                          const { key, shiftKey } = e;
                          if (key === 'Enter' && !shiftKey) {
                            e.preventDefault();
                            submitForm();
                          }
                        }}
                        onChange={(e) => {
                          setFieldValue('messageBody', e.target.value);
                          handleIsTyping();
                        }}
                        value={values.messageBody}
                        variant='outlined'
                        fullWidth
                        multiline
                        size='small'
                        style={{
                          whiteSpace: 'pre-line',
                          fontFamily: 'Open Sans',
                        }}
                        inputProps={{ maxLength: selectedOptions.sms ? 120 : 20000 }}
                        onFocus={() => {
                          if (isInitialMessages) {
                            dispatch(setIsInitialMessages(false));
                          }
                          if (isScheduledOpen && !isEditing) {
                            dispatch(setIsScheduledOpen(false));
                          }
                        }}
                      />
                      {addGiphyDropdown(
                        dispatch,
                        giphyButtonRef,
                        isGiphyDropdownVisible,
                        isMediaUploaded,
                      )}
                      {addEmojiDropdown(emojiButtonRef, values, setFieldValue)}
                    </FormGroup>
                    <SendButton
                      fullWidth
                      cta={isEditing ? 'Update' : 'Send'}
                      className='send-button'
                      rounded
                      customColor='#0dcc8a'
                      noBorder
                      primary
                      noHover
                      onClick={(e) => {
                        e.preventDefault();
                        submitForm();
                      }}
                    />
                  </div>
                  {currentUser.admin === 1 ? (
                    <MessagesSendAsRow
                      currentUser={currentUser}
                      setSelectedOptions={setSelectedOptions}
                      selectedOptions={selectedOptions}
                      scheduledMessage={scheduledMessage}
                    />
                  ) : null}

                </NewMessageBox>
              )}
            </Form>
          )}
        />
      </PageWrapper>
    );
  }
  return <NoConversationComponent currentUser={currentUser} />;
};

MessagesPanel.propTypes = { };
export default MessagesPanel;
