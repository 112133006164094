import styled from '@emotion/styled';

const Wrapper = styled('div')`
  align-items: center;
  background-image: ${(props) => (`linear-gradient(to right, ${props.color1}, ${props.color2})`)};
  display: flex;
  font-family: 'Nunito Sans';
  height: ${(props) => (props.height)};
  justify-content: space-between;
  min-height: ${(props) => (props.minHeight)};
  padding: 0px 40px;
  width: ${(props) => (props.width)};
`;

export default Wrapper;
