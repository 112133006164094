import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import CreateConversationForm from './modals/CreateConversationForm';
import RecordVideoModal from './modals/RecordVideoModal';
import RecordImageModal from './modals/RecordImageModal';
import SmallModal from './modals/SmallModal';
import ScheduleModal from './modals/ScheduleModal';
import VideoPlayerModal from './modals/VideoPlayerModal';
import MediaModalContent from './modals/MediaModalContent';
import CaptureModal from './modals/CaptureModal';
import ImageLightboxModal from './modals/ImageLightboxModal';
import ModalSupport from './modals/ModalSupport';
import ModalSupportContent from './modals/ModalSupportContent';
import ConversationDetailsModal from './components/presentational/ConversationDetailsModal';
import NotificationSettingsModalContent from './modals/NotificationSettingsModalContent';

import Modal from '../../shared/components/Modal/Modal';

import {
  setActiveReduxModal,
} from './ducks/conversationsActions';

const MainContainer = styled('main')`
`;

const ModalHandler = ({
  path,
}) => {
  const dispatch = useDispatch();

  const activeModal = useSelector((state) => state.conversations.data.activeModal);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const notificationAsset = useSelector((state) => state.notifications.data.notificationAsset);
  const journalMediaUrl = useSelector((state) => state.notifications.data.journalMediaUrl);
  const messageMediaUrl = useSelector((state) => state.conversations.data.messageMediaUrl);

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'create-conversation-modal') {
      returnModal = (
        <Modal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <CreateConversationForm
            onRequestClose={closeModal}
          />
        </Modal>
      );
    } else if (activeModal === 'conversation-details') {
      returnModal = (
        <Modal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ConversationDetailsModal
            onRequestClose={closeModal}
          />
        </Modal>
      );
    } else if (activeModal === 'record-video-modal') {
      returnModal = (
        <CaptureModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <RecordVideoModal
            onRequestClose={closeModal}
          />
        </CaptureModal>
      );
    } else if (activeModal === 'record-image-modal') {
      returnModal = (
        <CaptureModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <RecordImageModal
            onRequestClose={closeModal}
          />
        </CaptureModal>
      );
    } else if (activeModal === 'play-media-modal') {
      returnModal = (
        <VideoPlayerModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <MediaModalContent
            onRequestClose={closeModal}
            mediaUrl={notificationAsset.result.post.post_media_url}
          />
        </VideoPlayerModal>
      );
    } else if (activeModal === 'play-journal-video-modal') {
      returnModal = (
        <VideoPlayerModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <MediaModalContent
            onRequestClose={closeModal}
            mediaUrl={journalMediaUrl}
          />
        </VideoPlayerModal>
      );
    } else if (activeModal === 'schedule-modal') {
      returnModal = (
        <SmallModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ScheduleModal
            onRequestClose={closeModal}
          />
        </SmallModal>
      );
    } else if (activeModal === 'play-message-video-modal') {
      returnModal = (
        <VideoPlayerModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <MediaModalContent
            onRequestClose={closeModal}
            mediaUrl={messageMediaUrl}
          />
        </VideoPlayerModal>
      );
    } else if (activeModal === 'image-lightbox') {
      returnModal = (
        <VideoPlayerModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ImageLightboxModal
            onRequestClose={closeModal}
            mediaUrl={messageMediaUrl}
          />
        </VideoPlayerModal>
      );
    } else if (activeModal === 'support-modal') {
      returnModal = (
        <ModalSupport
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalSupportContent
            path={path}
            onRequestClose={closeModal}
          />
        </ModalSupport>
      );
    } else if (activeModal === 'settings-modal') {
      returnModal = (
        <NotificationSettingsModalContent onRequestClose={closeModal} isOpen={!!activeModal} />
      );
    }
    return returnModal;
  };

  return (
    <MainContainer>
      {modalHandler()}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
};

export default ModalHandler;
