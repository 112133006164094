/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import NotificationSideBarItem from './NotificationSideBarItem';
import useNotifications from '../hooks/useNotifications';
import SelectAllBox from './SelectAllBox';
import NotificationLoadingItem from './NotificationLoadingItem';

const Container = styled('div')`
`;

const EmptyMessage = styled('div')`
  text-align: center;
  padding-top: 15px;
`;

const NotificationBoxContent = ({
  setSelectedNotifications,
  isCheckboxesOpen,
}) => {
  const {
    handleSetActiveNotification,
  } = useNotifications();
  const isNotificationsLoading = useSelector(
    (state) => state.notifications.ui.isNotificationsLoading,
  );
  const notifications = useSelector((state) => state.notifications.data.notifications);
  const notificationFilter = useSelector(
    (state) => state.notifications.data.notificationFilter,
  );
  const localNotificationFilters = useSelector(
    (state) => state.notifications.data.localNotificationFilters,
  );

  const dispatch = useDispatch();

  const renderEmptyMessage = () => {
    if (isNotificationsLoading) {
      return (<div />);
    }
    if (notificationFilter) {
      return (
        <EmptyMessage>There are no notifications to display with the selected filter.</EmptyMessage>
      );
    }
    return (
      <EmptyMessage>There are no notifications to display.</EmptyMessage>
    );
  };

  const notificationMapper = (notificationsArray) => {
    const newArray = [];
    if (!isNotificationsLoading) {
      if (isCheckboxesOpen) {
        newArray.push(
          <SelectAllBox
            isCheckboxesOpen={isCheckboxesOpen}
            setSelectionFunction={setSelectedNotifications}
            objects={notifications}
            localNotificationFilters={localNotificationFilters}
          />,
        );
      }
      notificationsArray.forEach((notification, ind) => {
        newArray.push(
          <NotificationSideBarItem
            key={notification.id}
            index={ind}
            notificationObject={notification}
            setSelectedNotifications={setSelectedNotifications}
            handleSetActiveNotification={handleSetActiveNotification}
            isCheckboxesOpen={isCheckboxesOpen}
          />,
        );
      });
    } else {
      for (let i = 0; i <= 25; i += 1) {
        newArray.push(
          <NotificationLoadingItem />,
        );
      }
    }
    return newArray;
  };

  return (
    <Container>
      {notifications.length || isNotificationsLoading
        ? notificationMapper(notifications) : renderEmptyMessage()}
    </Container>
  );
};

NotificationBoxContent.propTypes = {
  setSelectedNotifications: PropTypes.func.isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
};

export default NotificationBoxContent;
