import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
// import IcomoonReact from 'icomoon-react';

import { ColorPrimary } from '../../../../shared/GlobalStyles';
// import iconSet from '../../../../shared/imagesteambuildr-selection.json';
import AthleteAvatar from './AthleteAvatar';
import AthleteName from './AthleteName';
import ProgressBar from './ProgressBar';
import CloseAthlete from './CloseAthlete';
import Spinner from './Spinner';
import AnimatedSwapButton from './AnimatedSwapButton';
import AnimatedProgressBar from './AnimatedProgressBar';
import AnimatedSaving from './AnimatedSaving';

const PanelHeaderContainer = styled('div')`
  display: flex;
  background-color: ${props => props.theme.colorAccent};
  height: 35px;
  width: 100%;
  padding: 0px 15px;
  justify-content: space-between;
  align-items: center;

  .progress-bar {
    .progress-bar-container {
      border: 1px solid ${props => (props.theme.isAccentDarkTheme ? ColorPrimary : 'white')};
    }
    .progress-bar-fill {
      background-color: ${props => (props.theme.isAccentDarkTheme ? ColorPrimary : 'white')};
    }
    .progress-text {
      color: ${props => (props.theme.isAccentDarkTheme ? ColorPrimary : 'white')};
    }
  }
`;

const AthleteDetails = styled('div')`
  display: flex;
  align-content: center;

  h3 {
    color: ${props => (props.theme.isAccentDarkTheme ? ColorPrimary : 'white')};
    @media screen and (max-width: 850px) {
      font-size: 12px;
    }

    @media screen and (max-width: 668px) {
      max-width: 78px;
    }
  }
`;

const SavingContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 110px;
  height: 35px;
  .spinner-container {
    margin-right: 30px;
    @media screen and (max-width: 668px) {
      margin-right: 20px;
    }
  }
`;

const PanelHeader = ({
  athleteAvatar,
  activeAthlete,
  // activeWorkoutIndex,
  closePanel,
  // closeWorkout,
  // isWorkoutSaving,
  namespace,
  // skin,
  // workoutCount,
}) => {
  // const isWorkoutActive = activeWorkoutIndex !== null;
  // const isAccentDarkTheme = skin && (skin.accent_dark_theme);

  // const handleClick = useCallback(() => {
  //   if (isWorkoutActive) {
  //     closeWorkout(namespace);
  //   } else {
  //     closePanel(namespace);
  //   }
  // }, [isWorkoutActive]);

  const handlePanelClose = useCallback(() => {
    closePanel(namespace);
  }, []);

  // const transitions = useTransition(isWorkoutActive, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });

  // const savingTransitions = useTransition(isWorkoutSaving, null, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  // });

  return (
    <PanelHeaderContainer>
      <AthleteDetails>
        <AthleteAvatar
          avatar={athleteAvatar}
          size={25}
        />
        <AthleteName
          name={activeAthlete}
        />
        {/* {transitions.map(({ item, key, props }) => (
          item && (
            <animated.div key={key} style={props}>
              <SwapButton
                onClick={handlePanelClose}
              >
                <IcomoonReact
                  color={isAccentDarkTheme ? ColorPrimary : 'white'}
                  icon='swap'
                  iconSet={iconSet}
                  handleClick={handlePanelClose}
                  size={12}
                />
              </SwapButton>
            </animated.div>
          )
        ))} */}
        <AnimatedSwapButton
          namespace={namespace}
          handlePanelClose={handlePanelClose}
        />
      </AthleteDetails>
      <AnimatedProgressBar
        namespace={namespace}
      />
      {/* {transitions.map(({ item, key, props }) => (
        item && (
          <animated.div key={key} style={props}>
            <ProgressBar
              key={key}
              style={props}
              assigned={workoutCount.assigned}
              completed={activeWorkoutIndex + 1}
            />
          </animated.div>
        )
      ))} */}
      <SavingContainer>
        <AnimatedSaving
          namespace={namespace}
        />
        <CloseAthlete
          namespace={namespace}
        />
      </SavingContainer>
    </PanelHeaderContainer>
  );
};

PanelHeader.propTypes = {
  athleteAvatar: PropTypes.string.isRequired,
  activeAthlete: PropTypes.string.isRequired,
  closePanel: PropTypes.instanceOf(Function).isRequired,
  namespace: PropTypes.string.isRequired,
};

export default PanelHeader;
