/* eslint-disable no-loop-func */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';

const Styles = styled.div`
  background: #FFFFFF;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  justify-content: center;
  align-items: flex-start;
  padding: 25px 20px 25px 20px;
  .paginationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .paginationButton {
    font-family: 'Nunito Sans';
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border: none;
  }
  & .sticky {
    overflow-x: auto;
    width: 100%;
  }
  .athleteColumnHeaderCell {
    border-bottom: none;
  }
  .noHoverCategoryColumnTitleCell {
    background-color: #BDBDBD1A;
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid #E0E0E0;
    min-width: 270px;
    max-width: 270px;
    border-top-left-radius: 8px;
  }
  .noHoverCategoryColumnTitleCell ~ .noHoverCategoryColumnTitleCell {
    border-top-left-radius: 0px;
  }
  .rawValueTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: normal;
    background-color: #BDBDBD1A;
    border-right: 1px solid #E0E0E0;
    color: #444444;
    text-align: left;
    min-width: 150px;
    max-width: 150px;
    cursor: pointer;
  }
  .athleteNameTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #444444;
    min-width: 225px;
    max-width: 225px;
    position: relative;
  }
  // Fixes sticky athlete title column not hidding content from other columns
  .athleteNameTitle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    background-color: #BDBDBD1A;
    border-top-left-radius: 8px;
    border-right: 1px solid #E0E0E0;
  }

  td:has(div):has(.percentRed) {
    background-color: #FFCDCD;
  }

  td:has(div):has(.percentGreen) {
    background-color: #CFF5E8;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #BDBDBD1A;
      text-align: center;
    }
    tr:first-child th:first-child {
      border-bottom: none;
    }
  }
`;

const Title = styled('div')`
  background-color: #ffffff;
  padding-left: 20px;
  padding-top: 20px;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: normal;
`;

const Table = ({
  columns,
  data,
  getCellProps,
  getHeaderProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 5 },
  },
  useSticky,
  useSortBy,
  usePagination);

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <button
          className='paginationButton'
          type='button'
          key={i}
          onClick={() => gotoPage(i)}
          style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
        >
          {i + 1}
        </button>,
      );
    }
    return buttons;
  };

  return (
    <>
      <div id='overviewTable' style={{ display: 'flex', zIndex: 0 }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),

                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <>
        {pageCount > 1 ? (
          <div className='paginationContainer'>
            <div className='pagination'>
              <button className='paginationButton' type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>
              {' '}
              {renderPageButtons()}
              {' '}
              <button className='paginationButton' type='button' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
            </div>
          </div>
        ) : (null)}
      </>
    </>
  );
};

const EvaluationReportOverviewTable = ({
  containerWidth,
  dataRows,
  rowIsScrollable,
  setColumnHeaderHeight,
}) => {
  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      id: 'topRow',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: '',
          accessor: () => null,
          id: 'secondRow',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '1px solid #E0E0E0',
            height: '58px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
          Cell: ({ row }) => (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div>
                {row.original.fullName}
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  const getColumns = (item, idx) => {
    const subColumns = [];

    for (let i = 0, n = item.units.length; i < n; i++) {
      subColumns.push(
        {
          Header: item.units[i],
          accessor: (row) => {
            const itemRow = row.results[idx];
            return (
              itemRow.rowData[itemRow.units[i]][0].value
            );
          },
          Cell: ({ row }) => {
            const itemCell = row.original.results[idx];
            if (itemCell.rowData[itemCell.units[i]][0].color === '#FF3737') {
              return (
                <div className='percentRed'>
                  <TBTooltip
                    alwaysDisplay
                    textToDisplay={itemCell.rowData[itemCell.units[i]][0].value ?? '-'}
                    textToHover={itemCell.rowData[itemCell.units[i]][0].hoverData}
                    textContainerPadding='0px'
                    fontWeight='normal'
                    cursor='default'
                  />
                </div>
              );
            }
            if (itemCell.rowData[itemCell.units[i]][0].color === '#0DCC8A') {
              return (
                <div className='percentGreen'>
                  <TBTooltip
                    alwaysDisplay
                    textToDisplay={itemCell.rowData[itemCell.units[i]][0].value ?? '-'}
                    textToHover={itemCell.rowData[itemCell.units[i]][0].hoverData}
                    textContainerPadding='0px'
                    fontWeight='normal'
                    cursor='default'
                  />
                </div>
              );
            }
            return (
              <div>
                <TBTooltip
                  alwaysDisplay
                  textToDisplay={itemCell.rowData[itemCell.units[i]][0].value ?? '-'}
                  textToHover={itemCell.rowData[itemCell.units[i]][0].hoverData}
                  textContainerPadding='0px'
                  fontWeight='normal'
                  cursor='default'
                />
              </div>
            );
          },
          id: `${item.itemName}_${item.units[i]}_${idx}_${i}`,
          className: 'rawValueTitle',
          style: {
            minWidth: '90px',
            maxWidth: '90px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
        },
      );
    }

    return subColumns;
  };

  const formatColumns = () => {
    const formattedData = dataRows[0].results.map((item, i) => ({
      Header: item.itemName,
      id: `${item.itemName}_${i}`,
      className: 'noHoverCategoryColumnTitleCell',
      columns: getColumns(item, i),
    }));

    return formattedData;
  };

  const formattedMiddleColumns = formatColumns();

  const columns = [...athleteNameColumn, ...formattedMiddleColumns];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.className === 'rawValueTitle') {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <>
      <Title>Group Averages</Title>
      <Styles
        ref={chartRef}
        onScroll={handleScroll}
        isScrollable={isScrollable}
        rowIsScrollable={rowIsScrollable}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        containerWidth={containerWidth}
      >
        <Table
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={() => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => handleHeaderClick(headerInfo),
          })}
        />
      </Styles>
    </>
  );
};

export default EvaluationReportOverviewTable;
