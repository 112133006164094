// a container for the search bar, header, active/archived cals toggle, and expand collapse toggle

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.css';

import { Link } from 'react-router-dom';
import SubText from '../../../../shared/components/SubText/SubText';
import Button from '../../../../shared/components/Button/Button';
import CustomCalendarSearch from './CustomCalendarSearchBar';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import useCalendars from '../hooks/useCalendars';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  resetReduxRedux,
} from '../../ducks/calendarsActions';

const TableHeaderWrapper = styled('div')`
  .search-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .submit-button {
    display: none
  }
`;

const HeaderAndBreadcrumbsWrapper = styled('div')`
  h1 {
    text-transform: capitalize;
  }
  @media screen and (max-width: 768px) {
    overflow: hidden;
    h1 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const ToggleWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .dropdown-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      span {
        color: #999999;
      }
      .drop-icon-text {
        margin-left: 10px;
        padding-top: 2px;
      }
      :hover {
        transition: linear 0s;
        background: #00b371;
        color: white;
        span {
          color: white;
        }
        svg {
          fill: white;
        }
      }
    }
    .dropdown-menu {
      padding: 0px;
    }
  .trick-fix {
    /* margin-left: 15px; */
    svg {
      margin-left: 5px;
    }
  }
`;

const SearchAndExpandButtonWrapper = styled('div')`
  display: flex;
  height: 100%;
  align-items: center;
  .search-form-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`;

const FormButtonContainer = styled('div')`
  display: flex;
`;

const CalendarSearchForm = (
  {
    setActiveSearchString,
    activeCalendars,
    archivedCalendars,
    setDisplayCalendars,
    unparsedCalendars,
    isArchivedShowing,
    setIsArchivedShowing,
    setSelectedRows,
    setSelectedChildRows,
    setClearRowToggle,
    clearRowToggle,
    setExpandCollapseDictionary,
  },
) => {
  const {
    handleSearch,
    currentUser,
  } = useCalendars();

  const dispatch = useDispatch();

  const [isAllCalendarsExpanded, setIsAllCalendarsExpanded] = useState(true);

  const expandCollapseDictionary = useSelector(
    (state) => state.calendars.data.expandCollapseDictionary,
  );

  const resetRedux = () => {
    dispatch(resetReduxRedux());
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      className=''
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      {!isArchivedShowing ? (
        <SubText
          fontSize={16}
        >
          Active
        </SubText>
      ) : (
        <SubText
          fontSize={16}
        >
          Archived
        </SubText>
      )}
      {'   '}
      <IcomoonReact
        iconSet={iconSet}
        size={15}
        icon='down-arrow'
        color='grey'
      />
    </Link>
  ));

  /**
   * this is a custom component that acts as a toggle to collapse or expand all
   * currently displayed calendars.  It is aware of and interacts with the following
   * functions: setExpandCollapseDictionary, and
   * setIsAllCalendarsExpanded.
   * The styling of the component changes based on what the current state is.
   */
  const CalendarExpandCollapseSwtich = () => {
    const SwitchWrapper = styled('div')`
      border: 1px solid black;
      width: 350px;
      height: 35px;
      display: flex;
      border-radius: 7px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    `;

    const CollapseSwitch = styled('div')`
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: ${isAllCalendarsExpanded ? '#f5f5f5' : '#444444'};
      color: ${isAllCalendarsExpanded ? '#444444' : '#f5f5f5'};
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      font-weight: bold;
      font-size: 14px;
    `;

    const ExpandSwitch = styled('div')`
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: ${isAllCalendarsExpanded ? '#444444' : '#f5f5f5'};
      color: ${isAllCalendarsExpanded ? '#f5f5f5' : '#444444'};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      font-weight: bold;
      font-size: 14px;
    `;

    /**
     * when expand/collapse switch is toggled, it sets the flip counter to 0 so that
     * the arrow setter in calendars knows to enter a different set of conditions
     * about which orientation the arrows should be in and whether the sub calendars
     * have a display property.  It maps over the current expandCollapseDictionary
     * to change all values in the dictionary to the same value.
     */
    return (
      <SwitchWrapper>
        <ExpandSwitch onClick={() => {
          const newExpandCollapseDictionary = {};
          Object.keys(expandCollapseDictionary).forEach((key) => {
            newExpandCollapseDictionary[key] = true;
          });
          setExpandCollapseDictionary(newExpandCollapseDictionary);
          setIsAllCalendarsExpanded(true);
        }}
        >
          EXPAND ALL
        </ExpandSwitch>
        <CollapseSwitch onClick={() => {
          const newExpandCollapseDictionary = {};
          Object.keys(expandCollapseDictionary).forEach((key) => {
            newExpandCollapseDictionary[key] = false;
          });
          setExpandCollapseDictionary(newExpandCollapseDictionary);
          setIsAllCalendarsExpanded(false);
        }}
        >
          COLLAPSE ALL
        </CollapseSwitch>
      </SwitchWrapper>
    );
  };

  /**
   * returns the header, the search form, the archive/active toggle, and the expand/collapse toggle
   * the search form iterates over whichever set of calendars is currently showing (archived
   * or active).  Then it creates an array of calendars whose names include the passed
   * string.  It sets the active search string to the passed string, and sets the current display
   * calendars to be the resulting array.
   *
   * The archived/active toggle is aware of both the set of active cals and archived cals, and
   * sets the display cals to be equal to whichever toggle is selected.  It also sets
   * isArchivedShowing to the appropriate value, expands all calendars, sets selectedRows to an
   * empty array, sets selectedRowNumber to 0, and flips the clearRowToggle to reset any
   * selected checkboxes
   */
  return (
    <TableHeaderWrapper>
      <div className='search-form-wrapper'>
        <HeaderAndBreadcrumbsWrapper>
          <HeaderText
            fontWeight='300'
            letterSpacing='normal'
          >
            Manage Calendars
          </HeaderText>
        </HeaderAndBreadcrumbsWrapper>
        <SearchAndExpandButtonWrapper>
          <div className='search-form-div'>
            <Formik
              initialValues={{
                search: '',
              }}
              onSubmit={(values) => {
                const searchArray = [];
                const currentCalendars = isArchivedShowing ? archivedCalendars : activeCalendars;
                if (values.search.length) {
                  const newExpandCollapseDictionary = expandCollapseDictionary;
                  setActiveSearchString(values.search);
                  currentCalendars.forEach((calendar) => {
                    const newCalendar = calendar;
                    if (calendar.name.toLowerCase().includes(values.search.toLowerCase())) {
                      newExpandCollapseDictionary[newCalendar.id] = false;
                      setExpandCollapseDictionary(newExpandCollapseDictionary);
                      searchArray.push(newCalendar);
                    }
                    if (calendar.newChildCalendars.length) {
                      calendar.newChildCalendars.forEach((childCalendar) => {
                        if (childCalendar.name.toLowerCase().includes(
                          values.search.toLowerCase(),
                        )) {
                          searchArray.push(childCalendar);
                        }
                      });
                    }
                  });
                  setDisplayCalendars(searchArray);
                } else {
                  const newExpandCollapseDictionary = {};
                  unparsedCalendars.forEach((cal) => {
                    if (cal.newChildCalendars.length) {
                      newExpandCollapseDictionary[cal.id] = expandCollapseDictionary[cal.id];
                    }
                    if (!cal.archived && cal.childCalendars.length) {
                      cal.childCalendars.forEach((childCal) => {
                        if (childCal.archived) {
                          const calId = cal.id;
                          newExpandCollapseDictionary[-calId] = expandCollapseDictionary[-calId];
                        }
                      });
                    }
                  });
                  setExpandCollapseDictionary(newExpandCollapseDictionary);
                  setActiveSearchString('');
                  setDisplayCalendars(isArchivedShowing ? archivedCalendars : activeCalendars);
                }
              }}
              render={({
                handleChange,
                handleSubmit,
                resetForm,
                values,
              }) => (
                <Form>
                  <FormButtonContainer>
                    <CustomCalendarSearch
                      name='search'
                      values={values.search}
                      handleChange={handleChange}
                      isClearable
                      onClear={resetForm}
                      handleSearch={handleSearch}
                      currentUser={currentUser}
                      setActiveSearchString={setActiveSearchString}
                      setDisplayCalendars={setDisplayCalendars}
                      oGCalendars={isArchivedShowing ? archivedCalendars : activeCalendars}
                      expandCollapseDictionary={expandCollapseDictionary}
                      setExpandCollapseDictionary={setExpandCollapseDictionary}
                      unparsedCalendars={unparsedCalendars}
                    />
                    <Button className='submit-button' onClick={handleSubmit} cta='submit' />
                  </FormButtonContainer>
                </Form>
              )}
            />
          </div>
        </SearchAndExpandButtonWrapper>
      </div>
      <ToggleWrapper>
        <Dropdown
          bsPrefix='trick-fix'
        >
          <Dropdown.Toggle as={CustomToggle} />
          <Dropdown.Menu>
            {!isArchivedShowing ? (
              <Dropdown.Item
                className='dropdown-item'
                onClick={() => {
                  setIsArchivedShowing(true);
                  setDisplayCalendars(archivedCalendars);
                  setClearRowToggle(!clearRowToggle);
                  setActiveSearchString('');
                  resetRedux();
                }}
              >
                <SubText
                  fontSize={14}
                >
                  Show Archived
                </SubText>
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                className='dropdown-item'
                onClick={() => {
                  setIsArchivedShowing(false);
                  setDisplayCalendars(activeCalendars);
                  setClearRowToggle(!clearRowToggle);
                  setActiveSearchString('');
                  resetRedux();
                }}
              >
                <SubText
                  fontSize={14}
                >
                  Show Active
                </SubText>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        <CalendarExpandCollapseSwtich />
      </ToggleWrapper>
    </TableHeaderWrapper>
  );
};

CalendarSearchForm.propTypes = {
  setActiveSearchString: PropTypes.func.isRequired,
  activeCalendars: PropTypes.instanceOf(Array).isRequired,
  archivedCalendars: PropTypes.instanceOf(Array).isRequired,
  unparsedCalendars: PropTypes.instanceOf(Array).isRequired,
  setSelectedChildRows: PropTypes.func.isRequired,
  setDisplayCalendars: PropTypes.func.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
  setIsArchivedShowing: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setClearRowToggle: PropTypes.func.isRequired,
  clearRowToggle: PropTypes.bool.isRequired,
  setExpandCollapseDictionary: PropTypes.func.isRequired,
};

export default CalendarSearchForm;
