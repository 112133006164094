import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../images/teambuildr-selection.json';
import HeaderText from '../HeaderText/HeaderText';

import Text from '../Text/Text';

const CardContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 300px;
  height: 125px;
  padding: 22px;
  border-radius: 6px;
  background-color: white;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
`;

const IconContainer = styled('div')`
  margin-right: 18px;
`;

const TextContainer = styled('div')`
  h1 {
    margin-bottom: 5px;
  }
`;

const LockBubble = styled('span')`
  position: absolute;
  display: inline-block;
  right: -10px;
  top: -5px;
  padding: 5px 10px;
  font-size: 10pt;
  border-radius: 20px;
  background-color: ${(props) => props.backgroundColor};
`;

const LegacyText = styled('span')`
  position: absolute;
  display: inline-block;
  color: #444444;
  font-weight: 600;
  font-size: 11px;
  right: 0px;
  bottom: 0px;
  border-bottom-right-radius: 6px;
  padding: 6px 8px;
  background-color: ${(props) => props.theme.colors.lightText[2]};
`;

const Card = ({
  title,
  description,
  icon,
  onClick,
  locked,
  lockText,
  lockColor,
  isLegacy,
}) => (
  <CardContainer
    onClick={onClick}
  >
    {locked && (
    <LockBubble backgroundColor={lockColor}>
      {lockText}
    </LockBubble>
    )}
    {isLegacy && (
      <LegacyText>
        LEGACY
      </LegacyText>
    )}
    <IconContainer>
      <IcomoonReact
        icon={locked ? 'lock' : icon}
        iconSet={iconSet}
        size={50}
        color='#444445'
      />
    </IconContainer>
    <TextContainer>
      <HeaderText
        fontSize={14}
        fontWeight={800}
        letterSpacing={1.3}
      >
        {title}
      </HeaderText>
      <Text
        fontSize={12}
      >
        {description}
      </Text>
    </TextContainer>
  </CardContainer>
);

Card.defaultProps = {
  locked: false,
  lockText: '',
  lockColor: '',
  isLegacy: false,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  locked: PropTypes.bool,
  lockText: PropTypes.string,
  lockColor: PropTypes.string,
  isLegacy: PropTypes.bool,
};

export default Card;
