import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import { animated, useSpring, useChain } from 'react-spring';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import PodiumCard from '../../../shared/components/PodiumCard/PodiumCard';
import testLeaderboardData from '../testLeaderboardData.json';

const PodiumWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${props => (props.activeBoards >= 2 ? '0px' : '0px 67px')};
  align-items: flex-end;
  flex-shrink: 0; /* Fix for Safari flex box wrong height issue */
  .accent-container {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: -30px;
    overflow: hidden;
  }

  :first-child {
    margin-left: 0px!important;
  }
  :last-child {
    margin-right: 0px!important;
  }

  .podium-card {
    width: 100%!important;
  }

  .podium-wrapper {
    position: relative;
  }
  .firstAnimatedPodium {
    order: 2;
  }

  .secondAnimatedPodium {
    order: 1
  }

  .thirdAnimatedPodium {
    order: 3
  }

  @media screen and (max-width: 991px) {
    padding: 0px!important;

    .result {
      font-size: 24px;
    }
  }
`;

const PodiumAccent = styled('div')`

`;

const defaultPodiumStyles = {
  width: '100%',
  margin: '0px 10px',
};

const minimizedPodiumStyles = {
  width: '100%',
};

// const arrayMove = (arr, fromIndex, toIndex) => {
//   const element = arr[fromIndex];
//   arr.splice(fromIndex, 1);
//   arr.splice(toIndex, 0, element);
// };

const MultiplePodiums = ({
  activeBoards,
  leaderboardData,
  windowSize,
}) => {
  // const animatedPodium = useRef(Array.from(leaderboardData, () => React.createRef()));
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(
      (activeBoards === 1)
        ? leaderboardData.slice(0, 3)
        : leaderboardData.slice(0, 1),
    );
  }, [activeBoards, leaderboardData]);

  useEffect(() => {
    setData(
      (activeBoards !== 1 || windowSize.width <= 768)
        ? leaderboardData.slice(0, 1)
        : leaderboardData.slice(0, 3),
    );
  }, [windowSize]);

  const firstPodiumRef = useRef();
  const firstAnimatedPodium = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: firstPodiumRef,
  });

  const accentRef = useRef();
  const accentReveal = useSpring({
    to: { height: 0 },
    from: { height: 40 },
    ref: accentRef,
  });

  const secondPodiumRef = useRef();
  const secondAnimatedPodium = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: secondPodiumRef,
  });


  const thirdPodiumRef = useRef();
  const thirdAnimatedPodium = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: thirdPodiumRef,
  });

  useChain([firstPodiumRef, accentRef, secondPodiumRef, thirdPodiumRef], [0, 0.5, 0.6, 0.9]);

  // const data = (
  //   (activeBoards === 1 || windowSize.width <= 768)
  //     ? leaderboardData.slice(0, 3)
  //     : leaderboardData.slice(0, 1)
  // );

  return (
    <PodiumWrapper
      activeBoards={activeBoards}
    >
      {data.map((athlete, index) => (
        <animated.div
          className={`podium-wrapper ${
            index === 0
              ? 'firstAnimatedPodium'
              : index === 1
                ? 'secondAnimatedPodium'
                : index === 2
                  ? 'thirdAnimatedPodium'
                  : null
          }`}
          style={{
            ...((activeBoards === 2 || activeBoards === 3 || windowSize.width <= 768) ? minimizedPodiumStyles : defaultPodiumStyles),
            ...(index === 0
              ? firstAnimatedPodium
              : index === 1
                ? secondAnimatedPodium
                : index === 2
                  ? thirdAnimatedPodium
                  : null)
          }}
        >
          {/* {index === 0 && (
            <animated.div className='accent-container' style={accentReveal}>
              <PodiumAccent>
                <IcomoonReact 
                  iconSet={iconSet}
                  icon='fist-bump-action'
                  size={20}
                />
              </PodiumAccent>
            </animated.div>
          )} */}
          <PodiumCard
            className='podium-card'
            athlete={athlete.userName}
            avatar={athlete.userPic}
            date={athlete.dateSet}
            place={athlete.rank}
            result={athlete.displayValue}
            small={activeBoards === 2 || activeBoards === 3 || windowSize.width <= 768}
            minimized={activeBoards === 3 || windowSize.width <= 480}
          />
        </animated.div>
      ))}
    </PodiumWrapper>
  );
};

export default MultiplePodiums;
