import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'emotion-theming';
import { updateProgram } from '../../../ducks/resellerActions';

import NoHoverDeleteButton from './NoHoverDeleteButton';
import Text from '../../../../../shared/components/Text/Text';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    display: flex;
    align-items: center;
    background-color: #f24540;
  }

  .error-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  display: flex;
  justify-content: center;
`;

const ModalArchiveProgram = ({
  programId,
  programName,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const updateProgramError = useSelector((state) => state.reseller.data.updateProgramError);
  const isProgramUpdating = useSelector((state) => state.reseller.ui.isProgramUpdating);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [submitClicked, setSubmitClicked] = useState(false);
  return (
    <ModalWrapper>
      <ModalWrapper>
        <TextWrapper>
          <Text
            fontWeight='600'
            fontSize={theme.textFontSizes[2]}
          >
            {programName ? `Are you sure you want to delete this program? (${programName})` : null}
          </Text>
          {updateProgramError && submitClicked ? <TitleError className='error-text error-margin'>{updateProgramError}</TitleError> : null}
        </TextWrapper>
        <NoHoverDeleteButton
          bottom
          fullWidth
          cta='Delete Program'
          className='modal-button'
          large
          noBorder
          primary
          square
          isLoading={isProgramUpdating}
          loadingMessage='Deleting...'
          onClick={() => {
            setSubmitClicked(true);
            const values = {
              accountCode: currentUser.accountCode,
              programId,
              newProgram: {
                status: 2,
              },
            };
            dispatch(updateProgram(values));
          }}
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalArchiveProgram.propTypes = {
  programId: PropTypes.instanceOf(Number).isRequired,
  programName: PropTypes.instanceOf(string).isRequired,
};

export default ModalArchiveProgram;
