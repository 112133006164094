/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import NotificationMediaContainer from './shared/NotificationMediaContainer';

const FeedPostText = styled('div')`
  margin: 0 0 21.1px 0;
  font-family: Open Sans;
  text-align: center;
`;

const BumperUserName = styled('div')`
  margin: 0 0 21.1px 0;
  font-family: Open Sans;
  text-align: center;
`;

const NotificationContainer = styled('div')`
  display: flex;
`;

const NotificationContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/**
 * Transforms post text from the form the API gives us
 * with the [u=...] stuff
 * @param {String} inputText the raw text from the API
 */
const transformPostText = (inputText) => {
  if (inputText && inputText.length) {
    let text = inputText;
    text = text.replace(/&nbsp;/g, '');
    text = text.replace(/\[u=\d+\|(.*?)\]/g, '@$1');
    return text;
  }
  return '';
};

/**
 * @returns a component that contains more specific details about the fetched
 * notification, including the body, text, and media
 */

const BossNotificationComponent = () => {
  const notificationAsset = useSelector((state) => state.notifications.data.notificationAsset);
  return (
    <NotificationContainer>
      <NotificationContent>
        {/**
         * displays the notification body
         */}
        <BumperUserName>
          {notificationAsset.body}
        </BumperUserName>
        {/**
         * text associated with the notification, if it exists, otherwise returns null
         */}
        {notificationAsset.postText ? (
          <FeedPostText>
            &quot;
            {transformPostText(notificationAsset?.postText)}
            &quot;
          </FeedPostText>
        ) : null}
        {/**
         * returns the component for handling notification media if it exists, otherwise null
         */}
        {notificationAsset.media ? <NotificationMediaContainer /> : null}
      </NotificationContent>
    </NotificationContainer>
  );
};

export default BossNotificationComponent;
