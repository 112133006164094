// A component that contains the left, right, and center columns that separate
// the different sections of the workout entry screen
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const Wrapper = styled('div')`
  width: 100%;
	display: flex;
	background: white;
  min-height: 255px;
  min-width: 650px;
`;

const SubscriptionLevelBox = styled('div')`
  width: 30%;
  height: 100%;
	display: flex;
  min-width: 260px;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #DBDBDB;
`;

const SubscriptionInformationBox = styled('div')`
  width: 70%;
  height: 100%;
	display: flex;
  padding: 40px;
`;

const TextAndIcon = styled('div')`
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled('div')`

`;

const SubText = styled('div')`
  color: #484848;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  margin-top: 15px;
`;

const SmallSubText = styled('div')`
  color: #484848;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
`;

const SubInfoHeader = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 25px;
  color: #484848;
`;

const SubInfoColumnBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const UserLimitBox = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  width: 250px;
`;

const UserLimitText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: #484848;
`;

const UserLimitNumber = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
  color: #959595;
`;

const LimitLine = styled('div')`
  background: #959595;
  width: 100%;
  height: 3px;
  margin-top: 15px;
  min-width: 315px;
`;

const ExpirationDateBox = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  width: 275px;
`;

const ExpirationDateText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: #484848;
`;

const Date = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 23px;
  color: #959595;
`;

const UpgradeButton = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #FFFFFF;
  background: #737373;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 6px;
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

const SubscriptionPanel = () => (
  <Wrapper>
    <SubscriptionLevelBox>
      <TextAndIcon>
        <IcomoonReact
          iconSet={iconSet}
          size={50}
          icon='swap'
          color='#484848'
        />
        <SubText>Subscription Level</SubText>
        <SmallSubText>Subscription Level</SmallSubText>
      </TextAndIcon>
    </SubscriptionLevelBox>
    <SubscriptionInformationBox>
      <SubInfoColumnBox>
        <SubInfoHeader>Subscription Information</SubInfoHeader>
        <UserLimitBox>
          <UserLimitText>
            User/Limit
          </UserLimitText>
          <UserLimitNumber>
            27/500
          </UserLimitNumber>
        </UserLimitBox>
        <LimitLine />
        <ExpirationDateBox>
          <ExpirationDateText>
            Expiration Date
          </ExpirationDateText>
          <Date>Date</Date>
        </ExpirationDateBox>
        <UpgradeButton>Upgrade</UpgradeButton>
      </SubInfoColumnBox>
    </SubscriptionInformationBox>
  </Wrapper>
);

SubscriptionPanel.propTypes = { };

export default SubscriptionPanel;
