import React from 'react';
import styled from '@emotion/styled';

import SubText from '../../../../../shared/components/SubText/SubText';
import LoginFormSSO from './LoginFormSSO';
import Logo from '../../../../../shared/images/full-logo-black-orange.png';

const FormContainer = styled('div')`
  width: 430px;
  height: auto;
  background-color: white;
  padding-bottom: 40px;

  @media screen and (max-width: 600px) {
    width: 330px;
    height: auto;
    padding-bottom: 20px;
  }
`;

const TitleArea = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid rgb(232, 232, 232);
  height: 160px;
  align-items: center;
  padding: 30px 80px 5px 80px;
  justify-content: center;

  img {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    height: 135px;
  }
`;

const FormArea = styled('div')`
  padding: 30px 54px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 30px;
  }
`;

const LoginFormContainerSSO = () => (
  <FormContainer>
    <TitleArea>
      <img src={Logo} alt='Teambuildr' className='img-fluid' />
      <SubText color='text'>Sign In</SubText>
    </TitleArea>
    <FormArea>
      <LoginFormSSO />
      {/* <Text className='text-center'>Have an Easy Join Code? Click Here</Text> */}
    </FormArea>
  </FormContainer>
);

export default LoginFormContainerSSO;
