/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { setIsAdditionalWorkouts } from '../../ducks/workoutsActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(-150%)',
  },
};

const ButtonInterior = styled('div')`
	display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled('div')`
	margin-right: 5px;
  align-items: center;
  margin-bottom: 5px;
`;

const defaultStyle = {
  transform: 'translateY(170%)',
  display: 'flex',
  position: 'absolute',
  height: '50px',
  width: '200px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: '40px',
  transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
  fontFamily: 'Nunito Sans',
  background: 'linear-gradient(90deg, rgba(0,212,255,1) 50%, rgba(3,160,203,1) 100%)',
  fontWeight: '600',
  fontSize: '15px',
};

const NewWorkoutsAlert = ({ updateIndex, activeIndex }) => {
  const dispatch = useDispatch();
  const isAdditionalWorkouts = useSelector(
    (state) => state.workouts.data.isAdditionalWorkouts,
  );
  const [switchAlertBanner, setSwitchAlertBanner] = useState(false);

  useEffect(() => {
    if (switchAlertBanner) {
      setTimeout(() => {
        dispatch(setIsAdditionalWorkouts(false));
        setSwitchAlertBanner(false);
        updateIndex(activeIndex + 1);
      }, 1250);
    }
  }, [switchAlertBanner]);

  useEffect(() => {
    if (isAdditionalWorkouts) {
      setTimeout(() => {
        setSwitchAlertBanner(true);
      }, 500);
    }
  }, [isAdditionalWorkouts]);

  return (
    <Transition in={switchAlertBanner}>
      {(state) => (
        <div
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <ButtonInterior>
            <IconContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon='lock'
                color='white'
              />
            </IconContainer>
            Workouts Unlocked!

          </ButtonInterior>
        </div>
      )}
    </Transition>
  );
};

NewWorkoutsAlert.propTypes = {
  activeIndex: PropTypes.number.isRequired,
  updateIndex: PropTypes.func.isRequired,
};

export default NewWorkoutsAlert;
