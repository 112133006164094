/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Text from '../../../../shared/components/Text/Text';
import { tabs } from '../../../../shared/constants';

const TabsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

const TabWrapper = styled('div')`
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
`;

const Tab = ({
  index, tab, isSelected, changeTab,
}) => (
  <TabWrapper onClick={() => changeTab(index)}>
    <Text
      fontSize={18}
      fontWeight='600'
      style={{
        textDecorationLine: isSelected(index) ? 'underline' : 'none',
        textAlign: 'center',
      }}
    >
      {tab}
    </Text>
  </TabWrapper>
);

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  tab: PropTypes.string.isRequired,
  isSelected: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
};

const Tabs = ({ isSelected, changeTab }) => (
  <TabsWrapper>
    {tabs.map((tab, index) => (
      <Tab key={index} index={index} tab={tab} isSelected={isSelected} changeTab={changeTab} />
    ))}
  </TabsWrapper>
);

Tabs.propTypes = {
  isSelected: PropTypes.func.isRequired,
  changeTab: PropTypes.func.isRequired,
};

export default Tabs;
