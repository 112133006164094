import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import IcomoonReact from 'icomoon-react';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import {
  ColorBlack,
  ColorGreen,
  ColorWhite,
  ColorGray,
} from '../../../shared/GlobalStyles';

const Container = styled('div')`
`;

const WorkoutContainer = styled('div')`
  font-family: 'Open Sans';
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${props => props.theme.textColor};
  position: relative;
  padding: 9px 10px;
  transition: all 250ms ease-in-out;

  .exercise_text {
    display: flex;
    width: 100%;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 1px;
    background-color: ${ColorGray};
    height: 16px;
    position: absolute;
    margin-top: -16px;
    margin-left: 11px;
  }
  .exercise_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border: 1px solid ${props => props.theme.iconBorderColor};
    background: ${props => props.theme.backgroundColorL2};
    border-radius: 20px;
    box-sizing: border-box;
    align-self: center;
    transition: all 250ms ease-in-out;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 1000;

    svg {
      path {
        fill: ${props => props.theme.textColor}!important;
      }
    }

    &.complete {
      background-color: ${ColorGreen};
      border: 1px solid ${ColorGreen};
      color: white;
      svg {
        path {
          fill: rgb(255, 255, 255)!important;
        }
      }
    }

    .grouping_letter {
      height: 14px;
      width: 14px;
      line-height: 14px;
      font-size: 9px;
      color: #fff;
      border-radius: 100%;
      position: absolute;
      align-self: flex-end;
      margin-left: 12px;
      margin-top: -12px;
      text-align: center;
    }
  }

  &.break {
    &:before {
      display: none;
    }
  }

  &.active {
    padding: 20px 10px 0px 10px;
    transition: all 250ms ease-in-out;
    a {
      height: 32px;
    }
    .exercise_icon {
      transition: all 250ms ease-in-out;
      transform: scale(1.3);
    }

    .exercise_sets_reps {
      display: flex;
      font-size: 10px;
      flex-shrink: 0;
      button {
        font-size: 9px;
        padding: 6px 11px;
      }
    }

    button {
      display: flex;
    }
    
  }

  .exercise_name {
    display: block;
    flex: 3;
    font-size: 14px;
    font-weight: 600;
    box-sizing: border-box;
    padding: 0px 0px 0px 17px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .exercise_sets_reps {
    align-self: center;
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: .5px;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    box-sizing: border-box;
    text-align: right;
    margin-right: ${props => (props.isActive ? '50px' : '0px')};
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .exercise_name {
      font-size: 13px!important;
    }
  }
`;

const SubHeader = styled('div')`
`;

const MainLink = styled('a')`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  cursor: pointer;
  width: 100%;
`;

const SharedWorkoutCard = ({
  complete,
  exerId,
  exerType,
  grouping,
  icon,
  id,
  index,
  isActive,
  name,
  subHeader,
  type,
  workoutInner,
}) => {
  let newIcon = icon;
  switch (newIcon) {
    case 'dumbbell':
      newIcon = 'lift';
      break;
    case 'lab':
      newIcon = 'science';
      break;
    case 'circuit':
    case 'roundabout':
      newIcon = 'circuit';
      break;
    case 'notes':
      newIcon = 'note';
      break;
    case 'fire':
      newIcon = 'warmup';
      break;
    case 'heart':
      newIcon = 'heart';
      break;
    case 'health':
      newIcon = 'heart';
      break;
    case 'snowflake':
      newIcon = 'cooldown';
      break;
    case 'facetime-video':
      newIcon = 'video';
      break;
    case 'more-windows':
      newIcon = 'window';
      break;
    case 'break':
      newIcon = 'window';
      break;
    case 'clock':
      newIcon = 'clock';
      break;
    case 'rest':
      newIcon = 'clock';
      break;
    case 'signal':
      newIcon = 'chart';
      break;
    default:
  }

  if (complete) {
    newIcon = 'checkmark';
  }

  return (
    <Container>
      <WorkoutContainer
        id={`workout-${id}`}
        className={`
          workout-container
          ${type}
      `}
      >
        <MainLink
          data-id={id}
          data-exerid={exerId}
          data-index={index}
          data-exertype={exerType}
          ref={workoutInner}
        >
          <div
            className={`
              exercise_icon
              ${complete ? 'complete' : ''} 
              ${grouping ? `grouping_${grouping}` : ''}
            `}
          >
            <IcomoonReact
              iconSet={iconSet}
              color={complete ? ColorWhite : ColorBlack}
              size={12}
              icon={icon}
            />

            {grouping ? (
              <div className='grouping_letter'>
                {grouping}
              </div>
            )
              : ''}
          </div>
          <div className='exercise_text'>
            <div className='exercise_name'>
              <span>
                {name}
              </span>
            </div>
            {!isActive && (
              <SubHeader className='exercise_sets_reps'>
                <span>
                  {subHeader}
                </span>
              </SubHeader>
            )}
          </div>
        </MainLink>
      </WorkoutContainer>
    </Container>
  );
};

SharedWorkoutCard.propTypes = {
  complete: PropTypes.bool,
  exerId: PropTypes.number.isRequired,
  exerType: PropTypes.string.isRequired,
  grouping: PropTypes.string,
  icon: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  workoutInner: PropTypes.instanceOf(Object).isRequired,
};

export default SharedWorkoutCard;
