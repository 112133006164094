/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';

import { useTheme } from 'emotion-theming';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const NotificationSideBarItem = () => {
  const NotificationDiv = styled('div')`
    display: flex;
    width: 100%;
    height: 80px;
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    .left-wrapper {
      display: flex;
      align-items: center;
      width: 80%;

      .checkbox-div:active {
        opacity: 1;
        width: 15%;
        margin-left: 19px;
      }

      .message-box {
        display: flex;
        width: 90%;
        flex-direction: row;
        color: #444444;
        align-items: center;
        padding: 9px;
        border-radius: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;

        .sender-latest-message {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: flex;
          flex-direction: column;
          .title {
            font-size: 12px;
            font-weight: bold;
          }
          .latest-message {
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .end-box {
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .latest-time {
        margin-top: -10px;
        margin-right: 10px;
        color: #444444;
        font-size: 10px;
      }

      .arrow-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `;

  return (
    <NotificationDiv>
      <div className='left-wrapper'>
        <div className='message-box'>
          <Shimmer>
            <div
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
              }}
              className='userpic'
            />
          </Shimmer>
          <div className='sender-latest-message'>
            <Shimmer>
              <div
                className='title'
                style={{
                  height: '17px',
                  width: '150px',
                  borderRadius: '10px',
                }}
              />
            </Shimmer>
            <Shimmer>
              <div
                className='latest-message'
                style={{
                  height: '15px',
                  width: '190px',
                  borderRadius: '10px',
                  marginTop: '1px',
                }}
              />
            </Shimmer>
          </div>
        </div>
      </div>
      <div className='end-box'>
        <Shimmer>
          <div className='latest-time' />
        </Shimmer>
        <div className='arrow-box'>
          <Shimmer>
            <div
              style={{
                height: '20px',
                width: '20px',
                borderRadius: '50%',
              }}
            />
          </Shimmer>
        </div>
      </div>
    </NotificationDiv>
  );
};

NotificationSideBarItem.propTypes = {

};

export default NotificationSideBarItem;
