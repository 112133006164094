import { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  fetchGroupAnalysisData,
  fetchOverviewData,
  fetchUserHistoryData,
  setActiveFilters,
  setActiveFiltersOverview,
  setActiveTypeFilters,
  setAthleteRadioValue,
  setIsFilterOpen,
} from '../ducks/RecoveryRadarActions';

const FilterHelper = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeFilters = useSelector((state) => state.recoveryRadar.data.activeFilters);
  const activeFiltersOverview = useSelector(
    (state) => state.recoveryRadar.data.activeFiltersOverview,
  );
  const activeTypeFilters = useSelector((state) => state.recoveryRadar.data.activeTypeFilters);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const isFilterOpen = useSelector((state) => state.recoveryRadar.ui.isFilterOpen);
  const selectedAthlete = useSelector((state) => state.recoveryRadar.data.selectedAthlete);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);

  const [searchTerm, setSearchTerm] = useState('');

  const dispatch = useDispatch();

  const typeOptions = [
    { name: 'Soreness', id: 1 },
    { name: 'Pain', id: 2 },
  ];

  const options = [
    { id: 1, name: 'Abs Upper' },
    { id: 2, name: 'Abs Lower' },
    { id: 3, name: 'Adductors Front Left' },
    { id: 4, name: 'Adductors Front Right' },
    { id: 5, name: 'Adductors Back Left' },
    { id: 6, name: 'Adductors Back Right' },
    { id: 7, name: 'Ankle Front Left' },
    { id: 8, name: 'Ankle Front Right' },
    { id: 9, name: 'Ankle Back Left' },
    { id: 10, name: 'Ankle Back Right' },
    { id: 11, name: 'Biceps Left' },
    { id: 12, name: 'Biceps Right' },
    { id: 13, name: 'Calves Front Left' },
    { id: 14, name: 'Calves Front Right' },
    { id: 15, name: 'Calves Back Left' },
    { id: 16, name: 'Calves Back Right' },
    { id: 17, name: 'Chest Left' },
    { id: 18, name: 'Chest Right' },
    { id: 19, name: 'Deltoid Front Left' },
    { id: 20, name: 'Deltoid Front Right' },
    { id: 21, name: 'Deltoid Back Left' },
    { id: 22, name: 'Deltoid Back Right' },
    { id: 23, name: 'Foot Front Right' },
    { id: 24, name: 'Foot Front Left' },
    { id: 25, name: 'Foot Back Right' },
    { id: 26, name: 'Foot Back Left' },
    { id: 27, name: 'Forearm Front Left' },
    { id: 28, name: 'Forearm Front Right' },
    { id: 29, name: 'Forearm Back Left' },
    { id: 30, name: 'Forearm Back Right' },
    { id: 31, name: 'Gluteal Left' },
    { id: 32, name: 'Gluteal Right' },
    { id: 33, name: 'Hamstring Left' },
    { id: 34, name: 'Hamstring Right' },
    { id: 35, name: 'Hand Front Left' },
    { id: 36, name: 'Hand Front Right' },
    { id: 37, name: 'Hand Back Left' },
    { id: 38, name: 'Hand Back Right' },
    { id: 39, name: 'Head Front' },
    { id: 40, name: 'Head Back' },
    { id: 41, name: 'Knee Left' },
    { id: 42, name: 'Knee Right' },
    { id: 43, name: 'Lower Back Left' },
    { id: 44, name: 'Lower Back Right' },
    { id: 45, name: 'Hip Left' },
    { id: 46, name: 'Hip Right' },
    { id: 47, name: 'Neck Front Left' },
    { id: 48, name: 'Neck Front Right' },
    { id: 49, name: 'Neck Back Left' },
    { id: 50, name: 'Neck Back Right' },
    { id: 51, name: 'Oblique Left' },
    { id: 52, name: 'Oblique Right' },
    { id: 53, name: 'Quadriceps Left' },
    { id: 54, name: 'Quadriceps Right' },
    { id: 55, name: 'Tibialis Left' },
    { id: 56, name: 'Tibialis Right' },
    { id: 57, name: 'Trapezius Front Left' },
    { id: 58, name: 'Trapezius Front Right' },
    { id: 59, name: 'Trapezius Back Left' },
    { id: 60, name: 'Trapezius Back Right' },
    { id: 61, name: 'Triceps Front Left' },
    { id: 62, name: 'Triceps Front Right' },
    { id: 63, name: 'Triceps Back Left' },
    { id: 64, name: 'Triceps Back Right' },
    { id: 65, name: 'Upper Back Left' },
    { id: 66, name: 'Upper Back Right' },
  ];

  const handleActionButton = () => {
    // Pull out the active filter IDs
    const filterIds = activeFilters.map((obj) => obj.id);
    const filterIdsOverview = activeFiltersOverview.map((obj) => obj.id);
    const typeIds = activeTypeFilters.map((obj) => obj.id);

    batch(() => {
      dispatch(fetchOverviewData(
        accountCode,
        group?.id,
        moment(selectedDateData).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIdsOverview,
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        group?.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIds,
      ));
      if (selectedAthlete?.id) {
        dispatch(fetchUserHistoryData(
          accountCode,
          selectedAthlete?.id,
          moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
          selectedDateData,
          typeIds,
          filterIds,
        ));
      }
      dispatch(setIsFilterOpen(false));
      setSearchTerm('');
    });
  };

  const handleChangeAnalysis = (filter) => {
  // Check if filter already exists, if so remove it
    if (activeFilters.some((obj) => obj.id === filter.id)) {
      const newFilters = [...activeFilters];
      const idx = newFilters.findIndex((obj) => obj.id === filter.id);
      if (idx > -1) {
        newFilters.splice(idx, 1);
      }
      dispatch(setActiveFilters(newFilters));
      // Else add filter to the active filters array
    } else {
      const newFilters = [...activeFilters];
      newFilters.push(filter);
      dispatch(setActiveFilters(newFilters));
    }
  };

  const handleChangeOverview = (filter) => {
  // Check if filter already exists, if so remove it
    if (activeFiltersOverview.some((obj) => obj.id === filter.id)) {
      const newFilters = [...activeFiltersOverview];
      const idx = newFilters.findIndex((obj) => obj.id === filter.id);
      if (idx > -1) {
        newFilters.splice(idx, 1);
      }
      dispatch(setActiveFiltersOverview(newFilters));
      // Else add filter to the active filters array
    } else {
      const newFilters = [...activeFiltersOverview];
      newFilters.push(filter);
      // If there are already 3 filters, remove the first one
      // Can only filter a max of 3
      if (newFilters.length > 3) {
        newFilters.splice(0, 1);
      }
      dispatch(setActiveFiltersOverview(newFilters));
    }
  };

  const handleChangeType = (filter) => {
    // Check if filter already exists, if so remove it
    if (activeTypeFilters.some((obj) => obj.id === filter.id)) {
      const newFilters = [...activeTypeFilters];
      const idx = newFilters.findIndex((obj) => obj.id === filter.id);
      if (idx > -1) {
        newFilters.splice(idx, 1);
      }
      dispatch(setActiveTypeFilters(newFilters));
    // Else add filter to the active filters array
    } else {
      const newFilters = [...activeTypeFilters];
      newFilters.push(filter);
      dispatch(setActiveTypeFilters(newFilters));
    }
  };

  const handleClear = () => {
    batch(() => {
      dispatch(setActiveFilters([]));
      dispatch(setActiveTypeFilters([{ name: 'Soreness', id: 1 }, { name: 'Pain', id: 2 }]));
      dispatch(setAthleteRadioValue('all'));
    });
    setSearchTerm('');
  };

  const handleClearOverview = () => {
    batch(() => {
      dispatch(setActiveFiltersOverview([]));
      dispatch(setActiveTypeFilters([{ name: 'Soreness', id: 1 }, { name: 'Pain', id: 2 }]));
    });
    setSearchTerm('');
  };

  const handleClose = () => {
    dispatch(setIsFilterOpen(false));
    setSearchTerm('');
  };

  const handleFilter = () => {
    dispatch(setIsFilterOpen(!isFilterOpen));
  };

  const handleOpen = () => {
    dispatch(setIsFilterOpen(true));
  };

  const handleRadioChange = (e) => {
    dispatch(setAthleteRadioValue(e.target.value));
  };

  return {
    handleActionButton,
    handleChangeAnalysis,
    handleChangeOverview,
    handleChangeType,
    handleClear,
    handleClearOverview,
    handleClose,
    handleFilter,
    handleOpen,
    handleRadioChange,
    options,
    searchTerm,
    setSearchTerm,
    typeOptions,
  };
};

export default FilterHelper;
