export const FETCH_REPORTABLE_GROUPS_HT_START = 'FETCH_REPORTABLE_GROUPS_HT_START';
export const FETCH_REPORTABLE_GROUPS_HT_FULFILLED = 'FETCH_REPORTABLE_GROUPS_HT_FULFILLED';
export const FETCH_REPORTABLE_GROUPS_HT_REJECTED = 'FETCH_REPORTABLE_GROUPS_HT_REJECTED';
export const FETCH_HABITS_FOR_ACCOUNT_START = 'FETCH_HABITS_FOR_ACCOUNT_START';
export const FETCH_HABITS_FOR_ACCOUNT_FULFILLED = 'FETCH_HABITS_FOR_ACCOUNT_FULFILLED';
export const FETCH_HABITS_FOR_ACCOUNT_REJECTED = 'FETCH_HABITS_FOR_ACCOUNT_REJECTED';
export const FETCH_CATEGORIES_FOR_ACCOUNT_START = 'FETCH_CATEGORIES_FOR_ACCOUNT_START';
export const FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED = 'FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED';
export const FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED = 'FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED';
export const SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED = 'SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED';
export const FETCH_ATHLETES_START_HT = 'FETCH_ATHLETES_START_HT';
export const FETCH_ATHLETES_FULFILLED_HT = 'FETCH_ATHLETES_FULFILLED_HT';
export const FETCH_ATHLETES_REJECTED_HT = 'FETCH_ATHLETES_REJECTED_HT';
export const CREATE_NEW_HABIT_START = 'CREATE_NEW_HABIT_START';
export const CREATE_NEW_HABIT_FULFILLED = 'CREATE_NEW_HABIT_FULFILLED';
export const CREATE_NEW_HABIT_REJECTED = 'CREATE_NEW_HABIT_REJECTED';
export const CREATE_NEW_CATEGORY_START = 'CREATE_NEW_CATEGORY_START';
export const CREATE_NEW_CATEGORY_FULFILLED = 'CREATE_NEW_CATEGORY_FULFILLED';
export const CREATE_NEW_CATEGORY_REJECTED = 'CREATE_NEW_CATEGORY_REJECTED';
export const EDIT_HABIT_START = 'EDIT_HABIT_START';
export const EDIT_HABIT_FULFILLED = 'EDIT_HABIT_FULFILLED';
export const EDIT_HABIT_REJECTED = 'EDIT_HABIT_REJECTED';
export const EDIT_CATEGORY_START = 'EDIT_CATEGORY_START';
export const EDIT_CATEGORY_FULFILLED = 'EDIT_CATEGORY_FULFILLED';
export const EDIT_CATEGORY_REJECTED = 'EDIT_CATEGORY_REJECTED';
export const DELETE_HABIT_START = 'DELETE_HABIT_START';
export const DELETE_HABIT_FULFILLED = 'DELETE_HABIT_FULFILLED';
export const DELETE_HABIT_REJECTED = 'DELETE_HABIT_REJECTED';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_FULFILLED = 'DELETE_CATEGORY_FULFILLED';
export const DELETE_CATEGORY_REJECTED = 'DELETE_CATEGORY_REJECTED';
export const CHECK_5_HABIT_MAX_START = 'CHECK_5_HABIT_MAX_START';
export const CHECK_5_HABIT_MAX_FULFILLED = 'CHECK_5_HABIT_MAX_FULFILLED';
export const CHECK_5_HABIT_MAX_REJECTED = 'CHECK_5_HABIT_MAX_REJECTED';
export const FETCH_HABIT_FOR_ACCOUNT_START = 'FETCH_HABIT_FOR_ACCOUNT_START';
export const FETCH_HABIT_FOR_ACCOUNT_FULFILLED = 'FETCH_HABIT_FOR_ACCOUNT_FULFILLED';
export const FETCH_HABIT_FOR_ACCOUNT_REJECTED = 'FETCH_HABIT_FOR_ACCOUNT_REJECTED';
export const SET_ASSIGNMENT_CONFLICT_ACTION = 'SET_ASSIGNMENT_CONFLICT_ACTION';
export const SET_ATTENTION_ACTION = 'SET_ATTENTION_ACTION';
export const SET_DELETE_MODAL_DATA = 'SET_DELETE_MODAL_DATA';
export const SET_GLOBAL_HABIT_FILTERS = 'SET_GLOBAL_HABIT_FILTERS';
export const SET_EDIT_CATEGORY_INFO = 'SET_EDIT_CATEGORY_INFO';
