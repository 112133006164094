import update from 'immutability-helper';

import {
  FETCH_WORKOUTS_START,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_REJECTED,
  FETCH_WORKOUT_HISTORY_START,
  FETCH_WORKOUT_HISTORY_FULFILLED,
  FETCH_WORKOUT_HISTORY_REJECTED,
  UPLOAD_WORKOUTS_MEDIA_START,
  UPLOAD_WORKOUTS_MEDIA_FULFILLED,
  UPLOAD_WORKOUTS_MEDIA_REJECTED,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_START,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED,
  SUBMIT_WORKOUT_START,
  SUBMIT_WORKOUT_REJECTED,
  SUBMIT_WORKOUT_FULFILLED,
  UPLOAD_QUICK_JOURNAL_MEDIA_START,
  UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED,
  UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED,
  GET_WORKOUTS_EXERTION_SCORE_START,
  GET_WORKOUTS_EXERTION_SCORE_FULFILLED,
  GET_WORKOUTS_EXERTION_SCORE_REJECTED,
  SUBMIT_SESSION_WORKOUT_FULFILLED,
  SUBMIT_SESSION_WORKOUT_REJECTED,
  FETCH_TAG_EXERCISES_START,
  FETCH_TAG_EXERCISES_FULFILLED,
  FETCH_TAG_EXERCISES_REJECTED,
  SET_HIDDEN_WORKOUTS_TOGGLE,
  SET_IS_DAY_CAROUSEL,
  SET_IS_PROGRAM_LIST_BOX_MODAL,
  SET_IS_RESET_PROGRAM_MODAL_SHOWING,
  SET_IS_SKIP_WORKOUT_MODAL_SHOWING,
  SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_START,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING,
  RESET_WORKOUT_PROGRAM_FULFILLED,
  COMPLETE_WORKOUT_PROGRAM_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEK_START,
  FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEK_REJECTED,
  FETCH_WORKOUT_PROGRAM_WEEKS_START,
  FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED,
  FETCH_WORKOUT_PROGRAMS_START,
  FETCH_WORKOUT_PROGRAMS_FULFILLED,
  FETCH_WORKOUT_PROGRAMS_REJECTED,
  WIPE_OUT_NON_DATE,
  SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING,
  COMPLETE_WORKOUT_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
  FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
  SET_IS_INTEGRATION_MODAL_SHOWING,
  FETCH_MORE_WORKOUT_HISTORY_START,
  FETCH_MORE_WORKOUT_HISTORY_FULFILLED,
  FETCH_MORE_WORKOUT_HISTORY_REJECTED,
} from './actionTypes';

const initialState = {
  isExertionScoreLoading: false,
  isWorkoutsListLoading: false,
  isWorkoutJournalMediaUploading: false,
  isWorkoutCompleting: false,
  workoutItemIndex: null,
  hiddenWorkoutsToggle: false,
  isDayCarousel: false,
  isProgramListBoxShowing: false,
  isResetProgramModalShowing: false,
  isCompleteProgramModalShowing: false,
  isProgramListItemsLoading: false,
  importedDataIntegrationsLoading: false,
  selectedIntegrationDataLoading: false,
  isIntegrationModalShowing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKOUTS_START: {
      return {
        ...state,
        isWorkoutsListLoading: true,
      };
    }
    case FETCH_WORKOUTS_FULFILLED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_WORKOUTS_REJECTED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START: {
      return {
        ...state,
        isWorkoutsListLoading: true,
      };
    }
    case FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WORKOUTS_START: {
      return {
        ...state,
        isWorkoutsListLoading: true,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED: {
      return {
        ...state,
        isWorkoutsListLoading: false,
      };
    }
    case FETCH_TAG_EXERCISES_START: {
      return {
        ...state,
        isTagExercisesLoading: true,
      };
    }
    case FETCH_TAG_EXERCISES_FULFILLED: {
      return {
        ...state,
        isTagExercisesLoading: false,
      };
    }
    case FETCH_TAG_EXERCISES_REJECTED: {
      return {
        ...state,
        isTagExercisesLoading: false,
      };
    }
    case UPLOAD_QUICK_JOURNAL_MEDIA_START: {
      return {
        ...state,
        isQuickJournalMediaUploading: true,
      };
    }
    case UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED: {
      return {
        ...state,
        isQuickJournalMediaUploading: false,
      };
    }
    case UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED: {
      return {
        ...state,
        isQuickJournalMediaUploading: false,
      };
    }
    case UPLOAD_WORKOUT_JOURNAL_MEDIA_START: {
      return {
        ...state,
        isWorkoutJournalMediaUploading: true,
      };
    }
    case UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED: {
      return {
        ...state,
        isWorkoutJournalMediaUploading: false,
      };
    }
    case UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED: {
      return {
        ...state,
        isWorkoutJournalMediaUploading: false,
      };
    }
    case FETCH_WORKOUT_HISTORY_START: {
      return {
        ...state,
        isWorkoutHistoryLoading: true,
      };
    }
    case FETCH_WORKOUT_HISTORY_FULFILLED: {
      return {
        ...state,
        isWorkoutHistoryLoading: false,
      };
    }
    case FETCH_WORKOUT_HISTORY_REJECTED: {
      return {
        ...state,
        isWorkoutHistoryLoading: false,
      };
    }
    case FETCH_MORE_WORKOUT_HISTORY_START: {
      return {
        ...state,
        isWorkoutHistoryLoading: true,
      };
    }
    case FETCH_MORE_WORKOUT_HISTORY_FULFILLED: {
      return {
        ...state,
        isWorkoutHistoryLoading: false,
      };
    }
    case FETCH_MORE_WORKOUT_HISTORY_REJECTED: {
      return {
        ...state,
        isWorkoutHistoryLoading: false,
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_START: {
      return {
        ...state,
        isMediaUploading: true,
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_FULFILLED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_REJECTED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case SUBMIT_WORKOUT_START: {
      return {
        ...state,
        isWorkoutCompleting: true,
        workoutItemIndex: action.workoutItemIndex,
      };
    }
    case SUBMIT_WORKOUT_FULFILLED: {
      return {
        ...state,
        isWorkoutCompleting: false,
        workoutItemIndex: null,
      };
    }
    case SUBMIT_WORKOUT_REJECTED: {
      return {
        ...state,
        isWorkoutCompleting: false,
        workoutItemIndex: null,
      };
    }
    case SUBMIT_SESSION_WORKOUT_FULFILLED: {
      return {
        ...state,
        isWorkoutCompleting: false,
        workoutItemIndex: null,
      };
    }
    case SUBMIT_SESSION_WORKOUT_REJECTED: {
      return {
        ...state,
        isWorkoutCompleting: false,
        workoutItemIndex: null,
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_START: {
      return {
        ...state,
        isExertionScoreLoading: true,
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_FULFILLED: {
      return {
        ...state,
        isExertionScoreLoading: false,
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_REJECTED: {
      return {
        ...state,
        isExertionScoreLoading: false,
      };
    }
    case SET_HIDDEN_WORKOUTS_TOGGLE: {
      return {
        ...state,
        hiddenWorkoutsToggle: action.payload,
      };
    }
    case SET_IS_DAY_CAROUSEL: {
      return {
        ...state,
        isDayCarousel: action.payload,
      };
    }
    case SET_IS_PROGRAM_LIST_BOX_MODAL: {
      return {
        ...state,
        isProgramListBoxShowing: action.payload,
      };
    }
    case SET_IS_RESET_PROGRAM_MODAL_SHOWING: {
      return {
        ...state,
        isResetProgramModalShowing: action.payload,
      };
    }
    case SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING: {
      return {
        ...state,
        isCompleteWorkoutModalShowing: action.payload,
      };
    }
    case SET_IS_SKIP_WORKOUT_MODAL_SHOWING: {
      return {
        ...state,
        isSkipWorkoutModalShowing: action.payload,
      };
    }
    case SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING: {
      return {
        ...state,
        isProgramInformationModalShowing: action.payload,
      };
    }
    case SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING: {
      return {
        ...state,
        isCompleteProgramModalShowing: action.payload,
      };
    }
    case SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return update(state, {
        isSkipWorkoutModalShowing: {
          $set: false,
        },
      });
    }
    case RESET_WORKOUT_PROGRAM_FULFILLED: {
      return {
        ...state,
        isResetProgramModalShowing: false,
      };
    }
    case COMPLETE_WORKOUT_PROGRAM_FULFILLED: {
      return {
        ...state,
        isCompleteProgramModalShowing: false,
      };
    }
    case COMPLETE_WORKOUT_FULFILLED: {
      return {
        ...state,
        isCompleteWorkoutModalShowing: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEK_START: {
      return {
        ...state,
        isProgramListItemsLoading: true,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEK_REJECTED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEKS_START: {
      return {
        ...state,
        isProgramListItemsLoading: true,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAMS_START: {
      return {
        ...state,
        isProgramListItemsLoading: true,
      };
    }
    case FETCH_WORKOUT_PROGRAMS_FULFILLED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case FETCH_WORKOUT_PROGRAMS_REJECTED: {
      return {
        ...state,
        isProgramListItemsLoading: false,
      };
    }
    case WIPE_OUT_NON_DATE: {
      return {
        ...state,
        isDayCarousel: false,
      };
    }
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START:
      return {
        ...state,
        importedDataIntegrationsLoading: true,
      };
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED:
      return {
        ...state,
        importedDataIntegrationsLoading: false,
      };
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED:
      return {
        ...state,
        importedDataIntegrationsLoading: false,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_START:
      return {
        ...state,
        selectedIntegrationDataLoading: true,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED:
      return {
        ...state,
        selectedIntegrationDataLoading: false,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED:
      return {
        ...state,
        selectedIntegrationDataLoading: false,
      };
    case SET_IS_INTEGRATION_MODAL_SHOWING:
      return {
        ...state,
        isIntegrationModalShowing: action.payload,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
