import React, { useLayoutEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import getAllUrlParams from '../../shared/utils/getAllUrlParams';
import { submitRefreshToken } from '../../modules/login/ducks/loginActions';
import Logger from 'js-logger';

const LoginRoute = ({
  component: Component,
  authed,
  path,
  ...rest
}) => {
  const [cookies, setCookies] = useCookies(['refreshToken']);
  const [isRefreshed, setIsRefreshed] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (authed === false) {
      // Check if we have a token to refresh with, if we do attempt to refresh access token.
      if (cookies.refreshToken !== undefined && cookies.refreshToken !== '0') {
        dispatch(submitRefreshToken())
          .then(() => {
            if (setIsRefreshed) {
              // Set the local state isRefresh to true to prevent the login page
              // from rendering if we're logged in.
              setIsRefreshed(true);
            } else {
              Logger.debug('setIsRefreshed not defined');
            }
          });
      }
    }
  }, []);

  return (
    <Route
      {...rest}
      render={
        (props) => (
          authed === false && isRefreshed === false
            ? <Component {...props} />
            // ? (
            //   <Route
            //     path={path}
            //     component={() => {
            //       window.location = 'https://app.teambuildr.com/login';
            //     }}
            //   />
            : (
              <Route
                path={path}
                component={() => {
                  // window.location.href = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}app.teambuildr.com/login?rel=${path.replace('/', '')}&version=2`;
                  // // window.location.href = `http://localhost:8080/login`;
                  // if (process.env.ENVIRONMENT === 'LOCAL') {
                  //   window.location.href = 'http://localhost:8080/login';
                  // }
                  const url = getAllUrlParams(window.location.href);
                  let domain;
                  if (process.env.PHP_APP_URL && process.env.WEBAPP_URL) {
                    if (url.version === '2' || currentUser.admin === 0) {
                      // set to app-v3 domain if user is not an admin or version is 2
                      domain = process.env.WEBAPP_URL;
                    } else {
                      domain = process.env.PHP_APP_URL;
                    }
                  } else {
                    const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
                    domain = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' || currentUser.admin === 0 ? 'app-v3' : 'app'}.teambuildr.com`;
                  }
                  const isWorkoutsOrDashboard = currentUser.admin === 0 ? 'workouts' : 'dashboard';
                  window.location = `${domain}/${url.rel === undefined ? isWorkoutsOrDashboard : url.rel}`;
                  return null;
                }}
              />
              // <Redirect to={`/login?rel=${path.replace('/', '')}&version=2`} />
            )
        )
      }
    />
  );
};

export default LoginRoute;
