import React from 'react';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { ColorBlack, ColorGreen } from '../../../../shared/GlobalStyles';

const IconRoundStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  background-color: ${props => props.theme.backgroundColorL2};
  border: ${props => (props.noBorder ? (`0px solid ${props.theme.iconBorderColor}`) : (`1px solid ${props.theme.iconBorderColor}`))};
  border-radius: 100%;
  box-sizing: border-box;
  align-self: center;
  transition: all 250ms ease-in-out;

  svg {
    path {
      fill: ${props => props.theme.textColor};
    }
  }
  ${props => (
    props.complete ? `
      background-color: ${ColorGreen};
      border: 1px solid ${ColorGreen};
    ` : ''
  )}

  ${props => (
    props.light ? `
      border: 1px solid ${props.theme.buttonBorderColor2};
      svg {
        path {
          fill: rgb(172,172,172)!important;
        }
      }
    ` : ''
  )}

  ${props => (
    props.large ? `
      transform: scale(1.6)
    ` : ''
  )}
  ${props => (
    props.xLarge ? `
      transform: scale(2.3)
    ` : ''
  )}
`;

const IconRound = ({
  className,
  icon,
  light,
  large,
  noBorder,
  size,
  xLarge,
}) => (
  <IconRoundStyled
    noBorder={noBorder}
    light={light}
    large={large}
    xLarge={xLarge}
    className={className}
  >
    <IcomoonReact
      iconSet={iconSet}
      size={size}
      icon={icon}
    />
  </IconRoundStyled>
);

IconRound.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  noBorder: PropTypes.bool,
  light: PropTypes.bool,
  large: PropTypes.bool,
  size: PropTypes.number,
  xLarge: PropTypes.bool,
};

IconRound.defaultProps = {
  className: 'icon',
  noBorder: false,
  light: false,
  large: false,
  size: 12,
  xLarge: false,
};

export default IconRound;
