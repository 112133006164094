// a relatively complex component that displays a different modal depending on which
// document type the user intends to create
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { useTheme } from 'emotion-theming';

import Select from 'react-select';
import SubText from '../../../../../shared/components/SubText/SubText';
import Text from '../../../../../shared/components/Text/Text';
import Link from '../../../../../shared/components/Link/Link';
import useReseller from '../../hooks/useReseller';

import 'react-datepicker/dist/react-datepicker.css';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitlePlusErrorWrapper2 = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 39px;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFlexWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .alt-form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .first-stage-select {
    width: 100%;
  }
  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }
  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }
  &.prices {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  &.fee-plus-label {
    height: 20px;
  }
  &.prices-header {
    margin-top: 15px;
  }
  &.last-line-item {
    padding-bottom: 25px;
  }
`;

const CheckboxWrapper = styled('div')`
  padding-top: 12px;
`;

const AltFormGroup = styled('div')`
  width: 100%;
  .control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .alt-form-control {
    min-height: 30px;
    width: 100%;
    div {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .alt-form-control-keep-border {
    min-height: 30px;
    width: 100%;
  }
  .inner-form-group-wrapper-keep-border {
    display: flex;
    width: 100%;
    .btow {
      text-align: center;
      font-size: 12px;
      font-weight: 800;
      width: 30%;
      height: 100%;
      padding: 9px;
      padding-left: 15px;
      border-radius: 0.25rem;
      border: 1px solid #ced4da;
      max-height: 38px;
      span {
        margin-left: -5px;
      }
    }
  }

  .first-stage-select {
    width: 100%;
  }
  .inner-form-group-wrapper {
    display: flex;
    width: 100%;
    .btow {
      text-align: center;
      font-size: 12px;
      font-weight: 800;
      width: 30%;
      height: 100%;
      padding: 9px;
      padding-left: 15px;
      border-radius: 0.25rem;
      border: 1px solid lightgrey;
      border-top-left-radius: 0px;
      border-left: none;
      max-height: 38px;
      span {
        margin-left: -5px;
      }
    }
  }
`;

const FirstStageInnerWrapper1 = styled('div')`
  display: flex;
  width: 55%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const FirstStageInnerWrapper2 = styled('div')`
  display: flex;
  width: 41%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const FirstStageWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TrialPeriodWrapper = styled('div')`
  display: flex;
  width: 55%;
  align-items: flex-start;
  flex-direction: column;
`;

const NumBillingCyclesWrapper = styled('div')`
  display: flex;
  width: 41%;
  align-items: flex-start;
  flex-direction: column;
`;

const PriceBreakdownContainer = styled('div')`
  border: 1px #ced4da solid;
  border-bottom: 0;
  background-image: url('data:image/svg+xml;utf8, <svg viewBox="0 0 200 110" xmlns="http://www.w3.org/2000/svg"><path d="M -15 110 L100 10 L215 110" fill="none" stroke="%23ced4da" stroke-width="1" vector-effect="non-scaling-stroke"/></svg>');
  background-position: bottom left;
  background-size: 5% auto;
  background-repeat: repeat-x;
  margin-top: 15px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
`;

const PriceBreakdownContents = styled('div')`
  margin: 15px 20px 20px 20px;
`;

const CreateProgramSlide3 = ({
  formProps,
  page3Errors,
}) => {
  const theme = useTheme();
  const {
    currentReseller,
  } = useReseller();

  const billingFrequencyOptions = [
    {
      name: 'Monthly',
      id: 1,
    },
    {
      name: '3 Months',
      id: 3,
    },
    {
      name: '6 Months',
      id: 6,
    },
    {
      name: 'Yearly',
      id: 12,
    },
  ];

  const trialPeriodOptions = () => {
    const returnArray = [];
    for (let i = 1; i < 31; i += 1) {
      returnArray.push({
        name: i,
        id: i,
      });
    }
    return returnArray;
  };

  const billingCylceOptions = () => {
    const returnArray = [];
    for (let i = 1; i < 13; i += 1) {
      returnArray.push({
        name: i,
        id: i,
      });
    }
    return returnArray;
  };

  const trialAndBillingText = () => {
    let part1;
    let part2;
    if (formProps.values.trialPeriod && formProps.values.trialPeriod.id > 0) {
      part1 = `Customer will be charged after ${formProps.values.trialPeriod.id} ${formProps.values.trialPeriod.id > 1 ? 'days' : 'day'}`;
    } else {
      part1 = 'Customer will be charged immediately after sign up';
    }
    if (formProps.values.numBillingCycles) {
      let billingFrequencyLabel = 'month';
      let billingMultiplier = formProps.values.billingFrequency;
      if (formProps.values.billingFrequency === 12) {
        billingFrequencyLabel = 'year';
        billingMultiplier = 1;
      }
      part2 = `, and the subscription will end after ${formProps.values.numBillingCycles.id * billingMultiplier} ${billingFrequencyLabel}${formProps.values.numBillingCycles.id > 1 ? 's.' : '.'}`;
    } else {
      part2 = ', and the subscription will be on-going.';
    }
    return `${part1}${part2}`;
  };

  const padNumber = (num) => {
    const twoPlacedFloat = parseFloat(num).toFixed(2);
    return twoPlacedFloat;
  };

  const calculateStripeFee = () => (padNumber((formProps.values.price * 0.029) + 0.30));

  const calculateTeamBuildrFee = () => padNumber(formProps.values.price * (currentReseller?.applicationFeePercentage / 100));

  const hasApplicationFee = () => currentReseller?.applicationFeePercentage && currentReseller?.applicationFeePercentage > 0;

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <FormGroup>
                <FirstStageWrapper>
                  <FirstStageInnerWrapper1>
                    <TitlePlusErrorWrapper2>
                      <Text className='formgroup-text'><strong>Billing Frequency</strong></Text>
                      {formProps.errors.billingFrequency && (formProps.touched.billingFrequency || page3Errors) ? (
                        <FieldError className='error-text'>{formProps.errors.billingFrequency}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper2>
                    <Select
                      defaultValue={
                        formProps.values.billingFrequency ? billingFrequencyOptions.filter((option) => option.id === formProps.values.billingFrequency)
                          : billingFrequencyOptions[0]
                      }
                      className='first-stage-select'
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={billingFrequencyOptions}
                      onChange={(options) => {
                        if (options) {
                          formProps.setFieldValue('billingFrequency', options.id);
                        }
                      }}
                      isOptionSelected={(option) => {
                        if (option) {
                          if (formProps.values.billingFrequency === option) {
                            return true;
                          }
                        }
                        return false;
                      }}
                      isSearchable
                    />
                  </FirstStageInnerWrapper1>
                  <FirstStageInnerWrapper2>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='formgroup-text'><strong>Price</strong></Text>
                        {formProps.errors.price && (formProps.touched.price || page3Errors) ? (
                          <FieldError className='error-text'>{formProps.errors.price}</FieldError>
                        ) : null}
                      </TitlePlusErrorWrapper>
                      <Field
                        className='form-control'
                        id='price-field'
                        name='price'
                        placeholder={currentReseller?.placeSymbolAfterCurrency ? `e.g. 50.00${currentReseller?.currencySymbol}` : `e.g. ${currentReseller?.currencySymbol}50.00`}
                        type='number'
                        as='select'
                        onBlur={() => {
                          if (formProps.values.price) {
                            const int = formProps.values.price;
                            const twoPlacedFloat = parseFloat(int).toFixed(2);
                            formProps.setFieldValue('price', twoPlacedFloat);
                            formProps.setFieldTouched('price', true);
                          }
                        }}
                      />
                    </FormGroup>
                  </FirstStageInnerWrapper2>
                </FirstStageWrapper>
              </FormGroup>
              <AltFormGroup>
                <OuterFlexWrapper>
                  <TrialPeriodWrapper>

                    <TitlePlusErrorWrapper2>
                      <Text className='formgroup-text'><strong>Trial Period</strong></Text>
                      <FieldError className='error-text'>{formProps.errors.trialPeriod}</FieldError>
                    </TitlePlusErrorWrapper2>
                    <div className='inner-form-group-wrapper'>
                      <Select
                        className='alt-form-control'
                        defaultValue={
                          formProps.values.trialPeriod ? trialPeriodOptions().filter((option) => option.id === formProps.values.trialPeriod.id)
                            : null
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={trialPeriodOptions()}
                        onChange={(options) => {
                          formProps.setFieldValue('trialPeriod', options);
                        }}
                        isOptionSelected={(trialPeriod) => formProps.values.trialPeriod === trialPeriod}
                        isClearable
                        placeholder='N/A'
                      />
                      <div className='btow'><SubText>Days</SubText></div>
                    </div>
                  </TrialPeriodWrapper>

                  <NumBillingCyclesWrapper>

                    <TitlePlusErrorWrapper2>
                      <Text className='formgroup-text'><strong>Max Billing Cycles</strong></Text>
                      <FieldError className='error-text'>{formProps.errors.numBillingCycles}</FieldError>
                    </TitlePlusErrorWrapper2>
                    <div className='inner-form-group-wrapper-keep-border'>
                      <Select
                        className='alt-form-control-keep-border'
                        defaultValue={
                          formProps.values.numBillingCycles ? billingCylceOptions().filter((option) => option.id === formProps.values.numBillingCycles.id)
                            : null
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={billingCylceOptions()}
                        onChange={(options) => {
                          formProps.setFieldValue('numBillingCycles', options);
                        }}
                        isOptionSelected={(numBillingCycles) => formProps.values.numBillingCycles === numBillingCycles}
                        isClearable
                        placeholder='N/A'
                      />
                    </div>
                  </NumBillingCyclesWrapper>
                </OuterFlexWrapper>
              </AltFormGroup>
              <FormGroup>
                <Text className='url-string' fontSize={14} style={{ marginTop: '5px' }}>{trialAndBillingText()}</Text>
              </FormGroup>
              {formProps.values.price && (
                <div>
                  <FormGroup className='prices-header'>
                    <Text
                      fontWeight={600}
                      fontSize={theme.textFontSizes[1]}
                    >
                      Pricing Breakdown
                    </Text>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      This is how much you make when the program is purchased
                    </Text>
                  </FormGroup>
                  <PriceBreakdownContainer>
                    <PriceBreakdownContents>
                      <FormGroup className='prices'>
                        <SubText>
                          Program Price
                        </SubText>
                        <Text
                          fontWeight={600}
                          fontSize='13px'
                        >
                          {currentReseller?.placeSymbolAfterCurrency ? `${padNumber(formProps.values.price)}${currentReseller?.currencySymbol}` : `${currentReseller?.currencySymbol}${padNumber(formProps.values.price)}`}
                        </Text>
                      </FormGroup>
                      <FormGroup className='prices fee-plus-label'>
                        <SubText>
                          Credit Card Processing Fee
                        </SubText>
                        <Text
                          fontWeight={600}
                          fontSize='13px'
                        >
                          {currentReseller?.placeSymbolAfterCurrency ? `-${calculateStripeFee()}${currentReseller?.currencySymbol}` : `-${currentReseller?.currencySymbol}${calculateStripeFee()}`}
                        </Text>
                      </FormGroup>
                      <Link
                        color='lightText.1'
                        href='https://support.teambuildr.com/article/d2cynfitju-what-are-the-stripe-transaction-charges'
                        target='_blank'
                        rel='noopener noreferrer'
                        display='block'
                      >
                        Based on US fee (2.9% + $0.30) - click here for other countries
                      </Link>
                      {hasApplicationFee() ? (
                        <FormGroup className='prices'>
                          <SubText>
                            {`TeamBuildr Fee (${currentReseller?.applicationFeePercentage}%)`}
                          </SubText>
                          <Text
                            fontWeight={600}
                            fontSize='13px'
                          >
                            {currentReseller?.placeSymbolAfterCurrency ? `-${calculateTeamBuildrFee()}${currentReseller?.currencySymbol}` : `-${currentReseller?.currencySymbol}${calculateTeamBuildrFee()}`}
                          </Text>
                        </FormGroup>
                      ) : null }
                      <FormGroup className='prices last-line-item'>
                        <SubText>
                          You Make
                        </SubText>
                        <Text
                          fontWeight={600}
                          fontSize='13px'
                          color={theme.colors.green}
                        >
                          {currentReseller?.placeSymbolAfterCurrency ? `${padNumber(formProps.values.price - calculateStripeFee() - calculateTeamBuildrFee())}${currentReseller?.currencySymbol}` : `${currentReseller?.currencySymbol}${padNumber(formProps.values.price - calculateStripeFee() - calculateTeamBuildrFee())}`}
                        </Text>
                      </FormGroup>
                    </PriceBreakdownContents>
                  </PriceBreakdownContainer>
                </div>
              )}
              <FormGroup className='d-flex'>
                <CheckboxWrapper>
                  {formProps.errors.agreement && page3Errors ? (
                    <FieldError className='error-text'>{formProps.errors.agreement}</FieldError>
                  ) : null}
                  <label
                    className='checkbox-label'
                  >
                    <Field
                      className='checkbox'
                      id='agreement-field'
                      name='agreement'
                      type='checkbox'
                      onChange={() => {
                        formProps.setFieldValue('agreement', !formProps.values.agreement && true);
                      }}
                      checked={formProps.values.agreement}
                    />
                    <Text className='url-string' fontSize={14}>
                      I verify this workout program is my intellectual property
                    </Text>

                  </label>
                </CheckboxWrapper>
              </FormGroup>
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

CreateProgramSlide3.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  submitClicked: PropTypes.instanceOf(Boolean).isRequired,
  page3Errors: PropTypes.bool.isRequired,
};

export default CreateProgramSlide3;
