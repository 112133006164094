/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const NotificationOuterContentDiv = styled('div')`
	background-color: white;
	margin: 20px 40px 0 40px;
	padding: 0 1px 25px 1px;
	object-fit: contain;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

	@media screen and (max-width: 767px) {
		margin: 20px 15px 0 15px;
  }
`;

const NotificationOuterContent = ({ children }) => (
  <NotificationOuterContentDiv>
    {children}
  </NotificationOuterContentDiv>
);

NotificationOuterContent.propTypes = {
  children: PropTypes.node.isRequired,
};
export default NotificationOuterContent;
