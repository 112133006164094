import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Nunito, Gutter } from '../../GlobalStyles';

import IconRound from '../IconRound';

const TitleContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${(props) => (props.noMargin ? '0px' : Gutter)};
  
  .title-icon {
    margin-top: 20px;
  }
`;

const TitleText = styled('h2')`
  color: ${(props) => props.theme.textColor};
  font-family: ${Nunito};
  margin-top: 35px;
  text-align: center;
`;

const Title = ({
  children,
  icon,
  noMargin,
}) => (
  <TitleContainer
    className={icon === 'note' ? 'note-title-container' : 'title-container'}
    noMargin={noMargin}
  >
    <IconRound
      className='title-icon'
      icon={icon}
      xLarge
    />
    <TitleText>
      {children}
    </TitleText>
  </TitleContainer>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
};

Title.defaultProps = {
  noMargin: false,
};

export default Title;
