import { connect } from 'react-redux';
import WorkoutEntriesLifts from '../presentational/WorkoutEntriesLifts';
import { updateWorkout } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteId: state[ownProps.namespace].athleteId,
  settings: state.weightRoom.currentUser.settings,
});

const mapDispatchToProps = dispatch => ({
  updateWorkout: (index, namespace, values) => {
    dispatch(updateWorkout(index, namespace, values));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(WorkoutEntriesLifts);
