/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDropDown from '../../../force-plate-dashboard/components/ForcePlateDropDown';
import ForcePlateDatePicker from '../../../force-plate-dashboard/components/presentational/ForcePlateDatePicker';
import SCOverviewChart from './SCOverviewChart';
import ExerciseMetricsContainers from './ExerciseMetricsContainer';
import SCLegendInfoModal from './SCLegendInfoModal';
import ExerciseFilter from './ExerciseFilter';
import {
  setSelectedAthletePerformance,
  setIsLegendInfoModalShowing,
} from '../../ducks/sportCoachDashboardActions';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 7px 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const LegendAndIcon = styled('div')`
  display: flex;
  width: 230px;
  margin-left: 17px;
  align-items: center;

  button {
    background: transparent!important;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const LegendTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
`;

const ViewOnlyOverview = () => {
  const athleteList = useSelector((state) => state.sportCoachDashboard.data.athleteList);
  const endDateCal = useSelector((state) => state.sportCoachDashboard.data.endDateCal);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const scDashData = useSelector((state) => state.sportCoachDashboard.data.scDashData);
  const selectedAthletePerformance = useSelector(
    (state) => state.sportCoachDashboard.data.selectedAthletePerformance,
  );
  const startDateCal = useSelector((state) => state.sportCoachDashboard.data.startDateCal);

  const dispatch = useDispatch();

  const athleteDropdownList = scDashData && [...athleteList];
  athleteDropdownList.length > 0 && athleteDropdownList.unshift({ fullName: 'View Group', userId: null });

  const setAthlete = (option) => {
    if (option.fullName === 'View Group') {
      dispatch(setSelectedAthletePerformance(null, false));
    } else {
      dispatch(setSelectedAthletePerformance(option, true));
    }
  };

  const handleLegendInfoClick = () => {
    dispatch(setIsLegendInfoModalShowing(true));
  };

  const emptyFunction = () => undefined;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              disabled
              list={['']}
              label='name'
              selectItemFunction={emptyFunction}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {scDashData && (
            <ForcePlateDropDown
              list={athleteDropdownList}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthletePerformance || {}}
            />
            )}
            <ExerciseFilter />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              disabled
              setStartDate={emptyFunction}
              setEndDate={emptyFunction}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <SCOverviewChart />
      </DashboardContainer>
      <LegendAndIcon>
        <LegendTitle>
          Key Performance Exercises
        </LegendTitle>
        <Button
          iconOnly
          icon='info'
          customColor='#00000000'
          noBorder
          onClick={handleLegendInfoClick}
          rounded
          disabled={!scDashData}
        />
      </LegendAndIcon>
      <ExerciseMetricsContainers />
    </>
  );
};

export default ViewOnlyOverview;
