// imports action types and dispatches action to the back end for processessing and db

import groupBy from 'lodash.groupby';

import moment from 'moment';
import Logger from 'js-logger';

import {
  SET_ACTIVE_REDUX_MODAL,
  SET_ACTIVE_CONVERSATION,
  CLEAR_ACTIVE_CONVERSATION,
  FETCH_CONVERSATIONS_START,
  FETCH_CONVERSATIONS_FULFILLED,
  FETCH_CONVERSATIONS_REJECTED,
  FETCH_CONVERSATION_MESSAGES_START,
  FETCH_CONVERSATION_MESSAGES_FULFILLED,
  FETCH_CONVERSATION_MESSAGES_REJECTED,
  FETCH_MORE_MESSAGES_START,
  FETCH_MORE_MESSAGES_FULFILLED,
  FETCH_MORE_MESSAGES_REJECTED,
  FETCH_MORE_MESSAGES_EMPTY,
  FETCH_SCHEDULED_MESSAGES_START,
  FETCH_SCHEDULED_MESSAGES_FULFILLED,
  FETCH_SCHEDULED_MESSAGES_REJECTED,
  UNSCHEDULE_MESSAGE_START,
  UNSCHEDULE_MESSAGE_FULFILLED,
  UNSCHEDULE_MESSAGE_REJECTED,
  UPDATE_MESSAGE_START,
  UPDATE_MESSAGE_FULFILLED,
  UPDATE_MESSAGE_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  UPLOAD_MEDIA_URL_START,
  UPLOAD_MEDIA_URL_FULFILLED,
  UPLOAD_MEDIA_URL_REJECTED,
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_CONVERSATION_USERS_START,
  FETCH_CONVERSATION_USERS_FULFILLED,
  FETCH_CONVERSATION_USERS_REJECTED,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_CALENDARS_START,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  CREATE_NEW_MESSAGE_START,
  CREATE_NEW_MESSAGE_FULFILLED,
  CREATE_NEW_MESSAGE_REJECTED,
  CREATE_NEW_SCHEDULED_MESSAGE,
  SET_INITIAL_MESSAGES,
  SET_IS_INITIAL_MESSAGES,
  SET_IS_MEDIA_UPLOADED,
  SET_IS_TYPING_TRUE,
  SET_IS_TYPING_FALSE,
  CREATE_CONVERSATION_START,
  CREATE_CONVERSATION_FULFILLED,
  CREATE_CONVERSATION_REJECTED,
  SET_SCHEDULED_MESSAGE_REDUX,
  SET_SELECTED_CONVERSATIONS_REDUX,
  SET_INCOMING_ACTIVE_MESSAGE,
  SET_INCOMING_INACTIVE_MESSAGE,
  SET_INCOMING_NEW_INACTIVE_MESSAGE,
  MARK_CONVERSATIONS_AS_READ_START,
  MARK_CONVERSATIONS_AS_READ_FULFILLED,
  MARK_CONVERSATIONS_AS_READ_REJECTED,
  STORE_CONVERSATION_MESSAGES_BY_DAY,
  DELETE_CONVERSATIONS_FULFILLED,
  DELETE_CONVERSATIONS_START,
  DELETE_CONVERSATIONS_REJECTED,
  MUTE_NOTIFICATIONS_FULFILLED,
  MUTE_NOTIFICATIONS_START,
  MUTE_NOTIFICATIONS_REJECTED,
  SET_MESSAGE_MEDIA_URL,
  FETCH_CONVERSATIONS_NEXT_PAGE_START,
  FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED,
  SET_IS_SCHEDULED_OPEN,
  CREATE_INDIVIDUAL_CONVERSATIONS_START,
  CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED,
  CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED,
  CREATE_SILENT_CONVERSATION_START,
  CREATE_SILENT_CONVERSATION_FULFILLED,
  CREATE_SILENT_CONVERSATION_REJECTED,
  SEND_EXTERNAL_MESSAGE_ONLY_START,
  SEND_EXTERNAL_MESSAGE_ONLY_FULFILLED,
  SEND_EXTERNAL_MESSAGE_ONLY_REJECTED,
  SET_SUPPORT_MODAL,
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  UPDATE_MOBILE_SIDEBAR,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';
import generateUniqueId from '../../../shared/utils/generateUniqueId';
import {object, array} from 'yup';
import MessagesList from '../../../shared/components/SocketTester/components/MessagesList';

authProvider();

export const fetchConversations = (maintainActiveConversation = false, refreshPage = false) => (
  (dispatch, getState) => {
    let page;
    if (refreshPage) {
      page = 1;
    } else {
      const currentPage = getState().conversations.data.page;
      page = currentPage ? currentPage + 1 : 1;
    }
    if (page === 1) {
      dispatch({ type: FETCH_CONVERSATIONS_START });
    } else {
      dispatch({ type: FETCH_CONVERSATIONS_NEXT_PAGE_START });
    }
    const pageSize = 50;
    axiosAuthed.get(`/me/conversations?page=${page}&pageSize=${pageSize}`)
      .then((response) => {
        if (page === 1) {
          dispatch({
            type: FETCH_CONVERSATIONS_FULFILLED,
            payload: {
              data: response.data,
              page,
              maintainActiveConversation,
            },
          });
        } else {
          dispatch({
            type: FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED,
            payload: {
              data: response.data,
              page,
              maintainActiveConversation,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CONVERSATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchConversationMessages = (accountCode, conversationId) => (
  (dispatch, getState) => {
    dispatch({ type: FETCH_CONVERSATION_MESSAGES_START });
    axiosAuthed.get(`/me/accounts/${accountCode}/conversations/${conversationId}/messages?page=1&pageSize=50`)
      .then((response) => {
        // To add date separators to the conversation view, we need to store the
        // messages by day using lodash groupBy and giving it the moment formatted sentAt date.
        // This returns a collection of objects with the day as the key and an array of messages.
        const timeZone = getState().auth.data.currentUser.userTimeZone;

        const messageDay = (message) => moment.tz(message.sentAt, timeZone).startOf('day').format('L');
        const today = moment.tz(timeZone).format('L');

        // Group messages by day in a collection and also in an array.
        const messagesByDay = groupBy(response.data, (message) => messageDay(message));
        const messagesByDayArray = Object.entries(messagesByDay);

        // Since messages by day may not have a today object,
        // we create an empty one and add it to the top of the object
        // so it doesn't crash when creating a new message if it doesn't exist.
        if (!(today in messagesByDay)) {
          messagesByDayArray.unshift([`${today}`, []]);
        }

        Logger.debug('Messages By Day', messagesByDay);

        dispatch({
          type: STORE_CONVERSATION_MESSAGES_BY_DAY,
          payload: messagesByDayArray,
        });

        setTimeout(() => {
          dispatch({
            type: FETCH_CONVERSATION_MESSAGES_FULFILLED,
            payload: response.data,
          });
        }, 600);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CONVERSATION_MESSAGES_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchMoreMessages = (accountCode, conversationId) => (
  (dispatch, getState) => {
    const currentPage = getState().conversations.data.activeConversationPage;
    const nextPage = currentPage + 1;

    dispatch({ type: FETCH_MORE_MESSAGES_START });
    Logger.debug('Fetching more messages');

    axiosAuthed.get(`/me/accounts/${accountCode}/conversations/${conversationId}/messages?page=${nextPage}&pageSize=50`)
      .then((response) => {
        // To add date separators to the conversation view, we need to store the
        // messages by day using lodash groupBy and giving it the moment formatted sentAt date.
        // This returns a collection of objects with the day as the key and an array of messages.
        const timeZone = getState().auth.data.currentUser.userTimeZone;
        const messageDay = (message) => moment.tz(message.sentAt, timeZone).startOf('day').format('L');

        // Get current messages by day array we already have loaded.
        const currentMessagesByDay = getState().conversations.data.conversationsByDay;
        const currentMessages = getState().conversations.data.conversationMessages;

        // Group new messages by day which is a collection and create a matching array.
        const newMessagesByDay = groupBy(response.data, (message) => messageDay(message));
        const newMessagesByDayArray = Object.entries(newMessagesByDay);

        // Destructure the messages array we already have and the new messages
        // we fetched and organized by day into a new array.
        const allMessagesByDay = [
          ...currentMessagesByDay,
          ...newMessagesByDayArray,
        ];

        // Store messages without day separation just in case.
        const allMessages = [
          ...currentMessages,
          ...response.data,
        ];

        Logger.debug('New Messages:', response.data, newMessagesByDay, currentMessagesByDay, allMessagesByDay);

        // dispatch({
        //   type: STORE_CONVERSATION_MESSAGES_BY_DAY,
        //   payload: allMessagesByDayArray,
        // });

        if (response.data.length !== 0) {
          setTimeout(() => {
            dispatch({
              type: FETCH_MORE_MESSAGES_FULFILLED,
              payload: allMessagesByDay,
            });
          }, 600);
        } else {
          setTimeout(() => {
            dispatch({
              type: FETCH_MORE_MESSAGES_EMPTY,
            });
          }, 600);
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_MORE_MESSAGES_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchScheduledMessages = (accountCode, conversationId) => (
  (dispatch) => {
    dispatch({ type: FETCH_SCHEDULED_MESSAGES_START });
    axiosAuthed.get(`/me/accounts/${accountCode}/conversations/${conversationId}/messages/scheduled`)
      .then((response) => {
        dispatch({
          type: FETCH_SCHEDULED_MESSAGES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SCHEDULED_MESSAGES_REJECTED,
          payload: err,
        });
      });
  }
);

export const unscheduleMessage = (accountCode, conversationId, messageId) => (
  (dispatch, getState) => {
    dispatch({ type: UNSCHEDULE_MESSAGE_START });
    axiosAuthed.post(`/me/accounts/${accountCode}/conversations/${conversationId}/messages/${messageId}/unschedule`)
      .then((response) => {
        const { scheduledMessages } = getState().conversations.data;
        const messageIndex = scheduledMessages.findIndex((message) => message.id === messageId);

        dispatch({
          type: UNSCHEDULE_MESSAGE_FULFILLED,
          payload: {
            ...response.data,
            messageIndex,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: UNSCHEDULE_MESSAGE_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateMessage = (accountCode, conversationId, messageId, values) => (
  (dispatch, getState) => {
    dispatch({ type: UPDATE_MESSAGE_START });
    axiosAuthed.put(`/me/accounts/${accountCode}/conversations/${conversationId}/messages/${messageId}`, values)
      .then((response) => {
        const { scheduledMessages } = getState().conversations.data;
        const messageIndex = scheduledMessages.findIndex((message) => message.id === messageId);

        dispatch({
          type: UPDATE_MESSAGE_FULFILLED,
          payload: {
            ...response.data,
            messageIndex,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_MESSAGE_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveConversation = (conversation) => (
  {
    type: SET_ACTIVE_CONVERSATION,
    payload: {
      ...conversation,
      page: 1,
    },
  }
);

export const setIsMobileSidebarOpen = (isOpen) => (
  {
    type: UPDATE_MOBILE_SIDEBAR,
    payload: isOpen,
  }
);

export const clearActiveConversation = () => ({
  type: CLEAR_ACTIVE_CONVERSATION,
});

export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?messaging=1`)
      .then((response) => {
        dispatch({
          type: FETCH_GROUPS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_GROUPS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchUsers = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_USERS_START });
    axiosAuthed.get(`/accounts/${accountCode}/users?groupFilter=messaging`)
      .then((response) => {
        dispatch({
          type: FETCH_USERS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USERS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchCalendars = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CALENDARS_START });
    axiosAuthed.get(`/accounts/${accountCode}/calendars`)
      .then((response) => {
        dispatch({
          type: FETCH_CALENDARS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchConversationUsers = (accountCode, conversationId) => (
  (dispatch) => {
    dispatch({ type: FETCH_CONVERSATION_USERS_START });
    axiosAuthed.get(`/me/accounts/${accountCode}/conversations/${conversationId}/users`)
      .then((response) => {
        dispatch({
          type: FETCH_CONVERSATION_USERS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CONVERSATION_USERS_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveReduxModal = (activeModal) => (
  {
    type: SET_ACTIVE_REDUX_MODAL,
    payload: activeModal,
  }
);

export const setSupportModal = (messageId) => (
  {
    type: SET_SUPPORT_MODAL,
    payload: messageId,
  }
);

export const setInitialMessages = (initialMessages) => (
  {
    type: SET_INITIAL_MESSAGES,
    payload: initialMessages,
  }
);

export const setIsInitialMessages = (bool) => (
  {
    type: SET_IS_INITIAL_MESSAGES,
    payload: bool,
  }
);

export const setIsMediaUploaded = (bool) => (
  {
    type: SET_IS_MEDIA_UPLOADED,
    payload: bool,
  }
);

export const setScheduledMessageRedux = (dateTime) => (
  {
    type: SET_SCHEDULED_MESSAGE_REDUX,
    payload: dateTime,
  }
);

export const setSelectedConversationsRedux = (selections) => (
  {
    type: SET_SELECTED_CONVERSATIONS_REDUX,
    payload: selections,
  }
);

export const setIsTyping = (data) => (
  (dispatch, getState) => {
    const activeConversation = getState().conversations.data.activeConversation.id;
    const isActiveConversation = data.conversationId === activeConversation;

    if (isActiveConversation && data.isTyping) {
      dispatch({
        type: SET_IS_TYPING_TRUE,
        payload: data,
      });
    }
    if (isActiveConversation && !data.isTyping) {
      const { usersTyping } = getState().conversations.data;
      const userTypingIndex = usersTyping.findIndex((user) => user.userId === data.userId);

      dispatch({
        type: SET_IS_TYPING_FALSE,
        payload: {
          index: userTypingIndex,
        },
      });
    }
  }
);

export const setIsScheduledOpen = (data) => (
  {
    type: SET_IS_SCHEDULED_OPEN,
    payload: data,
  }
);

export const createNewMessage = (newMessage, accountCode, conversationId) => (
  (dispatch, getState) => {
    dispatch({ type: CREATE_NEW_MESSAGE_START });
    axiosAuthed.post(`/me/accounts/${accountCode}/conversations/${conversationId}/messages`, newMessage)
      .then((response) => {
        const timeZone = getState().auth.data.currentUser.userTimeZone;

        const messageDay = moment.tz(response.data.sentAt, timeZone).startOf('day').format('L');
        const newDay = {
          [messageDay]: [
            response.data,
          ],
        };
        const dayMessages = getState().conversations.data.conversationsByDay[messageDay];
        const { conversationsByDay } = getState().conversations.data;
        const updatedConversationsByDay = {
          ...conversationsByDay,
          [messageDay]: [
            response.data,
          ],
        };

        console.log(newDay, getState().conversations.data.conversationsByDay, updatedConversationsByDay);
        const dayIndex = conversationsByDay.findIndex((day) => day[0] === messageDay);

        if (response.data.sentAt === null) {
          dispatch({
            type: CREATE_NEW_SCHEDULED_MESSAGE,
            payload: {
              ...response.data,
              userId: newMessage.userId,
              key: generateUniqueId(),
              day: moment(response.data.sentAt).startOf('day').format('L'),
              dayIndex,
            },
          });
        }
        if (response.data.sentAt !== null) {
          dispatch({
            type: CREATE_NEW_MESSAGE_FULFILLED,
            payload: {
              ...response.data,
              userId: newMessage.userId,
              key: generateUniqueId(),
              day: moment(response.data.sentAt).startOf('day').format('L'),
              dayIndex,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_NEW_MESSAGE_REJECTED,
          payload: err,
        });
      });
  }
);

export const createConversation = (values) => (dispatch) => {
  dispatch({ type: CREATE_CONVERSATION_START });
  axiosAuthed.post(`/me/accounts/${values.accountCode}/conversations`, values.newConversation)
    .then((response) => {
      dispatch({
        type: CREATE_CONVERSATION_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_CONVERSATION_REJECTED,
        payload: err,
      });
    });
};

export const createIndividualConversations = (values) => (dispatch) => {
  dispatch({ type: CREATE_INDIVIDUAL_CONVERSATIONS_START });
  axiosAuthed.post(`/me/accounts/${values.accountCode}/conversations/individual`, values.newConversation)
    .then((response) => {
      dispatch({
        type: CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED,
        payload: err,
      });
    });
};

export const setIncomingMessage = (data) => (
  (dispatch, getState) => {
    const { activeConversation, conversations, conversationsByDay } = getState().conversations.data;
    const currentUserId = getState().auth.data.currentUser.id;
    const timeZone = getState().auth.data.currentUser.userTimeZone;

    const incomingConversationIndex = conversations.findIndex(
      (conversation) => conversation.id === data.conversationId,
    );

    const messageDay = moment.tz(data.sentAt, timeZone).startOf('day').format('L');

    const dayIndex = conversationsByDay.findIndex((day) => day[0] === messageDay);

    // Check if new incoming message is from our active conversation,
    // and not a message from the current user.
    if ((data.conversationId === activeConversation.id) && (data.userId !== currentUserId)) {
      dispatch({
        type: SET_INCOMING_ACTIVE_MESSAGE,
        payload: {
          ...data,
          userId: data.userId,
          key: generateUniqueId(),
          day: moment(data.sentAt).startOf('day').format(),
          dayIndex,
          incomingConversationIndex,
        },
      });
    }

    // Check if the incoming message if from a different conversation and not a new message.
    if ((data.conversationId !== activeConversation.id && incomingConversationIndex !== -1)) {
      dispatch({
        type: SET_INCOMING_INACTIVE_MESSAGE,
        payload: {
          ...data,
          index: incomingConversationIndex,
          userId: data.userId,
          dayIndex,
        },
      });
    }

    // Check if the incoming message is a message that doesn't exist in the
    // current conversation array.
    if (incomingConversationIndex === -1) {
      const newConversation = {
        ...data.conversation,
        read: false,
        readAt: null,
      };
      dispatch({
        type: SET_INCOMING_NEW_INACTIVE_MESSAGE,
        payload: {
          ...newConversation,
          userId: data.userId,
          dayIndex,
        },
      });
    }
  }
);

// export const createNewMessage = (newMessage) => (
//   {
//     type: CREATE_NEW_MESSAGE,
//     payload: {
//       messageBody: newMessage.messageBody,
//       userId: newMessage.userId,
//       key: generateUniqueId(),
//     },
//   }
// );

export const uploadMedia = (values) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append(values.type, values.acceptedFile[0]);
    dispatch({ type: UPLOAD_MEDIA_START });
    axiosAuthed.post(`/media/upload/${values.type}`, formData).then((response) => {
      dispatch({
        type: UPLOAD_MEDIA_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_MEDIA_REJECTED,
          payload: err,
        });
      });
  }
);

export const uploadMediaUrl = (values) => (
  (dispatch) => {
    // const formData = new FormData();
    console.log(values);
    // formData.append(values);
    dispatch({ type: UPLOAD_MEDIA_URL_START });
    axiosAuthed.post('/media/url', values).then((response) => {
      dispatch({
        type: UPLOAD_MEDIA_URL_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_MEDIA_URL_REJECTED,
          payload: err,
        });
      });
  }
);

export const markAsRead = (values) => (
  (dispatch) => {
    dispatch({ type: MARK_CONVERSATIONS_AS_READ_START });
    axiosAuthed.patch(`/me/accounts/${values.accountCode}/conversations/read`, values.readArray)
      .then((response) => {
        dispatch({
          type: MARK_CONVERSATIONS_AS_READ_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MARK_CONVERSATIONS_AS_READ_REJECTED,
          payload: err,
        });
      });
  }
);

export const deleteConversations = (values) => (
  (dispatch) => {
    dispatch({ type: DELETE_CONVERSATIONS_START });
    axiosAuthed.patch(`/me/accounts/${values.accountCode}/conversations/delete`, values.readArray)
      .then((response) => {
        dispatch({
          type: DELETE_CONVERSATIONS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CONVERSATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const muteNotifications = (values) => (
  (dispatch) => {
    const { muted } = values;
    dispatch({ type: MUTE_NOTIFICATIONS_START });
    axiosAuthed.put(`/me/accounts/${values.accountCode}/conversations/${values.conversationId}`, { muted })
      .then((response) => {
        dispatch({
          type: MUTE_NOTIFICATIONS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: MUTE_NOTIFICATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const openMessageVideo = (mediaUrl) => (
  (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MEDIA_URL,
      payload: mediaUrl,
    });
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'play-message-video-modal',
    });
  }
);

export const openJournalVideo = (mediaUrl) => (
  (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MEDIA_URL,
      payload: mediaUrl,
    });
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'play-message-video-modal',
    });
  }
);

export const openImageLightbox = (mediaUrl) => (
  (dispatch) => {
    dispatch({
      type: SET_MESSAGE_MEDIA_URL,
      payload: mediaUrl,
    });
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: 'image-lightbox',
    });
  }
);

export const createSilentConversation = (values) => (dispatch) => {
  dispatch({ type: CREATE_SILENT_CONVERSATION_START });
  axiosAuthed.post(`/me/accounts/${values.accountCode}/conversations`, values.newConversation)
    .then((response) => {
      dispatch({
        type: CREATE_SILENT_CONVERSATION_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: CREATE_SILENT_CONVERSATION_REJECTED,
        payload: err,
      });
    });
};

export const sendExternalMessageOnly = (newMessage, accountCode, conversationId) => (dispatch) => {
  dispatch({ type: SEND_EXTERNAL_MESSAGE_ONLY_START });
  axiosAuthed.post(`/me/accounts/${accountCode}/conversations/${conversationId}/messages`, newMessage)
    .then((response) => {
      dispatch({
        type: SEND_EXTERNAL_MESSAGE_ONLY_FULFILLED,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SEND_EXTERNAL_MESSAGE_ONLY_REJECTED,
        payload: err,
      });
    });
};

export const submitSupport = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_SUPPORT_START });
    axiosAuthed.post('/support', values)
      .then((response) => {
        dispatch({
          type: SUBMIT_SUPPORT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_SUPPORT_REJECTED,
          payload: err,
        });
      });
  }
);
