import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IconRound from './IconRound';
import { TransitionGlobal, ColorGreen } from '../../../../shared/GlobalStyles';

const ArrowButtonContainer = styled('button')`
  display: flex;
  align-self: center;
  border: 0px;
  background-color: transparent;
  cursor: pointer;
  transition: ${TransitionGlobal};

  .icon {
    ${(props) => (
    props.complete && (
      `border: 1px solid ${ColorGreen}!important;`
    )
  )}
    svg {
      path {
        ${(props) => (
    props.complete && (
      'fill: rgb(13, 204, 138)!important;'
    )
  )}
      }
    }
  }

  ${(props) => (
    props.down ? `
      transform: rotate(180deg);
    ` : ''
  )}

  ${(props) => (
    props.right ? `
      transform: rotate(90deg);
    ` : ''
  )}

  ${(props) => (
    props.left ? `
      transform: rotate(-90deg);
    ` : ''
  )}

  ${(props) => (
    props.disabled ? `
      opacity: .2;
      cursor: default;
    ` : ''
  )}
`;

const ArrowButton = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    const { handleArrowClick } = props;
    handleArrowClick(e.currentTarget.dataset.first);
  };

  const {
    complete,
    dataFirst,
    down,
    right,
    left,
    disabled,
    large,
    light,
    icon,
  } = props;

  return (
    <ArrowButtonContainer
      complete={complete}
      onClick={handleClick}
      down={down}
      right={right}
      left={left}
      disabled={disabled}
      data-first={dataFirst}
    >
      <IconRound
        icon={icon}
        light={light}
        large={large}
      />
    </ArrowButtonContainer>
  );
};

ArrowButton.propTypes = {
  complete: PropTypes.bool,
  dataFirst: PropTypes.string,
  disabled: PropTypes.bool,
  down: PropTypes.bool,
  handleArrowClick: PropTypes.instanceOf(Function).isRequired,
  icon: PropTypes.string.isRequired,
  large: PropTypes.bool,
  left: PropTypes.bool,
  light: PropTypes.bool,
  right: PropTypes.bool,
};

ArrowButton.defaultProps = {
  complete: false,
  dataFirst: '',
  disabled: false,
  down: false,
  large: false,
  light: false,
  left: false,
  right: false,
};

export default ArrowButton;
