/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import SharedPickerComponent from './SharedPickerComponent';
import {
  setActiveGroup, fetchAthleteParticipationByGroupId, fetchAthletesToWatchByGroupIdAndDate,
} from '../../ducks/wearablesDashboardActions';

const WarningComponent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const reportableGroups = useSelector((state) => state.wearablesDashboard.data.reportableGroups);
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);

  /** total athletes to watch count, counts the total number of athletes with an exertion score available for that day, not just the
   * athletes to keep an eye on, which is returned as a seperate property on this endpoint
   */
  const totalAthletesToWatchCount = useSelector((state) => state.wearablesDashboard.data.athletesToWatch?.total);
  const isAthletesToWatchLoading = useSelector((state) => state.wearablesDashboard.ui.isAthletesToWatchLoading);

  /** This endpoint returns the number of active athetes who are connected and sharing */
  const activeAthleteParticipationCount = useSelector((state) => state.wearablesDashboard.data.athleteParticipation?.active?.count);
  const isAthleteParticipationLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteParticipationLoading);

  const isReportableGroupsLoading = useSelector((state) => state.wearablesDashboard.ui.isReportableGroupsLoading);

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    max-width: 253px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    padding: 20px;
    background-image: linear-gradient(rgb(255, 102, 0), rgb(252, 96, 118));
  `;

  const TextContainer = styled('span')`
    display: flex:
    justify-content: center;
    align-items: center;
    padding-top: 15px;
`;

  return (
    <>
      {currentUser && reportableGroups && !reportableGroups.length ? (
        <Wrapper>
          <IcomoonReact
            iconSet={iconSet}
            size={30}
            icon='warning'
            color='white'
          />
          <TextContainer>
            <Text fontSize={12} fontWeight={700} color='white'>Oops! Something went wrong. We were not able to find any reportable groups</Text>
          </TextContainer>
        </Wrapper>
      ) : (null)}
      { !isReportableGroupsLoading && !isAthletesToWatchLoading && !isAthleteParticipationLoading && reportableGroups?.length && totalAthletesToWatchCount <= 0 ? (
        <Wrapper>
          <IcomoonReact
            iconSet={iconSet}
            size={30}
            icon='warning'
            color='white'
          />
          {activeAthleteParticipationCount > 0 ? (
            <TextContainer>
              <Text fontSize={12} fontWeight={700} color='white'>{`Oops! No exertion score data for selected date. You have ${activeAthleteParticipationCount} active athlete(s) for the selected group, but no athletes in this group have exertion score data available for this date. Try selecting a different date.`}</Text>
            </TextContainer>
          ) : (
            <TextContainer>
              <Text fontSize={12} fontWeight={700} color='white'>Oops! No Active athletes in this group. Try selecting a different group or remind your athletes to share their data.</Text>
            </TextContainer>
          )}
        </Wrapper>
      ) : (null)}
    </>
  );
};

export default WarningComponent;
