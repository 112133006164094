import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';

import Conditional from './Conditional';
import WorkoutFields from './WorkoutFields';
import FieldsStatic from './WorkoutFieldsStatic';
import CompletionEntry from './WorkoutEntriesCompletion';

const CircuitEntries = ({
  athleteId,
  entriesViewHeight,
  formikRef,
  handleInfoClick,
  index,
  namespace,
  supersetWorkout,
  workout,
  wrvSupersetsEnabled,
  updateWorkout,
}) => {
  // Cardio Render Conditions
  const isCircuitTime = workout.exer_type_2 === 'T' && workout.additional_options.msec === 1;

  const isCircuitRepsorSets = workout.exer_type_2 === 'S' || workout.exer_type_2 === 'R';

  const isCircuitReps = workout.exer_type_2 === 'R';

  const isCircuitSets = workout.exer_type_2 === 'S';

  const isCompletion = workout.exer_type_2 === 'C';

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      initialValues={
        isCompletion ? (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                complete: workout.result.completed,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              complete: '0',
            });
          })()
        ) : (
          {
            work_id: workout.work_id,
            user_id: athleteId,
            result:
              workout.result ? (
                workout.result.data.map((set, i) => (
                  {
                    set_id: i + 1,
                    value:
                      set.value,
                  }
                ))
              ) : (
                Array(workout.sets).fill().map((set, i) => ({
                  set_id: i + 1,
                }))
              ),
          }
        )
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        // alert(JSON.stringify(values, null, 2))
        actions.setSubmitting(false);
      }}
      render={props => (
        <Form>
          <Conditional if={isCompletion}>
            <CompletionEntry
              handleInfoClick={handleInfoClick}
              handleSubmit={props.handleSubmit}
              info={workout.info}
              setFieldValue={props.setFieldValue}
              subHeader={workout.sub_header}
              values={props.values}
            />
          </Conditional>
          <FieldArray
            name='result'
            render={() => (
              <Fragment>
                <FieldArray
                  name='value'
                  render={() => (
                    <Fragment>
                      <Conditional if={isCircuitTime}>
                        <Fragment>
                          <FieldsStatic
                            title='Round'
                            entriesViewHeight={entriesViewHeight}
                            name='sets'
                            sets={workout.sets}
                            setList={workout.set_list}
                            exerType={workout.exer_type}
                            grouping={workout.grouping}
                            supersetWorkout={supersetWorkout}
                            isWrvSupersetsEnabled={wrvSupersetsEnabled}
                          />
                          <WorkoutFields
                            handleChange={props.handleChange}
                            handleSubmit={props.handleSubmit}
                            id='1'
                            labels={false}
                            name='value'
                            sets={workout.sets}
                            setList={workout.set_list}
                            title='Mins'
                          />
                          <WorkoutFields
                            handleChange={props.handleChange}
                            handleSubmit={props.handleSubmit}
                            id='2'
                            name='value'
                            sets={workout.sets}
                            setList={workout.set_list}
                            title='Secs'
                          />
                          <WorkoutFields
                            handleChange={props.handleChange}
                            handleSubmit={props.handleSubmit}
                            id='3'
                            name='value'
                            sets={workout.sets}
                            setList={workout.set_list}
                            title='MSecs'
                          />
                        </Fragment>
                      </Conditional>
                      <Conditional if={isCircuitRepsorSets}>
                        <FieldsStatic
                          title='Round'
                          entriesViewHeight={entriesViewHeight}
                          name='sets'
                          sets={workout.sets}
                          setList={workout.set_list}
                          exerType={workout.exer_type}
                          grouping={workout.grouping}
                          supersetWorkout={supersetWorkout}
                          isWrvSupersetsEnabled={wrvSupersetsEnabled}
                        />
                        <WorkoutFields
                          handleChange={props.handleChange}
                          handleSubmit={props.handleSubmit}
                          id='0'
                          labels={false}
                          name='value'
                          handleBlur={props.handleSubmit}
                          sets={workout.sets}
                          setList={workout.set_list}
                          title={`
                            ${isCircuitReps ? 'Reps' : ''}
                            ${isCircuitSets ? 'Sets' : ''}
                          `}
                        />
                      </Conditional>
                    </Fragment>
                  )}
                />
                {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
              </Fragment>
            )}
          />
        </Form>
      )}
    />
  );
};

CircuitEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  supersetWorkout: PropTypes.instanceOf(Object).isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  wrvSupersetsEnabled: PropTypes.bool.isRequired,
};

export default CircuitEntries;
