// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Select from 'react-select';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import NoHoverButton from './NoHoverButton';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CalendarIconCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

const NavigationWrapperFolder = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;
  height: 130px;

  .calendar-icon-text {
    min-width: 200px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
    margin-top: 10px;
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const ModalCreateForm = ({
  onRequestClose,
  currentUser,
  handleCreateCalendar,
  handleCreateChildCalendar,
  activeCalendars,
}) => {
  const createCalendarError = useSelector((state) => state.calendars.data.createCalendarError);
  const createChildCalendarError = useSelector(
    (state) => state.calendars.data.createChildCalendarError,
  );
  const isCalendarSubmitting = useSelector(
    (state) => state.calendars.ui.isCalendarSubmitting,
  );
  const unparsedCalendars = useSelector((state) => state.calendars.data.unparsedCalendars);
  // a handler for documents being submitted and created.  different processes depending on doc type
  const handleSubmit = (
    values,
  ) => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    const newValues = values;
    if (!newValues.workoutVisibility) {
      newValues.workoutVisibility = 0;
    }
    if (!values.parentId) {
      handleCreateCalendar({ accountCode, body: newValues });
    } else {
      const { parentId } = values;
      const parentCalendarIndex = unparsedCalendars.findIndex(
        (unparsedCalendar) => unparsedCalendar.id === parentId,
      );
      handleCreateChildCalendar({
        accountCode, body: newValues, parentId, parentCalendarIndex,
      });
    }
  };

  const parentCalendars = () => activeCalendars.filter(
    (calendar) => !calendar.parentId && calendar.id !== null,
  );

  const folderSchema = Yup.object().shape({
    name: Yup.string()
      .required('Calendar Name is required'),
  });

  const workoutVisibilityOptions = [
    {
      name: 'All',
      id: 0,
    },
    {
      name: 'One Week',
      id: 1,
    },
    {
      name: 'Current Day',
      id: 2,
    },
  ];

  return (
    <Formik
      initialValues={{
        name: '',
      }}
      validationSchema={folderSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleSubmit(values);
          setSubmitting(false);
        });
      }}
      render={({
        values,
        setFieldValue,
        submitForm,
        errors,
      }) => (
        <ModalWrapper>
          <AnotherWrapper>
            <NavigationWrapperFolder>
              <Text
                className='back-arrow-div'
                onClick={() => {
                  onRequestClose();
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='remove'
                />
              </Text>
            </NavigationWrapperFolder>
            <OuterFolderFormWrapper>
              <FolderTextWrapper>
                <CalendarIconCircle>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={30}
                    icon='calendar'
                  />
                </CalendarIconCircle>
                <Text className='calendar-icon-text'>Add Calendar</Text>
                {createCalendarError ? <TitleError className='error-text'>{createCalendarError}</TitleError> : null}
                {createChildCalendarError ? <TitleError className='error-text'>{createChildCalendarError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <InnerFolderFormWrapper>
                <Form className='formik-form'>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Calendar Name</strong></Text>
                      <FieldError className='error-text'>{errors.name}</FieldError>
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='name-field'
                      name='name'
                      placeholder='Calendar Name'
                    />
                  </FormGroup>
                  <FormGroup>
                    <Text className='formgroup-text'>
                      <strong>Parent Calendar</strong>
                    </Text>
                    <Select
                      defaultValue={values.parentId}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={parentCalendars()}
                      onChange={(options) => {
                        if (options) {
                          setFieldValue('parentId', options.id);
                        } else {
                          setFieldValue('parentId', 0);
                        }
                      }}
                      isOptionSelected={(group) => values.group === group}
                      isClearable
                      isSearchable
                    />
                  </FormGroup>
                  <FormGroup>
                    <Text className='formgroup-text'>
                      <strong>Workout Display</strong>
                    </Text>
                    <Select
                      defaultValue={workoutVisibilityOptions[0]}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={workoutVisibilityOptions}
                      onChange={(options) => {
                        setFieldValue('workoutVisibility', options.id);
                      }}
                      isOptionSelected={(group) => values.group === group}
                    />
                  </FormGroup>
                </Form>
              </InnerFolderFormWrapper>
            </OuterFolderFormWrapper>
          </AnotherWrapper>
          <NoHoverButton
            bottom
            fullWidth
            cta={!isCalendarSubmitting ? 'Create Calendar' : 'Creating...'}
            customColor='#10cd8c'
            className='modal-button'
            large
            noBorder
            primary
            square
            onClick={() => {
              submitForm();
            }}
            disabled={isCalendarSubmitting}
            noHover
          />
        </ModalWrapper>
      )}
    />
  );
};

ModalCreateForm.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  onRequestClose: PropTypes.func.isRequired,
  handleCreateCalendar: PropTypes.func.isRequired,
  handleCreateChildCalendar: PropTypes.func.isRequired,
  activeCalendars: PropTypes.instanceOf(Array).isRequired,
};

export default ModalCreateForm;
