import React from 'react';

class CustomInputText extends React.PureComponent {
  handleBlur = e => {
      const {
          field: { name, onBlur },
      } = this.props;

      console.log('BLURRED')
      onBlur(e);
      

      // The latest touched and errors aren't available until after the
      // formik's onBlur handler has been called. Setting a timeout allows us
      // to access the proper touched and errors after onBlur event has
      // occurred.
      setTimeout(() => {
          const {
              form: { touched, errors, handleSubmit },
          } = this.props;
          handleSubmit();
          const isError = touched[name] && errors[name];
          const isSuccess = touched[name] && !errors[name];
          if (isError) {
              console.log('validation error');
          }

          if (isSuccess) {
              console.log('validation success');
          }
      }, 0);
  };

  render() {
      const { field, ...otherProps } = this.props;

      return (
          <input
              className="floating-field"
              type="text"
              {...field}
              {...otherProps}
              onBlur={this.handleBlur}
          />
      );
  }
}

export default CustomInputText;
