/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { updateNotificationSettings } from '../../../../login/ducks/loginActions';

const NotificationSettings = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isNotificationSettingsLoading = useSelector((state) => state.auth.ui.isNotificationSettingsLoading);
  const isNotificationTypesLoading = useSelector((state) => state.auth.ui.isNotificationTypesLoading);
  const notificationTypeGroupsObject = useSelector((state) => state.auth.data.notificationTypeGroups);
  const globalNotificationSettings = useSelector((state) => state.auth.data.notificationSettings);

  const [notificationSettings, setNotificationSettings] = useState(globalNotificationSettings);

  const debouncedSetNotificationSettings = useDebouncedCallback(
    (newSettings) => {
      dispatch(updateNotificationSettings(newSettings));
    },
    1,
  );

  const handleSwitches = (field, v) => {
    setNotificationSettings((prev) => ({
      ...prev,
      [field]: v,
    }));
    debouncedSetNotificationSettings({
      ...notificationSettings,
      [field]: v,
    });
  };

  const notificationTypeGroupArrays = notificationTypeGroupsObject && Object.entries(notificationTypeGroupsObject);

  const dynamicCheckedStatus = (notificationShortName) => {
    const arrayWithShortNameAndTrueValue = Object.entries(globalNotificationSettings).filter((subArray) => (subArray[0] === notificationShortName ? subArray[1] : false));
    if (arrayWithShortNameAndTrueValue.length) {
      return Boolean(true);
    }
    return Boolean(false);
  };

  const handleIconNameing = (groupName) => {
    let iconName;
    switch (groupName) {
      case 'Feed':
        iconName = 'feed';
        break;
      case 'Journal':
        iconName = 'journal';
        break;
      case 'Workout':
        iconName = 'lift';
        break;
      case 'Payments':
        iconName = 'credit-card';
        break;
      case 'Wearables':
        iconName = 'watch-sync';
        break;
      default:
        iconName = 'sub-arrow';
    }
    return iconName;
  };

  const NotificationsSettingsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;

        .react-toggle .react-toggle-track {
            background-color: #E9E9EA;
        }
        .react-toggle--checked .react-toggle-track {
            background-color: ${theme.colors.green};
        }
        .react-toggle-thumb {
            border: none;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #E9E9EA;
        }
        
        .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: ${theme.colors.green};
        }
`;

  const LoadingShimmerContainer = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 800px;
    border-radius: 24px;
  `;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    cursor: pointer;
`;

  const HeaderContainer = styled('div')`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
    display: flex;
    flexDirection: row;
    justify-content: space-between;
    align-items: center;
`;

  const IconAndGroupNameRow = styled('div')`
   display: flex;
   flex-direction: row;
   align-items: center;
   margin-bottom: 20px;
`;
  return (
    <>
      {isNotificationSettingsLoading || isNotificationTypesLoading || !notificationTypeGroupsObject ? (
        <Shimmer>
          <LoadingShimmerContainer />
        </Shimmer>
      ) : (
        <NotificationsSettingsWrapper>
          <HeaderContainer>
            <div>
              <Text fontSize={containerWidth && containerWidth < 400 ? 24 : 28} fontWeight='700'>
                Notification Settings
              </Text>
              <Text fontSize={18} fontWeight='400'>Manage different notifications on our notification hub.</Text>
            </div>
          </HeaderContainer>
          <>
            {notificationTypeGroupArrays && notificationTypeGroupArrays.map((notificationTypeGroupArray) => (
              <div key={notificationTypeGroupArray[0]}>
                <IconAndGroupNameRow>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={18}
                    icon={handleIconNameing(notificationTypeGroupArray[0])}
                  />
                  <Text fontSize={18} fontWeight='700' ml={10}>
                    {' '}
                    {notificationTypeGroupArray[0]}
                  </Text>
                </IconAndGroupNameRow>
                {notificationTypeGroupArray[1]?.map((group) => (
                  <Row key={group.notificationKey}>
                    <Text fontSize={18} fontWeight='400'>
                      {group?.notificationName}
                    </Text>
                    <Toggle
                      checked={(dynamicCheckedStatus(group?.notificationShortName))}
                      icons={false}
                      onChange={() => {
                        handleSwitches(group?.notificationShortName, !dynamicCheckedStatus(group?.notificationShortName));
                      }}
                    />
                  </Row>

                ))}
              </div>

            ))}
          </>
        </NotificationsSettingsWrapper>
      )}
    </>
  );
};

NotificationSettings.propTypes = {
  containerWidth: PropTypes.number,
};

NotificationSettings.defaultProps = {
  containerWidth: '',
};

export default NotificationSettings;
