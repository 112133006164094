// imports action types and dispatches action to the back end for processessing and db

import {
  FETCH_EVALUATIONS_START,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_EVALUATIONS_REJECTED,
  SET_EVAL_OVERLAY,
  SET_INITIAL_STATE,
  SET_NEW_EVAL_MODAL1,
  SET_SELECT_EXISTING_EVAL_MODAL1,
  FETCH_EVALUATION_START,
  FETCH_EVALUATION_FULFILLED,
  FETCH_EVALUATION_REJECTED,
  SET_AUTO_SAVE_INFO_MODAL,
  SET_MANAGE_EVALUATIONS_SCREEN_ON,
  DELETE_EVALUATION_START,
  DELETE_EVALUATION_FULFILLED,
  DELETE_EVALUATION_REJECTED,
  CREATE_EVALUATION_START,
  CREATE_EVALUATION_FULFILLED,
  CREATE_EVALUATION_REJECTED,
  SET_EDIT_EVAL_MODAL1,
  EDIT_EVALUATION_START,
  EDIT_EVALUATION_FULFILLED,
  EDIT_EVALUATION_REJECTED,
  CREATE_CELL_VALUE_START,
  CREATE_CELL_VALUE_FULFILLED,
  CREATE_CELL_VALUE_REJECTED,
  SET_SELECTED_EVAL_DATE,
  SET_EVAL_GROUP_OPTION,
  SET_EVAL_GROUP_TYPE_OPTION,
  FETCH_TRACKED_ITEM_UNITS_START,
  FETCH_TRACKED_ITEM_UNITS_FULFILLED,
  FETCH_TRACKED_ITEM_UNITS_REJECTED,
  FETCH_TRACKED_EXERCISES_START,
  FETCH_TRACKED_EXERCISES_FULFILLED,
  FETCH_TRACKED_EXERCISES_REJECTED,
  SET_MANAGE_EVALUATIONS_SCREEN_OFF,
  RESET_EVALUATION,
  PRIME_DELETE_EVAL_ID,
  FETCH_EVAL_FROM_EDIT_START,
  FETCH_EVAL_FROM_EDIT_FULFILLED,
  FETCH_EVAL_FROM_EDIT_REJECTED,
  SET_EDITED_ITEMS,
  SET_EDIT_SLIDE_INDEX,
  SET_GROUP_ACCESS_EVAL_MODAL,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';
import { FETCH_CALENDARS_FULFILLED, FETCH_CALENDARS_REJECTED, FETCH_CALENDARS_START } from '../../calendars/ducks/actionTypes';
import { FETCH_GROUPS_FULFILLED, FETCH_GROUPS_REJECTED, FETCH_GROUPS_START } from '../../leaderboard/ducks/actionTypes';
import { showErrorToast } from '../../../shared/utils/toastManager';
import getAllUrlParams from '../../../shared/utils/getAllUrlParams';

authProvider();

export const fetchEvaluations = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_EVALUATIONS_START });
    axiosAuthed.get(`/accounts/${accountCode}/evaluations`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_EVALUATIONS_FULFILLED,
            payload: response.data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EVALUATIONS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchCalendars = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CALENDARS_START });
    axiosAuthed.get(`/accounts/${accountCode}/calendars`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_CALENDARS_FULFILLED,
            payload: response.data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups`)
      .then((response) => {
        dispatch({
          type: FETCH_GROUPS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_GROUPS_REJECTED,
          payload: err,
        });
      });
  }
);

export const deleteEvaluation = (accountCode, evalId, index) => (
  (dispatch) => {
    dispatch({ type: DELETE_EVALUATION_START });
    axiosAuthed.patch(`/accounts/${accountCode}/evaluations/delete`, {
      evaluationIds: [
        evalId,
      ],
    })
      .then(() => {
        dispatch({
          type: DELETE_EVALUATION_FULFILLED,
          deleteIndex: index,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_EVALUATION_REJECTED,
          payload: err,
        });
      });
  }
);

export const setIsGroupAccessEvalModalShowing = (bool) => (
  (dispatch, getState) => {
    dispatch({
      payload: bool,
      type: SET_GROUP_ACCESS_EVAL_MODAL,
    });

    // If user is closing the modal, redirect them to the dashboard
    if (!bool) {
      const url = getAllUrlParams(window.location.href);
      const { currentUser } = getState().auth.data;
      let domain;
      if (process.env.PHP_APP_URL && process.env.WEBAPP_URL) {
        domain = (url.version === '2' || currentUser.admin === 0 ? process.env.WEBAPP_URL : process.env.PHP_APP_URL);
      } else {
        const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
        domain = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' || currentUser.admin === 0 ? 'app-v3' : 'app'}.teambuildr.com`;
      }
      window.location = `${domain}/dashboard`;
    }
  }
);

export const fetchEvaluation = ({
  accountCode,
  evaluationId,
  selectedDate,
  filterType,
  filterId = undefined,
}) => (
  (dispatch) => {
    dispatch({ type: FETCH_EVALUATION_START });
    let ogUri = `/accounts/${accountCode}/evaluations/${evaluationId}?date=${selectedDate}&filterType=${filterType}`;
    if (filterId) {
      ogUri += `&filterIds=${filterId}`;
    }
    axiosAuthed.get(ogUri)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_EVALUATION_FULFILLED,
            payload: response.data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EVALUATION_REJECTED,
          payload: err,
        });
        if (err?.response?.headers?.message === 'You do not have access to any athletes') {
          dispatch(setIsGroupAccessEvalModalShowing(true));
        }
      });
  }
);

export const fetchEvalFromEdit = ({
  accountCode,
  evaluationId,
  selectedDate,
  filterType,
  filterId = undefined,
}) => (
  (dispatch) => {
    dispatch({ type: FETCH_EVAL_FROM_EDIT_START });
    let ogUri = `/accounts/${accountCode}/evaluations/${evaluationId}?date=${selectedDate}&filterType=${filterType}`;
    if (filterId) {
      ogUri += `&filterIds=${filterId}`;
    }
    axiosAuthed.get(ogUri)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_EVAL_FROM_EDIT_FULFILLED,
            payload: response.data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EVAL_FROM_EDIT_REJECTED,
          payload: err,
        });
      });
  }
);

export const createEvaluation = (accountCode, newEvaluation) => (
  (dispatch) => {
    dispatch({ type: CREATE_EVALUATION_START });
    return axiosAuthed.post(`/accounts/${accountCode}/evaluations`, newEvaluation)
      .then((response) => {
        dispatch(fetchEvaluations(accountCode));
        dispatch({
          type: CREATE_EVALUATION_FULFILLED,
          payload: response.data,
        });
        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: CREATE_EVALUATION_REJECTED,
          payload: err,
        });
        throw err;
      });
  }
);

const filterTypeResolver = (evalGroupTypeOption) => {
  if (evalGroupTypeOption === 'Select By Athlete Group') {
    return 2;
  } if (evalGroupTypeOption === 'Select By Calendars') {
    return 3;
  }
  return 1;
};

export const editEvaluation = (accountCode, submittedEvaluation) => (
  (dispatch, getState) => {
    dispatch({ type: EDIT_EVALUATION_START });
    const {
      evaluation,
      evaluations,
      evalGroupTypeOption,
      evalGroupOption,
      selectedEvalDate,
    } = getState().evaluations.data;

    const idx = evaluations.findIndex((item) => item.id === submittedEvaluation.id);

    axiosAuthed.put(`/accounts/${accountCode}/evaluations`, submittedEvaluation)
      .then((response) => {
        dispatch(fetchEvaluations(accountCode));
        if (evaluation.id === submittedEvaluation.id) {
          dispatch(fetchEvaluation({
            accountCode,
            evaluationId: submittedEvaluation.id,
            selectedDate: selectedEvalDate.format('YYYY-MM-DD'),
            filterType: filterTypeResolver(evalGroupTypeOption),
            filterId: evalGroupOption.id,
          }));
        }
        dispatch({
          type: EDIT_EVALUATION_FULFILLED,
          payload: {
            eval: response.data,
            idx,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: EDIT_EVALUATION_REJECTED,
          payload: err,
        });
      });
  }
);

export const createCellValue = (accountCode, values, evalId, athleteIdx, inputIdx, dataIdx) => (
  (dispatch, getState) => {
    const {
      selectedEvalDate,
    } = getState().evaluations.data;
    const formattedDate = selectedEvalDate.format('YYYY-MM-DD');
    dispatch({ type: CREATE_CELL_VALUE_START });
    return axiosAuthed.post(`/accounts/${accountCode}/evaluations/${evalId}/data?date=${formattedDate}`, { data: values })
      .then((response) => {
        dispatch({
          type: CREATE_CELL_VALUE_FULFILLED,
          payload: {
            data: response.data,
            athleteIdx,
            inputIdx,
            dataIdx,
            resultNumber: values.resultNumber,
          },
        });
        dispatch({
          type: SET_EDITED_ITEMS,
          payload: response.data.itemId,
        });
        return response;
      })
      .catch((err) => {
        const message = err.response?.headers.message;
        const errorMessage = message || err.message;
        if (values.value !== '' && values.value !== null && values.value !== undefined) {
          showErrorToast(message);
        }
        dispatch({
          type: CREATE_CELL_VALUE_REJECTED,
          payload: errorMessage,
        });
        return err;
      });
  }
);

export const setEvalOverlay = (bool) => (
  {
    type: SET_EVAL_OVERLAY,
    payload: bool,
  }
);

export const setInitialState = (bool) => (
  {
    type: SET_INITIAL_STATE,
    payload: bool,
  }
);

export const setNewEvalModal1 = (bool) => (
  {
    type: SET_NEW_EVAL_MODAL1,
    payload: bool,
  }
);

export const setEditEvalModal1 = (object) => (
  {
    type: SET_EDIT_EVAL_MODAL1,
    payload: object,
  }
);

export const setSelectExistingEvalModal1 = (bool) => (
  {
    type: SET_SELECT_EXISTING_EVAL_MODAL1,
    payload: bool,
  }
);

export const setAutoSaveInfoModal = (bool) => (
  {
    type: SET_AUTO_SAVE_INFO_MODAL,
    payload: bool,
  }
);

export const setManageEvaluationsScreenOn = () => (
  {
    type: SET_MANAGE_EVALUATIONS_SCREEN_ON,
  }
);

export const setManageEvaluationsScreenOff = () => (
  {
    type: SET_MANAGE_EVALUATIONS_SCREEN_OFF,
  }
);

export const setSelectedEvalDate = (date) => (
  {
    type: SET_SELECTED_EVAL_DATE,
    payload: date,
  }
);

export const setEvalGroupTypeOptionRedux = (object) => (
  {
    type: SET_EVAL_GROUP_TYPE_OPTION,
    payload: object,
  }
);

export const setEvalGroupOptionRedux = (object) => (
  {
    type: SET_EVAL_GROUP_OPTION,
    payload: object,
  }
);

export const resetEvaluation = () => (
  {
    type: RESET_EVALUATION,
  }
);

export const primeDeleteEvalId = (object) => (
  {
    type: PRIME_DELETE_EVAL_ID,
    payload: object,
  }
);

export const setEditedItems = (object) => (
  {
    type: SET_EDITED_ITEMS,
    payload: object,
  }
);

export const setEditSlideIndex = (object) => (
  {
    type: SET_EDIT_SLIDE_INDEX,
    payload: object,
  }
);

export const fetchTrackedItemUnits = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_TRACKED_ITEM_UNITS_START });
    axiosAuthed.get(`/accounts/${accountCode}/evaluations/units`)
      .then((response) => {
        dispatch({
          type: FETCH_TRACKED_ITEM_UNITS_FULFILLED,
          payload: {
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_TRACKED_ITEM_UNITS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchTrackedExercises = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_TRACKED_EXERCISES_START });
    axiosAuthed.get(`/accounts/${accountCode}/exercises?tracked=true&includePrFormat=true`)
      .then((response) => {
        dispatch({
          type: FETCH_TRACKED_EXERCISES_FULFILLED,
          payload: {
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_TRACKED_EXERCISES_REJECTED,
          payload: err,
        });
      });
  }
);
