import {
  ADD_ON_SPORTS_SCIENCE_PACKAGE_START,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED,
  FETCH_SUBSCRIPTION_PACKAGE_FULFILLED,
  FETCH_SUBSCRIPTION_PACKAGE_START,
  FETCH_SUBSCRIPTION_PACKAGE_REJECTED,
  FETCH_SUBSCRIPTION_STATUS_FULFILLED,
  FETCH_SUBSCRIPTION_STATUS_START,
  FETCH_SUBSCRIPTION_STATUS_REJECTED,
  SET_MODAL_OPEN,
  SET_ERROR_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  isUpgrading: false,
  isPackageLoading: false,
  isStatusLoading: true,
  activeModal: '',
  isModalOpen: false,
  activeErrorModal: '',
  isErrorModalOpen: false,
  errorModalMessage: null,
  errorModalDataObject: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ON_SPORTS_SCIENCE_PACKAGE_START: {
      return {
        ...state,
        isUpgrading: true,
      };
    }
    case ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED: {
      return {
        ...state,
        isUpgrading: false,
      };
    }
    case ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED: {
      return {
        ...state,
        isUpgrading: false,
      };
    }
    case FETCH_SUBSCRIPTION_PACKAGE_START: {
      return {
        ...state,
      };
    }
    case FETCH_SUBSCRIPTION_PACKAGE_FULFILLED: {
      return {
        ...state,
        isPackageLoading: false,
      };
    }
    case FETCH_SUBSCRIPTION_PACKAGE_REJECTED: {
      return {
        ...state,
        isPackageLoading: false,
      };
    }
    case FETCH_SUBSCRIPTION_STATUS_START: {
      return {
        ...state,
        isStatusLoading: true,
      };
    }
    case FETCH_SUBSCRIPTION_STATUS_FULFILLED: {
      return {
        ...state,
        isStatusLoading: false,
      };
    }
    case FETCH_SUBSCRIPTION_STATUS_REJECTED: {
      return {
        ...state,
        isStatusLoading: false,
      };
    }
    case SET_MODAL_OPEN: {
      return {
        ...state,
        activeModal: action.payload.activeModal,
        isModalOpen: action.payload.isModalOpen,
      };
    }
    case SET_ERROR_MODAL_OPEN: {
      return {
        ...state,
        activeErrorModal: action.payload.activeModal,
        isErrorModalOpen: action.payload.isModalOpen,
        errorModalMessage: action.payload.errorMessage,
        errorModalDataObject: action.payload.dataObject,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
