/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */

import React, {
  useState, useMemo, useEffect, useCallback,
} from 'react';
import styled from '@emotion/styled';
import { useTable, useSortBy } from 'react-table';
import { useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Select, { components } from 'react-select';
import Shimmer from 'react-shimmer-effect';
import { useTheme } from 'emotion-theming';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const SelectedIntregrationContainer = styled.div`
  font-family: 'Nunito Sans';
  `;

const LoadingContainer = styled.div`
  padding: 20px;
`;
const ShimmerBox = styled.div`
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const TitleShimmer = styled(ShimmerBox)`
  height: 28px;
  margin-bottom: 5px;
  margin-top:100px;
`;

const SubtitleShimmer = styled(ShimmerBox)`
  height: 20px
`;

const DropdownLabelShimmer = styled(ShimmerBox)`
  height: 16px;
`;

const DropdownShimmer = styled(ShimmerBox)`
  height: 40px; 
`;

const MetricBoxShimmerContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const MetricBoxShimmer = styled(ShimmerBox)`
  height: 80px;
  flex: 1 0 0;
`;

const TableShimmer = styled(ShimmerBox)`
  height: 300px;
  margin-botom:20px;
`;

const IntegrationImageWrapperShimmer = styled(ShimmerBox)`
  height: 200px;
`;

const IntegrationImageWrapper = styled.div`
  width: 100%; 
  height: 200px;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const IntegrationImage = styled.img`
  width: 100%;
  height: 100%;
  display: block; 
  position: absolute;
  z-index: -1;
`;

const Overlay = styled.div`
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75); 
  z-index: -1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const IntegrationResultsContainer = styled.div`
    padding: 0px 30px 20px 30px;
    @media (max-width: 768px) {
      overflow-x: scroll;
    }
  `;

const IntegrationTitleContainer = styled.div`
    margin-top:115px;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

    @media (max-width: 768px) {
      margin-top: 90px;
    }
  `;

const IntegrationName = styled.h2`
color: #FFF;
font-size: 28px;
font-weight: 700;
line-height: 130%;
letter-spacing: -0.34px;
margin:0;
  `;

const IntegrationDateContainer = styled.div`
    display: flex;
    align-items: center;
  `;

const IntegrationDate = styled.div`
    margin-left: 5px;
    color: #FFF;

  `;

const PerformanceMetrics = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 24px 30px;
    gap:8px;
  `;

const StyledTable = styled.table`
width: 100%;
border-collapse: collapse;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SortingIcon = styled.span`
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
`;

const StyledTh = styled.th`
padding: 16px; 
text-align: left;
position: relative;
color : ${(props) => props.theme.colors.text};

@media (max-width: 768px) {
  font-size: 12px;
}
`;

const StyledThead = styled.thead`
box-shadow: 0px -2px 0px 0px #EEE inset;
color:'black';
`;

const StyledTr = styled.tr`
  ${'' /* background-color: ${(props) => props.theme.colors.background[0]}; */}
  background-color: #FCFCFC;
  `;

const StyledTbody = styled.tbody`

`;

const StyledTd = styled.td`
  padding: 16px;
  border-bottom: 1px solid #eee;

  @media (max-width: 768px) {
    font-size: 14px;
  }
  `;

const TableRows = styled.tr`
  background-color: ${(props) => (props.isBestMetric ? '#DCE3EB' : 'none')};
  color: props.theme.colors.text;
`;

const MetricBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  justify-content: center;
  gap: 4px;
  box-shadow: 0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 2px 4px 0px rgba(48, 49, 51, 0.10);
  border-radius: 8px;
  flex: 1 0 0;
  background-color: ${(props) => props.theme.colors.background[1]};
  `;

const MetricValueWithUnit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const MetricBoxTitle = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightText[1]};
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

const MetrixBoxValue = styled.div`
  font-size: 24px;
  color: ${(props) => props.theme.colors.text};

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const MetrixBoxUnit = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.text};
`;

const FilterLabel = styled.label`
  display: block;
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightText[0]};
  margin-bottom: 8px;
  font-weight: 700; 
`;

const DropdownFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  padding-top: 16px;
`;

const DropdownIndicator = (props) => {
  const theme = useTheme();

  return (
    <components.DropdownIndicator {...props}>
      <IcomoonReact
        iconSet={iconSet}
        size={24}
        icon='down-arrow'
        color={theme.colors.lightText[0]}
      />
    </components.DropdownIndicator>
  );
};

const ImportedDataSelection = ({ isWeightRoomView = false, namespace = null }) => {
  const theme = useTheme();

  const selectedIntegrationData = isWeightRoomView ? useSelector((state) => state.weightRoomView[namespace]?.selectedIntegrationData) : useSelector((state) => state.workouts.data.selectedIntegrationData);

  const selectedIntegration = useSelector(
    (state) => state.workouts.data.selectedIntegration,
  );

  const [selectedHeader, setSelectedHeader] = useState('View All');
  const [metricData, setMetricData] = useState([]);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '4px',
      boxShadow: '0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 4px 8px 0px rgba(48, 49, 51, 0.10)',
      border: 'none',
      backgroundColor: theme.colors.background[1],
      color: 'black',
      '&:hover': {
        borderColor: 'none',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: theme.colors.background[1],
      marginTop: '0px',
      borderTop: 'none',
      color: theme.colors.text,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#DCE3EB' : provided.backgroundColor,
      color: state.isSelected ? 'black' : provided.color,
    }),
  };

  // Prepare the columns for the table based on the selected header
  const columns = useMemo(() => {
    if (selectedIntegrationData) {
      const allColumns = selectedIntegrationData.results.Attempt.data.headers.map((header) => ({
        Header: header.headerName,
        accessor: header.headerName,
      }));

      // Filter columns based on the selected header
      if (selectedHeader !== 'View All') {
        return allColumns.filter((column) => column.accessor === 'Attempt' || column.accessor === selectedHeader);
      }

      // Return all columns if "View All" is selected
      return allColumns;
    }
    return [];
  }, [selectedIntegrationData, selectedHeader]);

  // Prepare the data for the table
  const data = useMemo(() => {
    if (selectedIntegrationData) {
      return selectedIntegrationData.results.Attempt.data.rows.map((row) => {
        const rowObj = {};
        row.forEach((cell) => {
          rowObj[cell.columnName] = cell.value;
        });
        return rowObj;
      });
    }
    return [];
  }, [selectedIntegrationData]);

  const isBestMetric = (row) => {
    if (selectedIntegrationData && selectedHeader !== 'View All') {
      let { bestMetric } = selectedIntegrationData.results.Attempt.data;
      if (Array.isArray(bestMetric[0])) {
        // if so get the first value
        [bestMetric] = bestMetric;
      }
      const bestMetricValue = bestMetric
        .find((metric) => metric?.columnName === selectedHeader)?.value;

      // Find the row with the highest 'Attempt' value that matches the best metric value
      const latestBestMetricRow = data.reduce((latestRow, currentRow) => {
        if (currentRow[selectedHeader] === bestMetricValue) {
          return latestRow.attempt > currentRow.attempt ? latestRow : currentRow;
        }
        return latestRow;
      }, { attempt: -1 });

      return bestMetricValue === row[selectedHeader] && row.Attempt === latestBestMetricRow.Attempt;
    }
    return false;
  };

  const extractUnit = (headerName) => {
    const match = headerName.match(/\(([^)]+)\)$/); // Matches (Unit)
    return match ? match[1] : '';
  };

  useEffect(() => {
    if (selectedIntegrationData) {
      let newMetricData = [];

      if (selectedHeader === 'View All') {
        let bestMetrics = selectedIntegrationData.results.Attempt.data.bestMetric;

        // Check if bestMetrics is an array of arrays
        if (Array.isArray(bestMetrics[0])) {
          // if so get the first value
          [bestMetrics] = bestMetrics;
        }

        newMetricData = bestMetrics.map((metric) => {
          if (metric.columnName !== 'Attempt') {
            return {
              headerName: metric.columnName,
              best: metric.value,
            };
          }
          return null;
        }).filter((metric) => metric !== null);
      } else {
        const metricStats = selectedIntegrationData.results.Metric.data.statistics[selectedHeader];
        if (metricStats) {
          newMetricData = [{ headerName: selectedHeader, ...metricStats }];
        }
      }

      setMetricData(newMetricData);
    }
  }, [selectedHeader, selectedIntegrationData]);

  const handleSelectChange = useCallback((selectedOption) => {
    setSelectedHeader(selectedOption.value);
  }, []);

  const MetricBoxContent = React.memo(({ title, value, unit }) => {
    let displayValue = Number(value)?.toFixed(2);
    let valueStyle = {};

    if (title === 'Deviation' && value > 0) {
      displayValue = `+${displayValue}`;
      valueStyle = { color: theme.colors.green };
    }

    return (
      <>
        <MetricBoxTitle>{title}</MetricBoxTitle>
        <MetricValueWithUnit>
          <MetrixBoxValue style={valueStyle}>{displayValue}</MetrixBoxValue>
          {unit && <MetrixBoxUnit>{unit}</MetrixBoxUnit>}
        </MetricValueWithUnit>
      </>
    );
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  }, useSortBy);

  const dropdownOptions = useMemo(() => {
    if (!selectedIntegrationData) return [];
    return selectedIntegrationData.metrics.map((metric) => ({
      value: metric,
      label: metric,
    }));
  }, [selectedIntegrationData, selectedHeader]);

  return selectedIntegrationData ? (
    <SelectedIntregrationContainer>
      <IntegrationImageWrapper>
        <IntegrationImage src={selectedIntegration.logo} />
        <Overlay />
        <IntegrationTitleContainer>
          <IntegrationName>{selectedIntegration.testName}</IntegrationName>
          <IntegrationDateContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='restart'
              color='#9E9E9E'
            />
            <IntegrationDate>
              {selectedIntegration.latestTimestampOfTestForTheDay}
            </IntegrationDate>
          </IntegrationDateContainer>
        </IntegrationTitleContainer>
      </IntegrationImageWrapper>
      <DropdownFilterContainer>
        <FilterLabel>Filter By:</FilterLabel>
        <Select
          styles={customSelectStyles}
          options={dropdownOptions}
          value={dropdownOptions.find((option) => option.value === selectedHeader)}
          onChange={handleSelectChange}
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        />
      </DropdownFilterContainer>
      <PerformanceMetrics>
        {selectedHeader === 'View All' ? (
          metricData.map((metric) => {
            const unit = extractUnit(metric.headerName);
            return (
              <MetricBox key={metric.headerName}>
                <MetricBoxContent title={metric.headerName} value={metric.best} unit={unit} />
              </MetricBox>
            );
          })
        ) : (
          <>
            <MetricBox>
              <MetricBoxContent title='Best' value={metricData[0].best} unit={extractUnit(selectedHeader)} />
            </MetricBox>
            <MetricBox>
              <MetricBoxContent title='Average' value={metricData[0].average} unit={extractUnit(selectedHeader)} />
            </MetricBox>
            <MetricBox>
              <MetricBoxContent title='Deviation' value={metricData[0].deviation} />
            </MetricBox>
          </>
        )}
      </PerformanceMetrics>
      <IntegrationResultsContainer>

        <StyledTable {...getTableProps()}>
          <StyledThead>
            {headerGroups.map((headerGroup) => (
              <StyledTr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const sortingIcon = column.isSorted
                    ? column.isSortedDesc
                      ? '↓'
                      : '↑'
                    : '';
                  return (
                    <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                      <SortingIcon>
                        {sortingIcon}
                      </SortingIcon>
                      {column.render('Header')}
                    </StyledTh>
                  );
                })}
              </StyledTr>
            ))}
          </StyledThead>
          <StyledTbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              const shouldHighlightRow = isBestMetric(row.original, rowIndex);

              return (
                <TableRows {...row.getRowProps()} isBestMetric={shouldHighlightRow}>
                  {row.cells.map((cell) => (
                    <StyledTd {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </StyledTd>
                  ))}
                </TableRows>
              );
            })}
          </StyledTbody>
        </StyledTable>
      </IntegrationResultsContainer>
    </SelectedIntregrationContainer>
  ) : (
    <LoadingContainer>
      <IntegrationImageWrapperShimmer>
        <Shimmer>
          <TitleShimmer />
          <SubtitleShimmer />
        </Shimmer>
      </IntegrationImageWrapperShimmer>
      <Shimmer>
        <DropdownLabelShimmer />
        <DropdownShimmer />
      </Shimmer>
      <MetricBoxShimmerContainer>
        <Shimmer>
          <MetricBoxShimmer />
          <MetricBoxShimmer />
          <MetricBoxShimmer />
        </Shimmer>
      </MetricBoxShimmerContainer>

      <Shimmer>
        <TableShimmer />
      </Shimmer>

    </LoadingContainer>
  );
};
export default ImportedDataSelection;
