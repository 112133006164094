/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  submitTagWorkoutItem, setTagWorkout,
  submitProgramTagWorkoutItem,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	height: 170px;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
	flex-direction: column;
	background: white;
`;

const CompleteButton = styled('button')`
  width: 140px;
	height: 37px;
	border-radius: 40px;
	border: ${(props) => (props.isWorkoutComplete ? '1px solid #00b371;' : '1px solid lightgrey')};
	margin-top: 20px;
	background: ${(props) => (props.isWorkoutComplete ? '#00b371;' : 'white')};
`;

const HeaderText = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 10px;
	margin-bottom: 15px;
	font-family: 'Nunito Sans';
	color: gray;
`;

const InnerCompleteButton = styled('div')`
  display: flex;
	justify-content: center;
	align-items: center;
`;

const CompleteButtonText = styled('div')`
  font-size: 12px;
	margin-left: 10px;
	font-weight: 600;
	font-family: 'Nunito Sans';
	color: ${(props) => (props.isWorkoutComplete ? 'white' : props.color)};
`;

const TriggerAndIcon = styled('div')`
  width: 90%;
  display: flex;
  :hover {
		border-color: #C0C0C0;
	}
	border-radius: 4px;
  border: 1px solid #D8D8D8;
  align-items: center;
`;

const HeaderDivider = styled('div')`
  border: 0.5px solid lightgrey;
  height: 60%;
`;

const IconContainer = styled('div')`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TagSelectTrigger = styled('div')`
  font-weight: 500;
  color: grey;
  background: #ffffff;
	user-select: none;
  border-radius: 4px;
	cursor: default;
	min-height: 38px;
	padding: 8px;
	width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  z-index: 100;
  border: none;
  flex: 1;
`;

const TagCard = ({
  workoutObject, index, outerIndex, sessionIndex,
}) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const selectedTagExerciseObject = useSelector(
    (state) => state.workouts.data.selectedTagExerciseObject,
  );
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  const [buttonSetting, setButtonSetting] = useState(workoutObject.fullyCompleted);

  const dispatch = useDispatch();

  const theme = useTheme();

  const renderCoachCompletionOnlyLockButton = () => (
    <CompleteButton
      disabled
      title='Coach Input'
    />
  );

  const renderCoachCompletionText = () => (
    <div>
      A coach must complete this workout item
    </div>
  );

  const confirmTagSelect = () => {
    let exerciseId;
    if (selectedTagExerciseObject[workoutObject?.assignedId]?.id) {
      exerciseId = selectedTagExerciseObject[workoutObject?.assignedId]?.id;
    } else {
      exerciseId = selectedTagExerciseObject[workoutObject?.saveDataId]?.id;
    }
    const payload = {
      exerciseId,
    };
    if (workoutObject.assignedId) {
      dispatch(
        submitTagWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId || workoutObject.saveDataId,
          payload,
          index,
          outerIndex,
          sessionIndex,
        ),
      );
    } else {
      dispatch(
        submitProgramTagWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          payload,
          index,
          outerIndex,
          currentSelectedProgram.dayBasedProgramId,
          currentNonDateDay.trueDayNum,
          sessionIndex,
        ),
      );
    }
  };

  const tagSelectTriggerFunc = () => {
    if (selectedTagExerciseObject[workoutObject.assignedId]) {
      return selectedTagExerciseObject[workoutObject.assignedId]
      && Object.keys(selectedTagExerciseObject[workoutObject.assignedId]).length
        ? selectedTagExerciseObject[workoutObject.assignedId].name : '';
    }
    return selectedTagExerciseObject[workoutObject.saveDataId]
    && Object.keys(selectedTagExerciseObject[workoutObject.saveDataId]).length
      ? selectedTagExerciseObject[workoutObject.saveDataId].name : '';
  };

  return (
    <Container>
      <HeaderText>
        Select an exercise and click &apos;Confirm&apos;
      </HeaderText>
      <TriggerAndIcon onClick={() => dispatch(setTagWorkout(workoutObject))}>
        <TagSelectTrigger>
          {tagSelectTriggerFunc()}
        </TagSelectTrigger>
        <HeaderDivider />
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='down-arrow'
            color='lightgrey'
          />
        </IconContainer>
      </TriggerAndIcon>
      {!workoutObject.coachCompletionOnly
        || (workoutObject.coachCompletionOnly && currentUser.admin) ? (
          <CompleteButton
            isWorkoutComplete={buttonSetting}
            onClick={() => {
              confirmTagSelect();
            }}
          >
            <InnerCompleteButton>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color={!buttonSetting ? 'black' : 'white'}
              />
              <CompleteButtonText
                isWorkoutComplete={buttonSetting}
                color={theme.colors.text}
              >
                Confirm
              </CompleteButtonText>
            </InnerCompleteButton>
          </CompleteButton>
        ) : null}
      {workoutObject.coachCompletionOnly
			&& !currentUser.admin
        ? renderCoachCompletionOnlyLockButton()
        : null}
      {workoutObject.coachCompletionOnly
        ? renderCoachCompletionText()
        : null}
    </Container>
  );
};

TagCard.defaultProps = {
  sessionIndex: null,
};

TagCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default TagCard;
