const corePackage = {
  title: 'Sport Science Core Package',
  core: true,
  unlocked: false,
  sections: [
    {
      name: 'Monitoring',
      description: 'Generate insights based on athlete workload and self-reported questionnaire data.',
      icon: 'clipboard',
    },
    {
      name: 'Readiness',
      description: 'Determine athlete readiness by viewing health metrics from wearable devices like sleep, heart rate, HRV, and more.',
      icon: 'heart-rate',
    },
    {
      name: 'Recovery',
      description: 'Uncover athletes’ individualized responses to training and competition all while reducing the risk of injury.',
      icon: 'circuit',
    },
  ],
  videoUrl: 'https://www.youtube.com/watch?v=AcMYYgg_djQ',
  description: 'Compare athlete weight room load to questionnaire responses',
  icon: 'chart-line',
};

export default corePackage;
