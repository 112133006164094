/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { setAboutDashboardModalShowing } from '../../ducks/sportCoachDashboardActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import Button from '../../../../shared/components/Button/Button';

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  max-width: 600px;
  height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  width: 60vw;
  position: absolute; 
  left: 50%;
  top: 50%;
  right: 50%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  .opt-out-button {
    background: #424242;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
  box-shadow: ${BoxShadow};
  scroll-behavior: smooth;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
  font-family: 'Nunito Sans';
  font-weight: normal;
`;

const TopBox = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: solid 1px #EEEEEE;
  padding-bottom: 25px;
  width: 120%;
`;

const Image = styled('img')`
  width: 75px;
  height: 75px;
  margin-bottom: 10px;
`;

const TempName = styled('div')`
  font-size: 23px;
  font-weight: normal;
`;

const TempOwner = styled('div')`
  font-size: 16px;
  color: #9E9E9E;
`;

const TempEdgeBox = styled('div')`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const BottomBox = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Description = styled('div')`
  margin: 25px 0px 10px 0px;
  font-size: 16px;
  text-align: left;
`;

const ExerContainer = styled('div')`
`;

const ExerTitle = styled('div')`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const ColumnsContainer = styled('div')`
  display: flex;
`;

const Exercises = styled('ol')`
  padding-left: 25px;
  li + li {
    margin-top: 5px;
  }
`;

const Exercise = styled('li')`
  font-size: 16px;
  color: #616161;
`;

const AboutDashboardModal = () => {
  const aboutDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.aboutDashboardModalShowing,
  );
  const dashCreator = useSelector((state) => state.sportCoachDashboard.data.dashCreator);
  const dashDescription = useSelector((state) => state.sportCoachDashboard.data.dashDescription);
  const dashLogo = useSelector((state) => state.sportCoachDashboard.data.dashLogo);
  const dashTitle = useSelector((state) => state.sportCoachDashboard.data.dashTitle);
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const isReadOnlyView = useSelector((state) => state.sportCoachDashboard.ui.isReadOnlyView);
  const aboutTemplate = useSelector((state) => state.sportCoachDashboard.data.aboutTemplate);

  const dispatch = useDispatch();

  // Handle setting about dash info based on read-only view vs template view
  const logo = isReadOnlyView ? dashLogo : aboutTemplate?.teamImage;
  const title = isReadOnlyView ? dashTitle : aboutTemplate?.title;
  const creator = isReadOnlyView ? dashCreator : `${aboutTemplate?.firstName} ${aboutTemplate?.lastName}`;
  const description = isReadOnlyView ? dashDescription : aboutTemplate?.description;
  const exers = isReadOnlyView ? exercises : aboutTemplate?.exercises;

  // Splitting the exercises array into two separate arrays to render two cols
  const firstExerCol = exers?.slice(0, 5);
  const secondExerCol = exers?.slice(5, 10);
  const hasSecondCol = secondExerCol?.length > 0;

  return (
    <ModalContainer isActive={aboutDashboardModalShowing}>
      <div className='remove-div' onClick={() => dispatch(setAboutDashboardModalShowing(false))}>
        <div className='remove-div-icon'>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <TopBox>
          <Image src={logo} alt='' id='image' />
          <TempEdgeBox id='temp-edge-box'>
            <TempName id='temp-name'>{title}</TempName>
            <TempOwner id='temp-owner'>{creator}</TempOwner>
          </TempEdgeBox>
        </TopBox>
        <BottomBox>
          <Description>
            {description}
          </Description>
          <ExerContainer>
            <ExerTitle>Exercises</ExerTitle>
            <ColumnsContainer>
              <Exercises>
                {firstExerCol?.map((exer) => (
                  <Exercise key={exer.id}>{exer.name}</Exercise>
                ))}
              </Exercises>
              <Exercises start='6' style={{ marginLeft: '50px' }}>
                {hasSecondCol && secondExerCol?.map((exer) => (
                  <Exercise key={exer.id}>{exer.name}</Exercise>
                ))}
              </Exercises>
            </ColumnsContainer>
          </ExerContainer>
        </BottomBox>
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='Close'
        className='opt-out-button'
        large
        square
        noBorder
        primary
        customColor='#444444'
        customTextColor='#FFFFFF'
        onClick={() => {
          dispatch(setAboutDashboardModalShowing(false));
        }}
      />
    </ModalContainer>
  );
};

export default AboutDashboardModal;
