const conversationData = [
  {
    "id":199,
    "conversationId":56,
    "messageBody":"Duis ornare mi pellentesque neque pellentesque molestie. ",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:23:17 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":198,
    "conversationId":56,
    "messageBody":"ed vitae lectus vel dui eleifend auctor. Phasellus sed lectus eget leo porta venenatis. In dapibus venenatis metus vitae interdum.",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:23:14 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":197,
    "conversationId":56,
    "messageBody":"Sed vitae lectus vel dui eleifend auctor. Phasellus sed lectus eget leo porta venenatis. In dapibus venenatis metus vitae interdum. Quisque sollicitudin condimentum tristique. Duis ornare mi pellentesque neque pellentesque molestie. ",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:23:05 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":196,
    "conversationId":56,
    "messageBody":"\nQuisque sollicitudin condimentum tristique. Duis ornare mi pellentesque neque pellentesque molestie. ",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:53 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":195,
    "conversationId":56,
    "messageBody":"Quisque sollicitudin condimentum tristique.",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:50 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":194,
    "conversationId":56,
    "messageBody":"Quisque sollicitudin condimentum tristique. Duis ornare mi pellentesque neque pellentesque molestie. ",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:37 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":193,
    "conversationId":56,
    "messageBody":"Sed vitae lectus vel dui eleifend auctor.",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:33 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":192,
    "conversationId":56,
    "messageBody":"Sed vitae lectus vel dui eleifend auctor. Phasellus sed lectus eget leo porta venenatis. In dapibus venenatis metus vitae interdum. Quisque sollicitudin condimentum tristique. Duis ornare mi pellentesque neque pellentesque molestie. ",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:27 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":191,
    "conversationId":56,
    "messageBody":"Mauris hendrerit faucibus aliquet. Etiam laoreet odio placerat venenatis faucibus. Nunc malesuada nunc ut nisi rutrum sagittis quis vel neque.",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:09 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":190,
    "conversationId":56,
    "messageBody":"Lorem ipsum dolor sit amet",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:22:05 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":189,
    "conversationId":56,
    "messageBody":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras consequat tellus at quam aliquam, ac iaculis erat sagittis. Mauris hendrerit faucibus aliquet. Etiam laoreet odio placerat venenatis faucibus. Nunc malesuada nunc ut nisi rutrum sagittis quis vel neque.",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:21:57 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":188,
    "conversationId":56,
    "messageBody":"orem ipsum dolor sit amet, consectetur adipiscing elit. ",
    "media":null,
    "read":false,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:21:48 PM",
    "userId":413573,
    "userFirstName":"Elvis",
    "userLastName":"Guillen",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  },
  {
    "id":187,
    "conversationId":56,
    "messageBody":"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras consequat tellus at quam aliquam, ac iaculis erat sagittis. Mauris hendrerit faucibus aliquet. Etiam laoreet odio placerat venenatis faucibus. Nunc malesuada nunc ut nisi",
    "media":null,
    "read":true,
    "readAt":null,
    "scheduledAt":null,
    "sendEmail":false,
    "sendSMS":false,
    "sentAt":"December 7, 2020 7:21:40 PM",
    "userId":38,
    "userFirstName":"Coach",
    "userLastName":"TeamBuildr",
    "userPic":"https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png"
  }
]

export default conversationData;