/**
 * Select all box is a component that contains a checkbox for checking selecting
 * all notifications or conversations, depending on which one is currently displaying
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from 'emotion-theming';

import Text from '../../../../shared/components/Text/Text';

const StatusWrapper = styled('div')`
`;

const StatusIcons = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  align-items: flex-end;

  svg {
    margin-left: 5px;
  }
`;

/**
 *
 * @param {Function} setSelectionFunction function to execute when box checked
 * @param {Boolean} isCheckboxesOpen boolean of whether the checkboxes are displayed
 * @param {Array} objects array of notifications/conversations, depending on current settings
 * @param {Object} localNotificationFilters current active filters
 * @returns a component that displays select all box and dynamically handles select all function
 */
const SelectAllBox = ({
  setSelectionFunction,
  isCheckboxesOpen,
  objects,
  localNotificationFilters,
}) => {
  const theme = useTheme();

  // A slice of local state that figures whether to unselect or deselect all items
  const [isAllSelected, setIsAllSelected] = useState(false);

  const ConversationDiv = styled('div')`
    display: flex;
    width: 100%;
    height: 80px;
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;

    @keyframes slideIn {
      0% {
        opacity: 0;
        width: 5%;
        margin-left: 0px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 19px;
        opacity: 1;
        width: 15%
      }
    }
    @keyframes slideOut {
      0% {
        opacity: 1;
        width: 15%;
        margin-left: 19px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 0px;
        opacity: 0;
        width: 5%
      }
    }

    .left-wrapper {
      display: flex;
      align-items: center;
      width: 60%;

      .checkbox-div {
        margin-left: ${isCheckboxesOpen ? '19px' : '0px'};
        opacity: ${isCheckboxesOpen ? 1 : 0};
        width: ${isCheckboxesOpen ? '15%' : '5%'}
      }

      .message-box {
        display: flex;
        width: 90%;
        flex-direction: row;
        color: #444444;
        align-items: center;
        padding: 9px;
        border-radius: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: Nunito Sans;
        font-weight: bold;

        .sender-latest-message {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .title {
            font-weight: bold;
          }
          .latest-message {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
          }
        }
      }
    }

    .end-box {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .latest-time {
        margin-right: 10px;
      }

      .arrow-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 10%;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `;

  return (
    <ConversationDiv>
      <div className='left-wrapper'>
        <>
          <div className='checkbox-div'>
            <Checkbox
              onChange={() => {
                /**
                 * if all selected true, creates an object and iterates
                 * all filters to only display notifications/conversations
                 * that obey the constraints of current filters
                 * calls the set all function depending on what was passed in
                 * setIsAllSelected to true
                 * otherwise calls the selection function with an empty object and
                 * sets is all selected to false
                 */
                if (!isAllSelected) {
                  const selectedAllObjects = {};
                  if (localNotificationFilters?.length) {
                    objects.forEach((object) => {
                      if (localNotificationFilters.includes(object.typeDescription)) {
                        selectedAllObjects[object.id] = parseInt(object.id, 10);
                      }
                    });
                  } else {
                    objects.forEach((object) => {
                      selectedAllObjects[object.id] = parseInt(object.id, 10);
                    });
                  }
                  setSelectionFunction(selectedAllObjects);
                  setIsAllSelected(true);
                } else {
                  setSelectionFunction({});
                  setIsAllSelected(false);
                }
              }}
              checked={isAllSelected}
              color='primary'
              disabled={!isCheckboxesOpen}
            />
          </div>
        </>
        <div className='message-box'>
          <div className='sender-latest-message'>
            <Text
              fontSize={12}
              fontFamily='Open Sans'
              className='latest-message'
            >
              {!isAllSelected ? 'Select All' : 'Un-Select All'}
            </Text>
          </div>
        </div>
      </div>
      <div className='end-box'>
        <StatusWrapper>
          <Text
            color={theme.colors.lightText[0]}
            fontSize={12}
            className='latest-time'
          />
          <StatusIcons />
        </StatusWrapper>
        <div className='arrow-box' />
      </div>
    </ConversationDiv>
  );
};

SelectAllBox.propTypes = {
  isCheckboxesOpen: PropTypes.bool.isRequired,
  setSelectionFunction: PropTypes.func.isRequired,
  objects: PropTypes.instanceOf(Array).isRequired,
  localNotificationFilters: PropTypes.instanceOf(Array),
};

SelectAllBox.defaultProps = {
  localNotificationFilters: [],
};

export default SelectAllBox;
