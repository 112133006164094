/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
// // Authored by Colin Reitman
/** Added functionality by kristy street...
 * dynamic fixed position
 * out side click close
 * tranform
 * custom trigger component et.) */
import React, {
  useRef, useEffect, useState, useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { BoxShadow } from '../../GlobalStyles';
import iconSet from '../../images/teambuildr-selection.json';
import Wrapper from './Wrapper';

const DropdownMenu = styled('div')`
  position: fixed;
  width: ${(props) => (props.menuWidth ? props.menuWidth : '')};
  max-width: ${(props) => (props.menuMaxWidth ? props.menuMaxWidth : '')};
  max-height: ${(props) => (props.menuMaxHeight ? props.menuMaxHeight : '')};
  background: white;
  box-shadow: ${BoxShadow};
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left + props.menuAbsolutePosition}px;
  overflow: auto;
  padding-top: ${(props) => (props.menuPaddingTop ? props.menuPaddingTop : '')};
  padding-bottom: ${(props) => (props.menuPaddingBottom ? props.menuPaddingBottom : '')};
  border-radius: ${(props) => (props.menuBorderRadius ? props.menuBorderRadius : '')};
  border: ${(props) => (props.menuBorder ? props.menuBorder : '')}
`;

const DropdownOption = styled('div')`
  width: 100%;
  min-height: 40px;
  white-space: normal;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: ${(props) => (props.addBorderBottom ? '1px solid black' : 'none')};
  cursor: ${(props) => (props.hasFunction ? 'pointer' : 'mouse')};
  ${(props) => props.hasFunction
    && `
    :hover {
      background: #f5f5f5;
    }
  `}
`;

const DropdownOptionText = styled('div')`
  font-size: 14px;
  font-family: 'Nunito Sans';
  margin-left: 15px;
  color: 'lightgrey';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconContainer = styled('div')`
  cursor: pointer;
  transform: ${(props) => (props.transform ? props.transform : 'none')};
  transition: all 250ms ease-in-out;
  :hover {
    opacity: .7
  }
`;

const RelativeContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarWrapper = styled('div')`
background-image: url(${(props) => props.backgroundImage});
background-size: cover; /* Ensure the image covers the entire container */
background-position: center; /* Center the image */
min-width: 28px;
min-height: 28px;
border-radius: 50%;
border: solid 1px #444444;
`;

const DropdownContainer = ({
  dotColor,
  icon,
  iconColor,
  isOpen,
  options,
  setIsOpen,
  transform,
  menuWidth,
  menuMaxWidth,
  menuMaxHeight,
  menuAbsolutePosition,
  menuPaddingBottom,
  menuPaddingTop,
  menuBorderRadius,
  menuBorder,
  CustomTriggerComponent,
  customTriggerProps, /** Add this line to accept additional props for custom trigger */
  setParentRef, /** use this if need to access dropdown ref in parent component */
}) => {
  const dropdownRef = useRef(null);
  const containerRef = useRef(null);

  if (setParentRef) {
    setParentRef.current = dropdownRef.current;
  }

  const handleClickOutside = (event) => {
    if (isOpen) {
      const ref = dropdownRef.current;
      if (ref && !ref.contains(event.target)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const calculateMenuPosition = () => {
    const ref = containerRef?.current;
    if (ref) {
      const { top, left, height } = ref.getBoundingClientRect();
      setMenuPosition({ top: top + height, left });
    }
  };

  const handleClick = () => {
    const ref = containerRef?.current;
    if (ref) {
      calculateMenuPosition();
    }
    setIsOpen(!isOpen);
  };

  useLayoutEffect(() => {
    if (isOpen) {
      const ref = containerRef?.current;
      if (ref) {
        const { top, left, height } = ref.getBoundingClientRect();
        /** + 5 for padding */
        setMenuPosition({ top: top + (height + 5), left });
      }
    }
  }, [isOpen]);

  /** reposition dropdown if getting cut off page viewport */
  useLayoutEffect(() => {
    if (isOpen) {
      const viewportHeight = window.innerHeight;
      if (dropdownRef?.current) {
        const menuheight = dropdownRef?.current?.getBoundingClientRect()?.height;
        const { top, left } = containerRef?.current.getBoundingClientRect();
        if (top + menuheight > viewportHeight) {
          /** + 5 for padding */
          setMenuPosition({ top: top - (menuheight + 5), left });
        }
      }
    }
  }, [isOpen]);

  return (
    <RelativeContainer id='dropdown-container' ref={containerRef}>

      <IconContainer
        transform={transform}
        onClick={() => {
          handleClick();
        }}
      >
        {CustomTriggerComponent ? (
          <CustomTriggerComponent {...customTriggerProps} />) : (
            <IcomoonReact
              color={dotColor || 'grey'}
              icon={icon || 'dots'}
              iconSet={iconSet}
              size={25}
            />
        )}
      </IconContainer>

      {isOpen ? (
        <DropdownMenu
          menuWidth={menuWidth}
          menuMaxWidth={menuMaxWidth}
          menuMaxHeight={menuMaxHeight}
          menuAbsolutePosition={menuAbsolutePosition}
          menuPaddingBottom={menuPaddingBottom}
          menuPaddingTop={menuPaddingTop}
          menuBorderRadius={menuBorderRadius}
          menuBorder={menuBorder}
          top={menuPosition.top}
          left={menuPosition.left}
          ref={dropdownRef}
        >
          {options.map((option) => (
            <DropdownOption
              hasFunction={!!option.function}
              onClick={() => {
                setIsOpen(false);
                option.function();
              }}
            >
              {option.avatar ? (
                <AvatarWrapper backgroundImage={option.avatar} />
              ) : (
                <IcomoonReact
                  color={iconColor || 'lightgrey'}
                  icon={option.icon}
                  iconSet={iconSet}
                  size={20}
                />
              )}
              <DropdownOptionText>
                {option.text}
              </DropdownOptionText>
            </DropdownOption>
          ))}
        </DropdownMenu>
      ) : null}
    </RelativeContainer>
  );
};

/**
 *
 * @param {String} dotColor controls the color of the dropdown switch icon
 * @param {String} height custom height of wrapper containing dropdown switch
 * @param {String} icon controls switch icon
 * @param {String} iconColor controls color of icons
 * @param {Boolean} isOpen controls whether dropdown menu is open
 * @param {String} transform controls the orientation of the dots
 * (horizontal vs vertical ex: 'rotate(90deg)' would make them vertical)
 * * @param {Array} options array of menu options in the shape of:
 * [
 *  {
      text: 'text to display',
      function: () => {},
      icon: 'icon name from IcoMoon',
    },
 * ]
 * @param {Function} setIsOpen function that sets whether dropdown is open
 * @param {Object} styledProps accepts an object with all optional styles
 * in accordance with the styled-system paradigm (https://github.com/styled-system/styled-system/blob/master/docs/api.md)
 * @param {String} width custom width for the wrapper around the dropdown switch
 * @param {String} menuWidth custom width for the menu of the dropdown
 * @param {String} menuMaxWidth custom max width for the menu of the dropdown
 * @param {String} menuMaxHeight custom max height for the menu of the dropdown
 * @param {String} menuPaddingTop custom padding top for the menu of the dropdown
 * @param {String} menuPaddingBottom custom padding bottom for the menu of the dropdown
 * @param {String} menuBorderRadius border radius of the dropdown
 * @param {String} menuBorder border width and color for menu (ie. 1px solid lightgrey)
 * @param {Number} menuAbsolutePosition absolute position of the dropdown
 * @param {Element} CustomDropdownTrigger custom trigger component for dropdown
 * @param {Object} customTriggerProps any props that you need to pass to your custom component
 * @param setParentRef ref that you create in parent component if you need to access dropdown ref in parent
 * relative to the left of the dot container
 * @returns
 */
const Dropdown = (
  {
    dotColor = '',
    height = '',
    icon = '',
    iconColor = '',
    isOpen,
    options,
    setIsOpen,
    styledProps = {},
    width = '',
    transform = 'none',
    menuWidth = '',
    menuMaxWidth = '',
    menuMaxHeight = '',
    menuPaddingBottom = '',
    menuPaddingTop = '',
    menuBorderRadius = '',
    menuBorder = '',
    menuAbsolutePosition = 0,
    key,
    CustomTriggerComponent = null,
    customTriggerProps,
    setParentRef,
  },
) => (
  <Wrapper
    {...styledProps}
    width={width}
    height={height}
  >
    <DropdownContainer
      key={key}
      dotColor={dotColor}
      icon={icon}
      iconColor={iconColor}
      isOpen={isOpen}
      options={options}
      setIsOpen={setIsOpen}
      transform={transform}
      menuWidth={menuWidth}
      menuMaxWidth={menuMaxWidth}
      menuMaxHeight={menuMaxHeight}
      menuPaddingBottom={menuPaddingBottom}
      menuPaddingTop={menuPaddingTop}
      menuBorderRadius={menuBorderRadius}
      menuBorder={menuBorder}
      menuAbsolutePosition={menuAbsolutePosition}
      CustomTriggerComponent={CustomTriggerComponent}
      customTriggerProps={customTriggerProps}
      setParentRef={setParentRef}
    />
  </Wrapper>
);

Dropdown.propTypes = {
  dotColor: PropTypes.string,
  height: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  setIsOpen: PropTypes.func.isRequired,
  styledProps: PropTypes.instanceOf(Object),
  width: PropTypes.string,
  transform: PropTypes.string,
  menuWidth: PropTypes.string,
  menuMaxWidth: PropTypes.string,
  menuMaxHeight: PropTypes.string,
  menuPaddingBottom: PropTypes.string,
  menuPaddingTop: PropTypes.string,
  menuBorderRadius: PropTypes.string,
  menuBorder: PropTypes.string,
  menuAbsolutePosition: PropTypes.number,
  key: PropTypes.string,
  CustomTriggerComponent: PropTypes.elementType,
  customTriggerProps: PropTypes.instanceOf(Object),
  setParentRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

Dropdown.defaultProps = {
  dotColor: '',
  height: '',
  icon: '',
  iconColor: '',
  styledProps: {},
  width: '',
  transform: 'none',
  menuWidth: '',
  menuMaxWidth: '',
  menuMaxHeight: '',
  menuPaddingBottom: '',
  menuPaddingTop: '',
  menuBorderRadius: '',
  menuBorder: '',
  menuAbsolutePosition: 0,
  key: '',
  CustomTriggerComponent: null,
  customTriggerProps: {},
  setParentRef: null,
};

DropdownContainer.propTypes = {
  dotColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  setIsOpen: PropTypes.func.isRequired,
  transform: PropTypes.string,
  menuWidth: PropTypes.string,
  menuMaxWidth: PropTypes.string,
  menuMaxHeight: PropTypes.string,
  menuPaddingBottom: PropTypes.string,
  menuPaddingTop: PropTypes.string,
  menuBorderRadius: PropTypes.string,
  menuBorder: PropTypes.string,
  menuAbsolutePosition: PropTypes.number,
  CustomTriggerComponent: PropTypes.elementType,
  customTriggerProps: PropTypes.instanceOf(Object),
  setParentRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

DropdownContainer.defaultProps = {
  transform: 'none',
  menuWidth: '',
  menuMaxWidth: '',
  menuMaxHeight: '',
  menuPaddingBottom: '',
  menuPaddingTop: '',
  menuBorderRadius: '',
  menuBorder: '',
  menuAbsolutePosition: 0,
  CustomTriggerComponent: null,
  customTriggerProps: {},
  setParentRef: null,
};

export default Dropdown;
