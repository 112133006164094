import {
  FETCH_CALENDARS_START,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  CREATE_CALENDAR_START,
  CREATE_CALENDAR_FULFILLED,
  CREATE_CALENDAR_REJECTED,
  UPDATE_CALENDAR_START,
  UPDATE_CALENDAR_FULFILLED,
  UPDATE_CALENDAR_REJECTED,
  DELETE_CALENDARS_START,
  DELETE_CALENDARS_FULFILLED,
  DELETE_CALENDARS_REJECTED,
  ARCHIVE_CALENDARS_START,
  ARCHIVE_CALENDARS_FULFILLED,
  ARCHIVE_CALENDARS_REJECTED,
  UNARCHIVE_CALENDARS_START,
  UNARCHIVE_CALENDARS_FULFILLED,
  UNARCHIVE_CALENDARS_REJECTED,
  CREATE_CHILD_CALENDAR_START,
  CREATE_CHILD_CALENDAR_FULFILLED,
  CREATE_CHILD_CALENDAR_REJECTED,
} from './actionTypes';

const initialState = {
  isCalendarsLoading: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDARS_START: {
      return {
        ...state,
        isCalendarsLoading: true,
      };
    }
    case FETCH_CALENDARS_FULFILLED: {
      return {
        ...state,
        isCalendarsLoading: false,
      };
    }
    case FETCH_CALENDARS_REJECTED: {
      return {
        ...state,
        isCalendarsLoading: false,
      };
    }
    case CREATE_CALENDAR_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case CREATE_CALENDAR_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case CREATE_CALENDAR_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case UPDATE_CALENDAR_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case UPDATE_CALENDAR_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case UPDATE_CALENDAR_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case DELETE_CALENDARS_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case DELETE_CALENDARS_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case DELETE_CALENDARS_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case ARCHIVE_CALENDARS_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case ARCHIVE_CALENDARS_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case ARCHIVE_CALENDARS_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case UNARCHIVE_CALENDARS_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case UNARCHIVE_CALENDARS_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case UNARCHIVE_CALENDARS_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case CREATE_CHILD_CALENDAR_START: {
      return {
        ...state,
        isCalendarSubmitting: true,
      };
    }
    case CREATE_CHILD_CALENDAR_FULFILLED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    case CREATE_CHILD_CALENDAR_REJECTED: {
      return {
        ...state,
        isCalendarSubmitting: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
