import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';

import ProgressBar from './ProgressBar';

const AnimatedProgressBar = ({
  namespace,
}) => {
  const activeWorkoutIndex = useSelector(state => state[namespace].activeWorkoutIndex);
  const workoutCount = useSelector(state => state[namespace].workoutCount);

  const isWorkoutActive = activeWorkoutIndex !== null;

  const props = useSpring({
    to: { opacity: isWorkoutActive ? 1 : 0 },
    from: { opacity: 0 },
  });

  return (
    <animated.div style={props}>
      <ProgressBar
        assigned={workoutCount && workoutCount.assigned}
        completed={activeWorkoutIndex + 1}
      />
    </animated.div>
  );
};

AnimatedProgressBar.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default AnimatedProgressBar;
