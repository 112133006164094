/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { deleteEvaluation } from '../../ducks/evaluationsActions';
import Button from '../../../../shared/components/Button/Button';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-width: 300px;
  max-height: 150px;
  height: 50vh;
  width: 50vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #f24540;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 1000%)')};
  user-select: none;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  background: white;
  height: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  font-size: 20px;
  font-family: 'Nunito Sans';
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
`;

const DeleteConfirmationModal = () => {
  const primedDeleteEvalInfo = useSelector((state) => state.evaluations.data.primedDeleteEvalInfo);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();

  return (
    <ModalContainer isActive={Object.keys(primedDeleteEvalInfo).length}>
      <ContentContainer>
        Are you sure you want to delete this evaluation?
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='Delete'
        className='print-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          dispatch(deleteEvaluation(
            currentUser.accountCode,
            primedDeleteEvalInfo.id,
            primedDeleteEvalInfo.index,
          ));
        }}
      />
    </ModalContainer>
  );
};

DeleteConfirmationModal.propTypes = {

};

export default DeleteConfirmationModal;
