import React from 'react';
import styled from '@emotion/styled';

const SpinnerWrapper = styled('div')`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Spinner = () => (
  <SpinnerWrapper className='spinner-container'>
    <svg className='spinner' height='16px' viewBox='0 0 66 66' width='16px' xmlns='http://www.w3.org/2000/svg'>
      <circle className='path' cx='33' cy='33' fill='none' r='30' strokeLinecap='round' strokeWidth='6' />
    </svg>
  </SpinnerWrapper>
);

export default Spinner;
