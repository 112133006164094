import React from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import MaxReport from './MaxReport/MaxReport';
import ActivityReport from './ActivityReport/ActivityReport';
import RawDataReport from './RawDataReport/RawDataReport';
import QuestionnaireReport from './QuestionnaireReport/QuestionnaireReport';
import EvaluationReport from './EvaluationReport/EvaluationReport';
import Report from './BaseResultsReport/Report';
import ComparisonReport from './ComparisonReport/ComparisonReport';

const ActiveReport = ({ printRef, handlePrint }) => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  return (
    <>
      {activeReport === 'max' && (
        <MaxReport />
      )}
      {activeReport === 'activity' && (
        <ActivityReport />
      )}
      {activeReport === 'raw_data' && (
        <RawDataReport />
      )}
      {activeReport === 'questionnaire' && (
        <QuestionnaireReport />
      )}
      {activeReport === 'evaluation' && (
        <EvaluationReport />
      )}
      {activeReport === 'comparison' && (
        <ComparisonReport printRef={printRef} handlePrint={handlePrint} />
      )}
      {activeReport === 'workout_results' && (
        <Report resultsReportType='workoutResultsReport' />
      )}
      {activeReport === 'opt_out_notes' && (
        <Report resultsReportType='optOutNotesReport' />
      )}
    </>
  );
};

ActiveReport.propTypes = {
  printRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  handlePrint: PropTypes.func,
};

ActiveReport.defaultProps = {
  printRef: null,
  handlePrint: () => {},
};

export default ActiveReport;
