import { combineReducers } from 'redux';

import conversationsUiReducer from './conversationsUiReducer';
import conversationsDataReducer from './conversationsDataReducer';
import notificationsDataReducer from './notificationsDataReducer';
import notificationsUiReducer from './notificationsUiReducer';

export const conversationsReducer = combineReducers({
  ui: conversationsUiReducer,
  data: conversationsDataReducer,
});

export const notificationsReducer = combineReducers({
  data: notificationsDataReducer,
  ui: notificationsUiReducer,
});
