import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import Text from '../../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import useReseller from '../../hooks/useReseller';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-create-button {
    display: flex;
    align-items: center;
    align-self: center;
    background-color: #0dcc8a;
    position: absolute;
    bottom: 0;
  }
`;

const TypeFieldWrapper = styled('div')`
  display: flex;
  width: 61%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const ValueFieldWrapper = styled('div')`
  display: flex;
  width: 35%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const OuterFlexWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitlePlusErrorWrapper2 = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 39px;
`;

const DateSelectorTitleWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 75px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .date-picker {
    border-radius: 3px;
    border-color: lightgrey;
    border-width: 1px;
    margin-left: 20px;
    margin-top: 3px;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 12px;
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .first-stage-select {
    width: 100%;
  }
`;

const ModalCreateCoupon = (
  {
    onRequestClose,
  },
) => {
  const {
    currentUser,
    currentReseller,
    handleCreateCoupon,
  } = useReseller();

  const couponsError = useSelector((state) => state.reseller.data.couponsError);
  const isCouponCreating = useSelector((state) => state.reseller.ui.isCouponCreating);
  const [submitClicked, setSubmitClicked] = useState(false);
  const theme = useTheme();
  const handleSubmit = (
    values,
  ) => {
    setSubmitClicked(true);
    const couponValues = {
      currency: values.currency,
      duration: values.duration,
      redeemBy: values.redeemBy,
      id: values.id,
      name: values.name,
    };
    if (values.type === 'fixed') {
      couponValues.amountOff = parseInt(values.value) * 100;
      couponValues.currency = currentReseller.defaultCurrency;
    } else {
      couponValues.percentOff = parseInt(values.value);
    }
    if (couponValues.duration === 'repeating') {
      couponValues.durationInMonths = parseInt(values.durationInMonths);
    }
    if (couponValues.duration === 'forever' || !couponValues.redeemBy) {
      delete couponValues.redeemBy;
    }
    if (values.maxRedemptions) {
      couponValues.maxRedemptions = parseInt(values.maxRedemptions);
    }

    handleCreateCoupon({ accountCode: currentUser.accountCode, couponValues });
  };

  const couponSchema = Yup.object().shape({
    id: Yup.string()
      .required('Required')
      .matches(/^[a-zA-Z0-9_]*$/g, 'Must only contain alphanumeric characters or underscores'),
    name: Yup.string()
      .required('Required'),
    value: Yup.number()
      .required('Required'),
    duration: Yup.string()
      .required('Required'),
    type: Yup.string()
      .required('Required'),
    durationInMonths: Yup.string()
      .when('duration', {
        is: 'repeating',
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
    maxRedemptions: Yup.number(),
  });

  const frequencyOptions = [
    {
      name: 'Once',
      id: 'once',
    },
    {
      name: 'Repeating',
      id: 'repeating',
    },
    {
      name: 'Forever',
      id: 'forever',
    },
  ];

  const typeOptions = [
    {
      name: 'Fixed',
      id: 'fixed',
    },
    {
      name: 'Percentage',
      id: 'percentage',
    },
  ];

  const [selectedDate, setSelectedDate] = useState(null);
  const [touchedType, setTouchedType] = useState(false);
  const [touchedDuration, setTouchedDuration] = useState(false);

  const getPlaceholderPriceAndCurrency = (valueType) => {
    if (valueType === 'fixed') {
      if (currentReseller.placeSymbolAfterCurrency) {
        return `e.g. 50.00${currentReseller.currencySymbol}`;
      }
      return `e.g. ${currentReseller.currencySymbol}50.00`;
    }
    return 'e.g. 50%';
  };

  return (
    <Formik
      initialValues={{
        name: '',
        id: '',
        value: '',
        durationInMonths: '',
        maxRedemptions: '',
      }}
      validationSchema={couponSchema}
      onSubmit={(values) => {
        setTimeout(() => {
          handleSubmit(values);
        });
      }}
      render={({
        values,
        setFieldValue,
        setSubmitting,
        setFieldTouched,
        submitForm,
        errors,
        touched,
      }) => (
        <ModalWrapper>
          <NavigationWrapper>
            <Text
              className='close arrow-div'
              onClick={() => {
                onRequestClose();
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={13}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <AnotherWrapper>
            <OuterFolderFormWrapper>
              <FolderTextWrapper>
                <Text className='calendar-icon-text'>Add New Coupon</Text>
                {couponsError && submitClicked ? <TitleError className='error-text'>{couponsError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <InnerFolderFormWrapper>
                <Form className='formik-form'>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Code</strong></Text>
                      {touched.id && errors.id ? (<FieldError className='error-text'>{errors.id}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='id-field'
                      name='id'
                      placeholder='Coupon Code'
                      onBlur={() => {
                        setFieldValue('id', values.id.toUpperCase());
                        setFieldTouched('id');
                      }}
                    />
                    <Text className='url-string' fontSize={14} style={{ marginTop: '5px' }}>This is what customers will enter as the coupon code.</Text>
                  </FormGroup>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Description</strong></Text>
                      {touched.name && errors.name ? (<FieldError className='error-text'>{errors.name}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='name-field'
                      name='name'
                      placeholder='Coupon Description'
                    />
                  </FormGroup>

                  <FormGroup>

                    <OuterFlexWrapper>
                      <TypeFieldWrapper>
                        <TitlePlusErrorWrapper2>
                          <Text className='formgroup-text'><strong>Type</strong></Text>
                          {touchedType && errors.type ? (<FieldError className='error-text'>{errors.type}</FieldError>
                          ) : null}
                        </TitlePlusErrorWrapper2>
                        <Select
                          className='first-stage-select'
                          name='type'
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={typeOptions}
                          onBlur={() => setTouchedType(true)}
                          onChange={(options) => {
                            setTouchedType(true);
                            if (options) {
                              setFieldValue('type', options.id);
                            } else {
                              delete values.type;
                            }
                          }}
                          isOptionSelected={(typeOption) => values.typeOption === typeOption}
                          isClearable
                          isSearchable
                        />
                      </TypeFieldWrapper>
                      <ValueFieldWrapper>
                        <FormGroup>
                          <TitlePlusErrorWrapper>
                            <Text className='formgroup-text'><strong>Value</strong></Text>
                            {touched.value && errors.value ? (<FieldError className='error-text'>{errors.value}</FieldError>
                            ) : null}
                          </TitlePlusErrorWrapper>
                          <Field
                            className='form-control'
                            id='value-field'
                            name='value'
                            placeholder={getPlaceholderPriceAndCurrency(values.type)}
                            type='number'
                            onBlur={() => {
                              if (values.value && values.type === 'fixed') {
                                const int = values.value;
                                const twoPlacedFloat = parseFloat(int).toFixed(2);
                                setFieldValue('value', twoPlacedFloat);
                                setFieldTouched('value', true);
                              }
                            }}
                          />
                        </FormGroup>
                      </ValueFieldWrapper>
                    </OuterFlexWrapper>

                  </FormGroup>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Frequency</strong></Text>
                      {touchedDuration && errors.duration ? (<FieldError className='error-text'>{errors.duration}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <Select
                      name='duration'
                      className='first-stage-select'
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      options={frequencyOptions}
                      onBlur={() => setTouchedDuration(true)}
                      onChange={(options) => {
                        setTouchedDuration(true);
                        if (options) {
                          setFieldValue('duration', options.id);
                        } else {
                          delete values.duration;
                        }
                      }}
                      isOptionSelected={(pricingOption) => values.pricingOption === pricingOption}
                      isClearable
                      isSearchable
                    />
                  </FormGroup>
                  {values.duration !== 'repeating'
                    ? null
                    : (
                      <FormGroup>
                        <TitlePlusErrorWrapper>
                          <Text className='formgroup-text'><strong>Duration In Months</strong></Text>
                          {touched.durationInMonths && errors.durationInMonths ? (<FieldError className='error-text'>{errors.durationInMonths}</FieldError>
                          ) : null}
                        </TitlePlusErrorWrapper>
                        <Field
                          className='form-control'
                          id='duration-field'
                          name='durationInMonths'
                          placeholder='Duration In Months'
                        />
                      </FormGroup>
                    )}
                  {values.duration === 'forever' ? null : (
                    <FormGroup>
                      <DateSelectorTitleWrapper>
                        <TitlePlusErrorWrapper>
                          <Text className='formgroup-text'><strong>Expiration Date</strong></Text>
                        </TitlePlusErrorWrapper>
                        <DatePicker
                          name='redeemBy'
                          className='date-picker'
                          selected={selectedDate}
                          minDate={moment().toDate()}
                          onChange={(date) => {
                            setSelectedDate(date);
                            if (date) {
                              values.redeemBy = moment(date).unix().toString();
                            }
                          }}
                        />
                      </DateSelectorTitleWrapper>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Maximum # of Redemptions</strong></Text>
                      {touched.maxRedemptions && errors.maxRedemptions ? (<FieldError className='error-text'>{errors.maxRedemptions}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='redemptions-field'
                      name='maxRedemptions'
                      placeholder='Maximum # of redemptions'
                    />
                  </FormGroup>
                </Form>
              </InnerFolderFormWrapper>
            </OuterFolderFormWrapper>
          </AnotherWrapper>
          <NoHoverButton
            bottom
            fullWidth
            cta='Create Coupon'
            className='modal-create-button'
            customColor={theme.colors.green}
            large
            noBorder
            primary
            square
            isLoading={isCouponCreating}
            loadingMessage='Creating...'
            onClick={() => {
              /* The Formkit select component doesn't seem to
              handle it's touch events very well, so setting it
              manually here for both select fields so that
              we can properly show the "Required" error message
              */
              setTouchedType(true);
              setTouchedDuration(true);
              setSubmitting(true);
              submitForm();
            }}
          />

        </ModalWrapper>
      )}
    />
  );
};

ModalCreateCoupon.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalCreateCoupon;
