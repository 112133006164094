/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import FadeIn from 'react-fade-in';
import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';
import {
  Form as FormikForm, Field, Formik,
} from 'formik';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Button from '../../../../shared/components/Button/Button';
import Text from '../../../../shared/components/Text/Text';
import { setModalOpen, setErrorModalOpen } from '../../../sports-science/ducks/sportsScienceActions';
import {
  createNewHabitForAccount, fetchHabitsForAccount, editHabitForAccount, setAssignmentConflictAction,
} from '../../ducks/habitTrackingActions';
import Input from '../../../../shared/_TBComponents/Input/index';
import SelectBox from '../../../../shared/_TBComponents/SelectBox/index';
import ToggleComponent from '../../../../shared/_TBComponents/Toggle/index';
import habitPresets from '../../constants/index';
import SearchablePresetSelectMenu from './SearchablePresetSelectMenu';
import templatePNG from '../../../../shared/images/layout-template.png';
import createHabitPNG from '../../../../shared/images/container-plus.png';
import Avatar from '../../../../shared/_TBComponents/Avatar/Avatar';
import CounterInput from './CounterInput';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import MobileWidgetPreview from './MobileWidgetPreview';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FormWrapper = styled('div')`
.fade-in-100-width {
  width: 100%;
}
`;

const IconHoverWrapper = styled('div')`
  cursor: pointer;
  transition: all 250ms ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`;

const StyledForm = styled(FormikForm)`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: ${(props) => (props.activePage === 2 || props.activePage === 1 ? '60px' : '100px')};

  @media (max-width: 500px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
const PageContainer = styled('div')`
display: flex;
position: relative;
width: 100%;
flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'column')};
justify-content: center;
align-items: flex-start;
gap: ${(props) => (props.gap ? props.gap : '20px')};
  .fade-in-100-width {
    width: 100%;
  }

  @media (max-width: 500px) {
    gap: 20px;
  }
}
`;

const ToggleAndLableContainer = styled('div')`
display: flex;
justify-content: center;
align-items: center;
gap: 10px;
.toggle-label {
  margin-bottom: 0px;
}
`;

const StyledTextArea = styled('textarea')`
width: 100%;
height: 60px;
max-height: 60px !important;
min-height: 60px !important;
padding: 10px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 4px;
font-family: 'Nunito Sans';
color: #444444;
&:focus {
  outline: none;
  border-color: #ccc;
  box-shadow: none;
}
::placeholder {
  color: #9E9E9E;
}
`;

const TextAndPhotoContainer = styled('div')`
display: flex;
flex-direction: column;
gap: 20px;
width: 50%;
`;

const PhotoContainer = styled('div')`
box-shadow: 0px 4px 8px 0px #3031331A;
border-radius: 8px;
height: 233px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
transition: all 250ms ease-in-out;
  :hover {
    opacity: 0.7;
  }
  .photo {
    height: 125px;
    width: 125px;
    object-fit: contain;
  }
  @media (max-width: 500px) {
    height: 150px;

    .photo {
      height: 75px;
      width: 75px;
    }
  }
`;

const TextContainer = styled('div')`
display: flex;
flex-direction: column;
.title-fontsize {
  font-size: 23px;
}
.subtitle-fontsize {
  font-size: 16px
}
@media (max-width: 480px) {
  .title-fontsize {
    font-size: 18px;
  }
  .subtitle-fontsize {
    font-size: 13px;
  }

`;

const RadioGroupAndLabel = styled('div')`
display: flex;
width: 100%;
flex-direction: column;
font-family: Nunito Sans;
margin-bottom: -20px;
.MuiRadio-root {
}
.MuiTypography-body1{
  font-family: Nunito sans;
  font-size: 16px;
}
`;

const SpinnerWrapper = styled('div')`
.spinner-container {
  min-height: 200px;
}
`;

const WarningContainer = styled('div')`
display: flex;
border-radius: 8px;
padding: 8px 12px 8px 12px;
gap: 8px;
background-color: #E0E0E0;
margin-bottom: 10px;
`;

const StatusContainer = styled('div')`
display: flex;
flex-direction: column;
gap: 10px;
align-items: start;
`;

const SliderNav = styled('div')`
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 12px;
  margin-top: 10px;
  width: 100%;
`;

const NavCircle = styled('button')`
  height: 16px;
  width: 16px;
  display: block;
  background-color: #EEEEEE;
  border-radius: 100%;
  cursor: pointer;
  border: 0px;

  &.active {
    background-color: #444444;
    cursor: default;
  }
`;

const ErrorAndFieldContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CreateHabitForm = ({ initialPage, modalType }) => {
  const [activePage, setActivePage] = useState(initialPage);
  const [chosenPreset, setChosenPreset] = useState(null);
  const assignmentConflictAction = useSelector((state) => state.habitTracking.data.assignmentConflictAction);
  const habits = useSelector((state) => state.habitTracking.data.habits);
  const editHabitInfo = useSelector((state) => state.habitTracking.data.editHabit);
  const categories = useSelector((state) => state.habitTracking.data.categories);
  const athletes = useSelector((state) => state.habitTracking.data.athletes);
  const groups = useSelector((state) => state.habitTracking.data.reportableGroups);
  const isNewHabitLoading = useSelector((state) => state.habitTracking.ui.isNewHabitLoading);
  const isUpdateHabitLoading = useSelector((state) => state.habitTracking.ui.isUpdateHabitLoading);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const isSingleHabitLoading = useSelector((state) => state.habitTracking.ui.isSingleHabitLoading);
  const isCheck5HabitMaxLoading = useSelector((state) => state.habitTracking.ui.isCheck5HabitMaxLoading);
  const dispatch = useDispatch();
  const [showDescriptionBox, setShowDescriptionBox] = useState(false);
  const [formWrapperWidth, setFormWrapperWidth] = useState(0);

  useEffect(() => {
    if (assignmentConflictAction === 'continue-anyway') {
      setActivePage(4);
      dispatch(setErrorModalOpen(false, ''));
    }
  }, [assignmentConflictAction]);

  useEffect(() => {
    if (activePage === 4) {
      dispatch(setAssignmentConflictAction(''));
    }
  }, [activePage]);

  useEffect(() => {
    if (chosenPreset) {
      setShowDescriptionBox(!!chosenPreset?.description);
    }
    if (modalType === 'edit-modal' && editHabitInfo) {
      setShowDescriptionBox(!!editHabitInfo?.description);
    }
  }, [chosenPreset, editHabitInfo]);

  const [wasToggled, setWasToggled] = useState(false);

  const typeoptions = [
    { name: 'All athletes', id: 1 },
    { name: 'Groups(s)', id: 2 },
    { name: 'Individual athlete(s)', id: 3 },
  ];

  const athleteOptions = athletes?.map((athlete) => ({
    name: `${athlete?.first} ${athlete?.last}`,
    id: athlete.id,
    avatar: <Avatar avatar={athlete.pic} size={24} />,
  }));

  const groupOptions = groups?.map((group) => ({
    name: group.name,
    id: group.id,
  }));

  const categoryOptions = categories?.map((category) => ({
    name: category.name,
    id: category.id,
    icon: category.icon || 'question-mark',
    includeIconContainer: true,
    iconContainerColor: category.color,
  }));

  const getModalButtonCustomColors = (values, page, errors) => {
    if (page === 3) {
      if (values.name && values.type === 1 && !errors.name && !errors.description) {
        return {
          customColor: '#444444',
          customTextColor: 'white',
        };
      }
      if (values.name && values.type === 2 && !!values.groupIds?.length && !errors.name && !errors.description) {
        return {
          customColor: '#444444',
          customTextColor: 'white',
        };
      }
      if (values.name && values.type === 3 && !!values.userIds?.length && !errors.name && !errors.description) {
        return {
          customColor: '#444444',
          customTextColor: 'white',
        };
      }
    }
    return {
      customColor: '',
      customTextColor: '',
    };
  };

  const getSubmitButtonCustomColors = (values, errors) => {
    if (!Object.keys(errors)?.length) {
      return {
        customColor: '#444444',
        customTextColor: 'white',
      };
    }

    return {
      customColor: '',
      customTextColor: '',
    };
  };

  /** commenting out logic used for 5 habit max check */
  /**
  const findAddedUserIds = (userIds) => {
    const { users } = editHabitInfo;

    const existingUserIds = users.map((user) => user.id);

    const addedUserIds = userIds.filter((userId) => !existingUserIds.includes(userId));
    return addedUserIds;
  };
  */

  const handleGoToFrequencyClick = (validateForm, values, errors, setErrors, setTouched) => {
    validateForm().then(() => {
      if (values.name && values.type === 1 && !errors.name && !errors.description) {
        setActivePage(4);
        setErrors({});
        setTouched({});
      }
      if (values.name && values.type === 2 && !!values.groupIds?.length && !errors.name && !errors.description) {
        setActivePage(4);
        setErrors({});
        setTouched({});
      }
      if (values.name && values.type === 3 && !!values.userIds?.length && !errors.name && !errors.description) {
        /** COMMENT OUT LOGIC USED FOR 5 HABIT MAX CHECK */
        /**
        let userIdsToCheck;
        if (modalType === 'edit-modal') {
          userIdsToCheck = findAddedUserIds(values.userIds);
        } else {
          userIdsToCheck = values.userIds;
        }
        if (userIdsToCheck?.length) {
          dispatch(check5HabitMaxCompliance(accountCode, userIdsToCheck, {
            onSuccess: (data) => {
              const dataForErrorModal = {
                usersWithAssignmentConflict: data,
                habitName: values.name,
              };
              if (data.length > 0) {
                dispatch(setErrorModalOpen(true, 'habitTracking-assignment-conflict', '', dataForErrorModal));
              } else {
                setActivePage(4);
              }
              setErrors({});
              setTouched({});
            },
            onError: (error) => {
              dispatch(setErrorModalOpen(true, 'general-error', error));
              setErrors({});
              setTouched({});
            },
          }));
        } else {
          setActivePage(4);
          setErrors({});
          setTouched({});
        }
        */
        setActivePage(4);
        setErrors({});
        setTouched({});
      }
    });
  };

  const CustomTextArea = ({
    // eslint-disable-next-line react/prop-types
    field,
    // eslint-disable-next-line react/prop-types
    form,
    ...props
  }) => <StyledTextArea {...field} {...props} />;

  const HeaderArrowBackButton = (activePageToSet, values, setTouched, setErrors, resetForm) => (
    <Text
      style={{
        position: 'fixed',
        top: '20px',
        left: '20px',
      }}
      onClick={() => {
        const { creationType } = values;
        if (activePage === 2 || activePage === 3) {
          resetForm();
        }

        if (activePage === 2) {
          setChosenPreset({});
        }
        if (activePage === 3 && creationType === 'create-new') {
          setActivePage(1);
        } else {
          setActivePage(activePageToSet);
        }
        setErrors({});
        setTouched({});
      }}
    >
      <IconHoverWrapper>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='left-arrow'
        />
      </IconHoverWrapper>
    </Text>
  );

  const ModalButton = ({
    // eslint-disable-next-line react/prop-types
    onClick, cta, customColor, customTextColor, type,
  }) => (
    <Button
      bottom
      fullWidth
      cta={cta}
      className='modal-button'
      large
      noBorder
      customColor={customColor || '#E0E0E0'}
      customTextColor={customTextColor || '#BDBDBD'}
      primary
      square
      onClick={onClick}
      upperCase={false}
      fontSize='20px'
      letterSpacing={false}
      fontWeight={700}
      /** noHover */
      type={type}
      disabled={isNewHabitLoading || isUpdateHabitLoading}
    />

  );

  const modifyValues = (values) => {
    const updatedValues = { ...values };

    delete updatedValues.creationType;
    delete updatedValues.modalType;

    updatedValues.cadence = parseInt(updatedValues.cadence, 10);
    updatedValues.frequency = updatedValues.cadence === 1 ? 1 : parseInt(updatedValues.frequency, 10);

    if (updatedValues.description === '' || updatedValues.description === null) {
      updatedValues.description = '';
    }

    if (!showDescriptionBox) {
      updatedValues.description = '';
    }

    if (updatedValues.type === 1) {
      delete updatedValues.groupIds;
      delete updatedValues.userIds;
    } else if (updatedValues.type === 2) {
      delete updatedValues.userIds;
    } else if (updatedValues.type === 3) {
      delete updatedValues.groupIds;
    }

    /** for edit modal only submit values that have changed */
    if (modalType === 'edit-modal' && updatedValues.type === editHabitInfo.type) {
      delete updatedValues.type;
    }
    if (modalType === 'edit-modal' && updatedValues?.userIds?.length === editHabitInfo?.users?.length && updatedValues?.userIds?.sort().every((value, index) => value === editHabitInfo?.users?.map((user) => user.id).sort()[index])) {
      delete updatedValues.userIds;
    }
    if (modalType === 'edit-modal' && updatedValues?.groupIds?.length === editHabitInfo?.groups?.length && updatedValues?.groupIds?.sort().every((value, index) => value === editHabitInfo?.groups?.map((group) => group.id).sort()[index])) {
      delete updatedValues.groupIds;
    }
    if (modalType === 'edit-modal' && updatedValues.name === editHabitInfo.name) {
      delete updatedValues.name;
    }
    if (modalType === 'edit-modal' && updatedValues.description === editHabitInfo?.description) {
      delete updatedValues.description;
    }
    if (modalType === 'edit-modal' && updatedValues.cadence === editHabitInfo.cadence) {
      delete updatedValues.cadence;
    }
    if (modalType === 'edit-modal' && updatedValues.frequency === editHabitInfo.frequency) {
      delete updatedValues.frequency;
    }
    if (modalType === 'edit-modal' && updatedValues.categoryId === editHabitInfo.category.id) {
      delete updatedValues.categoryId;
    }
    if (modalType === 'edit-modal' && updatedValues.active === editHabitInfo.active) {
      delete updatedValues.active;
    }

    return updatedValues;
  };

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 400,
      borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      boxShadow: state.isFocused ? 'none' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: state.isFocused ? 'rgba(207, 245, 232, 1)' : 'white',
      color: '#444444',
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'rgba(207, 245, 232, 1)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      border: '0px',
      maxWidth: `${formWrapperWidth > 400 ? '350px' : '100%'}`,
      minWidth: '250px',
      padding: '16px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9E9E9E',
    }),
  };

  const CreateHabitValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Habit name is required')
      .max(100, 'Habit name cannot exceed 100 characters') /** 100 character limit */
      .test('unique-name', 'Name already exists, choose a different name to start collecting data', (value) => {
        if (modalType === 'edit-modal') {
          return true; /** Skip unique name validation in edit mode */
        }
        return value && !habits.some((habit) => habit.name.toLowerCase() === value.toLowerCase());
      }),
    type: Yup.number().nullable().required('Habit assignment required').test('is-non-empty', 'Habit assignment required', (value) => value !== null && value !== undefined),
    groupIds: Yup.array().of(Yup.number()).when('type', {
      is: 2,
      then: Yup.array().min(1, 'Please select at least one group'),
    }),
    userIds: Yup.array().of(Yup.number()).when('type', {
      is: 3,
      then: Yup.array().min(1, 'Please select at least one user'),
    }),
    frequency: Yup.number().when('cadence', {
      is: (val) => val === 2 || val === '2',
      then: Yup.number()
        .nullable()
        .required('Frequency required (must be a number)')
        .typeError('Frequency must be a valid number')
        .test('is-positive', 'Frequency must be greater than or equal to 1', (value) => value >= 1)
        .test('is-less-than-7', 'Frequency must be less than or equal to 7', (value) => value <= 7),
      otherwise: Yup.number().nullable(),
    }),
    categoryId: Yup.number().nullable().required('Category required'),
    description: Yup.string()
      .notRequired()
      .max(255, 'Description cannot exceed 255 characters'),
  });

  return (
    <>
      {isSingleHabitLoading || isNewHabitLoading || isUpdateHabitLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (

        <Formik
          initialValues={
          modalType === 'edit-modal' && editHabitInfo ? (
            {
              name: editHabitInfo.name,
              description: editHabitInfo?.description,
              type: editHabitInfo.type, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
              cadence: editHabitInfo.cadence, /** Enum: 1 - weekly, 2 - daily */
              frequency: editHabitInfo.frequency, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
              categoryId: editHabitInfo.category.id,
              groupIds: editHabitInfo.groups.map((group) => group.id), /** array[integer] */
              userIds: editHabitInfo.users.map((user) => user.id), /** array[integer] */
              creationType: 'edit',
              modalType,
              active: editHabitInfo.active,
            }
          ) : (
            {
              name: '',
              description: '',
              type: null, /** Enum: 1 - All athletes, 2 - Groups, 3 - Individuals */
              cadence: 1, /** Enum: 2 - weekly, 1 - daily */
              frequency: null, /** Goal frequency to maintain. Daily must be 1, Weekly can be >=1 */
              categoryId: null,
              groupIds: [], /** array[integer] */
              userIds: [], /** array[integer] */
              creationType: 'create-new', /** template or create-new */
              modalType,
            }
          )
        }
          validationSchema={CreateHabitValidationSchema}
          onSubmit={(values, { setSubmitting }) => {
            const updatedValues = modifyValues(values);

            setSubmitting(true);

            if (modalType === 'edit-modal') {
              const habitId = editHabitInfo?.id;
              dispatch(editHabitForAccount(accountCode, habitId, updatedValues, {
                onSuccess: () => {
                  setSubmitting(false);
                  dispatch(setModalOpen(false, ''));
                  dispatch(fetchHabitsForAccount(accountCode));
                },
                onError: () => {
                  setSubmitting(false);
                  dispatch(setModalOpen(false, ''));
                  dispatch(setErrorModalOpen(true, 'general-error', 'Could not edit habit'));
                },
              }));
            } else {
              dispatch(createNewHabitForAccount(accountCode, updatedValues, {
                onSuccess: () => {
                  setSubmitting(false);
                  dispatch(setModalOpen(false, ''));
                  dispatch(fetchHabitsForAccount(accountCode));
                },
                onError: () => {
                  setSubmitting(false);
                  dispatch(setErrorModalOpen(true, 'general-error', 'Could not create habit'));
                },
              }));
            }
          }}
          render={({
            errors,
            touched,
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            handleBlur,
            setFieldTouched,
            setErrors,
            setTouched,
            validateForm,
            resetForm,
          }) => (
            <FormWrapper>
              <ResizeObserver
                onResize={(rect) => {
                  setFormWrapperWidth(rect?.width);
                }}
              />
              <StyledForm activePage={activePage} style={{ paddingBottom: activePage === 2 || activePage === 1 ? '60px' : '80px' }}>
                <IconHoverWrapper>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='remove'
                    style={{
                      position: 'fixed',
                      top: '20px',
                      right: '20px',
                    }}
                    onClick={() => {
                      dispatch(setModalOpen(false, ''));
                      // Amplitude tracking
                      dashboardTracker('Consistency Coach', modalType === 'edit-modal' ? 'Edit Habit Modal Closed' : 'Create Habit Modal Closed', 'User exited out of the modal');
                    }}
                  />
                </IconHoverWrapper>
                { activePage === 1 && (
                  <div style={{
                    display: 'flex', flexDirection: 'column', height: '100%',
                  }}
                  >
                    <PageContainer flexDirection='row' gap='40px'>
                      <TextAndPhotoContainer
                        onClick={() => {
                          setActivePage(3);
                          // Amplitude tracking
                          dashboardTracker('Consistency Coach', 'Create Habit Modal - New Habit', 'User selected create new habit');
                        }}
                      >
                        <PhotoContainer>
                          <img src={createHabitPNG} alt='new habit' className='photo' />
                        </PhotoContainer>
                        <TextContainer>
                          <Text className='title-fontsize' fontWeight={700}>Create new</Text>
                          <Text className='subtitle-fontsize' fontWeight={400}>Fully customize from start to finish</Text>
                        </TextContainer>
                      </TextAndPhotoContainer>
                      <TextAndPhotoContainer
                        onClick={() => {
                          setActivePage(2);
                          // Amplitude tracking
                          dashboardTracker('Consistency Coach', 'Create Habit Modal - Preset Habit', 'User selected choose from presets');
                        }}
                      >
                        <PhotoContainer>
                          <img src={templatePNG} alt='template' className='photo' />
                        </PhotoContainer>
                        <TextContainer>
                          <Text className='title-fontsize' fontWeight={700}>Choose from presets</Text>
                          <Text className='subtitle-fontsize' fontWeight={400}>Pre-made habits, customize as needed</Text>
                        </TextContainer>
                      </TextAndPhotoContainer>
                    </PageContainer>
                  </div>
                )}
                {activePage === 2 && (
                  <>
                    {HeaderArrowBackButton(1, values, setTouched, setErrors, resetForm)}
                    <FadeIn className='fade-in-100-width'>
                      <PageContainer>
                        <SearchablePresetSelectMenu
                          habitPresets={habitPresets}
                          chosenPreset={chosenPreset}
                          setChosenPreset={setChosenPreset}
                          setActivePage={setActivePage}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </PageContainer>
                    </FadeIn>
                  </>
                )}
                {activePage === 3 && (
                  <>
                    {isCheck5HabitMaxLoading ? (
                      <SpinnerWrapper>
                        <Spinner />
                      </SpinnerWrapper>
                    ) : (
                      <>
                        {modalType !== 'edit-modal' && (
                        <>
                          {HeaderArrowBackButton(2, values, setTouched, setErrors, resetForm)}
                        </>
                        )}
                        <FadeIn className='fade-in-100-width'>
                          <PageContainer>
                            <Input
                              error={!!touched.name && !!errors.name}
                              errorMsg={errors.name}
                              fontSize='16px'
                              fontWeight={400}
                              textColor='#444444'
                              height='38px'
                              label='Name'
                              name='name'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              right='5px'
                              styledPropsLabel={{
                                fontSize: '19px',
                                fontWeight: 700,
                              }}
                              top='43px'
                              value={values.name}
                              width='100%'
                              placeholder='ie. Get 10,000 steps'
                            />
                            <ToggleAndLableContainer>
                              <ToggleComponent
                                onChange={() => {
                                  setShowDescriptionBox(!showDescriptionBox);
                                  /** if prev state is showDescription box true
                                * we know it is being toggled to false
                                * and if false we want to clear the description value
                                */
                                  setWasToggled(true);
                                }}
                                defaultChecked={!!values.description}
                                onColor='#0AA36E'
                                offColor='#E0E0E0'
                                thumbColorOff='#0AA36E'
                                width='50px'
                                thumbBorderOff='4px solid #E0E0E0'
                                thumbBorderOn='4px solid #0AA36E'
                                hoverColor={showDescriptionBox ? '#0AA36E' : '#E0E0E0'}
                              />
                              <Text
                                fontSize='16px'
                                fontWeight={400}
                                color='#9E9E9E'
                              >
                                Description
                              </Text>
                            </ToggleAndLableContainer>
                            {wasToggled && showDescriptionBox ? (
                              <FadeIn className='fade-in-100-width'>
                                <Field
                                  onChange={handleChange}
                                  name='description'
                                  className='form-control'
                                  component={CustomTextArea}
                                  placeholder='Enter description for habit here'
                                  value={values.description}
                                />
                                {errors.description && (
                                  <Text color='#CC0404' fontSize='13px' fontWeight={700} style={{ marginTop: '5px' }}>{errors.description}</Text>
                                )}
                              </FadeIn>
                            ) : !wasToggled && showDescriptionBox ? (
                              <ErrorAndFieldContainer>
                                <Field
                                  onChange={handleChange}
                                  name='description'
                                  className='form-control'
                                  component={CustomTextArea}
                                  placeholder='Enter description for habit here'
                                  value={values.description}
                                />
                                {errors.description && (
                                <Text color='#CC0404' fontSize='13px' fontWeight={700} style={{ marginTop: '5px' }}>{errors.description}</Text>
                                )}
                              </ErrorAndFieldContainer>
                            ) : null}
                            <SelectBox
                              options={typeoptions}
                              defaultValue={typeoptions.find((option) => option.id === values.type)}
                              onChangeFunction={(option) => {
                                setFieldValue('type', parseInt(option.id, 10)); // Set the field value here
                              }}
                              onBlur={() => setFieldTouched('type', true)}
                              error={!!touched.type && !!errors.type}
                              errorMsg={errors.type}
                              placeholder='ie. All athletes'
                              title='Assigned to'
                              titleStyledProps={{ fontSize: '19px', fontWeight: 700 }}
                              customStyles={customSelectStyles}
                            />
                            {values.type === 2 && (
                            <SelectBox
                              options={groupOptions}
                              isMulti
                              defaultValue={groupOptions.filter((option) => values.groupIds.includes(option.id))}
                              onChangeFunction={(selectedOptions) => {
                              /** Extract group IDs from the selected options */
                                const selectedGroupIds = selectedOptions.map((option) => option.id);
                                /* Update the 'groupIds' field in the form */
                                setFieldValue('groupIds', selectedGroupIds);
                              }}
                              onBlur={() => setFieldTouched('groupIds', true)}
                              error={!!touched.groupIds && !!errors.groupIds}
                              errorMsg={errors.groupIds}
                              placeholder='Select group(s)'
                              customStyles={customSelectStyles}
                              isClearable={false}
                              closeMenuOnSelect={false}
                            />
                            )}
                            {values.type === 3 && (

                            <SelectBox
                              options={athleteOptions}
                              isMulti
                              defaultValue={athleteOptions.filter((option) => values.userIds.includes(option.id))}
                              onChangeFunction={(selectedOptions) => {
                              /** Extract user IDs from the selected options */
                                const selectedUserIds = selectedOptions.map((option) => option.id);
                                /* Update the 'userIds' field in the form */
                                setFieldValue('userIds', selectedUserIds);
                                setFieldTouched('userIds', true);
                              }}
                              onBlur={() => setFieldTouched('userIds', true)}
                              error={!!touched.userIds && !!errors.userIds}
                              errorMsg={errors.userIds}
                              placeholder='Select athlete(s)'
                              customStyles={customSelectStyles}
                              isClearable={false}
                              closeMenuOnSelect={false}
                            />
                            )}
                            {modalType === 'edit-modal' && (
                            <StatusContainer>
                              <Text fontSize='19px' fontWeight={700}>
                                Status of habit
                              </Text>
                              <Text fontSize='16px' color='#9E9E9E'>
                                {'When changing the status of a habit to \'inactive\', it will stop collecting data in the future by removing it from the athletes view. The archived data will remain accessible for future reference.'}
                              </Text>
                              <ToggleAndLableContainer>
                                <ToggleComponent
                                  onChange={() => {
                                    setFieldValue('active', !values.active);
                                  }}
                                  defaultChecked={!!values.active}
                                  onColor='#0AA36E'
                                  offColor='#E0E0E0'
                                  thumbColorOff='#0AA36E'
                                  width='50px'
                                  thumbBorderOff='4px solid #E0E0E0'
                                  thumbBorderOn='4px solid #0AA36E'
                                  hoverColor={values.active ? '#0AA36E' : '#E0E0E0'}
                                />
                                <Text
                                  fontSize='16px'
                                  fontWeight={400}
                                  color='#9E9E9E'
                                >
                                  {values.active ? 'Active' : 'Inactive'}
                                </Text>
                              </ToggleAndLableContainer>
                            </StatusContainer>
                            )}
                            <SliderNav>
                              <NavCircle
                                className={activePage === 3 ? 'active' : ''}
                                onClick={() => {
                                  setActivePage(3);
                                }}
                              />
                              <NavCircle
                                className={activePage === 4 ? 'active' : ''}
                                onClick={() => handleGoToFrequencyClick(validateForm, values, errors, setErrors, setTouched)}
                              />
                            </SliderNav>
                          </PageContainer>
                        </FadeIn>
                        <ModalButton
                          customColor={getModalButtonCustomColors(values, 3, errors)?.customColor}
                          customTextColor={getModalButtonCustomColors(values, 3, errors)?.customTextColor}
                          onClick={() => { handleGoToFrequencyClick(validateForm, values, errors, setErrors, setTouched); }}
                          cta='Go to frequency'
                        />
                      </>
                    )}
                  </>
                )}
                {activePage === 4 && (
                  <>
                    {HeaderArrowBackButton(3, values, setTouched, setErrors, resetForm)}
                    <FadeIn className='fade-in-100-width'>
                      <PageContainer>
                        <RadioGroupAndLabel>
                          <Text fontWeight={700} fontSize='19px' color={modalType === 'edit-modal' && editHabitInfo?.hasCompletions ? 'rgba(0, 0, 0, 0.38)' : '#444444'}>Cadence</Text>
                          {modalType === 'edit-modal' && editHabitInfo?.hasCompletions && (
                          <WarningContainer>
                            <IcomoonReact
                              iconSet={iconSet}
                              size={24}
                              icon='warning'
                            />
                            <Text fontWeight={700} fontSize='13px'>
                              Editing a habit frequency will alter any future data recorded. Set habit inactive and create a new habit to record new data frequency.
                            </Text>
                          </WarningContainer>
                          )}
                          <Text fontWeight={400} fontSize='11px' color={modalType === 'edit-modal' && editHabitInfo?.hasCompletions ? 'rgba(0, 0, 0, 0.38)' : '#444444'}>Every habit starts the week on Monday</Text>
                          <RadioGroup
                            row
                            value={String(values.cadence)}
                            onChange={(e) => setFieldValue('cadence', parseInt(e.target.value, 10))}
                            name='radio-buttons-group'
                          >
                            <FormControlLabel
                              value='1'
                              label='Daily'
                              disabled={modalType === 'edit-modal' && editHabitInfo?.hasCompletions}
                              control={<Radio color='default' />}
                            />
                            <FormControlLabel
                              value='2'
                              label='Weekly'
                              disabled={modalType === 'edit-modal' && editHabitInfo?.hasCompletions}
                              control={<Radio color='default' />}
                            />
                          </RadioGroup>
                        </RadioGroupAndLabel>
                        {values.cadence === 2 && (
                        <CounterInput
                          setFieldValue={setFieldValue}
                          error={!!touched.frequency && !!errors.frequency}
                          errorMsg={errors.frequency}
                          fontSize='16px'
                          fontWeight={400}
                          textColor='#444444'
                          height='38px'
                          label='Frequency'
                          name='frequency'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          right='5px'
                          styledPropsLabel={{
                            fontSize: '19px',
                            fontWeight: 700,
                          }}
                          top='43px'
                          value={values.frequency}
                          width='100%'
                          placeholder='ie. 3'
                          setFieldTouched={setFieldTouched}
                          modalType={modalType}
                          hasCompletions={editHabitInfo?.hasCompletions}
                        />
                        )}
                        <SelectBox
                          options={categoryOptions}
                          includeIconContainer
                          defaultValue={categoryOptions.find((option) => option.id === values.categoryId)}
                          onChangeFunction={(option) => {
                            setFieldValue('categoryId', parseInt(option.id, 10));
                          }}
                          onBlur={() => setFieldTouched('categoryId', true)}
                          error={!!touched.categoryId && !!errors.categoryId}
                          errorMsg={errors.categoryId}
                          placeholder='Select category'
                          title='Category'
                          titleStyledProps={{ fontSize: '19px', fontWeight: 700 }}
                          customStyles={customSelectStyles}
                        />
                        <MobileWidgetPreview currentleySelectedCategory={values.categoryId} categories={categories} />
                        <SliderNav>
                          <NavCircle
                            className={activePage === 3 ? 'active' : ''}
                            onClick={() => {
                              setActivePage(3);
                            }}
                          />
                          <NavCircle
                            className={activePage === 4 ? 'active' : ''}
                            disabled
                            onClick={() => null}
                            type={undefined}
                          />
                        </SliderNav>
                      </PageContainer>
                    </FadeIn>
                    <ModalButton
                      customColor={getSubmitButtonCustomColors(values, errors)?.customColor}
                      customTextColor={getSubmitButtonCustomColors(values, errors)?.customTextColor}
                      onClick={() => {
                        handleSubmit();
                      }}
                      cta='Save habit'
                      type='submit'
                    />
                  </>
                )}
              </StyledForm>
            </FormWrapper>
          )}
        />
      )}
    </>
  );
};

CreateHabitForm.propTypes = {
  initialPage: PropTypes.number,
  modalType: PropTypes.string,
};

CreateHabitForm.defaultProps = {
  initialPage: 1,
  modalType: 'create-modal',
};
export default CreateHabitForm;
