/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Dropzone from 'react-dropzone';
import Dropdown from 'react-bootstrap/Dropdown';
import SubText from './SubText/SubText';
import iconSet from '../images/teambuildr-selection.json';
import {
  updateProfileImage, updateLogoImage, removeProfileImage,
} from '../../modules/login/ducks/loginActions';
import { setSubActiveReduxModal } from '../../modules/profile/ducks/profileActions';
import ProfilePicSubModalHandler from './MediaUploadSubModalHandler';

const MediaDropdownContainer = styled('div')`
  display: flex;
  justify-content: center;
  .remove-button {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 30px;
    z-index: 100;
    top: -5px;
    background: #d6d6d6;
  }
`;
const DropDownMenuWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  &.giphy-dropdown {
    position: absolute;
    top: 0px;
    right: 20px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
  }  
  &.emoji-dropdown {
    position: absolute;
    top: 0px;
    right: 55px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
  }  
  .dropdown-container {
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);
    border: 0px;
    &.giphy {
      padding: 20px;
      z-index: 100;
    }
    &.emoji {
      padding: 0px;
      z-index: 100;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    transition: none;
    span {
      color: #444444;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0px;
      /* color: #444444; */
      font-size: 16px;
    }
    :hover {
      /* transition: linear 0s; */
      background: #0dcc8a;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0px;
    box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 15%);
  }
  .dropdown-divider {
    margin: 0px;
  }
  .add-gif-button {
    height: 32px;
    width: 32px;
    color: #444444;
    background-color: transparent;
    font-weight: 800;
    letter-spacing: -.5px;
    &:hover {
      opacity: .7;
      background-color: transparent;
    }
  }
  &.notification-dropdown {
    position: absolute;
    right: 15px;
  }
`;
const DropZoneWrapper = styled('div')`
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }
  .update-button {
    width: 180px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;
    &:hover {
      opacity: .7;
    }
  }
`;
const ProfilePicUploadButton = ({
  isLogo,
}) => {
  const UploadPhotoButton = styled('div')`
  border: 2px solid white;
  display: flex;
  maxHeight: 40px;
  maxWidth: 200px;
  padding: 10px;
  background-color: #E5E5E5;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #ededed;
  }
`;
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;

  // eslint-disable-next-line react/prop-types
  const AddMediaToggle = React.forwardRef(({ children, onClick }, ref) => (
    <UploadPhotoButton
      href=''
      icon='plus'
      small
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
      <IcomoonReact
        iconSet={iconSet}
        size={28}
        icon='pencil'
        color='#444444'
      />
    </UploadPhotoButton>
  ));

  const handleDroppedFile = (acceptedFile) => {
    const type = 'image';
    if (isLogo) {
      dispatch(updateLogoImage(accountCode, { acceptedFile, type }));
    } else {
      dispatch(updateProfileImage({ acceptedFile, type }));
    }
  };

  const addSavedMedia = () => (
    <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
      {({ getRootProps, getInputProps }) => (
        <DropZoneWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <UploadPhotoButton>
            <IcomoonReact
              iconSet={iconSet}
              size={28}
              icon='pencil'
              color='#444444'
            />
          </UploadPhotoButton>
        </DropZoneWrapper>
      )}
    </Dropzone>
  );

  const fileAccepterSwitch = () => (
    <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
      {({ getRootProps, getInputProps }) => (
        <DropZoneWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <Dropdown.Item>
            <>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='attachment'
              />
              <SubText
                className='drop-icon-text'
                fontSize={14}
              >
                Add Saved Media
              </SubText>
            </>
          </Dropdown.Item>
        </DropZoneWrapper>
      )}
    </Dropzone>
  );

  const addMediaDropdown = () => (
    <DropDownMenuWrapper>
      <Dropdown
        bsPrefix='trick-fix'
      >
        <Dropdown.Toggle as={AddMediaToggle} />
        <Dropdown.Menu className='dropdown-container emoji'>
          <Dropdown.Item
            className='dropdown-item'
            onClick={() => dispatch(setSubActiveReduxModal('record-image-modal'))}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='camera'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Add Live Photo
            </SubText>
          </Dropdown.Item>
          {fileAccepterSwitch(dispatch, handleDroppedFile)}
          <Dropdown.Item
            className='dropdown-item'
            onClick={() => dispatch(removeProfileImage())}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='opt-out'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Remove Image
            </SubText>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </DropDownMenuWrapper>
  );

  return (
    <>
      <MediaDropdownContainer>
        {isLogo ? addSavedMedia() : addMediaDropdown()}
      </MediaDropdownContainer>
      <ProfilePicSubModalHandler isLogo={isLogo} />
    </>
  );
};

ProfilePicUploadButton.propTypes = {
  isLogo: PropTypes.bool,
};

ProfilePicUploadButton.defaultProps = {
  isLogo: false,
};
export default ProfilePicUploadButton;
