/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import InstantPaymentForm from './InstantPaymentForm';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  align-items: center;
  animation: animatebottom 0.2s;
  flex-direction: column;
  justify-content: space-between;
  transition: all 1s ease;
  @media screen and (min-width: 540px) {
    min-width: 466px;
  };
  @media screen and (max-width: 540px) {
    border-radius: 0px;
    width: 100%;
    height: 100%; 
    overflow: none;
    margin-top: 49px;
  };
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  @media screen and (max-height: 700px) {
    height: 90vh;
  }  
  max-height: 750px;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  margin-bottom: 85px;
  margin-top: 120px;
  box-shadow: ${BoxShadow};
  border-radius: 12px;
  scroll-behavior: smooth;
  @media screen and (max-width: 540px) {
    height: 100%; 
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 0px;
  };
`;

const InstantPaymentFormModal = ({
  setActiveSlide,
  activeSlide,
}) => {
  const isInstantPaymentModalShowing = useSelector(
    (state) => state.sportsScience.data.isInstantPaymentModalShowing,
  );

  const firstModalContainerRef = useRef();

  return (
    <ModalContainer isActive={isInstantPaymentModalShowing}>
      <ContentContainer ref={firstModalContainerRef}>
        <InstantPaymentForm
          setActiveSlide={setActiveSlide}
          activeSlide={activeSlide}
        />
      </ContentContainer>
    </ModalContainer>
  );
};

InstantPaymentFormModal.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default InstantPaymentFormModal;
