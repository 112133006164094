// authored by Joshua Beedle
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import Wrapper from './Wrapper';
import { BoxShadow } from '../../GlobalStyles';
import Text from '../Text';
import iconSet from '../../images/teambuildr-selection.json';

const DateSelectorContainer = styled('div')`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  font-family: 'Nunito Sans';
  justify-content: center;

  .date-picker {
    border: none;
    font-weight: 400;
    height: 40px;
    width: 100%;
  }
  .react-datepicker {
    border: none;
    box-shadow: ${BoxShadow};
  }
  .react-datepicker__day--inside-month {
    color: #444444;
  }
  .react-datepicker__day--keyboard-selected {
    background: none;
    color: #444444;
  }
  .react-datepicker__day--outside-month {
    color: #BDBDBD;
  }
  .react-datepicker__day--selected {
    background-color: #FF6600;
    color: white;
  }
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #FF6600;
    color: white;
  }
  .react-datepicker__header {
    background: white;
    border-bottom: none;
  }
  .react-datepicker__month-container {
    font-family: 'Nunito Sans';
    font-size: 15px;
    padding: 15px;
  }
  .react-datepicker__day-names, .react-datepicker__week {
    margin-bottom: 15px;
  }
  .react-datepicker-popper {
    z-index: 3;
  } 
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    display: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
`;

const CustomButton = styled('button')`
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
  min-height: 39px;
  min-width: 100px;
`;

const TextContainer2 = styled('div')`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
`;

const TextAndPickerRow = styled('div')`
  align-items: center;
  border: ${(props) => (!props.disabled ? '#979797 solid 1px' : '#E0E0E0 solid 1px')};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: 39px;
  padding-left: 12px;
  padding-right: 12px;
  width: fit-content;
`;

/**
 *
 * @param {Bool} disabled boolean to determine whether or not the picker is disabled
 * @param {Date} endDate selected end date in the picker
 * @param {String} placeholderText text to display before date selected
 * @param {Function} setEndDate function to select the end date
 * @param {Function} setStartDate function to select the start date
 * @param {Bool} singleDate boolean to determine if it is single date or range
 * @param {Date} startDate selected start date in the picker
 * @param {Object} styledWrapperProps custom props for Wrapper
 *
*/

const SharedDatePicker = ({
  disabled,
  endDate,
  placeholderText,
  setEndDate,
  setStartDate,
  singleDate,
  startDate,
  styledWrapperProps,
}) => {
  // eslint-disable-next-line react/prop-types
  const CustomStartDateButton = forwardRef(({ value, onClick }, ref) => (
    <CustomButton disabled={disabled} type='button' onClick={onClick} ref={ref}>
      <Text styledTextProps={{ color: '#979797', fontSize: 16 }}>{value || placeholderText}</Text>
    </CustomButton>
  ));

  // eslint-disable-next-line react/prop-types
  const CustomEndDateButton = forwardRef(({ value, onClick }, ref) => (
    <CustomButton disabled={disabled} type='button' onClick={onClick} ref={ref}>
      <Text styledTextProps={{ color: '#979797', fontSize: 16 }}>{value || 'End Date'}</Text>
    </CustomButton>
  ));

  return (
    singleDate ? (
      <Wrapper {...styledWrapperProps}>
        <TextAndPickerRow disabled={disabled}>
          <DateSelectorContainer>
            <DatePicker
              name='shared-date-picker'
              title=''
              className='date-picker'
              dateFormat='MMM dd, yyyy'
              placeholderText={placeholderText}
              selected={startDate}
              popperPlacement='top-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
              maxDate={moment().toDate()}
              onChange={(date) => {
                setStartDate(date);
              }}
              customInput={<CustomStartDateButton placeholderText={placeholderText} />}
              startDate={startDate}
              endDate={endDate}
              renderCustomHeader={({
                decreaseMonth,
                increaseMonth,
                date,
              }) => (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
                >
                  <button
                    type='button'
                    onClick={decreaseMonth}
                    style={{
                      border: 'none',
                      marginLeft: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='left-arrow'
                      color='#FF6600'
                    />
                  </button>
                  <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Nunito Sans',
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                  >
                    {date?.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  <button
                    type='button'
                    onClick={increaseMonth}
                    style={{
                      border: 'none',
                      marginRight: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='right-arrow'
                      color='#FF6600'
                    />
                  </button>
                </div>
              )}
            />
          </DateSelectorContainer>
        </TextAndPickerRow>
      </Wrapper>
    ) : (
      <Wrapper {...styledWrapperProps}>
        <TextAndPickerRow disabled={disabled}>
          <DateSelectorContainer>
            <DatePicker
              name='shared-date-picker-start-date'
              title=''
              className='date-picker'
              dateFormat='MMM dd, yyyy'
              placeholderText='Start Date'
              selected={startDate}
              popperPlacement='top-start'
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                },
              }}
              maxDate={moment().toDate()}
              onChange={(date) => {
                setStartDate(date);
              }}
              customInput={<CustomStartDateButton placeholderText={placeholderText} />}
              startDate={startDate}
              endDate={endDate}
              selectsStart='multiDay'
              renderCustomHeader={({
                decreaseMonth,
                increaseMonth,
                date,
              }) => (
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                }}
                >
                  <button
                    type='button'
                    onClick={decreaseMonth}
                    style={{
                      border: 'none',
                      marginLeft: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='left-arrow'
                      color='#FF6600'
                    />
                  </button>
                  <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontFamily: 'Nunito Sans',
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                  >
                    {date?.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  <button
                    type='button'
                    onClick={increaseMonth}
                    style={{
                      border: 'none',
                      marginRight: '10px',
                      background: 'rgba(95, 96, 100, 0.05)',
                      borderRadius: '5px',
                      width: '30px',
                      height: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={15}
                      icon='right-arrow'
                      color='#FF6600'
                    />
                  </button>
                </div>
              )}
            />
          </DateSelectorContainer>
          <>
            <TextContainer2>
              <IcomoonReact
                iconSet={iconSet}
                size={14}
                icon='alt-right-arrow'
                color='#979797'
              />
            </TextContainer2>
            <DateSelectorContainer>
              <DatePicker
                name='shared-date-picker-end-date'
                className='date-picker'
                dateFormat='MMM dd, yyyy'
                placeholderText='End Date'
                selected={endDate}
                popperPlacement='top-start'
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                  },
                }}
                maxDate={moment().toDate()}
                onChange={(date) => {
                  setEndDate(date);
                }}
                customInput={<CustomEndDateButton />}
                startDate={startDate}
                endDate={endDate}
                selectsEnd
                minDate={startDate}
                renderCustomHeader={({
                  decreaseMonth,
                  increaseMonth,
                  date,
                }) => (
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                  >
                    <button
                      type='button'
                      onClick={decreaseMonth}
                      style={{
                        border: 'none',
                        marginLeft: '10px',
                        background: 'rgba(95, 96, 100, 0.05)',
                        borderRadius: '5px',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={15}
                        icon='left-arrow'
                        color='#FF6600'
                      />
                    </button>
                    <span style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontFamily: 'Nunito Sans',
                      fontWeight: '700',
                      fontSize: '16px',
                    }}
                    >
                      {date?.toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </span>
                    <button
                      type='button'
                      onClick={increaseMonth}
                      style={{
                        border: 'none',
                        marginRight: '10px',
                        background: 'rgba(95, 96, 100, 0.05)',
                        borderRadius: '5px',
                        width: '30px',
                        height: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={15}
                        icon='right-arrow'
                        color='#FF6600'
                      />
                    </button>
                  </div>
                )}
              />
            </DateSelectorContainer>
          </>
        </TextAndPickerRow>
      </Wrapper>
    )
  );
};

SharedDatePicker.propTypes = {
  disabled: PropTypes.bool,
  endDate: PropTypes.instanceOf(Date),
  placeholderText: PropTypes.string,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func.isRequired,
  singleDate: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  styledWrapperProps: PropTypes.instanceOf(Object),
};

SharedDatePicker.defaultProps = {
  disabled: false,
  endDate: null,
  placeholderText: 'Select Date',
  setEndDate: null,
  singleDate: true,
  startDate: null,
  styledWrapperProps: {},
};

export default SharedDatePicker;
