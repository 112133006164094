import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ArrowButton from './ButtonArrow';

const EntryIcons = ({
  attributes,
  exerType,
  isHealthEntry,
  isMainWorkoutEntry,
  isNoteEntry,
  modalOpen,
}) => (
  <div className={`entry_icons ${exerType === 'N' ? 'note_icons' : ''}`}>
    {isMainWorkoutEntry && (
      <ArrowButton
        handleArrowClick={modalOpen}
        dataFirst='opt-out'
        icon='opt-out'
      />
    )}
    {!isNoteEntry && (
      <ArrowButton
        handleArrowClick={modalOpen}
        dataFirst='note'
        icon='note'
      />
    )}
    {!isHealthEntry && (
      <>
        {attributes.documents.length > 0 && (
          <ArrowButton
            handleArrowClick={modalOpen}
            dataFirst='attachment'
            icon='attachment'
          />
        )}
        {attributes.exercise.media ? (
          <ArrowButton
            handleArrowClick={modalOpen}
            dataFirst='video'
            icon='video'
          />
        ) : ''}
        {attributes.exercise.description && (
          <>
            {attributes.exercise.description !== '' && (
              <ArrowButton
                handleArrowClick={modalOpen}
                dataFirst='info'
                icon='info'
              />
            )}
          </>
        )}
      </>
    )}

    {(isMainWorkoutEntry || isHealthEntry) && (
      <ArrowButton
        handleArrowClick={modalOpen}
        dataFirst='history'
        icon='clock'
      />
    )}
  </div>
);

EntryIcons.propTypes = {
  attributes: PropTypes.instanceOf(Object).isRequired,
  exerType: PropTypes.string.isRequired,
  isHealthEntry: PropTypes.bool.isRequired,
  isMainWorkoutEntry: PropTypes.bool,
  isNoteEntry: PropTypes.bool.isRequired,
  modalOpen: PropTypes.func.isRequired,
};

EntryIcons.defaultProps = {
  isMainWorkoutEntry: false,
};

export default memo(EntryIcons);
