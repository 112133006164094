/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';
import MaxesAndPRCard from './maxesAndPRCard';
import Text from '../../../../shared/components/Text/Text';

const NewMaxesAndPRsPage = () => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(0);
  const [headerContainerWidth, setHeaderContainerWidth] = useState(0);
  const maxesArray = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.maxes?.maxes);
  const newMaxes = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.maxes?.newMaxes);
  const athlete = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview.user);

  const PageWrapper = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  `;
  const CardContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 840px;
    gap: ${(props) => (props.containerWidth && props.containerWidth < 540 ? '20px' : '40px')};
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
  `;
  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    border: 1px solid #F2F2F2;
  `;
  const CardRowWrapper = styled('div')`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #FDFBFB;
    padding-top: 40px;
    padding-bottom: 40px;
  `;
  const RibbonContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px;
    text-align: center;
  `;
  // const BadgesContainer = styled('div')`
  //   display: flex;
  //   flex: 1;
  //   justify-content: center;
  //   align-items: center;
  //   border-left: 1px solid #F2F2F2;
  //   margin-top: 40px;
  //   margin-bottom: 40px;
  // `;

  const IconContainer = styled('div')`
    height: 54px;
    width: 54px;
    background-color: #F3F3F3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  const NumberContainer = styled('div')`
    min-height: 70px;
    min-width: 70px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    padding-left: 10px;
    padding-right: 10px;
  `;
  const IncreaseContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  `;

  return (
    <PageWrapper>
      <HeaderContainer>
        <ResizeObserver
          onResize={(rect) => {
            setHeaderContainerWidth(rect?.width);
          }}
        />
        <RibbonContainer>
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={40}
              icon='ribbon'
              color={theme.colors.orange}
            />
          </IconContainer>
          <Text fontSize='16px' fontWeight={600}>New Maxes/ PRs:</Text>
          <NumberContainer>
            <Text fontSize='64px' fontWeight={800}>{newMaxes?.currentWeek}</Text>
          </NumberContainer>
          {!maxesArray?.length ? (
            <Text fontSize={headerContainerWidth < 540 ? '20px' : '32px'} fontWeight={800}>No New Maxes/ PRs This Week</Text>
          ) : (
            <Text fontSize={headerContainerWidth < 540 ? '20px' : '32px'} fontWeight={800}>Great Work This Week!</Text>
          )}
          <Text fontSize='16px' fontWeight={600}>
            {`${athlete?.first} logged ${newMaxes?.currentWeek} new maxes/PRs this week.`}
          </Text>
          <IncreaseContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='play-filled'
              color={newMaxes?.increase ? theme.colors.green : newMaxes?.difference === 0 ? theme.colors.yellow : '#FF3737'}
              style={{ transform: newMaxes?.increase ? 'rotate(-90deg)' : newMaxes?.difference === 0 ? 'rotate(-0deg)' : 'rotate(90deg)' }}
            />
            {newMaxes?.increase ? (
              <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                Increase of
                {' '}
                <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${newMaxes?.difference}`}</span>
                {' '}
                from previous week
              </Text>
            ) : (
              <>
                {newMaxes?.difference === 0 ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                    No change from previous week
                  </Text>

                ) : (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                    decrease of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(newMaxes?.difference)}</span>
                    {' '}
                    from previous week
                  </Text>
                )}
              </>
            )}
          </IncreaseContainer>
        </RibbonContainer>
        {/* {containerWidth > 737 && (
        <BadgesContainer>
          <Text>BADGES HERE</Text>
        </BadgesContainer>
        )} */}
      </HeaderContainer>
      {maxesArray?.length ? (
        <CardRowWrapper>
          <CardContainer containerWidth={containerWidth}>
            <ResizeObserver
              onResize={(rect) => {
                setContainerWidth(rect?.width);
              }}
            />
            {maxesArray?.map((maxObject) => (
              <MaxesAndPRCard maxObject={maxObject} key={generateUniqueId()} containerWidth={containerWidth} small={containerWidth < 520} />
            ))}
          </CardContainer>
        </CardRowWrapper>
      ) : (
        null
      )}
    </PageWrapper>
  );
};
export default NewMaxesAndPRsPage;
