/**
 * FilterDropdown is a shared component that acts as a portable dropdown list.  It accepts a
 * list of Filter components, though these are not necessary.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../images/teambuildr-selection.json';
import Button from '../Button/Button';

const Container = styled('div')`
  width: 376px;
  background: white;
  position: absolute;
  margin-left: 60px;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: hidden;
  z-index: 102;
  @media only screen and (max-width : 768px) {
    left: calc(50% - 188px);
    margin-left: 0;
  }
`;

const HeaderContainer = styled('div')`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #E0E0E0;
`;

const HeaderActionContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  top: 0;
  position: absolute;
  left: 0;
  z-index: 101;
`;

const ButtonDiv = styled('div')`
  min-height: 48px;
`;

const HeaderText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #444444;
`;

const HeaderActionButton = styled('button')`
  margin-left: 5px;
  width: 68px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #5F8BDC;
  border: none;
`;

/**
 * @param {Boolean} isShowing boolean that dictates whether the FilterDropdown is
 * rendered
 * @param {String} headerActionButtonText text associated with the header action button
 * @param {Function} headerAction function associated with the header action button
 * @param {String} headerActionIcon icon associated with the header action button
 * @param {String} filterHEaderText text of the header, describing the nature of the dropdown
 * @param {NodeList} children interior content of the FilterDropdown, such as a list of
 * Filter components
 * @param {Function} filterSubmitAction function that executes when the submit button is pressed
 * @param {String} submitActionText text with the submit button
 * @returns
 */

const FilterDropdown = ({
  isShowing,
  headerActionButtonText,
  headerAction,
  headerActionIcon,
  filterHeaderText,
  children,
  filterSubmitAction,
  closeFilterDropdown,
  submitActionText,
}) => {
  if (isShowing) {
    return (
      <>
        <Container>
          <HeaderContainer>
            <HeaderText>
              {filterHeaderText}
            </HeaderText>
            <HeaderActionContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon={headerActionIcon}
                color='#5F8BDC'
              />
              <HeaderActionButton
                onClick={() => headerAction()}
              >
                {headerActionButtonText}
              </HeaderActionButton>
            </HeaderActionContainer>
          </HeaderContainer>
          {children}
          <ButtonDiv>
            <Button
              bottom
              fullWidth
              cta={submitActionText}
              customColor='#0DCC8A'
              large
              noBorder
              primary
              square
              noHover
              onClick={() => filterSubmitAction()}
            />
          </ButtonDiv>
        </Container>
        {isShowing ? <Overlay onClick={() => closeFilterDropdown()} /> : null}
      </>
    );
  }
  return null;
};

FilterDropdown.defaultProps = {

};

FilterDropdown.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  headerAction: PropTypes.func.isRequired,
  headerActionButtonText: PropTypes.string.isRequired,
  headerActionIcon: PropTypes.string.isRequired,
  filterHeaderText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  filterSubmitAction: PropTypes.func.isRequired,
  closeFilterDropdown: PropTypes.func.isRequired,
  submitActionText: PropTypes.string.isRequired,
};

export default FilterDropdown;
