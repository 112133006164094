import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';

import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import SubText from '../../../shared/components/SubText/SubText';
import WeightRoomImage from '../../../shared/images/weightroom-white-transparent.png';

const Container = styled('div')`
  display: flex;
  width: 700px;
  padding-top: 100px;
  gap: 20px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  .test {
    display: grid;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const DetailsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 768px) {
    justify-content: center;
    text-align: center;
    padding: 0 15px;
    > div {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
      color: white;
    }
    padding-bottom: 0px;
  }
`;

const ImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-right: 25px;
  img {
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NameContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    text-transform: uppercase;
    font-size: 26px;
  }
`;

const DescriptionWrapper = styled('div')`
  height: auto;
`;

const SharedWorkoutNotFound = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoaded(true);
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {pageLoaded && alert('Requested workout does not exist')}
      <Container>
        <DetailsContainer>
          <ImageWrapper>
            <img src={WeightRoomImage} alt='Weight Room' />
          </ImageWrapper>
          <NameContainer>
            <HeaderText>
              <h1>No Workout Found</h1>
            </HeaderText>
            <DescriptionWrapper>
              <SubText>About</SubText>
              <Text>Invalid shared workout url. Please try again.</Text>
            </DescriptionWrapper>
          </NameContainer>
        </DetailsContainer>
      </Container>
    </>
  );
};

export default SharedWorkoutNotFound;
