import moment from 'moment';

import {
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_DAY_RANGE,
  SET_END_DATE_CAL,
  SET_END_DATE_DATA,
  SET_GROUP,
  SET_SELECTED_ATHLETE_GROUP,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_START_DATE_CAL,
  SET_START_DATE_DATA,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  SET_FLAGGED_ATHLETES,
  SET_FLAGGED_ATHLETES_GROUP,
  SET_FLAGGED_ATHLETES_PERFORMANCE,
} from './actionTypes';

const initialState = {
  dayRange: 7,
  endDateCal: moment(),
  endDateData: moment().format('YYYY-MM-DD'),
  flaggedAthletesData: [],
  flaggedAthletesDataGroup: [],
  flaggedAthletesDataPerformance: [],
  groupAnalysisAthleteData: [],
  groupAnalysisAverageData: [],
  groupFilterRadio: 'all',
  group: null,
  groups: [],
  metricCardData: [],
  metricCardError: null,
  metricFiltersGroup: ['PeakPower'],
  metricFiltersPerformance: ['PeakPower'],
  performanceOverviewData: [],
  performanceOverviewError: null,
  selectedAthleteGroup: null,
  selectedAthletePerformance: null,
  startDateCal: moment().subtract(6, 'days'),
  startDateData: moment().subtract(6, 'days').format('YYYY-MM-DD'),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groups: [],
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        performanceOverviewData: action.payload,
        performanceOverviewError: null,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_REJECTED: {
      return {
        ...state,
        performanceOverviewData: [],
        flaggedAthletesDataPerformance: [],
        performanceOverviewError: action.payload,
      };
    }
    case FETCH_METRIC_CARD_DATA_FULFILLED: {
      return {
        ...state,
        metricCardData: action.payload,
        metricCardError: null,
      };
    }
    case FETCH_METRIC_CARD_DATA_REJECTED: {
      return {
        ...state,
        metricCardData: [],
        metricCardError: action.payload,
      };
    }
    case FETCH_GROUP_ANALYSIS_FULFILLED: {
      return {
        ...state,
        groupAnalysisAthleteData: action.payload.athlete,
        groupAnalysisAverageData: action.payload.average,
        flaggedAthletesDataGroup: action.payload.flagged,
        groupAnalysisError: null,
      };
    }
    case FETCH_GROUP_ANALYSIS_REJECTED: {
      return {
        ...state,
        groupAnalysisAthleteData: [],
        groupAnalysisAverageData: [],
        flaggedAthletesDataGroup: [],
        groupAnalysisError: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        selectedAthletePerformance: action.payload.athlete,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        selectedAthleteGroup: action.payload.athlete,
      };
    }
    case SET_METRIC_FILTERS_PERFORMANCE: {
      return {
        ...state,
        metricFiltersPerformance: action.payload,
      };
    }
    case SET_METRIC_FILTERS_GROUP: {
      return {
        ...state,
        metricFiltersGroup: action.payload,
      };
    }
    case SET_GROUP_FILTER_RADIO: {
      return {
        ...state,
        groupFilterRadio: action.payload,
      };
    }
    case SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_START_DATE_CAL: {
      return {
        ...state,
        startDateCal: action.payload,
      };
    }
    case SET_START_DATE_DATA: {
      return {
        ...state,
        startDateData: action.payload,
      };
    }
    case SET_END_DATE_CAL: {
      return {
        ...state,
        endDateCal: action.payload,
      };
    }
    case SET_END_DATE_DATA: {
      return {
        ...state,
        endDateData: action.payload,
      };
    }
    case SET_DAY_RANGE: {
      return {
        ...state,
        dayRange: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES: {
      return {
        ...state,
        flaggedAthletesData: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_GROUP: {
      return {
        ...state,
        flaggedAthletesDataGroup: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_PERFORMANCE: {
      return {
        ...state,
        flaggedAthletesDataPerformance: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
