import axios from 'axios';
import axiosCancel from 'axios-cancel';
import moment from 'moment';
import groupBy from 'lodash.groupby';
import { tz } from 'moment-timezone';

import {
  SELECT_ATHLETE,
  SELECT_VERIFIED_ATHLETE,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_REJECTED,
  FETCH_WORKOUTS_START,
  FETCH_WORKOUT_FULFILLED,
  FETCH_WORKOUT_REJECTED,
  FETCH_WORKOUT_START,
  SELECT_GROUP,
  SELECT_WORKOUT,
  SEARCH_ATHLETE,
  CLOSE_PANEL,
  SELECT_DATE,
  NEXT_WORKOUT,
  PREV_WORKOUT,
  CLOSE_WORKOUT,
  COMPLETE_WORKOUT,
  OPEN_MODAL,
  CLOSE_MODAL,
  FETCH_HISTORY_START,
  FETCH_HISTORY_FULFILLED,
  FETCH_HISTORY_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_REJECTED,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_SIM_EXERCISES_START,
  FETCH_SIM_EXERCISES_FULFILLED,
  FETCH_SIM_EXERCISES_REJECTED,
  FETCH_SIM_TYPE_EXERCISES_START,
  FETCH_SIM_TYPE_EXERCISES_FULFILLED,
  FETCH_SIM_TYPE_EXERCISES_REJECTED,
  UPDATE_WORKOUTS_START,
  UPDATE_WORKOUTS_FULFILLED,
  UPDATE_WORKOUTS_REJECTED,
  UPDATE_SUBSTITUTE_WORKOUT,
  SELECT_QUESTION,
  NEXT_QUESTION,
  PREV_QUESTION,
  RESTART_QUESTIONS,
  UPDATE_JOURNAL_START,
  UPDATE_JOURNAL_FULFILLED,
  UPDATE_JOURNAL_REJECTED,
  STORE_PANEL_HEIGHT,
  FETCH_CALENDAR_START,
  FETCH_CALENDAR_FULFILLED,
  FETCH_CALENDAR_REJECTED,
  FETCH_JOURNAL_START,
  FETCH_JOURNAL_FULFILLED,
  FETCH_JOURNAL_REJECTED,
  SUBMIT_PIN_START,
  SUBMIT_PIN_FULFILLED,
  SUBMIT_PIN_REJECTED,
  OPEN_PINPAD,
  CLOSE_PINPAD,
  CANCEL_PINPAD,
  STORE_FORMIK_VALUES,
  STORE_WORKOUTS_BY_SUPERSET,
  INCREASE_COMPLETED_SETS,
  DECREASE_COMPLETED_SETS,
  SET_IS_NAVIGATING_SUPERSETS,
} from './actionTypes';

import '../../../shared/utils/setCommonHeaders';

axiosCancel(axios, {
  debug: false, // default
});

export const fetchWorkoutsFulfilled = (workout, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${FETCH_WORKOUTS_FULFILLED}`,
      payload: workout,
    });
  }
);

export const fetchWorkouts = (id, namespace, date) => (
  (dispatch, getState) => {
    const formattedDate = date.format('YYYY-MM-DD');
    dispatch({ type: `${namespace}/${FETCH_WORKOUTS_START}` });
    axios.get(`/workouts?date=${formattedDate}&user_id=${id}`, {
      requestId: `${namespace}/${fetchWorkouts}`,
    })
      .then((response) => {
        const wrvSupersetsEnabled = getState().weightRoom.currentUser.settings.wrv_supersets_enabled;
        // const wrvSupersetsEnabled = true;

        setTimeout(() => {
          dispatch({ type: `${namespace}/${FETCH_WORKOUTS_FULFILLED}`, payload: response.data });
        }, 800);

        let workoutsWithSupersets = response.data.result.dates[0].items.filter(workout => (
          workout.grouping !== null
        ));

        if (workoutsWithSupersets.length > 0) {
          workoutsWithSupersets = workoutsWithSupersets.map(workout => ({
            ...workout, completed_sets: 0,
          }));

          const workoutsBySupersets = groupBy(workoutsWithSupersets, 'grouping');
          console.log('You got some supersets', workoutsWithSupersets, workoutsBySupersets);

          dispatch({
            type: `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`,
            payload: workoutsBySupersets,
          });
        } else {
          console.log('Looks like you have no supersets', workoutsWithSupersets);
        }
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Fetch Workouts Cancelled');
        } else {
          dispatch({ type: `${namespace}/${FETCH_WORKOUTS_REJECTED}`, payload: thrown.response });
        }
      });
  }
);

export const fetchWorkout = (athleteId, date, index, namespace) => (
  (dispatch) => {
    const formattedDate = date.format('YYYY-MM-DD');
    dispatch({ type: `${namespace}/${FETCH_WORKOUT_START}` });
    axios.get(`/workouts?date=${formattedDate}&user_id=${athleteId}`, {
      requestId: `${namespace}/${fetchWorkouts}`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${FETCH_WORKOUT_FULFILLED}`,
          payload: {
            index,
            data: response.data.result.dates[0].items[index],
          },
        });
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Fetch Workouts Cancelled');
        } else {
          console.log('Other Reason');
        }
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_WORKOUT_REJECTED}`, payload: err });
      });
  }
);

export const fetchWorkoutCalendar = (athleteId, namespace, startDate, endDate) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_CALENDAR_START}` });
    axios.get(`/workouts/info?start=${startDate}&end=${endDate}&user_id=${athleteId}`, {
      requestId: `${namespace}/${fetchWorkoutCalendar}`,
    })
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_CALENDAR_FULFILLED}`, payload: response.data });
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Fetch Calendar Cancelled');
        } else {
          console.log('Other Reason');
        }
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_CALENDAR_REJECTED}`, payload: err });
      });
  }
);

export const selectAthlete = (athlete, id, avatar, namespace) => (
  (dispatch, getState) => {
    const today = moment().tz(getState().weightRoom.currentUser.settings.time_zone);
    dispatch({
      type: `${namespace}/${SELECT_ATHLETE}`,
      payload: {
        athlete, id, avatar, namespace, today,
      },
    });
    dispatch(fetchWorkouts(id, namespace, today));
  }
);

export const closePanel = namespace => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${CLOSE_PANEL}`,
      payload: {
        namespace,
      },
    });
  }
);

export const closeWorkout = namespace => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${CLOSE_WORKOUT}`,
      payload: {
        namespace,
      },
    });
  }
);

export const selectWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SELECT_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const nextWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${NEXT_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const prevWorkout = (id, index, exerId, exerType, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${PREV_WORKOUT}`,
      payload: {
        id, index, exerId, exerType,
      },
    });
  }
);

export const selectDate = (date, id, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SELECT_DATE}`,
      payload: date,
    });
    dispatch(fetchWorkouts(id, namespace, date));
  }
);

export const searchAthlete = (athlete, namespace) => (
  (dispatch) => {
    dispatch({
      type: `${namespace}/${SEARCH_ATHLETE}`,
      payload: athlete,
    });
  }
);

// export const selectGroup = (group, namespace) => (
//   (dispatch) => {
//     dispatch({
//       type: `${namespace}/${SELECT_GROUP}`,
//       payload: group,
//     });
//   }
// );

export const openModal = (namespace, modal) => ({
  type: `${namespace}/${OPEN_MODAL}`,
  payload: modal,
});

export const closeModal = namespace => ({
  type: `${namespace}/${CLOSE_MODAL}`,
});

export const openPinPad = (name, id, avatar, namespace) => (
  (dispatch, getState) => {
    const today = moment().tz(getState().weightRoom.currentUser.settings.time_zone);
    dispatch({
      type: `${namespace}/${OPEN_PINPAD}`,
      payload: {
        today,
        name,
        id,
        avatar,
      },
    });
  }
);

export const completeWorkout = namespace => ({
  type: `${namespace}/${COMPLETE_WORKOUT}`,
});

export const closePinPad = namespace => ({
  type: `${namespace}/${CLOSE_PINPAD}`,
});

export const cancelPinPad = namespace => ({
  type: `${namespace}/${CANCEL_PINPAD}`,
});

export const selectVerifiedAthlete = namespace => ({
  type: `${namespace}/${SELECT_VERIFIED_ATHLETE}`,
});

export const fetchHistory = (namespace, exerId, userId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_HISTORY_START}` });
    axios.get(`/exercises/${exerId}/history?user_id=${userId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_HISTORY_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_HISTORY_REJECTED}`, payload: err });
      });
  }
);

export const fetchDocument = (namespace, docId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_DOCUMENT_START}` });
    axios.get(`/documents/${docId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_DOCUMENT_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_DOCUMENT_REJECTED}`, payload: err });
      });
  }
);

export const fetchExercises = namespace => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_EXERCISES_START}` });
    axios.get('/exercises?type=L')
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_EXERCISES_REJECTED}`, payload: err });
      });
  }
);

export const fetchSimilarExercises = (namespace, exerId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_START}` });
    axios.get(`/exercises?similar=${exerId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_EXERCISES_REJECTED}`, payload: err.response.headers.message });
      });
  }
);

export const fetchSimilarTypeExercises = (namespace, exerId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_START}` });
    axios.get(`/exercises?filter=${exerId}`)
      .then((response) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_FULFILLED}`, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_SIM_TYPE_EXERCISES_REJECTED}`, payload: err.response.headers.message });
      });
  }
);


export const updateWorkout = (index, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axios.post('/workouts', values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`,
            payload: {
              index,
              data: response.data,
            },
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const fetchJournal = (index, namespace, workId, userId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${FETCH_JOURNAL_START}` });
    axios.get(`/journal/workout/${workId}?user_id=${userId}`)
      .then((response) => {
        dispatch({
          type: `${namespace}/${FETCH_JOURNAL_FULFILLED}`,
          payload: {
            index,
            data: response.data.result.entry,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${FETCH_JOURNAL_REJECTED}`, payload: err });
      });
  }
);

export const submitJournal = (index, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_JOURNAL_START}` });
    axios.post('/journal', values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_JOURNAL_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_JOURNAL_REJECTED}`, payload: err });
      });
  }
);

export const updateJournal = (index, namespace, values, workId) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_JOURNAL_START}` });
    axios.put(`/journal/${workId}`, values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_JOURNAL_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_JOURNAL_REJECTED}`, payload: err });
      });
  }
);

export const optOutWorkout = (athleteId, date, index, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axios.post('/workouts', values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
        const data = response.data.result;

        if (data.substitution && data.substitution > 0) {
          dispatch({
            type: `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`,
            payload: {
              index,
              subExerName: data.sub_exer_name,
              subId: data.substitution,
              subAttributes: data.sub_attributes,
            },
          });
          dispatch(fetchWorkout(athleteId, date, index, namespace));
        }
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const updateTagWorkout = (athleteId, date, index, namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_START}` });
    axios.post('/workouts', values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        dispatch({
          type: `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`,
          payload: {
            index,
            data: response.data,
          },
        });
        const data = response.data.result;

        dispatch({
          type: `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`,
          payload: {
            index,
            subExerName: data.sub_exer_name,
            subId: data.substitution,
            subAttributes: data.sub_attributes,
          },
        });
        dispatch(fetchWorkout(athleteId, date, index, namespace));
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${UPDATE_WORKOUTS_REJECTED}`, payload: err });
      });
  }
);

export const submitPinCode = (namespace, values) => (
  (dispatch) => {
    dispatch({ type: `${namespace}/${SUBMIT_PIN_START}` });
    axios.post('/users/pin', values, {
      requestId: `${namespace}/${updateWorkout}`,
    })
      .then((response) => {
        if (response.data.result.verified === true) {
          dispatch({
            type: `${namespace}/${SUBMIT_PIN_FULFILLED}`,
            payload: response.data.result,
          });
          dispatch(selectVerifiedAthlete(namespace));
          dispatch(closePinPad(namespace));
        }
        if (response.data.result.verified === false) {
          dispatch({ type: `${namespace}/${SUBMIT_PIN_REJECTED}` });
        }
      })
      .catch((err) => {
        dispatch({ type: `${namespace}/${SUBMIT_PIN_REJECTED}`, payload: err });
      });
  }
);

export const selectQuestion = (id, index, namespace) => ({
  type: `${namespace}/${SELECT_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const restartQuestions = namespace => ({
  type: `${namespace}/${RESTART_QUESTIONS}`,
});

export const nextQuestion = (id, index, namespace) => ({
  type: `${namespace}/${NEXT_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const prevQuestion = (id, index, namespace) => ({
  type: `${namespace}/${PREV_QUESTION}`,
  payload: {
    id,
    index,
  },
});

export const storePanelHeight = (height, width, namespace) => ({
  type: `${namespace}/${STORE_PANEL_HEIGHT}`,
  payload: {
    height,
    width,
  },
});

export const storeFormikValues = (namespace, values) => ({
  type: `${namespace}/${STORE_FORMIK_VALUES}`,
  payload: values,
});

export const storeWorkoutsBySuperset = (namespace, workouts) => ({
  type: `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`,
  payload: workouts,
});

// export const incrementCompletedSets = (namespace, group, workoutIndex) => (
//   (dispatch) => {
//     dispatch({
//       type: `${namespace}/${INCREMENT_COMPLETED_SETS}`,
//       payload: {
//         group,
//         workoutIndex,
//       },
//     });
//     return Promise.resolve();
//   }
// );

export const increaseCompletedSets = (namespace, group, workoutIndex) => (
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: `${namespace}/${INCREASE_COMPLETED_SETS}`,
        payload: {
          group,
          workoutIndex,
        },
      });
      resolve();
    });
  }
);

export const decreaseCompletedSets = (namespace, group, workoutIndex) => (
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: `${namespace}/${DECREASE_COMPLETED_SETS}`,
        payload: {
          group,
          workoutIndex,
        },
      });
      resolve();
    });
  }
);

export const setIsNavigatingSuperset = (namespace, value) => ({
  type: `${namespace}/${SET_IS_NAVIGATING_SUPERSETS}`,
  payload: value,
});
