/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import WeeklyTrainingResultsBarChart from './weeklyTrainingResultsBarChart';

const WeeklyTrainingResultsHeaderSection = () => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(0);
  const totalTime = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.training?.totalTime);
  const totalTimeForCurrentWeek = totalTime?.currentWeek;

  const HeaderContainer = styled('div')`
    display: flex;
    max-width: 1400px;
    width: 100%;
    //border: 1px solid #F2F2F2;
    padding: 40px;
  `;
  const IconAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 25px;
    text-align: center;
  `;
  const BarChartContainer = styled('div')`
    display: flex;
    flex: 1;
    align-items: center;
  `;

  const IconContainer = styled('div')`
    height: 54px;
    width: 54px;
    background-color: #F3F3F3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  const TotalTimeContainer = styled('div')`
    min-height: 70px;
    min-width: 70px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  `;
  const IncreaseContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  `;

  return (
    <HeaderContainer>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <IconAndTextContainer>
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={32}
            icon='history'
            color={theme.colors.orange}
          />
        </IconContainer>
        <Text fontSize='16px' fontWeight={600}>Total Training Time Logged</Text>
        <TotalTimeContainer>
          <Text fontSize={containerWidth < 540 ? '28px' : '40px'} fontWeight={800}>{totalTime?.currentWeek}</Text>
        </TotalTimeContainer>
        <Text fontSize='15px' fontWeight={600} color='#ADADAD'>Previous Week Total</Text>
        <Text fontSize='24px' fontWeight={800} color='#ADADAD' style={{ marginTop: -20 }}>
          {totalTime?.previousWeek}
        </Text>
        <IncreaseContainer>
          <Text color='#A4A2A2' fontSize='14px' fontWeight={600}>
            {`${totalTime?.direction} of`}
            {' '}
            <span style={{ fontWeight: '900', color: '#7E7A7A' }}>{totalTime?.difference}</span>
            {' '}
            from previous week
          </Text>
        </IncreaseContainer>
      </IconAndTextContainer>
      {(totalTimeForCurrentWeek !== '0 minutes' && containerWidth > 750) && (
      <BarChartContainer>
        <WeeklyTrainingResultsBarChart />
      </BarChartContainer>
      )}
    </HeaderContainer>
  );
};
export default WeeklyTrainingResultsHeaderSection;
