import React, { Fragment, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import useMaxReportModal from '../hooks/useMaxReportModal';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ReportingModal from '../ReportingModal';
import MaxReportData from './MaxReportData';
import ActiveReportSidebar from '../ActiveReportSidebar';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  
  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0px 15px;
    margin: 0px;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-height: 920px) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;

const BodyRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
  flex-basis: 100%;
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  width: 140px;
  height: 45px;
  bottom: 30px;
  left: 50%;
  margin-left: -70px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const MaxReport = () => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);

  useEffect(() => {
    handleSetIsModalOpen(true, 'main');
  }, []);

  return (
    <MainContainer
      isReportOpen={isReportOpen || activeModal === 'downloadMaxReport'}
    >
      {/* <ResizeObserver
        onResize={(rect) => {
          setChartWidth(rect.width);
        }}
      /> */}
      <ReportingModal />
      {isReportOpen && (
        <>
          <HeaderRow>
            <HeaderText
              fontWeight={300}
              letterSpacing='normal'
            >
              Max Report
            </HeaderText>
          </HeaderRow>
          <BodyRow>
            <MaxReportData />
          </BodyRow>
          <MobileActiveMenu>
            <ActiveReportSidebar download />
          </MobileActiveMenu>
        </>
      )}
    </MainContainer>
  );
};

export default MaxReport;
