import React from 'react';
import { useDispatch } from 'react-redux';

import PanelAthletes from '../presentational/PanelAthletes';
import {
  closePinPad,
  openPinPad,
  selectAthlete,
} from '../../ducks/panelActions';

const WeightroomPanelAthletes = (ownProps) => {
  const dispatch = useDispatch();

  const clsPinPad = (namespace) => {
    dispatch(closePinPad(namespace));
  };
  const opnPinPad = (name, id, avatar, namespace) => {
    dispatch(openPinPad(name, id, avatar, namespace));
  };
  const slctAthlete = (accountCode, name, id, avatar, namespace) => {
    dispatch(selectAthlete(accountCode, name, id, avatar, namespace));
  };

  return (
    <PanelAthletes
      namespace={ownProps.namespace}
      openPinPad={opnPinPad}
      selectAthlete={slctAthlete}
      closePinPad={clsPinPad}
    />
  );
};

export default WeightroomPanelAthletes;
