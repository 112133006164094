import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CheckInWrapper = styled('div')`
    display: flex;
    flex-direction: row;
`;

const CheckInBubble = styled('div')`
    width: 10px;
    height: 10px;
    margin: 3px 1px 0;
    border-radius: 35px;
    background-color: ${(props) => props.backgroundColor};
`;

const ToolTip = styled('span')`
    visibility: hidden;
    width: 250px;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 12px;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    margin-left: -119px;

    ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }
`;

const BubbleAndDayWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    :hover > ${ToolTip} {
      visibility: visible;
    }
`;

const DayAbbreviation = styled('div')`
    text-align: center;
    font-size: 8px;
`;

/*
SevenDaySnapshot takes in an array formatted like:
[
    {
        "day": "2021-01-01",
        "result": 1,
        "resultDescription": "..."
    }
]
We then iterate the array and create a red, green, or orange
indicator for whether or not there was activity on the given day.
 */
const SevenDaySnapshot = ({
  days,
}) => {
  const determineBackgroundColor = (item) => {
    if (item.result === 1) { // red
      return '#ff0024';
    } if (item.result === 2) { // green
      return '#0dcc8a';
    } if (item.result === 3) {
      // grey
      return '#DBDBDB';
    }
    // yellow
    return '#ffaf0e';
  };

  const determineDayOfWeek = (item) => {
    const weekday = new Array(7);
    weekday[0] = 'S';
    weekday[1] = 'M';
    weekday[2] = 'T';
    weekday[3] = 'W';
    weekday[4] = 'Th';
    weekday[5] = 'F';
    weekday[6] = 'S';
    return weekday[new Date(item.day).getUTCDay()];
  };

  const determineTooltip = (item) => {
    if (item.result === 1) {
      return 'Workout scheduled but no activity';
    } if (item.result === 2) {
      return 'Workout scheduled and had activity';
    } if (item.result === 3) {
      return 'No workout scheduled and no activity';
    }
    return 'No workout scheduled but had activity';
  };

  return (
    <CheckInWrapper>
      {days.map((item) => (
        <BubbleAndDayWrapper>
          <DayAbbreviation>{determineDayOfWeek(item)}</DayAbbreviation>
          <CheckInBubble
            backgroundColor={determineBackgroundColor(item)}
          />
          <ToolTip>{determineTooltip(item)}</ToolTip>
        </BubbleAndDayWrapper>
      ))}
    </CheckInWrapper>
  );
};

SevenDaySnapshot.propTypes = {
  days: PropTypes.instanceOf(Array).isRequired,
};

export default SevenDaySnapshot;
