/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import download from 'downloadjs';
import { batch } from 'react-redux';
import moment from 'moment';

import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_START,
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,

  SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  SET_CURRENT_GROUP_IN_GROUP_PICKER,
  SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  SET_ATHLETES_IN_CLICKED_ZONE,
  SET_CURRENT_CHECKED_BOXES,

  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,

  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,

  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,

  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,

  FETCH_LOAD_MONITORNING_LINE_CHART_START,
  FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED,

  SET_SHOW_ALL_FOR_DOWNLOAD,
  SET_IS_CHART_SHOWING_ALL_DATA,

} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchReportableGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_REPORTABLE_GROUPS_LM_DASH_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED, payload: err });
      });
  }
);

export const fetchQuestionnairesForAccount = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START });
    axiosAuthed.get(`/accounts/${accountCode}/questionnaires`)
      .then((response) => {
        dispatch({
          type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchQuestionsForAccount = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START });
    axiosAuthed.get(`/accounts/${accountCode}/questions`)
      .then((response) => {
        dispatch({
          type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchLoadMonitoringLineChartData = (accountCode, filterType, filterIds, lineChartValues) => (
  (dispatch) => {
    const {
      date,
      questionnaire,
      arrayOfQuestions,
    } = lineChartValues;

    const questionnaireId = questionnaire?.id;
    const questionnaireQuery = questionnaireId ? `&questionnaire=${questionnaireId}` : '';

    const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
    const questionsQuery = questionnaireId ? '' : `&questions=${arrayOfQuestionIds}`;

    const filterQuery = `?filterType=${filterType}`;
    const filterIdsQuery = `${filterIds.map((id) => `&filterIds=${id}`).join('')}`;
    const dateQuery = `&date=${date.queryFormat}`;

    dispatch({ type: FETCH_LOAD_MONITORNING_LINE_CHART_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/load-monitor/line${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}`)
      .then((response) => {
        console.log('LINE CHART API RESPONSE:', response);
        dispatch({
          type: FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchQuestionnaireReportSingleDay = (accountCode, filterType, filterIds, values, actionType) => (
  (dispatch, getState) => {
    const {
      startDate,
      date,
      selectionType,
      questionnaire,
      arrayOfQuestions,
      showRangeAverages,
      showTotal,
    } = values;

    console.log('showRangeAverages:', showRangeAverages);
    console.log('showTotal:', showTotal);

    const questionnaireId = questionnaire?.id;
    const questionnaireQuery = questionnaireId ? `&questionnaire=${questionnaireId}` : '';

    const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
    const questionsQuery = questionnaireId ? '' : `&questions=${arrayOfQuestionIds}`;

    const accountString = `/accounts/${accountCode}`;
    // const reportQuery = '/sports-science/load-monitor/table/group';
    const reportQuery = '/reports/load-monitor/table/group';
    // const reportQuery = '/reports/questionnaire/single';
    const filterQuery = `?filterType=${filterType}`;
    // const filterIdsQuery = `${filterIds.map((id) => `&filterIds=${id}`).join('')}`;
    const filterIdsQuery = `?group=${filterIds}`;
    const dateQuery = `&date=${date.queryFormat}`;
    const averageQuery = `${showRangeAverages ? ('&showAverages=true') : '&showAverages=false'}`;
    const totalQuery = `${showTotal ? ('&showTotals=true') : '&showTotals=false'}`;

    dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START });
    // return axiosAuthed.get(`${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`)
    return axiosAuthed.get(`${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`)
      .then((response) => {
        const apiResponseData = response.data;
        console.log('api response group data Group table:', apiResponseData);
        console.log('average query:', averageQuery);
        console.log('totalQuery:', totalQuery);
        console.log('query string:', `${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`);

        const dataRows = apiResponseData.filter((obj) => obj.name !== 'Team Average');

        /** array to loop through for the footer accessors to display team averages info */
        const teamAverageTotals = apiResponseData.filter((obj) => obj.name === 'Team Average')[0];

        console.log('TEAM AVERAGE TOTALS:', teamAverageTotals);

        const arrayOfAllQuestions = [];
        const getArrayOfAllQuestions = () => dataRows.map((athlete) => arrayOfAllQuestions.push(...athlete.questions));
        getArrayOfAllQuestions();

        const groupByCategory = arrayOfAllQuestions.reduce((group, question) => {
          const { id } = question;
          // eslint-disable-next-line no-param-reassign
          group[id] = group[id] ?? [];
          group[id].push(question);
          return group;
        }, {});

        const arrayOfGroupArrays = Object.values(groupByCategory);

        const donutChartData = arrayOfGroupArrays.map((groupArray) => ({
          title: groupArray[0].title,
          id: groupArray[0].id,
          groupQuestionResponsesFromEachAthlete: groupArray,
          red: groupArray.filter((question) => question.response.backgroundColor === '#FF3737'),
          yellow: groupArray.filter((question) => question.response.backgroundColor === '#FFCB47'),
          green: groupArray.filter((question) => question.response.backgroundColor === '#0DCC8A'),
          noColor: groupArray.filter((question) => question.response.backgroundColor !== '#0DCC8A' || '#FFCB47' || '#FF3737'),
        }));

        const totalScoresDonutChartDataRed = dataRows.filter((athleteObj) => (athleteObj.totalScores.value.backgroundColor === '#FF3737'));
        const totalScoresDonutChartDataGreen = dataRows.filter((athleteObj) => (athleteObj.totalScores.value.backgroundColor === '#0DCC8A'));
        const totalScoresDonutChartDataYellow = dataRows.filter((athleteObj) => (athleteObj.totalScores.value.backgroundColor === '#FFCB47'));

        dispatch({
          type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
          payload: {
            data: apiResponseData,
            dataRows,
            teamAverageTotals,
            values,
            arrayOfAllQuestions,
            donutChartData,
            totalScoresDonutChartDataGreen,
            totalScoresDonutChartDataRed,
            totalScoresDonutChartDataYellow,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchQuestionnaireReportMultiDay = (accountCode, filterType, filterIds, values, actionType) => (
  (dispatch, getState) => {
    const {
      startDate,
      endDate,
      selectionType,
      questionnaire,
      arrayOfQuestions,
      showRangeAverages,
      showTotal,
    } = values;

    const questionnaireId = questionnaire?.id;
    const questionnaireQuery = questionnaireId ? `&questionnaire=${questionnaireId}` : '';

    const arrayOfQuestionIds = arrayOfQuestions.map((question) => question.id);
    const questionsQuery = questionnaireId ? '' : `&questions=${arrayOfQuestionIds}`;

    const accountString = `/accounts/${accountCode}`;
    // const reportQuery = '/reports/questionnaire/multiple';
    // const reportQuery = '/sports-science/load-monitor/table/athlete';
    const reportQuery = '/reports/load-monitor/table/athlete';
    // const filterQuery = `?filterType=${filterType}`;
    // const filterIdsQuery = `${filterIds.map((id) => `&filterIds=${id}`).join('')}`;
    const filterIdsQuery = `?athlete=${filterIds}`;
    // const dateQuery = `&startDate=${startDate.queryFormat}&endDate=${endDate.queryFormat}`;
    const dateQuery = `&date=${endDate.queryFormat}`;
    const averageQuery = `${showRangeAverages ? ('&showAverages=true') : '&showAverages=false'}`;
    const totalQuery = `${showTotal ? ('&showTotals=true') : '&showTotals=false'}`;

    dispatch({ type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START });
    // return axiosAuthed.get(`${accountString}${reportQuery}${filterQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`)
    return axiosAuthed.get(`${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`)
      .then((apiResponse) => {
        const apiResponseData = apiResponse.data;
        console.log('api response athlete data:', apiResponseData);
        console.log('average query:', averageQuery);
        console.log('totalQuery:', totalQuery);
        console.log('query string:', `${accountString}${reportQuery}${filterIdsQuery}${dateQuery}${questionnaireQuery}${questionsQuery}${averageQuery}${totalQuery}`);

        const formatData = () => {
          /** to get total score for each athlete object we need to map through
           * the total scores array and return score date object that matches the date of
           * the question at index [i];
           */

          const totalScoreArraysForEachAthlete = apiResponseData.map((athleteObject, i) => athleteObject.totals.scores);

          const responsesArraysForEachAthlete = apiResponseData.map((athleteObject, i) => athleteObject.responses);

          const averagesArraysForEachAthlete = apiResponseData.map((athleteObject) => athleteObject.averages);

          const getResponseArray = (athleteObject, athleteIndex) => {
            const getNewQuestionsArray = (response) => {
              const questionsArrayForResponse = response.questions;

              const newQuestionsArrayForResponse = questionsArrayForResponse.map((questionsObject, Qindex) => (
                {
                  id: questionsObject.id,
                  title: questionsObject.title,
                  abbr: questionsObject.abbr,
                  value: questionsObject.response.value,
                  backgroundColor: questionsObject.response.backgroundColor,
                  textColor: questionsObject.response.textColor,
                  average: averagesArraysForEachAthlete[athleteIndex].filter((averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex)[0].response.value,
                  averageTextColor: averagesArraysForEachAthlete[athleteIndex].filter((averagesObj, averagesObjectIndex) => averagesObjectIndex === Qindex)[0].response.textColor,
                }
              ));
              return newQuestionsArrayForResponse;
            };

            const newResponseArray = responsesArraysForEachAthlete[athleteIndex].map((response, resonseIndex) => ({
              date: response.date,
              //* * map through questions, averages indexes should map with question indexes */
              questions: getNewQuestionsArray(response),
              totalScore: totalScoreArraysForEachAthlete[athleteIndex].filter((scoreObject) => scoreObject.date === response.date)[0].value,
              totalScoreBackgroundColor: totalScoreArraysForEachAthlete[athleteIndex].filter((scoreObject) => scoreObject.date === response.date)[0].backgroundColor,
              totalScoreTextColor: totalScoreArraysForEachAthlete[athleteIndex].filter((scoreObject) => scoreObject.date === response.date)[0].textColor,
              totalScoreAverage: athleteObject.totals.average.value,
              totalScoreAverageTextColor: athleteObject.totals.average.textColor,
            }));

            return newResponseArray;
          };
          const formattedData = apiResponseData.map((athleteObject, athleteIndex) => ({
            name: athleteObject.name,
            id: athleteObject.id,
            responses: getResponseArray(athleteObject, athleteIndex),
          }));
          return formattedData;
        };

        const formattedDataToPassToTable = formatData();

        dispatch({
          type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
          payload: {
            data: apiResponseData,
            dataRows: formattedDataToPassToTable,
            values,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
          payload: err,
        });
      });
  }
);
export const setCurrentQuestionInQuestionnairePicker = (value) => ({
  type: SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  payload: value,
});

export const setCurrentDateInDashDatePicker = (value) => ({
  type: SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  payload: value,
});

export const setCurrentGroupInGroupPicker = (value) => ({
  type: SET_CURRENT_GROUP_IN_GROUP_PICKER,
  payload: value,
});

export const setCurrentAthleteInAthletePicker = (value) => ({
  type: SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  payload: value,
});

export const setAthletesInClickedZone = (athletes) => (
  {
    type: SET_ATHLETES_IN_CLICKED_ZONE,
    payload: athletes,
  }
);
export const setCurrentCheckedBoxes = (checkedBoxesObject) => (
  {
    /** checked boxes object needs to include the question name and question/metric id */
    type: SET_CURRENT_CHECKED_BOXES,
    payload: checkedBoxesObject,
  }
);

export const setShowAllForDownload = (bool) => (
  {
    type: SET_SHOW_ALL_FOR_DOWNLOAD,
    payload: bool,
  }
);

export const setIsChartShowingAllData = (bool) => (
  {
    type: SET_IS_CHART_SHOWING_ALL_DATA,
    payload: bool,
  }
);
