import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// import { animated, useTransition } from 'react-spring';
import Logger from 'js-logger';
import socket from '../../utils/setSocketConfig';
import Layout from '../Layout/Layout';
import Title from '../Title/Title';
import Text from '../Text/Text';
import Button from '../Button/Button';
import MessagesList from './components/MessagesList';

import { setConnected, setTestMessage, setSocketAuthenticated } from './ducks/socketActions';
import { BoxShadow } from '../../GlobalStyles';

const SocketContainer = styled('div')`
  display: block;
  background-color: white;
  margin-top: 60px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: ${BoxShadow};

  button {
    margin-top: 15px;
  }

  .connection {
    display: flex;
  }

  .circle {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: block;
    margin-right: 5px;
    align-self: center;

    &.connected {
      background-color: green;
    }
    &.disconnected {
      background-color: red;
    }
  }
`;

const HeaderText = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const SocketTester = () => {
  const [cookies, setCookie] = useCookies(['accessToken']);
  const isConnected = useSelector((state) => state.socketTester.ui.isConnected);
  const isAuthenticated = useSelector((state) => state.socketTester.ui.isAuthenticated);
  const activeConversation = useSelector((state) => state.conversations.data.activeConversation);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
      socket.on('connect', () => {
        dispatch(setConnected(true));
      });
      socket.on('message', (data) => {
        if (activeConversation === data.conversationId) {
          dispatch(setTestMessage(data));
        } else {
          dispatch(setConversationMessage(data));
        }
      });
      socket.emit('authentication', { token: cookies.accessToken });
      socket.on('authenticated', () => {
        dispatch(setSocketAuthenticated(true));
      });
    }
    socket.on('disconnect', () => {
      dispatch(setConnected(false));
      dispatch(setSocketAuthenticated(false));
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    socket.emit('message', 'Testing message!');
  };
  const disconnect = () => {
    socket.disconnect();
    dispatch(setSocketAuthenticated(false));
  };
  const connect = () => {
    socket.connect();
    socket.emit('authentication', { token: cookies.accessToken });
  };

  return (
    <Layout>
      <SocketContainer>
        <Title icon='swap'>
          Socket Tester
        </Title>

        <HeaderText>
          {isConnected ? (
            <Text className='connection'>
              <span className='circle connected' />
              Connected
            </Text>
          ) : (
            <Text className='connection'>
              <span className='circle disconnected' />
              Disconnected
            </Text>
          )}
          {isAuthenticated ? (
            <Text>Authenticated</Text>
          ) : (
            <Text>Not Authenticated</Text>
          )}
        </HeaderText>
        <MessagesList />

        <Button
          cta='Send Message'
          onClick={sendMessage}
        />

        <Button
          cta={isConnected ? 'Disconnect' : 'Connect'}
          onClick={isConnected ? disconnect : connect}
        />

      </SocketContainer>
    </Layout>
  );
};

export default SocketTester;
