// A component that contains the left, right, and center columns that separate
// the different sections of the workout entry screen
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';

import WorkoutsLeftColumn from './WorkoutsLeftColumn';
import WorkoutsCenterColumn from './WorkoutsCenterColumn';
import WorkoutsRightColumn from './WorkoutsRightColumn';

import {

} from '../../ducks/workoutsActions';

const Wrapper = styled('div')`
	display: flex;
	width: 100%;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 170px;
  max-width: 1550px;
  justify-content: center;
  @media only screen and (max-width : 768px) {
    min-width: 0px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 0px;
  }
`;

const WorkoutsWrapper = () => (
  <Wrapper>
    <WorkoutsLeftColumn />
    <WorkoutsCenterColumn />
    <WorkoutsRightColumn />
  </Wrapper>
);

WorkoutsWrapper.propTypes = { };

export default WorkoutsWrapper;
