import { connect } from 'react-redux';
import ModalHistory from '../presentational/ModalHistory';
import { fetchHistory } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  exerId: state[ownProps.namespace].activeExerId,
  userId: state[ownProps.namespace].athleteId,
  isLoadingHistory: state[ownProps.namespace].isLoadingHistory,
  workoutHistory: state[ownProps.namespace].workoutHistory,
});


const mapDispatchToProps = dispatch => ({
  fetchHistory: (namespace, exerId, userId) => {
    dispatch(fetchHistory(namespace, exerId, userId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHistory);
