/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const PercentageBreakDownContainerMobileView = ({
  completionPercentage, assigned, newPrs, completed, containerWidth, fontSize,
}) => {
  const theme = useTheme();

  /** styles */
  const PercentageBreakDownContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #F2F2F2;
  min-width: ${(props) => (props.containerWidth && props.containerWidth <= 400 ? '250px' : '400px')};
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${(props) => (props.containerWidth < 802 ? '#FDFBFB' : 'white')};
`;
  const InnerContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
  height: 100%;
  padding: 15px;
`;

  const NumberContainer = styled('div')`
  min-width: 80px;
  max-width: 80px;
  min-height: 50px;
  max-height: 40px;
  background-color: #F3F3F3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
`;
  const IconAndTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  `;
  const Row = styled('div')`
  display: flex;
  `;

  return (
    <PercentageBreakDownContainer containerWidth={containerWidth}>
      <Row>
        <InnerContainer style={{
          borderRight: 'solid', borderRightWidth: 2, borderBottom: 'solid', borderBottomWidth: 2, borderColor: '#F2F1F1',
        }}
        >
          <NumberContainer>
            <Text fontSize='32px' fontWeight={800}>{assigned?.currentWeek}</Text>
          </NumberContainer>
          <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Exercises Assigned</Text>
          <IconAndTextContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='play-filled'
              color={assigned?.difference < 0 ? '#FF3737' : assigned?.difference === 0 ? theme.colors.yellow : theme.colors.green}
              style={{ transform: assigned?.difference < 0 ? 'rotate(90deg)' : assigned?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
            />
            {assigned?.difference < 0 ? (
              <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                decrease of
                {' '}
                <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(assigned?.difference)}</span>
                {' '}
                from previous week
              </Text>
            ) : (
              <>
                {assigned?.difference === 0 ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                    No change from previous week
                  </Text>

                ) : (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${assigned?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                )}
              </>
            )}
          </IconAndTextContainer>
        </InnerContainer>
        <InnerContainer style={{ borderBottom: 'solid', borderBottomWidth: 2, borderColor: '#F2F1F1' }}>
          <NumberContainer>
            <Text fontSize='32px' fontWeight={800}>{completed?.currentWeek}</Text>
          </NumberContainer>
          <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Exercises Completed</Text>
          <IconAndTextContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='play-filled'
              color={completed?.difference < 0 ? '#FF3737' : completed?.difference === 0 ? theme.colors.yellow : theme.colors.green}
              style={{ transform: completed?.difference < 0 ? 'rotate(90deg)' : completed?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
            />
            {completed?.difference < 0 ? (
              <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                decrease of
                {' '}
                <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(completed?.difference)}</span>
                {' '}
                from previous week
              </Text>
            ) : (
              <>
                {completed?.difference === 0 ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                    No change from previous week
                  </Text>

                ) : (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${completed?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                )}
              </>
            )}
          </IconAndTextContainer>
        </InnerContainer>
      </Row>
      <Row>
        <InnerContainer style={{ borderRight: 'solid', borderRightWidth: 2, borderColor: '#F2F1F1' }}>
          <NumberContainer>
            <Text fontSize={fontSize} fontWeight={800}>
              {`${completionPercentage?.currentWeek}%`}
            </Text>
          </NumberContainer>
          <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Completion Percentage</Text>
          <IconAndTextContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='play-filled'
              color={completionPercentage?.difference < 0 ? '#FF3737' : completionPercentage?.difference === 0 ? theme.colors.yellow : theme.colors.green}
              style={{ transform: completionPercentage?.difference < 0 ? 'rotate(90deg)' : completionPercentage?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
            />
            {completionPercentage?.difference < 0 ? (
              <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                decrease of
                {' '}
                <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${Math.abs(completionPercentage?.difference)}%`}</span>
                {' '}
                from previous week
              </Text>
            ) : (
              <>
                {completionPercentage?.difference === 0 ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                    No change from previous week
                  </Text>

                ) : (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${completionPercentage?.difference}%`}</span>
                    {' '}
                    from previous week
                  </Text>
                )}
              </>
            )}
          </IconAndTextContainer>
        </InnerContainer>
        <InnerContainer>
          <NumberContainer>
            <Text fontSize='32px' fontWeight={800}>{newPrs?.currentWeek}</Text>
          </NumberContainer>
          <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 50 }}>New PRs</Text>
          <IconAndTextContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='play-filled'
              color={newPrs?.difference < 0 ? '#FF3737' : newPrs?.difference === 0 ? theme.colors.yellow : theme.colors.green}
              style={{ transform: newPrs?.difference < 0 ? 'rotate(90deg)' : newPrs?.difference === 0 ? 'rotate(0deg)' : 'rotate(-90deg)' }}
            />
            {newPrs?.difference < 0 ? (
              <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                decrease of
                {' '}
                <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(newPrs?.difference)}</span>
                {' '}
                from previous week
              </Text>
            ) : (
              <>
                {newPrs?.difference === 0 ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                    No change from previous week
                  </Text>

                ) : (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 124 }}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${newPrs?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                )}
              </>
            )}
          </IconAndTextContainer>
        </InnerContainer>
      </Row>
    </PercentageBreakDownContainer>
  );
};

PercentageBreakDownContainerMobileView.propTypes = {
  completionPercentage: PropTypes.instanceOf(Object).isRequired,
  assigned: PropTypes.instanceOf(Object).isRequired,
  completed: PropTypes.instanceOf(Object).isRequired,
  newPrs: PropTypes.instanceOf(Object).isRequired,
  containerWidth: PropTypes.number.isRequired,
  fontSize: PropTypes.string.isRequired,
};

export default PercentageBreakDownContainerMobileView;
