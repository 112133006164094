import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { fetchSomething } from '../ducks/testPageActions';
import Button from '../../../shared/components/Button/Button';

const JournalContainer = styled('div')`
  text-align: center;
  
  button {
    display: none;
  }
`;

const MainContentComponent = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const testData = useSelector((state) => state.testPage.data.testData);
  const isExercisesLoading = useSelector((state) => state.testPage.ui.isExercisesLoading);

  const dispatch = useDispatch();

  console.log('Rendering Current User', currentUser.accountCode);

  const accountCode = currentUser ? currentUser.accountCode : 0;

  useEffect(() => {
    dispatch(fetchSomething(accountCode));
  }, []);

  const handleFetchJournals = () => {
    dispatch(fetchSomething(accountCode));
  };

  return (
    <JournalContainer>
      {/* {isExercisesLoading ? (
        <Spinner />
      ) : (
        <>
          {testData.map((data) => {
            <CoolListObject
              title={data.title}
            />
          })}
        </>
      )} */}
      <Button onClick={handleFetchJournals} />
    </JournalContainer>
  );
};

export default MainContentComponent;
