import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ColorLightGray } from '../../../../shared/GlobalStyles';

import IconRound from './IconRound';
import Title from './Title';

const OptedContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const Text = styled('p')`
  font-size: 14px;
  margin-bottom: 5px;
  color: ${ColorLightGray};
  opacity: 1;
`;

const OptedOut = (props) => {
  let { reason } = props;
  const { note, subExerName } = props;

  switch (+reason) {
    case 1:
      reason = 'Injury';
      break;
    case 2:
      reason = 'Prohibited';
      break;
    case 3:
      reason = 'Lack of Equipment';
      break;
    case 4:
      reason = 'Other (explained below)';
      break;
    default:
  }

  return (
    <OptedContainer>
      {/* <IconRound
        className='title-icon'
        icon='opt-out'
        xLarge
      /> */}
      <Title
        icon='opt-out'
        noMargin
      >
        Opted-Out
      </Title>
      <Text>
        Reason: {reason}
      </Text>
      <Text>
        Note: {note}
      </Text>
      {subExerName && (
        <Text>
          Substitute: {subExerName}
        </Text>
      )}

    </OptedContainer>
  );
};

OptedOut.propTypes = {
  note: PropTypes.string.isRequired,
  reason: PropTypes.number.isRequired,
  subExerName: PropTypes.string.isRequired,
};

export default OptedOut;
