/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  submitProgramWorkoutItem,
  submitWorkoutItem,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	height: 170px;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
	flex-direction: column;
  background: white;
`;

const StyledInput = styled('input')`
	font-family: 'Nunito Sans';
	font-weight: 600;
	font-size: 11px;
	border: 1px solid #ccc;
	text-align: center;
  width: 80%;
  border-radius: 4px;
  height: 30px;
  margin-top: 15px;
`;

const CompleteButton = styled('button')`
  width: 140px;
	height: 37px;
	border-radius: 40px;
  box-shadow: ${BoxShadow};
	border: ${(props) => (props.isWorkoutComplete ? '1px solid #00b371;' : '1px solid lightgrey')};
	margin-top: 20px;
	background: ${(props) => (props.isWorkoutComplete ? '#00b371;' : 'white')};
  transition: all 0.1s ease;
`;

const InnerCompleteButton = styled('div')`
  display: flex;
	justify-content: center;
	align-items: center;
`;

const CompleteButtonText = styled('div')`
  font-size: 12px;
	margin-left: 10px;
	font-weight: 600;
	font-family: 'Nunito Sans';
	color: ${(props) => (props.isWorkoutComplete ? 'white' : 'black')};
`;

const ButtonContainer = styled('div')`
  width: 100%;
	display: flex;
	justify-content: center;
`;

const AdditionalInformationText = styled('div')`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Nunito Sans';
  text-align: center;
`;

const HealthCard = ({
  workoutObject, index, outerIndex, sessionIndex = null,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [isWorkoutComplete, setIsWorkoutComplete] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const programDayIndex = useSelector(
    (state) => state.workouts.data.programDayIndex,
  );

  /**
   * Gets the placeholder value for the health input
   */
  const getPlaceholder = () => {
    if (workoutObject?.tableData[0]?.values[0]?.placeholder) {
      return workoutObject.tableData[0].values[0].placeholder;
    }
    return '';
  };

  useEffect(() => {
    if (workoutObject.tableData[0].values[0].value) {
      setValue(workoutObject.tableData[0].values[0].value);
    } else {
      setValue('');
    }
  }, [workoutObject]);

  const completeButton = (handleSubmit) => (
    <CompleteButton
      isWorkoutComplete={isWorkoutComplete}
      onClick={() => {
        handleSubmit();
      }}
      onMouseDown={() => {
        setIsWorkoutComplete(true);
      }}
      onMouseUp={() => {
        setTimeout(() => {
          setIsWorkoutComplete(false);
        }, 1250);
      }}
    >
      <InnerCompleteButton>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='checkmark'
          color={!isWorkoutComplete ? 'black' : 'white'}
        />
        <CompleteButtonText
          isWorkoutComplete={isWorkoutComplete}
        >
          Complete
        </CompleteButtonText>
      </InnerCompleteButton>
    </CompleteButton>
  );

  const submitHealthItem = () => {
    if (value) {
      const payload = {
        primary1: value,
      };
      if (workoutObject.assignedId) {
        dispatch(
          submitWorkoutItem(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            activeWorkoutDate,
            workoutObject.assignedId,
            payload,
            index,
            outerIndex,
            sessionIndex,
          ),
        );
      } else {
        dispatch(
          submitProgramWorkoutItem(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            workoutObject.saveDataId,
            payload,
            index,
            outerIndex,
            sessionIndex,
            currentSelectedProgram.dayBasedProgramId,
            programDayIndex,
          ),
        );
      }
    }
  };

  return (
    <Container>
      <AdditionalInformationText>
        {`Enter current ${workoutObject.exercise.name}`}
      </AdditionalInformationText>
      <StyledInput
        placeholder={getPlaceholder()}
        value={value}
        onChange={(e) => {
          let settingValue = e.target.value;
          if (typeof e.target.value === 'string') {
            const parsedStringValue = parseInt(e.target.value, 10);
            if (parsedStringValue <= 0) {
              const positiveParsedStringValue = parsedStringValue * -1;
              settingValue = positiveParsedStringValue.toString();
            }
          }
          if (typeof e.target.value === 'number') {
            if (e.target.value <= 0) {
              const positiveValue = e.target.value * -1;
              settingValue = positiveValue;
            }
          }
          setValue(settingValue);
        }}
      />
      <ButtonContainer>
        {completeButton(submitHealthItem)}
      </ButtonContainer>
    </Container>
  );
};

HealthCard.defaultProps = {
  sessionIndex: null,
};

HealthCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default HealthCard;
