import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const AthleteRadio = ({
  athleteRadioValue,
  handleRadioChange,
  isRecoveryRadar,
}) => (
  <FormControl>
    <FormLabel
      focused={false}
      id='athlete-options'
    >
      {isRecoveryRadar ? '' : 'Athletes'}
    </FormLabel>
    <RadioGroup
      aria-labelledby='athlete-options-group-label'
      className='radioOptions'
      name='radio-buttons-group'
      onChange={handleRadioChange}
      row
      value={athleteRadioValue}
    >
      <FormControlLabel control={<Radio color='default' />} label='View All' value='all' />
      <FormControlLabel control={<Radio color='default' />} label='Alerts Only' value='alerts' />
    </RadioGroup>
  </FormControl>
);

AthleteRadio.propTypes = {
  athleteRadioValue: PropTypes.string,
  handleRadioChange: PropTypes.func,
  isRecoveryRadar: PropTypes.bool,
};

AthleteRadio.defaultProps = {
  athleteRadioValue: 'all',
  handleRadioChange: null,
  isRecoveryRadar: false,
};

export default AthleteRadio;
