/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useDispatch } from 'react-redux';

import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setShowSessionBreakItems } from '../../ducks/panelActions';

const WorkoutItemDiv = styled('div')`
	display: flex;
	cursor: pointer;
  padding-left: 20px;
  border-radius: 10px;
  background: #34495E;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IconTitle = styled('div')`
	display: flex;
  max-width: 100%;
  width: 100%;
  color: white;
`;

const TitleDesc = styled('div')`
	max-width: 100%;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
    margin-left: 1px;
  }
`;

const Title = styled('div')`
	font-size: 16px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const IconContainer = styled('div')`
	display: flex;
  position: relative;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: ${(props) => (!props.completed ? '#0dcc8a' : '1px solid white')};
	width: 30px;
	height: 30px;
  background: ${(props) => (props.completed ? '#0dcc8a' : 'white')};
`;

const IconAndLine = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	align-items: center;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.disabled ? 'rotate(0deg)' : (props.isRotated ? 'rotate(180deg)' : 'rotate(0deg)'))};
  transition: transform 0.5s ease;
  margin-right: 10px;
`;

const SessionBreak = ({
  index,
  session,
  setIsExpanded,
  isExpanded,
  disabled,
  showSessionBreakItems,
  namespace,
  fullyCompleted,
}) => {
  const [rotated, setRotated] = useState(isExpanded);
  const dispatch = useDispatch();

  // This sets the chevron correctly when session break is expanded through navigation
  useEffect(() => {
    setRotated(showSessionBreakItems);
  }, [showSessionBreakItems]);

  return (
    <>
      <WorkoutItemDiv
        onClick={() => {
          setIsExpanded(!isExpanded);
          setRotated(!rotated);
          if (showSessionBreakItems === 1) {
            dispatch(setShowSessionBreakItems(namespace, index, 0));
          } else {
            dispatch(setShowSessionBreakItems(namespace, index, 1));
          }
        }}
      >
        <IconAndLine>
          <IconContainer completed={fullyCompleted}>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon={fullyCompleted ? 'checkmark' : session?.icon}
              color={fullyCompleted ? 'white' : 'black'}
            />
          </IconContainer>
        </IconAndLine>
        <IconTitle>
          <TitleDesc>
            <Title>
              {session?.title}
            </Title>
            <SubText>
              {session?.subTitle?.toUpperCase()}
            </SubText>
          </TitleDesc>
        </IconTitle>
        <Chevron isRotated={rotated} disabled={disabled}>
          <IcomoonReact
            iconSet={iconSet}
            size={18}
            icon='down-arrow'
            color='white'
          />
        </Chevron>
      </WorkoutItemDiv>
    </>
  );
};

SessionBreak.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SessionBreak;
