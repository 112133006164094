import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';

import IconRound from './IconRound';
import Modal from './Modal';
import ButtonRound from './ButtonRound';
import {
  ColorLightestGray, ColorSecondary, TransitionGlobal,
} from '../../../../shared/GlobalStyles';

import { ModalContentWrapper } from './ModalNote';
import Title from './Title';
import Spinner from './Spinner';

const ModalText = styled('p')`
  white-space: pre-wrap;
`;

const OptOutLinks = styled('div')`
  border-top: 1px solid ${ColorLightestGray};
  display: flex;
  height: 40px;
`;

const OptOutButton = styled('button')`
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  width: 50%;
  color: ${(props) => (props.active ? (
    ColorSecondary
  ) : (
    ColorLightestGray
  ))};
  transition: ${TransitionGlobal};

  &:first-of-type {
    border-right: 1px solid ${ColorLightestGray};
  }
`;

const FormGroup = styled('div')`
  padding: 20px calc(30px * 3);
  @media only screen and (max-height: 767px) {
    padding: 10px 30px; 
    .form-control {
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 1023px) {
    padding: 20px 15px;
  }
`;

const MessageContainer = styled('p')`
  text-align: center;
  margin-top: 5px;
  color: ${(props) => props.theme.textColor};
`;

const VideoButton = styled('div')`
  position: absolute;
  right: 80px;
  margin: 0px 5px 0px 30px;
  cursor: pointer;
  margin-top: -30px;

  @media only screen and (max-width: 1023px) {
    right: 15px;
  }
`;

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  min-height: 420px;
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

const validate = (values, props) => {
  const errors = {};

  if (values.body.optOut === 0) {
    errors.optOut = 'Reason Required';
  }

  if (values.body.optOut === 4 && values.body.optOutReason === '') {
    errors.optOutReason = 'Note Required';
  }

  return errors;
};

const ModalOptOut = ({
  optOutProgramWorkout,
  optOutWorkout,
  namespace,
  instruction,
  closeModal,
  name,
  workId,
  workout,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const activeExerType = useSelector((state) => state.weightRoomView[namespace].activeExerType);
  const activeWorkoutIndex = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeWorkoutIndex);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isLoadingExercises = useSelector((
    state,
  ) => state.weightRoomView[namespace].isLoadingExercises);
  const isLoadingSimExercises = useSelector((
    state,
  ) => state.weightRoomView[namespace].isLoadingSimExercises);
  const isProgramView = useSelector((state) => state.weightRoomView[namespace].isProgramView);
  const exercises = useSelector((state) => state.weightRoomView[namespace].exercises);
  const exercisesSimilar = useSelector((state) => state.weightRoomView[namespace].exercisesSimilar);

  // exercises and exercisesSimilar need to have values and labels attached
  // in order for React select to render correctly
  useEffect(() => {
    if (exercises && exercises.length > 0) {
      exercises.forEach((obj) => {
        obj.label = obj.name;
        obj.value = obj.id;
      });
    }
    if (exercisesSimilar && exercisesSimilar.length > 0) {
      exercisesSimilar.forEach((obj) => {
        obj.label = obj.name;
        obj.value = obj.id;
      });
    }
  }, [exercises, exercisesSimilar]);

  let optedOutReason = {};
  switch (workout.optedOutReason) {
    case 'INJURY':
      optedOutReason.value = 1;
      optedOutReason.label = 'Injury';
      break;
    case 'PROHIBITED':
      optedOutReason.value = 2;
      optedOutReason.label = 'Prohibited';
      break;
    case 'LACK OF EQUIPMENT':
      optedOutReason.value = 3;
      optedOutReason.label = 'Lack of Equipment';
      break;
    case 'SUBSTITUTION':
      optedOutReason.value = 4;
      optedOutReason.label = 'Other (explain below)';
      break;
    default:
      optedOutReason = 0;
  }

  const [exerciseView, setExerciseView] = useState('similar');
  const [isVidModalOpen, setIsVidModalOpen] = useState(false);
  const [previewVideo, setPreviewVideo] = useState('');

  const handleAllClick = () => {
    setExerciseView('all');
    setPreviewVideo('');
  };

  const handleSimilarClick = () => {
    setExerciseView('similar');
    setPreviewVideo('');
  };

  const handleModalOpen = () => {
    setIsVidModalOpen(true);
  };

  const handleModalClose = () => {
    setIsVidModalOpen(false);
  };

  const storeVideoPreview = (video) => {
    setPreviewVideo(video);
  };

  const isExerciseSimilar = exerciseView === 'similar';
  const isLiftType = activeExerType === 'L';
  const isTagType = activeExerType === 'T';

  const selectRef = React.createRef();

  const optOutReasons = [
    { value: 1, label: 'Injury' },
    { value: 2, label: 'Prohibited' },
    { value: 3, label: 'Lack of Equipment' },
    { value: 4, label: 'Other (explain below)' },
  ];

  const defaultReasonFunction = (reasonId) => {
    let returnReason;
    optOutReasons.forEach((optOutReason) => {
      if (optOutReason.value === reasonId) {
        returnReason = optOutReason;
      }
    });
    return returnReason;
  };

  return (
    <ModalContentWrapper>
      <Title
        icon='opt-out'
      >
        {name}
      </Title>
      { isLoadingSimExercises === false && isLoadingExercises === false ? (
        <Formik
          initialValues={
            workout.optedOut ? (
              {
                workId,
                userId: athleteId,
                assignedDate: moment(activeDate).format('YYYY-MM-DD'),
                activeWorkoutIndex,
                body: {
                  optOut: optedOutReason.value,
                  substitutedExerciseId: 0,
                  optOutReason:
                    workout.journalEntries[0]?.body !== null
                      ? workout.journalEntries[0]?.body : '',
                },
              }
            ) : (
              {
                workId,
                userId: athleteId,
                assignedDate: moment(activeDate).format('YYYY-MM-DD'),
                activeWorkoutIndex,
                body: {
                  optOut: 0,
                  substitutedExerciseId: 0,
                  optOutReason: '',
                },
              }
            )
          }
          isInitialValid={false}
          validate={validate}
          onSubmit={(values, actions) => {
            if (isProgramView) {
              setTimeout(() => {
                optOutProgramWorkout(
                  namespace,
                  accountCode,
                  currentSelectedProgram.dayBasedProgramId,
                  currentNonDateDay.trueDayNum,
                  values,
                );
                closeModal(namespace);
                actions.setSubmitting(false);
              }, 1000);
            } else {
              setTimeout(() => {
                optOutWorkout(namespace, accountCode, values);
                closeModal(namespace);
                actions.setSubmitting(false);
              }, 1000);
            }
          }}
          render={(props) => {
            const similarExercisesPicker = exercisesSimilar.length > 0 ? (
              <Select
                classNamePrefix='select'
                onChange={
                  (options) => {
                    props.setFieldValue('body.substitutedExerciseId', options.id);
                    if (options.media) {
                      storeVideoPreview(options.media[0].mediaUrl);
                    }
                  }
                }
                options={exercisesSimilar}
                value={props.values.body.substitutedExerciseId.value}
              />
            ) : (
              <MessageContainer>
                No Similar Exercises
              </MessageContainer>
            );

            const isPreviewVideoEmpty = (previewVideo !== '') && (previewVideo !== null);

            return (
              <Form>
                <FormGroup>
                  <label>Select Reason for Opt Out</label>
                  <Select
                    classNamePrefix='select'
                    onChange={(options) => {
                      props.setFieldValue('body.optOut', options.value);
                    }}
                    options={optOutReasons}
                    defaultValue={defaultReasonFunction(props.values.body.optOut)}
                  />
                  {props.errors.optOut && <FieldError>{props.errors.optOut}</FieldError>}

                </FormGroup>

                { (isLiftType || isTagType) && (
                  <FormGroup>
                    <label>Substitute with Another Exercise</label>
                    <OptOutLinks>
                      <OptOutButton
                        active={exerciseView === 'similar'}
                        onClick={handleSimilarClick}
                        type='button'
                      >
                        Similar
                      </OptOutButton>
                      <OptOutButton
                        active={exerciseView === 'all'}
                        onClick={handleAllClick}
                        type='button'
                      >
                        All Exercises
                      </OptOutButton>
                    </OptOutLinks>

                    { isExerciseSimilar ? (
                      similarExercisesPicker
                    ) : (
                      <>
                        <Select
                          classNamePrefix='select'
                          onChange={
                            (options) => {
                              props.setFieldValue('body.substitutedExerciseId', options.value);
                              if (options.media) {
                                storeVideoPreview(options.media[0].mediaUrl);
                              }
                            }
                          }
                          options={exercises}
                          ref={selectRef}
                          selectProps={{ handleModalClose, handleModalOpen }}
                          value={props.values.body.substitutedExerciseId.value}
                        />

                      </>
                    )}
                    {isPreviewVideoEmpty && (
                      <VideoButton onClick={() => handleModalOpen()}>
                        <IconRound
                          icon='video'
                          large
                        />
                      </VideoButton>
                    )}
                  </FormGroup>
                )}

                <FormGroup>
                  <label>Note</label>
                  <textarea
                    className='form-control'
                    onChange={props.handleChange}
                    name='body.optOutReason'
                    type='text'
                    defaultValue={props.values.body.optOutReason}
                  />
                  {props.errors.optOutReason && !props.touched.optOutReason
                    && <FieldError>{props.errors.optOutReason}</FieldError>}

                </FormGroup>
                <ButtonRound
                  bottom
                  fullWidth
                  cta='Submit'
                  className='modal-button'
                  large
                  noBorder
                  primary
                  square
                  onClick={props.onSubmit}
                />
              </Form>
            );
          }}
        />
      ) : (
        <Spinner />
      )}
      <ModalText>
        {instruction}
      </ModalText>
      <Modal
        isOpen={isVidModalOpen}
        onRequestClose={handleModalClose}
      >
        <StyledIframe
          className='video-iframe'
          src={previewVideo}
        />
      </Modal>
    </ModalContentWrapper>
  );
};

ModalOptOut.propTypes = {
  optOutProgramWorkout: PropTypes.func.isRequired,
  optOutWorkout: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  instruction: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  workId: PropTypes.number.isRequired,
  workout: PropTypes.string.isRequired,
};

export default ModalOptOut;
