/**
 * file for importing actions and wrapping them in functions for export to calendars.js
 * also for importing various slices of state for use in calendars.js
 */

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFirstWorkout,
} from '../../ducks/resellerActions';

const useResellerPrograms = () => {
  const firstWorkout = useSelector((state) => state.reseller.data.firstWorkout);
  const firstWorkoutError = useSelector((state) => state.reseller.data.firstWorkoutError);
  const isFirstWorkoutFetching = useSelector((state) => state.reseller.ui.isFirstWorkoutFetching);

  const dispatch = useDispatch();

  const handleFetchFirstWorkout = (values) => {
    dispatch(fetchFirstWorkout(values));
  };

  return {
    firstWorkout,
    firstWorkoutError,
    isFirstWorkoutFetching,
    handleFetchFirstWorkout,
  };
};

export default useResellerPrograms;
