import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import fitvids from 'fitvids';

import { ModalContentWrapper } from './ModalNote';
import Title from './Title';

fitvids();

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  min-height: 420px;
  margin-top: 30px;
`;

const StyledVideo = styled('video')`
  border: 0;
  width: 100%;
  min-height: 420px;
  margin-top: 30px;
`;

class ModalVideo extends PureComponent {
  componentDidMount() {
  }

  render() {
    const {
      name,
      video,
    } = this.props;

    return (
      <ModalContentWrapper>
        <Title
          icon='video'
        >
          {name}
        </Title>
        {video.includes('s3.amazon')
          ? (
            <StyledVideo
              className='video-iframe'
              src={video}
              controls
              controlsList='nodownload'
            >
              <source src={video} type='video/mp4' />
            </StyledVideo>
          ) : (
            <StyledIframe
              className='video-iframe'
              src={video}
            />
          )}
      </ModalContentWrapper>
    );
  }
}

ModalVideo.propTypes = {
  name: PropTypes.string.isRequired,
  video: PropTypes.string.isRequired,
};

export default ModalVideo;
