/**
 * Component that represents a single filter type, e.g. Feed with interior
 * subtypes, e.g. feedPosts, feedPostComments, feedPostFistBumps, etc.
 * This component dynamic and responds to changes in global state
 * by collapsing/expanding depending on user actions
 */

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import IcomoonReact from 'icomoon-react';
import FilterSubtype from './FilterSubtype';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setLocalNotificationFilters } from '../../ducks/notificationsActions';

const FilterHeader = styled('div')`
	min-height: 50px;
	display: flex;
	align-items: center;
	padding-right: 25px;
	justify-content: space-between;
  width: 100%;
`;

const FilterContainer = styled('div')`
	display: flex;
	flex-direction: column;
	cursor: pointer;
`;

const FilterText = styled('div')`

`;

const FilterSubtypes = styled('div')`
  max-height: ${(props) => (props.isExposed ? `${props.heightCalc}px` : '0px')};
	overflow: hidden;
	-webkit-transition: max-height 0.5s ease;
	-moz-transition: max-height 0.5s ease;
	transition: max-height 0.5s ease;
	padding-left: 25px;
	background: #F5F5F5;
`;

const CheckBoxAndText = styled('div')`
	display: flex;
	align-items: center;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: 0.5s ease;
`;

const InnerContainer = styled('div')`
  display: flex;
  width: 100%;
  padding-left: 10px;
`;

/**
 *
 * @param {Array} subTypes list of notification types that exist within the broader notification
 * type
 * @param {String} headerType the name of the broader notification type
 * @returns the Filter component, which affects and is affected by the interior filter sub types.
 * When interior subtypes are all deselected, this component's checkbox is deselected.  When this
 * components checkbox is selected and none of the interior filter subtypes
 * are selected, all interior subtypes are then selected.  When this component's checkbox is
 * selected and only some of the interior filter subtypes are selected, all filter subtype's
 * checkboxes are then selected.  If all or some intererior filter subtypes
 * checkboxes are selected, this component's checkbox is then automatically selected.
 * When this components checkbox is selected and all of the
 * interior filter subtype's checkboxes are selected, all interior filter subtypes checkboxes
 * are then deselected and all checkboxes are cleared.
 * Clicking on any part of the Filter component that isn't the checkbox, will collapse/expand
 * the accordion action of the filter component.
 */
const Filter = ({
  subTypes,
  headerType,
  // containerRef,
  // idx,
  // autoScroll,
  // isFirst,
}) => {
  // local state that determins whether the component should be expanded or collapsed
  const [isExposed, setIsExposed] = useState(false);
  /**
 * local state that tracks how many interior elements have been actively (not automatically)
 * been selected
 */
  // global state that represents which filters are currently active
  const localNotificationFilters = useSelector(
    (state) => state.notifications.data.localNotificationFilters,
  );
  // global ui state keeps track of whether filtering is pinging backend API
  const isNotificationsLoading = useSelector(
    (state) => state.notifications.ui.isNotificationsLoading,
  );

  const dispatch = useDispatch();

  const filterRef = useRef();

  /**
 * responds when filters have been selected or when backend API has been pinged
 * checks to see if active filters have been reduced to zero, and if so,
 * reduces the number of actively set filters to zero and collapses the accordion.
 * This function is necessary because it tracks the behavior of the 'clear all'
 * button that lives in the FilterDropdown component.
 * i.e. if the 'clear all' button has been pressed, we want zeroCount to be
 * automatically set to zero and the accordion to collapse
 */
  useEffect(() => {
    if (Object.keys(localNotificationFilters).length === 0) {
      if (!isNotificationsLoading) {
        setIsExposed(false);
      }
    }
  }, [localNotificationFilters, isNotificationsLoading]);

  /**
 * Every time the component loads, check to see if the present filter is
 * indicated in the list of active filters, and if so, expand the accordion.
 * This method is necessary because if the 'select all' button that lives
 * in the broader Filter component is selected, we want to automatically
 * expand the expand the accordion so that it's clear that all intorior
 * SubTypes have been selected
 */
  useEffect(() => {
    if (localNotificationFilters[headerType]) {
      setIsExposed(true);
    }
  });

  // optional useEffect that triggers scroll-to scrolljacking
  // useEffect(() => {
  //   if (autoScroll && (isExposed && idx !== 0)) {
  //     setTimeout(() => {
  //       containerRef?.current?.scroll(
  //         {
  //           top: filterRef?.current.offsetTop + (subTypes.length > 1 ? (
  //             (subTypes.length - 1) * 50) : 50),
  //           left: 0,
  //           behavior: 'smooth',
  //         },
  //       );
  //     }, 250);
  //   }
  // }, [isExposed]);

  return (
    <FilterContainer ref={filterRef}>
      <InnerContainer>
        {/**
         * responsive checkbox - is only checked when the current primary
         * notification type exists in the active notification types object.
         * When checked, determines if the current type does exist in the
         * active filters.  If not, it adds it as a key, sets it to true,
         * expands the accordion action, iterates the subtypes array,
         * adds each subtype to the local notification filters, and sets them
         * to true, and sets the selected zero count to the length of the
         * subtype array so that user action can be tracked.
         * If it does exist in the active filter, it sets it to false
         * and deselects all subtypes by setting them all to false and setting zero count
         * to zero.  Notice that there's a difference between the primary notification
         * type being set to false in the active filters, and the primary notification
         * being deleted from the active filters entirely.
         */}
        <Checkbox
          color='primary'
          checked={localNotificationFilters[headerType] || false}
          onChange={() => {
            const newLocalNotificationFilters = { ...localNotificationFilters };
            if (!localNotificationFilters[headerType]) {
              newLocalNotificationFilters[headerType] = true;
              setIsExposed(true);
              subTypes.forEach((subType) => {
                newLocalNotificationFilters[subType.notificationKey] = true;
              });
            } else {
              newLocalNotificationFilters[headerType] = false;
              subTypes.forEach((subType) => {
                newLocalNotificationFilters[subType.notificationKey] = false;
              });
            }
            dispatch(setLocalNotificationFilters(newLocalNotificationFilters));
          }}
        />
        {/**
         * If anything other than the checkbox is clicked, the filter header is
         * expanded/collapsed depending on its current state
         */}
        <FilterHeader onClick={() => setIsExposed(!isExposed)}>
          <CheckBoxAndText>
            {/**
             * display the primary notification type name
             */}
            <FilterText>
              {headerType}
            </FilterText>
          </CheckBoxAndText>
          {/**
           * chevron rotates back and forth by 90 degrees depeding on
           * whether the primary notification accordion is currently
           * expanded/collapsed
           */}
          <Chevron isRotated={isExposed}>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='altrightarrow'
              color='black'
            />
          </Chevron>
        </FilterHeader>
      </InnerContainer>
      {/**
       * Filter subtypes section that's height is calculated by the number of
       * subtypes in the array.  Also certain css properties are determined by
       * the current expanded/collapsed state of the primary type
       */}
      <FilterSubtypes heightCalc={(subTypes.length * 50).toString()} isExposed={isExposed}>
        {/**
         * maps the subTypes array and returns a FilterSubType for each sub type in the array
         */}
        {subTypes.map(
          (subType) => (
            <FilterSubtype
              subType={subType}
            />
          ),
        )}
      </FilterSubtypes>
    </FilterContainer>
  );
};

Filter.propTypes = {
  headerType: PropTypes.string.isRequired,
  subTypes: PropTypes.instanceOf(Array).isRequired,
  // containerRef: PropTypes.instanceOf(Object).isRequired,
  // idx: PropTypes.number.isRequired,
  // autoScroll: PropTypes.bool.isRequired,
  // isFirst: PropTypes.bool.isRequired,
};

export default Filter;
