const imageOptimizer = (imagePath, edits) => {
  const imagePathArray = imagePath.split('/');
  const dynamicPath = `${imagePathArray.slice(4).join('/')}`;

  const imageRequest = JSON.stringify({
    bucket: imagePathArray[3],
    key: dynamicPath,
    edits,
  });

  const url = `${process.env.CLOUDFRONT_URL}${btoa(imageRequest)}`;

  return url;
};

// To debug images use the Demo UI: https://dhv2jjffy0lhl.cloudfront.net/index.html
export default imageOptimizer;
