/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const Wrapper = styled('div')`
display: flex;
flex-direction: column;
border-radius: 8px;
background: #F6F6F6;
padding: 12px;
gap: 12px;
width: 100%;
`;

const HeaderContainer = styled('div')`
display: flex;
flex-direction: column;
gap: 4px;
`;

const WidgetContainer = styled('div')`
display: flex;
flex-wrap: ${(props) => (props.containerWidth < 400 ? 'wrap' : '')};
flex-direction: row;
gap: 12px;
`;

const Seperator = styled('div')`
display: flex;
width: 100%;
height: 1px;
background-color: #444444;
`;

const Widget = styled('div')`
display: flex;
flex-direction: column;
width: ${(props) => (props.containerWidth < 400 ? '100%' : '247px')};
height: 74px;
border-radius: 12px;
border-left: 6px solid ${(props) => (props.widgetColor ? props.widgetColor : '#9E9E9E')};
padding: 12px;
box-shadow: 0px 2px 4px 0px #3031331A;
background-color: ${(props) => props.backgroundColor};
`;

const WidgetDot = styled('div')`
display: flex;
width: 10px;
height: 10px;
border-radius: 50%;
background-color: ${(props) => props.backgroundColor};
`;

const WidgetDash = styled('div')`
display: flex;
width: 75px;
height: 10px;
border-radius: 200px;
background-color: ${(props) => props.backgroundColor};
`;

const IconCircle = styled('div')`
display: flex;
width: 36px;
min-width: 36px;
height: 36px;
min-height: 36px;
border: 2px solid ${(props) => (props.widgetColor ? props.widgetColor : '#9E9E9E')};
border-radius: 50%;
justify-content: center;
align-items: center;
`;

const DotAndIconContainer = styled('div')`
display: flex;
flex-direction: row;
with: 100%;
justify-content: space-between;
align-items: end;
`;

const MobileWidgetPreview = ({
  currentlySelectedColor, selectedIcon, currentleySelectedCategory, categories,
}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [widgetColor, setWidgetColor] = useState(null);

  const getWidgetColor = () => {
    const chosenCategoryObject = categories.find((category) => category.id === currentleySelectedCategory);
    if (chosenCategoryObject?.color) {
      return chosenCategoryObject.color;
    }
    return null;
  };

  useEffect(() => {
    if (currentlySelectedColor) {
      setWidgetColor(currentlySelectedColor);
    } else if (currentleySelectedCategory) {
      setWidgetColor(getWidgetColor());
    } else {
      setWidgetColor(null);
    }
  }, [currentleySelectedCategory, currentlySelectedColor]);

  return (
    <Wrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <HeaderContainer>
        <Text fontWeight={700} fontSize='16px'>Mobile widget preview</Text>
        <Text fontWeight={400} fontSize='11px'>Color and icon is determined by category selection.</Text>
      </HeaderContainer>
      <Seperator />
      <WidgetContainer containerWidth={containerWidth}>
        <Widget containerWidth={containerWidth} backgroundColor='white' widgetColor={widgetColor}>
          <WidgetDash backgroundColor='#1E1E1E' />
          <DotAndIconContainer>
            {selectedIcon ? (
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon={selectedIcon}
                color={widgetColor || '#1E1E1E'}
              />
            ) : (
              <WidgetDot backgroundColor='#1E1E1E' />
            )}
            <IconCircle widgetColor={widgetColor}>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='checkmark'
                color={widgetColor || '#9E9E9E'}
              />
            </IconCircle>
          </DotAndIconContainer>
        </Widget>
        <Widget containerWidth={containerWidth} backgroundColor='#1E1E1E' widgetColor={widgetColor}>
          <WidgetDash backgroundColor='white' />
          <DotAndIconContainer>
            {selectedIcon ? (
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon={selectedIcon}
                color={widgetColor || '#9E9E9E'}
              />
            ) : (
              <WidgetDot backgroundColor='#9E9E9E' />
            )}
            <IconCircle widgetColor={widgetColor}>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='checkmark'
                color={widgetColor || '#9E9E9E'}
              />
            </IconCircle>
          </DotAndIconContainer>
        </Widget>
      </WidgetContainer>
    </Wrapper>

  );
};

MobileWidgetPreview.propTypes = {
  currentlySelectedColor: PropTypes.string,
  currentleySelectedCategory: PropTypes.number,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  selectedIcon: PropTypes.string,
};

MobileWidgetPreview.defaultProps = {
  currentlySelectedColor: null,
  currentleySelectedCategory: null,
  categories: null,
  selectedIcon: null,
};

export default MobileWidgetPreview;
