import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import Text from '../../../../../shared/components/Text/Text';

const WelcomeContainer = styled('div')`
  max-width: 600px;
  width: 100%;
  margin-top: 60px;
`;

const QuestionContainer = styled('div')`
  margin-bottom: 30px;

  .question-text {
    margin-bottom: 15px;
  }
`;

const WelcomeFAQ = () => {
  const theme = useTheme();

  return (
    <WelcomeContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>What is the Payments Portal?</Text>
        <Text>
          The Payments Portal lets you sell training programs via TeamBuildr by automatically taking payment and registering clients onto the TeamBuildr app.
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>Is this included in my TeamBuildr subscription?</Text>
        <Text>
          Yes. You must have a paid TeamBuildr subscription to access the Online Payments feature. TeamBuildr charges no commissions for your sales except for online
          {' '}
          <a target='_blank' rel='noreferrer' href='https://support.teambuildr.com/article/d2cynfitju-what-are-the-stripe-transaction-charges'>credit card processing fees</a>
          .
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>Do paying subscribers count towards my subscription's athlete account (Eg: Silver plan - 50 athletes)?</Text>
        <Text>
          Yes. Your payment portal subscribers will count against your athlete/client number from your subscription. Eg: Silver plan allows up to 50 total users whether they are manually added or added via the Payments Portal.
          {' '}
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>How does payment processing work?</Text>
        <Text>
          We are integrated with Stripe - an online payment processor. You can create or connect a Stripe account in this portal. Payments from clients flow directly to your bank account minus the
          {' '}
          <a target='_blank' rel='noreferrer' href='https://support.teambuildr.com/article/d2cynfitju-what-are-the-stripe-transaction-charges'>Stripe processing fee</a>
          {' '}
          which varies by country.
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>My TeamBuildr account is paid through my employer (school or organization), can I still use the Payments Portal?</Text>
        <Text>
          Yes, but you may consider opening your own separate TeamBuildr account to keep the businesses separate. If you create a personal account, we can copy your exercises and programming over from the existing account.
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>I don’t plan on using Payments, can I remove the tab from my account?</Text>
        <Text>
          Yes, in your Organization’s Settings. You can also limit the Payment tab visibility to only you or specific coaches in your Settings as well.
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>How can I price my programs?</Text>
        <Text>
          Your programs can be priced on a monthly, 3-month, 6-month or yearly basis. You can also create a one-time purchase or a recurring purchase product.
        </Text>
      </QuestionContainer>
      <QuestionContainer>
        <Text className='question-text' fontSize={theme.headerFontSizes[2]}>Do you provide any integrations?</Text>
        <Text>
          Yes, we provide integrations with Stripe and with Zapier, which allows you to integrate with hundreds of marketing apps like MailChimp, etc. Note that the Zapier integration is only available with the Gold plan and up.
          {' '}
        </Text>
      </QuestionContainer>
    </WelcomeContainer>
  );
};

export default WelcomeFAQ;
