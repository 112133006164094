import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import Shimmer from 'react-shimmer-effect';
import { useCookies } from 'react-cookie';
import Logger from 'js-logger';

import { fetchProgram } from '../../ducks/programsActions';
import { fetchUserData } from '../../../login/ducks/loginActions';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import ProgramDetails from './ProgramDetails';
import ProgramExternalCheckout from './ProgramExternalCheckout';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import ProgramPage from './ProgramPage';

const ProgramContainer = styled('div')`
  height: 100%;
`;

const PageWrapper = styled('div')`
  #font-picker {
    position: fixed;
    top: -10000px;
    z-index: -1;
  }
`;

const BreakHeaderText = styled('h1')`
  margin-bottom: 0px;
  font-family: 'BLACKHAWK';
  color: white;
  font-size: 64px;
  white-space: normal;
  word-break: break-word;
  hyphens: auto;
`;

const FeatureContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 360px;
  background: #1d1d1d;
  background-size: cover;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    opacity: .1;
    width: 100%;
    height: 100%;
  }

  h1, span {
    ${(props) => (props.isLoadingProgram ? 'color: transparent' : 'color: white')};
  }

  span {
    margin-top: 15px;
  }
`;

const ProgramTitle = styled('div')`
  position: absolute;
  text-align: center;
`;

const Program = () => {
  const [cookies, setCookies] = useCookies(['v2token']);
  const { resellerSlug, programSlug } = useParams();
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoadingProgram = useSelector((state) => state.programs.ui.isLoadingProgram);
  const programData = useSelector((state) => state.programs.data.programData);
  const isRegisteredInClient = useSelector((state) => state.auth.ui.isRegisteredInClient);
  const isAuthenticated = useSelector((state) => state.auth.ui.isAuthenticated);
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
    && cookies.accessToken !== undefined && cookies.accessToken !== '0'
    && cookies.session_id !== undefined && cookies.session_id !== '0'
    && cookies.session_key !== undefined && cookies.session_key !== '0';

  const {
    headerMediaKey,
    programDescriptionShort,
    programName,
  } = programData;

  const headerImage = optimizeImage(
    `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${headerMediaKey}`,
    {
      resize: {
        with: 800, fit: 'cover',
      },
    },
  );

  useEffect(() => {
    if (isAuthenticatedv3) {
      dispatch(fetchUserData());
    }
  }, [isAuthenticatedv3]);

  useEffect(() => {
    // Fetch program data using the accountName and programName
    // We're using useLocation hook to monitor path changes.
    // Listening for path changes with useRouteMatch didn't work.
    dispatch(fetchProgram(resellerSlug, programSlug));
  }, [location]);

  Logger.debug('Params:', resellerSlug, programSlug);
  Logger.debug('Path:', path);
  Logger.debug(':', window.location.href);

  return (
    <ProgramContainer>
      <Helmet>
        <title>{`${programName} | TeamBuildr`}</title>
        <meta property='og:title' content={programName} />
        <meta property='og:description' content={programDescriptionShort} />
        <meta property='og:image' content={`https://api.urlbox.io/v1/fdqyKWxPSJnoxhLN/png?url=${encodeURIComponent(window.location.href)}&width=800&height=420&selector=%23FeatureContainer`} />
        <meta property='og:url' content={window.location.href} />
        <meta name='twitter:card' content={headerImage} />
        <meta property='og:site_name' content={programName} />
        <meta name='twitter:image:alt' content={programDescriptionShort} />
      </Helmet>
      <Switch>
        <Route exact path={`${path}/checkout`}>
          <ProgramExternalCheckout userName={currentUser && `${currentUser.first} ${currentUser.last}`} />
        </Route>
        <Route exact path={`${path}`}>
          {isLoadingProgram ? (
            <PageWrapper>
              <FeatureContainer id='FeatureContainer' isLoadingProgram={isLoadingProgram}>
                <ProgramTitle>
                  <Shimmer>
                    <HeaderText fontSize={74}>{programSlug}</HeaderText>
                  </Shimmer>
                </ProgramTitle>
              </FeatureContainer>
              <div className='container'>
                <ProgramDetails
                  isLoadingProgram={isLoadingProgram}
                />
              </div>
            </PageWrapper>
          ) : (
            <ProgramPage
              programData={programData}
              isAuthenticatedv3={isAuthenticatedv3}
              isLoadingProgram={isLoadingProgram}
              isRegisteredInClient={isRegisteredInClient}
              isAuthenticated={isAuthenticated}
              currentUser={currentUser}
            />
          )}
        </Route>
      </Switch>
    </ProgramContainer>
  );
};

export default Program;
