/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useDispatch } from 'react-redux';

import iconSet from '../../../../../../shared/images/teambuildr-selection.json';
import getWindowDimensions from './getWindowDimensions';
import { setNotificationSettingsModal } from '../../../../ducks/notificationsActions';

const NotificationWrapperDiv = styled('div')`
	width: 100%;
	height: 100%;
	padding-top: 30px;
	background-color: #fbfbfb;
	overflow-y: auto;
	z-index: 100;
	
	.dot-box {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		cursor: pointer;
		:hover {
			background: rgba(0,0,0,0.1);
		}
	}
	.settings-gear {
		position: absolute;
		margin-top: -20px;
		margin-right: 10px;
		right: 0;
		cursor: pointer;
		padding: 5px;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		:hover {
			background: rgba(0,0,0,0.1);
			border-radius: 50%;
		}
	}
`;

/**
 *
 * @param {Node} children content passed in from parent function
 * @returns a wrapper that adds a gear to allow the user to open
 * the notification settings modal where they can choose which
 * notifications that would like to see
 */

const NotificationWrapper = ({ children }) => {
  const dispatch = useDispatch();
  return (
    <NotificationWrapperDiv>
      {getWindowDimensions().width > 767 ? (
        <div
          onClick={() => {
            dispatch(setNotificationSettingsModal());
          }}
          className='settings-gear'
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='settings'
          />
        </div>
      ) : null}
      {children}
    </NotificationWrapperDiv>
  );
};

NotificationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationWrapper;
