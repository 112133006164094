import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useTheme } from 'emotion-theming';
import Helmet from 'react-helmet';
import CustomReactPlayer from './CustomReactPlayer';
import ModalHandler from './ModalHandler';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import Layout from '../../shared/components/Layout/Layout';
import AdminPanel from './components/presentational/reseller/adminPanel';
import Spinner from '../../shared/components/Spinner/Spinner';
import WelcomeFAQ from './components/presentational/reseller/WelcomeFAQ';
import AlertBanner from '../../shared/components/AlertBanner/AlertBanner';
import Text from '../../shared/components/Text/Text';
import { fetchReseller, connectStripeAccount } from './ducks/resellerActions';

const Reseller = (({ match }) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isResellerLoading = useSelector(
    (state) => state.reseller.ui.isResellerLoading,
  );
  const location = useSelector((state) => state.router.location.pathname);
  const connectionComplete = useSelector(
    (state) => state.router.location.query.connectionComplete,
  );
  const stripeAuthCode = useSelector(
    (state) => state.router.location.query.code,
  );
  const stripeStateToken = useSelector(
    (state) => state.router.location.query.state,
  );
  const didStandardConnectionComplete = useSelector(
    (state) => state.reseller.data.didStandardConnectionComplete,
  );
  const isStripeAccountConnected = useSelector(
    (state) => state.reseller.data.isStripeAccountConnected,
  );
  const connectStripeResellerError = useSelector(
    (state) => state.reseller.data.connectStripeResellerError,
  );
  const isResellerFetched = useSelector(
    (state) => state.reseller.data.isResellerFetched,
  );

  const dispatch = useDispatch();
  const theme = useTheme();

  const handleFetchReseller = (values) => {
    dispatch(fetchReseller(values));
  };

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !isResellerLoading && !isResellerFetched) {
      if (stripeAuthCode && stripeStateToken) {
        dispatch(
          connectStripeAccount({
            accountCode,
            stripeAuthCode,
            stripeStateToken,
          }),
        );
      }
      handleFetchReseller(accountCode);
    }
  }, [currentUser]);

  const HeaderVideoPlayerButtonContainer = styled('div')`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 60px 0px;
  `;

  const HeaderWrapper = styled('div')`
    margin-bottom: 50px;
  `;

  const SpinnerWrapper = styled('div')`
    position: absolute;
    display: flex;
    height: 50%;
    justify-content: center;
    width: 100%;
  `;

  // Check if the currentUser should be allowed to reach /reseller
  // If they don't have access, redirect them to dashboard
  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length > 0) {
      const hasAccess = (currentUser
        && (currentUser.organizationResellerAccountEnabled || currentUser.primaryAdmin > 0)
        && currentUser.userSettings.reseller === 1);

      if (!hasAccess) {
        dispatch(push('/'));
      }
    }
  }, [currentUser]);

  if (
    isResellerLoading
    || !Object.keys(currentUser).length
    || isStripeAccountConnected === undefined
  ) {
    return (
      <Layout>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      </Layout>
    );
  }

  if (isStripeAccountConnected) {
    if (location === '/reseller') {
      if (connectionComplete || didStandardConnectionComplete) {
        dispatch(push('/reseller/programs?connectionComplete=true'));
      } else {
        dispatch(push('/reseller/programs'));
      }
    }
    return <AdminPanel match={match} connectionComplete={connectionComplete} />;
  }
  if (!isStripeAccountConnected) {
    return (
      <Layout>
        <Helmet>
          <title>Payments Portal | TeamBuildr</title>
        </Helmet>
        {connectStripeResellerError && (
          <div>
            <AlertBanner>
              <Text>{connectStripeResellerError}</Text>
            </AlertBanner>
          </div>
        )}
        <HeaderVideoPlayerButtonContainer>
          <HeaderWrapper>
            <HeaderText
              fontSize={theme.headerFontSizes[0]}
              fontWeight='300'
              letterSpacing='normal'
            >
              Welcome to the Online Payments Portal
            </HeaderText>
          </HeaderWrapper>
          <CustomReactPlayer />
          <ModalHandler />
          {/* <LearnMoreWrapper>
            <Text>Want to learn more?</Text>
            <Text className='click-here'>Click here</Text>
          </LearnMoreWrapper> */}
          <WelcomeFAQ />
        </HeaderVideoPlayerButtonContainer>
      </Layout>
    );
  }
});

Reseller.propTypes = { match: PropTypes.instanceOf(Object).isRequired };

export default React.memo(Reseller);
