import React from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Form, FieldArray,
} from 'formik';
import Logger from 'js-logger';
import moment from 'moment';

import CompletionEntry from './WorkoutEntriesCompletion';
import WorkoutEntryTable from './WorkoutEntryTable';

const SaqEntries = ({
  athleteId,
  activeDate,
  entriesViewHeight,
  formikRef,
  handleInfoClick,
  index,
  isWeightValueEnabled,
  namespace,
  supersetWorkout,
  updateWorkout,
  workout,
  wrvSupersetsEnabled,
}) => {
  // SAQ Render Conditions

  const isCompletion = workout.new_version.formType === 'CompletionOnly';
  const isWrvSupersetsEnabled = wrvSupersetsEnabled;

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      initialValues={
        (isCompletion || workout.new_version.completionCheckbox) ? (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                complete: workout.result.completed,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              complete: '0',
            });
          })()
        ) : (
          {
            work_id: workout.work_id,
            user_id: athleteId,
            assigned_date: moment(activeDate).format('YYYY-MM-DD'),
            result:
                // Create dynamic workout submission by filtering out if its not readOnly,
                // and mapping through the set data array and adding valueName.
                workout.new_version.data.map((set, i) => ({
                  set_id: i + 1,
                  values:
                    set.values.filter((value) => !value.readOnly).map((value) => ({
                      valueName: value.valueName,
                      value: (!value.value && (isWeightValueEnabled && value.placeholder))
                        ? value.placeholder
                        : value.value,
                    })),
                })),
            // ),
          }
        )
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        // alert(JSON.stringify(values, null, 2))
        Logger.debug('Values:', values);
        actions.setSubmitting(false);
      }}
      render={(props) => (
        <Form>
          <FieldArray
            name='result'
            render={() => (
              <>
                {/* Show completion checkbox if completionCheckbox is true. */}
                {workout.new_version.formType === 'CompletionOnly' ? (
                  <CompletionEntry
                    handleInfoClick={handleInfoClick}
                    handleSubmit={props.handleSubmit}
                    info={workout.attributes.info}
                    setFieldValue={props.setFieldValue}
                    subHeader={workout.sub_header}
                    values={props.values}
                  />
                ) : (
                  // Show workout entry table if completionCheckbox is false.
                  <WorkoutEntryTable
                    entriesViewHeight={entriesViewHeight}
                    formProps={props}
                    handleInfoClick={handleInfoClick}
                    isWrvSupersetsEnabled={isWrvSupersetsEnabled}
                    supersetWorkout={supersetWorkout}
                    workout={workout}
                  />

                )}
              </>
            )}
          />
        </Form>
      )}
    />
  );
};

SaqEntries.propTypes = {
  activeDate: PropTypes.string.isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  athleteId: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isWeightValueEnabled: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  supersetWorkout: PropTypes.instanceOf(Object).isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  wrvSupersetsEnabled: PropTypes.bool.isRequired,
};

export default SaqEntries;
