/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import iconSet from '../../../../../../shared/images/teambuildr-selection.json';
import {
  openNotificationVideo,
  openImageLightbox,
} from '../../../../ducks/notificationsActions';

const MultipleMediaContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

const NotificationMediaContent = styled('div')`
  display: flex;
`;

const imageFlipper = (media) => {
  if (media.thumbnailKey) {
    return media.thumbnailUrl;
  }
  return media.mediaUrl;
};

const MediaBox = styled('div')`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	height: 100px;
	width: 100px;
	background-image: ${(props) => `url(${props.media && props.media.mediaUrl ? imageFlipper(props.media) : ''})`};
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  border-radius: 5px;
`;

const NotificationMediaContainer = () => {
  const dispatch = useDispatch();

  const notificationAsset = useSelector((state) => state.notifications.data.notificationAsset);

  const [standardizedNotificationMedia, setStandardizedNotificationMedia] = useState([]);

  useEffect(() => {
    if (Array.isArray(notificationAsset.media)) {
      setStandardizedNotificationMedia(notificationAsset.media);
    } else if (notificationAsset.media === null) {
      setStandardizedNotificationMedia(null);
    } else {
      setStandardizedNotificationMedia([notificationAsset.media]);
    }
  }, [notificationAsset]);

  const openModal = (url) => {
    dispatch(openNotificationVideo(url));
  };

  if (notificationAsset) {
    return (
      <NotificationMediaContent>
        <MultipleMediaContainer>
          {standardizedNotificationMedia?.map((media) => (
            <MediaBox
              media={media}
              onClick={() => {
                if (media.type === 'IMAGE' || media.type === 'EXTERNAL_URL') {
                  dispatch(openImageLightbox(media.mediaUrl));
                } else {
                  dispatch(openModal(media.mediaUrl));
                }
              }}
            >
              {media.type === 'VIDEO' ? (
                <div
                  onClick={() => {
                    dispatch(openModal(media.mediaUrl));
                  }}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50px',
                    width: '50px',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    background: 'rgba(255, 255, 255, 0.65)',
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={25}
                    icon='altrightarrow'
                  />
                </div>
              ) : null}
            </MediaBox>
          ))}
        </MultipleMediaContainer>
      </NotificationMediaContent>
    );
  }
  return null;
};

NotificationMediaContainer.propTypes = {
  // activeNotification: PropTypes.instanceOf(Object).isRequired,
};

export default NotificationMediaContainer;
