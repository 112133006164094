export const FETCH_ATHLETE_REPORT_CARD_START = 'FETCH_ATHLETE_REPORT_CARD_START';
export const FETCH_ATHLETE_REPORT_CARD_FULFILLED = 'FETCH_ATHLETE_REPORT_CARD_FULFILLED';
export const FETCH_ATHLETE_REPORT_CARD_REJECTED = 'FETCH_ATHLETE_REPORT_CARD_REJECTED';
export const FETCH_UNSUBSCRIBE_START = 'FETCH_UNSUBSCRIBE_START';
export const FETCH_UNSUBSCRIBE_FULFILLED = 'FETCH_UNSUBSCRIBE_FULFILLED';
export const FETCH_UNSUBSCRIBE_REJECTED = 'FETCH_UNSUBSCRIBE_REJECTED';
export const FETCH_REPORT_CARD_LINK_START = 'FETCH_REPORT_CARD_LINK_START';
export const FETCH_REPORT_CARD_LINK_FULFILLED = 'FETCH_REPORT_CARD_LINK_FULFILLED';
export const FETCH_REPORT_CARD_LINK_REJECTED = 'FETCH_REPORT_CARD_LINK_REJECTED';
export const SET_IS_SHARE_MODAL_OPEN = 'SET_IS_SHARE_MODAL_OPEN';
