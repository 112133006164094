import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';

import Select from 'react-select';

import ImageLightbox from '../components/presentational/ImageLightbox';
import NoHoverButton from './NoHoverButton';
import Text from '../../../shared/components/Text/Text';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import useConversations from '../components/hooks/useConversations';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
    color: white!important;
  }
`;

const InnerFormikWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  top: 0;
  position: absolute;
  margin-left: -20px;
  margin-top: 10px;
  .back-arrow-div {
    cursor: pointer;
  }
  top: 0;
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 10px;
    margin-bottom: 40px;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const FormGroup = styled('div')`
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    /* margin-top: 30px; */
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .folder-text {
    font-weight: bold;
  }
  .group-access-text {
    font-weight: bold;
    margin-top: 8px;
  }
`;

const RecordVideoModal = ({
  onRequestClose,
}) => {
  const {
    handleUploadMedia,
  } = useConversations();

  return (
    <ModalWrapper>
      {/* <AnotherWrapper>
        <NavigationWrapper>
          <Text
            className='back-arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='remove'
            />
          </Text>
        </NavigationWrapper> */}
      {/* </AnotherWrapper> */}
      <ImageLightbox />
    </ModalWrapper>
  );
};

RecordVideoModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default RecordVideoModal;
