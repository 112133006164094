/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setDuplicateModalShowing, duplicateDashboard } from '../../ducks/sportCoachDashboardActions';
import Button from '../../../../shared/components/Button/Button';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 50vw;
  overflow: auto;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  max-width: 600px;
  max-height: calc(100vh - 20px);
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #424242;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  max-height: ${(props) => props.customHeight};
  margin-bottom: 25px;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  transition: max-height 0.5s linear;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 24px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const DashTitle = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashTitleContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DashTitleBox = styled('input')`
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const DashDescription = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
`;

const DashDescriptionBox = styled('input')`
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  max-height: ${(props) => props.customHeight};
  transition: max-height 1s linear;
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
`;

const RelativePortalContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DashDescriptionContainer = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
`;

const DuplicateDashboardModal = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const duplicateDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.duplicateDashboardModalShowing,
  );
  const dupTemplate = useSelector((state) => state.sportCoachDashboard.data.dupTemplate);
  const template = useSelector((state) => state.sportCoachDashboard.data.template);

  const [dashDescription, setDashDescription] = useState('');
  const [dashTitle, setDashTitle] = useState('');

  const [errors, setErrors] = useState({
    errorsExist: true,
    errors: [{
      error: 'title error',
      msg: 'You must add a dashboard title',
      isShowing: false,
    },
    {
      error: 'incomplete item error',
      msg: 'Some evaluation items are incomplete',
      isShowing: false,
    },
    {
      error: 'API error',
      msg: 'There was an issue duplicating the dashboard, please check the form and try again.',
      isShowing: false,
    }],
  });

  useEffect(() => {
    if (duplicateDashboardModalShowing === false) {
      setErrors({
        errorsExist: true,
        errors: [{
          error: 'title error',
          msg: 'You must add a dashboard title',
          isShowing: false,
        },
        {
          error: 'incomplete item error',
          msg: 'Some evaluation items are incomplete',
          isShowing: false,
        },
        {
          error: 'API error',
          msg: 'There was an issue duplicating the dashboard, please check the form and try again.',
          isShowing: false,
        }],
      });
      setDashTitle('');
      setDashDescription('');
    }
  }, [duplicateDashboardModalShowing]);

  const templateId = template ? template?.templateId : dupTemplate?.templateId;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (dashTitle.length) {
      const submissionObj = {
        title: dashTitle,
        description: dashDescription,
      };
      // Amplitude tracking
      dashboardTracker('Sport Coach Dashboard', 'Duplicate Saved Dashboard', 'Dashboard duplicated');
      batch(() => {
        dispatch(duplicateDashboard(
          currentUser.accountCode,
          templateId,
          submissionObj,
        ));
        dispatch(setDuplicateModalShowing(false));
      });
      setDashTitle('');
      setDashDescription('');
    } else {
      const newErrors = { ...errors };
      newErrors.errors[0].isShowing = true;
      setErrors(newErrors);
    }
  };

  const handleClose = () => {
    dispatch(setDuplicateModalShowing(false));
    setDashTitle('');
    setDashDescription('');
  };

  return (
    <ModalContainer
      isActive={duplicateDashboardModalShowing}
      customWidth='1000px'
      customHeight='1000px'
    >
      <RelativePortalContainer>
        <ButtonContainer>
          <div />
          <PortalContainer>
            <RemoveDiv>
              <ModalHeader>
                <HeaderIconContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='copy'
                  />
                </HeaderIconContainer>
                <ModalHeaderText>Duplicate Dashboard</ModalHeaderText>
                <ErrorBox>
                  {errors.errors[0].isShowing
                    ? <MappedError>{errors.errors[0].msg}</MappedError> : null}
                  {errors.errors[1].isShowing
                    ? <MappedError>{errors.errors[1].msg}</MappedError> : null}
                  {errors.errors[2].isShowing
                    ? <MappedError>{errors.errors[2].msg}</MappedError> : null}
                </ErrorBox>
              </ModalHeader>
              <div
                className='remove-div-icon'
                onClick={handleClose}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='remove'
                  color='black'
                />
              </div>
            </RemoveDiv>
            <ContentContainer customHeight='500px'>
              <SlidesContainer customHeight='auto'>
                <Slides activeSlide={0}>
                  <Slide>
                    <DashTitleContainer>
                      <DashTitle>Title</DashTitle>
                      <DashTitleBox
                        onBlur={() => {
                          if (!dashTitle.length) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = true;
                            setErrors(newErrors);
                          }
                        }}
                        value={dashTitle}
                        onChange={(e) => {
                          if (errors.errors[0].isShowing && e.target.value.length) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = false;
                            setErrors(newErrors);
                          }
                          setDashTitle(e.target.value);
                        }}
                        placeholder='Title'
                      />
                    </DashTitleContainer>
                    <DashDescriptionContainer>
                      <DashDescription>Description</DashDescription>
                      <DashDescriptionBox
                        value={dashDescription}
                        onChange={(e) => {
                          setDashDescription(e.target.value);
                        }}
                        placeholder='Description (optional)'
                      />
                    </DashDescriptionContainer>
                  </Slide>
                </Slides>
              </SlidesContainer>
            </ContentContainer>
          </PortalContainer>
          <Button
            bottom
            fullWidth
            cta='Submit'
            className='print-button'
            customTextColor='#FFFFFF'
            large
            square
            noBorder
            primary
            onClick={handleSubmit}
          />
        </ButtonContainer>
      </RelativePortalContainer>
    </ModalContainer>
  );
};

DuplicateDashboardModal.defaultProps = {

};

DuplicateDashboardModal.propTypes = {

};

export default DuplicateDashboardModal;
