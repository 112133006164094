/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SketchPicker } from 'react-color';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { setIsSketchPickerPopupOpen } from '../../../../profile/ducks/profileActions';

const SketchPickerElement = ({
  defaultColor, handleSubmit, values, colorCategory, chosenColorIsTooBrightErrorMessage,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const isSketchPickerPopupOpen = useSelector((state) => state.profile.data.isSketchPickerPopupOpen);
  const [sketchPickerColor, setSketchPickerColor] = useState(defaultColor);
  const [errorMessage, setErrorMessage] = useState(chosenColorIsTooBrightErrorMessage);
  const currentGlobalPrimaryColor = useSelector((state) => state.auth.data.organizationSettings?.skin?.primaryColor);
  const currentGlobalSecondaryColor = useSelector((state) => state.auth.data.organizationSettings?.skin?.secondaryColor);
  const currentGlobalAccentColor = useSelector((state) => state.auth.data.organizationSettings?.skin?.accentColor);

  const handleChangeComplete = (color) => {
    setSketchPickerColor(color.hex);
    setErrorMessage('');
  };
  const handleClose = () => {
    if (colorCategory === 'primary' && sketchPickerColor !== currentGlobalPrimaryColor) {
      values.primaryColor = sketchPickerColor;
      handleSubmit(values);
    } else if (colorCategory === 'secondary' && sketchPickerColor !== currentGlobalSecondaryColor) {
      values.secondaryColor = sketchPickerColor;
      handleSubmit(values);
    } else if (colorCategory === 'accent' && sketchPickerColor !== currentGlobalAccentColor) {
      values.accentColor = sketchPickerColor;
      handleSubmit(values);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      dispatch(setIsSketchPickerPopupOpen({ colorCategory: '', bool: false }));
      handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const Popover = styled('div')`
    margin-top: 30px;
    position: absolute;
    z-index: 2;
  `;
  const ColorCircle = styled('div')`
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color:  ${sketchPickerColor};
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: #E9E9EA;
  `;

  const WarningContainer = styled('div')`
    height: 60px;
    width: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  `;

  return (

    <div>
      {errorMessage ? (
        <WarningContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={60}
            icon='warning'
            color='#ff6600'
          />
        </WarningContainer>

      ) : (
        <ColorCircle />
      )}

      { isSketchPickerPopupOpen.colorCategory === colorCategory && isSketchPickerPopupOpen.bool ? (
        <>
          <Popover ref={ref}>
            <SketchPicker
              width={240}
              color={sketchPickerColor}
              onChangeComplete={handleChangeComplete}
            />
          </Popover>
        </>
      ) : null }

    </div>
  );
};

SketchPickerElement.propTypes = {
  defaultColor: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.objectOf(PropTypes.string).isRequired,
  colorCategory: PropTypes.string.isRequired,
  chosenColorIsTooBrightErrorMessage: PropTypes.string,
};

SketchPickerElement.defaultProps = {
  chosenColorIsTooBrightErrorMessage: null,
};
export default SketchPickerElement;
