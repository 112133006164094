/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import ForcePlateDatePicker from '../../../force-plate-dashboard/components/presentational/ForcePlateDatePicker';
import ForcePlateDropDown from '../../../force-plate-dashboard/components/ForcePlateDropDown';
import ExerciseFilterGroup from './ExerciseFilterGroup';
import SCAnalysisTable from './SCAnalysisTable';
import {
  setSelectedAthleteGroup,
} from '../../ducks/sportCoachDashboardActions';
import Spinner from '../../../../shared/components/Spinner/Spinner';

const DashboardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 15px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 16px;
  box-shadow: ${BoxShadow};
  height: 100%;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .downloadBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    padding: 3px;
    margin-left: 10px;
  }
`;

const ColorNote = styled('div')`
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-weight: normal;
`;

const ViewOnlyAnalysis = () => {
  const athleteList = useSelector((state) => state.sportCoachDashboard.data.athleteList);
  const cardData = useSelector((state) => state.sportCoachDashboard.data.cardData);
  const endDateCal = useSelector((state) => state.sportCoachDashboard.data.endDateCal);
  const group = useSelector((state) => state.sportCoachDashboard.data.group);
  const exerciseFiltersGroup = useSelector((
    state,
  ) => state.sportCoachDashboard.data.exerciseFiltersGroup);
  const selectedAthleteGroup = useSelector((
    state,
  ) => state.sportCoachDashboard.data.selectedAthleteGroup);
  const startDateCal = useSelector((
    state,
  ) => state.sportCoachDashboard.data.startDateCal);

  const hasData = cardData?.length > 0;

  // Logic to populate athlete dropdown
  const athleteDropdownList = hasData && [...athleteList];
  athleteDropdownList.length > 0 && athleteDropdownList.unshift({ fullName: 'View Group', userId: null });

  // Manipulate and filter the data so it plays nicely with the GA table
  // Creating a new array with data attached to any athlete found in athleteList
  // and adding null data if exercise isn't found in return data
  // Data is filtered based on exerciseFiltersGroup and selectedAthleteGroup
  const manipulatedData = [];
  hasData && athleteList.forEach((athlete) => {
    if (
      !selectedAthleteGroup
    || (selectedAthleteGroup.fullName === 'View Group')
    || (selectedAthleteGroup && selectedAthleteGroup.fullName !== 'View Group' && selectedAthleteGroup.userId === athlete.userId)
    ) {
      const athleteResults = [];

      exerciseFiltersGroup.forEach((exerciseName) => {
        const exerciseData = cardData.find((exercise) => exercise.metricName === exerciseName);
        if (exerciseData) {
          const athleteExerciseData = exerciseData.athleteData.find(
            (resultData) => resultData.userId === athlete.userId,
          );
          if (athleteExerciseData) {
            athleteResults.push({
              exerName: exerciseName,
              newestMax: athleteExerciseData.newestMax,
              oldestMax: athleteExerciseData.oldestMax,
              percentChange: athleteExerciseData.percentChange,
              color: athleteExerciseData.color,
            });
          } else {
            athleteResults.push({
              exerName: exerciseName,
              newestMax: null,
              oldestMax: null,
              percentChange: null,
              color: null,
            });
          }
        } else {
        // If exercise data not found, push null data
          athleteResults.push({
            exerName: exerciseName,
            newestMax: null,
            oldestMax: null,
            percentChange: null,
            color: null,
          });
        }
      });

      manipulatedData.push({
        userId: athlete.userId,
        fullName: athlete.fullName,
        pic: athlete.pic,
        results: athleteResults,
      });
    }
  });

  // Ensure consitency with group average data by manipulating and
  // filtering it similar to above
  const groupAverageData = [];
  hasData && exerciseFiltersGroup.forEach((exerName) => {
    const exercise = hasData && cardData.find((exer) => exer.metricName === exerName);

    if (exercise) {
      groupAverageData.push({
        metricName: exercise.metricName,
        percentChange: exercise.percentChange,
        color: exercise.color,
        newestAverage: exercise.newestAverage,
        oldestAverage: exercise.oldestAverage,
      });
    } else {
    // Add exercise with null values if not found in cardData
      groupAverageData.push({
        metricName: exerName,
        percentChange: null,
        color: null,
        newestAverage: null,
        oldestAverage: null,
      });
    }
  });

  const dispatch = useDispatch();

  const setAthlete = (option) => {
    if (option.fullName === 'View All') {
      dispatch(setSelectedAthleteGroup({ fullName: 'Viewing All' }, false));
    } else {
      dispatch(setSelectedAthleteGroup(option, true));
    }
  };

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  const emptyFunction = () => undefined;

  return (
    <>
      <DashboardContainer>
        <OptionsHeader>
          <MenuOptionsContainer>
            <ForcePlateDropDown
              disabled
              list={['']}
              label='name'
              selectItemFunction={emptyFunction}
              headerWidthPx={175}
              placeholder='Select a Group'
              defaultOption={group || {}}
              readOnly
            />
            {hasData && (
            <ForcePlateDropDown
              list={athleteDropdownList}
              label='fullName'
              selectItemFunction={setAthlete}
              headerWidthPx={175}
              placeholder='Select an Athlete'
              defaultOption={selectedAthleteGroup || {}}
            />
            )}
            <ExerciseFilterGroup />
          </MenuOptionsContainer>
          <DateOptionsContainer>
            <ForcePlateDatePicker
              disabled
              setStartDate={emptyFunction}
              setEndDate={emptyFunction}
              startDate={startDateCal.toDate()}
              endDate={endDateCal.toDate()}
            />
          </DateOptionsContainer>
        </OptionsHeader>
        <ColorNote>
          <span style={{ fontWeight: 'bold' }}>NOTE: </span>
          The column background color is determined by the amount of percent change.
          The percent change column will be red if the percent change is less than
          3.00% or green if the percent greater than 0.00%.
        </ColorNote>
        {!manipulatedData.length > 0 ? (
          <Spinner />
        ) : (
          <SCAnalysisTable
            setColumnHeaderHeight={setColumnHeaderHeight}
            containerWidth={containerWidth}
            dataRows={manipulatedData}
            groupAverageData={groupAverageData}
            rowIsScrollable
          />
        )}
      </DashboardContainer>
    </>
  );
};

export default ViewOnlyAnalysis;
