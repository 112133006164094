/* eslint-disable no-unused-vars */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import IcomoonReact from 'icomoon-react';

import WebcamCaptureImage from './WebcamCaptureImage';
import Text from '../Text/Text';
import iconSet from '../../images/teambuildr-selection.json';
// import { uploadMedia } from '../ducks/journalActions';
import { updateProfileImage, updateLogoImage } from '../../../modules/login/ducks/loginActions';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
`;

const InnerFormikWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  top: 0;
  position: absolute;
  margin-left: -20px;
  margin-top: 10px;
  .back-arrow-div {
    cursor: pointer;
  }
  top: 0;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const RecordImageModal = ({
  onRequestClose,
  isLogo,
}) => (
  <ModalWrapper>
    <Formik
      initialValues={{
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        });
      }}
    >
      <InnerFormikWrapper>
        <AnotherWrapper>
          <NavigationWrapper>
            <Text
              className='back-arrow-div'
              onClick={() => {
                onRequestClose();
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
        </AnotherWrapper>
        <WebcamCaptureImage
          handleUploadMedia={isLogo ? updateLogoImage : updateProfileImage}
        />
      </InnerFormikWrapper>
    </Formik>
  </ModalWrapper>
);

RecordImageModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isLogo: PropTypes.bool,
};

RecordImageModal.defaultProps = {
  isLogo: false,
};

export default RecordImageModal;
