/* eslint-disable react/destructuring-assignment */
// Authored by Colin Reitman
/** Added functionality by Kristy Street (added additional custom style options and ability to pass
  in icon container in data for options, onBlur for formik fields
) */
import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../images/teambuildr-selection.json';
import Wrapper from './Wrapper';
import SelectTitle from './SelectTitle';

const ErrorText = styled('div')`
  color: #CC0404;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
`;

const IconContainer = styled('div')`
height: 24px;
width: 24px;
border-radius: 50%;
color: ${(props) => (props.color ? props.color : '#444444')};
border: solid 1px;
display: flex;
justify-content: center;
align-items: center;
margin-right: 5px;
`;

const CustomOption = (props) => (
  <components.Option {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!props.data.avatar ? (
        <>
          {props?.data?.includeIconContainer ? (
            <IconContainer color={props.data.iconContainerColor}>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon={props.data.icon}
                color={props.data.color || '#444444'}
              />
            </IconContainer>
          ) : (
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon={props.data.icon}
              color={props.data.color || '#444444'}
              style={{ marginRight: 5 }}
            />
          )}
        </>
      ) : (
        <div style={{
          marginRight: 5,
        }}
        >
          {props.data.avatar}
        </div>
      )}
      {props.children}
    </div>
  </components.Option>
);

const CustomSingleValue = (props) => (
  <components.SingleValue {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!props.data.avatar ? (
        <>
          {props?.data?.includeIconContainer ? (
            <IconContainer color={props.data.iconContainerColor}>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon={props.data.icon}
                color={props.data.color || '#444444'}
              />
            </IconContainer>
          ) : (
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon={props.data.icon}
              color={props.data.color || '#444444'}
              style={{ marginRight: 5 }}
            />
          )}
        </>
      ) : (
        <div style={{
          marginRight: 5,
        }}
        >
          {props.data.avatar}
        </div>
      )}
      {props.children}
    </div>
  </components.SingleValue>
);

const CustomMultiValue = (props) => (
  <components.MultiValue {...props}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!props.data.avatar ? (
        <>
          {props?.data?.includeIconContainer ? (
            <IconContainer color={props.data.iconContainerColor}>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon={props.data.icon}
                color={props.data.color || '#444444'}
              />
            </IconContainer>
          ) : (
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon={props.data.icon}
              color={props.data.color || '#444444'}
              style={{ marginRight: 5 }}
            />
          )}
        </>
      ) : (
        <div style={{
          marginRight: 5,
        }}
        >
          {props.data.avatar}
        </div>
      )}
      {props.children}
    </div>
  </components.MultiValue>
);

/**
 * @param {Object} defaultValue default selected value for the select box,
 * @param {Object} value value for the select box,
 * passed in as an index of the options array
 * @param {String} height the height of the wrapper containing the select box. Can be passed in
 * as a fixed value (e.g. '200px') or as a percentage (e.g. '50%')
 * @param {Bool} isMulti determines whether or not select box can select multiple items
 * @param {Bool} innerContainerWidth Determines width of inner container, defaults to 100%
 * @param {Bool} isSearchable determines whether or not component can accept a search string
 * @param {Func} onChangeFunction function that is run when an option is selected. Will
 * be called passing in the selected option
 * @param {Array} options the array of selectable options, with each option taking the shape of:
 * { name: 'option name', id: 1 }
 * @param {Bool} closeMenuOnSelect adding false causes select menu to persist after select
 * selecting a value
 * @param {String} placeholder adds a pleceholder to select box
 * @param {Bool} removeSeparator boolean to remove the IndicatorSeperator from Select
 * @param {Object} styledProps accepts an object with all optional styles
 * @param {String} width the width of the wrapper containing the select box. Can be passed in
 * * as a fixed value (e.g. '200px') or as a percentage (e.g. '50%')
 * @param {Bool} error bool to decide whether or not to display error messages and styling
 * @param {String} errorMsg custom message to display below the select input when there is an error
 * @param {Function} onBlur can be used to handle blur events for formik (ie touched)
 * @param {Bool} includeIconContainer boolean value that you can pass if you are
    choosing to use icons in your dropdown or your inputs and want that icon circle
    container around it
* @param {Bool} isClearable if you do not wish to render the clearIndicator inside the mutliselect
  set isClearable to false
 * @param {Object} customStyles option to pass in custom styles in the shape of...
 * customStyles={{
    control: (provided, state) => ({
      ...provided,
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 400,
      borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      boxShadow: state.isFocused ? 'none' : 'none',
        &:hover': {
        borderColor: state.isFocused ? 'lightgray' : 'lightgray',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      borderRadius: '8px',
      backgroundColor: state.isFocused ? 'rgba(207, 245, 232, 1)' : 'white',
      color: '#444444',
      fontFamily: 'Nunito Sans',
      fontSize: '16px',
      fontWeight: 700,
      '&:hover': {
        backgroundColor: 'rgba(207, 245, 232, 1)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      border: '0px',
      maxWidth: '260px',
      minWidth: '250px',
      padding: '16px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#9E9E9E', // Custom placeholder color
    }),
  }}
 * @returns
 */
const SelectBox = ({
  defaultValue = {},
  value = {},
  height = '100%',
  isMulti,
  innerContainerWidth = '100%',
  isSearchable = true,
  onChangeFunction,
  options,
  closeMenuOnSelect = true,
  placeholder = '',
  removeSeparator,
  styledProps = {},
  title = '',
  titleStyledProps = {},
  width = '100%',
  customStyles = {},
  error,
  errorMsg,
  onBlur,
  isClearable,
}) => (
  <Wrapper
    width={width}
    height={height}
    {...styledProps}
  >
    {title ? (
      <SelectTitle {...titleStyledProps}>
        {title}
      </SelectTitle>
    ) : null}
    <Select
      isClearable={isClearable}
      onBlur={onBlur}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        MultiValue: CustomMultiValue,
      }}
      defaultValue={defaultValue || null}
      value={value || defaultValue}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isDisabled={false}
      isMulti={isMulti}
      isSearchable={isSearchable}
      onChange={(option) => {
        onChangeFunction(option);
      }}
      useAvatars
      blurInputOnSelect={closeMenuOnSelect}
      closeMenuOnSelect={closeMenuOnSelect}
      options={options}
      placeholder={placeholder}
      /** components can be custom styled using the styles api
       * defined in the react-select docs:
       * https://react-select.com/components#replacing-components
      */
      styles={{
        ...customStyles,
        container: (provided) => ({
          ...provided,
          width: innerContainerWidth,
        }),
        indicatorSeparator: (base) => ({
          ...base,
          display: removeSeparator ? 'none' : 'block',
        }),
      }}
    />
    {error && (
      <ErrorText>{errorMsg}</ErrorText>
    )}
  </Wrapper>
);

//
SelectBox.propTypes = {
  defaultValue: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  height: PropTypes.string,
  innerContainerWidth: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChangeFunction: PropTypes.func.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  closeMenuOnSelect: PropTypes.bool,
  placeholder: PropTypes.string,
  removeSeparator: PropTypes.bool,
  styledProps: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  titleStyledProps: PropTypes.instanceOf(Object),
  width: PropTypes.string,
  customStyles: PropTypes.instanceOf(Object),
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  onBlur: PropTypes.func,
  isClearable: PropTypes.bool,
};

SelectBox.defaultProps = {
  defaultValue: {},
  value: null,
  height: '100%',
  innerContainerWidth: '100%',
  isSearchable: true,
  isMulti: false,
  styledProps: {},
  width: '100%',
  closeMenuOnSelect: true,
  placeholder: '',
  removeSeparator: false,
  title: '',
  titleStyledProps: {},
  customStyles: {},
  error: false,
  errorMsg: '',
  onBlur: () => {},
  isClearable: true,
};

CustomOption.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  props: PropTypes.instanceOf(Object).isRequired,
};

CustomSingleValue.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  props: PropTypes.instanceOf(Object).isRequired,
};

CustomMultiValue.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  props: PropTypes.instanceOf(Object).isRequired,
};

export default SelectBox;
