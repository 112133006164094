import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { createCellValue } from '../../ducks/evaluationsActions';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const RowValue = styled('div')`
  display: flex;
  justify-content: center;
  min-width: 220px;
  max-width: 220px;
  height: 48px;
  box-shadow: ${BoxShadow};
  flex: ${(props) => props.flexValue};
  margin-left: 10px;
  border-radius: 12px;
  padding: 8px;
  background: #FFFFFF;

  @media (max-width: 768px) {
    min-width: 150px;
    max-width: 150px;

  }
`;

const OneInputBox = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
`;

const SingleInputBox = styled('input')`
  width: ${(props) => (props.value ? '80%' : '98%')};
  padding-left: 10px;
  border: none;
  font-size: 12px;
`;

const UnitBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans';
  padding-right: 10px;
  font-size: 12px;
  color: #444444;
`;

const SingleInputComponent = ({
  item,
  valuesArray,
  evaluation,
  athleteIdx,
  inputIdx,
}) => {
  const [input, setInput] = useState('');

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    setInput(valuesArray[0][0].value);
  }, [valuesArray]);

  return (
    <RowValue flexValue={1}>
      <OneInputBox>
        <SingleInputBox
          value={input}
          placeholder={item.unit1.toLowerCase()}
          onChange={(e) => setInput(e.target.value)}
          onBlur={() => {
            const inputValue = valuesArray[0][0];
            let submissionObject;
            if (inputValue.id || inputValue.id === 0) {
              submissionObject = {
                id: inputValue.id,
                evalId: inputValue.evalId,
                itemId: inputValue.itemId,
                userId: inputValue.userId,
                value: input?.toString(),
                resultNumber: 1,
              };
            } else {
              submissionObject = {
                evalId: inputValue.evalId,
                itemId: inputValue.itemId,
                userId: inputValue.userId,
                value: input?.toString(),
                resultNumber: 1,
              };
            }
            dispatch(createCellValue(
              currentUser.accountCode,
              submissionObject,
              evaluation.id,
              athleteIdx,
              inputIdx,
              0,
            )).then(({ response }) => {
              if (response && response?.status === 400) {
                setInput('');
              }
            });
          }}
        />
        {input ? (
          <UnitBox>
            {item.unit1.toLowerCase()}
          </UnitBox>
        ) : null}
      </OneInputBox>
    </RowValue>
  );
};

SingleInputComponent.defaultProps = {

};

SingleInputComponent.propTypes = {
  valuesArray: PropTypes.instanceOf(Array).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  evaluation: PropTypes.instanceOf(Object).isRequired,
  athleteIdx: PropTypes.number.isRequired,
  inputIdx: PropTypes.number.isRequired,
};

export default SingleInputComponent;
