/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';

const WeightRoomViewGlobalSettings = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);

  const [globalWeightRoomViewSettings, setGlobalWeightRoomviewSettings] = useState({
    supersetsEnabled: Boolean(organizationSettings?.generalSettings?.wrvSupersetsEnabled),
    automaticallyInputPrescribedWeights: Boolean(organizationSettings?.generalSettings?.wrvPlaceholdersEnabled),
  });

  const debouncedSetGlobalWeightRoomviewSettings = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        generalSettings: {
          wrvSupersetsEnabled: newSettings.supersetsEnabled,
          wrvPlaceholdersEnabled: newSettings.automaticallyInputPrescribedWeights,
        },
      };
      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const handleSwitches = (field, v) => {
    setGlobalWeightRoomviewSettings((prev) => ({
      ...prev,
      [field]: v,
    }));
    debouncedSetGlobalWeightRoomviewSettings({
      ...globalWeightRoomViewSettings,
      [field]: v,
    });
  };

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;

        .react-toggle .react-toggle-track {
            background-color: #E9E9EA;
        }
        .react-toggle--checked .react-toggle-track {
            background-color: ${theme.colors.green};
        }
        .react-toggle-thumb {
            border: none;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #E9E9EA;
        }
        
        .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: ${theme.colors.green};
        }
`;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 25px;
    cursor: pointer;
`;

  const Column = styled('div')`
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    max-width: 602px;
`;

  const HeaderContainer = styled('div')`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
`;

  return (
    <Wrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Weight Room View - Global Settings
        </Text>
        <Text fontSize={18} fontWeight='400'> Manage global Weight Room View setting defaults for every device</Text>
      </HeaderContainer>
      <>
        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Supersets Enabled
            </Text>
            <Text fontSize={16} fontWeight='400'>
              In a superset, next and previous buttons will toggle by set and not exercise.
            </Text>
          </Column>
          <Toggle
            checked={globalWeightRoomViewSettings.supersetsEnabled}
            icons={false}
            onChange={() => {
              handleSwitches('supersetsEnabled', !globalWeightRoomViewSettings.supersetsEnabled);
            }}
          />
        </Row>

        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Automatically Input Prescribed/Suggested Weights
            </Text>
            <Text fontSize={16} fontWeight='400'>
              When enabled, suggested and prescribed weights will be automatically
              filled in and saved when the next button or the workout is closed. If disabled,
              the weight values will show up as a placeholder and athletes will have to enter
              their weights manually before saving.
            </Text>
          </Column>
          <Toggle
            checked={globalWeightRoomViewSettings.automaticallyInputPrescribedWeights}
            icons={false}
            onChange={() => {
              handleSwitches('automaticallyInputPrescribedWeights', !globalWeightRoomViewSettings.automaticallyInputPrescribedWeights);
            }}
          />
        </Row>
      </>
    </Wrapper>
  );
};

WeightRoomViewGlobalSettings.propTypes = {
  containerWidth: PropTypes.number,
};

WeightRoomViewGlobalSettings.defaultProps = {
  containerWidth: '',
};

export default WeightRoomViewGlobalSettings;
