import styled from '@emotion/styled';
import {
  color,
  flexbox,
  layout,
  space,
  typography,
} from 'styled-system';

const Label = styled('div')`
  ${color}
  ${flexbox}
  font-family: 'Nunito Sans';
  ${layout}
  margin-bottom: 5px;
  ${space}
  ${typography}
`;

export default Label;
