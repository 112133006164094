import update from 'immutability-helper';

import {
  FETCH_RESELLER_FULFILLED,
  FETCH_RESELLER_REJECTED,
  CREATE_RESELLER_FULFILLED,
  CREATE_RESELLER_REJECTED,
  CONNECT_STRIPE_FULFILLED,
  CONNECT_STRIPE_REJECTED,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  CREATE_PROGRAM_FULFILLED,
  CREATE_PROGRAM_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  STRIPE_ADMIN_LOGIN_FULFILLED,
  STRIPE_ADMIN_LOGIN_REJECTED,
  FETCH_SUBSCRIBERS_FULFILLED,
  FETCH_SUBSCRIBERS_REJECTED,
  FETCH_CANCELLED_SUBSCRIBERS_FULFILLED,
  FETCH_CANCELLED_SUBSCRIBERS_REJECTED,
  FETCH_SUBSCRIBER_FULFILLED,
  FETCH_SUBSCRIBER_REJECTED,
  UPDATE_PROGRAM_FULFILLED,
  UPDATE_PROGRAM_REJECTED,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  FETCH_FIRST_WORKOUT_FULFILLED,
  FETCH_FIRST_WORKOUT_REJECTED,
  FETCH_ALL_CHARGES_FULFILLED,
  FETCH_ALL_CHARGES_REJECTED,
  REFUND_CHARGE_FULFILLED,
  REFUND_CHARGE_REJECTED,
  UPDATE_PAYMENT_FULFILLED,
  UPDATE_PAYMENT_REJECTED,
  CANCEL_SUBSCRIPTION_FULFILLED,
  CANCEL_SUBSCRIPTION_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  REQUEST_STRIPE_CONNECT_LINK_FULFILLED,
  REQUEST_STRIPE_CONNECT_LINK_REJECTED,
  UPDATE_RESELLER_ACCOUNT_FULFILLED,
  UPDATE_RESELLER_ACCOUNT_REJECTED,
  FETCH_COUPONS_FULFILLED,
  FETCH_COUPONS_REJECTED,
  CREATE_COUPON_FULFILLED,
  CREATE_COUPON_REJECTED,
  DELETE_COUPON_FULFILLED,
  DELETE_COUPON_REJECTED,
  CLEAR_COUPON_FETCH_ERROR,
  UPDATE_COUPON_REJECTED,
  UPDATE_COUPON_FULFILLED,
  ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED,
  ADD_SUBSCRIBER_TO_PROGRAM_REJECTED,
  CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR,
  FETCH_SUBSCRIBER_START,
  CONNECT_STRIPE_START,
  SET_RESELLER_OVERLAY,
} from './actionTypes';

const initialState = {
  currentReseller: {},
  resellerError: '',
  uploadMediaError: '',
  updatedPayment: '',
  updatePaymentError: '',
  isResellerCreated: false,
  isResellerFetched: false,
  fetchSubscriberError: false,
  didStandardConnectionComplete: false,
  activeOverlay: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESELLER_FULFILLED: {
      return update(
        state, {
          currentReseller: {
            $set: action.payload,
          },
          isStripeAccountConnected: {
            $set: action.payload.stripeAccountConnected,
          },
          isResellerFetched: {
            $set: true,
          },
          isResellerCreated: {
            $set: true,
          },
        },
      );
    }
    case FETCH_RESELLER_REJECTED: {
      return update(
        state, {
          resellerError: {
            $set: action.payload,
          },
          isResellerCreated: {
            $set: false,
          },
          isStripeAccountConnected: {
            $set: false,
          },
        },
      );
    }
    case FETCH_PROGRAMS_FULFILLED: {
      return update(
        state, {
          programs: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_PROGRAMS_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case CREATE_PROGRAM_FULFILLED: {
      const currentPrograms = [...state.programs];
      currentPrograms.push(action.payload);
      currentPrograms.sort((a, b) => a.programName.localeCompare(b.programName));
      return update(
        state, {
          programs: {
            $set: currentPrograms,
          },
          createProgramError: {
            $set: '',
          },
          firstWorkout: {
            $set: null,
          },
          firstWorkoutError: {
            $set: null,
          },
        },
      );
    }
    case CREATE_PROGRAM_REJECTED: {
      return {
        ...state,
        createProgramError: action.payload.response.headers.message,
      };
    }
    case CREATE_RESELLER_FULFILLED: {
      return update(
        state, {
          currentReseller: {
            $merge: action.payload,
          },
          activeModal: {
            $set: 'verify_reseller_modal',
          },
          isResellerCreated: {
            $set: true,
          },
        },
      );
    }
    case CREATE_RESELLER_REJECTED: {
      return {
        ...state,
        createResellerError: action.payload.response.headers.message,
      };
    }
    case CONNECT_STRIPE_START: {
      return update(
        state, {
          connectStripeResellerError: {
            $set: false,
          },
        },
      );
    }
    case CONNECT_STRIPE_FULFILLED: {
      return update(
        state, {
          currentReseller: {
            $set: action.payload,
          },
          isStripeAccountConnected: {
            $set: action.payload.stripeAccountConnected,
          },
          isResellerFetched: {
            $set: true,
          },
          isResellerCreated: {
            $set: true,
          },
          connectStripeResellerError: {
            $set: false,
          },
          didStandardConnectionComplete: {
            $set: true,
          },
        },
      );
    }
    case CONNECT_STRIPE_REJECTED: {
      return {
        ...state,
        connectStripeResellerError: action.payload,
        isStripeAccountConnected: false,
        didStandardConnectionComplete: false,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case UPLOAD_MEDIA_START: {
      return {
        ...state,
        uploadMediaError: '',
      };
    }
    case UPLOAD_MEDIA_REJECTED: {
      return {
        ...state,
        uploadMediaError: action.payload,
      };
    }
    case UPLOAD_MEDIA_FULFILLED: {
      return {
        ...state,
        uploadMedia: action.payload,
        uploadMediaError: '',
      };
    }
    case STRIPE_ADMIN_LOGIN_FULFILLED: {
      return {
        ...state,
        stripeAdminURL: action.payload.url,
      };
    }
    case STRIPE_ADMIN_LOGIN_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case FETCH_SUBSCRIBER_START: {
      return {
        ...state,
        fetchSubscriberError: false,
      };
    }
    case FETCH_SUBSCRIBERS_FULFILLED: {
      return update(
        state, {
          subscribers: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_SUBSCRIBERS_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case FETCH_CANCELLED_SUBSCRIBERS_FULFILLED: {
      return update(
        state, {
          subscribers: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CANCELLED_SUBSCRIBERS_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case FETCH_SUBSCRIBER_FULFILLED: {
      return update(
        state, {
          subscriber: {
            $set: action.payload,
          },
          fetchSubscriberError: {
            $set: false,
          },
        },
      );
    }
    case UPDATE_PROGRAM_FULFILLED: {
      const currentPrograms = state.programs;
      const newTopLevelPrograms = [];
      currentPrograms.forEach((program) => {
        if (program.id !== action.payload.id) {
          newTopLevelPrograms.push(program);
        }
      });
      // Don't add the updated program if it was just deleted
      if (action.payload.status !== 2) {
        newTopLevelPrograms.push(action.payload);
      }
      newTopLevelPrograms.sort((a, b) => a.programName.localeCompare(b.programName));
      return update(
        state, {
          programs: {
            $set: newTopLevelPrograms,
          },
          updateProgramError: {
            $set: '',
          },
          firstWorkout: {
            $set: null,
          },
          firstWorkoutError: {
            $set: null,
          },
        },
      );
    }
    case UPDATE_PROGRAM_REJECTED: {
      return {
        ...state,
        updateProgramError: action.payload.response.headers.message,
      };
    }
    case FETCH_SUBSCRIBER_REJECTED: {
      return {
        ...state,
        fetchSubscriberError: true,
      };
    }
    case FETCH_CALENDARS_FULFILLED: {
      return update(
        state, {
          calendars: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CALENDARS_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case FETCH_FIRST_WORKOUT_FULFILLED: {
      return update(
        state, {
          firstWorkout: {
            $set: action.payload,
          },
          firstWorkoutError: {
            $set: '',
          },
        },
      );
    }
    case FETCH_FIRST_WORKOUT_REJECTED: {
      return {
        ...state,
        firstWorkoutError: action.payload,
        firstWorkout: null,
      };
    }
    case FETCH_ALL_CHARGES_FULFILLED: {
      return update(
        state, {
          allCharges: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_ALL_CHARGES_REJECTED: {
      return {
        ...state,
        resellerError: action.payload,
      };
    }
    case UPDATE_PAYMENT_FULFILLED: {
      return update(state, {
        subscriber: {
          paymentMethod: {
            $merge: action.payload,
          },
        },
      });
    }
    case UPDATE_PAYMENT_REJECTED: {
      return {
        ...state,
        updatePaymentError: action.payload,
      };
    }
    case CANCEL_SUBSCRIPTION_FULFILLED: {
      return update(state, {
        subscribers: {
          $splice: [[action.payload.index, 1]],
        },
      });
    }
    case CANCEL_SUBSCRIPTION_REJECTED: {
      return {
        ...state,
        cancelSubscriptinError: action.payload,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
        createProgramError: '',
        updateProgramError: '',
        firstWorkout: null,
        firstWorkoutError: null,
      };
    }
    case REQUEST_STRIPE_CONNECT_LINK_FULFILLED: {
      return update(state, {
        stripeAccountConnectLink: {
          $set: action.payload,
        },
      });
    }
    case REQUEST_STRIPE_CONNECT_LINK_REJECTED: {
      return {
        ...state,
        stripeAccountConnectError: action.payload,
      };
    }
    case UPDATE_RESELLER_ACCOUNT_FULFILLED: {
      return update(state, {
        currentReseller: {
          $set: action.payload,
        },
      });
    }
    case UPDATE_RESELLER_ACCOUNT_REJECTED: {
      return {
        ...state,
        updateResellerError: action.payload,
      };
    }
    case FETCH_COUPONS_FULFILLED: {
      return update(
        state, {
          coupons: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_COUPONS_REJECTED: {
      return {
        ...state,
        couponsError: action.payload,
      };
    }
    case CREATE_COUPON_FULFILLED: {
      const currentCoupons = [...state.coupons];
      currentCoupons.push(action.payload);
      return update(
        state, {
          coupons: {
            $set: currentCoupons,
          },
          couponsError: {
            $set: null,
          },
        },
      );
    }
    case CREATE_COUPON_REJECTED: {
      return {
        ...state,
        couponsError: action.payload,
      };
    }
    case DELETE_COUPON_FULFILLED: {
      const oldCoupons = [...state.coupons];
      const newCoupons = oldCoupons.filter((obj) => obj.id !== action.payload);
      return update(
        state, {
          coupons: {
            $set: newCoupons,
          },
          couponsError: {
            $set: null,
          },
        },
      );
    }
    case DELETE_COUPON_REJECTED: {
      return {
        ...state,
        couponsError: action.payload,
      };
    }
    case CLEAR_COUPON_FETCH_ERROR: {
      return {
        ...state,
        couponsFetchError: null,
      };
    }
    case UPDATE_COUPON_FULFILLED: {
      const newCoupons = [...state.coupons];
      const elementsIndex = state.coupons.findIndex((element) => element.id === action.payload.id);
      newCoupons[elementsIndex] = action.payload;
      return update(
        state, {
          coupons: {
            $set: newCoupons,
          },
          couponsError: {
            $set: null,
          },
        },
      );
    }
    case UPDATE_COUPON_REJECTED: {
      return {
        ...state,
        couponsError: action.payload,
      };
    }
    case ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED: {
      /*
      Remove the subscriber from the cancelled list
      */
      const cancelledSubscribers = state.subscribers;
      const newCancelledSubscribers = [];
      cancelledSubscribers.forEach((subscriber) => {
        if (subscriber.id !== action.payload.subscriberId) {
          newCancelledSubscribers.push(subscriber);
        }
      });
      return update(
        state, {
          subscribers: {
            $set: newCancelledSubscribers,
          },
          addSubscriberToProgramError: {
            $set: '',
          },
        },
      );
    }
    case ADD_SUBSCRIBER_TO_PROGRAM_REJECTED: {
      return {
        ...state,
        addSubscriberToProgramError: action.payload,
      };
    }
    case CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR: {
      return {
        ...state,
        addSubscriberToProgramError: '',
      };
    }
    case SET_RESELLER_OVERLAY:
      return {
        ...state,
        activeOverlay: action.payload,
      };

    default: {
      return {
        ...state,
      };
    }
  }
}
