import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from '@emotion/styled';
import Logger from 'js-logger';

import Select from 'react-select';
import Toggle from '../../../shared/components/Toggle/Toggle';
import { FormGroup } from '../../../shared/components/FormHandler/FormHandler';
import FormLabel from '../../../shared/components/FormLabel/FormLabel';


const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
  :first-of-type {
    margin-right: 30px;
  }
`;

const categoryOptions = [
  { value: 'L', label: 'Lift' },
  { value: 'R', label: 'SAQ + C' },
  { value: 'C', label: 'Circuits' },
];

const BoardFormFirstSlide = ({
  exercises,
  formProps,
  fetchExercises,
  groups,
  isExercisesDisabled,
  isGroupsDisabled,
  isLoadingExercises,
  isLoadingGroups,
}) => {
  const defaultCategoryOption = categoryOptions.filter(
    category => category.value === formProps.values.category
  );

  const defaultExerciseOption = exercises.filter(
    exercise => exercise.id === formProps.values.exerciseId
  );

  return (
    <Fragment>
      <FormGroup>
        <FormLabel>Select Category</FormLabel>
        <Select
          defaultValue={defaultCategoryOption.length !== 0
            ? ({ ...defaultCategoryOption[0] })
            : ''
          }
          options={categoryOptions}
          onChange={(options) => { fetchExercises(options.value); formProps.setFieldValue('category', options.value); Logger.debug(formProps.values); }}
          // onChange={(options) => { formProps.setFieldValue('category', options.value); }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Select Exercise</FormLabel>
        <Select
          defaultValue={defaultExerciseOption.length !== 0
            ? ({ ...defaultExerciseOption[0] })
            : ''
          }
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isDisabled={isExercisesDisabled}
          isLoading={isLoadingExercises}
          options={exercises}
          onChange={(option) => { formProps.setFieldValue('exerciseId', option.id); formProps.setFieldValue('exerciseName', option.name); Logger.debug(formProps); }}
          isOptionSelected={option => formProps.values.exercise === option.id}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Add Groups</FormLabel>
        <Select
          // components={animatedComponents}
          defaultValue={formProps.values.groups}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isDisabled={isGroupsDisabled}
          options={groups}
          onChange={(option) => { formProps.setFieldValue('groups', option); Logger.debug(option); }}
          isMulti
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Include Athletes That Match</FormLabel>
        <FormColumns>
          <ColumnContent>
            <FormLabel>Any of Selected Groups</FormLabel>
            <Toggle
              checked={!formProps.values.mustBeInAllGroups && true}
              defaultChecked={!formProps.values.mustBeInAllGroups && true}
              // disabled={!formProps.values.mustBeInAllGroups}
              icons={false}
              onChange={() => {
                formProps.setFieldValue(
                  'mustBeInAllGroups',
                  false,
                );
              }}
              value={!formProps.values.mustBeInAllGroups && true}
            />
          </ColumnContent>
          <ColumnContent>
            <FormLabel>All of Selected Groups</FormLabel>
            <Toggle
              defaultChecked={formProps.values.mustBeInAllGroups}
              disabled={formProps.values.mustBeInAllGroups}
              checked={formProps.values.mustBeInAllGroups}
              icons={false}
              onChange={() => {
                formProps.setFieldValue(
                  'mustBeInAllGroups',
                  true,
                );
              }}
              value={formProps.values.mustBeInAllGroups}
            />
          </ColumnContent>
        </FormColumns>
      </FormGroup>
    </Fragment>
  );
};

export default BoardFormFirstSlide;
