/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import OptOutModal from './OptOutModal';

import { setOptOutWorkout } from '../../ducks/workoutsActions';

const OptOutOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const OptOutModalContainer = () => {
  const optOutWorkout = useSelector((state) => state.workouts.data.optOutWorkout);

  const dispatch = useDispatch();

  return (
    <>
      <OptOutModal
        optOutWorkout={optOutWorkout}
        setOptOutModal={setOptOutWorkout}
      />
      <OptOutOverlay
        isActive={Object.keys(optOutWorkout).length}
        onClick={() => dispatch(setOptOutWorkout({}))}
      />
    </>
  );
};

OptOutModalContainer.propTypes = { };

export default OptOutModalContainer;
