import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';

import useDocuments from '../hooks/useDocuments';
import NoHoverButton from './NoHoverButton';
import Text from '../../../../shared/components/Text/Text';
import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Button from '../../../../shared/components/Button/Button';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const RightWrapper = styled('div')`
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  .formik-form {
    width: 100%;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const LeftWrapper = styled('div')`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: right;
  height: 100%;

  .icon-text {
    margin-top: 10px;
    font-size: 16px;
  }
`;

const IconTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .update-button {
    width: 170px;
    margin-top: 15px;
    color: black;
    background: white;
    border: 1px solid black;
    &:hover {
      border: 1px solid black;
      opacity: .7;
    }
  }
`;

const URLWrapper = styled('div')`
  width: 90%;
  margin-bottom: 37px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const FormGroup = styled('div')`
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    /* margin-top: 30px; */
    margin-bottom: 15px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .folder-text {
    font-weight: bold;
  }
  .group-access-text {
    font-weight: bold;
    margin-top: 8px;
  }
`;

const ModalUpdateDocument = ({
  handleUpdateDocument,
  currentUser,
  document,
  onRequestClose,
  currentFolder,
}) => {
  const {
    handleFetchFile,
  } = useDocuments();

  const updateDocumentError = useSelector((state) => state.documents.data.updateDocumentError);
  const visibleFolders = useSelector((state) => state.documents.data.folders);
  const userGroups = useSelector((state) => state.documents.data.userGroups);
  const folders = useSelector((state) => state.documents.data.folders);
  const documents = useSelector((state) => state.documents.data.documents);
  const folderDocuments = useSelector((state) => state.documents.data.folderDocuments);
  const isDocumentUpdating = useSelector((state) => state.documents.ui.isDocumentUpdating);

  const fileFetcherDownloader = async (accountCode, docId) => {
    await handleFetchFile({ accountCode, docId });
  };

  let defaultDocumentGroupIds;
  if (document && document.userGroupAccess) {
    const filterOutDeletedGroups = document.userGroupAccess.filter((group) => group.name === null);
    defaultDocumentGroupIds = filterOutDeletedGroups.length
      ? filterOutDeletedGroups.map((group) => group.id) : [];
  }

  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedGroups, setSelectedGroups] = useState(
    defaultDocumentGroupIds.length
      ? defaultDocumentGroupIds
      : [],
  );

  const iconResolver = () => {
    if (document.type === 0) {
      return 'folder';
    }
    if (document.type === 1) {
      return 'file';
    }
    if (document.type === 2) {
      return 'globe';
    }
    return null;
  };

  const updateText = () => {
    if (document.type === 0) {
      return 'FOLDER';
    }
    if (document.type === 1) {
      return 'FILE';
    }
    if (document.type === 2) {
      return 'LINK';
    }
    return null;
  };

  const handleSubmit = (values) => {
    const newValues = values;
    if (!newValues.groups.length && (currentFolder && currentFolder.userGroupAccess)) {
      newValues.groups = currentFolder.userGroupAccess;
    }
    if (!newValues.groups.length && (selectedFolder && selectedFolder.userGroupAccess)) {
      newValues.groups = selectedFolder.userGroupAccess;
    }
    if (!newValues.linkUrl) {
      delete newValues.linkUrl;
    }
    const indexOb = {};
    if (currentFolder) {
      indexOb.folderIndex = folderDocuments.findIndex(
        (documentItem) => documentItem.id === document.id,
      );
      if (document.parentFolderId !== values.folderId) {
        indexOb.oldFolderIndex = indexOb.folderIndex;
      }
    } else if (document.type === 0) {
      indexOb.folderDocIndex = folders.findIndex(
        (documentItem) => documentItem.id === document.id,
      );
      indexOb.documentIndex = documents.findIndex(
        (documentItem) => documentItem.id === document.id,
      );
    } else {
      indexOb.documentIndex = documents.findIndex(
        (documentItem) => documentItem.id === document.id,
      );
      if (document.parentFolderId !== values.folderId) {
        indexOb.oldDocumentIndex = indexOb.documentIndex;
      }
    }
    const groupIds = values.groups.map((group) => group.id);
    newValues.groups = groupIds;
    handleUpdateDocument(currentUser.accountCode, document.id, newValues, indexOb);
  };

  const selectedGroupsDisplay = (id) => {
    const documentGroupIds = document.userGroupAccess.map((group) => group.id);
    if (documentGroupIds.includes(id)) {
      return false;
    }
    return true;
  };

  const groupsRefiner = () => {
    const userGroupIdsArray = userGroups.map((group) => group.id);
    if (selectedFolder && selectedFolder.id === 0) {
      return userGroups;
    }
    if (currentFolder && userGroups) {
      if (currentFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const acceptedGroups = [];
      currentFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    if ((selectedFolder && Object.keys(selectedFolder).length) && userGroups) {
      if (selectedFolder.userGroupAccess.length === 0) {
        return userGroups;
      }
      const acceptedGroups = [];
      selectedFolder.userGroupAccess.forEach((group) => {
        if (userGroupIdsArray.includes(group.id)) {
          acceptedGroups.push(group);
        }
      });
      return acceptedGroups;
    }
    return userGroups;
  };

  const foldersRefiner = () => {
    const returnFolders = [];
    if (selectedGroups.length) {
      visibleFolders.forEach((folder) => {
        const folderGroupIds = folder.userGroupAccess.map((group) => group.id);
        let add = false;
        for (let i = 0; i < selectedGroups.length; i += 1) {
          if (!folderGroupIds.includes(selectedGroups[i])) {
            add = false;
            break;
          }
          add = true;
        }
        if (add) {
          returnFolders.push(folder);
        }
      });
      return returnFolders;
    }
    return visibleFolders;
  };

  const displayGroups = groupsRefiner();
  const displayFolders = currentFolder ? [currentFolder, { id: 0, name: 'Main Directory', type: 0 }] : foldersRefiner();

  const areGroupsEditable = () => {
    let editable = false;
    if (document && userGroups) {
      if (userGroups && !userGroups.length) {
        return editable;
      }
      const documentGroupIds = document.userGroupAccess.filter((group) => group.name !== null)
        .map((group) => group.id);
      const accessibleGroupIds = userGroups.map((group) => group.id);
      if (documentGroupIds.length === 0) {
        editable = true;
        return editable;
      }
      for (let i = 0; i < documentGroupIds.length; i += 1) {
        if (!accessibleGroupIds.includes(documentGroupIds[i])) {
          editable = false;
          break;
        }
        editable = true;
      }
    }
    return editable;
  };

  const defaultFolderFunction = (folderId) => {
    let returnFolder;
    visibleFolders.forEach((visibleFolder) => {
      if (visibleFolder.id === folderId) {
        returnFolder = visibleFolder;
      }
    });
    return returnFolder;
  };

  const displayFoldersFilter = (option, folderId) => {
    if (option === folderId) {
      return false;
    }
    return true;
  };

  return (
    <ModalWrapper>
      <Formik
        initialValues={{
          name: document.name,
          description: document.description,
          groups: document.userGroupAccess.filter((group) => group.name !== null),
          folderId: currentFolder ? currentFolder.id : 0,
          linkUrl: document.url,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values);
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          setFieldValue,
          values,
          errors,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapper>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapper>
              <FormWrapper>
                <LeftWrapper>
                  <IconTextWrapper>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={75}
                      icon={iconResolver()}
                    />
                    <SubText className='icon-text'>
                      UPDATE
                      {' '}
                      {updateText()}
                    </SubText>
                    {updateDocumentError ? <TitleError className='error-text'>{updateDocumentError}</TitleError> : null}
                    {document.type === 1 ? (
                      <Button
                        className='update-button'
                        cta='Preview File'
                        noHover
                        noBorder
                        primary
                        type='button'
                        onClick={() => {
                          const { accountCode } = currentUser;
                          const docId = document.id;
                          fileFetcherDownloader(accountCode, docId, document.name);
                        }}
                      />
                    ) : null}
                  </IconTextWrapper>
                  {document.type === 2 ? (
                    <URLWrapper>
                      <FormGroup>
                        <TitlePlusErrorWrapper>
                          <Text><strong>URL</strong></Text>
                        </TitlePlusErrorWrapper>
                        <Field
                          className='form-control'
                          id='url-field'
                          name='linkUrl'
                          placeholder='https://domain.com'
                        />
                      </FormGroup>
                    </URLWrapper>
                  ) : null}
                </LeftWrapper>
                <RightWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <Text><strong>Name</strong></Text>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Document Name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text><strong>Description</strong></Text>
                      <Field
                        className='form-control'
                        id='description-field'
                        name='description'
                        placeholder='Document Description'
                      />
                    </FormGroup>
                    {document.type !== 0 ? (
                      <FormGroup>
                        <Text className='folder-text'>Folder</Text>
                        <Select
                          defaultValue={defaultFolderFunction(values.folderId)}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          options={displayFolders}
                          onChange={(folder) => {
                            setSelectedFolder(folder);
                            if (folder) {
                              setFieldValue('folderId', folder.id);
                            }
                          }}
                          isOptionSelected={(folder) => values.folder === folder}
                          isClearable
                        />
                      </FormGroup>
                    ) : null}
                    <FormGroup>
                      <Text className='group-access-text'>Group Access</Text>
                      <Select
                        defaultValue={values.groups}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        filterOption={(option) => selectedGroupsDisplay(option.value)}
                        options={displayGroups.filter((group) => group.name !== null)}
                        onChange={(options) => {
                          const groupIds = options.map((group) => group.id);
                          setSelectedGroups(groupIds);
                          setFieldValue('groups', options);
                        }}
                        isDisabled={!areGroupsEditable()}
                        isOptionSelected={(group) => values.group === group}
                        isClearable
                        isMulti
                      />
                    </FormGroup>
                  </Form>
                </RightWrapper>
              </FormWrapper>
            </AnotherWrapper>
            <NoHoverButton
              bottom
              fullWidth
              cta={!isDocumentUpdating ? `Update ${updateText()}` : 'Updating...'}
              customColor='#10cd8c'
              className='modal-button'
              disabled={isDocumentUpdating}
              large
              noBorder
              primary
              square
              onClick={() => {
                submitForm();
              }}
            />
          </ModalWrapper>
        )}
      />
    </ModalWrapper>
  );
};

ModalUpdateDocument.propTypes = {
  handleUpdateDocument: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  document: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  // eslint-disable-next-line react/require-default-props
  currentFolder: PropTypes.oneOfType(
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ),
};

export default ModalUpdateDocument;
