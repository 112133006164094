import { useDispatch, useSelector } from 'react-redux';

import {
  setModalOpen,
} from '../../ducks/reportingActions';

const useMaxReportModal = () => {
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const isModalOpen = useSelector((state) => state.reporting.ui.isModalOpen);

  const dispatch = useDispatch();

  const handleSetIsModalOpen = (value, activeModalSelector) => {
    dispatch(setModalOpen(value, activeModalSelector));
  };

  return {
    activeModal,
    handleSetIsModalOpen,
    isModalOpen,
  };
};

export default useMaxReportModal;
