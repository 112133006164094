import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Route, Link, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Helmet from 'react-helmet';
import Spinner from '../../shared/components/Spinner/Spinner';

import useDocuments from './components/hooks/useDocuments';
import Layout from '../../shared/components/Layout/Layout';
import DocumentSearchForm from './components/presentational/DocumentSearch';
import ModalHandler from './ModalHandler';
import CustomTable from './CustomTable';

import {
  setActiveSearchStringRedux,
} from './ducks/documentsActions';

const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Documents = ({ match }) => {
  const [currentFolder, setCurrentFolder] = useState(false);
  // const [activeSearchString, setActiveSearchString] = useState('');

  const dispatch = useDispatch();

  const {
    handleFetchDocuments,
    handleFetchDocument,
    handleFetchFolder,
    handleFetchFile,
    handleFolderSearch,
    handleFetchUserGroups,
    handleFetchFolders,
    currentUser,
  } = useDocuments();

  const documents = useSelector((state) => state.documents.data.documents);
  const folderDocuments = useSelector((state) => state.documents.data.folderDocuments);
  const fetchedDocument = useSelector((state) => state.documents.data.document);
  const isFolderLoading = useSelector((state) => state.documents.ui.isFolderLoading);
  const isFoldersLoading = useSelector((state) => state.documents.ui.isFoldersLoading);
  const isDocumentLoading = useSelector((state) => state.documents.ui.isDocumentLoading);
  const isDocumentsLoading = useSelector((state) => state.documents.ui.isDocumentsLoading);
  const currentLocation = useSelector((state) => state.router.location.pathname);
  const currentFile = useSelector((state) => state.documents.data.file);
  const fetchDocumentError = useSelector((state) => state.documents.data.fetchDocumentError);
  const userGroups = useSelector((state) => state.documents.data.userGroups);
  const visibleFolders = useSelector((state) => state.documents.data.folders);

  const fileFetcherDownloader = async (accountCode, docId) => {
    handleFetchFile({ accountCode, docId });
  };

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    const pathArray = currentLocation.split('/');
    const pathLength = pathArray.length;
    if (accountCode !== undefined && !documents.length) {
      handleFetchDocuments(accountCode);
    }
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
    }
    if (accountCode !== undefined
      && !currentFolder
      && !visibleFolders.length
      && !isFoldersLoading
    ) {
      handleFetchFolders(accountCode);
    }
    if (!currentFolder && pathLength === 3 && currentUser) {
      const docId = parseInt(pathArray[2], 10);
      if (accountCode && !isDocumentLoading) {
        handleFetchDocument({ accountCode, docId });
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && currentFolder) {
      const folderId = currentFolder.id;
      handleFetchFolder({ accountCode, folderId });
    }
  }, [currentFolder]);

  useEffect(() => {
    if (currentFile) {
      window.open(currentFile.url, '_blank');
    }
  }, [currentFile]);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined) {
      if (fetchedDocument) {
        if (fetchedDocument.type === 1 || fetchedDocument.type === 2) {
          const docId = fetchedDocument.id;
          const { parentFolderId } = fetchedDocument;
          if (fetchedDocument.type === 1) {
            fileFetcherDownloader(accountCode, docId, fetchedDocument.name);
          }
          if (fetchedDocument.type === 2) {
            window.open(fetchedDocument.url, '_blank');
          }
          if (parentFolderId !== 0) {
            handleFetchDocument({ accountCode, docId: parentFolderId });
            dispatch(push(`/documents/${parentFolderId}`));
          } else {
            dispatch(push('/documents'));
          }
        }
      }
    }
  }, [fetchedDocument]);

  useEffect(() => {
    if (fetchDocumentError) {
      dispatch(push('/documents'));
    }
  }, [fetchDocumentError]);

  const setActiveSearchString = (activeModalString) => {
    dispatch(setActiveSearchStringRedux(activeModalString));
  };

  const pathWatcher = () => {
    const pathArray = currentLocation.split('/');
    const pathLength = pathArray.length;
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (currentFolder && pathArray < 3) {
      handleFolderSearch({ accountCode, values: { search: '' }, currentFolder });
      setCurrentFolder(false);
      setActiveSearchString('');
    }
    if (!currentFolder && fetchedDocument && pathLength === 3) {
      if (fetchedDocument.type === 0) {
        setCurrentFolder(fetchedDocument);
      }
    }
    if (pathLength < 3 && currentFolder) {
      handleFolderSearch({ accountCode, values: { search: '' }, currentFolder });
      setCurrentFolder(false);
      setActiveSearchString('');
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>Documents & Links | TeamBuildr</title>
      </Helmet>
      {isDocumentsLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>

          <Switch>
            {/* Child Path with content */}
            <Route path={`${match.path}/:documentId`}>
              <DocumentSearchForm
                currentFolder={currentFolder}
                directoryName={currentFolder.name || 'Loading..'}
                setActiveSearchString={setActiveSearchString}
              />
              <CustomTable
                data={folderDocuments}
                setCurrentFolder={setCurrentFolder}
                setActiveSearchString={setActiveSearchString}
                currentFolder={currentFolder}
              />
            </Route>
            {/* // Parent route with content */}
            <Route path={match.path}>
              <>
                <DocumentSearchForm
                  currentFolder={currentFolder}
                  directoryName='Documents and Links'
                  setActiveSearchString={setActiveSearchString}
                />
                <CustomTable
                  data={documents}
                  setCurrentFolder={setCurrentFolder}
                  setActiveSearchString={setActiveSearchString}
                />
              </>
            </Route>
          </Switch>
          <ModalHandler
            currentFolder={currentFolder}
            setCurrentFolder={setCurrentFolder}
            setActiveSearchString={setActiveSearchString}
          />
          {pathWatcher()}
        </>
      )}
    </Layout>
  );
};

Documents.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default Documents;
