export const FETCH_WORKOUTS_START = 'FETCH_WORKOUTS_START';
export const FETCH_WORKOUTS_FULFILLED = 'FETCH_WORKOUTS_FULFILLED';
export const FETCH_WORKOUTS_REJECTED = 'FETCH_WORKOUTS_REJECTED';

export const FETCH_MULTIPLE_WORKOUTS_START = 'FETCH_MULTIPLE_WORKOUTS_START';
export const FETCH_MULTIPLE_WORKOUTS_FULFILLED = 'FETCH_MULTIPLE_WORKOUTS_FULFILLED';
export const FETCH_MULTIPLE_WORKOUTS_REJECTED = 'FETCH_MULTIPLE_WORKOUTS_REJECTED';

export const FETCH_WORKOUT_PROGRAMS_START = 'FETCH_WORKOUT_PROGRAMS_START';
export const FETCH_WORKOUT_PROGRAMS_FULFILLED = 'FETCH_WORKOUT_PROGRAMS_FULFILLED';
export const FETCH_WORKOUT_PROGRAMS_REJECTED = 'FETCH_WORKOUT_PROGRAMS_REJECTED';

export const FETCH_WORKOUT_PROGRAM_WEEKS_START = 'FETCH_WORKOUT_PROGRAM_WEEKS_START';
export const FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED = 'FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED';
export const FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED = 'FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED';

export const FETCH_WORKOUT_PROGRAM_WORKOUTS_START = 'FETCH_WORKOUT_PROGRAM_WORKOUTS_START';
export const FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED = 'FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED';
export const FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED = 'FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED';

export const FETCH_WORKOUT_PROGRAM_WEEK_START = 'FETCH_WORKOUT_PROGRAM_WEEK_START';
export const FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED = 'FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED';
export const FETCH_WORKOUT_PROGRAM_WEEK_REJECTED = 'FETCH_WORKOUT_PROGRAM_WEEK_REJECTED';

export const FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_START = 'FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_START';
export const FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED = 'FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED';
export const FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_REJECTED = 'FETCH_WORKOUT_PROGRAM_WEEK_REJECTED';

export const FETCH_NEXT_WORKOUT_PROGRAM_WEEK_START = 'FETCH_NEXT_WORKOUT_PROGRAM_WEEK_START';
export const FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED = 'FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED';
export const FETCH_NEXT_WORKOUT_PROGRAM_WEEK_REJECTED = 'FETCH_NEXT_WORKOUT_PROGRAM_WEEK_REJECTED';

export const FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START = 'FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START';
export const FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED = 'FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED';
export const FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED = 'FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED';

export const FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_START = 'FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_START';
export const FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED = 'FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED';
export const FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_REJECTED = 'FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_REJECTED';

export const FETCH_WORKOUT_PROGRAM_DAYS_START = 'FETCH_WORKOUT_PROGRAM_DAYS_START';
export const FETCH_WORKOUT_PROGRAM_DAYS_FULFILLED = 'FETCH_WORKOUT_PROGRAM_DAYS_FULFILLED';
export const FETCH_WORKOUT_PROGRAM_DAYS_REJECTED = 'FETCH_WORKOUT_PROGRAM_DAYS_REJECTED';

export const SET_ACTIVE_WORKOUT_DATE = 'SET_ACTIVE_WORKOUT_DATE';

export const SET_IS_RESET_PROGRAM_MODAL_SHOWING = 'SET_IS_RESET_PROGRAM_MODAL_SHOWING';

export const WIPE_MEDIA_ERROR = 'WIPE_MEDIA_ERROR';

export const SET_TAG_WORKOUT = 'SET_TAG_WORKOUT';

export const SET_IS_DAY_CAROUSEL = 'SET_IS_DAY_CAROUSEL';

export const GET_EXERTION_SCORE_START = 'GET_EXERTION_SCORE_START';
export const GET_EXERTION_SCORE_FULFILLED = 'GET_EXERTION_SCORE_FULFILLED';
export const GET_EXERTION_SCORE_REJECTED = 'GET_EXERTION_SCORE_REJECTED';

export const GET_UPDATED_ACTIVE_DATE_START = 'GET_UPDATED_ACTIVE_DATE_START';
export const GET_UPDATED_ACTIVE_DATE_FULFILLED = 'GET_UPDATED_ACTIVE_DATE_FULFILLED';
export const GET_UPDATED_ACTIVE_DATE_REJECTED = 'GET_UPDATED_ACTIVE_DATE_REJECTED';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_FULLFILLED = 'GET_USERS_FULLFILLED';
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED';

export const SET_SHARED_ATHLETE = 'SET_SHARED_ATHLETE';

export const SET_IS_WORKOUTS_CAROUSEL_SHOWING = 'SET_IS_WORKOUTS_CAROUSEL_SHOWING';

export const UPDATE_CURRENT_NON_DATE_DAY = 'UPDATE_CURRENT_NON_DATE_DAY';

export const FETCH_WORKOUT_CALENDAR_START = 'FETCH_WORKOUT_CALENDAR_START';
export const FETCH_WORKOUT_CALENDAR_FULFILLED = 'FETCH_WORKOUT_CALENDAR_FULFILLED';
export const FETCH_WORKOUT_CALENDAR_REJECTED = 'FETCH_WORKOUT_CALENDAR_REJECTED';

export const REFETCH_CURRENT_PROGRAM_WORKOUTS_START = 'REFETCH_CURRENT_PROGRAM_WORKOUTS_START';
export const REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED = 'REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED';
export const REFETCH_CURRENT_PROGRAM_WORKOUTS_REJECTED = 'REFETCH_CURRENT_PROGRAM_WORKOUTS_REJECTED';

export const FETCH_WORKOUT_CALENDAR_START_ALT = 'FETCH_WORKOUT_CALENDAR_START_ALT';
export const FETCH_WORKOUT_CALENDAR_FULFILLED_ALT = 'FETCH_WORKOUT_CALENDAR_FULFILLED_ALT';
export const FETCH_WORKOUT_CALENDAR_REJECTED_ALT = 'FETCH_WORKOUT_CALENDAR_REJECTED_ALT';

export const FETCH_WORKOUT_SESSION_START = 'FETCH_WORKOUT_SESSION_START';
export const FETCH_WORKOUT_SESSION_FULFILLED = 'FETCH_WORKOUT_SESSION_FULFILLED';
export const FETCH_WORKOUT_SESSION_REJECTED = 'FETCH_WORKOUT_SESSION_REJECTED';

export const COMPLETE_WORKOUT_START = 'COMPLETE_WORKOUT_START';
export const COMPLETE_WORKOUT_FULFILLED = 'COMPLETE_WORKOUT_FULFILLED';
export const COMPLETE_WORKOUT_REJECTED = 'COMPLETE_WORKOUT_REJECTED';

export const SKIP_WORKOUT_PROGRAM_WORKOUTS_START = 'SKIP_WORKOUT_PROGRAM_WORKOUTS_START';
export const SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED = 'SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED';
export const SKIP_WORKOUT_PROGRAM_WORKOUTS_REJECTED = 'SKIP_WORKOUT_PROGRAM_WORKOUTS_REJECTED';

export const FETCH_WORKOUTS_EXERCISES_START = 'FETCH_WORKOUTS_EXERCISES_START';
export const FETCH_WORKOUTS_EXERCISES_FULFILLED = 'FETCH_WORKOUTS_EXERCISES_FULFILLED';
export const FETCH_WORKOUTS_EXERCISES_REJECTED = 'FETCH_WORKOUTS_EXERCISES_REJECTED';

export const REFRESH_WORKOUTS_START = 'REFRESH_WORKOUTS_START';

export const SET_IS_SKIP_WORKOUT_MODAL_SHOWING = 'SET_IS_SKIP_WORKOUT_MODAL_SHOWING';

export const FETCH_SIMILAR_EXERCISES_START = 'FETCH_SIMILAR_EXERCISES_START';
export const FETCH_SIMILAR_EXERCISES_FULFILLED = 'FETCH_SIMILAR_EXERCISES_FULFILLED';
export const FETCH_SIMILAR_EXERCISES_REJECTED = 'FETCH_SIMILAR_EXERCISES_REJECTED';

export const SUBMIT_OPT_OUT_START = 'SUBMIT_OPT_OUT_START';
export const SUBMIT_OPT_OUT_FULFILLED = 'SUBMIT_OPT_OUT_FULFILLED';
export const SUBMIT_OPT_OUT_REJECTED = 'SUBMIT_OPT_OUT_REJECTED';

export const SUBMIT_OPT_OUT_TOP_FULFILLED = 'SUBMIT_OPT_OUT_TOP_FULFILLED';

export const SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING = 'SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING';

export const GET_WORKOUTS_EXERTION_SCORE_START = 'GET_WORKOUTS_EXERTION_SCORE_START';
export const GET_WORKOUTS_EXERTION_SCORE_FULFILLED = 'GET_WORKOUTS_EXERTION_SCORE_FULFILLED';
export const GET_WORKOUTS_EXERTION_SCORE_REJECTED = 'GET_WORKOUTS_EXERTION_SCORE_REJECTED';

export const FETCH_OPT_OUT_ALL_LIFT_EXERCISES_START = 'FETCH_OPT_OUT_ALL_LIFT_EXERCISES_START';
export const FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED = 'FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED';
export const FETCH_OPT_OUT_ALL_LIFT_EXERCISES_REJECTED = 'FETCH_OPT_OUT_ALL_LIFT_EXERCISES_REJECTED';

export const SET_OPT_OUT_EXERCISE_TYPE_FILTER = 'SET_OPT_OUT_EXERCISE_TYPE_FILTER';

export const SET_ACTIVE_WORKOUTS_MEDIA = 'SET_ACTIVE_WORKOUTS_MEDIA';

export const SET_OPT_OUT_WORKOUT = 'SET_OPT_OUT_WORKOUT';

export const SET_ADDITIONAL_INFO_WORKOUT = 'SET_ADDITIONAL_INFO_WORKOUT';

export const SET_TIMER_DURATION = 'SET_TIMER_DURATION';

export const SET_TIMER_MODAL_OPEN = 'SET_TIMER_MODAL_OPEN';

export const SET_EXERTION_SCORE_MODAL_SHOWING = 'SET_EXERTION_SCORE_MODAL_SHOWING';

export const SET_IS_DAY_PICKER_OPEN = 'SET_IS_DAY_PICKER_OPEN';

export const SET_QUESTIONNAIRE_TOTAL = 'SET_QUESTIONNAIRE_TOTAL';

export const SET_CAPTURE_IMAGE_MODAL = 'SET_CAPTURE_IMAGE_MODAL';

export const SET_CAPTURE_VIDEO_MODAL = 'SET_CAPTURE_VIDEO_MODAL';

export const SET_HISTORY_WORKOUT = 'SET_HISTORY_WORKOUT';

export const SET_IS_PRINTING_OPTIONS_SHOWING = 'SET_IS_PRINTING_OPTIONS_SHOWING';

export const SET_JOURNAL_ENTRY_WORKOUT = 'SET_JOURNAL_ENTRY_WORKOUT';

export const SET_UPLOAD_WORKOUT_MEDIA_FALSE = 'SET_UPLOAD_WORKOUT_MEDIA_FALSE';

export const SET_UPLOAD_WORKOUT_JOURNAL_MEDIA_FALSE = 'SET_UPLOAD_WORKOUT_JOURNAL_MEDIA_FALSE';

export const SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY = 'SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY';

export const SET_ADDITIONAL_WORKOUTS_DATA = 'SET_ADDITIONAL_WORKOUTS_DATA';

export const SET_IS_ADDITIONAL_WORKOUTS = 'SET_IS_ADDITIONAL_WORKOUTS';

export const SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN = 'SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN';

export const SET_HIDDEN_WORKOUTS_TOGGLE = 'SET_HIDDEN_WORKOUTS_TOGGLE';

export const SUBMIT_WORKOUT_START = 'SUBMIT_WORKOUT_START';
export const SUBMIT_WORKOUT_REJECTED = 'SUBMIT_WORKOUT_REJECTED';
export const SUBMIT_WORKOUT_FULFILLED = 'SUBMIT_WORKOUT_FULFILLED';

export const SUBMIT_PROGRAM_WORKOUT_START = 'SUBMIT_PROGRAM_WORKOUT_START';
export const SUBMIT_PROGRAM_WORKOUT_REJECTED = 'SUBMIT_PROGRAM_WORKOUT_REJECTED';
export const SUBMIT_PROGRAM_WORKOUT_FULFILLED = 'SUBMIT_PROGRAM_WORKOUT_FULFILLED';

export const SUBMIT_SESSION_WORKOUT_START = 'SUBMIT_SESSION_WORKOUT_START';
export const SUBMIT_SESSION_WORKOUT_REJECTED = 'SUBMIT_SESSION_WORKOUT_REJECTED';
export const SUBMIT_SESSION_WORKOUT_FULFILLED = 'SUBMIT_SESSION_WORKOUT_FULFILLED';

export const SUBMIT_PROGRAM_SESSION_WORKOUT_START = 'SUBMIT_PROGRAM_SESSION_WORKOUT_START';
export const SUBMIT_PROGRAM_SESSION_WORKOUT_REJECTED = 'SUBMIT_PROGRAM_SESSION_WORKOUT_REJECTED';
export const SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED = 'SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED';

export const WRITE_WORKOUT_NOTE_TO_ATHLETE_START = 'WRITE_WORKOUT_NOTE_TO_ATHLETE_START';
export const WRITE_WORKOUT_NOTE_TO_ATHLETE_REJECTED = 'WRITE_WORKOUT_NOTE_TO_ATHLETE_REJECTED';
export const WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED = 'WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED';

export const WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED = 'WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED';

export const FETCH_WORKOUT_HISTORY_START = 'FETCH_WORKOUT_HISTORY_START';
export const FETCH_WORKOUT_HISTORY_REJECTED = 'FETCH_WORKOUT_HISTORY_REJECTED';
export const FETCH_WORKOUT_HISTORY_FULFILLED = 'FETCH_WORKOUT_HISTORY_FULFILLED';

export const FETCH_MORE_WORKOUT_HISTORY_START = 'FETCH_MORE_WORKOUT_HISTORY_START';
export const FETCH_MORE_WORKOUT_HISTORY_REJECTED = 'FETCH_MORE_WORKOUT_HISTORY_REJECTED';
export const FETCH_MORE_WORKOUT_HISTORY_FULFILLED = 'FETCH_MORE_WORKOUT_HISTORY_FULFILLED';

export const SET_SELECTED_TAG_EXERCISE = 'SET_SELECTED_TAG_EXERCISE';

export const FETCH_TAG_EXERCISES_START = 'FETCH_TAG_EXERCIES_START';
export const FETCH_TAG_EXERCISES_FULFILLED = 'FETCH_TAG_EXERCISES_FULFILLED';
export const FETCH_TAG_EXERCISES_REJECTED = 'FETCH_TAG_EXERCISES_REJECTED';

export const UPLOAD_WORKOUTS_MEDIA_START = 'UPLOAD_WORKOUTS_MEDIA_START';
export const UPLOAD_WORKOUTS_MEDIA_REJECTED = 'UPLOAD_WORKOUTS_MEDIA_REJECTED';
export const UPLOAD_WORKOUTS_MEDIA_FULFILLED = 'UPLOAD_WORKOUTS_MEDIA_FULFILLED';

export const UPLOAD_WORKOUT_JOURNAL_MEDIA_START = 'UPLOAD_WORKOUT_JOURNAL_MEDIA_START';
export const UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED = 'UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED';
export const UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED = 'UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED';

export const UPLOAD_QUICK_JOURNAL_MEDIA_START = 'UPLOAD_QUICK_JOURNAL_MEDIA_START';
export const UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED = 'UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED';
export const UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED = 'UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED';

export const SET_IS_WORKOUTS_MEDIA_UPLOADED = 'SET_IS_WORKOUTS_MEDIA_UPLOADED';

export const SET_IS_WORKOUT_JOURNAL_MEDIA_UPLOADED = 'SET_IS_WORKOUT_JOURNAL_MEDIA_UPLOADED';

export const SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL = 'SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL';

export const SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL = 'SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL';

export const SET_QUICK_JOURNAL_MODAL = 'SET_QUICK_JOURNAL_MODAL';

export const SET_QUICK_WORKOUTS_JOURNAL = 'SET_QUICK_WORKOUTS_JOURNAL';

export const SET_PROGRAM_DAY_INDEX = 'SET_PROGRAM_DAY_INDEX';

export const SET_WEEKDAY_INDEX = 'SET_WEEKDAY_INDEX';

export const SET_MULTI_QUESTION_MODAL = 'SET_MULTI_QUESTION_MODAL';

export const SET_MULTI_QUESTION_MODAL_RESPONSE = 'SET_MULTI_QUESTION_MODAL_RESPONSE';

export const SET_IS_PROGRAM_LIST_BOX_MODAL = 'SET_IS_PROGRAM_LIST_BOX_MODAL';

export const SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING = 'SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING';

export const CREATE_WORKOUT_JOURNAL_ENTRY_START = 'CREATE_WORKOUT_JOURNAL_ENTRY_START';
export const CREATE_WORKOUT_JOURNAL_ENTRY_REJECTED = 'CREATE_WORKOUT_JOURNAL_ENTRY_REJECTED';
export const CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED = 'CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED';

export const RESET_WORKOUT_PROGRAM_START = 'RESET_WORKOUT_PROGRAM_START';
export const RESET_WORKOUT_PROGRAM_REJECTED = 'RESET_WORKOUT_PROGRAM_REJECTED';
export const RESET_WORKOUT_PROGRAM_FULFILLED = 'RESET_WORKOUT_PROGRAM_FULFILLED';

export const COMPLETE_WORKOUT_PROGRAM_START = 'COMPLETE_WORKOUT_PROGRAM_START';
export const COMPLETE_WORKOUT_PROGRAM_REJECTED = 'COMPLETE_WORKOUT_PROGRAM_REJECTED';
export const COMPLETE_WORKOUT_PROGRAM_FULFILLED = 'COMPLETE_WORKOUT_PROGRAM_FULFILLED';

export const CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED = 'CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED';

export const SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING = 'SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING';

export const UPDATE_WORKOUT_JOURNAL_ENTRY_START = 'UPDATE_WORKOUT_JOURNAL_ENTRY_START';
export const UPDATE_WORKOUT_JOURNAL_ENTRY_REJECTED = 'UPDATE_WORKOUT_JOURNAL_ENTRY_REJECTED';
export const UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED = 'UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED';

export const UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED = 'UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED';

export const WIPE_OUT_NON_DATE = 'WIPE_OUT_NON_DATE';

export const FETCH_WORKOUT_DOCUMENT_START = 'FETCH_WORKOUT_DOCUMENT_START';
export const FETCH_WORKOUT_DOCUMENT_REJECTED = 'FETCH_WORKOUT_DOCUMENT_REJECTED';
export const FETCH_WORKOUT_DOCUMENT_FULFILLED = 'FETCH_WORKOUT_DOCUMENT_FULFILLED';

export const FETCH_WORKOUT_FILE_START = 'FETCH_WORKOUT_FILE_START';
export const FETCH_WORKOUT_FILE_REJECTED = 'FETCH_WORKOUT_FILE_REJECTED';
export const FETCH_WORKOUT_FILE_FULFILLED = 'FETCH_WORKOUT_FILE_FULFILLED';

export const FETCH_NEXT_WORKOUT_SESSION_START = 'FETCH_NEXT_WORKOUT_SESSION_START';
export const FETCH_NEXT_WORKOUT_SESSION_REJECTED = 'FETCH_NEXT_WORKOUT_SESSION_REJECTED';
export const FETCH_NEXT_WORKOUT_SESSION_FULFILLED = 'FETCH_NEXT_WORKOUT_SESSION_FULFILLED';

export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START';
export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED';
export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_START = 'FETCH_SELECTED_INTEGRATION_BY_DATE_START';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED = 'FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED = 'FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED';

export const SET_SELECTED_INTEGRATION = 'SET_SELECTED_INTEGRATION';

export const SET_IS_INTEGRATION_MODAL_SHOWING = 'SET_IS_INTEGRATION_MODAL_SHOWING';