import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { push } from 'connected-react-router';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const IntegrationSettings = () => {
  const theme = useTheme();
  // const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const AccountInfo = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 455px;
 `;

  const Container = styled('a')`
    display: flex;
    height: 158px;
    width: 100%;
    border-radius: 24px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 23px 27px 23px 40px;
    margin-bottom: 20px;
    background: #F7F7F7;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    cursor: pointer;
  `;

  const InfoWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
  `;

  const IconContainer = styled('div')`
    width: 30px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  const ArrowIconContainer = styled('div')`
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <Container onClick={() => {
      // will enentuall use this dispatch(push()) to link to the react page
      // that wil be built out soon but for now we will use this window.open to direct to php
      // dispatch(push('/settings/integrations'));
      window.open(`${process.env.PHP_APP_URL || 'https://app.teambuildr.com'}/settings/integrations`);
    }}
    >
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <InfoWrapper>
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={24}
            icon='swap'
            color={theme.colors.black}
          />
        </IconContainer>
        <AccountInfo>
          <Text fontSize={containerWidth && containerWidth < 450 ? 20 : 28} fontWeight='700'>
            Integrations
          </Text>
          <Text fontSize={containerWidth && containerWidth < 450 ? 16 : 18} fontWeight='400'>
            Manage different devices and data management integrations to
            supercharge your programming.
          </Text>
        </AccountInfo>
      </InfoWrapper>
      <ArrowIconContainer>
        <IcomoonReact
          iconSet={iconSet}
          size={24}
          icon='right-arrow'
          color={theme.colors.black}
        />
      </ArrowIconContainer>
    </Container>
  );
};

export default IntegrationSettings;
