import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BodyHeatMap from './BodyHeatMap';

const BodyHeatMapWithButtonWrapper = ({ heatMapDisplayData }) => {
  const [sideForMaleWrapperWithButtons, setSideForMaleWrapperWithButtons] = useState('front');

  const getIntensity = (dataObject) => {
    /** if color is grey return intensity level 1 */
    if (dataObject.color === '#9E9E9E') {
      return 1;
    }
    /** if color is yellow return intensity level 2 */
    if (dataObject.color === '#FFCB47') {
      return 2;
    }
    /** if color is green return intensity level 3 */
    if (dataObject.color === '#0DCC8A') {
      return 3;
    }
    /** if color is blue return intensity level 4 */
    if (dataObject.color === '#1787FB') {
      return 4;
    }
    return 1;
  };

  const formattedBodyMapData = heatMapDisplayData?.map((dataObject) => (
    {
      slug: dataObject.bodyPartName,
      intensity: getIntensity(dataObject),
      value: dataObject.value,
    }
  ));

  return (
    <BodyHeatMap
    /** [grey, yellow, green, blue]
     * grey (1) reported soreness but not top 3,
     * yellow (2) third highest reported,
     * green (3) second highest reported,
     * blue (4) highest reported */
      colors={['#9E9E9E', '#FFCB47', '#0DCC8A', '#1787FB']}
      data={formattedBodyMapData}
      /** data example:
      data={[
        { slug: 'quadriceps-right', intensity: 1 },
        { slug: 'hamstring-right', intensity: 2 },
        { slug: 'lower-back-left', intensity: 3 },
        { slug: 'quadriceps-left', intensity: 4 },
      ]}
      */
      side={sideForMaleWrapperWithButtons}
      setSideForMaleWrapperWithButtons={setSideForMaleWrapperWithButtons}
      includeFrontAndBackButtonWrapper
    />
  );
};

BodyHeatMapWithButtonWrapper.propTypes = {
  heatMapDisplayData: PropTypes.arrayOf(PropTypes.shape({
    bodyPartName: PropTypes.string,
    color: PropTypes.string,
    value: PropTypes.number,
  })),
};

BodyHeatMapWithButtonWrapper.defaultProps = {
  heatMapDisplayData: [],
};

export default BodyHeatMapWithButtonWrapper;
