import React from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import ActivityReportTable from './ActivityReportTable';
import ActivityBarLoading from './ActivityBarLoading';
import PieChartLoading from './PieChartLoading';

const TableContainer = styled('div')`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  min-height: 500px;
  margin-top: ${(props) => props.marginTop};
`;

const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: visible;
  flex-grow: 1;
  min-height: 0;
  width: 100%;
`;

const ChartsContainer = styled('div')`
  display: flex;
  @media screen and (max-width : 1224px) {
    flex-direction: column;
  }
  @media screen and (min-width : 1225px) {
    flex-direction: row;
  }
`;
const PieChartContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
`;

const TransparentText = styled('div')`
  color: transparent;
`;

const ActivityReportDataLoading = () => {
  const tableColumns = [
    {
      name: 'first',
      selector: 'first',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
    {
      name: 'last',
      selector: 'last',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
    {
      name: 'Latest Workout Activity',
      selector: 'latest_activity',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
    {
      name: 'Next Scheduled Workout',
      selector: 'next_workout',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
    {
      name: 'Last Scheduled Workout',
      selector: 'last_workout',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
    {
      name: 'last 7 days',
      selector: 'last_7_days',
      cell: (row) => (
        <Shimmer>
          <TransparentText>Testing</TransparentText>
        </Shimmer>
      ),
    },
  ];

  const tableData = [
    {
      first: 'Test',
      last: 'Test',
      latest_activity: 'Test',
      next_workout: 'Test',
      last_workout: 'Test',
      last_7_days: 'Test',
    },
    {
      first: 'Test',
      last: 'Test',
      latest_activity: 'Test',
      next_workout: 'Test',
      last_workout: 'Test',
      last_7_days: 'Test',
    },
    {
      first: 'Test',
      last: 'Test',
      latest_activity: 'Test',
      next_workout: 'Test',
      last_workout: 'Test',
      last_7_days: 'Test',
    },
    {
      first: 'Test',
      last: 'Test',
      latest_activity: 'Test',
      next_workout: 'Test',
      last_workout: 'Test',
      last_7_days: 'Test',
    },
    {
      first: 'Test',
      last: 'Test',
      latest_activity: 'Test',
      next_workout: 'Test',
      last_workout: 'Test',
      last_7_days: 'Test',
    },
  ];

  return (
    <ReportContainer>
      <ChartsContainer>
        <ActivityBarLoading />
        <PieChartContainer>
          <PieChartLoading />
        </PieChartContainer>
      </ChartsContainer>
      <TableContainer marginTop='0px'>
        <ActivityReportTable
          tableColumns={tableColumns}
          tableData={tableData}
        />
      </TableContainer>
    </ReportContainer>
  );
};

export default ActivityReportDataLoading;
