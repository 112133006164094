import {
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  SET_MODAL_OPEN,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchSomething = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_EXERCISES_START });
    axiosAuthed.get(`/accounts/${accountCode}/exercises/`)
      .then((response) => {
        dispatch({
          type: FETCH_EXERCISES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EXERCISES_REJECTED,
          payload: err,
        });
      });
  }
);

export const setModalOpen = () => ({
  type: SET_MODAL_OPEN,
});
