import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import Shimmer from 'react-shimmer-effect';
import { fetchReseller, fetchMorePrograms } from './ducks/programsActions';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import ProgramsLandingPage from './components/program/ProgramsLandingPage';

const ResellerContainer = styled('div')`
`;

const PageWrapper = styled('div')`
  #font-picker {
    position: fixed;
    top: -10000px;
    z-index: -1;
  }
`;

const FeatureContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 325px;
  background: #1d1d1d;
  background-size: cover;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    opacity: .1;
    width: 100%;
    height: 100%;
  }

  h1, span {
    ${(props) => (props.isLoading ? 'color: transparent' : 'color: white')};
  }

  span {
    margin-top: 15px;
  }
`;

const ProgramTitle = styled('div')`
  position: absolute;
  text-align: center;
`;

const ProgramsLandingContainer = () => {
  const { accountName } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoadingReseller = useSelector((state) => state.programs.ui.isLoadingReseller);
  const resellerData = useSelector((state) => state.programs.data.resellerData);

  useEffect(() => {
    // Fetch reseller data and program data
    // We're using useLocation hook to monitor path changes.
    // Listening for path changes with useRouteMatch didn't work.
    batch(() => {
      dispatch(fetchReseller(accountName));
      dispatch(fetchMorePrograms(accountName));
    });
  }, [location]);

  return (
    <ResellerContainer>
      <Helmet>
        <title>{`${resellerData?.resellerName} | TeamBuildr`}</title>
      </Helmet>
      <PageWrapper>
        {isLoadingReseller ? (
          <>
            <FeatureContainer id='FeatureContainer' isLoading>
              <ProgramTitle>
                <Shimmer>
                  <HeaderText fontSize={74} />
                </Shimmer>
              </ProgramTitle>
            </FeatureContainer>
          </>
        ) : (
          <>
            <ProgramsLandingPage
              resellerData={resellerData}
            />
          </>
        )}
      </PageWrapper>
    </ResellerContainer>
  );
};

export default ProgramsLandingContainer;
