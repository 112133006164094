/** @jsx jsx */
import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import EntryIcons from './EntryIcons';
import CoachNotes from './CoachNotes';
import HealthEntries from './WorkoutEntriesHealth';
import NoteEntries from './WorkoutEntriesNotes';
import WorkoutEntries from './WorkoutEntries';
import WeightroomWorkoutEntriesTag from '../container/WeightroomWorkoutEntriesTag';
import Spinner from './Spinner';

import WeightroomQuestionList from '../container/WeightroomQuestionList';
import EntriesProgression from './WorkoutEntriesProgression';
import Timer from './Timer';
import OptedOut from './OptedOut';
import WeightroomModals from '../container/WeightroomModals';

const WorkoutEntryStyle = css`
  border-left: 1px solid rgba(104,104,104,0.2);
  min-height: 180px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  padding-bottom: 11px;
  
  .coach_notes {
    display: flex;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 30px;
    margin-bottom: 10px;
  }
  
  & .reps_view {
    display: flex;
    flex-grow: 1;
    position: relative;
    &.with_notes {
      margin-top: 0px;
      .entry_icons {
        margin-top: 0px;
      }
    }
    &.without_notes {
      margin-top: 20px;
    }

    .entry_reps {
      /* margin-top: -15px; */
      align-self: center;
      padding: 2px 20px;
      overflow-x: auto;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

    }

    & .entry_icons {
      display: flex;
      flex-direction: column;
      margin-left: -11px;
      
      button {
        margin-bottom: 10px;
      }
      
    }
    
    & .note_icons {
      margin-top: 0px!important;
    }
  }
`;

const CenterContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const EntriesContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  & .entry_row {
    display: flex;
    margin-bottom: 10px;
    & .title {
      display: block;
      min-width: 90px;
      font-family: 'Open Sans';
      font-size: 13px;
      font-weight: 900;
      color: ${(props) => props.theme.textColor};
      text-transform: uppercase;
      letter-spacing: 1px;
      align-self: center; 
      
      &.with_label {
        margin-top: 30px;
      }
    }
  }

  & .form-group {
    margin: 0px 0px 0px 10px;
    text-align: center;
    width: 100%;
    min-width: 60px;
    position: relative;

    & label {
      font-family: 'Open Sans';
      font-weight: 900;
      font-size: 13px;
      align-self: center;
    }

    p {
      color: ${(props) => props.theme.textColor};
    }

    & input {
      font-family: 'Open Sans';
      font-weight: 900;
      background: ${(props) => props.theme.backgroundColorL3};
      border: 1px solid ${(props) => props.theme.noBorderColorForm};
      color: ${(props) => props.theme.textColor};
      &::placeholder {
        color: #aaa;
      }
    }
  }
  #timer_wrapper {
    margin-left: 20px;
    transform: scale(.95);
  }

  @media only screen and (max-width : ${(props) => props.maxWidth}) {
    &.isScrolling${(props) => props.numberOfSets} {
      .scrollable-field-container {
        padding-right: 60px;
      }
      ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 100%;
        background-image: ${(props) => props.theme.overflowGradient};
        pointer-events: none;
      }
    }
  }
`;

const WorkoutEntryView = ({
  closeModal,
  data,
  entriesContainerRef,
  entryRowRef,
  fetchDocument,
  fetchHistory,
  fetchExercises,
  fetchSimilarExercises,
  formikRef,
  isWorkoutActive,
  namespace,
  nextButton,
  openModal,
  scienceRef,
  scrollQuestions,
  supersetIndex,
  updateProgramWorkout,
  updateWorkout,
}) => {
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const index = useSelector((state) => state.weightRoomView[namespace].activeWorkoutIndex);
  const isWorkoutLoading = useSelector((
    state,
  ) => state.weightRoomView[namespace].isWorkoutLoading);
  const isWeightValueEnabled = useSelector((state) => state.weightRoomView.ui.isWeightValueEnabled);
  const settings = useSelector((state) => state.weightRoomView.data.currentUser.userSettings);
  const workoutsBySuperset = useSelector((
    state,
  ) => state.weightRoomView[namespace].workoutsBySuperset);
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);

  const isScienceEntry = data.type === 'S';
  const isVideoEntry = data.type === 'V';
  const isHealthEntry = data.type === 'H';
  const isProgressionEntry = data.type === 'P';
  const isRestEntry = data.type === 'RT';
  const isTagEntry = data.type === 'T';

  const workId = data.assignedId || data.saveDataId;

  const exerId = data.exercise?.id;

  const entriesViewRef = useRef(null);

  const [entriesViewHeight, setEntriesViewHeight] = React.useState(0);

  const { wrvSupersetsEnabled } = settings;

  React.useEffect(() => {
    console.log('Rendering Entry View, index:', index);
    if (entriesViewRef.current) {
      setEntriesViewHeight(entriesViewRef.current.getBoundingClientRect().height);
    }
  }, []);

  const isMainWorkoutEntry = (
    (data.type === 'L')
    || (data.type === 'R')
    || (data.type === 'C')
    || (data.exercise && data.type === 'T')
  );

  const isTagWithoutResult = (data.type === 'T');

  const isNoteEntry = (
    data.type === 'N'
    || data.type === 'W'
    || data.type === 'CD'
  );

  const hasIcons = (isMainWorkoutEntry || isHealthEntry || isProgressionEntry || isNoteEntry);

  const attributes = data;

  const exerName = data.title;

  const handleInfoClick = useCallback((modal) => {
    openModal(namespace, modal);
  }, []);

  const modalOpen = (modal) => {
    openModal(namespace, modal);
    if (modal === 'history') {
      const exerciseId = data.exercise?.id;
      fetchHistory(namespace, accountCode, exerciseId, athleteId);
    }
    if (modal === 'attachment') {
      fetchDocument(namespace, accountCode, data.documents[0].id);
      // If the document is a link, open the link in a new tab
      if (data.documents[0].type === 2) {
        setTimeout(() => {
          window.open(data.documents[0].url, '_blank', 'noreferrer');
        }, 1000);
      }
    }
    if (modal === 'opt-out') {
      fetchExercises(namespace, accountCode);
      fetchSimilarExercises(namespace, accountCode, exerId);
    }
  };

  const scrollQuestionsFunc = (prevQIndex, question) => {
    scrollQuestions(prevQIndex, question);
  };

  const isPortrait = window.innerHeight > window.innerWidth;
  const isLandscape = window.innerHeight < window.innerWidth;

  const numberOfSets = data.sets;
  let maxWidth = '';

  if (activeScreens <= 2 && isPortrait) {
    switch (numberOfSets) {
      case 10:
        maxWidth = '868px';
        break;
      case 9:
        maxWidth = '800px';
        break;
      case 8:
        maxWidth = '732px';
        break;
      case 7:
        maxWidth = '663px';
        break;
      case 6:
        maxWidth = '593px';
        break;
      case 5:
        maxWidth = '523px';
        break;
      case 4:
        maxWidth = '453px';
        break;
      case 3:
        maxWidth = '383px';
        break;
      default:
    }
  }

  if (activeScreens === 4 || (activeScreens === 2 && isLandscape)) {
    switch (numberOfSets) {
      case 10:
        maxWidth = '1800px';
        break;
      case 9:
        maxWidth = '1660px';
        break;
      case 8:
        maxWidth = '1500px';
        break;
      case 7:
        maxWidth = '1365px';
        break;
      case 6:
        maxWidth = '1220px';
        break;
      case 5:
        maxWidth = '1040px';
        break;
      case 4:
        maxWidth = '900px';
        break;
      case 3:
        maxWidth = '760px';
        break;
      default:
    }
  }

  return (
    <div
      className='workout_entry'
      css={WorkoutEntryStyle}
    >
      { ((isMainWorkoutEntry) || (isHealthEntry) || (isTagWithoutResult)) && (
        <CoachNotes
          info={data.additionalInformation}
          isTrackingActive={data.maxTrackingEnabled}
          label={data.type === 'H' ? 'Last' : 'Max'}
          max={data.workingMax === null ? '' : (Number.isInteger(data.workingMax.value)
            ? parseInt(data.workingMax.value, 10)
            : data.workingMax.value)}
          restTime={data.tableData[0]?.restInSeconds}
          tempo={data.tempo}
          optedOut={data.optedOut}
          optedOutReason={data.optedOutReason}
          substitutedExercise={data.substitutedExercise}
        />
      )}
      <div
        className={`
          reps_view
          ${((isMainWorkoutEntry) || (isHealthEntry) || (isTagWithoutResult)) ? 'with_notes' : 'without_notes'}
        `}
      >
        { (hasIcons) && (
          <EntryIcons
            attributes={attributes}
            exerType={data.type}
            isHealthEntry={isHealthEntry}
            isMainWorkoutEntry={isMainWorkoutEntry}
            isNoteEntry={isNoteEntry}
            modalOpen={modalOpen}
          />
        )}

        {/* WORKOUT ENTRIES */}
        {isWorkoutLoading ? (
          <Spinner />
        ) : (
          <EntriesContainer
            className={`
              ${(data.sets >= numberOfSets) && `isScrolling${numberOfSets}`}
            `}
            numberOfSets={numberOfSets}
            maxWidth={maxWidth}
          >
            {(isMainWorkoutEntry) && (
              <WorkoutEntries
                athleteId={athleteId}
                activeDate={activeDate}
                formikRef={formikRef}
                entriesContainerRef={entriesContainerRef}
                entriesViewRef={entriesViewRef}
                entriesViewHeight={entriesViewHeight}
                entryRowRef={entryRowRef}
                handleInfoClick={handleInfoClick}
                index={index}
                isWeightValueEnabled={isWeightValueEnabled}
                namespace={namespace}
                supersetIndex={supersetIndex}
                updateProgramWorkout={updateProgramWorkout}
                updateWorkout={updateWorkout}
                workout={data}
                workoutsBySuperset={workoutsBySuperset}
                workId={workId}
                wrvSupersetsEnabled={wrvSupersetsEnabled}
              />
            )}

            { isNoteEntry && (
              <NoteEntries info={data.text} />
            )}

            { (isScienceEntry && (data.questions)) && (
              <WeightroomQuestionList
                data={data.questions}
                formikRef={formikRef}
                index={index}
                namespace={namespace}
                scienceRef={scienceRef}
                scrollQuestions={scrollQuestionsFunc}
                workout={data}
              />
            )}

            { isVideoEntry && (
              <NoteEntries info={(
                <iframe
                  id='ytplayer'
                  type='text/html'
                  height='150'
                  allowFullScreen='true'
                  src={data.video.mediaUrl}
                  frameBorder='0'
                  title='video'
                />
                )}
              />
            )}

            {isHealthEntry && (
              <HealthEntries
                activeDate={activeDate}
                athleteId={athleteId}
                exerType={data.type}
                formikRef={formikRef}
                handleInfoClick={handleInfoClick}
                index={index}
                namespace={namespace}
                sets={data.sets}
                setList={data.setList}
                updateProgramWorkout={updateProgramWorkout}
                updateWorkout={updateWorkout}
                workout={data}
              />
            )}

            { isProgressionEntry && (
              <EntriesProgression
                activeDate={activeDate}
                athleteId={athleteId}
                formikRef={formikRef}
                info={data.additionalInformation}
                index={index}
                namespace={namespace}
                updateProgramWorkout={updateProgramWorkout}
                updateWorkout={updateWorkout}
                workout={data}
              />
            )}

            { isRestEntry && (
              <CenterContainer>
                <Timer
                  autoplay
                  nextButton={nextButton}
                  seconds={data.timerDurationInSeconds}
                />
              </CenterContainer>
            )}

            { (isTagEntry && !data.exercise) && (
              <WeightroomWorkoutEntriesTag
                formikRef={formikRef}
                index={index}
                namespace={namespace}
                workout={data}
              />
            )}

          </EntriesContainer>
        )}
      </div>

      {isWorkoutActive && (
        <WeightroomModals
          data={data}
          exerName={exerName}
          index={index}
          namespace={namespace}
          workId={workId}
        />
      )}

    </div>
  );
};

WorkoutEntryView.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  fetchDocument: PropTypes.func.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  fetchExercises: PropTypes.func.isRequired,
  fetchSimilarExercises: PropTypes.func.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  isWorkoutActive: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  nextButton: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  scienceRef: PropTypes.instanceOf(Object).isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  supersetIndex: PropTypes.number,
  updateProgramWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
};

WorkoutEntryView.defaultProps = {
  supersetIndex: 0,
};

export default WorkoutEntryView;
