/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */

import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import { fetchSelectedIntegrationByDate, setSelectedIntegration } from '../../../ducks/workoutsActions';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { fetchSelectedIntegrationByDateWRV } from '../../../../weightroom-view-new/ducks/panelActions';

const IntegrationListContainer = styled.div`
  position: relative;
  padding: 30px;
  font-family: Nunito Sans;
`;

const IntegrationItemContainer = styled.div`
  font-family: Nunito Sans;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eaeaea; 
  margin-bottom: 10px;
  justify-content: space-between;
  box-shadow: 0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 4px 8px 0px rgba(48, 49, 51, 0.10);
  padding: 16px 20px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.background[1]};
  &:last-child {
    border-bottom: none; 
  }
  &:hover {
    cursor: pointer;
  }
`;

const IntegrationItemImage = styled.img`
  width: 80px; 
  height: 80px; 
  margin-right: 20px; 

  @media (max-width: 768px) {
    width: 40px; 
    height: 40px; 
  }
`;

const IntegrationDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntegrationTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.text};

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const IntegrationTimestampContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IntegrationTimestamp = styled.div`
font-size: 19px;  
color: ${(props) => props.theme.colors.lightText[0]};
margin-left: 10px;

@media (max-width: 768px) {
  font-size: 11px; 
  margin-left: 5px;
}
`;

const IntegrationListTitle = styled.div`
color: ${(props) => props.theme.colors.text};
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: 130%;
letter-spacing: -0.34px;
margin-top:25px;

@media (max-width: 768px) {
  font-size: 25px;
}
`;

const IntegrationListSubtitle = styled.div`
width: 90%;
color: ${(props) => props.theme.colors.lightText[1]};
font-size: 22px;
line-height: 150%;
margin-top: 5px;
margin-bottom: 12px;

@media (max-width: 768px) {
  font-size: 15px;
}
`;
const IntegrationLogoAndName = styled.div`
  display: flex;
  align-items: center;
`;

const IntegrationIcon = styled(IcomoonReact)`
  height: 20px;
  width: 20px;

  @media (max-width: 768px) {
    height: 12px;
    width: 12px;
  }
`;

const RightArrowIcon = styled(IcomoonReact)`
  height: 32px;
  width: 32px;
  `;

const IntegrationItem = ({
  integration, handleSetActiveComponent, isWeightRoomView, namespace,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentUser = isWeightRoomView ? useSelector((state) => state.weightRoomView.data.currentUser) : useSelector((state) => state.auth.data.currentUser);
  const athleteId = useSelector((state) => state.weightRoomView[namespace]?.athleteId);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const today = moment().format('YYYY-MM-DD');
  const userId = currentUser?.admin ? sharedAthlete?.id : currentUser?.id;
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const activeDate = useSelector((
    state,
  ) => state.weightRoomView[namespace]?.activeDate);
  const handleIntegrationClick = useCallback(() => {
    handleSetActiveComponent();
    dispatch(setSelectedIntegration(integration));
    if (isWeightRoomView) {
      const formattedActiveDate = moment(activeDate).format('YYYY-MM-DD');
      dispatch(fetchSelectedIntegrationByDateWRV(namespace, currentUser, athleteId, formattedActiveDate, integration.integrationName, integration.testType));
    } else {
      const date = activeWorkoutDate || today;
      dispatch(fetchSelectedIntegrationByDate(currentUser, userId, date, integration.integrationName, integration.testType));
    }
  }, [dispatch, handleSetActiveComponent, integration, currentUser, userId, activeWorkoutDate, activeDate]);

  return (
    <>
      <IntegrationItemContainer onClick={handleIntegrationClick}>
        <IntegrationLogoAndName>
          <IntegrationItemImage src={integration.logo} />
          <IntegrationDetails>
            <IntegrationTitle>{integration.testName}</IntegrationTitle>
            <IntegrationTimestampContainer>
              <IntegrationIcon
                iconSet={iconSet}
                icon='restart'
                color={theme.colors.lightText[0]}
              />
              <IntegrationTimestamp>{integration.latestTimestampOfTestForTheDay}</IntegrationTimestamp>
            </IntegrationTimestampContainer>
          </IntegrationDetails>
        </IntegrationLogoAndName>
        <RightArrowIcon
          iconSet={iconSet}
          icon='right-arrow'
          color={theme.colors.lightText[0]}
        />
      </IntegrationItemContainer>
    </>
  );
};

const ImportedIntegrations = ({
  importedDataIntegrations, handleSetActiveComponent, isWeightRoomView = false, namespace = null,
}) => (
  <IntegrationListContainer>
    <IntegrationListTitle>Select an Integration</IntegrationListTitle>
    <IntegrationListSubtitle>Select an integration from below to view any imported data.</IntegrationListSubtitle>
    {importedDataIntegrations.map((integration, index) => (
      <IntegrationItem
        key={index}
        integration={integration}
        handleSetActiveComponent={handleSetActiveComponent}
        isWeightRoomView={isWeightRoomView}
        namespace={namespace}
      />

    ))}
  </IntegrationListContainer>
);

IntegrationItem.propTypes = {
  integration: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    testName: PropTypes.string.isRequired,
    latestTimestampOfTestForTheDay: PropTypes.string.isRequired,
    integrationName: PropTypes.string.isRequired,
    testType: PropTypes.string.isRequired,
  }).isRequired,
  handleSetActiveComponent: PropTypes.func.isRequired,
  isWeightRoomView: PropTypes.bool,
  namespace: PropTypes.string,
};

IntegrationItem.defaultProps = {
  isWeightRoomView: false,
  namespace: null,
};
ImportedIntegrations.propTypes = {
  importedDataIntegrations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSetActiveComponent: PropTypes.func.isRequired,
  isWeightRoomView: PropTypes.bool,
  namespace: PropTypes.string,
};

ImportedIntegrations.defaultProps = {
  isWeightRoomView: false,
  namespace: null,
};

export default ImportedIntegrations;
