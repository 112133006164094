/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { setIsProgramInfoModalShowing } from '../../ducks/panelActions';
import ProgramInfoModal from '../../../../shared/components/ProgramModals/ProgramInfoModal';

const ProgramInfoModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1003;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const WeightroomProgramInfoModal = ({ namespace }) => {
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isProgramInfoModalShowing = useSelector(
    (state) => state.weightRoomView[namespace].isProgramInfoModalShowing,
  );

  const dispatch = useDispatch();

  const infoProgramModalShowing = () => {
    dispatch(setIsProgramInfoModalShowing(namespace, false));
  };

  return (
    <>
      <ProgramInfoModal
        infoProgramModalShowing={infoProgramModalShowing}
        isModalActive={isProgramInfoModalShowing}
        program={currentSelectedProgram}
      />
      <ProgramInfoModalOverlay
        isActive={isProgramInfoModalShowing}
        onClick={
          () => dispatch(setIsProgramInfoModalShowing(namespace, false))
          }
      />
    </>
  );
};

WeightroomProgramInfoModal.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default WeightroomProgramInfoModal;
