import update from 'immutability-helper';

import {
  FETCH_CONVERSATIONS_FULFILLED,
  FETCH_CONVERSATIONS_REJECTED,
  FETCH_CONVERSATION_MESSAGES_FULFILLED,
  FETCH_CONVERSATION_MESSAGES_REJECTED,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_CONVERSATION_USERS_FULFILLED,
  FETCH_CONVERSATION_USERS_REJECTED,
  FETCH_SCHEDULED_MESSAGES_FULFILLED,
  FETCH_SCHEDULED_MESSAGES_REJECTED,
  UNSCHEDULE_MESSAGE_FULFILLED,
  UNSCHEDULE_MESSAGE_REJECTED,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_URL_FULFILLED,
  UPLOAD_MEDIA_URL_REJECTED,
  UPLOAD_MEDIA_URL_START,
  SET_ACTIVE_REDUX_MODAL,
  SET_ACTIVE_CONVERSATION,
  CLEAR_ACTIVE_CONVERSATION,
  CREATE_NEW_MESSAGE_FULFILLED,
  CREATE_NEW_MESSAGE_REJECTED,
  CREATE_NEW_SCHEDULED_MESSAGE,
  SET_INITIAL_MESSAGES,
  SET_IS_INITIAL_MESSAGES,
  SET_IS_MEDIA_UPLOADED,
  SET_SCHEDULED_MESSAGE_REDUX,
  SET_SELECTED_CONVERSATIONS_REDUX,
  SET_INCOMING_ACTIVE_MESSAGE,
  SET_INCOMING_INACTIVE_MESSAGE,
  SET_INCOMING_NEW_INACTIVE_MESSAGE,
  STORE_CONVERSATION_MESSAGES_BY_DAY,
  MARK_CONVERSATIONS_AS_READ_FULFILLED,
  MARK_CONVERSATIONS_AS_READ_REJECTED,
  DELETE_CONVERSATIONS_REJECTED,
  DELETE_CONVERSATIONS_FULFILLED,
  CREATE_CONVERSATION_FULFILLED,
  CREATE_CONVERSATION_REJECTED,
  MUTE_NOTIFICATIONS_FULFILLED,
  MUTE_NOTIFICATIONS_REJECTED,
  SET_MESSAGE_MEDIA_URL,
  FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED,
  SET_IS_TYPING,
  SET_IS_TYPING_TRUE,
  SET_IS_TYPING_FALSE,
  CREATE_SILENT_CONVERSATION_FULFILLED,
  CREATE_SILENT_CONVERSATION_REJECTED,
  SEND_EXTERNAL_MESSAGE_ONLY_FULFILLED,
  CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED,
  CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED,
  SET_SUPPORT_MODAL,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_MORE_MESSAGES_FULFILLED,
  FETCH_MORE_MESSAGES_REJECTED,
  FETCH_UNREAD_NOTIFICATIONS_FULFILLED,
  FETCH_UNREAD_NOTIFICATIONS_REJECTED,
} from './actionTypes';

const initialState = {
  conversations: [],
  activeConversation: {},
  activeConversationPage: 1,
  conversationMessages: [],
  conversationsByDay: [],
  conversationUsers: [],
  groups: [],
  calendars: [],
  users: [],
  usersTyping: [],
  notifications: [],
  uploadMediaError: '',
  currentMessages: [],
  isMediaUploaded: false,
  scheduledMessage: {},
  scheduledMessages: [],
  selectedConvos: {},
  convoLength: 0,
  latestMessage: {},
  conversationMessageNumber: 0,
  reportMessage: 0,
  unreadConversationsNumber: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONVERSATIONS_FULFILLED: {
      if (action.payload.maintainActiveConversation) {
        return update(
          state, {
            conversations: {
              $set: action.payload.data,
            },
          },
        );
      }
      return update(
        state, {
          conversations: {
            $set: action.payload.data,
          },
          activeConversation: {
            $set: {},
          },
          page: {
            $set: action.payload.page,
          },
          isEndOfList: {
            $set: action.payload.data.length < 50,
          },
        },
      );
    }
    case FETCH_CONVERSATIONS_REJECTED: {
      return update(
        state, {
          fetchConversationsError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED: {
      return update(
        state, {
          conversations: {
            $push: action.payload.data,
          },
          page: {
            $set: action.payload.page,
          },
          isEndOfList: {
            $set: action.payload.data.length < 50,
          },
        },
      );
    }
    case FETCH_CONVERSATION_MESSAGES_FULFILLED: {
      return update(
        state, {
          conversationMessages: {
            $set: action.payload,
          },
          // Set isInitialMessages to prevent message animations all at once.
          isInitialMessages: {
            $set: true,
          },
        },
      );
    }
    case FETCH_CONVERSATION_MESSAGES_REJECTED: {
      return update(
        state, {
          conversationMessagesError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_MORE_MESSAGES_FULFILLED: {
      return update(
        state, {
          activeConversationPage: {
            $set: state.activeConversationPage + 1,
          },
          conversationsByDay: {
            $set: action.payload,
          },
          // Set isInitialMessages to prevent message animations all at once.
          isInitialMessages: {
            $set: true,
          },
        },
      );
    }
    case FETCH_MORE_MESSAGES_REJECTED: {
      return update(
        state, {
          conversationMessagesError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_SCHEDULED_MESSAGES_FULFILLED: {
      return update(
        state, {
          scheduledMessages: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_SCHEDULED_MESSAGES_REJECTED: {
      return update(
        state, {
          scheduledMessagesError: {
            $set: action.payload,
          },
        },
      );
    }
    case UNSCHEDULE_MESSAGE_FULFILLED: {
      return update(
        state, {
          scheduledMessages: {
            $splice: [[action.payload.messageIndex, 1]],
          },
        },
      );
    }
    case UNSCHEDULE_MESSAGE_REJECTED: {
      return update(
        state, {
          unscheduledMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_GROUPS_FULFILLED: {
      return update(
        state, {
          groups: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_GROUPS_REJECTED: {
      return update(
        state, {
          fetchGroupsError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CALENDARS_FULFILLED: {
      return update(
        state, {
          calendars: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CALENDARS_REJECTED: {
      return update(
        state, {
          fetchCalendarsError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_USERS_FULFILLED: {
      return update(
        state, {
          users: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_USERS_REJECTED: {
      return update(
        state, {
          fetchUsersError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CONVERSATION_USERS_FULFILLED: {
      return update(
        state, {
          conversationUsers: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CONVERSATION_USERS_REJECTED: {
      return update(
        state, {
          conversationUsers: {
            $set: action.payload,
          },
        },
      );
    }
    case UPLOAD_MEDIA_START: {
      return {
        ...state,
        uploadMediaError: '',
      };
    }
    case UPLOAD_MEDIA_REJECTED: {
      return {
        ...state,
        uploadMediaError: action.payload,
      };
    }
    case UPLOAD_MEDIA_FULFILLED: {
      return {
        ...state,
        uploadMedia: action.payload,
        uploadMediaError: '',
        isMediaUploaded: true,
      };
    }
    case UPLOAD_MEDIA_URL_START: {
      return {
        ...state,
        uploadMediaError: '',
      };
    }
    case UPLOAD_MEDIA_URL_REJECTED: {
      return {
        ...state,
        uploadMediaError: action.payload,
      };
    }
    case UPLOAD_MEDIA_URL_FULFILLED: {
      return {
        ...state,
        uploadMedia: action.payload,
        uploadMediaError: '',
        isMediaUploaded: true,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
        reportMessage: 0,
      };
    }
    case SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversation: action.payload,
        activeConversationPage: 1,
        conversationMessages: [],
        scheduledMessages: [],
      };
    }
    case CLEAR_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversation: {},
        activeConversationPage: 1,
        conversationMessages: [],
        scheduledMessages: [],
      };
    }
    case CREATE_NEW_MESSAGE_FULFILLED: {
      const newConversations = [...state.conversations];
      let targetConversation;
      newConversations.forEach((conversation, idx) => {
        if (conversation.id === action.payload.conversationId) {
          targetConversation = newConversations.splice(idx, 1);
          targetConversation[0] = {
            ...targetConversation[0],
            latestMessage: action.payload,
          };
        }
      });
      newConversations.unshift(targetConversation[0]);
      return update(
        state, {
          conversationsByDay: {
            [action.payload.dayIndex]: {
              1: {
                $unshift: [action.payload],
              },
            },
          },
          activeModal: {
            $set: '',
          },
          isMediaUploaded: {
            $set: false,
          },
          latestMessage: {
            $set: action.payload,
          },
          conversations: {
            $set: newConversations,
          },
          scheduledMessage: {
            $set: {},
          },
        },
      );
    }
    case CREATE_NEW_SCHEDULED_MESSAGE: {
      return update(
        state, {
          scheduledMessages: {
            $unshift: [action.payload],
          },
          scheduledMessage: {
            $set: {},
          },
        },
      );
    }
    case CREATE_NEW_MESSAGE_REJECTED: {
      return update(
        state, {
          createNewMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case SET_INITIAL_MESSAGES: {
      return update(
        state, {
          conversationMessages: {
            $set: action.payload.reverse(),
          },
          isInitialMessages: {
            $set: true,
          },
        },
      );
    }
    case SET_IS_MEDIA_UPLOADED: {
      return {
        ...state,
        isMediaUploaded: action.payload,
        uploadMedia: null,
      };
    }
    case SET_IS_INITIAL_MESSAGES: {
      return {
        ...state,
        isInitialMessages: action.payload,
      };
    }
    case SET_SCHEDULED_MESSAGE_REDUX: {
      return {
        ...state,
        scheduledMessage: action.payload,
        activeModal: '',
      };
    }
    case SET_SELECTED_CONVERSATIONS_REDUX: {
      return {
        ...state,
        selectedConvos: action.payload,
        convoLength: Object.keys(action.payload).length,
      };
    }
    case STORE_CONVERSATION_MESSAGES_BY_DAY: {
      return {
        ...state,
        conversationsByDay: action.payload,
      };
    }
    case SET_INCOMING_ACTIVE_MESSAGE: {
      return update(
        state, {
          conversationsByDay: {
            [action.payload.dayIndex]: {
              1: {
                $unshift: [action.payload],
              },
            },
          },
          conversations: {
            [action.payload.incomingConversationIndex]: {
              latestMessage: {
                $set: action.payload.conversation.latestMessage,
              },
            },
          },
          latestMessage: {
            $set: action.payload.conversation.latestMessage,
          },
        },
      );
    }
    case SET_INCOMING_INACTIVE_MESSAGE: {
      const newConversations = [...state.conversations];
      const targetConversation = state.conversations[action.payload.index];
      const updatedConversation = update(targetConversation, {
        latestMessage: {
          $merge: action.payload,
        },
        unreadMessages: {
          $set: true,
        },
      });
      newConversations.splice(action.payload.index, 1);
      newConversations.unshift(updatedConversation);

      return update(
        state, {
          conversations: {
            $set: newConversations,
          },
          latestMessage: {
            $set: action.payload.conversation.latestMessage,
          },
        },
      );
    }
    case SET_INCOMING_NEW_INACTIVE_MESSAGE: {
      // const newConversations = [...state.conversations];
      // newConversations.unshift(action.payload);

      return update(
        state, {
          conversations: {
            $unshift: [action.payload],
          },
          latestMessage: {
            $set: action.payload.latestMessage,
          },
        },
      );
    }
    case SET_IS_TYPING_TRUE: {
      return update(
        state, {
          usersTyping: {
            $merge: [action.payload],
          },
        },
      );
    }
    case SET_IS_TYPING_FALSE: {
      return update(
        state, {
          usersTyping: {
            $splice: [[action.payload.index, 1]],
          },
        },
      );
    }
    case MARK_CONVERSATIONS_AS_READ_FULFILLED: {
      const { conversations, unreadConversationsNumber } = state;
      const newConvos = [];
      let newUnreadConversationsNumber = unreadConversationsNumber;
      conversations.forEach((convo) => {
        const newConvo = convo;
        if (action.payload.successful.includes(convo.id)) {
          if (newConvo.unreadMessages) {
            newUnreadConversationsNumber -= 1;
          }
          newConvo.unreadMessages = false;
        }
        newConvos.push(newConvo);
      });
      return update(
        state, {
          conversations: {
            $set: newConvos,
          },
          unreadConversationsNumber: {
            $set: newUnreadConversationsNumber,
          },
        },
      );
    }
    case MARK_CONVERSATIONS_AS_READ_REJECTED: {
      return update(
        state, {
          createNewMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case DELETE_CONVERSATIONS_FULFILLED: {
      const { conversations } = state;
      const newConvos = [];
      conversations.forEach((convo) => {
        const newConvo = convo;
        if (!action.payload.successful.includes(convo.id)) {
          newConvos.push(newConvo);
        }
      });
      if (action.payload.successful.includes(parseInt(state.activeConversation.id, 10))) {
        console.log('it does');
      }
      return update(
        state, {
          conversations: {
            $set: newConvos,
          },
          activeConversation: {
            $set: {},
          },
          activeConversationPage: {
            $set: 1,
          },
        },
      );
    }
    case DELETE_CONVERSATIONS_REJECTED: {
      return update(
        state, {
          createNewMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case CREATE_CONVERSATION_FULFILLED: {
      if (action.payload.latestMessage) {
        return update(
          state, {
            activeConversation: {
              $set: action.payload,
            },
            activeConversationPage: {
              $set: 1,
            },
          },
        );
      }
      const newConversations = [...state.conversations];
      const conversationsIds = newConversations.map((conversation) => conversation.id);
      if (!conversationsIds.includes(action.payload.id)) {
        newConversations.unshift(action.payload);
      }
      return update(
        state, {
          conversations: {
            $set: newConversations,
          },
          newConversation: {
            $set: action.payload,
          },
          activeConversation: {
            $set: action.payload,
          },
          activeConversationPage: {
            $set: 1,
          },
        },
      );
    }
    case CREATE_CONVERSATION_REJECTED: {
      return update(
        state, {
          createNewMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case CREATE_SILENT_CONVERSATION_FULFILLED: {
      return update(
        state, {
          newConversation: {
            $set: action.payload,
          },
        },
      );
    }
    case CREATE_SILENT_CONVERSATION_REJECTED: {
      return update(
        state, {
          createNewMessageError: {
            $set: action.payload,
          },
        },
      );
    }
    case MUTE_NOTIFICATIONS_FULFILLED: {
      return update(
        state, {
          activeConversation: {
            $set: action.payload,
          },
        },
      );
    }
    case MUTE_NOTIFICATIONS_REJECTED: {
      return update(
        state, {
          muteNotificationsError: {
            $set: action.payload,
          },
        },
      );
    }
    case SET_MESSAGE_MEDIA_URL: {
      return {
        ...state,
        messageMediaUrl: action.payload,
      };
    }
    case CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED: {
      return update(
        state, {
          conversationMessageNumber: {
            $set: action.payload,
          },
        },
      );
    }
    case CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED: {
      return update(
        state, {
          conversationMessageNumber: {
            $set: action.payload,
          },
        },
      );
    }
    case SET_SUPPORT_MODAL: {
      return update(
        state, {
          activeModal: {
            $set: 'support-modal',
          },
          reportMessage: {
            $set: action.payload,
          },
        },
      );
    }
    case SUBMIT_SUPPORT_FULFILLED: {
      return update(
        state, {
          activeModal: {
            $set: '',
          },
          reportMessage: {
            $set: 0,
          },
        },
      );
    }
    case SUBMIT_SUPPORT_REJECTED: {
      return update(
        state, {
          submitSupportError: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_UNREAD_NOTIFICATIONS_FULFILLED: {
      return update(
        state, {
          unreadConversationsNumber: {
            $set: action.payload.conversationsUnread,
          },
        },
      );
    }
    case FETCH_UNREAD_NOTIFICATIONS_REJECTED: {
      return update(
        state, {
          unreadConversationsNumber: {
            $set: 0,
          },
        },
      );
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
