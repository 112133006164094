/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  fetchNextWorkoutSession,
  fetchProgramWeeks,
  fetchProgramWeek,
  resetProgram,
  setIsResetProgramModalShowing,
} from '../../ducks/panelActions';
import ResetProgramModal from '../../../../shared/components/ProgramModals/ResetProgramModal';

const ResetProgramModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1003;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const WeightroomResetProgramModal = ({ namespace }) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isResetProgramModalShowing = useSelector(
    (state) => state.weightRoomView[namespace].isResetProgramModalShowing,
  );
  const selectedProgramWeek = useSelector(
    (state) => state.weightRoomView[namespace].selectedProgramWeek,
  );

  const dispatch = useDispatch();

  const resetProgramModalShowing = () => {
    dispatch(setIsResetProgramModalShowing(namespace, false));
  };

  const resetProgramClick = () => {
    batch(() => {
      dispatch(resetProgram(accountCode, athleteId, namespace, currentSelectedProgram));
      setTimeout(() => {
        dispatch(fetchNextWorkoutSession(
          accountCode,
          athleteId,
          namespace,
          currentSelectedProgram.dayBasedProgramId,
        ));
        dispatch(fetchProgramWeeks(
          accountCode,
          athleteId,
          namespace,
          currentSelectedProgram,
          currentSelectedProgram.programIdx,
        ));
        if (Object.keys(selectedProgramWeek).length > 0) {
          dispatch(fetchProgramWeek(
            accountCode,
            athleteId,
            namespace,
            currentSelectedProgram,
            selectedProgramWeek.zeroBasedWeekNum,
            selectedProgramWeek,
          ));
        }
      }, 500);
    });
  };

  return (
    <>
      <ResetProgramModal
        isModalActive={isResetProgramModalShowing}
        resetProgramModalShowing={resetProgramModalShowing}
        resetProgramClick={resetProgramClick}
      />
      <ResetProgramModalOverlay
        isActive={isResetProgramModalShowing}
        onClick={
          () => dispatch(setIsResetProgramModalShowing(namespace, false))
          }
      />
    </>
  );
};

WeightroomResetProgramModal.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default WeightroomResetProgramModal;
