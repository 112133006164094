import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDocuments,
  fetchFile,
  fetchFolder,
  fetchDocumentsWithSearch,
  fetchDocumentsWithSearchAndFolder,
  createDocument,
  uploadDocument,
  deleteDocuments,
  updateDocument,
  fetchUserGroups,
  fetchFolders,
  fetchDocument,
} from '../../ducks/documentsActions';

const useDocuments = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  const handleFetchDocuments = (values) => {
    dispatch(fetchDocuments(values));
  };

  const handleFetchDocument = (values) => {
    dispatch(fetchDocument(values));
  };

  const handleFetchFile = (values) => {
    dispatch(fetchFile(values));
  };

  const handleFetchFolder = (values) => {
    dispatch(fetchFolder(values));
  };

  const handleSearch = (values) => {
    dispatch(fetchDocumentsWithSearch(values));
  };

  const handleFolderSearch = (values) => {
    dispatch(fetchDocumentsWithSearchAndFolder(values));
  };

  const handleCreateDocument = (accountCode, values) => {
    dispatch(createDocument(accountCode, values));
  };

  const handleUploadDocument = (accountCode, file) => {
    dispatch(uploadDocument(accountCode, file));
  };

  const handleDeleteDocuments = (accountCode, docIdsArray) => {
    dispatch(deleteDocuments(accountCode, docIdsArray));
  };

  const handleUpdateDocument = (accountCode, docId, values, indexOb) => {
    dispatch(updateDocument(accountCode, docId, values, indexOb));
  };

  const handleFetchUserGroups = (accountCode) => {
    dispatch(fetchUserGroups(accountCode));
  };

  const handleFetchFolders = (accountCode) => {
    dispatch(fetchFolders(accountCode));
  };

  return {
    handleFetchDocuments,
    handleFetchDocument,
    handleFetchFile,
    handleFetchFolder,
    handleSearch,
    handleCreateDocument,
    handleUploadDocument,
    handleDeleteDocuments,
    handleUpdateDocument,
    handleFetchUserGroups,
    handleFetchFolders,
    handleFolderSearch,
    currentUser,
  };
};

export default useDocuments;
