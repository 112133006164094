/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Dropzone from 'react-dropzone';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import SubText from '../../../../../shared/components/SubText/SubText';
import TBButton from '../../../../../shared/components/Button/Button';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import {
  setIsWorkoutJournalMediaUploaded,
  setCaptureVideoModal,
  setCaptureImageModal,
  uploadWorkoutJournalMedia,
} from '../../../ducks/workoutsActions';

const MediaDropdownContainer = styled('div')`
  display: flex;
  justify-content: center;
  .remove-button {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 30px;
    z-index: 100;
    top: -5px;
    background: #d6d6d6;
  }
`;
const EmptyDropZoneWrapper = styled('div')`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  height: 75px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 75px;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 50%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;
const SpinnerWrapper = styled('div')`
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100%;
`;
const DropDownMenuWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  &.giphy-dropdown {
    position: absolute;
    top: 0px;
    right: 20px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
  }  
  &.emoji-dropdown {
    position: absolute;
    top: 0px;
    right: 55px;
    height: 100%;
    z-index: 100;
    cursor: pointer;
  }  
  .dropdown-container {
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(234, 234, 234, 0.5);
    border: 0px;
    &.giphy {
      padding: 20px;
      z-index: 100;
    }
    &.emoji {
      padding: 0px;
      z-index: 100;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    transition: none;
    span {
      color: #444444;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
      font-weight: 600;
      text-transform: none;
      letter-spacing: 0px;
      /* color: #444444; */
      font-size: 16px;
    }
    :hover {
      /* transition: linear 0s; */
      background: #0dcc8a;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 10px 0px;
    box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 15%);
  }
  .dropdown-divider {
    margin: 0px;
  }
  .add-gif-button {
    height: 32px;
    width: 32px;
    color: #444444;
    background-color: transparent;
    font-weight: 800;
    letter-spacing: -.5px;
    &:hover {
      opacity: .7;
      background-color: transparent;
    }
  }
  &.notification-dropdown {
    position: absolute;
    right: 15px;
  }
`;
const DropZoneWrapper = styled('div')`
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }
  .update-button {
    width: 180px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;
    &:hover {
      opacity: .7;
    }
  }
`;
const AttachmentButton = () => {
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isMediaUploading = useSelector((state) => state.workouts.ui.isMediaUploading);
  const isWorkoutJournalMediaUploading = useSelector(
    (state) => state.workouts.ui.isWorkoutJournalMediaUploading,
  );

  const ActionButton = styled('div')`
    display: flex;
    height: ${(props) => (!props.small ? '56px' : '40px')};
    width: ${(props) => (!props.small ? '56px' : '40px')};
    background-color: rgb(240,240,240);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background-color: #bfbfbfa1;
    }
  `;

  const AddMediaToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ActionButton
      href=''
      icon='plus'
      small
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
    >
      {children}
      {!isWorkoutJournalMediaUploading ? (
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='attachment'
          color='grey'
        />
      ) : (
        <div style={{
          height: '100%',
          width: '100%',
        }}
        >
          <Spinner
            saving
            darkTheme
          />
        </div>
      )}
    </ActionButton>
  ));

  const dispatch = useDispatch();
  const handleDroppedFile = (acceptedFile) => {
    // console.log(acceptedFile);
    const type = acceptedFile[0].type.split('/').shift();
    dispatch(uploadWorkoutJournalMedia(
      { accountCode: currentUser.accountCode, acceptedFile, type },
    ));
    dispatch(setIsWorkoutJournalMediaUploaded(false));
  };

  const fileAccepterSwitch = () => (
    <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
      {({ getRootProps, getInputProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DropZoneWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <Dropdown.Item>
            <>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='attachment'
              />
              <SubText
                className='drop-icon-text'
                fontSize={14}
              >
                Add Saved Media
              </SubText>
            </>
          </Dropdown.Item>
        </DropZoneWrapper>
      )}
    </Dropzone>
  );
  const addMediaDropdown = () => (
    <DropDownMenuWrapper>
      <Dropdown
        bsPrefix='trick-fix'
      >
        <Dropdown.Toggle as={AddMediaToggle} />
        <Dropdown.Menu className='dropdown-container emoji'>
          <Dropdown.Item
            className='dropdown-item'
            onClick={() => dispatch(setCaptureImageModal(true))}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='camera'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Add Live Photo
            </SubText>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => dispatch(setCaptureVideoModal(true))}>
            <>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='video'
              />
              <SubText
                className='drop-icon-text'
                fontSize={14}
              >
                Add Live Video
              </SubText>
            </>
          </Dropdown.Item>
          {fileAccepterSwitch(dispatch, handleDroppedFile, isMediaUploaded)}
        </Dropdown.Menu>
      </Dropdown>
    </DropDownMenuWrapper>
  );
  return (
    <MediaDropdownContainer>
      {isMediaUploaded ? (
        isMediaUploading ? (
          <EmptyDropZoneWrapper>
            <SpinnerWrapper>
              <Spinner
                saving
                darkTheme
              />
            </SpinnerWrapper>
          </EmptyDropZoneWrapper>
        ) : (
          <>
            <TBButton
              icon='remove'
              iconOnly
              square
              onClick={() => dispatch(setIsMediaUploaded(false))}
              className='remove-button'
            />
            {/* <DropZoneDiv /> */}
          </>
        )
      ) : (
        addMediaDropdown()
      )}
    </MediaDropdownContainer>
  );
};
AttachmentButton.propTypes = {

};
export default AttachmentButton;
