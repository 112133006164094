// modal for confirming deletion of selected documents

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import Checkmark from '../../../../../shared/components/Checkmark/Checkmark';
import SubText from '../../../../../shared/components/SubText/SubText';
import NoHoverButton from './NoHoverButton';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import useReseller from '../../hooks/useReseller';
import useResellerSubscribers from '../../hooks/useResellerSubscribers';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button2 {
    display: flex;
    align-items: center;
    background-color: #0dcc8a;
  }
`;

const PrimaryWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .history-header {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .options-header {
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .card-info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    svg {
      color: #444444;
    }
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .alt-form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .first-stage-select {
    width: 100%;
  }
`;

const FirstStageDatePickerInnerWrapper = styled('div')`
    display: flex;
    width: 50%;
    max-width: 190px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  `;

const SecondStageWrapper = styled('div')`
  margin-top: 10px;
  margin-bottom: 70px;
  display: flex;
  width: 85%;
  height: 38px;
  align-items: center;
  justify-content: center;
`;

const CheckMarkWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 38px;
  margin-top: 10px;
  margin-bottom: 70px;
`;

const SecondStageInnerWrapper1 = styled('div')`
  display: flex;
  width: 50%;
  min-width: 220px;
  align-items: center;
  .toggle-text {
    text-align: center;
    display: flex;
    justify-content: center;
    width: 60px;
    margin-left: 10px;
  }
`;

const ModalConfirmCancel = ({
  currentSubscriber,
  onRequestClose,
  currentSubscription,
  setActiveModal,
  currentChargeItem,
  setCurrentSubscriber,
}) => {
  const {
    currentUser,
    isRefundChargeLoading,
    handleRefundCharge,
  } = useReseller();

  const {
    subscriber,
  } = useResellerSubscribers();

  const [partialRefund, setPartialRefund] = useState(false);
  const [isRefundSubmitted, setIsRefundSubmitted] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState(null);

  const handleSubmit = (
    values,
  ) => {
    setIsRefundSubmitted(true);
    handleRefundCharge({
      accountCode: currentUser.accountCode,
      subscriberId: currentSubscriber.id,
      chargeId: currentChargeItem.id,
      body: { amount: parseInt(parseFloat(values.partial * 100).toFixed(0), 10) },
    });
  };

  useEffect(() => {
    if (subscriber) {
      const subscriberPic = optimizeImage(
        subscriber.userPic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(subscriberPic);
    }
  }, [subscriber]);

  const loadingFunction = () => {
    if (isRefundChargeLoading) {
      return <Spinner />;
    }
    return (
      <CheckMarkWrapper>
        <Checkmark />
      </CheckMarkWrapper>
    );
  };

  const chargedDate = (chargeItem) => {
    const newMoment = moment(chargeItem.nextCharge);
    return newMoment.format('MMMM D, YYYY');
  };

  const UserDetailBox = styled('div')`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    .userpic {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
      margin-right: 10px;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      .manage-button {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;

  return (
    <Formik
      initialValues={{
        partial: !currentChargeItem.amountRefunded
          ? parseFloat(currentChargeItem.amount / 100).toFixed(2)
          : parseFloat((
            currentChargeItem.amount - currentChargeItem.amountRefunded
          ) / 100).toFixed(2),
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleSubmit(values);
          setSubmitting(false);
        });
      }}
      render={({
        values,
        setFieldValue,
        submitForm,
      }) => (
        <ModalWrapper>
          <NavigationWrapper>
            <Text
              className='arrow-div'
              onClick={() => {
                setActiveModal('backwards_all_charges_modal');
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='left-arrow'
              />
            </Text>
            <Text
              className='close arrow-div'
              onClick={() => {
                onRequestClose();
                setCurrentSubscriber(null);
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={13}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <PrimaryWrapper>
            <FolderTextWrapper>
              <Text className='calendar-icon-text'>Confirm Refund</Text>
            </FolderTextWrapper>
            <div className='divider-line' />
            <UserDetailBox>
              <div className='userpic' />
              <div className='text-container'>
                <div>{`${subscriber.firstName} ${subscriber.lastName}`}</div>
                <div>{subscriber.emailAddress}</div>
                <div onClick={() => window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/manage_users?userId=${currentSubscriber.id}`, '_blank')} className='manage-button'>Manage User</div>
              </div>
            </UserDetailBox>
            <SubText
              className='history-header'
              fontSize={16}
            >
              Charge Information
            </SubText>
            <div className='card-info-box'>
              <div className='card-price'>
                Amount:
                {' '}
                {currentChargeItem.placeSymbolAfterCurrency
                  ? `${(currentChargeItem.amount / 100).toFixed(2)}${currentChargeItem.currencySymbol}`
                  : `${currentChargeItem.currencySymbol}${(currentChargeItem.amount / 100).toFixed(2)}`}
              </div>
              {currentChargeItem.amountRefunded ? (
                <div className='card-number'>
                  Amount Refunded:
                  {' '}
                  {currentChargeItem.placeSymbolAfterCurrency
                    ? `${(currentChargeItem.amountRefunded / 100).toFixed(2)}${currentChargeItem.currencySymbol}`
                    : `${currentChargeItem.currencySymbol}${(currentChargeItem.amountRefunded / 100).toFixed(2)}`}
                </div>
              ) : 'Amount Refunded: $0'}
              {currentChargeItem.amountRefunded ? (
                <div className='card-number'>
                  Amount Remaining:
                  {' '}
                  {currentChargeItem.placeSymbolAfterCurrency
                    ? `${((currentChargeItem.amount - currentChargeItem.amountRefunded) / 100).toFixed(2)}${currentChargeItem.currencySymbol}`
                    : `${currentChargeItem.currencySymbol}${((currentChargeItem.amount - currentChargeItem.amountRefunded) / 100).toFixed(2)}`}
                </div>
              ) : null}
              <div className='card-number'>
                Date of charge:
                {' '}
                {chargedDate(currentChargeItem)}
              </div>
            </div>
            <SubText
              className='options-header'
              fontSize={16}
            >
              Refund Options
            </SubText>
            {!isRefundSubmitted ? (
              <SecondStageWrapper>
                <SecondStageInnerWrapper1>
                  <SubText fontSize={11} className='toggle-text'>Full</SubText>
                  <Toggle
                    icons={false}
                    checked={partialRefund}
                    onChange={() => {
                      setPartialRefund(!partialRefund);
                    }}
                  />
                  <SubText fontSize={11} className='toggle-text'>Partial</SubText>
                </SecondStageInnerWrapper1>
                <FirstStageDatePickerInnerWrapper>
                  <FormGroup>
                    <Field
                      className='form-control'
                      disabled={!partialRefund}
                      id='partial-field'
                      name='partial'
                      type='number'
                      onBlur={() => {
                        const int = values.partial;
                        const twoPlacedFloat = parseFloat(int).toFixed(2);
                        setFieldValue('partial', twoPlacedFloat);
                      }}
                    />
                  </FormGroup>
                </FirstStageDatePickerInnerWrapper>
              </SecondStageWrapper>
            ) : loadingFunction()}
          </PrimaryWrapper>
          <NoHoverButton
            bottom
            fullWidth
            cta={(!isRefundChargeLoading && !isRefundSubmitted) ? 'Submit' : 'Close'}
            className='modal-button2'
            customColor='#FF8C00'
            large
            noBorder
            primary
            square
            onClick={() => {
              (!isRefundChargeLoading && !isRefundSubmitted) ? submitForm() : setActiveModal('backwards_all_charges_modal');
            }}
          />
        </ModalWrapper>
      )}
    />
  );
};

ModalConfirmCancel.propTypes = {

};

export default ModalConfirmCancel;
