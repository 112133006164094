import React from 'react';
import styled from '@emotion/styled';
import Spinner from '../Spinner/Spinner';

const AlertBannerContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: #c0392b;
  ${props => props.isClickable ? 'cursor: pointer;' : ''}
  p {
    color: white;
  }
`;

const AlertBanner = ({ 
  children,
  isClickable,
  handleClick,
  isLoading,
}) => {
  return (
    <AlertBannerContainer isClickable={isClickable} onClick={handleClick ? handleClick : null}>
      {isLoading ? (
        <Spinner saving />
      ) : (
        children
      )}
    </AlertBannerContainer>
  );
}
 
export default AlertBanner;