/* eslint-disable react/prop-types */
import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import AnimatedEntryView from './AnimatedEntryView';
import { openModal } from '../../ducks/panelActions';

import Workout from './Workout';

const WorkoutWrapper = styled('div')`
  display: block;

  &:first-of-type {
    .workout-container {
      &:before {
        display: none; 
      }
    }
  }
  &.BR {
    & .exercise_icon {
      &:before {
        display: none;
      }
    }
  }

`;

const WorkoutContainer = ({
  entriesContainerRef,
  entriesViewHeight,
  entryRowRef,
  formikRef,
  handleActiveWorkout,
  index,
  namespace,
  nextButton,
  questionListRef,
  scrollQuestions,
  supersetIndex,
  workoutWrapperRef,
  workoutInnerRef,
  workoutRef,
}) => {
  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);
  const activeWorkoutIndex = useSelector((
    state,
  ) => state.weightRoomView[namespace].activeWorkoutIndex);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const totalFormikValues = useSelector((
    state,
  ) => state.weightRoomView[namespace].totalFormikValues);
  const workout = useSelector((state) => state.weightRoomView[namespace].workouts[index]);

  const dispatch = useDispatch();

  const isWorkoutActive = (activeWorkoutIndex === index);
  const exerId = workout.assignedId || workout.saveDataId;

  const isComplete = workout.fullyCompleted
    || (currentNonDateDay.status === 'Completed' || currentNonDateDay.status === 'Skipped');

  const handleOpenModal = useCallback((modal) => {
    dispatch(openModal(namespace, modal));
  }, [namespace, exerId]);

  return (
    <WorkoutWrapper
      ref={workoutWrapperRef}
    >
      <Workout
        activeScreens={activeScreens}
        complete={workout && (isComplete)}
        icon={workout.icon}
        id={workout.assignedId || workout.saveDataId}
        index={index}
        isActive={isWorkoutActive}
        exerId={exerId}
        exerType={workout.type}
        grouping={workout.groupingLetter}
        handleActiveWorkout={handleActiveWorkout}
        hasInfo={workout.additionalInfo !== ''}
        hasDescription={workout.exercise?.description !== ('' || null)}
        isOptedOut={workout && (workout.optedOut === true)}
        key={workout.assignedId || workout.saveDataId}
        name={workout.title}
        namespace={namespace}
        handleOpenModal={handleOpenModal}
        ref={workoutRef}
        subHeader={workout.subTitle}
        scienceLength={workout.type === 'S' ? workout.questions?.length : 0}
        totalFormikValues={totalFormikValues}
        isTotalScienceEntries={workout.questionnaireTotalEnabled === true}
        type={workout.type}
        workoutInner={workoutInnerRef}
      />
      <AnimatedEntryView
        entriesContainerRef={entriesContainerRef}
        entryRowRef={entryRowRef}
        entriesViewHeight={entriesViewHeight}
        formikRef={formikRef}
        index={index}
        isWorkoutActive={isWorkoutActive}
        namespace={namespace}
        nextButton={nextButton}
        questionListRef={questionListRef}
        scrollQuestions={scrollQuestions}
        supersetIndex={supersetIndex}
      />
    </WorkoutWrapper>
  );
};

WorkoutContainer.propTypes = {
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleActiveWorkout: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  nextButton: PropTypes.func.isRequired,
  questionListRef: PropTypes.instanceOf(Object).isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  supersetIndex: PropTypes.number,
  workoutInnerRef: PropTypes.instanceOf(Object).isRequired,
  workoutWrapperRef: PropTypes.instanceOf(Object).isRequired,
  workoutRef: PropTypes.instanceOf(Object).isRequired,
};

WorkoutContainer.defaultProps = {
  supersetIndex: 0,
};

export default memo(WorkoutContainer);
