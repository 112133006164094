import React, { Fragment } from 'react';
import { useSpring, animated } from 'react-spring';
import { Formik } from 'formik';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, injectStripe } from 'react-stripe-elements';
import * as Yup from 'yup';
import Logger from 'js-logger';

import { submitSubscriptionPayment } from '../../ducks/programsActions';

import FormCheckout from './FormCheckout';

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  margin-left: -54px;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
  }
`;

const CheckoutContainer = styled('div')`
  position: relative;
  
  p {
    margin-bottom: 0px;
  }
  .info-card {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;

    .info-details-container {
      display: flex;
      align-items: center;
    }

    .coach-name {
      margin-bottom: 5px;
    }

  }
`;


const CheckoutSchema = Yup.object().shape({
  // frequency: Yup.object().shape({
  //   label: Yup.string().required('Frequency Required'),
  // }),
  name: Yup.string()
    .required('Full Name Required'),
  agreementData: Yup.mixed().oneOf([true], 'Field must be checked'),
  // agreementData: Yup.boolean()
  //   // .required('Must Accept Terms Before Subscribing.')
  //   .oneOf([true], 'Must Accept Terms Before Subscribing.'),
});

const ModalCheckout = ({
  externalCheckout,
}) => {
  const formAnimation = useSpring({
    // opacity: isLoginLoading || isAuthenticated ? 0 : 1,
    zIndex: 1,
    position: 'relative',
  });

  const programData = useSelector(state => state.programs.data.programData);
  const currentUser = useSelector(state => state.auth.data.currentUser);
  const dispatch = useDispatch();
  
  const {
    programId,
  } = programData;

  const {
    accountCode,
  } = currentUser;

  // const handleGoogleResponse = (response) => {
  //   const values = {
  //     grant_type: 'google',
  //     client_id: process.env.CLIENT_ID,
  //     client_secret: process.env.CLIENT_SECRET,
  //     tokenid: response.tokenId,
  //     scope: 'offline_access',
  //   };

  //   handleGoogleAuth(encode(values));
  // };

  return (
    <Formik
      initialValues={{
        resellerProgramId: programId,
        name: '',
        stripeToken: '',
        agreementData: false,
        // client_id: process.env.CLIENT_ID,
        // client_secret: process.env.CLIENT_SECRET,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          // handleRegistration(encode(values));
          // alert(JSON.stringify(encode(values), null, 2));
          Logger.debug(values);
          dispatch(submitSubscriptionPayment(accountCode, values));
          setSubmitting(false);
        }, 400);
      }}
      validationSchema={CheckoutSchema}
      render={
        formProps => (
          <CheckoutContainer>
            <Global
              styles={css`
                .ReactModal__Content {
                  width: 420px!important;
                }
              `}
            />
            <Fragment>
              <animated.div style={formAnimation}>
                <Elements>
                  <FormCheckout
                    formProps={formProps}
                    externalCheckout={externalCheckout}
                  />
                </Elements>
              </animated.div>
              {/* <animated.div style={spinnerAnimation}>
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              </animated.div>
              <animated.div style={completeAnimation}>
                <SpinnerContainer>
                  <Checkmark />
                </SpinnerContainer>
              </animated.div> */}
            </Fragment>
          </CheckoutContainer>
        )
      }
    />
  );
};

export default ModalCheckout;
