import { connect } from 'react-redux';
import WorkoutModals from '../presentational/WorkoutModals';
import {
  closeModal,
  fetchHistory,
  fetchDocument,
  openModal,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteId: state[ownProps.namespace].athleteId,
  activeModal: state[ownProps.namespace].activeModal,
  exerId: state[ownProps.namespace].activeExerId,
  index: state[ownProps.namespace].activeWorkoutIndex,
  isLoadingExercises: state[ownProps.namespace].isLoadingExercises,
  isModalOpen: state[ownProps.namespace].isModalOpen,
});

const mapDispatchToProps = dispatch => ({
  fetchHistory: (namespace, exerId, userId) => {
    dispatch(fetchHistory(namespace, exerId, userId));
  },
  fetchDocument: (namespace, docId) => {
    dispatch(fetchDocument(namespace, docId));
  },
  closeModal: (namespace) => {
    dispatch(closeModal(namespace));
  },
  openModal: (namespace, modal) => {
    dispatch(openModal(namespace, modal));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkoutModals);
