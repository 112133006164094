import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import { setTimerModalOpen } from '../../ducks/workoutsActions';

const ContentContainer = styled('div')`
	display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    :hover {
      overflow-y: scroll;
    }
  }
`;

const TimerDiv = styled('div')`
  font-family: 'Nunito Sans';
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
`;

const TimerValue = styled('div')`
  font-size: 40px;
`;

const ButtonContainer = styled('div')`
	display: flex;
  width: 80%;
`;

const Button = styled('button')`
	display: flex;
  width: 80%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
`;

/**
 * @returns a fun little modal that contains a timer that counts down
 * the time associated with a given rest period
 */
const RestTimer = ({ time }) => {
  const dispatch = useDispatch();

  const [isPlaying, setIsPlaying] = useState(false);
  const [localTimerDuration, setLocalTimerDuration] = useState(0);
  const [key, setKey] = useState(0);

  /**
   * When the component loads, the component checks if the reduxTimerDuration
   * exists, and if so, sets a local piece of state to that value
   */
  useEffect(() => {
    if (time) {
      setLocalTimerDuration(time);
    }
  }, [localTimerDuration]);

  // function that continuously updates the timer
  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <TimerDiv>Done!</TimerDiv>;
    }
    return (
      <TimerDiv>
        <TimerValue>{remainingTime}</TimerValue>
      </TimerDiv>
    );
  };

  /**
 * function called when the user pushes the reset button
 * in order to reset the timer, we need to give the timer a new
 * key - this is a quirk associated with the timer package and
 * I made it that way through following the documentation.
 * It actually doesn't work without resetting the key, so
 * there you go
 */
  const handleReset = () => {
    setKey((prevKey) => prevKey + 1);
    setLocalTimerDuration(time);
    setIsPlaying(true);
  };

  return (
    <>
      <ContentContainer>
        <CountdownCircleTimer
          isPlaying={isPlaying}
          key={key}
          size={120}
          duration={localTimerDuration}
          strokeWidth={6}
          colors={['#004777', '#F7B801', '#A30000', '#A30000']}
          colorsTime={[10, 6, 3, 0]}
          onComplete={() => {
            console.log('done');
          }}
        >
          {renderTime}
        </CountdownCircleTimer>
      </ContentContainer>
      <ButtonContainer>
        <Button onClick={handleReset}>
          <IcomoonReact
            iconSet={iconSet}
            size={35}
            icon='restart'
            color='black'
          />
        </Button>
        <Button onClick={() => {
          if (isPlaying) {
            setIsPlaying(false);
          } else {
            setIsPlaying(true);
          }
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={35}
            icon={isPlaying ? 'pause' : 'play'}
            color='black'
          />
        </Button>
      </ButtonContainer>
    </>
  );
};

RestTimer.propTypes = {
  time: PropTypes.number.isRequired,
};

export default RestTimer;
