// modal for adding a cancelled subscriber to a new program

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Form, Field } from 'formik';
import Select from 'react-select';
import FormHandler from '../../../../../shared/components/FormHandler/FormHandler';
import Text from '../../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import useReseller from '../../hooks/useReseller';
import useResellerSubscribers from '../../hooks/useResellerSubscribers';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const PrimaryWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .history-header {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .submit-new-program-button {
    background: #10cd8c;
    color: white;
    margin-bottom: 20px;
    margin-top: 15px;
    align-self: center;
    width: 150px;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    svg {
      color: #444444;
    }
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const SubmitProgramButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
`;

const FormGroup = styled('div')`
  width: 80%;
  label {
    width: 100%;
  }
  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }
  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const CheckboxWrapper = styled('div')`
  padding-top: 12px;
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  flex-direction: column;
`;

const SubmitError = styled('div')`
  margin: 0px 10px 10px 10px;
`;

const ModalAddToNewProgram = ({
  currentSubscriber,
  onRequestClose,
  setActiveModal,
  setCurrentSubscriber,
}) => {
  const {
    currentUser,
    handleFetchAllCharges,
  } = useReseller();

  const {
    subscriber,
    handleAddSubscriberToProgram,
    handleClearAddSubscriberToProgramError,
  } = useResellerSubscribers();

  const theme = useTheme();
  const [userProfilePic, setUserProfilePic] = useState(null);
  let programs = useSelector((state) => state.reseller.data.programs);
  if (programs && programs.length > 0) {
    programs = programs.filter((program) => program.status === 1);
  }
  const isAddingSubscriberToProgram = useSelector((state) => state.reseller.ui.isAddingSubscriberToProgram);
  const addSubscriberToProgramError = useSelector((state) => state.reseller.data.addSubscriberToProgramError);
  const isAllChargesLoading = useSelector((state) => state.reseller.ui.isAllChargesLoading);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [addNewProgramClicked, setAddNewProgramClicked] = useState(false);

  useEffect(() => {
    if (currentSubscriber && currentUser && !isAllChargesLoading) {
      handleFetchAllCharges({
        accountCode: currentUser.accountCode,
        subscriberId: currentSubscriber.id,
      });
      handleClearAddSubscriberToProgramError();
    }
  }, [subscriber]);

  useEffect(() => {
    if (subscriber) {
      const subscriberPic = optimizeImage(
        subscriber.userPic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(subscriberPic);
    }
  }, [subscriber]);

  const addToNewProgramSchema = Yup.object().shape({
    disableGroupAndCalendars: Yup.boolean(),
    programId: Yup.string()
      .required('Required'),
  });

  const UserDetailBox = styled('div')`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    .userpic {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
      margin-right: 10px;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      .manage-button {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;

  return (
    <ModalWrapper>
      <NavigationWrapper>
        <Text
          className='arrow-div'
          onClick={() => {
            setActiveModal('backwards_subscriber_detail_modal');
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='left-arrow'
          />
        </Text>
        <Text
          className='close arrow-div'
          onClick={() => {
            onRequestClose();
            setCurrentSubscriber(null);
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <PrimaryWrapper>
        <FolderTextWrapper>
          <Text className='calendar-icon-text'>Add to New Program</Text>
        </FolderTextWrapper>
        <div className='divider-line' />
        <UserDetailBox>
          <div className='userpic' />
          <div className='text-container'>
            <div>{`${subscriber.firstName} ${subscriber.lastName}`}</div>
            <div>{subscriber.emailAddress}</div>
            <div onClick={() => window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/manage_users?userId=${currentSubscriber.id}`, '_blank')} className='manage-button'>Manage User</div>
          </div>
        </UserDetailBox>
        <FormHandler
          initialValues={
                    {
                      disableGroupAndCalendars: false,
                      programName: '',
                    }
                  }
          validationSchema={addToNewProgramSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleAddSubscriberToProgram(
                currentUser.accountCode, currentSubscriber.id, values.programId, values.disableGroupAndCalendars,
              );
              setSubmitting(false);
            });
          }}
        >
          {(formProps) => (
            <Form className='formik-form'>
              <FormWrapper>
                <FormGroup>
                  <TitlePlusErrorWrapper>
                    <Text className='formgroup-text'><strong>Program</strong></Text>
                    {formProps.errors.programId && addNewProgramClicked ? (
                      <FieldError className='error-text'>{formProps.errors.programType}</FieldError>
                    ) : null}
                  </TitlePlusErrorWrapper>
                  <Select
                    className='program-select'
                    getOptionLabel={(option) => option.programName}
                    getOptionValue={(option) => option.id}
                    options={programs}
                    isOptionSelected={
                            (option) => formProps.values.option === option
                          }
                    isClearable
                    isSearchable
                    onChange={(options) => {
                      if (options) {
                        formProps.setFieldTouched('programId', true);
                        formProps.setFieldValue('programId', options.id);
                        setSelectedProgram(options);
                      } else {
                        formProps.setFieldValue('programId', null);
                        formProps.setFieldTouched('programId', true);
                        setSelectedProgram(null);
                      }
                    }}
                  />
                </FormGroup>
                <FormGroup className='d-flex'>
                  <CheckboxWrapper>
                    <label
                      className='checkbox-label'
                    >
                      <Field
                        className='checkbox'
                        id='disable-calendars-field'
                        name='disable-calendars'
                        type='checkbox'
                        onChange={() => {
                          formProps.setFieldValue('disableGroupAndCalendars', !formProps.values.disableGroupAndCalendars);
                        }}
                        checked={formProps.values.disableGroupAndCalendars}
                      />
                      <Text className='url-string' fontSize={14}>
                        Keep existing group and calendar assignments for user
                      </Text>

                    </label>
                  </CheckboxWrapper>
                </FormGroup>
                <SubmitProgramButton>
                  <NoHoverButton
                    onClick={() => {
                      formProps.setSubmitting(true);
                      setAddNewProgramClicked(true);
                      formProps.submitForm();
                    }}
                    cta={isAddingSubscriberToProgram ? 'Submitting...' : 'Submit'}
                    fullWidth
                    className='submit-new-program-button'
                    customColor={theme.colors.green}
                    noBorder
                    disabled={!selectedProgram || isAddingSubscriberToProgram}
                    bottom
                    type='button'
                  />
                </SubmitProgramButton>
                {addSubscriberToProgramError && (
                <SubmitError>
                  <FieldError>{addSubscriberToProgramError}</FieldError>
                </SubmitError>
                )}
              </FormWrapper>
            </Form>
          )}
        </FormHandler>
      </PrimaryWrapper>

    </ModalWrapper>
  );
};

ModalAddToNewProgram.propTypes = {

};

export default ModalAddToNewProgram;
