import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import styled from '@emotion/styled';

import Conditional from './Conditional';
import WorkoutFields from './WorkoutFields';

const HealthWrapper = styled('div')`
  margin-top: -8px;
  padding: 0px 3px;
`;

const HealthEntries = ({
  athleteId,
  exerType,
  formikRef,
  index,
  namespace,
  sets,
  setList,
  workout,
  updateWorkout,
}) => {
  // Cardio Render Conditions

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      initialValues={
        {
          work_id: workout.work_id,
          user_id: athleteId,
          result:
            workout.result ? (
              workout.result.data.map((set, i) => (
                {
                  set_id: i + 1,
                  value:
                    set.value,
                }
              ))
            ) : (
              Array(workout.sets).fill().map((set, i) => ({
                set_id: i + 1,
              }))
            ),
        }
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        // alert(JSON.stringify(values, null, 2))
        actions.setSubmitting(false);
      }}
      render={props => (
        <HealthWrapper className='health_wrapper'>
          <Form>
            <FieldArray
              name='result'
              render={() => (
                <Fragment>
                  <FieldArray
                    name='value'
                    render={() => (
                      <Fragment>
                        <WorkoutFields
                          exerType={exerType}
                          handleChange={props.handleChange}
                          handleSubmit={props.handleSubmit}
                          id='0'
                          labels={false}
                          name='value'
                          isHealth
                          sets={sets}
                          setList={setList}
                          title={workout.exer_name}
                        />
                      </Fragment>
                    )}
                  />
                  {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
                </Fragment>
              )}
            />
          </Form>
        </HealthWrapper>
      )}
    />
  );
};

HealthEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  exerType: PropTypes.string.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  sets: PropTypes.number.isRequired,
  setList: PropTypes.instanceOf(Array).isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default HealthEntries;
