import React from 'react';
import { useDispatch } from 'react-redux';

import ModalNote from '../presentational/ModalNote';
import {
  closeModal,
  submitJournal,
  updateJournal,
} from '../../ducks/panelActions';

const WeightroomModalNote = (ownProps) => {
  const dispatch = useDispatch();

  const clseModal = (namespace) => {
    dispatch(closeModal(namespace));
  };
  const sbmitJournal = (index, accountCode, namespace, values) => {
    dispatch(submitJournal(index, accountCode, namespace, values));
  };
  const updteJournal = (index, accountCode, namespace, values, entryId) => {
    dispatch(updateJournal(index, accountCode, namespace, values, entryId));
  };

  return (
    <ModalNote
      exerName={ownProps.exerName}
      index={ownProps.index}
      namespace={ownProps.namespace}
      workout={ownProps.workout}
      closeModal={clseModal}
      submitJournal={sbmitJournal}
      updateJournal={updteJournal}
    />
  );
};

export default WeightroomModalNote;
