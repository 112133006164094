import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import styled from '@emotion/styled';
import Logger from 'js-logger';

import Select from 'react-select';
import { FormGroup } from '../../../../shared/components/FormHandler/FormHandler';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';
import Link from '../../../../shared/components/Link/Link';
import Button from '../../../../shared/components/Button/Button';

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 15px 0px 0px;
  min-width: 50%;
  max-width: 50%;
  position: relative;

  .remove-link {
    position: absolute;
    right: 0;
    margin-right: 16px
  }

  @media screen and (max-width: 540px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const LinkContainer = styled('div')`
  width: 100%;
  margin-top: 20px;
  
  @media screen and (max-width: 540px) {
    button {
      margin-left: 0px!important;
    }
  }
`;

const MaxModalFormSlideOne = ({
  formProps,
  exercises,
}) => (
  <>
    <FormGroup>
      <FormLabel>Select Exercises</FormLabel>
      <FieldArray
        name='exerciseIds'
        render={arrayHelpers => (
          <>
            <FormColumns>
              {formProps.values.exerciseIds.length > 0 && (
                formProps.values.exerciseIds.map((exercise, index) => (
                  <ColumnContent>
                    <FormLabel>Exercise #{index + 1}</FormLabel>
                    {index !== 0 && (
                      <Link
                        className='remove-link'
                        onClick={(e) => {
                          e.preventDefault();
                          arrayHelpers.remove(index);
                        }}
                        type='button'
                      >
                        &#10005;
                      </Link>
                    )}
                    <Select
                      // isDisabled={isLoadingSelections}
                      classNamePrefix='react-select'
                      defaultValue={formProps.values.exerciseIds[index]}
                      options={exercises}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      name='exercise'
                      onChange={(option) => {
                        formProps.setFieldValue(`exerciseIds.${index}`, option);
                        // handleFetchSelections(accountCode, option.value);
                        Logger.debug('Exercises Array', formProps.values.exerciseIds);
                      }}
                      isOptionSelected={option => formProps.values.exerciseIds === option.id}
                      value={formProps.values.exerciseIds[index]}
                    />
                  </ColumnContent>
                ))
              )}
            </FormColumns>
            {formProps.values.exerciseIds.length <= 9 && (
              <LinkContainer>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    arrayHelpers.push('');
                  }}
                  cta='Add Exercise'
                  icon='plus'
                />
              </LinkContainer>
            )}

          </>
        )}
      />
    </FormGroup>
  </>
);

MaxModalFormSlideOne.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  exercises: PropTypes.instanceOf(Array),
};

MaxModalFormSlideOne.defaultProps = {
  exercises: [],
};

export default MaxModalFormSlideOne;
