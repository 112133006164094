// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import { StripeProvider, Elements } from 'react-stripe-elements';
import StripeFields from './StripeFields';
import Text from '../../../../../shared/components/Text/Text';
import SubText from '../../../../../shared/components/SubText/SubText';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import NoHoverButton from './NoHoverButton';
import useReseller from '../../hooks/useReseller';
import useResellerSubscribers from '../../hooks/useResellerSubscribers';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';
import useScript from '../../../../../shared/hooks/useScript';

function importAll(r) {
  const images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const images = importAll(require.context('../../../../../shared/images/payments/light', false, /\.(png|jpe?g|svg)$/));

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
    background-color: #0dcc8a;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ErrorText = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-size: 14px;
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const ErrorTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;

  .error-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
    color: #ff6600;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .history-header {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .payment-header {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .charge-history-button {
    background: #10cd8c;
    color: white;
    margin-bottom: 20px;
  }
  .add-to-new-program-button {
    background: #10cd8c;
    color: white;
    margin-bottom: 15px;
  }
  .submit-new-program-button {
    background: #10cd8c;
    color: white;
    margin-bottom: 20px;
    margin-top: 15px;
    align-self: center;
    width: 180px;
  }
`;

const SubscriptionCard = styled('div')`
  width: 375px;
  height: 94px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    .program-name {
      width: 200px;
    }
  }
  .cancel-button {
    background: #ff0024;
    color: white;
  }
`;

const SubscriptionCardCancelled = styled('div')`
  opacity: 0.5;
  width: 375px;
  height: 94px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    .program-name {
      width: 200px;
    }
  }
  .update-button {
    background: #ff0024;
    color: white;
  }
`;

const SubscriptionHistoryWrapper = styled('div')`
  max-height: 329px;
  overflow-y: auto;
  padding: 5px;
`;

const AddSubscriberToProgram = styled('div')`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubscriberDetailModal = (
  {
    onRequestClose,
    currentSubscriber,
    setActiveModal,
    setCurrentSubscription,
    setCurrentSubscriber,
  },
) => {
  const {
    currentUser,
  } = useReseller();

  const {
    subscriber,
    isSubscriberLoading,
    handleFetchSubscriber,
    handleClearAddSubscriberToProgramError,
  } = useResellerSubscribers();

  const [userProfilePic, setUserProfilePic] = useState(null);
  const fetchSubscriberError = useSelector((state) => state.reseller.data.fetchSubscriberError);
  console.log(fetchSubscriberError);
  useScript('https://js.stripe.com/v3/');

  useEffect(() => {
    if (currentSubscriber && currentUser && !isSubscriberLoading) {
      handleFetchSubscriber({
        accountCode: currentUser.accountCode,
        subscriberId: currentSubscriber.id,
      });
    }
    handleClearAddSubscriberToProgramError();
  }, [currentSubscriber]);

  useEffect(() => {
    if (subscriber) {
      const subscriberPic = optimizeImage(
        subscriber.userPic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(subscriberPic);
    }
  }, [subscriber]);

  const UserDetailBox = styled('div')`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    .userpic {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
      margin-right: 10px;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      .manage-button {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;

  const subscriptionCardGenerator = (subscriptionArray) => {
    const newArray = [];
    subscriptionArray.forEach((subscriptionItem) => {
      let nextChargeDate = 'N/A';
      if (subscriptionItem.nextCharge) {
        const newMoment = moment(subscriptionItem.nextCharge);
        nextChargeDate = newMoment.format('MMMM D, YYYY');
      }
      let price;
      if (subscriptionItem.placeSymbolAfterCurrency) {
        price = `${parseFloat(subscriptionItem.price / 100).toFixed(2)}${subscriptionItem.currencySymbol}`;
      } else {
        price = `${subscriptionItem.currencySymbol}${parseFloat(subscriptionItem.price / 100).toFixed(2)}`;
      }
      if (subscriptionItem.status === 'ACTIVE') {
        newArray.push(
          <SubscriptionCard>
            <div className='card-info-box'>
              <SubText
                fontSize={12}
                className='program-name'
              >
                {subscriptionItem.programName}
              </SubText>
              <div className='card-price'>
                {price}
                /
                {subscriptionItem.billingFrequencyDescription}
              </div>
              <div className='card-number'>
                Next Charge:
                {' '}
                {nextChargeDate}
              </div>
            </div>
            <NoHoverButton
              className='cancel-button'
              cta='Cancel'
              customColor='#10cd8c'
              noHover
              noBorder
              onClick={() => {
                setCurrentSubscription(subscriptionItem);
                setActiveModal('confirm_cancel_modal');
              }}
            />
          </SubscriptionCard>,
        );
      } else if (subscriptionItem.status === 'CANCELLED') {
        const newMoment = moment(subscriptionItem.updatedAt);
        const cancelDate = newMoment.format('MMMM D, YYYY');
        newArray.push(
          <SubscriptionCardCancelled>
            <div className='card-info-box'>
              <SubText
                className='program-name'
                fontSize={12}
              >
                {subscriptionItem.programName}
              </SubText>
              <div className='card-number'>
                Cancelled on
                {' '}
                {cancelDate}
              </div>
            </div>
            <SubText
              fontSize={12}
            >
              Cancelled
            </SubText>
          </SubscriptionCardCancelled>,
        );
      }
    });
    return newArray;
  };

  if (isSubscriberLoading) {
    return <Spinner />;
  }
  if (subscriber) {
    return (
      <ModalWrapper>
        <NavigationWrapper>
          <Text
            className='close arrow-div'
            onClick={() => {
              onRequestClose();
              setCurrentSubscriber(null);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='remove'
            />
          </Text>
        </NavigationWrapper>
        <AnotherWrapper>
          <OuterFolderFormWrapper>
            <FolderTextWrapper>
              <Text className='calendar-icon-text'>Subscriber Detail</Text>
            </FolderTextWrapper>
            <div className='divider-line' />
            <UserDetailBox>
              <div className='userpic' />
              <div className='text-container'>
                <div>{`${subscriber.firstName} ${subscriber.lastName}`}</div>
                <div>{subscriber.emailAddress}</div>
                {currentSubscriber ? <div onClick={() => window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/manage_users?userId=${currentSubscriber.id}`, '_blank')} className='manage-button'>Manage User</div> : null}
              </div>
            </UserDetailBox>
            <SubText
              className='payment-header'
              fontSize={16}
            >
              Payment Information
            </SubText>
            <StripeProvider apiKey={currentUser.accountCode === 1 ? process.env.STRIPE_PUBLIC_KEY_TEST : process.env.STRIPE_PUBLIC_KEY}>
              <Elements>
                <StripeFields
                  accountCode={currentUser && currentUser.accountCode}
                  subscriberId={currentSubscriber && currentSubscriber.id}
                  images={images}
                  subscriber={subscriber}
                />
              </Elements>
            </StripeProvider>
            {currentSubscriber && currentSubscriber.subscriptionStatus === 3 && (
            <AddSubscriberToProgram>
              <NoHoverButton
                className='add-to-new-program-button'
                cta='Add to New Program'
                customColor='#10cd8c'
                noHover
                noBorder
                onClick={() => setActiveModal('add_cancelled_subscriber_to_program_modal')}
              />
            </AddSubscriberToProgram>
            )}
            <SubText
              className='history-header'
              fontSize={16}
            >
              Subscription History
            </SubText>
            <NoHoverButton
              className='charge-history-button'
              cta='View All Charges'
              customColor='#10cd8c'
              noHover
              noBorder
              onClick={() => setActiveModal('all_charges_modal')}
            />
            <SubscriptionHistoryWrapper>
              {subscriptionCardGenerator(subscriber.subscriptions)}
            </SubscriptionHistoryWrapper>
          </OuterFolderFormWrapper>
        </AnotherWrapper>
      </ModalWrapper>
    );
  } if (fetchSubscriberError) {
    return (
      <ModalWrapper>
        <NavigationWrapper>
          <Text
            className='close arrow-div'
            onClick={() => {
              onRequestClose();
              setCurrentSubscriber(null);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='remove'
            />
          </Text>
        </NavigationWrapper>
        <AnotherWrapper>
          <OuterFolderFormWrapper>
            <ErrorTextWrapper>
              <Text className='error-text'>Error Fetching Subscriber</Text>
              <ErrorText>An error occurred fetching the subscriber information. If the issue persists, please contact support.</ErrorText>
            </ErrorTextWrapper>
          </OuterFolderFormWrapper>
        </AnotherWrapper>
      </ModalWrapper>
    );
  }
  return null;
};

SubscriberDetailModal.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default SubscriberDetailModal;
