import React, { Fragment, useState } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import { animated, useSpring } from 'react-spring';
import { useDispatch } from 'react-redux';
import Logger from 'js-logger';
import ResizeObserver from 'react-resize-observer';
import { setModalOpen } from '../../ducks/programsActions';

import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import SubText from '../../../../shared/components/SubText/SubText';
import Text from '../../../../shared/components/Text/Text';
import Link from '../../../../shared/components/Link/Link';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import Button from '../../../../shared/components/Button/Button';
import NoHoverButton from '../../../reseller/components/presentational/programs/NoHoverButton';
import { Nunito } from '../../../../shared/GlobalStyles';

const DetailsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 60px 0px;

  h1, span, p {
    ${(props) => (props.isLoadingProgram ? 'color: transparent' : props.theme.textColor)}
  }
  button {
    ${(props) => (props.isLoadingProgram ? 'border-size: 0px;' : 'border-size: 1px;')}
    color: white;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    > div {
      margin-bottom: 30px;
    }

    button {
      width: 100%;
      color: white;
    }
    padding-bottom: 0px;
  }
`;

const NameContainer = styled('div')`
  flex: 4;
  h1 {
    text-transform: capitalize;
  }
`;

const AvatarContainer = styled('div')`
  background: ${(props) => props.accountColorPrimary};
  border-radius: 50%;
  padding: 2px;
`;

const CoachDetails = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 25px;
  span {
    text-transform: capitalize;
    letter-spacing: 1px!important;
    &.trial {
      text-transform: uppercase;
    }
  }
`;

const CoachName = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const TrialCopyContainer = styled('div')`
  margin-top: 30px;
`;

const ProgramEndContainer = styled('div')`
  margin-top: ${(props) => props.marginTop};;
`;

const DescriptionContainer = styled('div')`
  flex: 6;
  overflow: hidden;
  button {
    margin-top: 15px;
  }

  .read-more {
    margin-top: 15px;
  }

  p {
    white-space: pre-wrap;
  }
`;

const DescriptionWrapper = styled('div')`
  height: auto;
`;

const PriceContainer = styled('div')`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LargePrice = styled('span')`
  font-size: 40px;
  font-family: ${Nunito};
  font-weight: 900;
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
`;

const SmallPrice = styled('span')`
  font-size: 12px;
  font-family: ${Nunito};
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
  margin-bottom: 30px;
`;

const CurrencySymbol = styled('span')`
  font-size: 22px;
  vertical-align: top;
  line-height: 45px;
`;

const NoHoverButtonSmallDisplay = styled('div')`
  @media screen and (max-width : 768px) {
    position: fixed;
    width: 100%;
    bottom: 0;
    text-align: center;
    z-index: 999;
    display: block;
  }
  @media screen and (min-width : 769px) {
    display: none;
  }
`;

const NoHoverButtonLargeDisplay = styled('div')`
  @media screen and (max-width : 768px) {
    display: none;
  }
`;

const ProgramDetails = ({
  accountColorPrimary,
  accountLogoKey,
  billingFrequency,
  billingFrequencyDescription,
  currencySymbol,
  isLoadingProgram,
  isRegisteredInClient,
  isAuthenticated,
  isAuthenticatedv3,
  numBillingCycles,
  programDescriptionLong,
  programName,
  programPrice,
  resellerName,
  status,
  trialPeriod,
  placeSymbolAfterCurrency,
}) => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [textDimensions, setTextDimensions] = useState();
  const dispatch = useDispatch();

  const readMoreTransition = useSpring({ height: isTextExpanded ? textDimensions.height : 145, paddingBottom: 30, overflow: 'hidden' });
  const isDescriptionTooSmall = textDimensions && textDimensions.height <= 145;

  const openCheckoutModal = () => {
    dispatch(setModalOpen(true, (isAuthenticatedv3 || isRegisteredInClient || isAuthenticated) ? 'checkout' : 'createAccount'));
  };

  const billingCycleMessage = () => {
    if (billingFrequency === 12) {
      return numBillingCycles > 1 ? `${numBillingCycles} years` : `${numBillingCycles} year`;
    }
    return numBillingCycles > 1 || billingFrequency > 1 ? `${billingFrequency * numBillingCycles} months` : `${billingFrequency * numBillingCycles} month`;
  };

  const programDescription = 'A paragraph description that describes what this workout entails. More info about this program here.';

  const renderLargeScreenBuyNowButton = () => (
    <NoHoverButtonLargeDisplay>
      <NoHoverButton
        cta='Buy Now'
        complete
        disabled={!status}
        icon={!status ? 'lock' : null}
        iconMarginTop={!status ? '-2px' : null}
        rounded
        large
        onClick={openCheckoutModal}
      />
    </NoHoverButtonLargeDisplay>
  );

  const renderSmallScreenBuyNowButton = () => (
    <NoHoverButtonSmallDisplay>
      <NoHoverButton
        cta={`Buy Now ${placeSymbolAfterCurrency ? `${programPrice / 100}${currencySymbol} /${billingFrequencyDescription}` : `${currencySymbol}${programPrice / 100} /${billingFrequencyDescription}`}`}
        complete
        disabled={!status}
        icon={!status ? 'lock' : null}
        iconMarginTop={!status ? '-2px' : null}
        large
        primary
        square
        onClick={openCheckoutModal}
      />
    </NoHoverButtonSmallDisplay>
  );

  return (
    <DetailsContainer
      isLoadingProgram={isLoadingProgram}
    >
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1' />
      </Helmet>
      {isLoadingProgram ? (
        <>
          <NameContainer>
            <Shimmer>
              <HeaderText>Workout Name</HeaderText>
            </Shimmer>
            <CoachDetails>
              <CoachName>
                <Shimmer>
                  <SubText>Coach Bella</SubText>
                </Shimmer>
                <Shimmer>
                  <SubText
                    fontWeight={400}
                    fontSize={13}
                  >
                    Bella University
                  </SubText>
                </Shimmer>
              </CoachName>
            </CoachDetails>
          </NameContainer>
          <DescriptionContainer>
            <Shimmer>
              <SubText>About</SubText>
            </Shimmer>
            <Shimmer>
              <Text>{programDescription}</Text>
            </Shimmer>
          </DescriptionContainer>
          <PriceContainer>
            <Shimmer>
              <LargePrice>$20</LargePrice>
              <SmallPrice>/Month</SmallPrice>
              <Button
                cta='Buy Now'
                complete
                rounded
                large
                onClick={openCheckoutModal}
              />
            </Shimmer>
          </PriceContainer>
        </>
      ) : (
        <>
          <NameContainer>
            <HeaderText>{programName}</HeaderText>
            <CoachDetails>
              <AvatarContainer accountColorPrimary={accountColorPrimary}>
                <Avatar
                  avatar={accountLogoKey}
                  backgroundColor={accountColorPrimary}
                  size={40}
                />
              </AvatarContainer>
              <CoachName>
                <SubText>Created By</SubText>
                <SubText
                  fontWeight={400}
                  fontSize={13}
                >
                  {resellerName}
                </SubText>
              </CoachName>
            </CoachDetails>

            {/* If we have a trial period greater than 0, show trial copy. */}
            {trialPeriod > 0 && (
              <TrialCopyContainer>
                <SubText className='trial'>{`Includes ${trialPeriod} day trial`}</SubText>
              </TrialCopyContainer>
            )}
            {/* If the program has a max # of billing cycles, show it here. */}
            {numBillingCycles && numBillingCycles > 0 && (
            <ProgramEndContainer marginTop={trialPeriod > 0 ? '15px' : '30px'}>
              <SubText className='trial'>{`Subscription ends after ${billingCycleMessage()}`}</SubText>
            </ProgramEndContainer>
            )}
          </NameContainer>
          <DescriptionContainer>
            <animated.div style={readMoreTransition}>
              <DescriptionWrapper>
                <SubText>About</SubText>
                <Text>
                  {programDescriptionLong}
                </Text>
                <ResizeObserver
                  onResize={(rect) => {
                    setTextDimensions(rect);
                  }}
                />
              </DescriptionWrapper>
            </animated.div>
            {!isDescriptionTooSmall && (
              <Link
                className='read-more'
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  setIsTextExpanded(!isTextExpanded && true);
                }}
                fontSize={14}
              >
                Read More
              </Link>
            )}

          </DescriptionContainer>
          {placeSymbolAfterCurrency
            ? (
              <PriceContainer>
                <LargePrice>
                  {programPrice / 100}
                  <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                </LargePrice>
                <SmallPrice>{`/${billingFrequencyDescription}`}</SmallPrice>
                {renderLargeScreenBuyNowButton()}
                {renderSmallScreenBuyNowButton()}
              </PriceContainer>
            )
            : (
              <PriceContainer>
                <LargePrice>
                  <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                  {programPrice / 100}
                </LargePrice>
                <SmallPrice>{`/${billingFrequencyDescription}`}</SmallPrice>
                {renderLargeScreenBuyNowButton()}
                {renderSmallScreenBuyNowButton()}
              </PriceContainer>
            )}
        </>
      )}
    </DetailsContainer>
  );
};

ProgramDetails.propTypes = {
  accountLogoKey: PropTypes.string,
  billingFrequencyDescription: PropTypes.string,
  isLoadingProgram: PropTypes.string,
  programDescriptionLong: PropTypes.string,
  programName: PropTypes.string,
  programPrice: PropTypes.number,
  resellerName: PropTypes.string,
};

ProgramDetails.defaultProps = {
  accountLogoKey: '',
  billingFrequencyDescription: '',
  isLoadingProgram: '',
  programDescriptionLong: '',
  programName: '',
  programPrice: 0,
  resellerName: '',
};

export default ProgramDetails;
