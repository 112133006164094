// authored by Ozan Sozuoz
/* eslint-disable jsx-a11y/anchor-is-valid */
import IcomoonReact from 'icomoon-react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { onboardingTracker } from '../../utils/amplitudeHelper';
import iconSet from '../../images/teambuildr-selection.json';

const Buttons = styled('div')`
  position: absolute;
  display: flex;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: ${BoxShadow};
  bottom: -10px;
  @media screen and (max-width: 786px) {
    position: relative;
    flex-direction: column;
    background: white;
    bottom: 0;
    padding: 15px;
  }
  @media (max-height: 700px) {
    padding: 10px;
    flex-direction: column;
    bottom: 0;
  }
`;
const BoxShadow = '0px 4px 10px rgba(0, 0, 0, 0.1)';
const ContentArea = styled('div')`
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
`;
const ContentContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  margin-bottom: 85px;
  box-shadow: ${BoxShadow};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  scroll-behavior: smooth;
  @media screen and (max-width: 786px) {
    height: 580px;
  }
  @media screen and (max-width: 540px) {
    height: 500px;
    margin-bottom: 0px;
    height: 500px;
  }
  @media (max-height: 700px) {
    margin-bottom: 70px;
    height: 430px;
  }
`;
const ContentDescription = styled('p')`
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
`;
const ContentTitle = styled('h2')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  background: ${(props) => getGradient(props.gradientType)};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: color 0.3s ease;
  margin-top: 20px;
  margin-bottom: 16px;
  @media screen and (min-width: 540px) {
    font-size: 32px;
    line-height: 100%;
  }
`;
const getGradient = (gradientType) => {
  switch (gradientType) {
    case 'primary':
      return 'linear-gradient(to right, #667EEA, #764BA2)';
    case 'secondary':
      return 'linear-gradient(270deg, #6A82FB 60%, #FC5C7D 100%)';
    default:
      return 'linear-gradient(270deg, #6A82FB 60%, #FC5C7D 100%)';
  }
};
const LowerBoxBadge = styled('div')`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 0px 0px 8px 0px;
  background: #FF8533;
  position: absolute;
  top: 0;
  left: 0;
  color: #FFF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: 1.28px;
`;
const ModalButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 10px 20px;
  margin-bottom: 5px;
  color: ${(props) => (props.isPrimary ? 'white' : '#FF8533')};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  background: ${(props) => (props.isPrimary
    ? 'linear-gradient(90deg, rgba(247,183,51,1) 0%, rgba(252,74,26,1) 100%)'
    : 'linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(247, 183, 51, 1), rgba(252, 74, 26, 1)) border-box')};
  border-radius: 10000px;
  border: ${(props) => (props.isPrimary ? 'none' : '3px solid transparent')};
  flex: 1;
  max-width: ${(props) => (props.fullWidth ? '100%' : '49%')};
  transition: all ${transitionSpeed}s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media screen and (max-width: 786px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    padding: 12px 20px;
    height: auto;
    min-height: 44px;
    white-space: normal;
    word-wrap: break-word;
  }
  @media (max-height: 800px) {
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  }
  &:last-child {
    @media screen and (max-width: 786px) {
      margin-bottom: 0;
    }
  }
  &:hover {
    opacity: 0.7;
  }
`;
const ModalStyles = (maxHeight, minWidth, maxWidth, minHeight) => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '12px',
    maxWidth: maxWidth || '800px',
    width: '50vw',
    minWidth: minWidth || 'auto',
    maxHeight,
    minHeight,
    overflow: 'hidden',
    background: 'none',
    transition: 'opacity 300ms ease-in-out, transform 300ms ease-in-out',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    transition: 'opacity 300ms ease-in-out',
  },
});
const SliderButton = styled('button')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  ${(props) => (props.direction === 'left' ? 'left: 10px;' : 'right: 10px;')}
`;

const SliderContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
`;
const SliderIndicator = styled('div')`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#FF8533' : '#E0E0E0')};
  cursor: pointer;
`;
const SliderIndicators = styled('div')`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;
const SlideImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`;

const transitionSpeed = 0.5;

const UpperBox = styled('div')`
  height: 339px;
  width: 100%;
  background: black;
  position: relative;
  @media screen and (max-width: 540px) {
    height: 50%;
  }
`;

const UpperLowerContainer = styled('div')`
  overflow-y: auto;
`;

/**
 * OnboardingModal Component
 *
 * @param {Boolean} badgeText Text function for the badge (default is 'GET STARTED')
 * @param {String} content Array of content to display in the modal slides
 * @param {String} dashName Name of the dashboard to help with Amplitude tracking
 * @param {String} maxHeight Maximum height for the modal
 * @param {String} maxWidth Maximum width for the modal
 * @param {Function} onClose Function to close the modal
 * @param {Function} onPrimaryAction Function for the primary action button
 * @param {Function} onSecondaryAction Function for the secondary action button
 * @param {Boolean} isActive Whether the modal is active or not
 * @param {String} minHeight Minimum height for the modal
 * @param {String} minWidth Minimum width for the modal
 * @param {Boolean} showSingleButton Whether to show a single button or two buttons
 * @param {String} primaryButtonText Text for the primary action button (default 'START CREATING HABITS')
 * @param {String} secondaryButtonText Text for the secondary action button (default 'CONTINUE WITHOUT CREATING HABITS')
 */
const OnboardingModal = ({
  badgeText,
  content,
  dashName,
  isActive,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  primaryButtonText,
  secondaryButtonText,
  showSingleButton,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  useEffect(() => {
    if (isActive) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 300);
    } else {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 300);
    }
  }, [isActive]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % content.length);
    // Amplitude tracking
    onboardingTracker('Next Arrow Clicked', dashName, 'User clicked the next-slide arrow to navigate within modal', currentSlide);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + content.length) % content.length);
    // Amplitude tracking
    onboardingTracker('Previous Arrow Clicked', dashName, 'User clicked the previous-slide arrow to navigate within modal', currentSlide);
  };

  const handleCloseModal = () => {
    setIsAnimating(true);
    setTimeout(() => {
      onClose();
      // Amplitude tracking
      onboardingTracker('Modal Closed', dashName, 'User closed onboarding modal to begin using dashboard', currentSlide);
    }, 300);
  };

  const customStyles = ModalStyles(maxHeight, minWidth, maxWidth, minHeight);

  return (
    <Modal
      isOpen={isActive}
      onRequestClose={handleCloseModal}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          opacity: isAnimating ? 0 : 1,
          transform: `translate(-50%, ${isAnimating ? (isActive ? '100%' : '-50%') : '-50%'})`,
        },
        overlay: {
          ...customStyles.overlay,
          opacity: isAnimating ? 0 : 1,
        },
      }}
      contentLabel='Onboarding Modal'
    >
      <ContentContainer fullWidth>
        <UpperLowerContainer>
          <UpperBox>
            <SliderContainer>
              <SlideImage src={content[currentSlide].image} alt={`Slide ${currentSlide + 1}`} />
              <SliderButton direction='left' onClick={prevSlide}>
                <IcomoonReact iconSet={iconSet} size={20} icon='altrightarrow' color='#333' style={{ transform: 'rotate(180deg)' }} />
              </SliderButton>
              <SliderButton direction='right' onClick={nextSlide}>
                <IcomoonReact iconSet={iconSet} size={20} icon='altrightarrow' color='#333' />
              </SliderButton>
            </SliderContainer>
          </UpperBox>
          <ContentArea>
            <LowerBoxBadge>
              {badgeText}
            </LowerBoxBadge>
            <ContentTitle gradientType={content[currentSlide].gradientType}>
              {content[currentSlide].title}
            </ContentTitle>
            <ContentDescription>{content[currentSlide].description}</ContentDescription>
            <SliderIndicators>
              {content.map((_, index) => (
                <SliderIndicator
                  key={index}
                  active={index === currentSlide}
                  onClick={() => {
                    setCurrentSlide(index);
                    // Amplitude tracking
                    onboardingTracker('Slide Indicator Clicked', dashName, 'User clicked an indicator to navigate slides within modal', currentSlide);
                  }}
                />
              ))}
            </SliderIndicators>
          </ContentArea>
        </UpperLowerContainer>
        <Buttons>
          {showSingleButton ? (
            <ModalButton
              isPrimary
              fullWidth
              onClick={() => {
                onPrimaryAction();
                // Amplitude tracking
                onboardingTracker('Modal Closed', dashName, 'User closed onboarding modal to begin using dashboard', currentSlide);
              }}
            >
              {primaryButtonText}
            </ModalButton>
          ) : (
            <>
              <ModalButton
                isPrimary
                fullWidth
                onClick={() => {
                  onPrimaryAction();
                  // Amplitude tracking
                  onboardingTracker('Modal Closed', dashName, 'User closed onboarding modal to begin using dashboard', currentSlide);
                }}
              >
                {primaryButtonText}
              </ModalButton>
              <ModalButton
                fullWidth
                isPrimary={false}
                onClick={() => {
                  onSecondaryAction();
                  // Amplitude tracking
                  onboardingTracker('Modal Closed', dashName, 'User closed onboarding modal to begin using dashboard', currentSlide);
                }}
              >
                {secondaryButtonText}
              </ModalButton>
            </>
          )}
        </Buttons>
      </ContentContainer>
      <style>
        {`
          .ReactModal__Overlay {
            height: 100vh !important;
          }
        `}
      </style>
    </Modal>
  );
};

OnboardingModal.defaultProps = {
  badgeText: 'GET STARTED',
  dashName: '',
  maxHeight: '80vh',
  maxWidth: '800px',
  minHeight: '500px',
  minWidth: 'auto',
  primaryButtonText: 'START CREATING HABITS',
  secondaryButtonText: 'CONTINUE WITHOUT CREATING HABITS',
  showSingleButton: false,
};

OnboardingModal.propTypes = {
  badgeText: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    gradientType: PropTypes.oneOf(['primary', 'secondary', 'accent', 'highlight', 'neutral']),
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })).isRequired,
  dashName: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  minWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onPrimaryAction: PropTypes.func.isRequired,
  onSecondaryAction: PropTypes.func.isRequired,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  showSingleButton: PropTypes.bool,
};
export default OnboardingModal;
