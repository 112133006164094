/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import Text from '../../../shared/components/Text/Text';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  @media screen and (max-width: 540px) {
    width: 100%;

    .users-box {
      flex-direction: column;
    }
  }

  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const CheckboxWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 45px;
`;

const FormGroup = styled('div')`
  label {
    width: 100%;
  }

  .formgroup-text {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .spinner-container {
    min-height: 100px;
  }

  z-index: 1;
  @media screen and (max-width: 540px) {
    a {
      font-size: 11px;
    }
    width: 100%!important;
    z-index: auto;
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;

const OptionsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

const Option = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 122px;
  height: 122px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
`;

const TextBoxOuterWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TextBoxContainer = styled('div')`
  border-color: ${(props) => props.borderColor};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  height: 165px;
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  cursor: pointer;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
`;

const TextBoxTitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
  margin-top: 10px;
`;

const TextBoxContentContainer = styled('div')`
  display: flex;
  align-items: center;
  text-align: center;
`;

const TextBoxTitle = styled('div')`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const TextBoxContent = styled('div')`
  font-size: 16px;
  font-weight: 300;
  margin-left: 6px;
  margin-right: 6px;

  @media screen and (max-width: 540px) {
    font-size: 14px;
  }

`;

const CreateProgramSlide1 = ({
  formProps,
  selectedOptions,
  setSelectedOptions,
  isGroupSelected,
  setIsGroupSelected,
}) => {
  const [selectedRecipientOption, setSelectedRecipientOption] = useState('Users');
  const [flattenedCalendars, setFlattenedCalendars] = useState([]);
  const groups = useSelector((state) => state.conversations.data.groups);
  const calendars = useSelector((state) => state.conversations.data.calendars);
  const users = useSelector((state) => state.conversations.data.users);
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const theme = useTheme();

  useEffect(() => {
    let newArray = [];
    calendars.forEach((calendar) => {
      newArray.push(calendar);
      if (calendar.childCalendars.length) {
        newArray = newArray.concat(calendar.childCalendars);
      }
    });
    setFlattenedCalendars(newArray);
  }, [calendars]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingLeft: state.data.parentId ? 35 : 15,
    }),
  };

  const recipientsOptions = [
    {
      name: 'Users',
      id: 1,
    },
    {
      name: 'Groups',
      id: 2,
    },
    {
      name: 'Calendars',
      id: 3,
    },
    {
      name: 'All Athletes',
      id: 4,
    },
    {
      name: 'All Coaches',
      id: 5,
    },
    {
      name: 'All Users',
      id: 6,
    },
  ];

  // const recipients = [
  //   {
  //     name: 'Colin',
  //     id: 0,
  //   },
  //   {
  //     name: 'Elvis',
  //     id: 1,
  //   },
  //   {
  //     name: 'James',
  //     id: 2,
  //   },
  //   {
  //     name: 'Kyle',
  //     id: 3,
  //   },
  // ];

  const optionsShaker = () => {
    if (selectedRecipientOption === 'Groups') {
      return groups;
    }
    if (selectedRecipientOption === 'Calendars') {
      return flattenedCalendars;
    }
    return users;
  };

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <TitlePlusErrorWrapper>
                <Text
                  className='formgroup-text'
                >
                  Recipients
                </Text>
                {(formProps.errors.selectedRecipients && (formProps.touched.selectedRecipients)) ? (
                  <FieldError className='error-text'>{formProps.errors.selectedRecipients}</FieldError>
                ) : null}
              </TitlePlusErrorWrapper>
              <div
                className='users-box'
                style={{
                  display: 'flex',
                  marginBottom: '5px',
                }}
              >
                <FormGroup
                  className='form-group'
                  style={{
                    width: '35%',
                    paddingRight: '10px',
                  }}
                >
                  <Select
                    defaultValue={recipientsOptions[0]}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    filterOption={(option) => {
                      /**
                       * If primary admin or coach has full user access, then the
                       * coach can access all user filter types.
                       */
                      if (currentUser.primaryAdmin || currentUser.fullUserAccess) {
                        return true;
                      }
                      /**
                       * If the above is not true, we will need to hide the All Users,
                       * Athlete, and Coach filters in all other cases.
                       */
                      if (option.label === 'All Athletes' || option.label === 'All Coaches' || option.label === 'All Users') {
                        return false;
                      }
                      /**
                       * If the user is an admin/coach, then they are able to see the rest
                       * of the filter options.
                       */
                      if (currentUser.admin) {
                        return true;
                      }
                      /**
                       * Otherwise, the user is an athlete and can only message other users
                       * individually.
                       */
                      return false;
                    }}
                    options={recipientsOptions}
                    isSearchable
                    onChange={(option) => {
                      setSelectedRecipientOption(option.name);
                      formProps.setFieldValue('memberGroupingType', option.id);
                      formProps.setFieldValue('selectedRecipients', []);
                    }}
                  />
                </FormGroup>
                <FormGroup
                  className='form-group'
                  style={{
                    width: '75%',
                  }}
                >
                  <Select
                    defaultValue={users}
                    value={formProps.values.selectedRecipients}
                    getOptionLabel={(option) => {
                      if (selectedRecipientOption === 'Users') {
                        return (`${option.last}, ${option.first}`);
                      }
                      return option.name;
                    }}
                    getOptionValue={(option) => option.id}
                    options={optionsShaker()}
                    styles={customStyles}
                    isDisabled={
                      selectedRecipientOption === 'All Athletes'
                      || selectedRecipientOption === 'All Coaches'
                      || selectedRecipientOption === 'All Users'
                    }
                    isClearable
                    isSearchable
                    hideSelectedOptions
                    isMulti
                    onChange={(options) => {
                      if (options) {
                        formProps.setFieldValue('selectedRecipients', options);
                      } else {
                        formProps.setFieldValue('selectedRecipients', []);
                      }
                    }}
                  />
                </FormGroup>
              </div>
              {(selectedRecipientOption === 'Users' && formProps.values.selectedRecipients.length < 2)
              || ((selectedOptions.sms || selectedOptions.em) && !selectedOptions.tbm) ? null : (
                <>
                  <TitlePlusErrorWrapper style={{
                    marginTop: '10px',
                  }}
                  >
                    <Text
                      className='formgroup-text'
                    >
                      Select Conversation Type
                    </Text>
                  </TitlePlusErrorWrapper>
                  <TextBoxOuterWrapper>
                    <TextBoxContainer
                      onClick={() => setIsGroupSelected(true)}
                      borderColor={isGroupSelected ? theme.colors.green : 'hsl(0,0%,80%)'}
                      marginRight='10px'
                    >
                      <IconWrapper>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={50}
                          icon='conversation-group'
                        />
                      </IconWrapper>
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Group
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          Message will be sent to the entire group
                        </TextBoxContent>
                      </TextBoxContentContainer>
                    </TextBoxContainer>
                    <TextBoxContainer
                      onClick={() => setIsGroupSelected(false)}
                      borderColor={!isGroupSelected ? theme.colors.green : 'hsl(0,0%,80%)'}
                      marginLeft='10px'
                    >
                      <IconWrapper>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={50}
                          icon='conversation-individual'
                        />
                      </IconWrapper>
                      <TextBoxTitleContainer>
                        <TextBoxTitle>
                          Individual
                        </TextBoxTitle>
                      </TextBoxTitleContainer>
                      <TextBoxContentContainer>
                        <TextBoxContent>
                          Messages will be sent to recipients individually
                        </TextBoxContent>
                      </TextBoxContentContainer>

                    </TextBoxContainer>
                  </TextBoxOuterWrapper>
                </>
                )}
              <FormGroup>
                {selectedOptions.tbm && currentUser.admin === 1 ? (
                  <CheckboxWrapper>
                    <Checkbox
                      onChange={() => {
                        formProps.setFieldValue('readOnly', !formProps.values.readOnly);
                      }}
                      checked={formProps.values.readOnly}
                      color='primary'
                      disabled={(selectedOptions.sms || selectedOptions.em) && !selectedOptions.tbm}
                    />
                    <Text
                      style={{
                        textDecoration: ((selectedOptions.sms || selectedOptions.em) && !selectedOptions.tbm) ? 'line-through' : '',
                      }}
                      fontSize={14}
                    >
                      Make conversation read only (Athletes cannot reply)
                    </Text>
                  </CheckboxWrapper>
                ) : null}
              </FormGroup>
              {(currentUser.admin ? (
                <>
                  <Text
                    className='formgroup-text'
                    style={{
                      marginBottom: '10px',
                    }}
                  >
                    Send message as
                  </Text>
                  <OptionsWrapper>
                    <Option
                      onClick={() => {
                        const newSelectedOptions = { ...selectedOptions };
                        newSelectedOptions.tbm = !selectedOptions.tbm;
                        setSelectedOptions(newSelectedOptions);
                      }}
                      style={{
                        borderColor: selectedOptions.tbm ? '#0dcc8a' : null,
                        borderWidth: selectedOptions.tbm ? '1px' : null,
                        borderStyle: selectedOptions.tbm ? 'solid' : null,
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={35}
                        icon='message-bubbles'
                      />
                      <Text style={{
                        fontSize: '10px',
                        marginTop: '5px',
                      }}
                      >
                        Message
                      </Text>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        background: !selectedOptions.tbm ? '' : '#10cd8c',
                        border: !selectedOptions.tbm ? '1px solid black' : '1px solid #10cd8c',
                        margin: '5px',
                      }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={10}
                          icon='checkmark'
                          color='white'
                        />
                      </div>
                    </Option>
                    <Option
                      onClick={() => {
                        const newSelectedOptions = { ...selectedOptions };
                        newSelectedOptions.sms = !selectedOptions.sms;
                        setSelectedOptions(newSelectedOptions);
                      }}
                      style={{
                        borderColor: selectedOptions.sms ? '#0dcc8a' : null,
                        borderWidth: selectedOptions.sms ? '1px' : null,
                        borderStyle: selectedOptions.sms ? 'solid' : null,
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={35}
                        icon='phone'
                      />
                      <Text style={{
                        fontSize: '10px',
                        marginTop: '5px',
                      }}
                      >
                        Text (SMS)
                      </Text>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        background: !selectedOptions.sms ? '' : '#10cd8c',
                        border: !selectedOptions.sms ? '1px solid black' : '1px solid #10cd8c',
                        margin: '5px',
                      }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={10}
                          icon='checkmark'
                          color='white'
                        />
                      </div>
                    </Option>
                    <Option
                      onClick={() => {
                        const newSelectedOptions = { ...selectedOptions };
                        newSelectedOptions.em = !selectedOptions.em;
                        setSelectedOptions(newSelectedOptions);
                      }}
                      style={{
                        borderColor: selectedOptions.em ? '#0dcc8a' : null,
                        borderWidth: selectedOptions.em ? '1px' : null,
                        borderStyle: selectedOptions.em ? 'solid' : null,
                      }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={35}
                        icon='envelope-closed'
                      />
                      <Text style={{
                        fontSize: '10px',
                        marginTop: '5px',
                      }}
                      >
                        Email
                      </Text>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        background: !selectedOptions.em ? '' : '#10cd8c',
                        border: !selectedOptions.em ? '1px solid black' : '1px solid #10cd8c',
                        margin: '5px',
                      }}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={10}
                          icon='checkmark'
                          color='white'
                        />
                      </div>
                    </Option>
                  </OptionsWrapper>
                </>
              ) : null)}
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

CreateProgramSlide1.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  selectedOptions: PropTypes.string.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  isGroupSelected: PropTypes.bool.isRequired,
  setIsGroupSelected: PropTypes.func.isRequired,
};

CreateProgramSlide1.defaultProps = {
};

export default CreateProgramSlide1;
