import {
  FETCH_REPORTABLE_GROUPS_FULFILLED,
  FETCH_REPORTABLE_GROUPS_REJECTED,
  FETCH_ATHLETE_PARTICIPATION_FULFILLED,
  SET_ACTIVE_WEARABLES_DASH_DATE,
  SET_ACTIVE_GROUP,
  SET_ACTIVE_ATHLETE,
  SET_ACTIVE_METRIC_TYPE,
  SET_FILTERED_SEARCH_LIST,
  SET_FILTER_INFO_FROM_PIE_CHART,
  SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL,
  FETCH_ATHLETES_TO_WATCH_FULFILLED,
  FETCH_ATHLETE_AVERAGES_FULFILLED,
  CLEAR_ATHLETE_AVERAGES,
  CLEAR_ACTIVE_ATHLETE,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED,
  CLEAR_WEARABLES_DATA_CHART_REPORT,
  DOWNLOAD_WEARABLES_REPORT_FULFILLED,
  CLEAR_REPORTABLE_GROUPS,
} from './actionTypes';

const initialState = {
  // reportableGroups: [],
  athleteParticipation: {},
  activeUserIds: [],
  notConnectedUserIds: [],
  connectedAndNotSharingUserIds: [],
  activeWearablesDashDate: '',
  activeGroup: {},
  activeAthlete: {},
  activeMetricType: {},
  activeModal: '',
  athletesToWatch: {},
  athleteAverages: {},
  sevenDayAthleteAveragesArray: [],
  wearablesReportDataForDataChart: {},
  wearablesReportcsv: {},
  filteredSearchList: [],
  pieChartFilter: {},
  showPieChartBadge: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_FULFILLED: {
      return {
        ...state,
        reportableGroups: action.payload,
        // reportableGroups: [],
      };
    }
    case FETCH_REPORTABLE_GROUPS_REJECTED: {
      return {
        ...state,
        reportableGroups: [],
      };
    }
    case FETCH_ATHLETE_PARTICIPATION_FULFILLED: {
      const activeIds = action.payload.active.users.map((user) => user.id);
      const notConnected = action.payload.notConnected.users.map((user) => user.id);
      const notSharing = action.payload.notSharing.users.map((user) => user.id);

      return {
        ...state,
        athleteParticipation: action.payload,
        activeUserIds: activeIds,
        notConnectedUserIds: notConnected,
        connectedAndNotSharingUserIds: notSharing,
      };
    }
    case SET_ACTIVE_WEARABLES_DASH_DATE: {
      return {
        ...state,
        activeWearablesDashDate: action.payload,
      };
    }
    case SET_ACTIVE_GROUP: {
      return {
        ...state,
        activeGroup: action.payload,
      };
    }
    case SET_ACTIVE_ATHLETE: {
      return {
        ...state,
        activeAthlete: action.payload,
      };
    }
    case CLEAR_ACTIVE_ATHLETE: {
      return {
        ...state,
        activeAthlete: action.payload,
      };
    }
    case CLEAR_REPORTABLE_GROUPS: {
      return {
        ...state,
        reportableGroups: null,
      };
    }
    case SET_ACTIVE_METRIC_TYPE: {
      return {
        ...state,
        activeMetricType: action.payload,
      };
    }
    case SET_FILTERED_SEARCH_LIST: {
      return {
        ...state,
        filteredSearchList: action.payload,
      };
    }
    case SET_FILTER_INFO_FROM_PIE_CHART: {
      return {
        ...state,
        pieChartFilter: action.payload,
        showPieChartBadge: false,
      };
    }
    case SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
      };
    }
    case FETCH_ATHLETES_TO_WATCH_FULFILLED: {
      return {
        ...state,
        athletesToWatch: action.payload,
      };
    }
    case FETCH_ATHLETE_AVERAGES_FULFILLED: {
      const sevenDayRunningScoresOriginalArray = action.payload.sevenDayRunningScores;
      /** we need to slice and reverse the array because the reverse method
       * will mutate the original array, causing all sorts of problems
       * this way we can return a new reversed array that doesn't mess with the original
       */
      const reversedArray = sevenDayRunningScoresOriginalArray?.slice().reverse();

      return {
        ...state,
        athleteAverages: action.payload,
        sevenDayAthleteAveragesArray: reversedArray,
      };
    }
    case CLEAR_ATHLETE_AVERAGES: {
      return {
        ...state,
        athleteAverages: action.payload,
        sevenDayAthleteAveragesArray: [],
      };
    }
    case FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED: {
      return {
        ...state,
        wearablesReportDataForDataChart: action.payload,
        showPieChartBadge: true,
      };
    }
    case CLEAR_WEARABLES_DATA_CHART_REPORT: {
      return {
        ...state,
        wearablesReportDataForDataChart: action.payload,
      };
    }
    case DOWNLOAD_WEARABLES_REPORT_FULFILLED: {
      return {
        ...state,
        wearablesReportcsv: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
