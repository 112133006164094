import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createPanelReducer from './panelReducer';
import weightroomDataReducer from './weightroomDataReducer';
import weightroomUiReducer from './weightroomUiReducer';

const dataPersistConfig = {
  key: 'data',
  storage,
};

const uiPersistConfig = {
  key: 'ui',
  storage,
  blacklist: ['fetchedCurrentUser', 'fetchedUsers', 'fetchedGroups'],
};

export default combineReducers({
  panel1: createPanelReducer('panel1'),
  panel2: createPanelReducer('panel2'),
  panel3: createPanelReducer('panel3'),
  panel4: createPanelReducer('panel4'),
  data: persistReducer(dataPersistConfig, weightroomDataReducer),
  ui: persistReducer(uiPersistConfig, weightroomUiReducer),
  // data: weightroomDataReducer,
  // ui: weightroomUiReducer,
});
