/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import { setAttentionAction } from '../../ducks/habitTrackingActions';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #FFFFFF;
  max-width: 452px;
`;

const Button = styled('button')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor && props.backgroundColor};
  padding: 8px 16px 8px 16px;
  height: 51px;
  border-radius: 4px;
  border: 0px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 20px 40px 20px;
  text-align: center;
  gap: 20px;
  
`;

const IconCircle = styled('div')`
  display: flex;
  height: 52px;
  width: 52px;
  min-width: 52px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  padding-bottom: 5px;
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.containerWidth < 400 ? '20px' : '20px 40px 36px 40px')};
  gap: 30px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AttentionModalContent = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState(0);
  const errorModalDataObject = useSelector((state) => state.sportsScience.ui.errorModalDataObject);

  return (
    <Wrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <Header>
        <IconCircle>
          <IcomoonReact
            iconSet={iconSet}
            size={28}
            icon='warning'
          />
        </IconCircle>
        <Text fontWeight={700} fontSize='32px'>Attention</Text>
      </Header>
      <Body containerWidth={containerWidth}>
        <Text fontSize={containerWidth < 400 ? '16px' : '19px'} fontWeight={700}>
          Athletes can have up to 5 habits displayed, showing only the first 5 created habits. For athletes who have more than 5 habits to display the habit
          {' '}
          {`"${errorModalDataObject?.habitName}"`}
          ,
          you will need to edit or remove one of the previous habits.
        </Text>
        <Button
          onClick={() => {
            closeModal();
            dispatch(setAttentionAction('continue'));
          }}
          backgroundColor='#FFCB47'
        >
          <Text fontSize='23px' fontWeight={700}>Continue</Text>
        </Button>
      </Body>
    </Wrapper>

  );
};

export default AttentionModalContent;

AttentionModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
