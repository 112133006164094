/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import NonDateBasedAthleteBottomSheet from './NonDateBasedAthleteBottomSheet';
import SmallScreenAthleteSelector from './SmallScreenAthleteSelector';

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const BottomSheetContainer = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);
  const normalBottomSheetFunction = () => {
    if (currentUser.admin) {
      return <SmallScreenAthleteSelector />;
    } if (!currentUser.admin && isDayCarousel) {
      return <NonDateBasedAthleteBottomSheet />;
    }
    return null;
  };
  return (
    <>
      {normalBottomSheetFunction()}
    </>
  );
};

export default BottomSheetContainer;
