/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const Wrapper = styled('div')`
display: flex;
flex-direction: column;
width: 100%;
padding: 16px;
border-radius: 12px;
box-shadow: 0px 4px 8px 0px #3031331A;
padding-bottom: 0px;

`;
const CardContainer = styled('div')`
display: flex;
flex-direction: column;
width: 100%;
max-height: 300px;
min-height: 300px;
overflow: auto;
gap: 8px;
`;

const PresetOptionCard = styled('div')`
display: flex;
padding: 8px 12px 8px 12px;
border-radius: 8px;
justify-content: space-between;
flex-wrap: wrap;
cursor: pointer;
:hover {
  background-color: #CFF5E8;
}
&.active {
  background-color: #CFF5E8;
}
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 50px;
  background: white;
  overflow: hidden;
  width: 100%;
  padding-right: 12px;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  height: 40x;
`;

const SearchContainer = styled('input')`
  display: flex;
  border: none;
  background: none;
  padding: 5px;
  width: 100%;
  height: 40x;
`;
const SearchablePresetSelectMenu = ({
  habitPresets, setChosenPreset, chosenPreset, setActivePage, setFieldValue, setFieldTouched,
}) => {
  const containerRef = useRef(null);
  const presetRefs = useRef({});

  useEffect(() => {
    if (chosenPreset && presetRefs.current[chosenPreset.id]) {
      const presetElement = presetRefs.current[chosenPreset.id];
      const { offsetTop, offsetHeight } = presetElement;
      const containerHeight = containerRef.current.clientHeight;
      containerRef.current.scrollTop = offsetTop - containerHeight / 2 + offsetHeight / 2;
    }
  }, [chosenPreset]);

  const handleOptionCardClick = (preset) => {
    setChosenPreset(preset);

    setFieldValue('name', preset.name);
    setFieldValue('description', preset.description);
    setFieldValue('type', parseInt(preset.type, 10));
    setFieldValue('cadence', preset.cadence);
    setFieldValue('frequency', preset.frequency);
    setFieldValue('categoryId', preset.categoryId);
    setFieldValue('groupIds', preset.groupIds);
    setFieldValue('userIds', preset.userIds);
    setFieldValue('creationType', preset.creationType);

    setFieldTouched('name', true);
    setFieldTouched('description', true);
    setFieldTouched('type', true);
    setFieldTouched('cadence', true);
    setFieldTouched('frequency', true);
    setFieldTouched('categoryId', true);
    setFieldTouched('groupIds', true);
    setFieldTouched('userIds', true);

    if (preset) {
      setActivePage(3);
    }
  };

  const [filteredArray, setFilteredArray] = useState(habitPresets);
  const [searchWord, setSearchWord] = useState('');

  const handleFilter = (event) => {
    const localsearchWord = event.target.value;
    setSearchWord(event.target.value);
    const filteredData = habitPresets?.filter((item) => item.name?.toLowerCase().includes(localsearchWord));
    setFilteredArray(filteredData);
  };

  const resetList = () => {
    setSearchWord('');
    setFilteredArray(habitPresets);
  };

  return (
    <Wrapper>
      <SearchAndIcon id='search-container'>
        <SearchIconContainer id='search-icon-container'>
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='magnifying-glass-alt'
            color='#9E9E9E'
          />
        </SearchIconContainer>
        <SearchContainer
          id='search-container-input'
          key='searchinput'
          placeholder='Search for habits'
          value={searchWord}
          autoFocus='autoFocus'
          onChange={handleFilter}
        />
        {searchWord && (
          <IcomoonReact
            onClick={() => resetList()}
            iconSet={iconSet}
            size={16}
            icon='remove'
            color='#444444'
          />
        )}
      </SearchAndIcon>
      <CardContainer ref={containerRef}>
        {!filteredArray.length && (
          <Text style={{ textAlign: 'center', color: 'grey', marginTop: '40px' }}>No options</Text>
        )}
        {filteredArray.map((preset) => (
          <PresetOptionCard
            ref={(el) => { presetRefs.current[preset.id] = el; }}
            key={preset.id}
            onClick={() => handleOptionCardClick(preset)}
            className={preset?.id === chosenPreset?.id && 'active'}
          >
            <Text>{preset.name}</Text>
          </PresetOptionCard>
        ))}
      </CardContainer>
    </Wrapper>
  );
};

SearchablePresetSelectMenu.propTypes = {
  setChosenPreset: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setActivePage: PropTypes.func.isRequired,
  chosenPreset: PropTypes.instanceOf(Object),
  habitPresets: PropTypes.instanceOf(Array).isRequired,
};

SearchablePresetSelectMenu.defaultProps = {
  chosenPreset: {},
};

export default SearchablePresetSelectMenu;
