import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useSelector, useDispatch } from 'react-redux';
import { closeWorkout, closePanel } from '../../ducks/panelActions';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const CloseAthleteWrapper = styled('div')`
  height: 30px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
`;

const CloseAthlete = ({
  namespace,
}) => {
  const isWorkoutActive = useSelector((state) => state.weightRoomView[namespace].isWorkoutActive);
  const skin = useSelector((state) => state.weightRoomView.data.currentUser.userSkin);

  const dispatch = useDispatch();

  const isAccentDarkTheme = skin && (skin.accent_dark_theme);

  const handleIconClick = () => {
    if (isWorkoutActive) {
      dispatch(closeWorkout(namespace));
    } else {
      dispatch(closePanel(namespace));
    }
  };

  return (
    <CloseAthleteWrapper
      onClick={handleIconClick}
    >
      <IcomoonReact
        iconSet={iconSet}
        color={isAccentDarkTheme ? '#444444' : 'white'}
        size={15}
        icon={`${isWorkoutActive ? 'list' : 'remove'}`}
      />
    </CloseAthleteWrapper>
  );
};

CloseAthlete.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default memo(CloseAthlete);
