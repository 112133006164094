/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import ReactPlayer from 'react-player';

import Text from '../../../shared/components/Text/Text';
import iconSet from '../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
    color: white!important;
  }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
`;

const AnotherWrapper = styled('div')`
  position: relative;
  padding-top: 56.25%;
`;

const BannerMessage = styled('div')`
  background-color: white;
  border-radius: 50px;
  text-align: center;
  width: 80%;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
`;

const BannerTitle = styled('div')`
  font-size: 18px;
  font-weight: 600;
  font-family: Nunito Sans;
`;

const BannerSubTitle = styled('div')`
  font-size: 15px;
  font-family: Open Sans;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  margin-bottom: 30px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const BannerWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: -100px;
  left: 0px;
`;

const MediaModalContent = ({
  onRequestClose,
  mediaUrl,
}) => {
  let altMediaUrl;

  if (mediaUrl.includes('drive.google')) {
    const split = mediaUrl.split('/');
    const id = split[split.length - 2];
    altMediaUrl = `https://drive.google.com/uc?export=download&id=${id}`;
  } else {
    altMediaUrl = mediaUrl;
  }

  return (
    <ModalWrapper>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            onRequestClose();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
            color='white'
          />
        </Text>
      </NavigationWrapper>
      {!mediaUrl.includes('.mp4') && (
      <BannerWrapper>
        <BannerMessage>
          <BannerTitle>
            Video Processing
          </BannerTitle>
          <BannerSubTitle>
            Please refresh if you experience issues
          </BannerSubTitle>
        </BannerMessage>
      </BannerWrapper>
      )}
      <InnerWrapper>
        <AnotherWrapper>
          <ReactPlayer
            className='react-player'
            url={altMediaUrl}
            controls
            width='100%'
            height='100%'
            playing
            style={{ backgroundColor: 'black' }}
          />
        </AnotherWrapper>
      </InnerWrapper>
    </ModalWrapper>
  );
};

MediaModalContent.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  mediaUrl: PropTypes.string.isRequired,
};

export default MediaModalContent;
