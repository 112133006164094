/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setDuplicateModalShowing } from '../../ducks/sportCoachDashboardActions';
import DuplicateDashboardModal from './DuplicateDashboardModal';

const DuplicateDashboardModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const DuplicateDashboardModalContainer = () => {
  const dispatch = useDispatch();
  const duplicateDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.duplicateDashboardModalShowing,
  );

  return (
    <>
      <DuplicateDashboardModal />
      <DuplicateDashboardModalOverlay
        isActive={duplicateDashboardModalShowing}
        onClick={
          () => dispatch(setDuplicateModalShowing(false))
          }
      />
    </>
  );
};

DuplicateDashboardModalContainer.propTypes = { };

export default DuplicateDashboardModalContainer;
