import {
  FETCH_ATHLETE_REPORT_CARD_FULFILLED,
  FETCH_ATHLETE_REPORT_CARD_REJECTED,
  FETCH_UNSUBSCRIBE_FULFILLED,
  FETCH_UNSUBSCRIBE_REJECTED,
  FETCH_REPORT_CARD_LINK_FULFILLED,
  FETCH_REPORT_CARD_LINK_REJECTED,
  SET_IS_SHARE_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  athleteReportCard: {},
  unsubscribed: null,
  reportCardLink: null,
  isShareModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATHLETE_REPORT_CARD_FULFILLED: {
      return {
        ...state,
        athleteReportCard: action.payload,
        athleteReportCardError: null,
      };
    }
    case FETCH_ATHLETE_REPORT_CARD_REJECTED: {
      return {
        ...state,
        athleteReportCard: {},
        athleteReportCardError: action.payload?.response?.headers?.message,
      };
    }
    case FETCH_UNSUBSCRIBE_FULFILLED: {
      return {
        ...state,
        unsubscribed: true,
        unsubscribeError: null,
      };
    }
    case FETCH_UNSUBSCRIBE_REJECTED: {
      return {
        ...state,
        unsubscribed: false,
        unsubscribeError: action.payload?.response?.headers?.message,
      };
    }
    case FETCH_REPORT_CARD_LINK_FULFILLED: {
      return {
        ...state,
        reportCardLink: action.payload,
        reportCardLinkError: null,
      };
    }
    case FETCH_REPORT_CARD_LINK_REJECTED: {
      return {
        ...state,
        reportCardLink: null,
        reportCardLinkError: action.payload?.response?.headers?.message,
      };
    }
    case SET_IS_SHARE_MODAL_OPEN: {
      return {
        ...state,
        isShareModalOpen: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
