export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const SET_RECOVERY_RADAR_VIEW = 'SET_RECOVERY_RADAR_VIEW';

export const SET_GROUP = 'SET_GROUP';

export const SET_SELECTED_ATHLETE = 'SET_SELECTED_ATHLETE';

export const SET_SELECTED_DATE_CAL = 'SET_SELECTED_DATE_CAL';
export const SET_SELECTED_DATE_DATA = 'SET_SELECTED_DATE_DATA';

export const SET_IS_FILTER_OPEN = 'SET_IS_FILTER_OPEN';

export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
export const SET_ACTIVE_FILTERS_OVERVIEW = 'SET_ACTIVE_FILTERS_OVERVIEW';
export const SET_ACTIVE_TYPE_FILTERS = 'SET_ACTIVE_TYPE_FILTERS';

export const SET_ATHLETE_RADIO_VALUE = 'SET_ATHLETE_RADIO_VALUE';

export const SET_RECOVERY_RADAR_ACTIVE_MODAL = 'SET_RECOVERY_RADAR_ACTIVE_MODAL';
export const SET_PRINT_CURRENT_PAGE = 'SET_PRINT_CURRENT_PAGE';

// OVERVIEW
export const FETCH_OVERVIEW_DATA_START = 'FETCH_OVERVIEW_DATA_START';
export const FETCH_OVERVIEW_DATA_FULFILLED = 'FETCH_OVERVIEW_DATA_FULFILLED';
export const FETCH_OVERVIEW_DATA_REJECTED = 'FETCH_OVERVIEW_DATA_REJECTED';

export const SET_DATE_RANGE = 'SET_DATE_RANGE';

export const SET_FLAGGED_ATHLETES_VIEW = 'SET_FLAGGED_ATHLETES_VIEW';
export const SET_SHOW_FLAGGED_ATHLETES_MODAL = 'SET_SHOW_FLAGGED_ATHLETES_MODAL';
export const SET_ATHLETES_TO_REVIEW = 'SET_ATHLETES_TO_REVIEW';

// ANALYSIS
export const FETCH_GROUP_ANALYSIS_DATA_START = 'FETCH_GROUP_ANALYSIS_DATA_START';
export const FETCH_GROUP_ANALYSIS_DATA_FULFILLED = 'FETCH_GROUP_ANALYSIS_DATA_FULFILLED';
export const FETCH_GROUP_ANALYSIS_DATA_REJECTED = 'FETCH_GROUP_ANALYSIS_DATA_REJECTED';

// HISTORY
export const FETCH_USERS_ASSOCIATED_WITH_GROUP_START = 'FETCH_USERS_ASSOCIATED_WITH_GROUP_START';
export const FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED = 'FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED';
export const FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED = 'FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED';
export const CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA = 'CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA';

export const FETCH_USER_HISTORY_HEAT_MAP_DATA_START = 'FETCH_USER_HISTORY_HEAT_MAP_DATA_START';
export const FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED = 'FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED';
export const FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED = 'FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED';
export const CLEAR_USER_HISTORY_DATA = 'CLEAR_USER_HISTORY_DATA';

// SETTINGS
export const FETCH_REGION_STATUS_START = 'FETCH_REGION_STATUS_START';
export const FETCH_REGION_STATUS_FULFILLED = 'FETCH_REGION_STATUS_FULFILLED';
export const FETCH_REGION_STATUS_REJECTED = 'FETCH_REGION_STATUS_REJECTED';

export const SET_REGION_STATUS_START = 'SET_REGION_STATUS_START';
export const SET_REGION_STATUS_FULFILLED = 'SET_REGION_STATUS_FULFILLED';
export const SET_REGION_STATUS_REJECTED = 'SET_REGION_STATUS_REJECTED';

export const SET_ONBOARDING_MODAL_OPEN = 'SET_ONBOARDING_MODAL_OPEN';

export const FETCH_VIEWED_FEATURE_START = 'FETCH_VIEWED_FEATURE_START';
export const FETCH_VIEWED_FEATURE_FULFILLED = 'FETCH_VIEWED_FEATURE_FULFILLED';
export const FETCH_VIEWED_FEATURE_REJECTED = 'FETCH_VIEWED_FEATURE_REJECTED';

export const SET_VIEWED_FEATURE_START = 'SET_VIEWED_FEATURE_START';
export const SET_VIEWED_FEATURE_FULFILLED = 'SET_VIEWED_FEATURE_FULFILLED';
export const SET_VIEWED_FEATURE_REJECTED = 'SET_VIEWED_FEATURE_REJECTED';
