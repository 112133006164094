/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Question from './Question';
import QuestionnaireTotal from './QuestionnaireTotal';

const Container = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const Questionnaire = ({
  workoutObject, index, outerIndex, sessionIndex,
}) => {
  const [localOb, setLocalOb] = useState(workoutObject);

  useEffect(() => {
    const newOb = { ...workoutObject };
    setLocalOb(newOb);
  }, [workoutObject]);

  return (
    <Container>
      {localOb?.questions?.map((question, idx) => (
        <Question
          question={question}
          workoutObject={localOb}
          first={idx === 0}
          index={index}
          outerIndex={outerIndex}
          sessionIndex={sessionIndex}
        />
      ))}
      <div />
      {localOb.questionnaireTotalEnabled
        ? <QuestionnaireTotal workoutObject={localOb} /> : null}
    </Container>
  );
};

Questionnaire.defaultProps = {
  sessionIndex: null,
};

Questionnaire.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default Questionnaire;
