export const FETCH_REPORTABLE_GROUPS_LM_DASH_START = 'FETCH_REPORTABLE_GROUPS_LM_DASH_START';
export const FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED = 'FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED';
export const FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED = 'FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED';

export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START';
export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED';
export const FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED = 'FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED';

export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED';
export const FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED = 'FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED';

export const FETCH_LOAD_MONITORNING_LINE_CHART_START = 'FETCH_LOAD_MONITORNING_LINE_CHART_START';
export const FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED = 'FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED';
export const FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED = 'FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED';

export const SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER = 'SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER';
export const SET_CURRENT_DATE_IN_DASH_DATE_PICKER = 'SET_CURRENT_DATE_IN_DASH_DATE_PICKER';
export const SET_CURRENT_GROUP_IN_GROUP_PICKER = 'SET_CURRENT_GROUP_IN_GROUP_PICKER';
export const SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER = 'SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER';
export const SET_ATHLETES_IN_CLICKED_ZONE = 'SET_ATHLETES_IN_CLICKED_ZONE ';
export const SET_CURRENT_CHECKED_BOXES = 'SET_CURRENT_CHECKED_BOXES';
export const SET_SHOW_ALL_FOR_DOWNLOAD = 'SET_SHOW_ALL_FOR_DOWNLOAD';
export const SET_IS_CHART_SHOWING_ALL_DATA = 'SET_IS_CHART_SHOWING_ALL_DATA';
