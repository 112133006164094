import React from 'react';
import { useDispatch } from 'react-redux';

import PinPad from '../presentational/PinPad';
import { closePinPad, submitPinCode } from '../../ducks/panelActions';

const WeightroomPinPad = (ownProps) => {
  const dispatch = useDispatch();

  const clsePinPad = (namespace) => {
    dispatch(closePinPad(namespace));
  };
  const sbmitPinCode = (namespace, accountCode, userId, values) => {
    dispatch(submitPinCode(namespace, accountCode, userId, values));
  };

  return (
    <PinPad
      namespace={ownProps.namespace}
      closePinPad={clsePinPad}
      submitPinCode={sbmitPinCode}
    />
  );
};

export default WeightroomPinPad;
