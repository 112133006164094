import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { batch, useSelector, useDispatch } from 'react-redux';
import Logger from 'js-logger';
import ResizeObserver from 'react-resize-observer';
import { StripeProvider } from 'react-stripe-elements';
import { useTheme } from 'emotion-theming';

import { logOut } from '../../../login/ducks/loginActions';
import { setModalOpen, clearPaymentData, clearCoupon, clearCouponError } from '../../ducks/programsActions';
import Modal from '../../../../shared/components/Modal/Modal';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../shared/components/Text/Text';
import Link from '../../../../shared/components/Link/Link';
import useScript from '../../../../shared/hooks/useScript';

import ModalLogin from './ModalLogin';
import ModalCreateAccount from './ModalCreateAccount';
import ModalCheckout from './ModalCheckout';


const ModalContainer = styled('div')`
  width: 100%;
  padding: ${props => (props.activeModal === 'Leaderboard Settings' ? '0px' : '0px')};

  button {
    position: relative;
    bottom: 0px;
    /* margin-left: ${props => (props.activeModal === 'Leaderboard Settings' ? '-40px' : '-130px')}; */
  }
  
  .toggle {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @media (max-width: 1260px) {
    padding: 0px 30px 30px 30px;
  }

  @media (max-width: 540px) {
    padding: 0px!important;
  }

  @media screen and (max-width: 991px) {
    padding: '0px 30px 60px 30px'};
    
  }

  @media (max-width: 767px) and (max-height: 581px) {
    /* button {
      margin-left: -70px;
    } */
  }
`;

const ModalHeader = styled('div')`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0;
  padding: 25px;
`;

const ModalBody = styled('div')`
  margin-top: 70px;
  p {
    margin-bottom: 20px;
  }

  .login-link {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
    cursor: pointer;
    
    span {
      font-family: inherit;
      color: ${props => props.theme.colors.orange}
    }
    &:hover {
      opacity: .7;
    }
  }

  .login {
    font-weight: 600;
    color: #ff6600;
    &:hover {
      opacity: .7;
    }
  }
`;

const ModalText = styled('div')`
  display: inline-flex;
`;

const AuthenticatedCheckOut = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  img {
    margin-right: 15px;
  }

  p {
    margin-bottom: 0px;
  }
`;

const ProgramModal = ({
  accountLogoKey,
  resellerSlug,
  programSlug,
  isAuthenticatedv3,
  userName,
}) => {
  // const closeModal = () => {
  //   handleSetIsModalOpen(false, activeModal);
  // };
  const [modalDimensions, setModalDimensions] = useState({});
  const activeModal = useSelector(state => state.programs.ui.activeModal);
  const isModalOpen = useSelector(state => state.programs.ui.isModalOpen);
  const isAuthenticated = useSelector(state => state.auth.ui.isAuthenticated);
  const isRegisteredInClient = useSelector(state => state.auth.ui.isRegisteredInClient);
  const accountCode = useSelector(state => state.auth.data.currentUser.accountCode);
  const theme = useTheme();
  useScript('https://js.stripe.com/v3/');

  const modalContentRef = useRef(null);

  const dispatch = useDispatch();

  // Check if we have authenticated when isAuthenticated changes, should only be
  // after a user logs in. If so, close login modal, open checkout modal.
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setModalOpen(false, 'login'));
      setTimeout(() => {
        dispatch(setModalOpen(true, 'checkout'));
      }, 800);
    }
  }, [isAuthenticated]);

  // Check if we have registered when isRegisteredInClient changes, should only be
  // after a user registers. If so, close createaccount modal, open checkout modal.
  useEffect(() => {
    if (isRegisteredInClient) {
      dispatch(setModalOpen(false, 'createAccount'));
      setTimeout(() => {
        dispatch(setModalOpen(true, 'checkout'));
      }, 800);
    }
  }, [isRegisteredInClient]);

  // To make the modal scrollable when the window height is small, we save the 
  // modal dimensions on isOpen, and set the height dynamically in a css media query
  // where we make the modal absolute position, and overflow auto.
  useEffect(() => {
    setTimeout(() => {
      Logger.debug('Modal height:', modalContentRef.current.clientHeight);
      setModalDimensions(modalContentRef.current.clientHeight);
    }, 2000);
  }, [isModalOpen]);

  const closeModal = (modal) => {
    dispatch(clearCouponError());
    dispatch(clearCoupon());
    dispatch(setModalOpen(false, modal));
  };

  const openModal = (modal) => {
    closeModal(activeModal);
    setTimeout(() => {
      dispatch(setModalOpen(true, modal));
    }, 800);
  };

  const openLogin = (e) => {
    e.preventDefault();
    dispatch(setModalOpen(false, 'checkout'));
    setTimeout(() => {
      batch(() => {
        dispatch(logOut());
        dispatch(clearPaymentData());
        dispatch(setModalOpen(true, 'login'));
      });
    }, 800);
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .ReactModal__Overlay {
            overflow: auto;
          }

          @media screen and (max-height: ${modalDimensions + 80}px) {
            .ReactModal__Content {
              position: absolute!important;
              top: 15px!important;
            }
          }

        `}
      />
      <Modal
        onRequestClose={closeModal}
        isOpen={isModalOpen}
        customButton
      >
        <ModalContainer
          ref={modalContentRef}
        >
          {/* {/* {activeModal === 'main' && (
            <MaxModalForm
              closeModal={closeModal}
            />
          )} */}
          {activeModal === 'login' && (
            <Fragment>
              <ModalHeader>
                <HeaderText>Sign In</HeaderText>
              </ModalHeader>
              <ModalBody>
                <ModalLogin 
                  openModal={openModal}
                />
              </ModalBody>
            </Fragment>
          )}
          {activeModal === 'createAccount' && (
            <Fragment>
              <ModalHeader>
                <HeaderText>Create Account</HeaderText>
              </ModalHeader>
              <ModalBody>
                <Link 
                  className='login-link' 
                  allCaps 
                  color='lightText.1' 
                  onClick={() => openModal('login')}
                >
                  Already have an account? <span>Click here to log in.</span>
                </Link>
                <ModalCreateAccount
                  programSlug={programSlug}
                  resellerSlug={resellerSlug}
                />
              </ModalBody>
            </Fragment>
          )}
          {activeModal === 'checkout' && (
            <Fragment>
              <ModalHeader>
                <HeaderText>Checkout</HeaderText>
              </ModalHeader>
              <ModalBody>
                {(isAuthenticated || isAuthenticatedv3) && (
                  <AuthenticatedCheckOut>
                    <Avatar
                      avatar={accountLogoKey}
                      size={20}
                    />
                    <Text fontSize={theme.textFontSizes[0]}>Checking out as {userName}. <Link className='login' fontSize={theme.textFontSizes[0]} href='#' onClick={openLogin}>Use another account?</Link></Text>
                  </AuthenticatedCheckOut>
                )}
                <StripeProvider apiKey={accountCode === 1 ? process.env.STRIPE_PUBLIC_KEY_TEST : process.env.STRIPE_PUBLIC_KEY}>
                  <ModalCheckout />
                </StripeProvider>
              </ModalBody>
            </Fragment>
          )}
        </ModalContainer>
      </Modal>
    </Fragment>
  );
};

export default ProgramModal;
