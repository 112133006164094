/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import update from 'immutability-helper';
import {
  CLEAR_USER,
  FETCH_USER_FULFILLED,
  FETCH_USER_DATA_FULFILLED,
  SUBMIT_LOGOUT_FULFILLED,
  SUBMIT_REGISTRATION_FULFILLED,
  SUBMIT_REGISTRATION_REJECTED,
  CHECK_SSO_FULFILLED,
  UPDATE_CURRENT_USER_PROFILE_FULFILLED,
  UPDATE_CURRENT_USER_PROFILE_REJECTED,
  UPDATE_PROFILE_IMAGE_FULFILLED,
  REMOVE_PROFILE_IMAGE_FULFILLED,
  UPDATE_LOGO_IMAGE_FULFILLED,
  FETCH_COMMUNICATION_SETTINGS_FULFILLED,
  UPDATE_COMMUNICATION_SETTINGS_FULFILLED,
  FETCH_NOTIFICATION_SETTINGS_FULFILLED,
  UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
  FETCH_ORGANIZATION_SETTINGS_FULFILLED,
  UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  FETCH_NOTIFICATION_TYPES_FULFILLED,
  UPDATE_ORGANIZATION_SETTINGS_START,
  UPDATE_ORGANIZATION_SETTINGS_REJECTED,
  GET_CUSTOM_LOGIN_DATA_FULFILLED,
  GET_CUSTOM_LOGIN_DATA_REJECTED,
  UPDATE_BACKGROUND_IMAGE_FULFILLED,
} from './actionTypes';

const initialState = {
  currentUser: {},
  currentUserUpdateError: '',
  customLoginData: {},
  customLoginDataError: null,
  registration: {},
  registrationError: {},
  isTokenRefreshed: false,
  ssoData: {},
  organizationSettings: {},
  organizationSettingsErrorMessage: '',
  notificationTypes: {},
  notificationSettings: {},
  communicationSettings: {},
  accountSettings: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // case FETCH_USER_START: {
    //   return {
    //     ...state,
    //     isLoginLoading: true,
    //   };
    // }
    case FETCH_USER_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case GET_CUSTOM_LOGIN_DATA_FULFILLED: {
      return {
        ...state,
        customLoginData: action.payload,
      };
    }
    case GET_CUSTOM_LOGIN_DATA_REJECTED: {
      return {
        ...state,
        customLoginDataError: action.payload.response.headers.message,
      };
    }
    case FETCH_USER_DATA_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case UPDATE_CURRENT_USER_PROFILE_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
        currentUserUpdateError: '',
        // accountSettings: action.payload,
      };
    }
    case UPDATE_CURRENT_USER_PROFILE_REJECTED: {
      return {
        ...state,
        currentUserUpdateError: action.payload,
        // accountSettings: action.payload,
      };
    }
    case FETCH_COMMUNICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        communicationSettings: action.payload,
        // currentUser: { ...state.currentUser, userSettings: action.payload },
      };
    }
    case UPDATE_COMMUNICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        communicationSettings: action.payload,
        // currentUser: { ...state.currentUser, userSettings: action.payload },
      };
    }
    case FETCH_NOTIFICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        notificationSettings: action.payload,
      };
    }
    case FETCH_NOTIFICATION_TYPES_FULFILLED: {
      console.log('notification Types from API:', action.payload);

      const { notificationTypesArray } = action.payload;
      const notificationTypeGroups = notificationTypesArray?.reduce((group, notificationTypeObj) => {
        const { notificationGroupName } = notificationTypeObj;
        group[notificationGroupName] = group[notificationGroupName] ?? [];
        group[notificationGroupName].push(notificationTypeObj);
        return group;
      }, {});

      return {
        ...state,
        notificationTypes: action.payload,
        notificationTypeGroups,
      };
    }
    case UPDATE_NOTIFICATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        notificationSettings: action.payload,
      };
    }
    case FETCH_ORGANIZATION_SETTINGS_FULFILLED: {
      return {
        ...state,
        organizationSettings: action.payload,
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS_START: {
      return {
        ...state,
        organizationSettingsErrorMessage: '',
      };
    }
    case UPDATE_ORGANIZATION_SETTINGS_FULFILLED: {
      const {
        primaryColor,
        secondaryColor,
        accentColor,
        useLightTextColor,
        useLightSecondaryTextColor,
        useLightAccentTextColor,
      } = action.payload.skin;

      return update(
        state, {
          organizationSettings: {
            $set: action.payload,
          },
          organizationSettingsErrorMessage: {
            $set: '',
          },
          currentUser: {
            userSkin: {
              primary: {
                $set: primaryColor,
              },
              secondary: {
                $set: secondaryColor,
              },
              accent: {
                $set: accentColor,
              },
              darkTheme: {
                $set: useLightTextColor,
              },
              accentDarkTheme: {
                $set: useLightAccentTextColor,
              },
              secondaryDarkTheme: {
                $set: useLightSecondaryTextColor,
              },
            },
          },
        },
      );
    }
    case UPDATE_ORGANIZATION_SETTINGS_REJECTED: {
      return {
        ...state,
        organizationSettingsErrorMessage: action.payload,
      };
    }
    case UPDATE_PROFILE_IMAGE_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case REMOVE_PROFILE_IMAGE_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
      };
    }
    case UPDATE_LOGO_IMAGE_FULFILLED: {
      const newLogo = action.payload.skin.logo;
      return {
        ...state,
        organizationSettings: action.payload,
        currentUser: {
          ...state.currentUser,
          userSkin: {
            ...state.currentUser.userSkin,
            logo: newLogo,
          },
        },
      };
    }
    case UPDATE_BACKGROUND_IMAGE_FULFILLED: {
      return {
        ...state,
        organizationSettings: action.payload,
      };
    }
    case SUBMIT_LOGOUT_FULFILLED: {
      return {
        ...state,
        currentUser: {},
      };
    }
    case CLEAR_USER: {
      return {
        ...state,
        currentUser: {},
      };
    }
    case SUBMIT_REGISTRATION_FULFILLED: {
      return {
        ...state,
        registration: action.payload,
        registrationError: {},
      };
    }
    case SUBMIT_REGISTRATION_REJECTED: {
      return {
        ...state,
        registrationError: action.payload.response.headers,
        isTokenRefreshed: true,
      };
    }
    case CHECK_SSO_FULFILLED: {
      return {
        ...state,
        ssoData: action.payload,
      };
    }
    // case FETCH_USER_REJECTED: {
    //   return {
    //     ...state,
    //     isLoginLoading: false,
    //     isAuthenticated: false,
    //   };
    // }
    default: {
      return {
        ...state,
      };
    }
  }
}
