import React from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

import ScheduledWorkoutsChartLegend from './ScheduledWorkoutsChartLegend';

const Outer = styled('div')`
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    @media screen and (max-width : 1224px) {
      margin-top: 30px;
      margin-left: 0px;
      width: 100%;
    }
    @media screen and (min-width : 1225px) {
      margin-top: 0px;
      margin-left: 50px;
    }
`;

const EmptyDiv = styled('div')`
`;

const TitleContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Title = styled('div')`
    padding-top: 15px;
    font-size: 14px;
    font-weight: 900;
    font-family: Nunito Sans;
    letter-spacing: 1.18px;
`;

const Description = styled('div')`
    font-size: 12px;
    padding-top: 5px;
    text-align: start;
`;

const ChartContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const Placeholder = styled('div')`
    height: 200px;
    width: 250px;
`;

const PieChartLoading = () => (
  <Outer>
    <TitleContainer>
      <Title>SCHEDULED WORKOUTS</Title>
      <Description>
        Chart of next scheduled workout for selected athletes into four date ranges. Click a section of the chart to filter table below.
      </Description>
    </TitleContainer>
    <ChartContainer>
      <Shimmer>
        <Placeholder />
      </Shimmer>
      <ScheduledWorkoutsChartLegend />
    </ChartContainer>
    <EmptyDiv />
  </Outer>
);

export default PieChartLoading;
