import {
  FETCH_SELECTIONS_FULFILLED,
  SEARCH_SELECTION,
  FETCH_EXERCISES_FULFILLED,
  FETCH_MAXREPORT_FULFILLED,
  FETCH_MAXREPORT_REJECTED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED,
  SAVE_SIDEBAR_FILTER,
  CLEAR_REPORT,
  FETCH_ACTIVITY_REPORT_FULFILLED,
  FETCH_ACTIVITY_REPORT_REJECTED,
  SET_LATEST_ACTIVITY_FILTER,
  SET_SCHEDULED_WORKOUT_FILTER,
  SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER,
  SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE,
  SET_ATHLETES_IN_CLICKED_ZONE,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_EVALUATIONS_REJECTED,
  FETCH_EVALUATION_REPORT_GA_FULFILLED,
  FETCH_EVALUATION_REPORT_GA_REJECTED,
  FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED,
  FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED,
  RESET_EVALUATION_REPORT_GA_ERROR,

  FETCH_OPT_OUT_REPORT_FULFILLED,
  FETCH_RESULTS_REPORT_FULFILLED,
  FETCH_RESULTS_REPORT_START,
  FETCH_COMPARISON_REPORT_FULFILLED,
  FETCH_COMPARISON_REPORT_REJECTED,
  SET_COMPARISON_REPORT_ERROR,
  RESET_COMPARISON_REPORT_ERROR,
  REPORT_READY_FOR_PRINT,
} from './actionTypes';

const initialState = {
  filterSelections: [],
  selectionSearch: '',
  exercises: [],
  sidebarFilter: {},
  maxReport: {},
  maxReportError: {},
  maxReportForm: {},
  /** questionnaire state start */
  questionnairesForAccount: [],
  questionsForAccount: [],
  questionnaireReport: {},
  questionnaireReportError: {},
  questionnaireReportForm: {},
  currentQuestionInQuestionnairePicker: '',
  activeReduxModalForQuestionnaireAthleteZone: '',
  athletesInClickedZone: [],
  /** questionnaire state end */
  rawDataReport: {},
  rawDataReportForm: {},
  rawDataReportError: {},
  activityReport: {},
  activityReportForm: {},
  activityReportError: {},
  latestActivityFilter: null,
  scheduledWorkoutFilter: null,
  /** evaluation state start */
  evaluationReportGA: null,
  evaluationReportGAError: null,
  evaluationReportForm: null,
  evaluationReportOverview: null,
  evaluationReportOverviewError: null,
  evaluations: [],
  evaluationsError: null,
  wRORReport: [],
  /** evaluation state end */
  /** comparison state start */
  comparisonReport: {},
  comparisonReportError: {},
  comparisonReportForm: {},
  /** comparison state end */
  reportReadyForPrint: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SELECTIONS_FULFILLED: {
      return {
        ...state,
        filterSelections: action.payload,
      };
    }
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        exercises: action.payload,
      };
    }
    case FETCH_MAXREPORT_FULFILLED: {
      return {
        ...state,
        maxReport: action.payload,
        maxReportForm: action.payload.values,
        maxReportError: {},
      };
    }
    case FETCH_MAXREPORT_REJECTED: {
      return {
        ...state,
        maxReportError: action.payload.response.headers,
      };
    }
    case FETCH_OPT_OUT_REPORT_FULFILLED: {
      return {
        ...state,
        wRORReport: action.payload.data,
        optOutReportError: {},
        resultsReportForm: action.payload.values,
      };
    }
    case FETCH_RESULTS_REPORT_START: {
      return {
        ...state,
        wRORReport: [],
        resultsReportError: {},
      };
    }
    case FETCH_RESULTS_REPORT_FULFILLED: {
      return {
        ...state,
        wRORReport: action.payload.data,
        resultsReportError: {},
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        questionnairesForAccount: action.payload,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        questionsForAccount: action.payload,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_FULFILLED: {
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReport: action.payload,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
        */
        questionnaireReportForm: action.payload.values,
        questionnaireReportError: {},
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_REJECTED: {
      return {
        ...state,
        questionnaireReportError: action.payload.response.headers,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_FULFILLED: {
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReport: action.payload,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
        */
        questionnaireReportForm: action.payload.values,
        questionnaireReportError: {},
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_REJECTED: {
      return {
        ...state,
        questionnaireReportError: action.payload.response.headers,
      };
    }
    case SEARCH_SELECTION: {
      return {
        ...state,
        selectionSearch: action.payload,
      };
    }
    case SAVE_SIDEBAR_FILTER: {
      return {
        ...state,
        sidebarFilter: action.payload,
      };
    }
    case CLEAR_REPORT: {
      return {
        ...state,
        maxReport: {},
        maxReportError: {},
        maxReportForm: {},
        // sidebarFilter: {},
        questionnaireReport: {},
        questionnaireReportError: {},
        questionnaireReportForm: {},
        latestActivityFilter: null,
        scheduledWorkoutFilter: null,
        wRORReport: [],
        comparisonReport: {},
        comparisonReportError: {},
        comparisonReportForm: {},
      };
    }
    case FETCH_ACTIVITY_REPORT_FULFILLED: {
      return {
        ...state,
        activityReport: action.payload,
        activityReportForm: action.payload.values,
        activityReportError: {},
      };
    }
    case FETCH_ACTIVITY_REPORT_REJECTED: {
      return {
        ...state,
        activityReportError: action.payload.response.headers,
      };
    }
    case SET_LATEST_ACTIVITY_FILTER: {
      return {
        ...state,
        latestActivityFilter: action.payload,
      };
    }
    case SET_CURRENT_QUESTION_IN_QUESTIONNAIRE_PICKER: {
      return {
        ...state,
        currentQuestionInQuestionnairePicker: action.payload,
      };
    }
    case SET_SCHEDULED_WORKOUT_FILTER: {
      return {
        ...state,
        scheduledWorkoutFilter: action.payload,
      };
    }
    case SET_ACTIVE_REDUX_MODAL_FOR_QUESTIONNAIRE_ATHLETE_ZONE: {
      return {
        ...state,
        activeModal: action.payload,
      };
    }
    case SET_ATHLETES_IN_CLICKED_ZONE: {
      return {
        ...state,
        athletesInClickedZone: action.payload,
      };
    }
    case FETCH_EVALUATIONS_FULFILLED: {
      return {
        ...state,
        evaluations: action.payload,
        evaluationsError: null,
      };
    }
    case FETCH_EVALUATIONS_REJECTED: {
      return {
        ...state,
        evaluations: [],
        evaluationsError: action.payload,
      };
    }
    case FETCH_EVALUATION_REPORT_GA_FULFILLED: {
      return {
        ...state,
        /** evaluationReportGA reflects the return values sent by the api endpoint */
        evaluationReportGA: action.payload.data,
        /** evaluationReportForm reflects the values sent in the form
         * we can use this for initial values in formik
        */
        evaluationReportForm: action.payload.values,
        evaluationReportGAError: null,
      };
    }
    case FETCH_EVALUATION_REPORT_GA_REJECTED: {
      return {
        ...state,
        evaluationReportGAError: action.payload,
      };
    }
    case RESET_EVALUATION_REPORT_GA_ERROR: {
      return {
        ...state,
        evaluationReportGAError: null,
      };
    }
    case FETCH_EVALUATION_REPORT_OVERVIEW_FULFILLED: {
      return {
        ...state,
        evaluationReportOverview: action.payload,
        evaluationReportOverviewError: null,
      };
    }
    case FETCH_EVALUATION_REPORT_OVERVIEW_REJECTED: {
      return {
        ...state,
        evaluationReportOverviewError: action.payload,
      };
    }
    case FETCH_COMPARISON_REPORT_FULFILLED: {
      return {
        ...state,
        comparisonReport: action.payload,
        comparisonReportForm: action.payload.values,
        comparisonReportError: {},
      };
    }
    case FETCH_COMPARISON_REPORT_REJECTED: {
      return {
        ...state,
        comparisonReportError: {
          message: action.payload,
        },
      };
    }
    case SET_COMPARISON_REPORT_ERROR: {
      return {
        ...state,
        comparisonReportError: {
          message: action.payload,
        },
      };
    }
    case RESET_COMPARISON_REPORT_ERROR: {
      return {
        ...state,
        comparisonReportError: {},
      };
    }
    case REPORT_READY_FOR_PRINT: {
      return {
        ...state,
        reportReadyForPrint: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
