import {
  FETCH_REPORTABLE_GROUPS_START,
  FETCH_REPORTABLE_GROUPS_FULFILLED,
  FETCH_REPORTABLE_GROUPS_REJECTED,
  FETCH_ATHLETE_PARTICIPATION_START,
  FETCH_ATHLETE_PARTICIPATION_FULFILLED,
  FETCH_ATHLETE_PARTICIPATION_REJECTED,
  FETCH_ATHLETES_TO_WATCH_START,
  FETCH_ATHLETES_TO_WATCH_FULFILLED,
  FETCH_ATHLETES_TO_WATCH_REJECTED,
  FETCH_ATHLETE_AVERAGES_START,
  FETCH_ATHLETE_AVERAGES_FULFILLED,
  FETCH_ATHLETE_AVERAGES_REJECTED,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED,
  DOWNLOAD_WEARABLES_REPORT_START,
  DOWNLOAD_WEARABLES_REPORT_FULFILLED,
  DOWNLOAD_WEARABLES_REPORT_REJECTED,
} from './actionTypes';

const initialState = {
  isReportableGroupsLoading: false,
  isAthleteParticipationLoading: false,
  isAthletesToWatchLoading: false,
  isAthleteAveragesLoading: false,
  isWearablesReportLoading: false,
  isWearablesReportcsvLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_START: {
      return {
        ...state,
        isReportableGroupsLoading: true,
      };
    }
    case FETCH_REPORTABLE_GROUPS_FULFILLED: {
      return {
        ...state,
        isReportableGroupsLoading: false,
      };
    }
    case FETCH_REPORTABLE_GROUPS_REJECTED: {
      return {
        ...state,
        isReportableGroupsLoading: false,
      };
    }
    case FETCH_ATHLETE_PARTICIPATION_START: {
      return {
        ...state,
        isAthleteParticipationLoading: true,
      };
    }
    case FETCH_ATHLETE_PARTICIPATION_FULFILLED: {
      return {
        ...state,
        isAthleteParticipationLoading: false,
      };
    }
    case FETCH_ATHLETE_PARTICIPATION_REJECTED: {
      return {
        ...state,
        isAthleteParticipationLoading: false,
      };
    }
    case FETCH_ATHLETES_TO_WATCH_START: {
      return {
        ...state,
        isAthletesToWatchLoading: true,
      };
    }
    case FETCH_ATHLETES_TO_WATCH_FULFILLED: {
      return {
        ...state,
        isAthletesToWatchLoading: false,
      };
    }
    case FETCH_ATHLETES_TO_WATCH_REJECTED: {
      return {
        ...state,
        isAthletesToWatchLoading: false,
      };
    }
    case FETCH_ATHLETE_AVERAGES_START: {
      return {
        ...state,
        isAthleteAveragesLoading: true,
      };
    }
    case FETCH_ATHLETE_AVERAGES_FULFILLED: {
      return {
        ...state,
        isAthleteAveragesLoading: false,
      };
    }
    case FETCH_ATHLETE_AVERAGES_REJECTED: {
      return {
        ...state,
        isAthleteAveragesLoading: false,
      };
    }
    case FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START: {
      return {
        ...state,
        isWearablesReportLoading: true,
      };
    }
    case FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED: {
      return {
        ...state,
        isWearablesReportLoading: false,
      };
    }
    case FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED: {
      return {
        ...state,
        isWearablesReportLoading: false,
      };
    }
    case DOWNLOAD_WEARABLES_REPORT_START: {
      return {
        ...state,
        isWearablesReportcsvLoading: true,
      };
    }
    case DOWNLOAD_WEARABLES_REPORT_FULFILLED: {
      return {
        ...state,
        isWearablesReportcsvLoading: false,
      };
    }
    case DOWNLOAD_WEARABLES_REPORT_REJECTED: {
      return {
        ...state,
        isWearablesReportcsvLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
