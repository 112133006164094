/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setIsUpgradeModalShowing } from '../../ducks/sportsScienceActions';
import UpgradeModal from './UpgradeModal';

const UpgradeModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const BillingModalContainer = () => {
  const dispatch = useDispatch();
  const isUpgradeModalShowing = useSelector(
    (state) => state.sportsScience.data.isUpgradeModalShowing,
  );

  return (
    <>
      <UpgradeModal />
      <UpgradeModalContainerOverlay
        isActive={isUpgradeModalShowing}
        onClick={
          () => dispatch(setIsUpgradeModalShowing(false))
          }
      />
    </>
  );
};

BillingModalContainer.propTypes = { };

export default BillingModalContainer;
