/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const TotalWeekBreakdownOverviewGrid = ({ containerWidth }) => {
  const [individualGridContainerWidth, setIndividualGridContainerWidth] = useState(0);
  const maxWidthForText = containerWidth < 400 ? individualGridContainerWidth * 0.45 : individualGridContainerWidth * 0.6;
  const sets = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.sets);
  const reps = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.reps);
  const tonnage = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.tonnage);
  const duration = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.duration);

  const theme = useTheme();

  const calculateFontSize = (textString, baseFontSize) => {
    const maxCharacters = 4; // Set the maximum number of characters before decreasing font size
    const fontSizeIncrement = 4; // Set the font size decrement for each character beyond the limit

    const charactersCount = textString.length;
    let fontSize = baseFontSize;

    if (charactersCount > maxCharacters) {
      const extraCharacters = charactersCount - maxCharacters;
      fontSize -= extraCharacters * fontSizeIncrement;
    }
    if (fontSize < 12 && baseFontSize > 22) {
      fontSize = 12;
    }
    if (fontSize < 12 && baseFontSize === 22) {
      fontSize = 10;
    }
    return fontSize;
  };

  /** styles */
  const PercentageBreakDownContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 650px;
  padding: 10px;
`;
  const InnerContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 50%;
  padding: ${containerWidth < 500 ? '10px' : '20px'};
`;

  const Row = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  `;

  const NumberContainer = styled('div')`
  background-color: ${containerWidth < 600 ? '' : '#F3F3F3'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 53px;
`;
  const IconAndNumberContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  `;
  const IconAndNumberWrapper = styled('div')`
  display: flex;
  flex: 1;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  `;

  const NumberAndTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
  `;

  const IconAndTitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  min-height: 40px;
  gap: 8px;
  `;

  const TextContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 33px;
  min-height: 33px;
  text-overflow: hidden; 
  `;

  return (
    <PercentageBreakDownContainer containerWidth={containerWidth}>
      <Row>
        <InnerContainer style={{
          borderRight: 'solid', borderRightWidth: 2, borderBottom: 'solid', borderBottomWidth: 2, borderColor: '#F2F1F1',
        }}
        >
          <ResizeObserver
            onResize={(rect) => {
              setIndividualGridContainerWidth(rect?.width);
            }}
          />
          <IconAndTitleContainer>
            <Text fontSize={containerWidth < 528 ? '12px' : '16px'} fontWeight={700} color='rgba(164, 162, 162, 0.60)'>Total Sets Completed</Text>
            <IcomoonReact
              iconSet={iconSet}
              size={containerWidth < 400 ? 16 : 26}
              icon='list'
              color='#A4A2A2'
            />
          </IconAndTitleContainer>
          <IconAndNumberWrapper>
            <IconAndNumberContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon='play-filled'
                color={sets?.difference < 0 ? '#FF3737' : sets?.difference === 0 ? theme.colors.yellow : theme.colors.green}
                style={{ transform: sets?.difference < 0 ? 'rotate(90deg)' : sets?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
              />
              <NumberAndTextContainer>
                <NumberContainer>
                  <Text style={{ maxWidth: maxWidthForText, overflow: 'auto' }} fontSize={containerWidth < 400 ? calculateFontSize(sets?.currentWeek.toString(), 22) : containerWidth < 1052 ? calculateFontSize(sets?.currentWeek.toString(), 32) : calculateFontSize(sets?.currentWeek.toString(), 44)} fontWeight={800}>{sets?.currentWeek}</Text>
                </NumberContainer>
              </NumberAndTextContainer>
            </IconAndNumberContainer>
            <TextContainer>
              <Text style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} fontSize='11px' fontWeight={700} color='rgba(164, 162, 162, 0.60)'>{`Average: ${sets?.average}`}</Text>
            </TextContainer>
          </IconAndNumberWrapper>
        </InnerContainer>
        <InnerContainer style={{ borderBottom: 'solid', borderBottomWidth: 2, borderColor: '#F2F1F1' }}>
          <IconAndTitleContainer>
            <Text fontSize={containerWidth < 528 ? '12px' : '16px'} fontWeight={700} color='rgba(164, 162, 162, 0.60)'>Total Reps</Text>
            <IcomoonReact
              iconSet={iconSet}
              size={containerWidth < 400 ? 16 : 26}
              icon='muscle'
              color='#A4A2A2'
            />
          </IconAndTitleContainer>
          <IconAndNumberWrapper>
            <IconAndNumberContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon='play-filled'
                color={reps?.difference < 0 ? '#FF3737' : reps?.difference === 0 ? theme.colors.yellow : theme.colors.green}
                style={{ transform: reps?.difference < 0 ? 'rotate(90deg)' : reps?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
              />
              <NumberAndTextContainer>
                <NumberContainer>
                  <Text style={{ maxWidth: maxWidthForText, overflow: 'auto' }} fontSize={containerWidth < 400 ? calculateFontSize(reps?.currentWeek.toString(), 22) : containerWidth < 1052 ? calculateFontSize(reps?.currentWeek.toString(), 32) : calculateFontSize(reps?.currentWeek.toString(), 44)} fontWeight={800}>{reps?.currentWeek}</Text>
                </NumberContainer>
              </NumberAndTextContainer>
            </IconAndNumberContainer>
            <TextContainer>
              <Text style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} fontSize='11px' fontWeight={700} color='rgba(164, 162, 162, 0.60)'>{`Average: ${reps?.average}`}</Text>
            </TextContainer>
          </IconAndNumberWrapper>
        </InnerContainer>
      </Row>
      <Row>
        <InnerContainer style={{ borderRight: 'solid', borderRightWidth: 2, borderColor: '#F2F1F1' }}>
          <IconAndTitleContainer>
            <Text fontSize={containerWidth < 528 ? '12px' : '16px'} fontWeight={700} color='rgba(164, 162, 162, 0.60)'>Total Tonage</Text>
            <IcomoonReact
              iconSet={iconSet}
              size={containerWidth < 400 ? 16 : 26}
              icon='lift'
              color='#A4A2A2'
            />
          </IconAndTitleContainer>
          <IconAndNumberWrapper>
            <IconAndNumberContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon='play-filled'
                color={tonnage?.difference < 0 ? '#FF3737' : tonnage?.difference === 0 ? theme.colors.yellow : theme.colors.green}
                style={{ transform: tonnage?.difference < 0 ? 'rotate(90deg)' : tonnage?.difference === 0 ? 'rotate(-0deg)' : 'rotate(-90deg)' }}
              />
              <NumberAndTextContainer>
                <NumberContainer>
                  <Text style={{ maxWidth: maxWidthForText, overflow: 'auto' }} fontSize={containerWidth < 400 ? calculateFontSize(tonnage.currentWeek.toString(), 22) : containerWidth < 1052 ? calculateFontSize(tonnage.currentWeek.toString(), 32) : calculateFontSize(tonnage.currentWeek.toString(), 44)} fontWeight={800}>{tonnage.currentWeek}</Text>
                </NumberContainer>
              </NumberAndTextContainer>
            </IconAndNumberContainer>
            <TextContainer>
              <Text style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} fontSize='11px' fontWeight={700} color='rgba(164, 162, 162, 0.60)'>{`Average: ${tonnage?.average}`}</Text>
            </TextContainer>
          </IconAndNumberWrapper>
        </InnerContainer>
        <InnerContainer>
          <IconAndTitleContainer>
            <Text fontSize={containerWidth < 528 ? '12px' : '16px'} fontWeight={700} color='rgba(164, 162, 162, 0.60)'>Total Session Duration</Text>
            <IcomoonReact
              iconSet={iconSet}
              size={containerWidth < 400 ? 16 : 26}
              icon='clock'
              color='#A4A2A2'
            />
          </IconAndTitleContainer>
          <IconAndNumberWrapper>
            <IconAndNumberContainer>
              <NumberAndTextContainer>
                <NumberContainer>
                  <Text style={{ maxWidth: maxWidthForText, overflow: 'auto' }} fontSize={containerWidth < 400 ? calculateFontSize(duration?.currentWeek?.formattedTime.toString(), 22) : containerWidth < 1052 ? calculateFontSize(duration?.currentWeek?.formattedTime.toString(), 32) : calculateFontSize(duration?.currentWeek?.formattedTime.toString(), 44)} fontWeight={800}>{duration?.currentWeek?.formattedTime}</Text>
                </NumberContainer>
              </NumberAndTextContainer>
            </IconAndNumberContainer>
            <TextContainer>
              <Text style={{ maxWidth: 100, overflow: 'hidden', textOverflow: 'ellipsis' }} fontSize='11px' fontWeight={700} color='rgba(164, 162, 162, 0.60)'>{`Average: ${duration?.average}`}</Text>
            </TextContainer>
          </IconAndNumberWrapper>
        </InnerContainer>
      </Row>
    </PercentageBreakDownContainer>
  );
};

TotalWeekBreakdownOverviewGrid.propTypes = {
  containerWidth: PropTypes.number.isRequired,
};

export default TotalWeekBreakdownOverviewGrid;
