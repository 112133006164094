import React, { Component } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import { matchSorter } from 'match-sorter';
import Athlete from './Athlete';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
// import { ClassNames } from '@emotion/core';

const AthletePickerContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const AthleteRenderer = ({ data, index, style }) => {
  const { filteredData, handleActive } = data;
  const athlete = filteredData[index];

  const image = optimizeImage(
    athlete.pic,
    {
      resize: {
        width: 70,
        height: 70,
        fit: 'cover',
      },
    },
  );

  return (
    <Athlete
      key={athlete.id}
      id={athlete.id}
      name={`${athlete.first} ${athlete.last}`}
      avatar={image}
      pinEnabled={athlete.pin_enabled}
      listStyle={style}
      handleActive={handleActive}
    />
  );
};


class AthletePicker extends Component {
  constructor(props) {
    super(props);
    this.handleActive = this.handleActive.bind(this);
  }

  handleActive(name) {
    const { handleActive } = this.props;
    handleActive(name);
  }

  filterGroups(groupFilter) {
    const { data } = this.props;
    return matchSorter(data, groupFilter, {
      keys: [
        'groups.*.id',
      ],
      sorter: (rankedItems) => rankedItems,
    },
    {
      threshold: matchSorter.rankings.EXACT,
    });
  }

  render() {
    const { panelDimensions, isDimensionSet } = this.props;
    let { groupFilter } = this.props;

    // Check if older string style group filters are saved, reset to object with ID.
    if (typeof groupFilter === 'string') {
      groupFilter = { id: '', name: 'All Athletes' };
    }

    const filteredData = this.filterGroups(groupFilter.id);
    const { handleActive } = this;

    return (
      <AthletePickerContainer>
        {isDimensionSet && (
          <List
            height={panelDimensions && panelDimensions.height - 82}
            itemData={{
              filteredData,
              handleActive,
            }}
            itemCount={filteredData.length}
            itemSize={55}
            width={panelDimensions && panelDimensions.width}
          >
            {AthleteRenderer}
          </List>
        )}
      </AthletePickerContainer>
    );
  }
}

AthleteRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
};

AthletePicker.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  groupFilter: PropTypes.string.isRequired,
  isDimensionSet: PropTypes.bool.isRequired,
  handleActive: PropTypes.func.isRequired,
  panelDimensions: PropTypes.instanceOf(Object).isRequired,
};

export default AthletePicker;
