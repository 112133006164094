import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik } from 'formik';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { ColorLightGray, ColorLightestGray } from '../../../../shared/GlobalStyles';

const PinContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const PinButton = styled('button')`
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
  background-color: white;
  border: 0;
  color: black;
  font-size: 25px;
  line-height: 50px;
  border-radius: 100%;
  opacity: 1;
  outline: 0;
  border: 1px solid ${ColorLightGray};
  margin: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${props => (props.invisible ? 'opacity: 0;' : '')}
  :active {
    background-color: ${ColorLightestGray}
  }
`;

const PinCloseButton = styled('button')`
  position: absolute;
  border: 0px;
  top: 15px;
  left: 15px;
  transform: rotate(-90deg);
`;

const PinGrid = styled('div')`
  display: block;
  width: 270px;

`;

const PinDisplay = styled('div')`
  display: flex;
  padding: 15px 30px 30px 30px;
`;

const PinCircle = styled('div')`
  height: 10px;
  width: 10px;
  border: 2px solid ${ColorLightGray};
  background-color: ${props => (props.active ? ColorLightGray : 'transparent')};
  border-radius: 100%;
  position: relative;
  margin-right: 20px;
  &:last-of-type {
    margin-right: 0px;
  }
`;

const PinPad = ({
  athleteId,
  closePinPad,
  isPinLoading,
  isPinRejected,
  isPinPadOpen,
  namespace,
  submitPinCode,
}) => {
  const handlePinClick = (props, number) => {
    const stringPin = String(props.values.pin_number);
    const addedToPin = stringPin.concat(`${number}`);

    if (stringPin.length === 3) {
      props.setFieldValue('pin_number', parseInt(addedToPin, 10));
      setTimeout(() => {
        props.submitForm();
      }, 400);
      setTimeout(() => {
        props.setFieldValue('pin_number', '');
      }, 1200);
    } else if (stringPin.length === 4) {
      console.log('Max Number of Pin Digits');
    } else {
      props.setFieldValue('pin_number', parseInt(addedToPin, 10));
    }
  };

  const handleClosePinPad = () => {
    closePinPad(namespace);
  };

  const handleDeleteClick = (props) => {
    const stringPin = String(props.values.pin_number);
    const subtractedPin = stringPin.length <= 1 ? '' : stringPin.substring(0, stringPin.length - 1);
    props.setFieldValue('pin_number', subtractedPin.length > 0 ? parseInt(subtractedPin, 10) : subtractedPin);
  };

  const pinInfoView = isPinRejected ? (
    <p>PIN Invalid</p>
  ) : (
    <p>Please enter your PIN code</p>
  );

  return (
    <Formik
      initialValues={{
        user_id: athleteId,
        pin_number: '',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          submitPinCode(namespace, values);
          setSubmitting(false);
        }, 400);
      }}
      render={props => (
        <Fragment>
          <PinCloseButton
            onClick={handleClosePinPad}
          >
            <IcomoonReact
              iconSet={iconSet}
              color='#444444'
              size={20}
              icon='alt-up-arrow'
            />
          </PinCloseButton>
          <PinContainer className='pin-container'>
            {isPinLoading ? (
              <p>Verifying PIN code...</p>
            ) : (
              pinInfoView
            )}
            <PinDisplay>
              <PinCircle active={props.values.pin_number.toString().length >= 1} />
              <PinCircle active={props.values.pin_number.toString().length >= 2} />
              <PinCircle active={props.values.pin_number.toString().length >= 3} />
              <PinCircle active={props.values.pin_number.toString().length >= 4} />
            </PinDisplay>
            <PinGrid>
              <PinButton onClick={() => handlePinClick(props, 1)}>1</PinButton>
              <PinButton onClick={() => handlePinClick(props, 2)}>2</PinButton>
              <PinButton onClick={() => handlePinClick(props, 3)}>3</PinButton>
              <PinButton onClick={() => handlePinClick(props, 4)}>4</PinButton>
              <PinButton onClick={() => handlePinClick(props, 5)}>5</PinButton>
              <PinButton onClick={() => handlePinClick(props, 6)}>6</PinButton>
              <PinButton onClick={() => handlePinClick(props, 7)}>7</PinButton>
              <PinButton onClick={() => handlePinClick(props, 8)}>8</PinButton>
              <PinButton onClick={() => handlePinClick(props, 9)}>9</PinButton>
              <PinButton invisible disabled onClick={() => handlePinClick(props, 0)}>0</PinButton>
              <PinButton onClick={() => handlePinClick(props, 0)}>0</PinButton>
              <PinButton disabled={props.values.pin_number.toString().length === 0} onClick={() => handleDeleteClick(props)}>
                <IcomoonReact
                  iconSet={iconSet}
                  color='#444444'
                  size={22}
                  icon='remove'
                />
              </PinButton>
            </PinGrid>
            {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
          </PinContainer>
        </Fragment>
      )}
    />
  );
};

PinPad.propTypes = {
  athleteId: PropTypes.number.isRequired,
  isPinLoading: PropTypes.bool.isRequired,
  isPinRejected: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  submitPinCode: PropTypes.func.isRequired,
};

export default PinPad;
