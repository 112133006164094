import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  LabelList,
} from 'recharts';
import moment from 'moment';

const ChartScrollContainer = styled('div')`
  width: 100%;
  height: ${(props) => (props.isLoading ? '355px' : '90%')};
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : 'none')};

  .recharts-tooltip-wrapper {
    z-index: 9999;
  }

  opacity: ${(props) => (props.isOpaque ? 0.3 : 1)};
  transition: opacity 0.4s ease-in-out;
`;

const CustomTooltipContainer = styled('div')`
  border-radius: 4px;
  border: 2px solid;
  border-color: ${(props) => props.color};
  background-color: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
  padding: 8px;

  span {
    overflow: hidden;
    color: #444;
    font-feature-settings: 'liga' off, 'clig' off;
    text-overflow: ellipsis;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;

// Custom Tooltip component for displaying habit name
const CustomTooltip = ({ hoveredBar }) => {
  if (!hoveredBar?.habitName) return null;
  return (
    <CustomTooltipContainer color={hoveredBar.color}>
      <span>{hoveredBar.habitName}</span>
    </CustomTooltipContainer>
  );
};

const HTOverviewChart = () => {
  const [hoveredBar, setHoveredBar] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);

  const overviewResults = useSelector((state) => state.habitTrackingDashboard.data.overviewResults);
  const selectedHabits = useSelector((state) => state.habitTrackingDashboard.data.selectedHabits);
  const isChartOpaque = useSelector((state) => state.habitTrackingDashboard.ui.isChartOpaque);

  // Update container width on window resize
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    handleResize(); // Initial call
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);

  // Function to format data for the chart
  const formatDataForChart = (overview) => {
    if (!overview || !overview.dateRanges) return [];

    // Map over date ranges and format data for the chart
    return overview.dateRanges
      .filter((range) => overview.rangeCompletionData[range])
      .map((range) => {
        const rangeData = overview.rangeCompletionData[range] || [];
        const formattedData = {
          date: `${moment(range.split(' - ')[0]).format('MM/DD')} - ${moment(range.split(' - ')[1]).format('MM/DD')}`,
        };

        // Map each habit and add habit value and color to the formatted data
        rangeData.forEach((habit) => {
          if (selectedHabits.includes(habit.habitId)) {
            formattedData[habit.habitName] = parseFloat(habit.value);
            formattedData[`${habit.habitName}_color`] = habit.habitCategoryColor;
          }
        });

        return formattedData;
      })
      .filter((data) => Object.keys(data).length > 1); // Ensure data contains habits
  };

  // Prepare data and calculate chart width based on the number of data points
  const chartData = formatDataForChart(overviewResults);
  const calculatedChartWidth = chartData.length * 350; // Dynamic width per data point
  const chartWidth = Math.max(calculatedChartWidth, containerWidth);

  // Event handlers for mouse interactions on bars
  const handleMouseEnter = (key, entry) => {
    setHoveredBar({
      habitName: key,
      date: entry.date,
      value: entry[key],
      color: entry[`${key}_color`],
    });
  };

  const handleMouseLeave = () => {
    setHoveredBar(null);
  };

  return (
    <ChartScrollContainer ref={containerRef} isBlurred={!chartData.length} isOpaque={isChartOpaque}>
      <ResponsiveContainer width={chartWidth} height='90%' minHeight={380}>
        <BarChart
          data={chartData.length > 0 ? chartData : [{ date: '' }]}
          barCategoryGap='15%'
        >
          <defs>
            {Object.keys(chartData[0] || {})
              .filter((key) => !key.includes('_color') && key !== 'date')
              .map((key, index) => (
                <linearGradient key={key} id={`gradient-${index}`} x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='0%' stopColor={chartData[0][`${key}_color`]} stopOpacity={0.9} />
                  <stop offset='100%' stopColor={chartData[0][`${key}_color`]} stopOpacity={0.55} />
                </linearGradient>
              ))}
          </defs>
          <CartesianGrid strokeDasharray='3 3' vertical={false} />
          <XAxis
            dataKey='date'
            angle={-30}
            height={70}
            textAnchor='end'
            tick={{ color: '#9E9E9E' }}
          />
          <YAxis
            label={{
              value: 'Completion (%)',
              fill: '#9E9E9E',
              angle: -90,
              position: 'insideLeft',
            }}
            width={60}
            domain={[0, 100]}
          />
          {/* Render each habit as a Bar with gradient */}
          {chartData.length > 0
            && Object.keys(chartData[0] || {})
              .filter((key) => !key.includes('_color') && key !== 'date')
              .map((key, index) => (
                <Bar
                  key={key}
                  name={key}
                  dataKey={key}
                  fill={`url(#gradient-${index})`}
                  isAnimationActive={false}
                  radius={[10, 10, 0, 0]}
                  minPointSize={5}
                  opacity={hoveredBar ? (hoveredBar.habitName === key ? 1 : 0.3) : 1}
                  onMouseEnter={(data) => handleMouseEnter(key, data)}
                  onMouseLeave={handleMouseLeave}
                >
                  <LabelList
                    dataKey={key}
                    position='insideBottom'
                    offset={30}
                    style={{ fill: '#000', fontSize: '14px' }}
                    formatter={(value) => (value > 0 ? `${value}%` : '0%')}
                    angle={270}
                  />
                </Bar>
              ))}
          <Tooltip
            content={<CustomTooltip hoveredBar={hoveredBar} />}
            cursor={{ fill: 'transparent' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartScrollContainer>
  );
};

export default HTOverviewChart;
