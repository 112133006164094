import update from 'immutability-helper';
import {
  FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED,
  FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED,
  FETCH_HABITS_FOR_ACCOUNT_FULFILLED,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_REPORTABLE_GROUPS_HT_FULFILLED,
  FETCH_REPORTABLE_GROUPS_HT_REJECTED,
  SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED,
  FETCH_ATHLETES_FULFILLED_HT,
  FETCH_ATHLETES_REJECTED_HT,
  CREATE_NEW_HABIT_FULFILLED,
  CREATE_NEW_HABIT_REJECTED,
  EDIT_HABIT_FULFILLED,
  FETCH_HABIT_FOR_ACCOUNT_FULFILLED,
  DELETE_HABIT_FULFILLED,
  CHECK_5_HABIT_MAX_FULFILLED,
  SET_ASSIGNMENT_CONFLICT_ACTION,
  SET_DELETE_MODAL_DATA,
  SET_GLOBAL_HABIT_FILTERS,
  SET_ATTENTION_ACTION,
  SET_EDIT_CATEGORY_INFO,
  CREATE_NEW_CATEGORY_FULFILLED,
  CREATE_NEW_CATEGORY_REJECTED,
  EDIT_CATEGORY_FULFILLED,
  DELETE_CATEGORY_FULFILLED,
} from './actionTypes';

const initialState = {
  reportableGroups: [],
  reportableGroupsError: null,
  habits: [],
  recentlyCreatedHabit: null,
  recentlyCreatedCategory: null,
  habitsError: null,
  categories: [],
  categoriesError: null,
  habitTrackingPageWrapperScrollTriggered: false,
  athletes: [],
  athletesError: null,
  editHabit: null,
  editCategoryInfo: null,
  usersWith5HabitMax: [],
  assignmentConflictAction: '',
  attentionAction: '',
  deleteModalData: null,
  globalHabitFilters: {
    categoryIds: null,
    groupIds: null,
    status: 1,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_HT_FULFILLED: {
      return {
        ...state,
        reportableGroups: action.payload,
      };
    }
    case FETCH_REPORTABLE_GROUPS_HT_REJECTED: {
      return {
        ...state,
        reportableGroups: [],
        reportableGroupsError: action.payload,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        habits: action.payload,
        habitsError: null,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        habits: [],
        habitsError: action.payload,
      };
    }
    case FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        categories: action.payload,
      };
    }
    case FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        categories: [],
        categoriesError: action.payload,
      };
    }
    case FETCH_ATHLETES_FULFILLED_HT: {
      return {
        ...state,
        athletes: action.payload,
      };
    }
    case FETCH_ATHLETES_REJECTED_HT: {
      return {
        ...state,
        athletesError: action.payload,
      };
    }
    case SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED: {
      return {
        ...state,
        habitTrackingPageWrapperScrollTriggered: action.payload,
      };
    }
    case SET_ASSIGNMENT_CONFLICT_ACTION: {
      return {
        ...state,
        assignmentConflictAction: action.payload,
      };
    }
    case SET_ATTENTION_ACTION: {
      return {
        ...state,
        attentionAction: action.payload,
      };
    }
    case CREATE_NEW_HABIT_FULFILLED: {
      return {
        ...state,
        recentlyCreatedHabit: action.payload,
      };
    }
    case CREATE_NEW_HABIT_REJECTED: {
      return {
        ...state,
        recentlyCreatedHabit: action.payload,
      };
    }
    case FETCH_HABIT_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        editHabit: action.payload,
      };
    }
    case EDIT_HABIT_FULFILLED: {
      const { id } = action.payload; /** Extract ID from the new data */
      const index = state.habits.findIndex((habit) => habit?.id === id);

      const updatedHabits = [...state.habits];
      updatedHabits[index] = { ...updatedHabits[index], ...action.payload };

      if (index === -1) {
        /** habit not found just return state (just in case) */
        return state;
      }
      return {
        ...state,
        habits: updatedHabits,
        editHabit: null,
      };
    }
    case DELETE_HABIT_FULFILLED: {
      const { id } = action.payload; /** Extract ID from the new data */
      const index = state.habits.findIndex((habit) => habit.id === id);
      return update(state, {
        habits: {
          $splice: [[index, 1]],
        },
      });
    }
    case CHECK_5_HABIT_MAX_FULFILLED: {
      return {
        ...state,
        usersWith5HabitMax: action.payload,
      };
    }
    case SET_DELETE_MODAL_DATA: {
      return {
        ...state,
        deleteModalData: action.payload,
      };
    }
    case SET_GLOBAL_HABIT_FILTERS: {
      return {
        ...state,
        globalHabitFilters: action.payload,
      };
    }
    case SET_EDIT_CATEGORY_INFO: {
      return {
        ...state,
        editCategoryInfo: action.payload,
      };
    }
    case CREATE_NEW_CATEGORY_FULFILLED: {
      return {
        ...state,
        recentlyCreatedCategory: action.payload,
      };
    }
    case CREATE_NEW_CATEGORY_REJECTED: {
      return {
        ...state,
        recentlyCreatedCategory: action.payload,
      };
    }
    case EDIT_CATEGORY_FULFILLED: {
      const { id } = action.payload; /** Extract ID from the new data */
      const index = state.categories.findIndex((category) => category?.id === id);

      const updatedCategories = [...state.categories];
      updatedCategories[index] = { ...updatedCategories[index], ...action.payload };

      if (index === -1) {
        /** category not found just return state (just in case) */
        return state;
      }
      return {
        ...state,
        categories: updatedCategories,
        editCategoryInfo: null,
      };
    }
    case DELETE_CATEGORY_FULFILLED: {
      const { id } = action.payload; /** Extract ID from the new data */
      const index = state.categories.findIndex((category) => category.id === id);
      return update(state, {
        categories: {
          $splice: [[index, 1]],
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
