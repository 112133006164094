import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const NoRecordWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const IconMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
`;

const NoConversationComponent = ({
  currentUser,
}) => (
  <NoRecordWrapper>
    {currentUser.userSettings !== undefined && (
      currentUser.userSettings.messaging !== 0 ? (
        <IconMessageContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={100}
            icon='message-bubbles'
            color='black'
          />
          <SubText
            style={{
              marginTop: '20px',
            }}
            fontSize={22}
          >
            Select a Conversation
          </SubText>
        </IconMessageContainer>
      ) : (
        <IconMessageContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={100}
            icon='opt-out'
            color='black'
          />
          <SubText
            style={{
              marginTop: '20px',
            }}
            fontSize={22}
          >
            No Access To This Page
          </SubText>
        </IconMessageContainer>
      )
    )}
  </NoRecordWrapper>
);

NoConversationComponent.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
};

export default NoConversationComponent;
