import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const AthleteAvatarContainer = styled('div')`
  img {
    height: ${props => (
    props.size ? `${props.size}px` : '35px'
  )};
    width: ${props => (
    props.size ? `${props.size}px` : '35px'
  )};
    border-radius: 50%;
  }
`;

const AthleteAvatar = ({
  avatar,
  size,
}) => (
  <AthleteAvatarContainer size={size}>
    <img src={avatar} alt='' />
  </AthleteAvatarContainer>
);

AthleteAvatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  size: PropTypes.number,
};

AthleteAvatar.defaultProps = {
  size: null,
};

export default memo(AthleteAvatar);
