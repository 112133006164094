import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { Formik, Form, Field } from 'formik';
import { animated, useTransition } from 'react-spring/web.cjs';
import Logger from 'js-logger';
import Shimmer from 'react-shimmer-effect';

import Text from '../../../../shared/components/Text/Text';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import conversationData from '../../ConversationData'

const MessageBoxContainer = styled('div')`
  height: 100%;
  width: 100%;
  min-width: 425px;
  display: flex;
  padding-top: 25px;
  overflow-y: scroll;
  padding-bottom: 40px;
  background: #fbfbfb;
  flex-direction: column-reverse;
  overflow-wrap: normal;
`;

const MessagesLoading = ({
  conversationMessages,
}) => {
  const messagesRef = useRef([]);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [userProfilePic, setUserProfilePic] = useState(null);

  // To add the dynamic height of each message to the animation, we rebuild
  // the conversationMessages array with an index key value and use that to access
  // the correct message ref from the messagesRef array to get the clientHeight.
  // We also use async await next to make sure the clientHeight isn't undefined before 
  // we access it. The refs are assigned in the currentMessageMapper.
  // const textAnimation = useTransition(
  //   conversationMessages.map((message, index) => ({ ...message, index: index })), 
  //   (item) => (item.key || item.id), 
  //   {
  //     from: { height: 0, opacity: 0, flexShrink: 0 },
  //     enter: ({ index }) => async next => await next({ height: messagesRef.current[index].clientHeight, opacity: 1 }) ,
  //     leave: { height: 0, opacity: 0 },
  //   },
  // );

  // useEffect(() => {
  //   conversationMessages.current = new Array(conversationData.length);
  // }, []);

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      const pic = optimizeImage(
        currentUser.pic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(pic);
    }
  }, [currentUser]);

  const imageOptimizer = (friendPic) => optimizeImage(
    friendPic,
    {
      resize: {
        width: 60,
        height: 60,
        fit: 'contain',
      },
    },
  );

  const FriendMessage = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    overflow-wrap: normal;
    padding-bottom: min(7px);
    .userpic {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      /* background-image: url(${userProfilePic}); */
      background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`};
      background-size: contain;
      margin-left: 10px;
    }
  `;

  const UserMessage = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    overflow-wrap: normal;
    padding-bottom: min(7px);

    .userpic {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
    }
  `;

  const MessageText = styled('div')`
    display: flex;
    align-items: center;
    color: transparent;
    background: ${props => props.friend ? '#77A7FF' : '#ff6600'};
    padding: 9px;
    border-radius: 20px;
    margin-right: ${props => props.friend ? '50px' : '10px'};
    overflow-wrap: anywhere;
    margin-left: ${props => props.friend ? '10px' : '50px'};

    max-width: 500px;
    white-space: break-spaces; 
  `

  const UserPic = styled('div')`
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: url(${userProfilePic});
    background-size: contain;
    ${props => props.friend ? 'margin-left: 10px;' : 'margin-right: 10px;'}
 `

  const currentMessageMapper = (
    currentMessagesArray,
  ) => conversationData.map((item, index) => {
    if (item.userId === currentUser.id) {
      return (
        // Each message assigns their ref in the messagesRef array.
        <UserMessage ref={el => messagesRef.current[index] = el} props={item}>
          <Shimmer>
            <MessageText>{item.messageBody}</MessageText>
          </Shimmer>
          <Shimmer>
            <UserPic />
          </Shimmer>
        </UserMessage>
      );
    }
    return (
      <FriendMessage ref={el => messagesRef.current[index] = el} props={item}>
        <Shimmer>
          <UserPic friend />
        </Shimmer>
        <Shimmer>
          <MessageText friend>{item.messageBody}</MessageText>
        </Shimmer>
      </FriendMessage>
    );
  });
 
  return (<MessageBoxContainer>{currentMessageMapper()}</MessageBoxContainer>);
};

MessagesLoading.propTypes = {
  conversationMessages: PropTypes.instanceOf(Array).isRequired,
};

export default MessagesLoading;
