import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { push } from 'connected-react-router';
import Checkbox from '@material-ui/core/Checkbox';

import useDocuments from './components/hooks/useDocuments';
import SubText from '../../shared/components/SubText/SubText';
import iconSet from '../../shared/images/teambuildr-selection.json';
import useWindowSize from '../../shared/hooks/useWindowSize';
import Table from '../../shared/components/Table/Table';
import Spinner from '../../shared/components/Spinner/Spinner';

import {
  setCurrentRowRedux,
  setActiveReduxModal,
  setModalShareLinkRedux,
  setSelectedRowsRedux,
  setSingleActionRedux,
} from './ducks/documentsActions';

const conditionalRowStyles = [
  {
    when: (row) => row,
    style: () => ({
      paddingLeft: '10px',
    }),
  },
];

// const DotContainer = styled('div')`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   height: 30px;
//   width: 30px;
//   border-radius: 30px;
// `;

const SpinnerWrapper = styled('div')`
  display: flex;
  margin-top: 200px;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const customNoRecordComponent = () => {
  const TextBox = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
  `;
  const TextAndIcon = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;
  `;
  return (
    <TextAndIcon>
      <IcomoonReact
        iconSet={iconSet}
        size={100}
        icon='file'
      />
      <TextBox>No documents/links are available</TextBox>
    </TextAndIcon>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={25}
      icon='dots'
    />
  </Link>
));

const customTableStyle = {
  paddingBottom: '130px',
};

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    span {
      color: #999999;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
    }
    :hover {
      transition: linear 0s;
      background: #006a65;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }
`;

const CustomTable = ({
  data,
  setCurrentFolder,
  setActiveSearchString,
  currentFolder,
}) => {
  const {
    handleSearch,
    handleFetchFile,
  } = useDocuments();
  const windowWidth = useWindowSize().width;

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isDocumentsLoading = useSelector((state) => state.documents.ui.isDocumentsLoading);
  const isFolderLoading = useSelector((state) => state.documents.ui.isFolderLoading);
  const clearRowsToggle = useSelector((state) => state.documents.data.clearRowsToggle);

  const dispatch = useDispatch();

  const setCurrentRow = (row) => {
    dispatch(setCurrentRowRedux(row));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const setModalShareLink = (activeModalString) => {
    dispatch(setModalShareLinkRedux(activeModalString));
  };

  const setSelectedRows = (newSelectedRows) => {
    dispatch(setSelectedRowsRedux(newSelectedRows));
  };

  const setSingleAction = (bool) => {
    dispatch(setSingleActionRedux(bool));
  };

  const fileFetcherDownloader = async (accountCode, docId) => {
    await handleFetchFile({ accountCode, docId });
  };

  const handleSelectedRowChange = (rows) => {
    const newArray = [];
    rows.selectedRows.forEach((row) => {
      newArray.push(row.id);
    });
    setSelectedRows(newArray);
  };

  const handleRowClicked = (row) => {
    if (row.type === 0) {
      setSelectedRows([]);
      setCurrentFolder(row);
      setActiveSearchString('');
      dispatch(push(`/documents/${row.id}`));
      const accountCode = currentUser !== undefined && currentUser.accountCode;
      handleSearch({ accountCode, values: { search: '' } });
    }
    if (row.type === 1) {
      const { accountCode } = currentUser;
      const docId = row.id;
      fileFetcherDownloader(accountCode, docId, row.name);
    }
    if (row.type === 2) {
      window.open(row.url, '_blank');
    }
  };

  const columns = [
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      cell: (row) => {
        let returnOb;
        if (row.type === 1) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='file'
            />
          );
        } else if (row.type === 0) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='folder'
            />
          );
        } else if (row.type === 2) {
          returnOb = (
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='globe'
            />
          );
        }
        return returnOb;
      },
      width: '65px',
    },
    // {
    //   cell: (row) => {
    //     if (row.type === 1) {
    //       return (
    //         <Text onClick={() => {
    //           setModalShareLink(`http://doc.tbldr.co/${row.id}`);
    //           setActiveModal('share_modal');
    //         }}
    //         >
    //           Share
    //         </Text>
    //       );
    //     }
    //     return null;
    //   },
    // },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      style: {
        'font-weight': 'bold',
      },
      minWidth: '200px',
      maxWidth: !currentUser.admin ? '350px' : '300px',
      grow: 1,
    },
    {
      name: 'Description',
      selector: ((row) => (row.description ? row.description : '-')),
      center: true,
      omit: windowWidth <= 768,
      grow: 1,
      maxWidth: !currentUser.admin ? '500px' : '300px',
    },
    {
      name: 'Access',
      selector: ((row) => {
        if (row.userGroupAccess.length) {
          const groupNames = [];
          const filteredGroups = row.userGroupAccess.filter((group) => group.name !== null);
          if (!filteredGroups.length) {
            return 'No groups';
          }
          filteredGroups.forEach((group, idx) => {
            if (idx < filteredGroups.length - 1) {
              groupNames.push(`${group.name}, `);
            }
            if (idx === filteredGroups.length - 1) {
              groupNames.push(`${group.name}`);
            }
          });
          return groupNames;
        }
        return 'Everyone';
      }
      ),
      sortable: true,
      omit: !currentUser.admin || windowWidth <= 768,
      center: true,
      grow: 1,
      maxWidth: '300px',
    },
    {
      name: 'Added',
      selector: 'dateAdded',
      sortable: true,
      omit: windowWidth <= 992,
      grow: 1,
      center: true,
      width: '200px',
    },
    {
      cell: (row) => {
        if (!currentUser.admin) {
          return (
            <dotContainer
              onClick={() => {
                handleRowClicked(row);
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='right-arrow'
              />
            </dotContainer>
          );
        }
        if (row.id) {
          return (
            <DropDownMenuWrapper>
              <Dropdown
                bsPrefix='trick-fix'
              >
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => {
                    setCurrentRow(row);
                    setActiveModal('update_doc_modal');
                  }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={12}
                      icon='pencil'
                    />
                    <SubText
                      className='drop-icon-text'
                      fontSize={14}
                    >
                      Update
                    </SubText>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {row.type === 1 || row.type === 2 ? (
                    <Dropdown.Item onClick={() => {
                      setModalShareLink(row.type === 1 ? `http://doc.tbldr.co/${row.id}` : row.url);
                      setActiveModal('share_modal');
                    }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={12}
                        icon='share'
                      />
                      <SubText
                        className='drop-icon-text'
                        fontSize={14}
                      >
                        Share
                      </SubText>
                    </Dropdown.Item>
                  ) : null}
                  {row.type === 1 ? <Dropdown.Divider /> : null}
                  <Dropdown.Item onClick={() => {
                    setSelectedRows([row.id]);
                    setSingleAction(true);
                    setActiveModal('delete_docs_modal');
                  }}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={12}
                      icon='trashcan'
                    />
                    <SubText
                      className='drop-icon-text'
                      fontSize={14}
                    >
                      Delete
                    </SubText>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </DropDownMenuWrapper>
          );
        }
        return null;
      },
      right: true,
      width: !currentUser.admin ? '40px' : '60px',
      style: {
        /**
         * Added margin here to make the header columns centered
         * for the rest of the table. There doesn't seem to be a
         * way to customize an individual headCell style, so was able to
         * work around it by adding margin which isn't carried over
         * to the headCell like the width does above.
         */
        marginLeft: '10px',
        marginRight: '10px',
      },
    },
  ];

  return (
    <Table
      highlightOnHover
      pointerOnHover
      noHeader
      fitContainer
      data={data}
      selectableRows={!!currentUser.admin}
      columns={columns}
      noDataComponent={customNoRecordComponent()}
      progressComponent={<SpinnerWrapper><Spinner /></SpinnerWrapper>}
      progressPending={isDocumentsLoading || (isFolderLoading && currentFolder !== undefined)}
      style={customTableStyle}
      conditionalRowStyles={conditionalRowStyles}
      onSelectedRowsChange={(rows) => handleSelectedRowChange(rows)}
      onRowClicked={(row) => (handleRowClicked(row))}
      clearSelectedRows={clearRowsToggle}
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={{ color: 'primary', size: 'small' }}
    />
  );
};

CustomTable.propTypes = {
};

export default CustomTable;
