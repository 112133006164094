/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import Logger from 'js-logger';
import {
  Route,
  Link,
  Switch,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import PortalWrapper from './PortalWrapper';

const Portal = () => {
  // const { clearCurrentUser } = useLogin();
  const { path } = useRouteMatch();

  return (
    <>
      <PortalWrapper path={path} />
    </>
  );
};

export default Portal;
