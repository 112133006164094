/**
 * file for importing actions and wrapping them in functions for export to calendars.js
 * also for importing various slices of state for use in calendars.js
 */

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSubscriber,
  addSubscriberToProgram,
  clearAddSubscriberToProgramError,
} from '../../ducks/resellerActions';

const useResellerSubscribers = () => {
  const subscriber = useSelector((state) => state.reseller.data.subscriber);
  const isSubscriberLoading = useSelector((state) => state.reseller.ui.isSubscriberLoading);
  const currentReseller = useSelector((state) => state.reseller.data.currentReseller);

  const dispatch = useDispatch();

  const handleFetchSubscriber = (values) => {
    dispatch(fetchSubscriber(values));
  };

  const handleAddSubscriberToProgram = (accountCode, subscriberId, programId, disableCalendarAndGroups) => {
    dispatch(addSubscriberToProgram(accountCode, subscriberId, programId, disableCalendarAndGroups));
  };

  const handleClearAddSubscriberToProgramError = () => {
    dispatch(clearAddSubscriberToProgramError());
  };

  return {
    subscriber,
    isSubscriberLoading,
    currentReseller,
    handleFetchSubscriber,
    handleAddSubscriberToProgram,
    handleClearAddSubscriberToProgramError,
  };
};

export default useResellerSubscribers;
