/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import { CSSTransition } from 'react-transition-group';
import iconSet from '../../shared/images/teambuildr-selection.json';
import { setRecoveryRadarActiveModal, setPrintCurrentPage } from './ducks/RecoveryRadarActions';
import Text from '../../shared/components/Text/Text';
import {
  TransitionGlobal,
} from '../../shared/GlobalStyles';
import AreasSelectedLegend from '../../shared/images/AreasSelectedLegend.png';

const ModalOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000!important;

  &.modal-enter {
    opacity: 0;
  }
  
  &.modal-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  }
  
  &.modal-exit {
    opacity: 1;
  }
  
  &.modal-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  }

`;

const ModalContainer = styled('div')`
  display: flex;
  background: white;
  border-radius: 8px;
  max-width: 90%;
  max-height: 95%;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;

`;

const Body = styled('div')`
  display: flex;
  height: ${(props) => (props.height ? props.height : '100%')};
  width: ${(props) => props.width};
  gap: 16px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  max-width: 100%;

`;

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Button = styled('div')`
  background: ${(props) => props.backgroundColor};
  padding: 6px 16px 6px 16px;
  border-radius: 4px;
  border: ${(props) => props.border && '2px solid #444444'};
  display: flex;
  width: 100%;
  transition: ${TransitionGlobal};
  :hover {
    opacity: .7;
  }
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const IconHoverWrapper = styled('div')`
  display: flex;
  cursor: pointer;
    :hover {
      opacity: .7;
    }
  transition: ${TransitionGlobal};
  position: absolute;
  top: 25px;
  right: 25px;
`;

const RowWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: flex-end;
  justify-content: center;
  max-width: 100%; /* Ensure the row doesn't overflow the container */
  min-width: 475px;
`;

const RecoveryRadarModal = () => {
  const nodeRef = useRef(null);
  const dispatch = useDispatch();
  const activeRecoveryRadarModal = useSelector((state) => state.recoveryRadar.data.activeModal);
  const modalData = useSelector((state) => state.recoveryRadar.data.modalData);

  const closeModal = () => {
    dispatch(setRecoveryRadarActiveModal(null));
    dispatch(setPrintCurrentPage(false));
  };

  const handleContinue = () => {
    dispatch(setPrintCurrentPage(true));
  };

  return (
    <>
      <CSSTransition
        in={!!activeRecoveryRadarModal}
        timeout={250}
        classNames='modal'
        unmountOnExit
      >
        <ModalOverlay nodeRef={nodeRef} onClick={(e) => closeModal(e)}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <IconHoverWrapper>
              <IcomoonReact
                iconSet={iconSet}
                size={16}
                icon='remove'
                onClick={() => closeModal()}
              />
            </IconHoverWrapper>
            {activeRecoveryRadarModal === 'Legend' && (
              <Body>
                <TitleContainer>
                  <Text color='#9E9E9E' fontSize='16px'>Legend</Text>
                  <Text fontSize='24px' fontWeight={700}>Areas Selected</Text>
                </TitleContainer>
                <RowWrapper>
                  <img
                    id='to'
                    src={AreasSelectedLegend}
                    alt='body-map'
                    style={{
                      objectFit: 'contain',
                      height: 'auto',
                      width: '100%', /* Make the image responsive */
                      minWidth: '475px', /* Minimum width for the image */
                      maxWidth: '700px', /* Limit maximum size */
                    }}
                  />
                </RowWrapper>
              </Body>
            )}
            {activeRecoveryRadarModal === 'PHI-warning' && (
              <Body width='500px'>
                <TitleContainer>
                  <Text fontSize='24px' fontWeight={700}>
                    Warning: Protected Health
                  </Text>
                  <Text fontSize='24px' fontWeight={700}>
                    Information (PHI)
                  </Text>
                </TitleContainer>
                <Text fontSize='16px' fontWeight={400}>
                  Please be aware that you are about to download material
                  that contains Protected Health
                  Information (PHI). As such, this information is subject to
                  strict confidentiality and security requirements under regulations
                  such as HIPAA (Health Insurance Portability and Accountability Act).
                </Text>
                <Text fontSize='16px' fontWeight={400}>
                  Data stored in TeamBuildr meets
                  all HIPAA regulation, but once outside our system,
                  it should be handled within the guidelines and regulations of
                  your local governing body.
                </Text>
                <Text fontSize='16px' fontWeight={400}>
                  You are responsible for any issues that arise from mishandling
                  or unauthorized access to this PHI and you may be held accountable.
                  Please verify that you are following all necessary procedures to protect
                  this sensitive information.
                </Text>
                <ButtonContainer>
                  <Button type='button' onClick={handleContinue} backgroundColor='#444444'>
                    <Text fontSize='16px' fontWeight={700} color='white'>
                      CONTINUE
                    </Text>
                  </Button>
                  <Button type='button' onClick={() => closeModal()} border>
                    <Text ontSize='16px' fontWeight={700}>
                      CANCEL
                    </Text>
                  </Button>
                </ButtonContainer>
              </Body>
            )}
            {activeRecoveryRadarModal === 'Additional-notes' && (
              <Body width='500px'>
                <TitleContainer>
                  <Text fontSize='24px' fontWeight={700}>
                    Additional Notes
                  </Text>
                </TitleContainer>
                <Text fontSize='16px' fontWeight={400}>
                  {modalData}
                </Text>
                <ButtonContainer>
                  <Button type='button' onClick={() => closeModal()} backgroundColor='#444444'>
                    <Text ontSize='16px' fontWeight={700} color='white'>
                      CLOSE
                    </Text>
                  </Button>
                </ButtonContainer>
              </Body>
            )}
            {activeRecoveryRadarModal === 'Reported-card' && (
              <Body width='500px'>
                <TitleContainer>
                  <Text color='#9E9E9E' fontSize='16px'>Legend</Text>
                  <Text fontSize='24px' fontWeight={700}>Reported</Text>
                </TitleContainer>
                <Text fontSize='16px' fontWeight={400}>
                  This represents the total number across this group for the day.
                </Text>
              </Body>
            )}
            {activeRecoveryRadarModal === 'Severity-card' && (
              <Body width='500px'>
                <TitleContainer>
                  <Text color='#9E9E9E' fontSize='16px'>Legend</Text>
                  <Text fontSize='24px' fontWeight={700}>Avg. Severity</Text>
                </TitleContainer>
                <Text fontSize='16px' fontWeight={400}>
                  This is the true average severity across all reported areas in
                  this group for the day selected. Please note this may differ from the
                  bottom table&lsquo;s average since that table is an average OF averages.
                </Text>
              </Body>
            )}
            {activeRecoveryRadarModal === 'Review-card' && (
              <Body width='500px'>
                <TitleContainer>
                  <Text color='#9E9E9E' fontSize='16px'>Legend</Text>
                  <Text fontSize='24px' fontWeight={700}>Athletes to Review</Text>
                </TitleContainer>
                <Text fontSize='16px' fontWeight={400}>
                  Total number of athletes that reported threshold values for the day.
                </Text>
              </Body>
            )}
          </ModalContainer>
        </ModalOverlay>
      </CSSTransition>
    </>
  );
};

export default RecoveryRadarModal;
