/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import CompletionBreakdownGrid from './completionBreakdownGrid';
import PercentageBreakDownContainerMobileView from './percentageBreakDownContainerMobileView';

const WeeklyOverviewPage = () => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(0);
  const athlete = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview.user);
  const completionPercentage = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview.completionPercentage);
  const assigned = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.assigned);
  const completed = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.completed);
  const newPrs = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.newMaxes);

  const calculateFontSize = (textString, baseFontSize) => {
    const maxCharacters = 3; // Set the maximum number of characters before decreasing font size
    const fontSizeIncrement = 4; // Set the font size decrement for each character beyond the limit

    const charactersCount = textString.length;
    let fontSize = baseFontSize;

    if (charactersCount > maxCharacters) {
      const extraCharacters = charactersCount - maxCharacters;
      fontSize -= extraCharacters * fontSizeIncrement;
    }
    if (fontSize < 10) {
      fontSize = 10;
    }
    return fontSize;
  };

  const PageWrapper = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  `;
  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    max-width: 1400px;
    flex-wrap: wrap;
  `;
  const GridWrapper = styled('div')`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${(props) => (props.containerWidth > 802 ? '#FDFBFB' : 'white')};
    padding-top: 30px;
    align-items: center;
    gap: 30px;
    flex-direction: column;
  `;
  const ChartAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 25px;
    text-align: center;
    min-width: ${(props) => (props.containerWidth && props.containerWidth <= 400 ? '250px' : '400px')};
  `;
  const PercentageBreakDownContainer = styled('div')`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #F2F2F2;
    min-width: ${(props) => (props.containerWidth && props.containerWidth <= 400 ? '250px' : '400px')};
    padding: 25px;
    background-color: ${(props) => (props.containerWidth < 802 ? '#FDFBFB' : 'white')};
  `;

  const IconContainer = styled('div')`
    height: 54px;
    width: 54px;
    background-color: #F3F3F3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
  const IncreaseContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 10px;
  `;
  const InnerContainer = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
    max-width: 125px;
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  `;

  const NumberContainer = styled('div')`
    min-width: 80px;
    max-width: 80px;
    min-height: 50px;
    max-height: 40px;
    background-color: #F3F3F3;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  `;
  const IconAndTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  `;

  return (
    <PageWrapper>
      <HeaderContainer>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <ChartAndTextContainer containerWidth={containerWidth}>
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={31}
              icon='calendar-chart'
              color={theme.colors.orange}
            />
          </IconContainer>
          <Text fontSize={containerWidth < 540 ? '20px' : '28px'} fontWeight={800}>
            {completionPercentage?.message}
          </Text>
          <IncreaseContainer>
            <Text fontWeight={500} fontSize='16px' color='#7A7878'>{`${athlete?.first} averaged a ${completionPercentage?.currentWeek}% completion percentage this week and a ${completionPercentage?.previousWeek}% completion percentage the previous week.`}</Text>
          </IncreaseContainer>
        </ChartAndTextContainer>
        {containerWidth > 415 ? (
          <PercentageBreakDownContainer containerWidth={containerWidth}>
            <InnerContainer style={{ borderRight: 'solid', borderRightWidth: 2, borderColor: '#F2F1F1' }}>
              <NumberContainer>
                <Text fontSize='32px' fontWeight={800}>{assigned?.currentWeek}</Text>
              </NumberContainer>
              <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Exercises Assigned</Text>
              <IconAndTextContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='play-filled'
                  color={assigned?.increase ? theme.colors.green : assigned?.difference === 0 ? theme.colors.yellow : '#FF3737'}
                  style={{ transform: assigned?.increase ? 'rotate(-90deg)' : assigned?.difference === 0 ? 'rotate(-0deg)' : 'rotate(90deg)' }}
                />
                {assigned?.increase ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${assigned?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                ) : (
                  <>
                    {assigned?.difference === 0 ? (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                        No change from previous week
                      </Text>

                    ) : (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                        decrease of
                        {' '}
                        <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(assigned?.difference)}</span>
                        {' '}
                        from previous week
                      </Text>
                    )}
                  </>
                )}
              </IconAndTextContainer>
            </InnerContainer>
            <InnerContainer style={{ borderRight: 'solid', borderRightWidth: 2, borderColor: '#F2F1F1' }}>
              <NumberContainer>
                <Text fontSize='32px' fontWeight={800}>{completed?.currentWeek}</Text>
              </NumberContainer>
              <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Exercises Completed</Text>
              <IconAndTextContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='play-filled'
                  color={completed?.increase ? theme.colors.green : completed?.difference === 0 ? theme.colors.yellow : '#FF3737'}
                  style={{ transform: completed?.increase ? 'rotate(-90deg)' : completed?.difference === 0 ? 'rotate(-0deg)' : 'rotate(90deg)' }}
                />
                {completed?.increase ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${completed?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                ) : (
                  <>
                    {completed?.difference === 0 ? (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                        No change from previous week
                      </Text>

                    ) : (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                        decrease of
                        {' '}
                        <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(completed?.difference)}</span>
                        {' '}
                        from previous week
                      </Text>
                    )}
                  </>
                )}
              </IconAndTextContainer>
            </InnerContainer>
            <InnerContainer style={{ borderRight: 'solid', borderRightWidth: 2, borderColor: '#F2F1F1' }}>
              <NumberContainer>
                <Text fontSize={completionPercentage?.currentWeek > 99 ? `${calculateFontSize(completionPercentage?.currentWeek.toString(), 24)}px` : '32px'} fontWeight={800}>
                  {`${completionPercentage?.currentWeek}%`}
                </Text>
              </NumberContainer>
              <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 100 }}>Completion Percentage</Text>
              <IconAndTextContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='play-filled'
                  color={completionPercentage?.increase ? theme.colors.green : completionPercentage?.difference === 0 ? theme.colors.yellow : '#FF3737'}
                  style={{ transform: completionPercentage?.increase ? 'rotate(-90deg)' : completionPercentage?.difference === 0 ? 'rotate(-0deg)' : 'rotate(90deg)' }}
                />
                {completionPercentage?.increase ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${completionPercentage?.difference}%`}</span>
                    {' '}
                    from previous week
                  </Text>
                ) : (
                  <>
                    {completionPercentage?.difference === 0 ? (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                        No change from previous week
                      </Text>

                    ) : (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                        decrease of
                        {' '}
                        <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${Math.abs(completionPercentage?.difference)}%`}</span>
                        {' '}
                        from previous week
                      </Text>
                    )}
                  </>
                )}
              </IconAndTextContainer>
            </InnerContainer>
            <InnerContainer>
              <NumberContainer>
                <Text fontSize='32px' fontWeight={800}>{newPrs?.currentWeek}</Text>
              </NumberContainer>
              <Text fontSize='14px' fontWeight={700} color={theme.colors.text} style={{ maxWidth: 50 }}>New PRs</Text>
              <IconAndTextContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='play-filled'
                  color={newPrs?.increase ? theme.colors.green : newPrs?.difference === 0 ? theme.colors.yellow : '#FF3737'}
                  style={{ transform: newPrs?.increase ? 'rotate(-90deg)' : newPrs?.difference === 0 ? 'rotate(-0deg)' : 'rotate(90deg)' }}
                />
                {newPrs?.increase ? (
                  <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                    Increase of
                    {' '}
                    <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{`${newPrs?.difference}`}</span>
                    {' '}
                    from previous week
                  </Text>
                ) : (
                  <>
                    {newPrs?.difference === 0 ? (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700} style={{ maxWidth: 110 }}>
                        No change from previous week
                      </Text>

                    ) : (
                      <Text color='#A4A2A2' fontSize='10px' fontWeight={700}>
                        decrease of
                        {' '}
                        <span style={{ fontWeight: '800', color: '#7E7A7A' }}>{Math.abs(newPrs?.difference)}</span>
                        {' '}
                        from previous week
                      </Text>
                    )}
                  </>
                )}
              </IconAndTextContainer>
            </InnerContainer>
          </PercentageBreakDownContainer>
        ) : (
          <PercentageBreakDownContainerMobileView completionPercentage={completionPercentage} assigned={assigned} completed={completed} newPrs={newPrs} containerWidth={containerWidth} fontSize={completionPercentage?.currentWeek > 99 ? `${calculateFontSize(completionPercentage?.currentWeek.toString(), 24)}px` : '32px'} />
        )}
      </HeaderContainer>
      <GridWrapper containerWidth={containerWidth}>
        <Text color='#ADADAD' fontSize='16px' fontWeight={700}>Completion Breakdown</Text>
        <CompletionBreakdownGrid />
      </GridWrapper>
    </PageWrapper>
  );
};

export default WeeklyOverviewPage;
