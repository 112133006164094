import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import Layout from '../../shared/components/Layout/Layout';
import Spinner from '../../shared/components/Spinner/Spinner';
import useProfile from './components/hooks/useProfile';
import Header from './components/presentational/Header';
import ProfileInfo from './components/presentational/ProfileInfo';
// import TotalInfoArea from './components/presentational/TotalInfoArea';
import Tabs from './components/presentational/Tabs';
import { tabs } from '../../shared/constants';
import LatestSession from './components/presentational/LatestSession';

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 50%;
  justify-content: center;
  width: 100%;
`;

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMeLoading = useSelector((state) => state.auth.ui.isLoginLoading);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const {
    handleFetchLatestSession,
    handleFetchMultiDaySessions,
  } = useProfile();

  const [currentTab, setCurrentTab] = useState(0);

  const isSelected = (tabIndex) => currentTab === tabIndex;

  const changeTab = (index) => {
    setCurrentTab(index);

    switch (index) {
      case 0:
        handleFetchLatestSession(currentUser.accountCode);
        break;
      case 1:
        handleFetchMultiDaySessions(currentUser.accountCode, 7);
        break;
      case 2:
        handleFetchMultiDaySessions(currentUser.accountCode, 30);
        break;
      default:
    }
  };

  const Wrapper = styled('div')`
    margin-top: 25px;
    border-radius: 24px;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    background-color: ${theme.colors.background[0]};
  `;

  useEffect(() => {
    if (currentUser && currentUser.accountCode) {
      handleFetchLatestSession(currentUser.accountCode);
    }
  }, [currentUser]);

  // if a user somehow hit the profile URL and they are an admin
  // they will be redirected to the /settings page
  useEffect(() => {
    if (currentUser && currentUser.admin) {
      dispatch(push('/settings'));
    }
  }, [currentUser.admin]);

  return (
    <Layout>
      <>
        <Helmet>
          <title>Profile | TeamBuildr</title>
        </Helmet>
        {(isMeLoading) ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )
          : (
            <Wrapper>
              <Header />
              <ProfileInfo />
              {/* <TotalInfoArea /> */}
              <Tabs isSelected={isSelected} changeTab={changeTab} />
              <LatestSession tab={tabs[currentTab]} />
            </Wrapper>
          )}
        <div style={{ height: 40 }} />
      </>
    </Layout>
  );
};
export default Profile;
