export const FEATURES = {
  CONSISTENCY_COACH_DASHBOARD: 1,
  RECOVERY_RADAR: 2,
  // Add other features here
};

/**
 * Utility function to check if a feature has been viewed
 * @param {Array} viewedFeatures - List of viewed features from the API
 * @param {Number} featureId - The feature ID to check
 * @returns {Boolean} - True if the feature has been viewed, otherwise false
 */
export const hasViewedFeature = (
  viewedFeatures, featureId,
) => viewedFeatures.some((vf) => vf.featureId === featureId);

/**
 * Utility function to prepare the payload for marking a feature as viewed
 * @param {String} accountCode - The user's account code
 * @param {String} userId - The user's ID
 * @param {Number} featureId - The feature ID to mark as viewed
 * @returns {Object} - The payload to send to the backend
 */
export const createViewedFeaturePayload = (accountCode, userId, featureId) => ({
  accountCode,
  userId,
  featureId,
});
