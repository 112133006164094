import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Form, FieldArray } from 'formik';

import 'react-rangeslider/lib/index.css';

import Question from './Question';

const QuestionListContainer = styled('div')`
  width: 100%;
  margin-left: -7px;
  padding-right: 15px;
  margin-bottom: 10px;

  form {
    display: flex;
    flex-direction: column;
  }
`;

const InfoContainer = styled('div')`
  margin-left: 36px;
  padding: 12px 20px;
  border: 1px solid rgba(104,104,104,0.2);
`;

const InfoTitle = styled('span')`
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 15px;
  letter-spacing: .6px;
`;

const InfoCopy = styled('p')`
  font-size: 13px;
  margin-bottom: 10px;
  color: ${props => props.theme.textColor};
`;

class QuestionList extends PureComponent {
  constructor(props) {
    super(props);
    this.handleActive = this.handleActive.bind(this);
    this.checkTotal = this.checkTotal.bind(this);
    this.question = [];
  }

  componentDidMount() {
    const { formikRef } = this.props;
    formikRef.current.validateForm();
  }

  handleActive(id, index) {
    const {
      formikRef,
      selectQuestion,
      namespace,
      scrollQuestions,
    } = this.props;
    selectQuestion(id, index, namespace);
    setTimeout(() => scrollQuestions(index), 800);
    formikRef.current.submitForm();
  }

  checkTotal(values) {
    const { namespace, storeFormikValues } = this.props;
    const numbers = values.result.map((result, i) => (
      values.result[i].value[0] > 0 ? parseInt(values.result[i].value[0], 10) : 0
    ));
    const add = (a, b) => (
      (Number.isNaN(a) ? 0 : a) + (Number.isNaN(b) ? 0 : b)
    );
    const totalNumber = numbers.reduce(add);
    const totalDefaultNumber = totalNumber === '' ? 0 : totalNumber;
    storeFormikValues(namespace, totalDefaultNumber);
  }

  render() {
    const {
      activeQuestionIndex,
      athleteId,
      data,
      formikRef,
      index,
      namespace,
      updateWorkout,
      workout,
    } = this.props;

    const hasInfo = workout.info;

    return (
      <QuestionListContainer
        id='question-list-container'
        data-sciencelength={data.length}
      >
        <Formik
          key={workout.work_id}
          ref={formikRef}
          enableReinitialize
          isInitialValid
          initialValues={{
            work_id: workout.work_id,
            user_id: athleteId,
            result:
              // ORIGINAL SOLUTION
              workout.result ? (
                workout.result.data.map((set, i) => {
                  const isEntryInput = workout.questions[i].input_type === 1;
                  const showDefaultValue = (isEntryInput ? '' : 0);
                  return (
                    {
                      question_id: set.question_id,
                      value:
                        (set.value[0] === null || set.value[0] === '')
                          ? showDefaultValue
                          : set.value,
                    }
                  );
                })
              ) : (
                workout.questions.map(set => ({
                  question_id: set.question_id,
                  value:
                    set.input_type === 0
                      ? 0
                      : '',
                }))
              ),
          }}
          onSubmit={(values, actions) => {
            updateWorkout(index, namespace, values);
            // alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false);
          }}
          validate={workout.total === 1 ? this.checkTotal : ''}
          render={props => (
            <Form>
              <FieldArray
                name='result'
                render={() => (
                  <Fragment>
                    {hasInfo ? (
                      <InfoContainer>
                        <InfoTitle>Additional Info:</InfoTitle>
                        <InfoCopy>{workout.info}</InfoCopy>
                      </InfoContainer>
                    ) : ('')}
                    {data.map((question, i) => {
                      const isQuestionActive = activeQuestionIndex === i;
                      return (
                        <Fragment
                          key={question.question_id}
                        >
                          <Question
                            description={question.description}
                            handleActive={this.handleActive}
                            handleChange={props.handleChange}
                            key={question.question_id}
                            inputType={question.input_type}
                            isQuestionActive={isQuestionActive}
                            questionId={question.question_id}
                            questionIndex={i}
                            questionRef={(el) => { this.question[i] = el; }}
                            questionTitle={question.title}
                            rangeStart={question.range_start}
                            rangeEnd={question.range_end}
                            setFieldValue={props.setFieldValue}
                            values={props.values}
                          />
                        </Fragment>
                      );
                    })}
                    {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
                  </Fragment>
                )}
              />
            </Form>
          )}
        />
      </QuestionListContainer>
    );
  }
}

QuestionList.propTypes = {
  activeQuestionIndex: PropTypes.number.isRequired,
  athleteId: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  storeFormikValues: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default QuestionList;
