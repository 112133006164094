import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import NoHoverButton from './NoHoverButton';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { useSelector, useDispatch } from 'react-redux';

import { Form } from 'formik';
import Logger from 'js-logger';

import { BoxShadow, OpenSans } from '../../../../../shared/GlobalStyles';
import { updatePayment } from '../../../ducks/resellerActions';
import FormHandler from '../../../../../shared/components/FormHandler/FormHandler';
import Spinner from '../../../../../shared/components/Spinner/Spinner';

const PaymentCard = styled('div')`
  background-color: transparent;
  width: 375px;
  height: 110px;

  .visa-logo {
    background-image: url(${props => props.subscriber && props.subscriber.paymentMethod ? props.images[`${props.subscriber.paymentMethod.cardType}.png`] : null});
    background-size: contain;
    background-repeat: no-repeat;
    width: 47px;
    height: 35px;
    display: flex;
    align-items: center;
    margin-top: 3px;
  }
  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .update-button {
    background: #10cd8c;
    color: white;
  }
  .back-button {
    background: ${props => props.theme.colors.lightBlue};
    color: white;
  }
  .StripeElement {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding: 5px;

    input {
      font-family: ${OpenSans};
    }
  }
`;

const PaymentButtons = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .spinner-container {
    min-height: 0;
    width: 94px;
    margin-right: 0px;
  }
`;

const PaymentCardFront = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const PaymentCardBack = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 5px;
  padding: 20px;

  button {
    margin-top: 10px;
  }
`;

const StripeFields = ({
  accountCode,
  elements,
  images,
  stripe,
  subscriber,
  subscriberId,

}) => {
  const [isCardFlipped, setIsCardFlipped] = useState(false);
  const isLoadingPaymentUpdate = useSelector(state => state.reseller.ui.isLoadingPaymentUpdate);
  const isPaymentUpdated = useSelector(state => state.reseller.ui.isPaymentUpdated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPaymentUpdated) {
      setIsCardFlipped(false);
    }
  }, [isPaymentUpdated]);

  const cardTransition = useSpring({ 
    transform: isCardFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
    position: 'relative',
    width: '100%',
    height: '100%',
    // transition: 'transform 0.8s',
    transformStyle: 'preserve-3d',
    boxShadow: BoxShadow,
    borderRadius: '5px',
    perspective: '1000px',
  });

  const handleCardFlip = () => {
    setIsCardFlipped(!isCardFlipped && true);
  };

  return (
    <PaymentCard
      images={images}
      subscriber={subscriber}
    >
      <animated.div style={cardTransition}>
        <PaymentCardFront>
          <div className='visa-logo' />
          <div className='card-info-box'>
            <div className='card-type'>
              {(subscriber && subscriber.paymentMethod) ? subscriber.paymentMethod.cardType : 'No card on file'}
            </div>
            <div className='card-number'>
              .... .... ....
              {' '}
              {(subscriber && subscriber.paymentMethod) ? subscriber.paymentMethod.lastFour : 'xxxx'}
            </div>
          </div>
          <NoHoverButton
            className='update-button'
            cta='Update'
            customColor='#10cd8c'
            noHover
            noBorder
            onClick={handleCardFlip}
          />
        </PaymentCardFront>
        <PaymentCardBack>
          <FormHandler
            initialValues={{
              accountCode,
              subscriberId,
              body: {
                stripeToken: '',
              }
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                console.log(values);
                setSubmitting(false);
                dispatch(updatePayment(values));
              });
            }}
          >
            {props => {
              const handleStripeSubmit = (ev) => {
                ev.preventDefault();
            
                // Use Elements to get a reference to the Card Element mounted somewhere
                const cardElement = elements.getElement('card');
            
                // From here we can call createPaymentMethod to create a PaymentMethod
                stripe.createPaymentMethod({
                  type: 'card',
                  card: cardElement,
                  billing_details: { name: props.values.name },
                })
                  .then(({ paymentMethod }) => {
                    Logger.debug('Received Stripe PaymentMethod:', paymentMethod);
                  });
            
                // Use createToken to create tokens.
                stripe.createToken({ type: 'card', name: props.values.name })
                  .then((token) => {
                    Logger.debug('Stripe Token:', token);
                    props.setFieldValue('body.stripeToken', token.token.id);
                    props.handleSubmit();
                  })
                  .catch((err) => {
                    Logger.debug('Stripe Token Error:', err);
                  });
              };

              return (
                <Form>
                  <CardElement style={{ 
                    base: { 
                      fontSize: '16px',
                      fontFamily: 'Open Sans, sans-serif',
                      border: '1px solid #dbdbdb',
                      borderRadius: '5px',
                      padding: '5px',
                    } 
                  }} />
                  <PaymentButtons>
                    <NoHoverButton
                      className='back-button'
                      cta='Back'
                      customColor='#3fbfe1'
                      noHover
                      noBorder
                      onClick={handleCardFlip}
                    />
                    {isLoadingPaymentUpdate ? (
                      <Spinner saving darkTheme />
                    ) : (
                      <NoHoverButton
                        className='update-button'
                        cta='Update'
                        customColor='#10cd8c'
                        noHover
                        noBorder
                        onClick={(e) => handleStripeSubmit(e)}
                      />
                    )}

                  </PaymentButtons>
                </Form>
              )
            }}
          </FormHandler>
        </PaymentCardBack>
      </animated.div>
    </PaymentCard>
  );
}
 
export default injectStripe(StripeFields);
