import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { useTransition, animated, config } from 'react-spring';
import { useCookies } from 'react-cookie';
import Logger from 'js-logger';
import useLogin from './components/hooks/useLogin';

import LoginFormContainer from './components/presentational/LoginFormContainer';
// import useLogin from './components/hooks/useLogin';
import LoginImage from '../../shared/images/tb_sign_in_3.jpg';
// import getAllUrlParams from '../../shared/utils/getAllUrlParams';
import optimizeImage from '../../shared/utils/imageOptimizer';
import useScript from '../../shared/hooks/useScript';

const LoginPageContainer = styled('div')`
  display: flex;
  background: ${props => props.theme.colors.darkBlue};
  height: 100%!important;
  width: 100%;
  align-items: center;
  justify-content: center;
  a {
    align-self: center;
  }
  .login-image-container {
    /* background-image: url(${LoginImage}); */
    background-image: ${props => props.theme.colors.gradientOverlay}, url('https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_1.jpg');
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0px;
    /* left: -160%;
    margin-top: -15%; */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

// const LoginImageContainer = styled('div')`
//   background-image: url(${LoginImage});
//   background-image: ${props => props.theme.colors.gradientOverlay}, url(${LoginImage});
//   height: 100%;
//   width: 100%;
//   position: absolute;
//   /* left: -160%;
//   margin-top: -15%; */
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// `;

const LoginWrapper = styled('div')`
  height: auto;
  display: flex;
  z-index: 1000;
`;

const slides = Array(12).fill(0);

slides.forEach((slide, index, slidesArray) => {
  const slidesArrayObject = slidesArray;

  slidesArrayObject[index] = {
    id: index,
    url: optimizeImage(
      `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/backgrounds/image_login_${index + 1}.jpg`,
      {
        resize: {
          width: 1500, height: 1500, fit: 'inside',
        },
      },
    ),
  };
});

const Login = () => {
  const { clearCurrentUser } = useLogin();
  const [imageIndex, setImageIndex] = useState(0);
  const [cookies, setCookies] = useCookies();

  const imageTransition = useTransition(slides[imageIndex], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  useScript('https://cdn.ywxi.net/js/1.js');

  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
    && cookies.accessToken !== undefined && cookies.accessToken !== '0'
    && cookies.session_id !== undefined && cookies.session_id !== '0'
    && cookies.session_key !== undefined && cookies.session_key !== '0';

  useEffect(() => {
    if (!isAuthenticatedv3) {
      // setIsAuthed(false);
      clearCurrentUser();
      if (window.gapi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 !== null) {
          auth2.signOut().then(
            Logger.debug('Google has been signed out'),
          );
        }
      }
    }
  }, []);

  useEffect(() => (
    setInterval(() => (
      setImageIndex(state => (state + 1) % slides.length)
    ), 6000)
  ), []);

  // useLayoutEffect(() => {
  //   if (isAuthenticated) {
  //     if (process.env.ENVIRONMENT === 'LOCAL') {
  //       window.location = 'http://localhost:8080/weightroom-view';
  //     }
  //     if (process.env.ENVIRONMENT !== 'LOCAL') {
  //       const url = getAllUrlParams(window.location.href);
  //       const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
  //       window.location = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' ? 'app-v3' : 'app'}.teambuildr.com/${url.rel === undefined ? 'dashboard' : url.rel}`;
  //     }
  //   }
  // }, [isAuthenticated]);

  return (
    <LoginPageContainer>
      <Helmet>
        <title>Sign In | TeamBuildr</title>
      </Helmet>
      <LoginWrapper>
        <LoginFormContainer />
      </LoginWrapper>
      {imageTransition.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className='login-image-container'
          style={{ ...props, backgroundImage: `linear-gradient(-20deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 154, 68, .5) 100%), url(${item.url})` }}
        />
      ))}
    </LoginPageContainer>
  );
};

export default Login;
