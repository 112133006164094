/**
 * file for importing actions and wrapping them in functions for export to calendars.js
 * also for importing various slices of state for use in calendars.js
 */

import { useDispatch, useSelector } from 'react-redux';
import {
  connectStripeAccount,
  uploadMedia,
  createCoupon,
  createProgram,
  fetchUserGroups,
  updateProgram,
  stripeAdminLogin,
  cancelSubscription,
  updateResellerAccount,
  fetchCalendars,
  fetchCancelledSubscribers,
  fetchAllCharges,
  refundCharge,
  fetchPrograms,
  fetchSubscribers,
  requestStripeConnectLink,
  fetchCoupons,
  setCouponModal,
  deleteCoupon,
  clearCouponsFetchError,
  updateCoupon,
} from '../../ducks/resellerActions';

const useReseller = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const currentReseller = useSelector((state) => state.reseller.data.currentReseller);
  const calendars = useSelector((state) => state.reseller.data.calendars);
  const stripeAdminLoginURL = useSelector((state) => state.reseller.data.stripeAdminURL);
  const isSubscribersLoading = useSelector((state) => state.reseller.ui.isSubscribersLoading);
  const isRefundChargeLoading = useSelector((state) => state.reseller.ui.refundChargeLoading);
  const isProgramsLoading = useSelector((state) => state.reseller.ui.isProgramsLoading);
  const userGroups = useSelector((state) => state.reseller.data.userGroups);
  const programs = useSelector((state) => state.reseller.data.programs);
  const subscribers = useSelector((state) => state.reseller.data.subscribers);

  const dispatch = useDispatch();

  const handleConnectStripeAccount = (values) => {
    dispatch(connectStripeAccount(values));
  };

  const handleUploadMedia = (values) => {
    dispatch(uploadMedia(values));
  };

  const handleCreateCoupon = (values) => {
    dispatch(createCoupon(values));
  };

  const handleCreateProgram = (values) => {
    dispatch(createProgram(values));
  };

  const handleFetchUserGroups = (accountCode) => {
    dispatch(fetchUserGroups(accountCode));
  };

  const handleUpdateProgram = (values) => {
    dispatch(updateProgram(values));
  };

  const handleStripeAdminLogin = (accountCode) => {
    dispatch(stripeAdminLogin(accountCode));
  };

  const handleFetchCancelledSubscribers = (accountCode) => {
    dispatch(fetchCancelledSubscribers(accountCode));
  };

  const handleCancelSubscription = (values) => {
    dispatch(cancelSubscription(values));
  };

  const handleUpdateResellerAccount = (accountCode, values) => {
    dispatch(updateResellerAccount(accountCode, values));
  };

  const handleFetchCalendars = (accountCode) => {
    dispatch(fetchCalendars(accountCode));
  };

  const handleFetchAllCharges = (values) => {
    dispatch(fetchAllCharges(values));
  };

  const handleRefundCharge = (values) => {
    dispatch(refundCharge(values));
  };

  const handleFetchPrograms = (accountCode) => {
    dispatch(fetchPrograms(accountCode));
  };

  const handleFetchSubscribers = (accountCode) => {
    dispatch(fetchSubscribers(accountCode));
  };

  const handleRequestStripeConnectLink = (accountCode, accountType = 'express') => {
    dispatch(requestStripeConnectLink(accountCode, accountType));
  };

  const handleFetchCoupons = (accountCode) => {
    dispatch(fetchCoupons(accountCode));
  };

  const handleSetCouponModal = (modal) => {
    dispatch(setCouponModal(modal));
  };

  const handleDeleteCoupon = (accountCode, couponId) => {
    dispatch(deleteCoupon(accountCode, couponId));
  };

  const handleClearCouponsFetchError = () => {
    dispatch(clearCouponsFetchError());
  };

  const handleUpdateCoupon = (accountCode, couponId, newCoupon) => {
    dispatch(updateCoupon(accountCode, couponId, newCoupon));
  };

  return {
    userGroups,
    stripeAdminLoginURL,
    isRefundChargeLoading,
    isProgramsLoading,
    programs,
    subscribers,
    calendars,
    isSubscribersLoading,
    currentUser,
    currentReseller,
    handleConnectStripeAccount,
    handleUploadMedia,
    handleCreateCoupon,
    handleCreateProgram,
    handleFetchUserGroups,
    handleUpdateProgram,
    handleStripeAdminLogin,
    handleFetchSubscribers,
    handleCancelSubscription,
    handleUpdateResellerAccount,
    handleFetchCalendars,
    handleFetchCancelledSubscribers,
    handleFetchAllCharges,
    handleRefundCharge,
    handleFetchPrograms,
    handleRequestStripeConnectLink,
    handleFetchCoupons,
    handleSetCouponModal,
    handleDeleteCoupon,
    handleClearCouponsFetchError,
    handleUpdateCoupon,
  };
};

export default useReseller;
