/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { batch, useDispatch, useSelector } from 'react-redux';

import HeaderText from '../../shared/components/HeaderText/HeaderText';
import PerformanceOverview from './components/presentational/PerformanceOverview';
import GroupAnalysis from './components/presentational/GroupAnalysis';
import {
  fetchGroupAnalysis,
  fetchMetricCardData,
  fetchPerformanceOverview,
  setFlaggedAthletesView,
  setForcePlateView,
  setGroupFilterRadio,
} from './ducks/forcePlateDashboardActions';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

const Container = styled('div')`
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1450px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;

  h1 {
    padding-bottom: 8px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const ForcePlateDashboard = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const endDateData = useSelector(
    (state) => state.forcePlateDashboard.data.endDateData,
  );
  const forcePlateView = useSelector((state) => state.forcePlateDashboard.ui.forcePlateView);
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const metricFiltersGroup = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersGroup,
  );
  const metricFiltersPerformance = useSelector(
    (state) => state.forcePlateDashboard.data.metricFiltersPerformance,
  );
  const startDateData = useSelector(
    (state) => state.forcePlateDashboard.data.startDateData,
  );

  const dispatch = useDispatch();

  const handleMenuClick = (view) => {
    if (view !== forcePlateView) {
      if (view === 'group' && group) {
        dispatch(
          fetchGroupAnalysis(
            currentUser.accountCode,
            startDateData,
            endDateData,
            group.id,
            metricFiltersGroup,
          ),
        );
      } else if (view === 'performance' && group) {
        batch(() => {
          dispatch(
            fetchPerformanceOverview(
              currentUser.accountCode,
              startDateData,
              endDateData,
              group.id,
              metricFiltersPerformance,
            ),
          );
          dispatch(
            fetchMetricCardData(
              currentUser.accountCode,
              startDateData,
              endDateData,
              group.id,
            ),
          );
        });
      }
      batch(() => {
        dispatch(setForcePlateView(view));
        dispatch(setFlaggedAthletesView(false));
        dispatch(setGroupFilterRadio('all'));
      });
      dashboardTracker('Force Plate Dashboard', view === 'group' ? 'Group Analysis' : 'Performance Overview', 'Menu item clicked');
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Force Plate Dashboard | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <DashboardHeader>
          <HeaderText
            fontWeight='300'
            letterSpacing='normal'
          >
            Force Plate Dashboard
          </HeaderText>
          <MenuContainer>
            <MenuItem
              isActive={forcePlateView === 'performance'}
              onClick={() => handleMenuClick('performance')}
            >
              Performance Overview
            </MenuItem>
            <MenuItem
              isActive={forcePlateView === 'group'}
              onClick={() => handleMenuClick('group')}
            >
              Group Analysis
            </MenuItem>
          </MenuContainer>
        </DashboardHeader>
        {forcePlateView === 'performance' && (
          <PerformanceOverview />
        )}
        {forcePlateView === 'group' && (
          <GroupAnalysis />
        )}
      </PageWrapper>
    </Container>
  );
};

export default ForcePlateDashboard;
