/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  setMultiQuestionModal,
  setMultiQuestionModalResponse,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
`;

const QuestionItem = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 25px;
  background: ${(props) => (!props.fake ? 'rgba(176,196,222, 0.1)' : 'none')};
	:hover {
		background: ${(props) => (!props.fake ? 'rgba(176,196,222, 0.5)' : 'none')};
	}
	display: flex;
	align-items: center;
  color: dimgrey;
  width: 100%;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: white;
  z-index: 1001;
`;

const RemoveDivIcon = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MultiQuestionModal = () => {
  const dispatch = useDispatch();
  const multiQuestionModalQuestion = useSelector(
    (state) => state.workouts.data.multiQuestionModalQuestion,
  );

  const selectFunction = (choice) => {
    dispatch(setMultiQuestionModalResponse(choice));
  };

  const choiceMapper = (choices) => choices.map((choice) => (
    <QuestionItem onClick={() => {
      selectFunction(choice);
    }}
    >
      {choice.displayValue}
    </QuestionItem>
  ));

  return (
    <ModalContainer
      isActive={Object.keys(multiQuestionModalQuestion).length}
    >
      <RemoveDiv>
        <RemoveDivIcon onClick={() => dispatch(setMultiQuestionModal({}, null))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </RemoveDivIcon>
        {multiQuestionModalQuestion.description}
        <div />
      </RemoveDiv>
      <ContentContainer>
        {Object.keys(multiQuestionModalQuestion).length
          ? choiceMapper(multiQuestionModalQuestion.choices) : null}
      </ContentContainer>
    </ModalContainer>
  );
};

export default MultiQuestionModal;
