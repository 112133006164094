/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import Shimmer from 'react-shimmer-effect';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../shared/images/teambuildr-selection.json';
import LandingPageFilter from './components/presentational/LandingPageFilter';
import {
  fetchReportableGroups, fetchHabitsForAccount, fetchCategoriesForAccount, fetchAthletes,
} from './ducks/habitTrackingActions';
import { setIsSideBarExpandedGlobal, setModalOpen } from '../sports-science/ducks/sportsScienceActions';
import CategoriesCard from './components/presentational/CategoriesCard';
import CustomTable from './components/presentational/CustomTable';
import Text from '../../shared/components/Text/Text';
import ActionButton from '../../shared/components/ActionButton/ActionButton';
import { dashboardTracker } from '../../shared/utils/amplitudeHelper';

const Container = styled('div')`
  display: flex;
  width: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const PageContainer = styled('div')`
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding: ${(props) => (props.containerWidth < 350 ? '20px' : '40px')};
  padding-bottom: ${(props) => (props.containerWidth < 523 ? '130px' : '150px')};
  gap: 40px;
  flex-wrap: ${(props) => (props.containerWidth <= 1201 ? 'wrap' : 'noWrap')};
  flex: 1;
  flex-grow: 1;
`;

const GeneratedDataWrapper = styled('div')`
  display: flex;
  width: 100%;
  min-width: 800px;
`;

const CategoriesCardContainer = styled('div')`
  display: flex;
  flex-basis: 280px;
  min-width: 280px;
  flex-grow: 1;
  border-radius: 8px;
  max-height: 300px;
  min-height: 300px;
  max-width: 750px;
  margin-top: ${(props) => (props.marginTop ? '77px' : '0px')};
  box-shadow: 0px 0px 1px 0px #3031330D;
  box-shadow: 0px 4px 8px 0px #3031331A;
  margin-left: auto;
  margin-right: auto;
`;

const NoHabitsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin-top: 77px;
  text-align: center;
`;

const SearchWrapper = styled('div')`
  display: flex;
  gap: 20px;
  height: 40px;
  width: ${(props) => (props.containerWidth <= 603 ? '100%' : '344px')};
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: 1px solid #EEEEEE;
  border-radius: 50px;
  background: white;
  overflow: hidden;
  width: ${(props) => (props.containerWidth <= 603 ? '100%' : '300px')};
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  height: 40x;
`;

const SearchContainer = styled('input')`
  display: flex;
  border: none;
  background: none;
  padding-left: 5px;
  width: 100%;
  height: 40x;
`;

const HeaderWidthContainer = styled('div')`
  max-width: 1440px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-wrap: wrap;
`;

const DataTableLoadingShimmerStrip = styled('div')`
  display: flex;
  width: 100%;
  min-height: 91px;
  border-radius: 8px;
  box-shadow: 0px 0px 1px 0px #3031330D;
  box-shadow: 0px 4px 8px 0px #3031331A;
`;

const DataTableLoadingShimmerContainer = styled('div')`
  display: flex;
  width: 100%;
  min-width: 800px;
  gap: 20px;
  flex-direction: column;
  margin-top: 77px;  
`;

const GeneratedDataRow = styled('div')`
  display: flex;
  width: 100%;
`;

const ActionButtonContainer = styled('div')`
  position: fixed;
  width: ${((props) => (props.containerWidth >= 523 ? '100%' : ''))};
  bottom: ${(props) => (props.containerWidth < 523 ? '72px' : '20px')};
  right: ${(props) => (props.containerWidth < 523 ? `${props.containerWidth / 2 - 28}px` : '80px')};
  max-width: ${((props) => (props.containerWidth >= 523 ? 'calc(100vw - 40px - 1745px)' : ''))};
`;

const SearchTableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HabitTracking = ({ scrollTriggered }) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isSideBarOpen = useSelector((state) => state.sportsScience.data.isSideBarOpen);
  const isLoadingReportableGroups = useSelector((state) => state.habitTracking.ui.isReportableGroupsLoading);
  const isSideBarExpanded = useSelector((state) => state.sportsScience.data.isSideBarExpanded);
  const accountCode = currentUser?.accountCode;
  const fetchHabitsError = useSelector((state) => state.habitTracking.data.habitsError);
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const pageContainerRef = useRef(null);
  const containerRef = useRef(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const [generatedDataWrapperContainerWidth, setGeneratedDataWrapperContainerWidth] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [localCurrentUser, setLocalCurrentUser] = useState(null);
  const isContentLoading = isLoadingReportableGroups;
  const habitsForAccount = useSelector((state) => state.habitTracking.data.habits);
  const searchedHabitsForAccount = Array.isArray(habitsForAccount) ? habitsForAccount.filter((habit) => habit?.name?.toLowerCase().includes(searchTerm.toLowerCase())) : [];
  const habitsForAccountLoading = useSelector((state) => state.habitTracking.ui.isHabitsLoading);
  const categoriesForAccount = useSelector((state) => state.habitTracking.data.categories);
  const isCategoriesLoading = useSelector((state) => state.habitTracking.ui.isCategoriesLoading);
  const globalHabitFilters = useSelector((state) => state.habitTracking.data.globalHabitFilters);
  const globalFilters = globalHabitFilters.status === 2 || globalHabitFilters.status === null || !!globalHabitFilters.groupIds || !!globalHabitFilters.categoryIds;

  useEffect(() => {
    if (currentUser) {
      setLocalCurrentUser(currentUser);
    }
  }, [currentUser]);

  /** set accurate container width after side bar animations */
  useEffect(() => {
    let timeoutId;
    if (pageContainerRef?.current) {
      timeoutId = setTimeout(() => {
        const rect = pageContainerRef.current.getBoundingClientRect();
        setContainerWidth(rect?.width);
      }, 400); /** 400 milliseconds delay */
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSideBarOpen]);

  useEffect(() => {
    if (localCurrentUser) {
      dispatch(fetchReportableGroups(accountCode));
      dispatch(fetchAthletes(accountCode));
      if (!habitsForAccount?.length) {
        dispatch(fetchHabitsForAccount(accountCode));
      }
      if (!categoriesForAccount?.length) {
        dispatch(fetchCategoriesForAccount(accountCode));
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!!habitsForAccount?.length && containerWidth <= 876) {
      dispatch(setIsSideBarExpandedGlobal(false));
    }
    if (containerWidth >= 1080) {
      dispatch(setIsSideBarExpandedGlobal(true));
    }
  }, [containerWidth]);

  useEffect(() => {
    let timeoutId;
    if (containerRef.current) {
      timeoutId = setTimeout(() => {
        const rect = containerRef.current.getBoundingClientRect();
        setGeneratedDataWrapperContainerWidth(rect?.width);
      }, 400);
    }

    /** Clean up the timeout if dependencies change or component unmounts */
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSideBarExpanded, isSideBarOpen]);

  const shimmerComponents = Array.from({ length: containerWidth < 1201 ? 3 : 5 }).map((_, index) => (
    <Shimmer key={index}>
      <DataTableLoadingShimmerStrip />
    </Shimmer>
  ));

  const renderSearchAndFilters = () => (
    <HeaderWidthContainer>
      <SearchWrapper containerWidth={containerWidth}>
        <LandingPageFilter isContentLoading={isContentLoading} />
        <SearchAndIcon
          id='search-container'
          onClick={() => {
            setIsSearchFocused(true);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Habit Search Clicked', 'User focused on search bar to search habits');
          }}
          isFocused={isSearchFocused}
          containerWidth={containerWidth}
        >
          <SearchIconContainer id='search-icon-container'>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='magnifying-glass-alt'
              color='#9E9E9E'
            />
          </SearchIconContainer>
          <SearchContainer
            placeholder='Search habit name'
            id='search-container-input'
            value={searchTerm}
            onFocus={() => setIsSearchFocused(true)}
            onChange={(inputValue) => {
              setSearchTerm(inputValue.target.value);
            }}
          />
        </SearchAndIcon>
      </SearchWrapper>
    </HeaderWidthContainer>
  );

  const renderContent = () => {
    if (habitsForAccountLoading) {
      return (
        <DataTableLoadingShimmerContainer>
          {shimmerComponents}
        </DataTableLoadingShimmerContainer>
      );
    }

    if (searchedHabitsForAccount?.length) {
      return (
        <GeneratedDataWrapper>
          <CustomTable data={searchedHabitsForAccount} pageContainerWidth={generatedDataWrapperContainerWidth} scrollTriggered={scrollTriggered} />
        </GeneratedDataWrapper>
      );
    }

    return (
      <NoHabitsContainer>
        {fetchHabitsError ? (
          <Text>{fetchHabitsError}</Text>
        ) : (
          <>
            {globalHabitFilters.status === 2 || globalHabitFilters.status === null || !!globalHabitFilters.groupIds || !!globalHabitFilters.categoryIds || searchTerm ? (
              <Text>{searchTerm ? 'No options' : 'No options with selected filters.'}</Text>
            ) : (
              <>
                <Text fontSize='23px' fontWeight={400} color='#9E9E9E'>No habits have been created yet.</Text>
                <Text fontSize='23px' fontWeight={400} color='#9E9E9E'>Create a habit to start collecting data.</Text>
                <ActionButton
                  icon='plus'
                  onClick={() => {
                    dispatch(setModalOpen(true, 'habitTracking-create-habit'));
                    // Amplitude tracking
                    dashboardTracker('Consistency Coach', 'Create Habit Clicked', 'Create new habit button clicked');
                  }}
                />
              </>
            )}
          </>
        )}
      </NoHabitsContainer>
    );
  };

  return (
    <Container>
      <Helmet>
        <title>Habit Tracking | TeamBuildr</title>
      </Helmet>
      {!localCurrentUser || !currentUser ? null : (
        <PageWrapper ref={pageRef}>
          <PageContainer containerWidth={containerWidth} ref={pageContainerRef}>
            <ResizeObserver
              onResize={(rect) => {
                setContainerWidth(rect?.width);
              }}
            />
            {containerWidth > 1201 ? (
              <>
                <GeneratedDataRow ref={containerRef}>
                  <ResizeObserver
                    onResize={(rect) => {
                      setGeneratedDataWrapperContainerWidth(rect?.width);
                    }}
                  />
                  <SearchTableWrapper>
                    {renderSearchAndFilters()}
                    {renderContent()}
                  </SearchTableWrapper>
                </GeneratedDataRow>
                {isCategoriesLoading ? (
                  <Shimmer>
                    <CategoriesCardContainer marginTop />
                  </Shimmer>
                ) : (
                  <CategoriesCard categories={categoriesForAccount} marginTop scrollTriggered={scrollTriggered} />
                )}
              </>
            ) : (
              <>
                {isCategoriesLoading ? (
                  <Shimmer>
                    <CategoriesCardContainer />
                  </Shimmer>
                ) : (
                  <CategoriesCard categories={categoriesForAccount} scrollTriggered={scrollTriggered} />
                )}
                <GeneratedDataRow ref={containerRef}>
                  <ResizeObserver
                    onResize={(rect) => {
                      setGeneratedDataWrapperContainerWidth(rect?.width);
                    }}
                  />
                  <SearchTableWrapper>
                    {renderSearchAndFilters()}
                    {renderContent()}
                  </SearchTableWrapper>
                </GeneratedDataRow>
              </>
            )}
            <ActionButtonContainer containerWidth={containerWidth}>
              {(!!habitsForAccount?.length || globalFilters || searchTerm) && (
                <ActionButton
                  icon='plus'
                  onClick={() => {
                    dispatch(setModalOpen(true, 'habitTracking-create-habit'));
                    // Amplitude tracking
                    dashboardTracker('Consistency Coach', 'Create Habit Clicked', 'Create new habit button clicked');
                  }}
                />
              )}
            </ActionButtonContainer>
          </PageContainer>
        </PageWrapper>
      )}
    </Container>
  );
};

export default HabitTracking;
