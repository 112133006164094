/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import Text from '../../../../shared/components/Text/Text';
import ProfilePicUploadButton from '../../../../shared/components/ProfilePicUploadButton';
import Avatar from '../../../../shared/components/Avatar/Avatar';
import Spinner from '../../../../shared/components/Spinner/Spinner';

const ProfileInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .error-message {
    margin-top: 10px;
    font-size: 14px;
    color: #ff6600;
  }
`;
const AvatarContentContainerColumn = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
`;

const ProfileInfo = () => {
  const me = useSelector((state) => state.auth.data.currentUser);
  const gradientColors = me.userSkin?.gradientColors;
  const isProfileImageUpdateLoading = useSelector((state) => state.auth.ui.isProfileImageUpdateLoading);
  const imageError = useSelector((state) => state.auth.ui.imageError);

  return (
    <ProfileInfoWrapper>
      <AvatarContentContainerColumn>
        {isProfileImageUpdateLoading ? (
          <div style={{ width: 150, height: 150 }}>
            <Spinner />
          </div>
        ) : (

          <Avatar avatar={me.pic} size={146} linearGradient={gradientColors}>
            <div style={{ position: 'absolute', top: -21, right: 0 }}>
              <ProfilePicUploadButton />
            </div>
          </Avatar>
        )}
        <Text fontSize={28} fontWeight='700'>
          {`${me.first} `}
          {`${me.last}`}
        </Text>
        {imageError ? (
          <div className='error-message'>{imageError}</div>
        ) : null}
      </AvatarContentContainerColumn>
    </ProfileInfoWrapper>
  );
};

export default ProfileInfo;
