import styled from '@emotion/styled';
import {
  color,
  flexbox,
  layout,
  space,
} from 'styled-system';

const Wrapper = styled('div')`
  ${color}
  ${flexbox}
  ${layout}
  position: relative;
  width: 100%;
  ${space}
`;

export default Wrapper;
