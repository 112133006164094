import { batch } from 'react-redux';

import {
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_FULFILLED,
  FETCH_CURRENT_USER_REJECTED,
  TOGGLE_SCREENS,
  TOGGLE_SUPERSETS,
  SELECT_GROUP,
  OPEN_GLOBAL_MODAL,
  CLOSE_GLOBAL_MODAL,
  SET_SCREENS,
  TOGGLE_DARK_THEME,
  TOGGLE_WEIGHT_VALUE,
  FETCH_DARKNESS_MODE_START,
  FETCH_DARKNESS_MODE_FULFILLED,
  FETCH_DARKNESS_MODE_REJECTED,
  SET_IS_GROUP_ACCESS_MODAL_SHOWING,
} from './actionTypes';
import { axiosWRV } from '../../../shared/utils/setCommonHeaders';
import getAllUrlParams from '../../../shared/utils/getAllUrlParams';

export const fetchCurrentUser = () => (
  (dispatch) => {
    dispatch({ type: FETCH_CURRENT_USER_START });
    axiosWRV.get('/me')
      .then((response) => {
        dispatch({ type: FETCH_CURRENT_USER_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CURRENT_USER_REJECTED, payload: err });
      });
  }
);

// Using this fetch breaks redux persist for darknessMode data
export const fetchDarknessMode = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_DARKNESS_MODE_START });
    axiosWRV.get(`/accounts/${accountCode}/organization/settings`)
      .then((response) => {
        dispatch({ type: FETCH_DARKNESS_MODE_FULFILLED, payload: response.data.skin.darknessMode });
      })
      .catch((err) => {
        dispatch({ type: FETCH_DARKNESS_MODE_REJECTED, payload: err });
      });
  }
);

export const setIsGroupAccessModalShowing = (bool) => (
  (dispatch, getState) => {
    dispatch({
      payload: bool,
      type: SET_IS_GROUP_ACCESS_MODAL_SHOWING,
    });

    // If user is closing the modal, redirect them to the dashboard
    if (!bool) {
      const url = getAllUrlParams(window.location.href);
      const { currentUser } = getState().auth.data;
      let domain;
      if (process.env.PHP_APP_URL && process.env.WEBAPP_URL) {
        domain = (url.version === '2' || currentUser.admin === 0 ? process.env.WEBAPP_URL : process.env.PHP_APP_URL);
      } else {
        const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
        domain = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' || currentUser.admin === 0 ? 'app-v3' : 'app'}.teambuildr.com`;
      }
      window.location = `${domain}/dashboard`;
    }
  }
);

export const fetchUsers = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_USERS_START });
    axiosWRV.get(`/accounts/${accountCode}/users?userType=0`)
      .then((response) => {
        // For ID based group filtering, we're adding a 0 group in the
        // group array if the user has no groups assigned.
        // Users don't have groups attached from new endpoint??
        response.data.map((user) => {
          // if (user.groups.length === 0) {
          //   user.groups.push({ id: 0, name: 'All Athletes' });
          // }
          if (!user.groupAssignments) {
            user.groupAssignments = [];
            user.groupAssignments.push({ groupId: 0, name: 'All Athletes' });
          }
        });
        dispatch({ type: FETCH_USERS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_USERS_REJECTED, payload: err.response?.headers?.message });
        if (err.response?.headers?.message === 'You do not have access to any users.') {
          dispatch(setIsGroupAccessModalShowing(true));
        }
      });
  }
);

export const fetchUserGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_GROUPS_START });
    axiosWRV.get(`/accounts/${accountCode}/groups`)
      .then((response) => {
        dispatch({ type: FETCH_USER_GROUPS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_USER_GROUPS_REJECTED, payload: err });
      });
  }
);

export const fetchInitial = (accountCode) => (
  (dispatch) => batch(() => {
    // dispatch(fetchDarknessMode(accountCode));
    dispatch(fetchUsers(accountCode));
    dispatch(fetchUserGroups(accountCode));
  })
);

export const selectGroup = (group) => (
  (dispatch) => {
    dispatch({
      type: SELECT_GROUP,
      payload: group,
    });
  }
);

export const setScreens = (screenAmount) => ({
  type: SET_SCREENS,
  payload: screenAmount,
});

export const toggleScreens = () => ({
  type: TOGGLE_SCREENS,
});

export const openGlobalModal = () => ({
  type: OPEN_GLOBAL_MODAL,
});

export const closeGlobalModal = () => ({
  type: CLOSE_GLOBAL_MODAL,
});

export const toggleSupersets = () => ({
  type: TOGGLE_SUPERSETS,
});

export const toggleDarkTheme = () => ({
  type: TOGGLE_DARK_THEME,
});

export const toggleWeightValue = () => ({
  type: TOGGLE_WEIGHT_VALUE,
});
