import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import ResizeObserver from 'react-resize-observer';

import PropTypes from 'prop-types';
import useMaxReportModal from '../hooks/useMaxReportModal';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ReportingModal from '../ReportingModal';
import ActiveReportSidebar from '../ActiveReportSidebar';
import ComparisonReportTable from './ComparisonReportTable';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;

  @media screen and (max-width: 767px) {
    padding: 0px 15px;
    margin: 0px;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 40px;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-height: 920px) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;

const BodyRow = styled('div')`
  display: flex;
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  @media print {
    overflow: visible !important;
  }
`;

const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;
  max-width: 100%;
  border-radius: 8px;
  width: 100%;

  @media screen and (max-width: 767px) {
    padding-right: 0px;
    padding-bottom: 25px;
  }
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  width: 180px;
  height: 45px;
  bottom: 15px;
  left: 50%;
  margin-left: -70px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const ComparisonReport = ({ printRef, handlePrint }) => {
  const { handleSetIsModalOpen } = useMaxReportModal();
  const [containerHeight, setContainerHeight] = useState(0);
  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const comparisonReportData = useSelector(
    (state) => state.reporting.data.comparisonReport.data,
  );

  useEffect(() => {
    handleSetIsModalOpen(true, 'comparison');
  }, []);

  return (
    <MainContainer
      isReportOpen={isReportOpen || activeModal === 'downloadComparisonReport'}
    >
      <ReportingModal />
      {isReportOpen && (
        <>
          <HeaderRow>
            <HeaderText fontWeight={300} letterSpacing='normal'>
              Comparison Report
            </HeaderText>
          </HeaderRow>
          <BodyRow>
            <ResizeObserver
              onResize={(rect) => {
                setContainerHeight(rect?.height);
              }}
            />
            <TableContainer>
              <ComparisonReportTable
                containerHeight={containerHeight}
                containerWidth={1000}
                dataRows={comparisonReportData}
                rowIsScrollable
                printRef={printRef}
              />
            </TableContainer>
          </BodyRow>
          <MobileActiveMenu>
            <ActiveReportSidebar edit print handlePrint={handlePrint} />
          </MobileActiveMenu>
        </>
      )}
    </MainContainer>
  );
};

ComparisonReport.propTypes = {
  printRef: PropTypes.shape({
    current: PropTypes.oneOfType([
      PropTypes.instanceOf(Element),
      PropTypes.oneOf([null]),
    ]),
  }),
  handlePrint: PropTypes.func,
};

ComparisonReport.defaultProps = {
  printRef: null,
  handlePrint: () => {},
};

export default ComparisonReport;
