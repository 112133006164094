/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  setIsDayPickerOpen,
  setIsSmallScreenDayPickerOpen,
} from '../../ducks/workoutsActions';

const DatePickerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 1002;
`;

const DayPickerOverlayContainer = () => {
  const isDayPickerOpen = useSelector((state) => state.workouts.data.isDayPickerOpen);
  const isSmallScreenDayPickerOpen = useSelector(
    (state) => state.workouts.data.isSmallScreenDayPickerOpen,
  );
  const dispatch = useDispatch();
  if (isDayPickerOpen || isSmallScreenDayPickerOpen) {
    return (
      null
      // <DatePickerOverlay
      //   onClick={() => {
      //     dispatch(setIsDayPickerOpen(false));
      //     dispatch(setIsSmallScreenDayPickerOpen(false));
      //   }}
      // />
    );
  }
  return null;
};

export default DayPickerOverlayContainer;
