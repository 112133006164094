import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { createCellValue } from '../../ducks/evaluationsActions';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const RowValue = styled('div')`
  display: flex;
  justify-content: center;
  min-width: ${(props) => (props.doubleWidth ? '440px' : '220px')};
  height: 48px;
  box-shadow: ${BoxShadow};
  flex: ${(props) => props.flexValue};
  margin-left: 10px;
  border-radius: 12px;
  padding: 8px;
  background: #FFFFFF;
  max-width: ${(props) => (props.doubleWidth ? '440px' : '220px')};

  @media (max-width: 768px) {
    min-width: ${(props) => (props.doubleWidth ? '300px' : '150px')};
    max-width: ${(props) => (props.doubleWidth ? '300px' : '150px')};
  }
`;

const DualInput = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const DualInputLeftBox = styled('div')`
  width: 50%;
  background: #FFFFFF;
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
`;

const DualInputRightBox = styled('div')`
  width: 50%;
  background: #FFFFFF;
  border-radius: 4px;
  justify-content: space-evenly;
  display: flex;
`;

const DualLeftRightInputBox = styled('input')`
  width: ${(props) => (props.value ? '80%' : '100%')};
  background: #FFFFFF;
  border: none;
  height: 100%;
  padding-left: 10px;
`;

const InputAndUnitBox = styled('div')`
  display: flex;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  width: 48%;
  font-size: 12px;
`;

const UnitBox = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-family: 'Nunito Sans';
  font-size: 12px;
  color: #444444;
`;

const DualLeftRightInputComponent = ({
  item,
  valuesArray,
  evaluation,
  athleteIdx,
  inputIdx,
  doubleWidth,
}) => {
  const [firstLeftInput, setFirstLeftInput] = useState('');
  const [secondLeftInput, setSecondLeftInput] = useState('');
  const [firstRightInput, setFirstRightInput] = useState('');
  const [secondRightInput, setSecondRightInput] = useState('');

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    setFirstLeftInput(valuesArray[0][0]?.value ?? '');
    setFirstRightInput(valuesArray[0][2]?.value ?? '');
    setSecondLeftInput(valuesArray[0][1]?.value ?? '');
    setSecondRightInput(valuesArray[0][3]?.value ?? '');
  }, [valuesArray]);

  return (
    <RowValue flexValue={4} doubleWidth={doubleWidth}>
      <DualInput>
        <DualInputLeftBox>
          <InputAndUnitBox>
            <DualLeftRightInputBox
              value={firstLeftInput}
              onChange={(e) => setFirstLeftInput(e.target.value)}
              placeholder={`${item.unit1.toLowerCase()} (left)`}
              onBlur={() => {
                const inputValue = valuesArray[0][0];
                let submissionObject;
                if (inputValue?.id || inputValue?.id === 0) {
                  submissionObject = {
                    id: inputValue.id,
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: firstLeftInput?.toString(),
                    resultNumber: 1,
                  };
                } else {
                  submissionObject = {
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: firstLeftInput?.toString(),
                    resultNumber: 1,
                  };
                }
                dispatch(createCellValue(
                  currentUser.accountCode,
                  submissionObject,
                  evaluation.id,
                  athleteIdx,
                  inputIdx,
                  0,
                ));
              }}
            />
            {firstLeftInput ? <UnitBox>{item.unit1.toLowerCase()}</UnitBox> : null}
          </InputAndUnitBox>
          <InputAndUnitBox>
            <DualLeftRightInputBox
              value={secondLeftInput}
              placeholder={`${item.unit2.toLowerCase()} (left)`}
              onChange={(e) => setSecondLeftInput(e.target.value)}
              onBlur={() => {
                const inputValue = valuesArray[0][1];
                let submissionObject;
                if (inputValue?.id || inputValue?.id === 0) {
                  submissionObject = {
                    id: inputValue.id,
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: secondLeftInput.toString(),
                    resultNumber: 2,
                  };
                } else {
                  submissionObject = {
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: secondLeftInput.toString(),
                    resultNumber: 2,
                  };
                }
                dispatch(createCellValue(
                  currentUser.accountCode,
                  submissionObject,
                  evaluation.id,
                  athleteIdx,
                  inputIdx,
                  1,
                ));
              }}
            />
            {secondLeftInput ? <UnitBox>{item.unit2.toLowerCase()}</UnitBox> : null}
          </InputAndUnitBox>
        </DualInputLeftBox>
        <DualInputRightBox>
          <InputAndUnitBox>
            <DualLeftRightInputBox
              value={firstRightInput}
              placeholder={`${item.unit1.toLowerCase()} (right)`}
              onChange={(e) => setFirstRightInput(e.target.value)}
              onBlur={() => {
                const inputValue = valuesArray[0][2];
                let submissionObject;
                if (inputValue?.id || inputValue?.id === 0) {
                  submissionObject = {
                    id: inputValue.id,
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: firstRightInput?.toString(),
                    resultNumber: 3,
                  };
                } else {
                  submissionObject = {
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: firstRightInput?.toString(),
                    resultNumber: 3,
                  };
                }
                dispatch(createCellValue(
                  currentUser.accountCode,
                  submissionObject,
                  evaluation.id,
                  athleteIdx,
                  inputIdx,
                  2,
                ));
              }}
            />
            {firstRightInput ? <UnitBox>{item.unit1.toLowerCase()}</UnitBox> : null}
          </InputAndUnitBox>
          <InputAndUnitBox>
            <DualLeftRightInputBox
              value={secondRightInput}
              placeholder={`${item.unit2.toLowerCase()} (right)`}
              onChange={(e) => (setSecondRightInput(e.target.value))}
              onBlur={() => {
                const inputValue = valuesArray[0][3];
                let submissionObject;
                if (inputValue.id || inputValue.id === 0) {
                  submissionObject = {
                    id: inputValue.id,
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: secondRightInput?.toString(),
                    resultNumber: 4,
                  };
                } else {
                  submissionObject = {
                    evalId: inputValue.evalId,
                    itemId: inputValue.itemId,
                    userId: inputValue.userId,
                    value: secondRightInput?.toString(),
                    resultNumber: 4,
                  };
                }
                dispatch(createCellValue(
                  currentUser.accountCode,
                  submissionObject,
                  evaluation.id,
                  athleteIdx,
                  inputIdx,
                  3,
                ));
              }}
            />
            {secondRightInput ? <UnitBox>{item.unit2.toLowerCase()}</UnitBox> : null}
          </InputAndUnitBox>
        </DualInputRightBox>
      </DualInput>
    </RowValue>
  );
};

DualLeftRightInputComponent.defaultProps = {

};

DualLeftRightInputComponent.propTypes = {
  valuesArray: PropTypes.instanceOf(Array).isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  evaluation: PropTypes.instanceOf(Object).isRequired,
  athleteIdx: PropTypes.number.isRequired,
  inputIdx: PropTypes.number.isRequired,
  doubleWidth: PropTypes.bool.isRequired,
};

export default DualLeftRightInputComponent;
