import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Formik, Form } from 'formik';

const FormGroupClass = ({ children }) => (
  <div className='form-group'>{children}</div>
);

export const FormWrapper = <Form />;
export const FormGroup = styled(FormGroupClass)`
  margin-bottom: 20px;
  
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const StyledForm = ({
  ...props
}) => {
  const { children } = props;
  return (
    <Formik
      {...props}
      // render={formProps => (
      //   <Form>
      //     {React.cloneElement(props.children, formProps)}
      //   </Form>
      // )}
      render={children}
    />
  );
};

export default StyledForm;
