import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import { ColorBlack, ColorGreen } from '../../../shared/GlobalStyles';

export const ButtonStyled = styled('button')`
  display: flex;
  flex-direction: row;
  font-family: 'Nunito Sans';
  align-items: center;
  font-size: ${(props) => (props.large ? '14px' : '12px')};
  font-weight: 900;
  letter-spacing: 1.5px;
  height: ${(props) => (props.large && '48px')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  background: ${(props) => {
    const isPrimary = props.primary ? props.theme.colorAccent : props.theme.backgroundColorL2;
    return (
      props.complete ? ColorGreen : isPrimary
    );
  }};
  border: ${(props) => {
    const hasNoBorder = props.noBorder ? '0px' : `1px solid ${props.theme.noBorderColor}`;
    return (
      props.complete ? `1px solid ${ColorGreen}` : hasNoBorder
    );
  }};
  border-radius: ${(props) => (
    props.square ? '0px' : '20px'
  )};
  text-transform: uppercase;
  color: ${(props) => {
    const isAccentDarkTheme = props.theme.isAccentDarkTheme ? 'white' : ColorBlack;
    return (
      props.primary || props.complete ? isAccentDarkTheme : props.theme.textColor
    );
  }};
  padding: 8px 18px;
  justify-content: center;
  align-self: ${(props) => (props.bottom ? 'flex-end' : '')};
  transition: all 250ms ease-in-out;
  cursor: pointer;
  white-space: nowrap;
  & svg {
    display: ${(props) => (props.icon ? 'block' : 'none')}!important;
    align-self: center;
    margin-right: 10px;
    path {
      transition: all 250ms ease-in-out;
      fill: ${(props) => (props.complete ? 'rgba(255,255,255)!important' : props.theme.textColor)}
    }
  }

  &:hover {
    background-color: ${(props) => (props.theme.colorAccent)};
    border: ${(props) => (props.noBorder ? '0px' : `1px solid ${props.theme.colorAccent}`)};
    color: ${(props) => (props.theme.isAccentDarkTheme ? 'white' : '#444444')};
    // opacity: .7;
    transition: all 250ms ease-in-out;
    & svg {
      path {
        transition: all 250ms ease-in-out;
        fill: ${(props) => (props.theme.isAccentDarkTheme ? 'rgb(255,255,255)!important' : 'rgb(68,68,68)!important')} ;
      }
    }
  }
`;

const ButtonRound = ({
  bottom,
  buttonRef,
  cta,
  className,
  complete,
  fullWidth,
  icon,
  large,
  noBorder,
  onClick,
  primary,
  square,
}) => (
  <ButtonStyled
    bottom={bottom}
    className={className}
    complete={complete}
    fullWidth={fullWidth}
    icon={icon}
    large={large}
    noBorder={noBorder}
    onClick={onClick}
    primary={primary}
    ref={buttonRef}
    square={square}
  >
    {icon && (
      <IcomoonReact
        iconSet={iconSet}
        size={12}
        icon={icon && icon}
      />
    )}
    {cta}
  </ButtonStyled>
);

ButtonRound.propTypes = {
  bottom: PropTypes.bool,
  buttonRef: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  complete: PropTypes.bool,
  cta: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  large: PropTypes.bool,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  square: PropTypes.bool,
};

ButtonRound.defaultProps = {
  bottom: false,
  buttonRef: {},
  className: '',
  complete: false,
  fullWidth: false,
  icon: '',
  large: false,
  noBorder: false,
  primary: false,
  square: false,
};

export default ButtonRound;
