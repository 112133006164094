/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import WeekCarousel from './WeekCarousel';
/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const TagWrapperContainer = () => {
  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);

  const shouldShowTopWrapper = () => {
    if (!isDayCarousel || (isDayCarousel && Object.keys(currentSelectedProgram).length)) {
      return true;
    }
    return false;
  };

  const TopWrapper = styled('div')`
    display: flex;
    flex-direction: column;
  `;
  return (
    (Object.keys(currentUser).length && (!currentUser.admin || (currentUser.admin
      && Object.keys(sharedAthlete).length)))
  && shouldShowTopWrapper() ? (
    <TopWrapper><WeekCarousel /></TopWrapper>
      ) : null
  );
};

TagWrapperContainer.propTypes = { };

export default TagWrapperContainer;
