import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Dropzone from 'react-dropzone';
import ResizeObserver from 'react-resize-observer';
import Text from '../../../../../shared/components/Text/Text';
import { updateBackgroundImage } from '../../../../login/ducks/loginActions';
import Spinner from '../../../../../shared/components/Spinner/Spinner';

const BackgroundUpload = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const uploadedImageErrror = useSelector((state) => state.auth.ui.imageError);
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const isBackgroundImageUpdating = useSelector((state) => state.auth.ui.isBackgroundImageUpdating);

  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [backgroundPicture, setBackgroundPicture] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);

  useEffect(() => {
    if (organizationSettings) {
      setBackgroundPicture(organizationSettings?.skin?.portalBackground);
    }
  }, [organizationSettings]);

  useEffect(() => {
    if (uploadedImageErrror) {
      setIsMediaUploaded(false);
    } else {
      setIsMediaUploaded(true);
    }
  }, [uploadedImageErrror]);

  const BackgroundUploadContainer = styled('div')`
  margin-top: 20px;
  width: 100%;
  `;

  const SaveButton = styled('button')`
  background-color:  ${(props) => (
    props.theme.colors.green
  )};;
  color: white;
  width:  ${containerWidth && containerWidth < 450 ? '100%' : '49%'};
  height: 50px;
  border-style: none;
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 700;
`;
  const DropZoneDiv = styled('div')`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 100%;
  cursor: pointer;
  color: #495057;
  background-image: url(${backgroundPicture});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  /* border-radius: 50%; */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:before {
    content: "${(props) => ((!props.backgroundPicture) ? 'Drag & Drop your image here or Click Upload Image.' : '')}";
    color: #777;
    text-align: center;
    font-size: 16px;
    font-style: italic;
  }
`;

  const DropZoneWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;

  /* width: 160px; */
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }

  .update-button {
    width: 170px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

  const EmptyDropZoneWrapper = styled('div')`
  width: 160px;
  height: 250px;
  border-radius: 12px;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

  const DropZoneDivUploadedFail = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 55px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 160px;
  height: 55px;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .file-icon-text-uploaded-fail {
    font-size: 12px;
    color: red;
  }
  .file-icon-text-uploaded-fail-restart {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
`;
  const handleDroppedFile = (acceptedFiles) => {
    const file = acceptedFiles[0]; // Take the first file if multiple are dropped
    const type = 'image';
    dispatch(updateBackgroundImage(accountCode, { acceptedFile: acceptedFiles, type }));
  };

  const fileAccepterSwitch = () => {
  // If media is being uploaded, show a spinner
    if (isBackgroundImageUpdating) {
      return (
        <EmptyDropZoneWrapper>
          <Spinner saving darkTheme />
        </EmptyDropZoneWrapper>
      );
    }

    // If there's an error, display error message and try again button
    if (uploadedImageErrror && !isMediaUploaded) {
      return (
        <DropZoneDivUploadedFail>
          <Text className='file-icon-text-uploaded-fail'>Something went wrong</Text>
          <Text onClick={() => setIsMediaUploaded(true)} className='file-icon-text-uploaded-fail-restart'>Try Again</Text>
        </DropZoneDivUploadedFail>
      );
    }

    // Default view with dropzone
    return (
      <Dropzone onDrop={handleDroppedFile}>
        {({ getRootProps, getInputProps }) => (
          <DropZoneWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            <DropZoneDiv backgroundPicture={backgroundPicture} />
            <SaveButton type='submit'>
              + Upload Image
            </SaveButton>
          </DropZoneWrapper>
        )}
      </Dropzone>
    );
  };

  return (
    <BackgroundUploadContainer>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <Text fontSize={18} fontWeight='700' pb={10}>
        Login Background Image
      </Text>
      {fileAccepterSwitch()}
    </BackgroundUploadContainer>
  );
};

export default BackgroundUpload;
