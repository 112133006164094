import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TagEntries from '../presentational/WorkoutEntriesTag';
import {
  fetchSimilarTypeExercises,
  updateTagProgramWorkout,
  updateTagWorkout,
} from '../../ducks/panelActions';

const WeightroomWorkoutEntriesTag = (ownProps) => {
  const athleteId = useSelector((state) => state.weightRoomView[ownProps.namespace].athleteId);

  const dispatch = useDispatch();

  const ftchSimilarTypeExercises = (namespace, accountCode, tagId) => {
    dispatch(fetchSimilarTypeExercises(namespace, accountCode, tagId));
  };
  const updteTagProgramWorkout = (namespace, accountCode, programId, dayNum, values) => {
    dispatch(updateTagProgramWorkout(namespace, accountCode, programId, dayNum, values));
  };
  const updteTagWorkout = (namespace, accountCode, values) => {
    dispatch(updateTagWorkout(namespace, accountCode, values));
  };

  return (
    <TagEntries
      athleteId={athleteId}
      formikRef={ownProps.formikRef}
      index={ownProps.index}
      namespace={ownProps.namespace}
      workout={ownProps.workout}
      fetchSimilarTypeExercises={ftchSimilarTypeExercises}
      updateTagProgramWorkout={updteTagProgramWorkout}
      updateTagWorkout={updteTagWorkout}
    />
  );
};

export default WeightroomWorkoutEntriesTag;
