import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';

const BetaSettings = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);

  const [darknessMode, setDarknessMode] = useState(organizationSettings?.skin?.darknessMode);

  const debouncedSetDarknessMode = useDebouncedCallback(
    (newSettings) => {
      const valueObj = {
        skin: {
          darknessMode: newSettings,
        },
      };
      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const handleSwitches = (v) => {
    setDarknessMode(v);

    debouncedSetDarknessMode(v);
  };

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 15px;

        .react-toggle .react-toggle-track {
            background-color: #E9E9EA;
        }
        .react-toggle--checked .react-toggle-track {
            background-color: ${theme.colors.green};
        }
        .react-toggle-thumb {
            border: none;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #E9E9EA;
        }
        
        .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: ${theme.colors.green};
        }
`;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    cursor: pointer;
`;

  const Column = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 602px;
`;

  const HeaderContainer = styled('div')`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
`;

  return (
    <Wrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Beta Settings
        </Text>
        <Text fontSize={18} fontWeight='400'> Manage notifications on your devices or in our notification hub for various topics.</Text>
      </HeaderContainer>
      <>
        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Dark(ness) Mode
            </Text>
            <Text fontSize={18} fontWeight='400'>
              In dark mode, new beta pages use a darker color palette.
            </Text>
          </Column>
          <Toggle
            checked={darknessMode}
            icons={false}
            onChange={() => {
              handleSwitches(!darknessMode);
            }}
          />
        </Row>
      </>
    </Wrapper>
  );
};

BetaSettings.propTypes = {
  containerWidth: PropTypes.number,
};

BetaSettings.defaultProps = {
  containerWidth: '',
};

export default BetaSettings;
