import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_START,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_IS_METRIC_INFO_MODAL_SHOWING,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_START_DATE_DATA,
  SET_END_DATE_DATA,
  SET_START_DATE_CAL,
  SET_END_DATE_CAL,
  SET_DAY_RANGE,
  FETCH_METRIC_CARD_DATA_START,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  SET_FORCE_PLATE_VIEW,
  FETCH_GROUP_ANALYSIS_START,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  SET_SELECTED_ATHLETE_GROUP,
  SET_GROUP,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
  SET_FLAGGED_ATHLETES_VIEW,
  SET_FLAGGED_ATHLETES,
  SET_FLAGGED_ATHLETES_GROUP,
  SET_FLAGGED_ATHLETES_PERFORMANCE,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_GROUPS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUPS_REJECTED,
        });
      });
  }
);

/*
Params:
userGroupId - id of group a user wants metric results for (integer) *Optional*
calendarId - id of calendar the user wants metric results for (integer) *Optional*
startDate - start date for metric results (string) Ex: 1694543961
endDate - end date of metric results range (string) Ex: 1694543961
bestResult - metric used to find test where “bestResult” metric has greatest value (string)
metrics - list of metrics to return test results for (string) Ex: ‘Peak Power, Stiffness, mRSI’
*/
// Fetch the performance overview data with any of the above params
export const fetchPerformanceOverview = (accountCode, startDate, endDate, groupId, metrics) => (
  (dispatch) => {
    const metricsQuery = metrics.length > 0 ? `&metrics=${metrics}` : '';

    dispatch({ type: FETCH_PERFORMANCE_OVERVIEW_START });
    axiosAuthed.get(
      `/accounts/${accountCode}/reports/force-plate/performance-overview?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}${metricsQuery}`,
    )
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_PERFORMANCE_OVERVIEW_REJECTED,
        });
      });
  }
);

// Same function as above, but w/o filters to use on metric cards
export const fetchMetricCardData = (accountCode, startDate, endDate, groupId) => (
  (dispatch) => {
    dispatch({ type: FETCH_METRIC_CARD_DATA_START });
    axiosAuthed.get(
      `/accounts/${accountCode}/reports/force-plate/performance-overview?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}&metrics=JumpHeight,TakeoffVelocity,PeakPower,L|RConcentricImpulse,mRSI`,
    )
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_METRIC_CARD_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_METRIC_CARD_DATA_REJECTED,
        });
      });
  }
);

// Same params as above
// Fetch the group analysis data with any of the above params
export const fetchGroupAnalysis = (accountCode, startDate, endDate, groupId, metrics) => (
  (dispatch) => {
    const metricsQuery = metrics.length > 0 ? `&metrics=${metrics}` : '';

    dispatch({ type: FETCH_GROUP_ANALYSIS_START });
    axiosAuthed.get(
      `/accounts/${accountCode}/reports/force-plate/group-analysis?userGroupId=${groupId}&startDate=${startDate}&endDate=${endDate}${metricsQuery}`,
    )
      .then((response) => {
        dispatch({
          payload: {
            athlete: response.data.athleteData,
            average: response.data.groupMetricsData,
            flagged: response.data.flaggedAthleteData,
          },
          type: FETCH_GROUP_ANALYSIS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUP_ANALYSIS_REJECTED,
        });
      });
  }
);

export const setIsMetricInfoModalShowing = (bool, metric) => (
  (dispatch) => {
    dispatch({
      payload: {
        bool,
        metric,
      },
      type: SET_IS_METRIC_INFO_MODAL_SHOWING,
    });
  }
);

export const setIsLegendInfoModalShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
    });
  }
);

export const setSelectedAthletePerformance = (athlete, bool) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
        bool,
      },
      type: SET_SELECTED_ATHLETE_PERFORMANCE,
    });
  }
);

export const setSelectedAthleteGroup = (athlete, bool) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
        bool,
      },
      type: SET_SELECTED_ATHLETE_GROUP,
    });
  }
);

export const setIsMetricFiltersPerformanceShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
    });
  }
);

export const setIsMetricFiltersGroupShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_METRIC_FILTERS_GROUP_SHOWING,
    });
  }
);

export const setMetricFiltersPerformance = (metrics) => (
  (dispatch) => {
    dispatch({
      payload: metrics,
      type: SET_METRIC_FILTERS_PERFORMANCE,
    });
  }
);

export const setMetricFiltersGroup = (metrics) => (
  (dispatch) => {
    dispatch({
      payload: metrics,
      type: SET_METRIC_FILTERS_GROUP,
    });
  }
);

export const setGroupFilterRadio = (option) => (
  (dispatch) => {
    dispatch({
      payload: option,
      type: SET_GROUP_FILTER_RADIO,
    });
  }
);

export const setGroup = (group) => (
  (dispatch) => {
    dispatch({
      payload: group,
      type: SET_GROUP,
    });
  }
);

export const setStartDateData = (startDate) => (
  (dispatch) => {
    dispatch({
      payload: startDate,
      type: SET_START_DATE_DATA,
    });
  }
);

export const setEndDateData = (endDate) => (
  (dispatch) => {
    dispatch({
      payload: endDate,
      type: SET_END_DATE_DATA,
    });
  }
);

export const setStartDateCal = (startDate) => (
  (dispatch) => {
    dispatch({
      payload: startDate,
      type: SET_START_DATE_CAL,
    });
  }
);

export const setEndDateCal = (endDate) => (
  (dispatch) => {
    dispatch({
      payload: endDate,
      type: SET_END_DATE_CAL,
    });
  }
);

export const setDayRange = (days) => (
  (dispatch) => {
    dispatch({
      payload: days,
      type: SET_DAY_RANGE,
    });
  }
);

export const setForcePlateView = (view) => (
  (dispatch) => {
    dispatch({
      payload: view,
      type: SET_FORCE_PLATE_VIEW,
    });
  }
);

export const setShowFlaggedAthletesModalPerformance = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
    });
  }
);

export const setShowFlaggedAthletesModalGroup = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
    });
  }
);

export const setFlaggedAthletesView = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_FLAGGED_ATHLETES_VIEW,
    });
  }
);

export const setFlaggedAthletes = (flaggedAthletes) => (
  (dispatch) => {
    dispatch({
      payload: flaggedAthletes,
      type: SET_FLAGGED_ATHLETES,
    });
  }
);

export const setFlaggedAthletesGroup = (flaggedAthletes) => (
  (dispatch) => {
    dispatch({
      payload: flaggedAthletes,
      type: SET_FLAGGED_ATHLETES_GROUP,
    });
  }
);

export const setFlaggedAthletesPerformance = (flaggedAthletes) => (
  (dispatch) => {
    dispatch({
      payload: flaggedAthletes,
      type: SET_FLAGGED_ATHLETES_PERFORMANCE,
    });
  }
);
