import update from 'immutability-helper';

import {
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  CREATE_CALENDAR_FULFILLED,
  CREATE_CALENDAR_REJECTED,
  DELETE_CALENDARS_FULFILLED,
  DELETE_CALENDARS_REJECTED,
  UPDATE_CALENDAR_FULFILLED,
  UPDATE_CALENDAR_REJECTED,
  UPDATE_CHILD_CALENDAR_FULFILLED,
  UPDATE_CHILD_CALENDAR_REJECTED,
  CREATE_CHILD_CALENDAR_FULFILLED,
  CREATE_CHILD_CALENDAR_REJECTED,
  ARCHIVE_CALENDARS_FULFILLED,
  ARCHIVE_CALENDARS_REJECTED,
  UNARCHIVE_CALENDARS_FULFILLED,
  UNARCHIVE_CALENDARS_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  SET_SELECTED_ROWS_REDUX,
  SET_CURRENT_ROW_REDUX,
  SET_EC_DICTIONARY_REDUX,
  SET_SELECTED_CHILD_ROWS_REDUX,
  SET_SINGLE_ACTION_REDUX,
  SET_SELECTED_CHILDREN_REDUX,
  RESET_REDUX_REDUX,
  RESET_CREATED_TOP_CAL_ID_REDUX,
} from './actionTypes';

const initialState = {
  unparsedCalendars: [],
  expandCollapseDictionary: {},
  calendarsError: '',
  selectedChildRows: {},
  selectedRows: {},
  isSingleAction: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CALENDARS_FULFILLED: {
      action.payload.sort((a, b) => a.name.localeCompare(b.name));
      return update(
        state, {
          unparsedCalendars: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CALENDARS_REJECTED: {
      return {
        ...state,
        calendarsError: action.payload,
      };
    }
    /**
     * copies the current state, make a duplicate, push the new calendar into the array
     * sorts the array and returns it as the state
     */
    case CREATE_CALENDAR_FULFILLED: {
      const { unparsedCalendars } = state;
      const newUnparsedCalendars = [...unparsedCalendars];
      newUnparsedCalendars.push(action.payload);
      newUnparsedCalendars.sort((a, b) => a.name.localeCompare(b.name));
      return update(
        state, {
          unparsedCalendars: {
            $set: newUnparsedCalendars,
          },
          createdTopCalId: {
            $set: action.payload.id,
          },
        },
      );
    }
    case CREATE_CALENDAR_REJECTED: {
      return {
        ...state,
        createCalendarError: action.payload.response.headers.message,
      };
    }
    /**
     * copies the current state, make a duplicate, iterate it
     * and if return success array includes the id of the calendar,
     * don't push that calendar into the return array.
     * Additionally, if a parent calendar hasn't been deleted and it has
     * children, iterate the child calendar array to exclude any sub calendars
     * that may have been deleted
     */
    case DELETE_CALENDARS_FULFILLED: {
      const { unparsedCalendars } = state;
      const newUnparsedCalendars = [];
      unparsedCalendars.forEach((cal) => {
        const newCal = cal;
        if (!action.payload.successful.includes(cal.id)) {
          if (cal.childCalendars && cal.childCalendars.length) {
            newCal.childCalendars = cal.childCalendars.filter(
              (childCalendar) => !action.payload.successful.includes(childCalendar.id),
            );
          }
          newUnparsedCalendars.push(newCal);
        }
      });
      return update(
        state, {
          unparsedCalendars: {
            $set: newUnparsedCalendars,
          },
          activeModal: {
            $set: '',
          },
          selectedChildRows: {
            $set: [],
          },
          selectedRows: {
            $set: [],
          },
          isSingleAction: {
            $set: false,
          },
        },
      );
    }
    case DELETE_CALENDARS_REJECTED: {
      return {
        ...state,
        calendarsError: action.payload,
      };
    }
    /**
     * copy the state and filter it to exclude the calendar that has been updated.
     * sort the childCalendar array if it exists.  push the updated calendar into the new
     * array, sort the new array, and set the new array to the state
     */
    case UPDATE_CALENDAR_FULFILLED: {
      if (!action.payload.responseData[0]) {
        return update(state, {
          unparsedCalendars: {
            [action.payload.calendarIndex]: {
              $set: action.payload.responseData,
            },
          },
        });
      }
      return update(state, {
        unparsedCalendars: {
          [action.payload.calendarIndex]: {
            childCalendars: {
              $push: [action.payload.responseData[0]],
            },
          },
          $splice: [[action.payload.oldCalendarIndex, 1]],
        },
      });
    }
    case UPDATE_CALENDAR_REJECTED: {
      return {
        ...state,
        updateCalendarError: action.payload.response.headers.message,
      };
    }
    case UPDATE_CHILD_CALENDAR_FULFILLED: {
      if (action.payload.responseData[0].parentId) {
        return update(
          state, {
            unparsedCalendars: {
              [action.payload.parentIndex]: {
                childCalendars: {
                  [action.payload.childIndex]: {
                    $set: action.payload.responseData[0],
                  },
                },
              },
            },
          },
        );
      }
      const newParentCalendar = action.payload.responseData[0];
      newParentCalendar.childCalendars = [];
      return update(
        state, {
          unparsedCalendars: {
            $push: [newParentCalendar],
            [action.payload.parentIndex]: {
              childCalendars: {
                $splice: [[action.payload.childIndex, 1]],
              },
            },
          },
        },
      );
    }
    case UPDATE_CHILD_CALENDAR_REJECTED: {
      return {
        ...state,
        updateChildCalendarError: action.payload.response.headers.message,
      };
    }
    /**
     * mostly same as before, but find the parentCalendar and push the new child
     * into the childCalendar array
     */
    case CREATE_CHILD_CALENDAR_FULFILLED: {
      const { unparsedCalendars } = state;
      const newUnparsedCalendars = [...unparsedCalendars];
      const { parentCalendarIndex } = action.payload;
      unparsedCalendars[parentCalendarIndex].childCalendars.push(action.payload.responseData[0]);
      unparsedCalendars[parentCalendarIndex].childCalendars.sort(
        (a, b) => a.name.localeCompare(b.name),
      );
      return update(
        state, {
          unparsedCalendars: {
            $set: newUnparsedCalendars,
          },
          createdTopCalId: {
            $set: action.payload.responseData[0].parentId,
          },
        },
      );
    }
    case CREATE_CHILD_CALENDAR_REJECTED: {
      return {
        ...state,
        createChildCalendarError: action.payload.response.headers.message,
      };
    }
    /**
     * similar, but the idea is that we're iterating over all calendars and their child
     * calendars to change their archived setting to true when the id is in the returned success
     * array.
     */
    case ARCHIVE_CALENDARS_FULFILLED: {
      const { unparsedCalendars } = state;
      const newUnparsedCalendars = [];
      unparsedCalendars.forEach((calendar) => {
        const newCalendar = calendar;
        if (!action.payload.successful.includes(calendar.id)) {
          if (calendar.childCalendars && calendar.childCalendars.length) {
            const newChildCalendarArray = [];
            calendar.childCalendars.forEach((childCalendar) => {
              if (action.payload.successful.includes(childCalendar.id)) {
                const newChildCalendar = childCalendar;
                newChildCalendar.archived = true;
                newChildCalendarArray.push(newChildCalendar);
              } else {
                newChildCalendarArray.push(childCalendar);
              }
            });
            newCalendar.childCalendars = newChildCalendarArray;
          }
          newUnparsedCalendars.push(newCalendar);
        } else {
          newCalendar.archived = true;
          if (newCalendar.childCalendars.length) {
            const newChildCalendarArray = [];
            newCalendar.childCalendars.forEach((childCalendar) => {
              const newChildCalendar = childCalendar;
              newChildCalendar.archived = true;
              newChildCalendarArray.push(newChildCalendar);
            });
            newCalendar.childCalendars = newChildCalendarArray;
          }
          newUnparsedCalendars.push(newCalendar);
        }
      });
      return update(
        state, {
          unparsedCalendars: {
            $set: newUnparsedCalendars,
          },
          selectedChildRows: {
            $set: [],
          },
          selectedRows: {
            $set: [],
          },
          isSingleAction: {
            $set: false,
          },
        },
      );
    }
    case ARCHIVE_CALENDARS_REJECTED: {
      return {
        ...state,
        calendarsError: action.payload,
      };
    }
    // same deal as before, but changing archived setting to false
    case UNARCHIVE_CALENDARS_FULFILLED: {
      const { unparsedCalendars } = state;
      const newUnparsedCalendars = [];
      unparsedCalendars.forEach((calendar) => {
        const newCalendar = calendar;
        if (!action.payload.successful.includes(calendar.id)) {
          if (calendar.childCalendars && calendar.childCalendars.length) {
            const newChildCalendarArray = [];
            calendar.childCalendars.forEach((childCalendar) => {
              if (action.payload.successful.includes(childCalendar.id)) {
                const newChildCalendar = childCalendar;
                newChildCalendar.archived = false;
                newChildCalendarArray.push(newChildCalendar);
              } else {
                newChildCalendarArray.push(childCalendar);
              }
            });
            newCalendar.childCalendars = newChildCalendarArray;
          }
          newUnparsedCalendars.push(newCalendar);
        } else {
          newCalendar.archived = false;
          if (newCalendar.childCalendars.length) {
            const newChildCalendarArray = [];
            newCalendar.childCalendars.forEach((childCalendar) => {
              const newChildCalendar = childCalendar;
              if (action.payload.successful.includes(childCalendar.id)) {
                newChildCalendar.archived = false;
              }
              newChildCalendarArray.push(newChildCalendar);
            });
            newCalendar.childCalendars = newChildCalendarArray;
          }
          newUnparsedCalendars.push(newCalendar);
        }
      });
      return update(
        state, {
          unparsedCalendars: {
            $set: newUnparsedCalendars,
          },
          selectedChildRows: {
            $set: [],
          },
          selectedRows: {
            $set: [],
          },
          isSingleAction: {
            $set: false,
          },
        },
      );
    }
    case UNARCHIVE_CALENDARS_REJECTED: {
      return {
        ...state,
        calendarsError: action.payload,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
        createCalendarError: '',
        createChildCalendarError: '',
      };
    }
    case SET_SELECTED_ROWS_REDUX: {
      return {
        ...state,
        selectedRows: action.payload,
      };
    }
    case SET_CURRENT_ROW_REDUX: {
      return {
        ...state,
        currentRow: action.payload,
      };
    }
    case SET_EC_DICTIONARY_REDUX: {
      return {
        ...state,
        expandCollapseDictionary: action.payload,
      };
    }
    case SET_SELECTED_CHILD_ROWS_REDUX: {
      return {
        ...state,
        selectedChildRows: action.payload,
      };
    }
    case SET_SINGLE_ACTION_REDUX: {
      return {
        ...state,
        isSingleAction: action.payload,
      };
    }
    case SET_SELECTED_CHILDREN_REDUX: {
      return {
        ...state,
        selectedChildren: action.payload,
      };
    }
    case RESET_REDUX_REDUX: {
      return {
        ...state,
        selectedRows: {},
        selectedChildRows: {},
        childrenAmount: 0,
      };
    }
    case RESET_CREATED_TOP_CAL_ID_REDUX: {
      return {
        ...state,
        createdTopCalId: undefined,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
