import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../../../shared/components/Text/Text';
import iconSet from '../../../../../../shared/images/teambuildr-selection.json';

const Dropdown = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  top: 100%;
  margin-top: 15px;
  margin-left: 12px;
  height: max-content;
  width: 232px;
  border-radius: 12px;
  padding-top: 6px;
`;

const DropdownOption = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const DropdownOptionNoClick = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`;

const FilterDropdownSubscriberProgram = ({
  setProgramValue, setProgramLabel, toggleFilterDropdown, updateFilter, overlayDispatchAction,
}) => {
  const resellerPrograms = useSelector((state) => state.reseller.data.subscribers);

  // Mapping over state and creating a new array to only include id and name
  const resellerProgramsIdName = resellerPrograms.map(
    ({ currentProgramId, currentProgramName }) => (
      {
        id: currentProgramId,
        label: currentProgramName,
      }),
  );

  // Creating a new array of objects that removes any duplicates
  const uniqueProgramArray = resellerProgramsIdName.filter(
    (obj, index, self) => index === self.findIndex((id) => (
      id[Object.keys(id)[0]] === obj[Object.keys(obj)[0]]
    )),
  );

  return (
    <Dropdown>
      { uniqueProgramArray.map((program) => ((
        <DropdownOption
          key={program.id}
          onClick={() => {
            toggleFilterDropdown('Program');
            setProgramValue(program.id);
            setProgramLabel(program.label);
            updateFilter(3);
            overlayDispatchAction(false);
          }}
        >
          <Text>{program.label}</Text>
          <IcomoonReact iconSet={iconSet} size={15} icon='right-arrow' color='#FF6600' />
        </DropdownOption>
      )
      ))}
      { uniqueProgramArray.length === 0
        ? (
          <DropdownOptionNoClick>
            <Text>There are no available subscriptions to filter.</Text>
          </DropdownOptionNoClick>
        )
        : null }
    </Dropdown>
  );
};

FilterDropdownSubscriberProgram.propTypes = {
  setProgramValue: PropTypes.func.isRequired,
  setProgramLabel: PropTypes.func.isRequired,
  toggleFilterDropdown: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  overlayDispatchAction: PropTypes.func.isRequired,
};

export default FilterDropdownSubscriberProgram;
