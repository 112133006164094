import update from 'immutability-helper';

import {
  FETCH_NOTIFICATIONS_FULFILLED,
  FETCH_NOTIFICATIONS_REJECTED,
  SET_ACTIVE_NOTIFICATION,
  SET_NOTIFICATION_FILTER,
  CLEAR_NOTIFICATION_FILTER,
  FETCH_NOTIFICATION_ASSET_FULFILLED,
  SET_SELECTED_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_DELETED_FULFILLED,
  MARK_NOTIFICATION_AS_READ_FULFILLED,
  SET_JOURNAL_MEDIA_URL,
  UPDATE_NOTIFICATION_FULFILLED,
  FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED,
  FETCH_NOTIFICATION_ASSET_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  FETCH_UNREAD_NOTIFICATIONS_FULFILLED,
  FETCH_UNREAD_NOTIFICATIONS_REJECTED,
  SET_LOCAL_NOTIFICATION_FILTERS,
  SET_ACTIVE_NOTIFICATION_FILTERS,
  CLEAR_ACTIVE_NOTIFICATION_FILTERS,
  SET_NOTIFICATION_FILTER_COUNT,
  CLEAR_NOTIFICATION_FILTER_COUNT,
  FETCH_NOTIFICATION_TYPES_FULFILLED,
  FETCH_NOTIFICATION_TYPES_REJECTED,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED,
  FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED,
  SET_IS_FILTER_DROPDOWN_SHOWING,
  SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED,
  SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED,
} from './actionTypes';

const initialState = {
  notifications: [],
  notificationFilter: null,
  selectedNotifications: {},
  selectedNotificationLength: 0,
  notificationAsset: {},
  activeNotification: {},
  unreadNotificationsNumber: 0,
  activeNotificationFilters: {},
  localNotificationFilters: {},
  notificationFilterCount: 0,
  notificationTypes: [],
  notificationTypeGroups: [],
  isFilterDropdownShowing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_FULFILLED: {
      return update(
        state, {
          notifications: {
            $set: action.payload.data,
          },
          page: {
            $set: action.payload.page,
          },
          isEndOfList: {
            $set: action.payload.data.length < 50,
          },
          fullNotifications: {
            $set: action.payload.data,
          },
        },
      );
    }
    case FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED: {
      return update(
        state, {
          notifications: {
            $push: action.payload.data,
          },
          page: {
            $set: action.payload.page,
          },
          isEndOfList: {
            $set: action.payload.data.length < 50,
          },
        },
      );
    }
    case FETCH_NOTIFICATIONS_REJECTED: {
      return update(
        state, {
          fetchNotificationsError: {
            $set: action.payload,
          },
        },
      );
    }
    case SET_ACTIVE_NOTIFICATION: {
      return {
        ...state,
        activeNotification: action.payload,
      };
    }
    case SET_NOTIFICATION_FILTER: {
      /*
      Every time the notification is changed,
      we are going to re-load the notifications
      from the API with the filter, thus we
      need to clear all of the previous state
      variables to prepare for the upcoming.
      */
      return update(
        state, {
          notificationFilters: {
            $set: action.payload,
          },
          notificationAsset: {
            $set: null,
          },
          page: {
            $set: null,
          },
          activeNotification: {
            $set: undefined,
          },
          notifications: {
            $set: [],
          },
          isEndOfList: {
            $set: false,
          },
        },
      );
    }
    case CLEAR_NOTIFICATION_FILTER: {
      return update(
        state, {
          notificationFilter: {
            $set: null,
          },
          isEndOfList: {
            $set: false,
          },
          notifications: {
            $set: [],
          },
          page: {
            $set: null,
          },
          notificationAsset: {
            $set: null,
          },
          activeNotification: {
            $set: undefined,
          },
        },
      );
    }
    case FETCH_NOTIFICATION_ASSET_FULFILLED: {
      return update(
        state, {
          notificationAsset: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_NOTIFICATION_ASSET_REJECTED: {
      return update(
        state, {
          notificationAsset: {
            $set: {},
          },
        },
      );
    }
    case SET_SELECTED_NOTIFICATIONS: {
      return {
        ...state,
        selectedNotifications: action.payload,
        selectedNotificationLength: Object.keys(action.payload).length,
      };
    }
    case MARK_NOTIFICATION_AS_DELETED_FULFILLED: {
      const { notifications } = state;
      const newNotifications = [];
      let newActiveNotificationIndex = null;
      notifications.forEach((notification, ind) => {
        const newNotification = notification;
        if (!action.payload.successful.includes(newNotification.id)) {
          newNotifications.push(newNotification);
        }
        if (newActiveNotificationIndex === null) {
          newActiveNotificationIndex = ind;
        }
      });
      // If the user deleted the active notification, we need to reset it
      if (action.payload.successful.includes(parseInt(state.activeNotification.id, 10))) {
        let newActiveNotification;
        if (newActiveNotificationIndex > newNotifications.length - 1) {
          newActiveNotification = newNotifications.length > 0 ? newNotifications[-1] : null;
        } else {
          newActiveNotification = newNotifications[newActiveNotificationIndex];
        }
        return update(
          state, {
            notifications: {
              $set: newNotifications,
            },
            activeNotification: {
              $set: newActiveNotification,
            },
            notificationAsset: {
              $set: null,
            },
          },
        );
      }

      return update(
        state, {
          notifications: {
            $set: newNotifications,
          },
        },
      );
    }
    case MARK_NOTIFICATION_AS_READ_FULFILLED: {
      const {
        notifications,
        unreadNotificationsNumber,
      } = state;
      let newUnreadNotificationsNumber = unreadNotificationsNumber;
      const newNotifications = [];
      notifications.forEach((notification) => {
        const newNotification = notification;
        if (action.payload.successful.includes(newNotification.id)) {
          if (!newNotification.read) {
            newUnreadNotificationsNumber -= 1;
          }
          newNotification.read = true;
        }
        newNotifications.push(newNotification);
      });
      return update(
        state, {
          notifications: {
            $set: newNotifications,
          },
          unreadNotificationsNumber: {
            $set: newUnreadNotificationsNumber,
          },
        },
      );
    }
    case UPDATE_NOTIFICATION_FULFILLED: {
      const {
        notifications,
        unreadNotificationsNumber,
      } = state;
      let newUnreadNotificationsNumber = unreadNotificationsNumber;
      const newNotifications = [];
      notifications.forEach((notification) => {
        if (action.payload.id === notification.id) {
          if (!action.payload.read) {
            newUnreadNotificationsNumber += 1;
          }
          newNotifications.push(action.payload);
        } else {
          newNotifications.push(notification);
        }
      });
      return update(
        state, {
          notifications: {
            $set: newNotifications,
          },
          unreadNotificationsNumber: {
            $set: newUnreadNotificationsNumber,
          },
        },
      );
    }
    case SET_JOURNAL_MEDIA_URL: {
      return {
        ...state,
        journalMediaUrl: action.payload,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
      };
    }
    case SET_LOCAL_NOTIFICATION_FILTERS: {
      return {
        ...state,
        localNotificationFilters: action.payload,
      };
    }
    case SET_ACTIVE_NOTIFICATION_FILTERS: {
      return {
        ...state,
        activeNotificationFilters: action.payload,
      };
    }
    case SET_NOTIFICATION_FILTER_COUNT: {
      return {
        ...state,
        notificationFilterCount: action.payload,
      };
    }
    case CLEAR_ACTIVE_NOTIFICATION_FILTERS: {
      return update(state, {
        activeNotificationFilters: {
          $set: {},
        },
        notificationFilterCount: {
          $set: 0,
        },
      });
    }
    case CLEAR_NOTIFICATION_FILTER_COUNT: {
      return {
        ...state,
        notificationFilterCount: action.payload,
      };
    }
    case FETCH_UNREAD_NOTIFICATIONS_FULFILLED: {
      return update(
        state, {
          unreadNotificationsNumber: {
            $set: action.payload.notificationsUnread,
          },
        },
      );
    }
    case FETCH_UNREAD_NOTIFICATIONS_REJECTED: {
      return update(
        state, {
          unreadNotificationsNumber: {
            $set: 0,
          },
        },
      );
    }
    case FETCH_NOTIFICATION_TYPES_FULFILLED: {
      const { notificationTypesArray } = action.payload;

      // eslint-disable-next-line max-len
      const notificationTypeGroups = notificationTypesArray?.reduce((group, notificationTypeObj) => {
        const { notificationGroupName } = notificationTypeObj;
        group[notificationGroupName] = group[notificationGroupName] ?? [];
        group[notificationGroupName].push(notificationTypeObj);
        return group;
      }, {});

      return update(
        state, {
          notificationTypes: {
            $set: action.payload.notificationTypesArray,
          },
          notificationTypeGroups: {
            $set: notificationTypeGroups,
          },
        },
      );
    }
    case FETCH_NOTIFICATION_TYPES_REJECTED: {
      return update(
        state, {
          notificationTypesError: action.payload,
        },
      );
    }
    case FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED: {
      return update(
        state, {
          notifications: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED: {
      return update(
        state, {
          fetchNotificationsError: action.payload,
        },
      );
    }
    case SET_IS_FILTER_DROPDOWN_SHOWING: {
      return {
        ...state,
        isFilterDropdownShowing: action.payload,
      };
    }
    case SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED: {
      return update(
        state, {
          notificationTypeGroups: {
            [action.payload.activeGroup]: {
              [action.payload.typeIndex]: {
                isEnabled: {
                  $set: action.payload.bool,
                },
              },
            },
          },
        },
      );
    }
    case SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED: {
      return update(
        state, {
          typeSwitchError: action.payload,
        },
      );
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
