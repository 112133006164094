import update from 'immutability-helper';

import {
  CLOSE_PANEL,
  CLOSE_WORKOUT,
  CLOSE_MODAL,
  COMPLETE_WORKOUT,
  FETCH_HISTORY_START,
  FETCH_HISTORY_FULFILLED,
  FETCH_HISTORY_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_SIM_EXERCISES_START,
  FETCH_SIM_EXERCISES_FULFILLED,
  FETCH_SIM_EXERCISES_REJECTED,
  FETCH_SIM_TYPE_EXERCISES_START,
  FETCH_SIM_TYPE_EXERCISES_FULFILLED,
  FETCH_SIM_TYPE_EXERCISES_REJECTED,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_START,
  FETCH_WORKOUTS_REJECTED,
  NEXT_QUESTION,
  NEXT_WORKOUT,
  OPEN_MODAL,
  PREV_QUESTION,
  PREV_WORKOUT,
  RESTART_QUESTIONS,
  SEARCH_ATHLETE,
  SELECT_ATHLETE,
  SELECT_VERIFIED_ATHLETE,
  SELECT_QUESTION,
  SELECT_WORKOUT,
  SELECT_DATE,
  STORE_PANEL_HEIGHT,
  UPDATE_JOURNAL_FULFILLED,
  UPDATE_WORKOUTS_START,
  UPDATE_WORKOUTS_FULFILLED,
  UPDATE_WORKOUTS_REJECTED,
  UPDATE_SUBSTITUTE_WORKOUT,
  FETCH_CALENDAR_REJECTED,
  FETCH_CALENDAR_FULFILLED,
  FETCH_JOURNAL_START,
  FETCH_JOURNAL_FULFILLED,
  FETCH_JOURNAL_REJECTED,
  SUBMIT_PIN_START,
  SUBMIT_PIN_FULFILLED,
  SUBMIT_PIN_REJECTED,
  OPEN_PINPAD,
  CLOSE_PINPAD,
  CANCEL_PINPAD,
  STORE_FORMIK_VALUES,
  STORE_WORKOUTS_BY_SUPERSET,
  INCREASE_COMPLETED_SETS,
  DECREASE_COMPLETED_SETS,
  SET_IS_NAVIGATING_SUPERSETS,
  SET_ADDITIONAL_WORKOUTS_DATA,
  CLEAR_ADDITIONAL_WORKOUTS_DATA,
  SET_IS_ADDITIONAL_WORKOUTS,
  SET_SHOW_SESSION_BREAK_ITEMS,
  SET_IS_CALENDAR_PROGRAM_VIEW,
  SET_IS_PROGRAM_LIST_MODAL,
  SET_IS_RESET_PROGRAM_MODAL_SHOWING,
  SET_IS_PROGRAM_INFO_MODAL_SHOWING,
  SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING,
  SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING,
  SET_IS_SKIP_WORKOUT_MODAL_SHOWING,
  CLEAR_PROGRAM_DATA,
  UPDATE_SUBSTITUTE_PROGRAM_WORKOUT,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_PROGRAM_WORKOUTS_START,
  FETCH_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_PROGRAM_WORKOUTS_REJECTED,
  FETCH_NEXT_WORKOUT_SESSION_FULFILLED,
  FETCH_PROGRAM_WEEKS_START,
  FETCH_PROGRAM_WEEKS_FULFILLED,
  FETCH_PROGRAM_WEEKS_REJECTED,
  FETCH_PROGRAM_WEEK_START,
  FETCH_PROGRAM_WEEK_FULFILLED,
  FETCH_PROGRAM_WEEK_REJECTED,
  RESET_PROGRAM_FULFILLED,
  COMPLETE_PROGRAM_FULFILLED,
  FINISH_PROGRAM_WORKOUT_FULFILLED,
  UPDATE_PROGRAM_WORKOUT_START,
  UPDATE_PROGRAM_WORKOUT_FULFILLED,
  UPDATE_PROGRAM_WORKOUT_REJECTED,
  FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START,
  SET_SELECTED_INTEGRATION,
  FETCH_SELECTED_INTEGRATION_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
  FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
  SET_IS_INTEGRATION_MODAL_SHOWING,
  FETCH_MORE_HISTORY_START,
  FETCH_MORE_HISTORY_FULFILLED,
  FETCH_MORE_HISTORY_REJECTED,
} from './actionTypes';

const initialState = {
  athleteChosen: false,
  athleteSearch: '',
  activeAthlete: '',
  activeModal: '',
  activeDate: {},
  activeWorkout: null,
  activeExerId: 0,
  activeExerType: '',
  activeQuestionId: '',
  activeQuestionIndex: 0,
  activeWorkoutIndex: null,
  additionalWorkoutsData: {},
  athleteId: '',
  athleteAvatar: '',
  currentNonDateDay: {},
  currentSelectedProgram: {},
  exercises: {},
  groupFilter: '',
  isAdditionalWorkouts: false,
  isCalendarView: true,
  isCompleteProgramModalShowing: false,
  isCompleteProgramWorkoutModalShowing: false,
  isDimensionSet: false,
  isEndOfWorkoutHistoryList: false,
  isLoading: false,
  isLoadingDocument: false,
  isLoadingHistory: false,
  isLoadingExercises: false,
  isModalOpen: false,
  isNavigatingSuperset: false,
  isPinPadOpen: false,
  isPinRejected: false,
  isPinVerified: false,
  isProgramInfoModalShowing: false,
  isProgramListItemsLoading: false,
  isProgramListModalShowing: false,
  isProgramView: false,
  isResetProgramModalShowing: false,
  isSkipWorkoutModalShowing: false,
  isWorkoutActive: false,
  isWorkoutComplete: false,
  isWorkoutLoading: false,
  isWorkoutSaving: false,
  nextWorkoutSession: {},
  panelDimensions: {},
  programs: [],
  programsError: '',
  programWeek: [],
  programWeeks: [],
  selectedProgramWeek: {},
  totalFormikValues: 0,
  workoutCalendar: [],
  workoutCount: 0,
  workoutDocument: {},
  workoutHistory: {},
  workoutHistoryPage: 1,
  workouts: {},
  workoutsError: '',
  workoutsBySuperset: {},
  importedDataIntegrations: null,
  selectedIntegration: null,
  importedDataIntegrationsError: null,
  selectedIntegrationData: null,
  isIntegrationModalShowing: false,
};

export default function createPanelReducer(namespace = '') {
  return function reducer(state = initialState, action) {
    switch (action.type) {
      case `${namespace}/${SELECT_ATHLETE}`: {
        return {
          ...state,
          athleteChosen: true,
          activeAthlete: action.payload.athlete,
          activeDate: action.payload.today,
          athleteId: action.payload.id,
          athleteAvatar: action.payload.avatar,
          isPinVerified: true,
        };
      }
      case `${namespace}/${SELECT_VERIFIED_ATHLETE}`: {
        return {
          ...state,
          athleteChosen: true,
        };
      }
      case `${namespace}/${SEARCH_ATHLETE}`: {
        return {
          ...state,
          athleteSearch: action.payload,
        };
      }
      case `${namespace}/${FETCH_WORKOUTS_START}`: {
        return {
          ...state,
          isLoading: true,
          isAdditionalWorkouts: action.payload,
        };
      }
      case `${namespace}/${FETCH_WORKOUTS_FULFILLED}`: {
        return {
          ...state,
          isLoading: false,
          workoutData: action.payload,
          workouts: action.payload.workoutItems,
          workoutsError: '',
          workoutCount: action.payload.workoutItems.length,
        };
      }
      case `${namespace}/${FETCH_WORKOUTS_REJECTED}`: {
        return {
          ...state,
          isLoading: false,
          workouts: [],
          workoutsError: action.payload.headers.message,
        };
      }
      case `${namespace}/${FETCH_CALENDAR_REJECTED}`: {
        return {
          ...state,
          workoutCalendar: action.payload,
        };
      }
      case `${namespace}/${FETCH_CALENDAR_FULFILLED}`: {
        return {
          ...state,
          workoutCalendar: action.payload,
        };
      }
      case `${namespace}/${FETCH_HISTORY_START}`: {
        return {
          ...state,
          isEndOfWorkoutHistoryList: false,
          isLoadingHistory: true,
          workoutHistoryPage: 1,
        };
      }
      case `${namespace}/${FETCH_HISTORY_FULFILLED}`: {
        return {
          ...state,
          isEndOfWorkoutHistoryList:
            !!(!action.payload || action.payload.length < 5),
          isLoadingHistory: false,
          workoutHistory: action.payload,
          workoutHistoryPage: 1,
        };
      }
      case `${namespace}/${FETCH_HISTORY_REJECTED}`: {
        return {
          ...state,
          isLoadingHistory: false,
          workoutHistory: action.payload,
        };
      }
      case `${namespace}/${FETCH_MORE_HISTORY_START}`: {
        return {
          ...state,
          isLoadingHistory: true,
        };
      }
      case `${namespace}/${FETCH_MORE_HISTORY_FULFILLED}`: {
        return {
          ...state,
          isEndOfWorkoutHistoryList:
            !!(!action.payload || action.payload.length < 5),
          isLoadingHistory: false,
          workoutHistory: [...state.workoutHistory, ...action.payload],
          workoutHistoryPage: state.workoutHistoryPage + 1,
        };
      }
      case `${namespace}/${FETCH_MORE_HISTORY_REJECTED}`: {
        return {
          ...state,
          isLoadingHistory: false,
          workoutHistory: action.payload,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_START}`: {
        return {
          ...state,
          isLoadingDocument: true,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_FULFILLED}`: {
        return {
          ...state,
          isLoadingDocument: false,
          workoutDocument: action.payload,
        };
      }
      case `${namespace}/${FETCH_DOCUMENT_REJECTED}`: {
        return {
          ...state,
          isLoadingDocument: false,
          workoutDocument: action.payload,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingExercises: true,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingExercises: false,
          exercises: action.payload,
        };
      }
      case `${namespace}/${FETCH_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingExercises: false,
          exercises: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingSimExercises: true,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exerciseSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_START}`: {
        return {
          ...state,
          isLoadingSimExercises: true,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_FULFILLED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_SIM_TYPE_EXERCISES_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${FETCH_JOURNAL_START}`: {
        return {
          ...state,
          isLoadingJournal: true,
        };
      }
      case `${namespace}/${FETCH_JOURNAL_FULFILLED}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              journalEntry: {
                $set: action.payload.data,
              },
            },
          },
        });
      }
      case `${namespace}/${FETCH_JOURNAL_REJECTED}`: {
        return {
          ...state,
          isLoadingSimExercises: false,
          exercisesSimilar: action.payload,
        };
      }
      case `${namespace}/${SELECT_WORKOUT}`: {
        return {
          ...state,
          activeQuestionIndex: 0,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: action.payload.index,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
          isWorkoutActive: true,
        };
      }
      case `${namespace}/${NEXT_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: state.activeWorkoutIndex + 1,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
        };
      }
      case `${namespace}/${PREV_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: action.payload.id,
          activeWorkoutIndex: state.activeWorkoutIndex - 1,
          activeExerId: action.payload.exerId,
          activeExerType: action.payload.exerType,
        };
      }
      case `${namespace}/${UPDATE_WORKOUTS_START}`: {
        return {
          ...state,
          isWorkoutSaving: true,
        };
      }
      case `${namespace}/${UPDATE_WORKOUTS_FULFILLED}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              $merge: action.payload.data.workoutItem,
            },
          },
          additionalWorkoutsData: {
            $set: action.payload.data.additionalData || {},
          },
        });
      }
      case `${namespace}/${UPDATE_WORKOUTS_REJECTED}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workoutsError: {
            $set: action.payload.data,
          },
        });
      }
      case SET_ADDITIONAL_WORKOUTS_DATA: {
        return update(state, {
          additionalWorkoutsData: {
            $merge: action.payload,
          },
        });
      }
      case CLEAR_ADDITIONAL_WORKOUTS_DATA: {
        return {
          ...state,
          additionalWorkoutsData: {},
        };
      }
      case SET_IS_ADDITIONAL_WORKOUTS: {
        return {
          ...state,
          isAdditionalWorkouts: action.payload,
        };
      }
      case `${namespace}/${UPDATE_SUBSTITUTE_WORKOUT}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              $set: action.payload.data,
            },
          },
        });
      }
      case `${namespace}/${UPDATE_JOURNAL_FULFILLED}`: {
        return update(state, {
          workouts: {
            [action.payload.index]: {
              journalEntries: {
                $set: [action.payload.data],
              },
            },
          },
        });
      }
      case `${namespace}/${COMPLETE_WORKOUT}`: {
        return {
          ...state,
          activeWorkout: null,
          activeWorkoutIndex: null,
          isWorkoutActive: false,
          isWorkoutComplete: true,
        };
      }
      case `${namespace}/${SUBMIT_PIN_START}`: {
        return {
          ...state,
          isPinLoading: true,
          isPinVerified: false,
        };
      }
      case `${namespace}/${SUBMIT_PIN_FULFILLED}`: {
        return {
          ...state,
          isPinLoading: false,
          isPinVerified: true,
        };
      }
      case `${namespace}/${SUBMIT_PIN_REJECTED}`: {
        return {
          ...state,
          isPinLoading: false,
          isPinRejected: true,
          isPinVerified: false,
        };
      }
      case `${namespace}/${SELECT_DATE}`: {
        return {
          ...state,
          activeDate: action.payload,
          activeWorkout: null,
          workoutsBySuperset: {},
        };
      }
      case `${namespace}/${CLOSE_WORKOUT}`: {
        return {
          ...state,
          activeQuestionIndex: null,
          activeWorkout: null,
          activeWorkoutIndex: null,
          isWorkoutActive: false,
          isWorkoutComplete: false,
        };
      }
      case `${namespace}/${CLOSE_PANEL}`: {
        return {
          ...state,
          athleteChosen: false,
          activeAthlete: '',
          activeQuestionIndex: null,
          activeWorkout: null,
          activeWorkoutIndex: null,
          athleteId: '',
          athleteAvatar: '',
          isCalendarView: true,
          isProgramView: false,
          isWorkoutComplete: false,
          isWorkoutActive: false,
          workoutCount: 0,
          workoutCalendar: [],
        };
      }
      case `${namespace}/${OPEN_MODAL}`: {
        return {
          ...state,
          isModalOpen: true,
          activeModal: action.payload,
        };
      }
      case `${namespace}/${CLOSE_MODAL}`: {
        return {
          ...state,
          isModalOpen: false,
          activeModal: '',
        };
      }
      case `${namespace}/${OPEN_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: true,
          activeAthlete: action.payload.name,
          activeDate: action.payload.today,
          athleteId: action.payload.id,
          athleteAvatar: action.payload.avatar,
        };
      }
      case `${namespace}/${CLOSE_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: false,
        };
      }
      case `${namespace}/${CANCEL_PINPAD}`: {
        return {
          ...state,
          isPinPadOpen: false,
          activeAthlete: '',
          athleteId: '',
          athleteAvatar: '',
        };
      }
      case `${namespace}/${SELECT_QUESTION}`: {
        return {
          ...state,
          activeQuestionId: action.payload.id,
          activeQuestionIndex: action.payload.index,
        };
      }
      case `${namespace}/${NEXT_QUESTION}`: {
        return {
          ...state,
          activeQuestionIndex: state.activeQuestionIndex + 1,
        };
      }
      case `${namespace}/${PREV_QUESTION}`: {
        return {
          ...state,
          activeQuestionIndex: state.activeQuestionIndex - 1,
        };
      }
      case `${namespace}/${RESTART_QUESTIONS}`: {
        return {
          ...state,
          activeQuestionIndex: 0,
          activeQuestionId: null,
        };
      }
      case `${namespace}/${STORE_PANEL_HEIGHT}`: {
        return {
          ...state,
          panelDimensions: {
            height: action.payload.height,
            width: action.payload.width,
          },
          isDimensionSet: true,
        };
      }
      case `${namespace}/${STORE_FORMIK_VALUES}`: {
        return {
          ...state,
          totalFormikValues: action.payload,
        };
      }
      case `${namespace}/${STORE_WORKOUTS_BY_SUPERSET}`: {
        return {
          ...state,
          workoutsBySuperset: action.payload,
        };
      }
      case `${namespace}/${INCREASE_COMPLETED_SETS}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workoutsBySuperset: {
            [action.payload.group]: {
              [action.payload.workoutIndex]: {
                completed_sets: {
                  $set: state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets !== state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].sets
                    ? state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets + 1
                    : state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets,
                },
              },
            },
          },
        });
      }
      case `${namespace}/${DECREASE_COMPLETED_SETS}`: {
        return update(state, {
          isLoadingJournal: {
            $set: false,
          },
          workoutsBySuperset: {
            [action.payload.group]: {
              [action.payload.workoutIndex]: {
                completed_sets: {
                  $set: state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets > 0
                    ? state.workoutsBySuperset[action.payload.group][action.payload.workoutIndex].completed_sets - 1
                    : 0,
                },
              },
            },
          },
        });
      }
      case `${namespace}/${SET_IS_NAVIGATING_SUPERSETS}`: {
        return {
          ...state,
          isNavigatingSuperset: action.payload,
        };
      }
      case `${namespace}/${SET_SHOW_SESSION_BREAK_ITEMS}`: {
        return update(state, {
          workouts: {
            [action.payload.index]: {
              showSessionBreakItems: {
                $set: action.payload.value,
              },
            },
          },
        });
      }
      case `${namespace}/${SET_IS_CALENDAR_PROGRAM_VIEW}`: {
        return {
          ...state,
          isCalendarView: action.payload.calendar,
          isProgramView: action.payload.program,
        };
      }
      case `${namespace}/${SET_IS_PROGRAM_LIST_MODAL}`: {
        return {
          ...state,
          isProgramListModalShowing: action.payload,
        };
      }
      case `${namespace}/${SET_IS_RESET_PROGRAM_MODAL_SHOWING}`: {
        return {
          ...state,
          isResetProgramModalShowing: action.payload,
        };
      }
      case `${namespace}/${SET_IS_PROGRAM_INFO_MODAL_SHOWING}`: {
        return {
          ...state,
          isProgramInfoModalShowing: action.payload,
        };
      }
      case `${namespace}/${SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING}`: {
        return {
          ...state,
          isCompleteProgramModalShowing: action.payload,
        };
      }
      case `${namespace}/${SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING}`: {
        return {
          ...state,
          isCompleteProgramWorkoutModalShowing: action.payload,
        };
      }
      case `${namespace}/${SET_IS_SKIP_WORKOUT_MODAL_SHOWING}`: {
        return {
          ...state,
          isSkipWorkoutModalShowing: action.payload,
        };
      }
      case `${namespace}/${CLEAR_PROGRAM_DATA}`: {
        return {
          ...state,
          currentNonDateDay: {},
          currentSelectedProgram: {},
          nextWorkoutSession: {},
          programs: [],
          programWeek: [],
          programWeeks: [],
        };
      }
      case `${namespace}/${UPDATE_SUBSTITUTE_PROGRAM_WORKOUT}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workouts: {
            [action.payload.index]: {
              $set: action.payload.data,
            },
          },
        });
      }
      case `${namespace}/${FETCH_PROGRAMS_START}`: {
        return {
          ...state,
          isProgramListItemsLoading: true,
        };
      }
      case `${namespace}/${FETCH_PROGRAMS_FULFILLED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
          programs: action.payload,
          programsError: '',
        };
      }
      case `${namespace}/${FETCH_PROGRAMS_REJECTED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
          programsError: action.payload,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WORKOUTS_START}`: {
        return {
          ...state,
          isLoading: true,
          isAdditionalWorkouts: action.payload,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WORKOUTS_FULFILLED}`: {
        return {
          ...state,
          isLoading: false,
          workoutData: action.payload.data,
          workouts: action.payload.data.workoutItems,
          workoutsError: '',
          workoutCount: action.payload.data.workoutItems.length,
          currentNonDateDay: action.payload.day,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WORKOUTS_REJECTED}`: {
        return {
          ...state,
          isLoading: false,
          workouts: [],
          workoutsError: action.payload,
        };
      }
      case `${namespace}/${FETCH_NEXT_WORKOUT_SESSION_FULFILLED}`: {
        return {
          ...state,
          nextWorkoutSession: action.payload,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEKS_START}`: {
        return {
          ...state,
          isProgramListItemsLoading: true,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEKS_FULFILLED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
          programWeeks: action.payload.data,
          currentSelectedProgram: action.payload.program,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEKS_REJECTED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEK_START}`: {
        return {
          ...state,
          isProgramListItemsLoading: true,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEK_FULFILLED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
          programWeek: action.payload.data,
          selectedProgramWeek: action.payload.selectedProgramWeek,
        };
      }
      case `${namespace}/${FETCH_PROGRAM_WEEK_REJECTED}`: {
        return {
          ...state,
          isProgramListItemsLoading: false,
        };
      }
      case `${namespace}/${RESET_PROGRAM_FULFILLED}`: {
        return update(state, {
          currentSelectedProgram: {
            $set: action.payload,
          },
          isResetProgramModalShowing: {
            $set: false,
          },
          programs: {
            [action.payload.programIdx]: {
              $set: action.payload,
            },
          },
        });
      }
      case `${namespace}/${COMPLETE_PROGRAM_FULFILLED}`: {
        return update(state, {
          currentSelectedProgram: {
            $set: action.payload,
          },
          isCompleteProgramModalShowing: {
            $set: false,
          },
          programs: {
            [action.payload.programIdx]: {
              $set: action.payload,
            },
          },
        });
      }
      case `${namespace}/${FINISH_PROGRAM_WORKOUT_FULFILLED}`: {
        return update(state, {
          currentNonDateDay: {
            $set: action.payload,
          },
          isCompleteProgramWorkoutModalShowing: {
            $set: false,
          },
          isSkipWorkoutModalShowing: {
            $set: false,
          },
          programWeek: {
            [action.payload.relativeDisplayDayNum - 1]: {
              $set: action.payload,
            },
          },
        });
      }
      case `${namespace}/${UPDATE_PROGRAM_WORKOUT_START}`: {
        return {
          ...state,
          isWorkoutSaving: true,
        };
      }
      case `${namespace}/${UPDATE_PROGRAM_WORKOUT_FULFILLED}`: {
        return update(state, {
          additionalWorkoutsData: {
            $set: action.payload.data.additionalData || {},
          },
          isWorkoutSaving: {
            $set: false,
          },
          programWeek: {
            [action.payload.dayIdx]: {
              status: {
                $set: action.payload.data.additionalData.dayBasedStatus,
              },
              statusColorCode: {
                $set: action.payload.data.additionalData.dayBasedStatusColorCode,
              },
            },
          },
          currentNonDateDay: {
            status: {
              $set: action.payload.data.additionalData.dayBasedStatus,
            },
          },
          workouts: {
            [action.payload.index]: {
              $merge: action.payload.data.workoutItem,
            },
          },
        });
      }
      case `${namespace}/${UPDATE_PROGRAM_WORKOUT_REJECTED}`: {
        return update(state, {
          isWorkoutSaving: {
            $set: false,
          },
          workoutsError: {
            $set: action.payload,
          },
        });
      }
      case `${namespace}/${FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED}`: {
        return {
          ...state,
          programWeek: action.payload.data,
        };
      }
      case `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START}`:
        return {
          ...state,
          importedDataIntegrationsError: null,
        };
      case `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED}`:
        return {
          ...state,
          importedDataIntegrationsError: null,
          importedDataIntegrations: action.payload,
        };
      case `${namespace}/${FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED}`:
        return {
          ...state,
          importedDataIntegrationsError: action.payload,
        };
      case `${namespace}/${SET_SELECTED_INTEGRATION}`:
        return {
          ...state,
          selectedIntegration: action.payload,
        };
      case `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_START}`:
        return {
          ...state,
          selectedIntegrationData: null,
        };
      case `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED}`:
        return {
          ...state,
          selectedIntegrationData: action.payload,
        };
      case `${namespace}/${FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED}`:
        return {
          ...state,
          selectedIntegrationData: null,
        };
      case `${namespace}/${SET_IS_INTEGRATION_MODAL_SHOWING}`:
        return {
          ...state,
          isIntegrationModalShowing: action.payload,
        };
      default: {
        return {
          ...state,
        };
      }
    }
  };
}
