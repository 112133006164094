import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import programsUiReducer from './programsUiReducer';
import programsDataReducer from './programsDataReducer';

// const authDataPersistConfig = {
//   key: 'data',
//   storage,
//   stateReconciler: hardSet,
// };

export default combineReducers({
  ui: programsUiReducer,
  data: programsDataReducer,
});
