// I copied this file mostly from modal; the biggest difference is that the size
// of the modal is increased to hold more content

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';

import {
  BorderRadius,
  BoxShadow,
  TransitionGlobal,
} from '../../../../../shared/GlobalStyles';

import { setActiveWorkoutsMedia } from '../../../ducks/workoutsActions';

const ModalStyle = {
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '1004',
    top: '0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    minWidth: '300px',
    maxWidth: '680px',
    backgroundColor: '#222',
    borderRadius: `${BorderRadius}`,
    boxShadow: `${BoxShadow}`,
  },
};

export const ModalContentWrapper = styled('div')`
  display: flex;
  flex-grow: 1;
  background-color: ${(props) => props.theme.backgroundColorL2};
  .select__control, .select__menu, textarea {
    background-color: ${(props) => props.theme.backgroundColorL3};
    color: ${(props) => props.theme.textColor};
    border-color: ${(props) => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${(props) => props.theme.textColor};
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
    }
    .title-container {
      display: none;
    }
  }
`;

const VideoPlayerModal = ({
  children,
}) => {
  const dispatch = useDispatch();

  const modalClose = () => {
    dispatch(setActiveWorkoutsMedia({}));
  };

  const activeWorkoutsMedia = useSelector((state) => state.workouts.data.activeWorkoutsMedia);

  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);
            &:focus {
              outline: -webkit-focus-ring-color auto 0px!important;
            }
          }

          .ReactModal__Overlay {
            background-color: rgba(216, 216, 216, 0.5);
            opacity: 1;
            z-index: 1004!important;
            justify-content: center;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .ReactModal__Content {
              width: 75vw!important;
            } 
          }

          @media only screen and (max-device-width: 767px) {
            .ReactModal__Content {
              min-width: 95%!important;
            }
          }
        `}
      />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={!!Object.keys(activeWorkoutsMedia).length}
        onRequestClose={modalClose}
        style={ModalStyle}
        className='global-modal'
        overlayClassName='overlay'
      >
        <ModalContentWrapper>
          {children}
        </ModalContentWrapper>
      </ReactModal>
    </>
  );
};

if (process.env.STORYBOOK_RUNNING === undefined) {
  ReactModal.setAppElement('#app');
}

VideoPlayerModal.propTypes = {
  children: PropTypes.node,
};

VideoPlayerModal.defaultProps = {
  children: '',
};

export default VideoPlayerModal;
