import React, {
  useEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logger from 'js-logger';
import Select from 'react-select';
import { Form, FieldArray } from 'formik';
import Shimmer from 'react-shimmer-effect';
import { createSelector } from 'reselect';
import { matchSorter } from 'match-sorter';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';

import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import FormHandler, { FormGroup } from '../../../shared/components/FormHandler/FormHandler';
import Search from '../../../shared/components/Search/Search';
import Button from '../../../shared/components/Button/Button';

import iconSet from '../../../shared/images/teambuildr-selection.json';

import { TransitionGlobal } from '../../../shared/GlobalStyles';
import useReporting from './hooks/useReporting';
import { saveSidebarFilter } from '../ducks/reportingActions';
import { reportingFilterTracker } from '../../../shared/utils/amplitudeHelper';

const SidebarContent = styled('div')`
  height: 100%;
  overflow: hidden;
  form {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .Shimmer-shimmer-0-1-1 {
    animation: shimmerAnim 0.7s infinite linear!important;
  }
`;

const SelectionContainer = styled('div')`
  /* margin-top: 30px; */
`;

const Selection = styled('div')`
  color: ${(props) => {
    // create dynamic text color condition for primary color if it's bright or dark.
    const dynamicTextColor = props.theme.isPrimaryDarkTheme
      ? props.theme.colors.text
      : 'white';

    return (
      props.isLoading ? 'transparent' : dynamicTextColor
    );
  }}!important;
  margin-bottom: 15px;
  display: flex;
  cursor: pointer;
  opacity: ${(props) => (props.isInArray ? 1 : 0.4)};
  
  p {
    color: ${(props) => (props.isLoading ? 'transparent' : props.theme.colors.text)};
  }

  .list-oval {
    background-color: ${(props) => (
    props.isInArray
      ? props.theme.colors.lightBlue
      : props.theme.colors.lightText[2]
  )}
  }
`;

const ListOval = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background: ${(props) => props.theme.colors.lightBlue};
  /* background: #4464dc; */
  margin-right: 10px;
  margin-top: 3px;
`;

const SelectionList = styled('div')`
`;

const TopFormContainer = styled('div')`
  flex-grow: 0;
  z-index: 101;
  padding: 30px 20px 20px 20px;

  .selection-indicator {
    position: absolute;
    margin-top: -65px;
    margin-left: 40px;
    padding: 20px;
    font-size: 13px;
    color: ${(props) => props.theme.colors.lightText[0]};
    cursor: pointer;
  }
  
  .react-select__control, .search-container {
    width: 100%!important;
    background-color: #f0f0f0!important;
    box-shadow: none;
    border: 0px!important;
    transition: ${TransitionGlobal};
    padding: 20px 10px;
    background-position: 20px 15px!important;
    border-radius: 5px!important;
    &:hover {
      /* border-color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')}!important; */
    }
  }
  
  .search-container {
    padding: 10px 30px 10px 45px!important;
  }

  .search-container input{
    font-weight: 600;
    /* color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')}!important; */
  }

  .css-dvua67-singleValue {
    /* color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')}; */

  }
  .react-select__single-value--is-disabled {
    color: gray;
    font-size: 13px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__value-container {
    margin-bottom: 20px;
    margin-left: 40px;
  }


`;

const BottomFormContainer = styled('div')`
  flex-grow: 1;
  overflow: auto;
  padding-left: 30px;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;  
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  }

`;

const filterTypeOptions = [
  {
    value: '1', label: 'Select By Athlete', indicator: 'athlete', icon: 'user',
  },
  {
    value: '2', label: 'Select By Group', indicator: 'group', icon: 'group',
  },
  {
    value: '3', label: 'Select By Calendar', indicator: 'calendar', icon: 'calendar',
  },
];

const loadingData = [
  { first: 'Rocky', last: 'Balboa' },
  { first: 'Jess', last: 'Bhamra' },
  { first: 'Calvin', last: 'Cambridge' },
  { first: 'Julius', last: 'Campbell' },
  { first: 'Mel', last: 'Clark' },
  { first: 'Charlie', last: 'Conway' },
  { first: 'Crash', last: 'Davis' },
  { first: 'Shane', last: 'Falco' },
  { first: 'Daniel', last: 'Larusso' },
  { first: 'Boobie', last: 'Miles' },
  { first: 'Becky', last: 'O\'Shea' },
  { first: 'Vince', last: 'Papale' },
  { first: 'Jules', last: 'Paxton' },
  { first: 'James', last: 'Peters' },
];

const SidebarHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    margin-bottom: 15px;
    padding: 0px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
`;

const IconContainer = styled('div')`
  position: absolute;
  margin-top: -65px;
  margin-left: 20px;
  cursor: pointer;

  svg {
    path {
      fill: ${(props) => props.theme.colors.text}
    }
  }
`;

const ReportingSidebarFilter = () => {
  const {
    handleFetchSelections,
    handleSearchSelections,
  } = useReporting();

  const selectRef = useRef(null);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isLoadingSelections = useSelector((state) => state.reporting.ui.isLoadingSelections);
  const searchValue = useSelector((state) => state.reporting.data.selectionSearch);
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);
  const [isSelectorOpen, setSelectorOpen] = useState(false);

  const theme = useTheme();
  const dispatch = useDispatch();

  const saveFilter = (values) => {
    dispatch(saveSidebarFilter(values));
  };

  useEffect(() => {
    console.log(selectRef);
  }, []);

  // Reselect Groups or Athlete Selector for displaying search results.
  const getVisibleSelections = createSelector(
    (state) => state.reporting.data.filterSelections,
    (state) => state.reporting.data.selectionSearch,
    (filterSelections, selectionSearch) => {
      const filterSearch = matchSorter(filterSelections, selectionSearch, { keys: ['first', 'last', 'name'] });
      return filterSearch;
    },
  );

  // Implement reselect selector that's filtered with search in the Redux selector.
  const filterSelection = useSelector((state) => (
    getVisibleSelections(state)
  ));

  const loadingAnimation = useSpring({ opacity: isLoadingSelections ? 1 : 0, zIndex: 0, position: 'absolute' });
  const nonLoadingAnimation = useSpring({ opacity: isLoadingSelections ? 0 : 1, zIndex: 100, position: 'relative' });

  const { accountCode } = currentUser;

  const defaultFilterType = filterTypeOptions.filter(
    (filter) => (sidebarFilter.filterType ? filter.value === sidebarFilter.filterType.value : filter.value === '1'),
  );

  useEffect(() => {
    if (accountCode !== undefined) {
      handleFetchSelections(accountCode, defaultFilterType[0].value);
    }
  }, [currentUser]);

  const customSelectStyles = {
    option: () => ({
      fontSize: '15px',
      fontWeight: '600',
    }),
  };

  return (
    <>
      <SidebarContent>
        <FormHandler
          initialValues={
            Object.keys(sidebarFilter).length !== 0 ? (
              sidebarFilter
            ) : (
              {
                filterType: filterTypeOptions[0],
                filterIds: [],
                search: '',
              }
            )
          }
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              Logger.debug('Form Values:', values);
            });
          }}
        >
          {(formProps) => {
            saveFilter(formProps.values);

            const { filterIds } = formProps.values;

            return (
              <Form>
                <TopFormContainer>
                  <SidebarHeader>
                    <HeaderText
                      fontWeight={600}
                      fontSize={16}
                    >
                      Filter
                    </HeaderText>

                    {filterIds.length !== 0 ? (
                      <Button
                        onClick={(e) => {
                          formProps.setFieldValue('filterIds', []);
                        }}
                        cta='Clear Filter'
                        icon='remove'
                        noBorder
                        customColor='transparent'
                      />
                    ) : null}
                  </SidebarHeader>
                  <FormGroup>
                    <Select
                      isDisabled={isLoadingSelections}
                      classNamePrefix='react-select'
                      closeMenuOnSelect
                      defaultValue={
                        defaultFilterType[0]
                      }
                      options={filterTypeOptions}
                      openMenuOnFocus
                      onChange={(option) => {
                        formProps.setFieldValue('filterType', option);
                        formProps.setFieldValue('filterIds', []);
                        handleFetchSelections(accountCode, option.value);
                        Logger.debug('Fetch Selections Params:', accountCode, option.value);
                        selectRef.current.blur();

                        // Amplitude tracker
                        reportingFilterTracker('Type Selection Clicked', option.indicator);
                      }}
                      style={customSelectStyles}
                      value={formProps.values.selectBy}
                      ref={selectRef}
                    />
                    <Text
                      className='selection-indicator'
                      onClick={() => {
                        selectRef.current.focus();
                      }}
                    >
                      {`
                        ${filterIds.length === 0 ? 'No' : filterIds.length} 
                        ${formProps.values.filterType.indicator}${filterIds.length === 1 ? ' has' : 's have'} been selected.
                      `}
                    </Text>
                    <IconContainer onClick={() => setSelectorOpen(!isSelectorOpen && true)}>
                      <IcomoonReact
                        className='icomoon'
                        iconSet={iconSet}
                        size='30'
                        icon={formProps.values.filterType.icon}
                      />
                    </IconContainer>
                  </FormGroup>
                  <FormGroup>
                    <Search
                      name='search'
                      isExpanded
                      handleChange={(e) => handleSearchSelections(e.target.value)}
                      value={searchValue}
                      noBorder
                    />
                  </FormGroup>
                </TopFormContainer>
                <BottomFormContainer>
                  <FormGroup>
                    <SelectionContainer>
                      <SelectionList>
                        <FieldArray
                          name='filterIds'
                          render={(arrayHelpers) => (
                            <>
                              <animated.div style={loadingAnimation}>
                                {loadingData.map((selection, index) => (
                                  <Selection isLoading>
                                    <Shimmer>
                                      <ListOval />
                                      <Text
                                        color='white'
                                        fontSize={13}
                                        fontWeight={600}
                                      >
                                        {`${selection.first} ${selection.last}`}
                                      </Text>
                                    </Shimmer>
                                  </Selection>
                                ))}
                              </animated.div>
                              <animated.div style={nonLoadingAnimation}>
                                {filterSelection !== undefined && (
                                  filterSelection.map((selection, index) => {
                                    // Find index of item in selectedItems formik array
                                    const selectionIndex = formProps.values.filterIds.map(
                                      (athl) => athl.id,
                                    ).indexOf(selection.id);

                                    // Check if item is in formik array if index is greater than -1.
                                    const isInArray = selectionIndex > -1;

                                    const handleSelectionClick = () => {
                                      if (isInArray) {
                                        arrayHelpers.remove(selectionIndex);
                                      } else {
                                        arrayHelpers.push(selection);
                                      }
                                      Logger.debug(formProps.values);
                                      saveFilter(formProps.values);

                                      // Amplitude tracker
                                      reportingFilterTracker('Selection Clicked', formProps.values.filterType.indicator);
                                    };

                                    return (
                                      <Selection
                                        onClick={handleSelectionClick}
                                        isInArray={isInArray}
                                      >
                                        <ListOval className='list-oval' />
                                        <Text
                                          color={theme.colors.text}
                                          fontSize={13}
                                          fontWeight={600}
                                        >
                                          {(formProps.values.filterType.value === '1' && !isLoadingSelections) && `${selection.first} ${selection.last}`}
                                          {((formProps.values.filterType.value === '2' || formProps.values.filterType.value === '3') && !isLoadingSelections) && `${selection.name}`}
                                        </Text>
                                      </Selection>
                                    );
                                  })
                                )}
                              </animated.div>
                            </>
                          )}
                        />
                      </SelectionList>
                    </SelectionContainer>
                  </FormGroup>
                </BottomFormContainer>
              </Form>
            );
          }}
        </FormHandler>
      </SidebarContent>
    </>
  );
};

export default ReportingSidebarFilter;
