import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ChildTable from './ChildTable';

const ChildTableWrapper = ({
  data,
  activeSearchString,
  isArchivedShowing,
  setCurrentRow,
  setActiveModal,
}) => {
  const [isChildChecksDisabled, setIsChildChecksDisabled] = useState(false);
  const selectedChildRows = useSelector((state) => state.calendars.data.selectedChildRows);
  const activeModal = useSelector((state) => state.calendars.data.activeModal);

  const selectedRows = useSelector((state) => state.calendars.data.selectedRows);

  useEffect(() => {
    if ((!isArchivedShowing || (isArchivedShowing && activeModal === 'delete_cals_modal')) && data.id && Object.keys(selectedRows).includes(data.id.toString())) {
      setIsChildChecksDisabled(true);
    } else {
      setIsChildChecksDisabled(false);
    }
  }, [selectedChildRows, selectedRows, activeModal]);

  return (
    <ChildTable
      data={data}
      activeSearchString={activeSearchString}
      isArchivedShowing={isArchivedShowing}
      isChildChecksDisabled={isChildChecksDisabled}
      setCurrentRow={setCurrentRow}
      setActiveModal={setActiveModal}
      selectedChildRows={selectedChildRows}
      selectedRows={selectedRows}
    />
  );
};

ChildTableWrapper.propTypes = {
  data: PropTypes.instanceOf(Object),
  activeSearchString: PropTypes.string.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
  setCurrentRow: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
};

ChildTableWrapper.defaultProps = {
  data: undefined,
};

export default ChildTableWrapper;
