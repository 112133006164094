import React, { Fragment, useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Form } from 'formik';
import { animated, useTransition, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useSelector } from 'react-redux';

import useBoard from './hooks/useBoard';
import useLeaderboard from './hooks/useLeaderboard';
import iconSet from '../../../shared/images/teambuildr-selection.json';

import Text from '../../../shared/components/Text/Text';
import FormHandler from '../../../shared/components/FormHandler/FormHandler';
import Button from '../../../shared/components/Button/Button';
import BoardFormFirstSlide from './BoardFormFirstSlide';
import BoardFormSecondSlide from './BoardFormSecondSlide';
import Spinner from '../../../shared/components/Spinner/Spinner';
import { TransitionGlobal } from '../../../shared/GlobalStyles';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 30px;
  margin: 0 auto;
  margin-top: 30px;
  z-index: 10;
  position: relative;
`;

const NavCircle = styled('div')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;


const defaultSlideStyle = { position: 'absolute', width: '100%' };

const FormAddBoard = ({
  closeModal,
  leaderboardId,
}) => {
  const leaderboardForm = useSelector(
    (state) => state.leaderboard.data.leaderboards[leaderboardId].leaderboardForm,
  );

  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();

  const {
    currentUser,
    exercises,
    groups,
    handleFetchExercises,
    handleFetchLeaderboard,
    isLoadingExercises,
    isLoadingGroups,
    isLoadingLeaderboard,
    leaderboardError,
  } = useLeaderboard();

  const { accountCode } = currentUser;

  // Retrieve height from absolute positioned slide component with slideRef,
  // and store it in slideDimensions state object for later use.
  useLayoutEffect(() => {
    if (slideRef.current) {
      setSlideDimensions({
        height: slideRef.current.clientHeight,
        width: slideRef.current.clientWidth,
      });
    }
  }, [activeSlide]);

  const formAnimation = useSpring({
    opacity: isLoadingLeaderboard ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isLoadingLeaderboard ? 1 : 0, zIndex: 0 });

  useEffect(() => {
    handleFetchExercises(accountCode, leaderboardForm.category);
  }, []);

  // Initialize slide transition animation.
  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(-25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: activeSlide === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(25%, 0, 0)' },
  });

  const fetchExercises = (exerciseType) => {
    handleFetchExercises(accountCode, exerciseType);
  };

  return (
    <Fragment>
      <NavigationWrapper>
        {activeSlide === 1 && (
          <Text
            className='back arrow-div'
            onClick={() => {
              setActiveSlide(0);
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='left-arrow'
            />
          </Text>
        )}
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <FormHandler
        initialValues={
          {
            accountCode,
            category: leaderboardForm.category,
            compareTo: leaderboardForm.compareTo,
            compareDates: leaderboardForm.compareDates,
            exerciseId: leaderboardForm.exerciseId,
            exerciseName: leaderboardForm.exerciseName,
            groups: leaderboardForm.groups,
            mustBeInAllGroups: leaderboardForm.mustBeInAllGroups,
            gender: leaderboardForm.gender,
            rangeType: leaderboardForm.rangeType,
            rangeDates: leaderboardForm.rangeDates,
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            handleFetchLeaderboard(values, 'edit', leaderboardId);
            // closeModal();
          });
        }}
      >
        {/* Import formik props as an argument in a function. */}
        {(formProps) => (
          <Form>
            {/* Form Slider container that dynamically changes height
                based on height calculated in slideDimensions. */}
            <animated.div style={formAnimation}>
              {leaderboardError && <FieldError className='text-center'>{leaderboardError.message}</FieldError>}
              <SlideContainer
                height={slideDimensions.height}
              >
                {/* Slider transition that mounts the active slide
                    based on the state object, activeSlide. */}
                {slideTransition.map(({ item, props }) => (
                  item === 0 ? (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <BoardFormFirstSlide
                        isExercisesDisabled={exercises.length === 0}
                        isGroupsDisabled={groups.length === 0}
                        exercises={exercises}
                        groups={groups}
                        fetchExercises={fetchExercises}
                        formProps={formProps}
                        isLoadingExercises={isLoadingExercises}
                        isLoadingGroups={isLoadingGroups}
                      />
                    </animated.div>
                  ) : (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <BoardFormSecondSlide formProps={formProps} />
                    </animated.div>
                  )))
                }
              </SlideContainer>

              <SliderNav>
                <NavCircle
                  className={activeSlide === 0 ? 'active' : ''}
                  onClick={() => {
                    const defaultOption = exercises.filter((exercise) => exercise.id === formProps.values.exercise);
                    console.log('yo', { ...defaultOption[0] });
                    setActiveSlide(0);
                  }}
                />
                <NavCircle
                  className={activeSlide === 1 ? 'active' : ''}
                  onClick={() => setActiveSlide(1)}
                />
              </SliderNav>
            </animated.div>
            <animated.div style={spinnerAnimation}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </animated.div>

            {activeSlide !== 0 && (
              <Button
                cta='Update Board'
                type='submit'
                fullWidth
                noBorder
                large
                square
                primary
                bottom
              />
            )}
          </Form>
        )}
      </FormHandler>
      {activeSlide === 0 && (
        <Button
          onClick={() => setActiveSlide(1)}
          cta='next'
          type='button'
          fullWidth
          noBorder
          large
          square
          primary
          bottom
        />
      )}
    </Fragment>
  );
};

FormAddBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  leaderboardId: PropTypes.number.isRequired,
};

export default FormAddBoard;
