import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import { ColorBlack, ColorGreen } from '../../../../../shared/GlobalStyles';

export const ButtonStyled = styled('button')`
  display: flex;
  align-items: center;
  flex-direction: row;
  font-family: 'Nunito Sans';
  font-size: ${props => (props.large ? '14px' : '12px')};
  font-weight: 900;
  letter-spacing: 1.5px;
  height: ${props => (props.large && '48px')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  background: ${(props) => {
    const isCustom = props.customColor ? props.customColor : props.theme.colorAccent;
    const isPrimary = props.primary ? isCustom : props.theme.colors.background[1];
    const isDisabled = props.disabled ? '#d3d3d3' : isPrimary;
    return (
      props.complete ? ColorGreen : isDisabled
    );
  }};
  border: ${(props) => {
    const hasNoBorder = props.noBorder ? '0px' : `1px solid ${props.theme.colors.noBorderColor}`;
    return (
      props.complete ? `1px solid ${ColorGreen}` : hasNoBorder
    );
  }};
  border-radius: ${(props) => {
    const isRounded = props.rounded ? '4px' : '20px';
    return (
      props.square ? '0px' : isRounded
    );
  }};
  text-transform: uppercase;
  color: ${(props) => {
    const isAccentDarkTheme = props.theme.isAccentDarkTheme ? 'white' : ColorBlack;
    return (
      props.primary || props.complete ? isAccentDarkTheme : props.theme.textColor
    );
  }};
  padding: 8px 18px;
  justify-content: center;
  align-self: ${props => (props.bottom ? 'flex-end' : '')};
  transition: all 250ms ease-in-out;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? '.8' : '1')};
  & svg {
    display: ${props => (props.icon ? 'block' : 'none')}!important;
    align-self: center;
    margin-right: 10px;
    path {
      transition: all 250ms ease-in-out;
      fill: ${props => (props.complete ? 'rgba(255,255,255)!important' : props.theme.textColor)}
    }
  }

  /* &:hover {
    background-color: ${props => (props.noHover ? '' : props.theme.colorAccent)};
    border: ${(props) => {
      const isNoHover = props.noHover ? `1px solid ${props.theme.colors.noBorderColor}` : `1px solid ${props.theme.colorAccent}`;
      return props.noBorder ? '0px' : isNoHover;
    }};
    color: ${(props) => {
      const noHover = props.noHover ? 'black' : 'white';
      return (
        props.theme.isAccentDarkTheme ? noHover : '#444444'
      );
    }};
    // opacity: .7;
    transition: all 250ms ease-in-out;
    & svg {
      path {
        transition: all 250ms ease-in-out;
        fill: ${props => (props.theme.isAccentDarkTheme ? 'rgb(255,255,255)!important' : 'rgb(68,68,68)!important')} ;
      }
    }
  } */
`;

const Icon = styled('img')`
  height: 20px;
  margin-right: 15px;
`;

const NoHoverDeleteButton = ({
  bottom,
  buttonRef,
  cta,
  className,
  complete,
  customColor,
  customIcon,
  disabled,
  fullWidth,
  icon,
  id,
  large,
  name,
  noBorder,
  noHover,
  onClick,
  primary,
  rounded,
  square,
}) => (
  <ButtonStyled
    bottom={bottom}
    className={className}
    complete={complete}
    customColor={customColor}
    disabled={disabled}
    fullWidth={fullWidth}
    icon={icon}
    large={large}
    name={name}
    id={id}
    noBorder={noBorder}
    noHover={noHover}
    onClick={onClick}
    primary={primary}
    ref={buttonRef}
    square={square}
    rounded={rounded}
  >
    {customIcon ? (
      <Icon className='fluid' src={customIcon} alt='button' />
    ) : (
      icon && (
        <IcomoonReact
          iconSet={iconSet}
          size={12}
          icon={icon && icon}
        />
      )
    )}
    {cta}
  </ButtonStyled>
);

NoHoverDeleteButton.propTypes = {
  bottom: PropTypes.bool,
  buttonRef: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  customColor: PropTypes.string,
  customIcon: PropTypes.string,
  complete: PropTypes.bool,
  disabled: PropTypes.bool,
  cta: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  large: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  noBorder: PropTypes.bool,
  noHover: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  rounded: PropTypes.bool,
  square: PropTypes.bool,
};

NoHoverDeleteButton.defaultProps = {
  bottom: false,
  buttonRef: {},
  className: '',
  customColor: null,
  customIcon: null,
  complete: false,
  disabled: false,
  fullWidth: false,
  icon: '',
  large: false,
  name: null,
  id: null,
  noBorder: false,
  noHover: false,
  primary: false,
  rounded: false,
  square: false,
};

export default NoHoverDeleteButton;
