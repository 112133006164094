// component that represents the elements on the left hand side of the workouts component
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-tabs */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBAthletePicker from '../../../../shared/components/TBAthletePicker.js/TBAthletePicker';
import ExertionScore from './ExertionScore';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import {
  setSharedAthlete,
  fetchWorkoutCalendar,
  setIsPrintingOptionsShowing,
  getWorkoutItemsForUserByDate,
  fetchSessionByDate,
  setHiddenWorkoutsToggle,
  setIsProgramListBoxModalShowing,
  fetchWorkoutPrograms,
  setIsDayCarousel,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const LeftColumnWrapper = styled('div')`
	display: flex;
	width: 20%;
	align-items: flex-end;
	flex-direction: column;
  min-width: 180px;
  max-width: 240px;
  @media only screen and (max-width : 768px) {
    display: none;
  }
`;

const PageTitle = styled('div')`
	font-family: 'Nunito Sans';
	font-size: 20pt;
	font-weight: 300;
	width: 100%;
	display: flex;
  min-width: 160px;
`;

const ExertionScoreContainer = styled('div')`
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	min-height: 260px;
	padding: 8px 16px 23px 16px;
	border-radius: 24px;
	box-shadow: ${BoxShadow};
	background: white;
  display: flex;
  flex-direction: column;
`;

const ScoreContainer = styled('div')`
	width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SpinnerContainer = styled('div')`
  display: block;
`;

const TitleText = styled('div')`
  display: flex;
`;

const PageOptionsContainer = styled('div')`
	margin-top: 20px;
	width: 100%;
	display: flex;
	align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const PageOptionText = styled('div')`
  font-family: 'Nunito Sans';
  margin-left: 5px;
  color: white;
  font-size: 11px;
  margin-top: 2px;
`;

const PageOption1 = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 60px;
  padding: 5px;
  min-width: 150px;
  box-shadow: ${BoxShadow};
	background: #444444;
  cursor: pointer;
`;

const PageOption2 = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 60px;
  padding: 5px;
  min-width: 150px;
  box-shadow: ${BoxShadow};
	background: #444444;
  cursor: pointer;
  margin-top: 10px;
`;

const AlertText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const BoxTitle = styled('div')`
	display: flex;
	height: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;

  svg {
    margin-top: 1px;
  }
`;

const BoxTitleText = styled('div')`
	font-size: 14px;
	font-weight: 700;
	margin-left: 5px;
	font-family: 'Nunito Sans';
`;

const WorkoutsLeftColumn = () => {
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const athletes = useSelector(
    (state) => state.workouts.data.athletes,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isExertionScoreLoading = useSelector((state) => state.workouts.ui.isExertionScoreLoading);
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);
  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  const hiddenWorkoutsToggle = useSelector((state) => state.workouts.ui.hiddenWorkoutsToggle);

  const dispatch = useDispatch();

  const hasKeys = (obj) => Object.keys(obj).length > 0;

  const isCurrentUserAdmin = () => hasKeys(currentUser) && currentUser.admin;

  const getFormattedDay = () => activeWorkoutDate || moment().format('YYYY-MM-DD');

  const getStartAndEndDates = (formattedDay) => ({
    startDate: moment(formattedDay).subtract(15, 'days').format('YYYY-MM-DD'),
    endDate: moment(formattedDay).add(15, 'days').format('YYYY-MM-DD'),
  });

  // this is the function that we pass to the athlete picker component
  // when the current user selects a user from the list of athletes, this function runs
  // the shared athlete is a slice of state that we use in various places in the workouts
  // component to fetch specific information from the back end
  const reduxFunction = (athlete) => {
    const interiorId = currentUser.admin ? athlete?.id : currentUser.id;
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          currentUser.id,
          formattedDay,
          false,
          hiddenWorkoutsToggle,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(athlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          athlete.id,
          formattedDay,
          false,
          hiddenWorkoutsToggle,
        ),
      );
    }
    let startDate;
    let endDate;
    if (formattedDay) {
      startDate = (`${moment(formattedDay).subtract(15, 'days').format('YYYY-MM-DD')}`);
      endDate = (`${moment(formattedDay).add(15, 'days').format('YYYY-MM-DD')}`);
    } else {
      startDate = (`${moment().subtract(15, 'days').format('YYYY-MM-DD')}`);
      endDate = (`${moment().add(15, 'days').format('YYYY-MM-DD')}`);
    }
    if (Object.keys(currentUser).length) {
      if ((currentUser.admin && Object.keys(athlete).length) || !currentUser.admin) {
        dispatch(fetchWorkoutCalendar(currentUser, interiorId, startDate, endDate));
      }
    }
    if (Object.keys(currentUser).length) {
      if ((currentUser.admin && Object.keys(sharedAthlete).length) || !currentUser.admin) {
        dispatch(fetchSessionByDate(currentUser, interiorId, formattedDay, {}));
      }
    }
    dispatch(setSharedAthlete(athlete));
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          currentUser.id,
          formattedDay,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(athlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          athlete.id,
          formattedDay,
        ),
      );
    }
  };

  useEffect(() => {
    const formattedDay = getFormattedDay(activeWorkoutDate);
    const { startDate, endDate } = getStartAndEndDates(formattedDay);

    if (isCurrentUserAdmin(currentUser) && hasKeys(sharedAthlete)) {
      dispatch(getWorkoutItemsForUserByDate(
        currentUser,
        sharedAthlete.id,
        formattedDay,
        false,
        hiddenWorkoutsToggle,
      ));
      dispatch(fetchWorkoutCalendar(
        currentUser,
        userId,
        startDate,
        endDate,
        hiddenWorkoutsToggle,
      ));
    }
  }, [hiddenWorkoutsToggle]);

  const toggleFunction = () => {
    dispatch(setHiddenWorkoutsToggle(!hiddenWorkoutsToggle));
  };

  const programSelectTriggerFunction = () => {
    dispatch(setIsProgramListBoxModalShowing(true));
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          currentUser.id,
          formattedDay,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          sharedAthlete.id,
          formattedDay,
        ),
      );
    }
  };

  const fetchSessionByClick = (day) => {
    if (Object.keys(currentUser).length) {
      if ((currentUser.admin && Object.keys(sharedAthlete).length) || !currentUser.admin) {
        dispatch(fetchSessionByDate(currentUser, userId, day, {}));
      }
    }
  };

  const workoutSelectTriggerFunction = () => {
    dispatch(setIsDayCarousel(false));
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    fetchSessionByClick(formattedDay);
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          currentUser.id,
          formattedDay,
          false,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          sharedAthlete.id,
          formattedDay,
          true,
        ),
      );
    }
  };

  return (
    <LeftColumnWrapper>
      <PageTitle>
        Workout Entry
      </PageTitle>
      {/**
       * Only important piece of information to note here
       * is that the TBAthlete picker only renders if the current
       * user is an admin
       */}
      {currentUser.admin ? (
        <div style={{
          marginTop: 30,
          width: '100%',
        }}
        >
          <TBAthletePicker
            list={athletes}
            reduxFunction={reduxFunction}
            visibleContainerItem={sharedAthlete}
            toggleFunction={toggleFunction}
            showToggle={false}
            toggleChecked={hiddenWorkoutsToggle}
          />
        </div>
      ) : null}
      {Object.keys(currentUser).length && !currentUser.admin ? (
        <ExertionScoreContainer>
          <BoxTitle>
            <TitleText>
              <IcomoonReact
                iconSet={iconSet}
                size={17}
                icon='watch-heart-rate'
                color='black'
              />
              <BoxTitleText>Exertion Score</BoxTitleText>
            </TitleText>
            {isExertionScoreLoading && (
            <SpinnerContainer>
              <Spinner saving darkTheme />
            </SpinnerContainer>
            )}
          </BoxTitle>
          <ScoreContainer>
            {currentUser?.deviceConnections
          && currentUser.deviceConnections.length
              ? <ExertionScore />
              : (
                <AlertText>
                  Connect a Wearable via the TeamBuildr mobile app to get an Exertion Score.
                </AlertText>
              )}
          </ScoreContainer>
        </ExertionScoreContainer>
      ) : null}
      <PageOptionsContainer>
        {!currentUser.admin || (currentUser.admin && sharedAthlete.id) ? (
          <PageOption2 onClick={() => {
            if (!isDayCarousel) {
              programSelectTriggerFunction();
            } else {
              workoutSelectTriggerFunction();
            }
          }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon={!isDayCarousel ? 'folder' : 'calendar'}
              color='white'
            />
            <PageOptionText>
              {!isDayCarousel ? 'Program Library' : 'Scheduled Workouts'}
            </PageOptionText>
          </PageOption2>
        ) : null}
        {(!currentUser.admin || (currentUser.admin && sharedAthlete.id)) && !isDayCarousel ? (
          <PageOption2 onClick={() => dispatch(setIsPrintingOptionsShowing(true))}>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='printer'
              color='white'
            />
            <PageOptionText>Print Workout</PageOptionText>
          </PageOption2>
        ) : null}
        {currentUser.admin ? (
          <PageOption2 onClick={() => {
            if (sharedAthlete?.id) {
              window.open(`${process.env.PHP_APP_URL}/calendar/A${sharedAthlete?.id}/${activeWorkoutDate}`, '_blank');
            } else {
              window.open(`${process.env.PHP_APP_URL}/calendar`, '_blank');
            }
          }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='alt-right-arrow'
              color='white'
            />
            <PageOptionText>Go to Calendar</PageOptionText>
          </PageOption2>
        ) : null}
      </PageOptionsContainer>
    </LeftColumnWrapper>
  );
};

WorkoutsLeftColumn.propTypes = { };

export default WorkoutsLeftColumn;
