import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Text from '../../../../shared/components/Text/Text';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import {
  Nunito, BorderRadius, BoxShadow, TransitionGlobal,
} from '../../../../shared/GlobalStyles';

const ProgramsContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background: ${(props) => props.theme.colors.background[0]};
  position: relative;
  margin-top: 50px;

  h1 {
    margin-bottom: 15px;
  }
`;

const IndividualProgramContainer = styled('div')`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LargePrice = styled('span')`
  font-size: 25px;
  font-family: ${Nunito};
  font-weight: 900;
  /* color: ${(props) => props.theme.textColor}; */
  color: white;
  text-transform: uppercase;
  text-align: center;
`;

const SmallPrice = styled('div')`
  font-size: 12px;
  font-family: ${Nunito};
  /* color: ${(props) => props.theme.textColor}; */
  color: white;
  text-transform: uppercase;
`;

const CurrencySymbol = styled('span')`
  font-size: 16px;
  font-weight: 900;
  vertical-align: top;
  line-height: 28px;
  color: white;
`;

const PriceContainer = styled('div')`
display: flex;
flex-direction: column;
align-items: center;
`;

const InfoCardContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 275px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${BorderRadius};
  box-shadow: ${BoxShadow};
  position: relative;
  overflow: hidden;
  cursor: pointer;
  ${(props) => (props.programsLength === 1 ? 'margin: 0 auto;' : '')}

  &:hover {
    .featured-image {
      transform: scale(1.25);
    }
  }
`;

const OuterDetailsContainer = styled('div')`
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    p {
        color: white;
    }
    width: 100%
`;

const InnerDetailsContainer = styled('div')`
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
`;

const InfoDetailsContainer = styled('div')`
  margin-right: 18px;
`;

const InfoSideDetailsContainer = styled('div')`
`;

const ImageCover = styled('img')`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  transition: ${TransitionGlobal};
  transform: scale(1);
`;

const ImageGradient = styled('div')`
  height: 100%;
  width: 100%;
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  z-index: 5;
  background-image: linear-gradient(180deg, transparent, #444444);
`;

const NoTextContainer = styled('div')`
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 16px;
`;

const PublicProgramsGrid = () => {
  const dispatch = useDispatch();
  const programsData = useSelector((state) => state.programs.data.morePrograms) || [];

  const handleReactLink = (routePath) => {
    dispatch(push(`/programs${routePath}`));
  };

  return (
    <ProgramsContainer>
      {programsData && programsData.length > 0 ? (
        <Grid fluid>
          <Row>
            {programsData.map((program, ind) => {
              const featuredImage = optimizeImage(
                `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${program.headerMediaKey}`,
                {
                  resize: {
                    width: 600, height: 400, fit: 'cover',
                  },
                },
              );
              return (
                <Col key={ind} style={{ height: 325, maxHeight: 325 }} sm={12} md={6} lg={4}>
                  <IndividualProgramContainer>
                    <InfoCardContainer programsLength={programsData.length} onClick={() => handleReactLink(program.programPath)} className='info-card'>
                      <ImageCover className='featured-image' src={featuredImage} />
                      <ImageGradient />
                      <OuterDetailsContainer>
                        <InnerDetailsContainer>
                          <InfoDetailsContainer className='info-details-container'>
                            {/* // Left side program details */}
                            <>
                              <Text
                                style={{ textAlign: 'left', overflowWrap: 'anywhere' }}
                                fontSize={23}
                                fontWeight='600'
                              >
                                {program.programName}
                              </Text>
                            </>
                          </InfoDetailsContainer>
                          <InfoSideDetailsContainer>
                            {/* // Right side program details */}
                            <PriceContainer>
                              {program.placeSymbolAfterCurrency
                                ? (
                                  <div>
                                    <div style={{ marginBottom: -6 }}>
                                      <LargePrice>{program.programPrice / 100}</LargePrice>
                                      <CurrencySymbol>
                                        {program.currencySymbol}
                                      </CurrencySymbol>
                                    </div>
                                    <SmallPrice>
                                      {program.billingFrequencyDescription}
                                    </SmallPrice>
                                  </div>
                                )
                                : (
                                  <div>
                                    <div style={{ marginBottom: -6 }}>
                                      <CurrencySymbol>
                                        {program.currencySymbol}
                                      </CurrencySymbol>
                                      <LargePrice>{program.programPrice / 100}</LargePrice>
                                    </div>
                                    <SmallPrice>
                                      {program.billingFrequencyDescription}
                                    </SmallPrice>
                                  </div>
                                )}

                            </PriceContainer>
                          </InfoSideDetailsContainer>
                        </InnerDetailsContainer>
                      </OuterDetailsContainer>
                    </InfoCardContainer>
                  </IndividualProgramContainer>
                </Col>
              );
            })}
          </Row>
        </Grid>
      )
        : (
          <NoTextContainer>No programs are currently available</NoTextContainer>)}

    </ProgramsContainer>
  );
};

export default PublicProgramsGrid;
