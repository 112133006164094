import React, { useEffect, useState, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../../../../shared/components/Button/Button';
import {
  setIsGroupFilterShowing,
  setSelectedGroupHabits,
} from '../../ducks/habitTrackingDashboardActions';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MaxHabitText = styled('div')`
  border-radius: 100000000px;
  background: #EEE;
  color:  #444;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: "Nunito Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 16.5px */
  padding: 4px 8px;
  margin-bottom: 16px;
  text-align: center;
  width: 150px;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterCategory = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #444;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const GroupFilter = () => {
  const isGroupFilterShowing = useSelector((state) => state.habitTrackingDashboard.ui.isGroupFilterShowing);
  const selectedGroupHabits = useSelector((state) => state.habitTrackingDashboard.data.selectedGroupHabits);
  const habits = useSelector((state) => state.habitTrackingDashboard.data.groupOverviewHabits);
  const groupOverviewData = useSelector((state) => state.habitTrackingDashboard.data.groupOverview);
  const selectedGroupDropdownOptions = useSelector((state) => state.habitTrackingDashboard.data.selectedGroupDropdownOptions);

  const dispatch = useDispatch();

  const [localSelectedHabits, setLocalSelectedHabits] = useState([]); // Track locally selected habits
  const initialLoadComplete = useRef(false); // Track initial load
  const previousGroupOverviewData = useRef(null); // Track previous group overview data

  /**
   * Select the first 3 habits from the `oldestToNewestHabits`.
   * @param {Array} habitsToSelectFrom - Array of habits to choose from.
   */
  const selectInitialHabits = (habitsToSelectFrom) => {
    const newSelectedHabits = habitsToSelectFrom.slice(0, 3).map((habit) => ({ id: habit.id, name: habit.name }));
    setLocalSelectedHabits(newSelectedHabits.map((habit) => habit.id)); // Set local habit IDs
    dispatch(setSelectedGroupHabits(newSelectedHabits)); // Dispatch selected habits to Redux
  };

  /**
   * Handles initial loading logic to select habits from `oldestToNewestHabits` and sync with Redux state.
   */
  useEffect(() => {
    if (!initialLoadComplete.current && habits?.oldestToNewestHabits) {
      if (selectedGroupHabits.length > 0) {
        setLocalSelectedHabits(selectedGroupHabits.map((habit) => habit.id)); // Sync local state with Redux state
      } else if (habits.oldestToNewestHabits.length > 0) {
        selectInitialHabits(habits.oldestToNewestHabits); // Select initial habits if none are selected yet
      }
      initialLoadComplete.current = true; // Mark initial load complete
      previousGroupOverviewData.current = groupOverviewData; // Store group overview data for comparison
    }
  }, [selectedGroupHabits, habits, dispatch]);

  /**
   * Synchronize local state whenever selectedGroupHabits changes.
   */
  useEffect(() => {
    setLocalSelectedHabits(selectedGroupHabits.map((habit) => habit.id)); // Sync local habit selection with Redux
  }, [selectedGroupHabits]);

  /**
   * Reset local selections and select initial habits when group overview data changes.
   */
  useEffect(() => {
    if (initialLoadComplete.current && groupOverviewData !== previousGroupOverviewData.current) {
      setLocalSelectedHabits([]); // Reset local habits
      if (habits?.oldestToNewestHabits) {
        selectInitialHabits(habits.oldestToNewestHabits); // Reselect initial habits
      }
      previousGroupOverviewData.current = groupOverviewData; // Update group overview data
    }
  }, [groupOverviewData, habits, dispatch]);

  const handleFilter = () => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', 'Filter clicked');
    dispatch(setIsGroupFilterShowing(!isGroupFilterShowing));
  };

  const handleClose = () => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', 'Filter closed');
    dispatch(setIsGroupFilterShowing(false));
  };

  /**
   * Handle selection/deselection of a habit.
   * @param {string} habitId - ID of the selected habit.
   */
  const handleChange = (habitId) => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', 'Filter selected');
    const newSelectedHabits = localSelectedHabits.includes(habitId)
      ? localSelectedHabits.filter((id) => id !== habitId)
      : [...localSelectedHabits, habitId];

    if (newSelectedHabits.length > 10) {
      newSelectedHabits.shift(); // Ensure only 10 habits can be selected
    }
    setLocalSelectedHabits(newSelectedHabits); // Update local selected habits
  };

  /**
   * Update the selected habits and close the filter modal.
   */
  const handleUpdate = () => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', 'Filters updated');
    const selectedHabitsData = localSelectedHabits.slice(0, 10).map((habitId) => {
      const habit = habits.oldestToNewestHabits.find((h) => h.id === habitId);
      return habit ? { id: habit.id, name: habit.name } : null;
    }).filter((habit) => habit !== null);

    batch(() => {
      dispatch(setIsGroupFilterShowing(false));
      dispatch(setSelectedGroupHabits(selectedHabitsData)); // Save selected habits
    });
  };

  /**
   * Clear all selected habits.
   */
  const handleClear = () => {
    // Amplitude tracking
    dashboardTracker('Consistency Coach', 'Group Analysis - Filter', 'Filters cleared');
    setLocalSelectedHabits([]); // Clear local habits
    dispatch(setSelectedGroupHabits([])); // Clear Redux selected habits
  };

  /**
   * Group habits by category for display.
   */
  const groupedHabits = habits?.assignedHabits?.reduce((acc, habit) => {
    const category = habit?.category?.name || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(habit);
    return acc;
  }, {});

  const universalHabits = habits?.universalHabits || [];
  const hasHabits = groupedHabits && (Object.keys(groupedHabits).length > 0 || universalHabits.length > 0);

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
        disabled={!hasHabits || !selectedGroupDropdownOptions[1]}
      />
      {localSelectedHabits.length > 0 && selectedGroupDropdownOptions[1] && (
        <Badge>{localSelectedHabits.length}</Badge> // Show badge with the count of selected habits
      )}
      {isGroupFilterShowing && hasHabits && (
        <FilterContainer>
          <FilterHeader>
            Filter
            <Button
              icon='remove'
              iconOnly
              onClick={handleClose}
            />
          </FilterHeader>
          <MaxHabitText>10-Habit Maximum</MaxHabitText>
          <Filters>
            {groupedHabits && Object.entries(groupedHabits).map(([category, habits]) => (
              <React.Fragment key={category}>
                <FilterCategory>{category}</FilterCategory>
                {habits.map((habit) => (
                  <FilterOption key={habit.id}>
                    <Checkbox
                      color='default'
                      checked={localSelectedHabits.includes(habit.id)}
                      onChange={() => handleChange(habit.id)} // Handle habit selection
                    />
                    {habit.name.length > 20 ? (
                      <TBTooltip
                        textToDisplay={habit.name}
                        fontSize='16px'
                        textContainerPadding='0px'
                        textContainerMaxWidth='170px'
                        textToHover={habit.name}
                      />
                    ) : (
                      <OptionText>{habit.name}</OptionText>
                    )}
                  </FilterOption>
                ))}
              </React.Fragment>
            ))}
            {universalHabits.length > 0 && (
              <>
                <FilterCategory>Universal Habits</FilterCategory>
                {universalHabits.map((habit) => (
                  <FilterOption key={habit.id}>
                    <Checkbox
                      color='default'
                      checked={localSelectedHabits.includes(habit.id)}
                      onChange={() => handleChange(habit.id)}
                    />
                    {habit.name.length > 20 ? (
                      <TBTooltip
                        textToDisplay={habit.name}
                        fontSize='16px'
                        textContainerPadding='0px'
                        textContainerMaxWidth='170px'
                        textToHover={habit.name}
                      />
                    ) : (
                      <OptionText>{habit.name}</OptionText>
                    )}
                  </FilterOption>
                ))}
              </>
            )}
          </Filters>
          <FilterBottom>
            <Button
              cta='Clear'
              rounded
              onClick={handleClear}
            />
            <Button
              cta='Update'
              disabled={localSelectedHabits.length === 0}
              rounded
              onClick={handleUpdate}
            />
          </FilterBottom>
        </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default GroupFilter;
