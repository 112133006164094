import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WeightroomView from '../WeightroomView';
import {
  closeGlobalModal,
  fetchCurrentUser,
  fetchInitial,
  openGlobalModal,
  toggleScreens,
} from '../../ducks/weightroomActions';

const WeightroomViewContainer = () => {
  const currentUser = useSelector((state) => state.weightRoomView.data.currentUser);

  const dispatch = useDispatch();

  const clseGlobalModal = () => {
    dispatch(closeGlobalModal());
  };
  const hndleScreenToggle = () => {
    dispatch(toggleScreens());
  };
  const opnGlobalModal = () => {
    dispatch(openGlobalModal());
  };

  useEffect(() => {
    dispatch(fetchCurrentUser());
  }, []);

  useEffect(() => {
    if (currentUser && Object.keys(currentUser).length) {
      dispatch(fetchInitial(currentUser.accountCode));
    }
  }, [currentUser]);

  return (
    <WeightroomView
      currentUser={currentUser}
      closeGlobalModal={clseGlobalModal}
      handleScreenToggle={hndleScreenToggle}
      openGlobalModal={opnGlobalModal}
    />
  );
};

export default WeightroomViewContainer;
