import update from 'immutability-helper';

import {
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_FULFILLED,
  FETCH_CURRENT_USER_REJECTED,
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_REJECTED,
  SELECT_GROUP,
  TOGGLE_SUPERSETS,
} from './actionTypes';

const initialState = {
  currentUser: {},
  currentUserError: null,
  groupFilter: { id: '', name: 'All Athletes' },
  userError: null,
  users: {},
  userGroups: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_START: {
      return {
        ...state,
      };
    }
    case FETCH_CURRENT_USER_FULFILLED: {
      return {
        ...state,
        currentUser: action.payload,
        currentUserError: null,
      };
    }
    case FETCH_CURRENT_USER_REJECTED: {
      return {
        ...state,
        currentUserError: action.payload,
      };
    }
    case FETCH_USERS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USERS_FULFILLED: {
      return {
        ...state,
        users: action.payload,
        userError: null,
      };
    }
    case FETCH_USERS_REJECTED: {
      return {
        ...state,
        userError: action.payload,
      };
    }
    case FETCH_USER_GROUPS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case SELECT_GROUP: {
      return {
        ...state,
        groupFilter: action.payload,
      };
    }
    case TOGGLE_SUPERSETS: {
      return update(state, {
        currentUser: {
          userSettings: {
            wrvSupersetsEnabled: {
              $set: state.currentUser.userSettings.wrvSupersetsEnabled === 1 ? 0 : 1,
            },
          },
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
