/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import { useTheme } from 'emotion-theming';
import Text from '../../../../shared/components/Text/Text';
import { setAthletesInClickedZone, setActiveReduxModalForQuestionnaireAthleteZone } from '../../ducks/reportingActions';

const DonutChart = ({
  arrayForDonutChartCount, greenAthletesForCurrentQuestion, yellowAthletesForCurrentQuestion, redAthletesForCurrentQuestion, containerWidth,
}) => {
  const theme = useTheme();
  // const dispatch = useDispatch();
  const arrayOfAllQuestions = useSelector((state) => state.reporting.data.questionnaireReport?.arrayOfAllQuestions);
  const idOfCurrentQuestionInQuestionnairePicker = useSelector((state) => state.reporting.data.currentQuestionInQuestionnairePicker?.id);
  // const abbreviationForCurrentQuestion = arrayOfAllQuestions.filter((question) => question.id === idOfCurrentQuestionInQuestionnairePicker)[0]?.abbr.toLowerCase();
  // const formattedAbbreviationOfCurrentQuestionInPicker = idOfCurrentQuestionInQuestionnairePicker === 'Total Scores' ? 'Total Scores' : abbreviationForCurrentQuestion?.charAt(0).toUpperCase() + abbreviationForCurrentQuestion?.slice(1);
  const abbreviationForCurrentQuestion = arrayOfAllQuestions.filter((question) => question.id === idOfCurrentQuestionInQuestionnairePicker)[0]?.abbr;
  const formattedAbbreviationOfCurrentQuestionInPicker = idOfCurrentQuestionInQuestionnairePicker === 'Total Scores' ? 'Total Scores' : abbreviationForCurrentQuestion?.toUpperCase();

  const isNewQuestionLoading = false;

  // console.log('array for donut chart:', arrayForDonutChartCount);

  const handleColorClick = (entry) => {
    if (entry.name === 'red') {
      console.log(redAthletesForCurrentQuestion);
      // dispatch(setAthletesInClickedZone(redAthletesForCurrentQuestion));
    }
    if (entry.name === 'yellow') {
      console.log(yellowAthletesForCurrentQuestion);
      // dispatch(setAthletesInClickedZone(yellowAthletesForCurrentQuestion));
    }
    if (entry.name === 'green') {
      console.log(greenAthletesForCurrentQuestion);
      // dispatch(setAthletesInClickedZone(greenAthletesForCurrentQuestion));
    }
    // dispatch(setActiveReduxModalForQuestionnaireAthleteZone('athletes-in-zone'));
  };

  // const levelsArrayDummyData = [
  //   {
  //     id: 'wellRested',
  //     label: 'Well Rested',
  //     count: 1,
  //     percentage: 16.7,
  //   },

  //   {
  //     id: 'rested',
  //     label: 'Rested',
  //     count: 1,
  //     percentage: 16.7,
  //   },
  //   {
  //     id: 'slightFatigue',
  //     label: 'Slight Fatigue',
  //     count: 4,
  //     percentage: 66.7,
  //   },
  //   {
  //     id: 'moderateFatigue',
  //     label: 'Moderate Fatigue',
  //     count: 0,
  //     percentage: 0,
  //   },
  //   {
  //     id: 'heavyFatigue',
  //     label: 'Heavy Fatigue',
  //     count: 0,
  //     percentage: 0,
  //   },
  // ];

  // const levelsArrayDummyData = arrayForDonutChartCount;

  // const formattedArrayToPassToDonutChart = levelsArrayDummyData?.map((level) => ({
  //   name: level.label, value: level.count,
  // }));

  // console.log('formattedData for donut chart model:', formattedArrayToPassToDonutChart);

  const DonutChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    min-width: 200px;
    // max-width: 300px;
    width: ${(props) => (props.containerWidth <= 820 && '100%')};
    min-height: 259px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
  `;

  const PieChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 189px;
    height: 170px;
    margin-bottom: 30px;
    padding-top: 15px;
  `;

  const DonutChartContainerPlaceholderShimmer = styled('div')`
    min-width: 200px;
    min-height: 259px;
    max-width: 300px;
    border-radius: 24px;
  `;

  const LegendContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 42px;
    widht: 225px;
    gap: 15px;
    margin: 15px;
    margin-top: 30px;
  `;

  const TextContainer = styled('span')`
    display: flex:
    justify-content: center;
    align-items: center;
    margin: 15px;
    width: 225px;
    text-align: center;
`;

  const CustomLabel = styled('div')`
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.08);
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

  const LabelText = styled('div')`
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: bold;
`;

  const data02 = arrayForDonutChartCount;

  const sumOfArray = () => {
    let sum = 0;
    // eslint-disable-next-line no-return-assign
    arrayForDonutChartCount.map((objectInArray) => sum += objectInArray.value);
    return sum;
  };

  // console.log('summed array:', sumOfArray());

  // console.log('data02', data02);
  // const data02 = formattedArrayToPassToDonutChart;
  // const data02 = [
  //   { name: 'Q1', value: 'green' },
  //   { name: 'Q1', value: 'yellow' },
  //   { name: 'Q1', value: 'red' },
  //   { name: 'Q1', value: 'red' },
  //   { name: 'Q1', value: 'yellow' },
  //   { name: 'Q1', value: 'red' },
  // ];

  const defaultData = [
    { name: 'A1', value: 1 },
    { name: 'A2', value: 1 },
    { name: 'B1', value: 1 },
    { name: 'B2', value: 1 },

  ];

  // [red, green, yellow]
  const COLORS = ['#FF3737', '#0DCC8A', '#FFCB47'];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, value, fill,
  // eslint-disable-next-line consistent-return
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value !== 0) {
      return (
        <foreignObject style={{ pointerEvents: 'none' }} x={x} y={y} width='50' height='50'>
          <CustomLabel>
            <LabelText>
              {value}
            </LabelText>
          </CustomLabel>
        </foreignObject>
      );
    }
  };

  // const CustomizedLegend = () => (
  //   <LegendContainer>
  //     <Column>
  //       <Row>
  //         <LegendCircleIcon color='#3fbfe1' />
  //         <Text fontSize={12} fontWeight={700}>Well Rested</Text>
  //       </Row>
  //       <Row>
  //         <LegendCircleIcon color='#0DCC8A' />
  //         <Text fontSize={12} fontWeight={700}>Rested</Text>
  //       </Row>
  //       <Row>
  //         <LegendCircleIcon color='#FFCB47' />
  //         <Text fontSize={12} fontWeight={700}>Slight Fatigue</Text>
  //       </Row>
  //     </Column>
  //     <Column>
  //       <Row>
  //         <LegendCircleIcon color='#FF8533' />
  //         <Text fontSize={12} fontWeight={700}>Moderate Fatigue </Text>
  //       </Row>
  //       <Row>
  //         <LegendCircleIcon color='#FF0505' />
  //         <Text fontSize={12} fontWeight={700}>Heavy Fatigue</Text>
  //       </Row>
  //     </Column>
  //   </LegendContainer>
  // );

  return (
    <DonutChartContainer containerWidth={containerWidth}>
      {isNewQuestionLoading ? (
        <Shimmer>
          <DonutChartContainerPlaceholderShimmer />
        </Shimmer>
      ) : (
        <>
          {arrayForDonutChartCount?.length && sumOfArray() !== 0 ? (
            <>
              <PieChartContainer>
                <Text fontSize={12} fontWeight={700}>Athletes In Each Zone</Text>
                <Text fontSize={12} fontWeight={700}>{formattedAbbreviationOfCurrentQuestionInPicker}</Text>
                <PieChart
                  width={200}
                  height={200}
                >
                  <Pie
                    isAnimationActive={false}
                    data={data02}
                    dataKey='value'
                    cx='50%'
                    cy='50%'
                    innerRadius={35}
                    outerRadius={60}
                    fill='#82ca9d'
                    label={renderCustomizedLabel}
                    labelLine={false}
                    // onClick={() => console.log('clicked pie chart')}
                  >
                    {data02.map((entry, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} style={{ cursor: 'auto' }} /** onClick={() => handleColorClick(entry)} */ />
                    ))}
                  </Pie>
                </PieChart>
              </PieChartContainer>
              {/* <CustomizedLegend /> */}
            </>
          ) : (
            <>
              <PieChartContainer>
                <Text fontSize={12} fontWeight={700}>Athletes In Each Zone</Text>
                <PieChart width={200} height={200}>
                  <Pie data={defaultData} dataKey='value' cx='50%' cy='50%' innerRadius={35} outerRadius={60} fill='grey' />
                </PieChart>
              </PieChartContainer>
              <TextContainer>
                <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>No Status</Text>
              </TextContainer>
              {/* {activeAthleteParticipationCount > 0 ? (
                <TextContainer>
                  <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>Oops! No exertion score data available for selected date.</Text>
                </TextContainer>
              ) : (
                <TextContainer>
                  <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>Oops! No active athletes.</Text>
                </TextContainer>
              )} */}
            </>
          )}
        </>
      )}
    </DonutChartContainer>
  );
};

export default DonutChart;
