import styled from '@emotion/styled';
import { typography, color, space } from 'styled-system';

const Text = styled('p')`
  font-family: 'Nunito Sans';
  font-size: 12pt;
  color: #444444;
  margin-bottom: 0px;
  ${space}
  ${typography}
  ${color}
  ${space}
`;

export default Text;
