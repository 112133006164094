/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { FixedSizeList as List } from 'react-window';
import SearchBarAndListComponent from './SearchBarAndListComponent';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import Text from '../../../../shared/components/Text/Text';

const ItemPickerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background: white;
  z-index: 100;
  position: absolute;
  box-shadow: ${BoxShadow};
  background: white;
  border-radius: 12px;
  left: ${(props) => ((props.containerWidth <= 767 && props.small) ? '-124px' : props.containerWidth <= 767 ? '-43px' : '-43px')};
  top: ${(props) => (props.small ? '40px' : '70px')};
    @media only screen and (max-width: 767px) {
      top: ${(props) => (props.small ? '50px' : '80px')};
    }
`;

const ItemContainer = styled('div')`
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
`;

const ItemNameContainer = styled('div')`

`;

const TextOverflowEllipse = styled('h3')`
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
width: 80%;
font-family: 'Nunito Sans';
font-size:  ${(props) => (props.small ? '12px' : '16px')};
color: ${(props) => (props.pickerFontColor ? props.pickerFontColor : '#444444')};
margin-bottom: 0px;
font-weight: 700;
`;

const ItemSelectorContainer = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
min-width:  ${(props) => (props.small ? '119px' : '196px')};
height:  ${(props) => (props.small ? '21px' : '47px')};
max-width: ${(props) => (props.small ? '120px' : '200px')};
border-radius: 12px;
padding: ${(props) => (props.small ? '8px' : '8px 16px 8px 16px')};
background-color: ${(props) => (props.pickerButtonColor ? props.pickerButtonColor : '#444444')};
box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
position: relative;
opacity: 1;
cursor: pointer;


 .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  bottom: 105%;
  left: 50%;
  margin-left: -60px;
}

 .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

:hover .tooltiptext {
  visibility: visible;
}

.tooltiptextBottom {
  visibility: hidden;
  width: 120px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  color: #444444;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 105%;
  left: 50%;
  margin-left: -60px;
}

 .tooltiptextBottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

:hover .tooltiptextBottom {
  visibility: visible;
}

`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

/**
 * @param {Object} data an object that's passed in from the 'itemData' prop in the list component
 * seen below
 * @param {Integer} index the index of the current data object in the passed array
 * @param {Object} style necessary object that styles each individual container in accordance with
 * the requirements of the list
 * @returns a formatted list of dynamically rendered athlete rows
 */
const ItemRenderer = ({ data, index, style }) => {
  const { list, reduxFunction } = data;
  const item = list[index];
  return (
    <ItemContainer
      onClick={() => reduxFunction(item)}
      id={item.id}
      style={style}
    >
      <ItemNameContainer>
        <Text fontSize={16} fontWeight={700}>
          { (item.first && item.last) ? `${item.first} ${item.last}` : `${item.name}`}
        </Text>
      </ItemNameContainer>
    </ItemContainer>
  );
};

/**
 *
 * @param {Array} list the list of athletes to be passed into a generated windowed list
 * @param {Function} reduxFunction the function to be called when the user clicks on
 * an athlete row in the windowed list
 * @param {Object} visibleContainerItem the item that is generated in the selector container
 * i.e. the currently selected item
 * @returns
 */
const SharedPickerComponent = ({
  list,
  reduxFunction,
  visibleContainerItem,
  isMetricPicker,
  isGroupPicker,
  small,
  addSearchBar,
  icon,
  pickerButtonColor,
  pickerFontColor,
  containerWidth,
}) => {
  // state that manages whether the dropdown athlete list is showing
  const [isSelectorShowing, setIsSelectorShowing] = useState(false);

  const [addToolTip, setAddToolTip] = useState(false);

  const [isToolTipOnBottom, setIsToolTipOnBottom] = useState(false);

  const ref = React.createRef();

  const toolTipRef = React.createRef();

  const handleMouseEnter = () => {
    if (addToolTip && toolTipRef.current.getBoundingClientRect().top < 45) {
      setIsToolTipOnBottom(true);
    }
  };

  const handleMouseLeave = () => {
    if (addToolTip) {
      setIsToolTipOnBottom(false);
    }
  };

  useLayoutEffect(() => {
    if (ref?.current.childNodes[0].clientWidth < ref?.current.childNodes[0].scrollWidth) {
      setAddToolTip(true);
    }
  }, [ref]);

  const handleClick = () => {
    setIsSelectorShowing(!isSelectorShowing);
  };

  /**
   *
   * @param {Object} shownItem the object that appears as the item that's selected
   * from the list
   * @returns a formatted item for the selector container
   */

  return (
    <>
      <ItemSelectorContainer
        style={{ cursor: 'pointer' }}
        // style={{ cursor: isMetricPicker ? 'default' : 'pointer' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={ref}
        small={small}
        pickerButtonColor={pickerButtonColor}
        onClick={handleClick}
        // onClick={!isMetricPicker ? handleClick : undefined}
        isSelectorShowing={isSelectorShowing}
      >
        {addToolTip && (
        <span className={isToolTipOnBottom ? 'tooltiptextBottom' : 'tooltiptext'} ref={toolTipRef}>
          {visibleContainerItem?.name ? (
            `${visibleContainerItem.name}`
          ) : (
            `${visibleContainerItem.first} ${visibleContainerItem.last}`
          )}
        </span>
        )}
        {visibleContainerItem?.name ? (
          <TextOverflowEllipse small={small} pickerFontColor={pickerFontColor}>
            { `${visibleContainerItem.name}`}
          </TextOverflowEllipse>
        ) : (
          <TextOverflowEllipse small={small}>
            {`${visibleContainerItem.first} ${visibleContainerItem.last}`}
          </TextOverflowEllipse>
        )}
        {icon
        && (
        <IcomoonReact
          color={icon.color}
          iconSet={iconSet}
          size={small ? 12 : icon.size}
          icon={icon.name}
        />
        )}
        {isSelectorShowing ? (
          <ItemPickerContainer small={small} containerWidth={containerWidth}>
            {addSearchBar ? (
              <SearchBarAndListComponent placeholder={isGroupPicker ? 'Search groups' : 'Search athletes'} listdata={list} reduxFunction={reduxFunction} />
            ) : (
              <List
                height={list.length < 7 ? list.length * 55 : 400}
                itemData={{
                  list,
                  reduxFunction,
                }}
                itemCount={list.length}
                itemSize={55}
                width={280}
              >
                {ItemRenderer}
              </List>

            )}
          </ItemPickerContainer>
        ) : null}
      </ItemSelectorContainer>
      {isSelectorShowing
        ? (
          <Overlay onClick={() => {
            setIsSelectorShowing(false);
          }}
          />
        )
        : null}
    </>
  );
};

ItemRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
};

SharedPickerComponent.propTypes = {
  list: PropTypes.instanceOf(Array).isRequired,
  reduxFunction: PropTypes.func.isRequired,
  visibleContainerItem: PropTypes.instanceOf(Object).isRequired,
  isMetricPicker: PropTypes.bool,
  isGroupPicker: PropTypes.bool,
  small: PropTypes.bool,
  addSearchBar: PropTypes.bool,
  icon: PropTypes.instanceOf(Object),
  pickerButtonColor: PropTypes.string,
  pickerFontColor: PropTypes.string,
};

SharedPickerComponent.defaultProps = {
  isMetricPicker: false,
  isGroupPicker: false,
  small: false,
  addSearchBar: false,
  icon: null,
  pickerButtonColor: '#444444',
  pickerFontColor: '#ffffff',
};

export default SharedPickerComponent;
