import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const BottomSheetContainer = styled('div')`
	width: 100%;
	background: white;
	position: fixed;
	bottom: 0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
	min-width: 325px;
	display: flex;
	justify-content: space-between;
	border-radius: 24px 24px 0px 0px;
	box-shadow: rgb(48 49 51 / 5%) 8px 0px 1px, rgb(48 49 51 / 50%) 0px 2px 4px;
	padding-right: 20px;
	padding-left: 20px;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
  height: 111px;
  @media only screen and (min-width : 768px) {
    display: none;
  }
	cursor: pointer;
`;

const AthleteContainer = styled('div')`
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
`;

const AthleteAvatar = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '45px')};
    width: ${(props) => (
    props.size ? `${props.size}px` : '45px')};
    border-radius: 50%;
  }
  display: flex;
  align-items: center;
`;

const AthleteNameContainer = styled('div')`
  display: flex;
	flex-direction: column;
	justify-content: center;
`;

const DayTitle = styled('div')`
  display: flex;
  align-items: center;
	width: 100%;
	justify-content: space-between;
  font-size: 20px;
  font-family: 'Nunito Sans';
  font-weight: bold;
`;

const SwitchIcon = styled('div')`
	height: 50px;
	width: 50px;
	background: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
  background: rgb(116,196,232);
  box-shadow: ${BoxShadow};
`;

const CompleteSwitchIcon = styled('div')`
	height: 50px;
	width: 50px;
	background: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
  background: #0dcc8a;
  box-shadow: ${BoxShadow};
`;

const StatusAndText = styled('div')`
  display: flex;
  align-items: center;
  height: 21px;
`;

const ProgramStatusCircle = styled('div')`
  border-radius: 50%;
  background: ${(props) => props.color};
  border: ${(props) => (!props.started ? '1px solid grey' : `1px solid ${props.color}`)};
  width: 14px;
  height: 14px;
`;

const ProgramStatus = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
`;

const NonDateBasedAthleteBottomSheet = () => {
  // state that manages whether the dropdown athlete list is showing
  const [isSelectorShowing, setIsSelectorShowing] = useState(false);

  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  return (
    <BottomSheetContainer
      onClick={() => setIsSelectorShowing(!isSelectorShowing)}
    >
      {Object.keys(currentNonDateDay).length ? (
        <>
          <AthleteNameContainer>
            <DayTitle>
              {currentNonDateDay.dayTitle}
            </DayTitle>
            <StatusAndText>
              <ProgramStatusCircle
                started={currentNonDateDay?.status !== 'Not Started'}
                color={currentNonDateDay?.statusColorCode}
              />
              <ProgramStatus>
                {currentNonDateDay?.status}
              </ProgramStatus>
            </StatusAndText>
          </AthleteNameContainer>
          {currentNonDateDay.status !== 'Complete' ? (
            <SwitchIcon>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='play-filled'
                color='white'
              />
            </SwitchIcon>
          ) : (
            <CompleteSwitchIcon>
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='checkmark'
                color='white'
              />
            </CompleteSwitchIcon>
          )}
        </>
      ) : null}
    </BottomSheetContainer>
  );
};

NonDateBasedAthleteBottomSheet.propTypes = {

};

export default NonDateBasedAthleteBottomSheet;
