/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring';
import IcomoonReact from 'icomoon-react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import ImportedDataSelection from '../../../modules/workouts/components/presentational/integrations/ImportedDataSelection';
import ImportedIntegrations from '../../../modules/workouts/components/presentational/integrations/ImportedIntegrations';
import iconSet from '../../images/teambuildr-selection.json';
import Text from '../Text/Text';
import { setIsIntegrationModalShowing } from '../../../modules/workouts/ducks/workoutsActions';
import { setIsIntegrationModalShowing as setWRVModalShowing } from '../../../modules/weightroom-view-new/ducks/panelActions';

const ModalWrapper = styled('div')`
  .ReactModal__Content {
    max-width: 710px;
  }
`;

const TransitionContainer = styled('div')`
  display: flex; // Use flexbox for alignment
  width: 100%; 
  height: 100%; 
  overflow: hidden; // Prevent layout shifts during transition
`;

const TransitionElement = styled('div')`
  flex-grow: 1;
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  min-width: 690px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;
const NavigationWrapper = styled('div')`
  display: flex;
  z-index: 5;
  position: absolute;
  top: 20px;
  right: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const ImportedDataModal = ({ isWeightRoomView = false, namespace = null }) => {
  const [activeComponent, setActiveComponent] = useState(0);
  const dispatch = useDispatch();

  const isIntegrationModalOpen = isWeightRoomView ? useSelector((state) => state.weightRoomView[namespace].isIntegrationModalShowing) : useSelector((state) => state.workouts.ui.isIntegrationModalShowing);
  const importedDataIntegrations = isWeightRoomView ? useSelector((state) => state.weightRoomView[namespace].importedDataIntegrations) : useSelector((state) => state.workouts.data.importedDataIntegrations);
  const transitions = useTransition(activeComponent, null, {
    from: { opacity: 0, transform: activeComponent === 1 ? 'translate3d(25%, 0, 0)' : 'translate3d(0%, 0, 0)', width: '100%' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)', width: '100%' },
    leave: { opacity: 0, transform: activeComponent === 1 ? 'translate3d(-25%, 0, 0)' : 'translate3d(0%, 0, 0)', width: '100%' },
  });

  const handleSetActiveComponent = () => {
    setActiveComponent(1);
  };

  const closeModalHandler = () => {
    setActiveComponent(0);

    if (isWeightRoomView) {
      dispatch(setWRVModalShowing(namespace, false));
    } else {
      dispatch(setIsIntegrationModalShowing(false));
    }
  };

  return (
    <ModalWrapper>
      <Modal
        onRequestClose={closeModalHandler}
        isOpen={isIntegrationModalOpen}
        customButton
        customPadding='0px'
        customMinHeight='750px'
        customBorderRadius='24px'
        customMaxWidth='690px'
      >
        <TransitionContainer>
          {transitions.map(({ item, props, key }) => {
            const componentProps = {
              importedDataIntegrations,
              namespace,
              isWeightRoomView,
            };
            const ComponentToRender = item === 0 ? ImportedIntegrations : ImportedDataSelection;

            const isActive = item === activeComponent;

            return (
              <animated.div key={key} style={props}>
                <NavigationWrapper>
                  <Text
                    className='close arrow-div'
                    onClick={() => {
                      closeModalHandler();
                    }}
                    color={activeComponent === 0 ? '#444' : '#fff'}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={13}
                      icon='remove'
                      color={activeComponent === 0 ? '#444' : '#fff'}
                    />
                  </Text>
                </NavigationWrapper>
                <TransitionElement isActive={isActive}>
                  <ComponentToRender {...componentProps} handleSetActiveComponent={handleSetActiveComponent} />
                </TransitionElement>
              </animated.div>
            );
          })}
        </TransitionContainer>
      </Modal>
    </ModalWrapper>
  );
};

export default ImportedDataModal;

ImportedDataModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  importedDataIntegrations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isWeightRoomView: PropTypes.bool,
  namespace: PropTypes.string,
};

ImportedDataModal.defaultProps = {
  isWeightRoomView: false,
  namespace: null,
};
