import { connect } from 'react-redux';

import Panel from '../presentational/Panel';
import { storePanelHeight } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteChosen: state[ownProps.namespace].athleteChosen,
  activeScreens: state.weightRoom.activeScreens,
  isPinVerified: state[ownProps.namespace].isPinVerified,
});

const mapDispatchToProps = dispatch => ({
  storePanelHeight: (height, width, namespace) => {
    dispatch(storePanelHeight(height, width, namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Panel);
