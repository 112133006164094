import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_START,
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
  FETCH_LOAD_MONITORNING_LINE_CHART_START,
  FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED,
  // SET_ACTIVE_REPORT,
} from './actionTypes';

const initialState = {
  isLoadingQuestionnairesForAccount: false,
  isLoadingQuestionsForAccount: false,
  isLoadingQuestionnaireReportSingleDay: false,
  isLoadingQuestionnaireReportMultiday: false,
  isLoadingReportableGroups: false,
  isLoadingLineChartData: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_LM_DASH_START: {
      return {
        ...state,
        isLoadingReportableGroups: true,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingReportableGroups: false,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingReportableGroups: false,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: true,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: false,
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnairesForAccount: false,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionsForAccount: true,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionsForAccount: false,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionsForAccount: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: true,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
        // isReportOpen: true,
        // hasReportData: true,
        // isSidebarFilterActive: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportSingleDay: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_START: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: true,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
        // isReportOpen: true,
        // hasReportData: true,
        // isSidebarFilterActive: false,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED: {
      return {
        ...state,
        isLoadingQuestionnaireReportMultiday: false,
      };
    }
    case FETCH_LOAD_MONITORNING_LINE_CHART_START: {
      return {
        ...state,
        isLoadingLineChartData: true,
      };
    }
    case FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    case FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED: {
      return {
        ...state,
        isLoadingLineChartData: false,
      };
    }
    // case SET_ACTIVE_REPORT: {
    //   return {
    //     ...state,
    //     activeReport: action.payload,
    //   };
    // }
    default: {
      return {
        ...state,
      };
    }
  }
}
