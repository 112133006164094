import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import { useTheme } from 'emotion-theming';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import Badge from '../../../../shared/components/Badge/Badge';

const BadgeContainer = styled('div')`
  display: block;
  align-self: center;
  margin-left: 15px;
  margin-right: -5px;
`;

const StatusWrapper = styled('div')`
`;

const StatusIcons = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  align-items: flex-end;

  svg {
    margin-left: 5px;
  }
`;

const Conversation = ({
  conversationObject,
  isCheckboxesOpen,
  handleSetActiveConversation,
  handleMarkAsRead,
  setSelectedConvos,
  isHovered,
  timeZone,
}) => {
  const [formattedDate, setFormattedDate] = useState();
  const activeConversation = useSelector((state) => state.conversations.data.activeConversation);
  const selectedConvos = useSelector((state) => state.conversations.data.selectedConvos);
  const latestMessage = useSelector((state) => state.conversations.data.latestMessage);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const theme = useTheme();

  const ConversationDiv = styled('div')`
    display: flex;
    width: 100%;
    height: 80px;
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    background: ${conversationObject.id === activeConversation.id ? 'lightgrey' : ''};

    @keyframes slideIn {
      0% {
        opacity: 0;
        width: 5%;
        margin-left: 0px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 19px;
        opacity: 1;
        width: 15%
      }
    }
    @keyframes slideOut {
      0% {
        opacity: 1;
        width: 15%;
        margin-left: 19px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 0px;
        opacity: 0;
        width: 5%
      }
    }

    .left-wrapper {
      display: flex;
      align-items: center;
      width: 60%;

      .checkbox-div {
        /* margin-left: 0px; */
        /* width: 5%; */
        /* opacity: 0; */
        margin-left: ${isCheckboxesOpen ? '19px' : '0px'};
        opacity: ${isCheckboxesOpen ? 1 : 0};
        width: ${isCheckboxesOpen ? '15%' : '5%'}
        /* ${isCheckboxesOpen ? 'animation: slideIn 0.25s forwards' : ''}; */
        /* ${(!isCheckboxesOpen ? 'animation: slideOut 0.25s forwards' : '')}; */
        /* ${isCheckboxesOpen ? 'animation: slideIn 0.25s forwards' : ''}; */
        /* ${(!isCheckboxesOpen ? 'animation: slideOut 0.25s forwards' : '')}; */
      }

      .message-box {
        display: flex;
        width: 90%;
        flex-direction: row;
        color: #444444;
        align-items: center;
        padding: 9px;
        border-radius: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;

        .sender-latest-message {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .title {
            font-weight: bold;
          }
          .latest-message {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .end-box {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .latest-time {
        margin-top: ${conversationObject.muted ? '0px' : '-16px'};
        margin-right: 10px;
      }

      .arrow-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 10%;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `;

  const picPicker = () => {
    if (conversationObject.latestMessage) {
      return optimizeImage(
        conversationObject.latestMessage.userPic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
    }
    return optimizeImage(
      'https://s3.amazonaws.com/teambuildr-staging-assets/pic/default.png',
      {
        resize: {
          width: 60,
          height: 60,
          fit: 'contain',
        },
      },
    );
  };

  useEffect(() => {
    const newMoment = conversationObject.latestMessage
      ? moment.tz(conversationObject.latestMessage.sentAt, timeZone)
      : moment();

    const formattedMoment = newMoment.calendar(null, {
      sameDay: 'hh:mm A',
      nextDay: '[Tomorrow]',
      lastDay: '[Yesterday]',
      lastWeek: 'MMMM D',
      sameElse: 'MMMM D',
    });

    setFormattedDate(formattedMoment);
  }, [conversationObject]);

  const mediaMessagePicker = () => {
    if (conversationObject.latestMessage) {
      return conversationObject.latestMessage.media
        ? `New ${conversationObject.latestMessage.media.type === 'EXTERNAL_URL' ? 'gif' : conversationObject.latestMessage.media.type.toLowerCase()} attached`
        : 'New message';
    }
  };

  const latestMessagePicker = () => {
    if (conversationObject.latestMessage) {
      return conversationObject.latestMessage.messageBody
        ? conversationObject.latestMessage.messageBody
        : mediaMessagePicker();
    }
  };

  const conversationTitleHandler = conversationObject.title === null ? 'Untitled Conversation' : conversationObject.title;

  return (
    <ConversationDiv
      onClick={(e) => {
        if (e.target.type !== 'checkbox') {
          if (activeConversation.id !== conversationObject.id) {
            handleSetActiveConversation(conversationObject);
            if (conversationObject.unreadMessages) {
              handleMarkAsRead({
                accountCode: currentUser.accountCode,
                readArray: [conversationObject.id],
              });
            }
          }
        }
      }}
    >
      <div className='left-wrapper'>
        {isCheckboxesOpen ? (
          <>
            <div className='checkbox-div'>
              <Checkbox
                className='conversation-checkbox'
                value={conversationObject.id}
                checked={selectedConvos[conversationObject.id]}
                onChange={(e) => {
                  const newSelectedConvos = { ...selectedConvos };
                  if (newSelectedConvos[e.target.value]) {
                    delete newSelectedConvos[e.target.value];
                  } else {
                    newSelectedConvos[e.target.value] = parseInt(e.target.value, 10);
                  }
                  setSelectedConvos(newSelectedConvos);
                }}
                color='primary'
                disabled={!isCheckboxesOpen}
              />
            </div>
            {conversationObject.unreadMessages && (
              <BadgeContainer>
                <Badge
                  color='#77A7FF'
                />
              </BadgeContainer>
            )}
          </>
        ) : (
          <>
            {conversationObject.unreadMessages && (
              <BadgeContainer>
                <Badge
                  color='#77A7FF'
                />
              </BadgeContainer>
            )}
          </>
        )}
        <div className='message-box'>
          <div
            className='userpic'
            style={{
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              backgroundImage: `url(${picPicker()})`,
              backgroundSize: 'contain',
              minWidth: '40px',
            }}
          />
          <div className='sender-latest-message'>
            <Text
              fontSize={14}
              className='title'
            >
              {conversationTitleHandler
                || ((latestMessage && latestMessage.conversationId === conversationObject.Id)
                  ? latestMessage.userFirstName : conversationObject.latestMessage.userFirstName)}
            </Text>
            <Text
              fontSize={12}
              fontFamily='Open Sans'
              className='latest-message'
            >
              {/* {conversationObject.latestMessage.messageBody
                ? conversationObject.latestMessage.messageBody
                : mediaMessagePicker()} */}
              {latestMessagePicker()}
            </Text>
          </div>
        </div>
      </div>
      <div className='end-box'>
        <StatusWrapper>
          <Text
            color={theme.colors.lightText[0]}
            fontSize={12}
            className='latest-time'
          >
            {formattedDate}
          </Text>
          <StatusIcons>
            {conversationObject.muted ? (
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='alert-off'
                color={theme.colors.lightText[0]}
              />
            ) : null}
            {conversationObject.readOnly ? (
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='visible'
                color={theme.colors.lightText[0]}
              />
            ) : null}
          </StatusIcons>
        </StatusWrapper>
        <div className='arrow-box'>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='altrightarrow'
            color='grey'
          />
        </div>
      </div>
    </ConversationDiv>
  );
};

Conversation.propTypes = {
  conversationObject: PropTypes.instanceOf(Object).isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
  handleSetActiveConversation: PropTypes.func.isRequired,
  setSelectedConvos: PropTypes.func.isRequired,
};

export default Conversation;
