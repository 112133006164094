import { connect } from 'react-redux';
import ModalOptOut from '../presentational/ModalOptOut';
import {
  fetchExercises,
  fetchSimilarExercises,
  fetchWorkout,
  optOutWorkout,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeDate: state[ownProps.namespace].activeDate,
  activeExerType: state[ownProps.namespace].activeExerType,
  activeWorkoutIndex: state[ownProps.namespace].activeWorkoutIndex,
  athleteId: state[ownProps.namespace].athleteId,
  isLoadingExercises: state[ownProps.namespace].isLoadingExercises,
  isLoadingSimExercises: state[ownProps.namespace].isLoadingSimExercises,
  exercises: state[ownProps.namespace].exercises,
  exercisesSimilar: state[ownProps.namespace].exercisesSimilar,
});


const mapDispatchToProps = dispatch => ({
  fetchExercises: (namespace) => {
    dispatch(fetchExercises(namespace));
  },
  fetchSimilarExercises: (namespace, exerId) => {
    dispatch(fetchSimilarExercises(namespace, exerId));
  },
  fetchWorkout: (id, index, namespace, date) => {
    dispatch(fetchWorkout(id, index, namespace, date));
  },
  optOutWorkout: (athleteId, date, index, namespace, values) => {
    dispatch(optOutWorkout(athleteId, date, index, namespace, values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalOptOut);
