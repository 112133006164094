import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { toast } from 'react-toastify';

import {
  REFRESH_WORKOUTS_START,
  FETCH_WORKOUTS_START,
  FETCH_WORKOUTS_FULFILLED,
  FETCH_WORKOUTS_REJECTED,
  FETCH_MULTIPLE_WORKOUTS_START,
  FETCH_MULTIPLE_WORKOUTS_FULFILLED,
  FETCH_MULTIPLE_WORKOUTS_REJECTED,
  SET_ACTIVE_WORKOUT_DATE,
  GET_EXERTION_SCORE_START,
  GET_EXERTION_SCORE_FULFILLED,
  GET_EXERTION_SCORE_REJECTED,
  GET_USERS_START,
  GET_USERS_FULLFILLED,
  GET_USERS_REJECTED,
  SET_SHARED_ATHLETE,
  SET_IS_WORKOUTS_CAROUSEL_SHOWING,
  FETCH_WORKOUT_CALENDAR_START,
  FETCH_WORKOUT_CALENDAR_FULFILLED,
  FETCH_WORKOUT_CALENDAR_REJECTED,
  FETCH_WORKOUT_SESSION_START,
  FETCH_WORKOUT_SESSION_FULFILLED,
  FETCH_WORKOUT_SESSION_REJECTED,
  SUBMIT_OPT_OUT_START,
  SUBMIT_OPT_OUT_FULFILLED,
  SUBMIT_OPT_OUT_REJECTED,
  FETCH_SIMILAR_EXERCISES_START,
  FETCH_SIMILAR_EXERCISES_FULFILLED,
  FETCH_SIMILAR_EXERCISES_REJECTED,
  FETCH_OPT_OUT_ALL_LIFT_EXERCISES_START,
  FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED,
  FETCH_OPT_OUT_ALL_LIFT_EXERCISES_REJECTED,
  SET_OPT_OUT_EXERCISE_TYPE_FILTER,
  SUBMIT_WORKOUT_START,
  SUBMIT_WORKOUT_FULFILLED,
  SUBMIT_WORKOUT_REJECTED,
  SET_OPT_OUT_WORKOUT,
  SET_TIMER_MODAL_OPEN,
  SET_TIMER_DURATION,
  SET_QUESTIONNAIRE_TOTAL,
  FETCH_TAG_EXERCISES_START,
  FETCH_TAG_EXERCIES_FULFILLED,
  FETCH_TAG_EXERCIES_REJECTED,
  SET_SELECTED_TAG_EXERCISE,
  SET_CAPTURE_IMAGE_MODAL,
  UPLOAD_WORKOUTS_MEDIA_START,
  UPLOAD_WORKOUTS_MEDIA_FULFILLED,
  UPLOAD_WORKOUTS_MEDIA_REJECTED,
  SET_IS_WORKOUTS_MEDIA_UPLOADED,
  SET_CAPTURE_VIDEO_MODAL,
  SET_HISTORY_WORKOUT,
  FETCH_WORKOUT_HISTORY_START,
  FETCH_WORKOUT_HISTORY_FULFILLED,
  FETCH_WORKOUT_HISTORY_REJECTED,
  SET_JOURNAL_ENTRY_WORKOUT,
  CREATE_WORKOUT_JOURNAL_ENTRY_START,
  CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  CREATE_WORKOUT_JOURNAL_ENTRY_REJECTED,
  SET_UPLOAD_WORKOUT_MEDIA_FALSE,
  WRITE_WORKOUT_NOTE_TO_ATHLETE_START,
  WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
  WRITE_WORKOUT_NOTE_TO_ATHLETE_REJECTED,
  SET_UPLOAD_WORKOUT_JOURNAL_MEDIA_FALSE,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED,
  SET_IS_WORKOUT_JOURNAL_MEDIA_UPLOADED,
  SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_START,
  SET_ACTIVE_WORKOUTS_MEDIA,
  GET_WORKOUTS_EXERTION_SCORE_START,
  GET_WORKOUTS_EXERTION_SCORE_FULFILLED,
  GET_WORKOUTS_EXERTION_SCORE_REJECTED,
  SET_EXERTION_SCORE_MODAL_SHOWING,
  SET_IS_PRINTING_OPTIONS_SHOWING,
  UPDATE_WORKOUT_JOURNAL_ENTRY_START,
  UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  UPDATE_WORKOUT_JOURNAL_ENTRY_REJECTED,
  SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL,
  SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL,
  UPLOAD_QUICK_JOURNAL_MEDIA_START,
  UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED,
  UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED,
  SET_QUICK_WORKOUTS_JOURNAL,
  SET_QUICK_JOURNAL_MODAL,
  FETCH_WORKOUT_CALENDAR_START_ALT,
  FETCH_WORKOUT_CALENDAR_FULFILLED_ALT,
  FETCH_WORKOUT_CALENDAR_REJECTED_ALT,
  SET_IS_DAY_PICKER_OPEN,
  SET_ADDITIONAL_INFO_WORKOUT,
  SET_ADDITIONAL_WORKOUTS_DATA,
  SET_IS_ADDITIONAL_WORKOUTS,
  SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN,
  WIPE_MEDIA_ERROR,
  SET_TAG_WORKOUT,
  GET_UPDATED_ACTIVE_DATE_START,
  GET_UPDATED_ACTIVE_DATE_FULFILLED,
  GET_UPDATED_ACTIVE_DATE_REJECTED,
  SET_MULTI_QUESTION_MODAL,
  SET_MULTI_QUESTION_MODAL_RESPONSE,
  SUBMIT_SESSION_WORKOUT_FULFILLED,
  SUBMIT_OPT_OUT_TOP_FULFILLED,
  WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
  CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  FETCH_TAG_EXERCISES_FULFILLED,
  FETCH_TAG_EXERCISES_REJECTED,
  FETCH_WORKOUT_DOCUMENT_START,
  FETCH_WORKOUT_DOCUMENT_FULFILLED,
  FETCH_WORKOUT_DOCUMENT_REJECTED,
  FETCH_WORKOUT_FILE_START,
  FETCH_WORKOUT_FILE_FULFILLED,
  FETCH_WORKOUT_FILE_REJECTED,
  SET_HIDDEN_WORKOUTS_TOGGLE,
  FETCH_WORKOUT_PROGRAMS_START,
  FETCH_WORKOUT_PROGRAMS_FULFILLED,
  FETCH_WORKOUT_PROGRAMS_REJECTED,
  SET_IS_DAY_CAROUSEL,
  FETCH_WORKOUT_PROGRAM_DAYS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_DAYS_REJECTED,
  FETCH_WORKOUT_PROGRAM_DAYS_START,
  FETCH_WORKOUT_PROGRAM_WEEKS_START,
  FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED,
  FETCH_WORKOUT_PROGRAM_WEEK_START,
  FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEK_REJECTED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_START,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  SET_IS_PROGRAM_LIST_BOX_MODAL,
  SET_PROGRAM_DAY_INDEX,
  SET_WEEKDAY_INDEX,
  FETCH_NEXT_WORKOUT_PROGRAM_WEEK_START,
  FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_NEXT_WORKOUT_PROGRAM_WEEK_REJECTED,
  FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_START,
  FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_REJECTED,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  SET_IS_RESET_PROGRAM_MODAL_SHOWING,
  SET_IS_SKIP_WORKOUT_MODAL_SHOWING,
  SKIP_WORKOUT_PROGRAM_WORKOUTS_START,
  SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  SKIP_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING,
  SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING,
  RESET_WORKOUT_PROGRAM_START,
  RESET_WORKOUT_PROGRAM_FULFILLED,
  RESET_WORKOUT_PROGRAM_REJECTED,
  COMPLETE_WORKOUT_PROGRAM_START,
  COMPLETE_WORKOUT_PROGRAM_FULFILLED,
  COMPLETE_WORKOUT_PROGRAM_REJECTED,
  FETCH_NEXT_WORKOUT_SESSION_START,
  FETCH_NEXT_WORKOUT_SESSION_FULFILLED,
  FETCH_NEXT_WORKOUT_SESSION_REJECTED,
  WIPE_OUT_NON_DATE,
  FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_START,
  FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_REJECTED,
  COMPLETE_WORKOUT_START,
  COMPLETE_WORKOUT_FULFILLED,
  COMPLETE_WORKOUT_REJECTED,
  SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING,
  UPDATE_CURRENT_NON_DATE_DAY,
  REFETCH_CURRENT_PROGRAM_WORKOUTS_START,
  REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED,
  REFETCH_CURRENT_PROGRAM_WORKOUTS_REJECTED,
  SUBMIT_PROGRAM_WORKOUT_START,
  SUBMIT_PROGRAM_WORKOUT_FULFILLED,
  SUBMIT_PROGRAM_WORKOUT_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START,
  SET_SELECTED_INTEGRATION,
  FETCH_SELECTED_INTEGRATION_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
  FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
  SET_IS_INTEGRATION_MODAL_SHOWING,
  SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED,
  FETCH_MORE_WORKOUT_HISTORY_START,
  FETCH_MORE_WORKOUT_HISTORY_FULFILLED,
  FETCH_MORE_WORKOUT_HISTORY_REJECTED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

axiosCancel(axiosAuthed, {
  debug: true, // default
});

/**
 * Fetches workout items for the specified user by account code and date (used by coaches only)
 * @param {Object} currentUser the current user object
 * @param {Integer} userId the user id of the athlete to fetch workouts for
 * @param {Integer} accountCode the account code the user is currently in
 * @param {String} workoutDate YYYY-MM-DD formatted date to retrieve workouts for
 * @param {Boolean} isAdditionalWorkouts whether it was triggered by a questionnaire completion
 * @param {Boolean} showHiddenItems whether to show hidden workout items
 */
export const getWorkoutItemsForUserByDate = (
  currentUser, userId, workoutDate, isAdditionalWorkouts = false, showHiddenItems = false,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }

  axiosAuthed
    .get(`${baseUrl}/workouts/${workoutDate}?organizeBySession=true${showHiddenItems ? '&showHiddenItems=true' : ''}`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            isAdditionalWorkouts,
          },
          type: FETCH_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUTS_REJECTED,
      });
    });
};

export const fetchProgramSession = (
  currentUser,
  userId,
  programId,
  dayIndex,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_SESSION_START });
  let baseUrl;
  if (currentUser.admin) {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  } else {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${programId}/days/${dayIndex}/summary`)
    .then((response) => {
      dispatch({
        payload: {
          data: response.data,
        },
        type: FETCH_WORKOUT_SESSION_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_WORKOUT_SESSION_REJECTED,
      });
    });
};

export const fetchNextWorkoutSession = (
  currentUser,
  currentSelectedProgram,
  userId,
) => (dispatch) => {
  dispatch({ type: FETCH_NEXT_WORKOUT_SESSION_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(
      `${baseUrl}/day-based-workouts/${currentSelectedProgram.dayBasedProgramId}/next-session`,
    )
    .then((response) => {
      dispatch({
        payload: {
          data: response.data,
        },
        type: FETCH_NEXT_WORKOUT_SESSION_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_NEXT_WORKOUT_SESSION_REJECTED,
      });
    });
};

export const fetchWorkoutProgramWeeks = (
  currentUser,
  program,
  userId,
  programIdx,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAM_WEEKS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(
      `${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/weeks`,
    )
    .then((response) => {
      dispatch(fetchNextWorkoutSession(currentUser, program, userId));
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            program: { ...program, programIdx },
          },
          type: FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED,
        });
      }, [750]);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAM_WEEKS_REJECTED,
      });
    });
};

export const fetchWorkoutProgramWeek = (currentUser, program, weekNum, userId) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAM_WEEK_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum + 1}`)
    .then((response) => {
      const returnData = response.data;
      setTimeout(() => {
        dispatch({
          payload: {
            data: returnData,
            workoutProgramWeekIndex: weekNum,
          },
          type: FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED,
        });
      }, [750]);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAM_WEEK_REJECTED,
      });
    });
};

export const fetchWorkoutProgramWeekViaNext = (
  currentUser,
  program,
  weekNum,
  userId,
  programDayIndex,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum + 1}`)
    .then((response) => {
      const returnData = response.data;
      let weekDayIndex = null;
      returnData.forEach((weekDay, idx) => {
        if (weekDay.zeroBasedDayNum === programDayIndex) {
          weekDayIndex = idx;
        }
      });
      setTimeout(() => {
        dispatch({
          payload: {
            data: returnData,
            workoutProgramWeekIndex: weekNum,
            weekDayIndex,
          },
          type: FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED,
        });
      }, [750]);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_REJECTED,
      });
    });
};

export const fetchNextWorkoutProgramWorkouts = (
  currentUser,
  program,
  dayNum,
  userId,
  day,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}?organizeBySession=true`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            day,
          },
          type: FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

export const fetchPreviousWorkoutProgramWorkouts = (
  currentUser,
  program,
  dayNum,
  userId,
  day,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}?organizeBySession=true`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            day,
          },
          type: FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

export const fetchInitialWorkoutProgramWorkouts = (
  currentUser,
  program,
  dayNum,
  userId,
  day = {},
) => (dispatch) => {
  dispatch({ type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum + 1}?organizeBySession=true`)
    .then((response) => {
      dispatch(fetchProgramSession(
        currentUser,
        userId,
        program.dayBasedProgramId,
        dayNum + 1,
      ));
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            dayIndex: dayNum + 1,
            day,
          },
          type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

export const fetchWorkoutProgramWorkoutsOnLoad = (
  currentUser, program, dayNum, userId, day,
) => (dispatch) => {
  dispatch({ type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}?organizeBySession=true`)
    .then((response) => {
      dispatch(fetchProgramSession(
        currentUser,
        userId,
        program.dayBasedProgramId,
        dayNum,
      ));
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            dayIndex: dayNum + 1,
            day,
          },
          type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

export const refetchCurrentProgramWorkouts = (
  currentUser, program, dayNum, userId,
) => (dispatch) => {
  dispatch({ type: REFETCH_CURRENT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}?organizeBySession=true`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
          },
          type: REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: REFETCH_CURRENT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

export const fetchWorkoutPrograms = (currentUser, userId) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_PROGRAMS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
          },
          type: FETCH_WORKOUT_PROGRAMS_FULFILLED,
        });
      }, [750]);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_WORKOUT_PROGRAMS_REJECTED,
      });
    });
};

/**
 * Fetches workout items for the specified user by account code and date (used by coaches only)
 * and saves it to an array for the workout landing page.
 * @param {Object} currentUser the current user object
 * @param {Integer} userId the user id of the athlete to fetch workouts for
 * @param {Integer} accountCode the account code the user is currently in
 * @param {String} workoutDate YYYY-MM-DD formatted date to retrieve workouts for
 * @param {Boolean} isRefresh whether this request triggered by a pull to refresh on workout list
 */
export const getWorkoutItemsForArray = (
  currentUser,
  userId,
  accountCode,
  workoutDate,
  isRefresh = false,
) => (dispatch) => {
  if (isRefresh) {
    // Don't show regular loading indicators when refresh is happening.
    dispatch({ type: REFRESH_WORKOUTS_START });
  } else {
    dispatch({ type: FETCH_MULTIPLE_WORKOUTS_START });
  }
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/workouts/${workoutDate}`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: {
            data: response.data,
            date: workoutDate,
          },
          type: FETCH_MULTIPLE_WORKOUTS_FULFILLED,
        });
      }, 600);
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_MULTIPLE_WORKOUTS_REJECTED,
      });
    });
};

export const setActiveWorkoutDate = (date) => ({
  payload: date,
  type: SET_ACTIVE_WORKOUT_DATE,
});

export const setAdditionalInfoWorkout = (workout) => ({
  payload: workout,
  type: SET_ADDITIONAL_INFO_WORKOUT,
});

export const setIsAdditionalWorkouts = (bool) => ({
  payload: bool,
  type: SET_IS_ADDITIONAL_WORKOUTS,
});

export const setIsProgramListBoxModalShowing = (bool) => ({
  payload: bool,
  type: SET_IS_PROGRAM_LIST_BOX_MODAL,
});

export const getExertionScore = (date = null) => (dispatch) => {
  /** YYYY-MM-DD */
  const dateQuery = `${date ? `?date=${date}` : ''}`;

  dispatch({ type: GET_EXERTION_SCORE_START });
  axiosAuthed
    .get(`/me/scores/exertion${dateQuery}`)
    .then((response) => {
      dispatch({
        payload: { data: response.data, scoreDate: date },
        type: GET_EXERTION_SCORE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.message,
        type: GET_EXERTION_SCORE_REJECTED,
      });
    });
};

export const getAthletes = (accountCode) => (dispatch) => {
  dispatch({ type: GET_USERS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/users?userType=0`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: GET_USERS_FULLFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: GET_USERS_REJECTED,
      });
    });
};

export const setSharedAthlete = (athleteObj) => ({
  payload: athleteObj,
  type: SET_SHARED_ATHLETE,
});

export const setIsWorkoutsCarouselShowing = (bool, index) => (dispatch, getState) => {
  dispatch({
    payload: {
      bool,
      index,
    },
    type: SET_IS_WORKOUTS_CAROUSEL_SHOWING,
  });
  if (!bool) {
    const { activeWorkoutDate } = getState().workouts.data;
    const { currentUser } = getState().auth.data;
    const { sharedAthlete } = getState().workouts.data;
    const { hiddenWorkoutsToggle } = getState().workouts.ui;
    let route;
    if (currentUser.id === sharedAthlete.id) {
      route = `/me/accounts/${currentUser.accountCode}`;
    } else {
      route = `/accounts/${currentUser.accountCode}/users/${sharedAthlete.id}`;
    }
    dispatch({ type: GET_UPDATED_ACTIVE_DATE_START });
    axiosAuthed
      .get(
        `${route}/workouts/overview?dateRangeStart=${activeWorkoutDate}&dateRangeEnd=${activeWorkoutDate}${hiddenWorkoutsToggle ? '&showHiddenItems=true' : ''}`,
        {
          requestId: 'fetchWorkoutCalendar',
        },
      )
      .then((response) => {
        dispatch({
          payload: { data: response.data },
          type: GET_UPDATED_ACTIVE_DATE_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err.message,
          type: GET_UPDATED_ACTIVE_DATE_REJECTED,
        });
      });
  }
};

export const fetchWorkoutCalendar = (
  currentUser, userId, startDate, endDate, showHiddenItems = false,
) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_CALENDAR_START });
  let route;
  if (currentUser.id === userId) {
    route = `/me/accounts/${currentUser.accountCode}`;
  } else {
    route = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(
      `${route}/workouts/overview?dateRangeStart=${startDate}&dateRangeEnd=${endDate}${showHiddenItems ? '&showHiddenItems=true' : ''}`,
      {
        requestId: 'fetchWorkoutCalendar',
      },
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_WORKOUT_CALENDAR_FULFILLED,
      });
    })
    .catch((thrown) => {
      if (axiosAuthed.isCancel(thrown)) {
        console.log('Fetch Calendar Cancelled');
      } else {
        console.log('Other Reason');
      }
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workout calendar',
        type: FETCH_WORKOUT_CALENDAR_REJECTED,
      });
    });
};

export const fetchWorkoutCalendarAlt = (athleteId, startDate, endDate) => (
  (dispatch) => {
    dispatch({ type: FETCH_WORKOUT_CALENDAR_START_ALT });
    axios.get(`/workouts/info?start=${startDate}&end=${endDate}&user_id=${athleteId}`, {
      requestId: fetchWorkoutCalendar,
    })
      .then((response) => {
        dispatch({ type: FETCH_WORKOUT_CALENDAR_FULFILLED_ALT, payload: response.data });
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Fetch Calendar Cancelled');
        } else {
          console.log('Other Reason');
        }
      })
      .catch((err) => {
        dispatch({ type: FETCH_WORKOUT_CALENDAR_REJECTED_ALT, payload: err });
      });
  }
);

export const fetchSessionByDate = (currentUser, userId, workoutDate, dayInfo) => (dispatch) => {
  dispatch({ type: FETCH_WORKOUT_SESSION_START });
  let baseUrl;
  if (currentUser.admin) {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  } else {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  }
  axiosAuthed
    .get(`${baseUrl}/workouts/${workoutDate}/summary?useAssignedDate=true`)
    .then((response) => {
      dispatch({
        payload: {
          data: response.data,
          activeDayInfo: dayInfo,
        },
        type: FETCH_WORKOUT_SESSION_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_WORKOUT_SESSION_REJECTED,
      });
    });
};

export const fetchPreviousWorkoutProgramWeek = (
  currentUser,
  program,
  weekNum,
  userId,
) => (dispatch) => {
  dispatch({ type: FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum + 1}`)
    .then((response) => {
      const returnData = response.data;
      dispatch(fetchPreviousWorkoutProgramWorkouts(
        currentUser,
        program,
        returnData[returnData.length - 1].trueDayNum,
        userId,
        returnData[returnData.length - 1],
      ));
      dispatch(fetchProgramSession(
        currentUser,
        userId,
        program.dayBasedProgramId,
        returnData[returnData.length - 1].trueDayNum,
      ));
      dispatch({
        payload: {
          data: returnData,
          workoutProgramWeekIndex: weekNum,
        },
        type: FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_REJECTED,
      });
    });
};

export const fetchNextWorkoutProgramWeek = (
  currentUser,
  program,
  weekNum,
  userId,
) => (dispatch) => {
  dispatch({ type: FETCH_NEXT_WORKOUT_PROGRAM_WEEK_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .get(`${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/weeks/${weekNum + 1}`)
    .then((response) => {
      const returnData = response.data;
      dispatch(fetchNextWorkoutProgramWorkouts(
        currentUser,
        program,
        returnData[0].trueDayNum,
        userId,
        returnData[0],
      ));
      dispatch({
        payload: {
          data: returnData,
          workoutProgramWeekIndex: weekNum,
        },
        type: FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED,
      });
      dispatch(fetchProgramSession(
        currentUser,
        userId,
        program.dayBasedProgramId,
        returnData[0].trueDayNum,
      ));
    })
    .catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: FETCH_NEXT_WORKOUT_PROGRAM_WEEK_REJECTED,
      });
    });
};

/**
 * Fetches an array of all exercises for the given account code
 * @param {Integer} accountCode the account code
 */
export const fetchAllLiftExercisesForAccount = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_OPT_OUT_ALL_LIFT_EXERCISES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/exercises?type=L`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_OPT_OUT_ALL_LIFT_EXERCISES_REJECTED,
      });
    });
};

/**
 * Fetches an array of similar exercises for the passed exercise id and account code
 * @param {Integer} exerciseId the exercise ID
 * @param {Integer} accountCode the account code
 */
export const fetchSimilarExercisesByExerciseId = (exerciseId, accountCode) => (dispatch) => {
  dispatch({ type: FETCH_SIMILAR_EXERCISES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/exercises?similarTo=${exerciseId}`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_SIMILAR_EXERCISES_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: FETCH_SIMILAR_EXERCISES_REJECTED,
      });
    });
};

/**
 * Submits an API call to opt out of a workout item for the given parameters
 * @param {Object} currentUser the currently loggged in user
 * @param {Integer} userId the user id to submit the workout item for
 * @param {Integer} accountCode the user's account code
 * @param {String} workoutDate YYYY-MM-DD formatted date of the workout
 * @param {Integer} assignedWorkoutId the ID of the workout assignment
 * @param {Integer} optOutEnum integer representing the opt out reason
 * @param {String} optOutText optional text based opt out reason
 * @param {Integer} substitutedExerciseId optional id of the exercise the user wishes to substitute
 */
export const submitOptOut = (
  currentUser,
  userId,
  accountCode,
  workoutDate,
  assignedWorkoutId,
  optOutEnum,
  optOutText = '',
  substitutedExerciseId = null,
  itemIndex,
  sessionIndex,
) => (dispatch) => {
  dispatch({ type: SUBMIT_OPT_OUT_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  // Build payload for the API
  const data = {
    optOut: optOutEnum,
  };
  if (optOutText) {
    data.optOutReason = optOutText;
  }
  if (substitutedExerciseId) {
    data.substitutedExerciseId = substitutedExerciseId;
  }
  axiosAuthed
    .post(
      `${baseUrl}/workouts/${workoutDate}/items/${assignedWorkoutId}/opt-out`,
      data,
    )
    .then((response) => {
      if (sessionIndex || sessionIndex === 0) {
        dispatch({
          payload: {
            data: response.data,
            index: itemIndex,
            sessionItemIndex: sessionIndex,
          },
          type: SUBMIT_OPT_OUT_FULFILLED,
        });
      } else {
        dispatch({
          payload: {
            data: response.data,
            index: itemIndex,
          },
          type: SUBMIT_OPT_OUT_TOP_FULFILLED,
        });
      }
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_OPT_OUT_REJECTED,
      });
    });
};

export const submitOptOutNonDate = (
  currentUser,
  userId,
  accountCode,
  saveDataId,
  optOutEnum,
  optOutText = '',
  substitutedExerciseId = null,
  itemIndex,
  sessionIndex,
  dayBasedProgramId,
  dayNum,
) => (dispatch) => {
  dispatch({ type: SUBMIT_OPT_OUT_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  // Build payload for the API
  const data = {
    optOut: optOutEnum,
  };
  if (optOutText) {
    data.optOutReason = optOutText;
  }
  if (substitutedExerciseId) {
    data.substitutedExerciseId = substitutedExerciseId;
  }
  // day-based-workouts/{dayBasedProgramId}/days/{dayNum}/items/{saveDataId}/opt-out
  axiosAuthed
    .post(
      `${baseUrl}/day-based-workouts/${dayBasedProgramId}/days/${dayNum}/items/${saveDataId}/opt-out`,
      data,
    )
    .then((response) => {
      if (sessionIndex || sessionIndex === 0) {
        dispatch({
          payload: {
            data: response.data,
            index: itemIndex,
            sessionItemIndex: sessionIndex,
          },
          type: SUBMIT_OPT_OUT_FULFILLED,
        });
      } else {
        dispatch({
          payload: {
            data: response.data,
            index: itemIndex,
          },
          type: SUBMIT_OPT_OUT_TOP_FULFILLED,
        });
      }
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_OPT_OUT_REJECTED,
      });
    });
};

export const setOptOutExerciseTypeFilter = (filterType) => ({
  payload: filterType,
  type: SET_OPT_OUT_EXERCISE_TYPE_FILTER,
});

export const setProgramDayIndex = (index) => ({
  payload: index,
  type: SET_PROGRAM_DAY_INDEX,
});

export const setActiveWorkoutsMedia = (media) => ({
  payload: media,
  type: SET_ACTIVE_WORKOUTS_MEDIA,
});

export const setWeekDayIndex = (index) => ({
  payload: index,
  type: SET_WEEKDAY_INDEX,
});

export const setOptOutWorkout = (object) => ({
  payload: object,
  type: SET_OPT_OUT_WORKOUT,
});

export const setIsProgramInformationModalShowing = (object) => ({
  payload: object,
  type: SET_IS_PROGRAM_INFORMATION_MODAL_SHOWING,
});

export const setCaptureImageModal = (bool) => ({
  payload: bool,
  type: SET_CAPTURE_IMAGE_MODAL,
});

export const setIsDayCarousel = (bool) => ({
  payload: bool,
  type: SET_IS_DAY_CAROUSEL,
});

export const setCaptureVideoModal = (bool) => ({
  payload: bool,
  type: SET_CAPTURE_VIDEO_MODAL,
});

export const setHistoryWorkout = (historyWorkout) => ({
  payload: historyWorkout,
  type: SET_HISTORY_WORKOUT,
});

export const wipeOutNonDate = () => ({
  type: WIPE_OUT_NON_DATE,
});

export const updateCurrentNonDateDay = (object) => ({
  payload: object,
  type: UPDATE_CURRENT_NON_DATE_DAY,
});

/**
 * Submits an API call to complete a workout item for the given parameters
 * @param {Object} currentUser the currently loggged in user
 * @param {Integer} userId the user id to submit the workout item for
 * @param {Integer} accountCode the user's account code
 * @param {String} workoutDate YYYY-MM-DD formatted date of the workout
 * @param {Integer} assignedWorkoutId the ID of the workout assignment
 * @param {Array} data the data to submit to the API
 */
export const submitWorkoutItem = (
  currentUser,
  userId,
  accountCode,
  workoutDate,
  assignedWorkoutId,
  data,
  itemIndex,
  outerIndex,
  sessionIndex = null,
) => (dispatch) => {
  dispatch({ type: SUBMIT_WORKOUT_START, workoutItemIndex: itemIndex });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/workouts/${workoutDate}/items/${assignedWorkoutId}`,
      data,
      {
        requestId: 'submitWorkoutItem',
      },
    )
    .then((response) => {
      dispatch(fetchSessionByDate(currentUser, userId, workoutDate, {}));
      setTimeout(() => {
        if (sessionIndex || sessionIndex === 0) {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
              sessionItemIndex: sessionIndex,
            },
            type: SUBMIT_SESSION_WORKOUT_FULFILLED,
          });
        } else {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
            },
            type: SUBMIT_WORKOUT_FULFILLED,
          });
        }
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_WORKOUT_REJECTED,
      });
    });
};

export const submitProgramWorkoutItem = (
  currentUser,
  userId,
  accountCode,
  saveDataId,
  data,
  itemIndex,
  outerIndex,
  sessionIndex = null,
  programId,
  dayIndex,
) => (dispatch, getState) => {
  dispatch({ type: SUBMIT_PROGRAM_WORKOUT_START, workoutItemIndex: itemIndex });
  const {
    currentNonDateDay,
    workoutProgramWeekIndex,
    weekDayIndex,
  } = getState().workouts.data;
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/day-based-workouts/${programId}/days/${dayIndex}/items/${saveDataId}`,
      data,
      {
        requestId: 'submitWorkoutItem',
      },
    )
    .then((response) => {
      dispatch(fetchProgramSession(currentUser, userId, programId, dayIndex));
      setTimeout(() => {
        if (sessionIndex || sessionIndex === 0) {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
              sessionItemIndex: sessionIndex,
              updateWeekIndex: workoutProgramWeekIndex,
              updateDayIndex: weekDayIndex,
            },
            type: SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED,
          });
        } else {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
              updateWeekIndex: workoutProgramWeekIndex,
              updateDayIndex: weekDayIndex,
            },
            type: SUBMIT_PROGRAM_WORKOUT_FULFILLED,
          });
        }
      }, 750);
      setTimeout(() => {
        if (currentNonDateDay && Object.keys(currentNonDateDay).length) {
          dispatch(updateCurrentNonDateDay(response.data));
        }
      }, [750]);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_PROGRAM_WORKOUT_REJECTED,
      });
    });
};

/**
 * Sets the selected exercise for a tag-select workout item
 */
export const setSelectedExerciseForTag = (exercise, tagWorkout) => ({
  payload: { exercise, tagWorkout },
  type: SET_SELECTED_TAG_EXERCISE,
});

export const submitTagWorkoutItem = (
  currentUser,
  userId,
  accountCode,
  workoutDate,
  assignedWorkoutId,
  data,
  itemIndex,
  outerIndex,
  sessionIndex = null,
) => (dispatch) => {
  dispatch({ type: SUBMIT_WORKOUT_START, workoutItemIndex: itemIndex });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/workouts/${workoutDate}/items/${assignedWorkoutId}`,
      data,
      {
        requestId: 'submitWorkoutItem',
      },
    )
    .then((response) => {
      dispatch(fetchSessionByDate(currentUser, userId, workoutDate, {}));
      setTimeout(() => {
        if (sessionIndex || sessionIndex === 0) {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
              sessionItemIndex: sessionIndex,
            },
            type: SUBMIT_SESSION_WORKOUT_FULFILLED,
          });
        } else {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
            },
            type: SUBMIT_WORKOUT_FULFILLED,
          });
        }
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_WORKOUT_REJECTED,
      });
    });
};

export const submitProgramTagWorkoutItem = (
  currentUser,
  userId,
  accountCode,
  saveDataId,
  data,
  itemIndex,
  outerIndex,
  programId,
  dayIndex,
  sessionIndex = null,
) => (dispatch) => {
  dispatch({ type: SUBMIT_WORKOUT_START, workoutItemIndex: itemIndex });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${accountCode}`;
  } else {
    baseUrl = `/accounts/${accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/day-based-workouts/${programId}/days/${dayIndex}/items/${saveDataId}`,
      data,
      {
        requestId: 'submitWorkoutItem',
      },
    )
    .then((response) => {
      setTimeout(() => {
        if (sessionIndex || sessionIndex === 0) {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
              sessionItemIndex: sessionIndex,
            },
            type: SUBMIT_SESSION_WORKOUT_FULFILLED,
          });
        } else {
          dispatch({
            payload: {
              data: response.data,
              index: outerIndex,
            },
            type: SUBMIT_WORKOUT_FULFILLED,
          });
        }
      }, 750);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: SUBMIT_WORKOUT_REJECTED,
      });
    });
};

export const setTimerDuration = (durationInSeconds) => ({
  payload: durationInSeconds,
  type: SET_TIMER_DURATION,
});

export const setTimerModalOpen = (isOpen) => ({
  payload: isOpen,
  type: SET_TIMER_MODAL_OPEN,
});

export const setQuestionnaireTotal = (object) => ({
  payload: object,
  type: SET_QUESTIONNAIRE_TOTAL,
});

export const fetchExercisesByTagIDForAccount = (tagId, accountCode) => (dispatch) => {
  dispatch({ type: FETCH_TAG_EXERCISES_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/exercises?tagId=${tagId}`)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: response.data,
          type: FETCH_TAG_EXERCISES_FULFILLED,
        });
      }, 750);
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch({
          payload: err,
          type: FETCH_TAG_EXERCISES_REJECTED,
        });
      }, 750);
    });
};

export const uploadWorkoutsMedia = (values) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append(values.type, values.acceptedFile[0]);
    dispatch({ type: UPLOAD_WORKOUTS_MEDIA_START });
    axiosAuthed.post(`/media/upload/${values.type}`, formData).then((response) => {
      dispatch({
        type: UPLOAD_WORKOUTS_MEDIA_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_WORKOUTS_MEDIA_REJECTED,
          payload: err,
        });
      });
  }
);

export const uploadWorkoutJournalMedia = (values) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append(values.type, values.acceptedFile[0]);
    dispatch({ type: UPLOAD_WORKOUT_JOURNAL_MEDIA_START });
    axiosAuthed.post(`/media/upload/${values.type}`, formData).then((response) => {
      dispatch({
        type: UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_WORKOUT_JOURNAL_MEDIA_REJECTED,
          payload: err,
        });
      });
  }
);

export const uploadQuickJournalMedia = (values) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append(values.type, values.acceptedFile[0]);
    dispatch({ type: UPLOAD_QUICK_JOURNAL_MEDIA_START });
    axiosAuthed.post(`/media/upload/${values.type}`, formData).then((response) => {
      dispatch({
        type: UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED,
          payload: err,
        });
      });
  }
);

export const setIsWorkoutsMediaUploaded = (bool) => (
  {
    type: SET_IS_WORKOUTS_MEDIA_UPLOADED,
    payload: bool,
  }
);

export const setTagWorkout = (tagWorkout) => (
  {
    type: SET_TAG_WORKOUT,
    payload: tagWorkout,
  }
);

export const setIsWorkoutJournalMediaUploaded = (bool) => (
  {
    type: SET_IS_WORKOUT_JOURNAL_MEDIA_UPLOADED,
    payload: bool,
  }
);

export const setJournalEntryWorkout = (workoutObject) => (
  {
    type: SET_JOURNAL_ENTRY_WORKOUT,
    payload: workoutObject,
  }
);

export const fetchWorkoutHistory = (exerId, currentUser, userId) => (
  (dispatch) => {
    dispatch({ type: FETCH_WORKOUT_HISTORY_START });
    let baseUrl;
    if (userId === currentUser.id) {
      baseUrl = `/me/accounts/${currentUser.accountCode}`;
    } else {
      baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
    }
    axiosAuthed.get(`${baseUrl}/exercises/${exerId}/workouts/history`)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: FETCH_WORKOUT_HISTORY_FULFILLED, payload: response.data });
        }, 1000);
      })
      .catch((err) => {
        dispatch({ type: FETCH_WORKOUT_HISTORY_REJECTED, payload: err });
      });
  }
);

export const fetchMoreWorkoutHistory = (exerId, currentUser, userId, currentPage) => (
  (dispatch) => {
    dispatch({ type: FETCH_MORE_WORKOUT_HISTORY_START });
    let baseUrl;
    if (userId === currentUser.id) {
      baseUrl = `/me/accounts/${currentUser.accountCode}`;
    } else {
      baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
    }
    axiosAuthed.get(`${baseUrl}/exercises/${exerId}/workouts/history?page=${currentPage + 1}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: FETCH_MORE_WORKOUT_HISTORY_FULFILLED, payload: response.data });
        }, 1000);
      })
      .catch((err) => {
        dispatch({ type: FETCH_MORE_WORKOUT_HISTORY_REJECTED, payload: err });
      });
  }
);

export const createMeJournalEntry = (accountCode, values, index, sessionItemIndex, date) => (
  (dispatch) => {
    dispatch({ type: CREATE_WORKOUT_JOURNAL_ENTRY_START });
    axiosAuthed.post(`/me/accounts/${accountCode}/journals/entries`, values)
      .then((response) => {
        if (sessionItemIndex || sessionItemIndex === 0) {
          dispatch({
            type: CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
            payload: {
              data: response.data,
              index,
              sessionItemIndex,
            },
          });
        } else {
          dispatch({
            type: CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
            payload: {
              data: response.data,
              index,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: CREATE_WORKOUT_JOURNAL_ENTRY_REJECTED,
          payload: err,
        });
      });
  }
);

export const setUploadWorkoutMediaFalse = () => (
  {
    type: SET_UPLOAD_WORKOUT_MEDIA_FALSE,
  }
);

export const wipeMediaError = () => (
  {
    type: WIPE_MEDIA_ERROR,
  }
);

export const setUploadWorkoutJournalMediaFalse = () => (
  {
    type: SET_UPLOAD_WORKOUT_JOURNAL_MEDIA_FALSE,
  }
);

export const writeWorkoutNoteToAthlete = (accountCode, values, index, sessionItemIndex) => (
  (dispatch) => {
    dispatch({ type: WRITE_WORKOUT_NOTE_TO_ATHLETE_START });
    axiosAuthed.post(`/accounts/${accountCode}/journals/entries`, values)
      .then((response) => {
        if (sessionItemIndex || sessionItemIndex === 0) {
          dispatch({
            type: WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
            payload: {
              data: response.data,
              index,
              sessionItemIndex,
            },
          });
        } else {
          dispatch({
            type: WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
            payload: {
              data: response.data,
              index,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: WRITE_WORKOUT_NOTE_TO_ATHLETE_REJECTED,
          payload: err,
        });
      });
  }
);

export const setRemoveWorkoutJournalMediaArray = (mediaId) => (
  {
    type: SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY,
    payload: mediaId,
  }
);

export const setExertionScoreModalShowing = (bool) => (
  {
    type: SET_EXERTION_SCORE_MODAL_SHOWING,
    payload: bool,
  }
);

export const setAdditionalWorkoutsData = (object) => (
  {
    type: SET_ADDITIONAL_WORKOUTS_DATA,
    payload: object,
  }
);

export const setIsDayPickerOpen = (bool) => (
  {
    type: SET_IS_DAY_PICKER_OPEN,
    payload: bool,
  }
);

export const setIsSmallScreenDayPickerOpen = (bool) => (
  {
    type: SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN,
    payload: bool,
  }
);

export const setIsPrintingOptionsShowing = (bool) => (
  {
    type: SET_IS_PRINTING_OPTIONS_SHOWING,
    payload: bool,
  }
);

export const setIsResetProgramModalShowing = (bool) => (
  {
    type: SET_IS_RESET_PROGRAM_MODAL_SHOWING,
    payload: bool,
  }
);

export const setIsCompleteProgramModalShowing = (bool) => (
  {
    type: SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING,
    payload: bool,
  }
);

export const setIsCompleteWorkoutModalShowing = (bool) => (
  {
    type: SET_IS_COMPLETE_WORKOUT_MODAL_SHOWING,
    payload: bool,
  }
);

export const setIsSkipWorkoutModalShowing = (bool) => (
  {
    type: SET_IS_SKIP_WORKOUT_MODAL_SHOWING,
    payload: bool,
  }
);

export const setMultiQuestionModal = (questionObject, workoutId) => (
  {
    type: SET_MULTI_QUESTION_MODAL,
    payload: {
      workoutId,
      questionObject,
    },
  }
);

export const setQuickImageWorkoutsJournalModal = (bool) => (
  {
    type: SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL,
    payload: bool,
  }
);

export const setQuickVideoWorkoutsJournalModal = (bool) => (
  {
    type: SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL,
    payload: bool,
  }
);

export const setQuickWorkoutsJournal = (object) => (
  {
    type: SET_QUICK_WORKOUTS_JOURNAL,
    payload: object,
  }
);

export const setMultiQuestionModalResponse = (response) => (
  {
    type: SET_MULTI_QUESTION_MODAL_RESPONSE,
    payload: response,
  }
);

export const setQuickJournalModal = (bool) => (
  {
    type: SET_QUICK_JOURNAL_MODAL,
    payload: bool,
  }
);

export const getWorkoutsExertionScore = (date = null) => (dispatch) => {
  /** YYYY-MM-DD */
  const dateQuery = `${date ? `?date=${date}` : ''}`;

  dispatch({ type: GET_WORKOUTS_EXERTION_SCORE_START });
  axiosAuthed
    .get(`/me/scores/exertion${dateQuery}`)
    .then((response) => {
      dispatch({
        payload: { data: response.data, scoreDate: date },
        type: GET_WORKOUTS_EXERTION_SCORE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.message,
        type: GET_WORKOUTS_EXERTION_SCORE_REJECTED,
      });
    });
};

export const updateJournalEntry = (accountCode, values, entryId, index, sessionItemIndex) => (
  (dispatch, getState) => {
    dispatch({ type: UPDATE_WORKOUT_JOURNAL_ENTRY_START });
    const { currentUser } = getState().auth.data;
    let uri;
    if (currentUser.admin) {
      uri = `/accounts/${accountCode}/journals/entries/${entryId}`;
    } else {
      uri = `/me/accounts/${accountCode}/journals/entries/${entryId}`;
    }
    axiosAuthed.put(uri, values)
      .then((response) => {
        if (sessionItemIndex || sessionItemIndex === 0) {
          dispatch({
            type: UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
            payload: {
              data: response.data,
              index,
              sessionItemIndex,
            },
          });
        } else {
          dispatch({
            type: UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
            payload: {
              data: response.data,
              index,
            },
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_WORKOUT_JOURNAL_ENTRY_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchWorkoutFile = (accountCode, docId) => (
  (dispatch) => {
    dispatch({ type: FETCH_WORKOUT_FILE_START });
    axiosAuthed.get(`/accounts/${accountCode}/documents/${docId}`)
      .then((response) => {
        if (response.data) {
          window.open(response.data.url, '_blank');
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_WORKOUT_FILE_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchWorkoutDocument = (accountCode, docId) => (
  (dispatch) => {
    dispatch({ type: FETCH_WORKOUT_DOCUMENT_START });
    axiosAuthed.get(`/accounts/${accountCode}/documents/${docId}`)
      .then((response) => {
        if (accountCode !== undefined) {
          if (response.data) {
            if (response.data.type === 1 || response.data.type === 2) {
              const responseId = response.data.id;
              if (response.data.type === 1) {
                dispatch(fetchWorkoutFile(accountCode, responseId));
              }
              if (response.data.type === 2) {
                window.open(response.data.url, '_blank');
              }
            }
          }
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_WORKOUT_DOCUMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const setHiddenWorkoutsToggle = (bool) => (
  {
    type: SET_HIDDEN_WORKOUTS_TOGGLE,
    payload: bool,
  }
);

export const resetWorkoutProgram = (
  currentUser,
  userId,
  currentSelectedProgram,
  currentNonDateDay,
  workoutProgramWeekIndex,
) => (dispatch) => {
  dispatch({ type: RESET_WORKOUT_PROGRAM_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/day-based-workouts/${currentSelectedProgram.dayBasedProgramId}`,
    )
    .then((response) => {
      const responseData = response.data;
      const { programIdx } = currentSelectedProgram;
      const organizedData = { ...responseData, programIdx };
      const fakeNonCurrentDay = {
        absoluteDayDisplayNum: 1,
        dayTitle: 'Day 1',
        numberOfItems: 2,
        relativeDayDisplayNum: 1,
        status: 'Not Started',
        statusColorCode: null,
        title: 'Day 1',
        trueDayNum: 1,
        trueWeekNum: 1,
        weekTitle: 'Week 1',
        zeroBasedDayNum: 0,
        zeroBasedWeekNum: 0,
      };
      dispatch(fetchWorkoutProgramWeek(
        currentUser,
        organizedData,
        workoutProgramWeekIndex,
        userId,
      ));
      dispatch(fetchWorkoutProgramWeeks(
        currentUser,
        organizedData,
        userId,
        organizedData.programIdx,
      ));
      if (Object.keys(currentNonDateDay).length) {
        dispatch(
          fetchInitialWorkoutProgramWorkouts(
            currentUser,
            organizedData,
            0,
            userId,
            fakeNonCurrentDay,
          ),
        );
        dispatch(setWeekDayIndex(0));
        dispatch(setIsDayCarousel(true));
        dispatch(fetchWorkoutProgramWeeks(currentUser, organizedData, userId, 0));
        dispatch(fetchWorkoutProgramWeek(
          currentUser,
          organizedData,
          0,
          userId,
        ));
      }
      dispatch(fetchNextWorkoutSession(currentUser, organizedData, userId));
      dispatch({
        payload: {
          data: organizedData,
        },
        type: RESET_WORKOUT_PROGRAM_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: RESET_WORKOUT_PROGRAM_REJECTED,
      });
    });
};

export const completeWorkout = (
  currentUser,
  userId,
  currentSelectedProgram,
  dayNum,
  weekDayIndex,
) => (dispatch) => {
  dispatch({ type: COMPLETE_WORKOUT_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .put(
      `${baseUrl}/day-based-workouts/${currentSelectedProgram.dayBasedProgramId}/days/${dayNum}/status`,
      { status: 'Completed' },
    )
    .then((response) => {
      dispatch(fetchWorkoutProgramWeeks(
        currentUser,
        currentSelectedProgram,
        userId,
        currentSelectedProgram.programIdx,
      ));
      dispatch(fetchWorkoutProgramWeeks(
        currentUser,
        currentSelectedProgram,
        userId,
        currentSelectedProgram.programIdx,
      ));
      dispatch(fetchNextWorkoutSession(currentUser, currentSelectedProgram, userId));
      dispatch(fetchInitialWorkoutProgramWorkouts(
        currentUser,
        currentSelectedProgram,
        dayNum - 1,
        userId,
        response.data,
      ));
      dispatch({
        payload: {
          data: response.data,
          weekDayIndex,
        },
        type: COMPLETE_WORKOUT_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: COMPLETE_WORKOUT_REJECTED,
      });
    });
};

export const completeWorkoutProgram = (
  currentUser,
  userId,
  currentSelectedProgram,
  workoutProgramWeekIndex,
) => (dispatch) => {
  dispatch({ type: COMPLETE_WORKOUT_PROGRAM_START });
  // Handle athlete vs. coach user
  let baseUrl;
  if (userId === currentUser.id) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .post(
      `${baseUrl}/day-based-workouts/${currentSelectedProgram.dayBasedProgramId}/complete`,
    )
    .then((response) => {
      const responseData = response.data;
      const { programIdx } = currentSelectedProgram;
      const organizedData = { ...responseData, programIdx };
      dispatch(fetchWorkoutProgramWeek(
        currentUser,
        organizedData,
        workoutProgramWeekIndex,
        userId,
      ));
      dispatch(fetchWorkoutProgramWeeks(
        currentUser,
        organizedData,
        userId,
        organizedData.programIdx,
      ));
      dispatch({
        payload: {
          data: organizedData,
        },
        type: COMPLETE_WORKOUT_PROGRAM_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: COMPLETE_WORKOUT_PROGRAM_REJECTED,
      });
    });
};

export const skipWorkout = (
  currentUser,
  program,
  dayNum,
  userId,
  weekDayIndex,
  refetch = false,
) => (dispatch) => {
  dispatch({ type: SKIP_WORKOUT_PROGRAM_WORKOUTS_START });
  let baseUrl;
  if (currentUser.id === userId) {
    baseUrl = `/me/accounts/${currentUser.accountCode}`;
  } else {
    baseUrl = `/accounts/${currentUser.accountCode}/users/${userId}`;
  }
  axiosAuthed
    .put(
      `${baseUrl}/day-based-workouts/${program.dayBasedProgramId}/days/${dayNum}/status`,
      { status: 'Skipped' },
    )
    .then((response) => {
      console.log(refetch);
      if (!refetch) {
        dispatch(fetchNextWorkoutSession(currentUser, program, userId));
      } else {
        dispatch(refetchCurrentProgramWorkouts(currentUser, program, dayNum, userId));
      }
      dispatch({
        payload: {
          data: response.data,
          weekDayIndex,
        },
        type: SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
      });
    }).catch((err) => {
      dispatch({
        payload:
            err?.response?.headers?.message
            || 'Unexpected error fetching workouts',
        type: SKIP_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
      });
    });
};

/**
 * @param {Integer} accountCode the account code
 * @param {Integer} userId the user ID
 * @param {String} date the date to fetch the integrations for
 */
export const fetchImportedIntegrationsByDate = (currentUser, userId, date) => (dispatch) => {
  dispatch({ type: FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START });
  const { accountCode } = currentUser;

  let route;
  if (currentUser.id === userId) {
    route = `/me/accounts/${accountCode}/integrations/imports/${date}/summary`;
  } else {
    route = `/accounts/${accountCode}/integrations/users/${userId}/imports/${date}/summary`;
  }
  axiosAuthed
    .get(route)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
      });
    });
};

/**
 * @param {Integer} accountCode the account code
 * @param {Integer} userId the user ID
 * @param {String} date the date to fetch the integrations for
 * @param {String} integration the integration to fetch data for
 * @param {String} testType the test type to fetch data for
 */
export const fetchSelectedIntegrationByDate = (currentUser, userId, date, integration, testType) => (dispatch) => {
  const { accountCode } = currentUser;

  dispatch({ type: FETCH_SELECTED_INTEGRATION_BY_DATE_START });
  let route;
  if (currentUser.id === userId) {
    route = `/me/accounts/${accountCode}/integrations/imports/${integration}/${testType}/${date}/data`;
  } else {
    route = `/accounts/${accountCode}/integrations/users/${userId}/imports/${integration}/${testType}/${date}/data`;
  }

  axiosAuthed
    .get(route)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
      });
    });
};

/**
 * @param {Object} integration the integration to set as selected
 */
export const setSelectedIntegration = (integration) => ({
  payload: integration,
  type: SET_SELECTED_INTEGRATION,
});

export const setIsIntegrationModalShowing = (bool) => ({
  type: SET_IS_INTEGRATION_MODAL_SHOWING,
  payload: bool,
});
