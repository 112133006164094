/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';

import VideoPlayerModal from './modals/VideoPlayerModal';
import MediaModalContent from './modals/MediaModalContent';

import {

} from '../../ducks/workoutsActions';

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const MediaModalContainer = () => {
  const activeWorkoutsMedia = useSelector((state) => state.workouts.data.activeWorkoutsMedia);

  // if activeWorkoutsMedia object has keys, then render the media modal
  // this way we control the presence of the modal by controlling the
  // existence of the object through which it operates - if nothing to operate on,
  // then no modal
  if (Object.keys(activeWorkoutsMedia).length) {
    return (
      <>
        <VideoPlayerModal>
          <MediaModalContent />
        </VideoPlayerModal>
      </>
    );
  }
  return null;
};

export default MediaModalContainer;
