/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import {
  setTimerDuration,
  setTimerModalOpen,
  submitWorkoutItem,
  submitProgramWorkoutItem,
} from '../../ducks/workoutsActions';

const RepsBox = styled('div')`
  display: flex;
  margin-bottom: 25px;
  border-radius: 12px;
  background: white;
  box-shadow: ${BoxShadow};
	flex-direction: column;
	align-items: center;
  width: ${(props) => `${props.width * 75}px`};
  min-width: 100%;
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
`;

const StandardBox = styled('div')`
  display: flex;
	justify-content: center;
  flex: 1;
	font-family: 'Nunito Sans';
	font-weight: 600;
	font-size: 11px;
	align-items: center;
`;

const SetNameBox = styled('div')`
  display: flex;
	justify-content: center;
  flex: 1;
	font-family: 'Nunito Sans';
	font-weight: 600;
	font-size: 9px;
	align-items: center;
`;

const StyledInput = styled('input')`
	font-family: 'Nunito Sans';
	font-weight: 700;
	font-size: 13px;
	border: 1px solid #E8E8E8;
	text-align: center;
  width: 90%;
  border-radius: 8px;
  height: 38px;
`;

const StandardRow = styled('div')`
  display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 15px;
`;

const CompleteButton = styled('button')`
  width: 140px;
	height: 37px;
	border-radius: 40px;
  box-shadow: ${BoxShadow};
	border: ${(props) => (props.isWorkoutComplete ? '1px solid #00b371;' : '1px solid lightgrey')};
	margin-top: 20px;
	background: ${(props) => (props.isWorkoutComplete ? '#00b371;' : 'white')};
`;

const RestTimerButton = styled('button')`
  width: 100%;
	border-radius: 10px;
	margin-top: 15px;
	background: #3fbfe1;
  border: none;
  padding: 2px;
  height: 27px;
  &:hover {
    opacity: 0.75;
  }
`;

const InnerCompleteButton = styled('div')`
  display: flex;
	justify-content: center;
	align-items: center;
`;

const CompleteButtonText = styled('div')`
  font-size: 12px;
	margin-left: ${(props) => (props.checkmark === false ? '0px' : '10px')};
	font-weight: 600;
	font-family: 'Nunito Sans';
	color: ${(props) => (props.isWorkoutComplete ? 'white' : 'black')};
`;

const RestTimerButtonText = styled('div')`
  font-size: 9px;
	margin-left: 10px;
	font-weight: 800;
	font-family: 'Nunito Sans';
	color: white;
`;

const SavePlaceholderValuesButton = styled('button')`
  width: 140px;
	height: 37px;
	border-radius: 40px;
	border: ${(props) => (props.isWorkoutComplete ? '1px solid #00b371;' : '1px solid lightgrey')};
	margin-top: 20px;
	background: ${(props) => (props.isWorkoutComplete ? '#00b371;' : 'white')};
`;

const SavePlaceholderButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SaveSuggestedText = styled('div')`
  font-size: 10px;
  margin-top: 15px;
`;

const ButtonContainer = styled('div')`
  width: 100%;
	display: flex;
	justify-content: center;
`;

const SetNameText = styled('div')`
  width: 80%;
  display: flex;
  justify-content: center;
  white-space: normal;
  text-align: center;
`;

const SetNumberAndPercentage = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Nunito Sans';
`;

const Percentage = styled('div')`
  display: flex;
  font-family: 'Nunito Sans';
  color: grey;
  font-size: 9px;
`;

const CoachCompletionOnlyText = styled('div')`
  white-space: normal;
  font-family: 'Nunito Sans';
  text-align: center;
  margin-top: 25px;
  font-size: 10px;
  font-weight: 500;
`;

const WorkoutEntryTable = (
  {
    workoutObject,
    index,
    setActiveIndex,
    isFinalIndex,
    outerIndex,
    sessionIndex,
  },
) => {
  const [valuesObject, setValuesObject] = useState({});
  const [buttonSetting, setButtonSetting] = useState(workoutObject.fullyCompleted);
  const [isSwitching, setIsSwitching] = useState(false);
  const [slideReady, setSlideReady] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  const dispatch = useDispatch();

  useEffect(() => {
    setButtonSetting(workoutObject.fullyCompleted);
  }, [workoutObject]);

  const initialValuesHeater = () => {
    const newObject = {};
    const newCompleteObject = {};
    const tableValues = workoutObject.tableData;
    tableValues.forEach((tableValue) => {
      newCompleteObject[tableValue.setId] = false;
      const mapValues = tableValue.values;
      if (!newObject[tableValue.setId]) {
        newObject[tableValue.setId] = {};
      }
      mapValues.forEach((mapValue) => {
        newObject[tableValue.setId][mapValue.valueName] = '';
        if (mapValue.value || mapValue.value === 0) {
          newObject[tableValue.setId][mapValue.valueName] = mapValue.value;
        }
      });
    });
    setValuesObject(newObject);
  };

  useEffect(() => {
    if (!isWorkoutsListLoading) {
      initialValuesHeater();
    }
  }, [isWorkoutsListLoading]);

  const boxWasher = (row, values, setValues, finalRow) => (
    <>
      <StandardBox>
        <SetNumberAndPercentage>
          {row.setId}
          {row.percentage ? (
            <Percentage>
              {`(${row.percentage}%)`}
            </Percentage>
          ) : null}
        </SetNumberAndPercentage>
      </StandardBox>
      {row?.values.map((rowItem, idx) => {
        const finalRowItem = row?.values.length - 1 === idx;
        return (
          <StandardBox>
            {rowItem.readOnly ? rowItem.value
              : (
                <StyledInput
                  id='input-field'
                  value={valuesObject[row.setId] && (valuesObject[row.setId][rowItem.valueName] || valuesObject[row.setId][rowItem.valueName] === 0) ? valuesObject[row.setId][rowItem.valueName] : ''}
                  onKeyUp={(e) => {
                    const tabKey = 9;
                    if (e.keyCode === tabKey) {
                      if (finalRow && finalRowItem) {
                        setSlideReady(true);
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    const tabKey = 9;
                    if (slideReady
                      && e.keyCode === tabKey
                      && finalRow
                      && finalRowItem
                      && !isFinalIndex) {
                      setActiveIndex(index + 1);
                      setSlideReady(false);
                    }
                  }}
                  onFocus={() => {
                    if (finalRow && finalRowItem) {
                      setSlideReady(true);
                    }
                  }}
                  onChange={(e) => {
                    let setValue = e.target.value;
                    if (typeof e.target.value === 'string') {
                      const parsedStringValue = parseInt(e.target.value, 10);
                      if (parsedStringValue <= 0) {
                        const positiveParsedStringValue = parsedStringValue * -1;
                        setValue = positiveParsedStringValue.toString();
                      }
                    }
                    if (typeof e.target.value === 'number') {
                      if (e.target.value <= 0) {
                        const positiveValue = e.target.value * -1;
                        setValue = positiveValue;
                      }
                    }
                    const newObject = { ...values };
                    newObject[row.setId][rowItem.valueName] = setValue;
                    setValues(newObject);
                  }}
                  type={rowItem?.valueType === 'STRING' ? 'text' : 'number'}
                  step={rowItem?.valueType === 'INTEGER' ? '1' : 'any'}
                  placeholder={!rowItem?.value && (rowItem?.placeholder || rowItem?.placeholder === 0) ? rowItem?.placeholder : ''}
                  onBlur={() => {
                    const submissionObject = { setId: row.setId, ...valuesObject[row.setId] };
                    const submissionObjectValues = Object.values(submissionObject);
                    let submissionCheck = true;
                    submissionObjectValues.forEach((value) => {
                      if (!value) {
                        submissionCheck = false;
                      }
                    });
                    const completeValuesObject = [];
                    Object.values(valuesObject).forEach((value, idx2) => {
                      const object = {
                        setId: idx2 + 1,
                        ...value,
                      };
                      completeValuesObject.push(object);
                    });
                    if (submissionCheck) {
                      if (workoutObject.assignedId) {
                        dispatch(
                          submitWorkoutItem(
                            currentUser,
                            sharedAthlete.id,
                            currentUser.accountCode,
                            activeWorkoutDate,
                            workoutObject.assignedId,
                            completeValuesObject,
                            index,
                            outerIndex,
                            sessionIndex,
                          ),
                        );
                      } else {
                        dispatch(
                          submitProgramWorkoutItem(
                            currentUser,
                            sharedAthlete.id,
                            currentUser.accountCode,
                            workoutObject.saveDataId,
                            completeValuesObject,
                            index,
                            outerIndex,
                            sessionIndex,
                            currentSelectedProgram.dayBasedProgramId,
                            currentNonDateDay.trueDayNum,
                          ),
                        );
                      }
                    }
                  }}
                />
              )}
          </StandardBox>
        );
      })}
    </>
  );

  const completeButton = (handleSubmit) => (
    <CompleteButton
      isWorkoutComplete={buttonSetting}
      onClick={() => {
        handleSubmit(valuesObject);
      }}
    >
      <InnerCompleteButton>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='checkmark'
          color={!buttonSetting ? 'black' : 'white'}
        />
        <CompleteButtonText
          isWorkoutComplete={buttonSetting}
        >
          Complete
        </CompleteButtonText>
      </InnerCompleteButton>
    </CompleteButton>
  );

  const handleSubmit = () => {
    setIsSwitching(true);
    setButtonSetting(!buttonSetting);
    if (workoutObject.assignedId) {
      dispatch(
        submitWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId,
          { complete: !workoutObject.fullyCompleted },
          index,
          outerIndex,
          sessionIndex,
        ),
      );
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          { complete: !workoutObject.fullyCompleted },
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          currentNonDateDay.trueDayNum,
        ),
      );
    }
  };

  /**
   * Iterates through the rows and fills in the values object at the correct
   * key with the placeholder when the placeholder exists and there's not currently
   * a value
   * sets the current values object to the new object and submits it
   */
  const savePlaceholderValues = () => {
    const submissionArray = [];
    const newObject = { ...valuesObject };
    workoutObject.tableData.forEach((setData) => {
      let isPushed = false; // Track if an entry has already been pushed
      setData.values.forEach((fieldData) => {
        if (!valuesObject[setData.setId][fieldData.valueName] && fieldData.placeholder) {
          newObject[setData.setId][fieldData.valueName] = fieldData.placeholder;
        }
      });
      const { setId } = setData;
      if (!isPushed) {
        submissionArray.push({ setId, ...newObject[setId] });
        isPushed = true; // Ensure only one push per setData
      }
    });
    setValuesObject(newObject);
    if (workoutObject.assignedId) {
      dispatch(
        submitWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId,
          submissionArray,
          index,
          outerIndex,
          sessionIndex,
        ),
      );
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          submissionArray,
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          currentNonDateDay.trueDayNum,
        ),
      );
    }
  };

  const renderSavePlaceholdersButton = () => (
    <SavePlaceholderButton>
      <SavePlaceholderValuesButton
        isWorkoutComplete={buttonSetting}
        onClick={() => {
          savePlaceholderValues();
        }}
      >
        <InnerCompleteButton>
          <CompleteButtonText
            isWorkoutComplete={buttonSetting}
            checkmark={false}
          >
            Confirm
          </CompleteButtonText>
        </InnerCompleteButton>
      </SavePlaceholderValuesButton>
      <SaveSuggestedText>
        Press &apos;Confirm&apos; to save all suggested values
      </SaveSuggestedText>
    </SavePlaceholderButton>
  );

  /**
   * Renders the button for a timer for rest in between sets
   * @param {Integer} timerLengthInSeconds
   */
  const renderSetRestTimer = (timerLengthInSeconds) => (
    <RestTimerButton
      isWorkoutComplete={buttonSetting}
      onClick={() => {
        batch(() => {
          dispatch(setTimerDuration(timerLengthInSeconds));
          dispatch(setTimerModalOpen(true));
        });
      }}
      disabled={isSwitching}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      style={{
        opacity: isFocused ? '0.75' : '1',
      }}
    >
      <InnerCompleteButton>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='clock'
          color='white'
        />
        <RestTimerButtonText>
          {`REST TIMER (${timerLengthInSeconds} SECS)`}
        </RestTimerButtonText>
      </InnerCompleteButton>
    </RestTimerButton>
  );

  return (
    <RepsBox width={workoutObject.tableHeaders.length + 1}>
      {workoutObject?.tableHeaders.length ? (
        <StandardRow>
          <SetNameBox>{workoutObject?.type === 'C' ? 'ROUND' : 'SET'}</SetNameBox>
          {workoutObject.tableHeaders.map(
            (header) => (
              <SetNameBox>
                <SetNameText>
                  {header?.valueDescription.toUpperCase()}
                </SetNameText>
              </SetNameBox>
            ),
          )}
        </StandardRow>
      ) : null}
      {workoutObject?.tableData.map((tableRow, idx) => {
        const finalRow = workoutObject?.tableData?.length - 1 === idx;
        return (
          <>
            <StandardRow>
              {Object.keys(valuesObject).length
                ? boxWasher(tableRow, valuesObject, setValuesObject, finalRow)
                : null}
            </StandardRow>
            {tableRow.restInSeconds && renderSetRestTimer(tableRow.restInSeconds)}
          </>
        );
      })}
      {workoutObject.unsavedSuggestedValuesRemaining
        && (!workoutObject.coachCompletionOnly
        || (workoutObject.coachCompletionOnly
        && currentUser.admin))
        ? renderSavePlaceholdersButton()
        : null}
      <ButtonContainer>
        {workoutObject.completionCheckbox
          ? completeButton(handleSubmit)
          : null}
      </ButtonContainer>
      {workoutObject?.coachCompletionOnly
        ? (
          <CoachCompletionOnlyText>
            A coach must complete this workout item
          </CoachCompletionOnlyText>
        )
        : null}
    </RepsBox>
  );
};

WorkoutEntryTable.defaultProps = {
  sessionIndex: null,
};

WorkoutEntryTable.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  isFinalIndex: PropTypes.bool.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default WorkoutEntryTable;
