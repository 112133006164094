import {
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_SUBSCRIBER_START,
  FETCH_SUBSCRIBER_FULFILLED,
  FETCH_SUBSCRIBER_REJECTED,
  FETCH_SUBSCRIBERS_START,
  FETCH_SUBSCRIBERS_FULFILLED,
  FETCH_SUBSCRIBERS_REJECTED,
  FETCH_CANCELLED_SUBSCRIBERS_START,
  FETCH_CANCELLED_SUBSCRIBERS_FULFILLED,
  FETCH_CANCELLED_SUBSCRIBERS_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  FETCH_RESELLER_START,
  FETCH_RESELLER_FULFILLED,
  FETCH_RESELLER_REJECTED,
  FETCH_ALL_CHARGES_START,
  FETCH_ALL_CHARGES_FULFILLED,
  FETCH_ALL_CHARGES_REJECTED,
  REFUND_CHARGE_START,
  REFUND_CHARGE_FULFILLED,
  REFUND_CHARGE_REJECTED,
  UPDATE_PAYMENT_START,
  UPDATE_PAYMENT_FULFILLED,
  UPDATE_PAYMENT_REJECTED,
  UPDATE_RESELLER_ACCOUNT_START,
  UPDATE_RESELLER_ACCOUNT_FULFILLED,
  UPDATE_RESELLER_ACCOUNT_REJECTED,
  FETCH_FIRST_WORKOUT_START,
  FETCH_FIRST_WORKOUT_FULFILLED,
  FETCH_FIRST_WORKOUT_REJECTED,
  CREATE_COUPON_START,
  CREATE_COUPON_REJECTED,
  CREATE_COUPON_FULFILLED,
  FETCH_COUPONS_START,
  FETCH_COUPONS_FULFILLED,
  FETCH_COUPONS_REJECTED,
  DELETE_COUPON_START,
  DELETE_COUPON_FULFILLED,
  DELETE_COUPON_REJECTED,
  UPDATE_COUPON_FULFILLED,
  UPDATE_COUPON_REJECTED,
  UPDATE_COUPON_START,
  STRIPE_ADMIN_LOGIN_START,
  STRIPE_ADMIN_LOGIN_FULFILLED,
  STRIPE_ADMIN_LOGIN_REJECTED,
  CREATE_PROGRAM_START,
  CREATE_PROGRAM_FULFILLED,
  CREATE_PROGRAM_REJECTED,
  UPDATE_PROGRAM_START,
  UPDATE_PROGRAM_FULFILLED,
  UPDATE_PROGRAM_REJECTED,
  ADD_SUBSCRIBER_TO_PROGRAM_START,
  ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED,
  ADD_SUBSCRIBER_TO_PROGRAM_REJECTED,
  CONNECT_STRIPE_START,
  CONNECT_STRIPE_FULFILLED,
  CONNECT_STRIPE_REJECTED,
} from './actionTypes';

const initialState = {
  isCouponsLoading: false,
  isProgramsLoading: false,
  isMediaUploading: false,
  isResellerLoading: false,
  isLoadingPaymentUpdate: false,
  isLoadingSettingsUpdate: false,
  isLoadingStripeAdmin: false,
  isPaymentUpdated: false,
  isProgramUpdating: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COUPONS_START: {
      return {
        ...state,
        isCouponsFetchLoading: true,
      };
    }
    case FETCH_COUPONS_FULFILLED: {
      return {
        ...state,
        isCouponsFetchLoading: false,
      };
    }
    case FETCH_COUPONS_REJECTED: {
      return {
        ...state,
        isCouponsFetchLoading: false,
      };
    }
    case CREATE_COUPON_START: {
      return {
        ...state,
        isCouponCreating: true,
      };
    }
    case CREATE_COUPON_FULFILLED: {
      return {
        ...state,
        isCouponCreating: false,
      };
    }
    case CREATE_COUPON_REJECTED: {
      return {
        ...state,
        isCouponCreating: false,
      };
    }
    case UPDATE_COUPON_START: {
      return {
        ...state,
        isCouponUpdating: true,
      };
    }
    case UPDATE_COUPON_FULFILLED: {
      return {
        ...state,
        isCouponUpdating: false,
      };
    }
    case UPDATE_COUPON_REJECTED: {
      return {
        ...state,
        isCouponUpdating: false,
      };
    }
    case DELETE_COUPON_START: {
      return {
        ...state,
        isCouponDeleting: true,
      };
    }
    case DELETE_COUPON_FULFILLED: {
      return {
        ...state,
        isCouponDeleting: false,
      };
    }
    case DELETE_COUPON_REJECTED: {
      return {
        ...state,
        isCouponDeleting: false,
      };
    }
    case FETCH_PROGRAMS_START: {
      return {
        ...state,
        isProgramsLoading: true,
      };
    }
    case FETCH_PROGRAMS_FULFILLED: {
      return {
        ...state,
        isProgramsLoading: false,
      };
    }
    case FETCH_PROGRAMS_REJECTED: {
      return {
        ...state,
        isProgramsLoading: false,
      };
    }
    case UPDATE_PROGRAM_START: {
      return {
        ...state,
        isProgramUpdating: true,
      };
    }
    case UPDATE_PROGRAM_FULFILLED: {
      return {
        ...state,
        isProgramUpdating: false,
      };
    }
    case UPDATE_PROGRAM_REJECTED: {
      return {
        ...state,
        isProgramUpdating: false,
      };
    }
    case FETCH_RESELLER_START: {
      return {
        ...state,
        isResellerLoading: true,
      };
    }
    case FETCH_RESELLER_FULFILLED: {
      return {
        ...state,
        isResellerLoading: false,
      };
    }
    case FETCH_RESELLER_REJECTED: {
      return {
        ...state,
        isResellerLoading: false,
      };
    }
    case FETCH_SUBSCRIBER_START: {
      return {
        ...state,
        isSubscriberLoading: true,
      };
    }
    case FETCH_SUBSCRIBER_FULFILLED: {
      return {
        ...state,
        isSubscriberLoading: false,
      };
    }
    case FETCH_SUBSCRIBER_REJECTED: {
      return {
        ...state,
        isSubscriberLoading: false,
      };
    }
    case FETCH_SUBSCRIBERS_START: {
      return {
        ...state,
        isSubscribersLoading: true,
      };
    }
    case FETCH_SUBSCRIBERS_FULFILLED: {
      return {
        ...state,
        isSubscribersLoading: false,
      };
    }
    case FETCH_SUBSCRIBERS_REJECTED: {
      return {
        ...state,
        isSubscribersLoading: false,
      };
    }
    case FETCH_CANCELLED_SUBSCRIBERS_START: {
      return {
        ...state,
        isSubscribersLoading: true,
      };
    }
    case FETCH_CANCELLED_SUBSCRIBERS_FULFILLED: {
      return {
        ...state,
        isSubscribersLoading: false,
      };
    }
    case FETCH_CANCELLED_SUBSCRIBERS_REJECTED: {
      return {
        ...state,
        isSubscribersLoading: false,
      };
    }
    case UPLOAD_MEDIA_START: {
      return {
        ...state,
        isMediaUploading: true,
      };
    }
    case UPLOAD_MEDIA_FULFILLED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case UPLOAD_MEDIA_REJECTED: {
      return {
        ...state,
        isMediaUploading: false,
      };
    }
    case FETCH_ALL_CHARGES_START: {
      return {
        ...state,
        isAllChargesLoading: true,
      };
    }
    case FETCH_ALL_CHARGES_FULFILLED: {
      return {
        ...state,
        isAllChargesLoading: false,
      };
    }
    case FETCH_ALL_CHARGES_REJECTED: {
      return {
        ...state,
        isAllChargesLoading: false,
      };
    }
    case REFUND_CHARGE_START: {
      return {
        ...state,
        isRefundChargeLoading: true,
      };
    }
    case REFUND_CHARGE_FULFILLED: {
      return {
        ...state,
        isRefundChargeLoading: false,
      };
    }
    case REFUND_CHARGE_REJECTED: {
      return {
        ...state,
        isRefundChargeLoading: false,
      };
    }
    case UPDATE_PAYMENT_START: {
      return {
        ...state,
        isLoadingPaymentUpdate: true,
        isPaymentUpdated: false,
      };
    }
    case UPDATE_PAYMENT_FULFILLED: {
      return {
        ...state,
        isLoadingPaymentUpdate: false,
        isPaymentUpdated: true,
      };
    }
    case UPDATE_PAYMENT_REJECTED: {
      return {
        ...state,
        isLoadingPaymentUpdate: false,
      };
    }
    case FETCH_FIRST_WORKOUT_START: {
      return {
        ...state,
        isFirstWorkoutFetching: true,
      };
    }
    case FETCH_FIRST_WORKOUT_FULFILLED: {
      return {
        ...state,
        isFirstWorkoutFetching: false,
      };
    }
    case FETCH_FIRST_WORKOUT_REJECTED: {
      return {
        ...state,
        isFirstWorkoutFetching: false,
      };
    }
    case UPDATE_RESELLER_ACCOUNT_START: {
      return {
        ...state,
        isLoadingSettingsUpdate: true,
      };
    }
    case UPDATE_RESELLER_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isLoadingSettingsUpdate: false,
      };
    }
    case UPDATE_RESELLER_ACCOUNT_REJECTED: {
      return {
        ...state,
        isLoadingSettingsUpdate: false,
      };
    }
    case STRIPE_ADMIN_LOGIN_START: {
      return {
        ...state,
        isLoadingStripeAdmin: true,
      };
    }
    case STRIPE_ADMIN_LOGIN_FULFILLED: {
      return {
        ...state,
        isLoadingStripeAdmin: false,
      };
    }
    case STRIPE_ADMIN_LOGIN_REJECTED: {
      return {
        ...state,
        isLoadingStripeAdmin: false,
      };
    }
    case CREATE_PROGRAM_START: {
      return {
        ...state,
        isProgramCreating: true,
      };
    }
    case CREATE_PROGRAM_FULFILLED: {
      return {
        ...state,
        isProgramCreating: false,
      };
    }
    case CREATE_PROGRAM_REJECTED: {
      return {
        ...state,
        isProgramCreating: false,
      };
    }
    case ADD_SUBSCRIBER_TO_PROGRAM_START: {
      return {
        ...state,
        isAddingSubscriberToProgram: true,
      };
    }
    case ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED: {
      return {
        ...state,
        isAddingSubscriberToProgram: false,
      };
    }
    case ADD_SUBSCRIBER_TO_PROGRAM_REJECTED: {
      return {
        ...state,
        isAddingSubscriberToProgram: false,
      };
    }
    case CONNECT_STRIPE_START: {
      return {
        ...state,
        isResellerLoading: true,
      };
    }
    case CONNECT_STRIPE_FULFILLED: {
      return {
        ...state,
        isResellerLoading: false,
      };
    }
    case CONNECT_STRIPE_REJECTED: {
      return {
        ...state,
        isResellerLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
