/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';
// import AlternativeDatePickerForToggle from './AlternativeDatePickerForToggle';
import AlternativeDatePicker from './AlternativeDatePicker';

import {
  fetchWorkoutCalendar,
  fetchSessionByDate,
  getWorkoutItemsForUserByDate,
  setActiveWorkoutDate,
  setProgramDayIndex,
  setWeekDayIndex,
  fetchNextWorkoutProgramWeek,
  fetchPreviousWorkoutProgramWeek,
  fetchPreviousWorkoutProgramWorkouts,
  fetchNextWorkoutProgramWorkouts,
  setIsProgramListBoxModalShowing,
  fetchWorkoutPrograms,
  setIsDayCarousel,
  setIsSmallScreenDayPickerOpen,
  fetchProgramSession,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const DayItemContainer = styled('div')`
	font-family: 'Nunito Sans';
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const DayItem = styled('div')`
	color: #444;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
`;

const MonthTextAndPicker = styled('div')`
	display: flex;
	align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 3px;
  margin-top: 15px;
`;

const MonthTextAndPickerNonDate = styled('div')`
	display: flex;
	align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const DayNumber = styled('div')`
	display: flex;
	justify-content: center;
  flex-direction: column;
  color: ${(props) => (props.customBackground && props.customBackground !== '#ffffff' ? 'white' : '#444')};
	align-items: center;
	border-radius: 50%;
	height: 40px;
	width: 40px;
  padding-top: 5px;
	border: ${(props) => (props.activeDay ? '0.5px solid black' : 'none')};
  background: ${(props) => (props.customBackground !== '#ffffff' ? props.customBackground : 'none')};
  transition: all 150ms linear;
	:hover {
		background: rgba(173, 202, 255, 0.5);
	}
  font-size: 15px;
`;

const WeekButtonLeft = styled('button')`
	border: none;
	border-radius: 50px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  position: absolute;
  bottom: 47.5px;
  left: -5px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const WeekButtonRight = styled('button')`
	border: none;
	border-radius: 50px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  position: absolute;
  bottom: 47.5px;
  right: -5px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const CarouselButtonWrapper = styled('div')`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: white;
  margin-top: 15px;
  border-radius: 24px;
  padding-bottom: 25px;
  box-shadow: ${BoxShadow};
  min-width: 325px;
`;

const DaysWrapper = styled('div')`
  display: flex;
  width: 95%;
  transition: opacity .1s linear;
  opacity: ${(props) => (!props.isChanging ? '1' : '.2')};
`;

const DatePickerDiv = styled('div')`
  height: 100%;
  width: 130px;
`;

const LeftDatePickerDiv = styled('div')`
  height: 100%;
  width: 20px;
`;

const MonthText = styled('div')`
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: 'Nunito Sans';
  font-weight: 300;
`;

const CarouselWrapper = styled('div')`
	display: flex;
  flex-direction: column;
  height: 110px;
  width: 95%;
  align-items: center;
	.carousel.slide {
		width: 100%;
	}
`;

const NonDateCarouselWrapper = styled('div')`
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NonDateDayElement = styled('div')`
  cursor: pointer;
`;

const CurrentSelectedProgramName = styled('div')`
  font-weight: 600;
  text-align: center;
  display: -webkit-box; 
  white-space: normal;
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 2; 
  overflow: hidden; 
  text-overflow: ellipsis;
  padding-left: 20px;
  padding-right: 20px;
`;

const SelectedProgramWeekAndDay = styled('div')`
  display: flex;
  justify-content: center;
  height: 24px;
`;

const RelativeContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled('div')`
  transform: rotate(90deg);
  cursor: pointer;
`;

const ViewProgramsMenu = styled('div')`
  position: absolute;
  width: 250px;
  background: white;
  box-shadow: ${BoxShadow};
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 30px;
  border-radius: 6px;
`;

const ViewProgramsMenuOption = styled('div')`
  width: 100%;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: ${(props) => (props.addBorderBottom ? '1px solid black' : 'none')};
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background: #f5f5f5;
  }
`;

const ViewProgramsMenuText = styled('div')`
  font-size: 14px;
  font-family: 'Nunito Sans';
  margin-left: 15px;
`;

const NonDateColumn = styled('div')`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

const Underlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`;

const WeekCarousel = () => {
  const [focusWeek, setFocusWeek] = useState([]);
  const [workoutCalendarDictionary, setWorkoutCalendarDictionary] = useState({});
  const [activeMonth, setActiveMonth] = useState('October');
  const [isChanging, setIsChanging] = useState(false);
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);
  // const [isSmallScreenDayPickerOpen, setIsSmallScreenDayPickerOpen] = useState(false);

  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);
  const workoutCalendar = useSelector(
    (state) => state.workouts.data.workoutCalendar,
  );
  const isSmallScreenDayPickerOpen = useSelector(
    (state) => state.workouts.data.isSmallScreenDayPickerOpen,
  );
  const workoutProgramWeek = useSelector(
    (state) => state.workouts.data.workoutProgramWeek,
  );
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );
  const weekDayIndex = useSelector(
    (state) => state.workouts.data.weekDayIndex,
  );
  const workoutProgramWeeks = useSelector(
    (state) => state.workouts.data.workoutProgramWeeks,
  );
  const workoutProgramWeekIndex = useSelector(
    (state) => state.workouts.data.workoutProgramWeekIndex,
  );
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  // Determines user id to fetch the calendar for - depending if the current user is admin or not
  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  const hiddenWorkoutsToggle = useSelector((state) => state.workouts.ui.hiddenWorkoutsToggle);

  const dispatch = useDispatch();

  const programSelectTriggerFunction = () => {
    setIsDropdownShowing(false);
    dispatch(setIsProgramListBoxModalShowing(true));
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          currentUser.id,
          formattedDay,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          sharedAthlete.id,
          formattedDay,
        ),
      );
    }
  };

  const fetchSessionByClick = (day) => {
    if (Object.keys(currentUser).length) {
      if ((currentUser.admin && Object.keys(sharedAthlete).length) || !currentUser.admin) {
        dispatch(fetchSessionByDate(currentUser, userId, day, {}));
      }
    }
  };

  const workoutSelectTriggerFunction = () => {
    setIsDropdownShowing(false);
    dispatch(setIsDayCarousel(false));
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    fetchSessionByClick(formattedDay);
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      batch(() => {
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            currentUser.id,
            formattedDay,
            false,
          ),
        );
      });
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          sharedAthlete.id,
          formattedDay,
          true,
        ),
      );
    }
  };

  useEffect(() => {
    const middleDay = focusWeek[3];
    if (middleDay) {
      setActiveMonth(middleDay.format('MMMM YYYY'));
    }
  }, [focusWeek]);

  useEffect(() => {
    if (!activeWorkoutDate) {
      const formattedDay = moment().format('YYYY-MM-DD');
      dispatch(setActiveWorkoutDate(formattedDay));
    }
  }, []);

  useEffect(() => {
    if (workoutCalendar.length) {
      const newDictionary = { ...workoutCalendarDictionary };
      workoutCalendar.forEach((calendarItem) => {
        newDictionary[calendarItem.date] = calendarItem;
      });
      setWorkoutCalendarDictionary(newDictionary);
    }
  }, [workoutCalendar]);

  const fetch4WeeksAroundWeek = (day) => {
    let startDate;
    let endDate;
    if (day) {
      startDate = moment(day).subtract(15, 'days').format('YYYY-MM-DD');
      endDate = moment(day).add(15, 'days').format('YYYY-MM-DD');
      if (Object.keys(currentUser).length) {
        const isSharedAthlete = currentUser.admin && Object.keys(sharedAthlete).length;
        if (!currentUser.admin || isSharedAthlete) {
          dispatch(
            fetchWorkoutCalendar(currentUser, userId, startDate, endDate, hiddenWorkoutsToggle),
          );
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(currentUser).length && !currentUser.admin) {
      const formattedDay = moment().format('YYYY-MM-DD');
      const startDate = (`${moment(formattedDay).subtract(15, 'days').format('YYYY-MM-DD')}`);
      const endDate = (`${moment(formattedDay).add(15, 'days').format('YYYY-MM-DD')}`);
      if (Object.keys(currentUser).length) {
        dispatch(
          fetchWorkoutCalendar(
            currentUser, currentUser.id, startDate, endDate, hiddenWorkoutsToggle,
          ),
        );
      }
    }
  }, [currentUser]);

  const fetchWorkoutOnClick = (newActiveDay) => {
    if (Object.keys(currentUser).length && !currentUser.admin) {
      batch(() => {
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            currentUser.id,
            newActiveDay,
            false,
          ),
        );
      });
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        getWorkoutItemsForUserByDate(
          currentUser,
          sharedAthlete.id,
          newActiveDay,
          false,
          hiddenWorkoutsToggle,
        ),
      );
    }
  };

  useEffect(() => {
    setIsChanging(true);
    const dayMoment = moment(activeWorkoutDate);
    const newFocusWeek = [];
    const newFirst = dayMoment.subtract(3, 'day');
    for (let i = 0; i < 7; i += 1) {
      newFocusWeek.push(moment(newFirst).add(i, 'day'));
    }
    setFocusWeek(newFocusWeek);
  }, [activeWorkoutDate]);

  useEffect(() => {
    setIsChanging(true);
    const dayMoment = moment(activeWorkoutDate.date);
    const newFocusWeek = [];
    const newFirst = dayMoment.subtract(3, 'day');
    for (let i = 0; i < 7; i += 1) {
      newFocusWeek.push(moment(newFirst).add(i, 'day'));
    }
    setFocusWeek(newFocusWeek);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsChanging(false);
    }, 100);
  }, [isChanging]);

  const reconfoogleFocusWeek = (dayInfo) => {
    setIsChanging(true);
    const dayMoment = moment(dayInfo.date);
    const newFocusWeek = [];
    const newFirst = dayMoment.subtract(3, 'day');
    for (let i = 0; i < 7; i += 1) {
      newFocusWeek.push(moment(newFirst).add(i, 'day'));
    }
    setFocusWeek(newFocusWeek);
  };

  const calendarScooper = (scoopDay) => {
    dispatch(setActiveWorkoutDate(scoopDay));
    reconfoogleFocusWeek({ date: scoopDay });
    fetchSessionByClick(scoopDay);
    fetchWorkoutOnClick(scoopDay);
    fetch4WeeksAroundWeek(scoopDay);
  };

  const dayBasher = (weekArray) => {
    const dayArray = [];
    for (let i = 0; i < weekArray.length; i += 1) {
      const formattedDay = weekArray[i].format('YYYY-MM-DD');
      const dayInfo = workoutCalendarDictionary[formattedDay];
      dayArray.push(
        <DayItemContainer key={generateUniqueId()}>
          <DayItem>
            <>
              {weekArray[i].format('dddd').substring(0, 3)}
            </>
            <DayNumber
              customBackground={dayInfo?.backgroundColorCode}
              activeDay={activeWorkoutDate === formattedDay}
              onClick={() => {
                if (activeWorkoutDate !== formattedDay) {
                  calendarScooper(formattedDay);
                }
              }}
            >
              {weekArray[i].date()}
              <div style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                background: dayInfo?.statusColorCode ? dayInfo.statusColorCode : null,
              }}
              />
            </DayNumber>
          </DayItem>
        </DayItemContainer>,
      );
    }
    return dayArray;
  };

  const programWeekMapper = (dayParameter) => {
    const workoutProgramElement = (
      <NonDateDayElement onClick={() => programSelectTriggerFunction()} key={generateUniqueId()}>
        <CurrentSelectedProgramName>
          {currentSelectedProgram.programName}
        </CurrentSelectedProgramName>
        <SelectedProgramWeekAndDay>
          {dayParameter.dayTitle ? `${dayParameter.weekTitle} - ${dayParameter.dayTitle}` : null}
        </SelectedProgramWeekAndDay>
      </NonDateDayElement>
    );
    return workoutProgramElement;
  };

  const addWeekBackwards = () => {
    setIsChanging(true);
    if (!isDayCarousel) {
      const newStartDay = moment(focusWeek[0]).subtract(1, 'week');
      const newFocusWeek = [];
      for (let i = 0; i < 7; i += 1) {
        newFocusWeek.push(moment(newStartDay).add(i, 'day'));
      }
      setFocusWeek(newFocusWeek);
      fetch4WeeksAroundWeek(newFocusWeek[3].format('YYYY-MM-DD'));
    } else if (weekDayIndex > 0 && !isWorkoutsListLoading) {
      const newCurrentDay = workoutProgramWeek[weekDayIndex - 1];
      const newIndex = newCurrentDay.trueDayNum;
      dispatch(setProgramDayIndex(newIndex));
      dispatch(setWeekDayIndex(weekDayIndex - 1));
      dispatch(fetchPreviousWorkoutProgramWorkouts(
        currentUser,
        currentSelectedProgram,
        newIndex,
        sharedAthlete.id,
        newCurrentDay,
      ));
      dispatch(fetchProgramSession(
        currentUser,
        userId,
        currentSelectedProgram.dayBasedProgramId,
        newIndex,
      ));
    } else if (weekDayIndex === 0 && workoutProgramWeekIndex !== 0 && !isWorkoutsListLoading) {
      let newWorkoutProgramWeekIndex = workoutProgramWeekIndex - 1;
      let workoutProgramWeekUnderFocus = workoutProgramWeeks[newWorkoutProgramWeekIndex];
      while (newWorkoutProgramWeekIndex < workoutProgramWeeks.length
        && workoutProgramWeekUnderFocus.numberOfActiveDays === 0) {
        newWorkoutProgramWeekIndex -= 1;
        workoutProgramWeekUnderFocus = [workoutProgramWeeks[newWorkoutProgramWeekIndex]];
      }
      dispatch(fetchPreviousWorkoutProgramWeek(
        currentUser,
        currentSelectedProgram,
        newWorkoutProgramWeekIndex,
        sharedAthlete.id,
      ));
    }
  };

  const addWeekForwards = () => {
    setIsChanging(true);
    if (!isDayCarousel) {
      const newStartDay = moment(focusWeek[0]).add(1, 'week');
      const newFocusWeek = [];
      for (let i = 0; i < 7; i += 1) {
        newFocusWeek.push(moment(newStartDay).add(i, 'day'));
      }
      setFocusWeek(newFocusWeek);
      fetch4WeeksAroundWeek(newFocusWeek[3].format('YYYY-MM-DD'));
    } else if (weekDayIndex < workoutProgramWeek.length - 1 && !isWorkoutsListLoading) {
      const newCurrentDay = workoutProgramWeek[weekDayIndex + 1];
      const newIndex = newCurrentDay.trueDayNum;
      if (newCurrentDay) {
        dispatch(setProgramDayIndex(newIndex));
        dispatch(setWeekDayIndex(weekDayIndex + 1));
        dispatch(fetchNextWorkoutProgramWorkouts(
          currentUser,
          currentSelectedProgram,
          newIndex,
          sharedAthlete.id,
          newCurrentDay,
        ));
        dispatch(fetchProgramSession(
          currentUser,
          userId,
          currentSelectedProgram.dayBasedProgramId,
          newIndex,
        ));
      }
    } else if (workoutProgramWeeks[workoutProgramWeekIndex + 1] && !isWorkoutsListLoading) {
      let newWorkoutProgramWeekIndex = workoutProgramWeekIndex + 1;
      let workoutProgramWeekUnderFocus = workoutProgramWeeks[newWorkoutProgramWeekIndex];
      while (newWorkoutProgramWeekIndex < workoutProgramWeeks.length
        && workoutProgramWeekUnderFocus.numberOfActiveDays === 0) {
        newWorkoutProgramWeekIndex += 1;
        workoutProgramWeekUnderFocus = [workoutProgramWeeks[newWorkoutProgramWeekIndex]];
      }
      dispatch(fetchNextWorkoutProgramWeek(
        currentUser,
        currentSelectedProgram,
        newWorkoutProgramWeekIndex,
        sharedAthlete.id,
      ));
    }
  };

  const setActiveDate = (date) => {
    const newDate = moment(date).format('YYYY-MM-DD');
    if (newDate) {
      dispatch(setActiveWorkoutDate(newDate));
      if (Object.keys(currentUser).length) {
        if ((currentUser.admin && Object.keys(sharedAthlete).length) || !currentUser.admin) {
          if (currentUser.admin) {
            dispatch(fetchSessionByDate(currentUser, userId, newDate, {}));
          } else {
            dispatch(fetchSessionByDate(currentUser, userId, newDate, {}));
          }
        }
      }
      if (Object.keys(currentUser).length && !currentUser.admin) {
        // fetch if the user isn't an admin and currentUser exists
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            currentUser.id,
            newDate,
            false,
          ),
        );
      } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
        // Fetch if the current user is an coach, but only if an athlete is selected
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            sharedAthlete.id,
            newDate,
            false,
            hiddenWorkoutsToggle,
          ),
        );
      }
    }
  };

  return (
    <>
      {isDropdownShowing || isSmallScreenDayPickerOpen
        ? (
          <Underlay onClick={() => {
            dispatch(setIsSmallScreenDayPickerOpen(false));
            setIsDropdownShowing(false);
          }}
          />
        )
        : null}
      <CarouselButtonWrapper>
        <WeekButtonLeft
          onClick={addWeekBackwards}
          type='button'
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='left-arrow'
            color='#444'
          />
        </WeekButtonLeft>
        {!isDayCarousel ? (
          <CarouselWrapper>
            <MonthTextAndPickerNonDate>
              <DatePickerDiv />
              <MonthText>{activeMonth}</MonthText>
              <DatePickerDiv>
                <RelativeContainer>
                  <AlternativeDatePicker
                    activeDate={activeWorkoutDate}
                    selectDate={setActiveDate}
                    isDayPickerOpen={isSmallScreenDayPickerOpen}
                    setIsDayPickerOpen={setIsSmallScreenDayPickerOpen}
                    left={75}
                    isSmallScreenOption
                  />
                  <IconContainer
                    onClick={() => setIsDropdownShowing(!isDropdownShowing)}
                  >
                    <IcomoonReact
                      iconSet={iconSet}
                      size={20}
                      icon='dots'
                      color='black'
                    />
                  </IconContainer>
                  {isDropdownShowing ? (
                    <ViewProgramsMenu>
                      <ViewProgramsMenuOption onClick={() => programSelectTriggerFunction()}>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={20}
                          icon='folder'
                          color='black'
                        />
                        <ViewProgramsMenuText>
                          View Program Library
                        </ViewProgramsMenuText>
                      </ViewProgramsMenuOption>
                    </ViewProgramsMenu>
                  ) : null}
                </RelativeContainer>
              </DatePickerDiv>
            </MonthTextAndPickerNonDate>
            <DaysWrapper isChanging={isChanging}>
              {dayBasher(focusWeek)}
            </DaysWrapper>
          </CarouselWrapper>
        ) : (
          <NonDateColumn>
            <MonthTextAndPicker>
              <LeftDatePickerDiv />
              <MonthText>Program Library</MonthText>
              <RelativeContainer>
                <IconContainer
                  onClick={() => setIsDropdownShowing(!isDropdownShowing)}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='dots'
                    color='black'
                  />
                </IconContainer>
                {isDropdownShowing ? (
                  <ViewProgramsMenu>
                    <ViewProgramsMenuOption
                      onClick={() => programSelectTriggerFunction()}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={20}
                        icon='folder'
                        color='black'
                      />
                      <ViewProgramsMenuText>
                        View Programs
                      </ViewProgramsMenuText>
                    </ViewProgramsMenuOption>
                    <ViewProgramsMenuOption
                      onClick={() => workoutSelectTriggerFunction()}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={20}
                        icon='calendar'
                        color='black'
                      />
                      <ViewProgramsMenuText>
                        View Scheduled Workouts
                      </ViewProgramsMenuText>
                    </ViewProgramsMenuOption>
                  </ViewProgramsMenu>
                ) : null}
              </RelativeContainer>
            </MonthTextAndPicker>
            <NonDateCarouselWrapper>
              {programWeekMapper(currentNonDateDay)}
            </NonDateCarouselWrapper>
          </NonDateColumn>
        )}
        <WeekButtonRight
          onClick={addWeekForwards}
          type='button'
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='right-arrow'
            color='#444'
          />
        </WeekButtonRight>
      </CarouselButtonWrapper>
    </>
  );
};

WeekCarousel.propTypes = { };

export default WeekCarousel;
