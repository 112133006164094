export const FETCH_CONVERSATIONS_START = 'FETCH_CONVERSATIONS_START';
export const FETCH_CONVERSATIONS_FULFILLED = 'FETCH_CONVERSATIONS_FULFILLED';
export const FETCH_CONVERSATIONS_REJECTED = 'FETCH_CONVERSATIONS_REJECTED';

export const FETCH_CONVERSATION_MESSAGES_START = 'FETCH_CONVERSATION_MESSAGES_START';
export const FETCH_CONVERSATION_MESSAGES_FULFILLED = 'FETCH_CONVERSATION_MESSAGES_FULFILLED';
export const FETCH_CONVERSATION_MESSAGES_REJECTED = 'FETCH_CONVERSATION_MESSAGES_REJECTED';

export const FETCH_SCHEDULED_MESSAGES_START = 'FETCH_SCHEDULED_MESSAGES_START';
export const FETCH_SCHEDULED_MESSAGES_FULFILLED = 'FETCH_SCHEDULED_MESSAGES_FULFILLED';
export const FETCH_SCHEDULED_MESSAGES_REJECTED = 'FETCH_SCHEDULED_MESSAGES_REJECTED';

export const FETCH_MORE_MESSAGES_START = 'FETCH_MORE_MESSAGES_START';
export const FETCH_MORE_MESSAGES_FULFILLED = 'FETCH_MORE_MESSAGES_FULFILLED';
export const FETCH_MORE_MESSAGES_REJECTED = 'FETCH_MORE_MESSAGES_REJECTED';
export const FETCH_MORE_MESSAGES_EMPTY = 'FETCH_MORE_MESSAGES_EMPTY';

export const UNSCHEDULE_MESSAGE_START = 'UNSCHEDULE_MESSAGE_START';
export const UNSCHEDULE_MESSAGE_FULFILLED = 'UNSCHEDULE_MESSAGE_FULFILLED';
export const UNSCHEDULE_MESSAGE_REJECTED = 'UNSCHEDULE_MESSAGE_REJECTED';

export const UPDATE_MESSAGE_START = 'UPDATE_MESSAGE_START';
export const UPDATE_MESSAGE_FULFILLED = 'UPDATE_MESSAGE_FULFILLED';
export const UPDATE_MESSAGE_REJECTED = 'UPDATE_MESSAGE_REJECTED';

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const FETCH_CALENDARS_START = 'FETCH_CALENDARS_START';
export const FETCH_CALENDARS_FULFILLED = 'FETCH_CALENDARS_FULFILLED';
export const FETCH_CALENDARS_REJECTED = 'FETCH_CALENDARS_REJECTED';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';

export const FETCH_CONVERSATION_USERS_START = 'FETCH_CONVERSATION_USERS_START';
export const FETCH_CONVERSATION_USERS_FULFILLED = 'FETCH_CONVERSATION_USERS_FULFILLED';
export const FETCH_CONVERSATION_USERS_REJECTED = 'FETCH_CONVERSATION_USERS_REJECTED';

export const UPLOAD_MEDIA_START = 'UPLOAD_MEDIA_START';
export const UPLOAD_MEDIA_FULFILLED = 'UPLOAD_MEDIA_FULFILLED';
export const UPLOAD_MEDIA_REJECTED = 'UPLOAD_MEDIA_REJECTED';

export const UPLOAD_MEDIA_URL_START = 'UPLOAD_MEDIA_URL_START';
export const UPLOAD_MEDIA_URL_FULFILLED = 'UPLOAD_MEDIA_URL_FULFILLED';
export const UPLOAD_MEDIA_URL_REJECTED = 'UPLOAD_MEDIA_URL_REJECTED';

export const FETCH_UNREAD_NOTIFICATIONS_START = 'FETCH_UNREAD_NOTIFICATIONS_START';
export const FETCH_UNREAD_NOTIFICATIONS_FULFILLED = 'FETCH_UNREAD_NOTIFICATIONS_FULFILLED';
export const FETCH_UNREAD_NOTIFICATIONS_REJECTED = 'FETCH_UNREAD_NOTIFICATIONS_REJECTED';

export const SET_ACTIVE_REDUX_MODAL = 'SET_ACTIVE_REDUX_MODAL';

export const SET_SUPPORT_MODAL = 'SET_SUPPORT_MODAL';

export const SET_ACTIVE_CONVERSATION = 'SET_ACTIVE_CONVERSATION';
export const CLEAR_ACTIVE_CONVERSATION = 'CLEAR_ACTIVE_CONVERSATION';

export const CREATE_NEW_MESSAGE_START = 'CREATE_NEW_MESSAGE_START';
export const CREATE_NEW_MESSAGE_FULFILLED = 'CREATE_NEW_MESSAGE_FULFILLED';
export const CREATE_NEW_MESSAGE_REJECTED = 'CREATE_NEW_MESSAGE_REJECTED';

export const CREATE_NEW_SCHEDULED_MESSAGE = 'CREATE_NEW_SCHEDULED_MESSAGE';

export const SET_INITIAL_MESSAGES = 'SET_INITIAL_MESSAGES';

export const SET_IS_MEDIA_UPLOADED = 'SET_IS_MEDIA_UPLOADED';

export const SET_IS_INITIAL_MESSAGES = 'SET_IS_INITIAL_MESSAGES';

export const SET_SCHEDULED_MESSAGE_REDUX = 'SET_SCHEDULED_MESSAGE_REDUX';

export const SET_SELECTED_CONVERSATIONS_REDUX = 'SET_SELECTED_CONVERSATIONS_REDUX';

export const SET_IS_TYPING_TRUE = 'SET_IS_TYPING_TRUE';
export const SET_IS_TYPING_FALSE = 'SET_IS_TYPING_FALSE';

export const CREATE_CONVERSATION_START = 'CREATE_CONVERSATION_START';
export const CREATE_CONVERSATION_FULFILLED = 'CREATE_CONVERSATION_FULFILLED';
export const CREATE_CONVERSATION_REJECTED = 'CREATE_CONVERSATION_REJECTED';

export const SET_INCOMING_ACTIVE_MESSAGE = 'SET_INCOMING_ACTIVE_MESSAGE';
export const SET_INCOMING_INACTIVE_MESSAGE = 'SET_INCOMING_INACTIVE_MESSAGE';
export const SET_INCOMING_NEW_INACTIVE_MESSAGE = 'SET_INCOMING_NEW_INACTIVE_MESSAGE';

export const MARK_CONVERSATIONS_AS_READ_START = 'MARK_CONVERSATIONS_AS_READ_START';
export const MARK_CONVERSATIONS_AS_READ_FULFILLED = 'MARK_CONVERSATIONS_AS_READ_FULFILLED';
export const MARK_CONVERSATIONS_AS_READ_REJECTED = 'MARK_CONVERSATIONS_AS_READ_REJECTED';

export const STORE_CONVERSATION_MESSAGES_BY_DAY = 'STORE_CONVERSATION_MESSAGES_BY_DAY';

export const DELETE_CONVERSATIONS_START = 'DELETE_CONVERSATIONS_START';
export const DELETE_CONVERSATIONS_FULFILLED = 'DELETE_CONVERSATIONS_FULFILLED';
export const DELETE_CONVERSATIONS_REJECTED = 'DELETE_CONVERSATIONS_REJECTED';

export const MUTE_NOTIFICATIONS_START = 'MUTE_NOTIFICATIONS_START';
export const MUTE_NOTIFICATIONS_FULFILLED = 'MUTE_NOTIFICATIONS_FULFILLED';
export const MUTE_NOTIFICATIONS_REJECTED = 'MUTE_NOTIFICATIONS_REJECTED';

export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_REJECTED = 'FETCH_NOTIFICATIONS_REJECTED';
export const FETCH_NOTIFICATIONS_FULFILLED = 'FETCH_NOTIFICATIONS_FULFILLED';

export const FETCH_NOTIFICATIONS_NEXT_PAGE_START = 'FETCH_NOTIFICATIONS_NEXT_PAGE_START';
export const FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED = 'FETCH_NOTIFICATIONS_NEXT_PAGE_FULFILLED';

export const FETCH_CONVERSATIONS_NEXT_PAGE_START = 'FETCH_CONVERSATIONS_NEXT_PAGE_START';
export const FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED = 'FETCH_CONVERSATIONS_NEXT_PAGE_FULFILLED';

export const SET_ACTIVE_NOTIFICATION = 'SET_ACTIVE_NOTIFICATION';
export const SET_NOTIFICATION_FILTER = 'SET_NOTIFICATION_FILTER';
export const CLEAR_NOTIFICATION_FILTER = 'CLEAR_NOTIFICATION_FILTER';

export const FETCH_NOTIFICATION_ASSET_START = 'FETCH_NOTIFICATION_ASSET_START';
export const FETCH_NOTIFICATION_ASSET_REJECTED = 'FETCH_NOTIFICATION_ASSET_REJECTED';
export const FETCH_NOTIFICATION_ASSET_FULFILLED = 'FETCH_NOTIFICATION_ASSET_FULFILLED';
export const SET_SELECTED_NOTIFICATIONS = 'SET_SELECTED_NOTIFICATIONS';

export const MARK_NOTIFICATION_AS_READ_START = 'MARK_NOTIFICATION_AS_READ_START';
export const MARK_NOTIFICATION_AS_READ_REJECTED = 'MARK_NOTIFICATION_AS_READ_REJECTED';
export const MARK_NOTIFICATION_AS_READ_FULFILLED = 'MARK_NOTIFICATION_AS_READ_FULFILLED';

export const MARK_NOTIFICATION_AS_DELETED_START = 'MARK_NOTIFICATION_AS_DELETED_START';
export const MARK_NOTIFICATION_AS_DELETED_REJECTED = 'MARK_NOTIFICATION_AS_DELETED_REJECTED';
export const MARK_NOTIFICATION_AS_DELETED_FULFILLED = 'MARK_NOTIFICATION_AS_DELETED_FULFILLED';
export const SET_JOURNAL_MEDIA_URL = 'SET_JOURNAL_MEDIA_URL';
export const SET_MESSAGE_MEDIA_URL = 'SET_MESSAGE_MEDIA_URL';

export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_REJECTED = 'UPDATE_NOTIFICATION_REJECTED';
export const UPDATE_NOTIFICATION_FULFILLED = 'UPDATE_NOTIFICATION_FULFILLED';

export const CREATE_INDIVIDUAL_CONVERSATIONS_START = 'CREATE_INDIVIDUAL_CONVERSATIONS_START';
export const CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED = 'CREATE_INDIVIDUAL_CONVERSATIONS_REJECTED';
export const CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED = 'CREATE_INDIVIDUAL_CONVERSATIONS_FULFILLED';

export const CREATE_SILENT_CONVERSATION_START = 'CREATE_SILENT_CONVERSATION_START';
export const CREATE_SILENT_CONVERSATION_REJECTED = 'CREATE_SILENT_CONVERSATION_REJECTED';
export const CREATE_SILENT_CONVERSATION_FULFILLED = 'CREATE_SILENT_CONVERSATION_FULFILLED';

export const SEND_EXTERNAL_MESSAGE_ONLY_START = 'SEND_EXTERNAL_MESSAGE_ONLY_START';
export const SEND_EXTERNAL_MESSAGE_ONLY_REJECTED = 'SEND_EXTERNAL_MESSAGE_ONLY_REJECTED';
export const SEND_EXTERNAL_MESSAGE_ONLY_FULFILLED = 'SEND_EXTERNAL_MESSAGE_ONLY_FULFILLED';

export const SET_IS_SCHEDULED_OPEN = 'SET_IS_SCHEDULED_OPEN';

export const SET_IS_FILTERS_CHANGING = 'SET_IS_FILTERS_CHANGING';

export const SUBMIT_SUPPORT_START = 'SUBMIT_SUPPORT_START';
export const SUBMIT_SUPPORT_FULFILLED = 'SUBMIT_SUPPORT_FULFILLED';
export const SUBMIT_SUPPORT_REJECTED = 'SUBMIT_SUPPORT_REJECTED';

export const SET_LOCAL_NOTIFICATION_FILTERS = 'SET_LOCAL_NOTIFICATION_FILTERS';
export const SET_ACTIVE_NOTIFICATION_FILTERS = 'SET_ACTIVE_NOTIFICATION_FILTERS';
export const CLEAR_ACTIVE_NOTIFICATION_FILTERS = 'CLEAR_ACTIVE_NOTIFICATION_FILTERS';
export const SET_NOTIFICATION_FILTER_COUNT = 'SET_NOTIFICATION_FILTER_COUNT';
export const CLEAR_NOTIFICATION_FILTER_COUNT = 'CLEAR_NOTIFICATION_FILTER_COUNT';

export const SET_IS_FILTER_DROPDOWN_SHOWING = 'SET_IS_FILTER_DROPDOWN_SHOWING';

export const FETCH_NOTIFICATION_TYPES_START = 'FETCH_NOTIFICATION_TYPES_START';
export const FETCH_NOTIFICATION_TYPES_FULFILLED = 'FETCH_NOTIFICATION_TYPES_FULFILLED';
export const FETCH_NOTIFICATION_TYPES_REJECTED = 'FETCH_NOTIFICATION_TYPES_REJECTED';

export const FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START = 'FETCH_NOTIFICATIONS_BY_FILTER_TYPE_START';
export const FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED = 'FETCH_NOTIFICATIONS_BY_FILTER_TYPE_FULFILLED';
export const FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED = 'FETCH_NOTIFICATIONS_BY_FILTER_TYPE_REJECTED';

export const SWITCH_NOTIFICATION_TYPE_SETTING_START = 'SWITCH_NOTIFICATION_TYPE_SETTING_START';
export const SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED = 'SWITCH_NOTIFICATION_TYPE_SETTING_FULFILLED';
export const SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED = 'SWITCH_NOTIFICATION_TYPE_SETTING_REJECTED';

export const UPDATE_MOBILE_SIDEBAR = 'UPDATE_MOBILE_SIDEBAR';
