/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import TableRow from './EvaluationTableRow';

const arrayMoveMutate = (array, from, to) => {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
};

const arrayMove = (array, from, to) => {
  const newArray = array.slice();
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

const EvaluationTableWrapper = styled('div')`
  .table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 10px;
    overflow: hidden;
  }
  table, th, td {
    border: none;
  }
  .table thead th {
    border: none;
    vertical-align: middle;
  }
  @media (max-width: 768px) {
    overflow: auto;
  }
`;

const ColumnContainer = styled('div')`
  height: 63px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Nunito Sans';
`;

const firstWidth = 2;
const secondWidth = 28;
const thirdWidth = 19;
const fourthWidth = 33;
const fifthWidth = 9;
const sixthWidth = 9;

const FirstColumn = styled('div')`
  background: #eeeeee;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
  width: ${firstWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const SecondColumn = styled('div')`
  background: #eeeeee;
  width: ${secondWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width:320px;
    }
`;

const ThirdColumn = styled('div')`
  background: #eeeeee;
  width: ${thirdWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width:150px;
    }
`;

const FourthColumn = styled('div')`
  background: #eeeeee;
  width: ${fourthWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width:150px;
    }
`;

const FifthColumn = styled('div')`
  background: #eeeeee;
  width: ${fifthWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width:70px;
    }
`;

const ToolTip = styled('span')`
    visibility: hidden;
    width: 245px;
    white-space: break-spaces;
    background-color: #FFFFFF;
    color: #444444;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
    padding: 5px;
    position: absolute;
    bottom: 70%;
    right: 10%;
    
    ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 83%;
      border-width: 5px;
      border-style: solid;
      border-color: #FFFFFF transparent transparent transparent;
    }
`;

const SixthColumn = styled('div')`
  background: #eeeeee;
  border-bottom-right-radius: 16px;
  border-top-right-radius: 16px;
  width: ${sixthWidth}%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
  position: relative;
  :hover > ${ToolTip} {
    visibility: visible;
  }
  @media only screen and (max-width : 768px) {
    padding-right: 50px;
    width:unset;
    }
`;

const NewRowButtonContainer = styled('div')`
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;

const NewCustomRowButton = styled('div')`
width: 138px;
height: 34px;
border: 1px solid #77A7FF;
color: #77A7FF;
border-radius: 20px;
display: flex;
justify-content: center;
align-items: center;
font-size: 12px;
font-family: 'Nunito Sans';
font-weight: 700;
transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const NewTrackedRowButton = styled('div')`
  width: 243px;
  height: 34px;
  background: #77A7FF;
  color: white;
  border: 1px solid #77A7FF;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const SortableContainerDiv = styled('div')`
  max-height: 200px;
  overflow-y: auto;
  scrollbar-color: lightgrey transparent;
  scrollbar-width: thin;

  .scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  .scrollbar::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
    position: absolute;
    right: -20px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0,0,0,.3);
    background-color: grey;
  }
  @media (max-width: 768px) {
  width: fit-content;
}
`;

const SortableCont = SortableContainer(({ children }) => (
  <SortableContainerDiv>
    {children}
  </SortableContainerDiv>
));

const SortableItem = SortableElement((props) => <TableRow key={props.key} {...props} />);

const EvaluationTable = ({
  trackedExercises,
  units,
  items,
  setItems,
}) => {
  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((oldItems) => arrayMove(oldItems, oldIndex, newIndex));
  }, []);

  const endOfListRef = useRef(null);

  return (
    <EvaluationTableWrapper>
      <ColumnContainer>
        <FirstColumn />
        <SecondColumn>Item</SecondColumn>
        <ThirdColumn>Input Values</ThirdColumn>
        <FourthColumn>Input Units</FourthColumn>
        <FifthColumn>L/R</FifthColumn>
        <SixthColumn>
          Invert*
          <ToolTip>
            Select this option if a lower value should be
            considered better when calculating percent changes
          </ToolTip>
        </SixthColumn>
      </ColumnContainer>
      <SortableCont
        onSortEnd={onSortEnd}
        axis='y'
        lockAxis='y'
        lockToContainerEdges
        lockOffset={['30%', '50%']}
        helperClass='helperContainerClass'
        useDragHandle
      >
        {items.map((value, index) => (
          <SortableItem
            key={value.id}
            index={index}
            itemIndex={index}
            items={items}
            setItems={setItems}
            firstWidth={firstWidth}
            secondWidth={secondWidth}
            thirdWidth={thirdWidth}
            fourthWidth={fourthWidth}
            fifthWidth={fifthWidth}
            sixthWidth={sixthWidth}
            item={value}
            units={units}
            trackedExercises={trackedExercises}
          />
        ))}
        <div ref={endOfListRef} />
      </SortableCont>
      <NewRowButtonContainer>
        <NewTrackedRowButton onClick={() => {
          setItems(
            [...items, {
              tracked: true,
              id: null,
            },
            ],
          );
          setTimeout(() => {
            endOfListRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }, 0);
        }}
        >
          SELECT TRACKED EXERCISE
        </NewTrackedRowButton>
        <div style={{
          width: 10,
        }}
        />
        <NewCustomRowButton onClick={() => {
          setItems(
            [...items, {
              tracked: false,
              id: null,
              inputType: 1,
              isLowerNumberBetter: false,
              leftRight: false,
              unit1: '',
              unit2: '',
            },
            ],
          );
          setTimeout(() => {
            endOfListRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }, 0);
        }}
        >
          ADD CUSTOM ITEM
        </NewCustomRowButton>
      </NewRowButtonContainer>
    </EvaluationTableWrapper>
  );
};

EvaluationTable.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  setItems: PropTypes.func.isRequired,
  units: PropTypes.instanceOf(Array).isRequired,
  trackedExercises: PropTypes.instanceOf(Array).isRequired,
};

export default EvaluationTable;
