import React from 'react';
import styled from '@emotion/styled';

import { BoxShadow } from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -60px 0px 0px -250px;
  background-color: #FFFFFF;
  width: 500px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
  z-index: 1;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const Header = styled('div')`
  font-size: 22px;
  color: #444444;
`;

const Description = styled('div')`
  font-size: 20px;
  text-align: center;
`;

const InvalidTokenModal = () => (
  <ModalContainer>
    <HeaderContainer>
      <Header>Invalid Token</Header>
    </HeaderContainer>
    <Description>
      This token is either expired or invalid. Please check the link you were given and try again.
    </Description>
  </ModalContainer>
);

export default InvalidTokenModal;
