import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring/web.cjs';

import { ColorGray, TransitionGlobal } from '../../GlobalStyles';
import SearchIcon from '../../images/magnifying-glass.svg';
import IconRound from '../IconRound/IconRound';

const SearchWrapper = styled('div')`
  display: flex;
  position: relative;
  &:focus {
    outline: none;
  }
  .search-container {
    font-family: 'Open Sans';
    border: 1px solid ${props => props.theme.colors.buttonBorder[0]};
    border-radius: 21px;
    background-color: ${props => props.theme.colors.background[1]};
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-size: 18px;
    color: ${props => props.theme.colors.text};
    width: 100%;
    &::placeholder {
      color: ${ColorGray};
    }

    @media screen and (orientation: portrait) {
      width: 180px;
    }
    
    @media screen and (max-width: 601px) {
      background-size: 18px!important;
      width: 140px;
      padding: 6px 10px 6px 45px;
    }
    input {
      font-family: 'Open Sans';
      width: 100%;
      border:none;
      background-image:none;
      background-color:transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: 30px;
      color: ${props => props.theme.colors.text};
    }
  }

  .icon {
    position: absolute;
    top: 9px;
    right: 8px;
    opacity: ${props => (props.isSearchActive ? '1' : '0')};
    transition: ${TransitionGlobal};
    cursor: pointer;
    z-index: ${props => (props.isSearchActive ? '1000' : '-1000')};
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  .button {
    margin: right;
    border: none;
  }
`;

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => (htmlElRef.current && htmlElRef.current.focus());

  return [htmlElRef, setFocus];
};

const AthleteSearch = ({
  handleChange,
  isClearable,
  isExpanded,
  name,
  values,
  onClear,
  handleSearch,
  currentUser,
  currentFolder,
  handleFolderSearch,
  setActiveSearchString,
}) => {
  const [isSearchActive, setSearchActive] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const expandSearch = useCallback(() => {
    setSearchActive(true);
    setTimeout(() => setInputFocus(), 200);
  }, []);

  const collapseSearch = useCallback(() => {
    if (!isExpanded) {
      setSearchActive(false);
    }
    // setTimeout(() => searchAthlete('', namespace), 200);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      setSearchActive(true);
    }
  }, []);

  const handleClearField = (e) => {
    e.preventDefault();
    const resetValues = {};
    resetValues.accountCode = currentUser.accountCode;
    resetValues.values = {};
    resetValues.values.search = '';
    if (resetValues.accountCode !== undefined) {
      if (!currentFolder) {
        handleSearch(resetValues);
      } else if (currentFolder) {
        resetValues.currentFolder = currentFolder;
        handleFolderSearch(resetValues);
      }
    }
    onClear();
    setActiveSearchString('');
  };

  const props = useSpring({
    to: {
      width: isSearchActive ? 180 : 42,
      padding: isSearchActive ? '5px 30px 5px 45px' : '5px 10px 5px 30px',
      backgroundPosition: isSearchActive ? '20px 10px' : '11px 10px',
    },
    from: {
      width: 0,
      padding: '5px 20px 5px 40px',
      backgroundPosition: '11px 10px',
      position: 'relative',
    },
  });

  return (
    <SearchWrapper
      isClearable={isClearable}
      isSearchActive={isSearchActive}
      onClick={expandSearch}
    >
      <animated.div
        className='search-container'
        style={props}
      >
        <input
          ref={inputRef}
          placeholder='Search'
          name={name}
          onChange={handleChange}
          values={values}
          tabIndex='0'
          onBlur={collapseSearch}
        />
        {isClearable && (
          <IconWrapper>
            <button
              className='button'
              type='reset'
              onMouseDown={e => handleClearField(e)}
            >
              <IconRound
                className='icon'
                icon='remove'
                noBorder
                size={10}
              />
            </button>
          </IconWrapper>
        )}
      </animated.div>
    </SearchWrapper>
  );
};

AthleteSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isClearable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  currentFolder: PropTypes.instanceOf(Object).isRequired,
  handleFolderSearch: PropTypes.func.isRequired,
  setActiveSearchString: PropTypes.func.isRequired,
};

export default AthleteSearch;
