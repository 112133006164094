import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import styled from '@emotion/styled';

import Title from './Title';
import Conditional from './Conditional';
import CompletionEntry from './WorkoutEntriesCompletion';
import WorkoutFields from './WorkoutFields';
import FieldsStatic from './WorkoutFieldsStatic';
import { ColorLightGray } from '../../../../shared/GlobalStyles';

const Text = styled('p')`
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0px 30px;
  color: ${ColorLightGray};
  opacity: 1;
  text-align: center;

  a {
    font-size: 14px;
  }
`;

const SaqEntries = ({
  athleteId,
  entriesViewHeight,
  formikRef,
  handleInfoClick,
  index,
  namespace,
  supersetIndex,
  supersetWorkout,
  updateWorkout,
  workout,
  workoutsBySuperset,
  wrvSupersetsEnabled,
}) => {
  // const wrvSupersetsEnabled = true;
  
  // SAQ Render Conditions
  const isCompletion = workout.exer_type_2 === 'C';

  const isSaqTime = workout.exer_type_2 === 'T';

  const isSaqTimemSec = workout.exer_type_2 === 'T' && workout.additional_options.msec === 1;

  const isNewTime = workout.exer_unit === 'MS' || workout.exer_unit === 'S';

  const isNewType = workout.exer_type_2 === 'O';

  const isSaqDistance = workout.exer_type_2 === 'D';

  const isSaqDistanceDefault = workout.exer_unit !== 'F';

  const SaqInches = workout.exer_unit === 'I' || workout.exer_unit === 'F';

  const SaqMeters = workout.exer_unit === 'M';

  const SaqYard = workout.exer_unit === 'Y';

  const SaqMiles = workout.exer_unit === 'L';

  const SaqKilo = workout.exer_unit === 'K';

  const SaqCentimeters = workout.exer_unit === 'C';

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      initialValues={
        isCompletion ? (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                complete: workout.result.completed,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              complete: '0',
            });
          })()
        ) : (
          {
            work_id: workout.work_id,
            user_id: athleteId,
            result:
              workout.result ? (
                workout.result.data.map((set, i) => (
                  {
                    set_id: i + 1,
                    value:
                      set.value,
                  }
                ))
              ) : (
                Array(workout.sets).fill().map((set, i) => ({
                  set_id: i + 1,
                }))
              ),
          }
        )
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        // alert(JSON.stringify(values, null, 2))
        actions.setSubmitting(false);
      }}
      render={props => (
        <Form>
          <FieldArray
            name='result'
            render={() => (
              <Fragment>
                <FieldArray
                  name='value'
                  render={() => (
                    <Fragment>
                      <Conditional if={isCompletion}>
                        <CompletionEntry
                          handleInfoClick={handleInfoClick}
                          handleSubmit={props.handleSubmit}
                          info={workout.attributes.info}
                          setFieldValue={props.setFieldValue}
                          subHeader={workout.sub_header}
                          values={props.values}
                        />
                      </Conditional>
                      <Conditional if={isSaqTime}>
                        <Conditional if={isNewTime}>
                          <Title
                            icon='opt-out'
                            noMargin
                          >
                            Not Compatible
                          </Title>
                          <Text className='text-center'>
                            This workout is temporarily not compatible with Weightroom View. Please use Workout Entry to input this data.
                          </Text>
                        </Conditional>
                        <Conditional if={!isNewTime}>
                          <Fragment>
                            {/* <FieldsStatic
                              title='Round'
                              name='round'
                              sets={workout.sets}
                              setList={workout.set_list}
                              exerType={workout.exer_type}
                            /> */}
                            <FieldsStatic
                              title='Sets'
                              entriesViewHeight={entriesViewHeight}
                              name='sets'
                              sets={workout.sets}
                              setList={workout.set_list}
                              exerType={workout.exer_type}
                              grouping={workout.grouping}
                              supersetWorkout={supersetWorkout}
                              isWrvSupersetsEnabled={wrvSupersetsEnabled}
                            />
                            <WorkoutFields
                              exerType2={workout.exer_type_2}
                              handleChange={props.handleChange}
                              id={isSaqTimemSec ? '1' : '0'}
                              name='value'
                              title={`
                                ${isSaqTimemSec ? 'Mins' : 'Hours'}       
                              `}
                              sets={workout.sets}
                              setList={workout.set_list}
                              labels={false}
                              value={props.values.value}
                            />
                            <WorkoutFields
                              exerType2={workout.exer_type_2}
                              handleChange={props.handleChange}
                              id={isSaqTimemSec ? '2' : '1'}
                              name='value'
                              title={`
                                ${isSaqTimemSec ? 'Secs' : 'Mins'}
                              `}
                              sets={workout.sets}
                              setList={workout.set_list}
                              labels={false}
                              value={props.values.value}
                            />
                            <WorkoutFields
                              exerType2={workout.exer_type_2}
                              handleChange={props.handleChange}
                              id={isSaqTimemSec ? '3' : '2'}
                              name='value'
                              title={`
                                ${isSaqTimemSec ? 'MSecs' : 'Secs'}
                              `}
                              sets={workout.sets}
                              setList={workout.set_list}
                              labels={false}
                              value={props.values.value}
                            />
                          </Fragment>
                        </Conditional>
                      </Conditional>

                      <Conditional if={isSaqDistance}>
                        <Fragment>
                          <FieldsStatic
                            title='Sets'
                            entriesViewHeight={entriesViewHeight}
                            name='sets'
                            sets={workout.sets}
                            setList={workout.set_list}
                            exerType={workout.exer_type}
                            grouping={workout.grouping}
                            supersetWorkout={supersetWorkout}
                            isWrvSupersetsEnabled={wrvSupersetsEnabled}
                          />
                          <WorkoutFields
                            exerType2={workout.exer_type_2}
                            handleChange={props.handleChange}
                            id='0'
                            name='value'
                            title={`
                              ${SaqInches ? 'Feet' : ''}
                              ${SaqMeters ? 'Meters' : ''}
                              ${SaqYard ? 'Yard' : ''}
                              ${SaqMiles ? 'Miles' : ''}
                              ${SaqKilo ? 'Kilo' : ''}
                              ${SaqCentimeters ? 'Centimeters' : ''}
                            `}

                            sets={workout.sets}
                            setList={workout.set_list}
                            labels={false}
                            value={props.values.value}
                          />
                          <Conditional if={SaqInches}>
                            <WorkoutFields
                              exerType2={workout.exer_type_2}
                              handleChange={props.handleChange}
                              id='1'
                              name='value'
                              title={`
                                ${SaqInches ? 'Inches' : ''}
                                ${SaqMeters ? 'Meters' : ''}
                                ${SaqYard ? 'Yard' : ''}
                                ${SaqMiles ? 'Miles' : ''}
                                ${SaqKilo ? 'Kilo' : ''}
                              `}
                              sets={workout.sets}
                              setList={workout.set_list}
                              labels={false}
                              value={props.values.value}
                            />
                          </Conditional>
                        </Fragment>
                      </Conditional>
                      <Conditional if={isNewType}>
                        <Title
                          icon='opt-out'
                          noMargin
                        >
                          Not Compatible
                        </Title>
                        <Text>
                          This workout is temporarily not compatible with Weightroom View. Please use <a href='/workouts' target='_blank'>Workout Entry</a> to input this data.
                        </Text>
                      </Conditional>
                    </Fragment>
                  )}
                />
                {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
              </Fragment>
            )}
          />
        </Form>
      )}
    />
  );
};

SaqEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default SaqEntries;
