/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-useless-escape */

import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  setIsUpgradeModalShowing, setIsInstantPaymentModalShowing, setSelectedPackage, fetchSubscriptionPackage,
} from '../../ducks/sportsScienceActions';
import corePackage from './corePackage';

const ContentWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 35px;
`;

const ReviewTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
`;

const SelectedModuleDetailsBox = styled('div')`
  border-radius: 6px;
  border: 2px solid transparent;
  margin-top: 20px;
  padding: 16px;
  background: ${(props) => (props.isCore
    ? 'linear-gradient(white, white) padding-box, linear-gradient(to left, #764BA2 50%, #667EEA 100%) border-box'
    : 'linear-gradient(white, white) padding-box, linear-gradient(to left, #6A82FB 50%, #FC5C7D 100%) border-box')};
`;

const TotalCostBox = styled('div')`
  border-radius: 6px;
  border: 3px solid #0DCC8A;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModuleName = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
`;

const ModuleNameAndBadge = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 540px) {
    justify-content: center;
  }
`;

const Badge = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  color: white;
  letter-spacing: 0.5px;
`;

const ModuleCost = styled('div')`
  display: flex;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
`;

const ChargeIntervalAndLearnMore = styled('div')`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 540px) {
    margin-top: 10px;
  }
`;

const ChargeInterval = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
`;

const LearnMoreText = styled('a')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  background: ${(props) => (!props.isCore
    ? 'linear-gradient(to left, #6A82FB, #FC5C7D)'
    : 'linear-gradient(to right, #667eea, #764ba2)')};
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: color 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    height: 1px; /* Adjust the thickness of the underline */
    width: 100%;
    background: ${(props) => (!props.isCore
    ? 'linear-gradient(to left, #6A82FB, #FC5C7D)'
    : 'linear-gradient(to right, #667eea, #764ba2)')};  }

  &:hover {
    opacity: 0.8;
  }
`;
const TotalTodayText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #0DCC8A;
  margin-top: 30px;
`;

const TotalCost = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 44px;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #444444;
`;

const TermsText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  margin-top: 20px;
`;

const BadgeWrapper = styled('div')`
display: flex;
justify-content: center;
align-items: center;
padding: 6px 16px;
background: ${(props) => (props.isAddOn
    ? 'linear-gradient(to left, #6A82FB, #FC5C7D)'
    : 'linear-gradient(to right, #667eea, #764ba2)')};
gap: 8px;
border-radius : 1000px;
`;

const ReviewSubtitle = styled('div')`
color: #444;
font-feature-settings: 'clig' off, 'liga' off;
margin-top: 10px;
margin-bottom: -10px;
/* typography/Caption/Regular */
font-family: "Nunito Sans";
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: 150%;
`;

const CoreText = styled('a')`
color: #563FD8;
font-feature-settings: 'clig' off, 'liga' off;
font-family: "Nunito Sans";
font-size: 13px;
font-style: normal;
font-weight: 700;
line-height: 150%;
`;

const InstantPaymentSlide1 = () => {
  const subscriptionPackage = useSelector((state) => state.sportsScience.data.subscriptionPackage.package);
  const addOns = useSelector((state) => state.sportsScience.data.subscriptionPackage.addOns);
  const totals = useSelector((state) => state.sportsScience.data.subscriptionPackage.totals);
  const subscriptionStatus = useSelector((state) => state.sportsScience.data.subscriptionStatus);
  const selectedPackage = useSelector((state) => state.sportsScience.data.selectedPackage);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();

  const theme = useTheme();

  return (
    <ContentWrapper>
      <ReviewTitle>
        Selected Add On:
      </ReviewTitle>
      <ReviewSubtitle>
        {subscriptionStatus?.packageSubscriptionStatus?.isSubscribed === false && !selectedPackage?.core && (
        <>
          <CoreText onClick={() => {
            setTimeout(() => {
              dispatch(setIsUpgradeModalShowing(true));
            }, 500);
            batch(() => {
              dispatch(setIsInstantPaymentModalShowing());
              dispatch(setSelectedPackage(corePackage));
              dispatch(fetchSubscriptionPackage(currentUser.accountCode, 1));
            });
          }}
          >
            Core
            {' '}
          </CoreText>
          must be selected before paying for any additional add-ons
        </>
        )}
      </ReviewSubtitle>
      {subscriptionStatus?.packageSubscriptionStatus?.isSubscribed === false && (
        <SelectedModuleDetailsBox isCore>
          <ModuleNameAndBadge>
            <ModuleName>
              Sport Science Hub Add-On
            </ModuleName>
            <BadgeWrapper>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color='white'
              />
              <Badge>
                SELECTED
              </Badge>
            </BadgeWrapper>
          </ModuleNameAndBadge>
          <ModuleCost>
            {subscriptionPackage?.displayTotalPrice}
          </ModuleCost>
          <ChargeIntervalAndLearnMore>
            <ChargeInterval>
              {subscriptionPackage?.subscriptionType === 'month' ? (
                'per month'
              ) : ('per year')}
            </ChargeInterval>
            <LearnMoreText href='https://www.teambuildr.com/sport-science' target='_blank' isCore>
              Learn More
            </LearnMoreText>
          </ChargeIntervalAndLearnMore>
        </SelectedModuleDetailsBox>
      )}

      {addOns?.map((addOn) => (
        <SelectedModuleDetailsBox>
          <ModuleNameAndBadge>
            <ModuleName>
              {addOn?.addOnName}
            </ModuleName>
            <BadgeWrapper isAddOn>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color='white'
              />
              <Badge>
                SELECTED
              </Badge>
            </BadgeWrapper>
          </ModuleNameAndBadge>
          <ModuleCost>
            {addOn?.displayTotalPrice}
          </ModuleCost>
          <ChargeIntervalAndLearnMore>
            <ChargeInterval>
              {addOn?.subscriptionType === 'month' ? (
                'per month'
              ) : ('per year')}
            </ChargeInterval>
            <LearnMoreText href='https://www.teambuildr.com/sport-science' target='_blank'>
              Learn More
            </LearnMoreText>
          </ChargeIntervalAndLearnMore>
        </SelectedModuleDetailsBox>
      ))}
      <TotalTodayText>
        Total today:
      </TotalTodayText>
      <TotalCostBox>
        <TotalCost>
          {totals?.displayProratedTotals}
        </TotalCost>
      </TotalCostBox>
      <TermsText>
        {`Current subscription runs through ${subscriptionPackage?.proratedEndDate}.`}
      </TermsText>
    </ContentWrapper>
  );
};

InstantPaymentSlide1.propTypes = {

};

InstantPaymentSlide1.defaultProps = {
};

export default InstantPaymentSlide1;
