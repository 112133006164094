/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { setIsProgramInformationModalShowing } from '../../ducks/workoutsActions';
import ProgramInformationModal from './ProgramInformationModal';

const ProgramInformationMdoalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1003;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const ProgramInformationModalContainer = () => {
  const isProgramInformationModalShowing = useSelector(
    (state) => state.workouts.ui.isProgramInformationModalShowing,
  );

  const dispatch = useDispatch();

  return (
    <>
      <ProgramInformationModal />
      <ProgramInformationMdoalOverlay
        isActive={isProgramInformationModalShowing}
        onClick={
          () => dispatch(setIsProgramInformationModalShowing(false))
          }
      />
    </>
  );
};

ProgramInformationModalContainer.propTypes = { };

export default ProgramInformationModalContainer;
