/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import JournalEntryModal from './JournalEntryModal';

import { setJournalEntryWorkout } from '../../ducks/workoutsActions';

const JournalEntryOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1001;
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const JournalEntryModalContainer = () => {
  const journalEntryWorkout = useSelector(
    (state) => state.workouts.data.journalEntryWorkout,
  );

  const dispatch = useDispatch();

  return (
    <>
      <JournalEntryModal
        journalEntryWorkout={journalEntryWorkout}
      />
      <JournalEntryOverlay
        isActive={Object.keys(journalEntryWorkout).length}
        onClick={() => {
          dispatch(setJournalEntryWorkout({}));
        }}
      />
    </>
  );
};

JournalEntryModalContainer.propTypes = { };

export default JournalEntryModalContainer;
