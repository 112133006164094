import React from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';

import Button from '../../../../shared/components/Button/Button';
import Checkmark from '../../../../shared/components/Checkmark/Checkmark';
import Text from '../../../../shared/components/Text/Text';
import Link from '../../../../shared/components/Link/Link';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import useReset from '../hooks/useReset';

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  .return-link {
    margin-top: 30px;
  }
  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

const ResponseMessage = styled('div')`
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #ff6600;
`;

export const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  margin-left: -54px;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    margin-left: -30px;
  }
`;

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
});

const ForgotPasswordForm = () => {
  const {
    handleForgot,
  } = useReset();

  const forgotError = useSelector((state) => state.forgot.ui.forgotError);
  const isSubmitting = useSelector((state) => state.forgot.ui.isSubmitting);
  const isComplete = useSelector((state) => state.forgot.ui.isComplete);
  const isDisabled = useSelector((state) => state.forgot.ui.isDisabled);

  const formAnimation = useSpring({
    opacity: isSubmitting || isComplete ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isSubmitting ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isComplete ? 1 : 0 });

  const theme = useTheme();

  return (
    <Formik
      initialValues={{
        email: '',
        // Having these below, results in a 400 error because of too many values in request
        // client_id: process.env.CLIENT_ID,
        // client_secret: process.env.CLIENT_SECRET,
        // scope: 'offline_access',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleForgot(values);
          setSubmitting(false);
        });
      }}
      validationSchema={EmailSchema}
      render={({
        errors,
        touched,
        handleSubmit,
      }) => (
        <>
          <animated.div style={formAnimation}>
            <Form
              name='forgot'
              id='forgot'
            >
              <FormGroup className='form-group'>
                <label>
                  <Text
                    fontSize={theme.textFontSizes[0]}
                    className='text-center'
                  >
                    Enter Your Email Address
                  </Text>
                  <Field
                    className='form-control'
                    id='email-field'
                    type='email'
                    name='email'
                    placeholder='Email'
                    disabled={isDisabled}
                  />
                </label>
                {errors.email && touched.email && <FieldError className='text-center'>{errors.email}</FieldError>}
                {forgotError && <FieldError className='text-center'>Please enter a valid email address and try again</FieldError>}
              </FormGroup>
              <FormGroup className='text-center'>
                <Button
                  cta='Submit'
                  customColor='#ff6600'
                  customTextColor='#ffffff'
                  name='submit'
                  id='submit'
                  onClick={handleSubmit}
                  disabled={isDisabled}
                  fullWidth
                  large
                  noBorder
                  rounded
                  primary
                  className='button'
                  isLoading={isSubmitting}
                />
                <Link allCaps className='return-link' color='lightText.1' disabled={isDisabled} href='/login'>
                  Return to Login
                </Link>
              </FormGroup>
            </Form>
          </animated.div>
          <animated.div style={spinnerAnimation}>
            <MessageContainer>
              <Spinner />
            </MessageContainer>
          </animated.div>
          <animated.div style={completeAnimation}>
            <MessageContainer>
              <Checkmark />
              <ResponseMessage className='text-center'>
                If your email is associated with a TeamBuildr account, a link to reset your password will be sent to your inbox
              </ResponseMessage>
            </MessageContainer>
          </animated.div>
        </>
      )}
    />
  );
};

export default ForgotPasswordForm;
