import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';

import Select from 'react-select';
import NoHoverButton from './NoHoverButton';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const BottomWrapper = styled('div')`
  width: 80%;
  display: flex;
  height: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: -25px;
  .formik-form {
    width: 100%;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 10px;
    margin-bottom: 40px;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const TopWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: right;
  height: 40%;
  margin-top: -30px;
  .icon-text {
    font-weight: 300;
    margin-top: 10px;
    font-size: 26px;
  }
`;

const CalendarIconCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;

const IconTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: -30px;
  height: 130px;
`;

const FormGroup = styled('div')`
  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    /* margin-top: 30px; */
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .folder-text {
    font-weight: bold;
  }
  .group-access-text {
    font-weight: bold;
    margin-top: 8px;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const ModalUpdateCalendar = ({
  handleUpdateCalendar,
  handleUpdateChildCalendar,
  currentUser,
  calendar,
  onRequestClose,
  calendars,
}) => {
  const unparsedCalendars = useSelector((state) => state.calendars.data.unparsedCalendars);
  const isCalendarSubmitting = useSelector(
    (state) => state.calendars.ui.isCalendarSubmitting,
  );
  const handleSubmit = (values) => {
    const newValues = values;
    if (!calendar.parentId) {
      let calendarIndex;
      let oldCalendarIndex;
      if (!values.parentId) {
        calendarIndex = unparsedCalendars.findIndex(
          (unparsedCalendar) => unparsedCalendar.id === calendar.id,
        );
      } else {
        calendarIndex = unparsedCalendars.findIndex(
          (unparsedCalendar) => unparsedCalendar.id === values.parentId,
        );
        oldCalendarIndex = unparsedCalendars.findIndex(
          (unparsedCalendar) => unparsedCalendar.id === calendar.id,
        );
      }
      handleUpdateCalendar(
        currentUser.accountCode,
        calendar.id,
        newValues,
        calendarIndex,
        oldCalendarIndex,
      );
    } else {
      const parentCalendarIndex = unparsedCalendars.findIndex(
        (unparsedCalendar) => unparsedCalendar.id === calendar.parentId,
      );
      const parentCalendar = unparsedCalendars[parentCalendarIndex];
      const childCalendarIndex = parentCalendar.childCalendars.findIndex(
        (childCalendar) => childCalendar.id === calendar.id,
      );
      handleUpdateChildCalendar(
        currentUser.accountCode,
        calendar.id, newValues,
        calendar.parentId,
        parentCalendarIndex,
        childCalendarIndex,
      );
    }
  };

  const updateCalendarError = useSelector((state) => state.calendars.data.updateCalendarError);
  const updateChildCalendarError = useSelector(
    (state) => state.calendars.data.updateChildCalendarError,
  );

  const workoutVisibilityOptions = [
    {
      name: 'All',
      id: 0,
    },
    {
      name: 'One Week',
      id: 1,
    },
    {
      name: 'Current Day',
      id: 2,
    },
  ];

  const parentsByIds = {};
  const parentCalendars = () => {
    const returnCalendars = [];
    const nonParentCalendar = { id: 0, name: '-None-' };
    returnCalendars.push(nonParentCalendar);
    calendars.forEach((ogCalendar) => {
      if (!ogCalendar.parentId && ogCalendar.id !== null && ogCalendar.id !== calendar.id) {
        parentsByIds[ogCalendar.id] = ogCalendar;
        returnCalendars.push(ogCalendar);
      }
    });
    return returnCalendars;
  };

  parentCalendars();

  return (
    <ModalWrapper>
      <Formik
        initialValues={{
          name: calendar.name,
          workoutVisibility: calendar.workoutVisibility,
          parentId: calendar.parentId,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleSubmit(values);
            setSubmitting(false);
          });
        }}
        render={({
          submitForm,
          setFieldValue,
          values,
        }) => (
          <ModalWrapper>
            <AnotherWrapper>
              <NavigationWrapper>
                <Text
                  className='back-arrow-div'
                  onClick={() => {
                    onRequestClose();
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={10}
                    icon='remove'
                  />
                </Text>
              </NavigationWrapper>
              <FormWrapper>
                <TopWrapper>
                  <IconTextWrapper>
                    <CalendarIconCircle>
                      <IcomoonReact
                        iconSet={iconSet}
                        size={30}
                        icon='calendar'
                      />
                    </CalendarIconCircle>
                    <Text className='icon-text'>Edit Calendar</Text>
                    {updateCalendarError ? <TitleError className='error-text'>{updateCalendarError}</TitleError> : null}
                    {updateChildCalendarError ? <TitleError className='error-text'>{updateChildCalendarError}</TitleError> : null}
                  </IconTextWrapper>
                </TopWrapper>
                <div className='divider-line' />
                <BottomWrapper>
                  <Form className='formik-form'>
                    <FormGroup>
                      <Text className='formgroup-text'><strong>Name</strong></Text>
                      <Field
                        className='form-control'
                        id='name-field'
                        name='name'
                        placeholder='Document Name'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Parent Calendar</strong>
                      </Text>
                      <Select
                        options={parentCalendars()}
                        clearValue={{ id: 0, name: '-None-' }}
                        defaultValue={parentsByIds[values.parentId]}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={(options) => {
                          if (options) {
                            setFieldValue('parentId', options.id);
                          } else {
                            setFieldValue('parentId', 0);
                          }
                        }}
                        isOptionSelected={(option) => values.parentName === option.name}
                        isDisabled={!calendar.parentId && calendar.childCalendars.length}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Text className='formgroup-text'>
                        <strong>Future Workout Visibility</strong>
                      </Text>
                      <Select
                        defaultValue={workoutVisibilityOptions[calendar.workoutVisibility]}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        options={workoutVisibilityOptions}
                        onChange={(options) => {
                          if (options) {
                            setFieldValue('workoutVisibility', options.id);
                          }
                        }}
                        isOptionSelected={(group) => values.group === group}
                      />
                    </FormGroup>
                  </Form>
                </BottomWrapper>
              </FormWrapper>
            </AnotherWrapper>
            <NoHoverButton
              bottom
              fullWidth
              cta={!isCalendarSubmitting ? 'Update Calendar' : 'Updating...'}
              customColor='#10cd8c'
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={() => {
                submitForm();
              }}
              disabled={isCalendarSubmitting}
              noHover
            />
          </ModalWrapper>
        )}
      />
    </ModalWrapper>
  );
};

ModalUpdateCalendar.propTypes = {
  handleUpdateCalendar: PropTypes.func.isRequired,
  handleUpdateChildCalendar: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  calendar: PropTypes.instanceOf(Object).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  calendars: PropTypes.instanceOf(Array).isRequired,
};

export default ModalUpdateCalendar;
