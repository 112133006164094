/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import moment from 'moment-timezone';
import { ToastContainer } from 'react-toastify';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { BoxShadow } from '../../../../shared/GlobalStyles';

import EvalTableDatePicker from './EvalTableDatePicker';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import SingleInputLeftRightComponent from './SingleInputLeftRightComponent';
import SingleInputComponent from './SingleInputComponent';
import DualLeftRightInputComponent from './DualLeftRightInputComponent';
import DualInputComponent from './DualInputComponent';
import {
  fetchEvaluation,
  resetEvaluation,
  setSelectedEvalDate,
  setEditEvalModal1,
  setAutoSaveInfoModal,
  setSelectExistingEvalModal1,
} from '../../ducks/evaluationsActions';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import useNetworkStatus from '../../../../shared/utils/networkStatus'; // Path to your custom hook
import 'react-toastify/dist/ReactToastify.css';

const athleteNameWidth = 200;
const athleteNameColumnPadding = '12px 24px 12px 24px';

const Wrapper = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const UpperPaddingWrapper = styled('div')`
  padding-left: 40px;
  padding-right: 20px;
  padding-top: 40px;

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const LowerPaddingWrapper = styled('div')`
`;

const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const TopRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TableWrapper = styled('div')`
  width: 100%;
  padding-bottom: 75px;
`;

const NameColumn = styled('div')`
  width: ${athleteNameWidth}px;
  display: flex;
  justify-content: center;
  font-weight: 700;
  position: sticky;
  left: 0;
  z-index: 1;
  min-height: 78px;
  align-items: center;
  border-radius: 16px;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100px;
    padding: 12px 24px 12px 24px;

  }
`;

const TableContainer = styled('div')`
  width: 100%;
  overflow: auto;
  margin-left: 40px;
  padding-right: 40px;
  font-family: 'Nunito Sans';
  @media (max-width: 768px) {
    margin-left: 15px;
    padding-right: 15px;
  }
`;

const TableHeader = styled('div')`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 16px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  background: #FFFFFF;
  min-height: 78px;
  margin-top: 20px;
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 8px;
`;

const TableRow = styled('div')`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: 'Nunito Sans';
  margin-top: 15px;
  &:last-of-type {
    margin-bottom: 72px;
  }
`;

const TableHeaderNames = styled('div')`
  height: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const TableHeaderValues = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const TableValuesWrapper = styled('div')`
`;
const TableHeaderValue = styled('div')`
  display: flex;
  justify-content: center;
  min-width: 220px;
  margin-left: 10px;
  max-width: 220px;
  align-items: center;
  flex: ${(props) => props.flexValue};
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    min-width: 150px;
  }
`;

const AthleteNames = styled('div')`
  flex-direction: column;
  position: sticky;
  left: 0;
  z-index: 1;
`;

const AthleteName = styled('div')`
  width: ${athleteNameWidth}px;
  font-family: 'Nunito Sans';
  padding: ${athleteNameColumnPadding};
  box-shadow: ${BoxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #FFFFFF;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    width: 100px;
    font-size: 14px;
    padding: 12px;
  }
`;

const Left = styled('div')`
  flex: 3;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 100%;
  font-family: 'Nunito Sans';
  color: #444444;
  font-weight: 400;
  font-size: 13px;
  padding-top: 15px;
`;

const Right = styled('div')`
  flex: 3;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 100%;
  font-family: 'Nunito Sans';
  color: #444444;
  font-weight: 400;
  font-size: 13px;
  padding-top: 15px;
`;

const LeftRightTableHeaderValue = styled('div')`
  flex: ${(props) => props.flexValue};
  display: flex;
  justify-content: space-evenly;
  min-width: ${(props) => (props.doubleWidth ? '440px' : '220px')};
  margin-left: 10px;
  position: relative;

  @media (max-width: 768px) {
    min-width: ${(props) => (props.doubleWidth ? '300px' : '150px')};
  }
`;

const LeftRightColumnTitle = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 35%;
`;

const DatePickerDiv = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  width: 250px;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
  background: white;
  margin-right: ${(props) => (props.show ? '0px' : '15px')};


  @media (max-width: 768px) {
    width: 160px;
  }
`;

const UpperRightBox = styled('div')`
  display: flex;
`;

const RelativityBox = styled('div')`
  position: relative;
`;

const EndBlock = styled('div')`
  width: 40px;
  background: #444444;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DatePickerText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const EditBox = styled('div')`
  display: flex;
  background: #77A7FF;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  width: ${(props) => (props.show ? 'unset' : '87px')};
  @media (max-width: 768px) {
    height:40px;
  }
`;

const EditBoxText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-left: 5px;
  margin-top: 1px;
`;

const FixedFloatingOptions = styled('div')`
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
  }
`;

const FloatOptionTopRow = styled('div')`
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-end;
  flex-direction: column;
  gap:20px;
  align-self: flex-end;
  width: auto;


  @media (max-width: 768px) {
    gap: 10px;
    margin-bottom: 10px;
  }
`;

const FloatOptionBottomRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const LastSavedBubble = styled('div')`
  background: white;
  height: 45px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 17px;
  cursor: pointer;
  position: relative;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 35px;
    padding: 10px;
  }
`;
const NetworkStatusCircle = styled('div')`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.networkStatusColor};
`;

const TypeSelectionBubble = styled('div')`
  background: white;
  height: 45px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
  display: flex;
  border-radius: 20px;
  align-items: center;
  padding: 5px;
  padding-left: 10px;


  @media (max-width: 768px) {
    height: 35px;
    padding: 2px;
    padding-left: 5px;
  }
`;

const TypeSelectionIcon = styled('div')`
  
`;

const VerticalLine = styled('div')`
  height: 70%;
  width: 2px;
  background: #BDBDBD;
  margin-left: 10px;
`;

const GreenCircle = styled('div')`
  background: #0dcc8a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
`;

const LastSavedText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #444444;
  font-weight: 700;
  margin-left: 10px;
  white-space: nowrap;
`;

const ActiveGroupText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #444444;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100px;
  margin-top: 2px;
  justify-content: center;
`;

const SelectedByTypeBubble = styled('div')`
  background: #444444;
  height: 25px;
  margin-left: 10px;
  border-radius: 25px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  padding-right: 10px;

  @media (max-width: 768px) {
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
  }
`;

const SelectedGroupBubble = styled('div')`
  background: #444444;
  height: 25px;
  margin-left: 10px;
  border-radius: 25px;
  display: flex;
  padding-left: 10px;
  align-items: center;
  padding-right: 10px;

  @media (max-width: 768px) {
    height: 20px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 5px;
  }
`;

const BubbleText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: white;
  font-weight: 700;
  margin-left: 10px;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const TitleAndBackButton = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BackButton = styled('div')`
  cursor: pointer;
`;

const HeaderName = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => props.fontSize || '16px'};
  width: 100%;
  max-width: 200px;

  @media (max-width: 768px) {
    font-size: 12px;
    max-width: 150px;
  }
`;

const Tooltip = styled.div`
  visibility: hidden;
  background-color: #FFFFFF;
  color: #444444;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: normal;
  max-width: 250px;
  box-sizing: border-box;
  word-wrap: break-word;
  box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  width:230px;

  &:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #FFFFFF transparent; 
  }
`;

const HeaderNameContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 700;
  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

const IconCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #000; 
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
`;

const Dropdown = styled.div`
  display: none;
  position: relative;
  @media (max-width: 768px) {
    display: block;
  }
`;

const DropdownContent = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 100;
  right: 0;
  gap:10px;
  flex-direction: column;
  border-radius: 6px;

  @media (max-width: 768px) {
    gap:20px;

  }
`;

const DropdownButton = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
    background-color: #77a7ff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
  }
`;
const IconRotator = styled('div')`
  transform: rotate(90deg);
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }
`;
const headerResolver = (headerData) => {
  const renderTrophyIcon = () => (
    <IconCircle>
      <IcomoonReact
        iconSet={iconSet}
        size={14}
        icon='trophy'
        color='#000'
      />
    </IconCircle>
  );

  const baseFontSize = headerData.name.length > 20 ? '12px' : '16px';
  const isLongName = headerData.name.length > 20;

  const headerContent = (
    <HeaderNameContainer>
      {headerData.exerLink && renderTrophyIcon()}
      <HeaderName fontSize={baseFontSize}>
        {headerData.name}
      </HeaderName>
      {isLongName && <Tooltip>{headerData.name}</Tooltip>}
    </HeaderNameContainer>
  );

  let flexValue = 1;
  let doubleWidth = false;

  if (headerData.inputType === 1) {
    if (headerData.leftRight) {
      flexValue = 2;
      doubleWidth = true;
    }
  } else if (headerData.inputType === 2) {
    flexValue = headerData.leftRight ? 4 : 2;
    doubleWidth = !!headerData.leftRight;
  }

  if (doubleWidth) {
    return (
      <LeftRightTableHeaderValue flexValue={flexValue} doubleWidth>
        <Left>Left</Left>
        {headerContent}
        <Right>Right</Right>
      </LeftRightTableHeaderValue>
    );
  }
  return (
    <TableHeaderValue flexValue={flexValue}>
      {headerContent}
    </TableHeaderValue>
  );
};

const rowResolver = (item, data, evaluation, athleteIdx, inputIdx) => {
  const valuesArray = Object.values(data);
  if (item.inputType === 1) {
    if (item.leftRight) {
      return (
        <SingleInputLeftRightComponent
          item={item}
          valuesArray={valuesArray}
          evaluation={evaluation}
          athleteIdx={athleteIdx}
          inputIdx={inputIdx}
          doubleWidth
        />
      );
    }
    return (
      <SingleInputComponent
        item={item}
        valuesArray={valuesArray}
        evaluation={evaluation}
        athleteIdx={athleteIdx}
        inputIdx={inputIdx}
      />
    );
  } if (item.inputType === 2) {
    if (item.leftRight) {
      return (
        <DualLeftRightInputComponent
          item={item}
          valuesArray={valuesArray}
          evaluation={evaluation}
          athleteIdx={athleteIdx}
          inputIdx={inputIdx}
          doubleWidth
        />
      );
    }
    return (
      <DualInputComponent
        item={item}
        valuesArray={valuesArray}
        evaluation={evaluation}
        athleteIdx={athleteIdx}
        inputIdx={inputIdx}
      />
    );
  }
  return null;
};

const EvaluationContentTable = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [showDropdown, setShowDropdown] = useState(false);
  const isEvaluationLoading = useSelector((state) => state.evaluations.ui.isEvaluationLoading);
  const evaluation = useSelector((state) => state.evaluations.data.evaluation);
  const selectedEvalDate = useSelector(
    (state) => state.evaluations.data.selectedEvalDate,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const evalGroupOption = useSelector(
    (state) => state.evaluations.data.evalGroupOption,
  );
  const evalGroupTypeOption = useSelector(
    (state) => state.evaluations.data.evalGroupTypeOption,
  );
  const dispatch = useDispatch();

  const [initialDateSetAttempted, setInitialDateSetAttempted] = useState(false);

  const latestTimestamp = useSelector((state) => state.evaluations.data.latestTimeStamp);

  const { isOnline, networkType } = useNetworkStatus();

  const networkStatus = isOnline
    ? networkType.includes('4g') || networkType.includes('wifi')
      ? 'Good'
      : 'Slow'
    : 'Check Connection';

  // Determine the color of the circle based on the network status
  const networkStatusColor = networkStatus === 'Good'
    ? '#0dcc8a' // Green for good
    : networkStatus === 'Slow'
      ? '#FFD700' // Yellow for slow
      : '#FF0000'; // Red for offline

  const filterTypeResolver = () => {
    if (evalGroupTypeOption === 'Select By Athlete Group') {
      return 2;
    } if (evalGroupTypeOption === 'Select By Calendars') {
      return 3;
    }
    return 1;
  };

  const setSelectedDate = (date) => {
    dispatch(fetchEvaluation({
      accountCode: currentUser.accountCode,
      evaluationId: evaluation.id,
      selectedDate: date.format('YYYY-MM-DD'),
      filterType: filterTypeResolver(),
      filterId: evalGroupOption.id,
    }));
    dispatch(setSelectedEvalDate(date));
  };

  useEffect(() => {
    if (!selectedEvalDate && !initialDateSetAttempted) {
      const today = moment();
      dispatch(setSelectedEvalDate(today));
      setInitialDateSetAttempted(true);
    }
  }, [selectedEvalDate, initialDateSetAttempted, dispatch]);

  // Primariliy used for the dropdown on smaller screens
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const [pickerOpen, setPickerOpen] = useState(false);
  return (
    <>
      {!isEvaluationLoading ? (
        <Wrapper onClick={(e) => {
          if (e.target.id !== 'eval-table-date-picker' && pickerOpen) {
            setPickerOpen(false);
          }
        }}
        >
          <UpperPaddingWrapper>
            <TopRow>
              <TitleAndBackButton>
                <BackButton onClick={() => dispatch(resetEvaluation())}>
                  <IcomoonReact iconSet={iconSet} size={20} icon='left-arrow' />
                </BackButton>
                <HeaderText style={{ marginLeft: 10 }}>
                  {evaluation.title}
                </HeaderText>
              </TitleAndBackButton>
              {isSmallScreen ? (
                <Dropdown>
                  <DropdownButton onClick={() => setShowDropdown(!showDropdown)}>
                    <IconRotator>
                      <IcomoonReact
                        iconSet={iconSet}
                        size={18}
                        icon='dots'
                        color='white'
                      />
                    </IconRotator>
                  </DropdownButton>
                  <DropdownContent show={showDropdown}>
                    <DatePickerDiv onClick={() => setPickerOpen(!pickerOpen)} show={showDropdown}>
                      <DatePickerText>
                        {selectedEvalDate ? selectedEvalDate.format('MMM DD, YYYY') : 'Choose Date'}
                      </DatePickerText>
                      <EvalTableDatePicker
                        id='eval-table-date-picker'
                        isDayPickerOpen={pickerOpen}
                        setIsDayPickerOpen={setPickerOpen}
                        activeDate={selectedEvalDate}
                        selectDate={setSelectedDate}
                        rightPosition={5}
                      />
                      <EndBlock>
                        <IcomoonReact iconSet={iconSet} size={20} icon='calendar' color='white' />
                      </EndBlock>
                    </DatePickerDiv>
                    <EditBox
                      onClick={() => dispatch(setEditEvalModal1(evaluation))}
                      show={showDropdown}
                    >
                      <IcomoonReact iconSet={iconSet} size={15} icon='pencil' color='white' />
                      <EditBoxText>Edit</EditBoxText>
                    </EditBox>
                  </DropdownContent>
                </Dropdown>
              ) : (
                <UpperRightBox>
                  <DatePickerDiv onClick={() => setPickerOpen(!pickerOpen)}>
                    <DatePickerText>
                      {selectedEvalDate ? selectedEvalDate.format('MMM DD, YYYY') : 'Choose Date'}
                    </DatePickerText>
                    <EvalTableDatePicker
                      id='eval-table-date-picker'
                      isDayPickerOpen={pickerOpen}
                      setIsDayPickerOpen={setPickerOpen}
                      activeDate={selectedEvalDate}
                      selectDate={setSelectedDate}
                    />
                    <EndBlock>
                      <IcomoonReact iconSet={iconSet} size={20} icon='calendar' color='white' />
                    </EndBlock>
                  </DatePickerDiv>
                  <EditBox onClick={() => dispatch(setEditEvalModal1(evaluation))}>
                    <IcomoonReact iconSet={iconSet} size={15} icon='pencil' color='white' />
                    <EditBoxText>Edit</EditBoxText>
                  </EditBox>
                </UpperRightBox>
              )}
            </TopRow>
          </UpperPaddingWrapper>
          <TableContainer>
            <TableHeader>
              <NameColumn>Athlete Name</NameColumn>
              <TableHeaderNames>
                {evaluation.items.map((evaluationItem) => (
                  headerResolver(evaluationItem)
                ))}
              </TableHeaderNames>
            </TableHeader>
            <TableWrapper>
              <LowerPaddingWrapper>
                <TableValuesWrapper>
                  {evaluation.data.map((evaluationDataOb, athleteIdx) => (
                    <TableRow key={athleteIdx}>
                      <AthleteNames>
                        <AthleteName>
                          {`${evaluationDataOb.first} ${evaluationDataOb.last}`}
                        </AthleteName>
                      </AthleteNames>
                      <TableHeaderValues>
                        {evaluationDataOb.itemData.map((item, inputIdx) => (
                          rowResolver(
                            evaluation.items[inputIdx], item, evaluation, athleteIdx, inputIdx,
                          )
                        ))}
                      </TableHeaderValues>
                    </TableRow>
                  ))}
                </TableValuesWrapper>
              </LowerPaddingWrapper>
            </TableWrapper>
          </TableContainer>
        </Wrapper>
      ) : (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <FixedFloatingOptions>
        <FloatOptionTopRow>
          <LastSavedBubble onClick={() => dispatch(setAutoSaveInfoModal(true))}>
            <NetworkStatusCircle networkStatusColor={networkStatusColor} />
            <LastSavedText>
              {latestTimestamp ? `Last Saved: ${moment.unix(latestTimestamp).format('hh:mmA')}` : networkStatus}
            </LastSavedText>
          </LastSavedBubble>
        </FloatOptionTopRow>
        <FloatOptionBottomRow>
          <TypeSelectionBubble>
            <TypeSelectionIcon>
              <IcomoonReact iconSet={iconSet} size={15} icon='filter' color='black' />
            </TypeSelectionIcon>
            <ActiveGroupText>Active Group</ActiveGroupText>
            <VerticalLine />
            <SelectedByTypeBubble onClick={() => dispatch(setSelectExistingEvalModal1(true))}>
              <IcomoonReact iconSet={iconSet} size={15} icon='swap' color='white' />
              <BubbleText>{evalGroupTypeOption}</BubbleText>
            </SelectedByTypeBubble>
            {Object.keys(evalGroupOption).length > 0 && evalGroupTypeOption !== 'All Athletes' && (
            <SelectedGroupBubble onClick={() => dispatch(setSelectExistingEvalModal1(true))}>
              <IcomoonReact iconSet={iconSet} size={15} icon='swap' color='white' />
              <BubbleText>{evalGroupOption.name}</BubbleText>
            </SelectedGroupBubble>
            )}
          </TypeSelectionBubble>
        </FloatOptionBottomRow>
      </FixedFloatingOptions>
      <ToastContainer />
    </>
  );
};

EvaluationContentTable.defaultProps = {

};

EvaluationContentTable.propTypes = {

};

export default EvaluationContentTable;
