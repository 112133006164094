import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';
import { Global, css } from '@emotion/core';

import Button from '../../../shared/components/Button/Button';
import Checkmark from '../../../shared/components/Checkmark/Checkmark';
import Text from '../../../shared/components/Text/Text';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Link from '../../../shared/components/Link/Link';
import GoogleIcon from '../../../shared/images/google_icon.svg';
import encode from '../../../shared/utils/encodeToUrl';
import useLogin from '../../login/components/hooks/useLogin';
import Spinner from '../../../shared/components/Spinner/Spinner';

const ModalContainer = styled('div')`
  position: absolute;
  z-index: 1001;
  height: 500px;
  width: 500px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  animation: animatebottom 0.2s;
  flex-direction: column;
  justify-content: space-between;
  @keyframes animatebottom {
    from {
      opacity: 0;
      transform: translate(-50%, 100%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  overflow: auto;
  left: 50%;
  top: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
`;

const LoginContainer = styled('div')`
  height: 500px;
  width: 500px;
  border-radius: 6px;
  background: #ffffff;
  #login {
    padding: 20px 80px;
  }
`;

const ModalHeader = styled('div')`
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  text-align: center;
  padding: 25px;
`;

const FormWrapper = styled('div')`
  opacity: ${props => (props.isLoginLoading || props.isAuthenticated ? 0 : 1)};
  z-index: 1;
  position: relative;
`;

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }

  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
    cursor: pointer;

  &:hover {
    opacity: .6;
  }

  span {
    font-family: inherit;
    color: ${(props) => props.theme.colors.orange}
  }
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
  }
`;

const LoginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
  password: Yup.string()
    .required('Password Required'),
});

const ModalLogin = ({
  setModal,
}) => {
  const {
    handleGoogleAuth,
    handleLogin,
    isLoginLoading,
    isAuthenticated,
    loginError,
    googleError,
  } = useLogin();

  const spinnerAnimation = useSpring({ opacity: isLoginLoading ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isAuthenticated ? 1 : 0 });

  const theme = useTheme();

  const handleGoogleResponse = (response) => {
    const values = {
      grant_type: 'google',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      tokenid: response.tokenId,
      scope: 'offline_access',
    };

    handleGoogleAuth(encode(values));
  };

  return (
    <ModalContainer>
      <LoginContainer>
        <Formik
          initialValues={{
            grant_type: 'password',
            username: '',
            password: '',
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
            scope: 'offline_access',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleLogin(encode(values));
              setSubmitting(false);
              setModal(false);
            });
          }}
          validationSchema={LoginSchema}
          render={({
            errors,
            touched,
            handleSubmit,
          }) => (
            <>
              <ModalHeader>
                <HeaderText>Sign In</HeaderText>
              </ModalHeader>
              <Global
                styles={css`
                .ReactModal__Content {
                  width: 420px!important;
                }
              `}
              />
              <FormWrapper isLoginLoading={isLoginLoading} isAuthenticated={isAuthenticated}>
                <Form
                  name='login'
                  id='login'
                >
                  {' '}
                  <FormGroup className='form-group'>
                    {' '}
                    <label>
                      {' '}
                      <Text
                        fontSize={theme.textFontSizes[0]}
                      >
                        Email Address
                      </Text>
                      <Field
                        className='form-control'
                        id='email-field'
                        type='email'
                        name='username'
                        placeholder='Email'
                      />
                    </label>
                    {errors.username && touched.username && <FieldError className='text-center'>{errors.username}</FieldError>}
                  </FormGroup>
                  <FormGroup className='form-group'>
                    <label>
                      <Text
                        fontSize={theme.textFontSizes[0]}
                      >
                        Password
                      </Text>
                      <Field
                        className='form-control'
                        type='password'
                        name='password'
                        placeholder='Password'
                      />
                    </label>
                    {errors.password && touched.password && <FieldError className='text-center'>{errors.password}</FieldError>}
                    {loginError && <FieldError className='text-center'>Your email address or password is incorrect! Please try again.</FieldError>}
                    {googleError && <FieldError className='text-center'>This google account does not seem to be associated with a TeamBuildr account.</FieldError>}
                  </FormGroup>
                  <FormGroup className='text-center'>
                    <Button
                      cta='Sign In'
                      customColor='#ff6600'
                      disabled={errors.password || errors.username}
                      name='submit'
                      id='submit'
                      onClick={handleSubmit}
                      fullWidth
                      large
                      noBorder
                      rounded
                      primary
                    />
                    <GoogleLogin
                      clientId='849504945663-2a20rmtkv13kkrs7idoafi82fvoko52l.apps.googleusercontent.com'
                      buttonText='Login with Google'
                      onSuccess={handleGoogleResponse}
                      onFailure={handleGoogleResponse}
                      cookiePolicy='single_host_origin'
                      render={(renderProps) => (
                        <Button
                          cta='Login with Google'
                          customColor='#ffffff'
                          customIcon={GoogleIcon}
                          disabled={renderProps.disabled}
                          noHover
                          onClick={renderProps.onClick}
                          fullWidth
                          large
                          rounded
                        />
                      )}
                    />
                    <Link allCaps color='lightText.1' href='https://app.teambuildr.com/signup?ej=true'>
                      Have an Easy Join Code? Click Here
                    </Link>
                    <Link allCaps color='lightText.2' href='https://app.teambuildr.com/forgot'>
                      Forgot Your Password?
                    </Link>
                  </FormGroup>
                </Form>
              </FormWrapper>
              <animated.div style={spinnerAnimation}>
                <SpinnerContainer>
                  <Spinner />
                </SpinnerContainer>
              </animated.div>
              <animated.div style={completeAnimation}>
                <SpinnerContainer>
                  <Checkmark />
                </SpinnerContainer>
              </animated.div>
            </>
          )}
        />
      </LoginContainer>
    </ModalContainer>
  );
};

ModalLogin.propTypes = {
  setModal: PropTypes.func.isRequired,
};

export default ModalLogin;
