import styled from '@emotion/styled';
import { typography, color, space } from 'styled-system';

const ButtonText = styled('p')`
  color: ${(props) => (props.txtColor)};
  ${color}
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.fontSize)};
  font-weight: 900;
  letter-spacing: 1.5px;
  margin: 0px;
  ${space}
  ${typography}
`;

export default ButtonText;
