/* eslint-disable no-nested-ternary */
// component that displays the list of the current days workout items
// for the current athlete, which is currentUser if not an admin
// and the selected athlete if the currentUser is an admin
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import Shimmer from 'react-shimmer-effect';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
// import PropTypes from 'prop-types';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ShimmerWorkoutsListItem from './ShimmerWorkoutsListItem';
import Title from '../../../../shared/components/Title/Title';
import WorkoutBreakSection from './WorkoutBreakSection';

import {
  fetchWorkoutPrograms,
  fetchWorkoutProgramWorkoutsOnLoad,
  getWorkoutItemsForUserByDate,
  setAdditionalWorkoutsData,
  setIsDayCarousel,
  setIsProgramInformationModalShowing,
  setIsProgramListBoxModalShowing,
  setIsCompleteWorkoutModalShowing,
  setIsSkipWorkoutModalShowing,
} from '../../ducks/workoutsActions';
import StandardWorkoutListItem from './StandardWorkoutListItem';

const WorkoutsListContainer = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
`;

const NoWorkoutDiv = styled('div')`
  display: flex;
  justify-content: center;
  font-family: "Nunito Sans";
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
`;

const DayTitle = styled('div')`
	display: flex;
  margin-left: 15px;
  flex-direction: column;
`;

const DayTitleText = styled('div')`
  font-weight: 700;
  font-family: 'Nunito Sans';
  font-size: 18px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LoadingTitle = styled('div')`
  display: flex;
  height: 30px;
  font-weight: 700;
  font-family: 'Nunito Sans';
  align-items: center;
  width: 200px;
  border-radius: 24px;
  margin-bottom: 20px;
`;

const BlankBoardContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: 15px;
`;

const TitleAndToggle = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const IconContainer = styled('div')`
  transform: rotate(90deg);
  cursor: pointer;
`;

const RelativeContainer = styled('div')`
  position: relative;
`;

const ViewProgramsMenu = styled('div')`
  position: absolute;
  width: 250px;
  background: white;
  box-shadow: ${BoxShadow};
  right: 0;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  z-index: 100;
`;

const ViewProgramsMenuOption = styled('div')`
  width: 100%;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: ${(props) => (props.addBorderBottom ? '1px solid black' : 'none')};
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background: #f5f5f5;
  }
`;

const ViewProgramsMenuText = styled('div')`
  font-size: 14px;
  font-family: 'Nunito Sans';
  margin-left: 15px;
`;

const Underlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
`;

const InfoIconCircle = styled('div')`
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusAndText = styled('div')`
  display: flex;
  align-items: center;
  height: 21px;
`;

const ProgramStatusCircle = styled('div')`
  border-radius: 50%;
  background: ${(props) => props.color};
  border: ${(props) => (!props.started ? '1px solid grey' : `1px solid ${props.color}`)};
  width: 14px;
  height: 14px;
`;

const ProgramStatus = styled('div')`
  font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 14px;
  margin-left: 5px;
`;

const SelectProgramButton = styled('div')`
  width: 200px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 16px;
  background: #214eb1;
  color: white;
  box-shadow: ${BoxShadow};
  cursor: pointer;
  opacity: 0.65;
  :hover {
    opacity: 1;
  }
  transition: opacity 0.25s linear;
`;

const WorkoutsList = () => {
  const [isProgramListMenuShowing, setIsProgramListMenuShowing] = useState(false);

  const workoutItems = useSelector((state) => state.workouts.data.currentWorkout.workoutItems);
  const workoutPrograms = useSelector((state) => state.workouts.data.workoutPrograms);
  const additionalWorkoutsData = useSelector((state) => state.workouts.data.additionalWorkoutsData);
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const hiddenWorkoutsToggle = useSelector((state) => state.workouts.ui.hiddenWorkoutsToggle);
  const activeDayInfo = useSelector((state) => state.workouts.data.activeDayInfo);
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);
  const programDayIndex = useSelector(
    (state) => state.workouts.data.programDayIndex,
  );
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDayCarousel) {
      if (!workoutItems.length) {
        const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
        if (Object.keys(currentUser).length && !currentUser.admin) {
          // fetch if the user isn't an admin and currentUser exists
          dispatch(
            getWorkoutItemsForUserByDate(
              currentUser,
              currentUser.id,
              formattedDay,
              false,
              hiddenWorkoutsToggle,
            ),
          );
        }
      }
    } else if ((currentSelectedProgram && Object.keys(currentSelectedProgram).length)) {
      if (programDayIndex || programDayIndex === 0) {
        if (currentUser && Object.keys(currentUser).length) {
          if (currentUser.admin && sharedAthlete && Object.keys(sharedAthlete).length) {
            dispatch(fetchWorkoutProgramWorkoutsOnLoad(
              currentUser,
              currentSelectedProgram,
              programDayIndex - 1,
              sharedAthlete.id,
              currentNonDateDay,
            ));
          } else if (!currentUser.admin) {
            dispatch(fetchWorkoutProgramWorkoutsOnLoad(
              currentUser,
              currentSelectedProgram,
              programDayIndex - 1,
              currentUser.id,
              currentNonDateDay,
            ));
          }
        }
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isDayCarousel) {
      if (additionalWorkoutsData.refreshWorkoutAfterCompleted) {
        const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
        if (Object.keys(currentUser).length && !currentUser.admin) {
          // fetch if the user isn't an admin and currentUser exists
          dispatch(
            getWorkoutItemsForUserByDate(
              currentUser,
              currentUser.id,
              formattedDay,
              true,
            ),
          );
        } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
          // Fetch if the current user is an coach, but only if an athlete is selected
          dispatch(
            getWorkoutItemsForUserByDate(
              currentUser,
              sharedAthlete.id,
              formattedDay,
              true,
            ),
          );
        }
        dispatch(setAdditionalWorkoutsData({}));
      }
    }
  }, [additionalWorkoutsData]);

  const idGenerator = (item) => {
    if (item.assignedId) {
      return item.assignedId;
    }
    return item.saveDataId;
  };

  const indexGenerator = (dictionary, item) => {
    let idxPass;
    if (item.assignedId) {
      idxPass = dictionary[item.assignedId];
    } else if (item.saveDataId) {
      idxPass = dictionary[item.saveDataId];
    }
    return idxPass;
  };

  const workoutsMapper = (array) => {
    const itemDictionary = {};
    let idx = 0;
    array.forEach((item) => {
      if (item.type !== 'BR') {
        const itemId = idGenerator(item);
        itemDictionary[itemId] = idx;
        idx += 1;
      }
      if (item.type === 'BR') {
        const itemId = idGenerator(item);
        itemDictionary[itemId] = idx;
        idx += 1;
        item.sessionBreakItems?.forEach((sessionBreakItem) => {
          const sessionBreakItemId = idGenerator(sessionBreakItem);
          itemDictionary[sessionBreakItemId] = idx;
          idx += 1;
        });
      }
    });
    const returnArray = [];
    array.forEach((item, index) => {
      if (item.type !== 'BR') {
        returnArray.push(
          <StandardWorkoutListItem
            workoutItem={item}
            index={indexGenerator(itemDictionary, item)}
            showVerticalLine={array[index + 1] && array[index + 1]?.type !== 'BR'}
          />,
        );
      } else if (item.type === 'BR') {
        returnArray.push(
          <WorkoutBreakSection
            sectionHeader={item}
            section={item.sessionBreakItems}
            itemDictionary={itemDictionary}
          />,
        );
      }
    });
    return returnArray;
  };

  const programSelectTriggerFunction = () => {
    setIsProgramListMenuShowing(false);
    dispatch(setIsProgramListBoxModalShowing(true));
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    if (Object.keys(currentUser).length && !currentUser.admin) {
      // fetch if the user isn't an admin and currentUser exists
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          currentUser.id,
          formattedDay,
        ),
      );
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      // Fetch if the current user is an coach, but only if an athlete is selected
      dispatch(
        fetchWorkoutPrograms(
          currentUser,
          sharedAthlete.id,
          formattedDay,
        ),
      );
    }
  };

  /**
   * this is a function that maps over the array of workout items and returns
   * list items that display the necessary details of the workout item.
   * If there is no workoutItems array and an athlete hasn't been selected and
   * the user is an admin, then it prompts the user to select an athlete, otherwise it,
   * displays the 'no workout' text
   */
  const workoutItemMapper = (
    workoutItemsArray,
  ) => {
    if (!isWorkoutsListLoading && Object.keys(currentUser).length) {
      if (workoutItemsArray.length) {
        return workoutsMapper(workoutItemsArray);
      }
      if (currentUser.admin) {
        if (!Object.keys(sharedAthlete).length) {
          return (
            <NoWorkoutDiv>
              <BlankBoardContainer>
                <Title
                  icon='lift'
                >
                  Please Select An Athlete
                </Title>
              </BlankBoardContainer>
            </NoWorkoutDiv>
          );
        }
        if (Object.keys(sharedAthlete).length) {
          return (
            <NoWorkoutDiv>
              <BlankBoardContainer>
                <Title
                  icon='lift'
                >
                  {!isDayCarousel ? 'No Workout for the Selected Day' : 'Please Select a Program'}
                </Title>
                {workoutPrograms.length ? (
                  <SelectProgramButton onClick={() => programSelectTriggerFunction()}>
                    View Program Library
                  </SelectProgramButton>
                ) : null}
              </BlankBoardContainer>
            </NoWorkoutDiv>
          );
        }
      }
      if (!currentUser.admin) {
        if (!workoutItemsArray.length) {
          return (
            <NoWorkoutDiv>
              <BlankBoardContainer>
                <Title
                  icon='lift'
                >
                  {!isDayCarousel ? 'No Workout for the Selected Day' : 'Please Select a Program'}
                </Title>
              </BlankBoardContainer>
              <SelectProgramButton onClick={() => programSelectTriggerFunction()}>
                View Program Library
              </SelectProgramButton>
            </NoWorkoutDiv>
          );
        }
      }
    }
    return null;
  };

  // This is a mapper that creates 10 shimmers with workoutitem dimensions
  const shimmerItemMapper = (
    workoutItemsArray,
  ) => workoutItemsArray.map(
    (idx) => (
      <ShimmerWorkoutsListItem
        showVerticalLine
        index={idx}
      />
    ),
  );

  const isActiveUserSelected = () => {
    if (Object.keys(currentUser).length && (!currentUser.admin || (currentUser.admin
      && Object.keys(sharedAthlete).length))) {
      return true;
    }
    return false;
  };

  const titleGrabber = () => {
    if (isActiveUserSelected()) {
      if (!isDayCarousel) {
        if (Object.keys(activeDayInfo).length) {
          if (activeDayInfo?.title === null) {
            return moment(activeWorkoutDate).format('MMMM D, YYYY');
          }
          return activeDayInfo.title;
        }
        if (activeWorkoutDate) {
          return moment(activeWorkoutDate).format('MMMM D, YYYY');
        }
        return 'Select a day';
      }
      if (isDayCarousel) {
        return `${currentNonDateDay.title || currentNonDateDay.dayTitle || ''}`;
      }
      return 'Select a day';
    }
    return '';
  };

  // determines whether to run the item mapper or the shimmer mapper
  return (
    <WorkoutsListContainer>
      {!isWorkoutsListLoading
        ? (
          <>
            <TitleAndToggle>
              <DayTitle>
                <DayTitleText>
                  {titleGrabber()}
                </DayTitleText>
                {isActiveUserSelected() && isDayCarousel ? (
                  <StatusAndText>
                    {' '}
                    <ProgramStatusCircle
                      started={currentNonDateDay?.status !== 'Not Started'}
                      color={currentNonDateDay?.statusColorCode}
                    />
                    <ProgramStatus>
                      {currentNonDateDay?.status}
                    </ProgramStatus>
                  </StatusAndText>
                ) : null}
              </DayTitle>
              <>
                {isProgramListMenuShowing
                  ? <Underlay onClick={() => setIsProgramListMenuShowing(false)} />
                  : null}
                {isDayCarousel
                && isActiveUserSelected()
                && Object.keys(currentSelectedProgram).length ? (
                  <RelativeContainer>
                    <IconContainer
                      onClick={() => setIsProgramListMenuShowing(!isProgramListMenuShowing)}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={20}
                        icon='dots'
                        color='black'
                      />
                    </IconContainer>
                    {isProgramListMenuShowing ? (
                      <ViewProgramsMenu>
                        <ViewProgramsMenuOption
                          onClick={() => {
                            setIsProgramListMenuShowing(false);
                            dispatch(setIsCompleteWorkoutModalShowing(true));
                          }}
                        >
                          <IcomoonReact
                            iconSet={iconSet}
                            size={20}
                            icon='checkmark'
                            color='black'
                          />
                          <ViewProgramsMenuText>
                            Mark as Complete
                          </ViewProgramsMenuText>
                        </ViewProgramsMenuOption>
                        <ViewProgramsMenuOption
                          onClick={() => {
                            dispatch(setIsSkipWorkoutModalShowing(true));
                            setIsProgramListMenuShowing(false);
                          }}
                        >
                          <IcomoonReact
                            iconSet={iconSet}
                            size={20}
                            icon='skip'
                            color='black'
                          />
                          <ViewProgramsMenuText>
                            Skip Workout
                          </ViewProgramsMenuText>
                        </ViewProgramsMenuOption>
                        {currentSelectedProgram.description ? (
                          <ViewProgramsMenuOption
                            onClick={() => {
                              dispatch(setIsProgramInformationModalShowing(true));
                              setIsProgramListMenuShowing(false);
                            }}
                          >
                            <InfoIconCircle>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={12}
                                icon='info'
                                color='black'
                              />
                            </InfoIconCircle>
                            <ViewProgramsMenuText>
                              Program Info
                            </ViewProgramsMenuText>
                          </ViewProgramsMenuOption>
                        ) : null}
                      </ViewProgramsMenu>
                    ) : null}
                  </RelativeContainer>
                  ) : null}
              </>
            </TitleAndToggle>
            {workoutItemMapper(workoutItems)}
          </>
        )
        : (
          <>
            <Shimmer><LoadingTitle /></Shimmer>
            {shimmerItemMapper([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])}
          </>
        )}
    </WorkoutsListContainer>
  );
};

WorkoutsList.propTypes = { };

export default WorkoutsList;
