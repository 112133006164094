import React, {
  Fragment, useEffect, useState, useRef,
} from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
// import useWeightroomSettings from '../hooks/useWeightroomSettings';

import { BorderRadius, BoxShadow, TransitionGlobal } from '../GlobalStyles';

// injectGlobal`
//   .ReactModal__Content  {
//     display: flex;
//     height: 100vh;
//     width: 100vw;
//     background-color: rgba(216, 216, 216, 0.5);
//     align-items: center;
//     position: absolute;
//     z-index: 100;
//     margin-top: -50px;
//   }
//   .ReactModal__Overlay {
//     display: flex;
//     width: 50vw;
//     min-height: 400px;
//     margin-left: 25vw;
//     background-color: white;
//     border-radius: ${BorderRadius};
//     box-shadow: ${BoxShadow};
//   }
// `;

// const ModalStyle = {
//   overlay: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: 'rgba(255, 255, 255, 0.75)'
//   },
//   content: {
//     position: 'absolute',
//     top: '40px',
//     left: '40px',
//     right: '40px',
//     bottom: '40px',
//     border: '1px solid #ccc',
//     background: '#fff',
//     overflow: 'auto',
//     WebkitOverflowScrolling: 'touch',
//     borderRadius: '4px',
//     outline: 'none',
//     padding: '20px',
//   },
// };

const ModalStyle = {
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    top: '0',
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '50vw',
    minHeight: '400px',
    minWidth: '530px',
    backgroundColor: 'white',
    borderRadius: `${BorderRadius}`,
    boxShadow: `${BoxShadow}`,
  },
};

export const ModalContentWrapper = styled('div')`
  display: flex;
  padding: 40px;
  flex-grow: 1;
  background-color: ${(props) => props.theme.backgroundColorL2};
`;

const Modal = (props) => {
  const [modalDimensions, setModalDimensions] = useState({});
  const modalContentRef = useRef(null);
  // const { isDarkThemeEnabled } = useWeightroomSettings();

  const { children, isOpen } = props;

  const modalClose = () => {
    const { onRequestClose } = props;
    onRequestClose();
  };

  // To make the modal scrollable when the window height is small, we save the
  // modal dimensions on isOpen, and set the height dynamically in a css media query
  // where we make the modal absolute position, and overflow auto.
  useEffect(() => {
    setTimeout(() => {
      // Logger.debug('Modal height:', modalContentRef.current.clientHeight);
      setModalDimensions(modalContentRef?.current?.clientHeight);
    }, 800);
  }, [isOpen]);

  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);
            &:focus {
              outline: -webkit-focus-ring-color auto 0px!important;
            }
          }

          .ReactModal__Overlay {
            background-color: rgba(216, 216, 216, 0.5);
            /* background-color: ${isDarkThemeEnabled ? 'rgba(30, 30, 30, 0.7)' : 'rgba(216, 216, 216, 0.5)'}!important; */
            opacity: 0;
            z-index: 1000!important;
            justify-content: center;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .ReactModal__Content {
              width: 75vw!important;
            } 
          }

          @media screen and (max-height: ${modalDimensions}px) {
            .ReactModal__Content {
              position: absolute!important;
              top: 15px!important;
            }
          }


          @media only screen and (max-device-width: 767px) {
            .ReactModal__Content {
              min-width: 95%!important;
            }
          }
        `}
      />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={isOpen}
        onRequestClose={modalClose}
        style={ModalStyle}
        className='global-modal'
        overlayClassName='overlay'
      >
        <ModalContentWrapper
          ref={modalContentRef}
        >
          {children}
        </ModalContentWrapper>
      </ReactModal>
    </>
  );
};

if (process.env.STORYBOOK_RUNNING === undefined) {
  ReactModal.setAppElement('#app');
}

Modal.propTypes = {
  children: PropTypes.node,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  children: '',
};

export default Modal;
