// list item with the same dimensions as a workout list item but shimmers
// for the loading effect
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

const WorkoutItemDiv = styled('div')`
	display: flex;
	cursor: pointer;
`;

const IconTitle = styled('div')`
	display: flex;
`;

const TitleDesc = styled('div')`
	width: 325px;
`;

const Title = styled('div')`
	font-size: 16px;
  border-radius: 24px;
`;

const Subtitle = styled('div')`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
	font-weight: 700;
  border-radius: 24px;
`;

const IconContainer = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid none;
	width: 30px;
	height: 30px;
`;

const IconAndLine = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	align-items: center;
`;

const WorkoutsListItem = () => (
  <WorkoutItemDiv>
    <IconAndLine>
      <Shimmer>
        <IconContainer />
      </Shimmer>
      <Shimmer>
        <div style={{
          height: '50px', width: '2.5px',
        }}
        />
      </Shimmer>
    </IconAndLine>
    <IconTitle>
      <TitleDesc>
        <Shimmer>
          <Title>
            <div style={{
              opacity: 0,
            }}
            >
              Dummy text for div height
            </div>
          </Title>
        </Shimmer>
        <Shimmer>
          <Subtitle>
            <div style={{
              opacity: 0,
            }}
            >
              dummy text for div height
            </div>
          </Subtitle>
        </Shimmer>
      </TitleDesc>
    </IconTitle>
  </WorkoutItemDiv>
);

export default WorkoutsListItem;
