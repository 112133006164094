import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const Container = styled('div')`
	width: 50px;
	background-color: ${(props) => (props.isSelected ? '#19AB27' : '#4D4D4D')};
	cursor: pointer;
	user-select: none;
	border-radius: 30px;
	height: 24px;
	position: relative;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;

	.dialog-button {
		line-height: 16px;
		cursor: pointer;
		background-color: #FAFAFA;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		box-sizing: border-box;
		position: absolute;
		left: 28px;
		transition: all 0.3s ease;
	}

	.disabled {
		left: 2px;
	}
`;

/**
 *
 * @param {Function} onChange function called when the toggle is switched
 * @param {Boolean} checked determines the current state of the toggle
 * (flipped or not flipped)
 * @returns a custom toggle component that's more performant than the NPM
 * react-toggle component
 */

const TBToggleSwitch = ({ onChange, checked }) => {
  // default state of the toggle is determined by this boolean
  const [selected, setSelected] = useState(checked);
  // fortification to prevent toggle from being flipped when it's loaded in
  const [firstLoad, setFirstLoad] = useState(true);

  /**
   * fires when toggle is flipped.  Waits .2 seconds before firing
   * dispatch so as to allow the animation to complete before the state updates
   */

  useEffect(() => {
    if (!firstLoad) {
      setTimeout(() => {
        onChange();
      }, 200);
    }
  }, [selected]);

  /**
   * fires once when the component loads and sets firstLoad to false
   * so that the above useEffect can do its work when toggle is flipped
   */
  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, []);


  return (
    <Container
      isSelected={selected}
      onClick={() => {
        setSelected(!checked);
      }}
    >
      {/**
       * brings the toggle under focus
       */}
      <div tabIndex='-1' className={`dialog-button ${selected ? 'enabled' : 'disabled'}`} />
    </Container>
  );
};

TBToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default TBToggleSwitch;
