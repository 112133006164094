// component that receives a workout item and transcribes the associated info
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';

import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  setIsWorkoutsCarouselShowing,
} from '../../ducks/workoutsActions';

const WorkoutItemDiv = styled('div')`
	display: flex;
	cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: #34495E;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IconTitle = styled('div')`
	display: flex;
  max-width: 100%;
  width: 100%;
  color: white;
`;

const TitleDesc = styled('div')`
	max-width: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const Title = styled('div')`
	font-size: 16px;
  width: 100%;
  padding-right: 15px;
`;

const IconContainer = styled('div')`
	display: flex;
  position: relative;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
	width: 30px;
	height: 30px;
  background: ${(props) => (props.completed ? '#0dcc8a' : 'white')};
`;

const IconAndLine = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: 20px;
	align-items: center;
  justify-content: center;
`;

const GroupingLetterContainer = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 50px;
  border-Width: 1px;
  height: 15px;
  justify-content: center;
  padding: 1.5px;
  position: absolute;
  width: 15px;
  border: ${(props) => (!props.completed ? `1px solid ${props.borderColor}` : '#0dcc8a')};
  background: ${(props) => (props.borderColor)};
  right: -3px;
  bottom: -3px;
`;

const GroupingLetter = styled('div')`
	color: ${(props) => props.color};
  font-size: 9px;
  font-weight: 500;
`;

const ChevronContainer = styled('div')`
  display: flex;
  align-items: center;
`;

const Chevron = styled('div')`
  transform: ${(props) => (props.isRotated ? 'rotate(270deg)' : 'rotate(90deg)')};
  transition: transform 0.5s ease;
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
`;

const WorkoutListBreak = ({
  workoutItem,
  index,
  setIsExpanded,
  isExpanded,
  sectionHeader,
  section,
}) => {
  const dispatch = useDispatch();

  const [rotated, setRotated] = useState(isExpanded);

  const [sectionFullyCompleted, setSectionFullyCompleted] = useState(false);

  useEffect(() => {
    let fullyCompleted = true;
    let sectionCompletable = false;
    section.forEach((item) => {
      if (item.completeableItem) {
        if (!sectionCompletable) {
          sectionCompletable = true;
        }
        if (!item.fullyCompleted) {
          fullyCompleted = false;
        }
      }
    });
    if (sectionCompletable && fullyCompleted) {
      setSectionFullyCompleted(true);
    }
  }, [section]);

  useEffect(() => {
    setRotated(isExpanded);
  }, [isExpanded]);

  const theme = useTheme();

  const getMediaEntries = () => {
    let media = [];
    if (workoutItem.journalEntries && workoutItem.journalEntries.length) {
      workoutItem.journalEntries.forEach((journalEntry) => {
        if (journalEntry.media && journalEntry.media.length) {
          media = media.concat(journalEntry.media);
        }
      });
    }
    return media;
  };

  const getJournalText = () => (
    workoutItem.journalEntries
      && workoutItem.journalEntries.length
      && workoutItem.journalEntries[0].body
      ? workoutItem.journalEntries[0].body.replace(/[\r\n]+/g, ' ')
      : ''
  );

  // const getTopLineHeight = () => {
  //   const hasMedia = getMediaEntries(workoutItem).length;
  //   const journalText = getJournalText();
  //   // console.log(journalText);
  //   // /**
  //   //  * Because there are so many different variations of things that we show
  //   //  * for any given workout item, we are defining custom heights for the vertical separator
  //   //  * line for each scenario here. We can show the following in a workout item: media,
  //   //  * journal text, subtitle, title. We will base the vertical line height from those.
  //   //  */
  //   // if (hasMedia) {
  //   //   if (journalText) {
  //   //     if (!workoutItem.subTitle) {
  //   //       // has media + journal text, but no sub title
  //   //       return 120;
  //   //     }
  //   //     // has media + journal text + sub title
  //   //     return 120;
  //   //   }
  //   //   // has media but no journal text
  //   //   return 110;
  //   // }
  //   // if (journalText) {
  //   //   // no media, but has journal text
  //   //   return 40;
  //   // }
  //   // default
  //   return 0;
  // };

  return (
    <>
      <WorkoutItemDiv
        onClick={() => {
          if (!sectionHeader) {
            dispatch(setIsWorkoutsCarouselShowing(true, index));
          } else {
            setIsExpanded(!isExpanded);
            setRotated(!rotated);
          }
        }}
      >
        <IconAndLine>
          {getMediaEntries(workoutItem).length || workoutItem?.subTitle ? (
            <div style={{
            // height: `${getTopLineHeight()}px`, width: '2px', background: 'lightgrey', opacity: index === 0 ? 0 : 1,
            }}
            />
          ) : null}
          <IconContainer borderColor={workoutItem?.groupingColorCode || 'white'} completed={sectionFullyCompleted}>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon={sectionFullyCompleted ? 'checkmark' : workoutItem.icon}
              color={sectionFullyCompleted ? 'white' : 'black'}
            />
            {workoutItem.groupingColorCode && workoutItem.groupingLetter && (
            <GroupingLetterContainer borderColor={workoutItem?.groupingColorCode || 'black'} completed={sectionFullyCompleted}>
              <GroupingLetter
                color={theme.colors.background[0]}
              >
                {workoutItem.groupingLetter}
              </GroupingLetter>
            </GroupingLetterContainer>
            )}
          </IconContainer>
        </IconAndLine>
        <IconTitle>
          <TitleDesc>
            <Title>
              {workoutItem.title}
            </Title>
            <SubText>
              {workoutItem?.subTitle?.toUpperCase()}
            </SubText>
          </TitleDesc>
        </IconTitle>
        <ChevronContainer>
          <Chevron isRotated={rotated}>
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon='altrightarrow'
              color='white'
            />
          </Chevron>
        </ChevronContainer>
      </WorkoutItemDiv>
    </>
  );
};

WorkoutListBreak.propTypes = {
  workoutItem: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  sectionHeader: PropTypes.instanceOf(Object).isRequired,
  section: PropTypes.instanceOf(Array).isRequired,
};

export default WorkoutListBreak;
