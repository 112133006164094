// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone';
// import DatePicker from 'react-datepicker';
import moment from 'moment';
import IcomoonReact from 'icomoon-react';
import Select from 'react-select';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

import NoHoverButton from './NoHoverButton';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import Text from '../../../shared/components/Text/Text';
import useConversations from '../components/hooks/useConversations';
import Spinner from '../../../shared/components/Spinner/Spinner';
import Button from '../../../shared/components/Button/Button';
import { setIsScheduledOpen } from '../ducks/conversationsActions';
import ActionButton from '../../../shared/components/ActionButton/ActionButton';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    display: flex;
    align-items: center;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const TitlePlusErrorWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 5px;

  .formik-form {
    width: 100%;
  }

  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    width: 100%;
  }
  .first-stage-select {
    width: 100%;
  }

  .checkbox-label {
    display: flex;
    margin-top: 10px;
  }

  .checkbox {
    margin-right: 10px;
    margin-top: 3px;
  }
`;

const TextFieldAndCounterWrapper = styled('div')`
  width: 100%;
  .counter {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 5px;
    padding-right: 7px;
    margin-top: -22px;
    font-size: 12px;
    min-height: 18px;
  }
`;

const DropZoneWrapper = styled('div')`
  display: flex;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  margin-top: 10px;
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }

  .update-button {
    width: 180px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const EmptyDropZoneWrapper = styled('div')`
  width: 160px;
  height: 55px;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const DateTimePickerWrapper = styled('div')`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: flex-start;

  button {
    margin-left: 5px;
  }
`;

const DropZoneDivAndRemove = styled('div')`
  margin-top: 15px;
  width: 80px;
  display: flex;
  flex-direction: column;
  .remove {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

const DropZoneDivUploadedFail = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 55px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 160px;
  height: 55px;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .file-icon-text-uploaded-fail {
    font-size: 12px;
    color: red;
  }
  .file-icon-text-uploaded-fail-restart {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const Option = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  width: 210px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 0px;
  margin-top: 30px;

  p {
    color: ${(props) => props.theme.colors.text};
  }
  /* @media screen and (max-width: 1100px) {
    margin-left: 5px;
  }
  @media screen and (max-width: 960px) {
    width: 120px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (max-width: 540px) {
    width: 100px;
    min-width: 80px;
    border: 0px;
    background: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
  } */
`;

const CreateProgramSlide2 = ({
  formProps,
  selectedOptions,
  setIsMediaUploaded,
  isMediaUploaded,
  isGroupSelected,
}) => {
  console.log(formProps);
  const {
    handleUploadMedia,
  } = useConversations();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const uploadedMedia = useSelector((state) => state.conversations.data.uploadMedia);
  const uploadedMediaError = useSelector((state) => state.conversations.data.uploadMediaError);
  const mediaIsUploading = useSelector((state) => state.conversations.ui.isMediaUploading);
  const newConversation = useSelector((state) => state.conversations.data.newConversation);
  const [isScheduleSelected, setScheduleSelected] = useState(false);

  const dispatch = useDispatch();

  const handleDroppedFile = (acceptedFile) => {
    const type = acceptedFile[0].type.split('/').shift();
    handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile, type });
    dispatch(setIsMediaUploaded(true));
  };

  const DropZoneDiv = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 75px;
    color: #495057;
    background-image: url(${(uploadedMedia && isMediaUploaded) ? uploadedMedia.mediaUrl : null});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid #ced4da;
    border-radius: 50%;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '40%',
      marginLeft: '40px',
    }),
  };

  const fileAccepterSwitch = () => {
    if (!mediaIsUploading) {
      if (uploadedMediaError && isMediaUploaded) {
        return (
          <DropZoneDivUploadedFail>
            <Text className='file-icon-text-uploaded-fail'>Something went wrong</Text>
            <Text
              onClick={() => setIsMediaUploaded(false)}
              className='file-icon-text-uploaded-fail-restart'
            >
              Try Again
            </Text>
          </DropZoneDivUploadedFail>
        );
      }
      if (!uploadedMediaError && isMediaUploaded && uploadedMedia) {
        return (
          <DropZoneDivAndRemove>
            <div className='remove'>
              <div
                style={{
                  cursor: 'pointer',
                  marginBottom: '-15px',
                }}
                onClick={() => dispatch(setIsMediaUploaded(false))}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='remove'
                />
              </div>
            </div>
            <DropZoneDiv />
          </DropZoneDivAndRemove>
        );
      }
      return (
        <Dropzone onDrop={(acceptedFile) => {
          handleDroppedFile(acceptedFile);
        }}
        >
          {({ getRootProps, getInputProps }) => (
            <DropZoneWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              <ActionButton
                href=''
                icon='attachment'
                small
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
                customColor='rgb(240, 240, 240)'
                customColorIcon='rgb(68, 68, 68'
              />
            </DropZoneWrapper>
          )}
        </Dropzone>
      );
    }
    if (mediaIsUploading) {
      return (
        <EmptyDropZoneWrapper>
          <Spinner
            saving
            darkTheme
          />
        </EmptyDropZoneWrapper>
      );
    }
  };

  const RecipientsWrapper = styled('div')`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    p {
      margin-right: 5px;
    }
  `;

  const Recipient = styled('div')`
    font-size: 14px;
    background: hsl(0,0%,90%);
    border-radius: 2px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: hsl(0,0%,20%);
    //List fix
    white-space: nowrap;
    margin-bottom: 5px;
    margin-right: 10px;
  `;

  const recipientsParser = (memberGroupingType) => {
    const newArray = [];

    formProps.values.selectedRecipients.forEach((recipient) => {
      newArray.push(
        <Recipient>
          {memberGroupingType === 1
            ? `${recipient.first} ${recipient.last.charAt(0)}.`
            : recipient.name}
        </Recipient>,
      );
    });
    return newArray;
  };

  const recipients = () => {
    const { memberGroupingType } = formProps.values;

    if (memberGroupingType === 6) {
      return (<Recipient>All Users</Recipient>);
    }
    if (memberGroupingType === 5) {
      return (<Recipient>All Coaches</Recipient>);
    }
    if (memberGroupingType === 4) {
      return (<Recipient>All Athletes</Recipient>);
    }
    return recipientsParser(memberGroupingType);
  };

  const MessageTypeWrapper = styled('div')`
    display: flex;
    align-items: center;
    margin-top: 10px;
  `;

  const MessageType = styled('div')`
    display: flex;
    align-items: center;
    width: 80px;
    margin-left: 15px;
    .type-text {
      font-size: 11px;
      margin-left: 5px;
    }
  `;

  const messageTypeParser = () => {
    const array = [];
    if (selectedOptions.sms) {
      array.push(
        <MessageType>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='phone'
            color='grey'
          />
          <Text className='type-text'>
            Text (SMS)
          </Text>
        </MessageType>,
      );
    }
    if (selectedOptions.tbm) {
      array.push(
        <MessageType>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='message-bubbles'
            color='grey'
          />
          <Text className='type-text'>
            Message
          </Text>
        </MessageType>,
      );
    }
    if (selectedOptions.em) {
      array.push(
        <MessageType>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='envelope-closed'
            color='grey'
          />
          <Text className='type-text'>
            Email
          </Text>
        </MessageType>,
      );
    }
    if (array.length) {
      return array;
    }
    return null;
  };

  return (
    <ModalWrapper>
      <AnotherWrapper>
        <OuterFolderFormWrapper>
          <InnerFolderFormWrapper>
            <Form className='formik-form'>
              <RecipientsWrapper>
                <Text
                  className='formgroup-text'
                >
                  Recipients:
                </Text>
                {recipients()}
              </RecipientsWrapper>
              <MessageTypeWrapper>
                <Text
                  className='message-type-text'
                >
                  Message Type:
                </Text>
                {' '}
                {messageTypeParser()}
              </MessageTypeWrapper>
              <MessageTypeWrapper>
                <Text
                  className='message-type-text'
                >
                  Type:
                  {' '}
                  {isGroupSelected ? 'Group Message' : 'Individual Messages'}
                </Text>
              </MessageTypeWrapper>
              <FormGroup>
                <Text
                  className='formgroup-text'
                  style={{
                    marginBottom: '10px',
                    marginTop: '10px',
                  }}
                >
                  Message:
                </Text>
              </FormGroup>
              <TextFieldAndCounterWrapper>
                <TextField
                  variant='outlined'
                  fullWidth
                  multiline
                  rows={5}
                  inputProps={{ maxLength: selectedOptions.sms ? 120 : 20000 }}
                  onChange={(e) => {
                    // If SMS is selected, limit characters to 120.
                    if (selectedOptions.sms) {
                      if (formProps.values.message.length <= 120) {
                        formProps.setFieldValue('message', e.target.value);
                      }
                    } else {
                      formProps.setFieldValue('message', e.target.value);
                    }
                  }}
                />
                <div className='counter'>
                  {selectedOptions.sms ? (
                    `${formProps.values.message.length}/120 for SMS`
                  ) : ' '}
                </div>
              </TextFieldAndCounterWrapper>
              {fileAccepterSwitch()}
              {!isScheduleSelected ? (
                <Option
                  onClick={() => {
                    setScheduleSelected(true);
                  }}
                >
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='clock'
                  />
                  <Text style={{
                    marginLeft: '10px',
                  }}
                  >
                    Schedule Message
                  </Text>
                </Option>
              ) : (
                <>
                  <DateTimePickerWrapper>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={25}
                      icon='clock'
                    />
                    <div style={{
                      marginLeft: '15px',
                      width: '70%',
                    }}
                    >
                      <DatePicker
                        style={{
                          width: '100%',
                        }}
                        size='large'
                        allowClear
                        format='MMMM Do, h:mm:ss a'
                        showTime
                        onOk={(dateTime) => {
                          formProps.setFieldValue('scheduledDeliveryTime', dateTime);
                        }}
                        placeholder='Schedule a time to send the message'
                        showToday
                        disabledDate={(date) => {
                          const today = new Date();
                          const yesterday = new Date(today);
                          yesterday.setDate(yesterday.getDate() - 1);
                          return date <= yesterday;
                        }}
                      />
                    </div>
                    <Button
                      icon='remove'
                      iconOnly
                      rounded
                      onClick={() => {
                        setScheduleSelected(false);
                      }}
                      type='button'
                    />
                  </DateTimePickerWrapper>
                </>
              )}
            </Form>
          </InnerFolderFormWrapper>
        </OuterFolderFormWrapper>
      </AnotherWrapper>
    </ModalWrapper>
  );
};

CreateProgramSlide2.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  selectedOptions: PropTypes.instanceOf(Object).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  page2Errors: PropTypes.bool.isRequired,
};

export default CreateProgramSlide2;
