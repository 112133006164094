/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { TransitionGlobal, Nunito } from '../../../../shared/GlobalStyles';
import OptionsBox from './OptionsBox';
import ConversationBox from './ConversationBox';
import Notebox from './NoteBox';
import { setSelectedConversationsRedux } from '../../ducks/conversationsActions';
import { setSelectedNotificationsRedux } from '../../ducks/notificationsActions';

const SidebarContainer = styled('aside')`
  height: 100%;
  width: 40%;
  max-width: 400px;
  min-width: 400px;
  left: 0px;
  background: #F5F5F5;
  transition: ${TransitionGlobal};  
  overflow: hidden;

  @media screen and (max-width: 1100px) {
    max-width: 300px;
    min-width: 300px;
  }

  @media screen and (max-width: 767px) {
    min-width: ${(props) => (props.isMobileSidebarOpen ? '100%' : '0%')};
    max-width: ${(props) => (props.isMobileSidebarOpen ? '100%' : '0%')};
  }

`;

const ConversationsSidebar = ({
  sidebarSwitchConvos,
  setSidebarSwitchConvos,
}) => {
  const dispatch = useDispatch();

  const [isCheckboxesOpen, setIsCheckboxesOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const isMobileSidebarOpen = useSelector((state) => state.conversations.ui.isMobileSidebarOpen);

  const setSelectedConvos = (convos) => {
    dispatch(setSelectedConversationsRedux(convos));
  };

  const setSelectedNotifications = (notifications) => {
    dispatch(setSelectedNotificationsRedux(notifications));
  };

  return (
    <SidebarContainer
      isMobileSidebarOpen={isMobileSidebarOpen}
    >
      <OptionsBox
        sidebarSwitchConvos={sidebarSwitchConvos}
        isCheckboxesOpen={isCheckboxesOpen}
        setSidebarSwitchConvos={setSidebarSwitchConvos}
        setIsCheckboxesOpen={setIsCheckboxesOpen}
        setSelectedConvos={setSelectedConvos}
        setSelectedNotifications={setSelectedNotifications}
        setIsHovered={setIsHovered}
      />
      {sidebarSwitchConvos ? (
        <ConversationBox
          isCheckboxesOpen={isCheckboxesOpen}
          setSelectedConvos={setSelectedConvos}
          isHovered={isHovered}
        />
      ) : (
        <Notebox
          setSelectedNotifications={setSelectedNotifications}
          isCheckboxesOpen={isCheckboxesOpen}
        />
      )}
    </SidebarContainer>
  );
};

ConversationsSidebar.propTypes = {
  sidebarSwitchConvos: PropTypes.bool.isRequired,
  setSidebarSwitchConvos: PropTypes.func.isRequired,
};

export default ConversationsSidebar;
