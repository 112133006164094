import {
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_START,
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED,
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED,
  FETCH_SHARED_WORKOUT_PREVIEW_START,
  FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED,
  FETCH_SHARED_WORKOUT_PREVIEW_REJECTED,
  SET_ACTIVE_SHARED_WORKOUT_MODAL,
  SET_IS_DOWNLOAD_BUTTON_CLICKED,
} from './actionTypes';

import {
  SUBMIT_LOGIN_FULFILLED,
} from '../../login/ducks/actionTypes';

const initialState = {
  activeModal: '',
  isDownloadButtonClicked: false,
  isLoadingProgram: false,
  isLoadingWorkout: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_START: {
      return {
        ...state,
        isLoadingProgram: true,
      };
    }
    case FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED: {
      return {
        ...state,
        isLoadingProgram: false,
      };
    }
    case FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED: {
      return {
        ...state,
        isLoadingProgram: false,
      };
    }
    case FETCH_SHARED_WORKOUT_PREVIEW_START: {
      return {
        ...state,
        isLoadingWorkout: true,
      };
    }
    case FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED: {
      return {
        ...state,
        isLoadingWorkout: false,
      };
    }
    case FETCH_SHARED_WORKOUT_PREVIEW_REJECTED: {
      return {
        ...state,
        isLoadingWorkout: false,
      };
    }
    case SET_ACTIVE_SHARED_WORKOUT_MODAL: {
      return {
        ...state,
        activeModal: action.payload.activeModal,
      };
    }
    case SUBMIT_LOGIN_FULFILLED: {
      return {
        ...state,
        activeModal: 'download',
      };
    }
    case SET_IS_DOWNLOAD_BUTTON_CLICKED: {
      return {
        ...state,
        isDownloadButtonClicked: action.payload.bool,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
