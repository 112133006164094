import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

import Text from '../../../../shared/components/Text/Text';
import SubText from '../../../../shared/components/SubText/SubText';
import Button from '../../../../shared/components/Button/Button';

import visaLogo from '../../../../shared/images/payments/dark/visaLogo.png';
import mastercardLogo from '../../../../shared/images/payments/dark/mastercardLogo.png';
import maestroLogo from '../../../../shared/images/payments/dark/maestroLogo.png';
import americanExpressLogo from '../../../../shared/images/payments/dark/americaExpressLogo.png';
import jcbLogo from '../../../../shared/images/payments/dark/jcbLogo.png';
import discoverLogo from '../../../../shared/images/payments/dark/discoverLogo.png';
import dinersLogo from '../../../../shared/images/payments/dark/dinersLogo.png';
import unionLogo from '../../../../shared/images/payments/dark/unionLogo.png';

const PaymentCardContainer = styled('div')`
  width: 100%;
  height: 94px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
  .visa-logo {
    background-image: url(${props => props.cardLogo});
    background-size: contain;
    background-repeat: no-repeat;
    width: 64px;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 3px;
  }
  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .update-button {
    background: #10CD8C;
    color: white;
    margin-bottom: 0px;
  }

  .Shimmer-shimmer-0-1-1 {
    color: transparent!important;
  }
`;

const PaymentCard = ({
  brand,
  last4,
  isLoading,
  handleUpdateButton,
}) => {
  // Accepted Payment Methods: Visa, MasterCard, Maestro, American Express, JCB, Discover, Diners Club, Union Pay
  let cardLogo = '';

  switch (brand) {
    case 'Visa': {
      cardLogo = visaLogo;
      break;
    }
    case 'MasterCard': {
      cardLogo = mastercardLogo;
      break;
    }
    case 'Maestro': {
      cardLogo = maestroLogo;
      break;
    }
    case 'American Express': {
      cardLogo = americanExpressLogo;
      break;
    }
    case 'JCB': {
      cardLogo = jcbLogo;
      break;
    }
    case 'Discover': {
      cardLogo = discoverLogo;
      break;
    }
    case 'Diners Club': {
      cardLogo = dinersLogo;
      break;
    }
    case 'Union Pay': {
      cardLogo = unionLogo;
      break;
    }
    default: {
      break;
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <PaymentCardContainer
          cardLogo={cardLogo}
        >
          <Shimmer><div className='visa-logo' /></Shimmer>
          <div className='card-info-box'>
            <Shimmer className='shimmer'>
              <Text fontWeight={300}>
                {brand}
              </Text>
            </Shimmer>
            <Shimmer>
              <SubText>
                .... .... .... {last4}
              </SubText>
            </Shimmer>
          </div>
        </PaymentCardContainer>
      ) : (
        <PaymentCardContainer
          cardLogo={cardLogo}
        >
          <div className='visa-logo' />
          <div className='card-info-box'>
            <Text fontWeight={300}>
              Visa
            </Text>
            <SubText>
              .... .... .... {last4}
            </SubText>
          </div>
          {/* <Button
            className='update-button'
            cta='Use A New Card'
            customColor='#10CD8C'
            noHover
            noBorder
            rounded
            onClick={handleUpdateButton}
          /> */}
        </PaymentCardContainer>
      )}
    </Fragment>
  );
};

export default PaymentCard;
