/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import 'react-dates/initialize';
import moment from 'moment';
import styled from '@emotion/styled';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { ColorGreen, BoxShadow } from '../../../../shared/GlobalStyles';
import ButtonRound from './ButtonRound';

import 'react-dates/lib/css/_datepicker.css';

import { fetchWorkoutCalendar } from '../../ducks/workoutsActions';

const DatePickerContainer = styled('div')`
  height: 100%;
  display: flex;
  position: relative;
    tr:first-of-type {
      .CalendarDay, .CalendarDay__selected {
        border-top: 1px solid #e4e7e7;
      }
      .CalendarDay__outside {
        border-top: 0px;
        border-bottom: 1px solid #e4e7e7;
      }
    } 
    & tr:last-of-type {
      border-right: 0px;
      .CalendarDay__outside {
        border-left: 1px solid #e4e7e7;
      }
      .CalendarDay__outside ~ .CalendarDay__outside{
        border-left: 0px solid #e4e7e7;
      }
    }
  
    .CalendarDay__selected {
      border-top: 0!important;
      border-right: 0px!important;
      border-color: #e4e7e7;
      .day-container {
        background-color: #0dcc8a!important;
      }
    }
    
    & button {
      border: 1px solid ${(props) => props.theme.noBorderColor};
      color: ${(props) => props.theme.lightTextColor};
      font-weight: 600;
      height: 100%;
    }
  
    & .CalendarMonth_caption {
      font-family: 'Nunito Sans';
      font-weight: 300;
    }
  
    & .CalendarDay {
      font-family: 'Nunito Sans';
      display: inline-flex;
      padding: 0;
      border-right: 0;
      border-top: 0;
      &:last-of-type:not(.CalendarDay__outside) {
        border-right: 1px solid #e4e7e7;
      }
      /* .CalendarDay_outside + &:last-of-type {
        border-right: 1px solid #e4e7e7;
      } */
    }
    
    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
      background: ${ColorGreen};
      border: 1px double ${ColorGreen};
      color: #fff;
    }
  
    & small {
      font-family: 'Nunito Sans';
    }
  
    .SingleDatePickerInput {
      background-color: transparent!important;
      height: 10px;
      border: 0px;
    
      svg {
        top: 10px!important;
        left: 16px!important;
      }
    }
  
    .SingleDatePicker {
      position: absolute;
    }
  
    .DayPicker__withBorder {
      box-shadow: ${BoxShadow}, 0 0 0 1px rgba(0, 0, 0, 0.07);
    }
  
    .SingleDatePicker_picker {
      top: 20px!important;
    }
    
    & .DateInput_input {
      visibility: hidden;
    }
    
    & .DateInput {
      background-color: transparent;
    }
  
    .DateInput__block {
        width: 100%;
        padding-right: 42px;
    }
  
    .SingleDatePickerInput_calendarIcon {
        position: absolute;
        right: 0;
        padding: 0 10px;
        line-height: 50px;
    }
  `;

const DayPickerWrapper = styled('div')`
    position: absolute;
    z-index: 10000;
    right: 10%;
    top: 50px;
    .CalendarMonth_caption {
      color: ${(props) => props.theme.textColor}
    }
    .DayPicker {
      background-color: ${(props) => props.theme.backgroundColorL2};
    }
    .CalendarMonthGrid, .CalendarMonth, .CalendarDay, .day-container {
      background: ${(props) => props.theme.backgroundColorL2}!important;
    }
  
    .CalendarDay__default {
      border-color: ${(props) => props.theme.borderColor}
    }
    .DayPicker tr:first-of-type .CalendarDay__outside {
      border-color: ${(props) => props.theme.borderColor};
    }
    .DayPicker tr:last-of-type .CalendarDay__outside {
      border-color: ${(props) => props.theme.borderColor};
    }
    .DayPicker tr:first-of-type .CalendarDay, .DayPicker tr:first-of-type .CalendarDay__selected {
      border-color: ${(props) => props.theme.borderColor};
    }
    .DayPicker .CalendarDay:last-of-type:not(.CalendarDay__outside) {
      border-color: ${(props) => props.theme.borderColor};
    }
    .CalendarDay__default {
      color: ${(props) => props.theme.textColor};
      /* border: 1px solid ${(props) => props.theme.borderColor}; */
    }
    .DayPickerNavigation_button__default {
      border: 1px solid ${(props) => props.theme.borderColor};
      background-color: ${(props) => props.theme.backgroundColorL3};
    }
    .circle {
      display: block;
      width: 5px;
      height: 5px;
      margin: 0 auto;
      border-radius: 50%;
      &.not-started-circle {
        background: #c0392b;
      }
      &.started-circle {
        background: #f39c12;
      }
      &.completed-circle {
        background: #1abc9c;
      }
    }
    .day-container {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  `;

const SmallScreenIconText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  color: #616161;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 41px;
  align-items: center;
  background: ${(props) => (props.isSmall ? '#F5F5F5' : '#444444')};
  border-top-right-radius: ${(props) => (props.isSmall ? '30px' : '4px')};
  border-bottom-right-radius: ${(props) => (props.isSmall ? '30px' : '4px')};
  border-top-left-radius: ${(props) => (props.isSmall ? '30px' : '4px')};
  border-bottom-left-radius: ${(props) => (props.isSmall ? '30px' : '4px')};
  cursor: pointer;
`;

const SmallIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100px;
  align-items: center;
  background: #F5F5F5;
  border-radius: 30px;
  cursor: pointer;
  justify-content: space-evenly;
  padding: 10px;
`;

const AlternativeDatePicker = ({
  activeDate,
  selectDate,
  isDayPickerOpen,
  setIsDayPickerOpen,
  isSmallScreenOption,
}) => {
  const [currentDate, setCurrentDate] = useState(moment(activeDate));
  const [currentFocused, setCurrentFocused] = useState(null);
  // const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);

  useEffect(() => {
    const newDateMoment = moment(activeDate);
    setCurrentDate(newDateMoment);
  }, [activeDate]);

  const calendarRef = React.useRef();
  const dayRef = React.useRef();
  const buttonRef = React.useRef();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );
  const workoutCalendar = useSelector(
    (state) => state.workouts.data.workoutCalendar,
  );

  const dispatch = useDispatch();

  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  const handleDateClick = () => {
    setCurrentFocused(true);
    dispatch(setIsDayPickerOpen(isDayPickerOpen !== true));
  };

  const handleDateChange = (date) => {
    dispatch(setIsDayPickerOpen(false));
    selectDate(date);
    setCurrentDate(date);
  };

  const today = moment();

  const notStartedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter((info) => (info.statusDescription === 'NOT_STARTED'))
      .map((info) => (moment(info.date)))
    : [];

  const startedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter((info) => (info.statusDescription === 'STARTED'))
      .map((info) => (moment(info.date)))
    : [];

  const completedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter((info) => (info.statusDescription === 'COMPLETED'))
      .map((info) => (moment(info.date)))
    : [];

  const highlightedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter((info) => (info.backgroundColorCode !== null))
      .map((info) => ({ date: moment(info.date), color: info.color }))
    : [];

  const fetchCalendar = () => {
    const firstMonthArray = calendarRef.current ? Object.keys(Object.entries(calendarRef.current.state.visibleDays).sort()[0][1]) : ('');
    const lastMonthArray = calendarRef.current ? Object.keys(Object.entries(calendarRef.current.state.visibleDays).sort()[2][1]) : ('');
    const startDate = calendarRef.current ? firstMonthArray[0] : ('');
    const endDate = calendarRef.current ? lastMonthArray[lastMonthArray.length - 1] : ('');
    dispatch(fetchWorkoutCalendar(currentUser, userId, startDate, endDate));
  };

  const isDayHighlighted = (day1) => (highlightedDays.some((day2) => isSameDay(day1, day2.date)));
  const isFalse = () => false;
  const onDateChange = (date) => handleDateChange(date);
  const onFocusChange = ({ focused }) => setCurrentFocused(focused);
  const renderDayContents = (day) => {
    const highlightColor = () => {
      let color = '';
      highlightedDays.forEach((arrayDay) => {
        if (isSameDay(day, arrayDay.date)) {
          ({ color } = arrayDay);
        }
      });
      return color;
    };
    return (
      <>
        <span ref={dayRef} style={{ borderColor: highlightColor(), backgroundColor: highlightColor() }} className='day-container'>
          {day.format('D')}
          {notStartedDays.some((arrayDay) => isSameDay(day, arrayDay)) ? (<span className='circle not-started-circle' />) : ('')}
          {startedDays.some((arrayDay) => isSameDay(day, arrayDay)) ? (<span className='circle started-circle' />) : ('')}
          {completedDays.some((arrayDay) => isSameDay(day, arrayDay)) ? (<span className='circle completed-circle' />) : ('')}
          {highlightedDays.some((arrayDay) => isSameDay(day, arrayDay.date)) ? (<span className='highlighted' />) : ('')}
        </span>
      </>
    );
  };

  return (
    <DatePickerContainer isSmall={isSmallScreenOption} onClick={handleDateClick}>
      <ButtonRound
        onClick={handleDateClick}
        isShowing={!isSmallScreenOption}
        cta={currentDate === null ? today.format('MMMM D, YYYY') : moment(currentDate).format('MMMM D, YYYY')}
        icon='calendar'
        gray
        buttonRef={buttonRef}
      />
      {isDayPickerOpen && (
        <DayPickerWrapper
          isHighlighted={(day) => (
            highlightedDays.some((arrayDay) => isSameDay(day, arrayDay.date))
          )}
        >
          <DayPickerSingleDateController
            date={currentDate}
            enableOutsideDays
            focused={currentFocused}
            isOutsideRange={isFalse}
            isDayHighlighted={isDayHighlighted}
            hideKeyboardShortcutsPanel
            keepOpenOnDateSelect={false}
            numberOfMonths={1}
            onDateChange={onDateChange}
            onFocusChange={onFocusChange}
            onPrevMonthClick={fetchCalendar}
            onNextMonthClick={fetchCalendar}
            renderDayContents={renderDayContents}
            ref={calendarRef}
          />
        </DayPickerWrapper>
      )}
      {!isSmallScreenOption ? (
        <IconContainer isSmall={isSmallScreenOption}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='calendar'
            color={!isSmallScreenOption ? 'white' : '#444444'}
          />
        </IconContainer>
      ) : (
        <SmallIconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='calendar'
            color={!isSmallScreenOption ? 'white' : '#444444'}
          />
          <SmallScreenIconText>Monthly</SmallScreenIconText>
          <IcomoonReact
            iconSet={iconSet}
            size={10}
            icon='down-arrow'
            color='#444444'
          />
        </SmallIconContainer>
      )}
    </DatePickerContainer>
  );
};

AlternativeDatePicker.propTypes = {
  activeDate: PropTypes.string.isRequired,
  selectDate: PropTypes.func.isRequired,
  setIsDayPickerOpen: PropTypes.func.isRequired,
  isDayPickerOpen: PropTypes.bool.isRequired,
  isSmallScreenOption: PropTypes.bool.isRequired,
};

export default memo(AlternativeDatePicker);
