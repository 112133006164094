// attaching action type strings to constants and exporting for consistency accross app

export const FETCH_CALENDARS_START = 'FETCH_CALENDARS_START';
export const FETCH_CALENDARS_FULFILLED = 'FETCH_CALENDARS_FULFILLED';
export const FETCH_CALENDARS_REJECTED = 'FETCH_CALENDARS_REJECTED';

export const CREATE_CALENDAR_START = 'CREATE_CALENDAR_START';
export const CREATE_CALENDAR_FULFILLED = 'CREATE_CALENDAR_FULFILLED';
export const CREATE_CALENDAR_REJECTED = 'CREATE_CALENDAR_REJECTED';

export const FETCH_SEARCH_CALENDARS_START = 'FETCH_SEARCH_CALENDARS_START';
export const FETCH_SEARCH_CALENDARS_FULFILLED = 'FETCH_SEARCH_CALENDARS_FULFILLED';
export const FETCH_SEARCH_CALENDARS_REJECTED = 'FETCH_SEARCH_CALENDARS_REJECTED';

export const DELETE_CALENDARS_START = 'DELETE_CALENDARS_START';
export const DELETE_CALENDARS_FULFILLED = 'DELETE_CALENDARS_FULFILLED';
export const DELETE_CALENDARS_REJECTED = 'DELETE_CALENDARS_REJECTED';

export const UPDATE_CALENDAR_START = 'UPDATE_CALENDAR_START';
export const UPDATE_CALENDAR_FULFILLED = 'UPDATE_CALENDAR_FULFILLED';
export const UPDATE_CALENDAR_REJECTED = 'UPDATE_CALENDAR_REJECTED';

export const UPDATE_CHILD_CALENDAR_START = 'UPDATE_CHILD_CALENDAR_START';
export const UPDATE_CHILD_CALENDAR_FULFILLED = 'UPDATE_CHILD_CALENDAR_FULFILLED';
export const UPDATE_CHILD_CALENDAR_REJECTED = 'UPDATE_CHILD_CALENDAR_REJECTED';

export const CREATE_CHILD_CALENDAR_START = 'CREATE_CHILD_CALENDAR_START';
export const CREATE_CHILD_CALENDAR_FULFILLED = 'CREATE_CHILD_CALENDAR_FULFILLED';
export const CREATE_CHILD_CALENDAR_REJECTED = 'CREATE_CHILD_CALENDAR_REJECTED';

export const ARCHIVE_CALENDARS_START = 'ARCHIVE_CALENDARS_START';
export const ARCHIVE_CALENDARS_FULFILLED = 'ARCHIVE_CALENDARS_FULFILLED';
export const ARCHIVE_CALENDARS_REJECTED = 'ARCHIVE_CALENDARS_REJECTED';

export const UNARCHIVE_CALENDARS_START = 'UNARCHIVE_CALENDARS_START';
export const UNARCHIVE_CALENDARS_FULFILLED = 'UNARCHIVE_CALENDARS_FULFILLED';
export const UNARCHIVE_CALENDARS_REJECTED = 'UNARCHIVE_CALENDARS_REJECTED';

export const SET_ACTIVE_REDUX_MODAL = 'SET_ACTIVE_REDUX_MODAL';

export const SET_SELECTED_ROWS_REDUX = 'SET_SELECTED_ROWS_REDUX';

export const SET_CURRENT_ROW_REDUX = 'SET_CURRENT_ROW_REDUX';

export const SET_EC_DICTIONARY_REDUX = 'SET_EC_DICTIONARY_REDUX';

export const SET_SELECTED_CHILD_ROWS_REDUX = 'SET_SELECTED_CHILD_ROWS_REDUX';

export const SET_SINGLE_ACTION_REDUX = 'SET_SINGLE_ACTION_REDUX';

export const SET_SELECTED_ROWS_DICT_REDUX = 'SET_SELECTED_ROWS_DICT_REDUX';

export const SET_SELECTED_CHILDREN_REDUX = 'SET_SELECTED_CHILDREN_REDUX';

export const RESET_REDUX_REDUX = 'RESET_REDUX_REDUX';

export const RESET_CREATED_TOP_CAL_ID_REDUX = 'RESET_CREATED_TOP_CAL_ID_REDUX';
