import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import reportingUiReducer from './reportingUiReducer';
import reportingDataReducer from './reportingDataReducer';

// const authDataPersistConfig = {
//   key: 'data',
//   storage,
//   stateReconciler: hardSet,
// };

export default combineReducers({
  ui: reportingUiReducer,
  data: reportingDataReducer,
});
