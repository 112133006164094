import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import Button from '../../../../../shared/components/Button/Button';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import Text from '../../../../../shared/components/Text/Text';
import zapier from '../../../../../shared/images/zapier-logo.png';
import stripelogo from '../../../../../shared/images/stripe.svg';
import useReseller from '../../hooks/useReseller';
import ResellerAlertBanner from '../reseller/ResellerAlertBanner';

const MainContainer = styled('main')`
  flex: 10;
  overflow-y: scroll;
`;

const GridContainer = styled('div')`
  margin: 0px 30px;
  padding-bottom: 60px;

  .update-button {
    color: white;
    &:hover {
      color: white;
      opacity: .7;
    }
  }
`;

const MailChimpSquare = styled('div')`
  width: 100%;
  height: 138px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 940px;
  margin-top: 15px;
  .chimp {
    background-image: url(${zapier});
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    margin-right: 20px;
    margin-top: 45px;
  }
  .textbox {
    display: flex;
    flex-direction: column;
    width: 500px;
    .text-header {
      color: #444444;
      font-size: 12px;
    }
    .p-text {
      color: #444444;
      font-size: 11px;
      opacity: 0.5;
    }
  }
  .connector {
    color: #ff6600;
    width: 150px;
    text-align: right;
    cursor: pointer;
  }
`;

const StripeSquare = styled('div')`
  width: 100%;
  height: 138px;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 940px;
  .chimp {
    background-image: url(${stripelogo});
    background-size: contain;
    background-repeat: no-repeat;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    margin-top: 55px;
    margin-right: 20px;
  }
  .textbox {
    display: flex;
    flex-direction: column;
    width: 500px;
    .text-header {
      color: #444444;
      font-size: 12px;
    }
    .p-text {
      color: #444444;
      font-size: 11px;
      opacity: 0.5;
    }
  }
  .connector {
    color: #ff6600;
    width: 150px;
    text-align: right;
    cursor: pointer;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  margin: 30px 30px 20px 30px;

  h1 {
    text-transform: capitalize;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  margin-right: -31px;
  button {
    margin-right: 15px;
  }
`;

const ProgramsGrid = () => {
  const {
    handleStripeAdminLogin,
    currentUser,
    currentReseller,
    stripeAdminLoginURL,
  } = useReseller();

  const [clicked, setClicked] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  
  const spinnerAnimation = useSpring({ opacity: loadingStripe ? 1 : 0 });

  useEffect(() => {
    if (stripeAdminLoginURL && clicked) {
      window.open(stripeAdminLoginURL, '_blank');
    }
  }, [stripeAdminLoginURL]);

  const handleLoadingStripe = () => {
    setLoadingStripe(true);
    setTimeout(() => {
      setLoadingStripe(false);
    }, 1000);
  }
  return (
    <MainContainer>
      {!currentReseller.stripeChargesEnabled && (
        <ResellerAlertBanner />
      )}
      <HeaderRow>
        <HeaderText
          fontWeight={300}
          letterSpacing='normal'
        >
          Integrations
        </HeaderText>
      </HeaderRow>
      <GridContainer>
        <StripeSquare>
          <div className='chimp' />
          <div className='textbox'>
            <div className='text-header'>Stripe</div>
            <div className='p-text'>Stripe is the best software platform for running an internet business. We handle billions of dollars every year for forward-thinking businesses around the world.</div>
          </div>

          <ButtonContainer>
            <Button
              className='update-button'
              cta='Manage Account'
              customColor='#10cd8c'
              noHover
              noBorder
              primary
              onClick={() => {
                setClicked(true);
                handleStripeAdminLogin(currentUser.accountCode);
                handleLoadingStripe();
              }}
            />
            <animated.div style={spinnerAnimation}>
              <Spinner saving darkTheme />
            </animated.div>
          </ButtonContainer>
        </StripeSquare>
        <MailChimpSquare>
          <div className='chimp' />
          <div className='textbox'>
            <div className='text-header'>Zapier</div>
            <div className='p-text'>Zapier is an online automation tool that connects your favorite apps, such as Gmail, Slack, Mailchimp, and more.</div>
          </div>
          <Button
            className='update-button'
            cta='Link to Zapier'
            customColor='#10cd8c'
            noHover
            noBorder
            primary
            onClick={() => {
              window.open('https://zapier.com/apps/teambuildr/integrations', '_blank');
            }}
          />
        </MailChimpSquare>
      </GridContainer>
    </MainContainer>
  );
};

export default ProgramsGrid;
