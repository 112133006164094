import {
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_START,
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED,
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED,
  FETCH_SHARED_WORKOUT_PREVIEW_START,
  FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED,
  FETCH_SHARED_WORKOUT_PREVIEW_REJECTED,
  SET_ACTIVE_SHARED_WORKOUT_MODAL,
  SET_IS_DOWNLOAD_BUTTON_CLICKED,
} from './actionTypes';

import { axiosUnauthed } from '../../../shared/utils/setCommonHeaders';

export const fetchProgram = (programSlug) => (
  (dispatch) => {
    dispatch({ type: FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_START });
    axiosUnauthed.get(`/public/shared-workouts/${programSlug}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED,
            payload: err,
          });
        }, 800);
      });
  }
);

export const fetchWorkout = (programSlug) => (
  (dispatch) => {
    dispatch({ type: FETCH_SHARED_WORKOUT_PREVIEW_START });
    axiosUnauthed.get(`/public/shared-workouts/${programSlug}/workout-preview`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SHARED_WORKOUT_PREVIEW_REJECTED,
        });
      });
  }
);

export const sharedWorkoutModal = (activeModal) => ({
  type: SET_ACTIVE_SHARED_WORKOUT_MODAL,
  payload: {
    activeModal,
  },
});

export const setDownloadButtonClicked = (bool) => ({
  type: SET_IS_DOWNLOAD_BUTTON_CLICKED,
  payload: {
    bool,
  },
});
