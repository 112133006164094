import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const EntryTitles = styled('div')`
  display: block;
`;

const EntryTitle = ({
  exerType,
  labels,
  title,
}) => (
  <EntryTitles>
    {exerType !== 'health' && (
      <div className={`title ${labels ? 'with_label' : ''}`}>
        {title}
      </div>
    )}
  </EntryTitles>
);

EntryTitle.propTypes = {
  exerType: PropTypes.string.isRequired,
  labels: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default EntryTitle;
