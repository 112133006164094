/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import PropTypes from 'prop-types';

import Spinner from '../Spinner/Spinner';
import Button from '../Button/Button';
import { BoxShadow } from '../../GlobalStyles';
import iconSet from '../../images/teambuildr-selection.json';

const darkTheme = {
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
  backgroundColorL2: 'rgb(65, 65, 65)',
  backgroundColorL3: 'rgb(95, 95, 95)',
  borderColor: 'rgb(100, 100, 100)',
  buttonBorderColor: 'rgb(65, 65, 65)',
  buttonBorderColor2: 'rgb(65, 65, 65)',
  focusColor: '#0ab3cd',
  noBorderColor: 'rgb(58, 58, 58)',
  textColor: 'rgba(255, 255, 255, 0.80)',
  lightTextColor: 'rgba(255, 255, 255, 0.75)',
  lightTextColor2: 'rgba(255, 255, 255, 0.65)',
  overlayColor: 'rgba(30, 30, 30, 0.6)',
  noBorderColorForm: 'rgb(95, 95, 95)',
  iconBorderColor: 'rgba(65, 65, 65)',
  overflowGradient: 'linear-gradient(to right,rgba(36,36,36,0),rgba(36,36,36,1))',
};

const ModalContainer = styled('div')`
	display: flex;
	background: ${(props) => (props.isDarkMode ? 'rgb(65, 65, 65)' : '#F7F7F7')};
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 70vh;
  width: 65vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-height: 650px;
  max-width: 590px;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: ${(props) => (props.isDarkMode ? 'rgb(65, 65, 65)' : '#F7F7F7')};
  .remove-div-icon {
    cursor: pointer;
  }
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  scrollbar-width: thin;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  .custom-form {
    padding: 30px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 80%;
    margin-top: 5px;
  }
`;

const PrimaryContent = styled('div')`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-left: 25px;
  padding-right: 25px;
  min-width: 430px;
  padding-bottom: 30px;
  user-select: none;
  scrollbar-width: thin;
`;

const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const NoHistoryDataText = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 80%;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: 600;
`;

const CompletionOnlyText = styled('div')`
  font-weight: 400;
  font-style: normal;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.4;
`;

const NoDataDiv = styled('div')`
  height: 60%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HistoryDataChunk = styled('div')`
  display: flex;
  height: auto;
`;

const HistoryDataTable = styled('div')`
  background: white;
  border-radius: 24px;
  box-shadow: ${BoxShadow};
  transition: height 0.2s ease;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  min-width: 272px;;
  position: relative;
  padding-bottom: ${(props) => (`${props.addExtraPadding}px`)};
  overflow-x: auto;
	background: ${(props) => (props.isDarkMode ? 'rgb(95,95,95)' : '#F7F7F7')};
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
  max-width: 400px;
  scrollbar-width: thin;
`;

const DateColumn = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  transition: all 0.2s ease; 
  margin-right: 20px;
  text-align: center;
  min-width: 100px;
  padding-bottom: 51px;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
  @media only screen and (max-width : 768px) {
    min-width: 100px;
  }
`;

const ColumnMonthDeets = styled('div')`
  font-style: normal;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1;
  .day-num {
    font-size: 20px;
    font-weight: 600;
  }
  .month {
    font-size: 14px;
    font-weight: 600;
  }
  .week-day {
    font-size: 12px;
    font-weight: 400;
  }
`;

const ColumnYearDeets = styled('div')`
  font-weight: 400;
  font-style: normal;
  font-family: 'Nunito Sans';
  color: #c9c9c9;
  font-size: 10px;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-top: 10px;
`;

const ValueRow = styled('div')`
  display: flex;
  font-weight: 700;
  font-style: normal;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1;
  height: 40px;
  align-items: center;
  width: 100%;
`;

const GreenCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  background: #0ecd8a;
  margin-right: 20px;
`;

const ValueBox = styled('div')`
  font-style: normal;
  font-family:'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  letter-spacing: 0.8px;
  line-height: 1;
  text-align: center;
  width: 50px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const CompletionOnlyBox = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const RowBox = styled('div')`
  display: flex;
  min-width: 260px;
  @media only screen and (max-width : 768px) {
    min-width: 230px;
  }
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
`;

const ValueBoxes = styled('div')`
  display: flex;
  font-weight: ${(props) => (props.isSet ? 800 : 500)};
`;

const HistoryTableContainer = styled('div')`
  padding-bottom: 25px;
  margin-bottom: 25px;
  margin-right: 60px;
  border-bottom: 1px solid #c9c9c9;
  @media only screen and (max-width : 768px) {
    margin-right: 30px;
  }
  transition: all 0.2s ease;
  width: 100%;
`;

const Grid = styled('div')`
  display: flex;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 94%;
`;

const RowsAndButton = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const RevealButton = styled('div')`
  display: flex;
  height: 35px;
  width: 90%;
  background: white;
  margin-top: 10px;
  border: 2px solid ${(props) => props.theme.textColor};
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.isDarkMode ? 'rgb(95,95,95)' : '#F7F7F7')};
`;

const RevealButtonText = styled('div')`
  font-style: normal;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 1;
  font-weight: 800;
`;

const PlayIconContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MediaAndText = styled('div')`
  overflow-x: auto;
`;

const Media = styled('div')`
  display: flex;
  overflow: auto;
  scrollbar-width: thin;
`;

const JournalText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  margin-bottom: 20px;
  margin-left: 20px;
  max-width: 240px;
  overflow: hidden;
  white-space: normal;
`;

const ValueName = styled('div')`
  font-family: 'Nunito Sans';
  min-width: 80px;
`;

const imageFlipper = (inputMedia) => {
  if (inputMedia.thumbnailKey) {
    return inputMedia.thumbnailUrl;
  }
  return inputMedia.mediaUrl;
};

const MediaDiv = styled('div')`
  min-height: 50px;
  min-width: 75px;
  border-radius: 5px;
  background-image: ${(props) => `url(${imageFlipper(props.media)})`};
  background-size: cover;
  margin-bottom: 20px;
  margin-left: 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
`;

const EndOfListText = styled('div')`
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 20px;
`;

/**
 * HistoryDataChunkComponent is a functional component that would
 * normally be abstracted out into its own file, but because this is a
 * shared component and needs to be modular, it's being defined here
 * within the component that uses it.
 *
 * The reason it needed to become its own component is because it relies
 * on a slice of local state that's specific to each grid representing
 * whether or not it's expanded beyond the typical 3 rows that are
 * visible when a grid has more than 3 rows associated with it
 * (the allRowsRevealed slice of local state).
 *
 * Since there are multiple history workout grids representing different dates
 * it's necessary for each one to have its own slice of local state, otherwise
 * the switch would affect all history grids at the same time.
 */

/**
 *
 * @param {Object} historyObject this is the individual object that's represented by the grid
 * @param {Function} mediaDispatchFunction the function that's called when a piece of media clicked
 * @returns each individual grid representing the history of a workout at a specific date
 */
const HistoryDataChunkComponent = ({
  historyObject,
  mediaDispatchFunction,
  isDarkMode,
}) => {
  const [allRowsRevealed, setAllRowsRevealed] = useState(false);
  // small local variable that keeps track of when to display the year
  // beneath the date

  const mediaMapper = (mediaArray) => mediaArray.map(
    (mediaOb) => (
      <MediaDiv
        media={mediaOb}
        onClick={() => {
          if (mediaDispatchFunction) {
            mediaDispatchFunction(mediaOb);
          }
        }}
      >
        {mediaOb.type === 'VIDEO' ? (
          <PlayIconContainer
            onClick={() => {
              if (mediaDispatchFunction) {
                mediaDispatchFunction(mediaOb);
              }
            }}
          >
            <IcomoonReact
              className='play-icon'
              iconSet={iconSet}
              size={30}
              icon='play-filled'
              color='white'
            />
          </PlayIconContainer>
        ) : null}

      </MediaDiv>
    ),
  );

  const rowMapper = (data, bool) => data.map((row, i) => {
    if (i < 3 || bool) {
      return (
        <ValueRow>
          <ValueName>{row.valueName}</ValueName>
          <ValueBoxes isSet={i === 0}>
            {row.values.map((value) => (
              <ValueBox>
                {value || value === 0 ? value : 'n/a'}
              </ValueBox>
            ))}
          </ValueBoxes>
        </ValueRow>
      );
    }
    return null;
  });

  const paddingCalculator = (historyOb) => {
    const hasMedia = historyOb.journalEntries.length && historyOb.journalEntries[0].media?.length;
    // const hasText = historyOb.journalEntries.length && historyOb.journalEntries[0].body;
    if (hasMedia) {
      return 75;
    }
    return 25;
  };

  return (
    <HistoryDataChunk>
      <DateColumn isDarkMode={isDarkMode}>
        <ColumnMonthDeets>
          <div className='day-num'>{moment(historyObject.dateCompleted).format('D')}</div>
          <div className='month'>{moment(historyObject.dateCompleted).format('MMM')}</div>
          <div className='week-day'>{moment(historyObject.dateCompleted).format('ddd')}</div>
        </ColumnMonthDeets>
        <ColumnYearDeets>
          {moment(historyObject.dateCompleted).format('YYYY')}
        </ColumnYearDeets>
      </DateColumn>
      <HistoryTableContainer>
        <HistoryDataTable
          isDarkMode={isDarkMode}
          addExtraPadding={paddingCalculator(historyObject)}
        >
          {!historyObject.completionOnly && historyObject.tableData.length ? (
            <RowsAndButton>
              <RowBox>
                {rowMapper(historyObject.tableData, allRowsRevealed)}
              </RowBox>
              {historyObject.tableData.length > 3
                ? (
                  <RevealButton
                    isDarkMode={isDarkMode}
                    onClick={() => {
                      setAllRowsRevealed(!allRowsRevealed);
                    }}
                  >
                    <div style={{
                      marginRight: 5,
                      marginBottom: allRowsRevealed ? 3 : 2,
                      transform: !allRowsRevealed ? 'rotate(-90deg)' : 'rotate(0deg)',
                    }}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={12}
                        icon='down-arrow'
                        color={isDarkMode ? 'white' : 'black'}
                      />
                    </div>
                    <RevealButtonText>ADDITIONAL INFORMATION</RevealButtonText>
                  </RevealButton>
                ) : null}
            </RowsAndButton>
          ) : historyObject.completionOnly ? (
            <CompletionOnlyBox>
              {historyObject.fullyCompleted ? (
                <GreenCircle>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='checkmark'
                    color='white'
                  />
                </GreenCircle>
              ) : null}
              <CompletionOnlyText>
                {historyObject.completionOnlyText}
              </CompletionOnlyText>
            </CompletionOnlyBox>
          ) : null}
          {(historyObject.journalEntries.length
    && historyObject.journalEntries[0]?.media.length) || (historyObject.journalEntries.length
    && historyObject.journalEntries[0].body) ? (
      <>
        {historyObject?.journalEntries[0]?.body
          ? <JournalText>{historyObject?.journalEntries[0]?.body}</JournalText>
          : null}
        <Grid>
          <MediaAndText>
            <Media>
              {historyObject.journalEntries.length
    && historyObject.journalEntries[0]?.media.length
                ? mediaMapper(historyObject.journalEntries.length
    && historyObject.journalEntries[0].media) : null}
            </Media>
          </MediaAndText>
        </Grid>
      </>
            ) : null}
        </HistoryDataTable>
      </HistoryTableContainer>
    </HistoryDataChunk>
  );
};

/**
 * @param {Object} modalTitle title of modal.
 * @param {Function} closeModalFunction function for closing the modal
 * @param {Boolean} isHistoryFetching boolean that indicates whether the
 * ui for the history data fetching is currently true
 * @param {Object} reduxHistoryData the array of history data for the modal to map
 * @param {Function} useEffectFunction optional function that fires when the opened
 * status of the modal changes
 * @param {Function} mediaDispatchFunction optional function that fires when
 * a piece of media is clicked and passes the media object as an argument
 * @returns a modal that specifically maps over history data returned from the
 * 'exercises/${exerId}/workouts/history' endpoint
 */
const WorkoutHistoryModal = ({
  modalTitle = '',
  closeModalFunction,
  isHistoryFetching,
  reduxHistoryData,
  useEffectFunction = undefined,
  isModalActive,
  mediaDispatchFunction = undefined,
  isDarkMode = false,
  fetchMoreHistoryFunction,
  isEndOfWorkoutHistoryList,
}) => {
  // useEffct that fires if a useEffectFunction parameter has been passed
  useEffect(() => {
    if (useEffectFunction) {
      useEffectFunction();
    }
  }, [isModalActive]);

  // function that returns either a spinner or a no content component
  // depending on the status of both the isHistoryFetching bool and data
  const loadingWhacker = () => {
    if (isHistoryFetching) {
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    } if (!isHistoryFetching && !Object.keys(reduxHistoryData).length) {
      return <NoDataDiv>No History for This Exercise</NoDataDiv>;
    }
    return null;
  };

  const handleLoadMore = () => {
    fetchMoreHistoryFunction();
  };

  const moreHistory = () => {
    if (!isHistoryFetching && reduxHistoryData.length) {
      if (!isEndOfWorkoutHistoryList) {
        return (
          <Button
            cta='Load More'
            large
            rounded
            noBorder
            primary
            customColor='#444444'
            customTextColor='#FFFFFF'
            onClick={handleLoadMore}
          />
        );
      }
      return (
        <EndOfListText>End of Workout History</EndOfListText>
      );
    }
  };

  // modal
  return (
    // the isActive parameter gets passed to the ModalContainer
    // the ModalContainer transitions based on the truth of isModalActive
    <ModalContainer isDarkMode={isDarkMode} isActive={isModalActive}>
      <RemoveDiv isDarkMode={isDarkMode}>
        <ModalTextTop isDarkMode={isDarkMode}>
          History -
          {' '}
          {modalTitle}
        </ModalTextTop>
        {/**
         * remove icon fires the closeModalFunction
         */}
        <div
          className='remove-div-icon'
          onClick={() => {
            closeModalFunction();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color={isDarkMode ? 'white' : 'black'}
          />
        </div>
      </RemoveDiv>
      <ContentContainer isDarkMode={isDarkMode}>
        <PrimaryContent isDarkMode={isDarkMode}>
          {!isHistoryFetching
            ? reduxHistoryData.length
              ? reduxHistoryData.map((historyObject) => (
                <HistoryDataChunkComponent
                  mediaDispatchFunction={mediaDispatchFunction}
                  historyObject={historyObject}
                  isDarkMode={isDarkMode}
                />
              ))
              : <NoHistoryDataText>No History Data</NoHistoryDataText> : loadingWhacker()}
          {moreHistory()}
        </PrimaryContent>
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='close'
        className='print-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          closeModalFunction();
        }}
      />
    </ModalContainer>
  );
};

WorkoutHistoryModal.defaultProps = {
  modalTitle: '',
  useEffectFunction: undefined,
  mediaDispatchFunction: undefined,
  isDarkMode: false,
};

WorkoutHistoryModal.propTypes = {
  modalTitle: PropTypes.string,
  closeModalFunction: PropTypes.func.isRequired,
  isHistoryFetching: PropTypes.bool.isRequired,
  reduxHistoryData: PropTypes.instanceOf(Object).isRequired,
  useEffectFunction: PropTypes.func,
  isModalActive: PropTypes.bool.isRequired,
  mediaDispatchFunction: PropTypes.func,
  isDarkMode: PropTypes.bool,
  fetchMoreHistoryFunction: PropTypes.func.isRequired,
  isEndOfWorkoutHistoryList: PropTypes.bool.isRequired,
};

HistoryDataChunkComponent.defaultProps = {
  mediaDispatchFunction: undefined,
  isDarkMode: false,
};

HistoryDataChunkComponent.propTypes = {
  historyObject: PropTypes.instanceOf(Object).isRequired,
  mediaDispatchFunction: PropTypes.func,
  isDarkMode: PropTypes.bool,
};

export default WorkoutHistoryModal;
