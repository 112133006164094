import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import LogRocket from 'logrocket';
import Logger from 'js-logger';

import { useReactToPrint } from 'react-to-print';
import Layout from '../../shared/components/Layout/Layout';
import ReportingSidebar from './components/ReportingSidebar';
import ReportingGrid from './components/ReportingGrid';
import ActiveReport from './components/ActiveReport';
import { BoxShadow } from '../../shared/GlobalStyles';
import {
  setModalOpen,
  setReportReadyForPrint,
  setShowAllForDownload,
} from './ducks/reportingActions';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  cursor: default;

  .filter-button {
    position: absolute;
    bottom: 30px;
    align-self: center;
    display: none;
    z-index: 100000;
    box-shadow: ${BoxShadow};

    @media screen and (max-width: 767px) {
      display: flex;
      padding: 10px 18px;
    }
  }
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
  @media print {
    height: auto !important;
    overflow: visible !important;
  }
`;

const Reporting = () => {
  const activeReport = useSelector((state) => state.reporting.ui.activeReport);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();
  // printRef will be passed down to the ActiveReport in whichever element we want to print.
  // For comparison report, we pass it to the table element.
  const printRef = useRef(null);
  // Ensures state updates completed to prepare printRef.current before printing.
  const reportReadyForPrint = useSelector(
    (state) => state.reporting.data.reportReadyForPrint,
  );

  const handleReactPrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => {
      // Reseting showAllForDownload resets comparison report table size to 50
      // for proper pagination and resets reportReadyForPrint to false.
      if (activeReport === 'comparison') {
        dispatch(setShowAllForDownload(false));
        dispatch(setReportReadyForPrint(false));
      }
    },
  });

  useEffect(() => {
    // Opening download modal for comparison report also
    // triggers cascade that sets readyForPrint to true.
    if (reportReadyForPrint && activeReport === 'comparison' && activeModal !== 'downloadComparisonReport') {
      handleReactPrint();
    }
  }, [reportReadyForPrint, activeModal]);

  /*
  This is the click function triggered by the print button in ActiveReportSidebar.
  For Comparison Report, asynchronous state updates
  are required to prepare the table to be printed properly.
  handlePrint sets showAllForDownload true, changing the table's page size to include all data rows.
  Once this is completed, reportReadyToPrint is set to true,
  triggering the useEffect above (handleReactPrint handles the actual print functionality).
  For other reports, if state updates are not required to prepare printRef,
  handleReactPrint maybe be called directly inside handlePrint.
  */
  const handlePrintClick = () => {
    if (activeReport === 'comparison') {
      dispatch(setShowAllForDownload(true));
      // Avoid unwanted overlapping interaction with download functionality
      dispatch(setModalOpen(false, ''));
    }
  };

  if (
    process.env.ENVIRONMENT === 'PRODUCTION'
    && currentUser.userSettings
    && currentUser.userSettings.sessionLoggingEnabled === 1
  ) {
    LogRocket.init('v2xspp/tb-reporting');
    Logger.debug('Logrocket Enabled v1.2.00');
    LogRocket.identify(currentUser && currentUser.id, {
      name: currentUser && `${currentUser.first} ${currentUser.last}`,
      email: currentUser && currentUser.email,
      organizationId: currentUser && currentUser.organizationId,
      organizationAccessLevel:
        currentUser && currentUser.organizationAccessLevel,
      subscriptionId: currentUser && currentUser.organizationSubscriptionId,
      appVersion: '1.2.00',
    });
  }

  return (
    <Layout fullWidth>
      <Helmet>
        <title>Reporting | TeamBuildr</title>
      </Helmet>
      <PageWrapper>
        <ReportingSidebar handlePrint={handlePrintClick} />
        {activeReport === '' ? (
          <ReportingGrid />
        ) : (
          <ActiveReport printRef={printRef} handlePrint={handlePrintClick} />
        )}
      </PageWrapper>
    </Layout>
  );
};

export default Reporting;
