// modal for confirming deletion of selected documents

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import { Link } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { useSelector } from 'react-redux';

import Text from '../../../../../shared/components/Text/Text';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import SubText from '../../../../../shared/components/SubText/SubText';
import NoHoverButton from './NoHoverButton';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import useReseller from '../../hooks/useReseller';
import useResellerSubscribers from '../../hooks/useResellerSubscribers';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const PrimaryWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .history-header {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    svg {
      color: #444444;
    }
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const SubscriptionCard = styled('div')`
  width: 375px;
  height: 94px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 11px;
  }
  .cancel-button {
    background: #ff0024;
    color: white;
  }
  .disabled-button {
    background: #D3D3D3;
    color: white;
  }
`;

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
`;

const SubscriptionHistoryWrapper = styled('div')`
  max-height: 329px;
  overflow-y: auto;
  padding: 5px;
`;

const DropdownItemContainer = styled('div')`
`;

const ModalConfirmCancel = ({
  currentSubscriber,
  onRequestClose,
  currentSubscription,
  setActiveModal,
  setCurrentChargeItem,
  setCurrentSubscriber,
}) => {
  const {
    currentUser,
    handleFetchAllCharges,
  } = useReseller();

  const {
    subscriber,
  } = useResellerSubscribers();

  const [userProfilePic, setUserProfilePic] = useState(null);

  const allCharges = useSelector((state) => state.reseller.data.allCharges);
  const isAllChargesLoading = useSelector((state) => state.reseller.ui.isAllChargesLoading);

  useEffect(() => {
    if (currentSubscriber && currentUser && !isAllChargesLoading) {
      handleFetchAllCharges({
        accountCode: currentUser.accountCode,
        subscriberId: currentSubscriber.id,
      });
    }
  }, [subscriber]);

  useEffect(() => {
    if (subscriber) {
      const subscriberPic = optimizeImage(
        subscriber.userPic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(subscriberPic);
    }
  }, [subscriber]);

  const UserDetailBox = styled('div')`
    display: flex;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    .userpic {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
      margin-right: 10px;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      .manage-button {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      href=''
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        icon='dots'
      />
    </Link>
  ));

  const DropdownMenu = ({
    setActiveModal,
    chargeItem,
    isDisabled,
  }) => (
    <>
      {/* Added a global component from emotion to inject css classes to the global html elements. */}
      <Global
        styles={css`
          .dropdown-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            span {
              color: #999999;
            }
            .drop-icon-text {
              margin-left: 10px;
              padding-top: 2px;
            }
            :hover {
              transition: linear 0s;
              background: #006a65;
              color: white;
              span {
                color: white;
              }
              svg {
                fill: white;
              }
            }
          }
  
          .dropdown-menu {
            padding: 0px;
          }
  
          .dropdown-divider {
            margin: 0px;
          }
        `}
      />
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {
          window.open(chargeItem.receiptUrl, '_blank');
        }}
        >
          <DropdownItemContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='magnifying-glass-alt'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              View Receipt
            </SubText>
          </DropdownItemContainer>
        </Dropdown.Item>
        <Dropdown.Divider />
        <DropdownItemContainer>
          <Dropdown.Item
            disabled={isDisabled}
            onClick={() => {
              if (!isDisabled) {
                setCurrentChargeItem(chargeItem);
                setActiveModal('confirm_refund_modal');
              }
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='dollar'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              {!isDisabled ? 'Refund' : 'Refund Complete'}
            </SubText>
          </Dropdown.Item>
        </DropdownItemContainer>
      </Dropdown.Menu>
    </>
  );

  const subscriptionCardGenerator = (chargesArray) => {
    const newArray = [];
    if (chargesArray) {
      chargesArray.forEach((chargeItem) => {
        const newMoment = moment(chargeItem.created);
        const chargedDate = newMoment.format('MMMM D, YYYY');
        const isDisabled = chargeItem.amount === chargeItem.amountRefunded;
        let amount;
        let refundedAmount;
        if (chargeItem.placeSymbolAfterCurrency) {
          amount = `${parseFloat(chargeItem.amount / 100).toFixed(2)}${chargeItem.currencySymbol}`;
          refundedAmount = `${parseFloat(chargeItem.amountRefunded / 100).toFixed(2)}${chargeItem.currencySymbol}`;
        } else {
          amount = `${chargeItem.currencySymbol}${parseFloat(chargeItem.amount / 100).toFixed(2)}`;
          refundedAmount = `${chargeItem.currencySymbol}${parseFloat(chargeItem.amountRefunded / 100).toFixed(2)}`;
        }

        newArray.push(
          <SubscriptionCard>
            <div className='card-info-box'>
              <div className='card-price'>
                Amount:
                {' '}
                {amount}
              </div>
              <div className='card-number'>
                {chargedDate}
              </div>
              <div className='card-number'>
                Refunded Amount:
                {' '}
                {refundedAmount}
              </div>
            </div>
            <DropDownMenuWrapper>
              <Dropdown
                bsPrefix='trick-fix'
              >
                <Dropdown.Toggle as={CustomToggle} />
                <DropdownMenu
                  setActiveModal={setActiveModal}
                  chargeItem={chargeItem}
                  isDisabled={isDisabled}
                />
              </Dropdown>
            </DropDownMenuWrapper>
            {/* <NoHoverButton
              className={!isDisabled ? 'cancel-button' : 'disabled-button'}
              cta={!isDisabled ? 'Refund' : 'Refunded'}
              disabled={isDisabled}
              noHover
              noBorder
              onClick={() => {
                setCurrentChargeItem(chargeItem);
                setActiveModal('confirm_refund_modal');
              }}
            /> */}
          </SubscriptionCard>,
        );
      });
    }
    if (newArray.length) {
      return newArray;
    }
    return <Text>No recent charges found</Text>;
  };

  if (isAllChargesLoading) {
    return <Spinner />;
  }

  return (
    <ModalWrapper>
      <NavigationWrapper>
        <Text
          className='arrow-div'
          onClick={() => {
            setActiveModal('backwards_subscriber_detail_modal');
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='left-arrow'
          />
        </Text>
        <Text
          className='close arrow-div'
          onClick={() => {
            onRequestClose();
            setCurrentSubscriber(null);
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <PrimaryWrapper>
        <FolderTextWrapper>
          <Text className='calendar-icon-text'>Subscriber Charges</Text>
        </FolderTextWrapper>
        <div className='divider-line' />
        <UserDetailBox>
          <div className='userpic' />
          <div className='text-container'>
            <div>{`${subscriber.firstName} ${subscriber.lastName}`}</div>
            <div>{subscriber.emailAddress}</div>
            <div onClick={() => window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/manage_users?userId=${currentSubscriber.id}`, '_blank')} className='manage-button'>Manage User</div>
          </div>
        </UserDetailBox>
        <SubText
          className='history-header'
          fontSize={16}
        >
          Recent Charges
        </SubText>
        <SubscriptionHistoryWrapper>
          {subscriptionCardGenerator(allCharges)}
        </SubscriptionHistoryWrapper>
      </PrimaryWrapper>

    </ModalWrapper>
  );
};

ModalConfirmCancel.propTypes = {

};

export default ModalConfirmCancel;
