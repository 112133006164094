/**
 * file for importing actions and wrapping them in functions for export to calendars.js
 * also for importing various slices of state for use in calendars.js
 */

import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCalendars,
  createCalendar,
  fetchCalendarsWithSearch,
  deleteCalendars,
  updateCalendar,
  updateChildCalendar,
  createChildCalendar,
  archiveCalendars,
  unarchiveCalendars,
} from '../../ducks/calendarsActions';

const useCalendars = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const handleFetchCalendars = (values) => {
    dispatch(fetchCalendars(values));
  };

  const handleSearch = (values) => {
    dispatch(fetchCalendarsWithSearch(values));
  };

  const handleCreateCalendar = (values) => {
    dispatch(createCalendar(values));
  };

  const handleDeleteCalendars = (accountCode, calIdsArray) => {
    dispatch(deleteCalendars(accountCode, calIdsArray));
  };

  const handleUpdateCalendar = (accountCode, calId, values, calendarIndex, oldCalendarIndex) => {
    dispatch(updateCalendar(accountCode, calId, values, calendarIndex, oldCalendarIndex));
  };

  const handleUpdateChildCalendar = (
    accountCode,
    calId,
    values,
    parentId,
    parentIndex,
    childIndex,
  ) => {
    dispatch(updateChildCalendar(accountCode, calId, values, parentId, parentIndex, childIndex));
  };

  const handleCreateChildCalendar = (values) => {
    dispatch(createChildCalendar(values));
  };

  const handleArchiveCalendars = (accountCode, calIdsArray) => {
    dispatch(archiveCalendars(accountCode, calIdsArray));
  };

  const handleUnarchiveCalendars = (accountCode, calIdsArray) => {
    dispatch(unarchiveCalendars(accountCode, calIdsArray));
  };

  return {
    handleFetchCalendars,
    handleCreateCalendar,
    handleSearch,
    handleDeleteCalendars,
    handleUpdateCalendar,
    handleUpdateChildCalendar,
    handleCreateChildCalendar,
    handleArchiveCalendars,
    handleUnarchiveCalendars,
    currentUser,
  };
};

export default useCalendars;
