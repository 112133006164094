import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WorkoutModals from '../presentational/WorkoutModals';
import {
  closeModal,
  openModal,
} from '../../ducks/panelActions';

const WeightroomModals = (ownProps) => {
  const isModalOpen = useSelector((state) => state.weightRoomView[ownProps.namespace].isModalOpen);

  const dispatch = useDispatch();

  const clseModal = (namespace) => {
    dispatch(closeModal(namespace));
  };
  const opnModal = (namespace, modal) => {
    dispatch(openModal(namespace, modal));
  };

  return (
    <WorkoutModals
      data={ownProps.data}
      exerName={ownProps.exerName}
      namespace={ownProps.namespace}
      workId={ownProps.workId}
      isModalOpen={isModalOpen}
      closeModal={clseModal}
      openModal={opnModal}
    />
  );
};

export default WeightroomModals;
