/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import moment from 'moment';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts';
import Text from '../../../../shared/components/Text/Text';

const ChartContainer = styled('div')`
  background: white;
  border-radius: 24px;
  width: 100%;
  height: ${(props) => props.containerWidth >= 575 && '100%'};
  max-width: ${(props) => props.maxWidth && props.maxWidth};
  max-height: ${(props) => props.maxHeight && props.maxHeight};
  min-width: 200px;
  box-shadow: 0px 0px 1px rgba(48,49,51,0.05), 0px 8px 16px rgba(48,49,51,0.1);
  display: flex;
  align-items: center;
  align-self: ${(props) => props.alignSelf && props.alignSelf};
  font-size: 11px;
  flex-direction: column;
  padding: 20px;
`;

const SpiderChart = ({
  maxWidth, maxHeight, alignSelf, containerWidth,
}) => {
  const currentReportType = useSelector((state) => state.loadMonitoringDashboard.data.currentReportType);
  const dataRows = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.dataRows);
  const currentDateInDashDatePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentDateInDashDatePicker);
  const teamAverageTotals = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.teamAverageTotals);
  const isLoadingQuestionnaireReportSingleDay = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportSingleDay);
  const isLoadingQuestionnaireReportMultiday = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportMultiday);

  const athleteData = dataRows && dataRows[0]?.responses && dataRows[0]?.responses[0]?.questions.map((question) => ({
    title: question?.abbr?.charAt(0).toUpperCase() + question?.abbr?.slice(1).toLowerCase(),
    value: question?.value === '-' ? 0 : question?.value,
    average: question?.average === '-' ? 0 : question?.average,
    load: 810,
  }));

  const mostRecentDateInReturnedAthleteData = dataRows && dataRows[0]?.responses && dataRows[0]?.responses[0]?.date;
  const mostRecentDateFromLast7DaysOfAthleteData = mostRecentDateInReturnedAthleteData || currentDateInDashDatePicker?.date;

  const filteredAthleteDataNoLoad = athleteData?.filter((metric) => metric.title !== 'Load');

  const athleteVolumeLoadData = athleteData?.filter((metric) => metric.title === 'Load')[0];

  const groupData = teamAverageTotals && teamAverageTotals.questions?.map((question) => ({
    title: question?.abbr?.charAt(0).toUpperCase() + question?.abbr?.slice(1).toLowerCase(),
    value: question?.response?.value === '-' ? 0 : question?.response?.value,
    average: question?.weekAverage?.value === '-' ? 0 : question?.weekAverage?.value,
  }));

  const filteredGroupDataNoLoad = groupData?.filter((metric) => metric.title !== 'Load');

  const groupVolumeLoadData = groupData?.filter((metric) => metric.title === 'Load')[0];

  const defaultData = [{
    title: '',
    value: 0,
    average: 0,
  }];

  const getSumOfDataForSelectedDate = () => {
    if (currentReportType === 'singleDay') {
      /** sum group data */
      const total = filteredGroupDataNoLoad?.reduce((acc, obj) => acc + obj.value, 0);
      return total;
    }
    /** sum athleteData */
    const total = filteredAthleteDataNoLoad?.reduce((acc, obj) => acc + obj.value, 0);
    return total;
  };

  const sumOfDataForSelectedDate = getSumOfDataForSelectedDate();

  const getSumOfDataForAverages = () => {
    if (currentReportType === 'singleDay') {
      /** sum group data */
      const total = filteredGroupDataNoLoad?.reduce((acc, obj) => acc + obj.average, 0);
      return total;
    }
    /** sum athleteData */
    const total = filteredAthleteDataNoLoad?.reduce((acc, obj) => acc + obj.average, 0);
    return total;
  };

  const sumOfDataForAverages = getSumOfDataForAverages();

  return (
    <>
      {isLoadingQuestionnaireReportMultiday || isLoadingQuestionnaireReportSingleDay ? (
        <Shimmer>
          <ChartContainer maxWidth={maxWidth} maxHeight={maxHeight} alignSelf={alignSelf} style={{ paddingBottom: containerWidth < 575 && '30px' }} containerWidth={containerWidth}>
            <ResponsiveContainer width='100%' height='90%' minHeight={250} maxWidth={245} minWidth={245}>
              <RadarChart cx='50%' cy='50%' outerRadius='50%' data={[]} />
            </ResponsiveContainer>
          </ChartContainer>
        </Shimmer>
      ) : (
        <ChartContainer maxWidth={maxWidth} maxHeight={maxHeight} alignSelf={alignSelf} containerWidth={containerWidth}>
          {!sumOfDataForAverages && !sumOfDataForSelectedDate && (
          <Text fontSize='11px' fontWeight={700} color='grey'>No Data For Selected Date</Text>
          )}
          <ResponsiveContainer width='100%' height='90%' minHeight={250} maxWidth={245} minWidth={245}>
            <RadarChart cx='50%' cy='50%' outerRadius='50%' data={(currentReportType === 'singleDay' ? filteredGroupDataNoLoad : filteredAthleteDataNoLoad) || defaultData}>
              <PolarGrid gridType='circle' />
              <PolarAngleAxis dataKey='title' tick={{ dy: -5, fill: (!sumOfDataForAverages && !sumOfDataForSelectedDate) ? 'grey' : '#444444' }} style={{ fontWeight: 700 }} />
              <PolarRadiusAxis angle={90} domain={[0, 12]} tick={{ fill: (!sumOfDataForAverages && !sumOfDataForSelectedDate) ? 'grey' : '#444444' }} style={{ fontWeight: 700 }} />
              <Radar name={moment(mostRecentDateFromLast7DaysOfAthleteData).format('MMM DD, YYYY')} dataKey='value' stroke={(!sumOfDataForAverages && !sumOfDataForSelectedDate) ? 'grey' : '#444444'} fillOpacity={0} strokeWidth={2} connectNulls />
              <Radar name='7 Day Average' dataKey='average' stroke={(!sumOfDataForAverages && !sumOfDataForSelectedDate) ? 'grey' : '#FF6600'} strokeDasharray='8 4' fillOpacity={0} strokeWidth={2} connectNulls />
              <Legend
                wrapperStyle={{
                  fontStyle: 'italic', fontSize: '14px', fontWeight: 700, color: (!sumOfDataForAverages && !sumOfDataForSelectedDate) ? 'grey' : '#444444',
                }}
                iconType='plainline'
                iconSize={30}
              />
            </RadarChart>
          </ResponsiveContainer>
          {!groupVolumeLoadData && !athleteVolumeLoadData ? null : (
            <>
              <Text fontSize='12px' fontWeight={700}>
                Volume Load (
                {moment(mostRecentDateFromLast7DaysOfAthleteData).format('MMM DD, YYYY')}
                ):
                {' '}
                {(currentReportType === 'singleDay' ? groupVolumeLoadData?.value : athleteVolumeLoadData?.value) || 'no status'}
              </Text>
              <Text fontSize='12px' fontWeight={700}>
                Volume Load (7 Day Average):
                {' '}
                {(currentReportType === 'singleDay' ? groupVolumeLoadData?.average : athleteVolumeLoadData?.average) || 'no status'}
              </Text>
              <Text style={{ marginTop: '10px' }} fontSize='12px'>* Chart reflects data from the most recent date data was found before selected date and the averages from the last 7 dates data was found before selected date</Text>
            </>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default SpiderChart;
