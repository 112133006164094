import { useEffect, useState } from 'react';

// Define the types of network connection
const getConnectionType = (connection) => {
  if (!connection) {
    return 'unknown';
  }
  return `${connection.effectiveType || 'unknown'} ${connection.downlink || '0'}Mbps`;
};

// Custom Hook
const useNetworkStatus = () => {
  // Determine if the navigator is online or not
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // Store detailed network information
  const [networkType, setNetworkType] = useState(getConnectionType(navigator.connection));

  useEffect(() => {
    // Update the online status
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
      setNetworkType(getConnectionType(navigator.connection));
    };

    // Event listeners for online and offline events
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // For more detailed network information, if available
    const connection = navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    if (connection) {
      connection.addEventListener('change', updateOnlineStatus);
    }

    // Cleanup
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
      if (connection) {
        connection.removeEventListener('change', updateOnlineStatus);
      }
    };
  }, []);

  return { isOnline, networkType };
};

export default useNetworkStatus;
