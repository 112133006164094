export const FETCH_TEMPLATES_START = 'FETCH_TEMPLATES_START';
export const FETCH_TEMPLATES_FULFILLED = 'FETCH_TEMPLATES_FULFILLED';
export const FETCH_TEMPLATES_REJECTED = 'FETCH_TEMPLATES_REJECTED';

export const SET_DELETE_DASHBOARD_MODAL = 'SET_DELETE_DASHBOARD_MODAL';

export const SET_ABOUT_DASHBOARD_MODAL = 'SET_ABOUT_DASHBOARD_MODAL';

export const SET_SC_DASH_VIEW = 'SET_SC_DASH_VIEW';

export const SET_EDIT_DASHBOARD_MODAL = 'SET_EDIT_DASHBOARD_MODAL';

export const SET_SHARE_DASHBOARD_MODAL = 'SET_SHARE_DASHBOARD_MODAL';

export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE';

export const SET_IS_OVERVIEW = 'SET_IS_OVERVIEW';

// Performance Overview and Group Analysis action types
export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_FULFILLED = 'FETCH_EXERCISES_FULFILLED';
export const FETCH_EXERCISES_REJECTED = 'FETCH_EXERCISES_REJECTED';

export const CREATE_DASHBOARD_START = 'CREATE_DASHBOARD_START';
export const CREATE_DASHBOARD_FULFILLED = 'CREATE_DASHBOARD_FULFILLED';
export const CREATE_DASHBOARD_REJECTED = 'CREATE_DASHBOARD_REJECTED';

export const EDIT_DASHBOARD_START = 'EDIT_DASHBOARD_START';
export const EDIT_DASHBOARD_FULFILLED = 'EDIT_DASHBOARD_FULFILLED';
export const EDIT_DASHBOARD_REJECTED = 'EDIT_DASHBOARD_REJECTED';

export const DELETE_DASHBOARD_START = 'DELETE_DASHBOARD_START';
export const DELETE_DASHBOARD_FULFILLED = 'DELETE_DASHBOARD_FULFILLED';
export const DELETE_DASHBOARD_REJECTED = 'DELETE_DASHBOARD_REJECTED';

export const FETCH_SCDASH_DATA_START = 'FETCH_SCDASH_DATA_START';
export const FETCH_SCDASH_DATA_FULFILLED = 'FETCH_SCDASH_DATA_FULFILLED';
export const FETCH_SCDASH_DATA_REJECTED = 'FETCH_SCDASH_DATA_REJECTED';

export const SET_GROUP = 'SET_GROUP';

export const SET_GROUPS = 'SET_GROUPS';

export const SET_SAVE_DASHBOARD_MODAL = 'SET_SAVE_DASHBOARD_MODAL';

export const SET_TRI_OPTION_MODAL = 'SET_TRI_OPTION_MODAL';

export const SET_SELECTED_ATHLETE_PERFORMANCE = 'SET_SELECTED_ATHLETE_PERFORMANCE';
export const SET_SELECTED_ATHLETE_GROUP = 'SET_SELECTED_ATHLETE_GROUP';

export const SET_START_DATE_CAL = 'SET_START_DATE_CAL';
export const SET_START_DATE_DATA = 'SET_START_DATE_DATA';
export const SET_END_DATE_CAL = 'SET_END_DATE_CAL';
export const SET_END_DATE_DATA = 'SET_END_DATE_DATA';

export const SET_DATE_TYPE = 'SET_DATE_TYPE';

export const SET_IS_LEGEND_INFO_MODAL_SHOWING = 'SET_IS_LEGEND_INFO_MODAL_SHOWING';

export const SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING = 'SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING';
export const SET_EXER_FILTERS_PERFORMANCE = 'SET_EXER_FILTERS_PERFORMANCE';
export const SET_IS_EXER_FILTERS_GROUP_SHOWING = 'SET_IS_EXER_FILTERS_GROUP_SHOWING';
export const SET_EXER_FILTERS_GROUP = 'SET_EXER_FILTERS_GROUP';

export const SET_MAX_TYPE = 'SET_MAX_TYPE';

export const SET_EXERCISES = 'SET_EXERCISES';

export const FETCH_SHARE_DASH_LINK_START = 'FETCH_SHARE_DASH_LINK_START';
export const FETCH_SHARE_DASH_LINK_FULFILLED = 'FETCH_SHARE_DASH_LINK_FULFILLED';
export const FETCH_SHARE_DASH_LINK_REJECTED = 'FETCH_SHARE_DASH_LINK_REJECTED';

export const FETCH_PUBLIC_SCDASH_DATA_START = 'FETCH_PUBLIC_SCDASH_DATA_START';
export const FETCH_PUBLIC_SCDASH_DATA_FULFILLED = 'FETCH_PUBLIC_SCDASH_DATA_FULFILLED';
export const FETCH_PUBLIC_SCDASH_DATA_REJECTED = 'FETCH_PUBLIC_SCDASH_DATA_REJECTED';

export const CLEAR_SHARE_DASH_LINK = 'CLEAR_SHARE_DASH_LINK';

export const SET_IS_UNSAVED_DASH_MODAL_SHOWING = 'SET_IS_UNSAVED_DASH_MODAL_SHOWING';

export const SET_NEXT_LOCATION = 'SET_NEXT_LOCATION';

export const SET_TEMPLATE = 'SET_TEMPLATE';

export const SET_DUPLICATE_MODAL_SHOWING = 'SET_DUPLICATE_MODAL_SHOWING';

export const DUPLICATE_DASHBOARD_START = 'DUPLICATE_DASHBOARD_START';
export const DUPLICATE_DASHBOARD_FULFILLED = 'DUPLICATE_DASHBOARD_FULFILLED';
export const DUPLICATE_DASHBOARD_REJECTED = 'DUPLICATE_DASHBOARD_REJECTED';

export const CLEAR_SC_DASH_DATA = 'CLEAR_SC_DASH_DATA';
