import React, {
  Fragment, useEffect, useState, memo,
} from 'react';
import PropTypes from 'prop-types';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import 'react-dates/initialize';
import moment from 'moment';
import styled from '@emotion/styled';
import { componentWillAppendToBody } from 'react-append-to-body';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import { ColorGray, ColorGreen, BoxShadow } from '../../../../shared/GlobalStyles';
import ButtonRound from './ButtonRound';

import 'react-dates/lib/css/_datepicker.css';

const DatePickerContainer = styled('div')`
  tr:first-of-type {
    .CalendarDay, .CalendarDay__selected {
      border-top: 1px solid #e4e7e7;
    }
    .CalendarDay__outside {
      border-top: 0px;
      border-bottom: 1px solid #e4e7e7;
    }
  }
  & tr:last-of-type {
    border-right: 0px;
    .CalendarDay__outside {
      border-left: 1px solid #e4e7e7;
    }
    .CalendarDay__outside ~ .CalendarDay__outside{
      border-left: 0px solid #e4e7e7;
    }
  }

  .CalendarDay__selected {
    border-top: 0!important;
    border-right: 0px!important;
    border-color: #e4e7e7;
    .day-container {
      background-color: #0dcc8a!important;
    }
  }
  
  & button {
    border: 1px solid ${props => props.theme.noBorderColor};
    color: ${props => props.theme.lightTextColor};
    font-weight: 600;
  }

  & .CalendarMonth_caption {
    font-family: 'Nunito Sans';
    font-weight: 300;
  }

  & .CalendarDay {
    font-family: 'Nunito Sans';
    display: inline-flex;
    padding: 0;
    border-right: 0;
    border-top: 0;
    &:last-of-type:not(.CalendarDay__outside) {
      border-right: 1px solid #e4e7e7;
    }
    /* .CalendarDay_outside + &:last-of-type {
      border-right: 1px solid #e4e7e7;
    } */
  }
  
  .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ${ColorGreen};
    border: 1px double ${ColorGreen};
    color: #fff;
  }

  & small {
    font-family: 'Nunito Sans';
  }

  .SingleDatePickerInput {
    background-color: transparent!important;
    height: 10px;
    border: 0px;
  
    svg {
      top: 10px!important;
      left: 16px!important;
    }
  }

  .SingleDatePicker {
    position: absolute;
  }

  .DayPicker__withBorder {
    box-shadow: ${BoxShadow}, 0 0 0 1px rgba(0, 0, 0, 0.07);
  }

  .SingleDatePicker_picker {
    top: 20px!important;
  }
  
  & .DateInput_input {
    visibility: hidden;
  }
  
  & .DateInput {
    background-color: transparent;
  }

  .DateInput__block {
      width: 100%;
      padding-right: 42px;
  }

  .SingleDatePickerInput_calendarIcon {
      position: absolute;
      right: 0;
      padding: 0 10px;
      line-height: 50px;
  }
`;


const DayPickerWrapper = styled('div')`
  position: absolute;
  z-index: 10000;
  /* top: 140px; */
  top: ${props => props.top}px;
  left: ${props => props.left}px;

  /* .CalendarDay__outside {
    border: 0;
  }*/

  .CalendarMonth_caption {
    color: ${props => props.theme.textColor}
  }

  .DayPicker {
    background-color: ${props => props.theme.backgroundColorL2};
  }
  .CalendarMonthGrid, .CalendarMonth, .CalendarDay, .day-container {
    background: ${props => props.theme.backgroundColorL2}!important;
  }

  .CalendarDay__default {
    border-color: ${props => props.theme.borderColor}
  }

  .DayPicker tr:first-of-type .CalendarDay__outside {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker tr:last-of-type .CalendarDay__outside {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker tr:first-of-type .CalendarDay, .DayPicker tr:first-of-type .CalendarDay__selected {
    border-color: ${props => props.theme.borderColor};
  }

  .DayPicker .CalendarDay:last-of-type:not(.CalendarDay__outside) {
    border-color: ${props => props.theme.borderColor};
  }

  .CalendarDay__default {
    color: ${props => props.theme.textColor};
    /* border: 1px solid ${props => props.theme.borderColor}; */
  }

  .DayPickerNavigation_button__default {
    border: 1px solid ${props => props.theme.borderColor};
    background-color: ${props => props.theme.backgroundColorL3};
  }
  
  .circle {
    display: block;
    width: 5px;
    height: 5px;
    margin: 0 auto;
    border-radius: 50%;
    &.not-started-circle {
      background: #c0392b;
    }
    &.started-circle {
      background: #f39c12;
    }
    &.completed-circle {
      background: #1abc9c;
    }
  }
  .day-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const DayPickerContainer = styled('div')`
`;

const AppendMyComponent = componentWillAppendToBody(DayPickerWrapper);

const DatePicker = ({
  activeDate,
  athleteId,
  fetchWorkoutCalendar,
  namespace,
  selectDate,
  timezone,
  workoutCalendar,
}) => {
  const [currentDate, setCurrentDate] = useState(activeDate);
  const [currentFocused, setCurrentFocused] = useState(null);
  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false);

  const calendarRef = React.useRef();
  const calendarWrapperRef = React.useRef();
  const dayRef = React.useRef();
  const buttonRef = React.useRef();

  useEffect(() => {
    if (workoutCalendar.length === 0) {
      const startDate = (`${moment().subtract(0.5, 'months').format('YYYY-MM-DD')}`);

      const endDate = (`${moment().add(0.5, 'months').format('YYYY-MM-DD')}`);
      fetchWorkoutCalendar(athleteId, namespace, startDate, endDate);
      console.log('Initial Render Function', startDate + endDate);
    }
  }, []);

  const handleDateClick = () => {
    setCurrentFocused(true);
    setIsDayPickerOpen(isDayPickerOpen !== true);
  };

  const handleDateChange = (date) => {
    setIsDayPickerOpen(false);
    selectDate(date, athleteId, namespace);
    setCurrentDate(date);
  };

  const today = moment().tz(timezone);

  const notStartedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter(info => (info.status === 1))
      .map(info => (moment(info.date)))
    : [];

  const startedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter(info => (info.status === 2))
      .map(info => (moment(info.date)))
    : [];

  const completedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter(info => (info.status === 3))
      .map(info => (moment(info.date)))
    : [];

  const highlightedDays = workoutCalendar.length !== 0
    ? workoutCalendar.filter(info => (info.color !== null))
      .map(info => ({ date: moment(info.date), color: info.color }))
    : [];

  // const StartedDays = workoutInfo.filter(info => info.status === 2)
  //    .map(info => (moment(info.date)));

  const fetchCalendar = () => {
    const firstMonthArray = calendarRef.current ? Object.keys(Object.entries(calendarRef.current.state.visibleDays).sort()[0][1]) : ('');
    const lastMonthArray = calendarRef.current ? Object.keys(Object.entries(calendarRef.current.state.visibleDays).sort()[2][1]) : ('');

    const startDate = calendarRef.current ? firstMonthArray[0] : ('');
    const endDate = calendarRef.current ? lastMonthArray[lastMonthArray.length - 1] : ('');


    fetchWorkoutCalendar(athleteId, namespace, startDate, endDate);

    console.log('Fetch Calendar Function', startDate + endDate);
  };

  const isDayHighlighted = day1 => (highlightedDays.some(day2 => isSameDay(day1, day2.date)));
  const isFalse = () => false;
  const onDateChange = date => handleDateChange(date);
  const onFocusChange = ({ focused }) => setCurrentFocused(focused);
  const renderDayContents = (day) => {
    const highlightColor = () => {
      let color = '';
      highlightedDays.forEach((arrayDay) => {
        if (isSameDay(day, arrayDay.date)) {
          ({ color } = arrayDay);
        }
      });
      return color;
    };
    return (
      <Fragment>
        <span ref={dayRef} style={{ borderColor: highlightColor(), backgroundColor: highlightColor() }} className='day-container'>
          {day.format('D')}
          {notStartedDays.some(arrayDay => isSameDay(day, arrayDay)) ? (<span className='circle not-started-circle' />) : ('')}
          {startedDays.some(arrayDay => isSameDay(day, arrayDay)) ? (<span className='circle started-circle' />) : ('')}
          {completedDays.some(arrayDay => isSameDay(day, arrayDay)) ? (<span className='circle completed-circle' />) : ('')}
          {highlightedDays.some(arrayDay => isSameDay(day, arrayDay.date)) ? (<span className='highlighted' />) : ('')}
        </span>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <DatePickerContainer>
        <ButtonRound
          onClick={handleDateClick}
          cta={currentDate === null ? today.format('MMMM Do YYYY') : currentDate.format('MMMM Do YYYY')}
          icon='calendar'
          gray
          buttonRef={buttonRef}
        />
        {isDayPickerOpen && (
          <AppendMyComponent
            isHighlighted={day => (
              highlightedDays.some(arrayDay => isSameDay(day, arrayDay.date))
            )}
            // Fix for bottom panels //
            top={
              buttonRef.current.getBoundingClientRect().bottom > 400
                ? buttonRef.current.getBoundingClientRect().bottom
                  - (calendarWrapperRef.current
                    ? calendarWrapperRef.current.getBoundingClientRect().height
                    : 300)
                  - buttonRef.current.getBoundingClientRect().height
                : buttonRef.current.getBoundingClientRect().bottom
            }
            left={buttonRef.current.getBoundingClientRect().left}
          >
            <DayPickerContainer
              ref={calendarWrapperRef}
            >
              <DayPickerSingleDateController
                date={currentDate}
                enableOutsideDays
                focused={currentFocused}
                isOutsideRange={isFalse}
                isDayHighlighted={isDayHighlighted}
                hideKeyboardShortcutsPanel
                keepOpenOnDateSelect={false}
                numberOfMonths={1}
                onDateChange={onDateChange}
                onFocusChange={onFocusChange}
                onPrevMonthClick={fetchCalendar}
                onNextMonthClick={fetchCalendar}
                renderDayContents={renderDayContents}
                ref={calendarRef}
              />
            </DayPickerContainer>
          </AppendMyComponent>
        )}
      </DatePickerContainer>
    </Fragment>
  );
};

DatePicker.propTypes = {
  athleteId: PropTypes.number.isRequired,
  activeDate: PropTypes.instanceOf(Object).isRequired,
  fetchWorkoutCalendar: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  selectDate: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  workoutCalendar: PropTypes.instanceOf(Object),
};

DatePicker.defaultProps = {
  workoutCalendar: [],
};

export default memo(DatePicker);
