import {
  SET_RECOVERY_RADAR_VIEW,
  SET_GROUP,
  FETCH_REGION_STATUS_START,
  FETCH_REGION_STATUS_FULFILLED,
  FETCH_REGION_STATUS_REJECTED,
  SET_REGION_STATUS_START,
  SET_REGION_STATUS_FULFILLED,
  SET_REGION_STATUS_REJECTED,
  SET_SELECTED_ATHLETE,
  SET_SELECTED_DATE_CAL,
  SET_SELECTED_DATE_DATA,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  SET_DATE_RANGE,
  FETCH_GROUP_ANALYSIS_DATA_START,
  FETCH_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_GROUP_ANALYSIS_DATA_REJECTED,
  SET_IS_FILTER_OPEN,
  SET_ACTIVE_FILTERS,
  SET_ACTIVE_TYPE_FILTERS,
  SET_ATHLETE_RADIO_VALUE,
  SET_ACTIVE_FILTERS_OVERVIEW,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_START,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_START,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED,
  CLEAR_USER_HISTORY_DATA,
  CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA,
  SET_RECOVERY_RADAR_ACTIVE_MODAL,
  SET_PRINT_CURRENT_PAGE,
  FETCH_OVERVIEW_DATA_START,
  FETCH_OVERVIEW_DATA_FULFILLED,
  FETCH_OVERVIEW_DATA_REJECTED,
  SET_SHOW_FLAGGED_ATHLETES_MODAL,
  SET_FLAGGED_ATHLETES_VIEW,
  SET_ATHLETES_TO_REVIEW,
  FETCH_VIEWED_FEATURE_START,
  FETCH_VIEWED_FEATURE_FULFILLED,
  FETCH_VIEWED_FEATURE_REJECTED,
  SET_ONBOARDING_MODAL_OPEN,
  SET_VIEWED_FEATURE_START,
  SET_VIEWED_FEATURE_FULFILLED,
  SET_VIEWED_FEATURE_REJECTED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { FEATURES, hasViewedFeature } from '../../../shared/utils/onboardingStatus';
import { onboardingTracker } from '../../../shared/utils/amplitudeHelper';

export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_GROUPS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUPS_REJECTED,
        });
      });
  }
);

export const setRecoveryRadarView = (view) => (
  (dispatch) => {
    dispatch({
      payload: view,
      type: SET_RECOVERY_RADAR_VIEW,
    });
  }
);

export const setGroup = (group) => (
  (dispatch) => {
    dispatch({
      payload: group,
      type: SET_GROUP,
    });
  }
);

export const setSelectedAthlete = (athlete) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
      },
      type: SET_SELECTED_ATHLETE,
    });
  }
);

export const setSelectedDateCal = (date) => (
  (dispatch) => {
    dispatch({
      payload: date,
      type: SET_SELECTED_DATE_CAL,
    });
  }
);

export const setSelectedDateData = (date) => (
  (dispatch) => {
    dispatch({
      payload: date,
      type: SET_SELECTED_DATE_DATA,
    });
  }
);

export const setIsFilterOpen = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_FILTER_OPEN,
    });
  }
);

export const setActiveFilters = (array) => (
  (dispatch) => {
    dispatch({
      payload: array,
      type: SET_ACTIVE_FILTERS,
    });
  }
);

export const setActiveFiltersOverview = (array) => (
  (dispatch) => {
    dispatch({
      payload: array,
      type: SET_ACTIVE_FILTERS_OVERVIEW,
    });
  }
);

export const setActiveTypeFilters = (array) => (
  (dispatch) => {
    dispatch({
      payload: array,
      type: SET_ACTIVE_TYPE_FILTERS,
    });
  }
);

export const setAthleteRadioValue = (value) => (
  (dispatch) => {
    dispatch({
      payload: value,
      type: SET_ATHLETE_RADIO_VALUE,
    });
  }
);

export const setRecoveryRadarActiveModal = (name, additionalDataObject) => (
  (dispatch) => {
    dispatch({
      payload: {
        name,
        data: additionalDataObject,
      },
      type: SET_RECOVERY_RADAR_ACTIVE_MODAL,
    });
  }
);

export const setPrintCurrentPage = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_PRINT_CURRENT_PAGE,
    });
  }
);

// OVERVIEW
export const fetchOverviewData = (
  accountCode, groupId, startDate, endDate, issueTypes, bodyParts,
) => (
  (dispatch) => {
    let issuesQuery = '';
    if (issueTypes.length > 0) {
      issuesQuery = `&issueTypes=${issueTypes}`;
    }
    let bpQuery = '';
    if (bodyParts.length > 0) {
      bpQuery = `&bodyPartIds=${bodyParts}`;
    }

    dispatch({ type: FETCH_OVERVIEW_DATA_START });
    axiosAuthed.get(`accounts/${accountCode}/reports/body-heat-map/overview?groupId=${groupId}&startDate=${startDate}&endDate=${endDate}${issuesQuery}${bpQuery}`)
      .then((response) => {
        dispatch({
          payload: {
            cardData: response.data.summaryCards,
            chartData: response.data.lineGraphData,
            defaultFilters: response.data.defaultFilterBodyParts,
            heatMapData: response.data.heatMapDisplayData,
            overviewGroupAverageData: response.data.averageData,
            overviewTableData: response.data.historyTableData,
          },
          type: FETCH_OVERVIEW_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_OVERVIEW_DATA_REJECTED,
        });
      });
  }
);

export const setDateRange = (range) => (
  (dispatch) => {
    dispatch({
      payload: range,
      type: SET_DATE_RANGE,
    });
  }
);

export const setFlaggedAthletesView = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_FLAGGED_ATHLETES_VIEW,
    });
  }
);

export const setShowFlaggedAthletesModal = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_SHOW_FLAGGED_ATHLETES_MODAL,
    });
  }
);

export const setAthletesToReview = (athletesToReview) => (
  (dispatch) => {
    dispatch({
      payload: athletesToReview,
      type: SET_ATHLETES_TO_REVIEW,
    });
  }
);

// ANALYSIS
export const fetchGroupAnalysisData = (
  accountCode, groupId, startDate, endDate, issueTypes, bodyParts,
) => (
  (dispatch) => {
    let issuesQuery = '';
    if (issueTypes.length > 0) {
      issuesQuery = `&issueTypes=${issueTypes}`;
    }
    let bpQuery = '';
    if (bodyParts.length > 0) {
      bpQuery = `&bodyPartIds=${bodyParts}`;
    }

    dispatch({ type: FETCH_GROUP_ANALYSIS_DATA_START });
    axiosAuthed.get(`accounts/${accountCode}/reports/body-heat-map/group-analysis?groupId=${groupId}&startDate=${startDate}&endDate=${endDate}${issuesQuery}${bpQuery}`)
      .then((response) => {
        dispatch({
          payload: {
            alertAverageData: response.data.alertAverageData,
            athletesToReview: response.data.alertUserData,
            groupAverageData: response.data.averageData,
            numOfAlertUsers: response.data.numOfAlertUsers,
            tableData: response.data.chartData,
          },
          type: FETCH_GROUP_ANALYSIS_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUP_ANALYSIS_DATA_REJECTED,
        });
      });
  }
);

// HISTORY
export const fetchUsersAssociatedWithGroup = (accountCode, groupId) => (
  (dispatch) => {
    dispatch({ type: FETCH_USERS_ASSOCIATED_WITH_GROUP_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups/${groupId}/user-detail`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED,
        });
      });
  }
);

export const fetchUserHistoryData = (
  accountCode,
  athleteId,
  startDate,
  endDate,
  issueTypes,
  bodyParts,
) => (
  (dispatch) => {
    let issuesQuery = '';
    if (issueTypes.length > 0) {
      issuesQuery = `&issueTypes=${issueTypes}`;
    }
    let bpQuery = '';
    if (bodyParts.length > 0) {
      bpQuery = `&bodyPartIds=${bodyParts}`;
    }

    dispatch({ type: FETCH_USER_HISTORY_HEAT_MAP_DATA_START });
    axiosAuthed.get(`accounts/${accountCode}/reports/body-heat-map/${athleteId}?startDate=${startDate}&endDate=${endDate}${issuesQuery}${bpQuery}`)
      .then((response) => {
        dispatch({
          payload: {
            areaSelectedAverageData: response.data.areaSelectedAverageData,
            areaSelectedTableData: response.data.areaSelectedData,
            historyAverageData: response.data.historyTableAverageData,
            historyHeatMapData: response.data.bodyMapData,
            historyTableData: response.data.historyTableData,
          },
          type: FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED,
        });
      });
  }
);

export const clearAthleteHistoryData = () => (
  (dispatch) => {
    dispatch({
      type: CLEAR_USER_HISTORY_DATA,
    });
  }
);

export const clearUsersAssociatedWithGroupData = () => (
  (dispatch) => {
    dispatch({
      type: CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA,
    });
  }
);

// SETTINGS
export const fetchRegionStatusToggle = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_REGION_STATUS_START });
    axiosAuthed.get(`/accounts/${accountCode}/sport-science/settings`)
      .then((response) => {
        dispatch({
          payload: response.data.heatMapTagsEnabled,
          type: FETCH_REGION_STATUS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_REGION_STATUS_REJECTED,
        });
      });
  }
);

export const setRegionStatusToggle = (accountCode, body) => (
  (dispatch) => {
    dispatch({ type: SET_REGION_STATUS_START });
    axiosAuthed.put(`/accounts/${accountCode}/sport-science/settings`, body)
      .then((response) => {
        dispatch({
          payload: response.data.heatMapTagsEnabled,
          type: SET_REGION_STATUS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: SET_REGION_STATUS_REJECTED,
        });
      });
  }
);

export const setOnboardingModalOpen = (bool) => ({
  type: SET_ONBOARDING_MODAL_OPEN,
  payload: bool,
});

export const fetchViewedFeatures = () => (
  (dispatch) => {
    dispatch({ type: FETCH_VIEWED_FEATURE_START });
    axiosAuthed.get('/me/features/viewed-features')
      .then((response) => {
        const isFeatureViewed = hasViewedFeature(response.data, FEATURES.RECOVERY_RADAR);
        if (!isFeatureViewed) {
          dispatch(setOnboardingModalOpen(true));
          // Amplitude tracking
          onboardingTracker('Modal Opened', 'Recovery Radar', 'Onboarding modal opened on user\'s first view of dashboard');
        }
        dispatch({ type: FETCH_VIEWED_FEATURE_FULFILLED });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_VIEWED_FEATURE_REJECTED,
        });
      });
  }
);

export const setViewedFeature = (viewedFeaturePayload) => (
  (dispatch) => {
    dispatch({ type: SET_VIEWED_FEATURE_START });
    axiosAuthed.post('/me/features/viewed-features', viewedFeaturePayload)
      .then(() => {
        dispatch({ type: SET_VIEWED_FEATURE_FULFILLED });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: SET_VIEWED_FEATURE_REJECTED,
        });
      });
  }
);
