import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_START_DATE,
  SET_END_DATE,
  SET_HABIT_TRACKING_VIEW,
  SET_SELECTED_ATHLETE_GROUP,
  SET_GROUP,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  FETCH_ATHLETES_START_HT_DASHBOARD,
  FETCH_ATHLETES_FULFILLED_HT_DASHBOARD,
  FETCH_ATHLETES_REJECTED_HT_DASHBOARD,
  FETCH_HABITS_FOR_ACCOUNT_START,
  FETCH_HABITS_FOR_ACCOUNT_FULFILLED,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_OVERVIEW_START,
  FETCH_OVERVIEW_FULFILLED,
  FETCH_OVERVIEW_REJECTED,
  FETCH_GROUP_OVERVIEW_START,
  FETCH_GROUP_OVERVIEW_FULFILLED,
  FETCH_GROUP_OVERVIEW_REJECTED,
  FETCH_GROUP_HABITS_START,
  FETCH_GROUP_HABITS_FULFILLED,
  FETCH_GROUP_HABITS_REJECTED,
  SET_SELECTED_HABITS,
  RESET_OVERVIEW_DATA,
  SET_WEEK_RANGE,
  SET_SELECTED_GROUP_HABITS,
  RESET_GROUP_OVERVIEW_DATA,
  SET_SELECTED_GROUP_DROPDOWN_OPTIONS,
  SET_IS_GROUP_FILTER_SHOWING,
  SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS,
  SET_IS_CHART_OPAQUE,
  SET_ONBOARDING_MODAL_OPEN,
  SET_HABITS,
  FETCH_GROUP_OVERVIEW_HABITS_FULFILLED,
  FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const setHabits = (habits) => (
  (dispatch) => {
    dispatch({
      payload: habits,
      type: SET_HABITS,
    });
  }
);

export const setOnboardingModalOpen = (isOpen) => ({
  type: SET_ONBOARDING_MODAL_OPEN,
  payload: isOpen,
});
export const setIsChartOpaque = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_CHART_OPAQUE,
    });
  }
);
export const setIsGroupFilterShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_GROUP_FILTER_SHOWING,
    });
  }
);

export const setSelectedPerformanceDropdownOptions = (options) => (
  (dispatch) => {
    dispatch({
      payload: options,
      type: SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS,
    });
  }
);

export const setSelectedGroupDropdownOptions = (options) => (
  (dispatch) => {
    dispatch({
      payload: options,
      type: SET_SELECTED_GROUP_DROPDOWN_OPTIONS,
    });
  }
);

export const setSelectedHabits = (habits) => (
  (dispatch) => {
    dispatch({
      payload: habits,
      type: SET_SELECTED_HABITS,
    });
  }
);

export const setSelectedGroupHabits = (habits) => (
  (dispatch) => {
    dispatch({
      payload: habits,
      type: SET_SELECTED_GROUP_HABITS,
    });
  }
);

export const resetGroupOverviewData = () => (
  (dispatch) => {
    dispatch({
      type: RESET_GROUP_OVERVIEW_DATA,
    });
  }
);

export const resetOverviewData = () => (
  (dispatch) => {
    dispatch({
      type: RESET_OVERVIEW_DATA,
    });
  }
);
export const fetchViewedFeatures = () => axiosAuthed.get('/me/features/viewed-features')
  .then((response) => response.data, // Return the data directly
  )
  .catch((err) => {
    console.error('Error fetching viewed features', err);
    throw err; // Re-throw the error so it can be caught in the calling function
  });

export const setViewedFeature = (viewedFeaturePayload) => (dispatch) => axiosAuthed.post('/me/features/viewed-features', viewedFeaturePayload)
  .then(() => {

  })
  .catch((err) => {
    console.error('Error setting viewed feature', err);
  });
export const fetchAthletesHabitDashboard = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_ATHLETES_START_HT_DASHBOARD });
  axiosAuthed
    .get(`/accounts/${accountCode}/users?userType=0`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ATHLETES_FULFILLED_HT_DASHBOARD,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ATHLETES_REJECTED_HT_DASHBOARD,
      });
    });
};

export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_GROUPS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUPS_REJECTED,
        });
      });
  }
);
export const fetchHabitsForAccount = (accountCode, userId, type = '') => (dispatch) => new Promise((resolve, reject) => {
  const url = `/accounts/${accountCode}/users/${userId}/habits/assigned`;

  dispatch({ type: FETCH_HABITS_FOR_ACCOUNT_START });

  axiosAuthed.get(url)
    .then((response) => {
      if (type === 'GroupOverview') {
        dispatch({ type: FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED, payload: response.data });
      } else {
        dispatch({ type: FETCH_HABITS_FOR_ACCOUNT_FULFILLED, payload: response.data });
      }
      resolve(response.data);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_HABITS_FOR_ACCOUNT_REJECTED,
        payload: err.response.headers.message,
      });
      reject(err.response.headers.message);
    });
});

export const fetchOverview = (
  accountCode,
  filterType,
  id,
  habitsArray,
  startDate,
  endDate,
) => (
  (dispatch) => {
    // filtertype 1 for athlete, 2 for group
    // filterId is the id of the athlete or group
    const habitsString = habitsArray.join(',');
    const url = `/accounts/${accountCode}/reports/habits/completion-overview?filterType=${filterType}&filterId=${id}&habitIds=${habitsString}&startDate=${startDate}&endDate=${endDate}`;

    dispatch({ type: FETCH_OVERVIEW_START });
    axiosAuthed.get(url)
      .then((response) => {
        dispatch({ type: FETCH_OVERVIEW_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_OVERVIEW_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);
export const fetchGroupHabits = (accountCode, userId, type = '') => (dispatch) => new Promise((resolve, reject) => {
  const url = `/accounts/${accountCode}/groups/${userId}/habits`;

  dispatch({ type: FETCH_GROUP_HABITS_START });

  axiosAuthed.get(url)
    .then((response) => {
      if (type === 'GroupOverview') {
        dispatch({ type: FETCH_GROUP_OVERVIEW_HABITS_FULFILLED, payload: response.data });
      } else {
        dispatch({ type: FETCH_GROUP_HABITS_FULFILLED, payload: response.data });
      }
      resolve(response.data);
    })
    .catch((err) => {
      dispatch({
        type: FETCH_GROUP_HABITS_REJECTED,
        payload: err.response.headers.message,
      });
      reject(err.response.headers.message);
    });
});

export const fetchGroupOverview = (
  accountCode,
  filterType,
  id,
  habitsArray,
) => (
  (dispatch) => {
    // filtertype 1 for athlete, 2 for group
    // filterId is the id of the athlete or group
    const habitsString = habitsArray.join(',');
    const url = `/accounts/${accountCode}/reports/habits/group-analysis?filterType=${filterType}&filterId=${id}&habitIds=${habitsString}`;

    dispatch({ type: FETCH_GROUP_OVERVIEW_START });
    axiosAuthed.get(url)
      .then((response) => {
        dispatch({ type: FETCH_GROUP_OVERVIEW_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_GROUP_OVERVIEW_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const setIsLegendInfoModalShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
    });
  }
);

export const setSelectedAthleteGroup = (athlete, bool) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
        bool,
      },
      type: SET_SELECTED_ATHLETE_GROUP,
    });
  }
);

export const setIsMetricFiltersPerformanceShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
    });
  }
);

export const setIsMetricFiltersGroupShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_METRIC_FILTERS_GROUP_SHOWING,
    });
  }
);

export const setMetricFiltersPerformance = (metrics) => (
  (dispatch) => {
    dispatch({
      payload: metrics,
      type: SET_METRIC_FILTERS_PERFORMANCE,
    });
  }
);

export const setMetricFiltersGroup = (metrics) => (
  (dispatch) => {
    dispatch({
      payload: metrics,
      type: SET_METRIC_FILTERS_GROUP,
    });
  }
);

export const setGroupFilterRadio = (option) => (
  (dispatch) => {
    dispatch({
      payload: option,
      type: SET_GROUP_FILTER_RADIO,
    });
  }
);

export const setGroup = (group) => (
  (dispatch) => {
    dispatch({
      payload: group,
      type: SET_GROUP,
    });
  }
);

export const setStartDateCal = (startDate) => (
  (dispatch) => {
    dispatch({
      payload: startDate,
      type: SET_START_DATE,
    });
  }
);

export const setEndDateCal = (endDate) => (
  (dispatch) => {
    dispatch({
      payload: endDate,
      type: SET_END_DATE,
    });
  }
);

export const setWeekRange = (range) => (
  (dispatch) => {
    dispatch({
      payload: range,
      type: SET_WEEK_RANGE,
    });
  }
);

export const setHabitTrackingView = (view) => (
  (dispatch) => {
    dispatch({
      payload: view,
      type: SET_HABIT_TRACKING_VIEW,
    });
  }
);
