/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import ExertionsScoreModal from './ExertionScoreModal';

import { setExertionScoreModalShowing } from '../../ducks/workoutsActions';

const ExertionScoreOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const ExertionScoreExpModalContainer = () => {
  const exertionScoreModalShowing = useSelector(
    (state) => state.workouts.data.exertionScoreModalShowing,
  );

  const dispatch = useDispatch();

  return (
    <>
      <ExertionsScoreModal />
      <ExertionScoreOverlay
        isActive={exertionScoreModalShowing}
        onClick={() => dispatch(setExertionScoreModalShowing(false))}
      />
    </>
  );
};

ExertionScoreExpModalContainer
  .propTypes = { };

export default ExertionScoreExpModalContainer;
