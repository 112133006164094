/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import NoHoverButton from './modals/NoHoverButton';

import {
  setQuickJournalModal,
  updateJournalEntry,
  writeWorkoutNoteToAthlete,
  createMeJournalEntry,
  setActiveWorkoutsMedia,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  height: 65%;
  width: 50vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  z-index: 1003;
  max-width: 600px;
  min-width: 300px;
  max-height: 650px;
  box-shadow: ${BoxShadow};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: absolute; 
  border-radius: 12px;
  overflow: auto;
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-height: 650px;
  .modal-button {
    position: absolute;
    bottom: 0;
    color: white;
    height: 55px;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  .arrow-div {
    cursor: pointer;
    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const PrimaryContent = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 55px;
`;

const StyledInput = styled('input')`
	font-family: 'Nunito Sans';
	font-weight: 700;
	font-size: 13px;
	border: 1px solid #E8E8E8;
  width: 100%;
  border-radius: 5px;
  height: 38px;
  padding-left: 10px;
  background: #F8F8F8;
  margin-top: 10px;
`;

const OptionalCaptionInput = styled('div')`
	display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
`;

const TitleText = styled('div')`
	font-family: 'Nunito Sans';
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-left: 20px;
`;

const MediaBox = styled('div')`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  background-color: black;
  .media-x-icon {
    position: absolute;
    top: -7px;
    right: -4px;
    padding: 4px;
    background: #d9d9d9;
    border-radius: 50%;
    pointer-events: stroke;
  }
  .play-icon {
    padding: 10px;
    background-color: #ffffff73;
    border-radius: 50%;
  }
  .open-media-div {
    color: #ffffff03;
    background: #ffffff05;
    width: 40px;
    height: 40px;
  }
`;

const QuickJournalModal = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const quickWorkoutsJournal = useSelector((state) => state.workouts.data.quickWorkoutsJournal);
  const quickJournalMedia = useSelector((state) => state.workouts.data.quickJournalMedia);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const quickJournalModalShowing = useSelector((state) => state.workouts.data.quickJournalModal);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );

  const [optionalText, setOptionalText] = useState('');

  const dispatch = useDispatch();

  const handleCreateQuickJournal = () => {
    const submittingValues = {};
    submittingValues.title = quickWorkoutsJournal?.workoutObject?.exercise.name;
    if (!optionalText) {
      submittingValues.body = quickWorkoutsJournal?.workoutObject?.journalEntries.length
      && quickWorkoutsJournal?.workoutObject?.journalEntries[0]?.body
        ? quickWorkoutsJournal?.workoutObject?.journalEntries[0]?.body
        : '';
    } else {
      submittingValues.body = optionalText;
    }
    if (!Object.keys(currentNonDateDay).length) {
      submittingValues.dateAssigned = activeWorkoutDate;
    }
    submittingValues.private = !submittingValues.private;
    let existingMedia = false;
    let mediaArray = [];
    if (quickWorkoutsJournal?.workoutObject?.journalEntries.length) {
      if (quickWorkoutsJournal?.workoutObject?.journalEntries[0].media
        && quickWorkoutsJournal?.workoutObject?.journalEntries[0].media.length) {
        existingMedia = true;
      }
    }
    if (existingMedia) {
      mediaArray = quickWorkoutsJournal?.workoutObject?.journalEntries[0].media.map(
        (media) => media.id,
      );
    }
    mediaArray.push(quickJournalMedia.id);
    submittingValues.media = mediaArray;
    submittingValues.private = false;
    if (quickWorkoutsJournal?.workoutObject?.assignedId) {
      submittingValues.associatedWorkoutAssignedId = quickWorkoutsJournal?.workoutObject?.assignedId;
    } else if (quickWorkoutsJournal?.workoutObject?.saveDataId) {
      submittingValues.associatedDayBasedWorkoutId = currentSelectedProgram.dayBasedProgramId;
      submittingValues.associatedDayBasedDayNum = currentNonDateDay.trueDayNum;
      submittingValues.associatedSaveDataId = quickWorkoutsJournal?.workoutObject?.saveDataId;
    }
    if (currentUser.admin) {
      submittingValues.userId = sharedAthlete.id;
      submittingValues.private = false;
      if (quickWorkoutsJournal?.workoutObject?.journalEntries.length) {
        dispatch(
          updateJournalEntry(
            currentUser.accountCode,
            submittingValues,
            quickWorkoutsJournal?.workoutObject?.journalEntries[0].id,
            quickWorkoutsJournal?.index,
            quickWorkoutsJournal?.sessionIndex,
          ),
        );
      } else {
        dispatch(writeWorkoutNoteToAthlete(
          currentUser.accountCode,
          submittingValues,
          quickWorkoutsJournal?.index,
          quickWorkoutsJournal?.sessionIndex,
        ));
      }
    } else {
      submittingValues.userId = currentUser.id;
      if (quickWorkoutsJournal?.workoutObject?.journalEntries.length) {
        dispatch(
          updateJournalEntry(
            currentUser.accountCode,
            submittingValues,
            quickWorkoutsJournal?.workoutObject?.journalEntries[0].id,
            quickWorkoutsJournal?.index,
            quickWorkoutsJournal?.sessionIndex,
          ),
        );
      } else {
        dispatch(
          createMeJournalEntry(
            currentUser.accountCode,
            submittingValues,
            quickWorkoutsJournal?.index,
            quickWorkoutsJournal?.sessionIndex,
          ),
        );
      }
    }
    dispatch(setQuickJournalModal(false));
  };

  return (
    <ModalContainer isActive={quickJournalModalShowing}>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            dispatch(setQuickJournalModal(false));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
            color='black'
          />
        </Text>
      </NavigationWrapper>
      <PrimaryContent>
        <TitleText>
          Workouts Media
        </TitleText>
        <MediaBox
          key={quickJournalMedia.id}
          style={{
            backgroundImage: `url(${quickJournalMedia.type === 'VIDEO' ? quickJournalMedia.thumbnailUrl : quickJournalMedia.mediaUrl})`,
          }}
        >
          {quickJournalMedia.type === 'IMAGE' || quickJournalMedia.type === 'EXTERNAL_URL' ? (
            <div
              className='open-media-div'
              onClick={() => {
                dispatch(setActiveWorkoutsMedia(quickJournalMedia));
              }}
            >
              open image
            </div>
          ) : null }
          {quickJournalMedia.type === 'VIDEO' ? (
            <div
              onClick={() => {
                dispatch(setActiveWorkoutsMedia(quickJournalMedia));
              }}
            >
              <IcomoonReact
                className='play-icon'
                iconSet={iconSet}
                size={40}
                icon='play-filled'
                color='white'
              />
            </div>
          ) : null}
        </MediaBox>
        <OptionalCaptionInput>
          Caption
          <StyledInput
            value={optionalText}
            placeholder='Add a caption (optional)'
            onChange={(e) => {
              setOptionalText(e.target.value);
            }}
          />
        </OptionalCaptionInput>
      </PrimaryContent>
      <NoHoverButton
        bottom
        fullWidth
        cta='Add to Journal'
        customColor='#10cd8c'
        className='modal-button'
        onClick={() => handleCreateQuickJournal()}
        large
        noBorder
        primary
        square
        noHover
      />
    </ModalContainer>
  );
};

QuickJournalModal.propTypes = {

};

export default QuickJournalModal;
