/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';
import IcomoonReact from 'icomoon-react';

import { useDispatch } from 'react-redux';
import iconSet from '../../images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../GlobalStyles';
import { setActiveWorkoutsMedia } from '../../../modules/workouts/ducks/workoutsActions';
import generateUniqueId from '../../utils/generateUniqueId';

const DropDownContainer = styled('div')`
  width: ${(props) => `${props.width}%`};
  position: relative;
`;

const DropDownHeader = styled('input')`
  font-weight: 500;
  color: grey;
  background: #ffffff;
	user-select: none;
  border-radius: 4px;
	cursor: default;
	min-height: 38px;
	padding: 8px;
	width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  z-index: 100;
  border: none;
  flex: 1;
`;

const DropDownListContainer = styled('div')`
	position: absolute;
  z-index: 101;
  width: 100%;
`;

const DropDownList = styled('div')`
  background: white;
	color: darkgrey;
  font-weight: 500;
  box-shadow: ${BoxShadow};
	border-radius: 4px;
  margin-top: 10px;
`;

const ListItem = styled('div')`
  margin-bottom: 0.8em;
	padding-left: 8px;
	padding-top: 8px;
	padding-bottom: 8px;
	:hover {
		background: ${(props) => (!props.fake ? 'rgba(176,196,222, 0.5)' : 'none')};
	}
	display: flex;
	align-items: center;
  color: dimgrey;
`;

const HeaderAndIcon = styled('div')`
  width: 100%;
  display: flex;
  :hover {
		border-color: #C0C0C0;
	}
	border-radius: 4px;
  border: 1px solid #D8D8D8;
  align-items: center;
  height: 100%;
`;

const HeaderDivider = styled('div')`
  border: 0.5px solid lightgrey;
  height: 60%;
`;

const IconContainer = styled('div')`
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectBoxItemRenderer = ({
  data, index, style,
}) => {
  const {
    itemList, onOptionClicked, label, dispatch,
  } = data;
  if (itemList[index]) {
    return (
      <ListItem
        fake={itemList[index].fake}
        style={style}
      >
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <div
            onClick={() => {
              if (!itemList[index].fake) {
                onOptionClicked(itemList[index]);
              }
            }}
            style={{
              display: 'flex',
              flex: 1,
            }}
          >
            {itemList[index][label]}
          </div>
          {itemList[index].media ? (
            <div
              onClick={() => dispatch(setActiveWorkoutsMedia(itemList[index].media[0]))}
              style={{
                width: '30px',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='video'
                color='dimgrey'
              />
            </div>
          ) : null}
        </div>
      </ListItem>
    );
  }
  return null;
};

/**
 * @param {Func} selectItemFunction this is the function that gets called with the selected item
 * when the item is clicked - generally goes well with a setSelected useState in the parent
 * component
 * @param {String} label the prop used to determine what's displayed in the list and in the selected
 * item - so if the list of options looks like this:
 * [{ id: 1, name: option1 }, { id: 2, name: option2 }]
 * and we want 'name' to be the value that's displayed in the select box and in the list, then the
 * string 'name' should be passed as the label prop - required to display properly
 * @param {Array} list an array of options to be passed into the windowed list
 * @param {Number} headerWidthPercentage number value to control the width of the header expressed
 * as a percentage
 * @param {Number} dropDownHeightPx number value to control the height of the dropdown
 * container expressed as a fixed pixel value
 * @param {Number} dropDownWidthPx number value to control the width of the dropdown
 * container expressed as a fixed pixel value
 * @returns A windowed list paired with selecting functionality.  By default, TBSelectBox
 * manages its own state about which item is currently displayed based on user intereaction.
 * It's posssible that the item currently displayed in the select box header is not the item that
 * the user is intending on managing, so it's up to the user to ensure that the item that's
 * displayed is the item under focus by using state in the parent component and selectItemFunction
 */
const TBSelectBox = ({
  selectItemFunction,
  label,
  list = [],
  defaultOption = {},
  headerWidthPercentage = 100,
  dropDownHeightPx = 200,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [adaptiveDropDownHeight, setAdaptiveDropDownHeight] = useState(dropDownHeightPx);

  const dispatch = useDispatch();

  const onOptionClicked = (clickedOption) => {
    setSelectedOption(clickedOption[label]);
    setIsOpen(false);
    selectItemFunction(clickedOption);
    setIsUserSearching(false);
    setSearchTerm('');
  };

  useEffect(() => {
    if (Object.keys(defaultOption).length) {
      setSelectedOption(defaultOption[label]);
    } else {
      setSelectedOption('');
    }
  }, [defaultOption]);

  useEffect(() => {
    if (isUserSearching && searchedList.length <= 4) {
      setAdaptiveDropDownHeight(searchedList.length * 50);
    } else if (isUserSearching && searchedList.length === 0) {
      setAdaptiveDropDownHeight(50);
    } else if (list.length <= 4) {
      setAdaptiveDropDownHeight(list.length * 50);
    } else {
      setAdaptiveDropDownHeight(dropDownHeightPx);
    }
  }, [searchedList, list]);

  useEffect(() => {
    const searchedArray = [];
    list.forEach((object) => {
      if (object[label].toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(object);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList([{
        id: generateUniqueId(),
        name: 'No matches found',
        fake: true,
      }]);
    }
  }, [searchTerm]);

  const listMaker = () => {
    if (isUserSearching) {
      if (searchedList.length) {
        return searchedList;
      }
    }
    return list;
  };

  return (
    <DropDownContainer width={headerWidthPercentage}>
      <HeaderAndIcon onClick={() => setIsOpen(!isOpen)}>
        <DropDownHeader
          placeholder='Select...'
          value={isUserSearching ? searchTerm : selectedOption}
          defaultValue={defaultOption}
          onChange={(inputValue) => {
            setSearchTerm(inputValue.target.value);
          }}
          onKeyDown={() => {
            if (selectedOption) {
              setSelectedOption('');
              selectItemFunction({});
            }
            setIsUserSearching(true);
          }}
        />
        <HeaderDivider />
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='down-arrow'
            color='lightgrey'
          />
        </IconContainer>
      </HeaderAndIcon>
      {isOpen ? (
        <DropDownListContainer>
          <DropDownList>
            <FixedSizeList
              height={adaptiveDropDownHeight}
              itemData={{
                itemList: listMaker(),
                onOptionClicked,
                label,
                dispatch,
              }}
              itemKey={(index, data) => {
                const { itemList } = data;
                const item = itemList[index];
                if (item) {
                  return item.id;
                }
                return null;
              }}
              itemCount={!isUserSearching ? list.length : searchedList.length}
              itemSize={50}
            >
              {SelectBoxItemRenderer}
            </FixedSizeList>
          </DropDownList>
        </DropDownListContainer>
      ) : null}
    </DropDownContainer>
  );
};

SelectBoxItemRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
  // dispatch: PropTypes.func.isRequired,
};

TBSelectBox.defaultProps = {
  defaultOption: {},
  headerWidthPercentage: 100,
  dropDownHeightPx: 200,
};

TBSelectBox.propTypes = {
  selectItemFunction: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.instanceOf(Array).isRequired,
  defaultOption: PropTypes.instanceOf(Object),
  headerWidthPercentage: PropTypes.number,
  dropDownHeightPx: PropTypes.number,
};

export default TBSelectBox;
