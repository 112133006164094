import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { ColorPrimary } from '../../../../shared/GlobalStyles';

const AthleteNameWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 10px;

  @media screen and (orientation: portrait) {
    margin-left: 10px;
  }
`;

const Name = styled('h3')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  color: ${ColorPrimary};
  margin-bottom: 0px;
  /* margin-right: 15px; */
  align-items: center;
  line-height: 16px;
  color: white;
  @media screen and (orientation: portrait) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props => (props.small ? '85px;' : 'none')}
  }
`;

const AthleteName = ({
  name,
  small,
}) => (
  <AthleteNameWrapper>
    <Name
      small={small}
    >
      {name}
    </Name>

  </AthleteNameWrapper>
);

AthleteName.propTypes = {
  name: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

AthleteName.defaultProps = {
  small: false,
};

export default memo(AthleteName);
