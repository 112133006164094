import update from 'immutability-helper';

import {
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_FULFILLED,
  FETCH_TEMPLATES_REJECTED,
  SET_DELETE_DASHBOARD_MODAL,
  SET_ABOUT_DASHBOARD_MODAL,
  SET_SC_DASH_VIEW,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_SCDASH_DATA_START,
  FETCH_SCDASH_DATA_FULFILLED,
  FETCH_SCDASH_DATA_REJECTED,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_SELECTED_ATHLETE_GROUP,
  SET_IS_LEGEND_INFO_MODAL_SHOWING,
  SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_EXER_FILTERS_GROUP_SHOWING,
  SET_SAVE_DASHBOARD_MODAL,
  DELETE_DASHBOARD_FULFILLED,
  SET_TRI_OPTION_MODAL,
  FETCH_SHARE_DASH_LINK_START,
  FETCH_SHARE_DASH_LINK_FULFILLED,
  FETCH_SHARE_DASH_LINK_REJECTED,
  FETCH_PUBLIC_SCDASH_DATA_START,
  FETCH_PUBLIC_SCDASH_DATA_FULFILLED,
  FETCH_PUBLIC_SCDASH_DATA_REJECTED,
  SET_IS_UNSAVED_DASH_MODAL_SHOWING,
  SET_IS_OVERVIEW,
  SET_DUPLICATE_MODAL_SHOWING,
  SET_SHARE_DASHBOARD_MODAL,
} from './actionTypes';

const initialState = {
  aboutDashboardModalShowing: false,
  deleteDashboardModal: {},
  duplicateDashboardModalShowing: false,
  isAthleteSelectedGroup: false,
  isAthleteSelectedPerformance: false,
  isExerciseFiltersGroupShowing: false,
  isExerciseFiltersPerformanceShowing: false,
  isGroupsLoading: false,
  triOptionModalShowing: false,
  isLegendInfoModalShowing: false,
  isMetricLoading: false,
  isPublicSCDashDataLoading: false,
  isReadOnlyView: false,
  isSCDashDataLoading: false,
  isShareDashLinkLoading: false,
  isShareDashModalShowing: false,
  isTemplatesLoading: false,
  isUnsavedDashModalShowing: false,
  scDashView: 'performance',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATES_START: {
      return {
        ...state,
        isTemplatesLoading: true,
      };
    }
    case FETCH_TEMPLATES_FULFILLED: {
      return {
        ...state,
        isTemplatesLoading: false,
      };
    }
    case FETCH_TEMPLATES_REJECTED: {
      return {
        ...state,
        isTemplatesLoading: false,
      };
    }
    case SET_DELETE_DASHBOARD_MODAL: {
      return {
        ...state,
        deleteDashboardModal: action.payload,
      };
    }
    case SET_ABOUT_DASHBOARD_MODAL: {
      return {
        ...state,
        aboutDashboardModalShowing: action.payload.bool,
      };
    }
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_SCDASH_DATA_START: {
      return {
        ...state,
        isSCDashDataLoading: true,
      };
    }
    case FETCH_SCDASH_DATA_FULFILLED: {
      return {
        ...state,
        isSCDashDataLoading: false,
      };
    }
    case FETCH_SCDASH_DATA_REJECTED: {
      return {
        ...state,
        isSCDashDataLoading: false,
      };
    }
    case SET_SC_DASH_VIEW: {
      return {
        ...state,
        scDashView: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        isAthleteSelectedPerformance: action.payload.bool,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        isAthleteSelectedGroup: action.payload.bool,
      };
    }
    case SET_IS_LEGEND_INFO_MODAL_SHOWING: {
      return {
        ...state,
        isLegendInfoModalShowing: action.payload,
      };
    }
    case SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING: {
      return {
        ...state,
        isExerciseFiltersPerformanceShowing: action.payload,
      };
    }
    case SET_IS_EXER_FILTERS_GROUP_SHOWING: {
      return {
        ...state,
        isExerciseFiltersGroupShowing: action.payload,
      };
    }
    case SET_SAVE_DASHBOARD_MODAL: {
      return {
        ...state,
        saveDashboardModalShowing: action.payload,
      };
    }
    case SET_TRI_OPTION_MODAL: {
      return {
        ...state,
        triOptionModalShowing: action.payload,
      };
    }
    case DELETE_DASHBOARD_FULFILLED: {
      return update(
        state, {
          deleteDashboardModal: {
            $set: {},
          },
        },
      );
    }
    case FETCH_SHARE_DASH_LINK_START: {
      return {
        ...state,
        isShareDashLinkLoading: true,
      };
    }
    case FETCH_SHARE_DASH_LINK_FULFILLED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_SHARE_DASH_LINK_REJECTED: {
      return {
        ...state,
        isShareDashLinkLoading: false,
      };
    }
    case FETCH_PUBLIC_SCDASH_DATA_START: {
      return {
        ...state,
        isPublicSCDashDataLoading: true,
        isReadOnlyView: true,
      };
    }
    case FETCH_PUBLIC_SCDASH_DATA_FULFILLED: {
      return {
        ...state,
        isPublicSCDashDataLoading: false,
      };
    }
    case FETCH_PUBLIC_SCDASH_DATA_REJECTED: {
      return {
        ...state,
        isPublicSCDashDataLoading: false,
      };
    }
    case SET_IS_OVERVIEW: {
      return {
        ...state,
        isOverview: action.payload,
      };
    }
    case SET_IS_UNSAVED_DASH_MODAL_SHOWING: {
      return {
        ...state,
        isUnsavedDashModalShowing: action.payload,
      };
    }
    case SET_DUPLICATE_MODAL_SHOWING: {
      return {
        ...state,
        duplicateDashboardModalShowing: action.payload.bool,
      };
    }
    case SET_SHARE_DASHBOARD_MODAL: {
      return {
        ...state,
        isShareDashModalShowing: action.payload.bool,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
