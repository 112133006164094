import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSpring, animated } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';

import { ColorGray } from '../../../../shared/GlobalStyles';
import SearchIcon from '../../../../shared/images/magnifying-glass.svg';

const SearchWrapper = styled('div')`
  &:focus {
    outline: none;
  }
  .search-container {
    font-family: 'Open Sans';
    border: 1px solid ${(props) => props.theme.buttonBorderColor};
    border-radius: 21px;
    background-color: ${(props) => props.theme.backgroundColorL2};
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-size: 18px;
    color: ${(props) => props.theme.textColor};
    width: 100%;
    &::placeholder {
      color: ${ColorGray};
    }

    @media screen and (orientation: portrait) {
      width: 180px;
    }
    
    @media screen and (max-width: 601px) {
      background-size: 18px!important;
      width: 140px;
      padding: 6px 10px 6px 45px;
    }
    input {
      font-family: 'Open Sans';
      width: 100%;
      border:none;
      background-image:none;
      background-color:transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: 30px;
      color: ${(props) => props.theme.textColor};
    }
  }
`;

const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => (htmlElRef.current && htmlElRef.current.focus());

  return [htmlElRef, setFocus];
};

const AthleteSearch = ({
  namespace,
  searchAthlete,
}) => {
  const athleteSearch = useSelector((state) => state.weightRoomView[namespace].athleteSearch);

  const [isSearchActive, setSearchActive] = useState(false);
  const [inputRef, setInputFocus] = useFocus();

  const expandSearch = useCallback(() => {
    setSearchActive(true);
    setTimeout(() => setInputFocus(), 200);
  }, []);

  const collapseSearch = useCallback(() => {
    setSearchActive(false);
    setTimeout(() => searchAthlete('', namespace), 200);
  }, []);

  const handleChange = useCallback((e) => {
    searchAthlete(e.target.value, namespace);
  }, []);

  const props = useSpring({
    to: {
      width: isSearchActive ? 180 : 42,
      padding: isSearchActive ? '5px 10px 5px 45px' : '5px 10px 5px 30px',
      backgroundPosition: isSearchActive ? '20px 10px' : '11px 10px',
    },
    from: { width: 0, padding: '5px 20px 5px 40px', backgroundPosition: '11px 10px' },
  });

  return (
    <SearchWrapper
      onClick={expandSearch}
    >
      <animated.div
        className='search-container'
        style={props}
      >
        <input
          ref={inputRef}
          value={athleteSearch}
          placeholder='Search'
          onChange={handleChange}
          tabIndex='0'
          onBlur={collapseSearch}
        />
      </animated.div>
    </SearchWrapper>
  );
};

AthleteSearch.propTypes = {
  namespace: PropTypes.string.isRequired,
  searchAthlete: PropTypes.instanceOf(Function).isRequired,
};

export default AthleteSearch;
