/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import SelectANotification from './SelectANotification';
import NotificationItemContainer from './notifications/NotificationItemContainer';

import {
  fetchNotificationAssetDetailsNew,
} from '../../ducks/notificationsActions';

const EmptyNotificationPanel = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

/**
 *
 * @returns the panel in which the currently selected notification is displayed
 */

const NotificationPanel = () => {
  const dispatch = useDispatch();
  // the currently selected notification (the notification in the list that the user selected)
  const activeNotification = useSelector((state) => state.notifications.data.activeNotification);
  // the list of all current notifications sorted by page
  const notifications = useSelector((state) => state.notifications.data.notifications);
  // ui state that tracks whether the API ping for more notification details is currently running
  const isNotificationAssetLoading = useSelector(
    (state) => state.notifications.ui.isNotificationAssetLoading,
  );

  // when a notification is selected, broader details about that notification are collected
  useEffect(() => {
    // Get additional details about the notification asset
    if (Object.keys(activeNotification).length) {
      dispatch(fetchNotificationAssetDetailsNew(
        activeNotification,
      ));
    }
  }, [activeNotification]);

  /**
   * f a notification is currently selected and it's greater details aren't loaoding,
   * return the general notification container.  Otherwise, return null
  */
  if (Object.keys(activeNotification).length) {
    if (isNotificationAssetLoading) {
      return null;
    }
    return (
      <NotificationItemContainer activeNotification={activeNotification} />
    );
  }

  // if there is no active notification selected, and notifications exist, display a prompt
  if (notifications.length > 0) {
    return (
      <SelectANotification />
    );
  }

  // if the list of notifications is 0, return the empty notification panel component
  return (
    <EmptyNotificationPanel />
  );
};

export default NotificationPanel;
