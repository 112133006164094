/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import Webcam from 'react-webcam';
import atob from 'atob';

import Spinner from '../../../../shared/components/Spinner/Spinner';
import Text from '../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import { setActiveReduxModal } from '../../ducks/conversationsActions';

const WebcamContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 26px;
    font-weight: 300;
  }
  .capture-button {
    width: 180px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const ImageLightBoxContainer = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const WebcamCaptureImage = ({
}) => {
  const dispatch = useDispatch();
  const image = useSelector(state => state.conversations.data.messageMediaUrl);

  return (
    <WebcamContainer>
      <ImageLightBoxContainer
        src={image}
      />
    </WebcamContainer>
  );
};

WebcamCaptureImage.propTypes = {
  handleUploadMedia: PropTypes.func.isRequired,
};

export default WebcamCaptureImage;
