import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { animated, useSpring } from 'react-spring';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import SubText from '../../../../shared/components/SubText/SubText';
import ConversationBoxContent from './ConversationBoxContent';
import useConversations from '../hooks/useConversations';

const ConversationBoxContainer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  }
`;

const LoadMoreContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
`;

const ConversationContent = styled('div')`
  padding-bottom: 100px;
`;

const ConversationBox = ({
  isCheckboxesOpen,
  setSelectedConvos,
}) => {
  const {
    handleFetchConversations,
  } = useConversations();
  const conversationContainerRef = useRef();
  const conversationListRef = useRef();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [containerHeight, setContainerHeight] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  // Initial Scroll location using react refs.
  const initialScrollLocation = conversationContainerRef.current
    ? conversationContainerRef.current.offsetTop : 0;

  // containerEnd is the location of the end of the conversation list
  // by subtracting the initial scroll location
  // from the conversation list height, then adding the outer container height.
  const containerEnd = (initialScrollLocation - listHeight)
    + containerHeight;

  const isEndOfList = useSelector(
    (state) => state.conversations.data.isEndOfList,
  );

  const isNextPageLoading = useSelector(
    (state) => state.conversations.ui.isNextPageLoading,
  );

  // Load More function which fetches the next conversation page if it's not the end of the list.
  const loadMore = () => {
    if (!isEndOfList) {
      handleFetchConversations(true, false);
    }
  };

  useEffect(() => {
    if (currentUser.accountCode !== undefined) {
      handleFetchConversations(false, true);
    }
  }, [currentUser]);

  useEffect(() => {
    setContainerHeight(
      conversationContainerRef.current && conversationContainerRef.current.offsetHeight,
    );
  }, [conversationContainerRef.current && conversationContainerRef.current.offsetHeight]);

  // React Spring animations for loading more, and auto scrolling.
  // Scrolling animation works by setting the scroll location from the tableScroll state
  const loadTransition = useSpring({
    position: 'absolute',
    opacity: isNextPageLoading ? 1 : 0,
  });

  const endTransition = useSpring({
    position: 'absolute',
    opacity: isEndOfList ? 1 : 0,
  });

  return (
    <ConversationBoxContainer
      ref={conversationContainerRef}
    >
      <ConversationContent
        ref={conversationListRef}
      >
        {/*
          This is the same method of implementing auto-fetch
          on page scroll as we use on Leaderboard. We use ResizeObserver
          to track when the position of the page changes. When the user has
          scrolled to the bottom of the list, we call loadMore() to fetch
          the next page of conversations. Important variables such as containerEnd,
          etc. are defined above.
        */}
        <ResizeObserver
          onResize={(rect) => {
            setListHeight(rect.height);
          }}
          onPosition={(rect) => {
            if (rect.top <= containerEnd && !isEndOfList) {
              loadMore();
            }
          }}
        />
        {currentUser.userSettings !== undefined && (currentUser.userSettings.messaging !== 0 ? (
          <>
            <ConversationBoxContent
              setSelectedConvos={setSelectedConvos}
              isCheckboxesOpen={isCheckboxesOpen}
            />
            <LoadMoreContainer>
              <animated.div style={loadTransition}>
                <Spinner
                  saving
                  darkTheme
                />
              </animated.div>
              <animated.div style={endTransition}>
                <SubText>
                  End of Conversations
                </SubText>
              </animated.div>
            </LoadMoreContainer>
          </>
        ) : (
          <LoadMoreContainer>
            <SubText className=''>
              No Access to Conversations
            </SubText>
          </LoadMoreContainer>
        ))}
      </ConversationContent>
    </ConversationBoxContainer>
  );
};

ConversationBox.propTypes = {
  setSelectedConvos: PropTypes.func.isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
};
export default ConversationBox;
