import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Button from '../../shared/components/Button/Button';
import NotFoundImage from '../../shared/images/NotFoundImage.jpg';

const PageHeader = styled('h1')`
  font-family: 'Nunito Sans';
`;

const PageWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const PageSubtext = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 20px;
`;

const RefreshButton = styled('div')`
  margin-top: 25px;
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled('img')`
  height: 400px;
  margin-left: 20px;
  margin-bottom: 30px;
`;

const FourOhFourRoute = ({
  errorBoundary = false,
}) => (
  <PageWrapper>
    <Image src={NotFoundImage} />
    <ContentWrapper>
      {!errorBoundary ? <PageHeader>Page Not Found</PageHeader>
        : <PageHeader>Something went wrong</PageHeader>}
      <PageSubtext>
        {!errorBoundary ? 'This is not the page you\'re looking for'
          : 'Try to refresh the page to fix the issue, otherwise contact support'}
      </PageSubtext>
      {errorBoundary ? (
        <RefreshButton>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            cta='Refresh Page'
          />
        </RefreshButton>
      ) : null}
    </ContentWrapper>
  </PageWrapper>
);

FourOhFourRoute.defaultProps = {
  errorBoundary: false,
};

FourOhFourRoute.propTypes = {
  errorBoundary: PropTypes.bool,
};

export default FourOhFourRoute;
