import React from 'react';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 18px 25px;

  button {
    border: 0;
  }
`;

const Header = () => {
  const dispatch = useDispatch();

  const handleReactLink = (path, e) => {
    e.preventDefault();
    dispatch(push(path));
  };

  return (
    <HeaderWrapper>
      <button type='button' onClick={(e) => { handleReactLink('/settings', e); }}>
        <IcomoonReact
          iconSet={iconSet}
          size={24}
          icon='settings'
        />
      </button>
    </HeaderWrapper>
  );
};

export default Header;
