/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import styled from '@emotion/styled';
import moment from 'moment';
import DataTable, { createTheme } from 'react-data-table-component';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import DownloadChartDataButton from './DownloadChartDataButton';
import {
  setFilterInfoFromPieChart, setActiveAthlete, fetchAthleteAverages, fetchWearablesReportForDataChartByGroupOrAthleteId, setActiveMetricType,
} from '../../ducks/wearablesDashboardActions';

const AthleteDataTable = ({ includeDownLoadChartButton, containerWidth }) => {
  const dispatch = useDispatch();
  const activeGroup = useSelector((state) => state.wearablesDashboard.data.activeGroup);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const activeUserIds = useSelector((state) => state.wearablesDashboard.data.activeUserIds);
  const activeAthlete = useSelector((state) => state.wearablesDashboard.data.activeAthlete);
  const activeMetricType = useSelector((state) => state.wearablesDashboard.data.activeMetricType);
  const activeAthleteName = activeAthlete.name ? activeAthlete.name : `${activeAthlete.first} ${activeAthlete.last}`;
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const isWearablesReportLoading = useSelector((state) => state.wearablesDashboard.ui.isWearablesReportLoading);
  const pieChartFilterObj = useSelector((state) => state.wearablesDashboard.data.pieChartFilter);
  const showPieChartBadge = useSelector((state) => state.wearablesDashboard.data.showPieChartBadge);
  const dataRows = useSelector((state) => state.wearablesDashboard.data.wearablesReportDataForDataChart?.rows);

  const formatedDataRows = useSelector((state) => state.wearablesDashboard.data.wearablesReportDataForDataChart?.rows?.map((row) => ({
    id: row[0].rawValue,
    first: row[1].rawValue.charAt(0).toUpperCase() + row[1].rawValue.slice(1),
    last: row[2].rawValue.charAt(0).toUpperCase() + row[2].rawValue.slice(1),
    date: row[3].rawValue,
    exertionScore: row[4].rawValue,
    basalCaloriesBurned: row[5].rawValue,
    activeCaloriesBurned: row[6].rawValue,
    sleep: row[7].rawValue,
    heartRate: row[8].rawValue,
    hrv: row[9].rawValue,
    steps: row[10].rawValue,
  })));

  const refFirstName = React.createRef();
  const refLastName = React.createRef();
  const badgeRef = React.createRef();

  const [isHover, setIsHover] = useState(false);

  const [isToolTipOnBottom, setIsToolTipOnBottom] = useState(false);

  const [mouseOverRowId, setMouseOverRowId] = useState('');

  const [widthOfFirstName, setWidthOfFirstName] = useState('');
  const [widthOfLastName, setWidthOfLastName] = useState('');

  /** listen for change in pieChartFilter global object and call raw report api with new filter data */
  useEffect(() => {
    if (pieChartFilterObj?.status) {
      /** this will be triggered when the user clicks the pie chart that sets the filter object
     * first we want to scroll the user to the data chart component incase they are on a smaller screen
     * and can't see that something happened when they click this pie chart
     */

      /** scroll to data chart on change */
      badgeRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });

      const athleteId = activeGroup.id;
      /** set activeAthlete() to active group  only if the current
      acitve athlete is not already set as the group
      * if the active athlete is currently set as the group they belong to
      then we can skip this api call
      */

      if (activeAthlete?.first || activeMetricType?.name !== 'Exertion Score') {
        /** default metric type back to exertion score when pie chart is clicked */
        const metricTypeRawValue = 1;
        const defaultMetricType = {
          id: 1,
          name: 'Exertion Score',
          rawValue: 1,
        };
        dispatch(setActiveMetricType(defaultMetricType));
        const filterType = 1;
        dispatch(setActiveAthlete(activeGroup));
        dispatch(fetchAthleteAverages(accountCode, athleteId, activeWearablesDashDate, filterType, metricTypeRawValue));
      }

      // const filterTypeForWearablesRawReport = 2;
      const filterTypeForWearablesRawReport = 1;
      // const filterIds = [athleteId];
      const filterIds = [activeUserIds];
      const csv = false;
      const { maxScore } = pieChartFilterObj;
      const { minScore } = pieChartFilterObj;
      dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv, maxScore, minScore));
    }
  }, [pieChartFilterObj]);

  const handleMouseEnter = (row) => {
    setMouseOverRowId(row.id);
    if (refFirstName) {
      setWidthOfFirstName(refFirstName.current?.getBoundingClientRect().width);
    }
    if (refLastName) {
      setWidthOfLastName(refLastName.current?.getBoundingClientRect().width);
    }
    if (refFirstName && refFirstName.current?.getBoundingClientRect().top < 45) {
      setIsToolTipOnBottom(true);
    }
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
    setMouseOverRowId('');
    setIsToolTipOnBottom(false);
  };

  const removePieChartFilter = () => {
    dispatch(setFilterInfoFromPieChart());
    /** remove minScore and maxScore and make
    raw report api call again */
    const athleteId = activeGroup.id;
    // const filterTypeForWearablesRawReport = 2;
    const filterTypeForWearablesRawReport = 1;
    // const filterIds = [athleteId];
    const filterIds = [activeUserIds];
    const csv = false;
    dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv));
  };

  const tooltipStyle = {
    display: 'flex',
    visibility: (isHover && widthOfFirstName > 85) || (isHover && widthOfLastName > 85) ? 'visible' : 'hidden',
    maxWidth: '200px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%)',
    fontFamily: 'Nunito Sans',
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'center',
    borderRadius: '6px',
    padding: '8px',
    zIndex: 1,
    position: 'absolute',
    bottom: isToolTipOnBottom ? '-110%' : '105%',
    color: '#444444',
  };

  const tooltipArrow = {
    content: '',
    position: 'absolute',
    top: '-3px',
    left: '60%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'white transparent transparent transparent',
    zIndex: 10,
  };

  const tooltipArrowBottom = {
    content: '',
    position: 'absolute',
    bottom: '-4px',
    left: '60%',
    marginLeft: '-5px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent white transparent',
    zIndex: 10,
  };

  const textOverFlowStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

  };

  const columns = [
    {
      id: 'Name',
      name: 'Name',
      cell: (row) => (
        <div
          style={{ maxWidth: '100px', display: 'flex', justifyContent: 'center' }}
          data-tag='allowRowEvents'
          // onMouseOver={() => handleMouseEnter(row)}
          // onFocus={() => handleMouseEnter(row)}
          // onMouseOut={handleMouseLeave}
          // onBlur={handleMouseLeave}
        >
          {/* {mouseOverRowId === row.id ? (
            <>
              <div style={tooltipStyle}>
                {`${row.first}`}
                {` ${row.last}`}
              </div>
              <div style={isToolTipOnBottom ? tooltipArrowBottom : tooltipArrow} />
              <div
                style={{ maxWidth: '85px' }}
              >
                <div style={{ maxWidth: '85px', ...textOverFlowStyle }}>
                  <span ref={refFirstName}>
                    {`${row.first}`}
                  </span>
                </div>
                <div style={{ maxWidth: '85px', ...textOverFlowStyle }}>
                  <span ref={refLastName}>
                    {` ${row.last}`}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{ maxWidth: '85px' }}
            >
              <div style={{ ...textOverFlowStyle }}>
                {`${row.first}`}
              </div>
              <div style={{ ...textOverFlowStyle }}>
                {` ${row.last}`}
              </div>
            </div>
          )} */}
          <div
            style={{ maxWidth: '100px' }}
          >
            <div style={{ ...textOverFlowStyle }}>
              {`${row.first}`}
            </div>
            <div style={{ ...textOverFlowStyle }}>
              {` ${row.last}`}
            </div>
          </div>
        </div>
      ),
      selector: (row) => row.last,
      sortable: true,
    },
    {
      id: 'exertionScore',
      name: 'Exertion Score',
      cell: (row) => (<div>{row.exertionScore || '-'}</div>),
      selector: (row) => row.exertionScore,
      sortable: true,
    },
    {
      id: 'Calories Burned',
      name: 'Calories Burned',
      cell: (row) => (<div>{row.activeCaloriesBurned || '-'}</div>),
      selector: (row) => row.basalCaloriesBurned,
      sortable: true,
    },
    {
      id: 'Sleep',
      name: 'Sleep',
      cell: (row) => (<div>{row.sleep || '-'}</div>),
      selector: (row) => row.sleep,
      sortable: true,
    },
    {
      id: 'Average HR',
      name: 'Average HR',
      cell: (row) => (<div>{row.heartRate || '-'}</div>),
      selector: (row) => row.heartRate,
      sortable: true,
    },
    {
      id: 'HRV',
      name: 'HRV',
      cell: (row) => (<div>{row.hrv || '-'}</div>),
      selector: (row) => row.hrv,
      sortable: true,
    },
    {
      id: 'Steps',
      name: 'Steps',
      cell: (row) => (<div>{row.steps || '-'}</div>),
      selector: (row) => row.steps,
      sortable: true,
    },
  ];

  const StyledDataTable = styled(DataTable)`
  .ffOiPm div:first-of-type {
    max-width: 100px;
    white-space: pre-wrap;
    // background-color: lightgrey;
    // #app > div > main > div.css-1tlnq76-FlexContainer.e1j7z9ry0 > div.css-19x2h0k-PageWrapper.e1j7z9ry1 > div > div > div.css-27gudf-PageContainer.ejbcgke2 > div.css-1upfikx-WrapperColumn2.ejbcgke4 > div.css-1wmfeba-Row.ejbcgke6 > div > div.sc-fznxsB.fWHyCt.css-czms40-StyledDataTable.e8td15l0 > div > div
  }
  
  `;

  const Wrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: ${(props) => (props.containerWidth >= 768 && props.containerWidth <= 911) && '30px'};
    padding-right: ${(props) => (props.containerWidth >= 768 && props.containerWidth <= 911) && '30px'};
    margin-top: ${(props) => ((props.containerWidth >= 768 && props.containerWidth <= 911) ? '0px' : '30px')};
  `;

  const LoadingWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-left: ${(props) => (props.containerWidth >= 768 && props.containerWidth <= 911) && '30px'};
    padding-right: ${(props) => (props.containerWidth >= 768 && props.containerWidth <= 911) && '30px'};
    margin-top: ${(props) => ((props.containerWidth >= 768 && props.containerWidth <= 911) ? '0px' : '50px')};
  `;

  const LoadingShimmer = styled('div')`
  margin-top: 20px;
  width: 100%;
  height: 47px;
  border-radius: 24px;
  
  `;

  const FilterBadgeAndTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 15px;
  `;

  const FilterBadge = styled('div')`
    background: ${pieChartFilterObj?.fillColor};
    display: flex;
    align-items: center;
    padding: 8px;
    box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
    justify-content: space-between;
    min-width: 119px;
    height: 21px;
    border-radius: 12px;
  `;

  createTheme('teambuildr', {
    text: {
      primary: '#444444',
      secondary: '#898989',
    },
    background: {
      default: 'transparent',
    },
  });

  const teambuildrStyles = {
    table: {
      style: {
        backgroundColor: '#F5F3F3',
      },
    },
    header: {
      style: {
        display: 'none',
      },
    },
    headRow: {
      style: {
        border: 'none',
        marginLeft: '3px',
        marginRight: '3px',
        width: '99%',
        backgroundColor: '#F5F3F3',
      },
    },
    headCells: {
      style: {
        fontFamily: 'Nunito Sans',
        fontSize: '10px',
        color: '#444444',
        fontWeight: '800',
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: '0px',
        justifyContent: 'center',
      },
    },
    cells: {
      style: {
        padding: '0px',
        textAlign: 'center',
        justifyContent: 'center',
        fontFamily: 'Nunito Sans',
        fontSize: '12px',
        fontWeight: '700',
      },
    },
    rows: {
      style: {
        background: 'white',
        padding: '0px',
        marginLeft: '3px',
        marginRight: '3px',
        marginBottom: '20px',
        width: '99%',
        borderBottomWidth: '0px',
        borderRadius: '24px',
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)',
      },
      highlightOnHoverStyle: {
        backgroundColor: '#f9f9f9',
        borderBottomColor: '#f9f9f9',
      },
    },
  };

  return (
    <>
      {isWearablesReportLoading ? (
        <LoadingWrapper containerWidth={containerWidth}>
          <div style={{ marginLeft: '5px' }}>
            <Text fontSize={14} fontWeight={700}>generating report ...</Text>
          </div>
          <Shimmer>
            <LoadingShimmer />
          </Shimmer>
        </LoadingWrapper>
      ) : (
        <Wrapper containerWidth={containerWidth}>
          {dataRows?.length ? (
            <>
              <div style={{
                marginLeft: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px',
              }}
              >
                <FilterBadgeAndTitleContainer ref={badgeRef}>
                  <Text fontSize={14} fontWeight={700}>{`Wearables Data Report (${moment(activeWearablesDashDate).format('MMM D, YYYY')}) - ${activeAthleteName}`}</Text>
                  {pieChartFilterObj?.status && showPieChartBadge && (
                  <FilterBadge onClick={() => { removePieChartFilter(); }}>
                    <Text fontSize={12} fontWeight={700} color='white'>{pieChartFilterObj.status}</Text>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={10}
                      icon='remove'
                      color='white'
                    />
                  </FilterBadge>
                  )}
                </FilterBadgeAndTitleContainer>
                {includeDownLoadChartButton && <DownloadChartDataButton />}
              </div>
              <StyledDataTable
                columns={columns}
                data={formatedDataRows}
                // pagination
                defaultSortFieldId='exertionScore'
                defaultSortAsc={false}
                onMouseOver={(row) => { handleMouseEnter(row); }}
                onRowMouseLeave={handleMouseLeave}
                customStyles={teambuildrStyles}
                theme='teambuildr'
              />
            </>
          ) : (null)}
        </Wrapper>
      )}
    </>
  );
};

AthleteDataTable.propTypes = {
  includeDownLoadChartButton: PropTypes.bool,
};
AthleteDataTable.defaultProps = {
  includeDownLoadChartButton: false,
};

export default AthleteDataTable;
