/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { fetchReportableGroups, fetchQuestionnairesForAccount, fetchQuestionsForAccount } from './ducks/loadMonitoringDashboardActions';
import LoadMonitoringForm from './components/presentational/LoadMonitoringForm';
import Spinner from '../../shared/components/Spinner/Spinner';

const Wrapper = styled('div')`
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  margin-top: 49px;
  // background: ${(props) => (props.activePage === 3 && 'white')};
  background: #F5F3F3;
`;

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.activePage && props.activePage !== 3 && '1200px'};
  justify-content: center;
  align-items: ${(props) => props.activePage && props.activePage !== 3 && 'center'};
  flex-direction: column;
  position: relative;
`;

const LoadMonitoringDashboard = () => {
  const [activePage, setActivePage] = useState(1);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isLoginLoading = useSelector((state) => state.auth.ui.isLoginLoading);
  const accountCode = currentUser?.accountCode;

  const isLoadingQuestionnairesForAccount = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnairesForAccount);
  const isLoadingQuestionsForAccount = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionsForAccount);
  const isLoadingReportableGroups = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingReportableGroups);

  const isSubscriptionStatusLoading = useSelector((state) => state.sportsScience.ui.isStatusLoading);
  const isContentLoading = isLoadingQuestionnairesForAccount || isLoadingQuestionsForAccount || isLoadingReportableGroups || isLoginLoading || isSubscriptionStatusLoading;

  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState({});

  const [localCurrentUser, setLocalCurrentUser] = useState(null);

  useEffect(() => {
    setLocalCurrentUser(currentUser);
  }, [currentUser]);

  useEffect(() => {
    /** on page load, fetch groups, questions and questionnaires associated with account
     */
    if (currentUser?.id && localCurrentUser) {
      batch(() => {
        dispatch(fetchReportableGroups(accountCode));
        dispatch(fetchQuestionsForAccount(accountCode));
        dispatch(fetchQuestionnairesForAccount(accountCode));
        /** fetch recently used reports from API */
      });
    }
  }, [currentUser]);
  return (
    <Wrapper activePage={activePage}>
      <Container activePage={activePage} containerWidth={containerWidth}>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        {isContentLoading || !currentUser?.id ? (
          <Spinner />
        ) : (
          <LoadMonitoringForm
            isLoadingQuestionnairesForAccount={isLoadingQuestionnairesForAccount}
            isLoadingQuestionsForAccount={isLoadingQuestionsForAccount}
            containerWidth={containerWidth}
            activePage={activePage}
            setActivePage={setActivePage}
          />
        )}
      </Container>
    </Wrapper>
  );
};
LoadMonitoringDashboard.propTypes = {

};

export default LoadMonitoringDashboard;
