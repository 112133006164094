/* eslint-disable no-tabs */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
  ColorGreen,
} from '../../../../shared/GlobalStyles';

import { getWorkoutsExertionScore, setExertionScoreModalShowing } from '../../ducks/workoutsActions';

const ScoreAndText = styled('div')`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ExertionScoreText = styled('div')`
  margin-top: 15px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  position: relative;
`;

const IconContainer = styled('div')`
  position: absolute;
  right: 0;
  margin-left: 50px;
  top: -5px;
  left: 3px;
`;

const AlertText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  text-align: center;
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const ExertionScore = () => {
  const dispatch = useDispatch();
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const exertionScore = useSelector((state) => state.workouts.data.exertionScore);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  
  const theme = useTheme();

  useEffect(() => {
    dispatch(getWorkoutsExertionScore(activeWorkoutDate));
  }, [activeWorkoutDate, sharedAthlete]);

  // return (
  //   <div>
  //     <svg style={{ height: 0 }}>
  //       <defs>
  //         <linearGradient id='progress-bar' gradientTransform={gradientTransform}>
  //           <stop offset='0%' stopColor='#0dcc8a' />
  //           <stop offset='50%' stopColor='#FFCB47' />
  //           <stop offset='100%' stopColor='#ff0024' />
  //         </linearGradient>
  //       </defs>
  //     </svg>
  //     <CircularProgressbar
  //       value={exertionScore?.score ? exertionScore.score * 10 : 0}
  //       // percentage={exertionScore?.score ? exertionScore.score * 10 : 0}
  //       percentage={90}
  //       text={`${percentage}%`}
  //       styles={{ path: { stroke: `url(#${'progress-bar'})`, height: '100%' } }}
  //     />
  //   </div>
  // );

  const handleExertionColor = () => {
    if (exertionScore?.score <= 2) {
      return theme.colors.green;
    }
    if (exertionScore?.score > 2 && exertionScore?.score <= 4) {
      return theme.colors.yellowBlend;
    }
    if (exertionScore?.score > 4 && exertionScore?.score <= 6) {
      return theme.colors.yellow;
    }
    if (exertionScore?.score > 6 && exertionScore?.score <= 8) {
      return theme.colors.redBlend;
    }
    return theme.colors.red;
  };

  const scoreRumbler = () => {
    if (Object.keys(sharedAthlete).length && !activeWorkoutDate) {
      return <AlertText>Please select a date to get exertion score</AlertText>;
    } if (activeWorkoutDate && !Object.keys(sharedAthlete).length) {
      return <AlertText>Please select an athlete to get exertion score</AlertText>;
    } if ((Object.keys(exertionScore)
      && exertionScore?.score !== null)
      && Object.keys(sharedAthlete)
      && activeWorkoutDate) {
      return (
        <>
          <ScoreAndText onClick={() => dispatch(setExertionScoreModalShowing(true))}>
            <CircularProgressbar
              value={exertionScore?.score ? exertionScore.score * 10 : 0}
              percentage={exertionScore?.score ? exertionScore.score * 10 : 0}
              text={`${exertionScore?.score ? exertionScore.score : 0}`}
              strokeWidth={13}
              styles={{
                path: { stroke: handleExertionColor() },
                root: {
                  width: '65%',
                },
                text: {
                  fontFamily: 'Nunito Sans',
                  fill: theme.colors.text,
                },
              }}
            />
            <ExertionScoreText>
              Exertion
              <IconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='question-mark-circle'
                  color='grey'
                />
              </IconContainer>
            </ExertionScoreText>
          </ScoreAndText>
          <AlertText>
            {exertionScore.alertText}
          </AlertText>
        </>
      );
    } if (exertionScore?.score === null && exertionScore?.alertText
            && Object.keys(sharedAthlete) && activeWorkoutDate) {
      return (
        <AlertText>
          {exertionScore.alertText}
        </AlertText>
      );
    }
    return null;
  };

  return (
    <>
      {scoreRumbler()}
    </>
  );
};

// linear gradient:
// green: '#0dcc8a',
// yellow: '#FFCB47',
// red: '#ff0024',

ExertionScore.propTypes = { };

export default ExertionScore;
