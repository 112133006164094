import { connect } from 'react-redux';
import TagEntries from '../presentational/WorkoutEntriesTag';

import {
  fetchSimilarTypeExercises,
  updateTagWorkout,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeDate: state[ownProps.namespace].activeDate,
  athleteId: state[ownProps.namespace].athleteId,
  exerId: state[ownProps.namespace].activeExerId,
  exercisesSimilar: state[ownProps.namespace].exercisesSimilar,
  isLoadingSimExercises: state[ownProps.namespace].isLoadingSimExercises,
});

const mapDispatchToProps = dispatch => ({
  fetchSimilarTypeExercises: (namespace, exerId) => {
    dispatch(fetchSimilarTypeExercises(namespace, exerId));
  },
  updateTagWorkout: (athleteId, date, index, namespace, values) => {
    dispatch(updateTagWorkout(athleteId, date, index, namespace, values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TagEntries);
