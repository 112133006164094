// /* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

import Spinner from '../Spinner/Spinner';
// import Text from '../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import { setSubActiveReduxModal } from '../../../modules/profile/ducks/profileActions';

const WebcamContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 26px;
    font-weight: 300;
  }
  .capture-button {
    width: 180px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const SpinnerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 395px;
  `;

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};
const WebcamCaptureImage = ({
  handleUploadMedia,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [isCaptured, setIsCaptured] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState(null);

  const isMediaLoading = useSelector((state) => state.profile.ui.isProfileImageLoading);
  const uploadedProfilePic = useSelector((state) => state.auth.data.currentUser.pic);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const imageBuff = Buffer.from(imageSrc.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const image = new File([imageBuff], 'jpg', {
        type: 'image/jpeg',
      });

      // const acceptedFile = image;
      // const type = 'image';
      // const webcam = true;
      setIsCaptured(true);
      setImgSrc(imageSrc);
      setAcceptedFile(image);
      // setAcceptedFile(acceptedFile);
      // dispatch(handleUploadMedia({ acceptedFile, type }, webcam));
    },
    [webcamRef],
  );

  console.log('isCaptured:', isCaptured);
  const isUpdatingLogo = handleUploadMedia.toString().includes('updateLogoImage(accountCode, values)');
  console.log('isUpdatingLogo:', isUpdatingLogo);

  const doneAndUpdatePic = () => {
    console.log('doneAndUpdatePic');
    // const acceptedFile = imgSrc;
    const type = 'image';
    const webcam = true;
    if (acceptedFile) {
      if (isUpdatingLogo) {
        console.log('file to update logo with from webcam:', acceptedFile);
        // dispatch(handleUploadMedia(accountCode, { acceptedFile, type }, webcam));
      } else {
        console.log('file to update profile pic with from webcam:', acceptedFile);
        dispatch(handleUploadMedia({ acceptedFile, type }, webcam));
      }
    }
    dispatch(setSubActiveReduxModal(''));
  };

  const buttonMasher = () => {
    if (!isCaptured) {
      return (
        <NoHoverButton
          buttonRef={null}
          className='modal-button'
          // onClick={capture}
          onClick={capture}
          cta='Take Photo'
          customColor='#10cd8c'
          noHover
          noBorder
          bottom
          fullWidth
          large
          primary
          square
        />
      );
    }
    return (
      <NoHoverButton
        buttonRef={null}
        bottom
        fullWidth
        cta='Update'
        customColor='#10cd8c'
        className='modal-button'
        // onClick={() => dispatch(setSubActiveReduxModal(''))}
        onClick={doneAndUpdatePic}
        large
        noBorder
        primary
        square
        noHover
      />
    );
  };

  const playerMasher = () => {
    // if (isCaptured) {
    //   if (isMediaLoading) {
    //     return (
    //       <SpinnerWrapper>
    //         <Spinner />
    //       </SpinnerWrapper>
    //     );
    //   }
    // }
    if (isCaptured) {
      if (imgSrc) {
        return (
          <div style={{
            width: '700px',
            height: '395px',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <img
              alt=''
              src={imgSrc}
            />
          </div>
        );
      }
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }
    return (
      <Webcam
        audio={false}
        width={700}
        height={395}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        screenshotQuality={1}
      />
    );
  };

  return (
    <WebcamContainer>
      {playerMasher()}
      {buttonMasher()}
    </WebcamContainer>
  );
};

WebcamCaptureImage.propTypes = {
  handleUploadMedia: PropTypes.func.isRequired,
};

export default WebcamCaptureImage;
