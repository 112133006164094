// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector, batch } from 'react-redux';

import IcomoonReact from 'icomoon-react';
import Text from '../../../../../shared/components/Text/Text';
import SubText from '../../../../../shared/components/SubText/SubText';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import NoHoverButton from '../programs/NoHoverButton';

import {
  createReseller,
} from '../../../ducks/resellerActions';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  .modal-button2 {
    display: flex;
    align-items: center;
    background-color: #10cd8c;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
`;

const TitlePlusErrorWrapper = styled('div')`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitlePlusErrorWrapper2 = styled('div')`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: 59px;
  align-items: center;
  width: 40%;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const InnerFolderFormWrapper = styled('div')`
  display: flex;
  width: 80%;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
  .formik-form {
    width: 100%;
  }
`;

const OuterFolderFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 10px;
  }
`;

const AltFieldWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const FormGroup = styled('div')`
  .url-string {
    text-transform: none;
    font-weight: 500;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
  .alt-form-control {
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .btow {
    font-size: 10px;
    font-weight: 800;
    width: 100%;
    height: 100%;
    min-width: 215px;
    color: white;
    background: black;
    padding: 9px;
    padding-left: 15px;
    border-radius: 0.25rem;
    border: 1px solid black;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const ModalCreateReseller = ({
  onRequestClose,
  currentUser,
}) => {
  const dispatch = useDispatch();
  const [urlHandled, setUrlHandled] = useState(false);
  const createResellerError = useSelector((state) => state.reseller.data.createResellerError);
  // a handler for documents being submitted and created.  different processes depending on doc type
  const handleSubmit = (
    values,
  ) => {
    const newValues = {
      urlSlug: values.linkUrl,
      businessName: values.name,
      defaultPortalMediaId: 0,
      stripeAuthCode: '',
      contactEmail: values.email,
    };
    batch(() => {
      dispatch(createReseller({ accountCode: currentUser.accountCode, body: newValues }));
    });
  };

  const urlSchema = Yup.object().shape({
    linkUrl: Yup.string()
      .required('URL is required'),
    name: Yup.string()
      .required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Must be a valid email'),
  });

  return (
    <Formik
      initialValues={{
        name: '',
        linkUrl: '',
        email: currentUser.email,
      }}
      validationSchema={urlSchema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleSubmit(values);
          setSubmitting(false);
        });
      }}
      render={({
        values,
        setFieldValue,
        submitForm,
        errors,
        touched,
      }) => (
        <ModalWrapper>
          <NavigationWrapper>
            <Text
              className='close arrow-div'
              onClick={() => {
                onRequestClose();
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={13}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <AnotherWrapper>
            <OuterFolderFormWrapper>
              <FolderTextWrapper>
                <Text className='calendar-icon-text'>Online Payments Account</Text>
                {createResellerError ? <TitleError className='error-text'>{createResellerError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <InnerFolderFormWrapper>
                <Form className='formik-form'>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Account Name</strong></Text>
                      {(errors.name && touched.name) ? (
                        <FieldError className='error-text'>{errors.name}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <Field
                      className='form-control'
                      id='name-field'
                      name='name'
                      placeholder='Account Name'
                      onBlur={() => {
                        if (!urlHandled) {
                          if (values.name.length) {
                            const newString = values.name.replace(/\s\s+/g, ' ');
                            const outString = newString.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                            const finalString = outString.split(' ').join('-');
                            const finalString2 = finalString.replace(/^-+|-+(?=-|$)/g, '');
                            const lowerCaseFinalString2 = finalString2.toLowerCase();
                            setFieldValue('linkUrl', lowerCaseFinalString2);
                          }
                        }
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <TitlePlusErrorWrapper>
                      <Text className='formgroup-text'><strong>Account URL</strong></Text>
                      {(errors.linkUrl && touched.linkUrl) ? (
                        <FieldError className='error-text'>{errors.linkUrl}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <AltFieldWrapper>
                      <Field
                        className='alt-form-control'
                        id='url-field'
                        name='linkUrl'
                        placeholder='account-name'
                        onFocus={() => {
                          if (!urlHandled) {
                            setUrlHandled(true);
                          }
                        }}
                        onBlur={() => {
                          const newString = values.linkUrl.replace(/\s\s+/g, ' ');
                          const outString = newString.replace(/[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, '');
                          const finalString = outString.split(' ').join('-');
                          const finalString2 = finalString.replace(/^-+|-+(?=-|$)/g, '');
                          const lowerCaseFinalString2 = finalString2.toLowerCase();
                          setFieldValue('linkUrl', lowerCaseFinalString2);
                        }}
                      />
                    </AltFieldWrapper>
                    <SubText fontSize={10} className='url-string'>{`${process.env.WEBAPP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app-v3.' : 'app-v3.'}teambuildr.com`}/programs/${values.linkUrl}`}</SubText>
                  </FormGroup>
                  <FormGroup>
                    <TitlePlusErrorWrapper2>
                      <Text className='formgroup-text'><strong>Contact Email</strong></Text>
                      {(errors.email && touched.email) ? (
                        <FieldError className='error-text'>{errors.email}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper2>
                    <Field
                      className='form-control'
                      id='name-field'
                      name='email'
                      placeholder='Account Email'
                    />
                    <SubText fontSize={10} className='url-string'>This email will be used for subscription and support emails</SubText>
                  </FormGroup>
                </Form>
              </InnerFolderFormWrapper>
            </OuterFolderFormWrapper>
          </AnotherWrapper>
          <NoHoverButton
            bottom
            fullWidth
            cta='Next'
            className='modal-button2'
            large
            noBorder
            primary
            square
            onClick={() => submitForm()}
          />
        </ModalWrapper>
      )}
    />
  );
};

ModalCreateReseller.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalCreateReseller;
