/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import TrainingResultsCard from './trainingResultsCard';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';
import Text from '../../../../shared/components/Text/Text';

const WeeklyTrainingResultsWorkoutCardsSection = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const sessions = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.training?.sessions);

  /** styles */
  const TrainingResultsPageWrapper = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background: #FDFBFB;
  `;
  const TrainingResultsScrollContainer = styled('div')`
    display: flex;
    width: 100%;
    max-width: 1400px;
    overflow-x: scroll;
    gap: 25px;
    padding: 15px;
    background: #FDFBFB;
  `;

  return (
    <TrainingResultsPageWrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <Text color='#7E7A7A' fontSize='20px' fontWeight={700} style={{ marginBottom: 25 }}>Training Results By Day Of Week:</Text>
      <TrainingResultsScrollContainer>
        {sessions?.map((session) => (
          session?.workoutItems && session?.workoutItems.length > 0 && (
          <TrainingResultsCard key={generateUniqueId()} containerWidth={containerWidth} workoutItems={session?.workoutItems} sessionDate={session?.date} sessionTime={session?.sessionTime} />
          )
        ))}
      </TrainingResultsScrollContainer>
    </TrainingResultsPageWrapper>
  );
};

export default WeeklyTrainingResultsWorkoutCardsSection;
