import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { FixedSizeList as List } from 'react-window';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import Avatar from '../../../../shared/components/Avatar/Avatar';

const AthletePickerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 320px;
  max-height: 320px;
  margin-left: -40px;
  margin-top: -5px;
  @media screen and (max-width: 767px) {
    margin-left: -20px;
    margin-top: -5px;
  }
`;

const AthleteContainer = styled('div')`
  display: flex;
  padding: 10px 30px;
  /* transition: ${TransitionGlobal}; */
  /* &:hover {
    background-color: ${props => props.theme.colorAccent};
    h3 {
      opacity: 1;
    }
  }
  img {
    height: 30px;
    width: 30px;
  } */

  h3 {
    /* transition: ${TransitionGlobal}; */
    /* color: ${props => props.theme.textColor}; */
  }

  svg {
    visibility: hidden;
  }
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
`;

const AthleteNameWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 10px;

  @media screen and (orientation: portrait) {
    margin-left: 10px;
  }
`;

const Name = styled('h3')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 0px;
  /* margin-right: 15px; */
  align-items: center;
  line-height: 16px;
  @media screen and (orientation: portrait) {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${props => (props.small ? '85px;' : 'none')}
  }
`;

const AthleteRenderer = ({ data, index, style }) => {
  const athlete = data[index];

  const image = optimizeImage(
    athlete.pic,
    {
      resize: {
        width: 70,
        height: 70,
        fit: 'cover',
      },
    },
  );

  return (
    <AthleteContainer
      key={athlete.id}
      style={style}
    >
      <Avatar
        avatar={image}
      />
      <AthleteNameWrapper>
        <Name
          // small
        >
          {`${athlete.first} ${athlete.last}`}
        </Name>
      </AthleteNameWrapper>
    </AthleteContainer>
  );
};

const ConversationDetailsModal = () => {
  const [panelDimensions, setPanelDimensions] = useState({ width: 0, height: 0 });
  // const handleActive = (name) => {
  //   handleActive(name);
  // }

  // filterGroups(groupFilter) {
  //   const { data } = this.props;
  //   return matchSorter(data, groupFilter, {
  //     keys: [
  //       'group_names',
  //     ],
  //   },
  //   {
  //     threshold: matchSorter.rankings.EQUAL,
  //   });
  // }
  // const { groupFilter, panelDimensions, isDimensionSet } = this.props;

  const conversationUsers = useSelector((state) => state.conversations.data.conversationUsers);

  return (
    <>
      <ResizeObserver
        onResize={(rect) => {
          setPanelDimensions({ width: rect.width, height: rect.height });
          console.log('set rect:', rect);
          console.log(conversationUsers);
        }}
      />
      <AthletePickerContainer>
        {conversationUsers.length > 0 && (
          <List
            height={panelDimensions && panelDimensions.height - 82}
            itemData={conversationUsers}
            itemCount={conversationUsers.length}
            itemSize={55}
            width={panelDimensions && panelDimensions.width}
          >
            {AthleteRenderer}
          </List>
        )}
      </AthletePickerContainer>
    </>
  );
};

export default ConversationDetailsModal;
