import React, { useRef, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import styled from '@emotion/styled';

import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Logger from 'js-logger';
import Linkify from 'react-linkify';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import Text from '../../../../shared/components/Text/Text';
import LoadingDots from '../../../../shared/components/LoadingDots/LoadingDots';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  openMessageVideo,
} from '../../ducks/conversationsActions';

// const imageOptimizer = (friendPic) => optimizeImage(
//   friendPic,
//   {
//     resize: {
//       width: 60,
//       height: 60,
//       fit: 'contain',
//     },
//   },
// );

const UserMessage = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: ${(props) => `url(${props.userProfilePic})`};
    background-size: contain;
    margin-right: 10px;
  }
  .message-text {
    display: flex;
    align-items: center;
    color: white;
    background: #77A7FF;
    padding: 9px;
    border-radius: 20px;
    margin-right: 10px;
    overflow-wrap: anywhere;
    margin-left: 50px;
    max-width: 500px;
    white-space: break-spaces;

    a {
      font-size: 14px;
      color: white;
      text-decoration: underline
    }
  }
`;

const MessageText = styled('div')`
  display: flex;
  align-items: center;
  color: transparent;
  background: ${(props) => (props.friend ? '#77A7FF' : '#ff6600')};
  padding: 9px;
  border-radius: 20px;
  margin-right: ${(props) => (props.friend ? '50px' : '10px')};
  overflow-wrap: anywhere;
  margin-left: ${(props) => (props.friend ? '10px' : '50px')};

  max-width: 500px;
  white-space: break-spaces;
`;

const UserPic = styled('div')`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.userProfilePic})`};
  background-size: contain;
  ${(props) => (props.friend ? 'margin-left: 10px;' : 'margin-right: 10px;')}
`;

const FriendMessage = styled('div')`
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    /* background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`}; */
    background-size: contain;
    margin-left: 10px;
  }
  .message-text {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.background[5]};
    /* background: #77A7FF; */
    padding: 9px;
    border-radius: 20px;
    margin-left: 50px;
    overflow-wrap: anywhere;
    margin-right: 50px;
    max-width: 500px;
    white-space: break-spaces; 

    a {
      font-size: 14px;
      color: white;
      text-decoration: underline
    }
  }
`;

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.friendMessage ? 'flex-start' : 'flex-end')};
  .latest-time {
    ${(props) => (props.friendMessage ? (
    'margin-left: 10px;'
  ) : (
    'margin-right: 10px;'
  ))}
    margin-top: 5px;
  }
`;

const UserMessageMedia = styled('div')`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* margin-top: ${(props) => {
    if (!props.props.messageBody) {
      return '-40px';
    }
    return '5px';
  }}; */
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const FriendMessageMedia = styled('div')`
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const StatusIcons = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  align-items: flex-end;

  svg {
    margin-left: 5px;
  }
`;

const MessagesIsTyping = () => {
  const usersTyping = useSelector((state) => state.conversations.data.usersTyping);
  const activeConversation = useSelector((state) => state.conversations.data.activeConversation);

  const [isTypingVisible, setIsTypingVisible] = useState(false);

  useEffect(() => {
    // If there are any users typing in the current active conversation
    if (usersTyping.length > 0
      && usersTyping.filter(
        (typingConvo) => typingConvo.conversationId === activeConversation.id,
      ).length > 0
    ) {
      setIsTypingVisible(true);
    } else {
      setIsTypingVisible(false);
    }
  }, [usersTyping.length]);

  // To add the dynamic height of each message to the animation, we rebuild
  // the conversationMessages array with an index key value and use that to access
  // the correct message ref from the messagesRef array to get the clientHeight.
  // We also use async await next to make sure the clientHeight isn't undefined before
  // we access it. The refs are assigned in the currentMessageMapper.
  const typingAnimation = useSpring(
    {
      height: isTypingVisible ? 70 : 0,
      opacity: isTypingVisible ? 1 : 0,
      flexShrink: 0,
      margin: '10px 0px 10px 10px',
    },
  );

  return (
    <>
      <animated.div style={{ ...typingAnimation, zIndex: 1 }}>
        <FriendMessage>
          {/* <div className='userpic' /> */}
          <MessageContainer friendMessage>
            <div className='message-text'>
              <LoadingDots />
            </div>
          </MessageContainer>
        </FriendMessage>
      </animated.div>
    </>
  );
};

export default MessagesIsTyping;
