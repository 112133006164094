/* eslint-disable no-loop-func */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, usePagination } from 'react-table';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { setReportReadyForPrint } from '../../ducks/reportingActions';

const Styles = styled.div`
  background: #ffffff;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${(props) => (props.multiPage ? '25px 20px 10px 20px' : '25px 20px 25px 20px')};
  border-radius: 8px;
  .athleteNameTitle {
    left: 0;
    background-color: #fff;
    z-index: 2;
  }
  tr:first-child th.athleteNameTitle {
    border-bottom: none;
    z-index: 3;
  }
  .athleteNameTitle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    background-color: #bdbdbd1a;
    border-top-left-radius: 8px;
  }

  table {
    tr {
      border-right: 1px solid #eee;
    }
    th {
      z-index: 10;
    }
    th,
    td {
      margin: 0;
      text-align: center;
      min-width: 40px;
    }
  }
  @media print {
    height: auto !important;
    overflow: visible !important;
  }
`;

const TableContainerDiv = styled('div')`
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  @media print {
    height: auto !important;
    overflow: visible !important;
    table {
      page-break-inside: auto !important;
      width: 100%;
      table-layout: auto;
      @page {
        margin: 6mm;
      }
    }
  }
`;

const TableHeaderCell = styled('th')`
  border-top: ${(props) => props.styles.borderTop};
  border-right: ${(props) => props.styles.borderRight};
  border-bottom: ${(props) => props.styles.borderBottom};
  border-left: ${(props) => props.styles.borderLeft};
  background-color: ${(props) => props.styles.backgroundColor};
  position: ${(props) => props.styles.position || 'static'};
  left: ${(props) => (props.styles.position ? 0 : '')};
  z-index: ${(props) => (props.styles.position ? 3 : 1)};
  border-radius: ${(props) => props.styles.borderRadius || '0 0 0 0'};
  padding: 0px;
  font-size: 14px;
  height: 52px;
`;

const AthleteRow = styled('tr')`
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  height: auto;
  box-sizing: border-box;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

const TableDataCell = styled('td')`
  border-top: ${(props) => props.styles.borderTop};
  border-right: ${(props) => props.styles.borderRight};
  border-bottom: ${(props) => props.styles.borderBottom};
  border-left: ${(props) => props.styles.borderLeft};
  background-color: ${(props) => props.styles.backgroundColor};
  position: ${(props) => props.styles.position || 'static'};
  padding: ${(props) => props.styles.padding || '0px'};
  text-wrap: ${(props) => props.styles.textWrap || 'wrap'};
  font-size: 13px;
  .tooltiptext {
    z-index: 1000;
  }
  .name-div {
    min-width: 150px;
    justify-content: flex-start;
  }
  @media screen and (max-width: 767px) {
    .name-div {
      min-width: 130px;
      justify-content: center;
    }
    .avatar-div {
      display: none;
    }
  }
`;

const PaginationContainer = styled('div')`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Pagination = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const PaginationButton = styled('button')`
  font-family: "Nunito Sans";
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  display: flex;
  justify-content: center;
  border: none;
`;

const Table = React.forwardRef(
  (
    {
      containerHeight,
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      columns,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      pageIndex,
      setPageSize,
      memoizedDataRows,
      numMaxColumns,
      showAllForDownload,
    },
    ref,
  ) => {
    // numbered page buttons
    const renderPageButtons = () => {
      const buttons = [];
      for (let i = 0; i < pageCount; i++) {
        buttons.push(
          <PaginationButton
            type='button'
            key={i}
            onClick={() => gotoPage(i)}
            style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
          >
            {i + 1}
          </PaginationButton>,
        );
      }
      return buttons;
    };

    // for downloading or printing, show all data in one page to ensure data visibility
    useEffect(() => {
      if (showAllForDownload) {
        setPageSize(memoizedDataRows.length);
      } else {
        setPageSize(50);
      }
    }, [showAllForDownload]);

    // style props for th elements
    const headerCellStyles = (header) => {
      const switchString = /^Max \d+$/.test(header) ? 'Max Number' : header;
      const maxColBorderRadius = () => {
        if (header === 'Max 1') return '8px 0 0 0';
        if (header === `Max ${numMaxColumns}`) return '0 8px 0 0';
        return '0 0 0 0';
      };

      switch (switchString) {
        case 'Name':
          return {
            borderBottom: '1px solid #eee',
            backgroundColor: '#fcfcfc',
            position: 'sticky',
            borderRadius: '8px 0 0 0',
            padding: '0',
          };
        case 'Exercise':
          return {
            borderBottom: '1px solid #eee',
            backgroundColor: '#fcfcfc',
          };
        case 'Date':
          return {
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px solid #eee',
            borderLeft: '1px solid #eee',
            backgroundColor: '#fcfcfc',
          };
        case 'Max':
          return {
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px solid #eee',
            borderLeft: 'none',
            backgroundColor: '#fcfcfc',
          };
        case 'Unit':
          return {
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: '1px solid #eee',
            borderLeft: 'none',
            backgroundColor: '#fcfcfc',
          };
        case 'Change':
          return {
            borderTop: 'none',
            borderRight: '1px solid #eee',
            borderBottom: '1px solid #eee',
            borderLeft: 'none',
            backgroundColor: '#fcfcfc',
          };
        case 'Max Number': {
          return {
            borderTop: 'none',
            borderRight: '1px solid #eee',
            borderBottom: 'none',
            borderLeft: header === 'Max 1' ? 'none' : '1px solid #eee',
            backgroundColor: '#fcfcfc',
            borderRadius: maxColBorderRadius(),
            padding: '0',
          };
        }
        case 'Name top': {
          return {
            borderTop: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            borderLeft: 'none',
            backgroundColor: '#ffffff',
            position: 'sticky',
          };
        }
        default:
          return {
            backgroundColor: '#ffffff',
          };
      }
    };

    // style props for td elements
    const tableCellStyles = (cellName, backgroundColor = '#fcfcfc') => {
      switch (cellName) {
        case 'name':
          return {
            backgroundColor,
            position: 'sticky',
          };
        case 'exercise':
          return {
            backgroundColor,
            padding: '9px 15px',
            textWrap: 'nowrap',
          };
        case 'date':
          return {
            borderTop: 'none',
            borderRight: '1px solid #eee',
            borderLeft: '1px solid #9e9e9e',
            backgroundColor,
            padding: '8px',
            textWrap: 'nowrap',
          };
        case 'max':
          return {
            borderTop: 'none',
            borderRight: '1px solid #eee',
            borderLeft: '1px solid #eee',
            padding: '8px',
            backgroundColor,
          };
        case 'unit':
          return {
            borderTop: 'none',
            borderLeft: '1px solid #eee',
            padding: '8px',
            backgroundColor,
          };
        case 'change':
          return {
            borderTop: 'none',
            borderRight: '1px solid #9e9e9e',
            borderLeft: '1px solid #eee',
            padding: '8px',
            backgroundColor,
          };
        default:
          return {
            borderTop: '1px solid #eee',
            borderRight: '1px solid #eee',
            borderLeft: '1px solid #eee',
            padding: '8px',
            backgroundColor,
          };
      }
    };

    return (
      <>
        <TableContainerDiv
          id='comparisonTable'
          style={{ display: 'flex', zIndex: 0 }}
        >
          <table
            {...getTableProps()}
            style={{
              minHeight: page.length <= 10 ? containerHeight : 'unset',
              display: page.length <= 10 ? 'inline' : 'flexbox',
              width: '100%',
            }}
            ref={ref}
          >
            <thead className='sticky-header'>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const headerName = () => {
                      if (column.headers && column.headers[0].Header === 'Name') return 'Name top';
                      if (
                        column.headers
                        && column.headers[0].Header === 'Exercise'
                      ) return 'Exercise top';
                      return column.render('Header').toString();
                    };
                    const isMaxCol = /^Max \d+$/.test(headerName());

                    return (
                      <TableHeaderCell
                        styles={headerCellStyles(headerName())}
                        colSpan={column.headers ? column.headers.length : 1}
                      >
                        {/* inner styling div to improve border behavior on th elements */}
                        <div
                          style={{
                            borderTop:
                              headerName() === 'Name'
                              || headerName() === 'Exercise'
                              || isMaxCol
                                ? '1px solid #eee'
                                : headerName() === 'Name top'
                                  ? '2px solid #fff'
                                  : '',
                            borderRight:
                              headerName() === 'Name' ? '1px solid #eee' : '',
                            borderLeft:
                              headerName() === 'Name'
                              || headerName() === 'Max 1'
                                ? '1px solid #eee'
                                : headerName() === 'Name top'
                                  ? '1px solid #fff'
                                  : '',
                            padding: headerName() === 'Name' ? '15px' : '0',
                            borderTopLeftRadius:
                              headerName() === 'Name'
                              || headerName() === 'Max 1'
                                ? '8px'
                                : '',
                            borderTopRightRadius:
                              headerName() === `Max ${numMaxColumns}`
                                ? '8px'
                                : '',
                            height: '56px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {column.render('Header')}
                        </div>
                      </TableHeaderCell>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ flexGrow: 1 }}>
              {page.map((row) => {
                prepareRow(row);
                // used to ensure athlete name td only renders
                // once per athlete and occupies proper height
                const rowsWithSameAthlete = page.filter(
                  (pageItem) => pageItem.original.athlete === row.original.athlete,
                );
                const len = rowsWithSameAthlete.length;
                const isFirstExercise = row.cells[1].value
                  === rowsWithSameAthlete[0].original.exercise.displayValue;
                const isLastRow = row.index + 1 === page.length;
                // minor reformat of data for easier iteration in table
                const cells = [];
                cells.push({
                  ...row.original.exercise,
                  name: 'exercise',
                });
                row.original.reportTable.cells.forEach((cellGroup) => {
                  Object.keys(cellGroup).forEach((cellName) => {
                    cells.push({
                      ...cellGroup[cellName],
                      name: cellName,
                    });
                  });
                });

                return (
                  <AthleteRow
                    {...row.getRowProps()}
                    borderTop={
                      isFirstExercise
                        ? '1px solid #9e9e9e !important'
                        : '0.9px solid #eee'
                    }
                    borderBottom={isLastRow ? '1px solid #eee' : 'none'}
                  >
                    {isFirstExercise && (
                      <TableDataCell
                        styles={tableCellStyles('name')}
                        rowSpan={rowsWithSameAthlete.length || 1}
                        style={{
                          verticalAlign: 'middle',
                          textAlign: 'center',
                          left: '0px',
                          zIndex: 2,
                        }}
                      >
                        {columns[0].Cell({ row }, len)}
                      </TableDataCell>
                    )}
                    {cells.map((cell) => (
                      <TableDataCell
                        styles={tableCellStyles(
                          cell.name,
                          cell.backgroundColor,
                        )}
                      >
                        <div
                          style={{
                            fontWeight: cell.name === 'change' ? 700 : 400,
                            color: cell.textColor,
                          }}
                        >
                          {cell.hoverMessage ? (
                            <TBTooltip
                              alwaysDisplay
                              textToDisplay={cell.displayValue}
                              textToHover={cell.hoverMessage}
                              textContainerPadding='0px'
                              fontColor={cell.textColor}
                              fontWeight={cell.name === 'change' ? 700 : 400}
                              fontSize='13px'
                              cursor='default'
                            />
                          ) : (
                            `${cell.displayValue}`
                          )}
                        </div>
                        {cell.detailedText && !showAllForDownload && (
                          <div
                            style={{
                              fontWeight: 'bold',
                              fontSize: '12px',
                              color: cell.textColor,
                            }}
                          >
                            {cell.detailedText.value}
                          </div>
                        )}
                      </TableDataCell>
                    ))}
                  </AthleteRow>
                );
              })}
            </tbody>
          </table>
        </TableContainerDiv>
        {pageCount > 1 ? (
          <PaginationContainer>
            <Pagination>
              <PaginationButton
                type='button'
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {'<'}
              </PaginationButton>
              {' '}
              {renderPageButtons()}
              {' '}
              <PaginationButton
                type='button'
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {'>'}
              </PaginationButton>
            </Pagination>
          </PaginationContainer>
        ) : null}
      </>
    );
  },
);

const ComparisonReportTable = ({
  containerHeight,
  containerWidth,
  dataRows,
  rowIsScrollable,
  printRef,
}) => {
  const dispatch = useDispatch();
  const showAllForDownload = useSelector(
    (state) => state.reporting.ui.showAllForDownload,
  );

  const athleteData = [...dataRows][1];

  // total number of max columns - each with date, max, unit, and change (except Max 1) subcolumns
  const numMaxColumns = useMemo(
    () => athleteData[0].exercises[0].reportTable.cells.length,
    [dataRows],
  );

  const chartRef = useRef(null);

  // Create columns
  const tableColumns = useMemo(() => {
    const staticColumns = [
      {
        Header: 'Name',
        accessor: 'athleteName',
        className: 'athleteNameTitle',
        Cell: ({ row }, length) => {
          const { athlete } = row.original;
          const divHeight = `${length * 52}px`;

          return (
            <div
              style={{
                borderRight: '1px solid #eee',
                borderLeft: '1px solid #eee',
                boxSizing: 'content-box',
                paddingLeft: '15px',
                paddingRight: '15px',
              }}
            >
              <div
                className='name-div'
                style={{
                  position: 'sticky',
                  left: '0px',
                  zIndex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  height: divHeight,
                }}
              >
                <div
                  className='avatar-div'
                  style={{
                    height: '32px',
                    minWidth: '32px',
                    maxWidth: '32px',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    marginRight: '10px',
                  }}
                >
                  <img
                    src={athlete.pic}
                    alt={`${athlete.firstName} ${athlete.lastName} icon`}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <span
                  style={{ fontSize: '13px', color: '#444', textAlign: 'left' }}
                >
                  {athlete.firstName}
                  {' '}
                  {athlete.lastName}
                </span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Exercise',
        accessor: 'exercise.displayValue',
      },
    ];

    const maxColumns = [];
    for (let i = 0; i < numMaxColumns; i++) {
      if (i === 0) {
        maxColumns.push({
          Header: `Max ${i + 1}`,
          className: 'maxColumnHeaderCell',
          style: {
            colSpan: 3,
          },
          columns: [
            {
              Header: 'Date',
              accessor: `reportTable.cells[${i}].date.displayValue`,
            },
            {
              Header: 'Max',
              accessor: `reportTable.cells[${i}].max.displayValue`,
            },
            {
              Header: 'Unit',
              accessor: `reportTable.cells[${i}].unit.displayValue`,
            },
          ],
        });
      } else {
        maxColumns.push({
          Header: `Max ${i + 1}`,
          style: {
            colSpan: 3,
          },
          columns: [
            {
              Header: 'Date',
              accessor: `reportTable.cells[${i}].date.displayValue`,
            },
            {
              Header: 'Max',
              accessor: `reportTable.cells[${i}].max.displayValue`,
            },
            {
              Header: 'Change',
              accessor: `reportTable.cells[${i}].change.displayValue`,
            },
          ],
        });
      }
    }

    return [...staticColumns, ...maxColumns];
  }, [numMaxColumns]);

  // athleteData has one obj per athlete; transform to one obj per exercise for iteration in table
  const transformedData = athleteData.flatMap((athlete) => athlete.exercises.map((exercise) => ({
    ...exercise,
    athlete,
  })));

  const memoizedColumns = useMemo(() => tableColumns, [
    athleteData,
    containerWidth,
  ]);
  const memoizedDataRows = useMemo(() => transformedData, [
    athleteData,
    containerWidth,
  ]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize, pageIndex },
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedDataRows,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    usePagination,
  );

  // typical page size of 50 rows (one row per exercise)
  useEffect(() => {
    setPageSize(50);
  }, []);

  // report is only ready for printing if all data is included in one page
  useEffect(() => {
    if (pageSize === memoizedDataRows.length) {
      dispatch(setReportReadyForPrint(true));
    } else {
      dispatch(setReportReadyForPrint(false));
    }
  }, [pageSize]);

  // ensure all data included in downloaded excel doc

  const multiPage = memoizedDataRows.length > 50;

  return (
    <>
      <Styles
        ref={chartRef}
        rowIsScrollable={rowIsScrollable}
        containerWidth={containerWidth}
        multiPage={multiPage}
      >
        <Table
          containerHeight={containerHeight}
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          prepareRow={prepareRow}
          page={page}
          columns={memoizedColumns}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          memoizedDataRows={memoizedDataRows}
          pageIndex={pageIndex}
          numMaxColumns={numMaxColumns}
          showAllForDownload={showAllForDownload}
          ref={printRef}
        />
      </Styles>
    </>
  );
};

export default ComparisonReportTable;
