import { useDispatch } from 'react-redux';
import { submitForgot, submitReset, validateToken } from '../../ducks/forgotActions';
import { fetchLogin, resetLogOut } from '../../../login/ducks/loginActions';

const useReset = () => {
  const dispatch = useDispatch();

  const handleFetchUser = () => {
    dispatch(fetchLogin());
  };

  const handleForgot = (values) => {
    dispatch(submitForgot(values));
  };

  const handleReset = (values) => {
    dispatch(submitReset(values));
  };

  const handleResetLogOut = () => {
    dispatch(resetLogOut());
  };

  const handleValidate = (values) => {
    dispatch(validateToken(values));
  };

  return {
    handleFetchUser,
    handleForgot,
    handleReset,
    handleResetLogOut,
    handleValidate,
  };
};

export default useReset;
