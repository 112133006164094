import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

import NoHoverButton from './NoHoverButton';
import Text from '../../../shared/components/Text/Text';
import Title from '../../../shared/components/Title/Title';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import { setScheduledMessageRedux } from '../ducks/conversationsActions';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  .modal-button {
    align-items: center;
    background: '#ff6600';
    margin-right: -40px;
    color: white!important;
  }

  @media screen and (max-width: 540px) {
    .modal-button {
      margin-right: -20px!important;
    }
  }

  @media screen and (max-height: 767px) {
    .modal-button {
      margin-right: -20px!important;
    }
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  padding-bottom: 20px;
  .modal-button {
    align-items: center;
    background: '#ff6600';
  }
  .ant-picker-input {
    input {
      text-align: center;
      margin-left: 10px;
    }
  }

  h1 {
    margin-bottom: 30px;
  }
`;

const NavigationWrapper = styled('div')`  
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  top: 0;
  position: absolute;
  margin-left: -60px;
  margin-top: 10px;
  .back-arrow-div {
    cursor: pointer;
  }
  top: 0;
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const ScheduleModal = ({
  onRequestClose,
}) => {
  const dispatch = useDispatch();
  const [scheduledMessage, setScheduledMessage] = useState({});
  return (
    <ModalWrapper>
      <InnerWrapper>
        <AnotherWrapper>
          <NavigationWrapper>
            <Text
              className='back-arrow-div'
              onClick={() => {
                onRequestClose();
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
        </AnotherWrapper>
        <HeaderText>Schedule New Message</HeaderText>
        <DatePicker
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '30px',
          }}
          size='large'
          allowClear
          showTime={{
            format: 'MMMM Do, h:mm a',
          }}
          format='MMMM Do, h:mm a'
          onChange={(dateTime) => {
            setScheduledMessage(dateTime);
          }}
          suffixIcon={<div />}
          placeholder='Schedule your message for a later date'
          showToday
          disabledDate={(date) => {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            return date <= yesterday;
          }}
        />
        <NoHoverButton
          className='modal-button'
          cta={Object.keys(scheduledMessage).length ? 'Schedule Message' : 'Close'}
          customColor='#10cd8c'
          onClick={() => {
            if (!Object.keys(scheduledMessage).length) {
              onRequestClose();
            } else {
              dispatch(setScheduledMessageRedux(scheduledMessage));
            }
          }}
          noHover
          noBorder
          bottom
          fullWidth
          large
          primary
          square
        />
      </InnerWrapper>
    </ModalWrapper>
  );
};

ScheduleModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default ScheduleModal;
