/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';

import Modal from '../../../shared/components/Modal/Modal';

import useMaxReportModal from './hooks/useMaxReportModal';
import MaxModalForm from './MaxReport/MaxModalForm';
import MaxDownloadReport from './MaxReport/MaxDownloadReport';
import QuestionnaireReportForm from './QuestionnaireReport/QuestionnaireReportForm';
import QuestionnaireDownloadReport from './QuestionnaireReport/QuestionnaireDownloadReport';
import DownloadActivityReport from './ActivityReport/DownloadActivityReport';
import RawDataReportForm from './RawDataReport/RawDataReportForm';
import EvaluationReportForm from './EvaluationReport/EvaluationReportForm';
import EvaluationDownloadReport from './QuestionnaireReport/EvaluationDownloadReport';
import OptOutNotesDownloadReport from './BaseResultsReport/OptOutNotesDownloadReport';

import { setShowAllForDownload } from '../ducks/reportingActions';
import WorkoutResultsReportForm from './BaseResultsReport/WorkoutResultsReportForm';
import OptOutNotesReportForm from './BaseResultsReport/OptOutNotesReportForm';
import ResultsDownloadReport from './BaseResultsReport/ResultsDownloadReport';
import ComparisonReportForm from './ComparisonReport/ComparisonReportForm';
import DownloadComparisonReport from './ComparisonReport/DownloadComparisonReport';

const ModalContainer = styled('div')`
  width: 100%;
  height: ${(props) => (props.height ? props.height : 'auto')};

  .modal-button {
    position: absolute;
    bottom: 0px;
  }
  
  .toggle {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  @media (max-width: 540px) {
    padding: 0px!important;
  }

  @media screen and (max-width: 1024) {
    padding: 0px 30px 60px 30px;
  }
`;

const ReportingModal = () => {
  const {
    activeModal,
    handleSetIsModalOpen,
    isModalOpen,
  } = useMaxReportModal();

  const sidebarWidth = useSelector((state) => state.reporting.ui.sidebarWidth);
  const hasReportData = useSelector((state) => state.reporting.ui.hasReportData);

  const dispatch = useDispatch();

  const closeModal = () => {
    handleSetIsModalOpen(false, activeModal);
    if (activeModal === 'downloadEvaluationReport' || activeModal === 'downloadComparisonReport') {
      dispatch(setShowAllForDownload(false));
    }
  };

  const modalTitle = () => {
    if (activeModal === 'downloadActivityReport') {
      return 'Download Activity Report';
    } if (activeModal === 'rawDataReportModal') {
      return 'New Raw Data Report';
    }
    if (activeModal === 'questionnaire') {
      return hasReportData ? 'Edit Questionnaire Report' : 'New Questionnaire Report';
    }
    if (activeModal === 'downloadQuestionnaireReport') {
      return 'Download Questionnaire Report';
    }
    if (activeModal === 'evaluation') {
      return 'Evaluation Report';
    }
    if (activeModal === 'downloadEvaluationReport') {
      return 'Download Evaluation Report';
    }
    if (activeModal === 'workoutResultsReport') {
      return 'Workout Results Report';
    }
    if (activeModal === 'optOutNotesReport') {
      return 'Opt Out Notes';
    }
    if (activeModal === 'downloadOptOutNotesReport') {
      return 'Download Opt Out Notes Report';
    }
    if (activeModal === 'downloadResultsReport') {
      return 'Download Results Report';
    }
    if (activeModal === 'comparison') {
      return 'Comparison Report';
    }
    if (activeModal === 'downloadComparisonReport') {
      return 'Download Comparison Report';
    }
    return hasReportData ? activeModal === 'downloadMaxReport'
      ? 'Download Max Report' : 'Edit Max Report' : 'New Max Report';
  };

  const modalIcon = () => {
    if (activeModal === 'downloadActivityReport') {
      return 'journal';
    } if (activeModal === 'rawDataReportModal') {
      return 'raw-data-report';
    }
    if ((activeModal === 'questionnaire') || (activeModal === 'downloadQuestionnaireReport')) {
      return 'science';
    }
    if ((activeModal === 'evaluation') || (activeModal === 'downloadEvaluationReport')) {
      return 'note';
    }
    if ((activeModal === 'workoutResultsReport')) {
      return 'list';
    }
    if ((activeModal === 'downloadResultsReport')) {
      return 'list';
    }
    if ((activeModal === 'optOutNotesReport')) {
      return 'warning';
    }
    if ((activeModal === 'downloadOptOutNotesReport')) {
      return 'warning';
    }
    if (activeModal === 'comparison' || activeModal === 'downloadComparisonReport') {
      return 'history';
    }
    return 'trophy';
  };

  return (
    <Modal
      onRequestClose={closeModal}
      isOpen={isModalOpen}
      title={modalTitle()}
      titleIcon={modalIcon()}
      customButton
      noOverlay
      sidebarWidth={sidebarWidth}
      customMaxWidth={activeModal === 'comparison' ? '750px' : '650px'}
    >
      <ModalContainer
        sidebarWidth={sidebarWidth}
        height={activeModal === 'rawDataReportModal' ? '525px' : ''}
      >
        {activeModal === 'main' && (
          <MaxModalForm
            closeModal={closeModal}
          />
        )}
        {activeModal === 'rawDataReportModal' && (
        <RawDataReportForm
          closeModal={closeModal}
        />
        )}
        {activeModal === 'questionnaire' && (
        <QuestionnaireReportForm
          closeModal={closeModal}
        />
        )}
        {activeModal === 'evaluation' && (
          <EvaluationReportForm
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadMaxReport' && (
          <MaxDownloadReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'workoutResultsReport' && (
          <WorkoutResultsReportForm
            closeModal={closeModal}
          />
        )}
        {activeModal === 'optOutNotesReport' && (
          <OptOutNotesReportForm
            closeModal={closeModal}
          />
        )}
        {activeModal === 'comparison' && (
          <ComparisonReportForm
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadActivityReport' && (
          <DownloadActivityReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadQuestionnaireReport' && (
          <QuestionnaireDownloadReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadEvaluationReport' && (
          <EvaluationDownloadReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadOptOutNotesReport' && (
          <OptOutNotesDownloadReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadResultsReport' && (
          <ResultsDownloadReport
            closeModal={closeModal}
          />
        )}
        {activeModal === 'downloadComparisonReport' && (
          <DownloadComparisonReport
            closeModal={closeModal}
          />
        )}
      </ModalContainer>
    </Modal>
  );
};

export default ReportingModal;
