/* eslint-disable react/prop-types */
import React, {
  useEffect, useLayoutEffect, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import DataTable, { createTheme } from 'react-data-table-component';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Dropdown from '../../../../shared/_TBComponents/Dropdown';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import {
  setHabitTrackingPageWrapperScrollTriggered, fetchSingleHabitForAccount, setDeleteModalData,
} from '../../ducks/habitTrackingActions';
import { setModalOpen, setErrorModalOpen } from '../../../sports-science/ducks/sportsScienceActions';

createTheme('teambuildr', {
  text: {
    primary: '#444444',
    secondary: '#898989',
  },
  background: {
    default: 'transparent',
  },
});
const TableWrapper = styled('div')`
display: flex;
width: 100%;
max-width: ${(props) => (props.pageContainerWidth)}px;
`;

const IconCircle = styled('div')`
height: 20px;
width: 20px;
min-width: 20px;
border-radius: 50%;
color: ${(props) => props.color};
border: solid 1px;
display: flex;
justify-content: center;
align-items: center;
`;

const GreenDotAndActiveContainer = styled('div')`
display: flex;
align-items: center;
gap: 5px;
`;

const GreenDot = styled('div')`
width: 10px;
height: 10px;
background: #0AA36E;
border-radius: 50%;
`;

const GrayDotAndInactiveContainer = styled('div')`
display: flex;
align-items: center;
gap: 5px;

`;

const GrayDot = styled('div')`
width: 10px;
height: 10px;
background: #BDBDBD;
border-radius: 50%;
`;

const IconAndNameAndFrequencyWrapper = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
width: 100%;
`;

const ClickableCell = styled.div`
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
`;

const NameAndFrequencyContainer = styled('div')`
display: flex;
flex-direction: column;
align-items: left;
width: 100%;
`;

const FrequencyAndDescriptionContainer = styled('div')`
gap: 5px;
display: flex;
align-items: center;
`;

const TinyGreyCircle = styled('div')`
min-width: 4px;
min-height: 4px;
max-width: 4px;
max-height: 4px;
border-radius: 50%;
background: #9E9E9E;
`;

const GroupsIconAndTextContainer = styled('div')`
display: flex;
align-items: center;
gap: 5px;
`;
const GroupInfoIconCircle = styled('div')`
height: 14px;
width: 14px;
min-width: 14px;
border-radius: 50%;
border: solid 0.5px #9E9E9E;
display: flex;
justify-content: center;
align-items: center;
`;

const customStyles = {
  headRow: {
    style: {
      border: 'none',
      minHeight: '77px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  headCells: {
    style: (column) => ({
      paddingLeft: column.column.name === 'Habit name' && '30px',
      fontFamily: 'Nunito Sans',
      fontSize: '19px',
      fontWeight: '700',
      textAlign: 'center',
    }),
  },
  cells: {
    style: {
      fontFamily: 'Nunito Sans',
      fontSize: '12px',
      fontWeight: '800',
      paddingLeft: '0px',
    },
  },
  rows: {
    style: {
      background: 'white',
      marginBottom: '20px',
      borderBottomWidth: '0px',
      borderRadius: '8px',
      boxShadow: '0px 4px 8px 0px #3031331A',
      padding: '20px',
    },
  },
};

const conditionalRowStyles = [
  {
    when: (row) => row,
    style: {
      height: '91px',
    },
  },
];

const CustomTable = ({
  data, pageContainerWidth, paddingRight, scrollTriggered,
}) => {
  const containerRef = useRef(null);
  const userDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openUserDropdown, setOpenUserDropdown] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [containerWidthNameAndFrequency, setContainerWidthNameAndFrequency] = useState(0);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;

  const handleUserNameClicked = (habitId) => {
    dispatch(fetchSingleHabitForAccount(accountCode, habitId, {
      onSuccess: () => {},
      onError: () => {
        dispatch(setModalOpen(false, ''));
        dispatch(setErrorModalOpen(true, 'general-error', 'could not fetch data for habit'));
      },
    }));
    dispatch(setModalOpen(true, 'habitTracking-edit-habit'));
  };

  const options = [
    {
      text: 'Edit',
      function: () => {
        dispatch(fetchSingleHabitForAccount(accountCode, openDropdown, {
          onSuccess: () => {},
          onError: () => {
            dispatch(setModalOpen(false, ''));
            dispatch(setErrorModalOpen(true, 'general-error', 'could not fetch data for habit'));
          },
        }));
        dispatch(setModalOpen(true, 'habitTracking-edit-habit'));
      },
      icon: 'pencil',
    },
    {
      text: 'Delete',
      function: () => {
        const habit = data.find((habitFromDataArray) => habitFromDataArray.id === openDropdown);
        dispatch(setModalOpen(true, 'habitTracking-delete-habit'));
        dispatch(setDeleteModalData({
          type: 'habit',
          id: habit?.id,
          name: habit?.name,
          active: habit?.active,
        }));
      },
      icon: 'trashcan',
    },
  ];

  const userDropdownOptions = userOptions?.map((user) => (
    {
      text: `${user.first} ${user.last}`,
      avatar: user.pic,
      icon: 'user',
    }
  ));
  const handleDropdownToggle = (categoryId) => {
    setOpenDropdown(openDropdown === categoryId ? null : categoryId);
  };

  const handleDropdownToggleForUserDropdown = (habitId, userArray) => {
    setOpenUserDropdown(openUserDropdown === habitId ? null : habitId);
    setUserOptions(openUserDropdown === habitId ? [] : userArray);
  };

  useEffect(() => {
    if (openDropdown && scrollTriggered) {
      setOpenDropdown(null);
    }
    if (openUserDropdown && scrollTriggered) {
      setOpenUserDropdown(null);
    }
  }, [scrollTriggered]);

  const handleScroll = (event) => {
    if (userDropdownRef?.current && userDropdownRef.current.contains(event.target)) {
      /** If scrolling happens within the dropdown, do nothing */
      return;
    }
    /** Close the dropdowns if scrolling happens outside of the dropdown */
    if (openDropdown) {
      setOpenDropdown(null);
    }
    if (openUserDropdown) {
      setOpenUserDropdown(null);
    }
  };

  useEffect(() => {
    if (openDropdown) {
      dispatch(setHabitTrackingPageWrapperScrollTriggered(false));
    }
  }, [openDropdown]);

  useEffect(() => {
    if (openUserDropdown) {
      dispatch(setHabitTrackingPageWrapperScrollTriggered(false));
    }
  }, [openUserDropdown]);

  useLayoutEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidthNameAndFrequency(containerRef.current.getBoundingClientRect().width);
      }
    };

    const deferInitialWidthCalculation = () => {
      requestAnimationFrame(handleResize); /** Defer execution until after initial render */
    };

    deferInitialWidthCalculation(); /** Call the function to defer the width calculation */

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  const CustomDropdownTrigger = ({ row }) => {
    const AvatarContainer = styled('div')`
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  `;

    const Avatar = styled('div')`
    width: 28px;
    height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 50%;
    border: solid 1px #444444;
    margin-right: -8px;
    background-image: ${(p) => `url(${p.url})`};
    background-color: #E5E4E2;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  `;

    return (
      <AvatarContainer row={row}>
        {row.users?.length
          ? row.users.map((user, index) => {
            if (index <= 3) {
              return (
                <Avatar key={user.id} url={user.pic}>
                  {!user.pic && (
                  <IcomoonReact
                    color='grey'
                    icon='user'
                    iconSet={iconSet}
                    size={20}
                  />
                  )}
                </Avatar>
              );
            }
            return null;
          })
          : null}
        {row.users?.length > 4 ? (
          <Text ml={10} fontWeight={700} fontSize='16px'>{`+${row.users?.length - 4}`}</Text>
        ) : null}
      </AvatarContainer>
    );
  };

  const columns = [
    {
      name: 'Habit name',
      cell: (row) => (
        <ClickableCell>
          <IconAndNameAndFrequencyWrapper onClick={() => handleUserNameClicked(row?.id)}>
            <IconCircle color={row.category?.color}>
              <IcomoonReact
                iconSet={iconSet}
                size={12}
                icon={row.category?.icon || 'question-mark'}
              />
            </IconCircle>
            <NameAndFrequencyContainer ref={containerRef}>
              <TBTooltip
                addHoverOpacity
                textToDisplay={row.name}
                textToHover={row.name}
                fontSize='16px'
                fontWeight={700}
                toolTipFontSize='13px'
                toolTipFontWeight={400}
                textContainerPadding='0px'
                textContainerMaxWidth={`${containerWidthNameAndFrequency - 20}px`}
              />
              <FrequencyAndDescriptionContainer>
                {row.cadence === 1 ? (
                  <Text fontSize='11px' fontWeight={700} color='#9E9E9E' style={{ whiteSpace: 'nowrap' }}>
                    Daily
                  </Text>
                ) : (
                  <Text fontSize='11px' fontWeight={700} color='#9E9E9E' style={{ whiteSpace: 'nowrap' }}>
                    {`${row.frequency} x week`}
                  </Text>
                )}
                {!!row.description && (
                  <>
                    <TinyGreyCircle style={{ whiteSpace: 'nowrap' }} />
                    <TBTooltip
                      textToDisplay={row.description}
                      textToHover={row.description}
                      fontColor='#9E9E9E'
                      fontSize='11px'
                      fontWeight={700}
                      toolTipFontSize='13px'
                      toolTipFontWeight={400}
                      textContainerPadding='0px'
                      textContainerMaxWidth={`${containerWidthNameAndFrequency - 75}px`}
                    />
                  </>
                )}

              </FrequencyAndDescriptionContainer>
            </NameAndFrequencyContainer>
          </IconAndNameAndFrequencyWrapper>
        </ClickableCell>
      ),
      grow: 1,
      minWidth: '250px',
      maxWidth: '431px',
      style: {
        paddingLeft: '0px',
      },
    },
    {
      name: 'Assigned to',
      cell: ((row) => {
        switch (row.type) {
          case 1:
            return (
              <Text fontWeight={400} fontSize='16px'>
                All athletes
              </Text>
            );

          case 2: {
            const getNumberOfGroupsToDisplayInToolTip = () => {
              const limit = 10;
              const displayedGroups = row.groups.slice(0, limit);
              const remainingCount = row.groups.length - displayedGroups.length;

              const groupNames = displayedGroups.map((group) => group.name).join(', ');

              return remainingCount > 0 ? `${groupNames}, +${remainingCount} more` : groupNames;
            };
            return (
              <>
                {row.groups?.length > 1 ? (
                  <TBTooltip
                    textToHover={getNumberOfGroupsToDisplayInToolTip()}
                    alwaysDisplay
                    textContainerPadding='0px'
                    toolTipFontSize='13px'
                    toolTipFontWeight={400}
                    CustomComponentToHover={() => (
                      <GroupsIconAndTextContainer>
                        <GroupInfoIconCircle>
                          <IcomoonReact
                            iconSet={iconSet}
                            size={8}
                            icon='info'
                            color='#9E9E9E'
                          />
                        </GroupInfoIconCircle>
                        <Text fontWeight={400} fontSize='16px'>
                          {row.groups?.length}
                          {' '}
                          groups
                        </Text>
                      </GroupsIconAndTextContainer>
                    )}
                  />
                ) : (
                  <Text fontWeight={400} fontSize='16px'>
                    {row.groups[0]?.name || '-'}
                  </Text>
                )}
              </>
            );
          }
          case 3:
            return (
              <>
                {!row.users?.length ? (
                  <Text style={{ paddingLeft: '5px' }} color='lightgray'>
                    Not assigned
                  </Text>
                ) : (
                  <Dropdown
                    key={row.id}
                    options={userDropdownOptions}
                    isOpen={openUserDropdown === row.id}
                    setIsOpen={() => handleDropdownToggleForUserDropdown(row.id, row.users)}
                    menuAbsolutePosition={-50}
                    menuMaxWidth='280px'
                    menuMaxHeight='260px'
                    width='280px'
                    menuPaddingTop='15px'
                    menuPaddingBottom='15px'
                    CustomTriggerComponent={CustomDropdownTrigger}
                    customTriggerProps={{ row }}
                    menuBorderRadius='8px'
                    setParentRef={userDropdownRef}
                  />
                )}
              </>
            );

          default:
            return '-';
        }
      }),
      grow: 1,
      maxWidth: '200px',
      minWidth: '165px',
    },
    {
      name: 'Date added',
      selector: ((row) => (row.createdAt ? row.createdAt : '-')),
      sortable: true,
      grow: 1,
      maxWidth: '200px',
      minWidth: '150px',
      style: {
        fontWeight: 400,
        fontSize: '16px',
      },
    },
    {
      name: 'Status',
      selector: ((row) => (
        <>
          {row.active ? (
            <GreenDotAndActiveContainer>
              <GreenDot />
              <Text>Active</Text>
            </GreenDotAndActiveContainer>
          ) : (
            <GrayDotAndInactiveContainer>
              <GrayDot />
              <Text color='#BDBDBD'>
                Inactive
              </Text>
            </GrayDotAndInactiveContainer>
          )}
        </>
      )),
      grow: 1,
      maxWidth: '140px',
      minWidth: '100px',
      style: {
        fontWeight: 400,
        fontSize: '16px',
      },
    },
    {
      cell: (row) => (
        <div>
          <Dropdown
            key={row.id}
            options={options}
            isOpen={openDropdown === row.id}
            setIsOpen={() => handleDropdownToggle(row.id)}
            transform='rotate(90deg)'
            menuAbsolutePosition={-70}
            menuWidth='144px'
            menuBorderRadius='4px'
          />
        </div>
      ),

      right: true,
      width: '40px',
      style: {},
    },
  ];

  return (
    <TableWrapper
      pageContainerWidth={pageContainerWidth}
      paddingRight={paddingRight}
      onScroll={handleScroll}
    >
      <DataTable
        columns={columns}
        data={data}
        noHeader
        conditionalRowStyles={conditionalRowStyles}
        customStyles={customStyles}
        theme='teambuildr'
      />
    </TableWrapper>
  );
};

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageContainerWidth: PropTypes.number.isRequired,
  paddingRight: PropTypes.bool,
  scrollTriggered: PropTypes.bool,
};

CustomTable.defaultProps = {
  paddingRight: false,
  scrollTriggered: false,
};

export default CustomTable;
