/* eslint-disable no-tabs */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import QuickJournalModal from './QuickJournalModal';
import { setQuickJournalModal } from '../../ducks/workoutsActions';

const QuickJournalModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const QuickJournalModalContainer = () => {
  const quickJournalModal = useSelector((state) => state.workouts.data.quickJournalModal);

  const dispatch = useDispatch();

  return (
    <>
      <QuickJournalModal />
      <QuickJournalModalOverlay
        isActive={quickJournalModal}
        onClick={() => dispatch(setQuickJournalModal(false))}
      />
    </>
  );
};

QuickJournalModalContainer.propTypes = { };

export default QuickJournalModalContainer;
