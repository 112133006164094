import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuestionList from '../presentational/QuestionList';
import {
  selectQuestion,
  storeFormikValues,
  updateProgramWorkout,
  updateWorkout,
} from '../../ducks/panelActions';

const WeightroomQuestionList = (ownProps) => {
  const activeQuestionIndex = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].activeQuestionIndex);
  const athleteId = useSelector((state) => state.weightRoomView[ownProps.namespace].athleteId);
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const activeDate = useSelector((state) => state.weightRoomView[ownProps.namespace].activeDate);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].currentSelectedProgram);
  const isProgramView = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].isProgramView);

  const dispatch = useDispatch();

  const slctQuestion = (id, index, namespace) => {
    dispatch(selectQuestion(id, index, namespace));
  };
  const updteProgramWorkout = (index, namespace, programId, dayNum, dayIdx, values) => {
    dispatch(updateProgramWorkout(index, namespace, programId, dayNum, dayIdx, values));
  };
  const updteWorkout = (index, namespace, values) => {
    dispatch(updateWorkout(index, namespace, values));
  };
  const streFormikValues = (namespace, values) => {
    dispatch(storeFormikValues(namespace, values));
  };

  return (
    <QuestionList
      accountCode={accountCode}
      activeDate={activeDate}
      activeQuestionIndex={activeQuestionIndex}
      athleteId={athleteId}
      currentNonDateDay={currentNonDateDay}
      currentSelectedProgram={currentSelectedProgram}
      data={ownProps.data}
      formikRef={ownProps.formikRef}
      ref={ownProps.scienceRef}
      index={ownProps.index}
      isProgramView={isProgramView}
      namespace={ownProps.namespace}
      selectQuestion={slctQuestion}
      scrollQuestions={ownProps.scrollQuestions}
      storeFormikValues={streFormikValues}
      updateProgramWorkout={updteProgramWorkout}
      updateWorkout={updteWorkout}
      workout={ownProps.workout}
    />
  );
};

export default WeightroomQuestionList;
