// attaching action type strings to constants and exporting for consistency accross app

export const FETCH_EVALUATIONS_START = 'FETCH_EVALUATIONS_START';
export const FETCH_EVALUATIONS_FULFILLED = 'FETCH_EVALUATIONS_FULFILLED';
export const FETCH_EVALUATIONS_REJECTED = 'FETCH_EVALUATIONS_REJECTED';

export const FETCH_EVALUATION_START = 'FETCH_EVALUATION_START';
export const FETCH_EVALUATION_FULFILLED = 'FETCH_EVALUATION_FULFILLED';
export const FETCH_EVALUATION_REJECTED = 'FETCH_EVALUATION_REJECTED';

export const FETCH_EVAL_FROM_EDIT_START = 'FETCH_EVAL_FROM_EDIT_START';
export const FETCH_EVAL_FROM_EDIT_FULFILLED = 'FETCH_EVAL_FROM_EDIT_FULFILLED';
export const FETCH_EVAL_FROM_EDIT_REJECTED = 'FETCH_EVAL_FROM_EDIT_REJECTED';

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const FETCH_CALENDARS_START = 'FETCH_CALENDARS_START';
export const FETCH_CALENDARS_FULFILLED = 'FETCH_CALENDARS_FULFILLED';
export const FETCH_CALENDARS_REJECTED = 'FETCH_CALENDARS_REJECTED';

export const DELETE_EVALUATION_START = 'DELETE_EVALUATION_START';
export const DELETE_EVALUATION_FULFILLED = 'DELETE_EVALUATION_FULFILLED';
export const DELETE_EVALUATION_REJECTED = 'DELETE_EVALUATION_REJECTED';

export const CREATE_EVALUATION_START = 'CREATE_EVALUATION_START';
export const CREATE_EVALUATION_FULFILLED = 'CREATE_EVALUATION_FULFILLED';
export const CREATE_EVALUATION_REJECTED = 'CREATE_EVALUATION_REJECTED';

export const CREATE_CELL_VALUE_START = 'CREATE_CELL_VALUE_START';
export const CREATE_CELL_VALUE_FULFILLED = 'CREATE_CELL_VALUE_FULFILLED';
export const CREATE_CELL_VALUE_REJECTED = 'CREATE_CELL_VALUE_REJECTED';

export const EDIT_EVALUATION_START = 'EDIT_EVALUATION_START';
export const EDIT_EVALUATION_FULFILLED = 'EDIT_EVALUATION_FULFILLED';
export const EDIT_EVALUATION_REJECTED = 'EDIT_EVALUATION_REJECTED';

export const SET_EVAL_OVERLAY = 'SET_EVAL_OVERLAY';

export const SET_EDIT_EVAL_MODAL1 = 'SET_EDIT_EVAL_MODAL1';

export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export const SET_NEW_EVAL_MODAL1 = 'SET_NEW_EVAL_MODAL1';

export const PRIME_DELETE_EVAL_ID = 'PRIME_DELETE_EVAL_ID';

export const SET_SELECT_EXISTING_EVAL_MODAL1 = 'SET_SELECT_EXISTING_EVAL_MODAL1';

export const SET_MANAGE_EVALUATIONS_SCREEN_ON = 'SET_MANAGE_EVALUATIONS_SCREEN_ON';

export const SET_MANAGE_EVALUATIONS_SCREEN_OFF = 'SET_MANAGE_EVALUATIONS_SCREEN_OFF';

export const SET_AUTO_SAVE_INFO_MODAL = 'SET_AUTO_SAVE_INFO_MODAL';

export const SET_SELECTED_EVAL_DATE = 'SET_SELECTED_EVAL_DATE';

export const SET_EVAL_GROUP_TYPE_OPTION = 'SET_EVAL_GROUP_TYPE_OPTION';

export const SET_EVAL_GROUP_OPTION = 'SET_EVAL_GROUP_OPTION';

export const RESET_EVALUATION = 'RESET_EVALUATION';

export const FETCH_TRACKED_ITEM_UNITS_START = 'FETCH_TRACKED_ITEM_UNITS_START';
export const FETCH_TRACKED_ITEM_UNITS_FULFILLED = 'FETCH_TRACKED_ITEM_UNITS_FULFILLED';
export const FETCH_TRACKED_ITEM_UNITS_REJECTED = 'FETCH_TRACKED_ITEM_UNITS_REJECTED';

export const FETCH_TRACKED_EXERCISES_START = 'FETCH_TRACKED_EXERCISES_START';
export const FETCH_TRACKED_EXERCISES_FULFILLED = 'FETCH_TRACKED_EXERCISES_FULFILLED';
export const FETCH_TRACKED_EXERCISES_REJECTED = 'FETCH_TRACKED_EXERCISES_REJECTED';

export const SET_EDITED_ITEMS = 'SET_EDITED_ITEMS';

export const SET_EDIT_SLIDE_INDEX = 'SET_EDIT_SLIDE_INDEX';

export const SET_GROUP_ACCESS_EVAL_MODAL = 'SET_GROUP_ACCESS_EVAL_MODAL';
