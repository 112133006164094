import axios from 'axios';
import Cookies from 'js-cookie';
import Logger from 'js-logger';
// import { push } from 'connected-react-router'
import getAllUrlParams from '../../../shared/utils/getAllUrlParams';

import { store } from '../../../redux_setup/store';
import encode from '../../../shared/utils/encodeToUrl';
import {
  axiosAuthed, axiosLogin, axiosUnauthed, axiosWRV,
} from '../../../shared/utils/setCommonHeaders';

import {
  CLEAR_USER,
  SUBMIT_LOGIN_START,
  SUBMIT_LOGIN_FULFILLED,
  SUBMIT_LOGIN_REJECTED,
  SUBMIT_REGISTRATION_START,
  SUBMIT_REGISTRATION_FULFILLED,
  SUBMIT_REGISTRATION_REJECTED,
  SUBMIT_LOGOUT_FULFILLED,
  SUBMIT_GOOGLE_START,
  SUBMIT_GOOGLE_FULFILLED,
  SUBMIT_GOOGLE_REJECTED,
  SUBMIT_REFRESH_START,
  SUBMIT_REFRESH_FULFILLED,
  SUBMIT_REFRESH_REJECTED,
  SUBMIT_V2EXCHANGE_START,
  SUBMIT_V2EXCHANGE_FULFILLED,
  SUBMIT_V2EXCHANGE_REJECTED,
  FETCH_USER_START,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED,
  FETCH_USER_DATA_START,
  FETCH_USER_DATA_FULFILLED,
  FETCH_USER_DATA_REJECTED,
  FETCH_V2USER_START,
  FETCH_V2USER_FULFILLED,
  FETCH_V2USER_REJECTED,
  CHECK_SSO_START,
  CHECK_SSO_FULFILLED,
  CHECK_SSO_REJECTED,
  LOGIN_WITH_SSO_TOKEN_START,
  LOGIN_WITH_SSO_TOKEN_FULFILLED,
  LOGIN_WITH_SSO_TOKEN_REJECTED,
  UPDATE_CURRENT_USER_PROFILE_START,
  UPDATE_CURRENT_USER_PROFILE_FULFILLED,
  UPDATE_CURRENT_USER_PROFILE_REJECTED,
  UPDATE_PROFILE_IMAGE_START,
  UPDATE_PROFILE_IMAGE_FULFILLED,
  UPDATE_PROFILE_IMAGE_REJECTED,
  REMOVE_PROFILE_IMAGE_START,
  REMOVE_PROFILE_IMAGE_FULFILLED,
  REMOVE_PROFILE_IMAGE_REJECTED,
  FETCH_COMMUNICATION_SETTINGS_START,
  FETCH_COMMUNICATION_SETTINGS_FULFILLED,
  FETCH_COMMUNICATION_SETTINGS_REJECTED,
  UPDATE_COMMUNICATION_SETTINGS_START,
  UPDATE_COMMUNICATION_SETTINGS_FULFILLED,
  UPDATE_COMMUNICATION_SETTINGS_REJECTED,
  FETCH_NOTIFICATION_SETTINGS_START,
  FETCH_NOTIFICATION_SETTINGS_FULFILLED,
  FETCH_NOTIFICATION_SETTINGS_REJECTED,
  UPDATE_NOTIFICATION_SETTINGS_START,
  UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
  UPDATE_NOTIFICATION_SETTINGS_REJECTED,
  FETCH_NOTIFICATION_TYPES_START,
  FETCH_NOTIFICATION_TYPES_FULFILLED,
  FETCH_NOTIFICATION_TYPES_REJECTED,
  FETCH_ORGANIZATION_SETTINGS_START,
  FETCH_ORGANIZATION_SETTINGS_FULFILLED,
  FETCH_ORGANIZATION_SETTINGS_REJECTED,
  UPDATE_ORGANIZATION_SETTINGS_START,
  UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  UPDATE_ORGANIZATION_SETTINGS_REJECTED,
  UPDATE_LOGO_IMAGE_START,
  UPDATE_LOGO_IMAGE_FULFILLED,
  UPDATE_LOGO_IMAGE_REJECTED,
  UPDATE_BACKGROUND_IMAGE_START,
  UPDATE_BACKGROUND_IMAGE_FULFILLED,
  UPDATE_BACKGROUND_IMAGE_REJECTED,
  GET_CUSTOM_LOGIN_DATA_START,
  GET_CUSTOM_LOGIN_DATA_FULFILLED,
  GET_CUSTOM_LOGIN_DATA_REJECTED,
  SET_MAINTENANCE_MODE_ALERT,
} from './actionTypes';

const thirtyDays = 30;
const fourteenDays = 14;

// Simple function to return a promise that resolves in X milliseconds
const delay = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const saveAuthTokens = (v2Token, accessToken, refreshToken, expiresIn) => {
  const now = new Date();
  const tokenExpiration = new Date(now.getTime() + (expiresIn * 1000)).getTime();
  const thirtyMinutes = new Date(now.getTime() + (expiresIn * 1000));

  Cookies.set('v2token', v2Token, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    expires: thirtyDays,
  });
  Cookies.set('accessToken', accessToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    expires: thirtyMinutes,
  });
  Cookies.set('refreshToken', refreshToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    expires: thirtyDays,
  });
  Cookies.set('tokenExpiration', tokenExpiration, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    expires: thirtyMinutes,
  });
};

const deleteAuthTokens = (v2Token, accessToken, refreshToken, expiresIn) => {
  Cookies.remove('v2token', v2Token, {
    // secure: false,
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.remove('accessToken', accessToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
  Cookies.remove('refreshToken', refreshToken, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });

  const now = new Date();
  const tokenExpiration = new Date(now.getTime() + (expiresIn * 1000)).getTime();

  Cookies.remove('tokenExpiration', tokenExpiration, {
    secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
    domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
  });
};

// get custom login page data
export const getCustomLoginData = (portalSlug) => (
  (dispatch) => {
    dispatch({ type: GET_CUSTOM_LOGIN_DATA_START });
    axiosUnauthed.get(`/public/portal/${portalSlug}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: GET_CUSTOM_LOGIN_DATA_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: GET_CUSTOM_LOGIN_DATA_REJECTED,
          payload: err,
        });
      });
  }
);

// Fetch /me endpoint to get v1 session ids and session keys with login.
export const fetchLogin = () => (
  (dispatch, getState) => {
    const currentPath = getState().router.location.pathname;
    const paths = currentPath.split('/');

    dispatch({ type: FETCH_USER_START });
    axiosAuthed.get('/me')
      .then((response) => {
        Cookies.set('session_id', response.data.sessionId, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
          expires: fourteenDays,
        });
        Cookies.set('session_key', response.data.sessionKey, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
          expires: fourteenDays,
        });
        dispatch({
          type: FETCH_USER_FULFILLED,
          payload: response.data,
        });
        Logger.debug('Current Path:', currentPath);
        Logger.debug('Paths:', paths);

        // Checking for programs or shared workout pages to prevent log in redirects.
        // Otherwise, it will redirect to dashboard/other pages as normal.
        if (!(paths[1] === 'programs' || paths[1] === 'shared-workouts')) {
          const url = getAllUrlParams(window.location.href);
          const { currentUser } = getState().auth.data;
          let domain;
          if (process.env.PHP_APP_URL && process.env.WEBAPP_URL) {
            domain = (url.version === '2' || currentUser.admin === 0 ? process.env.WEBAPP_URL : process.env.PHP_APP_URL);
          } else {
            const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
            domain = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}${url.version === '2' || currentUser.admin === 0 ? 'app-v3' : 'app'}.teambuildr.com`;
          }
          const isWorkoutsOrDashboard = currentUser.admin === 0 ? 'workouts' : 'dashboard';
          window.location = `${domain}/${url.rel === undefined ? isWorkoutsOrDashboard : url.rel}`;
        }
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_REJECTED,
          payload: err,
        });
      });
  }
);

// Fetch /me endpoint to get v1 session ids and session keys with login.
export const fetchUser = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_START });
    axiosAuthed.get('/me')
      .then((response) => {
        Cookies.set('session_id', response.data.sessionId, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
          expires: fourteenDays,
        });
        Cookies.set('session_key', response.data.sessionKey, {
          secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
          expires: fourteenDays,
        });
        dispatch({
          type: FETCH_USER_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_REJECTED,
          payload: err,
        });
      });
  }
);

// Fetch /me endpoint for general pages, does not save tokens or sets isAuthenticated
export const fetchUserData = () => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_DATA_START });
    axiosAuthed.get('/me')
      .then((response) => {
        dispatch({
          type: FETCH_USER_DATA_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_DATA_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateCurrentUserProfile = (values) => (dispatch) => {
  console.log('values:', values);
  dispatch({ type: UPDATE_CURRENT_USER_PROFILE_START });
  axiosAuthed
    .put('/me', values)
    .then((response) => {
      console.log(response.data);
      dispatch({
        payload: response.data,
        type: UPDATE_CURRENT_USER_PROFILE_FULFILLED,
      });
    })
    .catch((err) => {
      console.log(err.response.headers.message);
      dispatch({
        payload: err.response.headers.message,
        type: UPDATE_CURRENT_USER_PROFILE_REJECTED,
      });
    });
};

export const fetchCommunicationSettings = () => (dispatch) => {
  dispatch({ type: FETCH_COMMUNICATION_SETTINGS_START });
  axiosAuthed
    .get('/me/settings')
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_COMMUNICATION_SETTINGS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_COMMUNICATION_SETTINGS_REJECTED,
      });
    });
};

export const updateCommunicationSettings = (values) => (dispatch) => {
  dispatch({ type: UPDATE_COMMUNICATION_SETTINGS_START });
  axiosAuthed
    .put('/me/settings', values)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: UPDATE_COMMUNICATION_SETTINGS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: UPDATE_COMMUNICATION_SETTINGS_REJECTED,
      });
    });
};

export const getNotificationSettings = () => (dispatch) => {
  dispatch({ type: FETCH_NOTIFICATION_SETTINGS_START });
  axiosAuthed
    .get('/me/settings/notifications')
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_NOTIFICATION_SETTINGS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_NOTIFICATION_SETTINGS_REJECTED,
      });
    });
};
export const getNotificationTypes = () => (dispatch) => {
  dispatch({ type: FETCH_NOTIFICATION_TYPES_START });
  axiosAuthed
    .get('/me/notifications/types')
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_NOTIFICATION_TYPES_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_NOTIFICATION_TYPES_REJECTED,
      });
    });
};

export const updateNotificationSettings = (values) => (dispatch) => {
  dispatch({ type: UPDATE_NOTIFICATION_SETTINGS_START });
  axiosAuthed
    .put('/me/settings/notifications', values)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: UPDATE_NOTIFICATION_SETTINGS_REJECTED,
      });
    });
};

export const getOrganizationSettings = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_ORGANIZATION_SETTINGS_START });
  axiosAuthed
    .get(`/accounts/${accountCode}/organization/settings`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ORGANIZATION_SETTINGS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ORGANIZATION_SETTINGS_REJECTED,
      });
    });
};

export const updateOrganizationSettings = (accountCode, values) => (dispatch) => {
  dispatch({ type: UPDATE_ORGANIZATION_SETTINGS_START });
  axiosAuthed
    .put(`/accounts/${accountCode}/organization/settings`, values)
    .then((response) => {
      setTimeout(() => {
        dispatch({
          payload: response.data,
          type: UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
        });
      }, 800);
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: UPDATE_ORGANIZATION_SETTINGS_REJECTED,
      });
    });
};

export const updateProfileImage = (values, webcam = false) => (dispatch) => {
  const formData = new FormData();
  if (webcam) {
    formData.append('image', values.acceptedFile);
  } else {
    // values.acceptedFile[0].type
    formData.append('image', values.acceptedFile[0]);
  }
  dispatch({ type: UPDATE_PROFILE_IMAGE_START });
  axiosAuthed
    .post('/me/profileimage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: UPDATE_PROFILE_IMAGE_FULFILLED,
      });
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response.headers.message);
      dispatch({
        payload: err.response.headers.message,
        type: UPDATE_PROFILE_IMAGE_REJECTED,
      });
    });
};

export const removeProfileImage = () => (dispatch) => {
  dispatch({ type: REMOVE_PROFILE_IMAGE_START });
  axiosAuthed
    .delete('/me/profileimage')
    .then((response) => {
      dispatch({
        payload: response.data,
        type: REMOVE_PROFILE_IMAGE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err.response.headers.message,
        type: REMOVE_PROFILE_IMAGE_REJECTED,
      });
    });
};

export const updateLogoImage = (accountCode, values, webcam = false) => (dispatch) => {
  const formData = new FormData();
  if (webcam) {
    formData.append('image', values.acceptedFile);
  } else {
    formData.append('file', values.acceptedFile[0]);
  }
  dispatch({ type: UPDATE_LOGO_IMAGE_START });
  axiosAuthed
    .put(`/accounts/${accountCode}/organization/settings/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: UPDATE_LOGO_IMAGE_FULFILLED,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        payload: err.response.headers.message,
        type: UPDATE_LOGO_IMAGE_REJECTED,
      });
    });
};
export const updateBackgroundImage = (accountCode, values, webcam = false) => (dispatch) => {
  const formData = new FormData();
  if (webcam) {
    formData.append('image', values.acceptedFile);
  } else {
    formData.append('file', values.acceptedFile[0]);
  }
  dispatch({ type: UPDATE_BACKGROUND_IMAGE_START });
  axiosAuthed
    .put(`/accounts/${accountCode}/organization/settings/portal-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: UPDATE_BACKGROUND_IMAGE_FULFILLED,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        payload: err.response.headers.message,
        type: UPDATE_BACKGROUND_IMAGE_REJECTED,
      });
    });
};

// Submit google auth token to get back v2 token.
export const submitGoogleAuth = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_GOOGLE_START });
    axiosLogin.post('/oauth/token', values)
      .then((response) => {
        const { data } = response;

        setTimeout(() => {
          dispatch({
            type: SUBMIT_GOOGLE_FULFILLED,
            payload: response.data,
          });
        }, 800);
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_GOOGLE_REJECTED,
          payload: err,
        });
        const auth2 = window.gapi.auth2.getAuthInstance();
        if (auth2 !== null) {
          auth2.signOut().then(
            Logger.debug('Google has been signed out'),
            auth2.disconnect(),
          );
        }
      });
  }
);

// Submit login details to get oauth token.
export const submitLogin = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_LOGIN_START });
    axiosLogin.post('/oauth/token', values)
      .then((response) => {
        const { data } = response;
        setTimeout(() => {
          dispatch({
            type: SUBMIT_LOGIN_FULFILLED,
            payload: response.data,
          });
        }, 800);
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_LOGIN_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Check if user has SSO enabled
export const checkSSO = (emailAddress) => (
  (dispatch) => {
    dispatch({ type: CHECK_SSO_START });
    axiosLogin.get(`/users/${emailAddress}/sso`)
      .then((response) => {
        dispatch({
          type: CHECK_SSO_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CHECK_SSO_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Submit Login with SSO token
export const submitLoginSSO = (values) => (
  (dispatch) => {
    dispatch({ type: LOGIN_WITH_SSO_TOKEN_START });
    axiosLogin.post('/oauth/token', values)
      .then((response) => {
        const { data } = response;
        dispatch({
          type: LOGIN_WITH_SSO_TOKEN_FULFILLED,
          payload: response.data,
        });
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        dispatch(fetchLogin());
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_WITH_SSO_TOKEN_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Log Out
export const logOut = () => (
  (dispatch, getState) => {
    const currentPath = getState().router.location.pathname;
    const paths = currentPath.split('/');

    dispatch({ type: SUBMIT_LOGOUT_FULFILLED });
    deleteAuthTokens();
    Cookies.remove('session_id', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('session_key', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('tokenExpiration', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    console.log('cookies should be clear');
    // Checking for programs or shared workout pages to prevent log in redirects.
    // Otherwise, it will redirect to dashboard/other pages as normal.
    if (!(paths[1] === 'programs' || paths[1] === 'shared-workouts')) {
      const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.' : '';
      const domain = process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : isDevelop}app.teambuildr.com`;
      window.location = `${domain}/assets/log_action.php?act=logout`;
    }
  }
);

// Log Out any users before resetting password
export const resetLogOut = () => (
  (dispatch) => {
    dispatch({ type: SUBMIT_LOGOUT_FULFILLED });
    deleteAuthTokens();
    Cookies.remove('session_id', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('session_key', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
    Cookies.remove('tokenExpiration', {
      secure: process.env.ENVIRONMENT !== 'LOCAL' && true,
      domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
    });
  }
);

// Return Axios promise to have access to token in Axios interceptor.
export const submitRefreshToken = () => (
  (dispatch, getState) => {
    if (Cookies.get('refreshToken')) {
      const refreshBody = {
        grant_type: 'refresh_token',
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        refresh_token: Cookies.get('refreshToken'),
      };
      dispatch({ type: SUBMIT_REFRESH_START });
      return axiosLogin.post('/oauth/token', encode(refreshBody))
        .then((response) => {
          const { data } = response;
          dispatch({
            type: SUBMIT_REFRESH_FULFILLED,
            payload: response.data,
          });
          saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
          return response.data.access_token;
        })
        .catch((err) => {
          dispatch({
            type: SUBMIT_REFRESH_REJECTED,
            payload: err,
          });
          dispatch(logOut());
        });
    }
    dispatch(logOut());
  }
);

// Submit registration details to create a new account.
export const submitRegistration = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_REGISTRATION_START });
    axiosLogin.post('/register', values)
      .then((response) => {
        const { data } = response.data;

        setTimeout(() => {
          dispatch({
            type: SUBMIT_REGISTRATION_FULFILLED,
            payload: data,
          });
          dispatch(fetchLogin());
          saveAuthTokens(data.v2_token, data.accessToken, data.refreshToken, data.expires_in);
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_REGISTRATION_REJECTED,
          payload: err,
        });
        Logger.debug(err);
      });
  }
);

// Set isAuthenticated to false if there are no cookies on initial load
export const clearUser = () => ({
  type: CLEAR_USER,
});

// Submit v2 token to get an accessToken, return Axios promise to
// have access to token in Axios interceptor.

export const submitV2Exchange = (values) => (
  (dispatch, getState) => {
    dispatch({ type: SUBMIT_V2EXCHANGE_START });
    return axiosLogin.post('/oauth/token', encode(values))
      .then((response) => {
        const { data } = response;
        dispatch({
          type: SUBMIT_V2EXCHANGE_FULFILLED,
          payload: response.data,
        });
        saveAuthTokens(data.v2_token, data.access_token, data.refresh_token, data.expires_in);
        return response.data;
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_V2EXCHANGE_REJECTED,
          payload: err,
        });
        const currentPath = getState().router.location.pathname;
        const paths = currentPath.split('/');
        window.location.href = `/login${paths[1] !== '' ? `?rel=${paths[1]}` : ''}`;
      });
  }
);

export const fetchV2User = () => (
  (dispatch) => {
    dispatch({ type: FETCH_V2USER_START });
    return axios.get('/users/me', { crossDomain: true })
      .then((response) => {
        dispatch({ type: FETCH_V2USER_FULFILLED, payload: response.data });
        return response.data.result.user_info.email;
      })
      .catch((err) => {
        dispatch({ type: FETCH_V2USER_REJECTED, payload: err });
      });
  }
);

/**
 * Retries a 401 request, but waits until another refresh token is done being submitted
 * @param {*} axiosClient either axiosLogin or axiosAuthed
 * @param {*} err the error object from the failed axios request
 */
const waitForRefresh = async (axiosClient, err) => {
  let counter = 0;
  while (counter < 3) {
    const { isRefreshRejected, isSubmittingRefresh } = store.getState().auth.ui;
    if (!isSubmittingRefresh && !isRefreshRejected) {
      const newAccessToken = Cookies.get('accessToken');
      err.config.headers.Authorization = `Bearer ${newAccessToken}`;
      return axiosClient.request(err.config);
    }
    counter += 1;
    // eslint-disable-next-line no-await-in-loop
    await delay(500);
  }
};

const requestInterceptor = async (config) => {
  const originalRequest = config;
  const currentDate = new Date().getTime();
  const v2token = Cookies.get('v2token');
  const accessToken = Cookies.get('accessToken');
  const refreshToken = Cookies.get('refreshToken');
  const tokenExpiration = Cookies.get('tokenExpiration');
  const isTokenExpired = currentDate > tokenExpiration;
  const { isRetry } = originalRequest;

  Logger.debug('Retry?', isRetry);

  // Check if authToken is expired.
  if (isTokenExpired || (accessToken === undefined)) {
    // Submit refresh token.
    let { isSubmittingRefresh } = store.getState().auth.ui;
    if (!isSubmittingRefresh) {
      // if no other refresh submissions are happening, go ahead and do it here
      return store.dispatch(submitRefreshToken()).then((token) => {
        originalRequest.headers.common.Authorization = `Bearer ${token}`;
        return originalRequest;
      });
    }
    // Another refresh is happening - wait until it completes to make the originalRequest
    let counter = 0;
    while (counter < 3) {
      isSubmittingRefresh = store.getState().auth.ui.isSubmittingRefresh;
      const { isRefreshRejected } = store.getState().auth.ui;
      if (!isSubmittingRefresh && !isRefreshRejected) {
        const newAccessToken = Cookies.get('accessToken');
        originalRequest.headers.common.Authorization = `Bearer ${newAccessToken}`;
        return originalRequest;
      }
      counter += 1;
      // eslint-disable-next-line no-await-in-loop
      await delay(1000);
    }
  }

  // Check if there is a v2token and no authToken or refresh token,
  // if so try to exchange the v2token for an authToken.
  // Requires getting the user email from v2 /me api request.
  if ((accessToken === undefined && refreshToken === undefined) && (v2token !== undefined && v2token !== '0')) {
    Logger.debug('Retry?', isRetry);
    return store.dispatch(fetchV2User()).then((username) => {
      const v2ExchangeValues = {
        grant_type: 'v2token',
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        username,
        v2token,
        scope: 'offline_access',
      };

      // Submit v2 token exchange for a new auth token.
      return store.dispatch(submitV2Exchange(v2ExchangeValues)).then(() => {
        originalRequest.headers.common.Authorization = `Bearer ${Cookies.get('accessToken')}`;
        return originalRequest;
      });
    });
  }

  // Set Auth Token to request header
  originalRequest.headers.common.Authorization = `Bearer ${Cookies.get('accessToken')}`;

  return originalRequest;
};

const responseInterceptor = async (err) => {
  if (err.response?.status === 503) {
    /*
    503 Response Code means the servers are in maintenance mode.
    We need to log the user out and display an alert to them.
    */
    store.dispatch({
      type: SET_MAINTENANCE_MODE_ALERT,
      payload: true,
    });
    store.dispatch(logOut());
  }
  if (err.response?.status === 401) {
    const { isSubmittingRefresh } = store.getState().auth.ui;
    if (!isSubmittingRefresh) {
      // If we're not already submitting another refresh token
      // Go ahead and submit it, and then call the original request that failed
      return store.dispatch(submitRefreshToken())
        .then((token) => {
          // Set the Authorization header with the new token
          // and then fire off the original request that failed with a 401
          if (token) {
            err.config.headers.Authorization = `Bearer ${token}`;
            return axiosAuthed.request(err.config);
          }
        });
    }
    /*
    401 occurred, but another 401 has already triggered a refresh token submission.
    Wait until that refresh is finished and then retry the request.
    */
    return waitForRefresh(axiosAuthed, err);
  }
  return Promise.reject(err);
};

// Request Interceptor for setting tokens before requests,
// it will also get a new auth token if the token is expired before requests are sent,
// as well as getting a new auth token if we only have a v2token.

export const authProvider = () => {
  axiosAuthed.interceptors.request.use(requestInterceptor, (err) => (
    Promise.reject(err)
  ));
  axiosWRV.interceptors.request.use(requestInterceptor, (err) => (
    Promise.reject(err)
  ));

  // Response interceptor for authenticated requests
  axiosAuthed.interceptors.response.use((response) => response, responseInterceptor);
  axiosWRV.interceptors.response.use((response) => response, responseInterceptor);

  // Response interceptor for unauthenticated requests
  axiosLogin.interceptors.response.use((response) => response, async (err) => {
    if (err.response.status === 503) {
      /*
      503 Response Code means the servers are in maintenance mode.
      We need to not let the user log in and show them an alert here.
      */
      store.dispatch({
        type: SET_MAINTENANCE_MODE_ALERT,
        payload: true,
      });
    }
    if (err.response.status === 401) {
      const { isSubmittingRefresh } = store.getState().auth.ui;
      if (!isSubmittingRefresh) {
        // If we're not already submitting another refresh token
        // Go ahead and submit it, and then call the original request that failed
        return store.dispatch(submitRefreshToken())
          .then((token) => {
            // Set the Authorization header with the new token
            // and then fire off the original request that failed with a 401
            if (token) {
              err.config.headers.Authorization = `Bearer ${token}`;
              return axiosLogin.request(err.config);
            }
          });
      }
      /*
      401 occurred, but another 401 has already triggered a refresh token submission.
      Wait until that refresh is finished and then retry the request.
      */
      return waitForRefresh(axiosLogin, err);
    }
    return Promise.reject(err);
  });
};

authProvider();

// export const submitRefreshToken = () => (
//   async (dispatch) => {
//     const refreshBody = {
//       grant_type: 'refresh_token',
//       client_id: process.env.CLIENT_ID,
//       client_secret: process.env.CLIENT_SECRET,
//       refresh_token: Cookies.get('refreshToken'),
//     };
//     dispatch({ type: SUBMIT_REFRESH_START });
//     try {
//       const data = await axiosLogin.post('oauth/token', encode(refreshBody));
//       const token = await data.access_token;
//       if (token) {
//         console.log(token);
//         dispatch({ type: SUBMIT_REFRESH_FULFILLED });
//         Cookies.set('accessToken', token, {
//           secure: !process.env.ENVIRONMENT === 'DEVELOPMENT' && true,
//           domain: process.env.ENVIRONMENT === 'DEVELOPMENT' ? 'localhost' : '.teambuildr.com',
//         });
//         Cookies.set('refreshToken', token, {
//           secure: !process.env.ENVIRONMENT === 'DEVELOPMENT' && true,
//           domain: process.env.ENVIRONMENT === 'DEVELOPMENT' ? 'localhost' : '.teambuildr.com',
//         });
//         return token;
//       }
//     } catch (err) {
//       dispatch({ type: SUBMIT_REFRESH_REJECTED });
//       console.log(err);
//     }
//     return 'nope';
//   }
// );
