import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  BoxShadow,
} from '../../GlobalStyles';

const SliderContainer = styled('div')`
	width: 100%;
	display: flex;
	align-items: center;
	height: 20px;
	.slider {
		-webkit-appearance: none;
		appearance: none;
		width: 100%;
		height: 3px;
		outline: none;
		background: #3fbfe1;
		border-radius: 24px;
	}
	.slider::-webkit-slider-runnable-track {
		border-radius: 24px;
	}
	.slider::-moz-range-track {
		border-radius: 24px;
	}
	.slider::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 17px;
		height: 17px;
		background: white;
		cursor: pointer;
		border: none;
		border-radius: 50%;
		box-shadow: rgb(48 49 51 / 5%) 0px 0px 1px, rgb(48 49 51 / 50%) 0px 2px 4px;
	}
	.slider::-moz-range-thumb {
		width: 17px;
		height: 17px;
		border-radius: 50%;
		background: white;
		cursor: pointer;
		border: none;
		box-shadow: rgb(48 49 51 / 5%) 0px 0px 1px, rgb(48 49 51 / 50%) 0px 2px 4px;
	}
`;

const TBRangeSlider = ({
  minValue, maxValue, value, onChange, onMouseUpFunction,
}) => {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(parseInt(e.target.value, 10));
  };

  return (
    <SliderContainer>
      <input
        className='slider'
        type='range'
        value={value}
        min={minValue}
        max={maxValue}
        step={1}
        onChange={handleChange}
        onMouseUp={onMouseUpFunction}
        onTouchEnd={onMouseUpFunction}
      />
    </SliderContainer>
  );
};

TBRangeSlider.defaultProps = {
  minValue: 0,
  maxValue: 0,
};

TBRangeSlider.propTypes = {
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onMouseUpFunction: PropTypes.func.isRequired,
};

export default TBRangeSlider;
