/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { animated, useTransition, useSpring } from 'react-spring';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import InstantPaymentFormSlide1 from './InstantPaymentSlide1';
import InstantPaymentFormSlide2 from './InstantPaymentSlide2';

import {
  TransitionGlobal,
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import {
  setIsInstantPaymentModalShowing,
  addOnPackage,
} from '../../ducks/sportsScienceActions';
import { subscriptionFlowTracker } from '../../../../shared/utils/amplitudeHelper';

const transitionSpeed = 0.5;

const SlideContainer = styled('div')`
  display: flex;
  align-items: center;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const Buttons = styled('div')`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: ${BoxShadow};
  height: 72px;
  z-index: 1000;
  @media screen and (max-width: 540px) {
    height: auto;
    flex-direction: column;
    bottom: 49px;
    background: none;
  }
`;

const ProgressBarContainer = styled('div')`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: ${BoxShadow};
  height: 107px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 540px) {
    border-radius: 0px;
    position: relative;
    box-shadow: none;
  }
`;

const ProgressBar = styled('div')`
  min-height: 17px;
  width:  100%;
  border-radius: 10px;
  background: #EEEEEE;
`;

const StaticBubble = styled('div')`
  border-radius: 50%;
  background: #FF8533;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #FF8533;
`;

const DynamicBubble = styled('div')`
  border-radius: 50%;
  background: ${(props) => (props.isPaid ? '#FF8533' : 'none')};
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF8533;
  border: 1px solid #FF8533;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 0px;
  transition: 0.5 ${transitionSpeed}s ease;
`;

const DoneBubble = styled('div')`
  border-radius: 50%;
  background: ${(props) => (props.isPaid ? '#FF8533' : '#E0E0E0')};
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isPaid ? '#FF8533' : '#444444')};
  border: ${(props) => (props.isPaid ? '1px solid #FF8533' : '1px solid #E0E0E0')};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 0px;
  transition: 0.5 ${transitionSpeed}s ease;
`;

const ProgressColorDiv = styled('div')`
  width: ${(props) => props.num * 10}%;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(to right, rgba(247,183,51,1), rgba(252,74,26,1));
  transition: width ${transitionSpeed}s ease-in-out;
`;

const LeftBubbleAndTextColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-evenly;
`;

const CenterBubbleAndTextColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
`;

const RightBubbleAndTextColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-evenly;
`;

const ProgressText = styled('div')`
  color: #FFA500;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const DoneText = styled('div')`
  color: ${(props) => (props.isPaid ? '#FF8533' : '#444444')};
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`;

const TopPart = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const SlideWrapper = styled('div')`
  width: 100%;
  @media screen and (max-width: 540px) {
    padding-bottom: 150px;
  }
`;

const InnerFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const ModalNextButton = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-radius: 10000px;
  height: 40px;
  padding: 10px;
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  background: linear-gradient(90deg, rgba(247,183,51,1) 0%, rgba(252,74,26,1) 100%);
  flex: 1;
  max-width: ${(props) => (props.isPaid ? '100%' : '49%')};
  transition: all ${transitionSpeed}s ease;
  @media screen and (max-width: 540px) {
    min-width: 100%;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const ModalCancelButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  height: 40px;
  width: ${(props) => (props.isPaid ? '0%' : '49%')};
  margin-bottom: 5px;
  color: white;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-image: linear-gradient(45deg, purple, orange) 1;
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(90deg, rgba(247, 183, 51, 1), rgba(252, 74, 26, 1)) border-box;
  border: ${(props) => (props.isPaid ? '0px' : '3px solid transparent')};
  color: #FF8533;
  opacity: ${(props) => (props.isPaid ? '0' : '1')};
  transition: all ${transitionSpeed}s ease;
  @media screen and (max-width: 540px) {
    min-width: 100%;
  }
  &:hover {
    opacity: 0.7;
  }
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const InstantPaymentForm = ({
  activeSlide,
  setActiveSlide,
}) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const isUpgrading = useSelector((state) => state.sportsScience.ui.isUpgrading);
  const addedPackage = useSelector((state) => state.sportsScience.data.addedPackage);
  const selectedPackage = useSelector((state) => state.sportsScience.data.selectedPackage);

  // const [activeSlide, setActiveSlide] = useState(0);

  const dispatch = useDispatch();

  const slideRef = useRef();

  const formAnimation = useSpring({
    opacity: 1,
  });

  useEffect(() => {
    if (addedPackage && Object.keys(addedPackage).length) {
      setActiveSlide(1);
    }
  }, [addedPackage]);

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: 'translate3d(25%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-25%, 0, 0)' },
  });

  // Tracks what action was taken during the upgrade process
  const handleAmplitude = (action) => {
    subscriptionFlowTracker(action, 'Upgrade Modal');
  };

  return (
    <InnerFormWrapper>
      <ProgressBarContainer>
        <TopPart>
          <LeftBubbleAndTextColumn>
            <StaticBubble>
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='checkmark'
                color='white'
              />
            </StaticBubble>
            <ProgressText>
              REVIEW
            </ProgressText>
          </LeftBubbleAndTextColumn>
          <CenterBubbleAndTextColumn>
            <DynamicBubble isPaid={activeSlide === 1}>
              {activeSlide === 1 ? (
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='checkmark'
                  color='white'
                />
              ) : 2}
            </DynamicBubble>
            <ProgressText>
              Activate
            </ProgressText>
          </CenterBubbleAndTextColumn>
          <RightBubbleAndTextColumn>
            <DoneBubble isPaid={activeSlide === 1}>
              {activeSlide === 1 ? (
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='checkmark'
                  color='white'
                />
              ) : 3}
            </DoneBubble>
            <DoneText isPaid={activeSlide === 1}>
              Done
            </DoneText>
          </RightBubbleAndTextColumn>
        </TopPart>
        <ProgressBar>
          <ProgressColorDiv num={activeSlide === 0 ? 5.5 : 10} />
        </ProgressBar>
      </ProgressBarContainer>
      <SlideWrapper>
        <animated.div style={{ ...formAnimation, height: '100%' }}>
          <SlideContainer
            height={slideDimensions.height}
          >
            {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
            {slideTransition.map(({ item, props }) => {
              let returnComponent;
              if (item === 0) {
                if (!isUpgrading) {
                  returnComponent = (
                    <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                      <ResizeObserver
                        onResize={(rect) => {
                          setSlideDimensions(rect);
                        }}
                      />
                      <InstantPaymentFormSlide1 />
                    </animated.div>
                  );
                } else {
                  returnComponent = (
                    <SpinnerWrapper>
                      <Spinner />
                    </SpinnerWrapper>
                  );
                }
              } else if (item === 1) {
                returnComponent = (
                  <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                    <ResizeObserver
                      onResize={(rect) => {
                        setSlideDimensions(rect);
                      }}
                    />
                    <InstantPaymentFormSlide2 />
                  </animated.div>
                );
              }
              return returnComponent;
            })}
          </SlideContainer>
        </animated.div>
        <Buttons>
          <ModalNextButton
            isPaid={activeSlide === 1}
            onClick={() => {
              if (activeSlide === 0) {
                handleAmplitude('Pay Now Clicked');
                dispatch((addOnPackage(currentUser.accountCode, {
                  packageId: 1,
                  packageAddOnIds: selectedPackage.core ? [] : [selectedPackage.addOnId],
                })));
              } else if (activeSlide === 1) {
                handleAmplitude('Completed');
                dispatch(setIsInstantPaymentModalShowing());
                setActiveSlide(0);
              }
            }}
          >
            {activeSlide === 1 ? 'DONE' : 'PAY NOW'}
          </ModalNextButton>
          <ModalCancelButton
            isPaid={activeSlide === 1}
            onClick={() => {
              handleAmplitude('Cancel Clicked');
              dispatch(setIsInstantPaymentModalShowing());
              setActiveSlide(0);
            }}
          >
            CANCEL
          </ModalCancelButton>
        </Buttons>
      </SlideWrapper>
    </InnerFormWrapper>
  );
};

InstantPaymentForm.propTypes = {
  scrollRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  activeSlide: PropTypes.number.isRequired,
  setActiveSlide: PropTypes.func.isRequired,
};

export default InstantPaymentForm;
