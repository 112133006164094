import React, { useEffect, useState } from 'react';
import { batch, useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { StripeProvider } from 'react-stripe-elements';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import { useTheme } from 'emotion-theming';
import { animated, useSpring, useTransition } from 'react-spring';
import FontPicker from 'font-picker-react';

import Avatar from '../../../../shared/components/Avatar/Avatar';
import Button from '../../../../shared/components/Button/Button';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../shared/components/Text/Text';
import SubText from '../../../../shared/components/SubText/SubText';
import Link from '../../../../shared/components/Link/Link';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import useScript from '../../../../shared/hooks/useScript';
import useWindowSize from '../../../../shared/hooks/useWindowSize';

import { fetchWorkoutPreview, setModalOpen, clearPaymentData } from '../../ducks/programsActions';
import { logOut } from '../../../login/ducks/loginActions';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ModalCreateAccount from './ModalCreateAccount';
import ModalCheckout from './ModalCheckout';
import ModalLogin from './ModalLogin';
import WorkoutPreview from './WorkoutPreview';
import Logger from 'js-logger';

const CheckoutContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1 auto;
  height: 100%;
  
  #font-picker {
    position: fixed;
    top: -10000px;
    z-index: -1;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

const OrderSection = styled('div')`
  width: 50%;
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightGray;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: ${(props) => (props.isOrderConfirmed ? '0%' : '100%')};
  }
`;

const PaymentSection = styled('div')`
  width: 50%;
  flex: 1 auto;
  padding: 120px;
  position: relative;
  
  .back-button {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    padding: 80px;
  }
  @media screen and (max-width: 992px) {
    padding: 40px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: ${(props) => (props.isOrderConfirmed ? '100%' : '0%')};
    padding: ${(props) => (!props.isOrderConfirmed ? '0px' : '40px 20px')};
    position: absolute;
    .back-button {
      display: block;
      transform: rotate(-90deg);
      z-index: 1000;
      position: absolute;
      top: 29px;
      left: 15px;
    }
  }
`;

const ModalHeader = styled('div')`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  position: absolute;
  text-align: center;
  left: 0px;
  top: 0;
  padding: 25px;
  margin-bottom: 30px;
`;

const SummaryContainer = styled('div')`
  background: white;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  align-items: center;

  h1 {
    margin-bottom: 30px;
    font-size: 20pt;
    font-weight: 300;
    @media screen and (max-width: 767px) {
      font-size: 16pt
    }
  }

  button {
    margin-top: 60px;
    display: none;
    position: absolute;
    bottom: 0px;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
`;

const SummaryContent = styled('div')`
  background: white;
  border-radius: 5px;
  width: 480px;
  height: auto;
  box-shadow: ${BoxShadow};

  @media screen and (max-width: 992px) {
    width: 380px;
  }

`;

const PriceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`;

const LargePrice = styled('span')`
  font-size: 40px;
  font-family: 'Nunito Sans';
  font-weight: 900;
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
`;

const SmallPrice = styled('span')`
  font-size: 12px;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
`;

const CurrencySymbol = styled('span')`
  font-size: 22px;
  vertical-align: top;
  line-height: 45px;
`;

const ModalBody = styled('div')`
  margin-top: ${(props) => (props.className === 'checkout' || props.className === 'createAccount' ? '10px' : '70px')};

  p {
    margin-bottom: 20px;
  }

  .login-link {
    text-align: center;
    margin-bottom: 30px;
    width: 100%;
    cursor: pointer;
    
    span {
      font-family: inherit;
      color: ${props => props.theme.colors.orange}
    }
    &:hover {
      opacity: .7;
    }
  }

  .login {
    font-weight: 600;
    color: #ff6600;
    &:hover {
      opacity: .7;
    }
  }

  .css-jtzdnx-ModalHeader {

  }
`;

const AuthenticatedCheckOut = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  img {
    margin-right: 15px;
  }

  p {
    margin-bottom: 0px;
  }
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px;
`;

const TitleContainer = styled('div')`
  margin-bottom: 20px;
`;

const TrialCopyContainer = styled('div')`
  margin-top: 5px;
`;

const ProgramEndContainer = styled('div')`
  margin-top: ${(props) => props.marginTop};;
`;

const HeroImage = styled('div')`
  display: flex;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0,0,0, 0.4)), url(${props => props.backgroundImage});
  background-size: cover;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    font-size: 40px;
    margin-bottom: 0px;
  }
`;

const AccountHeader = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid lightgray;
  margin-bottom: 30px;
  
  h1 {
    margin-top: 10px;
  }
`;

const AvatarContainer = styled('div')`
  background: ${(props) => props.accountColorPrimary};
  border-radius: 50%;
  padding: 10px;
`;

const loadAsyncScript = (src) => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = src;
  script.addEventListener('load', () => {
    resolve({ successful: true });
  });
  script.addEventListener('error', (event) => {
    reject({ error: event });
  });
  document.head.appendChild(script);
});

const ProgramCheckout = ({
  userName,
  workoutPreview,
}) => {
  const [stripeLoaded, setStripeLoaded] = useState({});
  const [paymentPhase, setPaymentPhase] = useState('createAccount');
  const [isOrderConfirmed, setOrderConfirmed] = useState(false);
  const [cookies, setCookies] = useCookies(['v2token']);

  // Asynchronously load Stripe script and prevent Stripe Provider from loading until succesful
  // script load to prevent "Please load Stripe.js" errors.
  useEffect(() => {
    const fetchData = async () => {
      const result = await loadAsyncScript('https://js.stripe.com/v3/');
      setStripeLoaded(result);
    };
    fetchData();
  }, []);

  // useScript('https://js.stripe.com/v3/');

  const { resellerSlug, programSlug } = useParams();

  const theme = useTheme();

  const windowSize = useWindowSize();

  const isAuthenticated = useSelector(state => state.auth.ui.isAuthenticated);
  const isRegisteredInClient = useSelector(state => state.auth.ui.isRegisteredInClient);
  const accountCode = useSelector(state => state.auth.data.currentUser.accountCode);
  const programData = useSelector((state) => state.programs.data.programData);

  const dispatch = useDispatch();

  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
    && cookies.accessToken !== undefined && cookies.accessToken !== '0'
    && cookies.session_id !== undefined && cookies.session_id !== '0'
    && cookies.session_key !== undefined && cookies.session_key !== '0';

  const {
    accountLogoKey,
    accountColorPrimary,
    billingFrequency,
    billingFrequencyDescription,
    customFont,
    currencySymbol,
    headerMediaKey,
    numBillingCycles,
    programDescriptionLong,
    programDescriptionShort,
    programName,
    programPrice,
    resellerName,
    status,
    trialPeriod,
    placeSymbolAfterCurrency,
  } = programData;

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticatedv3) {
        setPaymentPhase('checkout');
        console.log('changing phase to checkout');
      } else {
        setPaymentPhase('createAccount');
      }
      console.log('changing phase', isAuthenticatedv3, cookies.accessToken);
    }, 400);
  }, [programData]);

  // Check if we have authenticated when isAuthenticated changes, should only be
  // after a user logs in. If so, close login modal, open checkout modal.
  useEffect(() => {
    if (isAuthenticated || isAuthenticatedv3) {
      setTimeout(() => {
        setPaymentPhase('checkout');
      }, 500);
    }
    console.log('Are we authenticated?', isAuthenticatedv3);
  }, [isAuthenticated, isAuthenticatedv3]);

  // Check if we have registered when isRegisteredInClient changes, should only be
  // after a user registers. If so, close createaccount modal, open checkout modal.
  useEffect(() => {
    if (isRegisteredInClient) {
      setPaymentPhase('createAccount');
      // setTimeout(() => {
      //   setPaymentPhase('createAccount');
      // }, 800);
    }
  }, [isRegisteredInClient]);

  const checkoutTransition = useSpring({ opacity: paymentPhase === 'checkout' ? 1 : 0, height: '100%', display: 'flex' });
  const accountTransition = useSpring({ opacity: paymentPhase === 'createAccount' ? 1 : 0, height: '100%', display: 'flex' });
  const loginTransition = useSpring({ opacity: paymentPhase === 'login' ? 1 : 0, height: '100%', display: 'flex' });
  const paymentTransition = useSpring({ opacity: isOrderConfirmed ? 1 : 0, height: !isOrderConfirmed ? '0%' : '100%' });
  const orderTransition = useSpring({ opacity: isOrderConfirmed ? 0 : 1, height: !isOrderConfirmed ? '100%' : '0%' });

  const billingCycleMessage = () => {
    if (billingFrequency === 12) {
      return numBillingCycles > 1 ? `${numBillingCycles} years` : `${numBillingCycles} year`;
    }
    return numBillingCycles > 1 || billingFrequency > 1 ? `${billingFrequency * numBillingCycles} months` : `${billingFrequency * numBillingCycles} month`;
  };

  const headerImage = optimizeImage(
    `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${headerMediaKey}`,
    {
      resize: {
        with: 800, fit: 'cover',
      },
    },
  );

  const openModal = console.log();

  const openLogin = (e) => {
    e.preventDefault();
    setPaymentPhase('login');
    setTimeout(() => {
      batch(() => {
        dispatch(logOut());
        dispatch(clearPaymentData());
        dispatch(setModalOpen(true, 'login'));
      });
    }, 800);
  };

  const isMobile = windowSize.width <= 767;

  return (
    <CheckoutContainer>
      <OrderSection isOrderConfirmed={isOrderConfirmed}>
        <animated.div style={isMobile ? orderTransition : null}>
          <SummaryContainer>
            <AccountHeader>
              <AvatarContainer
                accountColorPrimary={accountColorPrimary}
              >
                <Avatar
                  avatar={accountLogoKey}
                  size={50}
                />
              </AvatarContainer>
              <HeaderText>
                {resellerName}
              </HeaderText>
            </AccountHeader>
            {/* <HeaderText>Order Summary</HeaderText> */}
            <SummaryContent>
              <HeroImage backgroundImage={headerImage}>
              </HeroImage>
              <ProductDetails>
                <TitleContainer>
                  <SubText>Program</SubText>
                  <Text fontSize={20}>{programName}</Text>
                  {/* If we have a trial period greater than 0, show trial copy. */}
                  {trialPeriod > 0 && (
                    <TrialCopyContainer>
                      <SubText className='trial'>{`Includes ${trialPeriod} day trial`}</SubText>
                    </TrialCopyContainer>
                  )}
                  {/* If the program has a max # of billing cycles, show it here. */}
                  {numBillingCycles && numBillingCycles > 0 && (
                  <ProgramEndContainer marginTop={trialPeriod > 0 ? '15px' : '30px'}>
                    <SubText className='trial'>{`Subscription ends after ${billingCycleMessage()}`}</SubText>
                  </ProgramEndContainer>
                  )}
                </TitleContainer>
                <PriceContainer>
                  <LargePrice>
                    <CurrencySymbol>{currencySymbol}</CurrencySymbol>
                    {programPrice / 100}
                  </LargePrice>
                  <SmallPrice>{`/${billingFrequencyDescription}`}</SmallPrice>
                </PriceContainer>
              </ProductDetails>
            </SummaryContent>

            <Button
              complete
              cta='Confirm & Pay'
              icon='credit-card'
              onClick={() => setOrderConfirmed(true)}
              primary
              square
              bottom
              fullWidth
              large
              zIndex={1000}
            />
          </SummaryContainer>
          {workoutPreview && (
            <WorkoutPreview
              dispatch={dispatch}
              fetchWorkoutPreview={fetchWorkoutPreview}
              resellerSlug={resellerSlug}
              programSlug={programSlug}
            />
          )}
        </animated.div>
      </OrderSection>
      <PaymentSection isOrderConfirmed={isOrderConfirmed}>
        <animated.div style={isMobile ? paymentTransition : null}>
          <Button
            className='back-button'
            iconOnly
            icon='alt-up-arrow'
            onClick={() => setOrderConfirmed(false)}
          />
          {paymentPhase === 'login' && (
            <animated.div style={loginTransition}>
              <ModalHeader>
                <HeaderText>Sign In</HeaderText>
              </ModalHeader>
              <ModalBody>
                <ModalLogin
                  openModal={setPaymentPhase}
                />
              </ModalBody>
            </animated.div>
          )}
          {paymentPhase === 'createAccount' && (
            <animated.div style={accountTransition}>
              <ModalHeader>
                <HeaderText>Create Account to Pay</HeaderText>
              </ModalHeader>
              <ModalBody>
                <Link 
                  className='login-link' 
                  allCaps 
                  color='lightText.1' 
                  onClick={() => setPaymentPhase('login')}
                >
                  Already have an account? <span>Click here to log in.</span>
                </Link>
                <ModalCreateAccount
                  programSlug={programSlug}
                  resellerSlug={resellerSlug}
                />
              </ModalBody>
            </animated.div>
          )}
          {/* {checkoutAnimation.map(({ item, key, props }) => */}
          {paymentPhase === 'checkout' && (
            // item && (
              <animated.div style={checkoutTransition}>
                {/* <ModalHeader>
                  <HeaderText>Checkout</HeaderText>
                </ModalHeader> */}
                <ModalBody className='checkout'>
                  {(isAuthenticated || isAuthenticatedv3) && (
                    <AuthenticatedCheckOut>
                      <Avatar
                        avatar={accountLogoKey}
                        size={20}
                      />
                      <Text
                        fontSize={theme.textFontSizes[0]}
                      >
                        Checking out as {userName}. 
                        <Link
                          className='login'
                          fontSize={theme.textFontSizes[0]} 
                          href='#' 
                          onClick={openLogin}
                        >
                          Use another account?
                        </Link>
                      </Text>
                    </AuthenticatedCheckOut>
                  )}
                  {stripeLoaded.successful && (
                    <StripeProvider
                      apiKey={accountCode === 1
                        ? process.env.STRIPE_PUBLIC_KEY_TEST
                        : process.env.STRIPE_PUBLIC_KEY}
                    >
                      <ModalCheckout externalCheckout />
                    </StripeProvider>
                  )}
                </ModalBody>
              </animated.div>
            // ))}
          )}
        </animated.div>
      </PaymentSection>
    </CheckoutContainer>
  );
}

export default ProgramCheckout;