/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import LayoutWithSidebarNav from '../../shared/components/Layout/LayoutWithSidebarNav';
import AccountSettings from './components/presentational/account-settings';
import CommunicationSettings from './components/presentational/communication-settings';
import SingleSignOn from './components/presentational/single-sign-on';
import OrganizationSettings from './components/presentational/organization-settings';
import IntegrationSettings from './components/presentational/integrations-settings';
import SubscriptionSettings from './components/presentational/subscriptions-settings';
import ManageCookies from './components/presentational/manage-cookies';
import NotificationSettings from './components/presentational/notification-settings';
import { generalSettingsSideBarNavArrayOfObjects } from '../../shared/constants';
import {
  getOrganizationSettings, getNotificationSettings, getNotificationTypes, fetchCommunicationSettings,
} from '../login/ducks/loginActions';
import Text from '../../shared/components/Text/Text';
import Spinner from '../../shared/components/Spinner/Spinner';

const Settings = () => {
  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState(null);

  const isMeLoading = useSelector((state) => state.auth.ui.isLoginLoading);

  const accountCode = useSelector((state) => state.auth.data.currentUser?.accountCode);

  const primaryAdmin = useSelector((state) => state.auth.data.currentUser?.primaryAdmin);

  const admin = useSelector((state) => state.auth.data.currentUser?.admin);

  const organizationResellerAccountEnabled = useSelector((state) => state.auth.data.currentUser?.organizationResellerAccountEnabled);

  const resellerSubscriptions = useSelector((state) => state.auth.data.currentUser?.resellerSubscriptions);

  useEffect(() => {
    if (accountCode && primaryAdmin) {
      dispatch(getOrganizationSettings(accountCode));
    }
  }, [accountCode]);

  useEffect(() => {
    dispatch(getNotificationSettings());
    dispatch(getNotificationTypes());
    dispatch(fetchCommunicationSettings());
  }, []);

  const helmetText = 'Settings';
  const navLinkArrayOfObjects = generalSettingsSideBarNavArrayOfObjects;

  const PageTitleContainer = styled('div')`
  text-align: ${containerWidth && containerWidth < 400 ? 'center' : 'start'};
  padding-bottom: 30px;
  `;

  const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100vh;
  `;

  return (
    <LayoutWithSidebarNav
      helmetText={helmetText}
      navLinkArrayOfObjects={navLinkArrayOfObjects}
      sideBarTitle='Settings'
      sideBarTitleIcon='settings'
    >
      {isMeLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <ResizeObserver
            onResize={(rect) => {
              setContainerWidth(rect.width);
            }}
          />
          <PageTitleContainer>
            <Text fontSize={containerWidth && containerWidth < 400 ? 28 : 34} fontWeight='400'>General Settings</Text>
          </PageTitleContainer>
          <AccountSettings />
          {primaryAdmin > 0 && <OrganizationSettings />}
          {(!admin && (organizationResellerAccountEnabled || (resellerSubscriptions && resellerSubscriptions.length)))
           && <SubscriptionSettings />}
          {primaryAdmin > 0 && <IntegrationSettings />}
          {!admin && <CommunicationSettings containerWidth={containerWidth} />}
          <NotificationSettings containerWidth={containerWidth} />
          <SingleSignOn containerWidth={containerWidth} />
          <ManageCookies containerWidth={containerWidth} />
        </>

      )}
    </LayoutWithSidebarNav>
  );
};

export default Settings;
