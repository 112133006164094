import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring/web.cjs';

import { ColorGreen, Gutter } from '../../../../shared/GlobalStyles';
import ScienceEntries from './WorkoutEntriesScience';

const QuestionWrapper = styled('div')`
  margin-top: 23px;
  :first-of-type {
    margin-top: 0px;
  }
`;

const QuestionContainer = styled('a')`
  display: flex;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
  color: ${(props) => props.theme.textColor}!important;
  p {
    margin-bottom: 0px;
  }

  :before {
    content: '';
    display: block;
    height: 13px;
    width: 13px;
    background-color: ${ColorGreen};
    border-radius: 50%;
    margin-right: ${Gutter};
  }

  /* :after {
    content: '';
    width: 1px;
    background-color: #979797;
    height: 28px;
    position: absolute;
    margin-top: 20px;
    margin-left: 6px;
  } */

  :last-of-type {
    :after {
      display: none;
    }
  }
`;

const Question = ({
  choices,
  description,
  handleActive,
  handleChange,
  inputType,
  isQuestionActive,
  questionId,
  questionIndex,
  questionRef,
  questionTitle,
  rangeStart,
  rangeEnd,
  setFieldValue,
  values,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    handleActive(
      parseInt(e.currentTarget.dataset.id, 10),
      parseInt(e.currentTarget.dataset.index, 10),
    );
  };

  // Sort the choice array based on the order given
  choices?.sort((a, b) => a.order - b.order);

  const transitions = useTransition(isQuestionActive, null, {
    from: { maxHeight: 0, marginBottom: 0 },
    enter: { maxHeight: 500, marginBottom: 7 },
    leave: { maxHeight: 0, marginTop: 6, marginBottom: 0 },
  });

  return (
    <>
      <QuestionWrapper
        ref={questionRef}
      >
        <QuestionContainer
          id={`question-${questionId}`}
          data-id={questionId}
          data-index={questionIndex}
          onClick={handleClick}
        >
          {questionTitle}
        </QuestionContainer>
        {transitions.map(({ item, key, props }) => item && (
          <animated.div
            key={key}
            style={{
              ...props,
              marginTop: '0px',
              marginLeft: '-8px',
              overflow: 'hidden',
            }}
          >
            <ScienceEntries
              choices={choices}
              description={description}
              handleChange={handleChange}
              index={questionIndex}
              inputType={inputType}
              values={values}
              rangeStart={rangeStart}
              rangeEnd={rangeEnd}
              setFieldValue={setFieldValue}
            />
          </animated.div>
        ))}
      </QuestionWrapper>
    </>
  );
};

Question.propTypes = {
  choices: PropTypes.instanceOf(Array).isRequired,
  description: PropTypes.string.isRequired,
  inputType: PropTypes.number.isRequired,
  isQuestionActive: PropTypes.bool.isRequired,
  handleActive: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  questionRef: PropTypes.func.isRequired,
  questionTitle: PropTypes.string.isRequired,
  rangeStart: PropTypes.number.isRequired,
  rangeEnd: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default Question;
