/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import generateUniqueId from '../../../../shared/utils/generateUniqueId';

const CompletionBreakdownGrid = () => {
  const theme = useTheme();
  const isAthleteReportCardLoading = useSelector((state) => state.athleteReportCard.ui.isAthleteReportCardLoading);
  const weekOfSessions = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.training?.sessions);

  /** styles */
  const cellStyle = {
    borderLeft: 'solid 2px #F2F1F1', display: 'flex', justifyContent: 'center', alignItems: 'center',
  };
  const smallCellStyle = {
    borderLeft: 'solid 2px #F2F1F1', display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, flexDirection: 'column', textAlign: 'center',
  };

  const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
`;

  const PlaceHolderContainer = styled('div')`
    display: flex;
    width: 100%;
    min-width: 1017px;
    height: 156px;
    border-radius: 4px;
    max-width: 1017px;
  `;
  const HorizontalScrollContainer = styled('div')`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 40px;
  box-shadow: 0px 8px 16px 0px #3031331A;
  max-width: 1017px;
  
  `;

  return (
    <Wrapper>
      {isAthleteReportCardLoading ? (
        <Shimmer>
          <PlaceHolderContainer />
        </Shimmer>
      ) : (
        <HorizontalScrollContainer>
          <Grid style={{
            minWidth: 1017, maxWidth: 1017, borderRadius: 4, height: 156, backgroundColor: 'white',
          }}
          >
            <Row>
              {weekOfSessions?.map((session, index) => (
                <Col
                  key={generateUniqueId()}
                  style={{
                    ...smallCellStyle, borderLeft: index === 0 ? 'none' : 'solid 2px #F2F1F1',
                  }}
                  xs
                >
                  <Text fontSize='11px' color={theme.colors.lightText[2]}>{moment(session?.date).format('dddd')}</Text>
                  <Text fontSize='12px' fontWeight={800} color={theme.colors.text}>{moment(session?.date).format('MM/DD/YYYY')}</Text>
                </Col>
              ))}
            </Row>
            <Row>
              {weekOfSessions?.map((session, index) => (
                <Col
                  key={generateUniqueId()}
                  style={{
                    ...cellStyle,
                    height: 56,
                    backgroundColor: (session?.workoutItems?.length && session?.incompleteCount === 0) ? theme.colors.green : (!session?.workoutItems?.length && session?.incompleteCount === 0) ? theme.colors.lightText[2] : '#FFCB47',
                    borderTop: 'solid 2px #F2F1F1',
                    borderLeft: index === 0 ? 'none' : 'solid 2px #F2F1F1',
                  }}
                  xs
                >
                  {!session.workoutItems?.length ? (
                    null
                  ) : (
                    <>
                      {session.incompleteCount > 0 ? (
                        <div style={{
                          width: 22, height: 3, borderRadius: 100, backgroundColor: 'white',
                        }}
                        />
                      ) : (
                        <IcomoonReact
                          iconSet={iconSet}
                          size={24}
                          icon='checkmark'
                          color='white'
                        />
                      )}
                    </>
                  )}
                </Col>
              ))}
            </Row>
            <Row>
              {weekOfSessions?.map((session, index) => (
                <Col
                  key={generateUniqueId()}
                  style={{
                    ...smallCellStyle, borderTop: 'solid 2px #F2F1F1', borderLeft: index === 0 ? 'none' : 'solid 2px #F2F1F1',
                  }}
                  xs
                >
                  {!session.workoutItems?.length ? (
                    <Text fontSize='11px' color={theme.colors.lightText[2]}>
                      No Data For Session
                    </Text>
                  ) : (
                    <>
                      {session.incompleteCount > 0 ? (
                        <Text fontSize='11px' color={theme.colors.lightText[2]}>
                          {session.incompleteCount}
                          {' '}
                          {session.incompleteCount > 1 ? (
                            'Exercises'
                          ) : (
                            'Exercise'
                          )}
                          <span style={{ color: '#A4A4A4', fontWeight: '900', fontFamily: 'Nunito Sans' }}> Not Completed</span>
                        </Text>
                      ) : (
                        <Text fontSize='11px' color={theme.colors.lightText[2]}>
                          All Exercises Completed
                        </Text>
                      )}
                    </>
                  )}
                </Col>
              ))}
            </Row>
          </Grid>
        </HorizontalScrollContainer>
      )}
    </Wrapper>
  );
};
export default CompletionBreakdownGrid;
