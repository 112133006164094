import update from 'immutability-helper';

import {
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_EVALUATIONS_REJECTED,
  FETCH_EVALUATIONS_START,
  FETCH_EVALUATION_FULFILLED,
  FETCH_EVALUATION_REJECTED,
  FETCH_EVALUATION_START,
  SET_AUTO_SAVE_INFO_MODAL,
  SET_INITIAL_STATE,
  SET_MANAGE_EVALUATIONS_SCREEN_ON,
  SET_MANAGE_EVALUATIONS_SCREEN_OFF,
  SET_NEW_EVAL_MODAL1,
  SET_SELECT_EXISTING_EVAL_MODAL1,
  CREATE_EVALUATION_FULFILLED,
  RESET_EVALUATION,
  FETCH_EVAL_FROM_EDIT_FULFILLED,
  FETCH_EVAL_FROM_EDIT_START,
  CREATE_EVALUATION_REJECTED,
  SET_EDIT_SLIDE_INDEX,
  FETCH_CALENDARS_START,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  SET_GROUP_ACCESS_EVAL_MODAL,
} from './actionTypes';

const initialState = {
  evalOverlayOpen: true,
  firstSelectionModalOpen: true,
  newEvalModal1: false,
  selectExistingEvalModal1: false,
  autoSaveInfoModalShowing: false,
  isEvaluationsLoading: false,
  isEvaluationLoading: false,
  isEvaluationContentTable: false,
  isEvaluationsContentList: false,
  isCalendarsLoading: false,
  isGroupsLoading: false,
  fetchedCalendars: false,
  fetchedGroups: false,
  createEvaluationError: '',
  editSlideIndex: 0,
  isGroupAccessEvalModalShowing: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVALUATIONS_START: {
      return {
        ...state,
        isEvaluationsLoading: true,
      };
    }
    case FETCH_EVALUATIONS_FULFILLED: {
      return {
        ...state,
        isEvaluationsLoading: false,
      };
    }
    case FETCH_EVALUATIONS_REJECTED: {
      return {
        ...state,
        isEvaluationsLoading: false,
      };
    }
    case FETCH_EVALUATION_START: {
      return {
        ...state,
        isEvaluationLoading: true,
      };
    }
    case FETCH_EVAL_FROM_EDIT_START: {
      return {
        ...state,
        isEvaluationLoading: true,
      };
    }
    case FETCH_EVALUATION_FULFILLED: {
      return {
        ...state,
        isEvaluationLoading: false,
        isEvaluationContentTable: true,
        evalOverlayOpen: false,
        selectExistingEvalModal1: false,
      };
    }
    case FETCH_EVAL_FROM_EDIT_FULFILLED: {
      return {
        ...state,
        isEvaluationLoading: false,
        isEvaluationContentTable: true,
        evalOverlayOpen: false,
        selectExistingEvalModal1: false,
        isEvaluationsContentList: false,
      };
    }
    case RESET_EVALUATION: {
      return {
        ...state,
        isEvaluationContentTable: false,
        evalOverlayOpen: true,
        selectExistingEvalModal1: true,
      };
    }
    case FETCH_EVALUATION_REJECTED: {
      return {
        ...state,
        isEvaluationLoading: false,
        selectExistingEvalModal1: false,
        evalOverlayOpen: false,
      };
    }
    case SET_INITIAL_STATE: {
      return {
        ...state,
        evalOverlayOpen: action.payload,
        firstSelectionModalOpen: action.payload,
      };
    }
    case SET_NEW_EVAL_MODAL1: {
      return {
        ...state,
        newEvalModal1: action.payload,
        firstSelectionModalOpen: !action.payload,
      };
    }
    case SET_SELECT_EXISTING_EVAL_MODAL1: {
      if (state.evalOverlayOpen) {
        return {
          ...state,
          selectExistingEvalModal1: action.payload,
          firstSelectionModalOpen: !action.payload,
        };
      }
      return {
        ...state,
        selectExistingEvalModal1: action.payload,
        firstSelectionModalOpen: false,
      };
    }
    case SET_AUTO_SAVE_INFO_MODAL: {
      return {
        ...state,
        autoSaveInfoModalShowing: action.payload,
      };
    }
    case SET_MANAGE_EVALUATIONS_SCREEN_ON: {
      return {
        ...state,
        evalOverlayOpen: false,
        isEvaluationsContentList: true,
        firstSelectionModalOpen: false,
      };
    }
    case SET_MANAGE_EVALUATIONS_SCREEN_OFF: {
      return {
        ...state,
        evalOverlayOpen: true,
        isEvaluationsContentList: false,
        firstSelectionModalOpen: true,
      };
    }
    case CREATE_EVALUATION_FULFILLED: {
      return {
        ...state,
        evalOverlayOpen: false,
        newEvalModal1: false,
        createEvaluationError: '',
      };
    }
    case CREATE_EVALUATION_REJECTED: {
      return {
        ...state,
        createEvaluationError: action.payload.response.headers.message,
      };
    }
    case SET_EDIT_SLIDE_INDEX: {
      return {
        ...state,
        editSlideIndex: action.payload,
      };
    }
    case FETCH_CALENDARS_START: {
      return {
        ...state,
        isCalendarsLoading: true,
      };
    }
    case FETCH_CALENDARS_FULFILLED: {
      return {
        ...state,
        isCalendarsLoading: false,
        fetchedCalendars: true,
      };
    }
    case FETCH_CALENDARS_REJECTED: {
      return {
        ...state,
        isCalendarsLoading: false,
        fetchedCalendars: true,
      };
    }
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
        fetchedGroups: true,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
        fetchedGroups: true,
      };
    }
    case SET_GROUP_ACCESS_EVAL_MODAL: {
      return {
        ...state,
        isGroupAccessEvalModalShowing: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
