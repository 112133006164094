import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';

const ProgressBarWrapper = styled('div')`
  width: 105px;
  height: 22px;
  position: relative;
  @media screen and (max-width: 668px) {
    width: 74px;
  }
`;

const ProgressBarContainer = styled('div')`
  width: 105px;
  height: 22px;
  display: inline-flex;
  position: absolute;
  background-color: ${props => props.theme.colorAccent};
  border: 1px solid white;
  border-radius: 2px;
  @media screen and (max-width: 668px) {
    width: 74px;
  }
`;

const ProgressBarFill = styled('div')`
  background-color: white;
  position: absolute;
  width: ${props => (props.percent ? `${props.percent}%` : '0%')};
  height: 20px;
  opacity: .6;
  transition: ${TransitionGlobal};
`;

const ProgressText = styled('div')`
  width: 100%;
  font-family: 'Open Sans';
  font-size: 10px;
  font-weight: 900;
  color: white;
  text-align: center;
  align-self: center;
`;

const ProgressBar = ({
  assigned,
  completed,
}) => {
  const percent = completed * 100 / assigned;

  return (
    <ProgressBarWrapper className='progress-bar'>
      <ProgressBarContainer className='progress-bar-container'>
        <ProgressBarFill className='progress-bar-fill' percent={percent} />
        <ProgressText className='progress-text'>
          <span>
            {completed}
            /
            {assigned}
          </span>
        </ProgressText>
      </ProgressBarContainer>
    </ProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  assigned: PropTypes.number,
  completed: PropTypes.number,
};

ProgressBar.defaultProps = {
  assigned: 0,
  completed: 0,
};

export default ProgressBar;
