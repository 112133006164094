// simple component for displaying details of the session,
// including sets, reps, tonnage, and PRs
/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import Shimmer from 'react-shimmer-effect';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {

} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 300px;
	border-radius: 24px;
	margin-top: 15px;
	padding: 20px;
	box-shadow: ${BoxShadow};
	background: white;
`;

const RowBox = styled('div')`
	display: flex;
	height: 120px;
	width: 100%;
	align-items: center;
	justify-content: space-evenly;
`;

const TopSec = styled('div')`
	display: flex;
	width: 100%;
  justify-content: space-between;

  svg {
    margin-top: 2px;
  }
`;

const Box = styled('div')`
	display: flex;
	width: 45%;
	height: 90%;
	padding: 12px;
	box-shadow: ${BoxShadow};
	background: white;
	border-radius: 12px;
	flex-direction: column;
	justify-content: space-between;
`;

const BoxTitle = styled('div')`
	display: flex;
	height: 25px;
  margin-bottom: 10px;

  svg {
    margin-top: 1px;
  }
`;

const BoxTitleText = styled('div')`
	font-size: 14px;
	font-weight: 700;
	margin-left: 5px;
	font-family: 'Nunito Sans';
`;

const TopText = styled('div')`
	font-size: 12px;
	font-weight: 700;
	font-family: 'Nunito Sans';
`;

const BottomText = styled('div')`
	font-size: 18px;
	font-weight: 700;
	font-family: 'Nunito Sans';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SessionSummaryDetails = () => {
  const workoutSessionData = useSelector((state) => state.workouts.data.workoutSessionData);
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);

  // this is a simple function for converting the duration
  // of the session into a hour/minute format
  const secondsConverter = (seconds) => {
    if (seconds) {
      return moment.utc(seconds * 1000).format('HH:mm');
    }
    return 0;
  };

  if (isWorkoutsListLoading) {
    return (
      <Container>
        <BoxTitle>
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='lift'
            color='black'
          />
          <BoxTitleText>Session Summary</BoxTitleText>
        </BoxTitle>
        <RowBox>
          <Box>
            <Shimmer>
              <TopText style={{
                height: 20,
                borderRadius: 24,
              }}
              />
            </Shimmer>
            <Shimmer>
              <BottomText style={{
                borderRadius: 24,
                height: 27,
                width: 50,
              }}
              />
            </Shimmer>
          </Box>
          <Box>
            <Shimmer>
              <TopText style={{
                height: 20,
                borderRadius: 24,
              }}
              />
            </Shimmer>
            <Shimmer>
              <BottomText style={{
                borderRadius: 24,
                height: 27,
                width: 50,
              }}
              />
            </Shimmer>
          </Box>
        </RowBox>
        <RowBox>
          <Box>
            <Shimmer>
              <TopText style={{
                height: 20,
                borderRadius: 24,
              }}
              />
            </Shimmer>
            <Shimmer>
              <BottomText style={{
                borderRadius: 24,
                height: 27,
                width: 50,
              }}
              />
            </Shimmer>
          </Box>
          <Box>
            <Shimmer>
              <TopText style={{
                height: 20,
                borderRadius: 24,
              }}
              />
            </Shimmer>
            <Shimmer>
              <BottomText style={{
                borderRadius: 24,
                height: 27,
                width: 50,
              }}
              />
            </Shimmer>
          </Box>
        </RowBox>
      </Container>
    );
  }
  return (
    <Container>
      <BoxTitle>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='lift'
          color='black'
        />
        <BoxTitleText>Session Summary</BoxTitleText>
      </BoxTitle>
      <RowBox>
        <Box>
          <TopSec>
            <TopText>Sets Completed</TopText>
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='list'
              color='#444'
            />
          </TopSec>
          <BottomText>{workoutSessionData.setsCompleted || 0}</BottomText>
        </Box>
        <Box>
          <TopSec>
            <TopText>Total Reps</TopText>
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='muscle'
              color='#444'
            />
          </TopSec>
          <BottomText>{workoutSessionData.repsCompleted || 0}</BottomText>
        </Box>
      </RowBox>
      <RowBox>
        <Box>
          <TopSec>
            <TopText>Tonnage</TopText>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='lift'
              color='#444'
            />
          </TopSec>
          <BottomText>{workoutSessionData.tonnage || 0}</BottomText>
        </Box>
        <Box>
          <TopSec>
            <TopText>Session Duration</TopText>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='clock'
              color='#444'
            />
          </TopSec>
          <BottomText>
            {workoutSessionData.sessionDurationInSeconds ? secondsConverter(workoutSessionData.sessionDurationInSeconds) : 'N/A'}
          </BottomText>
        </Box>
      </RowBox>
    </Container>
  );
};

SessionSummaryDetails.propTypes = { };

export default SessionSummaryDetails;
