/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBToggleSwitch from '../../../../shared/components/TBToggleSwitch/TBToggleSwitch';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import { setIsPrintingOptionsShowing } from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ModalText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  font-weight: bold;
`;

const ModalTextCust = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  .custom-form {
    padding: 30px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 80%;
    margin-top: 5px;
  }
`;

const StyleContainer = styled('div')`
  width: 100%;
  .submit-btn {
    background: #0dcc8a;
    border-style: none;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .submit-btn:hover {
    background-color: rgba(9, 194, 123);
  }
  .sub-text{
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`;

const PaddingContainer = styled('div')`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
`;

const InnerContentContainer = styled('div')`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.isWorkoutGenerating ? 0 : 1)};
  transition: opacity 0.5s ease;
  z-index: 1;
`;

const SpinnerWrapper = styled('div')`
  display: flex;
  height: 60%;
  justify-content: center;
  width: 100%;
  position: absolute;
  transition: opacity 0.5s ease;
  opacity: ${(props) => (props.isWorkoutGenerating ? 1 : 0)};
`;

const SettingsRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .select__value-container {
    padding: 2px 16px 2px 12px;
  }
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const ReadyText = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-weight: 500;
  font-size: 20px;
  padding: 10px;
  margin-top: 30px;
  text-align: center;
`;

const TextLabel = styled('p')`
  font-weight: 400;
  margin-bottom: 0px;
  color: ${(props) => props.theme.textColor};
  font-size: 14px;
`;

const CustomOptionsHeader = styled('div')`
	min-height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
  padding-right: 15px;
  cursor: pointer;
`;

const WorkoutOptionsContainer = styled('div')`
	display: flex;
	flex-direction: column;
`;

const Options = styled('div')`
  max-height: ${(props) => (props.isExposed ? `${props.heightCalc}px` : '0px')};
	overflow: hidden;
	-webkit-transition: max-height 1s ease;
	-moz-transition: max-height 1s ease;
	transition: max-height 1s ease;
`;

const Chevron = styled('div')`
	transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : 'rotate(0deg)')};
	transition: 0.5s ease;
`;

const InnerContainer = styled('div')`
	display: flex;
	width: 100%;
`;

const objectMapper = (object, setObject) => {
  const settingsTypeArray = [];
  const optionKeys = Object.keys(object);
  optionKeys.forEach((key) => {
    settingsTypeArray.push(
      <SettingsRow>
        <TextContainer>
          <TextLabel>
            {key}
          </TextLabel>
        </TextContainer>
        <TBToggleSwitch
          checked={object[key]}
          onChange={() => {
            const newObject = { ...object };
            const newSetting = !object[key];
            newObject[key] = newSetting;
            setObject(newObject);
          }}
        />
      </SettingsRow>,
    );
  });
  return settingsTypeArray;
};

const ToggleContainer = styled('div')`
	width: 50px;
	background-color: ${(props) => (props.isSelected ? '#19AB27' : '#4D4D4D')};
	cursor: pointer;
	user-select: none;
	border-radius: 30px;
	height: 24px;
	position: relative;
	display: flex;
	align-items: center;
	transition: all 0.3s ease;
	.dialog-button {
		line-height: 16px;
		cursor: pointer;
		background-color: #FAFAFA;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		box-sizing: border-box;
		position: absolute;
		left: 28px;
		transition: all 0.3s ease;
	}
	.disabled {
		left: 2px;
	}
`;

const PrintingOptionsModal = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const isPrintingOptionsShowing = useSelector(
    (state) => state.workouts.data.isPrintingOptionsShowing,
  );

  const filterRef = useRef();

  const [workoutComponentsSettings, setWorkoutComponentsSettings] = useState({
    Notes: true,
    'Warm-Ups': true,
    Cooldowns: true,
    Lifts: true,
    Tags: true,
    'SAQ+C': true,
    Circuits: true,
    Health: true,
    'Sport Science': true,
    'Session Breaks': true,
    'Rest Time': true,
    Questionnaires: true,
  });

  const [isForToday, setIsForToday] = useState(true);
  const [showMaxes, setShowMaxes] = useState(true);
  const [isExposed, setIsExposed] = useState(false);
  const [isWorkoutGenerating, setIsWorkoutGenerating] = useState(false);
  const [printOptionsLink, setPrintOptionsLink] = useState('');

  const [cookies] = useCookies();

  useEffect(() => {
    if (!isPrintingOptionsShowing) {
      setPrintOptionsLink('');
    }
  }, [isPrintingOptionsShowing]);

  return (
    <ModalContainer
      isActive={isPrintingOptionsShowing}
      isReady={printOptionsLink.length}
    >
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='printer'
            color='black'
          />
          <div style={{
            marginLeft: 10,
          }}
          >
            <ModalTextTop>Printing</ModalTextTop>
          </div>
        </div>
        <div className='remove-div-icon' onClick={() => dispatch(setIsPrintingOptionsShowing(false))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        {!printOptionsLink ? (
          <InnerContentContainer isWorkoutGenerating={isWorkoutGenerating}>
            <StyleContainer>
              <PaddingContainer>
                <ModalText>Create Printout for:</ModalText>
                <SettingsRow>
                  <TextContainer>
                    <TextLabel>
                      Today
                    </TextLabel>
                  </TextContainer>
                  <ToggleContainer
                    isSelected={isForToday}
                    onClick={() => setIsForToday(!isForToday)}
                  >
                    <div tabIndex='-1' className={`dialog-button ${isForToday ? 'enabled' : 'disabled'}`} />
                  </ToggleContainer>
                </SettingsRow>
                <SettingsRow>
                  <TextContainer>
                    <TextLabel>
                      Entire Week
                    </TextLabel>
                  </TextContainer>
                  <ToggleContainer
                    isSelected={!isForToday}
                    onClick={() => setIsForToday(!isForToday)}
                  >
                    <div tabIndex='-1' className={`dialog-button ${!isForToday ? 'enabled' : 'disabled'}`} />
                  </ToggleContainer>
                </SettingsRow>
              </PaddingContainer>
            </StyleContainer>
            <StyleContainer>
              <PaddingContainer>
                <ModalText>Max Display Selection</ModalText>
                <SettingsRow>
                  <TextContainer>
                    <TextLabel>
                      Show Maxes
                    </TextLabel>
                  </TextContainer>
                  <ToggleContainer
                    isSelected={showMaxes}
                    onClick={() => setShowMaxes(!showMaxes)}
                  >
                    <div tabIndex='-1' className={`dialog-button ${showMaxes ? 'enabled' : 'disabled'}`} />
                  </ToggleContainer>
                </SettingsRow>
              </PaddingContainer>
            </StyleContainer>
            <StyleContainer>
              <PaddingContainer>
                <WorkoutOptionsContainer ref={filterRef}>
                  <InnerContainer>
                    <CustomOptionsHeader onClick={() => setIsExposed(!isExposed)}>
                      <ModalTextCust>Customize Workout</ModalTextCust>
                      <Chevron isRotated={isExposed}>
                        <IcomoonReact
                          iconSet={iconSet}
                          size={15}
                          icon='altrightarrow'
                          color='black'
                        />
                      </Chevron>
                    </CustomOptionsHeader>
                  </InnerContainer>
                  <Options
                    heightCalc={(Object.keys(workoutComponentsSettings).length * 50).toString()}
                    isExposed={isExposed}
                  >
                    {objectMapper(workoutComponentsSettings, setWorkoutComponentsSettings)}
                  </Options>
                </WorkoutOptionsContainer>
              </PaddingContainer>
            </StyleContainer>
          </InnerContentContainer>
        ) : (
          <InnerContentContainer
            isWorkoutGenerating={isWorkoutGenerating}
          >
            <ReadyText>Your printout is ready!</ReadyText>
          </InnerContentContainer>
        )}
        <SpinnerWrapper isWorkoutGenerating={isWorkoutGenerating}>
          <Spinner />
        </SpinnerWrapper>
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta={!printOptionsLink ? 'submit' : 'download'}
        className='print-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          if (!printOptionsLink) {
            setIsWorkoutGenerating(true);
            setTimeout(() => {
              axios.post(`${process.env.PHP_APP_URL}/assets/build_packet_beta2.php?act=build&token=${encodeURIComponent(cookies?.v2token) || ''}`, {
                user_id: currentUser.admin ? sharedAthlete?.id // id of selected user (if admin)
                  : currentUser.id, // the id of the current user
                date: activeWorkoutDate, // the current selected date
                print_from: 1, // printing from workout entry
                build_for: 'A', // building for athlete
                show_items: [ // these are the workout items the user checked off
                  workoutComponentsSettings.Notes ? 'N' : '', // Notes
                  workoutComponentsSettings['Warm-Ups'] ? 'W' : '', // Warm-Ups
                  workoutComponentsSettings.Cooldowns ? 'CD' : '', // Cooldowns
                  workoutComponentsSettings.Lifts ? 'L' : '', // Lifts
                  workoutComponentsSettings.Tags ? 'T' : '', // Tags
                  workoutComponentsSettings['SAQ+C'] ? 'R' : '', // SAQ+C
                  workoutComponentsSettings.Circuits ? 'C' : '', // Circuits
                  workoutComponentsSettings.Health ? 'H' : '', // Health
                  workoutComponentsSettings.Questionnaires ? 'S' : '', // Questionnaires
                  workoutComponentsSettings['Session Breaks'] ? 'BR' : '', // Session Breaks
                  workoutComponentsSettings['Rest Time'] ? 'RT' : '', // Rest Time
                  workoutComponentsSettings.Progressions ? 'P' : '', // Progressions
                ],
                hide_max: showMaxes ? 0 : 1, // 0 = Show Maxes, 1 = Hide Maxes
                print_type: 1,
                print_time: isForToday ? 1 : 2, // 1 = Today, 2 = Entire Week
              }, {
                headers: {
                  // 'application/json' is the modern content-type for JSON, but some
                  // older servers may use 'text/json'.
                  // See: http://bit.ly/text-json
                  session_id: 'e818f4dd53d35135e08f16e5431462c4',
                  session_key: '344c93d8ada6b33f83fce2ffb65e64f7',
                },
              })
                .then((response) => {
                  setIsWorkoutGenerating(false);
                  setIsExposed(false);
                  if (response.data.error === 0) {
                    setPrintOptionsLink(response.data.link);
                  } else {
                    alert(response.data.err_msg);
                  }
                })
                .catch((error) => {
                  setIsWorkoutGenerating(false);
                  alert(error);
                });
            }, 1000);
          } else {
            window.open(printOptionsLink, '_blank');
          }
        }}
      />
    </ModalContainer>
  );
};

export default PrintingOptionsModal;
