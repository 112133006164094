// modal for confirming deletion of selected documents

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';

import NoHoverButton from './NoHoverButton';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: -40px;
`;

const TextWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
`;

const ModalConfirmDelete = ({
  handleDeleteDocuments,
  setSelectedRows,
  selectedRows,
  currentUser,
  onRequestClose,
  setSingleAction,
  isSingleAction,
}) => {
  const groupDeleteDocuments = () => {
    handleDeleteDocuments(currentUser.accountCode, selectedRows);
  };
  const isDocumentDeleting = useSelector((state) => state.documents.ui.isDocumentDeleting);
  const theme = useTheme();

  const languageThingy = () => {
    let languageString = ' ';
    if (selectedRows.length === 1) {
      languageString += 'this';
    } else {
      languageString += 'these';
    }
    languageString += ' ';
    if (selectedRows.length > 1) {
      languageString += selectedRows.length.toString();
    }
    languageString += ' ';
    if (selectedRows.length === 1) {
      languageString += 'document?';
    } else {
      languageString += 'documents?';
    }
    return languageString;
  };

  return (
    <ModalWrapper>
      <ModalWrapper>
        <AnotherWrapper>
          <NavigationWrapper>
            <Text
              className='back-arrow-div'
              onClick={() => {
                onRequestClose();
                if (isSingleAction) {
                  setSelectedRows([]);
                  setSingleAction(false);
                }
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <TextWrapper>
            <Text
              fontWeight='600'
              fontSize={theme.textFontSizes[2]}
              style={{
                marginTop: '-15px',
              }}
            >
              Are you sure you want to delete
              {selectedRows.length === 1 ? ' this document?' : languageThingy()}
            </Text>
          </TextWrapper>
        </AnotherWrapper>
        <NoHoverButton
          bottom
          fullWidth
          customColor='#f24540'
          cta={!isDocumentDeleting ? `Delete ${selectedRows.length === 1 ? 'Document' : 'Documents'}` : 'Deleting...'}
          className='modal-button'
          disabled={isDocumentDeleting}
          large
          noBorder
          primary
          square
          onClick={() => (groupDeleteDocuments())}
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalConfirmDelete.propTypes = {
  handleDeleteDocuments: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  onRequestClose: PropTypes.func.isRequired,
  setSingleAction: PropTypes.func.isRequired,
  isSingleAction: PropTypes.bool.isRequired,
};

export default ModalConfirmDelete;
