import { connect } from 'react-redux';
import ModalNote from '../presentational/ModalNote';
import {
  closeModal,
  fetchJournal,
  submitJournal,
  updateJournal,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteId: state[ownProps.namespace].athleteId,
  workId: state[ownProps.namespace].activeWorkout,
});


const mapDispatchToProps = dispatch => ({
  closeModal: (namespace) => {
    dispatch(closeModal(namespace));
  },
  fetchJournal: (index, namespace, workId, userId) => {
    dispatch(fetchJournal(index, namespace, workId, userId));
  },
  submitJournal: (index, namespace, values) => {
    dispatch(submitJournal(index, namespace, values));
  },
  updateJournal: (index, namespace, values, workId) => {
    dispatch(updateJournal(index, namespace, values, workId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalNote);
