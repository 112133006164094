import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import FontPicker from 'font-picker-react';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import SubText from '../../../../shared/components/SubText/SubText';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import PublicProgramsGrid from './PublicProgramsGrid';

const FeatureContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 325px;
  background: #1d1d1d;
  background-size: cover;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  img {
    object-fit: cover;
    opacity: .1;
    width: 100%;
    height: 100%;
  }

  h1, span {
    ${(props) => (props.isLoading ? 'color: transparent' : 'color: white')};
  }

  span {
    margin-top: 15px;
  }
`;

const ProgramTitle = styled('div')`
  position: absolute;
  text-align: center;
`;

const ProgramsLandingPage = ({
  resellerData,
}) => {
  const headerImage = optimizeImage(
    `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging' : 'production'}-assets/${resellerData?.headerMediaKey}`,
    {
      resize: {
        with: 1200, fit: 'contain',
      },
    },
  );

  return (
    <>
      <FeatureContainer>
        <FontPicker
          apiKey='AIzaSyDQgB01ojlqMbudd0krjI5GkJpeJQILjFA'
          activeFontFamily={resellerData?.customFont === null ? 'Open Sans' : resellerData?.customFont}
        />
        <img alt='background' src={headerImage} />
        <ProgramTitle>
          <HeaderText className={resellerData?.customFont === null ? '' : 'apply-font'} fontFamily='BLACKHAWK' fontSize={64}>{resellerData?.resellerName}</HeaderText>
          <SubText fontWeight={500}>Programs Available for Purchase</SubText>
        </ProgramTitle>
      </FeatureContainer>
      <PublicProgramsGrid />
    </>
  );
};

ProgramsLandingPage.propTypes = {
  resellerData: PropTypes.instanceOf(Object).isRequired,
};

export default ProgramsLandingPage;
