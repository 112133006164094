import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import Logger from 'js-logger';

const BreadcrumbContainer = styled('div')`
  display: flex;
  align-items: center;
  a {
    font-size: 14px;
  }
`;

const Seperator = styled('div')`
  padding: 0px 10px;
`;

const Breadcrumb = ({
  children,
}) => {
  const dispatch = useDispatch();

  const handleClick = (e, href) => {
    e.preventDefault();
    dispatch(push(href));
    Logger.debug(`Clicked href: ${href}`);
  };

  return (
    <BreadcrumbContainer>
      {React.Children.map(children || null, (child, i) => {
        if (child !== null) {
          return (
            <Fragment>
              <child.type {...child.props} key={i} onClick={e => handleClick(e, child.props.href)} />
              {i + 1 !== React.Children.map.length && (
                <Seperator>/</Seperator>
              )}
            </Fragment>
          );
        }
        return null;
      })}
    </BreadcrumbContainer>
  );
};

Breadcrumb.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};

export default Breadcrumb;
