import { useDispatch } from 'react-redux';
import {
  fetchLatestSession,
  fetchSessions,
  fetchMultidaySessions,
} from '../../ducks/profileActions';
import { fetchUserData } from '../../../login/ducks/loginActions';

const useProfile = () => {
  const dispatch = useDispatch();

  const handleFetchProfile = () => {
    dispatch(fetchUserData());
  };

  const handleFetchLatestSession = (accountCode) => {
    dispatch(fetchLatestSession(accountCode));
  };

  const handleFetchSessions = (accountCode, dateRangeStart, dateRangeEnd) => {
    dispatch(fetchSessions(accountCode, dateRangeStart, dateRangeEnd));
  };

  const handleFetchMultiDaySessions = (accountCode, multiDay) => {
    dispatch(fetchMultidaySessions(accountCode, multiDay));
  };

  return {
    handleFetchLatestSession,
    handleFetchMultiDaySessions,
    handleFetchProfile,
    handleFetchSessions,
  };
};

export default useProfile;
