import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import workoutsUiReducer from './workoutsUiReducer';
import workoutsDataReducer from './workoutsDataReducer';

const uIPersistConfig = {
  key: 'ui',
  storage,
  whitelist: ['isDayCarousel', 'hiddenWorkoutsToggle'],
};

const dataPersistConfig = {
  key: 'data',
  storage,
  whitelist: [
    'workoutPrograms',
    'workoutProgramWeeks',
    'currentSelectedProgram',
    'workoutProgramWeek',
    'workoutProgramWeekIndex',
    'weekDayIndex',
    'programDayIndex',
    'currentNonDateDay',
  ],
};

const workoutsReducer = combineReducers({
  // data: workoutsDataReducer,
  data: persistReducer(dataPersistConfig, workoutsDataReducer),
  ui: persistReducer(uIPersistConfig, workoutsUiReducer),
});

export default workoutsReducer;
