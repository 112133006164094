import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';

import OptionField from './OptionField';

const ProgressionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  .css-uk3tfh-NoteContainer {
    padding: 0px 20px 0px 0px;
  }
`;

export const OptionText = styled('p')`
  font-size: 13px;
  color: ${(props) => props.theme.textColor};
  white-space: pre-line;
`;

export const OptionLabel = styled('span')`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  color: ${(props) => props.theme.textColor};
`;

export const OptionSubtext = styled('span')`
  font-size: 10px;
  font-weight: 300;
  color: ${(props) => props.theme.lightTextColor};
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
`;

const EntriesProgression = ({
  activeDate,
  athleteId,
  formikRef,
  index,
  info,
  namespace,
  updateProgramWorkout,
  updateWorkout,
  workout,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isProgramView = useSelector((state) => state.weightRoomView[namespace].isProgramView);

  const isCompletion = workout.completionCheckbox;

  const MASTERED = true;
  const STILL_WORKING = false;

  const masteredProgressionOption = {
    inputLabel: 'I have mastered this',
    label: 'I have mastered this',
    value: MASTERED,
  };

  const stillWorkingProgressionOption = {
    inputLabel: 'I am still working on this',
    label: 'I am still working on this',
    value: STILL_WORKING,
  };

  const progressionOptions = [
    stillWorkingProgressionOption,
    masteredProgressionOption,
  ];

  return (
    <Formik
      key={workout.assignedId || workout.saveDataId}
      ref={formikRef}
      initialValues={
        isCompletion ? (
          (() => ({
            accountCode,
            assignedDate: moment(activeDate).format('YYYY-MM-DD'),
            assignedId: workout.assignedId || workout.saveDataId,
            userId: athleteId,
            body: {
              mastered: true,
              level: workout.progressionLevel,
            },
          })
          )()
        ) : (
          (() => ({
            accountCode,
            assignedDate: moment(activeDate).format('YYYY-MM-DD'),
            assignedId: workout.assignedId || workout.saveDataId,
            userId: athleteId,
            body: {
              mastered: workout.progressionStepMastered,
              level: workout.progressionLevel,
            },
          }))()
        )
      }
      onSubmit={(values, actions) => {
        // Originally mastered was being sent as a string
        // created newValues and spread the old values to make sure
        // mastered is being sent as a boolean
        const newValues = {
          ...values,
          body: {
            mastered: values.body.mastered === 'true' || values.body.mastered === true,
            level: values.body.level,
          },
        };
        if (isProgramView) {
          updateProgramWorkout(
            index,
            namespace,
            currentSelectedProgram.dayBasedProgramId,
            currentNonDateDay.trueDayNum,
            currentNonDateDay.relativeDisplayDayNum - 1,
            newValues,
          );
        } else {
          updateWorkout(index, namespace, newValues);
        }
        actions.setSubmitting(false);
      }}
      render={(props) => (
        <ProgressionContainer>
          <Form>
            <OptionText>
              {info}
            </OptionText>
            {isCompletion ? (
              <OptionLabel>
                This is a supplementary step. It does not require you to master.
              </OptionLabel>
            ) : (
              <>
                <OptionLabel>
                  Select current status for this step
                </OptionLabel>
                <OptionField
                  handleChange={props.handleChange}
                  isKeyValuePair
                  name='body.mastered'
                  selectId={props.values.assignedId}
                  values={props.values.body.mastered}
                  options={progressionOptions}
                />
              </>
            )}
            <OptionSubtext>
              *All steps in a level must be mastered to move on to the next level.
            </OptionSubtext>
          </Form>
        </ProgressionContainer>

      )}
    />
  );
};

EntriesProgression.propTypes = {
  athleteId: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  info: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  updateProgramWorkout: PropTypes.func.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default EntriesProgression;
