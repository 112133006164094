/* eslint-disable no-nested-ternary */
// authored by Joshua Beedle
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import { BoxShadow } from '../../GlobalStyles';
import Wrapper from './Wrapper';
import Badge from './Badge';
import Button from '../Button';
import Tooltip from '../Tooltip';
import AthleteRadio from './AthleteRadio';

const FilterContainer = styled('div')`
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: ${BoxShadow};
  cursor: default;
  display: flex;
  flex-direction: column;
  max-height: 420px;
  padding: 16px;
  position: absolute;
  top: 38px;
  width: ${(props) => (props.athleteRadio ? '290px' : '250px')};
  z-index: 5;
`;

const FilterHeader = styled('div')`
  display: flex;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.includesType ? '16px' : '20px')};
  font-weight: 700;
  justify-content: space-between;
  margin-bottom: ${(props) => (props.includeMarginBottom ? '10px' : '0px')};
`;

const MaxText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 100;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;

  .MuiFormLabel-root {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    color: #444444;
  }

  .MuiTypography-body1 {
    font-family: 'Nunito Sans';
    font-weight: normal;
  }
`;

const FilterOption = styled('div')`
  align-items: center;
  display: flex;
  margin-left: -10px;
`;

const OptionText = styled('div')`
  color: ${(props) => (props.isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#444444')};
  font-family: 'Nunito Sans';
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

/**
 * @param {String} actionButtonName controls the text to display in the main action button
 * @param {Array} activeFilters an array of filters that are currently active
 * @param {Array} activeTypeFilters an array of type filters that are currently active
 * @param {Boolean} athleteRadio boolean to control whether or not to display athlete radio
 * option for athletes with alerts (FP Dashboard)
 * @param {String} athleteRadioValue controls the value of the selected athlete radio
 * @param {Boolean} disableOptions controls whether or not to disable main action buttton
 * @param {Boolean} disableOptions controls whether or not to disable options when filter
 * limit is reached
 * @param {String} filterLimit controls whether or not to display the max filter amount
 * @param {String} filterTitle controls the title to display in the filter dropdown
 * @param {Function} handleActionButton function to handle what the main action button should do
 * @param {Function} handleChange function to handle checkbox changes
 * @param {Function} handleChangeType function to handle type checkbox changes
 * @param {Function} handleClear function to handle clearing the selected/active filters
 * @param {Function} handleClose function to handle closing the filter dropdown
 * @param {Function} handleFilter function to handle opening the filter dropdown
 * @param {Function} handleRadioChange function to handle selecting the athlete radios
 * @param {Boolean} isArrayOfObjects determines whether or not the option array is an
 * array of objects
 * @param {Boolean} isOpen controls whether filter dropdown is open
 * @param {Boolean} isRecoveryRadar controls whether or not to display 'Athletes' in radio label
 * @param {Boolean} isSearchable controls whether or not to display a search bar in filter
 * @param {Array} options an array of options to display in the filter dropdown
 * @param {Object} styledButtonTextProps accepts an object with all optional styles for ButtonText
 * @param {Object} styledButtonWrapperProps accepts an object with all optional
 * styles for Button Wrapper
 * @param {Object} styledPropsWrapper accepts an object with all optional styles for Wrapper
 * in accordance with the styled-system paradigm (https://github.com/styled-system/styled-system/blob/master/docs/api.md)
 * @param {String} subheader controls the text to display if a subheader is attached
 * @param {Array} typeOptions an array of type options to display in the filter dropdown
 * @param {Boolean} useReplace controls whether or not to use .replace() with Checkbox checked prop
 * @returns
 */
const DashboardFilter = ({
  actionButtonName,
  activeFilters,
  activeTypeFilters,
  athleteRadio,
  athleteRadioValue,
  disableActionButton,
  disableOptions,
  filterLimit,
  filterTitle,
  handleActionButton,
  handleChange,
  handleChangeType,
  handleClear,
  handleClose,
  handleFilter,
  handleRadioChange,
  isArrayOfObjects,
  isOpen,
  isRecoveryRadar,
  isSearchable,
  options,
  searchTerm,
  setSearchTerm,
  styledButtonTextProps,
  styledButtonWrapperProps,
  styledPropsWrapper,
  subheader,
  typeOptions,
  useReplace,
}) => {
  const badgeMath = activeTypeFilters.length > 0 ? activeFilters.length + activeTypeFilters.length
    : activeFilters.length;
  const filteredOptions = options.filter((option) => {
    const optionText = isArrayOfObjects ? option.name : option;
    return optionText.toLowerCase().includes(searchTerm.toLowerCase());
  });
  const hasFilters = activeFilters.length > 0;
  const includesType = typeOptions.length > 0;
  const includeMarginBottom = (athleteRadio === true || subheader.length > 0) && !includesType;
  const tooltipLimit = athleteRadio ? 25 : 20;

  return (
    <Wrapper {...styledPropsWrapper}>
      <Button
        icon='filter'
        name='Filter'
        onClick={handleFilter}
        rounded
        {...styledButtonTextProps}
        {...styledButtonWrapperProps}
        upperCase
      />
      {badgeMath > 0 && (
      <Badge>{badgeMath}</Badge>
      )}
      {isOpen && (
      <FilterContainer athleteRadio={athleteRadio}>
        <FilterHeader
          includeMarginBottom={includeMarginBottom}
          includesType={includesType}
        >
          {filterTitle}
          <Button
            icon='remove'
            iconOnly
            name=''
            noBorder
            onClick={handleClose}
            spinnerColor='#808080'
            {...styledButtonTextProps}
            {...styledButtonWrapperProps}
          />
        </FilterHeader>
        {filterLimit && typeOptions.length <= 0 && (
          <MaxText>
            (Max.
            {' '}
            {filterLimit}
            {' '}
            Selections)
          </MaxText>
        )}
        <Filters>
          {athleteRadio && (
            <AthleteRadio
              athleteRadioValue={athleteRadioValue}
              handleRadioChange={handleRadioChange}
              isRecoveryRadar={isRecoveryRadar}
            />
          )}
          {athleteRadio && typeOptions.length > 0 && (
            'Type'
          )}
          {typeOptions.length > 0 && typeOptions.map(
            (type) => {
              // Variables to help keep code a bit cleaner when passing props
              const checked = activeTypeFilters.some((obj) => obj.id === type.id);
              const tooltipCheck = type.name.length;
              return (
                <FilterOption key={type.id}>
                  <Checkbox
                    color='default'
                    checked={checked}
                    onChange={() => handleChangeType(type)}
                  />
                  {tooltipCheck > tooltipLimit ? (
                    <Tooltip
                      cursor='default'
                      displayColor='#444444'
                      displayText={type.name}
                      hoverText={type.name}
                      id={type.name}
                    />
                  ) : (
                    <OptionText>
                      {type.name}
                    </OptionText>
                  )}
                </FilterOption>
              );
            },
          )}
          {subheader}
          {filterLimit && typeOptions.length > 0 && (
            <MaxText>
              (Max.
              {' '}
              {filterLimit}
              {' '}
              Selections)
            </MaxText>
          )}
          {isSearchable && (
            <input
              type='text'
              placeholder='Search filters...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                borderRadius: '4px',
                border: '1px solid #ccc',
                fontSize: '12px',
                marginTop: '5px',
                padding: '5px',
                width: '95%',
              }}
            />
          )}
          {filteredOptions.map(
            (option) => {
              // Variables to help keep code a bit cleaner when passing props
              const key = isArrayOfObjects ? option.id : option;
              const checked = useReplace ? activeFilters.includes(option.replace(/\s/g, ''))
                : isArrayOfObjects ? activeFilters.some((obj) => obj.id === option.id)
                  : activeFilters.includes(option);
              const disabled = isArrayOfObjects && disableOptions
                ? (hasFilters && activeFilters[0].unitAbbr !== option.unitAbbr)
                : disableOptions ? activeFilters.length === filterLimit
                  : null;
              const tooltipCheck = isArrayOfObjects ? option.name.length : option.length;
              const color = isArrayOfObjects
                ? (hasFilters && activeFilters[0].unitAbbr !== option.unitAbbr ? 'rgba(0, 0, 0, 0.26)' : '#444444')
                : disableOptions ? (activeFilters.length === filterLimit ? 'rgba(0, 0, 0, 0.26)' : '#444444')
                  : '#444444';
              const txt = isArrayOfObjects ? option.name : option;
              return (
                <FilterOption key={key}>
                  <Checkbox
                    color='default'
                    checked={checked}
                    disabled={disabled}
                    onChange={() => handleChange(option)}
                  />
                  {tooltipCheck > tooltipLimit ? (
                    <Tooltip
                      cursor='default'
                      displayColor={color}
                      displayText={txt}
                      hoverText={txt}
                      id={txt}
                    />
                  ) : (
                    <OptionText isDisabled={disabled}>
                      {txt}
                    </OptionText>
                  )}
                </FilterOption>
              );
            },
          )}
        </Filters>
        <FilterBottom>
          <Button
            name='Clear'
            onClick={handleClear}
            rounded
            {...styledButtonTextProps}
            {...styledButtonWrapperProps}
            upperCase
          />
          <Button
            bgColor='#444444'
            disabled={disableActionButton}
            name={actionButtonName}
            noHover={activeFilters.length === 0}
            onClick={handleActionButton}
            rounded
            {...styledButtonTextProps}
            {...styledButtonWrapperProps}
            txtColor='#FFFFFF'
            upperCase
          />
        </FilterBottom>
      </FilterContainer>
      )}
    </Wrapper>
  );
};

DashboardFilter.propTypes = {
  actionButtonName: PropTypes.string.isRequired,
  activeFilters: PropTypes.instanceOf(Array).isRequired,
  activeTypeFilters: PropTypes.instanceOf(Array),
  athleteRadio: PropTypes.bool,
  athleteRadioValue: PropTypes.string,
  disableActionButton: PropTypes.bool,
  disableOptions: PropTypes.bool,
  filterLimit: PropTypes.number,
  filterTitle: PropTypes.string,
  handleActionButton: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeType: PropTypes.func,
  handleClear: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  handleRadioChange: PropTypes.func,
  isArrayOfObjects: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isRecoveryRadar: PropTypes.bool,
  isSearchable: PropTypes.bool,
  options: PropTypes.instanceOf(Array).isRequired,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  styledButtonTextProps: PropTypes.instanceOf(Object),
  styledButtonWrapperProps: PropTypes.instanceOf(Object),
  styledPropsWrapper: PropTypes.instanceOf(Object),
  subheader: PropTypes.string,
  useReplace: PropTypes.bool,
  buttonPadding: PropTypes.string,
  borderColor: PropTypes.string,
  upperCase: PropTypes.bool,
  letterSpacing: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.number,
  txtColor: PropTypes.string,
  typeOptions: PropTypes.instanceOf(Array),
};

DashboardFilter.defaultProps = {
  activeTypeFilters: [],
  athleteRadio: false,
  athleteRadioValue: 'all',
  disableActionButton: false,
  disableOptions: false,
  filterLimit: null,
  filterTitle: 'Filters',
  handleChangeType: null,
  handleRadioChange: null,
  isArrayOfObjects: false,
  isRecoveryRadar: false,
  isSearchable: false,
  searchTerm: '',
  setSearchTerm: null,
  styledButtonTextProps: {},
  styledButtonWrapperProps: {},
  styledPropsWrapper: {},
  subheader: '',
  useReplace: false,
  buttonPadding: '8px 18px',
  borderColor: '#444444',
  upperCase: true,
  letterSpacing: '1.5px',
  fontSize: '12px',
  fontWeight: 900,
  txtColor: '#444444',
  typeOptions: [],
};

export default DashboardFilter;
