import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import CircularProgressbar from 'react-circular-progressbar';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import { ColorGreen } from '../../../../shared/GlobalStyles';

import 'react-circular-progressbar/dist/styles.css';
import IconRound from './IconRound';

const TimerWrapper = styled('div')` 
  background-color: ${props => props.theme.backgroundColorL2};
  border-radius: 50%;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  width: 170px;
  height: 170px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  .CircularProgressbar {
    padding: 10px;
    transform: rotate(-145deg);
  }
  .CircularProgressbar-path { stroke: ${ColorGreen}; }
  .CircularProgressbar-trail { stroke: transparent; }
  .CircularProgressbar-text { fill: yellow; }
  .CircularProgressbar-background { fill: green; }
`;

const TimerContainer = styled('div')`
  background-color: ${props => props.theme.backgroundColorL2};
  border-radius: 50%;
  width: 140px;
  height: 140px;
  display: flex;
  flex-direction: column;
  position: absolute;
`;

const TimerControls = styled('div')`
  background-color: ${props => props.theme.backgroundColorL2};
  padding: 5px;
  display: flex;
  align-content: stretch;
  justify-content: center;
  width: 60px;
  position: absolute;
  bottom: -8px;
  align-self: center;
`;

const PlayButton = styled('button')`
  background-color: white;
  border: 0px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  padding: 0px;
  margin-left: -5px;
`;

const PauseButton = styled('button')`
  background-color: ${props => props.theme.backgroundColorL2};
  border: 0px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  padding: 0px;
  margin-left: -5px;
`;

const StopButton = styled('button')`
  background-color: white;
  border: 0px;
  cursor: pointer;
  height: 10px;
  width: 10px;
  display: block;
  padding: 0px;
  margin-top: 6px;
  margin-left: 10px;
  border: 1px solid black;
`;

const Time = styled('span')`
  font-size: 56px;
  margin-top: 20px;
  color: ${props => props.theme.textColor};
  align-self: center;
`;

const Text = styled('p')`  
  font-size: 14px;
  font-weight: 800;
  color: ${props => props.theme.textColor};
  text-align: center;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin-top: -10px;
`;

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      seconds: props.seconds,
      secondsLeft: props.seconds,
      percentage: 80,
    };
    this.tick = this.tick.bind(this);
    this.startCountDown = this.startCountDown.bind(this);
    this.pauseCountDown = this.pauseCountDown.bind(this);
    this.stopCountDown = this.stopCountDown.bind(this);
  }

  componentDidMount() {
    const { autoplay } = this.props;
    if (autoplay) {
      this.setState({
        active: true,
      });
      this.intervalHandle = setInterval(this.tick, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
    this.setState({
      active: false,
    });
  }

  tick() {
    const { seconds, secondsLeft } = this.state;
    const { nextButton } = this.props;

    const secsLeft = (secondsLeft - 1);
    const perc = ((secsLeft / seconds) * 80);

    if (secondsLeft === 0) {
      clearInterval(this.intervalHandle);
      this.setState({
        active: false,
      });
      nextButton();
    } else {
      this.setState({
        secondsLeft: secsLeft,
        percentage: perc,
      });
    }
  }

  startCountDown() {
    this.intervalHandle = setInterval(this.tick, 1000);
    this.setState({
      active: true,
    });
  }

  pauseCountDown() {
    clearInterval(this.intervalHandle);
    this.setState({
      active: false,
    });
  }

  stopCountDown() {
    const { seconds } = this.state;
    clearInterval(this.intervalHandle);
    this.setState({
      active: false,
      secondsLeft: seconds,
      percentage: 80,
    });
  }

  render() {
    const { active, secondsLeft, percentage } = this.state;
    const { autoplay } = this.props;

    const isComplete = secondsLeft === 0;

    return (
      <TimerWrapper id='timer_wrapper'>
        <CircularProgressbar
          percentage={percentage}
          strokeWidth={50}
          textForPercentage={null}
          styles={{
            path: { strokeLinecap: 'butt', fill: ColorGreen },
            text: { fill: ColorGreen },
          }}
        />
        <TimerContainer id='timer_container'>
          <Time>
            {isComplete ? (
              <IcomoonReact
                iconSet={iconSet}
                color={ColorGreen}
                size={40}
                icon='checkmark'
              />
            ) : (
              secondsLeft
            )}
          </Time>
          <Text>
            {isComplete ? (
              'Complete'
            ) : (
              <Fragment>
                <Fragment>
                  {active && (
                    'Active'
                  )}
                </Fragment>
                <Fragment>
                  {!active && (
                    'Paused'
                  )}
                </Fragment>
              </Fragment>
            )}
            
          </Text>
          <TimerControls>
            { active ? (
              <PauseButton
                onClick={this.pauseCountDown}
              >
                <IconRound
                  icon='pause'
                  noBorder
                />
              </PauseButton>
            ) : (
              <PlayButton
                onClick={this.startCountDown}
              >
                <IconRound
                  icon='play'
                  noBorder
                />
              </PlayButton>
            )}

            <StopButton
              onClick={this.stopCountDown}
            />
          </TimerControls>
        </TimerContainer>
      </TimerWrapper>
    );
  }
}

export default Timer;


// constructor(props) {
//   super(props);
//   this.state = {
//     seconds: '00', 
//     minutes: ''
//   }
//   this.secondsRemaining; 
//   this.intervalHandle;
//   this.handleChange = this.handleChange.bind(this);
//   // method that triggers the countdown functionality
//   this.startCountDown = this.startCountDown.bind(this);
//   this.tick = this.tick.bind(this);
// }
// handleChange(event) {
//   this.setState({
//     minutes: event.target.value
//   })
// }
// tick() {
//   var min = Math.floor(this.secondsRemaining / 60);
//   var sec = this.secondsRemaining - (min * 60);
//   this.setState({
//     minutes: min,
//     seconds: sec
//   })
//   if (sec < 10) {
//     this.setState({
//       seconds: "0" + this.state.seconds,
//     })
//   }
//   if (min < 10) {
//     this.setState({
//       value: "0" + min,
//     })
//   }
//   if (min === 0 & sec === 0) {
//     clearInterval(this.intervalHandle);
//   }
//   this.secondsRemaining--
// }

// startCountDown() {
//   this.intervalHandle = setInterval(this.tick, 1000);
//   let time = this.state.minutes;
//   this.secondsRemaining = time * 60;
// }

// 1. Set Interval for every 1 second, run the tick function.

// 2. Create a Tick Function that reduces the seconds state by 1. If it gets to 0 stop the interval.

// 3. Calculate percentage ( ( Time Remaining / Provided Time ) * 100 )

// 3. Circular Progression is updated with percentage and text with seconds.