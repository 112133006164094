import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring';
import ResizeObserver from 'react-resize-observer';
import Shimmer from 'react-shimmer-effect';

import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Text from '../../../shared/components/Text/Text';
import SubText from '../../../shared/components/SubText/SubText';
import Avatar from '../../../shared/components/Avatar/Avatar';
import Button from '../../../shared/components/Button/Button';
import WeightRoomImage from '../../../shared/images/weightroom-white-transparent.png';
import { fetchProgram, sharedWorkoutModal, setDownloadButtonClicked } from '../ducks/sharedWorkoutActions';
import SharedWorkoutNotFound from './SharedWorkoutNotFound';

const Container = styled('div')`
  display: flex;
  width: 700px;
  padding-top: 100px;
  gap: 20px;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  .shimmer-div {
    display: grid;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const DetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 768px) {
    text-align: center;
    padding: 0 15px;
    > div {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
      color: white;
    }
    padding-bottom: 0px;
  }
`;

const ImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-right: 25px;
  img {
    width: 300px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ImageShimmerDiv = styled('div')`
  height: 100px;
  width: 300px;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NameContainer = styled('div')`
  h1 {
    text-transform: uppercase;
    font-size: 26px;
  }
`;

const CoachDetails = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 25px 0;
  span {
    text-transform: capitalize;
    letter-spacing: 1px!important;
    &.trial {
      text-transform: uppercase;
    }
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

const AvatarContainer = styled('div')`
  background: ${(props) => props.accountColorPrimary};
  border-radius: 50%;
  padding: 2px;
`;

const CoachName = styled('div')`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  .sub-text {
    text-transform: uppercase;
  }
`;

const DescriptionContainer = styled('div')`
  flex: 6;
  overflow: hidden;
  button {
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    &:hover {
      color: orange;
      transition: all 300ms ease-in-out;
    }
  }

  .read-more {
    margin-top: 15px;
    color: #444444;
  }

  p {
    white-space: pre-wrap;
  }
`;

const DescriptionWrapper = styled('div')`
  height: auto;
`;

const DescriptionShimmerDiv = styled('div')`
  height: 100px;
  border-radius: 5px;
  margin-top: -5px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px 40px 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 35px;
  .button-download {
    background: #10cd8c;
    color: #fff;
    font-size: 16px;
    padding: 12px 30px;
    border-radius: 3px;
  }
  .button-shimmer {
    font-size: 16px;
    color: transparent;
    padding: 12px 30px;
    border: none;
    border-radius: 3px;
  }
`;

const SharedProgramDetails = () => {
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  const [textDimensions, setTextDimensions] = useState();

  const programData = useSelector((state) => state.sharedWorkout.data.programData);
  const isProgramDataLoaded = useSelector((state) => state.sharedWorkout.data.isProgramDataLoaded);
  const isLoadingProgram = useSelector((state) => state.sharedWorkout.ui.isLoadingProgram);
  const isAuthenticated = useSelector((state) => state.auth.ui.isAuthenticated);
  const readMoreTransition = useSpring({ height: isTextExpanded ? textDimensions.height : 145, paddingBottom: 30, overflow: 'hidden' });
  const isDescriptionTooSmall = textDimensions && textDimensions.height <= 145;

  const { programSlug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProgram(programSlug));
  }, [location]);

  const downloadHandler = () => {
    dispatch(setDownloadButtonClicked(true));
    if (isAuthenticated) {
      dispatch(sharedWorkoutModal('download'));
    } else {
      dispatch(sharedWorkoutModal('login'));
    }
  };

  return (
    <>
      { isLoadingProgram ? (
        <>
          <Container>
            <div className='shimmer-div'>
              <Shimmer>
                <ImageShimmerDiv style={{ marginTop: 50 }} />
                <ImageShimmerDiv style={{ marginTop: -110 }} />
              </Shimmer>
            </div>
            <DetailsContainer>
              <NameContainer>
                <Shimmer>
                  <HeaderText style={{
                    height: 50,
                    width: 200,
                    borderRadius: 5,
                  }}
                  />
                </Shimmer>
                <CoachDetails>
                  <CoachName>
                    <Shimmer>
                      <SubText style={{
                        height: 20,
                        width: 125,
                        borderRadius: 5,
                        marginBottom: 5,
                        marginLeft: 40,
                      }}
                      />
                      <SubText style={{
                        height: 20,
                        width: 125,
                        borderRadius: 5,
                        marginLeft: 40,
                      }}
                      />
                    </Shimmer>
                  </CoachName>
                </CoachDetails>
              </NameContainer>
              <Shimmer>
                <DescriptionShimmerDiv style={{ marginBottom: -10 }} />
                <DescriptionShimmerDiv />
              </Shimmer>
            </DetailsContainer>
          </Container>
          <ButtonContainer style={{ marginTop: 35 }}>
            <Shimmer>
              <Button className='button-shimmer' cta='Download' />
            </Shimmer>
          </ButtonContainer>
        </>
      ) : (
        <>
          { !isProgramDataLoaded ? (
            <SharedWorkoutNotFound />
          ) : (
            <>
              <Container>
                <div>
                  <ImageWrapper>
                    <img src={WeightRoomImage} alt='Weight Room' />
                  </ImageWrapper>
                </div>
                <DetailsContainer>
                  <NameContainer>
                    <HeaderText>
                      <h1>{programData.title}</h1>
                    </HeaderText>
                    <CoachDetails>
                      <AvatarContainer>
                        <Avatar
                          avatar={programData.createdByUserPic}
                          size={40}
                        />
                      </AvatarContainer>
                      <CoachName>
                        <SubText>{programData.createdByUserName}</SubText>
                        <SubText
                          className='sub-text'
                          fontWeight={400}
                          fontSize={13}
                        >
                          {programData.createdByOrganizationName}
                        </SubText>
                      </CoachName>
                    </CoachDetails>
                  </NameContainer>
                  <DescriptionContainer>
                    <animated.div style={readMoreTransition}>
                      <DescriptionWrapper>
                        <SubText>About</SubText>
                        <Text>
                          {programData.description !== '' ? programData.description : 'A description for this workout does not exist.'}
                        </Text>
                        <ResizeObserver
                          onResize={(rect) => {
                            setTextDimensions(rect);
                          }}
                        />
                      </DescriptionWrapper>
                    </animated.div>
                    {!isDescriptionTooSmall && (
                      <button
                        type='button'
                        className='read-more'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsTextExpanded(!isTextExpanded && true);
                        }}
                        style={{ fontSize: 14 }}
                      >
                        { isTextExpanded ? 'Read Less' : 'Read More' }
                      </button>
                    )}
                  </DescriptionContainer>
                </DetailsContainer>
              </Container>
              <ButtonContainer>
                <Button
                  className='button-download'
                  complete
                  cta='Download'
                  onClick={downloadHandler}
                />
              </ButtonContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SharedProgramDetails;
