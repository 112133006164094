/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import PropTypes from 'prop-types';
import { clearReport, setActiveReport } from '../ducks/reportingActions';
import Button from '../../../shared/components/Button/Button';
import useMaxReportModal from './hooks/useMaxReportModal';
import { reportNavEditTracker } from '../../../shared/utils/amplitudeHelper';

const ActiveSidebarContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  button {
    background: transparent;
    margin-bottom: 15px;
    svg {
      path {
        fill: rgb(68, 68, 68);
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: row;
    padding: 0px;

    button {
      margin-bottom: 0px;
      margin-left: 16px;
      &.download {
        margin-right: ${(props) => (props.print ? '0px' : '16px')}
      }
      &.print {
        margin-right: ${(props) => (props.print ? '16px' : '0px')}
      }
    }
  }
`;

const TopButtonContainer = styled('div')`
  display: flex;
  justify-self: flex-start;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 50%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .arrow-button {
    transform: rotate(-90deg);
  }
  @media screen and (min-width: 768px) {
    svg {
      height: 22px;
      width: 22px;
    }
  }
`;

const ActiveReportSidebar = ({ edit, print, handlePrint }) => {
  const { handleSetIsModalOpen } = useMaxReportModal();

  const dispatch = useDispatch();

  const activeReport = useSelector((state) => state.reporting.ui.activeReport);

  const handleClearReport = () => {
    dispatch(clearReport());
  };

  const handleFilterClick = () => {
    if (activeReport === 'max') {
      handleSetIsModalOpen(true, 'main');
      // Amplitude tracking
      reportNavEditTracker('edit', 'Max Report');
    }
    if (activeReport === 'questionnaire') {
      handleSetIsModalOpen(true, 'questionnaire');
      // Amplitude tracking
      reportNavEditTracker('edit', 'Questionnaire Report');
    }
    if (activeReport === 'workout_results') {
      handleSetIsModalOpen(true, 'workoutResultsReport');
      // Amplitude tracking
      reportNavEditTracker('edit', 'Workout Results Report');
    }
    if (activeReport === 'opt_out_notes') {
      handleSetIsModalOpen(true, 'optOutNotesReport');
      // Amplitude tracking
      reportNavEditTracker('edit', 'Opt Out Notes Report');
    }
    if (activeReport === 'comparison') {
      handleSetIsModalOpen(true, 'comparison');
      // Amplitude tracking
      reportNavEditTracker('edit', 'Comparison Report');
    }
  };

  const handleBackClick = () => {
    // Amplitude tracking
    switch (activeReport) {
      case 'activity':
        reportNavEditTracker('back', 'Activity Report');
        break;
      case 'evaluation':
        reportNavEditTracker('back', 'Evaluation Report');
        break;
      case 'max':
        reportNavEditTracker('back', 'Max Report');
        break;
      case 'opt_out_notes':
        reportNavEditTracker('back', 'Opt Out Notes Report');
        break;
      case 'questionnaire':
        reportNavEditTracker('back', 'Questionnaire Report');
        break;
      default:
        reportNavEditTracker('back', 'Workout Results Report');
    }
    // Since there is no modal for the activity report selection,
    // simply clear the report if the filter icon is clicked
    dispatch(setActiveReport(''));
    handleClearReport();
  };

  const handleDownloadClick = () => {
    handleSetIsModalOpen(
      true,
      activeReport === 'activity'
        ? 'downloadActivityReport'
        : activeReport === 'questionnaire'
          ? 'downloadQuestionnaireReport'
          : activeReport === 'evaluation'
            ? 'downloadEvaluationReport'
            : activeReport === 'opt_out_notes'
              ? 'downloadOptOutNotesReport'
              : activeReport === 'workout_results'
                ? 'downloadResultsReport'
                : activeReport === 'comparison'
                  ? 'downloadComparisonReport'
                  : 'downloadMaxReport',
    );
  };

  const handlePrintClick = () => {
    if (activeReport === 'comparison') {
      handlePrint();
    }
    // Amplitude tracker.
    reportInteractionTracker('Print Clicked', activeReport);
  };

  return (
    <ActiveSidebarContainer print={print}>
      <TopButtonContainer>
        <Button
          className='arrow-button'
          icon='alt-up-arrow'
          iconOnly
          square
          onClick={handleBackClick}
        />
        {edit && (
          <Button icon='pencil' iconOnly square onClick={handleFilterClick} />
        )}
        <Button
          className='download'
          icon='download'
          iconOnly
          square
          onClick={handleDownloadClick}
        />
        {print && (
          <Button
            className='print'
            icon='printer'
            iconOnly
            square
            onClick={handlePrintClick}
          />
        )}
      </TopButtonContainer>
    </ActiveSidebarContainer>
  );
};

ActiveReportSidebar.propTypes = {
  edit: PropTypes.bool,
  print: PropTypes.bool,
  handlePrint: PropTypes.func,
};

ActiveReportSidebar.defaultProps = {
  edit: false,
  print: false,
  handlePrint: () => {},
};

export default ActiveReportSidebar;
