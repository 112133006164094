/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring/web.cjs';
import styled from '@emotion/styled';
import Delayed from 'react-delayed';

import useMeasure from '../hooks/useMeasure';
import WeightroomWorkoutEntryView from '../container/WeightroomWorkoutEntryView';

const EntryViewWrapper = styled('div')`
  display: block;
`;

const AnimatedEntryView = ({
  entriesContainerRef,
  entryRowRef,
  formikRef,
  index,
  isWorkoutActive,
  namespace,
  nextButton,
  questionListRef,
  scrollQuestions,
  supersetIndex,
}) => {
  const [bind, { height: viewHeight }] = useMeasure();

  const props = useSpring({
    to: { height: isWorkoutActive ? viewHeight : 0 },
    from: { height: 0 },
  });

  return (
    <animated.div
      style={{
        ...props,
        marginTop: '-2px',
        marginLeft: '-9px',
        overflow: 'hidden',
      }}
    >
      <EntryViewWrapper
        {...bind}
      >
        <Delayed mounted={isWorkoutActive} mountAfter={0} unmountAfter={800}>
          <WeightroomWorkoutEntryView
            entriesContainerRef={entriesContainerRef}
            entryRowRef={entryRowRef}
            formikRef={formikRef}
            index={index}
            isWorkoutActive={isWorkoutActive}
            namespace={namespace}
            nextButton={nextButton}
            scienceRef={questionListRef}
            scrollQuestions={scrollQuestions}
            supersetIndex={supersetIndex}
          />
        </Delayed>
      </EntryViewWrapper>
    </animated.div>
  );
};

AnimatedEntryView.propTypes = {
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  isWorkoutActive: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  nextButton: PropTypes.func.isRequired,
  questionListRef: PropTypes.instanceOf(Object),
  scrollQuestions: PropTypes.func.isRequired,
  supersetIndex: PropTypes.number,
};

AnimatedEntryView.defaultProps = {
  questionListRef: {},
  supersetIndex: null,
};

export default AnimatedEntryView;
