import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const OptionWrapper = styled('div')`
  /* padding: 0px 20px;
  width: 290px; */

  select {
    width: 100%;
  }
`;

const OptionField = ({
  handleChange,
  isKeyValuePair,
  name,
  options,
  selectId,
  values,
}) => (
  <OptionWrapper>
    <select
      name={name}
      onChange={handleChange}
      selectId={selectId}
      value={values}
    >
      {options.map((option, i) => (
        <option value={option.value}>{isKeyValuePair ? option.label : option}</option>
      ))}
    </select>
  </OptionWrapper>
);

OptionField.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
};

export default OptionField;
