/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import Webcam from 'react-webcam';
import ReactPlayer from 'react-player';

import Spinner from '../../../../shared/components/Spinner/Spinner';
import Text from '../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import { setActiveReduxModal } from '../../ducks/conversationsActions';

const WebcamContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 26px;
    font-weight: 300;
  }
  .capture-button {
    width: 180px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const SpinnerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 395px;
  `;

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};

const WebcamCapture = ({
  handleUploadMedia,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [isCaptured, setIsCaptured] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const uploadMedia = useSelector((state) => state.conversations.data.uploadMedia);

  const handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      setIsCaptured(true);
      setCapturing(false);
      const file = new File([data], 'mkv', {
        type: 'video/mkv',
      });
      const fileArray = [file];
      const type = fileArray[0].type.split('/').shift();
      handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile: fileArray, type });
    }
  };

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream);
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const buttonMasher = () => {
    if (!isCaptured) {
      if (capturing) {
        return (
          <NoHoverButton
            className='modal-button'
            onClick={handleStopCaptureClick}
            cta='Stop Capture'
            customColor='#10cd8c'
            noHover
            noBorder
            bottom
            fullWidth
            large
            primary
            square
          />
        );
      }
      return (
        <NoHoverButton
          className='modal-button'
          onClick={handleStartCaptureClick}
          cta='Start Capture'
          customColor='#10cd8c'
          noHover
          noBorder
          bottom
          fullWidth
          large
          primary
          square
        />
      );
    }
    return (
      <NoHoverButton
        bottom
        fullWidth
        cta='Done'
        customColor='#10cd8c'
        className='modal-button'
        onClick={() => dispatch(setActiveReduxModal(''))}
        large
        noBorder
        primary
        square
        noHover
      />
    );
  };

  const playerMasher = () => {
    if (isCaptured) {
      if (uploadMedia && uploadMedia.mediaUrl) {
        return (
          <ReactPlayer
            url={uploadMedia.mediaUrl}
            controls
            width={700}
            height={395}
          />
        );
      }
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }
    return (
      <Webcam
        width={700}
        height={395}
        ref={webcamRef}
        videoConstraints={videoConstraints}
      />
    );
  };

  return (
    <WebcamContainer>
      {playerMasher()}
      {buttonMasher()}
    </WebcamContainer>
  );
};

WebcamCapture.propTypes = {
  handleUploadMedia: PropTypes.func.isRequired,
};

export default WebcamCapture;
