import { useDispatch } from 'react-redux';
import {
  fetchNotifications,
  setActiveNotification,
  markNotificationsAsRead,
  deleteNotifications,
  updateNotification,
  fetchNotificationTypes,
} from '../../ducks/notificationsActions';

const useNotifications = () => {
  const dispatch = useDispatch();

  const handleFetchNotifications = (accountCode, refreshAll) => {
    dispatch(fetchNotifications(accountCode, refreshAll));
  };

  const handleSetActiveNotification = (notification) => {
    dispatch(setActiveNotification(notification));
  };

  const handleMarkNotificationsAsRead = (accountCode, notificationIds) => {
    dispatch(markNotificationsAsRead(accountCode, notificationIds));
  };

  const handleDeleteNotifications = (accountCode, notificationIds) => {
    dispatch(deleteNotifications(accountCode, notificationIds));
  };

  const handleUpdateNotification = (accountCode, notificationId, updates) => {
    dispatch(updateNotification(accountCode, notificationId, updates));
  };

  const handleFetchNotificationTypes = () => {
    dispatch(fetchNotificationTypes());
  };

  return {
    handleFetchNotifications,
    handleSetActiveNotification,
    handleMarkNotificationsAsRead,
    handleDeleteNotifications,
    handleUpdateNotification,
    handleFetchNotificationTypes,
  };
};

export default useNotifications;
