import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PanelWorkouts from '../presentational/PanelWorkouts';
import {
  closePanel,
  closeWorkout,
} from '../../ducks/panelActions';

const WeightroomPanelWorkouts = (ownProps) => {
  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);
  const isLoading = useSelector((state) => state.weightRoomView[ownProps.namespace].isLoading);
  const isWorkoutComplete = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].isWorkoutComplete);

  const dispatch = useDispatch();

  const clsPanel = (namespace) => {
    dispatch(closePanel(namespace));
  };
  const clsWorkout = (namespace) => {
    dispatch(closeWorkout(namespace));
  };

  return (
    <PanelWorkouts
      activeScreens={activeScreens}
      isLoading={isLoading}
      isWorkoutComplete={isWorkoutComplete}
      closePanel={clsPanel}
      closeWorkout={clsWorkout}
      namespace={ownProps.namespace}
    />
  );
};

export default WeightroomPanelWorkouts;
