/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import {
  TransitionGlobal,
} from '../../../../shared/GlobalStyles';

const BillingPanel = styled('div')`
  width: 100%;
  background: blue;
  min-width: 650px;
`;

const BillingDashboard = () => <BillingPanel>Billing Dashboard</BillingPanel>;

BillingDashboard.propTypes = {

};

export default BillingDashboard;
