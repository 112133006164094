// A component that contains the left, right, and center columns that separate
// the different sections of the workout entry screen
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import SubscriptionPanel from './SubscriptionPanel';
import BillingDashboard from './BillingDashboard';

const Wrapper = styled('div')`
	display: flex;
  flex-direction: column;
	width: 90%;
  max-width: 1550px;
`;

const BillingDashboardWrapper = () => (
  <Wrapper>
    <SubscriptionPanel />
    <BillingDashboard />
  </Wrapper>
);

BillingDashboardWrapper.propTypes = { };

export default BillingDashboardWrapper;
