import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import PropTypes from 'prop-types';

import { setScheduledWorkoutFilter } from '../../ducks/reportingActions';
import ScheduledWorkoutsChartLegend from './ScheduledWorkoutsChartLegend';

const Outer = styled('div')`
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    min-height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    @media screen and (max-width : 1224px) {
      margin-top: 30px;
      margin-left: 0px;
      width: 100%;
    }
    @media screen and (min-width : 1225px) {
      margin-top: 0px;
      margin-left: 50px;
    }
`;

const EmptyDiv = styled('div')`
`;

const TitleContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const Title = styled('div')`
    padding-top: 15px;
    font-size: 14px;
    font-weight: 900;
    font-family: Nunito Sans;
    letter-spacing: 1.18px;
`;

const Description = styled('div')`
    font-size: 12px;
    padding-top: 5px;
    text-align: start;
`;

const ChartContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const CustomLabel = styled('div')`
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.08);
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const LabelText = styled('div')`
    font-family: Nunito Sans;
    font-size: 18px;
    font-weight: bold;
`;

const ScheduledWorkoutsPieChart = ({
  lastScheduledWorkoutBreakdown,
}) => {
  const dispatch = useDispatch();
  const chartData = [];
  const colors = [];

  Object.keys(lastScheduledWorkoutBreakdown).forEach((key) => {
    chartData.push({
      name: key,
      value: lastScheduledWorkoutBreakdown[key].count,
    });
    colors.push(lastScheduledWorkoutBreakdown[key].color);
  });

  const handleClick = (chartSector) => {
    dispatch(setScheduledWorkoutFilter(chartSector.name));
  };

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, value,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value !== 0) {
      return (
        <foreignObject style={{ pointerEvents: 'none' }} x={x} y={y} width='50' height='50'>
          <CustomLabel>
            <LabelText>
              {value}
            </LabelText>
          </CustomLabel>
        </foreignObject>
      );
    }
  };

  return (
    <Outer>
      <TitleContainer>
        <Title>LAST SCHEDULED WORKOUTS</Title>
        <Description>
          Chart of last scheduled workout for selected athletes into four date ranges. Click a section of the chart to filter table below.
        </Description>
      </TitleContainer>
      <ChartContainer>
        <PieChart cursor='pointer' width={200} height={250}>
          <Pie
            data={chartData}
            innerRadius={60}
            outerRadius={90}
            fill='#8884d8'
            dataKey='value'
            label={renderCustomizedLabel}
            labelLine={false}
            onClick={handleClick}
          >
            {
        chartData.map((entry, index) => <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />)
      }
          </Pie>
        </PieChart>
        <ScheduledWorkoutsChartLegend />
      </ChartContainer>
      <EmptyDiv />
    </Outer>
  );
};

ScheduledWorkoutsPieChart.propTypes = {
  lastScheduledWorkoutBreakdown: PropTypes.instanceOf(Object).isRequired,
};

export default ScheduledWorkoutsPieChart;
