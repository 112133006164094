import { useDispatch, useSelector } from 'react-redux';

import {
  fetchSelections,
  searchSelection,
} from '../../ducks/reportingActions';

const useReporting = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const filterSelections = useSelector((state) => state.reporting.data.filterSelections);
  const selectionSearch = useSelector((state) => state.reporting.data.searchSelection);

  const dispatch = useDispatch();

  const handleFetchSelections = (accountCode, selectionType) => {
    dispatch(fetchSelections(accountCode, selectionType));
  };

  const handleSearchSelections = (values) => {
    dispatch(searchSelection(values));
  };

  return {
    currentUser,
    filterSelections,
    handleFetchSelections,
    handleSearchSelections,
    selectionSearch,
  };
};

export default useReporting;
