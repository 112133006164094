export const FETCH_PROFILE_START = 'FETCH_PROFILE_START';
export const FETCH_PROFILE_FULFILLED = 'FETCH_PROFILE_FULFILLED';
export const FETCH_PROFILE_REJECTED = 'FETCH_PROFILE_REJECTED';

export const FETCH_LATEST_SESSION_START = 'FETCH_LATEST_SESSION_START';
export const FETCH_LATEST_SESSION_FULFILLED = 'FETCH_LATEST_SESSION_FULFILLED';
export const FETCH_LATEST_SESSION_REJECTED = 'FETCH_LATEST_SESSION_REJECTED';

export const FETCH_MULTIDAY_SESSION_START = 'FETCH_MULTIDAY_SESSION_START';
export const FETCH_MULTIDAY_SESSION_FULFILLED = 'FETCH_MULTIDAY_SESSION_FULFILLED';
export const FETCH_MULTIDAY_SESSION_REJECTED = 'FETCH_MULTIDAY_SESSION_REJECTED';

export const FETCH_SESSIONS_START = 'FETCH_SESSIONS_START';
export const FETCH_SESSIONS_FULFILLED = 'FETCH_SESSIONS_FULFILLED';
export const FETCH_SESSIONS_REJECTED = 'FETCH_SESSIONS_REJECTED';

// export const UPDATE_NOTIFICATION_SETTINGS_START = 'UPDATE_NOTIFICATION_SETTINGS_START';
// export const UPDATE_NOTIFICATION_SETTINGS_FULFILLED = 'UPDATE_NOTIFICATION_SETTINGS_FULFILLED';
// export const UPDATE_NOTIFICATION_SETTINGS_REJECTED = 'UPDATE_NOTIFICATION_SETTINGS_REJECTED';

// export const FETCH_ORGANIZATION_SETTINGS_START = 'FETCH_ORGANIZATION_SETTINGS_START';
// export const FETCH_ORGANIZATION_SETTINGS_FULFILLED = 'FETCH_ORGANIZATION_SETTINGS_FULFILLED';
// export const FETCH_ORGANIZATION_SETTINGS_REJECTED = 'FETCH_ORGANIZATION_SETTINGS_REJECTED';

// export const UPDATE_ORGANIZATION_SETTINGS_START = 'UPDATE_ORGANIZATION_SETTINGS_START';
// export const UPDATE_ORGANIZATION_SETTINGS_FULFILLED = 'UPDATE_ORGANIZATION_SETTINGS_FULFILLED';
// export const UPDATE_ORGANIZATION_SETTINGS_REJECTED = 'UPDATE_ORGANIZATION_SETTINGS_REJECTED';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

export const UPDATE_PROFILE_IMAGE_START = 'UPDATE_PROFILE_IMAGE_START';
export const UPDATE_PROFILE_IMAGE_FULFILLED = 'UPDATE_PROFILE_IMAGE_FULFILLED';
export const UPDATE_PROFILE_IMAGE_FAILED = 'UPDATE_PROFILE_IMAGE_FAILED';

export const SET_SUB_ACTIVE_PROFILE_PIC_WEBCAM_CATURE_MODAL = 'SET_SUB_ACTIVE_REDUX_MODAL';
export const IS_SKETCH_PICKER_POPUP_OPEN = 'IS_SKETCH_PICKER_POPUP_OPEN';
