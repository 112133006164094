import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Gutter } from '../../../../shared/GlobalStyles';
import ButtonRound from './ButtonRound';

import Title from './Title';

export const ModalContentWrapper = styled('div')`
  padding: 40px;
  flex-grow: 1;
  background-color: ${(props) => props.theme.backgroundColorL2};

  p {
    color: ${(props) => props.theme.textColor}
  }
  form {
    padding-bottom: 20px;
    label {
      color: ${props => props.theme.textColor};
    }
    input, textarea {
      background-color: ${props => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${props => props.theme.backgroundColorL3};
    color: ${props => props.theme.textColor};
    border-color: ${props => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${props => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .form-group {
    padding: ${Gutter} calc(${Gutter} * 3);
  }
  .form-control {
    min-height: 150px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    .form-group {
      padding: 20px 15px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
`;

const ModalNote = ({
  athleteId,
  exerName,
  fetchJournal,
  index,
  modalClose,
  namespace,
  submitJournal,
  updateJournal,
  workId,
  workout,
}) => {
  const hasJournalEntry = workout.result && workout.result.opt_text !== '';

  useEffect(() => {
    if (hasJournalEntry) {
      fetchJournal(index, namespace, workId, athleteId);
    }
  }, []);

  return (
    <Formik
      initialValues={{
        entry_title: exerName,
        entry_text: hasJournalEntry ? (
          workout.result.opt_text
        ) : (''),
        user_id: athleteId,
        workout_id: workId,
      }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          if (hasJournalEntry) {
            updateJournal(index, namespace, values, workout.journalEntry.id);
          } else {
            submitJournal(index, namespace, values);
          }
          actions.setSubmitting(false);
          modalClose();
        }, 1000);
      }}
      render={props => (
        <ModalContentWrapper>
          <Form>
            <Title
              icon='note'
            >
              Add Note
            </Title>
            <div className='form-group'>
              <label>Note</label>
              <textarea
                // disabled={hasJournalEntry}
                onChange={props.handleChange}
                name='entry_text'
                className='form-control'
                value={props.values.entry_text}
              />
            </div>
            {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
            <ButtonRound
              bottom
              fullWidth
              cta={hasJournalEntry ? 'Update' : 'Submit'}
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={props.onSubmit}
            />
          </Form>
        </ModalContentWrapper>
      )}
    />
  );
};

ModalNote.propTypes = {
  athleteId: PropTypes.number.isRequired,
  exerName: PropTypes.string.isRequired,
  fetchJournal: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  modalClose: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitJournal: PropTypes.func.isRequired,
  updateJournal: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  workId: PropTypes.number.isRequired,
  workout: PropTypes.string.isRequired,
};

export default ModalNote;
