import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import RestTimer from './RestTimer';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
	flex-direction: column;
  background: white;
  padding-top: 20px;
	margin-top: 20px;
`;

const TimerCard = ({ workoutObject }) => (
  <Container>
    <RestTimer time={workoutObject.timerDurationInSeconds} />
  </Container>
);

TimerCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default TimerCard;
