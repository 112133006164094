import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from '@emotion/styled';
import Select from 'react-select';
import ReportDatePicker from './ReportDatePicker';

const selectBoxOptions = [
  {
    id: 1,
    label: 'All Dates',
  },
  {
    id: 2,
    label: '30 Days',
  },
  {
    id: 3,
    label: 'Today',
  },
  {
    id: 4,
    label: 'Custom Range',
  },
];

const SelectDateTypeText = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 12pt;
  color: #444444;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
`;

const OptOutNotesReportFormSlideTwo = ({ formProps }) => {
  const setStartDate = (date) => {
    formProps.setFieldValue('startDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
    formProps.setFieldValue('endDate', {
      date: null,
      queryFormat: '',
    });
  };

  const setEndDate = (date) => {
    formProps.setFieldValue('endDate', {
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
    });
  };

  const customStyles = {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
    menuPortal: (base) => ({
      ...base, zIndex: 9999,
    }),
  };

  return (
    <>
      <SelectDateTypeText>Select Date Type</SelectDateTypeText>
      <Select
        className='item-select'
        options={selectBoxOptions}
        menuPortalTarget={document.body}
        styles={customStyles}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.id}
        onChange={(selectedType) => {
          formProps.setFieldValue('rangeType', selectedType.id);
        }}
      />
      {formProps.values.rangeType === 4 ? (
        <ReportDatePicker
          formProps={formProps}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={formProps.values.startDate.date}
          endDate={formProps.values.endDate.date}
          reportType='multiDay'
        />
      ) : null}
    </>
  );
};

OptOutNotesReportFormSlideTwo.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
};

export default OptOutNotesReportFormSlideTwo;
