import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import Text from '../../shared/components/Text/Text';
import MessagesPanel from './components/presentational/MessagesPanel';
import ConversationsSidebar from './components/presentational/ConversationsSidebar';
import StatusIndicator from './components/presentational/StatusIndicator';
import NotificationPanel from './components/presentational/NotificationPanel';
import SocketHandler from './SocketHandler';

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  background: white;
`;

const ConversationsPanel = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { userSettings } = currentUser;

  const [sidebarSwitchConvos, setSidebarSwitchConvos] = useState(true);

  // If messaging is enabled, default to conversations, otherwise default to notifications.
  // false = notifications, true = conversations
  useEffect(() => {
    if (userSettings?.messaging === 1) {
      setSidebarSwitchConvos(true);
    } else {
      setSidebarSwitchConvos(false);
    }
  }, [userSettings]);

  return (
    <PageWrapper>
      <ConversationsSidebar
        sidebarSwitchConvos={sidebarSwitchConvos}
        setSidebarSwitchConvos={setSidebarSwitchConvos}
      />
      {sidebarSwitchConvos ? (
        <MessagesPanel />
      ) : (
        <NotificationPanel />
      )}
      <SocketHandler />
      <StatusIndicator />
    </PageWrapper>
  );
};

ConversationsPanel.propTypes = { };
export default ConversationsPanel;
