import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';
import styled from '@emotion/styled';

import CustomInputText from './FormikCustomInput';

const EntryTitles = styled('div')`
  display: block;
  min-width: 90px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: center; 
`;

const FieldsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  .form-control {
  }
`;

const FieldsWrapper = styled('div')`
  display: flex;
`;

const FieldsDefault = ({
  exerType,
  exerType2,
  handleChange,
  id,
  isHealth,
  isWeightValuesEnabled,
  labels,
  name,
  sets,
  setList,
  submitForm,
  title,
  weightUnit,
}) => {
  const handleAutoSave = (e) => {
    handleChange(e);
    setTimeout(submitForm, 0);
  };

  // New handleChange to enforce only allowing positive numbers to be input/saved
  const handleChangePositive = (e) => {
    if (Number(e.target.value) < 0) {
      e.target.value = Math.abs(Number(e.target.value));
    }
    handleChange(e);
  };

  return (
    <>
      <div className='entry_row'>
        {exerType !== 'H' && (
          <EntryTitles>
            <div className={`title ${labels ? 'with_label' : ''}`}>
              {title}
            </div>
          </EntryTitles>
        )}

        <FieldsWrapper>
          <FieldsContainer
            className='scrollable-field-container'
            isHealth={isHealth}
          >
            {/* Pretty sure exerType L and T is not possible when isHealth is true */}
            {/* Might be able to remove the form-groups that are not exerType H here */}
            {/* But I don't want to risk breaking something by removing this */}
            {exerType === 'L' || exerType === 'T' ? (
              sets > 0 && (
                setList.map((set, i) => (
                  <div className='form-group' key={i}>
                    {labels && <label>{i + 1}</label>}
                    <FastField
                      className='form-control'
                      name={`result[${i}].${name}${(name === 'power' || name === 'value') ? `[${id}]` : ''}`}
                      onBlur={handleAutoSave}
                      placeholder={`${title === 'weight' ? (isWeightValuesEnabled ? weightUnit : set.suggested_weight === null ? weightUnit : set.suggested_weight) : ''}${title === 'reps' && set.suggested_reps !== null ? set.suggested_reps : ''}${(title === 'reps' && set.assigned_reps === 0) ? 'AMAP' : ''}`}
                      tabIndex={i + 1}
                      type='number'
                    />
                  </div>
                ))
              )
            ) : (
              (sets > 0 && (
                Array(sets).fill().map((set, i) => (
                  <div className='form-group' key={i}>
                    {labels && <label>{i + 1}</label>}
                    <FastField
                      className='form-control'
                      name={`result[${i}].${name}${name === 'value' ? `[${id}]` : ''}`}
                      onChange={handleChange}
                      placeholder={exerType2 === 'T' ? '00' : ''}
                      tabIndex={i + 1}
                      type='number'
                    />
                  </div>
                ))
              ))
            )}
            {exerType === 'H' && (
              <div className='form-group'>
                <FastField
                  className='form-control'
                  component={CustomInputText}
                  name='body.primary1'
                  onChange={handleChangePositive}
                  type='number'
                />
              </div>
            )}
          </FieldsContainer>
        </FieldsWrapper>
      </div>
    </>
  );
};

FieldsDefault.propTypes = {
  exerType: PropTypes.string,
  exerType2: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  isHealth: PropTypes.bool,
  labels: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sets: PropTypes.number.isRequired,
  setList: PropTypes.instanceOf(Object),
  title: PropTypes.string.isRequired,
};

FieldsDefault.defaultProps = {
  exerType: '',
  exerType2: '',
  id: '',
  isHealth: false,
  setList: {},
};

export default memo(FieldsDefault);
