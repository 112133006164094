import React, {
  useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import GoogleLogin from 'react-google-login';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';
import { useLocation } from 'react-router-dom';
import Button from '../../../../../shared/components/Button/Button';
import Checkmark from '../../../../../shared/components/Checkmark/Checkmark';
import Text from '../../../../../shared/components/Text/Text';
import Link from '../../../../../shared/components/Link/Link';
import GoogleIcon from '../../../../../shared/images/google_icon.svg';
import encode from '../../../../../shared/utils/encodeToUrl';
import useLogin from '../../hooks/useLogin';
import Spinner from '../../../../../shared/components/Spinner/Spinner';

import getAllUrlParams from '../../../../../shared/utils/getAllUrlParams';

const FormGroup = styled('div')`
  .divider-line {
    display: flex;
    width: 100%;
    border-top: 2px solid rgb(232,232,232);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  .button {
    margin-bottom: 20px;
  }
  .button-sign-in {
    margin-bottom: 10px;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const DividerContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

const SSOFieldError = styled('div')`
  margin-bottom: 10px;
  font-size: 14px;
  color: #ff6600;
`;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  margin-left: -54px;
  z-index: 0;

  .checkmark {
    display: flex;
    align-self: center;
  }
`;

// const useHeight = ({ on = true /* no value means on */ } = {}) => {
//   const ref = useRef();
//   const [height, set] = useState(0);
//   const heightRef = useRef(height);
//   const [ro] = useState(
//     () => new ResizeObserver((packet) => {
//       if (ref.current && heightRef.current !== ref.current.offsetHeight) {
//         heightRef.current = ref.current.offsetHeight;
//         set(ref.current.offsetHeight);
//       }
//     }),
//   );
//   useLayoutEffect(() => {
//     if (on && ref.current) {
//       set(ref.current.offsetHeight);
//       ro.observe(ref.current, {});
//     }
//     return () => ro.disconnect();
//   }, [on, ref.current]);
//   return [ref, height];
// };

const PasswordSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
  password: Yup.string()
    .required('Password Required'),
});

const EmailSchema = Yup.object().shape({
  username: Yup.string()
    .email('Invalid Email Address')
    .required('Email Required'),
});

const LoginFormSSO = () => {
  const ssoData = useSelector((state) => state.auth.data.ssoData);
  const isSSOCheckLoading = useSelector((state) => state.auth.ui.isSSOCheckLoading);
  const [passwordButtonClicked, setPasswordButtonClicked] = useState(false);
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false);
  const location = useLocation();
  const {
    handleGoogleAuth,
    handleLogin,
    isSSOLoginLoading,
    isLoginLoading,
    ssoLoginError,
    isAuthenticated,
    loginError,
    googleError,
    handleCheckSSO,
    handleLoginSSO,
  } = useLogin();

  useEffect(() => {
    const urlParams = getAllUrlParams(window.location.href, false);
    const values = {
      grant_type: 'sso_token',
      token: urlParams.token,
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
    };
    handleLoginSSO(encode(values));
  }, [location]);

  const formAnimation = useSpring({
    opacity: isSSOLoginLoading || isLoginLoading || isAuthenticated ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isSSOLoginLoading || isLoginLoading ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isAuthenticated ? 1 : 0 });

  const theme = useTheme();

  const handleGoogleResponse = (response) => {
    const values = {
      grant_type: 'google',
      client_id: process.env.CLIENT_ID,
      client_secret: process.env.CLIENT_SECRET,
      tokenid: response.tokenId,
      scope: 'offline_access',
    };

    handleGoogleAuth(encode(values));
  };

  const handleSSOLogin = () => {
    const baseUrl = process.env.NODE_API_URL || `https://${process.env.ENVIRONMENT === 'PRODUCTION' ? '' : 'staging.'}api.teambuildr.com/`;
    window.location.replace(`${baseUrl}login/sso/${ssoData.ssoId}/`);
  };

  const showPasswordField = () => isNextButtonClicked && !isSSOCheckLoading && (passwordButtonClicked || Object.keys(ssoData).length === 0);

  // const [heightRef, height] = useHeight();
  // const slideInStyles = useSpring({
  //   from: { opacity: 0, height: 0 },
  //   to: {
  //     opacity: showPasswordField() ? 1 : 0,
  //     height: showPasswordField() ? height : 0,
  //   },
  // });

  return (
    <Formik
      initialValues={{
        grant_type: 'password',
        username: '',
        password: '',
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        scope: 'offline_access',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          if (showPasswordField()) {
            handleLogin(encode(values));
            // alert(JSON.stringify(encode(values), null, 2));
            setSubmitting(false);
          }
        });
      }}
      validationSchema={showPasswordField() ? PasswordSchema : EmailSchema}
      render={({
        errors,
        touched,
        handleSubmit,
        values,
      }) => (
        <>
          <animated.div style={formAnimation}>
            <Form
              name='login'
              id='login'
            >
              {ssoLoginError && <SSOFieldError className='text-center'>Unable to login with your SSO provider. Please try again or contact support if the issue persists.</SSOFieldError>}
              {ssoLoginError && (
              <FormGroup className='form-group'>
                <label>
                  <Text
                    fontSize={theme.textFontSizes[0]}
                  >
                    Email Address
                  </Text>
                  <Field
                    className='form-control'
                    id='email-field'
                    type='email'
                    name='username'
                    placeholder='Email'
                  />
                </label>
                {errors.username && touched.username && <FieldError className='text-center'>{errors.username}</FieldError>}
              </FormGroup>
              )}
              {ssoLoginError && showPasswordField() ? (
              // <animated.div style={{ ...slideInStyles }}>
                <FormGroup className='form-group'>
                  <label>
                    <Text
                      fontSize={theme.textFontSizes[0]}
                    >
                      Password
                    </Text>
                    <Field
                      autoFocus
                      className='form-control'
                      type='password'
                      name='password'
                      placeholder='Password'
                    />
                  </label>
                  {errors.password && touched.password && <FieldError className='text-center'>{errors.password}</FieldError>}
                  {loginError && <FieldError className='text-center'>Your email address or password is incorrect! Please try again.</FieldError>}
                </FormGroup>
              // </animated.div>
              ) : null}
              {ssoLoginError && (
              <FormGroup className='form-group'>
                {googleError && <FieldError className='text-center'>This google account does not seem to be associated with a TeamBuildr account.</FieldError>}
              </FormGroup>
              )}
              {ssoLoginError
              && (
              <FormGroup className='text-center'>
                {(!isNextButtonClicked || isSSOCheckLoading) && (
                <Button
                  cta='Next'
                  customColor='#ff6600'
                  onClick={() => {
                    setIsNextButtonClicked(true);
                    // username is the email
                    handleCheckSSO(values.username);
                  }}
                  fullWidth
                  large
                  noBorder
                  rounded
                  primary
                  isLoading={!showPasswordField() && isSSOCheckLoading}
                  className='button'
                />
                )}
                {Object.keys(ssoData).length > 0 && !passwordButtonClicked ? (
                  <Button
                    cta={`Sign in with ${ssoData.loginName}`}
                    customColor='#3FBFE1'
                    disabled={false}
                    name='submit'
                    id='submit'
                    onClick={handleSSOLogin}
                    fullWidth
                    large
                    noBorder
                    rounded
                    primary
                    className='button-sign-in'
                  />
                ) : null}
                {Object.keys(ssoData).length > 0 && !passwordButtonClicked ? (
                  <DividerContainer>
                    <div className='divider-line' />
                    <Text style={{ marginBottom: '0px' }} fontWeight='bold'>OR</Text>
                    <div className='divider-line' />

                  </DividerContainer>
                ) : null}
                {isNextButtonClicked && !passwordButtonClicked && Object.keys(ssoData).length > 0 ? (
                  <Button
                    cta='Sign In With Password'
                    customColor='#ff6600'
                    name='submit'
                    id='submit'
                    onClick={() => setPasswordButtonClicked(true)}
                    fullWidth
                    large
                    noBorder
                    rounded
                    primary
                    className='button'
                  />
                ) : null}
                {showPasswordField() ? (
                  <Button
                    cta='Sign In'
                    customColor='#ff6600'
                    disabled={errors.password || errors.username || (showPasswordField() && !values.password)}
                    name='submit'
                    id='submit'
                    onClick={handleSubmit}
                    fullWidth
                    large
                    noBorder
                    rounded
                    primary
                    className='button'
                    isLoading={isLoginLoading}
                  />
                ) : null}
                <GoogleLogin
                  clientId='849504945663-2a20rmtkv13kkrs7idoafi82fvoko52l.apps.googleusercontent.com'
                  buttonText='Login with Google'
                  onSuccess={handleGoogleResponse}
                  className='button'
                  onFailure={handleGoogleResponse}
                  cookiePolicy='single_host_origin'
                  render={(renderProps) => (
                    <Button
                      cta='Login with Google'
                      customColor='#ffffff'
                      customIcon={GoogleIcon}
                      disabled={renderProps.disabled}
                      noHover
                      onClick={renderProps.onClick}
                      fullWidth
                      large
                      rounded
                    />
                  )}
                />
                <Link className='easy-join' allCaps color='lightText.1' href='https://app.teambuildr.com/signup?ej=true'>
                  Have an Easy Join Code? Click Here
                </Link>
                <Link allCaps color='lightText.2' href='https://app.teambuildr.com/forgot'>
                  Forgot Your Password?
                </Link>
              </FormGroup>
              )}

            </Form>
          </animated.div>
          <animated.div style={spinnerAnimation}>
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          </animated.div>
          <animated.div style={completeAnimation}>
            <SpinnerContainer>
              <Checkmark />
            </SpinnerContainer>
          </animated.div>
        </>
      )}
    />
  );
};

export default LoginFormSSO;
