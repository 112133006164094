import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import useMaxReportModal from '../hooks/useMaxReportModal';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import ReportingModal from '../ReportingModal';
import WorkoutResultsReportData from './ReportData';
import { fetchAthletes } from '../../ducks/reportingActions';
import ActiveReportSidebar from '../ActiveReportSidebar';
import ReportAthletePicker from './ReportAthletePicker';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  
  @media screen and (max-width: 540px) {
    padding: 0px 15px;
    margin: 0px;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  min-height: 85px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled('div')`
  font-size: 20px;
  color: #424242;
  text-align: center;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  @media screen and (max-width: 540px) {
    height: 100%;
  }
`;

const HeaderDate = styled('div')`
  padding-right: 17px;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Nunito Sans';
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
  min-width: 200px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const CenterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  @media screen and (max-width: 540px) {
    height: 78px;
  }
`;

const HeaderAndGroups = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Groups = styled('div')`
  display: flex;
  justify-content: center;
`;

const BodyRow = styled('div')`
  display: flex;
  height: 100%;
  overflow: auto;
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  width: 140px;
  height: 45px;
  bottom: 30px;
  left: 50%;
  margin-left: -70px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const Report = ({ resultsReportType }) => {
  const {
    handleSetIsModalOpen,
  } = useMaxReportModal();

  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const wRORReport = useSelector((state) => state.reporting.data.wRORReport);
  const sidebarFilter = useSelector((state) => state.reporting.data.sidebarFilter);
  const reportType = useSelector((state) => state.reporting.ui.reportType);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();

  const [selectedAthlete, setSelectedAthlete] = useState({});
  const [headerDate, setHeaderDate] = useState('');

  useEffect(() => {
    if (wRORReport[0]) {
      setSelectedAthlete(wRORReport[0]);
    }
  }, [wRORReport]);

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      dispatch(fetchAthletes(currentUser.accountCode));
    }
  }, [currentUser]);

  useEffect(() => {
    handleSetIsModalOpen(true, resultsReportType);
  }, []);

  // const scrollToElementWithOffset = (element, offset) => {
  //   const elementPosition = element.getBoundingClientRect().top;
  //   const offsetPosition = elementPosition + offset;

  //   console.log(element);
  //   console.log(elementPosition, offsetPosition);

  //   const bodyRowEl = document.getElementById('body-row-id-reporting');

  //   bodyRowEl.scrollTo({
  //     top: offsetPosition,
  //     behavior: 'smooth',
  //   });
  // };

  // const clickFunction = (athlete) => {
  //   scrollToElementWithOffset(document.getElementById(`${athlete.userId}report`), 0);
  //   setSelectedAthlete(athlete);
  // };

  const clickFunction = (athlete) => {
    document.getElementById(`${athlete.userId}report`)?.scrollIntoView({ behavior: 'smooth' });
    setSelectedAthlete(athlete);
  };

  return (
    <MainContainer
      isReportOpen={isReportOpen || activeModal === 'downloadOptOutNotesReport'}
    >
      <ReportingModal />
      {isReportOpen && (
        <>
          <HeaderRow>
            <CenterHeader>
              {wRORReport.length ? (
                <ReportAthletePicker
                  list={wRORReport.map((user) => user)}
                  visibleContainerItem={selectedAthlete}
                  reduxFunction={clickFunction}
                />
              ) : <div />}
              <HeaderAndGroups>
                <Header>
                  {reportType}
                </Header>
                <Groups>
                  {sidebarFilter.filterIds.map((filterId, idx) => {
                    if (idx !== sidebarFilter.filterIds.length - 1) {
                      if (filterId.name !== undefined) {
                        return `${filterId.name}, `;
                      }
                    }
                    if (filterId.name !== undefined) {
                      return `${filterId.name}`;
                    }
                    return null;
                  })}
                </Groups>
              </HeaderAndGroups>
              <HeaderDate>
                {headerDate}
              </HeaderDate>
            </CenterHeader>
          </HeaderRow>
          <BodyRow>
            <WorkoutResultsReportData
              currentAthlete={selectedAthlete}
              setCurrentAthlete={setSelectedAthlete}
              setHeaderDate={setHeaderDate}
            />
          </BodyRow>
          <MobileActiveMenu>
            <ActiveReportSidebar />
          </MobileActiveMenu>
        </>
      )}
    </MainContainer>
  );
};

export default Report;
