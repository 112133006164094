/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */
import {
  FETCH_ATHLETE_REPORT_CARD_START,
  FETCH_ATHLETE_REPORT_CARD_FULFILLED,
  FETCH_ATHLETE_REPORT_CARD_REJECTED,
  FETCH_UNSUBSCRIBE_START,
  FETCH_UNSUBSCRIBE_FULFILLED,
  FETCH_UNSUBSCRIBE_REJECTED,
  FETCH_REPORT_CARD_LINK_START,
  FETCH_REPORT_CARD_LINK_FULFILLED,
  FETCH_REPORT_CARD_LINK_REJECTED,
  SET_IS_SHARE_MODAL_OPEN,
} from './actionTypes';

import { axiosAuthed, axiosUnauthed } from '../../../shared/utils/setCommonHeaders';

export const fetchAthleteReportCard = (
  accountCode,
  userId,
  startDate = null,
  endDate = null,
) => (dispatch) => {
  dispatch({ type: FETCH_ATHLETE_REPORT_CARD_START });
  axiosAuthed
    .get(
      `/accounts/${accountCode}/reports/report-card?userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ATHLETE_REPORT_CARD_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ATHLETE_REPORT_CARD_REJECTED,
      });
    });
};

export const getReportCardLink = (
  accountCode,
  userId,
  startDate = null,
  endDate = null,
) => (dispatch) => {
  dispatch({ type: FETCH_REPORT_CARD_LINK_START });

  const requestBody = {
    userId,
    startDate,
    endDate,
  };

  axiosAuthed
    .post(
      `/accounts/${accountCode}/reports/report-card/link`,
      requestBody,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_REPORT_CARD_LINK_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_REPORT_CARD_LINK_REJECTED,
      });
    });
};

export const fetchAthleteReportCardPublic = (
  token,
) => (dispatch) => {
  dispatch({ type: FETCH_ATHLETE_REPORT_CARD_START });
  axiosUnauthed
    .get(
      `/public/report-card?token=${token}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ATHLETE_REPORT_CARD_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ATHLETE_REPORT_CARD_REJECTED,
      });
    });
};

export const unsubscribe = (
  token, email,
) => (dispatch) => {
  dispatch({ type: FETCH_UNSUBSCRIBE_START });
  axiosUnauthed
    .put(
      `/public/report-card/unsubscribe?token=${token}&email=${email}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_UNSUBSCRIBE_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_UNSUBSCRIBE_REJECTED,
      });
    });
};

export const setIsShareModalOpen = (isOpen) => (
  {
    type: SET_IS_SHARE_MODAL_OPEN,
    payload: isOpen,
  }
);
