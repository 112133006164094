import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import LegendSVG from '../../../../shared/images/sc-dash-metrics-legend.png';
import { setIsLegendInfoModalShowing } from '../../ducks/sportCoachDashboardActions';

const ModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -125px 0px 0px -345px;
  background-color: #FFFFFF;
  width: 715px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
  z-index: 1003;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Header = styled('div')`
  font-size: 16px;
  color: #9E9E9E;
`;

const Title = styled('div')`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const DescriptionImage = styled('div')`
  background-image: url(${LegendSVG});
  background-repeat: no-repeat;
  height: 275px;
  width: 685px;
`;

const SCLegendInfoModal = () => {
  const isLegendInfoModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isLegendInfoModalShowing,
  );

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsLegendInfoModalShowing(false));
  };

  return (
    <>
      <ModalContainer>
        <HeaderContainer>
          <Header>Legend</Header>
          <Button
            icon='remove'
            iconOnly
            onClick={handleClose}
          />
        </HeaderContainer>
        <Title>Key Performance Exercises</Title>
        <DescriptionImage />
      </ModalContainer>
      <ModalOverlay
        isActive={isLegendInfoModalShowing}
        onClick={handleClose}
      />
    </>
  );
};

export default SCLegendInfoModal;
