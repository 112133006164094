import generateUniqueId from '../../../shared/utils/generateUniqueId';

import {
  SET_MODAL_OPEN,
  FETCH_PROGRAM_START,
  FETCH_PROGRAM_FULFILLED,
  FETCH_PROGRAM_REJECTED,
  FETCH_STRIPE_DETAILS_START,
  FETCH_STRIPE_DETAILS_FULFILLED,
  FETCH_STRIPE_DETAILS_REJECTED,
  SUBMIT_PAYMENT_START,
  SUBMIT_PAYMENT_FULFILLED,
  SUBMIT_PAYMENT_REJECTED,
  FETCH_RESELLER_START,
  FETCH_RESELLER_FULFILLED,
  FETCH_RESELLER_REJECTED,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_PUBLIC_COUPON_START,
  FETCH_PUBLIC_COUPON_FULFILLED,
  FETCH_PUBLIC_COUPON_REJECTED,
  SET_PAYMENT_LOADING,
} from './actionTypes';

const initialState = {
  activeModal: '',
  isLoadingProgram: false,
  isLoadingPaymentDetails: false,
  isLoadingPayment: false,
  isModalOpen: false,
  isLoadingCoupon: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESELLER_START: {
      return {
        ...state,
        isLoadingReseller: true,
      };
    }
    case FETCH_RESELLER_FULFILLED: {
      return {
        ...state,
        isLoadingReseller: false,
      };
    }
    case FETCH_RESELLER_REJECTED: {
      return {
        ...state,
        isLoadingReseller: false,
      };
    }
    case FETCH_PROGRAMS_START: {
      return {
        ...state,
        isLoadingPrograms: true,
      };
    }
    case FETCH_PROGRAMS_FULFILLED: {
      return {
        ...state,
        isLoadingPrograms: false,
      };
    }
    case FETCH_PROGRAMS_REJECTED: {
      return {
        ...state,
        isLoadingPrograms: false,
      };
    }
    case FETCH_PUBLIC_COUPON_START: {
      return {
        ...state,
        isLoadingCoupon: true,
      };
    }
    case FETCH_PUBLIC_COUPON_FULFILLED: {
      return {
        ...state,
        isLoadingCoupon: false,
      };
    }
    case FETCH_PUBLIC_COUPON_REJECTED: {
      return {
        ...state,
        isLoadingCoupon: false,
      };
    }
    case FETCH_PROGRAM_START: {
      return {
        ...state,
        isLoadingProgram: true,
      };
    }
    case FETCH_PROGRAM_FULFILLED: {
      return {
        ...state,
        isLoadingProgram: false,
      };
    }
    case FETCH_PROGRAM_REJECTED: {
      return {
        ...state,
        isLoadingProgram: false,
      };
    }
    case FETCH_STRIPE_DETAILS_START: {
      return {
        ...state,
        isLoadingPaymentDetails: true,
      };
    }
    case FETCH_STRIPE_DETAILS_FULFILLED: {
      return {
        ...state,
        isLoadingPaymentDetails: false,
      };
    }
    case FETCH_STRIPE_DETAILS_REJECTED: {
      return {
        ...state,
        isLoadingPaymentDetails: false,
      };
    }
    case SET_MODAL_OPEN: {
      return {
        ...state,
        activeModal: action.payload.activeModal,
        isModalOpen: action.payload.isModalOpen,
      };
    }
    case SUBMIT_PAYMENT_START: {
      return {
        ...state,
        isLoadingPayment: true,
      };
    }
    case SUBMIT_PAYMENT_FULFILLED: {
      return {
        ...state,
        isLoadingPayment: false,
      };
    }
    case SUBMIT_PAYMENT_REJECTED: {
      return {
        ...state,
        isLoadingPayment: false,
      };
    }
    case SET_PAYMENT_LOADING: {
      return {
        ...state,
        isLoadingPayment: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
