import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  setLatestActivityFilter,
} from '../../ducks/reportingActions';

const ActivityBarWrapper = styled('div')`
    display: flex;
    flex: 1.5;
    min-height: 225px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
    justify-content: space-between;
`;

const MainContent = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SingleBar = styled('div')`
    background-color: ${(props) => (props.backgroundColor)};
    height: 5px;
    width: 100%;
`;

const BreakdownCard = styled('div')`
    box-shadow: 4px 4px 8px 4px rgba(0,0,0,0.08);
    background-color: #ffffff;
    margin-bottom: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    @media screen and (max-width: 540px) {
      background-color: transparent;
      box-shadow: none;
      padding-bottom: 10px;
      width: 40px;
      height: 40px;
    }
`;

const CardAndBarWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => props.width};
    cursor: pointer;
`;

const BreakdownLabel = styled('div')`
    font-size: 12px;
`;

const BreakdownValue = styled('div')`
    font-size: 24px;
    font-weight: bold;
`;

const EmptyDiv = styled('div')`
`;

const Title = styled('div')`
    padding-top: 15px;
    font-size: 14px;
    font-weight: 900;
    font-family: Nunito Sans;
    letter-spacing: 1.18px;
`;

const TitleAndDescription = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Description = styled('div')`
    font-size: 12px;
    padding-top: 5px;
`;

const ActivityBar = ({
  activityDatesBreakdown,
  total,
}) => {
  const dispatch = useDispatch();

  /*
  Calculates the width of the individual bar based on the percent of value/total
  */
  const calculateWidth = (value) => {
    const percentage = (value / total) * 100;
    if (percentage > 0) {
      return `calc(${percentage}% + 80px)`;
    }
    return '80px';
  };

  const handleClick = (value) => {
    dispatch(setLatestActivityFilter(value));
  };

  return (
    <ActivityBarWrapper>
      <TitleAndDescription>
        <Title>LATEST WORKOUT ACTIVITY</Title>
        <Description>
          Breakdown of latest completed workout for selected athletes into four date ranges.
          Click a range to filter table below.
        </Description>
      </TitleAndDescription>
      <MainContent>
        <CardAndBarWrapper onClick={() => handleClick('no activity')} width={calculateWidth(activityDatesBreakdown['no activity'])}>
          <BreakdownCard>
            <BreakdownLabel>
              no activity
            </BreakdownLabel>
            <BreakdownValue>{activityDatesBreakdown['no activity']}</BreakdownValue>
          </BreakdownCard>
          <SingleBar backgroundColor='#444445' />
        </CardAndBarWrapper>

        <CardAndBarWrapper onClick={() => handleClick('30+')} width={calculateWidth(activityDatesBreakdown['30+'])}>
          <BreakdownCard>
            <BreakdownLabel>
              30+ days
            </BreakdownLabel>
            <BreakdownValue>{activityDatesBreakdown['30+']}</BreakdownValue>
          </BreakdownCard>
          <SingleBar backgroundColor='#ff0024' />
        </CardAndBarWrapper>

        <CardAndBarWrapper onClick={() => handleClick('15-29')} width={calculateWidth(activityDatesBreakdown['15-29'])}>
          <BreakdownCard>
            <BreakdownLabel>
              15-29 days
            </BreakdownLabel>
            <BreakdownValue>{activityDatesBreakdown['15-29']}</BreakdownValue>
          </BreakdownCard>
          <SingleBar backgroundColor='#ff730e' />
        </CardAndBarWrapper>

        <CardAndBarWrapper onClick={() => handleClick('7-14')} width={calculateWidth(activityDatesBreakdown['7-14'])}>
          <BreakdownCard>
            <BreakdownLabel>
              7-14 days
            </BreakdownLabel>
            <BreakdownValue>{activityDatesBreakdown['7-14']}</BreakdownValue>
          </BreakdownCard>
          <SingleBar backgroundColor='#ffaf0e' />
        </CardAndBarWrapper>

        <CardAndBarWrapper onClick={() => handleClick('0-6')} width={calculateWidth(activityDatesBreakdown['0-6'])}>
          <BreakdownCard>
            <BreakdownLabel>
              0-6 days
            </BreakdownLabel>
            <BreakdownValue>{activityDatesBreakdown['0-6']}</BreakdownValue>
          </BreakdownCard>
          <SingleBar backgroundColor='#10cd8c' />
        </CardAndBarWrapper>
      </MainContent>
      <EmptyDiv />
    </ActivityBarWrapper>
  );
};

ActivityBar.propTypes = {
  activityDatesBreakdown: PropTypes.instanceOf(Object).isRequired,
  total: PropTypes.instanceOf(Number).isRequired,
};

export default ActivityBar;
