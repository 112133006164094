export const SUBMIT_EMAIL_START = 'SUBMIT_EMAIL_START';
export const SUBMIT_EMAIL_FULFILLED = 'SUBMIT_EMAIL_FULFILLED';
export const SUBMIT_EMAIL_REJECTED = 'SUBMIT_EMAIL_REJECTED';

export const SUBMIT_RESET_START = 'SUBMIT_RESET_START';
export const SUBMIT_RESET_FULFILLED = 'SUBMIT_RESET_FULFILLED';
export const SUBMIT_RESET_REJECTED = 'SUBMIT_RESET_REJECTED';

export const VALIDATE_TOKEN_START = 'VALIDATE_TOKEN_START';
export const VALIDATE_TOKEN_FULFILLED = 'VALIDATE_TOKEN_FULFILLED';
export const VALIDATE_TOKEN_REJECTED = 'VALIDATE_TOKEN_REJECTED';
