import React from 'react';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import iconSet from '../../images/teambuildr-selection.json';
import { ColorGreen } from '../../GlobalStyles';

const IconRoundStyled = styled('div')`
  align-items: center;
  align-self: center;
  background-color: ${(props) => {
    const isCustomBackground = props.customBackground
      ? props.customBackground
      : props.theme.colors.background[1];

    return (props.transparent ? 'transparent' : isCustomBackground);
  }};
  border: ${(props) => {
    const isCustomBorder = props.customBorder
      ? (`1px solid ${props.customBorder}`)
      : (`1px solid ${props.theme.colors.iconBorder}`);
    return (
      props.noBorder
        ? (`0px solid ${props.theme.colors.iconBorder}`)
        : isCustomBorder
    );
  }};
  border-radius: 100%;
  box-sizing: border-box;
  display: flex;
  height: 22px;
  justify-content: center;
  transition: all 250ms ease-in-out;
  width: 22px;

  svg {
    path {
      fill: ${(props) => (props.customColor ? props.customColor : props.theme.textColor)};
    }
  }

  ${(props) => (
    props.complete ? `
      background-color: ${ColorGreen};
      border: 1px solid ${ColorGreen};
    ` : ''
  )}

  ${(props) => (
    props.large ? `
      transform: scale(1.6)
    ` : ''
  )}

  ${(props) => (
    props.light ? `
      border: 1px solid ${props.theme.buttonBorderColor2};
      svg {
        path {
          fill: rgb(172,172,172)!important;
        }
      }
    ` : ''
  )}

  ${(props) => (
    props.xLarge ? `
      transform: scale(2.3)
    ` : ''
  )}

  ${(props) => (
    props.jumbo ? `
      transform: scale(5)
    ` : ''
  )}
`;

const IconRound = ({
  className = 'icon',
  customBackground = '',
  customBorder = '',
  customColor = '',
  icon,
  jumbo = false,
  large = false,
  light = false,
  noBorder = false,
  size = 12,
  transparent = false,
  xLarge = false,
}) => (
  <IconRoundStyled
    className={className}
    customBackground={customBackground}
    customBorder={customBorder}
    customColor={customColor}
    jumbo={jumbo}
    large={large}
    light={light}
    noBorder={noBorder}
    transparent={transparent}
    xLarge={xLarge}
  >
    <IcomoonReact
      iconSet={iconSet}
      icon={icon}
      size={size}
    />
  </IconRoundStyled>
);

IconRound.propTypes = {
  className: PropTypes.string,
  customBackground: PropTypes.string,
  customBorder: PropTypes.string,
  customColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  jumbo: PropTypes.bool,
  large: PropTypes.bool,
  light: PropTypes.bool,
  noBorder: PropTypes.bool,
  size: PropTypes.number,
  transparent: PropTypes.bool,
  xLarge: PropTypes.bool,
};

IconRound.defaultProps = {
  className: 'icon',
  customBackground: '',
  customBorder: '',
  customColor: '',
  jumbo: false,
  large: false,
  light: false,
  noBorder: false,
  size: 12,
  transparent: false,
  xLarge: false,
};

export default IconRound;