// modal that pops up after document is created, showing links to create other documents

import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .icon-text-wrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }
`;

const ItemsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-bottom: -58px;
  width: 100%;
  height: 100%;
  min-width: 400px;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5px;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
    margin-right: 10px;
  }
`;

const NavigationFormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const CreateObjectWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  :hover {
    background-color: #ff6600;
    color: white;
    .create-doc-text {
      text-align: center;
      color: white;
    }
    svg {
      fill: rgb(255,255,255);
    }
  }
  .create-doc-text {
    margin-top: 25px;
    font-weight: 900;
    font-size: 14px;
  }
`;

const TextAndObjectsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const TextWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  .success-text {
    font-size: 16px;
    font-weight: bold;
    color: #0dcc8a;
  }

  .question-text {
    font-size: 18px;
    margin-top: 30px;
    font-weight: bold;
  }
`;

const ModalConfirmCreate = ({
  setActiveModal,
  currentFolder,
  documentType,
  onRequestClose,
}) => {
  const createItemClicked = (clickedItem) => {
    setActiveModal(clickedItem);
  };

  const createFolderObjectRenderer = () => {
    if (!currentFolder) {
      return (
        <CreateObjectWrapper
          style={
          {
            width: !currentFolder ? '33.3%' : '50%',
            paddingBottom: '40px',
            paddingTop: '30px',
          }
        }
          onClick={() => createItemClicked('create_folder_modal')}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={75}
            icon='folder'
          />
          <Text className='create-doc-text'>
            CREATE A FOLDER
          </Text>
        </CreateObjectWrapper>
      );
    }
    return null;
  };

  return (
    <ModalWrapper>
      <NavigationFormWrapper>
        <NavigationWrapper>
          <Text
            className='back-arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='remove'
            />
          </Text>
        </NavigationWrapper>
        <TextAndObjectsWrapper>
          <TextWrapper>
            <Text className='success-text'>
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='checkmark'
                color='#0dcc8a'
              />
              {' '}
              {documentType}
              {' '}
              successfully added!
            </Text>
            <Text className='question-text'>Need to add anything else?</Text>
          </TextWrapper>
          <ItemsWrapper>
            {createFolderObjectRenderer()}
            <CreateObjectWrapper
              style={
            {
              width: !currentFolder ? '33.3%' : '50%',
              paddingBottom: '40px',
              paddingTop: '30px',
            }
          }
              onClick={() => createItemClicked('upload_file_modal')}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={75}
                icon='file'
              />
              <Text className='create-doc-text'>
                UPLOAD A FILE
              </Text>
            </CreateObjectWrapper>
            <CreateObjectWrapper
              style={
            {
              width: !currentFolder ? '33.3%' : '50%',
              paddingBottom: '40px',
              paddingTop: '30px',
            }
          }
              onClick={() => createItemClicked('add_link_modal')}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={75}
                icon='globe'
              />
              <Text className='create-doc-text'>
                ADD A LINK
              </Text>
            </CreateObjectWrapper>
          </ItemsWrapper>
        </TextAndObjectsWrapper>
      </NavigationFormWrapper>
    </ModalWrapper>
  );
};

ModalConfirmCreate.propTypes = {
  setActiveModal: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};
export default ModalConfirmCreate;
