/* eslint-disable import/prefer-default-export */
import {
  FETCH_REPORTABLE_GROUPS_HT_START,
  FETCH_REPORTABLE_GROUPS_HT_FULFILLED,
  FETCH_REPORTABLE_GROUPS_HT_REJECTED,
  FETCH_HABITS_FOR_ACCOUNT_START,
  FETCH_HABITS_FOR_ACCOUNT_FULFILLED,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_CATEGORIES_FOR_ACCOUNT_START,
  FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED,
  FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED,
  SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED,
  FETCH_ATHLETES_START_HT,
  FETCH_ATHLETES_FULFILLED_HT,
  FETCH_ATHLETES_REJECTED_HT,
  CREATE_NEW_HABIT_START,
  CREATE_NEW_HABIT_FULFILLED,
  CREATE_NEW_HABIT_REJECTED,
  EDIT_HABIT_START,
  EDIT_HABIT_FULFILLED,
  EDIT_HABIT_REJECTED,
  DELETE_HABIT_START,
  DELETE_HABIT_FULFILLED,
  DELETE_HABIT_REJECTED,
  CHECK_5_HABIT_MAX_START,
  CHECK_5_HABIT_MAX_FULFILLED,
  CHECK_5_HABIT_MAX_REJECTED,
  FETCH_HABIT_FOR_ACCOUNT_START,
  FETCH_HABIT_FOR_ACCOUNT_FULFILLED,
  FETCH_HABIT_FOR_ACCOUNT_REJECTED,
  SET_ASSIGNMENT_CONFLICT_ACTION,
  SET_ATTENTION_ACTION,
  SET_DELETE_MODAL_DATA,
  SET_GLOBAL_HABIT_FILTERS,
  SET_EDIT_CATEGORY_INFO,
  CREATE_NEW_CATEGORY_START,
  CREATE_NEW_CATEGORY_FULFILLED,
  CREATE_NEW_CATEGORY_REJECTED,
  EDIT_CATEGORY_START,
  EDIT_CATEGORY_FULFILLED,
  EDIT_CATEGORY_REJECTED,
  DELETE_CATEGORY_START,
  DELETE_CATEGORY_FULFILLED,
  DELETE_CATEGORY_REJECTED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';

export const fetchReportableGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_REPORTABLE_GROUPS_HT_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_HT_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_HT_REJECTED, payload: err });
      });
  }
);

export const fetchHabitsForAccount = (
  accountCode,
  categoryIds = null,
  groupIds = null,
  status = 1,
) => (
  (dispatch) => {
    let queryString = '';

    if (categoryIds !== null) {
      queryString += `${queryString.length === 0 ? '?' : '&'}categoryIds=${categoryIds}`;
    }
    if (groupIds !== null) {
      queryString += `${queryString.length === 0 ? '?' : '&'}groupIds=${groupIds}`;
    }
    if (status !== null) {
      queryString += `${queryString.length === 0 ? '?' : '&'}status=${status}`;
    }

    const url = `/accounts/${accountCode}/habits${queryString}`;

    dispatch({ type: FETCH_HABITS_FOR_ACCOUNT_START });
    axiosAuthed.get(url)
      .then((response) => {
        dispatch({ type: FETCH_HABITS_FOR_ACCOUNT_FULFILLED, payload: response.data });
        dispatch({
          type: SET_GLOBAL_HABIT_FILTERS,
          payload: {
            categoryIds,
            groupIds,
            status,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_HABITS_FOR_ACCOUNT_REJECTED,
          payload: err.response.headers.message,
        });
        dispatch({
          type: SET_GLOBAL_HABIT_FILTERS,
          payload: {
            categoryIds: null,
            groupIds: null,
            status: null,
          },
        });
      });
  }
);

export const fetchSingleHabitForAccount = (accountCode, habitId, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: FETCH_HABIT_FOR_ACCOUNT_START });
    axiosAuthed.get(`/accounts/${accountCode}/habits/${habitId}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: FETCH_HABIT_FOR_ACCOUNT_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: FETCH_HABIT_FOR_ACCOUNT_REJECTED, payload: err });
        if (onError) {
          onError(err.message);
        }
      });
  }
);
export const fetchCategoriesForAccount = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CATEGORIES_FOR_ACCOUNT_START });
    axiosAuthed.get(`/accounts/${accountCode}/habits/categories`)
      .then((response) => {
        dispatch({ type: FETCH_CATEGORIES_FOR_ACCOUNT_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CATEGORIES_FOR_ACCOUNT_REJECTED, payload: err });
      });
  }
);

export const setHabitTrackingPageWrapperScrollTriggered = (value) => ({
  type: SET_HABIT_TRACKING_PAGE_WRAPPER_SCROLL_TRIGGERED,
  payload: value,
});

export const fetchAthletes = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_ATHLETES_START_HT });
  axiosAuthed
    .get(`/accounts/${accountCode}/users?userType=0`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_ATHLETES_FULFILLED_HT,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_ATHLETES_REJECTED_HT,
      });
    });
};
export const createNewHabitForAccount = (accountCode, values, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: CREATE_NEW_HABIT_START });

    axiosAuthed.post(`/accounts/${accountCode}/habits`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: CREATE_NEW_HABIT_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'New Habit Saved', 'User successfully saved a new habit');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: CREATE_NEW_HABIT_REJECTED, payload: err.message });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'New Habit Error', err.message);
        }
      });
  }
);

export const editHabitForAccount = (accountCode, habitId, values, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: EDIT_HABIT_START });

    axiosAuthed.put(`/accounts/${accountCode}/habits/${habitId}`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: EDIT_HABIT_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Habit Edited', 'User successfully edited a habit');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: EDIT_HABIT_REJECTED, payload: err.message });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'Habit Edit Error', err.message);
        }
      });
  }
);

export const deleteHabitForAccount = (accountCode, habitId, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: DELETE_HABIT_START });
    axiosAuthed.delete(`/accounts/${accountCode}/habits/${habitId}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: DELETE_HABIT_FULFILLED, payload: { data: response.data, id: habitId } });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Habit Deleted', 'User successfully deleted a habit');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: DELETE_HABIT_REJECTED, payload: err });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'Habit Delete Error', err.message);
        }
      });
  }
);

export const check5HabitMaxCompliance = (accountCode, userIds, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: CHECK_5_HABIT_MAX_START });

    axiosAuthed.post(`/accounts/${accountCode}/habits/user-check`, { userIds })
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: CHECK_5_HABIT_MAX_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: CHECK_5_HABIT_MAX_REJECTED, payload: err.message });
        if (onError) {
          onError(err.message);
        }
      });
  }
);

export const setAssignmentConflictAction = (value) => ({
  type: SET_ASSIGNMENT_CONFLICT_ACTION,
  payload: value,
});

export const setAttentionAction = (value) => ({
  type: SET_ATTENTION_ACTION,
  payload: value,
});

export const setDeleteModalData = (obj) => ({
  type: SET_DELETE_MODAL_DATA,
  payload: obj,
});

export const setEditCategoryInfo = (value) => ({
  type: SET_EDIT_CATEGORY_INFO,
  payload: value,
});

export const createNewCategoryForAccount = (accountCode, values, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: CREATE_NEW_CATEGORY_START });

    axiosAuthed.post(`/accounts/${accountCode}/habits/categories`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: CREATE_NEW_CATEGORY_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'New Habit Category Saved', 'User successfully added a new habit category');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: CREATE_NEW_CATEGORY_REJECTED, payload: err.message });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'New Habit Category Error', err.message);
        }
      });
  }
);

export const editCategoryForAccount = (
  accountCode,
  categoryId,
  values,
  { onSuccess, onError } = {},
) => (
  (dispatch) => {
    dispatch({ type: EDIT_CATEGORY_START });

    axiosAuthed.put(`/accounts/${accountCode}/habits/categories/${categoryId}`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({ type: EDIT_CATEGORY_FULFILLED, payload: response.data });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Habit Category Edited', 'User successfully edited a habit category');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: EDIT_CATEGORY_REJECTED, payload: err.message });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'Edit Habit Category Error', err.message);
        }
      });
  }
);

export const deleteCategoryForAccount = (accountCode, categoryId, { onSuccess, onError } = {}) => (
  (dispatch) => {
    dispatch({ type: DELETE_CATEGORY_START });
    axiosAuthed.delete(`/accounts/${accountCode}/habits/categories/${categoryId}`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: DELETE_CATEGORY_FULFILLED,
            payload: { data: response.data, id: categoryId },
          });
          if (onSuccess) {
            onSuccess(response.data);
            // Amplitude tracking
            dashboardTracker('Consistency Coach', 'Habit Category Deleted', 'User successfully deleted a habit category');
          }
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: DELETE_CATEGORY_REJECTED, payload: err });
        if (onError) {
          onError(err.message);
          // Amplitude tracking
          dashboardTracker('Consistency Coach', 'Habit Category Delete Error', err.message);
        }
      });
  }
);
