import React from 'react';
import { useDispatch } from 'react-redux';

import DatePicker from '../presentational/DatePicker';
import {
  fetchPrograms,
  fetchWorkoutCalendar,
  selectDate,
  setIsProgramListModalShowing,
} from '../../ducks/panelActions';

const WeightroomDatePicker = (ownProps) => {
  const dispatch = useDispatch();

  const ftchPrograms = (accountCode, athleteId, namespace) => {
    dispatch(fetchPrograms(accountCode, athleteId, namespace));
  };
  const ftchWorkoutCalendar = (accountCode, athleteId, namespace, startDate, endDate) => {
    dispatch(fetchWorkoutCalendar(accountCode, athleteId, namespace, startDate, endDate));
  };
  const slctDate = (accountCode, date, athleteId, namespace) => {
    dispatch(selectDate(accountCode, date, athleteId, namespace));
  };
  const setIsPrgrmListModalShowing = (namespace, bool) => {
    dispatch(setIsProgramListModalShowing(namespace, bool));
  };

  return (
    <DatePicker
      fetchPrograms={ftchPrograms}
      fetchWorkoutCalendar={ftchWorkoutCalendar}
      selectDate={slctDate}
      setIsProgramListModalShowing={setIsPrgrmListModalShowing}
      namespace={ownProps.namespace}
    />
  );
};

export default WeightroomDatePicker;
