/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';

const Styles = styled.div`
  background: #FFFFFF;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;

  & .sticky {
    overflow-x: auto;
    margin: 25px 10px;
    filter: blur(1.2px);

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #F0F0F0;
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid #BDBDBD1A;
    border-top-left-radius: 8px;
    min-width: 270px;
    max-width: 270px;
  }

  .rawValueTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: normal;
    background-color: #F0F0F0;
    color: #444444;
    text-align: left;
    min-width: 100px;
    max-width: 100px;
  }

  .athleteNameTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #444444;
    background-color: #F0F0F0; 
    min-width: 180px;
    max-width: 180px;
    position: relative;
  }

  .footerCells {
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: normal;
    text-align: left;
    color: #444444;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #BDBDBD1A;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
         border-top: 1px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
         background: #FCFCFC;
         border-bottom-left-radius: 8px;
        }
      }
      td:first-child {
        color: #444444;
        font-size: 16px;
        font-weight: normal;
        text-align: left;
        font-family: 'Nunito Sans';
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  columns,
  data,
  getCellProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 5 },
  },
  useSticky,
  useSortBy,
  usePagination);

  return (
    <>
      <div id='reportTable' style={{ display: 'flex' }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),

                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => <td data-a-wrap {...column.getFooterProps([{ className: 'footerCells' }])}>{column.render('Footer')}</td>)}
              </tr>
            ))}
          </tfoot>
        </table>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            background: '#5F8BDC1A',
            color: '#5F8BDC',
            border: '1px dashed #5F8BDC',
            borderRadius: '5px',
            width: '250px',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'NunitoSans',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        >
          <span>+Add Exercise</span>
        </div>
      </div>
    </>
  );
};

const SCAnalysisTableNoData = ({
  containerWidth,
  dataRows,
  setColumnHeaderHeight,
  rowIsScrollable,
}) => {
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = () => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
      setColumnHeaderHeight(
        chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0].clientHeight,
      );
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Athlete',
          accessor: 'name',
          Footer: 'Group Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '1px solid #E0E0E0',
            height: '58px',
          },
          Cell: ({ row }) => (
            <TBTooltip
              textToDisplay={row.original.name}
              textContainerPadding='0px'
              textContainerMaxWidth='175px'
              fontSize='12px'
              fontColor='black'
            />
          ),
        },
      ],
    },
  ];

  const NoDataColumn = [
    {
      Header: () => exercises[0].name || 'Barbell Deadlift',
      Footer: () => null,
      id: 'exerName',
      className: 'noHoverCategoryColumnTitleCell',
      columns: [
        {
          Header: 'Start',
          accessor: 'start',
          Cell: () => (
            <TBTooltip
              textToDisplay=''
              textContainerPadding='0px'
              textContainerMaxWidth='90px'
              fontSize='12px'
              fontColor='black'
            />
          ),
          Footer: '0000.00',
          id: 'startDate',
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFBDBDBD1A',
          },
        },
        {
          Header: 'End',
          accessor: 'end',
          Cell: () => (
            <TBTooltip
              textToDisplay=''
              textContainerPadding='0px'
              textContainerMaxWidth='90px'
              fontSize='12px'
              fontColor='black'
            />
          ),
          Footer: '0000.00',
          id: 'endDate',
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFBDBDBD1A',
          },
        },
        {
          Header: 'Change',
          accessor: 'change',
          Cell: () => (
            <TBTooltip
              textToDisplay=''
              textContainerPadding='0px'
              textContainerMaxWidth='90px'
              fontSize='12px'
              fontColor='black'
            />
          ),
          Footer: '0.00',
          id: 'change',
          className: 'rawValueTitle',
          style: {
            fontWeight: 700,
            minWidth: '90px',
            maxWidth: '90px',
            fillColor: 'FFBDBDBD1A',
          },
        },
      ],
    },
  ];

  const columns = [...athleteNameColumn, ...NoDataColumn];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  return (
    <>
      <Styles
        ref={chartRef}
        onScroll={handleScroll}
        isScrollable={isScrollable}
        rowIsScrollable={rowIsScrollable}
        headerAndSubHeaderHeight={headerAndSubHeaderHeight}
        containerWidth={containerWidth}
      >
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={() => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
        />
      </Styles>
    </>
  );
};

export default SCAnalysisTableNoData;
