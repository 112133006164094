import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Formik, Form, Field } from 'formik';
import Dropzone from 'react-dropzone';
import { useTheme } from 'emotion-theming';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import * as Yup from 'yup';
import FontPicker from 'font-picker-react';

// import { setActiveReport } from '../ducks/reportingActions';
import Avatar from '../../../../../shared/components/Avatar/Avatar';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import SubText from '../../../../../shared/components/SubText/SubText';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import NoHoverButton from '../programs/NoHoverButton';
import useReseller from '../../hooks/useReseller';
import optimizeImage from '../../../../../shared/utils/imageOptimizer';
import ResellerAlertBanner from '../reseller/ResellerAlertBanner';

const MainContainer = styled('main')`
  flex: 10;
  /* margin: 0px 30px; */
  overflow-y: scroll;
`;

const GridContainer = styled('div')`
  margin: 0px 30px;
  padding-bottom: 60px;

  .first-left-section-header {
    margin-bottom: 5px;
  }
  .left-section-header-middle {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .left-section-header-middle2 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
`;

const FieldError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 5px;
`;

const TitlePlusErrorWrapper = styled('div')`
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomWrapper = styled('div')`
  margin-top: 30px;
  .account-settings-text {
    margin-bottom: 20px;
    font-size: 14px;
    display: inline-block;
  }
  .left-section-header {
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

const SettingsTitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const MiddleSettingsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MS-L {
    width: 100%;
    display: flex;
    flex-direction: column;
    .account-name-text {
      margin-bottom: 5px;
      margin-top: 15px;
    }
  }
  .MS-R {
    width: 30%;
    display: flex;
    flex-direction: column;
    .account-color-text {
      margin-bottom: 5px;
      min-width: 25px;
    }
  }
  .modal-button {
    width: 25%;
    margin-top: 60px;
    color: white;
    &:hover {
      opacity: .8;
    }
  }
  .spinner-container {
    width: 60px;
    height: 48px;
    margin-top: 60px;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .formik-form {
    width: 70%;
    .url-string {
      text-transform: none;
      font-weight: 500;
      letter-spacing: normal;
    }

    .update-button {
      margin-top: 15px;
      color: white;

      &:hover {
        opacity: .7;
      }
    }
  }
  .color-square {
    height: 37px;
    width: 37px;
    background: #ff6600;
    border-radius: 3px;
  }
`;

const AltFieldWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const HeaderRow = styled('div')`
  display: flex;
  margin-top: 30px;
  margin-bottom: 20px;
  margin: 30px 30px 20px 30px;
  h1 {
    text-transform: capitalize;
  }
`;

const FormGroup = styled('div')`
  width: 100%;
  z-index: 1;

  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .easy-join {
    margin-bottom: 15px;
  }

  .formgroup-text {
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .caveat-text {
    font-size: 11px;
    margin-top: 5px;
  }
  #font-picker {
    box-shadow: none;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .dropdown-button {
    margin-bottom: 0px;
    background-color: white!important;
    border-radius: 5px;
    padding: 20px 10px;
  }

  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
  .form-control {
    min-height: 30px;
    width: 100%;
  }
`;
  
const DropZoneWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  width: 600px;

  /* width: 160px; */
  .upload-image-text {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    color: #ff6600;
  }

  .update-button {
    width: 170px;
    margin-top: 15px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const EmptyDropZoneWrapper = styled('div')`
  width: 160px;
  height: 55px;
  margin-top: 5px;
  display: flex;
  align-items: center;
`;

const DropZoneDivUploaded = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 55px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 250px;
  height: 55px;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .file-icon-text-uploaded {
    font-size: 12px;
    color: green;
  }
  .file-icon-text-uploaded-name {
    font-size: 12px;
  }
  .file-icon-text-uploaded-success-again {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const DropZoneDivUploadedFail = styled('div')`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 55px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  width: 160px;
  height: 55px;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .file-icon-text-uploaded-fail {
    font-size: 12px;
    color: red;
  }
  .file-icon-text-uploaded-fail-restart {
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  margin-top: 10px;
`;

const CustomFontContainer = styled('div')`
  display: flex;
  flex-direction: column;

  .react-toggle {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const GeneralSettingsGrid = () => {
  const {
    currentUser,
    currentReseller,
    handleUploadMedia,
    handleUpdateResellerAccount,
  } = useReseller();

  const uploadedMedia = useSelector((state) => state.reseller.data.uploadMedia);
  const uploadedMediaError = useSelector((state) => state.reseller.data.uploadMediaError);
  const mediaIsUploading = useSelector((state) => state.reseller.ui.isMediaUploading);

  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [userProfilePic, setUserProfilePic] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isCustomFontActive, setCustomFontActive] = useState(currentReseller ? (currentReseller.customFont === null ? false : true) : false);

  const urlRef = useRef(null);

  const isLoadingSettingsUpdate = useSelector((state) => state.reseller.ui.isLoadingSettingsUpdate);

  const theme = useTheme();

  const spinnerAnimation = useSpring({ opacity: isLoadingSettingsUpdate ? 1 : 0 });
  const copyAnimation = useSpring({ opacity: copySuccess ? 1 : 0 });

  useEffect(() => {
    if (currentReseller && currentReseller.defaultPortalMediaKey) {
      const resellerPic = optimizeImage(
        `https://s3.amazonaws.com/teambuildr-${process.env.ENVIRONMENT.toLowerCase() !== 'production' ? 'staging' : 'production'}-assets/${currentReseller.defaultPortalMediaKey}`,
        {
          resize: {
            width: 1200,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(resellerPic);
    }
  }, [currentReseller]);

  // a handler for documents being submitted and created.  different processes depending on doc type
  const handleSubmit = (values) => {
    const newValues = {
      businessName: values.name,
      contactEmail: values.contactEmail,
      customFont: values.customFont,
    };
    if (isMediaUploaded) {
      newValues.defaultPortalMediaId = uploadedMedia.id;
    }
    handleUpdateResellerAccount(currentUser.accountCode, newValues);
  };
  // handler for file dropped (uploaded)
  const handleDroppedFile = (acceptedFile) => {
    handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile });
    setIsMediaUploaded(true);
  };

  const DropZoneDiv = styled('div')`
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    min-height: 30px;
    width: 100%;
    cursor: pointer;
    color: #495057;
    background-image: url(${(uploadedMedia && isMediaUploaded) ? uploadedMedia.mediaUrl : userProfilePic});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 0px solid #ced4da;
    /* border-radius: 50%; */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  const fileAccepterSwitch = () => {
    if (!mediaIsUploading) {
      if (uploadedMediaError && isMediaUploaded) {
        return (
          <DropZoneDivUploadedFail>
            <Text className='file-icon-text-uploaded-fail'>Something went wrong</Text>
            <Text
              onClick={() => setIsMediaUploaded(false)}
              className='file-icon-text-uploaded-fail-restart'
            >
              Try Again
            </Text>
          </DropZoneDivUploadedFail>
        );
      }
      return (
        <Dropzone onDrop={(acceptedFile) => handleDroppedFile(acceptedFile)}>
          {({ getRootProps, getInputProps }) => (
            <DropZoneWrapper {...getRootProps()}>
              <input {...getInputProps()} />
              {((uploadedMedia && isMediaUploaded) || userProfilePic) && (
                <DropZoneDiv />
              )}
              <NoHoverButton
                className='update-button'
                cta='+ Upload Image'
                customColor='#10cd8c'
                noHover
                noBorder
              />
              {/* <div className='upload-image-text'>+ Upload Image</div> */}
            </DropZoneWrapper>
          )}
        </Dropzone>
      );
    }
    if (mediaIsUploading) {
      return (
        <EmptyDropZoneWrapper>
          <Spinner
            saving
            darkTheme
          />
        </EmptyDropZoneWrapper>
      );
    }
    // if (!mediaIsUploading) {
    //   if (!uploadedMediaError) {
    //     return (
    //       <DropZoneDivUploaded>
    //         <Text className='file-icon-text-uploaded'>Success!</Text>
    //         <Text className='file-icon-text-uploaded-name'>{uploadedMediaName[0].name}</Text>
    //         <Text
    //           onClick={() => setIsMediaUploaded(false)}
    //           className='file-icon-text-uploaded-success-again'
    //         >
    //           CHANGE FILE
    //         </Text>
    //       </DropZoneDivUploaded>
    //     );
    //   }
    // }
  };

  const copyToClipboard = (e) => {
    navigator.clipboard.writeText(
      `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/${currentReseller ? currentReseller.urlSlug : ''}
    `);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const settingsSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required'),
    contactEmail: Yup.string()
      .required('Email is required')
      .email('Must be a valid email'),
  });

  return (
    <MainContainer>
      {!currentReseller.stripeChargesEnabled && (
        <ResellerAlertBanner />
      )}
      <HeaderRow>
        <HeaderText
          fontWeight={300}
          letterSpacing='normal'
        >
          General Settings
        </HeaderText>
      </HeaderRow>
      <GridContainer>
        {/* <Text className='first-left-section-header'>Agreement Text</Text>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleSubmit(values);
              setSubmitting(false);
            });
          }}
          render={({
            values,
            setFieldValue,
            submitForm,
            errors,
          }) => (
            <FormWrapper>
              <Form className='formik-form'>
                <FormGroup>
                  <Field
                    className='form-control'
                    id='name-field'
                    name='name'
                    placeholder='e.g. "I agree to pay for 3 months.."'
                  />
                </FormGroup>
              </Form>
              <NoHoverButton
                cta='Save Text'
                className='modal-button'
                customColor='#FF8C00'
                large
                noBorder
                primary
                square
                onClick={() => submitForm()}
              />
            </FormWrapper>
          )}
        /> */}
        <BottomWrapper>
          <SubText className='account-settings-text'>Account Settings</SubText>
          <MiddleSettingsWrapper>
            <div className='MS-L'>
              <Formik
                validationSchema={settingsSchema}
                initialValues={{
                  name: currentReseller ? currentReseller.businessName : '',
                  linkUrl: currentReseller ? currentReseller.urlSlug : '',
                  contactEmail: currentReseller ? currentReseller.contactEmail : '',
                  customFont: currentReseller ? (currentReseller.customFont === null ? '' : currentReseller.customFont) : '',
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    handleSubmit(values);
                    setSubmitting(false);
                  });
                }}
                render={({
                  submitForm,
                  errors,
                  touched,
                  setFieldValue,
                  values,
                }) => (
                  <>
                    <TitlePlusErrorWrapper>
                      <Text className='account-name-text' fontWeight={600}>Account Name</Text>
                      {errors.name && touched.name ? (
                        <FieldError className='error-text'>{errors.name}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <FormWrapper>
                      <Form className='formik-form'>
                        <FormGroup>
                          <Field
                            className='form-control'
                            id='name-field'
                            name='name'
                          />
                        </FormGroup>
                      </Form>
                    </FormWrapper>
                    <TitlePlusErrorWrapper>
                      <Text className='left-section-header-middle' fontWeight={600}>Contact Email</Text>
                      {errors.contactEmail && touched.contactEmail ? (
                        <FieldError className='error-text'>{errors.contactEmail}</FieldError>
                      ) : null}
                    </TitlePlusErrorWrapper>
                    <FormWrapper>
                      <Form className='formik-form'>
                        <FormGroup>
                          <Field
                            className='form-control'
                            id='contactEmail-field'
                            name='contactEmail'
                          />
                        </FormGroup>
                        <SubText fontSize={12} className='url-string'>This email will be used for subscription and support emails</SubText>
                      </Form>
                    </FormWrapper>
                    <Text className='left-section-header-middle2' fontWeight={600}>Base Account URL</Text>
                    <FormWrapper>
                      <Form className='formik-form'>
                        <AltFieldWrapper>
                          <Text ref={urlRef} fontSize={18} onClick={copyToClipboard}>
                            {`https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.market.' : 'market.'}teambuildr.com/programs/`}
                            {currentReseller ? currentReseller.urlSlug : ''}
                          </Text>
                        </AltFieldWrapper>
                        <div className='d-flex'>
                          <NoHoverButton
                            className='update-button'
                            cta='Click To Copy'
                            customColor='#10cd8c'
                            onClick={copyToClipboard}
                            primary
                            noHover
                            noBorder
                            type='button'
                          />
                          <animated.div style={copyAnimation}>
                            <Text fontSize={18} style={{ marginTop: '18px', marginLeft: '15px' }}>Copied!</Text>
                          </animated.div>
                        </div>
                      </Form>
                    </FormWrapper>
                    <FormGroup>
                      <TitlePlusErrorWrapper>
                        <Text className='account-name-text' fontWeight={600}>Custom Font for Program Title</Text>
                      </TitlePlusErrorWrapper>
                      <CustomFontContainer>
                        <Toggle
                          checked={isCustomFontActive}
                          defaultChecked={(values.customFont === '' || values.customFont === null) ? false : true}
                          // disabled={!formProps.values.mustBeInAllGroups}
                          icons={false}
                          onChange={() => {
                            if (isCustomFontActive) {
                              setFieldValue(
                                'customFont',
                                '',
                              );
                              setCustomFontActive(false);
                            } else {
                              setFieldValue(
                                'customFont',
                                'Arimo',
                              );
                              setCustomFontActive(true);
                            }
                          }}
                        />
                        {isCustomFontActive ? (
                          <FontPicker
                            apiKey='AIzaSyDQgB01ojlqMbudd0krjI5GkJpeJQILjFA'
                            activeFontFamily={values.customFont}
                            onChange={(nextFont) => {
                              setFieldValue('customFont', nextFont.family);
                              console.log(values);
                            }}
                          />
                        ) : null}
                      </CustomFontContainer>
                    </FormGroup>
                    <Text className='left-section-header' fontWeight={600}>Default Program Header Image</Text>
                    {fileAccepterSwitch()}
                    <ButtonContainer>
                      <NoHoverButton
                        cta='Update Settings'
                        className='modal-button'
                        disabled={isLoadingSettingsUpdate}
                        customColor={theme.colors.green}
                        noBorder
                        primary
                        square
                        large
                        onClick={() => {
                          submitForm();
                          setSubmitClicked(true);
                        }}
                      />
                      <animated.div style={spinnerAnimation}>
                        <Spinner saving darkTheme />
                      </animated.div>
                    </ButtonContainer>
                    {(Object.keys(errors).length && submitClicked) ? <TitleError className='error-text'>Some required fields are blank</TitleError> : null}
                  </>
                )}
              />
            </div>
          </MiddleSettingsWrapper>
        </BottomWrapper>
      </GridContainer>
    </MainContainer>
  );
};

export default GeneralSettingsGrid;
