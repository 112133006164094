import {
  SET_CONNECTED,
  SET_TEST_MESSAGE,
  SET_SOCKET_AUTHENTICATED,
  SET_RECONNECT_ATTEMPTS,
} from './actionTypes';

import generateUniqueId from '../../../utils/generateUniqueId';

export const setConnected = (data) => ({
  type: SET_CONNECTED,
  payload: data,
});

export const setTestMessage = (data) => ({
  type: SET_TEST_MESSAGE,
  payload: {
    key: generateUniqueId(),
    message: data,
  },
});

export const setSocketAuthenticated = (data) => ({
  type: SET_SOCKET_AUTHENTICATED,
  payload: data,
});

export const setReconnectAttempts = (data) => ({
  type: SET_RECONNECT_ATTEMPTS,
  payload: data,
});
