/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';
import BackgroundUpload from './BackgroundUpload';

const Wrapper = styled('div')(({ theme }) => `
  display: flex;
  flex-direction: column;
  width: 100%;

  .react-toggle .react-toggle-track {
    background-color: #E9E9EA;
}
.react-toggle--checked .react-toggle-track {
    background-color: ${theme.colors.green};
}
.react-toggle-thumb {
    border: none;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #E9E9EA;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${theme.colors.green};
}
`);

const Row = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
margin-top: 25px;
cursor: pointer;
`;

const Column = styled('div')`
display: flex;
flex-direction: column;
margin-right: 30px;
max-width: 602px;
`;

const HeaderContainer = styled('div')`
padding-top: 15px;
padding-bottom: 15px;
border-bottom: #E0E0E0 1px solid;
`;

const CustomInput = styled('input')`
font-weight: 400;
border: 2px solid #E0E0E0;
border-radius: 6px;
padding-left: 16px;
padding-right: 16px;
height: 56px;
`;

const InputRow = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
`;

const ErrorMessage = styled('div')`
  position: absolute;
  bottom: -40px; 
  left: 0;
  color: red; 
  font-size: 12px; 
  width: 100%;
  text-align: left; 
`;
const subdomainSchema = yup.object().shape({
  subdomain: yup
    .string()
    .matches(
      /^[a-z0-9]+$/,
      'Subdomain must only contain lowercase letters and numbers.',
    )
    .min(1, 'Subdomain must be at least 1 character long.')
    .max(63, 'Subdomain must be no more than 63 characters long.'),
});
const CustomLoginPage = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);

  const [toggle, setToggle] = useState(false);
  const [subdomain, setSubdomain] = useState('');
  const [subdomainError, setSubdomainError] = useState('');

  const handleSubmit = (input) => {
    const valueObj = {
      skin: {
        portalSlug: input,
      },
    };
    dispatch(updateOrganizationSettings(accountCode, valueObj));
  };

  useEffect(() => {
    if (organizationSettings?.skin?.portalSlug) {
      setToggle(true);
      setSubdomain(organizationSettings?.skin?.portalSlug);
    }
  }, [organizationSettings]);

  const handleToggle = () => {
    if (toggle === true) {
      setSubdomain('');
      dispatch(updateOrganizationSettings(accountCode, { skin: { portalSlug: '' } }));
    }
    setToggle(!toggle);
  };

  const handleBlur = (e) => {
    const value = e.target.value.trim().toLowerCase();
    subdomainSchema.validate({ subdomain: value })
      .then(() => {
        setSubdomainError('');
        handleSubmit(value);
      })
      .catch((err) => {
        setSubdomainError(err.message);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSubdomain(value);
    subdomainSchema.validate({ subdomain: value })
      .then(() => setSubdomainError(''))
      .catch((err) => setSubdomainError(err.message));
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Custom Login Page
        </Text>
        <Text fontSize={18} fontWeight='400'> Manage your organization&apos;s custom login page.</Text>
      </HeaderContainer>
      <>
        <BackgroundUpload />

        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Subdomain Enabled
            </Text>
            <Text fontSize={16} fontWeight='400'>
              Use a subdomain URL for your login page. (subdomain.teambuilr.com)
            </Text>
          </Column>
          <Toggle
            checked={toggle}
            icons={false}
            onChange={handleToggle}
          />
        </Row>
        {toggle && (
        <Row>
          <Column>
            <Text fontSize={18} fontWeight='700' pb={10}>
              Subdomain
            </Text>
          </Column>
          <InputRow>
            <CustomInput
              key='subdomain_name_key'
              id='subDomainName'
              name='subDomainName'
              type='text'
              autoCapitalize='none'
              placeholder='New Subdomain'
              value={subdomain}
              onBlur={handleBlur}
              onChange={handleChange}
              aria-describedby='subdomainError'
            />
            {subdomainError && <ErrorMessage id='subdomainError'>{subdomainError}</ErrorMessage>}
          </InputRow>
        </Row>
        )}

      </>
    </Wrapper>
  );
};

CustomLoginPage.propTypes = {
  containerWidth: PropTypes.number,
};

CustomLoginPage.defaultProps = {
  containerWidth: '',
};

export default CustomLoginPage;
