import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import Text from './Text/Text';
import iconSet from '../images/teambuildr-selection.json';

const TitleSection = styled('div')`
  display: flex;
  flex-direction: row;
  height: 40px;
  justify-content: space-between;
  align-items: center;
`;

const SessionInfoCard = ({
  title, desc, icon, bottom,
}) => {
  const theme = useTheme();

  const SessionInfoCardWrapper = styled('div')`
    background-color: ${theme.colors.background[0]};
    border-color: ${theme.colors.border};
    border-radius: 20px;
    border-width: 1px;
    flex: 1;
    height: 160px;
    width: 210px;
    margin: 0px 25px 10px 25px;
    padding: 20px;
    box-shadow: 0px 5px 20px ${theme.colors.background[3]};

    @media only screen and (max-width: 540px) and (min-width: 401px) {
      width: 46%;
      margin-left: 2%;
      margin-right: 2%;
    }
  `;

  return (
    <SessionInfoCardWrapper>
      <TitleSection>
        <Text fontSize={16} fontWeight='600' style={{ maxWidth: '70%' }}>
          {title}
        </Text>
        <IcomoonReact
          iconSet={iconSet}
          size={24}
          icon={icon}
        />
      </TitleSection>
      <Text fontSize={28} fontWeight='800' style={{ textAlign: 'right', marginTop: 10, marginBottom: 10 }}>
        {desc}
      </Text>
      <Text fontSize={14} fontWeight='500' style={{ textAlign: 'right' }}>
        {bottom}
      </Text>
    </SessionInfoCardWrapper>
  );
};

SessionInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]).isRequired,
  bottom: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default SessionInfoCard;
