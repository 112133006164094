import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';

import Layout from '../../shared/components/Layout/Layout';
import WorkoutsWrapper from './components/presentational/WorkoutsWrapper';
import WorkoutCarouselContainer from './components/presentational/WorkoutCarouselContainer';
import MediaModalContainer from './components/presentational/MediaModalContainer';

import OptOutModalContainer from './components/presentational/OptOutModalContainer';
import RestTimerModalContainer from './components/presentational/RestTimerModalContainer';
import RecordImageModalContainer from './components/presentational/RecordWorkoutsImageModalContainer';
import RecordVideoModalContainer from './components/presentational/RecordWorkoutsVideoModalContainer';
import WorkoutHistoryModalContainer from './components/presentational/WorkoutHistoryModalContainer';
import QuickJournalModalContainer from './components/presentational/QuickJournalModalContainer';
import DatePickerOverlayContainer from './components/presentational/DatePickerOverlayContainer';

import {
  getAthletes,
  setSharedAthlete,
  setActiveWorkoutDate,
  wipeOutNonDate,
} from './ducks/workoutsActions';
import JournalEntryModalContainer from './components/presentational/JournalEntryModalContainer';
import ExertionScoreModalContainer from './components/presentational/ExertionScoreModalContainer';
import PrintingOptionsModalContainer from './components/presentational/PrintingOptionsModalContainer';
import AdditionalInfoModalContainer from './components/presentational/AdditionalInfoModalContainer';

import getAllUrlParams from '../../shared/utils/getAllUrlParams';
import TagSelectionModalContainer from './components/presentational/TagSelectionModalContainer';
import MultiQuestionModalContainer from './components/presentational/MultiQuestionModalContainer';
import ProgramListBoxModalContainer from './components/presentational/ProgramListBoxModalContainer';
import ResetProgramModalContainer from './components/presentational/ResetProgramModalContainer';
import CompleteWorkoutModalContainer from './components/presentational/CompleteWorkoutModalContainer';
import SkipWorkoutModalContainer from './components/presentational/SkipWorkoutModalContainer';
import CompleteProgramModalContainer from './components/presentational/CompleteProgramModalContainer';
import ProgramInformationModalContainer from './components/presentational/ProgramInformationModalContainer';
import BottomSheetContainer from './components/presentational/BottomSheetContainer';

const PageWrapper = styled('div')`
  display: block;
  flex-direction: row;
  height: calc(100% - 49px);
  margin-top: 49px;
  @media only screen and (max-width : 768px) {
    height: calc(100%);
  }
  background: #F5F5F5;
  justify-content: center;
  overflow: scroll;
`;

const MarginComponent = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Workouts = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);

  const dispatch = useDispatch();

  useEffect(() => {
    // If the user is an admin, well fetch the list of athletes they are able to choose from here
    if (currentUser.admin) {
      dispatch(getAthletes(currentUser.accountCode));
      dispatch(wipeOutNonDate());
    } else {
      // Otherwise, we set selected athlete to the current user
      dispatch(setSharedAthlete(currentUser));
    }
  }, [currentUser]);

  useEffect(() => {
    const params = getAllUrlParams(window.location.href, false);
    if (params.date) {
      dispatch(setActiveWorkoutDate(params.date));
    }
  }, []);

  useEffect(() => {
    if (currentUser.admin) {
      dispatch(wipeOutNonDate());
    }
  }, [sharedAthlete]);

  // this component is accompanied by a series of containers that handle the various modals
  return (
    <Layout
      fullWidth
    >
      <Helmet>
        <title>Workouts | TeamBuildr</title>
      </Helmet>
      <PrintingOptionsModalContainer />
      <OptOutModalContainer />
      <RestTimerModalContainer />
      <RecordImageModalContainer />
      <RecordVideoModalContainer />
      <WorkoutHistoryModalContainer />
      <JournalEntryModalContainer />
      <ExertionScoreModalContainer />
      <QuickJournalModalContainer />
      <DatePickerOverlayContainer />
      <AdditionalInfoModalContainer />
      <TagSelectionModalContainer />
      <MultiQuestionModalContainer />
      <ProgramListBoxModalContainer />
      <ResetProgramModalContainer />
      <CompleteWorkoutModalContainer />
      <SkipWorkoutModalContainer />
      <CompleteProgramModalContainer />
      <ProgramInformationModalContainer />
      <PageWrapper>
        <WorkoutCarouselContainer />
        <MarginComponent>
          <WorkoutsWrapper />
        </MarginComponent>
      </PageWrapper>
      <BottomSheetContainer />
      <MediaModalContainer />
    </Layout>
  );
};

Workouts.propTypes = { };
export default Workouts;
