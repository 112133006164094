import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ResizeObserver from 'react-resize-observer';

import optimizeImage from '../../../../shared/utils/imageOptimizer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import ImportedDataModal from '../../../../shared/components/ImportedDataModal/ImportedDataModal';
import TBToggleSwitch from '../../../../shared/components/TBToggleSwitch/TBToggleSwitch';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import {
  setSharedAthlete,
  getWorkoutItemsForUserByDate,
  fetchWorkoutCalendar,
  setIsIntegrationModalShowing,
  setHiddenWorkoutsToggle,
} from '../../ducks/workoutsActions';

const SmallAthleteSelContainer = styled('div')`
	width: 100%;
	background: white;
	position: fixed;
	bottom: 0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
	min-width: 325px;
	display: flex;
	justify-content: space-between;
	border-radius: 24px 24px 0px 0px;
	box-shadow: rgb(48 49 51 / 5%) 8px 0px 1px, rgb(48 49 51 / 50%) 0px 2px 4px;
	padding-right: 20px;
	padding-left: 20px;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 30px;
  @media only screen and (min-width : 768px) {
    display: none;
  }
	cursor: pointer;
`;

const AthletePickerContainer = styled('div')`
  display: flex;
  background: white;
  z-index: 1002;
  position: absolute;
  box-shadow: ${BoxShadow};
	background: white;
  border-radius: 6px;
  flex-direction: column;
  color: '#444';
	left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
	width: 300px;
  @media only screen and (max-width : 768px) {
    width: 95%;
    top: 59px;
    height: calc(100% - 179px);
  }
  @media only screen and (min-width : 769px) {
    bottom: 120px
  }
`;

const AthleteContainer = styled('div')`
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: lightgrey;
  }
  img {
    height: 30px;
    width: 30px;
  }
  h3 {
    color: black;
  }
  svg {
    visibility: hidden;
  }
`;

const SearchContainer = styled('input')`
  display: flex;
  padding: 10px;
  cursor: text;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  @media screen and (orientation: portrait) {
    padding: 10px 20px!important;
  }
  border: none;
`;

const AthleteAvatar = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '45px')};
    width: ${(props) => (
    props.size ? `${props.size}px` : '45px')};
    border-radius: 50%;
  }
  display: flex;
  align-items: center;
`;

const AthleteAvatarList = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '25px')};
    width: ${(props) => (
    props.size ? `${props.size}px` : '25px')};
    border-radius: 50%;
		margin-right: 10px;
  }
  display: flex;
  align-items: center;
`;

const AthleteNameContainer = styled('div')`
  display: flex;
	flex-direction: column;
	justify-content: center;
`;

const AthleteNameContainerZap = styled('div')`
  display: flex;
	flex-direction: column;
	justify-content: center;
`;

const AthleteNameBase = styled('div')`
  display: flex;
  align-items: center;
	width: 100%;
	justify-content: space-between;
`;

const AthleteName = styled('h3')`
  font-family: 'Nunito Sans';
  color: #444;
  margin-bottom: 0px;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
`;

const AthleteSelectorContainer = styled('div')`

`;

const ImportedIntegrationsButton = styled('div')`
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #5B86E5 0%, #36D1DC 100%);
  border-radius: 25px;
  color: white;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; 
  cursor: pointer;
  padding: 10px 16px;
  position: absolute;
  bottom: 120px;
  box-shadow: ${BoxShadow};
  bottom: 140px;
  left: 50%;
  transform: translate(-50%, 50%);
  display: none;
  &:hover {
    background: #36D1DC;
  }

  @media only screen and (max-width : 768px) {
    display: flex;
  }
`;

const SwitchIconAndAv = styled('div')`
	position: relative;
`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
`;

const SwitchIcon = styled('div')`
	position: absolute;
	height: 20px;
	width: 20px;
	background: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0;
	margin-top: -7px;
	margin-right: -7px;
`;

const DayTitle = styled('div')`
	display: flex;
  font-weight: 400;
  font-family: 'Nunito Sans';
  align-items: center;
  font-size: 12px;
	color: lightgrey;
	margin-top: 2px;
	margin-left: 1px;
`;

const HiddenToggleContainer = styled('div')`
  display: flex;
  padding: 15px 25px;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px 15px 15px 15px;
  background: #fff;
  box-shadow: 0 -3px 6px -2px rgba(0, 0, 0, 0.1);
  gap: 12px;
`;

const HiddenToggleText = styled('div')`
  font-family: Nunito Sans;
  font-size: 18.48px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27.719px */
  flex: 1;
`;

/**
 * @param {Object} data an object that's passed in from the 'itemData' prop in the list component
 * seen below
 * @param {Integer} index the index of the current data object in the passed array
 * @param {Object} style necessary object that styles each individual container in accordance with
 * the requirements of the list
 * @returns a formatted list of dynamically rendered athlete rows
 */
const AthleteRenderer = ({ data, index, style }) => {
  const {
    list, onOptionClicked, setSearchedList, setIsUserSearching,
  } = data;
  const athlete = list[index];
  const image = optimizeImage(
    athlete.pic,
    {
      resize: {
        width: 70,
        height: 70,
        fit: 'cover',
      },
    },
  );

  return (
    <AthleteContainer
      onClick={() => {
        if (!athlete.fake) {
          onOptionClicked(athlete);
        }
        setSearchedList([]);
        setIsUserSearching(false);
      }}
      id={athlete.id}
      style={style}
    >
      {athlete.pic ? (
        <AthleteAvatarList>
          <img src={image} alt='' />
        </AthleteAvatarList>
      ) : null}
      <AthleteNameContainer>
        <AthleteName>
          <Text>
            {`${athlete.first} ${athlete.last}`}
          </Text>
        </AthleteName>
      </AthleteNameContainer>
    </AthleteContainer>
  );
};

/**
 *
 * @param {Array} list the list of athletes to be passed into a generated windowed list
 * @param {Function} reduxFunction the function to be called when the user clicks on
 * an athlete row in the windowed list
 * @param {Object} visibleContainerItem the item that is generated in the selector container
 * i.e. the currently selected item
 * @returns
 */
const SmallScreenAthleteSelector = () => {
  // state that manages whether the dropdown athlete list is showing
  const [isSelectorShowing, setIsSelectorShowing] = useState(false);
  const [searchedList, setSearchedList] = useState([]);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [adaptiveDropDownHeight, setAdaptiveDropDownHeight] = useState(300);
  const [listHeight, setListHeight] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);

  const list = useSelector(
    (state) => state.workouts.data.athletes,
  );
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeDayInfo = useSelector((state) => state.workouts.data.activeDayInfo);
  const importedDataIntegrations = useSelector((state) => state.workouts.data.importedDataIntegrations);
  const hiddenWorkoutsToggle = useSelector((state) => state.workouts.ui.hiddenWorkoutsToggle);

  const dispatch = useDispatch();

  const hasKeys = (obj) => Object.keys(obj).length > 0;

  const isCurrentUserAdmin = () => hasKeys(currentUser) && currentUser.admin;

  const getFormattedDay = () => activeWorkoutDate || moment().format('YYYY-MM-DD');

  const getStartAndEndDates = (formattedDay) => ({
    startDate: moment(formattedDay).subtract(15, 'days').format('YYYY-MM-DD'),
    endDate: moment(formattedDay).add(15, 'days').format('YYYY-MM-DD'),
  });

  // this is the function that we pass to the athlete picker component
  // when the current user selects a user from the list of athletes, this function runs
  // the shared athlete is a slice of state that we use in various places in the workouts
  // component to fetch specific information from the back end
  const reduxFunction = (athlete) => {
    const formattedDay = getFormattedDay(activeWorkoutDate);
    const userId = currentUser.admin ? athlete?.id : currentUser.id;
    const { startDate, endDate } = getStartAndEndDates(formattedDay);

    if (hasKeys(currentUser) && !currentUser.admin) {
      dispatch(getWorkoutItemsForUserByDate(currentUser, currentUser.id, formattedDay, false));
    } else if (isCurrentUserAdmin(currentUser) && hasKeys(athlete)) {
      dispatch(getWorkoutItemsForUserByDate(
        currentUser,
        athlete.id,
        formattedDay,
        false,
        hiddenWorkoutsToggle,
      ));
    }

    if (hasKeys(currentUser) && (
      (isCurrentUserAdmin(currentUser) && hasKeys(athlete)) || !currentUser.admin
    )) {
      dispatch(fetchWorkoutCalendar(
        currentUser,
        userId,
        startDate,
        endDate,
        hiddenWorkoutsToggle,
      ));
    }

    dispatch(setSharedAthlete(athlete));
  };

  useEffect(() => {
    const formattedDay = getFormattedDay(activeWorkoutDate);
    const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;
    const { startDate, endDate } = getStartAndEndDates(formattedDay);

    if (isCurrentUserAdmin(currentUser) && hasKeys(sharedAthlete)) {
      dispatch(getWorkoutItemsForUserByDate(
        currentUser,
        sharedAthlete.id,
        formattedDay,
        false,
        hiddenWorkoutsToggle,
      ));
      dispatch(fetchWorkoutCalendar(
        currentUser,
        userId,
        startDate,
        endDate,
        hiddenWorkoutsToggle,
      ));
    }
  }, [hiddenWorkoutsToggle]);

  useEffect(() => {
    if (isUserSearching && searchedList.length <= 4) {
      setAdaptiveDropDownHeight(searchedList.length * 50);
    } else if (isUserSearching && searchedList.length === 0) {
      setAdaptiveDropDownHeight(50);
    } else if (list.length <= 4) {
      setAdaptiveDropDownHeight(list.length * 50);
    } else {
      setAdaptiveDropDownHeight(listHeight);
    }
  }, [searchedList, list]);

  const onOptionClicked = (clickedOption) => {
    reduxFunction(clickedOption);
  };

  /**
   *
   * @param {Object} shownItem the object that appears as the item that's selected
   * from the list
   * @returns a formatted item for the selector container
   */

  const toggleFunction = () => {
    dispatch(setHiddenWorkoutsToggle(!hiddenWorkoutsToggle));
  };

  /**
   * Determines if a given element or its ancestors has a specific ID.
   *
   * @param {HTMLElement} element - The element to start the search from.
   * @param {string} id - The ID to search for.
   * @returns {boolean} - Returns true if the element or any of its ancestors has the given ID
   */
  const isClickInsideElementWithId = (element, id) => {
    let el = element;
    // Iterate over the element and its parent elements.
    while (el) {
      if (el.id === id) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  };

  const generateSelectedAthlete = (shownItem) => {
    let image;
    if (Object.keys(shownItem).length) {
      image = optimizeImage(
        shownItem.pic,
        {
          resize: {
            width: 70,
            height: 70,
            fit: 'cover',
          },
        },
      );
    }

    const titleGrabber = () => {
      if (!Object.keys(activeDayInfo).length || activeDayInfo?.title === null) {
        return moment(activeWorkoutDate).format('MMMM D, YYYY');
      }
      return activeDayInfo.title;
    };

    return (
      <SmallAthleteSelContainer
        onClick={() => setIsSelectorShowing(!isSelectorShowing)}
      >
        {Object.keys(sharedAthlete).length ? (
          <>
            <AthleteNameContainer>
              <AthleteName>
                {`${shownItem.first} ${shownItem.last}`}
              </AthleteName>
              <DayTitle>
                {titleGrabber()}
              </DayTitle>
            </AthleteNameContainer>
            <SwitchIconAndAv>
              <SwitchIcon>
                <IcomoonReact
                  iconSet={iconSet}
                  size={10}
                  icon='swap'
                  color='black'
                />
              </SwitchIcon>
              <AthleteAvatar>
                <img src={image} alt='' />
              </AthleteAvatar>
            </SwitchIconAndAv>
          </>
        ) : (
          <>
            <AthleteNameBase>
              <AthleteNameContainer>
                <AthleteName>
                  Select Athlete
                </AthleteName>
                <DayTitle>
                  {titleGrabber()}
                </DayTitle>
              </AthleteNameContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={40}
                icon='user'
                color='#444444'
              />
            </AthleteNameBase>
          </>
        )}
      </SmallAthleteSelContainer>
    );
  };

  const listMaker = () => {
    if (isUserSearching) {
      if (searchedList.length) {
        return searchedList;
      }
    }
    return list;
  };

  const handleButtonClick = () => {
    dispatch(setIsIntegrationModalShowing(true));
  };

  return (
    <>
      {importedDataIntegrations?.length > 0 && <ImportedIntegrationsButton onClick={handleButtonClick}>View Imported Data</ImportedIntegrationsButton>}
      <AthleteSelectorContainer
        onClick={(e) => {
          if (!isClickInsideElementWithId(e.target, 'list-searcher')
              && !isClickInsideElementWithId(e.target, 'hidden-workouts-toggle')) {
            setIsSelectorShowing(!isSelectorShowing);
          }
        }}
        isSelectorShowing={isSelectorShowing}
      >
        {generateSelectedAthlete(sharedAthlete)}
        {isSelectorShowing ? (
          <AthletePickerContainer>
            <ResizeObserver
              onResize={(rect) => {
                setAdaptiveDropDownHeight(rect.height);
                setListHeight(rect.height);
              }}
            />
            <SearchContainer
              id='list-searcher'
              placeholder='search'
              autoFocus
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setIsUserSearching(true);
                  const newList = list.filter(
                    (item) => item.first.toLowerCase().includes(e.target.value.toLowerCase())
                      || item.last.toLowerCase().includes(e.target.value.toLowerCase()),
                  );
                  if (!newList.length) {
                    setSearchedList([{
                      first: 'No Athletes Found',
                      fake: true,
                      last: '',
                      pic: '',
                    }]);
                  } else {
                    setSearchedList(newList);
                  }
                } else {
                  setIsUserSearching(false);
                  setSearchedList([]);
                }
              }}
            />
            <List
              height={adaptiveDropDownHeight}
              itemData={{
                list: listMaker(),
                onOptionClicked,
                setSearchedList,
                setIsUserSearching,
              }}
              itemCount={!isUserSearching ? list.length : searchedList.length}
              itemSize={55}
              width='100%'
            >
              {AthleteRenderer}
            </List>
            <HiddenToggleContainer id='hidden-workouts-toggle'>
              <HiddenToggleText> View workouts hidden from athletes</HiddenToggleText>
              <TBToggleSwitch
                checked={hiddenWorkoutsToggle}
                onChange={toggleFunction}
              />
            </HiddenToggleContainer>
          </AthletePickerContainer>
        ) : null}
      </AthleteSelectorContainer>
      {isSelectorShowing
        ? (
          <Overlay onClick={() => {
            setIsSelectorShowing(false);
          }}
          />
        )
        : null}
      {isModalOpen && (
      <ImportedDataModal
        isModalOpen={isModalOpen}
        importedDataIntegrations={importedDataIntegrations}
      />
      )}
    </>
  );
};

AthleteRenderer.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.instanceOf(Object).isRequired,
};

SmallScreenAthleteSelector.propTypes = {

};

export default SmallScreenAthleteSelector;
