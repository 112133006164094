import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import WorkoutReportSection from './ReportSection';
import EmptyReportSection from './EmptyReportSection';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  @media screen and (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: 540px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ReportContainer = styled('div')`
  width: 100%;
`;

const ReportData = ({ setCurrentAthlete, setHeaderDate }) => {
  const wRORReport = useSelector((state) => state.reporting.data.wRORReport);

  if (!wRORReport) return null;

  return (
    <Wrapper>
      <ReportContainer id='printable-results-content'>
        {wRORReport.map((user, index) => {
          const SectionComponent = user.dates.length ? WorkoutReportSection : EmptyReportSection;
          return (
            <div key={user.firstName + user.lastName}>
              <SectionComponent
                setHeaderDate={setHeaderDate}
                setCurrentAthlete={setCurrentAthlete}
                user={user}
                lastUser={wRORReport.length - 1 === index}
              />
            </div>
          );
        })}
      </ReportContainer>
    </Wrapper>
  );
};

ReportData.propTypes = {
  setCurrentAthlete: PropTypes.func.isRequired,
  setHeaderDate: PropTypes.func.isRequired,
};

export default ReportData;