import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SpinnerWrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = ({
  darkTheme,
  saving,
}) => {
  const isDarkTheme = darkTheme ? 'dark-saving-path' : 'saving-path';
  return (
    <SpinnerWrapper className='spinner-container'>
      <svg className={saving ? 'saving-spinner' : 'spinner'} width={saving ? '16px' : '65px'} height={saving ? '16px' : '65px'} viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
        <circle className={saving ? isDarkTheme : 'path'} fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30' />
      </svg>
    </SpinnerWrapper>
  );
};

Spinner.propTypes = {
  darkTheme: PropTypes.bool,
  saving: PropTypes.bool,
};

Spinner.defaultProps = {
  darkTheme: false,
  saving: false,
};

export default Spinner;
