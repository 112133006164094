import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import styled from '@emotion/styled';
import Logger from 'js-logger';

import Select from 'react-select';
import { FormGroup } from '../../../../shared/components/FormHandler/FormHandler';
import FormLabel from '../../../../shared/components/FormLabel/FormLabel';

const FormColumns = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  label {
    margin-bottom: 0px;
  }
`;

const ColumnContent = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
  flex-direction: column;
  padding: 15px 15px 0px 0px;
  min-width: 50%;
  max-width: 50%;
  position: relative;

  @media screen and (max-width: 540px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

const customStyles = {
  option: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
  control: (styles) => ({
    ...styles,
    cursor: 'pointer',
  }),
};

const EvaluationReportFormSlideOne = ({
  formProps,
  evaluations,
}) => (
  <>
    <FormGroup>
      <FormLabel>Select an Evaluation</FormLabel>
      <Field
        name='evaluation'
        render={() => (
          <>
            <FormColumns>
              <ColumnContent>
                <FormLabel>
                  Evaluation
                </FormLabel>
                <Select
                  styles={customStyles}
                  classNamePrefix='react-select'
                  defaultValue={formProps.values.evaluation}
                  options={evaluations}
                  getOptionLabel={(option) => option.title}
                  getOptionValue={(option) => option.id}
                  name='evaluation'
                  onChange={(option) => {
                    formProps.setFieldValue('evaluation', option);
                    Logger.debug('Evaluation', formProps.values.evaluation);
                  }}
                  isOptionSelected={(option) => formProps.values.evaluation === option.id}
                  value={formProps.values.evaluation}
                />
              </ColumnContent>
            </FormColumns>
          </>
        )}
      />
    </FormGroup>
  </>
);

EvaluationReportFormSlideOne.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  evaluations: PropTypes.instanceOf(Array),
};

EvaluationReportFormSlideOne.defaultProps = {
  evaluations: [],
};

export default EvaluationReportFormSlideOne;
