import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const MaxTempoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 25%;
  color: ${props => props.theme.lightTextColor2};
`;

const CoachCopy = styled('p')`
  font-size: 12px;
  width: 80%;
  color: ${props => props.theme.lightTextColor2};
`;

const CoachNotes = ({
  info,
  isTrackingActive,
  label,
  max,
  tempo,
  restTime,
}) => (
  <div className='coach_notes'>
    {(isTrackingActive || restTime > 0 || tempo !== '') ? (
      <MaxTempoContainer>
        {isTrackingActive && (
          <span>
            {`${label}: ${max === 0 || max === null ? 'N/A' : max}`}
          </span>
        )}
        { tempo && (
          <span>
            {`Tempo: ${tempo}`}
          </span>
        )}
        { restTime ? (
          <span>
            {`Rest: ${restTime}s`}
          </span>
        ) : ('')}
      </MaxTempoContainer>
    ) : ('')}
    <CoachCopy>{info.length <= 255 ? info : `${info.substr(0, 255)} ...`}</CoachCopy>
  </div>
);

CoachNotes.propTypes = {
  info: PropTypes.string.isRequired,
  isTrackingActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  tempo: PropTypes.string.isRequired,
  restTime: PropTypes.number.isRequired,

};

export default memo(CoachNotes);
