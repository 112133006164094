import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  authed,
  registered,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      (props) => (
        authed && registered
          ? <Component {...props} />
          : (
            <Route
              path={path}
              component={() => {
                if (authed && !registered) {
                  console.log('here');
                  window.location.href = `${process.env.PHP_APP_URL || 'http://localhost'}/register`;
                } else {
                  if (process.env.ENVIRONMENT === 'LOCAL') {
                    window.location.href = `${process.env.WEBAPP_URL || 'http://localhost:8080'}/login`;
                  }
                  if (process.env.ENVIRONMENT !== 'LOCAL') {
                    let domain;
                    if (process.env.WEBAPP_URL) {
                      domain = process.env.WEBAPP_URL;
                    } else {
                      const isDevelop = process.env.ENVIRONMENT === 'DEVELOP' ? 'dev.app-v3' : 'app.';
                      domain = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.app-v3.' : isDevelop}teambuildr.com`;
                    }
                    window.location.href = `${domain}/login?rel=${path.replace('/', '')}&version=2`;
                  }
                  // window.location.href = `http://localhost:8080/login`;
                  // window.location.href = `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}app-v3.teambuildr.com/login`;
                }

                return null;
              }}
            />
            // <Redirect to={`/login?rel=${path.replace('/', '')}&version=2`} />
          )
      )
    }
  />
);

export default PrivateRoute;
