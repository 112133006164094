import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const CarouselContainer = styled('div')`
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

const Wrapper = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
`;

const CarouselInner = styled('div')`
  white-space: nowrap;
  transition: transform 0.3s;
  transform: ${(props) => `translateX(-${props.activeIndex * 100}%)`};
  height: 100%;
  width: 100%;
`;

const CarouselItem = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: green;
  color: #fff;
  height: 100%;
  width: 100%;
`;

const Indicators = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const PaddingWrapper = styled('div')`
  padding: 20px;
`;

const IndicatorButton = styled('div')`
  margin: 5px;
  background: #444444;
  height: 15px;
  width: 15px;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  border-radius: 50%;
  cursor: pointer;
  :hover {
    opacity: ${(props) => (!props.isActive ? 0.5 : 0.8)};
  }
  transition: all .3s ease;
`;

const Title = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  color: #737373;
  width: 100%;
`;

const ButtonsWrapper = styled('div')`
  display: flex;
  max-width: 200px;
  user-select: none;
  .mc-next-button {
    background: #FFA500;
    color: white;
    border-radius: 4px;
    height: 32px;
    transition: all 0.5s ease;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
  }
  .mc-back-button {
    border: 1px solid #FFA500;
    color: #FFA500;
    border-radius: 4px;
    height: 32px;
    background: none;
    transition: all 0.5s ease;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
  }
`;

const TextBox = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const DescriptionText = styled('div')`
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 5px;
  color: #737373;
`;

const CarouselButton = styled('div')`
  opacity: ${(props) => ((props.activeIndex === 0 && props.back) || (props.activeIndex === 3 && props.next) ? '0.1' : '1')};
`;

const MarketingCarousel = ({ title, descriptionText }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Wrapper>
      <CarouselContainer>
        <CarouselInner activeIndex={activeIndex}>
          <CarouselItem>
            <div>Item 1</div>
          </CarouselItem>
          <CarouselItem>
            <div>Item 2</div>
          </CarouselItem>
          <CarouselItem>
            <div>Item 3</div>
          </CarouselItem>
          <CarouselItem>
            <div>Item 4</div>
          </CarouselItem>
        </CarouselInner>
      </CarouselContainer>
      <Indicators>
        <IndicatorButton isActive={activeIndex === 0} onClick={() => setActiveIndex(0)} />
        <IndicatorButton isActive={activeIndex === 1} onClick={() => setActiveIndex(1)} />
        <IndicatorButton isActive={activeIndex === 2} onClick={() => setActiveIndex(2)} />
        <IndicatorButton isActive={activeIndex === 3} onClick={() => setActiveIndex(3)} />
      </Indicators>
      <PaddingWrapper>
        <TextBox>
          <Title>{title}</Title>
          <DescriptionText>
            {descriptionText}
          </DescriptionText>
        </TextBox>
        <ButtonsWrapper>
          <CarouselButton
            className='mc-back-button'
            activeIndex={activeIndex}
            back
            onClick={() => {
              if (activeIndex > 0) {
                setActiveIndex(activeIndex - 1);
              }
            }}
          >
            <div style={{
              marginTop: 4,
            }}
            >
              BACK
            </div>
          </CarouselButton>
          <CarouselButton
            activeIndex={activeIndex}
            className='mc-next-button'
            next
            onClick={() => {
              if (activeIndex < 3) {
                setActiveIndex(activeIndex + 1);
              }
            }}
          >
            <div style={{
              marginTop: 4,
            }}
            >
              NEXT
            </div>
          </CarouselButton>
        </ButtonsWrapper>
      </PaddingWrapper>
    </Wrapper>
  );
};

MarketingCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
};

export default MarketingCarousel;
