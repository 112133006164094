import { toast } from 'react-toastify';
import {
  FETCH_TEMPLATES_START,
  FETCH_TEMPLATES_FULFILLED,
  FETCH_TEMPLATES_REJECTED,
  SET_DELETE_DASHBOARD_MODAL,
  SET_ABOUT_DASHBOARD_MODAL,
  SET_SC_DASH_VIEW,
  SET_EDIT_DASHBOARD_MODAL,
  SET_SHARE_DASHBOARD_MODAL,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_SCDASH_DATA_START,
  FETCH_SCDASH_DATA_FULFILLED,
  FETCH_SCDASH_DATA_REJECTED,
  SET_GROUP,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_SELECTED_ATHLETE_GROUP,
  SET_START_DATE_CAL,
  SET_START_DATE_DATA,
  SET_END_DATE_CAL,
  SET_END_DATE_DATA,
  SET_DATE_TYPE,
  SET_IS_LEGEND_INFO_MODAL_SHOWING,
  SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING,
  SET_EXER_FILTERS_PERFORMANCE,
  SET_IS_EXER_FILTERS_GROUP_SHOWING,
  SET_EXER_FILTERS_GROUP,
  SET_MAX_TYPE,
  SET_EXERCISES,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  SET_SAVE_DASHBOARD_MODAL,
  CREATE_DASHBOARD_START,
  CREATE_DASHBOARD_FULFILLED,
  CREATE_DASHBOARD_REJECTED,
  DELETE_DASHBOARD_START,
  DELETE_DASHBOARD_FULFILLED,
  DELETE_DASHBOARD_REJECTED,
  SET_TRI_OPTION_MODAL,
  FETCH_SHARE_DASH_LINK_START,
  FETCH_SHARE_DASH_LINK_FULFILLED,
  FETCH_SHARE_DASH_LINK_REJECTED,
  FETCH_PUBLIC_SCDASH_DATA_START,
  FETCH_PUBLIC_SCDASH_DATA_FULFILLED,
  FETCH_PUBLIC_SCDASH_DATA_REJECTED,
  EDIT_DASHBOARD_START,
  EDIT_DASHBOARD_FULFILLED,
  EDIT_DASHBOARD_REJECTED,
  CLEAR_SHARE_DASH_LINK,
  SET_ACTIVE_TEMPLATE,
  SET_IS_UNSAVED_DASH_MODAL_SHOWING,
  SET_NEXT_LOCATION,
  SET_TEMPLATE,
  SET_IS_OVERVIEW,
  SET_DUPLICATE_MODAL_SHOWING,
  DUPLICATE_DASHBOARD_START,
  DUPLICATE_DASHBOARD_FULFILLED,
  DUPLICATE_DASHBOARD_REJECTED,
  SET_GROUPS,
  CLEAR_SC_DASH_DATA,
} from './actionTypes';

import { axiosAuthed, axiosUnauthed } from '../../../shared/utils/setCommonHeaders';

export const fetchTemplates = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_TEMPLATES_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/sport-coach/templates`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_TEMPLATES_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_TEMPLATES_REJECTED,
        });
      });
  }
);

export const createDashboard = (accountCode, submissionObject) => (
  (dispatch) => {
    dispatch({ type: CREATE_DASHBOARD_START });
    axiosAuthed.post(`/accounts/${accountCode}/reports/sport-coach/templates`, submissionObject)
      .then((response) => {
        toast.success('Dashboard Created!');
        dispatch({
          payload: response.data,
          type: CREATE_DASHBOARD_FULFILLED,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.headers?.message || 'An error has occurred');
        dispatch({
          payload: err,
          type: CREATE_DASHBOARD_REJECTED,
        });
      });
  }
);

export const editDashboard = (accountCode, submissionObject, templateId, index) => (
  (dispatch) => {
    dispatch({ type: EDIT_DASHBOARD_START });
    axiosAuthed.put(`/accounts/${accountCode}/reports/sport-coach/templates/${templateId}?resetGroups=false&resetExercises=false`, submissionObject)
      .then((response) => {
        toast.success('Edit Successful!');
        dispatch({
          payload: {
            data: response.data,
            index,
          },
          type: EDIT_DASHBOARD_FULFILLED,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.headers?.message || 'An error has occurred');
        dispatch({
          payload: err,
          type: EDIT_DASHBOARD_REJECTED,
        });
      });
  }
);

export const deleteDashboard = (accountCode, templateId, templateIndex) => (
  (dispatch) => {
    dispatch({ type: DELETE_DASHBOARD_START });
    axiosAuthed.delete(`/accounts/${accountCode}/reports/sport-coach/templates/${templateId}`)
      .then((response) => {
        toast.success('Template Deleted!');
        dispatch({
          payload: { data: response.data, index: templateIndex },
          type: DELETE_DASHBOARD_FULFILLED,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.headers?.message || 'An error has occurred');
        dispatch({
          payload: err,
          type: DELETE_DASHBOARD_REJECTED,
        });
      });
  }
);

export const fetchExercises = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_EXERCISES_START });
    axiosAuthed.get(`/accounts/${accountCode}/exercises?tracked=1`)
      .then((response) => {
        dispatch({
          type: FETCH_EXERCISES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_EXERCISES_REJECTED,
          payload: err,
        });
      });
  }
);

// Fetch available groups for the current account
export const fetchGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({
          payload: response.data,
          type: FETCH_GROUPS_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_GROUPS_REJECTED,
        });
      });
  }
);

/*
  Fetch data for performance overview chart, metric cards, and group analysis table
  Params:
  accountCode - current user's account code
  startDate/endDate - date range to fetch data for
  groupId - group to fetch data for based on ID
  exerIds - array of exercise IDs to get max data for
  dateType - 1 = monthly averages, 2 = daily averages
  maxType - 1 = all, 2 = manual input only, 3 - generated only
*/
export const fetchSCDashData = (
  accountCode, startDate, endDate, groupId, exerIds, dateType, maxType,
) => (
  (dispatch) => {
    dispatch({ type: FETCH_SCDASH_DATA_START });
    axiosAuthed.get(
      `/accounts/${accountCode}/reports/sport-coach?startDate=${startDate}&endDate=${endDate}&groupId=${groupId}&exerciseIds=${exerIds}&dateType=${dateType}&maxType=${maxType}`,
    )
      .then((response) => {
        dispatch({
          payload: {
            athleteList: response.data.athleteList,
            cardData: response.data.cardData,
            chartData: response.data.chartData,
            scDashData: response.data,
          },
          type: FETCH_SCDASH_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err,
          type: FETCH_SCDASH_DATA_REJECTED,
        });
      });
  }
);

// Create a shareable link for the current dashboard
export const fetchShareDashLink = (
  accountCode, submissionObj,
) => (
  (dispatch) => {
    dispatch({ type: FETCH_SHARE_DASH_LINK_START });
    axiosAuthed.post(`/accounts/${accountCode}/reports/sport-coach/link`, submissionObj)
      .then((response) => {
        dispatch({
          payload: response.data.url,
          type: FETCH_SHARE_DASH_LINK_FULFILLED,
        });
      })
      .catch(() => {
        dispatch({
          payload: true,
          type: FETCH_SHARE_DASH_LINK_REJECTED,
        });
      });
  }
);

// Fetch SC Dash data from public route using token
export const fetchPublicSCDashData = (token) => (
  (dispatch) => {
    dispatch({ type: FETCH_PUBLIC_SCDASH_DATA_START });
    axiosUnauthed.get(`/public/reports/sport-coach?token=${token}`)
      .then((response) => {
        dispatch({
          payload: {
            athleteList: response.data.athleteList,
            cardData: response.data.cardData,
            chartData: response.data.chartData,
            scDashData: response.data,
          },
          type: FETCH_PUBLIC_SCDASH_DATA_FULFILLED,
        });
      })
      .catch((err) => {
        dispatch({
          payload: err?.response?.headers?.message,
          type: FETCH_PUBLIC_SCDASH_DATA_REJECTED,
        });
      });
  }
);

// Create a duplicate dashboard/template based on the ID
export const duplicateDashboard = (accountCode, templateId, submissionObject) => (
  (dispatch) => {
    dispatch({ type: DUPLICATE_DASHBOARD_START });
    axiosAuthed.post(`/accounts/${accountCode}/reports/sport-coach/templates/${templateId}/duplicate`, submissionObject)
      .then((response) => {
        toast.success('Template Duplicated!');
        dispatch({
          payload: response.data,
          type: DUPLICATE_DASHBOARD_FULFILLED,
        });
      })
      .catch((err) => {
        toast.error(err?.response?.headers?.message || 'An error has occurred');
        dispatch({
          payload: err,
          type: DUPLICATE_DASHBOARD_REJECTED,
        });
      });
  }
);

export const setDeleteDashboardModal = (object) => (
  {
    type: SET_DELETE_DASHBOARD_MODAL,
    payload: object,
  }
);

export const setActiveTemplate = (object) => (
  {
    type: SET_ACTIVE_TEMPLATE,
    payload: object,
  }
);

export const setAboutDashboardModalShowing = (bool, template = null) => (
  {
    type: SET_ABOUT_DASHBOARD_MODAL,
    payload: {
      bool,
      template,
    },
  }
);

export const setEditDashboardModal = (object) => (
  {
    type: SET_EDIT_DASHBOARD_MODAL,
    payload: object,
  }
);

export const setTriOptionModal = (bool) => (
  {
    type: SET_TRI_OPTION_MODAL,
    payload: bool,
  }
);

export const setIsOverview = (bool) => (
  {
    type: SET_IS_OVERVIEW,
    payload: bool,
  }
);

export const setShareDashboardModal = (bool, template = null) => (
  {
    type: SET_SHARE_DASHBOARD_MODAL,
    payload: {
      bool,
      template,
    },
  }
);

// Performance Overview and Group Analysis Redux Actions
export const setSCDashView = (view) => (
  (dispatch) => {
    dispatch({
      payload: view,
      type: SET_SC_DASH_VIEW,
    });
  }
);

export const setGroup = (group) => (
  (dispatch) => {
    dispatch({
      payload: group,
      type: SET_GROUP,
    });
  }
);

export const setGroups = (groups) => (
  (dispatch) => {
    dispatch({
      payload: groups,
      type: SET_GROUPS,
    });
  }
);

export const setSelectedAthletePerformance = (athlete, bool) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
        bool,
      },
      type: SET_SELECTED_ATHLETE_PERFORMANCE,
    });
  }
);

export const setSelectedAthleteGroup = (athlete, bool) => (
  (dispatch) => {
    dispatch({
      payload: {
        athlete,
        bool,
      },
      type: SET_SELECTED_ATHLETE_GROUP,
    });
  }
);

export const setStartDateCal = (startDate) => (
  (dispatch) => {
    dispatch({
      payload: startDate,
      type: SET_START_DATE_CAL,
    });
  }
);

export const setEndDateCal = (endDate) => (
  (dispatch) => {
    dispatch({
      payload: endDate,
      type: SET_END_DATE_CAL,
    });
  }
);

export const setStartDateData = (startDate) => (
  (dispatch) => {
    dispatch({
      payload: startDate,
      type: SET_START_DATE_DATA,
    });
  }
);

export const setEndDateData = (endDate) => (
  (dispatch) => {
    dispatch({
      payload: endDate,
      type: SET_END_DATE_DATA,
    });
  }
);

export const setDateType = (type) => (
  (dispatch) => {
    dispatch({
      payload: type,
      type: SET_DATE_TYPE,
    });
  }
);

export const setIsLegendInfoModalShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_LEGEND_INFO_MODAL_SHOWING,
    });
  }
);

export const setIsExerFiltersPerformanceShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_EXER_FILTERS_PERFORMANCE_SHOWING,
    });
  }
);

export const setExerFiltersPerformance = (exers) => (
  (dispatch) => {
    dispatch({
      payload: exers,
      type: SET_EXER_FILTERS_PERFORMANCE,
    });
  }
);

export const setIsExerFiltersGroupShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_EXER_FILTERS_GROUP_SHOWING,
    });
  }
);

export const setExerFiltersGroup = (exers) => (
  (dispatch) => {
    dispatch({
      payload: exers,
      type: SET_EXER_FILTERS_GROUP,
    });
  }
);

export const setSaveDashboardModal = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_SAVE_DASHBOARD_MODAL,
    });
  }
);

export const setMaxType = (type) => (
  (dispatch) => {
    dispatch({
      payload: type,
      type: SET_MAX_TYPE,
    });
  }
);

export const setExercises = (exers) => (
  (dispatch) => {
    dispatch({
      payload: exers,
      type: SET_EXERCISES,
    });
  }
);

export const clearShareDashLink = () => (
  (dispatch) => {
    dispatch({
      type: CLEAR_SHARE_DASH_LINK,
    });
  }
);

export const setIsUnsavedDashModalShowing = (bool) => (
  (dispatch) => {
    dispatch({
      payload: bool,
      type: SET_IS_UNSAVED_DASH_MODAL_SHOWING,
    });
  }
);

export const setNextLocation = (location) => (
  (dispatch) => {
    dispatch({
      payload: location,
      type: SET_NEXT_LOCATION,
    });
  }
);

export const setTemplate = (template) => (
  (dispatch) => {
    dispatch({
      payload: template,
      type: SET_TEMPLATE,
    });
  }
);

export const setDuplicateModalShowing = (bool, template = null) => (
  (dispatch) => {
    dispatch({
      payload: {
        bool,
        template,
      },
      type: SET_DUPLICATE_MODAL_SHOWING,
    });
  }
);

export const clearSCDashData = () => (
  (dispatch) => {
    dispatch({
      type: CLEAR_SC_DASH_DATA,
    });
  }
);
