/**
 * FilterSubtype component that accepts an object representing a filter subtype
 * e.g. feedPost, feedPostComment, etc.
 * */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import {
  setLocalNotificationFilters,
} from '../../ducks/notificationsActions';

const FilterSubtypeDiv = styled('div')`
	display: flex;
	align-items: center;
  height: 50px;
`;

/**
 * @param {Object} subType data representing a filter sub type
 * @returns a styled component representing the filter sub type
 */
const FilterSubtype = ({ subType }) => {
  // object representing currently active filters
  const localNotificationFilters = useSelector(
    (state) => state.notifications.data.localNotificationFilters,
  );

  const dispatch = useDispatch();

  return (
    <FilterSubtypeDiv>
      {/**
       * checkbox is checked when notification type exists in active filters
       * if it doesn't exist, then it adds it and increments the parents
       * tracker for selected interior components by 1
       * if it does exists, it sets it to false and decrements the parent's
       * tracker count by 1
       * sets the local filters to the updated object
       */}
      <Checkbox
        checked={localNotificationFilters[subType.notificationKey] || false}
        color='primary'
        onChange={() => {
          const newNotificationFilters = { ...localNotificationFilters };
          newNotificationFilters[
            subType.notificationKey
          ] = !localNotificationFilters[
            subType.notificationKey
          ];
          dispatch(setLocalNotificationFilters(newNotificationFilters));
        }}
      />
      {/**
       * name of the filter type
       */}
      {subType.notificationName}
    </FilterSubtypeDiv>
  );
};

FilterSubtype.propTypes = {
  subType: PropTypes.instanceOf(Object).isRequired,
};

export default FilterSubtype;
