import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTransition, animated } from 'react-spring/web.cjs';
import ResizeObserver from 'react-resize-observer';

import WeightroomPanelAthletes from '../container/WeightroomPanelAthletes';
import WeightroomPanelWorkouts from '../container/WeightroomPanelWorkouts';

const PanelContainer = styled('div')`
  display: flex;
  position: relative;
  ${(props) => (
    props.activeScreens === 4 ? `
      height: calc((var(--vh, 1vh) * 50) - 25px);
    ` : `
      height: calc((var(--vh, 1vh) * 100) - 50px);
    `
  )}
 
  ${(props) => (
    props.activeScreens === 1 ? `
      width: 100%;
    ` : `
      width: 50%;
    `
  )}
  background-color: ${(props) => props.theme.backgroundColor};
  border-right: 1px solid rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 305px;

  &:nth-of-type(even) {
    border-right: 0px;
  }

  @media screen and (orientation: portrait) {
    ${(props) => (
    props.activeScreens === 2 && `
      height: calc((var(--vh, 1vh) * 50) - 25px);
      width: 100%;
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${(props) => (
    props.activeScreens === 2 && `
      height: calc((var(--vh, 1vh) * 100) - 50px);
      width: 50%;
    `
  )}
    .pin-container {
      transform: scale(.7);
    }

  }

  @media screen and (max-width: 799px) {
    .pin-container {
      transform: scale(.6);
    }
  }
  @media screen and (max-width: 601px) {
    .dropdown-link {
      font-size: 10px;
    }
  }
`;

const Panel = ({
  namespace,
  storePanelHeight,
}) => {
  const athleteChosen = useSelector((
    state,
  ) => state.weightRoomView[namespace].athleteChosen);
  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);
  const isPinVerified = useSelector((
    state,
  ) => state.weightRoomView[namespace].isPinVerified);

  const panelRef = useRef();

  useEffect(() => {
    const listener = () => (
      storePanelHeight(panelRef.current.clientHeight, panelRef.current.clientWidth, namespace)
    );
    storePanelHeight(panelRef.current.clientHeight, panelRef.current.clientWidth, namespace);
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const handlePanelResize = (rect) => {
    storePanelHeight(rect.height, rect.width, namespace);
  };

  const isAthleteVerified = isPinVerified;
  const isAthleteChosen = athleteChosen === true;
  const isAthleteReady = (isAthleteChosen && isAthleteVerified);
  const isSilkBrowser = /\bSilk\b/.test(navigator.userAgent);

  const transitions = useTransition(isAthleteReady, null, {
    from: { position: 'absolute', opacity: 0, transform: 'translate3d(100%, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0, 0)' },
  });

  const silkPanels = isAthleteReady ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <WeightroomPanelWorkouts
        namespace={namespace}
      />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <WeightroomPanelAthletes
        namespace={namespace}
      />
    </div>
  );

  return (
    <PanelContainer
      activeScreens={activeScreens}
      id={namespace}
      ref={panelRef}
    >
      {isSilkBrowser ? (
        silkPanels
      ) : (
        transitions.map(({ item, key, props }) => (
          item
            ? (
              <animated.div
                key={key}
                style={{
                  ...props,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  minHeight: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <WeightroomPanelWorkouts
                  namespace={namespace}
                />
              </animated.div>
            )
            : (
              <animated.div
                key={key}
                style={{
                  ...props,
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  minHeight: 0,
                  width: '100%',
                  height: '100%',
                }}
              >
                <WeightroomPanelAthletes
                  namespace={namespace}
                />
              </animated.div>
            )
        ))
      )}
      <ResizeObserver
        onResize={handlePanelResize}
      />
    </PanelContainer>
  );
};

Panel.propTypes = {
  namespace: PropTypes.string.isRequired,
  storePanelHeight: PropTypes.func.isRequired,
};

export default Panel;
