import React from 'react';
import { animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { ColorBlack, ColorGreen } from '../../../../shared/GlobalStyles';

export const ButtonStyled = styled('button')`
  display: flex;
  flex-direction: row;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.large ? '14px' : '13px')};
  font-weight: 700;
  letter-spacing: 1.5px;
  height: ${(props) => (props.large && '64px')};
  width: ${(props) => (props.fullWidth ? '100%' : '50%')};
  background: ${(props) => {
    const isDisabled = props.disabled ? '#d3d3d3' : props.theme.colorAccent;
    const isIconOnly = props.iconOnly ? 'transparent' : isDisabled;
    const isCustom = props.customColor ? props.customColor : isIconOnly;
    const isPrimary = props.primary ? isCustom : props.theme.colors.background[1];
    return (
      props.complete ? ColorGreen : isPrimary
    );
  }};
  border: ${(props) => {
    const hasNoBorder = props.noBorder || props.iconOnly ? '0px' : `1px solid ${props.borderColor || props.theme.colors.noBorderColor}`;
    return (
      props.complete ? `1px solid ${ColorGreen}` : hasNoBorder
    );
  }};
  border-radius: ${(props) => {
    const isRounded = props.rounded ? '4px' : '20px';
    const borderRadius = props.customBorderRadius || isRounded;
    return (
      props.square ? '0px' : borderRadius
    );
  }};
  text-transform: ${(props) => (props.upperCase ? 'uppercase' : 'none')};
  color: white;
  padding: ${(props) => (props.iconOnly ? '5px' : '16px')};
  justify-content: center;
  align-self: ${(props) => (props.bottom ? 'flex-end' : '')};
  align-items: center;
  transition: all 250ms ease-in-out;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '.4' : '1')};
  & svg {
    display: ${(props) => (props.icon || props.isLoading ? 'block' : 'none')}!important;
    align-self: center;
    margin-right: ${(props) => (props.iconOnly ? '0px' : '10px')};
    margin-top: ${(props) => (props.iconMarginTop || '0px')};
    path {
      transition: all 250ms ease-in-out;
      fill: ${(props) => {
    const isIconOnly = props.iconOnly ? props.theme.colors.lightText[0] : props.theme.textColor;
    return props.complete ? 'rgba(255,255,255)!important' : isIconOnly;
  }}
    }
  }

  &:hover {
    opacity: ${(props) => (props.noHover ? '1' : '0.7')};
  }
`;

const Icon = styled('img')`
  height: 20px;
  margin-right: 20px;
`;

const BlueButton = ({
  bottom,
  borderColor,
  cta,
  className,
  complete,
  customColor,
  customTextColor,
  customBorderRadius,
  customIcon,
  disabled,
  fullWidth,
  id,
  icon,
  iconMarginTop,
  iconOnly,
  large,
  name,
  noBorder,
  noHover,
  onClick,
  primary,
  rounded,
  square,
  type,
  isLoading,
  upperCase,
}) => {
  const spinnerAnimation = useSpring({ opacity: 1 });
  return (
    <ButtonStyled
      bottom={bottom}
      borderColor={borderColor}
      className={className}
      complete={complete}
      customColor={customColor}
      customTextColor={customTextColor}
      customBorderRadius={customBorderRadius}
      disabled={disabled}
      fullWidth={fullWidth}
      icon={icon}
      iconMarginTop={iconMarginTop}
      iconOnly={iconOnly}
      large={large}
      name={name}
      id={id}
      noBorder={noBorder}
      noHover={noHover}
      onClick={onClick}
      primary={primary}
      rounded={rounded}
      square={square}
      type={type}
      isLoading={isLoading}
      upperCase={upperCase}
    >
      {customIcon ? (
        <Icon className='fluid' src={customIcon} alt='button' />
      ) : (
        icon && (
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon={icon && icon}
        />
        )
      )}
      {isLoading ? (
        <animated.div style={spinnerAnimation}>
          <Spinner saving />
        </animated.div>
      )
        : cta}
    </ButtonStyled>
  );
};

BlueButton.propTypes = {
  bottom: PropTypes.bool,
  borderColor: PropTypes.string,
  className: PropTypes.string,
  customColor: PropTypes.string,
  customTextColor: PropTypes.string,
  customBorderRadius: PropTypes.string,
  customIcon: PropTypes.string,
  complete: PropTypes.bool,
  disabled: PropTypes.bool,
  cta: PropTypes.string,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconMarginTop: PropTypes.string,
  iconOnly: PropTypes.bool,
  large: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  noBorder: PropTypes.bool,
  noHover: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  primary: PropTypes.bool,
  rounded: PropTypes.bool,
  square: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  upperCase: PropTypes.bool,
};

BlueButton.defaultProps = {
  bottom: false,
  borderColor: null,
  className: '',
  cta: '',
  customColor: null,
  customTextColor: null,
  customBorderRadius: null,
  customIcon: null,
  complete: false,
  disabled: false,
  fullWidth: false,
  icon: '',
  iconMarginTop: '0px',
  iconOnly: false,
  large: false,
  name: null,
  id: null,
  noBorder: false,
  noHover: false,
  primary: false,
  rounded: false,
  square: false,
  type: 'button',
  isLoading: false,
  upperCase: false,
};

export default BlueButton;
