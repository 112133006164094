export const ColorPrimary = '#444444';
export const ColorSecondary = '#ff6600';
export const ColorBlack = '#444445';
export const ColorGreen = '#0dcc8a';
export const ColorWhite = '#FFFFFF';
export const ColorGray = '#979797';
export const ColorLightGray = '#686868';
export const ColorLightestGray = '#DBDBDB';

export const Gutter = '30px';

export const TransitionGlobal = 'all 250ms ease-in-out';

export const BoxShadow = '0 2px 4px 0 rgba(0, 0, 0, 0.1)';

export const Nunito = 'Nunito Sans';

export const OpenSans = 'Open Sans';

export const BorderRadius = '5px';
