import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import lightenDarkenColor from '../../utils/lightenDarkenColor';

import { ColorGreen, TransitionGlobal } from '../../GlobalStyles';
import iconSet from '../../images/teambuildr-selection.json';

const ButtonContainer = styled('div')`
  display: flex;
  height: ${(props) => (!props.small ? '56px' : '40px')};
  width: ${(props) => (!props.small ? '56px' : '40px')};
  background-color: ${(props) => (props.customColor ? props.customColor : ColorGreen)};
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ${TransitionGlobal};

  :hover {
    background-color: ${(props) => (lightenDarkenColor(props.customColor ? props.customColor : ColorGreen, -25))}
  }
  
  svg {
    path {
      fill: ${(props) => (props.customColorIcon ? props.customColorIcon : 'white')};
    }
  }
`;

const ActionButton = ({
  children,
  customColor,
  customColorIcon,
  onClick,
  icon,
  ref,
  small,
  iconSize,
}) => {
  const handleClickEvent = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <ButtonContainer
      customColor={customColor}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      ref={ref}
      small={small}
    >
      {children}
      <IcomoonReact
        iconSet={iconSet}
        size={iconSize || 20}
        icon={icon}
        color={customColorIcon}
      />
    </ButtonContainer>
  );
};

ActionButton.propTypes = {
  customColor: PropTypes.string,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  ref: PropTypes.oneOfType(Object).isRequired,
  small: PropTypes.bool,
  iconSize: PropTypes.number,
};

ActionButton.defaultProps = {
  customColor: '',
  small: false,
  iconSize: 20,
};

export default ActionButton;
