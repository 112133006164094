import { useDispatch, useSelector } from 'react-redux';
import {
  submitLogin,
  submitGoogleAuth,
  submitRegistration,
  fetchLogin,
  fetchUser,
  clearUser,
  checkSSO,
  submitLoginSSO,
} from '../../ducks/loginActions';

const useLogin = () => {
  const isLoginLoading = useSelector((state) => state.auth.ui.isLoginLoading);
  const isSSOLoginLoading = useSelector((state) => state.auth.ui.isSSOLoginLoading);
  const isAuthenticated = useSelector((state) => state.auth.ui.isAuthenticated);
  const loginError = useSelector((state) => state.auth.ui.loginError);
  const googleError = useSelector((state) => state.auth.ui.googleError);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const ssoLoginError = useSelector((state) => state.auth.ui.ssoLoginError);

  const dispatch = useDispatch();

  const handleLogin = (values) => {
    dispatch(submitLogin(values));
  };

  const handleLoginSSO = (values) => {
    dispatch(submitLoginSSO(values));
  };

  const handleCheckSSO = (email) => {
    dispatch(checkSSO(email));
  };

  const handleGoogleAuth = (values) => {
    dispatch(submitGoogleAuth(values));
  };

  const handleFetchUser = () => {
    dispatch(fetchLogin());
  };

  const handleFetchMe = () => {
    dispatch(fetchUser());
  };

  const clearCurrentUser = () => {
    dispatch(clearUser());
  };

  const handleRegistration = (values) => {
    dispatch(submitRegistration(values));
  };

  return {
    clearCurrentUser,
    currentUser,
    isSSOLoginLoading,
    handleGoogleAuth,
    handleLogin,
    handleFetchUser,
    handleFetchMe,
    handleRegistration,
    googleError,
    isLoginLoading,
    isAuthenticated,
    loginError,
    ssoLoginError,
    handleCheckSSO,
    handleLoginSSO,
  };
};

export default useLogin;
