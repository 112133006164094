// Container that handles the carousel
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CarouselCard from './CarouselCard';
import Carousel from './Carousel';

import { setIsWorkoutsCarouselShowing } from '../../ducks/workoutsActions';

const WorkoutCarouselContainer = () => {
  const workoutItems = useSelector((state) => state.workouts.data.currentWorkout.workoutItems);
  const isWorkoutsCarouselShowing = useSelector(
    (state) => state.workouts.data.isWorkoutsCarouselShowing,
  );
  const selectedWorkoutsListItemIndex = useSelector(
    (state) => state.workouts.data.selectedWorkoutsListItemIndex,
  );

  const dispatch = useDispatch();

  const showStopper = (bool, index) => {
    dispatch(setIsWorkoutsCarouselShowing(bool, index));
  };

  // this is where the workout cards will be generated and passed into the carousel
  // it should have a wrapper div with a height and width of 100%.  All other
  // styling should happen within the component
  const workoutCardsMapper = (
    inputArray,
  ) => inputArray.map((workoutObject, index) => ({
    cardElement: <CarouselCard
      workoutObject={workoutObject.item}
      outerIndex={workoutObject.index}
      sessionIndex={workoutObject.sessionIndex}
      index={index}
    />,
    shouldExpand: workoutObject.item.tableHeaders && workoutObject.item.tableHeaders.length >= 3,
    key: workoutObject.item.id,
  }));

  const flattenArray = (unflattenedArray) => {
    const flattenedArray = [];
    unflattenedArray.forEach((item, index1) => {
      flattenedArray.push({ item, index: index1 });
      if (item.type === 'BR') {
        item.sessionBreakItems.forEach((sessionBreakItem, innerIndex) => {
          flattenedArray.push({ item: sessionBreakItem, index: index1, sessionIndex: innerIndex });
        });
      }
    });
    return flattenedArray;
  };

  // important to mention in the comments here that the cards
  // are an array of objects, with each object containing
  // a key pointing to 'cardElement', which is what the function will
  // use to display the element itself.  This is important because it allows
  // us to pass in additional information about adding margins to the elements,
  // and whether a particular element should be in expanded view

  return (
    <Carousel
      cards={workoutCardsMapper(flattenArray(workoutItems))}
      isShowing={isWorkoutsCarouselShowing}
      setIsShowing={showStopper}
      defaultIndex={selectedWorkoutsListItemIndex}
    />
  );
};

WorkoutCarouselContainer.propTypes = { };
export default WorkoutCarouselContainer;
