/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {

} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	height: 170px;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
	flex-direction: column;
	@media only screen and (max-height : 724px) {
		min-height: 400px;
  }
`;

const IconContainer = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: 1px solid black;
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
`;

const SessionBreak = ({ workoutObject }) => (
  <Container>
    <IconContainer>
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        icon='break'
        color='black'
      />
    </IconContainer>
    {workoutObject.title}
  </Container>
);

SessionBreak.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default SessionBreak;
