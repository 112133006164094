import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Gutter } from '../../../../shared/GlobalStyles';
import ButtonRound from './ButtonRound';
import Title from './Title';

export const ModalContentWrapper = styled('div')`
  padding: 40px;
  flex-grow: 1;
  background-color: ${(props) => props.theme.backgroundColorL2};

  p {
    color: ${(props) => props.theme.textColor}
  }
  form {
    padding-bottom: 20px;
    label {
      color: ${(props) => props.theme.textColor};
    }
    input, textarea {
      background-color: ${(props) => props.theme.backgroundColorL3};
    }
  }
  .select__control, .select__menu, textarea {
    background-color: ${(props) => props.theme.backgroundColorL3};
    color: ${(props) => props.theme.textColor};
    border-color: ${(props) => props.theme.noBorderColorForm};
  }
  .select__single-value {
    color: ${(props) => props.theme.textColor};
  }
  .spinner-container {
    min-height: 300px;
  }
  .form-group {
    padding: ${Gutter} calc(${Gutter} * 3);
  }
  .form-control {
    min-height: 150px;
  }
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  .modal-button {
    position: absolute;
    margin-left: -40px;
    bottom: 0;
    align-items: center;
  }
  @media only screen and (min-height: 581px) and (max-height : 768px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-height : 580px) {
    padding: 20px;
    .modal-button {
      margin-left: -20px;
    }
    .form-control {
      min-height: 120px;
    }
    .title-container {
      display: none;
    }
  }
  @media only screen and (max-width: 1023px) {
    .form-group {
      padding: 20px 15px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .note-title-container {
      margin-bottom: 0px!important;
    }
  }
`;

const ModalNote = ({
  exerName,
  index,
  closeModal,
  namespace,
  submitJournal,
  updateJournal,
  workout,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isProgramView = useSelector((state) => state.weightRoomView[namespace].isProgramView);
  const workId = useSelector((state) => state.weightRoomView[namespace].activeWorkout);

  const hasJournalEntry = workout && workout.journalEntries.length > 0;

  return (
    <Formik
      initialValues={
        isProgramView ? (
          (() => ({
            associatedDayBasedDayNum: currentNonDateDay.trueDayNum,
            associatedDayBasedWorkoutId: currentSelectedProgram.dayBasedProgramId,
            associatedSaveDataId: workId,
            title: exerName,
            body: hasJournalEntry ? (
              workout.journalEntries[0].body
            ) : (''),
            userId: athleteId,
            private: false,
          })
          )()
        ) : (
          (() => ({
            associatedWorkoutAssignedId: workId,
            dateAssigned: moment(activeDate).format('YYYY-MM-DD'),
            title: exerName,
            body: hasJournalEntry ? (
              workout.journalEntries[0].body
            ) : (''),
            userId: athleteId,
            private: false,
          })
          )()
        )
      }
      onSubmit={(values, actions) => {
        setTimeout(() => {
          if (hasJournalEntry) {
            updateJournal(index, accountCode, namespace, values, workout.journalEntries[0].id);
          } else {
            submitJournal(index, accountCode, namespace, values);
          }
          actions.setSubmitting(false);
          closeModal(namespace);
        }, 1000);
      }}
      render={(props) => (
        <ModalContentWrapper>
          <Form>
            <Title
              icon='note'
            >
              Add Note
            </Title>
            <div className='form-group'>
              <label>Note</label>
              <textarea
                onChange={props.handleChange}
                name='body'
                className='form-control'
                defaultValue={props.values.body}
              />
            </div>
            <ButtonRound
              bottom
              fullWidth
              cta={hasJournalEntry ? 'Update' : 'Submit'}
              className='modal-button'
              large
              noBorder
              primary
              square
              onClick={props.onSubmit}
            />
          </Form>
        </ModalContentWrapper>
      )}
    />
  );
};

ModalNote.propTypes = {
  exerName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  closeModal: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitJournal: PropTypes.func.isRequired,
  updateJournal: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  workout: PropTypes.string.isRequired,
};

export default ModalNote;
