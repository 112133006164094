import styled from '@emotion/styled';
import {
  space, color, layout, flexbox, typography,
} from 'styled-system';

/**
 * adding color to div from styled-system gives access to a slew of
 * color-based props that just get automatically added to the
 * styled div (so, for instance, passing color in as below, allows 'bg'
 * to be defined on the wrapper component)
 *
 * https://github.com/styled-system/styled-system/blob/master/docs/api.md
 *
 * Go to the above link to see which props expose which css-based properties
 * on the component
 */

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-x: visible !important;
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  width: ${(props) => (props.width ? props.width : 'auto !important')};
  height: ${(props) => (props.height ? props.height : 'auto !important')};
`;

export default Wrapper;
