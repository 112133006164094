import update from 'immutability-helper';

import {
  FETCH_DOCUMENTS_FULFILLED,
  FETCH_DOCUMENTS_REJECTED,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_FOLDER_FULFILLED,
  FETCH_FOLDER_REJECTED,
  FETCH_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_SEARCH_DOCUMENTS_REJECTED,
  CREATE_DOCUMENT_FULFILLED,
  CREATE_DOCUMENT_REJECTED,
  UPLOAD_DOCUMENT_FULFILLED,
  UPLOAD_DOCUMENT_REJECTED,
  DELETE_DOCUMENTS_FULFILLED,
  DELETE_DOCUMENTS_REJECTED,
  UPDATE_DOCUMENT_FULFILLED,
  UPDATE_DOCUMENT_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  FETCH_FILE_FULFILLED,
  FETCH_FILE_REJECTED,
  FETCH_FOLDERS_FULFILLED,
  FETCH_FOLDERS_REJECTED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED,
  FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  SET_CURRENT_ROW_REDUX,
  SET_MODAL_SHARE_LINK_REDUX,
  SET_SELECTED_ROWS_REDUX,
  SET_ACTIVE_SEARCH_STRING_REDUX,
  SET_SINGLE_ACTION_REDUX,
} from './actionTypes';

const initialState = {
  documents: [],
  folderDocuments: [],
  documentsError: '',
  uploadDocument: '',
  fetchDocumentError: '',
  activeSearchString: '',
  folders: [],
  selectedRows: [],
  clearRowsToggle: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case FETCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_DOCUMENT_FULFILLED: {
      return {
        ...state,
        document: action.payload,
      };
    }
    case FETCH_DOCUMENT_REJECTED: {
      return {
        ...state,
        fetchDocumentError: action.payload,
      };
    }
    case FETCH_FOLDER_FULFILLED: {
      return {
        ...state,
        folderDocuments: action.payload,
      };
    }
    case FETCH_FOLDER_REJECTED: {
      return {
        ...state,
        folderError: action.payload,
      };
    }
    case FETCH_SEARCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        documents: action.payload,
      };
    }
    case FETCH_SEARCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case CREATE_DOCUMENT_FULFILLED: {
      let returnOb;
      if (action.payload.parentFolderId) {
        const { folderDocuments } = state;
        const newFolderLevelDocs = [...folderDocuments];
        newFolderLevelDocs.push(action.payload);
        newFolderLevelDocs.sort((a, b) => a.name.localeCompare(b.name));
        returnOb = update(
          state, {
            folderDocuments: {
              $set: newFolderLevelDocs,
            },
            activeModal: {
              $set: 'confirm_create_modal',
            },
          },
        );
      } else {
        const { documents } = state;
        const newTopLevelDocs = [...documents];
        newTopLevelDocs.push(action.payload);
        newTopLevelDocs.sort((a, b) => a.name.localeCompare(b.name));
        if (action.payload.type === 0) {
          returnOb = update(
            state, {
              documents: {
                $set: newTopLevelDocs,
              },
              folders: {
                $push: [action.payload],
              },
              activeModal: {
                $set: 'confirm_create_modal',
              },
            },
          );
        } else {
          returnOb = update(
            state, {
              documents: {
                $set: newTopLevelDocs,
              },
              activeModal: {
                $set: 'confirm_create_modal',
              },
            },
          );
        }
      }
      return returnOb;
    }
    case CREATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        createDocumentError: action.payload.response.headers.message,
      };
    }
    case UPLOAD_DOCUMENT_FULFILLED: {
      return {
        ...state,
        uploadDocument: action.payload,
        uploadDocumentError: '',
      };
    }
    case UPLOAD_DOCUMENT_REJECTED: {
      return {
        ...state,
        uploadDocumentError: action.payload,
      };
    }
    case DELETE_DOCUMENTS_FULFILLED: {
      const currentTopLevelDocs = state.documents;
      const { clearRowsToggle } = state;
      const newTopLevelDocs = currentTopLevelDocs.filter(
        (doc) => !action.payload.successful.includes(doc.id),
      );
      const currentFolderLevelDocs = state.folderDocuments;
      const newFolderLevelDocs = currentFolderLevelDocs.filter(
        (doc) => !action.payload.successful.includes(doc.id),
      );
      const currentFolders = state.folders;
      const newFolders = currentFolders.filter(
        (doc) => !action.payload.successful.includes(doc.id),
      );
      return update(
        state, {
          documents: {
            $set: newTopLevelDocs,
          },
          folderDocuments: {
            $set: newFolderLevelDocs,
          },
          folders: {
            $set: newFolders,
          },
          activeModal: {
            $set: '',
          },
          selectedRows: {
            $set: [],
          },
          clearRowsToggle: {
            $set: !action.payload.length ? !clearRowsToggle : clearRowsToggle,
          },
          isSingleAction: {
            $set: false,
          },
        },
      );
    }
    case DELETE_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case UPDATE_DOCUMENT_FULFILLED: {
      const currentFolderLevelDocs = [...state.folderDocuments];
      let folderTrue = false;
      if (action.payload.responseData.type === 0) {
        folderTrue = true;
      }

      let updateOb;
      if (folderTrue) {
        updateOb = update(
          state, {
            documents: {
              [action.payload.indices.documentIndex]: {
                $set: action.payload.responseData,
              },
            },
            folderDocuments: {
              $set: currentFolderLevelDocs,
            },
            folders: {
              [action.payload.indices.folderDocIndex]: {
                $set: action.payload.responseData,
              },
            },
            activeModal: {
              $set: '',
            },
          },
        );
      } else if (!action.payload.responseData.parentFolderId
        && (action.payload.indices.oldFolderIndex || action.payload.indices.oldFolderIndex === 0)) {
        updateOb = update(
          state, {
            documents: {
              $push: [action.payload.responseData],
            },
            folderDocuments: {
              $splice: [[action.payload.indices.oldFolderIndex, 1]],
            },
            activeModal: {
              $set: '',
            },
          },
        );
        updateOb.documents.sort((a, b) => a.name.localeCompare(b.name));
      } else if (action.payload.responseData.parentFolderId
        && (action.payload.indices.oldDocumentIndex
          || action.payload.indices.oldDocumentIndex === 0)) {
        updateOb = update(
          state, {
            documents: {
              $splice: [[action.payload.indices.oldDocumentIndex, 1]],
            },
            folderDocuments: {
              $push: [action.payload.responseData],
            },
            activeModal: {
              $set: '',
            },
          },
        );
        updateOb.folderDocuments.sort((a, b) => a.name.localeCompare(b.name));
      } else if (action.payload.responseData.parentFolderId
        && (action.payload.indices.oldFolderIndex === undefined)) {
        updateOb = update(
          state, {
            folderDocuments: {
              [action.payload.indices.folderIndex]: {
                $set: action.payload.responseData,
              },
            },
            activeModal: {
              $set: '',
            },
          },
        );
      } else if (!action.payload.responseData.parentFolderId
        && (action.payload.indices.oldDocumentIndex === undefined)) {
        updateOb = update(
          state, {
            documents: {
              [action.payload.indices.documentIndex]: {
                $set: action.payload.responseData,
              },
            },
            activeModal: {
              $set: '',
            },
          },
        );
      }
      return updateOb;
    }
    case UPDATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        updateDocumentError: action.payload.response.headers.message,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        userGroups: action.payload,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FILE_FULFILLED: {
      return {
        ...state,
        file: action.payload,
      };
    }
    case FETCH_FILE_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FOLDERS_FULFILLED: {
      return {
        ...state,
        folders: action.payload,
      };
    }
    case FETCH_FOLDERS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_FOLDER_SEARCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        folderDocuments: action.payload,
      };
    }
    case FETCH_FOLDER_SEARCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case SET_ACTIVE_REDUX_MODAL: {
      return {
        ...state,
        activeModal: action.payload,
        createDocumentError: '',
        updateDocumentError: '',
      };
    }
    case SET_CURRENT_ROW_REDUX: {
      return {
        ...state,
        currentRow: action.payload,
      };
    }
    case SET_MODAL_SHARE_LINK_REDUX: {
      return {
        ...state,
        modalShareLink: action.payload,
      };
    }
    case SET_SELECTED_ROWS_REDUX: {
      const { clearRowsToggle } = state;
      return {
        ...state,
        selectedRows: action.payload,
        clearRowsToggle: !action.payload.length ? !clearRowsToggle : clearRowsToggle,
      };
    }
    case SET_ACTIVE_SEARCH_STRING_REDUX: {
      return {
        ...state,
        activeSearchString: action.payload,
      };
    }
    case SET_SINGLE_ACTION_REDUX: {
      return {
        ...state,
        isSingleAction: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
