import {
  SUBMIT_EMAIL_START,
  SUBMIT_EMAIL_FULFILLED,
  SUBMIT_EMAIL_REJECTED,
  SUBMIT_RESET_START,
  SUBMIT_RESET_FULFILLED,
  SUBMIT_RESET_REJECTED,
  VALIDATE_TOKEN_START,
  VALIDATE_TOKEN_FULFILLED,
  VALIDATE_TOKEN_REJECTED,
} from './actionTypes';

const initialState = {
  forgotError: null,
  isComplete: false,
  isDisabled: false,
  isSubmitting: false,
  resetError: null,
  validateError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_EMAIL_START: {
      return {
        ...state,
        isSubmitting: true,
        isDisabled: true,
      };
    }
    case SUBMIT_EMAIL_FULFILLED: {
      return {
        ...state,
        isSubmitting: false,
        isComplete: true,
        forgotError: null,
      };
    }
    case SUBMIT_EMAIL_REJECTED: {
      return {
        ...state,
        isSubmitting: false,
        isComplete: false,
        isDisabled: false,
        forgotError: action.payload,
      };
    }
    case SUBMIT_RESET_START: {
      return {
        ...state,
        isSubmitting: true,
        isDisabled: true,
      };
    }
    case SUBMIT_RESET_FULFILLED: {
      return {
        ...state,
        resetError: null,
      };
    }
    case SUBMIT_RESET_REJECTED: {
      return {
        ...state,
        isSubmitting: false,
        isComplete: false,
        isDisabled: false,
        resetError: action.payload,
      };
    }
    case VALIDATE_TOKEN_START: {
      return {
        ...state,
      };
    }
    case VALIDATE_TOKEN_FULFILLED: {
      return {
        ...state,
        validateError: null,
      };
    }
    case VALIDATE_TOKEN_REJECTED: {
      return {
        ...state,
        validateError: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
