import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { ColorPrimary } from '../../../../shared/GlobalStyles';
import AthleteAvatar from './AthleteAvatar';
import AthleteName from './AthleteName';
import CloseAthlete from './CloseAthlete';
import AnimatedSwapButton from './AnimatedSwapButton';
import AnimatedProgressBar from './AnimatedProgressBar';
import AnimatedSaving from './AnimatedSaving';

const PanelHeaderContainer = styled('div')`
  display: flex;
  background-color: ${(props) => props.theme.colorAccent};
  height: 35px;
  width: 100%;
  padding: 0px 15px;
  justify-content: space-between;
  align-items: center;

  .progress-bar {
    .progress-bar-container {
      border: 1px solid ${(props) => (props.theme.isAccentDarkTheme ? 'white' : ColorPrimary)};
    }
    .progress-bar-fill {
      background-color: ${(props) => (props.theme.isAccentDarkTheme ? 'white' : ColorPrimary)};
    }
    .progress-text {
      color: ${(props) => (props.theme.isAccentDarkTheme ? 'white' : ColorPrimary)};
    }
  }
`;

const AthleteDetails = styled('div')`
  display: flex;
  align-content: center;

  h3 {
    color: ${(props) => (props.theme.isAccentDarkTheme ? 'white' : ColorPrimary)};
    @media screen and (max-width: 850px) {
      font-size: 12px;
    }

    @media screen and (max-width: 668px) {
      max-width: 78px;
    }
  }
`;

const SavingContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 110px;
  height: 35px;
  .spinner-container {
    margin-right: 30px;
    @media screen and (max-width: 668px) {
      margin-right: 20px;
    }
  }
`;

const PanelHeader = ({
  athleteAvatar,
  activeAthlete,
  closePanel,
  namespace,
}) => {
  const handlePanelClose = useCallback(() => {
    closePanel(namespace);
  }, []);

  return (
    <PanelHeaderContainer>
      <AthleteDetails>
        <AthleteAvatar
          avatar={athleteAvatar}
          size={25}
        />
        <AthleteName
          name={activeAthlete}
        />
        <AnimatedSwapButton
          namespace={namespace}
          handlePanelClose={handlePanelClose}
        />
      </AthleteDetails>
      <AnimatedProgressBar
        namespace={namespace}
      />
      <SavingContainer>
        <AnimatedSaving
          namespace={namespace}
        />
        <CloseAthlete
          namespace={namespace}
        />
      </SavingContainer>
    </PanelHeaderContainer>
  );
};

PanelHeader.propTypes = {
  athleteAvatar: PropTypes.string.isRequired,
  activeAthlete: PropTypes.string.isRequired,
  closePanel: PropTypes.instanceOf(Function).isRequired,
  namespace: PropTypes.string.isRequired,
};

export default PanelHeader;
