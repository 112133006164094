// modal for confirming deletion of selected documents

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';

import NoHoverButton from './NoHoverDeleteButton';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    align-items: center;
    color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
`;

const SubApplication = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  .back-arrow-div {
    cursor: pointer;
  }
`;

const AnotherWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: -40px;
`;

const ModalConfirmDelete = ({
  handleDeleteDocuments,
  setSelectedRows,
  setSelectedChildRows,
  selectedRows,
  selectedChildRows,
  currentUser,
  setSingleAction,
  isSingleAction,
  onRequestClose,
  isArchivedShowing,
  activeSearchString,
}) => {
  const isCalendarSubmitting = useSelector(
    (state) => state.calendars.ui.isCalendarSubmitting,
  );
  const [childrenNumber, setChildrenNumber] = useState(0);

  const selectedChildRowsKeys = Object.keys(selectedChildRows);
  const selectedRowsKeys = Object.keys(selectedRows);

  useEffect(() => {
    let count = 0;
    selectedRowsKeys.forEach((key) => {
      if (selectedRows[key].newChildCalendars) {
        count += selectedRows[key].newChildCalendars.length;
      }
    });
    setChildrenNumber(count);
  }, [selectedRows]);


  const groupDeleteDocuments = () => {
    let finalRows;
    if (selectedChildRowsKeys.length) {
      finalRows = selectedRowsKeys.concat(selectedChildRowsKeys);
    } else {
      finalRows = selectedRowsKeys;
    }
    const finalFinalRows = finalRows.map((string) => parseInt(string, 10));
    handleDeleteDocuments(currentUser.accountCode, finalFinalRows);
  };
  const theme = useTheme();

  const languageThingy = () => {
    let languageString = ' ';
    if ((selectedRowsKeys.length + selectedChildRowsKeys.length) === 1) {
      languageString += 'this';
    } else {
      languageString += 'these';
    }
    languageString += ' ';
    if ((selectedRowsKeys.length + selectedChildRowsKeys.length) > 1) {
      languageString += (selectedRowsKeys.length + selectedChildRowsKeys.length).toString();
    }
    languageString += ' ';
    if ((selectedRowsKeys.length + selectedChildRowsKeys.length) === 1) {
      languageString += 'calendar?';
    } else {
      languageString += 'calendars?';
    }
    return languageString;
  };

  return (
    <ModalWrapper>
      <ModalWrapper>
        <AnotherWrapper>
          <NavigationWrapper>
            <Text
              className='back-arrow-div'
              onClick={() => {
                onRequestClose();
                if (isSingleAction) {
                  setSelectedRows({});
                  setSelectedChildRows({});
                  setSingleAction(false);
                }
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={10}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <TextWrapper>
            <Text
              fontWeight='600'
              fontSize={theme.textFontSizes[2]}
              style={{
                marginTop: '-10px',
              }}
            >
              Are you sure you want to delete
              {languageThingy()}

            </Text>
            {(selectedRowsKeys.length && childrenNumber) ? (
              <SubApplication>
                (Including
                {' '}
                {childrenNumber}
                {' '}
                sub-calendars)
              </SubApplication>
            ) : null}
          </TextWrapper>
        </AnotherWrapper>
        <NoHoverButton
          bottom
          fullWidth
          cta={(!isCalendarSubmitting ? `${(selectedChildRowsKeys.length) + selectedRowsKeys.length > 1 ? 'Delete calendars' : 'Delete calendar'}` : 'Deleting...')}
          customColor='#f24540'
          className='modal-button'
          large
          noBorder
          primary
          square
          onClick={() => {
            groupDeleteDocuments();
          }}
          disabled={isCalendarSubmitting}
          noHover
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalConfirmDelete.propTypes = {
  handleDeleteDocuments: PropTypes.func.isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  selectedRows: PropTypes.instanceOf(Array).isRequired,
  currentUser: PropTypes.instanceOf(Object).isRequired,
  setSelectedChildRows: PropTypes.func.isRequired,
  selectedChildRows: PropTypes.instanceOf(Array).isRequired,
  setSingleAction: PropTypes.func.isRequired,
  isSingleAction: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default ModalConfirmDelete;
