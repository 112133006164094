/* eslint-disable react/no-array-index-key */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import { setIsAdditionalWorkouts } from '../../ducks/panelActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const transitionStyles = {
  entering: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(-150%)',
  },
};

const ButtonInterior = styled('div')`
	display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 11px;
`;

const IconContainer = styled('div')`
	margin-right: 5px;
  align-items: center;
  margin-bottom: 5px;
`;

const defaultStyle = {
  transform: 'translateY(170%)',
  display: 'block',
  position: 'fixed',
  left: '37%',
  bottom: '-50px',
  zIndex: 10000,
  marginLeft: '-50px',
  height: '50px',
  width: '200px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: '40px',
  transition: 'transform 300ms ease-in-out, opacity 300ms ease-in-out',
  fontFamily: 'Nunito Sans',
  background: 'linear-gradient(90deg, rgba(0,212,255,1) 50%, rgba(3,160,203,1) 100%)',
  fontWeight: '600',
  fontSize: '15px',
};

const UnlockWorkoutsAlert = ({ namespace }) => {
  const dispatch = useDispatch();

  const isAdditionalWorkouts = useSelector(
    (state) => state.weightRoomView[namespace].isAdditionalWorkouts,
  );
  const [switchAlertBanner, setSwitchAlertBanner] = useState(false);

  useEffect(() => {
    if (switchAlertBanner) {
      setTimeout(() => {
        dispatch(setIsAdditionalWorkouts(false));
        setSwitchAlertBanner(false);
      }, 2000);
    }
  }, [switchAlertBanner]);

  useEffect(() => {
    if (isAdditionalWorkouts) {
      setSwitchAlertBanner(true);
    }
  }, [isAdditionalWorkouts]);

  return (
    <Transition in={switchAlertBanner}>
      {(state) => (
        <div
          style={{ ...defaultStyle, ...transitionStyles[state] }}
        >
          <ButtonInterior>
            <IconContainer>
              <IcomoonReact
                iconSet={iconSet}
                size={18}
                icon='lock'
                color='white'
              />
            </IconContainer>
            Workouts Unlocked!

          </ButtonInterior>
        </div>
      )}
    </Transition>
  );
};

UnlockWorkoutsAlert.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default UnlockWorkoutsAlert;
