// a container for the search bar, breadcrumbs, and header/foldername (if it exists)

import React from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Button from '../../../../shared/components/Button/Button';
import Search from '../../../../shared/components/Search/Search';
import Breadcrumb from '../../../../shared/components/Breadcrumb/Breadcrumb';
import useDocuments from '../hooks/useDocuments';

const TableHeaderWrapper = styled('div')`
  .search-form-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .submit-button {
    display: none
  }
`;

const HeaderAndBreadcrumbsWrapper = styled('div')`

`;

const FormButtonContainer = styled('div')`
  display: flex;
`;

const DocumentSearchForm = (
  {
    currentFolder,
    directoryName,
    setActiveSearchString,
  },
) => {
  const {
    handleSearch,
    currentUser,
    handleFolderSearch,
  } = useDocuments();

  const activeSearchString = useSelector((state) => state.documents.data.activeSearchString);

  return (
    <TableHeaderWrapper>
      <div className='search-form-wrapper'>
        <HeaderAndBreadcrumbsWrapper>
          <h3>{directoryName}</h3>
          <Breadcrumb>
            <Link href='/documents'>Main Directory</Link>
            {currentFolder ? <Link href={`/documents/${currentFolder.id}`}>{currentFolder.name}</Link> : null}
            {activeSearchString && activeSearchString.length
              ? (
                <Link
                  href={currentFolder ? `/documents/${currentFolder.id}` : '/documents'}
                >
                  {`Search for: ${` '${activeSearchString}'`}`}
                </Link>
              ) : null}
          </Breadcrumb>
        </HeaderAndBreadcrumbsWrapper>
        <Formik
          initialValues={{
            search: '',
          }}
          onSubmit={(values) => {
            const accountCode = currentUser !== undefined && currentUser.accountCode;
            if (accountCode !== undefined) {
              setActiveSearchString(values.search);
              if (!currentFolder) {
                handleSearch({ accountCode, values });
              } else if (currentFolder) {
                handleFolderSearch({ accountCode, values, currentFolder });
              }
            }
          }}
          render={({
            handleChange,
            handleSubmit,
            resetForm,
            values,
          }) => (
            <Form>
              <FormButtonContainer>
                <Search
                  name='search'
                  values={values.search}
                  handleChange={handleChange}
                  isClearable
                  onClear={resetForm}
                  handleSearch={handleSearch}
                  currentUser={currentUser}
                  currentFolder={currentFolder}
                  handleFolderSearch={handleFolderSearch}
                  setActiveSearchString={setActiveSearchString}
                />
                <Button className='submit-button' onClick={handleSubmit} cta='submit' />
              </FormButtonContainer>
            </Form>

          )}
        />
      </div>
    </TableHeaderWrapper>
  );
};

DocumentSearchForm.propTypes = {
  currentFolder: PropTypes.instanceOf(Object).isRequired,
  directoryName: PropTypes.string.isRequired,
  setActiveSearchString: PropTypes.func.isRequired,
};

export default DocumentSearchForm;
