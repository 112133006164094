/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { createPortal } from 'react-dom';

import moment from 'moment';
import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  editEvaluation, setEditEvalModal1, fetchEvalFromEdit, setSelectedEvalDate,
  setEvalGroupOptionRedux, setEditSlideIndex,
} from '../../ducks/evaluationsActions';
import Button from '../../../../shared/components/Button/Button';
import EditEvaluationTable from './EditEvaluationTable';
import SelectEvalDatePicker from './SelectEvalDatePicker';

const ModalContainer = styled('div')`
  z-index: 1003;
  min-height: 270px;
  width: 75vw;
  height: 90vh;
  overflow: auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  max-width: ${(props) => props.customWidth};
  .print-button {
    background: #10cd8c;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    min-height: 48px;
  }
  transition: all 0.5s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  user-select: none;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }
  @media only screen and (max-height : 590px) {
    padding-top: 120px;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-left: 40px;
  padding-right: 40px;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }

  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 28px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
`;

const EvalSelectionText = styled('div')`
  font-size: 19px;
  font-family: 'Nunito Sans';

  @media only screen and (max-width : 768px) {
    font-size: 15px;
  }
`;

const EvalSelectionContainer = styled('div')`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const EvalSelectionBox = styled('input')`
  flex: 1;
  margin-left: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 10px;

  @media only screen and (max-width : 768px) {
    margin-left: 5px;
  }
`;

const SlidesContainer = styled('div')`
  overflow: hidden;
  width: 100%;
  height: ${(props) => props.customHeight};
`;

const Slides = styled('div')`
  white-space: nowrap;
  transition: transform 0.5s ease;
  transform: ${(props) => `translateX(-${props.activeSlide * 100}%)`};
  height: 100%;
`;

const Slide = styled('div')`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  height: 100%;
`;

const SlideIndicators = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 40px;
  align-items: center;
  background: white;
`;

const SlideIndicator = styled('div')`
  width: 7px;
  height: 7px;
  border: 1px solid grey;
  background: ${(props) => (props.isActive ? 'grey' : 'none')};
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
  transition: background-color 0.5s ease;
`;

const Slide2BlockRow = styled('div')`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

const Slide2Block2 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const Slide2Block3 = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const BlockText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 800;
`;

const PortalContainer = styled('div')`
  width: 100%;
  background: white;
  box-shadow: ${BoxShadow};
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  overflow: auto;
  padding: 20px;
`;

const RelativeContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const DatePickerDiv = styled('div')`
  display: flex;
  border: 1px solid #EEEEEE;
  border-radius: 8px;
  width: 250px;
  height: 40px;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding-left: 12px;
  cursor: pointer;
`;

const EndBlock = styled('div')`
  width: 40px;
  background: #444444;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EvalSelectEndBlock = styled('div')`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.selected ? '#444444' : 'none')};
`;

const DatePickerText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const EvaluationSelectText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  color: #EEEEEE;
`;

const SelectedEvaluationGroupText = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const SelectEvaluationGroupBox = styled('div')`
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border: ${(props) => (props.active ? '1px solid #5F8BDC' : '1px solid #EEEEEE')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding-left: 12px;
  overflow: hidden;
  cursor: pointer;
`;

const FloatingEvalGroupTypeSelector = styled('div')`
  width: 50%;
  z-index: 1;
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 6px;
  min-width: 450px;
  padding: 16px;
`;

const FloatingEvalGroupSelector = styled('div')`
  width: 50%;
  z-index: 1;
  position: absolute;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 6px;
  min-width: 450px;
  max-width: 600px; 
  max-height: 300px;
  overflow-y: auto; 
  padding: 16px;
`;

const FloatingEvalOption = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: #EEEEEE;
  }
`;

const GoEvaluationButton = styled('div')`
  padding: 6px 16px 6px 16px;
  border: 2px solid #5F8BDC;
  background: white;
  color: #5F8BDC;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 247px;
  height: 30px;
  border-radius: 30px;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }`;

const GoEvaluationButtonContainer = styled('div')`
  width: 100%;
  justify-content: center;
  display: ${(props) => (props.activeSlide === 0 ? 'flex' : 'none')};
`;

const GoEvaluationButtonText = styled('div')`
  font-size: 12px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  margin-left: 10px;
`;

const FloatingEvalGroupOption = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  padding: 8px 12px 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    background: #EEEEEE;
  }
`;

const SlideCenterDiv = styled('div')`
  
`;

const ButtonContainer = styled('div')`
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ErrorBox = styled('div')`
  display: flex;
  flex-direction: column;
`;

const MappedError = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: #c0392b;
`;

const EditEvalModal = () => {
  const editEvalModal1Object = useSelector(
    (state) => state.evaluations.data.editEvalModal1Object,
  );
  const isEvaluationLoading = useSelector(
    (state) => state.evaluations.ui.isEvaluationLoading,
  );
  const isEvaluationContentTable = useSelector(
    (state) => state.evaluations.ui.isEvaluationContentTable,
  );
  const isEvaluationsContentList = useSelector(
    (state) => state.evaluations.ui.isEvaluationsContentList,
  );
  const currentUser = useSelector(
    (state) => state.auth.data.currentUser,
  );
  const groups = useSelector(
    (state) => state.evaluations.data.groups,
  );
  const calendars = useSelector(
    (state) => state.evaluations.data.calendars,
  );
  const trackedItemUnits = useSelector(
    (state) => state.evaluations.data.trackedItemUnits,
  );
  const trackedExercises = useSelector(
    (state) => state.evaluations.data.trackedExercises,
  );
  const selectedEvalDate = useSelector(
    (state) => state.evaluations.data.selectedEvalDate,
  );

  const editSlideIndex = useSelector(
    (state) => state.evaluations.ui.editSlideIndex,
  );

  const [activeSlide, setActiveSlide] = useState(0);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [evalName, setEvalName] = useState('');
  const [dscElement, setDscElement] = useState(null);
  const [escElement, setEscElement] = useState(null);
  const [gElement, setgElement] = useState(null);
  const [selectEvalTypeBoxActive, setSelectEvalTypeBoxActive] = useState(false);
  const [selectEvalGroupBoxActive, setSelectEvalGroupBoxActive] = useState(false);
  const [evalGroupTypeOption, setEvalGroupTypeOption] = useState('');
  const [evalGroupOption, setEvalGroupOption] = useState({});
  const [items, setItems] = useState([]);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [errors, setErrors] = useState({
    errorsExist: true,
    errors: [{
      error: 'name error',
      msg: 'You must add an evaluation name',
      isShowing: false,
    },
    {
      error: 'item error',
      msg: 'You must add evaluation items',
      isShowing: false,
    },
    {
      error: 'incomplete item error',
      msg: 'Some evaluation items are incomplete',
      isShowing: false,
    },
    ],
  });
  const evalTypeBoxRef = useRef(null);
  const evalGroupBoxRef = useRef(null);
  const [floatingSelectorStyle, setFloatingSelectorStyle] = useState({
    position: 'absolute',
  });

  const itemChecker = () => {
    let isComplete = true;
    items.forEach((item) => {
      if (item.tracked) {
        if (!item.name) {
          isComplete = false;
        }
      } else if (!item.name) {
        isComplete = false;
      }
    });
    if (!isComplete) {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = true;
      setErrors(newErrors);
    } else if (isComplete) {
      const newErrors = { ...errors };
      newErrors.errors[2].isShowing = false;
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (items.length) {
      const newErrors = { ...errors };
      newErrors.errors[1].isShowing = false;
      setErrors(newErrors);
    }
    itemChecker();
  }, [items]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(editEvalModal1Object).length) {
      setEvalName(editEvalModal1Object.title);
      setItems(editEvalModal1Object.items);
    }
  }, [editEvalModal1Object]);

  const resetModalState = () => {
    setEvalName('');
    setActiveSlide(0);
    setEvalGroupTypeOption('');
    setEvalGroupOption({});
    setItems([]);
    dispatch(setEditSlideIndex(0));
    setErrors({
      errorsExist: true,
      errors: [
        {
          error: 'name error',
          msg: 'You must add an evaluation name',
          isShowing: false,
        },
        {
          error: 'item error',
          msg: 'You must add evaluation items',
          isShowing: false,
        },
        {
          error: 'incomplete item error',
          msg: 'Some evaluation items are incomplete',
          isShowing: false,
        },
      ],
    });
  };
  useEffect(() => {
    if (Object.keys(editEvalModal1Object).length === 0) {
      resetModalState();
    }
  }, [editEvalModal1Object]);

  useEffect(() => {
    const dsc = document.getElementById('date-selector-container-edit');
    if (dsc) {
      setDscElement(dsc);
    }
    const esc = document.getElementById('eval-selector-container-edit');
    if (esc) {
      setEscElement(dsc);
    }
    const g = document.getElementById('eval-group-selector-container-edit');
    if (g) {
      setgElement(g);
    }
  }, []);

  const openFloatingSelector = (ref) => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY + 8;
      const left = rect.left + window.scrollX;
      setFloatingSelectorStyle({ top: `${top}px`, left: `${left}px` });
    }
  };

  useEffect(() => {
    if (selectEvalTypeBoxActive && evalTypeBoxRef.current) {
      const rect = evalTypeBoxRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY - 40;

      setFloatingSelectorStyle({
        position: 'absolute',
        top: `${top}px`,
        left: '0px',
        zIndex: 1000,
      });
    }
  }, [selectEvalTypeBoxActive, evalTypeBoxRef.current]);

  useEffect(() => {
    if (selectEvalGroupBoxActive && evalGroupBoxRef.current) {
      const rect = evalGroupBoxRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY - 40;

      setFloatingSelectorStyle({
        position: 'absolute',
        top: `${top}px`,
        left: '0px',
        zIndex: 1000,

      });
    }
  }, [selectEvalGroupBoxActive, evalGroupBoxRef.current]);

  const evalSelectOptions = [
    'Select By Athlete Group',
    'Select By Calendars',
    'All Athletes',
  ];

  const unitNameToAbbreviation = useMemo(() => {
    if (!trackedItemUnits?.data?.length) return {};
    const mapping = {};
    trackedItemUnits.data.forEach((unit) => {
      mapping[unit.name.toLowerCase()] = unit.abbreviation;
    });
    return mapping;
  }, [trackedItemUnits]);

  const submitNewEval = () => {
    const updatedItems = items.map((item) => {
      const updatedItem = { ...item };

      // Check if 'unit1' is set to "No Unit" and convert to an empty string
      if (item.unit1 && unitNameToAbbreviation[item.unit1.toLowerCase()]) {
        updatedItem.unit1 = unitNameToAbbreviation[item.unit1.toLowerCase()];
      } else if (item.unit1 === 'No Unit' || !item.unit1) {
        updatedItem.unit1 = ''; // Set to an empty string if "No Unit" or undefined
      } else {
        updatedItem.unit1 = item.unit1.toLowerCase();
      }

      if (item.unit2 && unitNameToAbbreviation[item.unit2.toLowerCase()]) {
        updatedItem.unit2 = unitNameToAbbreviation[item.unit2.toLowerCase()];
      } else if (item.unit2 === 'No Unit' || !item.unit2) {
        updatedItem.unit2 = ''; // Set to an empty string if "No Unit" or undefined
      } else {
        updatedItem.unit2 = item.unit2.toLowerCase();
      }

      return updatedItem;
    });
    const editEvalObject = {
      title: evalName,
      items: updatedItems,
      description: '',
      id: editEvalModal1Object.id,
    };

    dispatch(editEvaluation(currentUser.accountCode, editEvalObject, editEvalModal1Object.id));
  };

  const setSelectedDate = (date) => {
    dispatch(setSelectedEvalDate(date));
  };

  const filterTypeResolver = () => {
    if (evalGroupTypeOption === 'Select By Athlete Group') {
      return 2;
    } if (evalGroupTypeOption === 'Select By Calendars') {
      return 3;
    }
    return 1;
  };

  const setEvalGroupHandler = (date) => {
    setEvalGroupOption(date);
    dispatch(setEvalGroupOptionRedux(date));
  };

  useEffect(() => {
    if (editSlideIndex === 1) {
      setActiveSlide(1);
    } else {
      setActiveSlide(0);
    }
  }, [editEvalModal1Object, editSlideIndex]);

  return (
    // the isActive parameter gets passed to the ModalContainer
    // the ModalContainer transitions based on the truth of isModalActive
    <ModalContainer
      onClick={(e) => {
        if (e.target !== 'floating-eval-selector-box' && selectEvalTypeBoxActive) {
          setSelectEvalTypeBoxActive(false);
        }
        if (e.target !== 'floating-eval-group-selector-box' && selectEvalGroupBoxActive) {
          setSelectEvalGroupBoxActive(false);
        }
        if (e.target !== 'floating-date-picker-comp' && pickerOpen) {
          setPickerOpen(false);
        }
      }}
      isActive={Object.keys(editEvalModal1Object).length}
      customWidth={activeSlide === 1 ? '550px' : '1000px'}
    >
      <RelativeContainer>
        <div id='date-selector-container-edit' />
        <div id='eval-selector-container-edit' />
        <div id='eval-group-selector-container-edit' />
        <ButtonContainer>
          <PortalContainer>
            <RemoveDiv>
              <div
                className='remove-div-icon'
                onClick={() => {
                  if (activeSlide === 1) {
                    setActiveSlide(0);
                  }
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={18}
                  icon='left-arrow'
                  color={activeSlide === 1 ? 'black' : 'white'}
                />
              </div>
              <ModalHeader>
                <HeaderIconContainer>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='pencil'
                  />
                </HeaderIconContainer>
                <ModalHeaderText>Edit Evaluation</ModalHeaderText>
                <ErrorBox>
                  {errors.errors[0].isShowing
                    ? <MappedError>{errors.errors[0].msg}</MappedError> : null}
                  {errors.errors[1].isShowing
                    ? <MappedError>{errors.errors[1].msg}</MappedError> : null}
                  {errors.errors[2].isShowing && isButtonPressed
                    ? <MappedError>{errors.errors[2].msg}</MappedError> : null}
                </ErrorBox>
              </ModalHeader>
              <div
                className='remove-div-icon'
                onClick={() => {
                  dispatch(setEditEvalModal1({}));
                }}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={15}
                  icon='remove'
                  color='black'
                />
              </div>
            </RemoveDiv>
            <ContentContainer>
              <SlidesContainer customHeight={activeSlide === 1 ? '250px' : 'auto'}>
                <Slides activeSlide={activeSlide}>
                  <Slide>
                    <EvalSelectionContainer>
                      <EvalSelectionText>Evaluation Name</EvalSelectionText>
                      <EvalSelectionBox
                        value={evalName}
                        onBlur={() => {
                          if (!evalName.length) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = true;
                            setErrors(newErrors);
                          }
                        }}
                        onChange={(e) => {
                          if (errors.errors[0].isShowing && e.target.value.length) {
                            const newErrors = { ...errors };
                            newErrors.errors[0].isShowing = false;
                            setErrors(newErrors);
                          }
                          setEvalName(e.target.value);
                        }}
                        placeholder='Evaluation'
                      />
                    </EvalSelectionContainer>
                    <EditEvaluationTable
                      trackedExercises={trackedExercises}
                      units={trackedItemUnits}
                      items={items}
                      setItems={setItems}
                    />
                  </Slide>
                  <Slide>
                    <SlideCenterDiv>
                      <Slide2BlockRow>
                        <BlockText>
                          Select Evaluation Date
                        </BlockText>
                        <DatePickerDiv onClick={() => setPickerOpen(!pickerOpen)}>
                          <DatePickerText>{selectedEvalDate ? selectedEvalDate.format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')}</DatePickerText>
                          {dscElement ? createPortal(
                            <SelectEvalDatePicker
                              id='floating-date-picker-comp'
                              isDayPickerOpen={pickerOpen}
                              setIsDayPickerOpen={setPickerOpen}
                              activeDate={selectedEvalDate}
                              selectDate={setSelectedDate}
                            />, dscElement,
                          ) : null}
                          <EndBlock>
                            <IcomoonReact
                              iconSet={iconSet}
                              size={15}
                              icon='calendar'
                              color='white'
                            />
                          </EndBlock>
                        </DatePickerDiv>
                      </Slide2BlockRow>
                      <Slide2Block2>
                        <BlockText>
                          Evaluation Group
                        </BlockText>
                        <SelectEvaluationGroupBox
                          active={selectEvalTypeBoxActive}
                          onClick={() => {
                            setSelectEvalTypeBoxActive(true);
                            openFloatingSelector(evalGroupBoxRef);
                          }}
                          ref={evalTypeBoxRef}
                        >
                          {!evalGroupTypeOption ? (
                            <EvaluationSelectText>
                              i.e. Select All Athletes
                            </EvaluationSelectText>
                          ) : (
                            <SelectedEvaluationGroupText>
                              {evalGroupTypeOption}
                            </SelectedEvaluationGroupText>
                          )}
                          <EvalSelectEndBlock selected={evalGroupTypeOption}>
                            <IcomoonReact
                              iconSet={iconSet}
                              size={15}
                              icon={evalGroupTypeOption ? 'swap' : 'trashcan'}
                              color={evalGroupTypeOption ? 'white' : 'black'}
                            />
                          </EvalSelectEndBlock>
                        </SelectEvaluationGroupBox>
                        {escElement ? createPortal(
                          <FloatingEvalGroupTypeSelector
                            id='floating-eval-selector-box'
                            active={selectEvalTypeBoxActive}
                            style={floatingSelectorStyle}
                          >
                            {evalSelectOptions.map(
                              (selectOption) => (
                                <FloatingEvalOption
                                  onClick={() => setEvalGroupTypeOption(selectOption)}
                                >
                                  {selectOption}
                                </FloatingEvalOption>
                              ),
                            )}
                          </FloatingEvalGroupTypeSelector>, escElement,
                        ) : null}
                      </Slide2Block2>
                      {evalGroupTypeOption === 'Select By Athlete Group' || evalGroupTypeOption === 'Select By Calendars' ? (
                        <Slide2Block3>
                          <SelectEvaluationGroupBox
                            active={selectEvalGroupBoxActive}
                            onClick={() => setSelectEvalGroupBoxActive(true)}
                            ref={evalGroupBoxRef}
                          >
                            {!evalGroupOption ? (
                              <EvaluationSelectText>
                                i.e. Select
                              </EvaluationSelectText>
                            ) : (
                              <SelectedEvaluationGroupText>
                                {evalGroupOption.name}
                              </SelectedEvaluationGroupText>
                            )}
                            <EvalSelectEndBlock selected={evalGroupOption}>
                              <IcomoonReact
                                iconSet={iconSet}
                                size={15}
                                icon={evalGroupOption ? 'swap' : 'trashcan'}
                                color={evalGroupOption ? 'white' : 'black'}
                              />
                            </EvalSelectEndBlock>
                          </SelectEvaluationGroupBox>
                          {gElement ? createPortal(
                            <FloatingEvalGroupSelector
                              id='floating-eval-group-selector-box'
                              active={selectEvalGroupBoxActive}
                              style={floatingSelectorStyle}
                            >
                              {evalGroupTypeOption === 'Select By Calendars' ? calendars.map(
                                (groupTypeOption) => (
                                  <FloatingEvalGroupOption
                                    onClick={() => setEvalGroupHandler(groupTypeOption)}
                                  >
                                    {groupTypeOption.name}
                                  </FloatingEvalGroupOption>
                                ),
                              ) : groups.map(
                                (groupTypeOption) => (
                                  <FloatingEvalGroupOption
                                    onClick={() => setEvalGroupHandler(groupTypeOption)}
                                  >
                                    {groupTypeOption.name}
                                  </FloatingEvalGroupOption>
                                ),
                              )}
                            </FloatingEvalGroupSelector>, gElement,
                          ) : null}
                        </Slide2Block3>
                      ) : null}
                    </SlideCenterDiv>
                  </Slide>
                </Slides>
              </SlidesContainer>
            </ContentContainer>
            {(!isEvaluationContentTable && !isEvaluationsContentList) && (
            <GoEvaluationButtonContainer activeSlide={activeSlide}>
              <GoEvaluationButton onClick={() => {
                setActiveSlide(1);
              }}
              >
                <GoEvaluationButtonText>
                  GO TO EVALUATION
                </GoEvaluationButtonText>

              </GoEvaluationButton>
            </GoEvaluationButtonContainer>
            )}
          </PortalContainer>
          <Button
            bottom
            fullWidth
            cta={activeSlide === 0 ? 'Save' : 'Submit'}
            className='print-button'
            large
            square
            noBorder
            isLoading={isEvaluationLoading}
            primary
            disabled={isEvaluationLoading}
            onClick={() => {
              if (activeSlide === 0) {
                setIsButtonPressed(true);
                if (!evalName.length) {
                  const newErrors = { ...errors };
                  newErrors.errors[0].isShowing = true;
                  setErrors(newErrors);
                }
                if (!items.length) {
                  const newErrors = { ...errors };
                  newErrors.errors[1].isShowing = true;
                  setErrors(newErrors);
                }
                if (items.length && evalName.length && !errors.errors[0].isShowing
                  && !errors.errors[1].isShowing
                  && !errors.errors[2].isShowing
                ) {
                  submitNewEval();
                  dispatch(setEditEvalModal1({}));
                }
              } else if (activeSlide === 1) {
                batch(() => {
                  dispatch(fetchEvalFromEdit({
                    accountCode: currentUser.accountCode,
                    evaluationId: editEvalModal1Object.id,
                    selectedDate: selectedEvalDate?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD'),
                    filterType: filterTypeResolver(),
                    filterId: evalGroupOption.id,
                  }));
                  dispatch(setEditEvalModal1({}));
                });
              }
            }}
          />
        </ButtonContainer>
      </RelativeContainer>
    </ModalContainer>
  );
};

EditEvalModal.defaultProps = {
  modalTitle: '',
  useEffectFunction: undefined,
  mediaDispatchFunction: undefined,
  isDarkMode: false,
};

EditEvalModal.propTypes = {

};

export default EditEvalModal;
