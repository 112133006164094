import update from 'immutability-helper';
import findKey from 'lodash.findkey';

import generateUniqueId from '../../../shared/utils/generateUniqueId';

import {
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_LEADERBOARD_FULFILLED,
  FETCH_LEADERBOARD_REJECTED,
  FETCH_NEXT_PAGE_FULFILLED,
  FETCH_NEXT_PAGE_START,
  FETCH_NEXT_PAGE_REJECTED,
  EDIT_LEADERBOARD_FULFILLED,
  EDIT_LEADERBOARD_REJECTED,
  CLOSE_BOARD,
  SET_MODAL_OPEN,
  UPDATE_SETTING,
} from './actionTypes';

const initialState = {
  exercises: [],
  exercisesError: '',
  groups: [],
  groupsError: '',
  leaderboardError: '',
  leaderboards: {},
  leaderboardsReference: {},
  recentLeaderboards: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        exercises: action.payload,
      };
    }
    case FETCH_EXERCISES_REJECTED: {
      return {
        ...state,
        exercisesError: action.payload,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groupsError: action.payload,
      };
    }
    case FETCH_LEADERBOARD_FULFILLED: {
      const leaderboardId = generateUniqueId();

      // To prevent duplicates in recent leaderboards, we find the
      // key of the object in leaderboards that has the same leaderboardForm values in
      // the new leaderboard object.
      const sameLeaderboard = findKey(
        state.recentLeaderboards,
        { leaderboardForm: action.payload.values },
      );
      console.log(state.recentLeaderboards, sameLeaderboard);

      // If sameLeaderboard is undefined, the new leaderboard is unique
      // so we add it to the leaderboards, and recentLeaderboards object.
      // If it isn't undefined, we just add it to leaderboards.
      if (sameLeaderboard === undefined) {
        return update(state, {
          leaderboards: {
            $merge: {
              [leaderboardId]: {
                id: leaderboardId,
                leaderboardData: action.payload.data,
                leaderboardExercise: action.payload.exerciseName,
                leaderboardForm: action.payload.values,
                page: 1,
                settings: {
                  autoScroll: false,
                  autoLoad: false,
                  loadTime: { value: 60000, label: 'One Minute' },
                },
              },
            },
          },
          // We're adding our new leaderboard to a leaderboardsReference object that
          // only holds a board id for optimizing our main boards view. We then pass the
          // id to reference our main leaderboard object with data for child board components.
          leaderboardsReference: {
            $merge: {
              [leaderboardId]: {
                id: leaderboardId,
              },
            },
          },
          recentLeaderboards: {
            $merge: {
              [leaderboardId]: {
                id: leaderboardId,
                leaderboardData: [],
                leaderboardExercise: action.payload.exerciseName,
                leaderboardForm: action.payload.values,
                page: 1,
                settings: {
                  autoScroll: false,
                  autoLoad: false,
                  loadTime: { value: 60000, label: 'One Minute' },
                },
              },
            },
          },
        });
      }
      return update(state, {
        leaderboards: {
          $merge: {
            [leaderboardId]: {
              id: leaderboardId,
              leaderboardData: action.payload.data,
              leaderboardExercise: action.payload.exerciseName,
              leaderboardForm: action.payload.values,
              page: 1,
              settings: {
                autoScroll: false,
                autoLoad: false,
                loadTime: { value: 60000, label: 'One Minute' },
              },
            },
          },
        },
        leaderboardsReference: {
          $merge: {
            [leaderboardId]: {
              id: leaderboardId,
            },
          },
        },
      });
    }
    case EDIT_LEADERBOARD_FULFILLED: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            $set: {
              id: action.payload.id,
              leaderboardData: action.payload.data,
              leaderboardExercise: action.payload.exerciseName,
              leaderboardForm: action.payload.values,
              page: 1,
              settings: {
                ...state.leaderboards[action.payload.id].settings,
              },
            },
          },
        },
      });
    }
    case FETCH_LEADERBOARD_REJECTED: {
      return {
        ...state,
        leaderboardError: action.payload.response.headers,
      };
    }
    case FETCH_NEXT_PAGE_START: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            isLeaderboardLoading: {
              $set: true,
            },
          },
        },
      });
    }
    case FETCH_NEXT_PAGE_FULFILLED: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            leaderboardData: {
              $push: action.payload.page === state.leaderboards[action.payload.id].page
                ? []
                : action.payload.data,
            },
            page: {
              $set: action.payload.page,
            },
            isEndOfList: {
              $set: action.payload.data.length === 0,
            },
            isLeaderboardLoading: {
              $set: false,
            },
          },
        },
      });
    }
    case CLOSE_BOARD: {
      return update(state, {
        leaderboards: {
          $unset: [action.payload.id],
        },
        leaderboardsReference: {
          $unset: [action.payload.id],
        },
      });
    }
    case SET_MODAL_OPEN: {
      return {
        ...state,
        leaderboardError: '',
      };
    }
    case UPDATE_SETTING: {
      return update(state, {
        leaderboards: {
          [action.payload.id]: {
            settings: {
              [action.payload.setting]: {
                $set: action.payload.value,
              },
            },
          },
        },
      });
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
