import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import {
  setNewEvalModal1,
  setSelectExistingEvalModal1,
  setManageEvaluationsScreenOn,
} from '../../ducks/evaluationsActions';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';

const ModalContainer = styled('div')`
  height: 65%;
  min-width: 300px;
  // z-index: 1003;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  position: absolute; 
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-height: 650px;
  @media only screen and (max-width : 768px) {
    flex-direction: column;
    max-height: 100%;
    height: auto;
    transform: ${(props) => (props.isActive ? 'none' : 'translate(-50%, 300%)')};
    position: ${(props) => (props.isActive ? 'relative' : 'absolute')};
    top: -20px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 80px;
  }
  @media only screen and (max-width : 400px) {
    height: 600px;
    overflow-y: scroll;
  }
`;

const InteriorContainer = styled('div')`
  background: white;
  box-shadow: ${BoxShadow};
  border-radius: 12px;
  height: 80%;
  width: 30%;
  min-width: 275px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  @media only screen and (max-width : 768px) {
    margin-top: 20px;
    width: 80%;
    height: 200px;
  }

  &:hover {
    box-shadow: 0 0 10px #0DCC8A;

  }
`;

const ContentContainer = styled('div')`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 50%;
`;

const OptionTitle = styled('div')`
	font-family: 'Nunito Sans';
  font-weight: 700;
  font-size: 16px;
  display: flex;
  margin-left: 12%;
  margin-top: 20px;
  @media only screen and (max-width : 768px) {
    margin-top: 12px;
  }
`;

const OptionGraphic = styled('div')`
  height: 100%;
  box-shadow: ${BoxShadow};
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
`;

const OptionGraphic2 = styled('div')`
  height: 100%;
  box-shadow: ${BoxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding-left: 20px;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10%;
  margin-right: 10%;
`;

const OptionGraphic3 = styled('div')`
  height: 100%;
  box-shadow: ${BoxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
`;

const GreenLine = styled('div')`
	width: 100%;
  height: 3px;
  background: #0AA36E;
  margin-left: 10px;
`;

const RegularLine = styled('div')`
	width: 100%;
  height: 3px;
  background: #BDBDBD;
  margin-left: 10px;
`;

const AbsoluteTrash = styled('div')`
	position: absolute;
  bottom: -20px;
  right: -50px;
  opacity: 0.4;

  @media only screen and (max-width : 768px) {
    bottom: -10px;
    right: -20px;
  
  }
`;

const TrashContainer = styled('div')`
	position: relative;
`;

const OuterGreenCircle = styled('div')`
	width: 125px;
  height: 125px;
  background: #CFF5E8;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  @media only screen and (max-width : 768px) {
    height: 80px;
    width: 80px;
  }
`;

const InnerCircle = styled('div')`
	width: 61px;
  height: 61px;
  background: #3DD6A1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width : 768px) {
    height: 40px;
    width: 40px;
  }
`;

const GreenBar = styled('div')`
	width: 100%;
  height: 45px;
  background: #CFF5E8;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

const RegularBar = styled('div')`
	width: 100%;
  height: 45px;
  border-top: 2px solid #EEEEEE;
  border-left: 2px solid #EEEEEE;
  border-bottom: 2px solid #EEEEEE;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
`;

const HorizontalBar = styled('div')`
	width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftLineBox = styled('div')`
	display: flex;
  flex-direction: column;
  justify-content: center;
  height: 15px;
  justify-content: space-evenly;
`;

const TopLine = styled('div')`
	display: flex;
  flex-direction: column;
  width: 70px;
  height: 2px;
  background: #A9A9A9;
  border-radius: 10px;
`;

const BottomLine = styled('div')`
	display: flex;
  flex-direction: column;
  width: 40px;
  height: 2px;
  background: #A9A9A9;
  border-radius: 10px;
`;

const BarBox = styled('div')`
	height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 10px;
`;

const MedBubble = styled('div')`
	height: 11px;
  width: 35px;
  border: ${(props) => (props.color ? `2px solid ${props.color}` : '2px solid #E1E1E1')};
  margin-right: 10px;
  border-radius: 3px;
`;

const SmallBubble = styled('div')`
	height: 11px;
  width: 25px;
  border: ${(props) => (props.color ? `2px solid ${props.color}` : '2px solid #E1E1E1')};
  margin-right: 10px;
  border-radius: 3px;
`;

const LargeBubble = styled('div')`
	height: 11px;
  width: 45px;
  border: ${(props) => (props.color ? `2px solid ${props.color}` : '2px solid #E1E1E1')};
  margin-right: 10px;
  border-radius: 3px;
`;

const BubbleBox = styled('div')`
  display: flex;
`;

const TitleWrapper = styled('div')`
  display: flex;
  width: 89%;
`;

const HeaderDiv = styled('div')`
  position: absolute;
  top: -20px;
  left: 0;
  margin-left: 2%;

  @media only screen and (max-width : 768px) {
    position: relative;
    top: 0;
    margin-left: 0;
  }
`;
const Icon = styled(IcomoonReact)`
  height: ${(props) => props.size || '30px'};
  width: ${(props) => props.size || '30px'};

  @media only screen and (max-width: 768px) {
    height: ${(props) => props.mobileSize || '20px'};
    width: ${(props) => props.size || '20px'};
  }
`;

const SelectionModal = () => {
  const dispatch = useDispatch();
  const firstSelectionModalOpen = useSelector(
    (state) => state.evaluations.ui.firstSelectionModalOpen,
  );
  return (
    <ModalContainer isActive={firstSelectionModalOpen}>
      <HeaderDiv>
        <HeaderText>
          Evaluations
        </HeaderText>
      </HeaderDiv>
      <InteriorContainer onClick={() => {
        dispatch(setNewEvalModal1(true));
      }}
      >
        <ContentContainer>
          <OptionGraphic>
            <OuterGreenCircle>
              <InnerCircle>
                <Icon
                  iconSet={iconSet}
                  size='15px'
                  icon='plus'
                  color='white'
                />
              </InnerCircle>
            </OuterGreenCircle>
            <BarBox>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble />
                  <SmallBubble />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble color='#77A7FF' />
                  <SmallBubble />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble />
                  <SmallBubble />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble />
                  <SmallBubble />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble />
                  <SmallBubble color='#FFCB47' />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
              <HorizontalBar>
                <LeftLineBox>
                  <TopLine />
                  <BottomLine />
                </LeftLineBox>
                <BubbleBox>
                  <MedBubble />
                  <LargeBubble />
                  <SmallBubble />
                  <SmallBubble />
                </BubbleBox>
              </HorizontalBar>
            </BarBox>
          </OptionGraphic>
          <TitleWrapper>
            <OptionTitle>
              Create New Evaluation
            </OptionTitle>
          </TitleWrapper>
        </ContentContainer>
      </InteriorContainer>
      <InteriorContainer onClick={() => {
        dispatch(setSelectExistingEvalModal1(true));
      }}
      >
        <ContentContainer>
          <OptionGraphic2>
            <GreenBar>
              <Icon
                iconSet={iconSet}
                size='30px'
                mobileSize='20px'
                icon='checkmark'
                color='#0DCC8A'
              />
              <GreenLine />
            </GreenBar>
            <RegularBar>
              <Icon
                iconSet={iconSet}
                size='30px'
                mobileSize='20px'
                icon='checkmark'
                color='#BDBDBD'
              />
              <RegularLine />
            </RegularBar>
            <RegularBar>
              <Icon
                iconSet={iconSet}
                icon='checkmark'
                size='30px'
                mobileSize='20px'
                color='#BDBDBD'
              />
              <RegularLine />
            </RegularBar>
          </OptionGraphic2>
          <OptionTitle>
            Select Existing Evaluation
          </OptionTitle>
        </ContentContainer>
      </InteriorContainer>
      <InteriorContainer onClick={() => dispatch(setManageEvaluationsScreenOn())}>
        <ContentContainer>
          <OptionGraphic3>
            <TrashContainer>
              <Icon
                iconSet={iconSet}
                size='130px'
                mobileSize='50px'
                icon='settings'
                color='#0DCC8A'
              />
              <AbsoluteTrash>
                <Icon
                  iconSet={iconSet}
                  size='100px'
                  mobileSize='50px'
                  icon='wrench'
                  color='#0DCC8A'
                />
              </AbsoluteTrash>
            </TrashContainer>
          </OptionGraphic3>
          <OptionTitle>
            Manage Evaluations
          </OptionTitle>
        </ContentContainer>
      </InteriorContainer>
    </ModalContainer>
  );
};

SelectionModal.propTypes = {

};

export default SelectionModal;
