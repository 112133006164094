/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleLogin from 'react-google-login';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  connectGoogleAccount,
  disconnectGoogleAccount,
} from '../../../../profile/ducks/profileActions';
import { fetchUserData } from '../../../../login/ducks/loginActions';

import Text from '../../../../../shared/components/Text/Text';
import theme from '../../../../../shared/theme/theme';
// import GoogleIcon from '../../../../../shared/images/google_icon.svg';
import GoogleImage from '../../../../../shared/images/google-2015-logo.png';
import BlueButton from '../BlueButton';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import { object } from '@storybook/addon-knobs';

const Image = styled('img')`
  width: 116px;
`;

const HeaderContainer = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: #E0E0E0 1px solid;

`;

// eslint-disable-next-line react/prop-types
const DisconnectButton = ({ disconnect }) => (
  <div
    role='button'
    tabIndex={0}
    style={{
      borderRadius: 5,
      height: 45,
      width: 200,
      alignItems: 'center',
      backgroundColor: theme.colors.red,
      display: 'flex',
      justifyContent: 'center',
    }}
    onClick={disconnect}
  >
    <Text color='white' fontSize={20} fontWeight='800'>
      Disconnect
    </Text>
  </div>
);

const SingleSignOn = ({ containerWidth }) => {
  const dispatch = useDispatch();
  // const me = useSelector((state) => state.profile.data.me);
  const me = useSelector((state) => state.auth.data.currentUser);

  const disconnectGoogle = () => {
    dispatch(disconnectGoogleAccount());
    dispatch(fetchUserData());
  };

  const handleGoogleResponse = (response) => {
    dispatch(
      connectGoogleAccount({
        googleId: response.tokenId,
      }),
    );
    setTimeout(() => dispatch(fetchUserData()), 800);
  };

  const handleFailResponse = (response) => {
    console.log(response);
    console.log('this is on single sign on page');
  };

  const render = () => (
    <>
      <HeaderContainer>
        <Text color='black' fontSize={containerWidth && containerWidth < 400 ? 24 : 28} fontWeight='700'>
          Single Sign On
        </Text>
        <Text fontSize={18} fontWeight='400'> Manage alternative ways to sign in to the TeamBuildr platform.</Text>
      </HeaderContainer>

      <div style={{
        alignItems: 'center',
        borderBottomColor: theme.colors.noBorderColor,
        borderBottomWidth: 1,
        display: 'flex',
        flexDirection: 'row',
        height: 70,
        justifyContent: 'space-between',
      }}
      >
        <Image alt='Google' src={GoogleImage} />
        {Object.keys(me?.userSettings?.google).length ? (
          <DisconnectButton disconnect={disconnectGoogle} />
        ) : (
          <GoogleLogin
            clientId='849504945663-2a20rmtkv13kkrs7idoafi82fvoko52l.apps.googleusercontent.com'
            buttonText='Login with Google'
            onSuccess={handleGoogleResponse}
            onFailure={handleFailResponse}
            cookiePolicy='single_host_origin'
            render={(renderProps) => (
              <div style={{ width: 300, marginLeft: 10 }}>
                <BlueButton
                  cta='Login with Google'
                  disabled={renderProps.disabled}
                  noHover
                  onClick={renderProps.onClick}
                  fullWidth
                  primary
                  customColor='#77A7FF'
                  customTextColor='white'
                  customBorderRadius='12px'
                  noBorder
                  upperCase
                  large
                />
              </div>
            )}
          />
        )}
      </div>
    </>
  );

  return (
    <div style={{
      display: 'flex',
      marginBottom: 20,
      flexDirection: 'column',
      width: '100%',
    }}
    >
      {Object.keys(me).length && render()}
    </div>
  );
};

SingleSignOn.propTypes = {
  containerWidth: PropTypes.number,
};

SingleSignOn.defaultProps = {
  containerWidth: '',
};

export default SingleSignOn;
