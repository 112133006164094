import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ColorGreen } from '../../../../shared/GlobalStyles';
import ButtonRound from './ButtonRound';
import Checkmark from './Checkmark';

const CompleteContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.theme.backgroundColor};
  z-index: 1000;
`;

const CompleteIcon = styled('div')`
  height: 40px;
  margin-bottom: 40px;
  zoom: 200%;
  .complete-icon {
    color: white;
    background-color: ${ColorGreen};
    border: 1px solid ${ColorGreen};
    path {
      fill: rgb(255,255,255)!important;
    }
  }
`;

const CompleteButtons = styled('div')`
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 10px;
  }
`;

const Complete = ({
  handleCloseWorkout,
  handleClosePanel,
}) => (
  <CompleteContainer>
    <CompleteIcon>
      {/* <IconRound
        className='complete-icon'
        icon='checkmark'
        xLarge
      /> */}
      <Checkmark />
    </CompleteIcon>
    <CompleteButtons>
      <ButtonRound
        onClick={() => handleCloseWorkout()}
        cta='Exercise List'
        icon='list'
        gray
      />
      <ButtonRound
        onClick={() => handleClosePanel()}
        cta='Finish Workout'
        icon='remove'
        gray
      />
    </CompleteButtons>
  </CompleteContainer>
);

Complete.propTypes = {
  handleCloseWorkout: PropTypes.func.isRequired,
  handleClosePanel: PropTypes.func.isRequired,
};

export default Complete;
