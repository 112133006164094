import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AlertBanner from '../../../../../shared/components/AlertBanner/AlertBanner';
import Text from '../../../../../shared/components/Text/Text';
import { stripeAdminLogin } from '../../../ducks/resellerActions';

const ResellerAlertBanner = () => {
  const [clicked, setClicked] = useState(false);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const stripeAdminLoginURL = useSelector((state) => state.reseller.data.stripeAdminURL);
  const isLoadingStripeAdmin = useSelector((state) => state.reseller.ui.isLoadingStripeAdmin);

  const dispatch = useDispatch();

  useEffect(() => {
    if (stripeAdminLoginURL && clicked) {
      window.open(stripeAdminLoginURL, '_blank');
    }
  }, [stripeAdminLoginURL]);

  const handleClick = () => {
    dispatch(stripeAdminLogin(currentUser && currentUser.accountCode));
    setClicked(true);
  };

  return (
    <AlertBanner 
      isClickable
      handleClick={handleClick}
      isLoading={isLoadingStripeAdmin}
    >
      <Text>Your account is missing some information before you can charge customers. <span style={{ fontWeight: 600 }}>Click to complete.</span></Text>
    </AlertBanner>
  );
}
 
export default ResellerAlertBanner;