import styled from '@emotion/styled';
import {
  space, color, layout, flexbox,
} from 'styled-system';

const Wrapper = styled('div')`
  ${color}
  cursor: pointer;
  display: flex;
  ${flexbox}
  flex-direction: column;
  ${layout}
  ${space}
`;

export default Wrapper;
