import styled from '@emotion/styled';
import {
  space, color, layout, flexbox, typography,
} from 'styled-system';

const SelectTitle = styled('div')`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  font-family: 'Nunito Sans';
  margin-bottom: 5px;
`;

export default SelectTitle;
