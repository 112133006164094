import React from 'react';
import PropTypes from 'prop-types';

import AppHeader from '../../../AppHeader/AppHeader';
import useLayout from '../../hooks/useLayout';

const LayoutAppHeader = ({
  hideHeader,
}) => {
  const {
    currentUser,
    fetchUnread,
    fetchUserAccounts,
    handleLogOut,
    location,
    unreadMessages,
    isUnreadMessagesLoading,
  } = useLayout();

  return (
    <AppHeader
      data={currentUser}
      handleLogOut={handleLogOut}
      fetchUnreadMessages={fetchUnread}
      isUnreadMessagesLoading={isUnreadMessagesLoading}
      fetchUserAccounts={fetchUserAccounts}
      location={location}
      unreadMessages={unreadMessages}
      currentUser={currentUser}
      hideHeader={hideHeader}
    />
  );
};

LayoutAppHeader.propTypes = {
  hideHeader: PropTypes.bool,
};

LayoutAppHeader.defaultProps = {
  hideHeader: undefined,
};

export default LayoutAppHeader;
