import React, { useEffect, useState } from 'react';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Table from '../../../../../shared/components/Table/Table';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import useReseller from '../../hooks/useReseller';
import ModalHandler from '../reseller/ModalHandler';
import ResellerAlertBanner from '../reseller/ResellerAlertBanner';
import ResellerSubscribersFilter from '../reseller/ResellerSubscribersFilter';
import ResellerOverlay from '../reseller/ResellerOverlay';

const MainContainer = styled('main')`
  flex: 10;
  overflow-y: scroll;
`;

const GridContainer = styled('div')`
  margin: 0px 30px;
  padding-bottom: 60px;
`;

const FilterContainer = styled('div')`
  position: relative;
  margin-left: 30px;
  margin-right: 30px;
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 60%;
  justify-content: center;
  width: 100%;
  margin-left: -150px;
`;

const HeaderRow = styled('div')`
  display: flex;
  margin: 30px 30px 20px 30px;
  justify-content: space-between;
  h1 {
    text-transform: capitalize;
  }
`;

const SecondStageInnerWrapper1 = styled('div')`
    display: flex;
    width: 400px;
    min-width: 220px;
    align-items: center;
    justify-content: space-between;
    .toggle-text {
      text-align: center;
      display: flex;
      justify-content: center;
      width: 200px;
      margin-left: 15px;
    }
  `;

/**
   * cells for the table:
   * orientation arrow, icon, name, user count, created date, workout visability, dropdown toggle
   */
const columns = [
  {
    cell: (row) => row.lastName,
    name: 'Last',
    sortable: true,
    selector: 'lastName',
    style: {
      'font-weight': 'bold',
    },
  },
  {
    cell: (row) => row.firstName,
    name: 'First',
    sortable: true,
    style: {
      'font-weight': 'bold',
    },
  },
  {
    cell: (row) => row.currentProgramName,
    name: 'Current Program',
    sortable: true,
    selector: 'currentProgramName',
  },
  {
    cell: (row) => {
      if (row.subscriptionStatus === 1) {
        return 'Active';
      }
      if (row.subscriptionStatus === 2) {
        return 'Payment Failed';
      }
      if (row.subscriptionStatus === 3) {
        return 'Cancelled';
      }
    },
    name: 'Subscription Status',
    sortable: true,
    selector: 'subscriptionStatus',
    center: true,
    style: {
      'margin-left': '-25px',
    },
  },
  {
    cell: (row) => (
      <IcomoonReact
        iconSet={iconSet}
        size={20}
        icon='altrightarrow'
      />
    ),
    right: true,
  },
];

const customTableStyle = {
};

/**
 * this array is passed to the react data table component, and styles the rows
 * differently depending on whether they meet certain criteria.  currently, I'm giving
 * child calendars sharp edges and clumping them together under the parent calendar
 */
const conditionalRowStyles = [
  {
    when: (row) => row.id,
    style: {
      paddingLeft: '10px',
    },
  },
];

const SubscribersGrid = () => {
  const {
    subscribers,
    isSubscribersLoading,
    currentUser,
    currentReseller,
  } = useReseller();

  const [subscriberList, setSubscriberList] = useState('active');
  const [currentSubscriber, setCurrentSubscriber] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [currentChargeItem, setCurrentChargeItem] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
  }, [currentUser]);

  const customNoRecordComponent = (theme) => {
    const TextBox = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    font-size: 20px;
  `;
    const TextAndIcon = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;
  `;
    return (
      <TextAndIcon>
        <TextBox>
          <Text fontSize={theme.textFontSizes[3]}>
            There are currently no
            {' '}
            {subscriberList}
            {' '}
            subscribers that match your filter.
          </Text>
        </TextBox>
      </TextAndIcon>
    );
  };

  return (
    <MainContainer>
      <ResellerOverlay />
      {!currentReseller.stripeChargesEnabled && (
        <ResellerAlertBanner />
      )}
      <HeaderRow>
        <HeaderText
          fontWeight={300}
          letterSpacing='normal'
        >
          Subscribers
        </HeaderText>
      </HeaderRow>
      <FilterContainer>
        <ResellerSubscribersFilter />
      </FilterContainer>
      <GridContainer>
        {isSubscribersLoading ? (<SpinnerWrapper><Spinner /></SpinnerWrapper>) : (
          <Table
            columns={columns}
            data={subscribers}
            highlightOnHover
            pointerOnHover
            noHeader
            onRowClicked={(row) => {
              setCurrentSubscriber(row);
            }}
            responsive
            style={customTableStyle}
            conditionalRowStyles={conditionalRowStyles}
            noDataComponent={!isSubscribersLoading ? customNoRecordComponent(theme) : null}
          />
        )}
      </GridContainer>
      <ModalHandler
        setCurrentSubscription={setCurrentSubscription}
        currentSubscription={currentSubscription}
        setCurrentChargeItem={setCurrentChargeItem}
        currentChargeItem={currentChargeItem}
        currentSubscriber={currentSubscriber}
        setCurrentSubscriber={setCurrentSubscriber}
      />
    </MainContainer>
  );
};

export default SubscribersGrid;
