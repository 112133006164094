/* eslint-disable no-nested-ternary */
/**
 * FilterDropdown is a shared component that acts as a portable dropdown list.  It accepts a
 * list of Filter components, though these are not necessary.
 */

/**
 * The carousel component is a complex component that renders
 * the container within which the list of workout items are rendered
 * as cards - interactive elements that display the details of the
 * specific workout
 */

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import NewWorkoutsAlert from './NewWorkoutsAlert';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import { setHistoryWorkout, setAdditionalInfoWorkout, wipeMediaError } from '../../ducks/workoutsActions';
import getAllUrlParams from '../../../../shared/utils/getAllUrlParams';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

// to adjust height of the carousel cards, adjust the padding-top prop here
const CarouselContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isActive ? '' : '0')};
  background-color: ${(props) => (props.isActive ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)')};
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  margin-top: -49px;
  @media only screen and (min-height : 1200px) {
    transform: scale(1.3);
  }
  @media only screen and (max-height : 724px) {
    overflow-y: scroll;
    padding-top: 30px;
  }
  @media only screen and (min-height : 1700px) {
    transform: scale(1.9);
  }
  @media only screen and (min-height : 725px) {
    padding-bottom: 99px;
  }
  scroll-behavior: smooth;
`;

const Overlay = styled('div')`
  height: 100vh;
  width: 100vw;
  top: 0;
  position: absolute;
  left: 0;
  z-index: 999;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const CarouselInnerWindow = styled('div')`
  white-space: nowrap;
  transition: transform 0.4s;
  transform: ${(props) => `translateX(-${props.activeIndex * 100}%)`};
  height: 100%;
  width: 40%;
  min-width: 319px;
  @media only screen and (max-width : 768px) {
    max-width: none;
    width: 100%;
  }
  max-width: 350px;
  min-height: 250px;
`;

const CarouselItem = styled('div')`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  @media only screen and (max-height : 724px) {
    vertical-align: top;
    padding-bottom: 175px;
    align-items: flex-start;
  }
  @media only screen and (min-height : 725px) {
    height: 100%;
  }
`;

const leftMarginGrabber = (activeIndex, index, addMarginLeft) => {
  if (activeIndex === index - 1 && addMarginLeft) {
    return '60px';
  }
  if (activeIndex === index - 1 && !addMarginLeft) {
    return '20px';
  }
  return '0px';
};

const rightMarginGrabber = (activeIndex, index, addMarginRight) => {
  if (activeIndex === index + 1 && addMarginRight) {
    return '60px';
  }
  if (activeIndex === index + 1 && !addMarginRight) {
    return '20px';
  }
  return '0px';
};

const CarouselItemInnerElements = styled('div')`
  width: ${(props) => ((props.index === props.activeIndex) && props.shouldExpand ? '115%' : '80%')};
  @media only screen and (max-width : 768px) {
    width: 80%;
  }
  transform: ${(props) => (props.index === props.activeIndex ? 'scale(1.15)' : 'scale(1)')};
  /* transition: all 0.3s ease-in-out; */
  height: 80%;
  @media only screen and (max-height : 724px) {
    height: auto;
    transform: ${(props) => (props.index === props.activeIndex ? 'scale(1.05)' : 'scale(1)')};
    max-height: none;
  }
  display: flex;
  justify-content: center;
  background: white;
  align-items: center;
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
  margin-left: ${(props) => leftMarginGrabber(props.activeIndex, props.index, props.addMarginLeft)};
  margin-right: ${(props) => rightMarginGrabber(props.activeIndex, props.index, props.addMarginRight)};
  max-height: 600px;
`;

const ButtonContainer = styled('div')`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  bottom: 0px;
  z-index: 1000;
  align-items: center;
  height: 120px;
  opacity: ${(props) => (props.isActive ? '' : '0')};
  /* opacity: ${(props) => (props.isActive ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0)')}; */
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  /* background-color: ${(props) => (props.isActive ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0)')}; */
  transition: all 0.3s ease;
`;

const ButtonInnerElement = styled('div')`
  display: flex;
  height: 50%;
  width: 50%;
  background: white;
  border-radius: 50px;
  justify-content: space-between;
  min-width: 200px;
  max-width: 250px;
  box-shadow: ${BoxShadow};
`;

const SpinnerWrapper = styled('div')`
  height: 100%;
  svg {
    height: 40px;
  }
`;

const LeftOptionButton = styled('div')`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  transition: opacity 0.3s;
  opacity: ${(props) => (props.enabled ? 1 : 0)};
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  box-shadow: ${BoxShadow};
`;

const RightOptionButton = styled('div')`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  transition: opacity 0.3s;
  cursor: ${(props) => (props.enabled ? 'pointer' : 'default')};
  opacity: ${(props) => (props.enabled ? 1 : 0)};
  box-shadow: ${BoxShadow};
`;

const NavButton = styled('button')`
  border: none;
  border-radius: 50px;
  width: 75px;
  transition: opacity 0.3s;
`;

const CloseCatch = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

/**
  *
  * @param {Boolean} isShowing boolean that dictates whether the FilterDropdown is
  * @param {Array<Objects>} cards array of card objects, which include various properties, includes:
  * at least a cardElement key that points to a value of <Card /> component in the shared folder.
  * Also optionally a key and a prop (shouldExpand) that indicates whether the element should
  * be the optional large size of 100% of the container (as opposed to 60%)
  * @param {Function} setIsShowing function for setting showing bool
  * @param {Number} defaultIndex integer for setting the index the carousel should open to
  * @returns a home-brewed Teambuildr carousel with transitions, animations, and index flexibility
  */

const Carousel = ({
  isShowing,
  setIsShowing,
  cards = [],
  defaultIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  // const [touchPosition, setTouchPosition] = useState(null);
  const dispatch = useDispatch();

  const isWorkoutCompleting = useSelector((state) => state.workouts.ui.isWorkoutCompleting);
  const quickJournalMediaError = useSelector((state) => state.workouts.data.quickJournalMediaError);

  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);

  const handleTouchStart = (e) => {
    // const touchDown = e.touches[0].clientX;
    // setTouchPosition(touchDown);
  };

  const containerRef = useRef();

  // this effect watches for the default index and sets active to it
  useEffect(() => {
    if (defaultIndex || defaultIndex === 0) {
      setActiveIndex(defaultIndex);
    }
  }, [defaultIndex]);

  useEffect(() => {
    if (!isLocked) {
      const params = getAllUrlParams(window.location.href, false);
      if ((params.assignedid || params.saveDataId) && cards.length) {
        cards.forEach((element, idx) => {
          const elementId = element.cardElement.props.workoutObject.assignedId
          || element.cardElement.props.workoutObject.saveDataId;
          if (parseInt(params.assignedid, 10) === elementId
          || parseInt(params.saveDataId, 10) === elementId) {
            setIsShowing(true);
            setActiveIndex(idx);
            setIsLocked(true);
          }
        });
      }
    }
  }, [cards]);

  // function for updating index based on input number
  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      setActiveIndex(cards.length - 1);
    } else if (newIndex > cards.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(newIndex);
    }
  };

  // function for handling swiping
  const handleTouchMove = (e) => {
    // const touchDown = touchPosition;
    // if (touchDown === null) {
    //   return;
    // }
    // const currentTouch = e.touches[0].clientX;
    // const diff = touchDown - currentTouch;
    // if (diff > 5) {
    //   updateIndex(activeIndex + 1);
    // }
    // if (diff < -5) {
    //   updateIndex(activeIndex - 1);
    // }
    // setTouchPosition(null);
  };

  /**
    * @param {Array} inputArray array of cards to map
    * @returns a function that determines the styling of the element
    * (expanded or regular) and its neighbors
    * Also, returns an array of carousel items
    */
  const itemMapper = (inputArray) => inputArray.map((item, index) => {
    const {
      cardElement, shouldExpand,
    } = item;
    let nextElement;
    if (index < inputArray.length - 1) {
      nextElement = inputArray[index + 1];
    }
    let addMarginRight = false;
    if (nextElement?.shouldExpand) {
      addMarginRight = true;
    }
    let prevElement;
    if (index > 0) {
      prevElement = inputArray[index - 1];
    }
    let addMarginLeft = false;
    if (prevElement?.shouldExpand) {
      addMarginLeft = true;
    }
    const isFinalIndex = index === inputArray.length - 1;

    return (
      <CarouselItem onClick={() => {
        if (index !== activeIndex) {
          setActiveIndex(index);
        }
      }}
      >
        <CarouselItemInnerElements
          index={index}
          activeIndex={activeIndex}
          shouldExpand={shouldExpand}
          addMarginRight={addMarginRight}
          addMarginLeft={addMarginLeft}
        >
          {React.cloneElement(cardElement,
            {
              activeIndex,
              setActiveIndex,
              isFinalIndex,
            })}
        </CarouselItemInnerElements>
      </CarouselItem>
    );
  });

  return (
    <>
      <CarouselContainer
        ref={containerRef}
        id='carousel-container'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        isActive={isShowing}
        onClick={(e) => {
          if (e.target.id === 'carousel-container') {
            setIsShowing(false);
          }
        }}
      >
        <CarouselInnerWindow itemNumber={cards.length} activeIndex={activeIndex}>
          {itemMapper(cards)}
        </CarouselInnerWindow>
      </CarouselContainer>
      <ButtonContainer
        id='button-container'
        isActive={isShowing}
        onClick={(e) => {
          if (e.target.id === 'button-container') {
            setIsShowing(false);
          }
        }}
      >
        <CloseCatch>
          <LeftOptionButton
            onClick={() => dispatch(setAdditionalInfoWorkout(
              cards[activeIndex]?.cardElement?.props?.workoutObject,
            ))}
            enabled={cards[activeIndex]?.cardElement?.props?.workoutObject?.exercise?.media
             || cards[activeIndex]?.cardElement?.props?.workoutObject?.exercise?.description}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={25}
              icon='info'
              color='black'
            />
          </LeftOptionButton>
          <ButtonInnerElement>
            <NavButton
              style={{
                opacity: isWorkoutCompleting ? '0' : '1',
              }}
              disabled={activeIndex === 0 || isWorkoutCompleting}
              onClick={() => {
                if (quickJournalMediaError) {
                  dispatch(wipeMediaError());
                }
                updateIndex(activeIndex - 1);
                setTimeout(() => {
                  containerRef.current.scrollTo(0, 0);
                }, 325);
              }}
              type='button'
            >
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='left-arrow'
                color={activeIndex !== 0 ? 'black' : 'lightgrey'}
              />
            </NavButton>
            {!isWorkoutCompleting ? (
              <NavButton
                style={{
                  opacity: !isWorkoutCompleting ? '1' : '0',
                }}
                onClick={() => {
                  setActiveIndex(0);
                  setIsShowing(false);
                }}
                type='button'
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={25}
                  icon='remove'
                  color='black'
                />
              </NavButton>
            ) : (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}
            <NavButton
              style={{
                opacity: isWorkoutCompleting ? '0' : '1',
              }}
              disabled={activeIndex === cards.length - 1 || isWorkoutCompleting}
              onClick={() => {
                if (quickJournalMediaError) {
                  dispatch(wipeMediaError());
                }
                updateIndex(activeIndex + 1);
                setTimeout(() => {
                  containerRef.current.scrollTo(0, 0);
                }, 325);
              }}
              type='button'
            >
              <IcomoonReact
                iconSet={iconSet}
                size={25}
                icon='right-arrow'
                color={activeIndex !== cards.length - 1 ? 'black' : 'lightgrey'}
              />
            </NavButton>
          </ButtonInnerElement>
          <RightOptionButton
            enabled={cards[activeIndex]?.cardElement?.props?.workoutObject?.historyEnabled}
            onClick={() => {
              if (cards[activeIndex]?.cardElement?.props?.workoutObject?.historyEnabled) {
                dispatch(setHistoryWorkout(
                  cards[activeIndex]?.cardElement?.props?.workoutObject,
                ));
              }
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={30}
              icon='clock'
              color='black'
            />
          </RightOptionButton>
        </CloseCatch>
        <NewWorkoutsAlert updateIndex={updateIndex} activeIndex={activeIndex} />
      </ButtonContainer>
      <Overlay isActive={isShowing} onClick={() => setIsShowing(false)} />
    </>
  );
};

Carousel.defaultProps = {
  defaultIndex: 0,
};

Carousel.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  setIsShowing: PropTypes.func.isRequired,
  cards: PropTypes.instanceOf(Array).isRequired,
  defaultIndex: PropTypes.number,
};

export default Carousel;
