import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_FULFILLED,
  FETCH_DOCUMENTS_REJECTED,
  FETCH_FOLDER_START,
  FETCH_FOLDER_FULFILLED,
  FETCH_FOLDER_REJECTED,
  UPLOAD_DOCUMENT_START,
  UPLOAD_DOCUMENT_FULFILLED,
  UPLOAD_DOCUMENT_REJECTED,
  FETCH_FOLDERS_START,
  FETCH_FOLDERS_FULFILLED,
  FETCH_FOLDERS_REJECTED,
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_FULFILLED,
  FETCH_DOCUMENT_REJECTED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  CREATE_DOCUMENT_START,
  CREATE_DOCUMENT_FULFILLED,
  CREATE_DOCUMENT_REJECTED,
  DELETE_DOCUMENTS_START,
  DELETE_DOCUMENTS_FULFILLED,
  DELETE_DOCUMENTS_REJECTED,
  UPDATE_DOCUMENT_START,
  UPDATE_DOCUMENT_FULFILLED,
  UPDATE_DOCUMENT_REJECTED,
} from './actionTypes';

const initialState = {
  isDocumentsLoading: true,
  isFolderLoading: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_START: {
      return {
        ...state,
        isDocumentsLoading: true,
      };
    }
    case FETCH_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case FETCH_DOCUMENTS_REJECTED: {
      return {
        ...state,
        isDocumentsLoading: false,
      };
    }
    case FETCH_DOCUMENT_START: {
      return {
        ...state,
        isDocumentLoading: true,
      };
    }
    case FETCH_DOCUMENT_FULFILLED: {
      return {
        ...state,
        isDocumentLoading: false,
      };
    }
    case FETCH_DOCUMENT_REJECTED: {
      return {
        ...state,
        isDocumentLoading: false,
      };
    }
    case FETCH_FOLDER_START: {
      return {
        ...state,
        isFolderLoading: true,
      };
    }
    case FETCH_FOLDER_FULFILLED: {
      return {
        ...state,
        isFolderLoading: false,
      };
    }
    case FETCH_FOLDER_REJECTED: {
      return {
        ...state,
        isFolderLoading: false,
      };
    }
    case FETCH_FOLDERS_START: {
      return {
        ...state,
        isFoldersLoading: true,
      };
    }
    case FETCH_FOLDERS_FULFILLED: {
      return {
        ...state,
        isFoldersLoading: false,
      };
    }
    case FETCH_FOLDERS_REJECTED: {
      return {
        ...state,
        isFoldersLoading: false,
      };
    }
    case UPLOAD_DOCUMENT_START: {
      return {
        ...state,
        isDocumentUploading: true,
      };
    }
    case UPLOAD_DOCUMENT_FULFILLED: {
      return {
        ...state,
        isDocumentUploading: false,
      };
    }
    case UPLOAD_DOCUMENT_REJECTED: {
      return {
        ...state,
        isDocumentUploading: false,
      };
    }
    case FETCH_USER_GROUPS_START: {
      return {
        ...state,
        isUserGroupsLoading: true,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        isUserGroupsLoading: false,
      };
    }
    case FETCH_USER_GROUPS_REJECTED: {
      return {
        ...state,
        isUserGroupsLoading: false,
      };
    }
    case CREATE_DOCUMENT_START: {
      return {
        ...state,
        isDocumentCreating: true,
      };
    }
    case CREATE_DOCUMENT_FULFILLED: {
      return {
        ...state,
        isDocumentCreating: false,
      };
    }
    case CREATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        isDocumentCreating: false,
      };
    }
    case DELETE_DOCUMENTS_START: {
      return {
        ...state,
        isDocumentDeleting: true,
      };
    }
    case DELETE_DOCUMENTS_FULFILLED: {
      return {
        ...state,
        isDocumentDeleting: false,
      };
    }
    case DELETE_DOCUMENTS_REJECTED: {
      return {
        ...state,
        isDocumentDeleting: false,
      };
    }
    case UPDATE_DOCUMENT_START: {
      return {
        ...state,
        isDocumentUpdating: true,
      };
    }
    case UPDATE_DOCUMENT_FULFILLED: {
      return {
        ...state,
        isDocumentUpdating: false,
      };
    }
    case UPDATE_DOCUMENT_REJECTED: {
      return {
        ...state,
        isDocumentUpdating: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
