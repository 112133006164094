import moment from 'moment';

import {
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  SET_WEEK_RANGE,
  SET_END_DATE,
  SET_GROUP,
  SET_START_DATE,
  SET_METRIC_FILTERS_PERFORMANCE,
  SET_METRIC_FILTERS_GROUP,
  SET_GROUP_FILTER_RADIO,
  FETCH_ATHLETES_FULFILLED_HT_DASHBOARD,
  FETCH_ATHLETES_REJECTED_HT_DASHBOARD,
  FETCH_HABITS_FOR_ACCOUNT_FULFILLED,
  FETCH_HABITS_FOR_ACCOUNT_REJECTED,
  FETCH_GROUP_OVERVIEW_FULFILLED,
  FETCH_GROUP_OVERVIEW_REJECTED,
  FETCH_GROUP_HABITS_FULFILLED,
  FETCH_GROUP_HABITS_REJECTED,
  FETCH_OVERVIEW_FULFILLED,
  FETCH_OVERVIEW_REJECTED,
  SET_SELECTED_HABITS,
  RESET_OVERVIEW_DATA,
  RESET_GROUP_OVERVIEW_DATA,
  SET_SELECTED_GROUP_HABITS,
  SET_SELECTED_GROUP_DROPDOWN_OPTIONS,
  SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS,
  SET_HABITS,
  FETCH_GROUP_OVERVIEW_HABITS_FULFILLED,
  FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED,
} from './actionTypes';

const initialState = {
  endDate: moment().format('YYYY-MM-DD'),
  groupFilterRadio: 'all',
  group: null,
  groups: [],
  performanceOverviewData: [],
  performanceOverviewError: null,
  selectedAthleteGroup: null,
  selectedAthletePerformance: null,
  startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),

  athletes: [],
  athletesError: null,
  athleteHabits: [],
  fetchingHabitsError: null,
  overviewResults: [],
  overViewError: null,

  habits: [],
  habitsError: null,

  groupHabits: [],
  groupOverviewHabits: [],
  groupOverview: [],

  selectedHabits : [],
  selectedGroupHabits: [],
  weekRange: '1W',

  selectedGroupDropdownOptions : [],
  selectedPerformanceDropdownOptions : [],

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_HABITS: {
      return {
        ...state,
        habits: action.payload,
      };
    }
    case SET_SELECTED_GROUP_DROPDOWN_OPTIONS: {
      return {
        ...state,
        selectedGroupDropdownOptions: action.payload,
      };
    }
    case SET_SELECTED_PERFORMANCE_DROPDOWN_OPTIONS: {
      return {
        ...state,
        selectedPerformanceDropdownOptions: action.payload,
      };
    }
    case SET_SELECTED_GROUP_HABITS: {
      return {
        ...state,
        selectedGroupHabits: action.payload,
      };
    }
    case SET_WEEK_RANGE: {
      return {
        ...state,
        weekRange: action.payload,
      };
    }
    case RESET_OVERVIEW_DATA: {
      return {
        ...state,
        overviewResults: [],
      };
    }
    case RESET_GROUP_OVERVIEW_DATA: {
      return {
        ...state,
        groupOverview: [],
      };
    }
    case SET_SELECTED_HABITS: {
      return {
        ...state,
        selectedHabits: action.payload,
      };
    }
    case FETCH_GROUP_OVERVIEW_HABITS_FULFILLED: {
      return {
        ...state,
        groupOverviewHabits: action.payload,
      };
    }
    case FETCH_GROUP_OVERVIEW_HABITS_FOR_ACCCOUNT_FULFILLED: {
      return {
        ...state,
        groupOverviewHabits: action.payload,
      };
    }
    case FETCH_GROUP_HABITS_FULFILLED: {
      return {
        ...state,
        habits: action.payload,
      };
    }
    case FETCH_GROUP_HABITS_REJECTED: {
      return {
        ...state,
        habitsError: [],
      };
    }
    case FETCH_GROUP_OVERVIEW_FULFILLED: {
      return {
        ...state,
        groupOverview: action.payload,
      };
    }
    case FETCH_GROUP_OVERVIEW_REJECTED: {
      return {
        ...state,
        groupOverviewError: action.payload,
      };
    }
    case FETCH_OVERVIEW_FULFILLED: {
      return {
        ...state,
        overviewResults: action.payload,
      };
    }
    case FETCH_OVERVIEW_REJECTED: {
      return {
        ...state,
        overViewError: action.payload,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_FULFILLED: {
      return {
        ...state,
        habits: action.payload,
      };
    }
    case FETCH_HABITS_FOR_ACCOUNT_REJECTED: {
      return {
        ...state,
        fetchingHabitsError: action.payload,
      };
    }
    case FETCH_ATHLETES_FULFILLED_HT_DASHBOARD: {
      return {
        ...state,
        athletes: action.payload,
      };
    }
    case FETCH_ATHLETES_REJECTED_HT_DASHBOARD: {
      return {
        ...state,
        athletesError: action.payload,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groups: [],
      };
    }
    case SET_METRIC_FILTERS_PERFORMANCE: {
      return {
        ...state,
        metricFiltersPerformance: action.payload,
      };
    }
    case SET_METRIC_FILTERS_GROUP: {
      return {
        ...state,
        metricFiltersGroup: action.payload,
      };
    }
    case SET_GROUP_FILTER_RADIO: {
      return {
        ...state,
        groupFilterRadio: action.payload,
      };
    }
    case SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_START_DATE: {
      return {
        ...state,
        startDate: action.payload,
      };
    }
    case SET_END_DATE: {
      return {
        ...state,
        endDate: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
