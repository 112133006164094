import React, { useCallback, memo } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AnimatedEntryView from './AnimatedEntryView';
import { fetchExercises, fetchSimilarExercises, openModal } from '../../ducks/panelActions';

// import useMeasure from '../hooks/useMeasure';

import Workout from './Workout';
// import WeightroomWorkoutEntryView from '../container/WeightroomWorkoutEntryView';

const WorkoutWrapper = styled('div')`
  display: block;

  &:first-of-type {
    .workout-container {
      &:before {
        display: none; 
      }
    }
  }
  &.break {
    & .exercise_icon {
      &:before {
        display: none;
      }
    }
  }

`;

// const EntryViewWrapper = styled('div')`
//   display: block;
// `;

const WorkoutContainer = ({
  activeWorkoutIndex,
  entriesContainerRef,
  entriesViewHeight,
  entryRowRef,
  formikRef,
  // fetchExercises,
  // fetchSimilarExercises,
  handleActiveWorkout,
  index,
  namespace,
  nextButton,
  // openModal,
  questionListRef,
  scrollQuestions,
  supersetIndex,
  // totalFormikValues,
  // workout,
  workoutWrapperRef,
  workoutInnerRef,
  workoutRef,
}) => {
  // const activeWorkoutIndex = useSelector(state => state[namespace].activeWorkoutIndex);
  const totalFormikValues = useSelector(state => state[namespace].totalFormikValues);
  const workout = useSelector(state => state[namespace].workouts[index]);
  const activeScreens = useSelector(state => state.weightRoom.activeScreens);

  const dispatch = useDispatch();

  const isWorkoutActive = (activeWorkoutIndex === index);
  const hasResult = workout.result !== null;
  const exerId = (workout.result && (workout.result.substitution > 0))
    ? workout.result.substitution
    : workout.exer_id;

  const handleOpenModal = useCallback((modal) => {
    dispatch(openModal(namespace, modal));
    if (modal === 'opt-out') {
      batch(() => {
        dispatch(fetchExercises(namespace));
        dispatch(fetchSimilarExercises(namespace, exerId));
      });
    }
  }, [namespace, exerId]);

  return (
    <WorkoutWrapper
      ref={workoutWrapperRef}
    >
      <Workout
        activeScreens={activeScreens}
        complete={workout.result && (workout.result.fully_completed)}
        hasResult={hasResult}
        icon={workout.exer_icon}
        id={workout.work_id}
        index={index}
        isActive={isWorkoutActive}
        exerId={
          (hasResult && workout.result.substitution > 0) ? (
            workout.result.substitution
          ) : (
            workout.exer_id
          )
        }
        exerType={workout.exer_type}
        grouping={workout.grouping}
        handleActiveWorkout={handleActiveWorkout}
        handleOptOut={handleOpenModal}
        hasInfo={workout.info !== ''}
        hasDescription={workout.description !== ''}
        isOptedOut={workout.result && (workout.result.opt_out > 0)}
        key={workout.work_id}
        name={
          (hasResult && workout.result.substitution > 0) ? (
            workout.result.sub_exer_name
          ) : (
            workout.exer_name
          )
        }
        handleOpenModal={handleOpenModal}
        ref={workoutRef}
        subHeader={workout.sub_header}
        totalFormikValues={totalFormikValues}
        isTotalScienceEntries={workout.total === 1}
        type={workout.exer_type}
        workoutInner={workoutInnerRef}
      />
      <AnimatedEntryView
        entriesContainerRef={entriesContainerRef}
        entryRowRef={entryRowRef}
        entriesViewHeight={entriesViewHeight}
        formikRef={formikRef}
        index={index}
        isWorkoutActive={isWorkoutActive}
        namespace={namespace}
        nextButton={nextButton}
        questionListRef={questionListRef}
        scrollQuestions={scrollQuestions}
        supersetIndex={supersetIndex}
      />
    </WorkoutWrapper>
  );
};

WorkoutContainer.propTypes = {
  activeWorkoutIndex: PropTypes.number,
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleActiveWorkout: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  // modalOpen: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  nextButton: PropTypes.func.isRequired,
  questionListRef: PropTypes.instanceOf(Object).isRequired,
  scrollQuestions: PropTypes.func.isRequired,
  supersetIndex: PropTypes.number,
  // workout: PropTypes.instanceOf(Object).isRequired,
  workoutInnerRef: PropTypes.instanceOf(Object).isRequired,
  workoutWrapperRef: PropTypes.instanceOf(Object).isRequired,
  workoutRef: PropTypes.instanceOf(Object).isRequired,
};

WorkoutContainer.defaultProps = {
  activeWorkoutIndex: null,
  supersetIndex: 0,
};

// const activePropsAreEqual = (prevProps, nextProps) => (
//   // prevProps.index !== nextProps.activeWorkoutIndex + 1
//   //   && prevProps.index !== nextProps.activeWorkoutIndex - 1
//   nextProps.activeWorkoutIndex !== nextProps.index
//     // && nextProps.activeWorkoutIndex !== prevProps.index + 1
//     // && nextProps.activeWorkoutIndex !== prevProps.index - 1
//     && prevProps.activeWorkoutIndex !== prevProps.index
//     // && nextProps.activeWorkoutIndex !== ''
// );

export default memo(WorkoutContainer);
