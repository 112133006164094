import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import styled from '@emotion/styled';

import Avatar from '../../../shared/components/Avatar/Avatar';
import Text from '../../../shared/components/Text/Text';
import tbLogo from '../../../shared/images/t-logo-xl.png';
import { sharedWorkoutModal, setDownloadButtonClicked } from '../ducks/sharedWorkoutActions';
import { logOut } from '../../login/ducks/loginActions';

const HeaderContainer = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  height: 49px;
  width: 100%;
  padding: 0 40px;
  background-color: #472233;
  box-shadow: 1px;
  z-index: 1000;
`;

const LeftNav = styled('div')`
`;

const RightNav = styled('div')`
  background: #FF6600;
`;

const NavLink = styled('a')`
  display: flex;
  position: relative;
  height: 49px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  letter-spacing: 1.2px;
  line-height: 23px;
  text-decoration: none;
  cursor: pointer;

  .nav-logo {
    height: 20px;
  }

  .login-text {
    color: #ffffff;
    font-size: 12px;
  }
`;

const CustomNav = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isAuthenticated = useSelector((state) => state.auth.ui.isAuthenticated);
  const [cookies, setCookies] = useCookies(['v2token']);
  const dispatch = useDispatch();

  const isAuthenticatedv3 = cookies.v2token !== undefined && cookies.v2token !== '0'
  && cookies.accessToken !== undefined && cookies.accessToken !== '0'
  && cookies.session_id !== undefined && cookies.session_id !== '0'
  && cookies.session_key !== undefined && cookies.session_key !== '0';

  const loginHandler = () => {
    if (isAuthenticated || isAuthenticatedv3) {
      dispatch(sharedWorkoutModal('download'));
    } else {
      dispatch(sharedWorkoutModal('login'));
    }
  };

  const signOut = () => {
    dispatch(logOut());
    dispatch(setDownloadButtonClicked(false));
    setCookies('v2token', '0', { path: '/' });
    setCookies('accessToken', '0', { path: '/' });
    setCookies('session_id', '0', { path: '/' });
    setCookies('session_key', '0', { path: '/' });
  };

  const userName = Object.keys(currentUser).length ? `${currentUser.first} ${currentUser.last}` : '';

  return (
    <HeaderContainer>
      <LeftNav>
        <NavLink href='https://app.teambuildr.com'>
          <img
            className='nav-logo'
            src={tbLogo}
            alt='teambuildr logo'
          />
        </NavLink>
      </LeftNav>
      <RightNav>
        { isAuthenticated || isAuthenticatedv3 ? (
          <NavLink>
            <>
              <Avatar
                avatar={currentUser.pic}
                size={20}
              />
              { userName ? (
                <Text className='login-text'>{userName}</Text>
              ) : ''}
              <Text className='login-text' onClick={signOut}>(Sign Out)</Text>
            </>
          </NavLink>
        ) : (
          <NavLink onClick={loginHandler}>
            <Text className='login-text'>Sign In</Text>
          </NavLink>
        )}
      </RightNav>
    </HeaderContainer>
  );
};

export default CustomNav;
