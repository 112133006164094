import React, { memo } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Logger from 'js-logger';

import Table from '../../../../shared/components/Table/Table';

const TableWrapper = styled('div')`
    padding: 0px 20px;
    margin-top: 30px;
  .rdt_TableBody {
    padding: 0px 5px;
  }

  @media screen and (max-width: 540px) {
    padding: 0px;
    margin: 0px;
  }
`;

const MaxTable = ({
  setActiveAthlete,
  tableColumns,
  tableData,
}) => {
  const handleRowClicked = (row) => {
    setActiveAthlete(parseInt(row.userId, 10));
  };

  Logger.debug(tableColumns, tableData);

  return (
    <TableWrapper>
      <Table
        center
        columns={tableColumns}
        data={tableData}
        highlightOnHover
        noHeader
        pointerOnHover
        onRowClicked={row => (row.userFirst !== 'Average' ? handleRowClicked(row) : null)}
      />
    </TableWrapper>
  );
};

MaxTable.propTypes = {
  setActiveAthlete: PropTypes.func.isRequired,
  tableColumns: PropTypes.instanceOf(Object).isRequired,
  tableData: PropTypes.instanceOf(Object).isRequired,
};

export default memo(MaxTable);
