/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';

import WorkoutsList from './WorkoutsList';
import TopWrapperContainer from './TopWrapperContainer';

import {

} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const CenterColumnWrapper = styled('div')`
	display: flex;
	width: 50%;
  margin-left: 30px;
  margin-right: 30px;
  flex-direction: column;
  min-width: 350px;
  @media only screen and (max-width : 768px) {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  @media only screen and (min-width : 769px) and (max-width : 820px) {
    width: 80%;
  }
`;

const WorkoutsListWrapper = styled('div')`
	display: flex;
  justify-content: center;
  background: white;
  width: 100%;
  margin-top: 30px;
  border-radius: 24px;
  box-shadow: ${BoxShadow};
`;

const WorkoutsCenterColumn = () => (
  <CenterColumnWrapper>
    <TopWrapperContainer />
    <WorkoutsListWrapper>
      <WorkoutsList />
    </WorkoutsListWrapper>
  </CenterColumnWrapper>
);

WorkoutsCenterColumn.propTypes = { };

export default WorkoutsCenterColumn;
