import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '../../../../shared/components/Button/Button';
import {
  fetchGroupAnalysis,
  setFlaggedAthletes,
  setFlaggedAthletesView,
  setGroupFilterRadio,
  setIsMetricFiltersGroupShowing,
  setMetricFiltersGroup,
  setSelectedAthleteGroup,
} from '../../ducks/forcePlateDashboardActions';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
  margin-top: 8px;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 290px;
  height: 420px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;

  .MuiFormLabel-root {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    color: #444444;
  }

  .MuiTypography-body1 {
    font-family: 'Nunito Sans';
    font-weight: normal;
  }
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;

  h3 {
    font-weight: normal;
  }
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const GroupFilter = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const endDateData = useSelector((state) => state.forcePlateDashboard.data.endDateData);
  const groupFilterRadio = useSelector((state) => state.forcePlateDashboard.data.groupFilterRadio);
  const group = useSelector((state) => state.forcePlateDashboard.data.group);
  const isMetricFiltersGroupShowing = useSelector((
    state,
  ) => state.forcePlateDashboard.ui.isMetricFiltersGroupShowing);
  const startDateData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.startDateData);
  const metricFiltersGroup = useSelector((
    state,
  ) => state.forcePlateDashboard.data.metricFiltersGroup);

  const metricOptions = [
    'Peak Power',
    'Jump Height',
    'Takeoff Velocity',
    'mRSI',
    'Flight Time',
    'Stiffness',
    'Positive Impulse',
    'Braking RFD (Rate of Force Development)',
    'Countermovement Depth',
    'Peak Relative Power',
    'Peak Landing Force',
    'Braking Impulse',
    'L|R Braking Impulse',
    'L|R Landing Impulse',
    'L|R Landing RFD',
    'L|R Concentric Impulse',
    'Left - Average Propulsive Force',
    'Right - Average Propulsive Force',
    'Left - Average Landing Force',
    'Right - Average Landing Force',
  ];

  const dispatch = useDispatch();

  const handleFilter = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', 'Filter clicked');
    dispatch(setIsMetricFiltersGroupShowing(!isMetricFiltersGroupShowing));
  };

  const handleClose = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', 'Filter closed');
    dispatch(setIsMetricFiltersGroupShowing(false));
  };

  const handleUpdate = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', 'Filters updated');
    batch(() => {
      if (groupFilterRadio === 'alerts') {
        dispatch(setFlaggedAthletesView(true));
        dispatch(setSelectedAthleteGroup(null, false));
      } else {
        dispatch(setFlaggedAthletesView(false));
        dispatch(setFlaggedAthletes([]));
      }
      dispatch(setIsMetricFiltersGroupShowing(false));
      dispatch(
        fetchGroupAnalysis(
          currentUser.accountCode,
          startDateData,
          endDateData,
          group.id,
          metricFiltersGroup,
        ),
      );
    });
  };

  const handleChange = (metric) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', `Filter selected - ${metric}`);
    // Remove whitespace from metric to fit in request body
    const formattedMetric = metric.replace(/\s/g, '');
    // If metric is already in filters, remove it
    if (metricFiltersGroup.includes(formattedMetric)) {
      const newMetrics = [...metricFiltersGroup];
      const index = newMetrics.indexOf(formattedMetric);
      if (index > -1) {
        newMetrics.splice(index, 1);
      }
      dispatch(setMetricFiltersGroup(newMetrics));
    // Else add it to the filters array
    } else {
      const newMetrics = [...metricFiltersGroup];
      newMetrics.push(formattedMetric);
      dispatch(setMetricFiltersGroup(newMetrics));
    }
  };

  const handleClear = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', 'Filters cleared');
    batch(() => {
      dispatch(setMetricFiltersGroup([]));
      dispatch(setGroupFilterRadio('all'));
    });
  };

  const handleRadioChange = (e) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', `${e.target.value} radio selected`);
    dispatch(setGroupFilterRadio(e.target.value));
  };

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
      />
      {metricFiltersGroup.length > 0 && (
        <Badge>{metricFiltersGroup.length}</Badge>
      )}
      {isMetricFiltersGroupShowing && (
      <FilterContainer>
        <FilterHeader>
          Filter
          <Button
            icon='remove'
            iconOnly
            onClick={handleClose}
          />
        </FilterHeader>
        <Filters>
          <FormControl>
            <FormLabel id='athlete-options'>Athletes</FormLabel>
            <RadioGroup
              className='radioOptions'
              row
              aria-labelledby='athlete-options-group-label'
              value={groupFilterRadio}
              onChange={handleRadioChange}
              name='radio-buttons-group'
            >
              <FormControlLabel value='all' label='View All' control={<Radio color='default' />} />
              <FormControlLabel value='alerts' label='Alerts Only' control={<Radio color='default' />} />
            </RadioGroup>
          </FormControl>
          Metric(s)
          {metricOptions.map(
            (option) => (
              <FilterOption key={option}>
                <Checkbox
                  color='default'
                  checked={metricFiltersGroup.includes(option.replace(/\s/g, ''))}
                  onChange={() => handleChange(option)}
                />
                {option.length > 25 ? (
                  <TBTooltip
                    textToDisplay={option}
                    fontSize='16px'
                    textContainerPadding='0px'
                    textContainerMaxWidth='200px'
                  />
                ) : (
                  <OptionText>{option}</OptionText>
                )}
              </FilterOption>
            ),
          )}
        </Filters>
        <FilterBottom>
          <Button
            cta='Clear'
            rounded
            onClick={handleClear}
          />
          <Button
            cta='Update'
            disabled={metricFiltersGroup.length === 0}
            rounded
            onClick={handleUpdate}
          />
        </FilterBottom>
      </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default GroupFilter;
