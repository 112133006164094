import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import styled from '@emotion/styled';
import IconRound from './IconRound';
import Modal from './Modal';

import Spinner from './Spinner';
import ButtonRound from './ButtonRound';
// import { SpinnerContainer } from './PanelWorkouts';
import Title from './Title';

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  /* ${props => (
    props.activeScreens === 4
      ? 'height: calc(50vh - 137px);'
      : 'height: calc(100vh - 137px);'
  )} */
  flex: 1;
  position: relative;

  /* @media screen and (orientation: portrait) {
    ${props => (
    props.activeScreens <= 2 && `
      height: calc(50vh - 137px);
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${props => (
    props.activeScreens <= 2 && `
      height: calc(100vh - 137px);
    `
  )}
  } */
`;

const TagContainer = styled('div')`
  display: flex;
  padding-left: 30px;
  button {
    margin-top: 20px;
  }

  h2 {
    text-align: center;
  }
`;

const VideoButton = styled('div')`
  position: absolute;
  left: 270px;
  margin: 0px 5px 0px 30px;
  cursor: pointer;
  margin-top: -30px;
`;

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  min-height: 420px;
`;

const TagEntries = ({
  athleteId,
  activeDate,
  exercisesSimilar,
  formikRef,
  fetchSimilarTypeExercises,
  index,
  isLoadingSimExercises,
  namespace,
  updateTagWorkout,
  workout,
}) => {
  // const [exerciseView, setExerciseView] = useState('similar');
  const [isVidModalOpen, setIsVidModalOpen] = useState(false);
  const [previewVideo, setVideoPreview] = useState('');

  useEffect(() => {
    fetchSimilarTypeExercises(namespace, workout.exer_id);
  }, []);

  const isPreviewVideoEmpty = (previewVideo !== '') && (previewVideo !== null);

  return (
    <TagContainer>
      <Formik
        key={workout.work_id}
        ref={formikRef}
        initialValues={
          {
            work_id: workout.work_id,
            user_id: athleteId,
            new_exercise: 0,
          }
        }
        onSubmit={(values, actions) => {
          setTimeout(() => {
            updateTagWorkout(athleteId, activeDate, index, namespace, values);
            // alert(JSON.stringify(values, null, 2))
            actions.setSubmitting(false);
          }, 1000);
        }}
        render={({
          setFieldValue,
          values,
          isSubmitting,
        }) => (
          <Form>
            {isLoadingSimExercises === false ? (
              <Fragment>
                {typeof exercisesSimilar !== 'string' ? (
                  <Fragment>
                    <p>
                      Select an exercise and click &quot;Save&quot;
                    </p>
                    <Select
                      menuPortalTarget={document.body}
                      onChange={(options) => { setFieldValue('new_exercise', options.value); setVideoPreview(options.data.video); }}
                      options={typeof exercisesSimilar !== 'string' ? exercisesSimilar.result.options : ('')}
                      value={values.new_exercise.value}
                    />
                    {isPreviewVideoEmpty && (
                      <VideoButton onClick={() => setIsVidModalOpen(true)}>
                        <IconRound
                          icon='video'
                          large
                        />
                      </VideoButton>
                    )}
                    <ButtonRound
                      cta='Save'
                      icon='checkmark'
                      type='submit'
                      disabled={isSubmitting}
                    />
                  </Fragment>
                ) : (
                  <Title icon='opt-out'>
                    {exercisesSimilar}
                  </Title>
                )}

              </Fragment>
            ) : (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </Form>
        )}
      />
      <Modal
        isOpen={isVidModalOpen}
        onRequestClose={() => setIsVidModalOpen(false)}
      >
        <StyledIframe
          className='video-iframe'
          src={previewVideo}
        />
      </Modal>
    </TagContainer>
  );
};

TagEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  activeDate: PropTypes.instanceOf(Object).isRequired,
  exercisesSimilar: PropTypes.instanceOf(Object).isRequired,
  fetchSimilarTypeExercises: PropTypes.func.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  isLoadingSimExercises: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  updateTagWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default TagEntries;
