import React from 'react';
import { useDispatch } from 'react-redux';

import Panel from '../presentational/Panel';
import { storePanelHeight } from '../../ducks/panelActions';

const WeightroomPanel = (ownProps) => {
  const dispatch = useDispatch();

  const savePanelHeight = (height, width, namespace) => {
    dispatch(storePanelHeight(height, width, namespace));
  };

  return (
    <Panel
      namespace={ownProps.namespace}
      storePanelHeight={savePanelHeight}
    />
  );
};

export default WeightroomPanel;
