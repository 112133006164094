import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes, { string } from 'prop-types';
import { useTheme } from 'emotion-theming';

import NoHoverDeleteButton from './NoHoverDeleteButton';
import Text from '../../../../../shared/components/Text/Text';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    display: flex;
    align-items: center;
    background-color: #f24540;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 60%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const ModalConfirmDelete = ({
  handleDelete,
  couponId,
}) => {
  const theme = useTheme();
  const couponsError = useSelector((state) => state.reseller.data.couponsError);
  const isCouponDeleting = useSelector((state) => state.reseller.ui.isCouponDeleting);
  const [submitClicked, setSubmitClicked] = useState(false);
  return (
    <ModalWrapper>
      <ModalWrapper>
        <TextWrapper>
          <Text
            fontWeight='600'
            fontSize={theme.textFontSizes[2]}
          >
            {couponId ? `Are you sure you want to delete this coupon? (${couponId})` : null}
          </Text>
          {couponsError && submitClicked ? <TitleError className='error-text'>{couponsError}</TitleError> : null}
        </TextWrapper>
        <NoHoverDeleteButton
          bottom
          fullWidth
          cta='Delete Coupon'
          className='modal-button'
          large
          noBorder
          primary
          square
          isLoading={isCouponDeleting}
          loadingMessage='Deleting...'
          onClick={() => {
            setSubmitClicked(true);
            handleDelete();
          }}
        />
      </ModalWrapper>
    </ModalWrapper>
  );
};

ModalConfirmDelete.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  couponId: PropTypes.instanceOf(string).isRequired,
};

export default ModalConfirmDelete;
