import styled from '@emotion/styled';
import {
  space, color, layout, flexbox,
} from 'styled-system';

const Badge = styled('div')`
  background-color: #444444;
  border-radius: 100px;
  color: #FFFFFF;
  ${color}
  display: flex;
  ${flexbox}
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
  justify-content: center;
  ${layout}
  position: absolute;
  right: -7px;
  ${space}
  top: -7px;
  width: 16px;
`;

export default Badge;
