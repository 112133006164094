/**
 * Component that maps over the collapsible types dictionary and
 * returns a list of collapsible accordion-style Filter components
 */

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Filter from './Filter';

// change overflow-y to scroll to make the scrollbar visible all the time
const Container = styled('div')`
	display: flex;
	flex-direction: column;
	background: white;
	font-family: 'Nunito Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  } 
`;

/**
 *
 * @param {Object} collapsibleTypesDictionary object with keys for broad filter types
 * with lists of specific notification types as values
 * e.g. { feed: [feedPosts, feedPostComments, feedPostFistBumps] }
 * @returns
 */
const AccordionFilters = ({ collapsibleTypesDictionary }) => {
  const containerRef = useRef();
  return (
    <Container ref={containerRef}>
      {Object.keys(collapsibleTypesDictionary).map(
        (headerType, idx) => (
          <Filter
            headerType={headerType}
            subTypes={collapsibleTypesDictionary[headerType]}
            containerRef={containerRef}
            idx={idx}
            // isFirst={idx === 0}
            autoScroll={false}
          />
        ),
      )}
    </Container>
  );
};

AccordionFilters.propTypes = {
  collapsibleTypesDictionary: PropTypes.instanceOf(Object).isRequired,
};

export default AccordionFilters;
