import update from 'immutability-helper';

import {
  CLOSE_BOARD,
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_LEADERBOARD_START,
  FETCH_LEADERBOARD_FULFILLED,
  FETCH_LEADERBOARD_REJECTED,
  FETCH_NEXT_PAGE_FULFILLED,
  EDIT_LEADERBOARD_START,
  EDIT_LEADERBOARD_FULFILLED,
  EDIT_LEADERBOARD_REJECTED,
  SET_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  activeBoardId: 0,
  activeBoards: 0,
  activeModal: '',
  isLoadingExercises: false,
  isLoadingGroups: false,
  isLoadingLeaderboard: false,
  isModalOpen: false,
  leaderboards: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISES_START: {
      return {
        ...state,
        isLoadingExercises: true,
      };
    }
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        isLoadingExercises: false,
      };
    }
    case FETCH_EXERCISES_REJECTED: {
      return {
        ...state,
        isLoadingExercises: false,
      };
    }
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isLoadingGroups: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isLoadingGroups: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isLoadingGroups: false,
      };
    }
    case FETCH_LEADERBOARD_START: {
      return {
        ...state,
        isLoadingLeaderboard: true,
      };
    }
    case FETCH_LEADERBOARD_FULFILLED: {
      return {
        ...state,
        activeBoards: action.payload.length !== 0 ? state.activeBoards + 1 : state.activeBoards,
        isLoadingLeaderboard: false,
      };
    }
    case FETCH_LEADERBOARD_REJECTED: {
      return {
        ...state,
        isLoadingLeaderboard: false,
      };
    }
    case EDIT_LEADERBOARD_START: {
      return {
        ...state,
        isLoadingLeaderboard: true,
      };
    }
    case EDIT_LEADERBOARD_FULFILLED: {
      return {
        ...state,
        isLoadingLeaderboard: false,
      };
    }
    case EDIT_LEADERBOARD_REJECTED: {
      return {
        ...state,
        isLoadingLeaderboard: false,
      };
    }
    case CLOSE_BOARD: {
      return {
        ...state,
        activeBoards: state.activeBoards !== 0 ? state.activeBoards - 1 : state.activeBoards,
      };
    }
    case SET_MODAL_OPEN: {
      return {
        ...state,
        activeBoardId: action.payload.activeBoardId,
        activeModal: action.payload.activeModal,
        isModalOpen: action.payload.isModalOpen,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
