import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import Text from '../../../shared/components/Text/Text';
import Button from '../../../shared/components/Button/Button';

import useBoard from './hooks/useBoard';

const SettingsRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .select__value-container {
    width: 130px;
    padding: 2px 16px 2px 12px;
  }

  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const BoardSettings = ({
  closeModal,
  leaderboardId,
}) => {
  const leaderboardExercise = useSelector(state => state.leaderboard.data.leaderboards[leaderboardId].leaderboardExercise);
  const leaderboardForm = useSelector(state => state.leaderboard.data.leaderboards[leaderboardId].leaderboardForm);
  const leaderboardSettings = useSelector(state => state.leaderboard.data.leaderboards[leaderboardId].settings);

  const {
    handleUpdateSetting,
  } = useBoard(leaderboardId);

  const {
    autoScroll,
    autoLoad,
    loadTime,
  } = leaderboardSettings;

  const closeBoard = () => {
    closeModal();
  };

  const toggleAutoScroll = () => {
    handleUpdateSetting(leaderboardId, 'autoScroll', autoScroll === true ? false : true);
  };

  const toggleAutoLoad = () => {
    handleUpdateSetting(leaderboardId, 'autoLoad', autoLoad === true ? false : true);
  };

  const setLoadTime = (selectedTime) => {
    handleUpdateSetting(leaderboardId, 'loadTime', selectedTime);
  };

  return (
    <Fragment>
      <SettingsRow>
        <TextContainer>
          <Text
            fontWeight={600}
            fontSize={16}
          >
            Scroll Leaderboard Automatically
          </Text>
          <Text
            fontSize={12}
          >
            The leaderboard table will automatically scroll down.
          </Text>
        </TextContainer>
        <Toggle
          defaultChecked={autoScroll}
          icons={false}
          onChange={toggleAutoScroll}
          value={autoScroll}
        />
      </SettingsRow>
      <SettingsRow>
        <TextContainer>
          <Text
            fontWeight={600}
          >
            Reload Leaderboard Automatically
          </Text>
          <Text
            fontSize={12}
          >
            The leaderboard table will reload user data after a specified amount of time.
          </Text>
        </TextContainer>
        <Toggle
          defaultChecked={autoLoad}
          icons={false}
          onChange={toggleAutoLoad}
          value={autoLoad}
        />
      </SettingsRow>
      <SettingsRow>
        <TextContainer>
          <Text
            fontWeight={600}
          >
            Time Before Reload
          </Text>
          <Text
            fontSize={12}
          >
            Specified amount of time before leaderboard data is reloaded.
          </Text>
        </TextContainer>
        <Select
          classNamePrefix='select'
          getOptionLabel={option => option.label}
          getOptionValue={option => option.value}
          onChange={option => (setLoadTime(option))}
          options={[
            { value: 60000, label: 'One Minute' },
            { value: 300000, label: 'Five Minutes' },
            { value: 900000, label: 'Fifteen Minutes' },
            { value: 1800000, label: 'Thirty Minutes' },
          ]}
          value={loadTime}
        />
      </SettingsRow>
      <Button
        cta='Close'
        onClick={closeBoard}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </Fragment>
  );
};

BoardSettings.propTypes = {
  closeModal: PropTypes.func.isRequired,
  leaderboardId: PropTypes.number.isRequired,
};

export default BoardSettings;
