import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import useCalendars from './components/hooks/useCalendars';
import Layout from '../../shared/components/Layout/Layout';
import CalendarSearchForm from './components/presentational/CalendarSearch';

import Spinner from '../../shared/components/Spinner/Spinner';
import CustomTable from './components/presentational/CustomTable';
import ModalHandler from './components/presentational/ModalHandler';

import {
  setSelectedRowsRedux,
  setECDictionaryRedux,
  setSelectedChildRowsRedux,
  resetCreatedTopCalIdRedux,
} from './ducks/calendarsActions';

const Calendars = ({ match }) => {
  const {
    handleFetchCalendars,
    currentUser,
  } = useCalendars();

  const dispatch = useDispatch();

  const unparsedCalendars = useSelector((state) => state.calendars.data.unparsedCalendars);
  const isCalendarsLoading = useSelector((state) => state.calendars.ui.isCalendarsLoading);
  const expandCollapseDictionary = useSelector(
    (state) => state.calendars.data.expandCollapseDictionary,
  );
  const createdTopCalId = useSelector(
    (state) => state.calendars.data.createdTopCalId,
  );

  const [activeSearchString, setActiveSearchString] = useState('');
  const [displayCalendars, setDisplayCalendars] = useState([]);
  const [activeCalendars, setActiveCalendars] = useState([]);
  const [archivedCalendars, setArchivedCalendars] = useState([]);
  const [singleArchive, setSingleArchive] = useState(false);
  const [isArchivedShowing, setIsArchivedShowing] = useState(false);
  const [clearRowToggle, setClearRowToggle] = useState(false);
  const [childrenWithGenParents, setChildrenWithGenParents] = useState([]);
  const [defaultTablePage, setDefaultTablePage] = useState(1);
  const [defaultPageResetter, setDefaultPageResetter] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const setSelectedRows = (rowSelection) => {
    dispatch(setSelectedRowsRedux(rowSelection));
  };

  const setSelectedChildRows = (rowSelection) => {
    dispatch(setSelectedChildRowsRedux(rowSelection));
  };

  const setExpandCollapseDictionary = (activeModalString) => {
    dispatch(setECDictionaryRedux(activeModalString));
  };

  const resetCreatedTopCalId = (dict) => {
    dispatch(resetCreatedTopCalIdRedux(dict));
  };

  const SpinnerWrapper = styled('div')`
    position: absolute;
    display: flex;
    height: 50%;
    justify-content: center;
    width: 100%;
  `;

  // re-fetches calendars whenever currentUser changes
  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined) {
      handleFetchCalendars(accountCode);
    }
  }, [currentUser]);

  useEffect(() => {
    
  }, [unparsedCalendars]);

  /**
   * this is the sorter. it triggers every time unparsedCalendars changes
   * and it serves multiple functions:
   * 1. it receives the array of unparsed calendars from the state
   * 2. it sorts those calendars into two separate hooks:
   *  a. activeCalendars
   *  b. archivedCalendars
   * 3. it also handles parsing of child calendars (i.e., if child calendars
   * are archived but exist under an active calendar, it sorts the child cals into
   * the array of a generic archived calendar)
   * 4. sets the display calendars depending on whether arhivedCalendars are showing
   * 5. clears the selected rows, if any have been selected
   */
  useEffect(() => {
    const currentActiveCalendars = [];
    const currentArchivedCalendars = [];
    const newExpandCollapseDictionary = {};
    let genChildren = [];
    const unparsedCalendarsCopy = unparsedCalendars.map((calendar) => calendar);
    unparsedCalendarsCopy.forEach((calendar) => {
      if (calendar.archived) {
        const newCalendar = calendar;
        const newChildCalendars = calendar.childCalendars;
        newCalendar.newChildCalendars = newChildCalendars;
        currentArchivedCalendars.push(newCalendar);
        if (newChildCalendars.length) {
          if (expandCollapseDictionary[newCalendar.id] === true) {
            newExpandCollapseDictionary[newCalendar.id] = true;
          } else if (expandCollapseDictionary[newCalendar.id] === false) {
            newExpandCollapseDictionary[newCalendar.id] = false;
          } else if (expandCollapseDictionary[newCalendar.id] === undefined) {
            newExpandCollapseDictionary[newCalendar.id] = true;
          }
        }
      } else {
        const newCalendar = calendar;
        const activeChildren = [];
        const archivedChildren = [];
        newCalendar.childCalendars.forEach((childCalendar) => {
          const newChild = childCalendar;
          newChild.parentName = calendar.name;
          if (childCalendar.archived) {
            archivedChildren.push(newChild);
          } else {
            activeChildren.push(newChild);
          }
        });
        newCalendar.newChildCalendars = activeChildren;
        if (calendar.newChildCalendars.length) {
          if (expandCollapseDictionary[newCalendar.id] === true) {
            newExpandCollapseDictionary[newCalendar.id] = true;
          } else if (expandCollapseDictionary[newCalendar.id] === false) {
            newExpandCollapseDictionary[newCalendar.id] = false;
          } else if (expandCollapseDictionary[newCalendar.id] === undefined) {
            newExpandCollapseDictionary[newCalendar.id] = true;
          }
        }
        if (archivedChildren.length) {
          genChildren = genChildren.concat(archivedChildren);
          const genericParentCalendar = {
            newChildCalendars: archivedChildren,
            childCalendars: calendar.childCalendars,
            id: -newCalendar.id,
            name: `Active Parent (${calendar.name})`,
            archived: true,
            generic: true,
          };
          currentArchivedCalendars.push(genericParentCalendar);
          if (expandCollapseDictionary[genericParentCalendar.id] === true) {
            newExpandCollapseDictionary[genericParentCalendar.id] = true;
          } else if (expandCollapseDictionary[genericParentCalendar.id] === false) {
            newExpandCollapseDictionary[genericParentCalendar.id] = false;
          } else if (expandCollapseDictionary[genericParentCalendar.id] === undefined) {
            newExpandCollapseDictionary[genericParentCalendar.id] = true;
          }
        }
        currentActiveCalendars.push(newCalendar);
      }
    });
    if (createdTopCalId) {
      const calendarIndex = currentActiveCalendars.findIndex(
        (activeCalendar) => activeCalendar.id === createdTopCalId,
      );
      const newPage1 = Math.floor(calendarIndex / rowsPerPage);
      const newPage2 = newPage1 + 1;
      setDefaultTablePage(newPage2);
      resetCreatedTopCalId();
    }
    setActiveCalendars(currentActiveCalendars);
    setArchivedCalendars(currentArchivedCalendars);
    setExpandCollapseDictionary(newExpandCollapseDictionary);
    if (isArchivedShowing) {
      setDisplayCalendars(currentArchivedCalendars);
    } else {
      setDisplayCalendars(currentActiveCalendars);
    }
    setClearRowToggle(!clearRowToggle);
    setChildrenWithGenParents(genChildren);
  }, [unparsedCalendars]);

  useEffect(() => {
    if (defaultTablePage) {
      setDefaultPageResetter(!defaultPageResetter);
    }
  }, [defaultTablePage]);

  /**
   * this is the display function -
   * displays the calendar search form, which actually includes the header, the search form,
   * the active/archived calendars toggle, and the expand collapse all calendars toggle.
   * as such, it's aware of most, if not all of the hooks.
   * includes a spinner and wrapper for loading times
   * displays the table with all current data,
   * the modal handler, which displays the active modal if one exists,
   * create calendar button, delete calendars button, and archive calendars button.
   */

  return (
    <Layout>
      <Helmet>
        <title>Manage Calendars | TeamBuildr</title>
      </Helmet>
      <CalendarSearchForm
        setIsArchivedShowing={setIsArchivedShowing}
        setSelectedRows={setSelectedRows}
        setSelectedChildRows={setSelectedChildRows}
        setClearRowToggle={setClearRowToggle}
        setActiveSearchString={setActiveSearchString}
        setDisplayCalendars={setDisplayCalendars}
        setExpandCollapseDictionary={setExpandCollapseDictionary}
        activeSearchString={activeSearchString}
        activeCalendars={activeCalendars}
        archivedCalendars={archivedCalendars}
        isArchivedShowing={isArchivedShowing}
        clearRowToggle={clearRowToggle}
        unparsedCalendars={unparsedCalendars}
      />
      <Route path={match.path}>
        {isCalendarsLoading ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : (
          <>
            <CustomTable
              setSelectedRows={setSelectedRows}
              setSingleArchive={setSingleArchive}
              displayCalendars={displayCalendars}
              clearRowToggle={clearRowToggle}
              isArchivedShowing={isArchivedShowing}
              activeSearchString={activeSearchString}
              childrenWithGenParents={childrenWithGenParents}
              defaultTablePage={defaultTablePage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              defaultPageResetter={defaultPageResetter}
              setDefaultTablePage={setDefaultTablePage}
            />
          </>
        )}
      </Route>
      <ModalHandler
        setSelectedRows={setSelectedRows}
        setSelectedChildRows={setSelectedChildRows}
        singleArchive={singleArchive}
        activeCalendars={activeCalendars}
        isArchivedShowing={isArchivedShowing}
        childrenWithGenParents={childrenWithGenParents}
        activeSearchString={activeSearchString}
      />
    </Layout>
  );
};

Calendars.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default Calendars;
