export const SET_INSTANT_PAYMENT_MODAL = 'SET_INSTANT_PAYMENT_MODAL';

export const SET_UPGRADE_MODAL = 'SET_UPGRADE_MODAL';
export const SET_MARKETING_MODAL = 'SET_MARKETING_MODAL';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_ERROR_MODAL_OPEN = 'SET_ERROR_MODAL_OPEN';

export const SET_SIDE_BAR_OPEN = 'SET_SIDE_BAR_OPEN';

export const SET_BOOK_DEMO_MODAL = 'SET_BOOK_DEMO_MODAL';

export const SET_IS_SIDE_BAR_EXPANDED = 'SET_IS_SIDE_BAR_EXPANDED';

export const FETCH_SUBSCRIPTION_STATUS_START = 'FETCH_SUBSCRIPTION_STATUS_START';
export const FETCH_SUBSCRIPTION_STATUS_FULFILLED = 'FETCH_SUBSCRIPTION_STATUS_FULFILLED';
export const FETCH_SUBSCRIPTION_STATUS_REJECTED = 'FETCH_SUBSCRIPTION_STATUS_REJECTED';

export const FETCH_SUBSCRIPTION_PACKAGE_START = 'FETCH_SUBSCRIPTION_PACKAGE_START';
export const FETCH_SUBSCRIPTION_PACKAGE_FULFILLED = 'FETCH_SUBSCRIPTION_PACKAGE_FULFILLED';
export const FETCH_SUBSCRIPTION_PACKAGE_REJECTED = 'FETCH_SUBSCRIPTION_PACKAGE_REJECTED';

export const ADD_ON_SPORTS_SCIENCE_PACKAGE_START = 'ADD_ON_SPORTS_SCIENCE_PACKAGE_START';
export const ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED = 'ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED';
export const ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED = 'ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED';

export const SET_SELECTED_PACKAGE = 'SET_SELECTED_PACKAGE';
