/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  fetchWorkouts,
  fetchNextWorkoutSession,
  fetchProgramWeek,
  fetchProgramWeeks,
  fetchProgramWeekViaNextSession,
  fetchProgramWorkouts,
  setIsProgramListModalShowing,
  setIsResetProgramModalShowing,
  setIsProgramInfoModalShowing,
  setIsCompleteProgramModalShowing,
  setIsCalendarProgramView,
} from '../../ducks/panelActions';
import ProgramListBoxModal from '../../../../shared/components/ProgramListBoxModal/ProgramListBoxModal';

const ProgramListBoxModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const WeightroomProgramListModal = ({ namespace }) => {
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const currentUser = useSelector((state) => state.weightRoomView.data.currentUser);
  const isProgramListItemsLoading = useSelector(
    (state) => state.weightRoomView[namespace].isProgramListItemsLoading,
  );
  const isProgramListModalShowing = useSelector(
    (state) => state.weightRoomView[namespace].isProgramListModalShowing,
  );
  const nextWorkoutSession = useSelector((
    state,
  ) => state.weightRoomView[namespace].nextWorkoutSession);
  const programs = useSelector((state) => state.weightRoomView[namespace].programs);
  const programWeeks = useSelector((state) => state.weightRoomView[namespace].programWeeks);
  const programWeek = useSelector((state) => state.weightRoomView[namespace].programWeek);
  const selectedProgramWeek = useSelector((
    state,
  ) => state.weightRoomView[namespace].selectedProgramWeek);

  const isProgramView = Object.keys(currentSelectedProgram).length;

  const dispatch = useDispatch();

  const setCurrentDate = () => {
    batch(() => {
      dispatch(setIsProgramListModalShowing(namespace, false));
      dispatch(fetchWorkouts(accountCode, athleteId, namespace, activeDate));
      dispatch(setIsCalendarProgramView(namespace, 'calendar'));
    });
  };

  const programCardClickFunction = (program, index) => {
    dispatch(fetchProgramWeeks(accountCode, athleteId, namespace, program, index));
    setTimeout(() => {
      dispatch(
        fetchNextWorkoutSession(accountCode, athleteId, namespace, program.dayBasedProgramId),
      );
    }, 500);
  };

  const weekCardClickFunction = (prgramWeek, idx) => {
    dispatch(fetchProgramWeek(
      accountCode,
      athleteId,
      namespace,
      currentSelectedProgram,
      idx,
      prgramWeek,
    ));
  };

  const dayCardClickFunction = (prgramWeekDay, idx) => {
    batch(() => {
      dispatch(fetchProgramWorkouts(
        accountCode,
        athleteId,
        namespace,
        currentSelectedProgram,
        prgramWeekDay.trueDayNum,
        prgramWeekDay,
      ));
      dispatch(setIsProgramListModalShowing(namespace, false));
      dispatch(setIsCalendarProgramView(namespace, 'program'));
    });
  };

  const nextWorkoutClick = (nextSession) => {
    batch(() => {
      dispatch(fetchProgramWorkouts(
        accountCode,
        athleteId,
        namespace,
        currentSelectedProgram,
        nextWorkoutSession.trueDayNum,
        nextSession,
      ));
      dispatch(fetchProgramWeekViaNextSession(
        accountCode,
        athleteId,
        namespace,
        currentSelectedProgram,
        nextSession.trueWeekNum,
      ));
      dispatch(setIsProgramListModalShowing(namespace, false));
      dispatch(setIsCalendarProgramView(namespace, 'program'));
    });
  };

  const resetProgram = () => {
    dispatch(setIsResetProgramModalShowing(namespace, true));
  };

  const showInfoModal = () => {
    dispatch(setIsProgramInfoModalShowing(namespace, true));
  };

  const completeProgramModal = () => {
    dispatch(setIsCompleteProgramModalShowing(namespace, true));
  };

  const onLoadFunc = () => {
    if (Object.keys(currentSelectedProgram).length && isProgramListModalShowing) {
      batch(() => {
        dispatch(fetchProgramWeek(
          accountCode,
          athleteId,
          namespace,
          currentSelectedProgram,
          selectedProgramWeek.zeroBasedWeekNum,
          selectedProgramWeek,
        ));
        dispatch(fetchProgramWeeks(
          accountCode,
          athleteId,
          namespace,
          currentSelectedProgram,
          currentSelectedProgram.dayBasedProgramId,
        ));
      });
    }
  };

  return (
    <>
      <ProgramListBoxModal
        isModalActive={isProgramListModalShowing}
        currentUser={currentUser}
        viewScheduledWorkoutsClickFunction={setCurrentDate}
        sharedAthlete={athleteId}
        programCardClickFunction={programCardClickFunction}
        programWeekCardClickFunction={weekCardClickFunction}
        programWeekDayCardClickFunction={dayCardClickFunction}
        currentSelectedProgram={currentSelectedProgram}
        nextSession={nextWorkoutSession}
        workoutProgramsList={programs}
        workoutProgramWeeksList={programWeeks}
        workoutProgramDaysList={programWeek}
        nextWorkoutDayClickFunction={nextWorkoutClick}
        showNextSessionOption
        resetProgramButtonClickFunction={resetProgram}
        infoButtonClickFunction={showInfoModal}
        completeButtonClickFunction={completeProgramModal}
        isItemsLoading={isProgramListItemsLoading}
        showInfoButton={currentSelectedProgram.description}
        isAllowedReset={currentUser.admin || currentUser.allowDayBasedProgramRestarts}
        isNonDateProgramsActive={isProgramView}
        onLoadFunction={onLoadFunc}
      />
      <ProgramListBoxModalContainerOverlay
        isActive={isProgramListModalShowing}
        onClick={
          () => dispatch(setIsProgramListModalShowing(namespace, false))
        }
      />
    </>
  );
};

WeightroomProgramListModal.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default WeightroomProgramListModal;
