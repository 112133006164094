import React, { useState } from 'react';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import Text from '../../../../../shared/components/Text/Text';
// import BlueButton from '../BlueButton';

const ManageCookies = () => {
  const [containerWidth, setContainerWidth] = useState(null);

  const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
 `;

  const HeaderContainer = styled('div')`
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: #E0E0E0 1px solid;
 `;

  return (
    <Wrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 400 ? 24 : 28} fontWeight='700'>
          Manage Cookies
        </Text>
        <Text fontSize={18} fontWeight='400'>TeamBuildr collects information using cookies, manage them here.</Text>
      </HeaderContainer>
      <button
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Nunito Sans',
          fontSize: '14px',
          fontWeight: '700',
          letterSpacing: '1.5px',
          height: '64px',
          width: containerWidth && containerWidth < 450 ? '100%' : '50%',
          background: '#77A7FF',
          border: '0px',
          borderRadius: '12px',
          color: 'white',
          padding: '16px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        type='submit'
        id='ot-sdk-btn'
        className='ot-sdk-show-settings'
      >
        {' '}
        Cookie Settings
      </button>
    </Wrapper>
  );
};

export default ManageCookies;
