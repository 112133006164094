/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import ResizeObserver from 'react-resize-observer';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Dropdown from '../../../../shared/_TBComponents/Dropdown';
import Text from '../../../../shared/components/Text/Text';
import { setAssignmentConflictAction } from '../../ducks/habitTrackingActions';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #FFFFFF;
  max-width: 452px;
`;

const Button = styled('button')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor && props.backgroundColor};
  padding: 8px 16px 8px 16px;
  height: 51px;
  border-radius: 4px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 40px 20px 40px 20px;
  text-align: center;
  gap: 20px;
  
`;

const IconCircle = styled('div')`
  display: flex;
  height: 52px;
  width: 52px;
  min-width: 52px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #444444;
  padding-bottom: 5px;
`;

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.containerWidth < 400 ? '20px' : '20px 40px 36px 40px')};
  gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AssignmentConflictModalContent = ({ closeModal }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [openUserDropdown, setOpenUserDropdown] = useState(null);
  const dispatch = useDispatch();
  const errorModalDataObject = useSelector((state) => state.sportsScience.ui.errorModalDataObject);

  const handleDropdownToggleForUserDropdown = () => {
    setOpenUserDropdown(!openUserDropdown);
  };

  const userDropdownOptions = errorModalDataObject?.usersWithAssignmentConflict.map((user) => (
    {
      text: `${user.first} ${user.last}`,
      avatar: user.pic,
      icon: 'user',
    }
  ));

  const CustomDropdownTrigger = () => {
    const TextContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

  
    .underlined-text {
      border-bottom: 1px solid #444444;
    }
    
    &:hover ${Text} {
      color: lightgray;
    }

    &:hover .underlined-text {
      border-bottom: 1px solid lightgray;
    }
    `;

    return (
      <TextContainer>
        {errorModalDataObject?.usersWithAssignmentConflict.length
          ? errorModalDataObject?.usersWithAssignmentConflict.map((user, index) => {
            const displayLimit = errorModalDataObject?.usersWithAssignmentConflict?.length >= 4 ? 3 : errorModalDataObject?.usersWithAssignmentConflict?.length - 1;
            const isSecondToLast = index === displayLimit - 1;
            const isLast = index === displayLimit;

            if (index <= displayLimit) {
              return (
                <Text style={{ marginRight: '5px' }} key={user.id} fontSize={containerWidth < 400 ? '16px' : '19px'} fontWeight={700}>
                  {user.first.toUpperCase()}
                  {isSecondToLast ? ' and ' : ''}
                  {!isLast && !isSecondToLast ? ', ' : ''}
                </Text>
              );
            }
            return null;
          })
          : null}
        {errorModalDataObject?.usersWithAssignmentConflict?.length > 4 ? (
          <Text className='underlined-text' style={{ marginLeft: '5px' }} fontWeight={700} fontSize={containerWidth < 400 ? '16px' : '19px'}>{`+${errorModalDataObject?.usersWithAssignmentConflict?.length - 4} more athlete(s)`}</Text>
        ) : null}
      </TextContainer>
    );
  };

  return (
    <Wrapper>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <Header>
        <IconCircle>
          <IcomoonReact
            iconSet={iconSet}
            size={28}
            icon='warning'
          />
        </IconCircle>
        <Text fontWeight={700} fontSize='32px'>Assignment Conflict</Text>
      </Header>
      <Body containerWidth={containerWidth}>
        <Dropdown
          options={userDropdownOptions}
          isOpen={openUserDropdown}
          setIsOpen={() => handleDropdownToggleForUserDropdown()}
          menuAbsolutePosition={-25}
          menuMaxWidth='280px'
          menuMaxHeight='260px'
          menuPaddingTop='15px'
          menuPaddingBottom='15px'
          CustomTriggerComponent={CustomDropdownTrigger}
          menuBorderRadius='8px'
          menuBorder='1px solid lightgrey'
        />
        <Text fontSize={containerWidth < 400 ? '16px' : '19px'} fontWeight={700}>
          {`${errorModalDataObject?.usersWithAssignmentConflict?.length === 1 ? 'has' : 'have'} the maximum of 5 habits assigned. 
            Remove conflicted athlete(s) from older habits in order to have the habit "${errorModalDataObject?.habitName}"
            show up for the listed athlete(s).`}
        </Text>
        <Text style={{ marginTop: '10px', marginBottom: '10px' }} fontSize={containerWidth < 400 ? '16px' : '19px'} fontWeight={700}>
          GO BACK to
          {` "${errorModalDataObject?.habitName}"`}
          {' '}
          to edit.
        </Text>
        <Text style={{ marginBottom: '10px' }} fontSize={containerWidth < 400 ? '16px' : '19px'} fontWeight={700}>CONTINUE ANYWAY and the listed athlete(s) will not be assigned this habit when you save.</Text>
        <Button onClick={() => closeModal()} backgroundColor='#444444'>
          <Text color='white' fontSize='23px' fontWeight={700}>Go Back</Text>
        </Button>
        <Button
          onClick={() => {
            dispatch(setAssignmentConflictAction('continue-anyway'));
          }}
          backgroundColor='#FFCB47'
        >
          <Text fontSize='23px' fontWeight={700}>Continue anyway</Text>
        </Button>
      </Body>
    </Wrapper>

  );
};

export default AssignmentConflictModalContent;

AssignmentConflictModalContent.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
