import { connect } from 'react-redux';
import PanelHeader from '../presentational/PanelHeader';
import { closePanel, closeWorkout } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeAthlete: state[ownProps.namespace].activeAthlete,
  // activeWorkoutIndex: state[ownProps.namespace].activeWorkoutIndex,
  athleteAvatar: state[ownProps.namespace].athleteAvatar,
  isLoadinge: state[ownProps.namespace].isLoading,
  // isWorkoutComplete: state[ownProps.namespace].isWorkoutComplete,
  // isWorkoutSaving: state[ownProps.namespace].isWorkoutSaving,
  // skin: state.weightRoom.currentUser.skin,
  // workoutCount: state[ownProps.namespace].workoutCount,
});

const mapDispatchToProps = dispatch => ({
  closePanel: (namespace) => {
    dispatch(closePanel(namespace));
  },
  closeWorkout: (namespace) => {
    dispatch(closeWorkout(namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelHeader);
