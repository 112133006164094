// imports action types and dispatches action to the back end for processessing and db

import {
  FETCH_CALENDARS_START,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  CREATE_CALENDAR_START,
  CREATE_CALENDAR_FULFILLED,
  CREATE_CALENDAR_REJECTED,
  FETCH_SEARCH_CALENDARS_START,
  FETCH_SEARCH_CALENDARS_FULFILLED,
  FETCH_SEARCH_CALENDARS_REJECTED,
  DELETE_CALENDARS_START,
  DELETE_CALENDARS_FULFILLED,
  DELETE_CALENDARS_REJECTED,
  UPDATE_CALENDAR_START,
  UPDATE_CALENDAR_FULFILLED,
  UPDATE_CALENDAR_REJECTED,
  UPDATE_CHILD_CALENDAR_START,
  UPDATE_CHILD_CALENDAR_FULFILLED,
  UPDATE_CHILD_CALENDAR_REJECTED,
  CREATE_CHILD_CALENDAR_START,
  CREATE_CHILD_CALENDAR_FULFILLED,
  CREATE_CHILD_CALENDAR_REJECTED,
  ARCHIVE_CALENDARS_START,
  ARCHIVE_CALENDARS_FULFILLED,
  ARCHIVE_CALENDARS_REJECTED,
  UNARCHIVE_CALENDARS_START,
  UNARCHIVE_CALENDARS_FULFILLED,
  UNARCHIVE_CALENDARS_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  SET_SELECTED_ROWS_REDUX,
  SET_CURRENT_ROW_REDUX,
  SET_EC_DICTIONARY_REDUX,
  SET_SELECTED_CHILD_ROWS_REDUX,
  SET_SINGLE_ACTION_REDUX,
  SET_SELECTED_CHILDREN_REDUX,
  RESET_REDUX_REDUX,
  RESET_CREATED_TOP_CAL_ID_REDUX,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const fetchCalendars = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CALENDARS_START });
    axiosAuthed.get(`/accounts/${accountCode}/calendars`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: FETCH_CALENDARS_FULFILLED,
            payload: response.data,
          });
        }, 1000);
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const createCalendar = (values) => (
  (dispatch) => {
    dispatch({ type: CREATE_CALENDAR_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/calendars`, values.body)
      .then((response) => {
        dispatch({
          type: CREATE_CALENDAR_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_CALENDAR_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchCalendarsWithSearch = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_SEARCH_CALENDARS_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/calendars/?search=${values.values.search}`)
      .then((response) => {
        dispatch({
          type: FETCH_SEARCH_CALENDARS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SEARCH_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const deleteCalendars = (accountCode, calIdsArray) => (
  (dispatch) => {
    dispatch({ type: DELETE_CALENDARS_START });
    axiosAuthed.patch(`/accounts/${accountCode}/calendars/delete`, { calendarIds: calIdsArray })
      .then((response) => {
        dispatch({
          type: DELETE_CALENDARS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateCalendar = (accountCode, calendarId, body, calendarIndex, oldCalendarIndex) => (
  (dispatch) => {
    dispatch({ type: UPDATE_CALENDAR_START });
    axiosAuthed.put(`/accounts/${accountCode}/calendars/${calendarId}`, body)
      .then((response) => {
        dispatch({
          type: UPDATE_CALENDAR_FULFILLED,
          payload: { responseData: response.data, calendarIndex, oldCalendarIndex },
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_CALENDAR_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateChildCalendar = (
  accountCode,
  calendarId,
  body,
  parentId,
  parentIndex,
  childIndex,
) => (
  (dispatch) => {
    dispatch({ type: UPDATE_CHILD_CALENDAR_START });
    axiosAuthed.put(`/accounts/${accountCode}/calendars/${parentId}/children/${calendarId}`, body)
      .then((response) => {
        dispatch({
          type: UPDATE_CHILD_CALENDAR_FULFILLED,
          payload: { responseData: response.data, parentIndex, childIndex },
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_CHILD_CALENDAR_REJECTED,
          payload: err,
        });
      });
  }
);

export const createChildCalendar = (values) => (
  (dispatch) => {
    dispatch({ type: CREATE_CHILD_CALENDAR_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/calendars/${values.parentId}/children`, values.body)
      .then((response) => {
        dispatch({
          type: CREATE_CHILD_CALENDAR_FULFILLED,
          payload: { responseData: response.data, parentCalendarIndex: values.parentCalendarIndex },
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_CHILD_CALENDAR_REJECTED,
          payload: err,
        });
      });
  }
);

export const archiveCalendars = (accountCode, calIdsArray) => (
  (dispatch) => {
    dispatch({ type: ARCHIVE_CALENDARS_START });
    axiosAuthed.patch(`/accounts/${accountCode}/calendars/archive`, { calendarIds: calIdsArray, unarchive: false })
      .then((response) => {
        dispatch({
          type: ARCHIVE_CALENDARS_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: ARCHIVE_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const unarchiveCalendars = (accountCode, calIdsArray) => (
  (dispatch) => {
    dispatch({ type: UNARCHIVE_CALENDARS_START });
    axiosAuthed.patch(`/accounts/${accountCode}/calendars/archive`, { calendarIds: calIdsArray, unarchive: true })
      .then((response) => {
        dispatch({
          type: UNARCHIVE_CALENDARS_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: UNARCHIVE_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveReduxModal = (activeModal) => (
  {
    type: SET_ACTIVE_REDUX_MODAL,
    payload: activeModal,
  }
);

export const setSelectedRowsRedux = (rowSelection) => (
  {
    type: SET_SELECTED_ROWS_REDUX,
    payload: rowSelection,
  }
);

export const setCurrentRowRedux = (rowSelection) => (
  {
    type: SET_CURRENT_ROW_REDUX,
    payload: rowSelection,
  }
);

export const setECDictionaryRedux = (rowSelection) => (
  {
    type: SET_EC_DICTIONARY_REDUX,
    payload: rowSelection,
  }
);

export const setSelectedChildRowsRedux = (rowSelection) => (
  {
    type: SET_SELECTED_CHILD_ROWS_REDUX,
    payload: rowSelection,
  }
);

export const setSingleActionRedux = (bool) => (
  {
    type: SET_SINGLE_ACTION_REDUX,
    payload: bool,
  }
);

export const setSelectedChildrenRedux = (children) => (
  {
    type: SET_SELECTED_CHILDREN_REDUX,
    payload: children,
  }
);

export const resetReduxRedux = (children) => (
  {
    type: RESET_REDUX_REDUX,
    payload: children,
  }
);

export const resetCreatedTopCalIdRedux = (children) => (
  {
    type: RESET_CREATED_TOP_CAL_ID_REDUX,
    payload: children,
  }
);
