/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const ErrorMessageWarning = ({ errorMessage, apiErrorMessage }) => {
  const theme = useTheme();

  const PageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    flex: 1;
    justify-content: center;
  `;
  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    max-width: 500px;
    border: 1px solid #F2F2F2;
    flex-wrap: wrap;
    box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.10);
    border-radius: 10px;
  `;
  const IconAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 25px;
    text-align: center;
  `;

  return (
    <PageWrapper>
      <HeaderContainer>
        <IconAndTextContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={36}
            icon='warning'
            color={theme.colors.orange}
          />
          <Text fontSize='20px' fontWeight={700}>
            {errorMessage}
          </Text>
          {apiErrorMessage && apiErrorMessage !== '' && (
            <Text fontSize='16px'>
              {`Error: ${apiErrorMessage}`}
            </Text>
          )}
        </IconAndTextContainer>
      </HeaderContainer>
    </PageWrapper>
  );
};
ErrorMessageWarning.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  apiErrorMessage: PropTypes.string,
};

ErrorMessageWarning.defaultProps = {
  apiErrorMessage: '',
};
export default ErrorMessageWarning;
