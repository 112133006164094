/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import iconSet from '../../images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1004;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 225px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 1000%)')};
  opacity: ${(props) => (props.isActive ? 1 : 0)};
  height: auto;
  max-height: 70vh;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  padding: 20px;
`;

/**
 *
 * @param {func} completeProgramWorkoutClick function that gets called when the complete
 * button is clicked to complete the current workout
 * @param {func} completeProgramWorkoutModalShowing function that gets called when X icon is clicked
 * to close the complete workout modal
 * @param {bool} isModalActive simple boolean to
 * determine whether the modal should slide up or slide down
 *
 */

const CompleteProgramWorkoutModal = ({
  completeProgramWorkoutClick,
  completeProgramWorkoutModalShowing,
  isModalActive,
}) => (
  <ModalContainer
    isActive={isModalActive}
  >
    <div className='remove-div'>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{
          marginLeft: 10,
        }}
        >
          <ModalTextTop>Complete This Workout?</ModalTextTop>
        </div>
      </div>
      <div className='remove-div-icon' onClick={completeProgramWorkoutModalShowing}>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='remove'
          color='black'
        />
      </div>
    </div>
    <ContentContainer>
      This day will be marked as completed and
      you will still be able to access the workout.
    </ContentContainer>
    <Button
      bottom
      fullWidth
      cta='COMPLETE'
      className='print-button'
      large
      square
      noBorder
      primary
      onClick={completeProgramWorkoutClick}
    />
  </ModalContainer>
);

CompleteProgramWorkoutModal.propTypes = {
  completeProgramWorkoutClick: PropTypes.func.isRequired,
  completeProgramWorkoutModalShowing: PropTypes.func.isRequired,
  isModalActive: PropTypes.bool.isRequired,
};

export default CompleteProgramWorkoutModal;
