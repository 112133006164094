import update from 'immutability-helper';

import {
  FETCH_MULTIPLE_WORKOUTS_FULFILLED,
  FETCH_MULTIPLE_WORKOUTS_REJECTED,
  FETCH_WORKOUTS_FULFILLED,
  GET_EXERTION_SCORE_FULFILLED,
  GET_EXERTION_SCORE_REJECTED,
  GET_EXERTION_SCORE_START,
  SET_ACTIVE_WORKOUT_DATE,
  GET_USERS_FULLFILLED,
  SET_SHARED_ATHLETE,
  SET_IS_WORKOUTS_CAROUSEL_SHOWING,
  FETCH_WORKOUT_CALENDAR_FULFILLED,
  FETCH_WORKOUT_CALENDAR_REJECTED,
  FETCH_WORKOUT_SESSION_FULFILLED,
  FETCH_WORKOUT_SESSION_REJECTED,
  FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED,
  FETCH_SIMILAR_EXERCISES_FULFILLED,
  SET_OPT_OUT_EXERCISE_TYPE_FILTER,
  SUBMIT_OPT_OUT_FULFILLED,
  SUBMIT_WORKOUT_FULFILLED,
  SET_OPT_OUT_WORKOUT,
  SET_TIMER_MODAL_OPEN,
  SET_TIMER_DURATION,
  SET_QUESTIONNAIRE_TOTAL,
  SET_SELECTED_TAG_EXERCISE,
  SET_CAPTURE_IMAGE_MODAL,
  SET_IS_WORKOUTS_MEDIA_UPLOADED,
  UPLOAD_WORKOUTS_MEDIA_START,
  UPLOAD_WORKOUTS_MEDIA_REJECTED,
  UPLOAD_WORKOUTS_MEDIA_FULFILLED,
  SET_CAPTURE_VIDEO_MODAL,
  SET_HISTORY_WORKOUT,
  FETCH_WORKOUT_HISTORY_FULFILLED,
  SET_JOURNAL_ENTRY_WORKOUT,
  SET_UPLOAD_WORKOUT_MEDIA_FALSE,
  UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED,
  SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY,
  SET_ACTIVE_WORKOUTS_MEDIA,
  GET_WORKOUTS_EXERTION_SCORE_START,
  GET_WORKOUTS_EXERTION_SCORE_FULFILLED,
  GET_WORKOUTS_EXERTION_SCORE_REJECTED,
  SET_EXERTION_SCORE_MODAL_SHOWING,
  SET_IS_PRINTING_OPTIONS_SHOWING,
  CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL,
  SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL,
  UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED,
  SET_QUICK_JOURNAL_MODAL,
  SET_QUICK_WORKOUTS_JOURNAL,
  WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
  FETCH_WORKOUT_CALENDAR_FULFILLED_ALT,
  FETCH_WORKOUT_CALENDAR_REJECTED_ALT,
  SET_IS_DAY_PICKER_OPEN,
  SET_ADDITIONAL_INFO_WORKOUT,
  SET_ADDITIONAL_WORKOUTS_DATA,
  SET_IS_ADDITIONAL_WORKOUTS,
  SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN,
  UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED,
  WIPE_MEDIA_ERROR,
  SET_TAG_WORKOUT,
  GET_UPDATED_ACTIVE_DATE_FULFILLED,
  SET_MULTI_QUESTION_MODAL,
  SET_MULTI_QUESTION_MODAL_RESPONSE,
  SUBMIT_SESSION_WORKOUT_FULFILLED,
  SUBMIT_OPT_OUT_TOP_FULFILLED,
  WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED,
  CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED,
  FETCH_TAG_EXERCISES_FULFILLED,
  FETCH_WORKOUT_FILE_FULFILLED,
  FETCH_WORKOUT_FILE_REJECTED,
  FETCH_WORKOUT_DOCUMENT_FULFILLED,
  FETCH_WORKOUT_DOCUMENT_REJECTED,
  FETCH_WORKOUT_PROGRAMS_FULFILLED,
  FETCH_WORKOUT_PROGRAMS_REJECTED,
  FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED,
  SET_PROGRAM_DAY_INDEX,
  SET_WEEKDAY_INDEX,
  FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED,
  FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED,
  RESET_WORKOUT_PROGRAM_FULFILLED,
  COMPLETE_WORKOUT_PROGRAM_FULFILLED,
  FETCH_NEXT_WORKOUT_SESSION_FULFILLED,
  WIPE_OUT_NON_DATE,
  FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED,
  COMPLETE_WORKOUT_FULFILLED,
  UPDATE_CURRENT_NON_DATE_DAY,
  REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED,
  SUBMIT_PROGRAM_WORKOUT_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED,
  FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START,
  SET_SELECTED_INTEGRATION,
  FETCH_SELECTED_INTEGRATION_BY_DATE_START,
  FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED,
  FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED,
  SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED,
  FETCH_MORE_WORKOUT_HISTORY_FULFILLED,
  FETCH_WORKOUT_HISTORY_START,
} from './actionTypes';

const initialState = {
  carouselWorkouts: {},
  currentWorkout: { workoutItems: [] },
  activeWorkoutDate: '',
  sharedAthlete: {},
  athletes: [],
  workoutCalendar: [],
  activeDayInfo: {},
  workoutSessionData: {},
  optOutAllExercises: [],
  similarExercisesForOptOut: [],
  optOutExerciseTypeFilter: 'similar',
  activeWorkoutsMedia: {},
  optOutWorkout: {},
  timerDuration: 0,
  isTimerModalOpen: false,
  questionnaireTotal: {},
  exercisesForTag: [],
  historyWorkout: {},
  historyData: [],
  journalEntryWorkout: {},
  uploadedWorkoutsMedia: {},
  uploadedWorkoutJournalMedia: [],
  uploadedWorkoutJournalMediaExtended: [],
  removeWorkoutJournalMediaArray: [],
  uploadedWorkoutJournalMediaObject: {},
  journalEntriesData: [],
  quickImageWorkoutsJournalModal: false,
  quickVideoWorkoutsJournalModal: false,
  quickJournalMedia: {},
  quickJournalModal: false,
  isWorkoutsCarouselShowing: false,
  workoutCalendarAlt: [],
  isDayPickerOpen: false,
  additionalInfoWorkout: {},
  exertionScore: {},
  additionalWorkoutsData: {},
  isAdditionalWorkouts: false,
  isSmallScreenDayPickerOpen: false,
  quickJournalMediaError: '',
  isPrintingOptionsShowing: false,
  tagWorkout: {},
  multiQuestionModalResponse: {},
  multiQuestionModalQuestion: {},
  questionWorkoutId: null,
  selectedTagExerciseObject: {},
  workoutPrograms: [],
  workoutProgramWeeks: [],
  workoutProgramWeek: [],
  workoutProgramWeekIndex: 0,
  currentSelectedProgram: {},
  programDayIndex: 1,
  weekDayIndex: 0,
  nextWorkoutSession: {},
  currentNonDateDay: {},
  importedDataIntegrations: null,
  selectedIntegration: null,
  importedDataIntegrationsError: null,
  selectedIntegrationData: null,
  historyPage: 1,
  isEndOfWorkoutHistoryList: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKOUTS_FULFILLED: {
      return {
        ...state,
        currentWorkout: action.payload.data,
        activeDayInfo: { title: action.payload.data.title },
        isAdditionalWorkouts: action.payload.isAdditionalWorkouts,
      };
    }
    case FETCH_MULTIPLE_WORKOUTS_FULFILLED: {
      return update(state, {
        carouselWorkouts: {
          $merge: {
            [action.payload.date]: {
              ...action.payload.data,
            },
          },
        },
      });
    }
    case FETCH_MULTIPLE_WORKOUTS_REJECTED: {
      return update(state, {
        carouselWorkouts: {
          $merge: {
            [action.payload.date]: {
              ...action.payload.data,
            },
          },
        },
      });
    }
    case FETCH_WORKOUT_PROGRAMS_FULFILLED: {
      return {
        ...state,
        workoutPrograms: action.payload.data,
      };
    }
    case SET_WEEKDAY_INDEX: {
      return {
        ...state,
        weekDayIndex: action.payload,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEKS_FULFILLED: {
      return {
        ...state,
        workoutProgramWeeks: action.payload.data,
        currentSelectedProgram: action.payload.program,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WEEK_FULFILLED: {
      return {
        ...state,
        workoutProgramWeek: action.payload.data,
        workoutProgramWeekIndex: action.payload.workoutProgramWeekIndex,
      };
    }
    case FETCH_NEXT_WORKOUT_PROGRAM_WEEK_FULFILLED: {
      return {
        ...state,
        workoutProgramWeek: action.payload.data,

        workoutProgramWeekIndex: action.payload.workoutProgramWeekIndex,
        weekDayIndex: 0,
      };
    }
    case FETCH_PREVIOUS_WORKOUT_PROGRAM_WEEK_FULFILLED: {
      return {
        ...state,
        workoutProgramWeek: action.payload.data,

        workoutProgramWeekIndex: action.payload.workoutProgramWeekIndex,
        weekDayIndex: action.payload.data.length - 1,
      };
    }
    case FETCH_WORKOUT_PROGRAMS_REJECTED: {
      return {
        ...state,
        workoutProgramsError: action.payload.data,
      };
    }
    case GET_UPDATED_ACTIVE_DATE_FULFILLED: {
      return update(state, {
        workoutCalendar: {
          15: {
            $set: {
              ...action.payload.data[0],
            },
          },
        },
      });
    }
    case SET_ACTIVE_WORKOUT_DATE: {
      return {
        ...state,
        activeWorkoutDate: action.payload,
      };
    }
    case SET_ADDITIONAL_INFO_WORKOUT: {
      return {
        ...state,
        additionalInfoWorkout: action.payload,
      };
    }
    case SET_TAG_WORKOUT: {
      return {
        ...state,
        tagWorkout: action.payload,
      };
    }
    case GET_EXERTION_SCORE_START: {
      return {
        ...state,
        errorMessage: '',
      };
    }
    case GET_EXERTION_SCORE_FULFILLED: {
      return {
        ...state,
        exertionScore: {
          alertText: action.payload.data.alertText,
          alertType: action.payload.data.alertType,
          date: action.payload.scoreDate,
          externalUrl: action.payload.data.externalUrl,
          score: action.payload.data.score,
        },
      };
    }
    case GET_EXERTION_SCORE_REJECTED: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case GET_USERS_FULLFILLED: {
      const athletes = action.payload;
      return {
        ...state,
        athletes,
      };
    }
    case SET_SHARED_ATHLETE: {
      return {
        ...state,
        sharedAthlete: action.payload,
      };
    }
    case SET_IS_WORKOUTS_CAROUSEL_SHOWING: {
      return {
        ...state,
        isWorkoutsCarouselShowing: action.payload.bool,
        selectedWorkoutsListItemIndex: action.payload.index,
      };
    }
    case FETCH_WORKOUT_CALENDAR_FULFILLED: {
      return {
        ...state,
        workoutCalendar: action.payload,
      };
    }
    case FETCH_WORKOUT_CALENDAR_REJECTED: {
      return {
        ...state,
        workoutCalendarError: action.payload,
      };
    }
    case FETCH_WORKOUT_CALENDAR_FULFILLED_ALT: {
      return {
        ...state,
        workoutCalendarAlt: action.payload.result.dates,
      };
    }
    case FETCH_WORKOUT_CALENDAR_REJECTED_ALT: {
      return {
        ...state,
        workoutCalendarErrorAlt: action.payload,
      };
    }
    case FETCH_WORKOUT_SESSION_FULFILLED: {
      return {
        ...state,
        activeDayInfo: action.payload.activeDayInfo || {},
        workoutSessionData: action.payload.data,
      };
    }
    case FETCH_WORKOUT_SESSION_REJECTED: {
      return {
        ...state,
        workoutCalendarError: action.payload.response.headers,
      };
    }
    case FETCH_SIMILAR_EXERCISES_FULFILLED: {
      const updates = {};
      if (!action.payload || !action.payload.length) {
        updates.optOutExerciseTypeFilter = 'all';
      } else {
        updates.optOutExerciseTypeFilter = 'similar';
      }
      return {
        ...state,
        similarExercisesForOptOut: action.payload,
        ...updates,
      };
    }
    case FETCH_OPT_OUT_ALL_LIFT_EXERCISES_FULFILLED: {
      return {
        ...state,
        optOutAllExercises: action.payload,
      };
    }
    case SET_OPT_OUT_EXERCISE_TYPE_FILTER: {
      return {
        ...state,
        optOutExerciseTypeFilter: action.payload,
      };
    }
    case SET_ACTIVE_WORKOUTS_MEDIA: {
      return {
        ...state,
        activeWorkoutsMedia: action.payload,
      };
    }
    case SUBMIT_WORKOUT_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              $merge: action.payload.data.workoutItem,
            },
          },
        },
        additionalWorkoutsData: {
          $set: action.payload.data.additionalData || {},
        },
      });
    }
    case SUBMIT_PROGRAM_WORKOUT_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              $merge: action.payload.data.workoutItem,
            },
          },
        },
        additionalWorkoutsData: {
          $set: action.payload.data.additionalData || {},
        },
        workoutProgramWeeks: {
          [action.payload.updateWeekIndex]: {
            status: {
              $set: action.payload.data.additionalData.weekStatus,
            },
            statusColorCode: {
              $set: action.payload.data.additionalData.weekStatusColorCode,
            },
          },
        },
        workoutProgramWeek: {
          [action.payload.updateDayIndex]: {
            status: {
              $set: action.payload.data.additionalData.dayBasedStatus,
            },
            statusColorCode: {
              $set: action.payload.data.additionalData.dayBasedStatusColorCode,
            },
          },
        },
      });
    }
    case SUBMIT_SESSION_WORKOUT_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  $merge: action.payload.data.workoutItem,
                },
              },
            },
          },
        },
        additionalWorkoutsData: {
          $set: action.payload.data.additionalData || {},
        },
      });
    }
    case SUBMIT_PROGRAM_SESSION_WORKOUT_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  $merge: action.payload.data.workoutItem,
                },
              },
            },
          },
        },
        additionalWorkoutsData: {
          $set: action.payload.data.additionalData || {},
        },
        workoutProgramWeeks: {
          [action.payload.updateWeekIndex]: {
            status: {
              $set: action.payload.data.additionalData.weekStatus,
            },
            statusColorCode: {
              $set: action.payload.data.additionalData.weekStatusColorCode,
            },
          },
        },
        workoutProgramWeek: {
          [action.payload.updateDayIndex]: {
            status: {
              $set: action.payload.data.additionalData.dayBasedStatus,
            },
            statusColorCode: {
              $set: action.payload.data.additionalData.dayBasedStatusColorCode,
            },
          },
        },
      });
    }

    case SET_ADDITIONAL_WORKOUTS_DATA: {
      return {
        ...state,
        additionalWorkoutsData: action.payload,
      };
    }
    case SET_OPT_OUT_WORKOUT: {
      return {
        ...state,
        optOutWorkout: action.payload,
      };
    }
    case SET_TIMER_MODAL_OPEN: {
      return {
        ...state,
        isTimerModalOpen: action.payload,
        timerDuration: !action.payload ? null : state.timerDuration,
      };
    }
    case SET_TIMER_DURATION: {
      return {
        ...state,
        timerDuration: action.payload,
      };
    }
    case SET_QUESTIONNAIRE_TOTAL: {
      return {
        ...state,
        questionnaireTotal: action.payload,
      };
    }
    case SET_CAPTURE_IMAGE_MODAL: {
      return {
        ...state,
        captureImageModal: action.payload,
        uploadedWorkoutJournalMediaObject: {},
      };
    }
    case SET_CAPTURE_VIDEO_MODAL: {
      return {
        ...state,
        captureVideoModal: action.payload,
        uploadedWorkoutJournalMediaObject: {},
      };
    }
    case SET_HISTORY_WORKOUT: {
      return {
        ...state,
        historyWorkout: action.payload,
        historyData: {},
      };
    }
    case FETCH_TAG_EXERCISES_FULFILLED: {
      return {
        ...state,
        exercisesForTag: action.payload,
      };
    }
    case SET_SELECTED_TAG_EXERCISE: {
      if (action.payload.tagWorkout.assignedId) {
        return {
          ...state,
          selectedTagExerciseObject: {
            ...state.selectedTagExerciseObject,
            [action.payload.tagWorkout.assignedId]: action.payload.exercise,
          },
          tagWorkout: {},
        };
      }
      return {
        ...state,
        selectedTagExerciseObject: {
          ...state.selectedTagExerciseObject,
          [action.payload.tagWorkout.saveDataId]: action.payload.exercise,
        },
        tagWorkout: {},
      };
    }
    case SUBMIT_OPT_OUT_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  $set: action.payload.data,
                },
              },
            },
          },
        },
        optOutWorkout: {
          $set: {},
        },
      });
    }
    case SUBMIT_OPT_OUT_TOP_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              $set: action.payload.data,
            },
          },
        },
        optOutWorkout: {
          $set: {},
        },
      });
    }
    case SET_IS_WORKOUTS_MEDIA_UPLOADED: {
      return {
        ...state,
        isWorkoutMediaUploaded: action.payload,
        uploadedWorkoutMedia: [],
      };
    }
    case SET_JOURNAL_ENTRY_WORKOUT: {
      return {
        ...state,
        journalEntryWorkout: action.payload,
        uploadedWorkoutJournalMedia: action.payload?.workoutObject?.journalEntries[0]?.media.length
          ? action.payload?.workoutObject?.journalEntries[0]?.media.map((media) => media.id) : [],
        uploadedWorkoutJournalMediaExtended:
          action.payload?.workoutObject?.journalEntries[0]?.media || [],
        uploadedWorkoutJournalMediaObject: {},
        removeWorkoutJournalMediaArray: [],
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_START: {
      return {
        ...state,
        uploadedWorkoutMediaError: '',
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_REJECTED: {
      return {
        ...state,
        uploadedMediaError: action.payload,
      };
    }
    case UPLOAD_WORKOUTS_MEDIA_FULFILLED: {
      return {
        ...state,
        uploadedWorkoutsMediaError: '',
        uploadedWorkoutsMedia: action.payload,
        isWorkoutsMediaUploaded: true,
      };
    }
    case UPLOAD_WORKOUT_JOURNAL_MEDIA_FULFILLED: {
      const { uploadedWorkoutJournalMedia } = state;
      const uploadedWorkoutJournalMediaArray = [...uploadedWorkoutJournalMedia, action.payload.id];
      const { uploadedWorkoutJournalMediaExtended } = state;
      const uploadedWorkoutMediaExtendedArray = [
        ...uploadedWorkoutJournalMediaExtended, action.payload,
      ];
      return {
        ...state,
        uploadedWorkoutJournalMediaError: '',
        isWorkoutJournalMediaUploaded: true,
        uploadedWorkoutJournalMedia: uploadedWorkoutJournalMediaArray,
        uploadedWorkoutJournalMediaExtended: uploadedWorkoutMediaExtendedArray,
        uploadedWorkoutJournalMediaObject: action.payload,
      };
    }
    case FETCH_WORKOUT_HISTORY_START: {
      return {
        ...state,
        historyPage: 1,
        isEndOfWorkoutHistoryList: false,
      };
    }
    case FETCH_WORKOUT_HISTORY_FULFILLED: {
      return {
        ...state,
        historyData: action.payload,
        historyPage: 1,
        isEndOfWorkoutHistoryList:
          !!(!action.payload || action.payload.length < 5),
      };
    }
    case FETCH_MORE_WORKOUT_HISTORY_FULFILLED: {
      return {
        ...state,
        historyData: [...state.historyData, ...action.payload],
        historyPage: state.historyPage + 1,
        isEndOfWorkoutHistoryList:
        !!(!action.payload || action.payload.length < 5),
      };
    }
    case SET_UPLOAD_WORKOUT_MEDIA_FALSE: {
      return {
        ...state,
        uploadedWorkoutMedia: [],
        uploadedWorkoutMediaExtended: [],
      };
    }
    case SET_REMOVE_WORKOUT_JOURNAL_MEDIA_ARRAY: {
      const { removeWorkoutJournalMediaArray } = state;
      const newArray = [...removeWorkoutJournalMediaArray, parseInt(action.payload, 10)];
      return {
        ...state,
        removeWorkoutJournalMediaArray: newArray,
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_START: {
      return {
        ...state,
        errorMessage: '',
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_FULFILLED: {
      return {
        ...state,
        exertionScore: {
          alertText: action.payload.data.alertText,
          alertType: action.payload.data.alertType,
          date: action.payload.scoreDate,
          externalUrl: action.payload.data.externalUrl,
          score: action.payload.data.score,
        },
      };
    }
    case GET_WORKOUTS_EXERTION_SCORE_REJECTED: {
      return {
        ...state,
        errorMessage: action.payload,
      };
    }
    case SET_EXERTION_SCORE_MODAL_SHOWING: {
      return {
        ...state,
        exertionScoreModalShowing: action.payload,
      };
    }
    case SET_IS_PRINTING_OPTIONS_SHOWING: {
      return {
        ...state,
        isPrintingOptionsShowing: action.payload,
      };
    }
    case SET_IS_ADDITIONAL_WORKOUTS: {
      return {
        ...state,
        isAdditionalWorkouts: action.payload,
      };
    }
    case SET_MULTI_QUESTION_MODAL_RESPONSE: {
      return {
        ...state,
        multiQuestionModalResponse: action.payload,
      };
    }
    case SET_QUICK_IMAGE_WORKOUTS_JOURNAL_MODAL: {
      return {
        ...state,
        quickImageWorkoutsJournalModal: action.payload,
      };
    }
    case SET_QUICK_VIDEO_WORKOUTS_JOURNAL_MODAL: {
      return {
        ...state,
        quickVideoWorkoutsJournalModal: action.payload,
      };
    }
    case SET_QUICK_JOURNAL_MODAL: {
      return {
        ...state,
        quickJournalModal: action.payload,
      };
    }
    case SET_QUICK_WORKOUTS_JOURNAL: {
      return {
        ...state,
        quickWorkoutsJournal: action.payload,
      };
    }
    case SET_IS_DAY_PICKER_OPEN: {
      return {
        ...state,
        isDayPickerOpen: action.payload,
      };
    }
    case SET_MULTI_QUESTION_MODAL: {
      return {
        ...state,
        multiQuestionModalQuestion: action.payload.questionObject,
        questionWorkoutId: action.payload.workoutId,
      };
    }
    case WIPE_MEDIA_ERROR: {
      return {
        ...state,
        quickJournalMediaError: '',
      };
    }
    case SET_IS_SMALL_SCREEN_DAY_PICKER_OPEN: {
      return {
        ...state,
        isSmallScreenDayPickerOpen: action.payload,
      };
    }
    case UPLOAD_QUICK_JOURNAL_MEDIA_FULFILLED: {
      return {
        ...state,
        quickJournalMedia: action.payload,
        quickJournalModal: true,
        quickImageWorkoutsJournalModal: false,
        quickVideoWorkoutsJournalModal: false,
        quickJournalMediaError: '',
      };
    }
    case UPLOAD_QUICK_JOURNAL_MEDIA_REJECTED: {
      return {
        ...state,
        quickJournalMediaError: action.payload.response.headers.message,
      };
    }
    case SET_PROGRAM_DAY_INDEX: {
      return {
        ...state,
        programDayIndex: action.payload,
      };
    }
    case CREATE_WORKOUT_JOURNAL_ENTRY_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  journalEntries: {
                    $set: [action.payload.data],
                  },
                },
              },
            },
          },
        },
      });
    }
    case CREATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              journalEntries: {
                $set: [action.payload.data],
              },
            },
          },
        },
      });
    }
    case UPDATE_WORKOUT_JOURNAL_ENTRY_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  journalEntries: {
                    $set: [action.payload.data],
                  },
                },
              },
            },
          },
        },
      });
    }
    case UPDATE_TOP_WORKOUT_JOURNAL_ENTRY_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              journalEntries: {
                $set: [action.payload.data],
              },
            },
          },
        },
      });
    }
    case WRITE_WORKOUT_NOTE_TO_ATHLETE_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              sessionBreakItems: {
                [action.payload.sessionItemIndex]: {
                  journalEntries: {
                    $set: [action.payload.data],
                  },
                },
              },
            },
          },
        },
      });
    }
    case WRITE_TOP_WORKOUT_NOTE_TO_ATHLETE_FULFILLED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              journalEntries: {
                $set: [action.payload.data],
              },
            },
          },
        },
      });
    }
    case FETCH_WORKOUT_DOCUMENT_FULFILLED: {
      return {
        ...state,
        fetchedWorkoutDocument: action.payload,
      };
    }
    case FETCH_WORKOUT_DOCUMENT_REJECTED: {
      return {
        ...state,
        fetchedWorkoutDocument: action.payload,
      };
    }
    case FETCH_WORKOUT_FILE_FULFILLED: {
      return {
        ...state,
        currentWorkoutFile: action.payload,
      };
    }
    case FETCH_WORKOUT_FILE_REJECTED: {
      return {
        ...state,
        documentsError: action.payload,
      };
    }
    case FETCH_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return update(state, {
        currentWorkout: {
          $set: action.payload.data,
        },
        currentNonDateDay: {
          $set: action.payload.day,
        },
      });
    }
    case FETCH_INITIAL_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return update(state, {
        currentWorkout: {
          $set: action.payload.data,
        },
        programDayIndex: {
          $set: action.payload.dayIndex,
        },
        currentNonDateDay: {
          $set: action.payload.day,
        },
      });
    }
    case REFETCH_CURRENT_PROGRAM_WORKOUTS_FULFILLED: {
      return update(state, {
        currentWorkout: {
          $set: action.payload.data,
        },
      });
    }
    case FETCH_WORKOUT_PROGRAM_WORKOUTS_REJECTED: {
      return update(state, {
        currentWorkout: {
          workoutItems: {
            [action.payload.index]: {
              journalEntries: {
                $set: [action.payload.data],
              },
            },
          },
        },
      });
    }
    case RESET_WORKOUT_PROGRAM_FULFILLED: {
      return update(state, {
        currentSelectedProgram: {
          $set: action.payload.data,
        },
        workoutPrograms: {
          [action.payload.data.programIdx]: {
            $set: action.payload.data,
          },
        },
      });
    }
    case COMPLETE_WORKOUT_PROGRAM_FULFILLED: {
      return update(state, {
        currentSelectedProgram: {
          $set: action.payload.data,
        },
        workoutPrograms: {
          [action.payload.data.programIdx]: {
            $set: action.payload.data,
          },
        },
      });
    }
    case FETCH_NEXT_WORKOUT_SESSION_FULFILLED: {
      return update(state, {
        nextWorkoutSession: {
          $set: action.payload.data,
        },
      });
    }
    case UPDATE_CURRENT_NON_DATE_DAY: {
      return update(state, {
        currentNonDateDay: {
          status: {
            $set: action.payload.additionalData.dayBasedStatus,
          },
          statusColorCode: {
            $set: action.payload.additionalData.dayBasedStatusColorCode,
          },
        },

      });
    }
    case SKIP_WORKOUT_PROGRAM_WORKOUTS_FULFILLED: {
      return update(state, {
        workoutProgramWeek: {
          [action.payload.weekDayIndex]: {
            $set: action.payload.data,
          },
        },
        currentNonDateDay: {
          $set: action.payload.data,
        },
      });
    }
    case COMPLETE_WORKOUT_FULFILLED: {
      return update(state, {
        workoutProgramWeek: {
          [action.payload.weekDayIndex]: {
            $set: action.payload.data,
          },
        },
        currentNonDateDay: {
          $set: action.payload.data,
        },
      });
    }
    case FETCH_WORKOUT_PROGRAM_WEEK_VIA_NEXT_FULFILLED: {
      return {
        ...state,
        workoutProgramWeek: action.payload.data,
        workoutProgramWeekIndex: action.payload.workoutProgramWeekIndex,
        weekDayIndex: action.payload.weekDayIndex,
      };
    }
    case WIPE_OUT_NON_DATE: {
      return update(state, {
        workoutPrograms: {
          $set: [],
        },
        workoutProgramWeeks: {
          $set: [],
        },
        currentSelectedProgram: {
          $set: {},
        },
        workoutProgramWeek: {
          $set: [],
        },
        workoutProgramWeekIndex: {
          $set: 0,
        },
        weekDayIndex: {
          $set: 0,
        },
        programDayIndex: {
          $set: 1,
        },
        currentNonDateDay: {
          $set: {},
        },
      });
    }
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START:
      return {
        ...state,
        importedDataIntegrationsError: null,
      };
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED:
      return {
        ...state,
        importedDataIntegrationsError: null,
        importedDataIntegrations: action.payload,
      };
    case FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED:
      return {
        ...state,
        importedDataIntegrationsError: action.payload,
      };
    case SET_SELECTED_INTEGRATION:
      return {
        ...state,
        selectedIntegration: action.payload,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_START:
      return {
        ...state,
        selectedIntegrationData: null,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED:
      return {
        ...state,
        selectedIntegrationData: action.payload,
      };
    case FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED:
      return {
        ...state,
        selectedIntegrationData: null,
      };
    default: {
      return {
        ...state,
      };
    }
  }
}
