import React, {
  useEffect, useState, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';
import { animated, useTransition, useSpring } from 'react-spring';

import * as Yup from 'yup';
import IcomoonReact from 'icomoon-react';

import { Form } from 'formik';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

import Text from '../../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import FormHandler from '../../../../../shared/components/FormHandler/FormHandler';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import useReseller from '../../hooks/useReseller';

import CreateProgramSlide1 from './CreateProgramSlide1';
import CreateProgramSlide2 from './CreateProgramSlide2';
import CreateProgramSlide3 from './CreateProgramSlide3';

import { TransitionGlobal } from '../../../../../shared/GlobalStyles';

const SlideContainer = styled('div')`
  display: block;
  height: auto;
  height: ${(props) => `${props.height}px`};
  position: relative;
  width: 100%;
  min-height: 184px;
  transition: ${TransitionGlobal};
  z-index: 1000;
`;

const SliderNav = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 45px;
  margin: 0 auto;
  z-index: 10;
  position: relative;
`;

const ModalWrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const NavCircle = styled('div')`
  height: 10px;
  width: 10px;
  display: block;
  border: 1px solid #979797;
  border-radius: 100%;
  cursor: pointer;

  &.active {
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    cursor: default;
  }
`;

const TitleError = styled('div')`
  font-size: 14px;
  color: #ff6600;
  width: 500px;
  display: flex;
  justify-content: center;
`;

const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  position: absolute;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 0;
  left: 0;
  margin-top: 40px;
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  margin-bottom: 30px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const InnerFormWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divider-line {
    display: flex;
    width: calc(100% + 80px);
    border-top: 2px solid rgb(232,232,232);
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

const FolderTextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  min-height: 78px;
  min-width: 250px;
  margin-top: -10px;

  .calendar-icon-text {
    min-width: 300px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    font-weight: 300;
  }
`;

const programSchema = Yup.object().shape({
  agreement: Yup.boolean()
    .required('Checkbox below is required')
    .oneOf([true], 'Checkbox below is required'),
  programName: Yup.string()
    .required('Name is required'),
  shortDescription: Yup.string()
    .required('Tagline is required'),
  longDescription: Yup.string()
    .required('Description is required'),
  price: Yup.string()
    .required('Required'),
  programUrl: Yup.string()
    .required('A program URL is required'),
  billingFrequency: Yup.string()
    .required('Required'),
  redirectUrl: Yup.string()
    .url('Must be a valid URL'),
  programType: Yup.string()
    .when('calendarId', {
      is: (calId) => calId,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }).nullable(),
  calendarOffsetDate: Yup.string()
    .when('programType', {
      is: (programType) => programType === 'PRE-BUILT',
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
});

const defaultSlideStyle = { position: 'absolute', width: '100%' };

const CreateProgramForm = ({
  onRequestClose,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(0);
  const [slideDimensions, setSlideDimensions] = useState({
    height: 0,
    width: 0,
  });
  const slideRef = useRef();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedCalendar, setSelectedCalendar] = useState('');
  const [urlHandled, setUrlHandled] = useState(false);
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [uploadedMediaName, setUploadedMediaName] = useState('');
  const [programType, setProgramType] = useState(null);
  const [page1Errors, setPage1Errors] = useState(false);
  const [page2Errors, setPage2Errors] = useState(false);
  const [page3Errors, setPage3Errors] = useState(false);

  const theme = useTheme();

  const {
    currentUser,
    userGroups,
    handleFetchUserGroups,
    handleFetchCalendars,
    handleCreateProgram,
  } = useReseller();

  const uploadedMedia = useSelector((state) => state.reseller.data.uploadMedia);
  const createProgramError = useSelector((state) => state.reseller.data.createProgramError);
  const isProgramCreating = useSelector((state) => state.reseller.ui.isProgramCreating);
  const [submitClicked, setSubmitClicked] = useState(false);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
      handleFetchCalendars(accountCode);
    }
  }, [currentUser]);

  const formAnimation = useSpring({
    opacity: 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: 0, zIndex: 0 });

  const fromResolver = () => {
    if (prevSlide === 0 && activeSlide === 1) {
      return 'translate3d(25%, 0, 0)';
    } if (prevSlide === 1 && activeSlide === 2) {
      return 'translate3d(25%, 0, 0)';
    }
    return 'translate3d(-25%, 0, 0)';
  };

  const leaveResolver = () => {
    if (prevSlide === 2 && activeSlide === 1) {
      return 'translate3d(-25%, 0, 0)';
    } if (prevSlide === 1 && activeSlide === 2) {
      return 'translate3d(-25%, 0, 0)';
    } if (prevSlide === 0 && activeSlide === 1) {
      return 'translate3d(-25%, 0, 0)';
    }
    return 'translate3d(25%, 0, 0)';
  };

  const slideTransition = useTransition(activeSlide, null, {
    from: { opacity: 0, transform: fromResolver() },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: leaveResolver() },
  });

  return (
    <>
      <ModalWrapper>
        <NavigationWrapper>
          {(activeSlide === 1 || activeSlide === 2) && (
          <Text
            className='back arrow-div'
            onClick={() => {
              if (activeSlide === 1) {
                setActiveSlide(0);
              } else if (activeSlide === 2) {
                setActiveSlide(1);
              }
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='left-arrow'
            />
          </Text>
          )}
          <Text
            className='close arrow-div'
            onClick={() => {
              onRequestClose();
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={13}
              icon='remove'
            />
          </Text>
        </NavigationWrapper>
        <FormHandler
          initialValues={
            {
              status: (currentUser.organizationSubscriptionStatus === 'ACTIVE' ? 1 : 0),
              programName: '',
              programUrl: '',
              shortDescription: '',
              longDescription: '',
              billingFrequency: 1,
              price: '',
            }
          }
          validationSchema={programSchema}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              const newValues = {
                descriptionLong: values.longDescription,
                descriptionShort: values.shortDescription,
                price: Math.round(values.price * 100),
                programName: values.programName,
                status: values.status,
                urlSlug: values.programUrl,
                billingFrequency: values.billingFrequency,
                mediaId: uploadedMedia ? uploadedMedia.id : 0,
                trialPeriod: values.trialPeriod ? values.trialPeriod.id : 0,
                calendarId: values.calendarId || 0,
                groups: values.groups || [],
                redirectUrl: values.redirectUrl || '',
              };
              if (!values.groups) {
                newValues.groups = [];
              } else {
                newValues.groups = values.groups;
              }
              if (values.numBillingCycles) {
                newValues.numBillingCycles = values.numBillingCycles.id;
              }
              if (values.mondayStart) {
                newValues.startOnDayOfWeek = 1;
              }
              // ONLY send calendarOffsetDate if the program type is PRE-BUILT
              if (values.programType === 'PRE-BUILT') {
                newValues.calendarOffsetDate = values.calendarOffsetDate || '';
              }
              handleCreateProgram({ accountCode: currentUser.accountCode, newProgram: newValues });
              setSubmitting(false);
            });
          }}
        >
          {(formProps) => (
            <InnerFormWrapper>
              <FolderTextWrapper>
                <Text className='calendar-icon-text'>Add New Program</Text>
                {(Object.keys(formProps.errors).length && submitClicked) ? <TitleError className='error-text'>Some required fields are blank</TitleError> : null}
                {createProgramError ? <TitleError className='error-text'>{createProgramError}</TitleError> : null}
              </FolderTextWrapper>
              <div className='divider-line' />
              <Form
                style={{ width: '100%' }}
              >
                <animated.div style={formAnimation}>
                  {formProps.errors.exercise && formProps.touched.exercise ? (
                    <FieldError className='text-center'>{formProps.errors.exercise}</FieldError>
                  ) : null}
                  <SlideContainer
                    height={slideDimensions.height}
                  >
                    {/* Slider transition that mounts the active slide
                      based on the state object, activeSlide. */}
                    {slideTransition.map(({ item, props }) => {
                      let returnComponent;
                      if (item === 0) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateProgramSlide1
                              formProps={formProps}
                              currentUser={currentUser}
                              urlHandled={urlHandled}
                              setUrlHandled={setUrlHandled}
                              isMediaUploaded={isMediaUploaded}
                              setIsMediaUploaded={setIsMediaUploaded}
                              uploadedMediaName={uploadedMediaName}
                              setUploadedMediaName={setUploadedMediaName}
                              page1Errors={page1Errors}
                            />
                          </animated.div>
                        );
                      } else if (item === 1) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateProgramSlide2
                              programType={programType}
                              setProgramType={setProgramType}
                              formProps={formProps}
                              selectedDate={selectedDate}
                              setSelectedDate={setSelectedDate}
                              selectedGroups={selectedGroups}
                              setSelectedGroups={setSelectedGroups}
                              selectedCalendar={selectedCalendar}
                              setSelectedCalendar={setSelectedCalendar}
                              page2Errors={page2Errors}
                            />
                          </animated.div>
                        );
                      } else if (item === 2) {
                        returnComponent = (
                          <animated.div ref={slideRef} style={{ ...defaultSlideStyle, ...props }}>
                            <ResizeObserver
                              onResize={(rect) => {
                                setSlideDimensions(rect);
                              }}
                            />
                            <CreateProgramSlide3
                              formProps={formProps}
                              page3Errors={page3Errors}
                            />
                          </animated.div>
                        );
                      }
                      return returnComponent;
                    })}
                  </SlideContainer>
                  <SliderNav>
                    <NavCircle
                      className={activeSlide === 0 ? 'active' : ''}
                      onClick={() => {
                        // Always allow the user to go back to slide 0
                        setPrevSlide(activeSlide);
                        setActiveSlide(0);
                      }}
                    />
                    <NavCircle
                      className={activeSlide === 1 ? 'active' : ''}
                      onClick={() => {
                        /*
                        If the user is on page 1, and they're trying to click
                        the circle to get to page 2, we need to validate the fields
                        on page 1 first.
                        */
                        if (activeSlide === 0) {
                          formProps.validateForm().then((errors) => {
                            const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                            if (Object.keys(errors).some((item) => page1Fields.includes(item))) {
                              setPage1Errors(true);
                            } else {
                              setPrevSlide(activeSlide);
                              setActiveSlide(1);
                            }
                          });
                        } else {
                          setPrevSlide(activeSlide);
                          setActiveSlide(1);
                        }
                      }}
                    />
                    <NavCircle
                      className={activeSlide === 2 ? 'active' : ''}
                      onClick={() => {
                        /*
                        If the user is on page 1 or 2, and they're trying to click
                        the circle to get to page 3, we need to validate the fields
                        on page 1/2 first.
                        */
                        if (activeSlide === 0 || activeSlide === 1) {
                          formProps.validateForm().then((errors) => {
                            const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                            const page2Fields = formProps.values.calendarId !== '' && formProps.values.calendarId !== undefined ? ['programType', 'calendarOffsetDate'] : [];
                            const fieldsToCheck = activeSlide === 0 ? page1Fields : page2Fields;
                            if (Object.keys(errors).some((item) => fieldsToCheck.includes(item))) {
                              activeSlide === 0 ? setPage1Errors(true) : setPage2Errors(true);
                            } else {
                              setPrevSlide(activeSlide);
                              setActiveSlide(2);
                            }
                          });
                        } else {
                          setPrevSlide(activeSlide);
                          setActiveSlide(2);
                        }
                      }}
                    />
                  </SliderNav>
                </animated.div>
                <animated.div style={spinnerAnimation}>
                  <SpinnerContainer>
                    <Spinner />
                  </SpinnerContainer>
                </animated.div>
                {
              (activeSlide === 2) && (
              <NoHoverButton
                onClick={() => {
                  // Validate slide 3 fields before allowing form to be submitted
                  formProps.validateForm().then((errors) => {
                    const page3Fields = ['price', 'billingFrequency', 'agreement'];
                    if (Object.keys(errors).some((item) => page3Fields.includes(item))) {
                      setPage3Errors(true);
                    } else {
                      formProps.setSubmitting(true);
                      formProps.submitForm();
                      setSubmitClicked(true);
                    }
                  });
                }}
                cta='Submit'
                type='button'
                fullWidth
                className='modal-button2'
                customColor={theme.colors.green}
                noBorder
                disabled={isProgramCreating}
                large
                square
                primary
                bottom
              />
              )
}
                {(activeSlide === 0
                || activeSlide === 1
                )
              && (
              <NoHoverButton
                onClick={() => {
                  if (activeSlide === 0) {
                    // Validate slide 1 fields before moving to next page
                    formProps.validateForm().then((errors) => {
                      const page1Fields = ['programName', 'programUrl', 'shortDescription', 'longDescription', 'redirectUrl'];
                      if (Object.keys(errors).some((item) => page1Fields.includes(item))) {
                        setPage1Errors(true);
                      } else {
                        setPrevSlide(activeSlide);
                        setActiveSlide(1);
                      }
                    });
                  } else if (activeSlide === 1) {
                    // Validate slide 2 fields before moving to next page
                    formProps.validateForm().then((errors) => {
                      const page2Fields = formProps.values.calendarId !== '' && formProps.values.calendarId !== undefined ? ['programType', 'calendarOffsetDate'] : [];
                      if (Object.keys(errors).some((item) => page2Fields.includes(item))) {
                        setPage2Errors(true);
                      } else {
                        setPrevSlide(activeSlide);
                        setActiveSlide(2);
                      }
                    });
                  }
                }}
                cta='Next Step'
                type='button'
                fullWidth
                className='modal-button2'
                customColor={theme.colors.green}
                noBorder
                large
                square
                primary
                bottom
              />
              )}
              </Form>
            </InnerFormWrapper>
          )}
        </FormHandler>
      </ModalWrapper>
    </>
  );
};

CreateProgramForm.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
};

export default CreateProgramForm;
