/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import Text from '../../../../shared/components/Text/Text';
import MiniBarChart from './miniBarChart';

const TotalWeekBreakdownBarChartsGrid = () => {
  const [containerWidth, setContainerWidth] = useState(0);
  const sets = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.sets);
  const reps = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.reps);
  const tonnage = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.tonnage);
  const duration = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.breakdown?.duration);

  /** styles */
  const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  padding-top: ${containerWidth > 767 ? '20px' : '0px'};
  padding-bottom: 20px;
`;

  const Row = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: ${containerWidth < 768 ? 'wrap' : ''};
  `;
  const InnerContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-width: ${containerWidth < 768 ? '100%' : ''};
  text-align: center;
  flex-wrap: ${containerWidth < 400 ? 'wrap' : ''};
  padding: 20px;
`;

  return (
    <Container>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect?.width);
        }}
      />
      <Row>
        <InnerContainer>
          <Text style={{ minWidth: 110, maxWidth: 110 }} fontSize='15px' fontWeight={800}>Total Sets Completed</Text>
          <MiniBarChart title='Total Sets Completed' currentWeekValue={sets?.currentWeek} previousWeekValue={sets?.previousWeek} currentWeekLabel={`${sets?.currentWeek}`} previousWeekLabel={`${sets?.previousWeek}`} containerWidth={containerWidth} />
        </InnerContainer>
        <InnerContainer style={{ borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: '#F2F2F2' }}>
          <Text style={{ minWidth: 110, maxWidth: 110 }} fontSize='15px' fontWeight={800}>Total Reps</Text>
          <MiniBarChart title='Total Reps' currentWeekValue={reps?.currentWeek} previousWeekValue={reps?.previousWeek} currentWeekLabel={`${reps?.currentWeek}`} previousWeekLabel={`${reps?.previousWeek}`} containerWidth={containerWidth} />
        </InnerContainer>
      </Row>
      <Row style={containerWidth > 767 ? { borderTopWidth: 1, borderTopStyle: 'solid', borderTopColor: '#F2F2F2' } : {}}>
        <InnerContainer>
          <Text style={{ minWidth: 110, maxWidth: 110 }} fontSize='15px' fontWeight={800}>Total Tonnage</Text>
          <MiniBarChart title='Total Tonnage' currentWeekValue={tonnage?.currentWeek} previousWeekValue={tonnage?.previousWeek} currentWeekLabel={`${tonnage?.currentWeek}`} previousWeekLabel={`${tonnage?.previousWeek}`} containerWidth={containerWidth} />
        </InnerContainer>
        <InnerContainer style={{ borderLeftWidth: 1, borderLeftStyle: 'solid', borderLeftColor: '#F2F2F2' }}>
          <Text style={{ minWidth: 110, maxWidth: 110 }} fontSize='15px' fontWeight={800}>Total Session Duration</Text>
          <MiniBarChart title='Total Session Duration' currentWeekValue={duration?.currentWeek?.timeInSeconds} previousWeekValue={duration?.previousWeek?.timeInSeconds} currentWeekLabel={duration?.currentWeek?.formattedTime} previousWeekLabel={duration?.previousWeek?.formattedTime} containerWidth={containerWidth} />
        </InnerContainer>
      </Row>
    </Container>
  );
};

export default TotalWeekBreakdownBarChartsGrid;
