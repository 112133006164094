import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import NoteEntries from './WorkoutEntriesNotes';
import OptionField from './OptionField';
import { ColorLightGray } from '../../../../shared/GlobalStyles';

const ProgressionContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-left: 20px;

  .css-uk3tfh-NoteContainer {
    padding: 0px 20px 0px 0px;
  }
`;

export const OptionText = styled('p')`
  font-size: 13px;
  color: ${props => props.theme.textColor};
  white-space: pre-line;
`;

export const OptionLabel = styled('span')`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
  color: ${props => props.theme.textColor};
`;

export const OptionSubtext = styled('span')`
  font-size: 10px;
  font-weight: 300;
  color: ${props => props.theme.lightTextColor};
  margin-top: 10px;
  margin-bottom: 20px;
  display: block;
`;

const EntriesProgression = ({
  athleteId,
  formikRef,
  index,
  info,
  namespace,
  updateWorkout,
  workout,
}) => {
  const isCompletion = workout.additional_options.completion === 1;

  return (
    <Formik
      key={workout.work_id}
      ref={formikRef}
      initialValues={
        isCompletion ? (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                mastered: 1,
                level: workout.level,
                step: workout.step,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              mastered: 1,
              level: workout.level,
              step: workout.step,
            });
          })()
        ) : (
          (() => {
            if (workout.result) {
              return ({
                work_id: workout.work_id,
                user_id: athleteId,
                mastered: workout.result.completed,
                level: workout.level,
                step: workout.step,
              });
            }
            return ({
              work_id: workout.work_id,
              user_id: athleteId,
              mastered: 0,
              level: workout.level,
              step: workout.step,
            });
          })()
        )
      }
      onSubmit={(values, actions) => {
        updateWorkout(index, namespace, values);
        // alert(JSON.stringify(values, null, 2))
        actions.setSubmitting(false);
      }}
      render={props => (
        <ProgressionContainer>
          <Form>
            <OptionText>
              {info}
            </OptionText>
            {isCompletion ? (
              <OptionLabel>
                This is a supplementary step. It does not require you to master.
              </OptionLabel>
            ) : (
              <Fragment>
                <OptionLabel>
                  Select current status for this step
                </OptionLabel>
                <OptionField
                  handleChange={props.handleChange}
                  isKeyValuePair
                  name='mastered'
                  values={props.values.mastered}
                  options={[
                    { label: "I'm still working on this." },
                    { label: 'I have mastered this.' },
                  ]}
                />
              </Fragment>
            )}
            <OptionSubtext>
              *All steps in a level must be mastered to move on to the next level.
            </OptionSubtext>
            {/* <pre>{JSON.stringify(props.values, null, 2)}</pre> */}
          </Form>
        </ProgressionContainer>


      )}
    />
  );
};

EntriesProgression.propTypes = {
  athleteId: PropTypes.number.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  info: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  updateWorkout: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object).isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default EntriesProgression;
