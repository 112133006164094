/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const NoteBox = styled('div')`
  display: flex;
  margin-bottom: 25px;
  border-radius: 12px;
  background: white;
  box-shadow: ${BoxShadow};
	flex-direction: column;
	align-items: center;
	padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  white-space: pre-wrap;
  font-size: 12px;
  font-family: 'Nunito Sans';
  text-align: center;
`;

const WorkoutNotes = (
  { workoutObject },
) => (
  <NoteBox>
    {workoutObject.text}
  </NoteBox>
);

WorkoutNotes.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default WorkoutNotes;
