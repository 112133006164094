/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import {
  useLocation,
} from 'react-router-dom';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import ErrorMessageWarning from './errorMessageWarning';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { unsubscribe } from '../../ducks/athleteReportCardActions';

const Unsubscribe = ({ message }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const token = new URLSearchParams(location.search).get('token');
  const encodedemailString = encodeURIComponent(email);
  const isUnscubscribing = useSelector((state) => state.athleteReportCard.ui.isUnscubscribing);
  const unsubscribed = useSelector((state) => state.athleteReportCard.data.unsubscribed);
  const unsubscribeError = useSelector((state) => state.athleteReportCard.data.unsubscribeError);

  useEffect(() => {
    if (email && token) {
      dispatch(unsubscribe(token, encodedemailString));
    }
  }, []);

  const PageWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    flex: 1;
    justify-content: center;
  `;
  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    max-width: 500px;
    border: 1px solid #F2F2F2;
    flex-wrap: wrap;
    box-shadow: 0px 8px 16px 0px rgba(48, 49, 51, 0.10);
    border-radius: 10px;
  `;
  const IconAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 25px;
    text-align: center;
  `;
  const IconContainer = styled('div')`
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background-color: ${theme.colors.green};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  return (
    <>
      <Helmet>
        <title>Weekly Performance Assessment | TeamBuildr</title>
      </Helmet>
      {isUnscubscribing ? (
        <PageWrapper>
          <Spinner />
        </PageWrapper>
      ) : (
        <>
          {unsubscribed ? (
            <PageWrapper>
              <HeaderContainer>
                <IconAndTextContainer>
                  <IconContainer>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={36}
                      icon='checkmark'
                      color='white'
                    />
                  </IconContainer>
                  <Text fontSize='20px' fontWeight={700}>
                    {message}
                  </Text>
                </IconAndTextContainer>
              </HeaderContainer>
            </PageWrapper>
          ) : (
            <>
              {unsubscribeError && (
              <PageWrapper>
                <ErrorMessageWarning errorMessage='Opps! Something went wrong when trying to unsubscribe. Please contact your administrator.' apiErrorMessage={unsubscribeError} />
              </PageWrapper>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
Unsubscribe.propTypes = {
  message: PropTypes.string.isRequired,
};
export default Unsubscribe;
