/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Spinner from '../../../../shared/components/Spinner/Spinner';

import {
  setTagWorkout, setSelectedExerciseForTag, setActiveWorkoutsMedia, fetchExercisesByTagIDForAccount,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    height: 84px;
    .remove-div-icon {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  position: relative;
`;

const SearchBar = styled('input')`
  font-weight: 500;
  color: grey;
  background: #ffffff;
	user-select: none;
  border-radius: 4px;
	cursor: default;
	min-height: 38px;
	padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
	width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  z-index: 100;
  border: none;
  flex: 1;
  background: rgba(176,196,222, 0.1);
  cursor: text;
`;

const TagExercise = styled('div')`
  padding: 15px;
  background: ${(props) => (!props.fake ? 'rgba(176,196,222, 0.1)' : 'none')};
	:hover {
		background: ${(props) => (!props.fake ? 'rgba(176,196,222, 0.5)' : 'none')};
	}
	display: flex;
	align-items: center;
  color: dimgrey;
  width: 100%;
  cursor: pointer;
`;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 65%;
  justify-content: center;
  width: 100%;
`;

const SpinnerAndText = styled('div')`
  display: flex;
  flex-direction: column;
`;

const TagSelectionModal = () => {
  const dispatch = useDispatch();
  const tagWorkout = useSelector(
    (state) => state.workouts.data.tagWorkout,
  );
  const exercisesForTag = useSelector(
    (state) => state.workouts.data.exercisesForTag,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isTagExercisesLoading = useSelector((state) => state.workouts.ui.isTagExercisesLoading);

  const [localList, setLocalList] = useState([]);
  const [searchedList, setSearchedList] = useState([]);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setLocalList(exercisesForTag);
  }, [exercisesForTag]);

  useEffect(() => {
    if (tagWorkout && Object.keys(tagWorkout).length) {
      dispatch(
        fetchExercisesByTagIDForAccount(
          tagWorkout.tag.id,
          currentUser.accountCode,
        ),
      );
    }
  }, [tagWorkout]);

  useEffect(() => {
    const searchedArray = [];
    localList.forEach((object) => {
      if (object.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(object);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList([{
        name: 'No matches found',
        fake: true,
      }]);
    }
  }, [searchTerm]);

  const selectFunction = (selectedOption) => {
    dispatch(setSelectedExerciseForTag(selectedOption, tagWorkout));
  };

  const listMaker = () => {
    if (isUserSearching) {
      if (searchedList.length) {
        return searchedList;
      }
    }
    return localList;
  };

  const tagMapper = (tagArray) => tagArray.map((tagExercise) => (
    <TagExercise
      fake={tagExercise.fake}
    >
      <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div
          onClick={() => selectFunction(tagExercise)}
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {tagExercise.name}
        </div>
        {tagExercise.media ? (
          <div
            onClick={() => dispatch(setActiveWorkoutsMedia(tagExercise.media[0]))}
            style={{
              width: '30px',
              height: '100%',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='video'
              color='dimgrey'
            />
          </div>
        ) : null}
      </div>
    </TagExercise>
  ));

  return (
    <ModalContainer
      isActive={Object.keys(tagWorkout).length}
    >
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          width: '90%',
        }}
        >
          {!isTagExercisesLoading ? (
            <SearchBar
              placeholder='Search for Exercise...'
              value={isUserSearching ? searchTerm : ''}
              onChange={(inputValue) => {
                setSearchTerm(inputValue.target.value);
              }}
              onKeyDown={() => {
                setIsUserSearching(true);
              }}
            />
          ) : null}
        </div>
        <div className='remove-div-icon' onClick={() => dispatch(setTagWorkout({}))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        {!isTagExercisesLoading ? tagMapper(listMaker()) : (
          <SpinnerWrapper>
            <SpinnerAndText>
              <Spinner />
              <div>Tag Exercises Loading..</div>
            </SpinnerAndText>
          </SpinnerWrapper>
        )}
      </ContentContainer>
    </ModalContainer>
  );
};

export default TagSelectionModal;
