import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import { componentWillAppendToBody } from 'react-append-to-body';
import BigModal from '../reseller/BigModal';
import DeleteModal from './DeleteModal';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalUpdateCoupon from './ModalUpdateCoupon';
import AlertBanner from '../../../../../shared/components/AlertBanner/AlertBanner';
import Text from '../../../../../shared/components/Text/Text';
import SubText from '../../../../../shared/components/SubText/SubText';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import ActionButton from '../../../../../shared/components/ActionButton/ActionButton';
import Table from '../../../../../shared/components/Table/Table';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import useReseller from '../../hooks/useReseller';
import ModalHandler from '../reseller/ModalHandler';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

const MainContainer = styled('main')`
  flex: 10;
  overflow: scroll;
`;

const GridContainer = styled('div')`
  margin-left: 30px;
  margin-right: 30px;
`;

const HeaderRow = styled('div')`
  display: flex;
  margin: 30px 30px 20px 30px;
  justify-content: space-between;
  h1 {
    text-transform: capitalize;
  }
`;

const CreateButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
`;

const SwitchButtonWrapper = styled('div')`
    display: flex;
    width: 400px;
    min-width: 220px;
    align-items: center;
    justify-content: space-between;
    .toggle-text {
      text-align: center;
      display: flex;
      justify-content: center;
      width: 200px;
      margin-left: 15px;
    }
  `;

const SpinnerWrapper = styled('div')`
  position: absolute;
  display: flex;
  height: 60%;
  justify-content: center;
  width: 100%;
  margin-left: -150px;
`;

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
`;

const DropdownMenu = ({
  row,
  setcurrentCouponRow,
  handleSetCouponModal,
}) => (
  <>
    {/* Added a global component from emotion to inject css classes to the global html elements. */}
    <Global
      styles={css`
        .dropdown-item {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px;
          span {
            color: #999999;
          }
          .drop-icon-text {
            margin-left: 10px;
            padding-top: 2px;
          }
          :hover {
            transition: linear 0s;
            background: #006a65;
            color: white;
            span {
              color: white;
            }
            svg {
              fill: white;
            }
          }
        }

        .dropdown-menu {
          padding: 0px;
        }

        .dropdown-divider {
          margin: 0px;
        }
      `}
    />
    <Dropdown.Menu>

      <Dropdown.Item
        className='dropdown-item'
        onClick={() => {
          setcurrentCouponRow(row);
          handleSetCouponModal('update_coupon_modal');
        }}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={12}
          icon='pencil'
        />
        <SubText
          className='drop-icon-text'
          fontSize={14}
        >
          Update
        </SubText>
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item onClick={() => {
        setcurrentCouponRow(row);
        handleSetCouponModal('delete_coupon_modal');
      }}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={12}
          icon='trashcan'
        />
        <SubText
          className='drop-icon-text'
          fontSize={14}
        >
          Delete
        </SubText>
      </Dropdown.Item>
    </Dropdown.Menu>
  </>
);

const AppendDropdownList = componentWillAppendToBody(DropdownMenu);

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={25}
      icon='dots'
    />
  </Link>
));

const CouponsGrid = () => {
  const {
    currentUser,
    handleFetchCoupons,
    handleDeleteCoupon,
    handleSetCouponModal,
    handleClearCouponsFetchError,
  } = useReseller();

  const isCouponsLoading = useSelector((state) => state.reseller.ui.isCouponsFetchLoading);
  const coupons = useSelector((state) => state.reseller.data.coupons);
  const [activeToggle, setActiveToggle] = useState('active');
  let activeCoupons = [];
  let expiredCoupons = [];
  if (coupons) {
    activeCoupons = coupons.filter((coupon) => coupon.valid);
    expiredCoupons = coupons.filter((coupon) => !coupon.valid);
  }
  const couponsToDisplay = activeToggle === 'active' ? activeCoupons : expiredCoupons;
  const couponsFetchError = useSelector((state) => state.reseller.data.couponsFetchError);
  const activeReduxModal = useSelector((state) => state.reseller.data.activeModal);

  const [currentCouponRow, setcurrentCouponRow] = useState(null);

  if (couponsFetchError) {
    // If the initial fetch coupons errors out, we just show the error in a banner
    setTimeout(() => {
      handleClearCouponsFetchError();
    }, 8000);
  }

  useEffect(() => {
    // Handle initial fetch of coupons
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined
      && !coupons && !isCouponsLoading) {
      handleFetchCoupons(accountCode);
    }
  }, [currentUser]);

  const theme = useTheme();

  const customTableStyle = {
    // paddingBottom: '130px',
  };

  const handleDelete = () => {
    handleDeleteCoupon(currentUser.accountCode, currentCouponRow.id);
  };

  const columns = [
    {
      selector: 'id',
      name: 'Code',
      sortable: true,
      style: {
        'font-weight': 'bold',
      },
      width: '15%',
      wrap: true,
    },
    {
      selector: 'name',
      name: 'Description',
      sortable: true,
      style: {
        'font-weight': 'bold',
      },
      width: '25%',
    },
    {
      cell: (row) => {
        let part1;
        let part2;
        if (row.amountOff) {
          if (row.placeSymbolAfterCurrency) {
            part1 = `${row.amountOff / 100}${row.currencySymbol}`;
          } else {
            part1 = `${row.currencySymbol}${row.amountOff / 100}`;
          }
        } else {
          part1 = `${row.percentOff}%`;
        }
        if (row.duration === 'repeating') {
          const months = row.durationInMonths > 1 ? 'months' : 'month';
          part2 = `for ${row.durationInMonths} ${months}`;
        } else {
          part2 = row.duration;
        }
        return `${part1} ${part2}`;
      },
      name: 'Amount Off',
      sortable: true,
      selector: 'amountOff',
      style: {
        'font-weight': 'bold',
      },
    },
    {
      cell: (row) => {
        if (!row.maxRedemptions) {
          return row.timesRedeemed;
        }
        return `${row.timesRedeemed}/${row.maxRedemptions}`;
      },
      name: 'Redemptions',
      hide: 'md',
      selector: 'maxRedemptions',
      center: true,
    },
    {
      cell: (row) => {
        if (!row.redeemBy) {
          return 'N/A';
        }
        const newMoment = moment(row.redeemBy);
        return newMoment.format('MMM D, YYYY');
      },
      name: 'Exp. Date',
      hide: 'md',
      selector: 'redeemBy',
      center: true,
    },
    {
      cell: (row) => {
        const newMoment = moment(row.createdAt);
        return newMoment.format('MMM D, YYYY');
      },
      name: 'Created',
      sortable: true,
      selector: 'createdAt',
      hide: 'md',
      center: true,
      style: {
        'margin-right': '17px',
      },
    },
    {
      cell: (row) =>
      /**
           * defines the dropdown with the correct options
           * (only shows update option if calendar is active)
           * swaps archive for unarchive if in archived view
           */
        (
          <DropDownMenuWrapper>
            <Dropdown
              bsPrefix='trick-fix'
            >
              <Dropdown.Toggle as={CustomToggle} />
              <AppendDropdownList
                row={row}
                setcurrentCouponRow={setcurrentCouponRow}
                handleSetCouponModal={handleSetCouponModal}
              />
            </Dropdown>
          </DropDownMenuWrapper>
        ),
      right: true,
      width: '65px',
    },
  ];

  const createCouponButton = (setActiveModal) => (
    <ActionButton
      icon='plus'
      customColor={theme.colors.green}
      onClick={() => {
        setActiveModal('create_coupon_modal');
      }}
    />
  );

  const activeSwitch = () => {
    const SwitchWrapper = styled('div')`
      border: 1px solid black;
      width: 350px;
      height: 35px;
      display: flex;
      border-radius: 7px;
      @media screen and (max-width: 768px) {
        display: none;
      }
    `;

    const CollapseSwitch = styled('div')`
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: ${activeToggle === 'inactive' ? '#f5f5f5' : '#444444'};
      color: ${activeToggle === 'inactive' ? '#444444' : '#f5f5f5'};
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      font-weight: bold;
      font-size: 14px;
    `;

    const ExpandSwitch = styled('div')`
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: ${activeToggle === 'inactive' ? '#444444' : '#f5f5f5'};
      color: ${activeToggle === 'inactive' ? '#f5f5f5' : '#444444'};
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-weight: bold;
      font-size: 14px;
    `;

    return (
      <SwitchWrapper>
        <CollapseSwitch onClick={() => {
          setActiveToggle('active');
        }}
        >
          ACTIVE
        </CollapseSwitch>
        <ExpandSwitch onClick={() => {
          setActiveToggle('inactive');
        }}
        >
          INACTIVE
        </ExpandSwitch>
      </SwitchWrapper>
    );
  };

  return (
    <MainContainer>
      {couponsFetchError && (
      <AlertBanner>
        <Text>{couponsFetchError}</Text>
      </AlertBanner>
      )}
      <HeaderRow>
        <HeaderText
          fontWeight={300}
          letterSpacing='normal'
        >
          Coupons
        </HeaderText>
        <SwitchButtonWrapper>
          {activeSwitch()}
        </SwitchButtonWrapper>
      </HeaderRow>
      <GridContainer>
        {isCouponsLoading ? (<SpinnerWrapper><Spinner /></SpinnerWrapper>) : (
          <Table
            columns={columns}
            data={couponsToDisplay}
            highlightOnHover
            pointerOnHover
            style={customTableStyle}
            responsive
            noHeader
            onRowClicked={(row) => {
              setcurrentCouponRow(row);
              handleSetCouponModal('update_coupon_modal');
            }}
            noDataComponent={!isCouponsLoading ? `There are no ${activeToggle} coupons` : null}
          />
        )}
        {activeReduxModal === 'delete_coupon_modal' ? (

          <DeleteModal
            isOpen={!!activeReduxModal}
            onRequestClose={() => handleSetCouponModal('')}
            customButton
          >
            <ModalConfirmDelete
              handleDelete={handleDelete}
              couponId={currentCouponRow.id}
            />
          </DeleteModal>
        ) : null}
        {activeReduxModal === 'update_coupon_modal' ? (
          <BigModal
            isOpen
            onRequestClose={() => handleSetCouponModal('')}
            customButton
            customModalHeight='300px'
          >
            <ModalUpdateCoupon
              coupon={currentCouponRow}
              onRequestClose={() => handleSetCouponModal('')}
            />
          </BigModal>
        ) : null}
      </GridContainer>
      <CreateButtonWrapper>
        <ModalHandler
          actionButton={createCouponButton}
          currentCouponRow={currentCouponRow}
          setcurrentCouponRow={setcurrentCouponRow}
        />
      </CreateButtonWrapper>
    </MainContainer>
  );
};

DropdownMenu.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
  setcurrentCouponRow: PropTypes.func.isRequired,
  handleSetCouponModal: PropTypes.func.isRequired,
};

export default CouponsGrid;
