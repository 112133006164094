import download from 'downloadjs';

import {
  FETCH_REPORTABLE_GROUPS_START,
  FETCH_REPORTABLE_GROUPS_FULFILLED,
  FETCH_REPORTABLE_GROUPS_REJECTED,
  FETCH_ATHLETE_PARTICIPATION_START,
  FETCH_ATHLETE_PARTICIPATION_FULFILLED,
  FETCH_ATHLETE_PARTICIPATION_REJECTED,
  SET_ACTIVE_WEARABLES_DASH_DATE,
  SET_ACTIVE_GROUP,
  SET_ACTIVE_ATHLETE,
  SET_ACTIVE_METRIC_TYPE,
  SET_FILTERED_SEARCH_LIST,
  SET_FILTER_INFO_FROM_PIE_CHART,
  SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL,
  FETCH_ATHLETES_TO_WATCH_START,
  FETCH_ATHLETES_TO_WATCH_FULFILLED,
  FETCH_ATHLETES_TO_WATCH_REJECTED,
  FETCH_ATHLETE_AVERAGES_START,
  FETCH_ATHLETE_AVERAGES_FULFILLED,
  FETCH_ATHLETE_AVERAGES_REJECTED,
  CLEAR_ATHLETE_AVERAGES,
  CLEAR_ACTIVE_ATHLETE,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED,
  FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED,
  CLEAR_WEARABLES_DATA_CHART_REPORT,
  DOWNLOAD_WEARABLES_REPORT_FULFILLED,
  DOWNLOAD_WEARABLES_REPORT_START,
  DOWNLOAD_WEARABLES_REPORT_REJECTED,
  CLEAR_REPORTABLE_GROUPS,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const fetchReportableGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_REPORTABLE_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups?reporting=1`)
      .then((response) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_REPORTABLE_GROUPS_REJECTED, payload: err });
      });
  }
);

export const clearReportableGroups = () => ({
  type: CLEAR_REPORTABLE_GROUPS,
  payload: {},
});

export const fetchAthleteParticipationByGroupId = (accountCode, groupId) => (
  (dispatch) => {
    dispatch({ type: FETCH_ATHLETE_PARTICIPATION_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/wearables/participation?filterType=1&filterId=${groupId}`)
      .then((response) => {
        dispatch({ type: FETCH_ATHLETE_PARTICIPATION_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_ATHLETE_PARTICIPATION_REJECTED, payload: err });
      });
  }
);
export const setActiveWearablesDashDate = (date) => ({
  type: SET_ACTIVE_WEARABLES_DASH_DATE,
  payload: date,
});
export const setActiveGroup = (group) => ({
  type: SET_ACTIVE_GROUP,
  payload: group,
});
export const setActiveAthlete = (athlete) => ({
  type: SET_ACTIVE_ATHLETE,
  payload: athlete,
});
export const clearActiveAthlete = () => ({
  type: CLEAR_ACTIVE_ATHLETE,
  payload: {},
});
export const setActiveMetricType = (metricType) => ({
  type: SET_ACTIVE_METRIC_TYPE,
  payload: metricType,
});
export const setFilteredSearchList = (filteredData) => ({
  type: SET_FILTERED_SEARCH_LIST,
  payload: filteredData,
});
export const setFilterInfoFromPieChart = (filterObject) => ({
  type: SET_FILTER_INFO_FROM_PIE_CHART,
  payload: filterObject,
});
export const setActiveReduxModal = (activeModal) => (
  {
    type: SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL,
    payload: activeModal,
  }
);
export const fetchAthletesToWatchByGroupIdAndDate = (accountCode, groupId, date) => (
  (dispatch) => {
    dispatch({ type: FETCH_ATHLETES_TO_WATCH_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/wearables/donut-chart?filterType=1&filterId=${groupId}&date=${date}&watchThreshold=0`)
      .then((response) => {
        dispatch({ type: FETCH_ATHLETES_TO_WATCH_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_ATHLETES_TO_WATCH_REJECTED, payload: err });
      });
  }
);
export const fetchAthleteAverages = (
  accountCode,
  userId,
  currentDate,
  filterType,
  metricTypeRawValue,
) => (
  (dispatch) => {
    dispatch({ type: FETCH_ATHLETE_AVERAGES_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/wearables/line-chart?filterType=${filterType}&filterId=${userId}&metricType=${metricTypeRawValue}&date=${currentDate}`)
      .then((response) => {
        dispatch({ type: FETCH_ATHLETE_AVERAGES_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_ATHLETE_AVERAGES_REJECTED, payload: err });
      });
  }
);
export const clearAthleteAverages = () => ({
  type: CLEAR_ATHLETE_AVERAGES,
  payload: {},
});

export const fetchWearablesReportForDataChartByGroupOrAthleteId = (
  accountCode,
  filterIds,
  currentDate,
  filterType,
  csv,
  maxScore,
  minScore,
) => (
  (dispatch) => {
    const formatQuery = csv ? '&format=csv' : '';
    const minAndMaxQuery = (maxScore >= 0) && (minScore >= 0) ? `&maxScore=${maxScore}&minScore=${minScore}` : '';
    dispatch({ type: FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/wearables/raw-report?filterType=${filterType}&filterIds=${filterIds}&date=${currentDate}${formatQuery}${minAndMaxQuery}`)
      .then((response) => {
        dispatch({ type: FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED, payload: response.data });
      })
      .catch((err) => {
        dispatch({ type: FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED, payload: err });
      });
  }
);
export const downloadWearablesReport = (
  accountCode,
  filterIds,
  currentDate,
  filterType,
  csv = null,
) => (
  (dispatch) => {
    const formatQuery = csv ? '&format=csv' : '';
    dispatch({ type: DOWNLOAD_WEARABLES_REPORT_START });
    axiosAuthed.get(`/accounts/${accountCode}/reports/wearables/raw-report?filterType=${filterType}&filterIds=${filterIds}&date=${currentDate}${formatQuery}`)
      .then((response) => {
        dispatch({ type: DOWNLOAD_WEARABLES_REPORT_FULFILLED, payload: response.data });
        download(response.data, 'Teambuildr Wearables Report.csv', 'text/csv');
      })
      .catch((err) => {
        dispatch({ type: DOWNLOAD_WEARABLES_REPORT_REJECTED, payload: err });
      });
  }
);
export const clearWearablesDataChartReport = () => ({
  type: CLEAR_WEARABLES_DATA_CHART_REPORT,
  payload: {},
});
