/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, {
  useEffect, useRef,
} from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const TableWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 50px;
  margin-bottom: ${(props) => (props.last ? '200px' : '0px')};
`;

const TableHeader = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const NoExercisesBlock = styled('div')`
  font-family: 'Nunito Sans';
  font-Weight: 700;
  font-size: 24px;
  color: #939393;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
`;

const HeaderDeet = styled('div')`
  font-size: 20px;
  font-family: 'Nunito Sans';
  font-weight: 700;
`;

const AthleteName = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  font-weight: 700;
  margin-left: 10px;
`;

const AthleteAndAvatar = styled('div')`
  display: flex;
  align-items: center;
`;

const AthleteAvatar = styled('div')`
  img {
    height: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    width: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    border-radius: 50%;
  }
`;

const RefWrapper = styled('div')`

`;

const EmptyReportSection = ({
  user, setHeaderDate, setCurrentAthlete, lastUser,
}) => {
  const athleteGenerator = (generatedUser) => (
    <AthleteAndAvatar>
      <AthleteAvatar>
        <img src={user.pic || 'https://s3.amazonaws.com/teambuildr-staging-assets/pic/1670956695571-38.jpg'} alt='' />
      </AthleteAvatar>
      <AthleteName>
        {`${generatedUser.firstName} ${generatedUser.lastName}`}
      </AthleteName>
    </AthleteAndAvatar>
  );

  const { ref: athleteRef, inView: athleteInView } = useInView({
    rootMargin: '-50px 0px -80% 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (athleteInView) {
      setCurrentAthlete(user);
    }
  }, [athleteInView, setCurrentAthlete, user]);

  const { ref: dateRef, inView: dateInView } = useInView({
    rootMargin: '-50px 0px -80% 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (dateInView) {
      setHeaderDate('');
    }
  }, [dateInView, setHeaderDate, user]);

  return (
    <RefWrapper ref={athleteRef}>
      <TableWrapper
        key={`${user.userId}report`}
        id={`${user.userId}report`}
        last={user.dates.length - 1 === 0 && lastUser}
        ref={dateRef}
      >
        <TableHeader className='TableHeader'>
          {athleteGenerator(user)}
          <HeaderDeet />
        </TableHeader>
        <NoExercisesBlock>No Exercises Completed Yet</NoExercisesBlock>
      </TableWrapper>
    </RefWrapper>
  );
};

EmptyReportSection.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  setHeaderDate: PropTypes.func.isRequired,
  setCurrentAthlete: PropTypes.func.isRequired,
  lastUser: PropTypes.bool.isRequired,
};

export default EmptyReportSection;
