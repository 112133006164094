import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { useDispatch, useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import Text from '../../../../shared/components/Text/Text';

const StatusIndicatorContainer = styled('div')`
  position: fixed;
  bottom: 23px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  width: auto;
  margin-right: 20px;

  .connection {
    display: flex;
    margin-left: 10px;
  }

  .circle {
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: block;
    margin-right: 5px;
    align-self: center;

    &.connected {
      background-color: ${props => props.theme.colors.green};
    }
    &.disconnected {
      background-color: ${props => props.theme.colors.red};
    }
  }

  @media screen and (max-width: 480px) {
    svg {
      display: none!important;
    }
    display: none;
  }
`;

const StatusIndicator = () => {
  const isConnected = useSelector((state) => state.socketTester.ui.isConnected);
  const isAuthenticated = useSelector((state) => state.socketTester.ui.isAuthenticated);
  const theme = useTheme();

  return (
    <StatusIndicatorContainer>
      <IcomoonReact
        iconSet={iconSet}
        size={15}
        icon='swap'
        color={theme.colors.lightText[0]}
      />
      {isConnected ? (
        <Text className='connection'>
          <span className='circle connected' />
          {/* Connected */}
        </Text>
      ) : (
        <Text className='connection'>
          <span className='circle disconnected' />
          {/* Disconnected */}
        </Text>
      )}
      {/* {isAuthenticated ? (
        <Text>Authenticated</Text>
      ) : (
        <Text>Not Authenticated</Text>
      )} */}
    </StatusIndicatorContainer>
  );
};

export default StatusIndicator;
