/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine,
} from 'recharts';
import Text from '../../../../shared/components/Text/Text';

const AthleteAveragesLineChart = ({ containerWidth }) => {
  const isAthleteParticipationLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteParticipationLoading);
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const activeMetricType = useSelector((state) => state.wearablesDashboard.data.activeMetricType);
  const athleteAveragesObject = useSelector((state) => state.wearablesDashboard.data.athleteAverages);
  const sevenDayAthleteAveragesArray = useSelector((state) => state.wearablesDashboard.data.sevenDayAthleteAveragesArray);
  const isAthleteAveragesLoading = useSelector((state) => state.wearablesDashboard.ui.isAthleteAveragesLoading);
  const activeAthleteInPicker = useSelector((state) => state.wearablesDashboard.data.activeAthlete);

  const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    height: 392px;
    width: ${(props) => (props.containerWidth <= 767 ? '100%' : props.containerWidth > 767 && props.containerWidth <= 911 ? '97%' : '76%')};

    
      
  `;
  const TextWrapper = styled('div')`
    margin-left: 40px;
  `;

  const LoadingShimmer = styled('div')`
    border-radius: 24px;
    height: 392px;
    width: ${(props) => (props.containerWidth <= 767 ? '100%' : props.containerWidth > 767 && props.containerWidth <= 911 ? '97%' : '76%')};

  `;

  const data = sevenDayAthleteAveragesArray;

  const defaultData = [
    {
      date: moment(new Date(activeWearablesDashDate) - 5 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(new Date(activeWearablesDashDate) - 4 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(new Date(activeWearablesDashDate) - 3 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(new Date(activeWearablesDashDate) - 2 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(new Date(activeWearablesDashDate) - 1 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(new Date(activeWearablesDashDate) - 0 * 24 * 3600 * 1000).format('MMM DD, YYYY'),
      score: 0,
    },
    {
      date: moment(activeWearablesDashDate).format('MMM DD, YYYY'),
      score: 0,
    },
  ];

  const formatData = (dataToFormat) => {
    const formatedData = dataToFormat?.map((object) => (
      {
        date: moment(object.date).format('MMM DD, YYYY'),
        // M/D/YYYY
        /** safeguard here to make sure we are not passing in a null score, which will break the graph */
        score: object.score || 0,
      }));

    return formatedData;
  };

  const caluculateYforReferenceLine = () => {
    /** dusting off algebra skills here
     * equation of a line is y = mx + b
     * the endpoint returns our slope of the line and our y intercept
     * so we know the first point on the graph for this line will be (0, yintercept)
     * we also know the slope
     * we also know the x intercept of the second point because we are only plotting 7 points,
     * therefore the x index of the second point will always be 6
     * so to find the y index of our second point we just need to plug all the known numbers into the function
     * and solve for y. This y value will be the last number we need in the reference line.
    */
    const slope = athleteAveragesObject?.lineSlope;
    const yIntersect = athleteAveragesObject?.lineIntersect;
    const xIndex = 6;
    const y = (slope * xIndex) + yIntersect;
    return y;
  };

  const getDomain = () => {
    if (activeMetricType.name === 'HRV') {
      return 100;
    }
    if (activeMetricType.name === 'Sleep') {
      return 12;
    }
    return 10;
  };

  return (
    <>
      {isAthleteAveragesLoading || isAthleteParticipationLoading ? (
        <Shimmer>
          <LoadingShimmer containerWidth={containerWidth} />
        </Shimmer>
      ) : (
        <Wrapper containerWidth={containerWidth}>
          <TextWrapper>
            {activeAthleteInPicker.id ? (
              <Text fontSize={14} fontWeight={700}>
                7 Day
                {' '}
                {activeAthleteInPicker?.name ? 'Group' : 'Athlete'}
                {' '}
                {`Average for ${activeMetricType.name} - `}
                {/* Average - */}
                {' '}
                {activeAthleteInPicker?.name || `${activeAthleteInPicker?.first} ${activeAthleteInPicker?.last}`}
              </Text>
            ) : (
              <Text fontSize={14} fontWeight={700}>7 Day Athlete Average</Text>
            )}
          </TextWrapper>
          <ResponsiveContainer
            width='98%'
            height={350}
          >
            <LineChart
              data={(!sevenDayAthleteAveragesArray?.length) ? defaultData : formatData(data)}
              margin={{
                top: 30,
                right: 20,
                left: 0,
                bottom: 30,
              }}
            >
              <CartesianGrid horizontal={false} />
              <XAxis dataKey='date' interval={0} angle={315} tick={{ fontFamily: 'Nunito Sans', fontSize: 10, fontWeight: 700 }} axisLine={false} tickLine={false} textAnchor='end' allowDecimals />
              <YAxis strokeWidth={0} domain={[0, getDomain()]} tickCount={6} tickLine={false} tick={{ fontFamily: 'Nunito Sans', fontSize: 10, fontWeight: 700 }} allowDecimals />
              {((athleteAveragesObject?.lineSlope || athleteAveragesObject?.lineSlope === 0) && (athleteAveragesObject?.lineIntersect || athleteAveragesObject?.lineIntersect === 0)) && (
              <ReferenceLine stroke='#ff6600' strokeWidth={2} strokeDasharray='0 0' segment={[{ x: formatData(data)[0].date, y: caluculateYforReferenceLine() }, { x: formatData(data)[6].date, y: athleteAveragesObject.lineIntersect }]} />
              )}
              <Line
                connectNulls
                type='linear'
                dataKey='score'
                stroke='#3571f2'
                activeDot={{ r: 8 }}
                isAnimationActive={false}
                animationEasing='linear'
                dot={{ stroke: '#3571f2', fill: '#3571f2', strokeWidth: 3 }}
                strokeWidth={2}
                label={{
                  fill: '#3571f2', fontSize: 10, dy: -15, fontWeight: 700,
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Wrapper>
      )}
    </>
  );
};

export default AthleteAveragesLineChart;
