/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import { setIsMarketingModalShowing } from '../../ducks/sportsScienceActions';
import MarketingCarousel from './MarketingCarousel';

const transitionSpeed = 0.5;

const ModalContainer = styled('div')`
	display: flex;
  z-index: 1003;
  justify-content: center;
  min-width: 660px;
  height: 90vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
  transition: all 1s ease;
  width: 50vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: white;
  margin-bottom: 85px;
  box-shadow: ${BoxShadow};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  scroll-behavior: smooth;
  .marketing-modal-next-button {
    background: #FFA500;
    color: white;
    border-radius: 8px;
    height: 32px;
    transition: 0.5 1s ease;
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .marketing-modal-cancel-button {
    border: 1px solid #FFA500;
    color: #FFA500;
    border-radius: 8px;
    height: 32px;
    background: none;
    transition: all ${transitionSpeed}s ease;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito Sans';
    cursor: pointer;
    :hover {
      opacity: 0.8;
    }
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;
  }
  .custom-form {
    padding: 30px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 80%;
    margin-top: 5px;
  }
`;

const Buttons = styled('div')`
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  box-shadow: ${BoxShadow};
`;

const MarketingModal = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isMarketingModalShowing = useSelector(
    (state) => state.sportsScience.data.isMarketingModalShowing,
  );

  const handleDragStart = (e) => e.preventDefault();

  return (
    <ModalContainer isActive={isMarketingModalShowing}>
      <div className='remove-div'>
        <div onClick={() => dispatch(setIsMarketingModalShowing(false))} className='remove-div-icon'>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <MarketingCarousel
          title='Title Slide'
          descriptionText='Body standard phasellus justo purus, venenatis a sapien eu, faucibus porttitor libero.
          Proin venenatis diam tortor, nec vulputate
          sem efficitur in. Aenean ipsum ipsum, imperdiet.'
          showButton
        />
        <Buttons>
          <div
            className='marketing-modal-next-button'
            onClick={() => {

            }}
          >
            UPGRADE
          </div>
          <div
            className='marketing-modal-cancel-button'
            onClick={() => {

            }}
          >
            BOOK A DEMO
          </div>
          <div
            className='marketing-modal-cancel-button'
            onClick={() => {

            }}
          >
            CANCEL
          </div>
        </Buttons>
      </ContentContainer>
    </ModalContainer>
  );
};

export default MarketingModal;
