import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useSpring } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';
import IcomoonReact from 'icomoon-react';

import { ColorPrimary } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const SwapButton = styled('div')`
  cursor: pointer;
  margin-right: 15px;
`;

const AnimatedSwapButton = ({
  handlePanelClose,
  namespace,
}) => {
  const isWorkoutActive = useSelector(state => state[namespace].isWorkoutActive);
  const skin = useSelector(state => state.weightRoom.currentUser.skin);

  const isAccentDarkTheme = skin && (skin.accent_dark_theme);

  const props = useSpring({
    to: { opacity: isWorkoutActive ? 1 : 0 },
    from: { opacity: 0 },
  });

  return (
    <animated.div style={props}>
      <SwapButton
        onClick={handlePanelClose}
      >
        <IcomoonReact
          color={isAccentDarkTheme ? ColorPrimary : 'white'}
          icon='swap'
          iconSet={iconSet}
          handleClick={handlePanelClose}
          size={12}
        />
      </SwapButton>
    </animated.div>
  );
};

AnimatedSwapButton.propTypes = {
  handlePanelClose: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default AnimatedSwapButton;
