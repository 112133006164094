import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';

import IconRound from './IconRound';
import Modal from './Modal';
import Spinner from './Spinner';
import ButtonRound from './ButtonRound';
import Title from './Title';

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  /* ${(props) => (
    props.activeScreens === 4
      ? 'height: calc(50vh - 137px);'
      : 'height: calc(100vh - 137px);'
  )} */
  flex: 1;
  position: relative;

  /* @media screen and (orientation: portrait) {
    ${(props) => (
    props.activeScreens <= 2 && `
      height: calc(50vh - 137px);
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${(props) => (
    props.activeScreens <= 2 && `
      height: calc(100vh - 137px);
    `
  )}
  } */
`;

const TagContainer = styled('div')`
  display: flex;
  padding-left: 30px;
  button {
    margin-top: 20px;
  }

  h2 {
    text-align: center;
  }
`;

const VideoButton = styled('div')`
  position: absolute;
  left: 270px;
  margin: 0px 5px 0px 30px;
  cursor: pointer;
  margin-top: -30px;
`;

const StyledIframe = styled('iframe')`
  border: 0;
  width: 100%;
  min-height: 420px;
`;

const TagEntries = ({
  athleteId,
  formikRef,
  fetchSimilarTypeExercises,
  index,
  namespace,
  updateTagProgramWorkout,
  updateTagWorkout,
  workout,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const exercisesSimilar = useSelector((state) => state.weightRoomView[namespace].exercisesSimilar);
  const isLoadingSimExercises = useSelector((
    state,
  ) => state.weightRoomView[namespace].isLoadingSimExercises);
  const isProgramView = useSelector((
    state,
  ) => state.weightRoomView[namespace].isProgramView);

  const [isVidModalOpen, setIsVidModalOpen] = useState(false);
  const [previewVideo, setVideoPreview] = useState('');

  useEffect(() => {
    fetchSimilarTypeExercises(namespace, accountCode, workout.tag.id);
  }, []);

  const isPreviewVideoEmpty = (previewVideo !== '') && (previewVideo !== null);

  // exercisesSimilar needs to have value and label attached
  // in order for React select to render correctly
  useEffect(() => {
    if (exercisesSimilar && exercisesSimilar.length > 0) {
      exercisesSimilar.forEach((obj) => {
        obj.label = obj.name;
        obj.value = obj.id;
      });
    }
  }, [exercisesSimilar]);

  return (
    <TagContainer>
      <Formik
        key={workout.assignedId || workout.saveDataId}
        ref={formikRef}
        initialValues={
          {
            assignedId: workout.assignedId || workout.saveDataId,
            assignedDate: moment(activeDate).format('YYYY-MM-DD'),
            userId: athleteId,
            index,
            body: {
              exerciseId: 0,
            },
          }
        }
        onSubmit={(values, actions) => {
          if (isProgramView) {
            setTimeout(() => {
              updateTagProgramWorkout(
                namespace,
                accountCode,
                currentSelectedProgram.dayBasedProgramId,
                currentNonDateDay.trueDayNum,
                values,
              );
              actions.setSubmitting(false);
            }, 1000);
          } else {
            setTimeout(() => {
              updateTagWorkout(namespace, accountCode, values);
              actions.setSubmitting(false);
            }, 1000);
          }
        }}
        render={({
          setFieldValue,
          values,
          isSubmitting,
        }) => (
          <Form>
            {isLoadingSimExercises === false ? (
              <>
                {typeof exercisesSimilar !== 'string' ? (
                  <>
                    <p>
                      Select an exercise and click &quot;Save&quot;
                    </p>
                    <Select
                      menuPortalTarget={document.body}
                      menuPlacement='auto'
                      onChange={(options) => {
                        setFieldValue('body.exerciseId', options.value);
                        if (options.media) {
                          setVideoPreview(options.media[0].mediaUrl);
                        }
                      }}
                      options={typeof exercisesSimilar !== 'string' ? exercisesSimilar : ('')}
                      value={values.body.exerciseId.value}
                    />
                    {isPreviewVideoEmpty && (
                      <VideoButton onClick={() => setIsVidModalOpen(true)}>
                        <IconRound
                          icon='video'
                          large
                        />
                      </VideoButton>
                    )}
                    <ButtonRound
                      cta='Save'
                      icon='checkmark'
                      type='submit'
                      disabled={isSubmitting}
                    />
                  </>
                ) : (
                  <Title icon='opt-out'>
                    {exercisesSimilar}
                  </Title>
                )}

              </>
            ) : (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            )}
          </Form>
        )}
      />
      <Modal
        isOpen={isVidModalOpen}
        onRequestClose={() => setIsVidModalOpen(false)}
      >
        <StyledIframe
          className='video-iframe'
          src={previewVideo}
        />
      </Modal>
    </TagContainer>
  );
};

TagEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  fetchSimilarTypeExercises: PropTypes.func.isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  namespace: PropTypes.string.isRequired,
  updateTagProgramWorkout: PropTypes.func.isRequired,
  updateTagWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
};

export default TagEntries;
