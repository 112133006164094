import { combineReducers } from 'redux';

import sportsScienceUiReducer from './sportsScienceUiReducer';
import sportsScienceDataReducer from './sportsScienceDataReducer';

const sportsScienceReducer = combineReducers({
  data: sportsScienceDataReducer,
  ui: sportsScienceUiReducer,
});

export default sportsScienceReducer;
