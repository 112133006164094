import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { useSelector, useDispatch } from 'react-redux';
import bcrypt from 'bcryptjs';

import CustomBigModal from './components/presentational/reseller/CustomBigModal';
import Button from '../../shared/components/Button/Button';
import SubText from '../../shared/components/SubText/SubText';
import ModalCreateReseller from './components/presentational/reseller/ModalCreateReseller';
import ModalCreateReseller2 from './components/presentational/reseller/ModalCreateReseller2';
import ModalCreateReseller3 from './components/presentational/reseller/ModalCreateReseller3';
import CustomCreateResellerWrapModal from './components/presentational/reseller/CustomCreateResellerWrapModal';
import CustomCreateResellerWrapModal2 from './components/presentational/reseller/CustomCreateResellerWrapModal2';
import ModalVerifyReseller from './components/presentational/reseller/ModalVerifyReseller';

import {
  setActiveReduxModal,
  createReseller,
} from './ducks/resellerActions';

const ModalHandler = () => {
  const dispatch = useDispatch();
  const handleCreateReseller = (values) => {
    dispatch(createReseller(values));
  };

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isResellerCreated = useSelector((state) => state.reseller.data.isResellerCreated);
  const isVerified = useSelector((state) => state.reseller.data.currentReseller.isVerified);

  const activeModal = useSelector((state) => state.reseller.data.activeModal);

  const salt = bcrypt.genSaltSync(10);
  const token = bcrypt.hashSync(toString(currentUser.userId), salt);

  const theme = useTheme();

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const MainContainer = styled('main')`
  `;

  const ButtonWrapper = styled('div')`
    margin-top: 50px;

    button {
      &:hover {
        opacity: .6;
        color: white;
      }
    }
  `;

  /**
   * Modal handler responds to what the activeModal hook is currently set to.
   * if the activeModal hook is set, it will display the appropriate modal
   */
  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'create_reseller_modal') {
      returnModal = (
        <CustomBigModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateReseller
            currentUser={currentUser}
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            handleCreateReseller={handleCreateReseller}
          />
        </CustomBigModal>
      );
    } else if (activeModal === 'verify_reseller_modal') {
      returnModal = (
        <CustomCreateResellerWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalVerifyReseller
            currentUser={currentUser}
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
          />
        </CustomCreateResellerWrapModal>
      );
    } else if (activeModal === 'create_reseller_modal2') {
      returnModal = (
        <CustomCreateResellerWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateReseller2
            currentUser={currentUser}
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            token={token}
          />
        </CustomCreateResellerWrapModal>
      );
    } else if (activeModal === 'create_reseller_modal3') {
      returnModal = (
        <CustomCreateResellerWrapModal2
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateReseller3 />
        </CustomCreateResellerWrapModal2>
      );
    }
    return returnModal;
  };

  return (
    <MainContainer>
      <ButtonWrapper>
        {currentUser && currentUser.primaryAdmin ? (
          <Button
            onClick={() => {
              if (!isResellerCreated) {
                dispatch(setActiveReduxModal('create_reseller_modal'));
              } else if (!isVerified) {
                dispatch(setActiveReduxModal('verify_reseller_modal'));
              } else {
                dispatch(setActiveReduxModal('create_reseller_modal2'));
              }
            }}
            cta='Create Online Payments Account'
            customColor={theme.colors.green}
            noBorder
            primary
            large
            rounded
            noHover
          />
        ) : <SubText>Please contact your account administrator to set up this service</SubText>}
      </ButtonWrapper>
      {modalHandler()}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
  setSecondConnection: PropTypes.func.isRequired,
};

export default ModalHandler;
