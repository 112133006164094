import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';

import { ColorSecondary, ColorBlack } from '../../../../shared/GlobalStyles';

const RibbonContainer = styled('div')`
  display: inline-block;
  background-color: ${props => props.theme.colorAccent};
  font-size: 12px;
  font-weight: 600;
  color: ${props => (props.theme.isAccentDarkTheme ? ColorBlack : 'white')};
  padding: 8px 15px;
  height: 35px;
  align-self: center;
`;

const Ribbon = (props) => {
  const { date } = props;
  const year = moment(date).format('YYYY');

  return (
    <RibbonContainer>
      {year}
    </RibbonContainer>
  );
};

Ribbon.propTypes = {
  date: PropTypes.string.isRequired,
};

export default Ribbon;
