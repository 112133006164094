/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';

import { BoxShadow } from '../../../../shared/GlobalStyles';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const MetricsContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px 5px;
  filter: ${(props) => (props.isBlurred ? 'blur(1.2px)' : null)};
  padding-bottom: 10px;
`;

const MetricContainer = styled('div')`
  height: 150px;
  background-color: ${(props) => props.bgColor};
  border: ${(props) => (props.highlighted ? `solid 2px ${props.borderColor}` : null)};
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  box-shadow: ${BoxShadow};
  flex: 0 0 31%;
  margin: 10px;
`;

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const TitleAthleteContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
`;

const ExerTitleContainer = styled('div')`
`;

const ExerName = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 700;
  color: #9E9E9E;
`;

const MetricTitle = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
`;

const AthleteName = styled('div')`
  text-overflow: ellipsis;
  overflow: hidden;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: 400;
  margin-top: 18px;
`;

const EndDataContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const EndDateData = styled('div')`
  height: 55px;
  display: flex;
  align-items: center;
  font-size: 41px;
  font-family: 'Nunito Sans';
  font-weight: 700;
`;

const FooterContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 19px;
  font-weight: 400;
`;

const StartDataContainer = styled('div')`
  display: flex;
`;

const StartDateData = styled('div')`
  display: flex;
  align-items: center;
  color: #9E9E9E;
`;

const GroupStartData = styled('div')`
  display: flex;
  align-items: center;
  color: #9E9E9E;
  margin-right: 10px;
`;

const AthleteStartData = styled('div')`
  display: flex;
  align-items: center;
  color: #9E9E9E;
  margin-left: 10px;
`;

const PercentageContainer = styled('div')`
  display: flex;
  align-items: center;
  svg {
    fill: ${(props) => (props.color ? props.color : '#9E9E9E')};
  }
`;

const Percentage = styled('div')`
  margin-left: 5px;
`;

const ExerciseMetricsContainers = () => {
  const isAthleteSelectedPerformance = useSelector(
    (state) => state.sportCoachDashboard.ui.isAthleteSelectedPerformance,
  );
  const cardData = useSelector((state) => state.sportCoachDashboard.data.cardData);
  const exerciseFiltersPerformance = useSelector(
    (state) => state.sportCoachDashboard.data.exerciseFiltersPerformance,
  );
  const exercises = useSelector((state) => state.sportCoachDashboard.data.exercises);
  const selectedAthletePerformance = useSelector(
    (state) => state.sportCoachDashboard.data.selectedAthletePerformance,
  );

  const hasData = cardData.length > 0;

  const numPlaceholder = hasData ? '-' : '000';
  const percentPlaceholder = hasData ? '-%' : '0.00%';

  const colors = ['#1787FB', '#0DCC8A', '#FFCB47'];

  const theme = useTheme();

  const getCardDataIndex = (exer) => {
    const idx = cardData && cardData.findIndex((item) => item.metricName === exer.name);
    if (idx !== -1) {
      return idx;
    }

    return null;
  };

  const getAthleteIndex = (cardIdx) => {
    const idx = selectedAthletePerformance && cardData[cardIdx]?.athleteData.findIndex((
      athlete,
    ) => athlete.userId === selectedAthletePerformance.userId);

    if (idx !== -1) {
      return idx;
    }

    return null;
  };

  const getColorForExercise = (exerId) => {
    const index = exerciseFiltersPerformance.findIndex((exercise) => exercise.id === exerId);

    return index !== -1 ? colors[index % colors.length] : null;
  };

  return (
    <MetricsContainer isBlurred={!hasData}>
      {exercises.map((exer) => {
        const idx = getCardDataIndex(exer);
        const athleteIdx = getAthleteIndex(idx);
        const highlightColor = getColorForExercise(exer.id);
        return (
          <MetricContainer
            key={exer.id}
            highlighted={highlightColor !== null}
            borderColor={highlightColor}
            bgColor={theme.colors.background[0]}
          >
            {/* Metric card split with athlete data */}
            {isAthleteSelectedPerformance ? (
              <>
                <TitleAthleteContainer>
                  <ExerTitleContainer>
                    <ExerName>
                      {exer.name}
                    </ExerName>
                    <MetricTitle>
                      Group Average
                    </MetricTitle>
                  </ExerTitleContainer>
                  <AthleteName>
                    {selectedAthletePerformance?.fullName}
                  </AthleteName>
                </TitleAthleteContainer>
                <EndDataContainer>
                  <EndDateData>
                    {cardData[idx]?.newestAverage || numPlaceholder}
                  </EndDateData>
                  <EndDateData>
                    {cardData[idx]?.athleteData[athleteIdx]?.newestMax || numPlaceholder}
                  </EndDateData>
                </EndDataContainer>
                <FooterContainer>
                  <StartDataContainer>
                    <GroupStartData>
                      {cardData[idx]?.oldestAverage || numPlaceholder}
                    </GroupStartData>
                    <PercentageContainer color={cardData[idx]?.color || null}>
                      <IcomoonReact
                        iconSet={iconSet}
                        size={13}
                        icon={
                          cardData[idx]?.percentChange > 0 ? 'alt-up-arrow'
                            : cardData[idx]?.percentChange < 0 ? 'alt-down-arrow'
                              : 'alt-right-arrow'
                        }
                      />
                      <Percentage>
                        {cardData[idx] ? `${cardData[idx].percentChange}%` : percentPlaceholder}
                      </Percentage>
                    </PercentageContainer>
                  </StartDataContainer>
                  <StartDataContainer>
                    <PercentageContainer
                      color={cardData[idx]?.athleteData[athleteIdx]?.color || null}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={13}
                        icon={
                          cardData[idx]?.athleteData[athleteIdx]?.percentChange > 0 ? 'alt-up-arrow'
                            : cardData[idx]?.athleteData[athleteIdx]?.percentChange < 0 ? 'alt-down-arrow'
                              : 'alt-right-arrow'
                        }
                      />
                      <Percentage>
                        {cardData[idx]?.athleteData[athleteIdx]
                          ? `${cardData[idx].athleteData[athleteIdx].percentChange}%`
                          : percentPlaceholder}
                      </Percentage>
                    </PercentageContainer>
                    <AthleteStartData>
                      {cardData[idx]?.athleteData[athleteIdx]?.oldestMax || numPlaceholder}
                    </AthleteStartData>
                  </StartDataContainer>
                </FooterContainer>
              </>
            ) : (
            // Metric card with only group data
              <>
                <TitleContainer>
                  <ExerName>
                    {exer.name}
                  </ExerName>
                  <MetricTitle>
                    Group Average
                  </MetricTitle>
                </TitleContainer>
                <EndDateData>
                  {cardData[idx]?.newestAverage || numPlaceholder}
                </EndDateData>
                <FooterContainer>
                  <StartDateData>
                    {cardData[idx]?.oldestAverage || numPlaceholder}
                  </StartDateData>
                  <PercentageContainer color={cardData[idx]?.color || null}>
                    <IcomoonReact
                      iconSet={iconSet}
                      size={13}
                      icon={
                        cardData[idx]?.percentChange > 0 ? 'alt-up-arrow'
                          : cardData[idx]?.percentChange < 0 ? 'alt-down-arrow'
                            : 'alt-right-arrow'
                      }
                    />
                    <Percentage>
                      {cardData[idx] ? `${cardData[idx].percentChange}%` : percentPlaceholder}
                    </Percentage>
                  </PercentageContainer>
                </FooterContainer>
              </>
            )}
          </MetricContainer>
        );
      })}
    </MetricsContainer>
  );
};

export default ExerciseMetricsContainers;
