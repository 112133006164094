/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import AttachmentsButton from './modals/AttachmentsButton';

import {
  setJournalEntryWorkout,
  createMeJournalEntry,
  writeWorkoutNoteToAthlete,
  setUploadWorkoutMediaFalse,
  setRemoveWorkoutJournalMediaArray,
  setActiveWorkoutsMedia,
  updateJournalEntry,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1002;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 40vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  .opt-out-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  .custom-form {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 80%;
    margin-top: 5px;
  }
`;

const StyleContainer = styled('div')`
  width: 100%;
  .submit-btn {
    background: #0dcc8a;
    border-style: none;
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
  .submit-btn:hover {
    background-color: rgba(9, 194, 123);
  }
  .sub-text{
    font-family: 'Nunito Sans';
    font-size: 12px;
    font-weight: 900;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }
`;

const MediaBox = styled('div')`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  .media-x-icon {
    position: absolute;
    top: -7px;
    right: -4px;
    padding: 4px;
    background: #d9d9d9;
    border-radius: 50%;
    pointer-events: stroke;
  }
  .play-icon {
    padding: 10px;
    background-color: #ffffff73;
    border-radius: 50%;
  }
  .open-media-div {
    color: #ffffff03;
    background: #ffffff05;
    width: 40px;
    height: 40px;
  }
`;

const StyledInput = styled('input')`
	font-family: 'Nunito Sans';
	font-weight: 700;
	font-size: 13px;
	border: 1px solid #E8E8E8;
  width: 100%;
  border-radius: 5px;
  height: 38px;
  padding-left: 10px;
  background: #F8F8F8;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const MediaBoxContainer = styled('div')`
  display: flex;
  align-items: center;
`;

/**
 *
 * @returns this is a modal that I repurposed from Kristy's journal
 * endpoint.  It's not quite as complex because it doesn't need to respond
 * to new entries - the submission is sent to the journal endpoint and that's that
 *
 */
const JournalEntryModal = ({ journalEntryWorkout }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const accountCode = currentUser ? currentUser.accountCode : 0;
  const uploadedWorkoutJournalMedia = useSelector(
    (state) => state.workouts.data.uploadedWorkoutJournalMedia,
  );
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const uploadedWorkoutJournalMediaExtended = useSelector(
    (state) => state.workouts.data.uploadedWorkoutJournalMediaExtended,
  );
  const removeWorkoutJournalMediaArray = useSelector(
    (state) => state.workouts.data.removeWorkoutJournalMediaArray,
  );

  const [isSubmitting, setSubmitting] = useState();
  const [body, setBody] = useState('');

  const updateArray = [...uploadedWorkoutJournalMedia];
  const updateArrayToSubmit = updateArray.filter(
    (mediaId) => !removeWorkoutJournalMediaArray.includes(mediaId),
  );
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  useEffect(() => {
    setBody(journalEntryWorkout?.workoutObject?.journalEntries[0]?.body || '');
  }, [journalEntryWorkout]);

  /**
 * this is a function that 'deletes' media from the array of media that's been uploaded
 * 'deletes' is in quotes because it doesn't actually remove anything - but later in the
 * component we're keeping track of which items exist in the 'deleted' array and
 * selectively rendering the pieces of media whose keys do not exist in the array.
 * Shoutout to Kristy for that one because it's super clever
 * @param {Object} event normal function event passed from the onClick handler
 */
  const handleDeleteWorkoutMedia = (event) => {
    const mediaId = event.target.id;
    dispatch(setRemoveWorkoutJournalMediaArray(mediaId));
  };

  // just a little semantic function for determining where to get the image source
  const imageFlipper = (inputMedia) => {
    if (inputMedia.thumbnailKey) {
      return inputMedia.thumbnailUrl;
    }
    return inputMedia.location;
  };

  const submitFunction = () => {
    const submittingValues = { };
    setSubmitting(true);
    submittingValues.body = body || '';
    submittingValues.title = journalEntryWorkout?.workoutObject?.exercise?.name || '';
    submittingValues.media = updateArrayToSubmit;
    submittingValues.private = false;
    if (!Object.keys(currentNonDateDay).length) {
      submittingValues.dateAssigned = activeWorkoutDate;
    }
    if (journalEntryWorkout?.workoutObject?.assignedId) {
      submittingValues.associatedWorkoutAssignedId = journalEntryWorkout?.workoutObject?.assignedId;
    } else if (journalEntryWorkout?.workoutObject?.saveDataId) {
      submittingValues.associatedDayBasedWorkoutId = currentSelectedProgram.dayBasedProgramId;
      submittingValues.associatedDayBasedDayNum = currentNonDateDay.trueDayNum;
      submittingValues.associatedSaveDataId = journalEntryWorkout?.workoutObject?.saveDataId;
    }
    if (currentUser.admin) {
      submittingValues.userId = sharedAthlete.id;
      submittingValues.private = false;
      setSubmitting(false);
      if (
        journalEntryWorkout?.workoutObject?.journalEntries?.length && journalEntryWorkout?.workoutObject?.journalEntries[0].id
      ) {
        dispatch(updateJournalEntry(
          accountCode,
          submittingValues,
          journalEntryWorkout?.workoutObject?.journalEntries[0].id,
          journalEntryWorkout.index,
          journalEntryWorkout.sessionIndex,
        ));
      } else {
        dispatch(writeWorkoutNoteToAthlete(
          accountCode,
          submittingValues,
          journalEntryWorkout.index,
          journalEntryWorkout.sessionIndex,
          activeWorkoutDate,
        ));
      }
    } else {
      submittingValues.userId = currentUser.id;
      setSubmitting(false);
      if (journalEntryWorkout?.workoutObject?.journalEntries?.length && journalEntryWorkout?.workoutObject?.journalEntries[0].id) {
        dispatch(updateJournalEntry(
          accountCode,
          submittingValues,
          journalEntryWorkout?.workoutObject?.journalEntries[0].id,
          journalEntryWorkout.index,
          journalEntryWorkout.sessionIndex,
        ));
      } else {
        dispatch(createMeJournalEntry(
          accountCode, submittingValues,
          journalEntryWorkout.index,
          journalEntryWorkout.sessionIndex,
        ));
      }
    }
    dispatch(setUploadWorkoutMediaFalse());
    dispatch(setJournalEntryWorkout({}));
  };

  const titleExtractor = (object) => {
    if (object?.workoutObject?.exercise?.name) {
      return object?.workoutObject?.exercise?.name;
    } if (object?.workoutObject?.title) {
      return object?.workoutObject?.title;
    }
    return '';
  };

  return (
    <ModalContainer isActive={Object.keys(journalEntryWorkout).length}>
      <div className='remove-div'>
        <ModalTextTop>New Journal Entry</ModalTextTop>
        <div className='remove-div-icon' onClick={() => dispatch(setJournalEntryWorkout({}))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <StyleContainer>
          <form className='custom-form' onSubmit={submitFunction}>
            <Form.Group controlId='formTitle'>
              <Form.Label className='sub-text'>Title :</Form.Label>
              <StyledInput
                type='text'
                name='title'
                disabled
                value={titleExtractor(journalEntryWorkout)}
              />
            </Form.Group>
            <Form.Group controlId='formBody'>
              <Form.Label className='sub-text'>Text :</Form.Label>
              <Form.Control
                as='textarea'
                rows={5}
                type='text'
                name='body'
                placeholder=''
                onChange={(e) => {
                  setBody(e.target.value);
                }}
                value={body}
              />
            </Form.Group>
            <MediaBoxContainer>
              <AttachmentsButton />
              {uploadedWorkoutJournalMediaExtended.length ? (
                uploadedWorkoutJournalMediaExtended.map((media) => (
                  <MediaBox
                    key={media.id}
                    style={{
                      backgroundImage: `url(${media && media.thumbnailUrl
                        ? imageFlipper(media) : ''})`,
                      display: removeWorkoutJournalMediaArray.includes(media.id) ? 'none' : null,
                    }}
                  >
                    {media.type === 'IMAGE' || media.type === 'EXTERNAL_URL' ? (
                      <div
                        className='open-media-div'
                        onClick={() => {
                          dispatch(setActiveWorkoutsMedia(media));
                        }}
                      >
                        open image
                      </div>
                    ) : null }
                    <IcomoonReact
                      id={media.id}
                      onClick={handleDeleteWorkoutMedia}
                      className='media-x-icon'
                      iconSet={iconSet}
                      size={20}
                      icon='remove'
                      color='#999'
                    />
                    {media.type === 'VIDEO' ? (
                      <div
                        onClick={() => {
                          dispatch(setActiveWorkoutsMedia(media));
                        }}
                      >
                        <IcomoonReact
                          className='play-icon'
                          iconSet={iconSet}
                          size={40}
                          icon='play-filled'
                          color='white'
                        />
                      </div>
                    ) : null}
                  </MediaBox>
                ))
              ) : null}
            </MediaBoxContainer>
          </form>
        </StyleContainer>
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='submit'
        className='opt-out-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          submitFunction();
        }}
        disabled={isSubmitting}
      />
    </ModalContainer>
  );
};

JournalEntryModal.propTypes = {
  journalEntryWorkout: PropTypes.instanceOf(Object).isRequired,
};

export default JournalEntryModal;
