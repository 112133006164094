import React, { Fragment } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';

import { BorderRadius, BoxShadow, TransitionGlobal } from '../../../shared/GlobalStyles';

const ModalStyle = {
  overlay: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(216, 216, 216, 0.5)',
    alignItems: 'center',
    position: 'fixed',
    zIndex: '100',
    top: '0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '530px',
    maxWidth: '600px',
    backgroundColor: 'white',
    borderRadius: `${BorderRadius}`,
    boxShadow: `${BoxShadow}`,
    overflow: 'auto',
  },
};

const Modal = (props) => {
  const { children, isOpen } = props;

  const modalClose = () => {
    const { onRequestClose } = props;
    onRequestClose();
  };

  return (
    <>
      <Global
        styles={css`
          .ReactModal__Content {
            opacity: 0;
            transform: translateY(50%);
          }

          .ReactModal__Overlay {
            // background-color: ${isDarkThemeEnabled ? 'rgba(30, 30, 30, 0.7)' : 'rgba(216, 216, 216, 0.5)'}!important;
            opacity: 0;
            z-index: 1000!important;
            justify-content: center;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
            transition: ${TransitionGlobal};
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          .ReactModal__Content--after-open {
            opacity: 1;
            transform: translateY(0);
            transition: ${TransitionGlobal};
          }

          .ReactModal__Content--before-close {
            opacity: 0;
            transform: translateY(50%);
          }
          @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            .ReactModal__Content {
              width: 75vw!important;
            }
          }
        `}
      />
      <ReactModal
        closeTimeoutMS={250}
        isOpen={isOpen}
        onRequestClose={modalClose}
        style={ModalStyle}
        className='wrv-modal'
        overlayClassName='overlay'
      >
        {children}
      </ReactModal>
    </>
  );
};

ReactModal.setAppElement('#app');

Modal.propTypes = {
  children: PropTypes.node,
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

Modal.defaultProps = {
  children: '',
};

export default Modal;
