/* eslint-disable import/prefer-default-export */
const tabs = ['Latest Session', 'Last 7 Days', 'Last 30 Days'];

/** General Settings Side Bar Nav Link info */
const generalSettingsSideBarNavArrayOfObjects = [
  { routeName: '/settings', text: 'General', icon: 'user' },
  { routeName: '/settings/account', text: 'Account Settings', icon: 'user' },
  { routeName: '/settings/organization', text: 'Organization Settings', icon: 'group-alt' },
  { routeName: '/settings/subscriptions', text: 'Subscriptions', icon: 'credit-card' },
  { routeName: '/settings/tactical', text: 'Enhanced Security (Tactical) Settings', icon: 'lock' },
  { routeName: '/settings/integrations', text: 'Integrations', icon: 'swap' },
];

export {
  tabs,
  generalSettingsSideBarNavArrayOfObjects,
};
