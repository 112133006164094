/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import SharedSingleDayQuestionnaireGrid from '../../../../shared/components/SportsHub/SharedSingleDayQuestionnaireGrid';
import SharedMuliDayQuestionnaireGrid from '../../../../shared/components/SportsHub/SharedMultiDayQuestionnaireGrid';
import DownloadReportButton from './DownloadReportButton';
import {
  setCurrentQuestionInQuestionnairePicker,
  setCurrentDateInDashDatePicker,
  setCurrentGroupInGroupPicker,
  setCurrentAthleteInAthletePicker,
  fetchQuestionnaireReportSingleDay,
  fetchLoadMonitoringLineChartData,
  fetchQuestionnaireReportMultiDay,
  setAthletesInClickedZone,
} from '../../ducks/loadMonitoringDashboardActions';
import PickerAndDonutChart from './PickerAndDonutChart';
import Picker from '../../../../shared/components/SportsHub/Picker';
import TBDatePicker from '../../../../shared/components/TBDatePicker/TBDatePicker';
import Text from '../../../../shared/components/Text/Text';
import LoadMonitoringComposedChart from './LoadMonitoringComposedChart';
import SpiderChart from './SpiderChart';
import CheckboxFilterComponent from './CheckboxFilterComponent';
import WarningComponent from './WarningComponent';

const MainContainer = styled('main')`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.containerWidth < 424 ? '15px' : '0px 25px')};
  width: 100%;
`;

const GraphAndPickersContainer = styled('div')`
display: flex;
flex-direction: column;
// background: ${(props) => (props.containerWidth < 424 ? 'white' : '#F5F3F3')};
background: ${(props) => (props.containerWidth < 424 ? '#F5F3F3' : '#F5F3F3')};
border-radius: 20px;
margin-bottom: 30px;
// padding: ${(props) => (props.containerWidth < 424 ? '0px' : '20px')};
gap: 20px;
`;

const PickerHeaderRow = styled('div')`
display: flex;
flex-direction: row;
gap: 20px;
align-items: center;
flex-wrap: wrap;
`;

const PickerHeaderSubContainerRow = styled('div')`
display: flex;
flex-direction: row;
background: #FFFFFF;
box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
border-radius: 24px;
width: ${(props) => props.containerWidth < 575 && '100%'};
max-width: ${(props) => ((props.containerWidth < 1035 && props.containerWidth > 575) ? '240px' : 'max-content')};
padding: ${(props) => (props.containerWidth < 301 ? '15px' : props.containerWidth > 301 && props.containerWidth < 1035 ? '25px' : '10px 25px')};
align-items: center;
gap: ${(props) => (props.containerWidth < 1035 ? '30px' : '15px')};
flex-wrap: wrap;
flex-grow: 1;
`;

const AthletePickerAndSelectedQuestionsSubRow = styled('div')`
display: flex;
flex-direction: row;
align-items: center;
gap: 30px;
flex-wrap: wrap;
`;

const PickerAndDescriptionContainer = styled('div')`
display: flex;
flex-direction: ${(props) => (props.containerWidth < 414 ? 'column' : 'row')};
align-items: ${(props) => (props.containerWidth >= 414 && 'center')};
gap: ${(props) => (props.containerWidth < 424 ? '0px' : '15px')};
flex-wrap: wrap;
width : ${(props) => props.containerWidth < 1035 && '100%'};
justify-content: ${(props) => props.containerWidth < 1035 && 'space-between'};
`;

const RowWrapper = styled('div')`
display: flex;
flex-direction: row;
gap: 40px;
flex-wrap: ${(props) => (props.containerWidth < 850 && 'wrap')};
padding-bottom: 20px;

`;

const BodyRow = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
  height: max-content;
  overflow-x: ${(props) => (props.containerWidth < 510 ? 'scroll' : 'hidden')};
  background: #F5F3F3;
`;

const ChartRow = styled('div')`
display: flex;
flex-direction: row;
gap: 20px;
`;

const RelativelyPositionedRowWrapperForScrollIndicator = styled('div')`
display: flex;
flex-direction: row;
height: max-content;
position: relative;
height: max-content;
overflow-x: hidden;
`;

const GridTitleRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
`;

const GridLoadingShimmerPlaceHolder = styled('div')`
  display: flex;
  flex-direction: row;
  height: 400px;
  width: 100vw;
  border-radius: 20px;
`;

const PlaceHolderForAthletePickerShimmerLoading = styled('div')`
min-width: 150px;
height: 21px;
border-radius: 12px;
padding: 8px;
background-color: #FF6600;
box-shadow: 0px 0px 1px rgba(48,49,51,0.05), 0px 4px 8px rgba(48,49,51,0.1);
`;

const PickerDonutChartAndDownloadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: ${(props) => (props.containerWidth >= 850 && props.columnHeaderHeight && `${props.columnHeaderHeight}px`)};
  width: ${(props) => (props.containerWidth < 850 && '100%')};
`;

const BadgeButton = styled('button')`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  border: none;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(48,49,51,0.05), 0px 4px 8px rgba(48,49,51,0.1);
  display: flex;
  max-width: fit-content;
  align-items: center;
  gap: 15px;
`;

const TitleAndBadgeContainer = styled('div')`
  display: flex;
  gap: ${(props) => (props.isAthlete ? '0px' : '15px')};
  flex-wrap: wrap;
  flex-direction: ${(props) => (props.isAthlete ? 'column' : 'row')};
`;

const GeneratedDataPage = ({
  containerWidth,
  setActivePage,
  formProps,
}) => {
  const dispatch = useDispatch();
  const gridRowRef = useRef(null);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { accountCode } = currentUser;
  const isLoadingQuestionnaireReportSingleDay = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportSingleDay);
  const isLoadingQuestionnaireReportMultiday = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingQuestionnaireReportMultiday);
  const athletesAssociatedWithCurrentGroup = useSelector((state) => state.loadMonitoringDashboard.data?.athletesAssociatedWithCurrentGroup);

  const lineChartData = useSelector((state) => state.loadMonitoringDashboard.data.loadMonitoringLineChartData);

  const athletesInClickedZone = useSelector((state) => state.loadMonitoringDashboard.data.athletesInClickedZone);
  const currentQuestionInDonutChartQuestionnairePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentQuestionInDonutChartQuestionnairePicker);
  const thresholdFilterColor = athletesInClickedZone.length && athletesInClickedZone[0].questions.filter((question) => question.id === currentQuestionInDonutChartQuestionnairePicker.id)[0].response.backgroundColor;

  /** state to track for dash change Api calls */
  const currentDateInDashDatePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentDateInDashDatePicker);
  const currentGroupInGroupPicker = useSelector((state) => state.loadMonitoringDashboard.data.currentGroupInGroupPicker);
  const currentAthleteInAthletePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentAthleteInAthletePicker);
  const currentReportType = useSelector((state) => state.loadMonitoringDashboard.data.currentReportType);
  const reportableGroups = useSelector((state) => state.loadMonitoringDashboard.data.reportableGroups);
  /** props to pass to grids */
  const dataRows = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.dataRows);
  const filteredDataRows = dataRows?.filter((athlete) => athletesInClickedZone.indexOf(athlete) !== -1);

  const teamAverageTotals = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.teamAverageTotals);
  const formResponses = useSelector((state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues);
  const showAverages = formResponses?.showRangeAverages;
  const showTotals = formResponses?.showTotal;
  const apiUnformatedData = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.data);

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);

  const [rowIsScrollable, setIsRowScrollable] = useState(false);

  const handleScroll = () => {
    /** targetting the gird row container div scroll properties */
    const scrollWidth = gridRowRef.current?.scrollWidth;
    const offsetWidth = gridRowRef.current?.offsetWidth;
    const scrollLeft = gridRowRef.current?.scrollLeft;
    setIsRowScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (gridRowRef.current) {
      const scrollWidth = gridRowRef.current?.scrollWidth;
      const offsetWidth = gridRowRef.current?.offsetWidth;
      const scrollLeft = gridRowRef.current?.scrollLeft;
      setIsRowScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, [containerWidth]);

  const formattedListOfGroupsForGroupPicker = reportableGroups?.map((group) => {
    const obj = { ...group, displayText: group.name };
    return obj;
  });

  const formattedListOfAthletesForAthletePicker = athletesAssociatedWithCurrentGroup?.map((athlete) => {
    const obj = { ...athlete, displayText: athlete.name, isAthlete: true };
    return obj;
  });

  const formattedCurrentGroupInGroupPicker = { ...currentGroupInGroupPicker, displayText: currentGroupInGroupPicker.name };

  const formattedListForSelectedQuestionsPicker = [
    { displayText: 'All Questions From Selected Questionnaire', id: 'selectAllQuestions' },
    { displayText: 'Individual Questions', id: 'selectIndividualQuestions' },
    // { displayText: 'Previously Saved Report', id: 'previouslySavedReport' },
  ];

  const getVisibleContainerItemForSelectedQuestionsPicker = () => {
    if (formResponses?.selectionType === 'previouslySavedReport') {
      console.log('previouslySavedReport');
    } else if (formResponses?.selectionType === 'selectIndividualQuestions') {
      return 'Individual Questions';
    } else {
      return 'All Questions From Selected Questionnaire';
    }
  };

  /** any time the user changes the date, changes the group, or changes the athlete, we need
   * to make another call to the one of the grid APIs to get the updated data
   * to do this we will set up a function called handleGridAPICall()=> this function will check if the current athlete in the picker
   * if the current athlete in the picker is a group, then it will make a call to the single day grid
   * if the current ahtlete in the picker is actually an athlete, then it will make a call to the multiday grid
   * athletes will have a userId, and groups will just have an id
   * group fiter value is 2, athlete filter value is 1
  */
  const handleGridAPICall = (item, infoForAPICall) => {
    /** set filterType and filterIds based on item being passed */
    const lineChartValues = infoForAPICall;

    if (item?.isAthlete) {
      /** get start and end date for 7 days prior to date in date picker */
      const dateInPicker = infoForAPICall.date.date;
      const sevenDaysBeforeDateInPicker = new Date(new Date(dateInPicker).setDate(new Date(dateInPicker).getDate() - 6));

      const dateObjectForSevenDaysBefore = {
        date: sevenDaysBeforeDateInPicker,
        queryFormat: moment(sevenDaysBeforeDateInPicker).format('YYYY-MM-DD'),
        // queryFormat: sevenDaysBeforeDateInPicker.toISOString().slice(0, 10),
      };

      /** filter types to pass to endpoint */
      const filterType = 1;
      const filterIds = [item.userId];

      /** values to pass to endpoint */
      const values = {
        selectionType: infoForAPICall.selectionType,
        startDate: dateObjectForSevenDaysBefore,
        endDate: infoForAPICall.date,
        questionnaire: infoForAPICall.questionnaire,
        arrayOfQuestions: infoForAPICall.arrayOfQuestions,
        showRangeAverages: infoForAPICall.showRangeAverages,
        showTotal: infoForAPICall.showTotal,
      };

      dispatch(fetchQuestionnaireReportMultiDay(accountCode, filterType, filterIds, values));
      /** everytime we make a call to the grid api with changes we also need to
       * make a call to the line chart to reflect those chages
       */
      dispatch(fetchLoadMonitoringLineChartData(accountCode, filterType, filterIds, lineChartValues));
    } else {
      const filterType = 2;
      const filterIds = [item.id];
      const values = infoForAPICall;
      dispatch(fetchQuestionnaireReportSingleDay(accountCode, filterType, filterIds, values));
      /** everytime we make a call to the grid api with changes we also need to
       * make a call to the line chart to reflect those chages
       */
      dispatch(fetchLoadMonitoringLineChartData(accountCode, filterType, filterIds, lineChartValues));
    }
  };

  const setActiveDate = (date) => {
    dispatch(setCurrentDateInDashDatePicker({
      date,
      queryFormat: moment(date).format('YYYY-MM-DD'),
      // queryFormat: date.toISOString().slice(0, 10),
    }));
    const infoForAPICall = {
      selectionType: formResponses.selectionType,
      date: {
        date,
        queryFormat: moment(date).format('YYYY-MM-DD'),
        // queryFormat: date.toISOString().slice(0, 10),
      },
      questionnaire: formResponses.questionnaire,
      arrayOfQuestions: formResponses.arrayOfQuestions,
      showRangeAverages: showAverages,
      showTotal: showTotals,
    };
    if (reportableGroups?.length) {
      handleGridAPICall(currentAthleteInAthletePicker, infoForAPICall);
    }
  };

  const functionForAthletePicker = (item) => {
    dispatch(setCurrentAthleteInAthletePicker(item));
    const infoForAPICall = {
      selectionType: formResponses.selectionType,
      date: currentDateInDashDatePicker,
      questionnaire: formResponses.questionnaire,
      arrayOfQuestions: formResponses.arrayOfQuestions,
      showRangeAverages: showAverages,
      showTotal: showTotals,
    };
    handleGridAPICall(item, infoForAPICall);
  };

  const functionForGroupPicker = (item) => {
    dispatch(setCurrentGroupInGroupPicker(item));
    dispatch(setCurrentAthleteInAthletePicker(item));

    const infoForAPICall = {
      selectionType: formResponses.selectionType,
      date: currentDateInDashDatePicker,
      questionnaire: formResponses.questionnaire,
      arrayOfQuestions: formResponses.arrayOfQuestions,
      showRangeAverages: showAverages,
      showTotal: showTotals,
    };
    handleGridAPICall(item, infoForAPICall);
  };

  const functionForSelectedQuestionsPicker = (item) => {
    if (item.id === 'selectIndividualQuestions') {
      formProps.resetForm();
      formProps.setFieldValue('selectionType', 'selectIndividualQuestions');
      formProps.setErrors({});
      formProps.setTouched({});
      setActivePage(2);
    }
    if (item.id === 'selectAllQuestions') {
      formProps.resetForm();
      formProps.setFieldValue('selectionType', 'selectAllQuestions');
      formProps.setErrors({});
      formProps.setTouched({});
      setActivePage(2);
    }
    if (item.id === 'previouslySavedReport') {
      // formProps.resetForm();
      // formProps.setFieldValue('selectionType', 'previouslySavedReport');
      console.log('need to wire up logic for previously saved reports');
      // formProps.setErrors({});
      // formProps.setTouched({});
      // setActivePage(2);
    }
  };

  /** clear the threshold filter everytime the user manipulates a data point */
  useEffect(() => {
    dispatch(setAthletesInClickedZone([]));
  }, [currentQuestionInDonutChartQuestionnairePicker, currentGroupInGroupPicker, currentAthleteInAthletePicker, currentDateInDashDatePicker]);

  return (
    <MainContainer containerWidth={containerWidth}>
      {containerWidth < 1035 ? (
        <GraphAndPickersContainer containerWidth={containerWidth}>
          <PickerHeaderRow style={{ flexWrap: containerWidth < 575 ? 'wrap' : 'nowrap' }}>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '20px', height: containerWidth > 574 ? '100%' : 'fit-content',
            }}
            >
              <TBDatePicker activeDate={currentDateInDashDatePicker?.date} setActiveDate={setActiveDate} additionalStyles={{ justifyContent: 'flex-start', margin: '0px', maxWidth: '240px' }} />
              {reportableGroups.length ? (
                <PickerHeaderSubContainerRow containerWidth={containerWidth}>
                  <PickerAndDescriptionContainer containerWidth={containerWidth} style={{ borderRight: containerWidth < 1035 ? 'none' : '2px solid #D9D9D9', paddingRight: containerWidth < 1035 ? '0px' : '15px' }}>
                    <Text fontSize='15px' fontWeight={700}> Group:</Text>
                    <Picker list={formattedListOfGroupsForGroupPicker} reduxFunction={functionForGroupPicker} visibleContainerItem={currentGroupInGroupPicker?.displayText} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' minWidth={190} height={34} itemPickerContainerMaxWidth={containerWidth < 414 && 200} absoluteLeftPositioningOfItemPickerContainer={(containerWidth < 575 && containerWidth >= 414) && -100} addSearchBar />
                  </PickerAndDescriptionContainer>
                  <AthletePickerAndSelectedQuestionsSubRow>
                    <PickerAndDescriptionContainer containerWidth={containerWidth}>
                      <Text fontSize='15px' fontWeight={700}> Currently Viewing:</Text>
                      {isLoadingQuestionnaireReportMultiday || isLoadingQuestionnaireReportSingleDay ? (
                        <Shimmer>
                          <PlaceHolderForAthletePickerShimmerLoading />
                        </Shimmer>
                      ) : (
                        <Picker list={[{ ...formattedCurrentGroupInGroupPicker }, ...formattedListOfAthletesForAthletePicker]} reduxFunction={functionForAthletePicker} visibleContainerItem={currentAthleteInAthletePicker?.displayText} icon={{ name: 'swap', size: 16, color: '#444444' }} pickerButtonColor='#EEEEEE' pickerFontColor='#444444' small minWidth={150} itemPickerContainerMaxWidth={containerWidth < 420 && 200} absoluteLeftPositioningOfItemPickerContainer={(containerWidth < 575 && containerWidth >= 414) && -100} addSearchBar />
                      )}
                    </PickerAndDescriptionContainer>
                    <PickerAndDescriptionContainer containerWidth={containerWidth}>
                      <Text fontSize='15px' fontWeight={700}> Selected Questions:</Text>
                      <Picker list={formattedListForSelectedQuestionsPicker} reduxFunction={functionForSelectedQuestionsPicker} visibleContainerItem={getVisibleContainerItemForSelectedQuestionsPicker()} icon={{ name: 'swap', size: 16, color: '#444444' }} pickerButtonColor='#EEEEEE' pickerFontColor='#444444' small minWidth={150} itemPickerContainerMaxWidth={containerWidth < 420 && 200} absoluteLeftPositioningOfItemPickerContainer={(containerWidth < 575 && containerWidth >= 414) && -100} />
                    </PickerAndDescriptionContainer>
                  </AthletePickerAndSelectedQuestionsSubRow>
                </PickerHeaderSubContainerRow>
              ) : (
                <WarningComponent containerWidth={containerWidth} message='Oops! Something went wrong. We were not able to find any reportable groups. Please check the status of your user groups and make sure reporting is enabled.' />
              )}
            </div>
            <SpiderChart maxHeight='500px' alignSelf='flex-end' containerWidth={containerWidth} />
          </PickerHeaderRow>
          <ChartRow style={{ flexWrap: containerWidth < 768 ? 'wrap' : 'nowrap' }}>
            <LoadMonitoringComposedChart containerWidth={containerWidth} lineChartData={lineChartData} />
            <CheckboxFilterComponent containerWidth={containerWidth} renderTooltip={!(containerWidth < 768)} lineChartData={lineChartData || []} />
          </ChartRow>
        </GraphAndPickersContainer>
      ) : (
        <>
          <GraphAndPickersContainer containerWidth={containerWidth}>
            <PickerHeaderRow>
              <TBDatePicker activeDate={currentDateInDashDatePicker?.date} setActiveDate={setActiveDate} additionalStyles={{ justifyContent: 'flex-start', margin: '0px', maxWidth: '240px' }} />
              {reportableGroups.length ? (
                <PickerHeaderSubContainerRow containerWidth={containerWidth}>
                  <PickerAndDescriptionContainer containerWidth={containerWidth} style={{ borderRight: containerWidth < 1035 ? 'none' : '2px solid #D9D9D9', paddingRight: containerWidth < 1035 ? '0px' : '15px' }}>
                    <Text fontSize='15px' fontWeight={700}> Group:</Text>
                    <Picker list={formattedListOfGroupsForGroupPicker} reduxFunction={functionForGroupPicker} visibleContainerItem={currentGroupInGroupPicker?.displayText} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' minWidth={190} height={34} addSearchBar />
                  </PickerAndDescriptionContainer>
                  <AthletePickerAndSelectedQuestionsSubRow>
                    <PickerAndDescriptionContainer containerWidth={containerWidth}>
                      <Text fontSize='15px' fontWeight={700}> Currently Viewing:</Text>
                      {isLoadingQuestionnaireReportMultiday || isLoadingQuestionnaireReportSingleDay ? (
                        <Shimmer>
                          <PlaceHolderForAthletePickerShimmerLoading />
                        </Shimmer>
                      ) : (
                        <Picker list={[{ ...formattedCurrentGroupInGroupPicker }, ...formattedListOfAthletesForAthletePicker]} reduxFunction={functionForAthletePicker} visibleContainerItem={currentAthleteInAthletePicker?.displayText} icon={{ name: 'swap', size: 16, color: '#444444' }} pickerButtonColor='#EEEEEE' pickerFontColor='#444444' small minWidth={150} addSearchBar />
                      )}
                    </PickerAndDescriptionContainer>
                    <PickerAndDescriptionContainer containerWidth={containerWidth}>
                      <Text fontSize='15px' fontWeight={700}> Selected Questions:</Text>
                      <Picker list={formattedListForSelectedQuestionsPicker} reduxFunction={functionForSelectedQuestionsPicker} visibleContainerItem={getVisibleContainerItemForSelectedQuestionsPicker()} icon={{ name: 'swap', size: 16, color: '#444444' }} pickerButtonColor='#EEEEEE' pickerFontColor='#444444' small minWidth={containerWidth < 1084 ? 150 : 200} absoluteLeftPositioningOfItemPickerContainer={-100} />
                    </PickerAndDescriptionContainer>
                  </AthletePickerAndSelectedQuestionsSubRow>
                </PickerHeaderSubContainerRow>
              ) : (
                <WarningComponent containerWidth={containerWidth} message='Oops! Something went wrong. We were not able to find any reportable groups. Please check the status of your user groups and make sure reporting is enabled.' />
              )}
            </PickerHeaderRow>
            <ChartRow>
              <SpiderChart maxWidth='275px' containerWidth={containerWidth} />
              <LoadMonitoringComposedChart containerWidth={containerWidth} lineChartData={lineChartData} />
              <CheckboxFilterComponent containerWidth={containerWidth} renderTooltip lineChartData={lineChartData || []} />
            </ChartRow>
          </GraphAndPickersContainer>
        </>
      )}
      {reportableGroups?.length && dataRows?.length ? (
        <>
          <GridTitleRow>
            <TitleAndBadgeContainer isAthlete={currentAthleteInAthletePicker?.isAthlete}>
              <Text fontWeight={700} fontSize='16px'>
                {' '}
                {currentAthleteInAthletePicker?.isAthlete ? 'Athlete' : 'Group'}
                {' '}
                Report -
                {' '}
                {currentAthleteInAthletePicker?.name}
                {' '}
                {currentAthleteInAthletePicker?.isAthlete ? null : (`(${moment(currentDateInDashDatePicker?.date).format('MMM DD, YYYY')})`)}
              </Text>
              {currentAthleteInAthletePicker?.isAthlete && (
              <Text fontSize='11px' fontWeight={700}>Last 7 days that data was found for current athlete before selected date</Text>
              )}
              {athletesInClickedZone.length ? (
                <BadgeButton type='button' onClick={() => dispatch(setAthletesInClickedZone([]))} backgroundColor={thresholdFilterColor}>
                  <Text color='white' fontSize='12px' fontWeight={700}>Threshold Filter</Text>
                  <IcomoonReact
                    iconSet={iconSet}
                    color='white'
                    size={8}
                    icon='remove'
                  />
                </BadgeButton>
              ) : null}
            </TitleAndBadgeContainer>
            {currentReportType === 'multiDay' && (<DownloadReportButton backgroundColor='white' textColor='#444444' chartName={`Athlete Report - ${currentAthleteInAthletePicker?.name}`} />)}
            {/* {currentReportType === 'multiDay' && (<DownloadReportButton backgroundColor='#F5F3F3' textColor='#444444' chartName={`Athlete Report - ${currentAthleteInAthletePicker?.name}`} />)} */}
          </GridTitleRow>
          <RowWrapper containerWidth={containerWidth}>
            {currentReportType === 'singleDay' && (
            <PickerDonutChartAndDownloadContainer columnHeaderHeight={columnHeaderHeight} containerWidth={containerWidth}>
              <PickerAndDonutChart containerWidth={containerWidth} />
              <DownloadReportButton chartName={`Group Report - ${currentAthleteInAthletePicker?.name} (${moment(currentDateInDashDatePicker?.date).format('MMM DD, YYYY')}) `} />
            </PickerDonutChartAndDownloadContainer>
            )}
            <RelativelyPositionedRowWrapperForScrollIndicator>
              <BodyRow containerWidth={containerWidth} ref={gridRowRef} onScroll={handleScroll} rowIsScrollable={rowIsScrollable}>
                {isLoadingQuestionnaireReportMultiday || isLoadingQuestionnaireReportSingleDay ? (
                  <Shimmer>
                    <GridLoadingShimmerPlaceHolder />
                  </Shimmer>
                ) : (
                  <>
                    {currentReportType === 'singleDay' ? (
                      <SharedSingleDayQuestionnaireGrid
                        setColumnHeaderHeight={setColumnHeaderHeight}
                        containerWidth={containerWidth}
                        dataRows={athletesInClickedZone?.length ? filteredDataRows : dataRows}
                        teamAverageTotals={teamAverageTotals}
                        showAverages={showAverages}
                        showTotals={showTotals}
                        setCurrentQuestionInDonutChartQuestionnairePicker={setCurrentQuestionInQuestionnairePicker}
                        rowIsScrollable={rowIsScrollable}
                        athletesInClickedZone={athletesInClickedZone}
                      />
                    ) : (
                      <>
                        <SharedMuliDayQuestionnaireGrid
                          containerWidth={containerWidth}
                          dataRows={dataRows}
                          apiUnformatedData={apiUnformatedData}
                          showAverages={showAverages}
                          showTotals={showTotals}
                          setColumnHeaderHeight={setColumnHeaderHeight}
                          rowIsScrollable={rowIsScrollable}
                        />
                      </>
                    )}
                  </>
                )}
              </BodyRow>
            </RelativelyPositionedRowWrapperForScrollIndicator>
          </RowWrapper>
        </>
      ) : null}
    </MainContainer>
  );
};

export default GeneratedDataPage;
