import update from 'immutability-helper';
import findKey from 'lodash.findkey';

import generateUniqueId from '../../../shared/utils/generateUniqueId';

import {
  FETCH_EXERCISES_START,
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
} from './actionTypes';

const initialState = {
  isExercisesLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISES_START: {
      return {
        ...state,
        isExercisesLoading: true,
      };
    }
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        isExercisesLoading: false,
      };
    }
    case FETCH_EXERCISES_REJECTED: {
      return {
        ...state,
        isExercisesLoading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
