import {
  FETCH_PROGRAM_START,
  FETCH_PROGRAM_FULFILLED,
  FETCH_PROGRAM_REJECTED,
  FETCH_WORKOUT_PREVIEW_FULFILLED,
  FETCH_STRIPE_DETAILS_FULFILLED,
  SUBMIT_PAYMENT_FULFILLED,
  SUBMIT_PAYMENT_REJECTED,
  FETCH_MORE_PROGRAMS_FULFILLED,
  SET_MODAL_OPEN,
  CLEAR_PAYMENT_DATA,
  FETCH_RESELLER_FULFILLED,
  FETCH_PUBLIC_COUPON_FULFILLED,
  FETCH_PUBLIC_COUPON_REJECTED,
  CLEAR_COUPON_ERROR,
  SET_COUPON_ERROR,
  CLEAR_COUPON,
} from './actionTypes';

const initialState = {
  programData: {},
  workoutData: [],
  paymentDetails: {},
  morePrograms: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESELLER_FULFILLED: {
      return {
        ...state,
        resellerData: action.payload,
      };
    }
    case FETCH_PUBLIC_COUPON_FULFILLED: {
      return {
        ...state,
        coupon: action.payload,
        couponError: null,
      };
    }
    case FETCH_PUBLIC_COUPON_REJECTED: {
      return {
        ...state,
        coupon: null,
        couponError: action.payload,
      };
    }
    case CLEAR_COUPON_ERROR: {
      return {
        ...state,
        couponError: null,
      };
    }
    case CLEAR_COUPON: {
      return {
        ...state,
        coupon: null,
      };
    }
    case SET_COUPON_ERROR: {
      return {
        ...state,
        couponError: action.payload,
      };
    }
    case FETCH_PROGRAM_FULFILLED: {
      return {
        ...state,
        programData: action.payload,
      };
    }
    case FETCH_WORKOUT_PREVIEW_FULFILLED: {
      return {
        ...state,
        workoutData: action.payload,
      };
    }
    case SUBMIT_PAYMENT_FULFILLED: {
      return {
        ...state,
        paymentData: action.payload,
        paymentError: '',
      };
    }
    case FETCH_STRIPE_DETAILS_FULFILLED: {
      return {
        ...state,
        paymentDetails: action.payload,
      };
    }
    case FETCH_MORE_PROGRAMS_FULFILLED: {
      return {
        ...state,
        morePrograms: action.payload,
      };
    }
    case SUBMIT_PAYMENT_REJECTED: {
      return {
        ...state,
        paymentError: action.payload.response.headers,
      };
    }
    case SET_MODAL_OPEN: {
      return {
        ...state,
        paymentError: '',
      };
    }
    case CLEAR_PAYMENT_DATA: {
      return {
        ...state,
        paymentDetails: {},
        paymentError: '',
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
