/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { Formik, Form, Field } from 'formik';
import { animated, useSpring } from 'react-spring';
import Logger from 'js-logger';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import ResizeObserver from 'react-resize-observer';

import Text from '../../../../shared/components/Text/Text';
import SubText from '../../../../shared/components/SubText/SubText';
import Button from '../../../../shared/components/Button/Button';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import MessagesByDay from './MessagesByDay';
import MessagesScheduled from './MessagesScheduled';
import MessagesIsTyping from './MessagesIsTyping';
import useConversations from '../hooks/useConversations';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  openMessageVideo,
  setIsScheduledOpen,
} from '../../ducks/conversationsActions';

const MessageBoxContainer = styled('div')`
  height: 100%;
  width: 100%;
  min-width: 425px;
  display: flex;
  padding-top: 25px;
  overflow-y: auto;
  background: #fbfbfb;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  .schedule-button {
    align-self: center;
    margin: 20px 0px;
    width: 250px;
    z-index: 100;
  }
`;

const MessageListContainer = styled('div')`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  display: flex;
`;

const LoadMoreContainer = styled('div')`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 20px 15px;
  justify-content: center;
  position: relative;
`;

const mediaOptimizer = (imageUrl) => optimizeImage(
  imageUrl,
  {
    resize: {
      width: 60,
      height: 60,
      fit: 'contain',
    },
  },
);

// const MessageMedia = styled('div')`
//   display: flex;
//   width: 100px;
//   min-height: 100px;
//   align-items: center;
//   justify-content: flex-start;
//   font-size: 13px;
//   overflow-wrap: normal;
//   padding-bottom: min(7px);
//   background-image: ${(props) => `url(${props.props.media.mediaUrl})`};
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: center;
// `;

const MessageBox = ({
  conversationMessages,
  scheduledMessages,
  setEditingMessageId,
  setIsEditing,
  messageFormRef,
}) => {
  const [containerHeight, setContainerHeight] = useState(0);
  const [messagesHeight, setMessagesHeight] = useState(0);
  const [isAlreadyLoading, setAlreadyLoading] = useState(false);

  const dispatch = useDispatch();
  const messagesBoxRef = useRef();
  const messagesRef = useRef();
  const scheduledMessagesRef = useRef([]);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeConversation = useSelector((state) => state.conversations.data.activeConversation);
  const messagesByDay = useSelector((state) => state.conversations.data.conversationsByDay);
  const isInitialMessagesFetch = useSelector((state) => state.conversations.ui.isInitialMessagesFetch);
  const isScheduledOpen = useSelector((state) => state.conversations.ui.isScheduledOpen);
  const isMessagesLoading = useSelector((state) => state.conversations.ui.isMessagesLoading);
  const isMoreMessagesLoading = useSelector((state) => state.conversations.ui.isMoreMessagesLoading);
  const isMoreMessagesEmpty = useSelector((state) => state.conversations.ui.isMoreMessagesEmpty);

  const [userProfilePic, setUserProfilePic] = useState(null);
  const [rerender, setRerender] = useState();

  const theme = useTheme();

  const {
    handleFetchMoreMessages,
  } = useConversations();

  // React Spring animations for loading more, and auto scrolling.
  // Scrolling animation works by setting the scroll location from the tableScroll state
  const loadTransition = useSpring({
    position: 'absolute',
    opacity: isMoreMessagesLoading ? 1 : 0,
  });

  const endTransition = useSpring({
    position: 'absolute',
    opacity: isMoreMessagesEmpty ? 1 : 0,
  });

  // useEffect(() => {
  //   conversationMessages.current = new Array(conversationMessages.length);
  // }, []);

  const scheduledTransition = useSpring({
    height: isScheduledOpen ? scheduledMessagesRef.current.clientHeight : 0,
    opacity: isScheduledOpen ? 1 : 0,
  });

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      const pic = optimizeImage(
        currentUser.pic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(pic);
    }
  }, [currentUser]);

  const imageOptimizer = (friendPic) => optimizeImage(
    friendPic,
    {
      resize: {
        width: 60,
        height: 60,
        fit: 'contain',
      },
    },
  );

  const FriendMessage = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 50px;
    align-items: flex-start;
    font-size: 13px;
    overflow-wrap: normal;
    padding-bottom: min(7px);
    margin: 10px 0px;

    .userpic {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      /* background-image: url(${userProfilePic}); */
      background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`};
      background-size: contain;
      margin-left: 10px;
    }
      .userpic {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`};
        background-size: contain;
        margin-left: 10px;
      }
      .message-text {
        display: flex;
        align-items: center;
        color: white;
        background: #77A7FF;
        padding: 9px;
        border-radius: 20px;
        margin-left: 10px;
        overflow-wrap: anywhere;
        margin-right: 50px;
        max-width: 500px;
        white-space: break-spaces; 
    }
  `;

  const ScheduleContainer = styled('div')`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
  `;

  const UserMessage = styled('div')`
    display: flex;  
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    font-size: 13px;
    overflow-wrap: normal;
    padding-bottom: min(7px);
    margin: 10px 0px;

    .userpic {
      height: 40px;
      width: 40px;
      min-width: 40px;
      border-radius: 50%;
      background-image: url(${userProfilePic});
      background-size: contain;
      margin-right: 10px;
    }
      .userpic {
        height: 40px;
        width: 40px;
        min-width: 40px;
        border-radius: 50%;
        background-image: url(${userProfilePic});
        background-size: contain;
        margin-right: 10px;
      }
      .message-text {
        display: flex;
        align-items: center;
        color: white;
        background: #ff6600;
        padding: 9px;
        border-radius: 20px;
        margin-right: 10px;
        overflow-wrap: anywhere;
        margin-left: 50px;
        max-width: 500px;
        white-space: break-spaces; 
      }
  `;

  const messagesByDayMapper = (messages) => (
    messages.map((day) => (
      <>
        <MessagesByDay
          currentUser={currentUser}
          day={day[0]}
          dayMessages={day[1]}
          isInitialMessagesFetch={isInitialMessagesFetch}
        />
        {day[1].length !== 0 ? (
          <Text
            fontSize={16}
            style={{
              alignSelf: 'center',
              margin: '20px 0px',
            }}
            color={theme.colors.lightText[0]}
          >
            {moment.tz(day[0], currentUser.userSettings.timeZone).format('MMMM D')}
          </Text>
        ) : null}
      </>
    ))
  );

  const scheduledMessagesMapper = (messages) => {
    console.log(messages);
    console.log(messageFormRef);
    if (messages.length !== 0) {
      return (
        <ScheduleContainer>
          <Button
            className='schedule-button'
            color={theme.colors.lightText[1]}
            onClick={() => {
              dispatch(setIsScheduledOpen());
              console.log(scheduledMessagesRef.current.clientHeight);
            }}
            cta='Scheduled Messages'
            icon='clock'
          />
          <animated.div style={scheduledTransition}>
            <MessagesScheduled
              activeConversation={activeConversation}
              currentUser={currentUser}
              dayMessages={messages}
              isInitialMessagesFetch={isInitialMessagesFetch}
              messageFormRef={messageFormRef}
              scheduledMessagesRef={scheduledMessagesRef}
              setIsEditing={setIsEditing}
              setEditingMessageId={setEditingMessageId}
            />
          </animated.div>
        </ScheduleContainer>
      );
    }
    return null;
  };

  const isTypingMapper = (messages) => (
    messages.map((day) => (
      <>
        <MessagesByDay
          currentUser={currentUser}
          day={day[0]}
          dayMessages={day[1]}
          isInitialMessagesFetch={isInitialMessagesFetch}
        />
      </>
    ))
  );

  // INFINITE SCROLL VARIABLES:

  // Margins in the page
  const tableMargins = 30;

  // Initial Scroll location using react refs.
  const initialScrollLocation = messagesBoxRef.current ? messagesBoxRef.current.offsetTop : 0;

  // tableEnd is the location of the end of the list by subtracting the initial scroll location
  // from the table list height, then adding the container height and subtracting both margins.
  const containerEnd = (initialScrollLocation - messagesHeight + 1000);
  // + tableContainerHeight - (tableMargins * 2) + 10;

  // Load More function which fetches the next leaderboard page if it's not the end of the list.
  // const loadMore = () => {
  //   if (!isEndOfList) {
  //     handleFetchLeaderboard(leaderboardForm, 'loadMore', id);
  //   }
  // };

  return (
    <MessageBoxContainer ref={messagesBoxRef}>
      <MessageListContainer ref={messagesRef}>
        {/*
          Instead of using a scroll event listener which can be expensive, we're using
          the resize observer api which determines changes to the dimensions and position information
          of an element. By placing it within the TableWrapper we are observing the leaderboard table
          and can use the rect.top data for scroll location.
        */}
        <ResizeObserver
          onResize={(rect) => {
            setMessagesHeight(rect.height);
            console.log(rect.height, messagesRef, containerEnd);
          }}
          onPosition={(rect) => {
            // If rect.top from observer api is less than tableEnd from
            // the above variable, then load more data.
            if (rect.top >= 100
              && !isMoreMessagesLoading
              && messagesByDay.length > 0
              && conversationMessages.length > 49

            ) {
              // loadMore();
              // setCurrentScroll(rect.top);
              console.log('We here');
              handleFetchMoreMessages(currentUser.accountCode, activeConversation.id);
            }
            console.log(rect.top, containerEnd, messagesHeight);
          }}
        />
        {scheduledMessagesMapper(scheduledMessages)}
        <MessagesIsTyping />
        {/* {messagesByDayMapper(messagesByDay)} */}
        {
          messagesByDay.map((day) => (
            <>
              <MessagesByDay
                currentUser={currentUser}
                day={day[0]}
                dayMessages={day[1]}
                isInitialMessagesFetch={isInitialMessagesFetch}
              />
              {day[1].length !== 0 ? (
                <Text
                  fontSize={16}
                  style={{
                    alignSelf: 'center',
                    margin: '20px 0px',
                  }}
                  color={theme.colors.lightText[0]}
                >
                  {moment.tz(day[0], currentUser.userSettings.timeZone).format('MMMM D')}
                </Text>
              ) : null}
            </>
          ))
        }
        <LoadMoreContainer>
          <animated.div style={loadTransition}>
            <Spinner
              saving
              darkTheme
            />
          </animated.div>
          <animated.div style={endTransition}>
            <SubText>
              Beginning of Conversation
            </SubText>
          </animated.div>
        </LoadMoreContainer>
      </MessageListContainer>
    </MessageBoxContainer>
  );
};
MessageBox.propTypes = {
  conversationMessages: PropTypes.instanceOf(Array).isRequired,
};
export default MessageBox;
