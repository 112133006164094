import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BoxShadow, BorderRadius } from '../../../../shared/GlobalStyles';

const InfoCardContainer = styled('div')`
  display: flex;
  min-width: 333px;
  width: 333px;
  padding: 30px;
  border-radius: ${BorderRadius};
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 530px) {
    min-width: 0px!important;
  }
`;

const InfoDetailsContainer = styled('div')`
  flex: 10;
`;

const InfoSideDetailsContainer = styled('div')`
  flex: 2;
`;


const InfoCard = ({
  InfoDetails,
  InfoSideDetails,
}) => {
  return (
    <InfoCardContainer
      className='info-card'
    >
      <InfoDetailsContainer
        className='info-details-container'
      >
        {InfoDetails}
      </InfoDetailsContainer>
      <InfoSideDetailsContainer>
        {InfoSideDetails}
      </InfoSideDetailsContainer>
    </InfoCardContainer>
  );
};

InfoCard.propTypes = {
  InfoDetails: PropTypes.element.isRequired,
  InfoSideDetails: PropTypes.element.isRequired,
};

export default InfoCard;
