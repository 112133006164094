import { connect } from 'react-redux';
import DatePicker from '../presentational/DatePicker';
import { fetchWorkoutCalendar, selectDate } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeDate: state[ownProps.namespace].activeDate,
  athleteId: state[ownProps.namespace].athleteId,
  timezone: state.weightRoom.currentUser.settings.time_zone,
  workoutCalendar: state[ownProps.namespace].workoutCalendar,
});

const mapDispatchToProps = dispatch => ({
  fetchWorkoutCalendar: (athleteId, namespace, startDate, endDate) => {
    dispatch(fetchWorkoutCalendar(athleteId, namespace, startDate, endDate));
  },
  selectDate: (date, athleteId, namespace) => {
    dispatch(selectDate(date, athleteId, namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
