import React from 'react';
import styled from '@emotion/styled';

import CustomLoginForm from './CustomLoginForm';
import CustomLoginFormHeader from './CustomLoginFormHeader';

const FormContainer = styled('div')`
  width: 430px;
  height: auto;
  background-color: white;
  padding-bottom: 40px;

  @media screen and (max-width: 600px) {
    width: 330px;
    height: auto;
    padding-bottom: 20px;
  }
`;

const FormArea = styled('div')`
  padding: 30px 54px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 30px;
  }
`;

const CustomLoginFormContainer = () => (
  <FormContainer>
    <CustomLoginFormHeader />
    <FormArea>
      <CustomLoginForm />
    </FormArea>
  </FormContainer>
);

export default CustomLoginFormContainer;
