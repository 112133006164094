/* eslint-disable no-nested-ternary */
import update from 'immutability-helper';

import {
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_FULFILLED,
  FETCH_CURRENT_USER_REJECTED,
  FETCH_USERS_START,
  FETCH_USERS_FULFILLED,
  FETCH_USERS_REJECTED,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_START,
  OPEN_GLOBAL_MODAL,
  CLOSE_GLOBAL_MODAL,
  SET_SCREENS,
  TOGGLE_SCREENS,
  TOGGLE_DARK_THEME,
  TOGGLE_WEIGHT_VALUE,
  FETCH_DARKNESS_MODE_START,
  FETCH_DARKNESS_MODE_FULFILLED,
  FETCH_DARKNESS_MODE_REJECTED,
  SET_IS_GROUP_ACCESS_MODAL_SHOWING,
} from './actionTypes';

const initialState = {
  activeScreens: 4,
  fetchedUsers: false,
  fetchedGroups: false,
  fetchedCurrentUser: false,
  isGlobalModalOpen: false,
  isGroupAccessModalShowing: false,
  isDarkThemeEnabled: false,
  isWeightValueEnabled: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CURRENT_USER_START: {
      return {
        ...state,
      };
    }
    case FETCH_CURRENT_USER_FULFILLED: {
      return {
        ...state,
        fetchedCurrentUser: true,
        wrvSupersets: true,
        isWeightValueEnabled: action.payload.userSettings.wrvPlaceholdersEnabled === 1,
      };
    }
    case FETCH_CURRENT_USER_REJECTED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case FETCH_DARKNESS_MODE_START: {
      return {
        ...state,
      };
    }
    case FETCH_DARKNESS_MODE_FULFILLED: {
      return {
        ...state,
        isDarkThemeEnabled: action.payload,
      };
    }
    case FETCH_DARKNESS_MODE_REJECTED: {
      return {
        ...state,
        isDarkThemeEnabled: false,
      };
    }
    case FETCH_USERS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USERS_FULFILLED: {
      return {
        ...state,
        fetchedUsers: true,
      };
    }
    case FETCH_USERS_REJECTED: {
      return {
        ...state,
        error: action.payload,
      };
    }
    case FETCH_USER_GROUPS_START: {
      return {
        ...state,
      };
    }
    case FETCH_USER_GROUPS_FULFILLED: {
      return {
        ...state,
        fetchedGroups: true,
      };
    }
    case TOGGLE_SCREENS: {
      return {
        ...state,
        activeScreens:
          state.activeScreens === 4
            ? state.activeScreens - 3
            : (
              state.activeScreens === 2
                ? state.activeScreens + 2
                : state.activeScreens + 1
            ),
      };
    }
    case SET_SCREENS: {
      return update(state, {
        activeScreens: {
          $set: action.payload,
        },
      });
    }
    case OPEN_GLOBAL_MODAL: {
      return {
        ...state,
        isGlobalModalOpen: true,
      };
    }
    case CLOSE_GLOBAL_MODAL: {
      return {
        ...state,
        isGlobalModalOpen: false,
      };
    }
    case TOGGLE_DARK_THEME: {
      return update(state, {
        isDarkThemeEnabled: {
          $set: !state.isDarkThemeEnabled && true,
        },
      });
    }
    case TOGGLE_WEIGHT_VALUE: {
      return update(state, {
        isWeightValueEnabled: {
          $set: !state.isWeightValueEnabled,
        },
      });
    }
    case SET_IS_GROUP_ACCESS_MODAL_SHOWING: {
      return {
        ...state,
        isGroupAccessModalShowing: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
