import { connect } from 'react-redux';
import WeightroomView from '../WeightroomView';
import {
  closeGlobalModal,
  fetchInitial,
  openGlobalModal,
  toggleScreens,
} from '../../ducks/weightroomActions';


const mapStateToProps = state => ({
  activeScreens: state.weightRoom.activeScreens,
  currentUser: state.weightRoom.currentUser,
  fetchedCurrentUser: state.weightRoom.fetchedCurrentUser,
  fetchedGroups: state.weightRoom.fetchedGroups,
  fetchedUsers: state.weightRoom.fetched,
  isDarkThemeEnabled: state.weightRoom.isDarkThemeEnabled,
  isGlobalModalOpen: state.weightRoom.isGlobalModalOpen,
  skin: state.weightRoom.currentUser.skin,
  userInfo: state.weightRoom.currentUser.user_info,
});

const mapDispatchToProps = dispatch => ({
  closeGlobalModal: () => {
    dispatch(closeGlobalModal());
  },
  fetchUsers: () => {
    dispatch(fetchInitial());
  },
  handleScreenToggle: () => {
    dispatch(toggleScreens());
  },
  openGlobalModal: () => {
    dispatch(openGlobalModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WeightroomView);
