import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from '../../../../shared/components/Text/Text';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
  border: 1px solid ${(props) => (props.modalType === 'edit-modal' && props.hasCompletions ? '#EAEAEA' : 'rgb(204, 204, 204)')};
  border-radius: 4px;
  padding-right: 12px;
  padding-left: 12px;
  justify-content: space-between;

`;

const Label = styled('div')`
  font-family: 'Nunito Sans';
  margin-bottom: 5px;
  font-size: 19px;
  font-weight: 700;
  color: ${(props) => (props.modalType === 'edit-modal' && props.hasCompletions ? 'rgba(0, 0, 0, 0.38)' : '#444444')};
`;

const Input = styled('input')`
  display: flex;
  background-color: white;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.fontSize)};
  font-weight: ${(props) => props.fontWeight || 600}; // Set default font-weight to 600
  color: ${(props) => props.textColor || 'inherit'}; // Set default color to inherit
  height: ${(props) => props.height};
  padding: 0px;
  text-align: ${(props) => (props.centered && 'center')};
  width: ${(props) => (props.value ? `calc(${String(props.value).length}ch + 0px)` : '30px')};
  max-width: 58px;
  &::placeholder {
    color: ${(props) => props.placeholderColor || 'inherit'}; // Set default placeholder color to inherit
  }
  text-align: center;
  border: none;
  outline: none;
`;

const ErrorText = styled('div')`
  color: #CC0404;
  font-family: 'Nunito Sans';
  font-size: 13px;
  font-weight: 700;
  margin-top: 5px;
`;

const Button = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.modalType === 'edit-modal' && props.hasCompletions ? '#DFDFDF' : '#757575')};
  height: 20px;
  width: 20px;
  border-radius: 50%;
`;

const ButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const InputAndWeeklyContainer = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
`;

const CounterInput = ({
  setFieldValue,
  setFieldTouched,
  centered,
  error,
  errorMsg,
  fontSize,
  height,
  inputOnly,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  width,
  fontWeight,
  textColor,
  placeholderColor,
  onBlur,
  modalType,
  hasCompletions,
}) => {
  const inputRef = useRef(null);
  const [counterValue, setCounterValue] = useState(value || 0);

  const handleIncrease = () => {
    setCounterValue((prevValue) => String(Number(prevValue) + 1));
  };

  const handleDecrease = () => {
    setCounterValue((prevValue) => String(Number(prevValue) - 1));
  };

  useEffect(() => {
    if (counterValue) {
      setFieldTouched('frequency', true);
      setFieldValue('frequency', counterValue);
    }
  }, [counterValue]);

  useEffect(() => {
    if (value) {
      setCounterValue(value);
    }
  }, [value]);

  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <Wrapper onClick={() => focusInput()}>
      {!inputOnly && (
        <Label modalType={modalType} hasCompletions={hasCompletions}>
          {label}
        </Label>
      )}
      <InnerWrapper modalType={modalType} hasCompletions={hasCompletions}>
        <InputAndWeeklyContainer>
          <Input
            disabled={modalType === 'edit-modal' && hasCompletions}
            ref={inputRef}
            centered={centered}
            className={error ? 'error' : null}
            error={error}
            fontSize={fontSize}
            fontWeight={fontWeight}
            textColor={modalType === 'edit-modal' && hasCompletions ? 'rgba(0, 0, 0, 0.38)' : textColor}
            placeholderColor={placeholderColor}
            height={height}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
            width={width}
            onBlur={onBlur}
            modalType={modalType}
          />
          <Text color={value && !hasCompletions ? '#44444' : 'rgba(0, 0, 0, 0.38)'}>
            x
          </Text>
          <Text color={value && !hasCompletions ? '#44444' : 'rgba(0, 0, 0, 0.38)'}>
            Weekly
          </Text>
        </InputAndWeeklyContainer>
        <ButtonContainer>
          <Button
            type='button'
            onClick={handleDecrease}
            disabled={modalType === 'edit-modal' && hasCompletions}
            modalType={modalType}
            hasCompletions={hasCompletions}
          >
            <Text fontSize='27px' color={modalType === 'edit-modal' && hasCompletions ? '#DFDFDF' : '#757575'}>-</Text>
          </Button>
          <Button
            type='button'
            onClick={handleIncrease}
            disabled={modalType === 'edit-modal' && hasCompletions}
            modalType={modalType}
            hasCompletions={hasCompletions}
          >
            <Text fontSize='22px' color={modalType === 'edit-modal' && hasCompletions ? '#DFDFDF' : '#757575'}>+</Text>
          </Button>
        </ButtonContainer>
      </InnerWrapper>
      {error && (
        <ErrorText>{errorMsg}</ErrorText>
      )}
    </Wrapper>
  );
};

CounterInput.propTypes = {
  centered: PropTypes.bool,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  fontSize: PropTypes.string,
  height: PropTypes.string,
  inputOnly: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.string,
  fontWeight: PropTypes.number,
  textColor: PropTypes.string,
  placeholderColor: PropTypes.string,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  hasCompletions: PropTypes.bool,
};

CounterInput.defaultProps = {
  centered: false,
  error: false,
  errorMsg: '',
  fontSize: '12px',
  fontWeight: 600,
  height: '',
  inputOnly: false,
  label: '',
  placeholder: '',
  type: 'text',
  width: '',
  textColor: '#444444',
  placeholderColor: '#9E9E9E',
  onBlur: () => {},
  hasCompletions: false,
};

export default CounterInput;
