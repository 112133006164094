import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import update from 'immutability-helper';
import { useTheme } from 'emotion-theming';
import Logger from 'js-logger';

import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import MaxChartFilter from './MaxChartFilter';

const MainContainer = styled('main')`
  padding: 40px;
  border-radius: 4px;
  background-color: white;
  /* background-image: linear-gradient(114deg, rgba(255 , 154, 68, 0.99) 7%, #fc6076 210%); */
  .recharts-legend-item-text {
    color: ${props => props.theme.colors.text};
  }

  .legend-item-0 {
    .recharts-surface {
      opacity: 0.6;
    }
  }

  @media screen and (max-height: 920px) {
    padding: 30px;
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
`;

const ChartUnit = styled('div')`
  text-transform: uppercase;

  h1 {
    color: ${props => props.theme.colors.text};
    margin-bottom: 30px;
  }
`;

const ChartFilterContainer = styled('div')`
`;

const data = [
  {
    "name": "Barbell Back Squat",
    "Athlete Value": 350,
    "Selection Average": 300,
  },
  {
    "name": "Barbell Bench Press",
    "Athlete Value": 250,
    "Selection Average": 200,
  },
  {
    "name": "Total",
    "Athlete Value": 600,
    "Selection Average": 500,
  },
];

const MaxChart = ({
  activeAthlete,
  athletesChartData,
  layoutHeight,
  setActiveAthlete,
  setAthletesChartData,
  rawTableData,
}) => {
  const [maxValue, setMaxValue] = useState(0);
  const maxReport = useSelector(state => state.reporting.data.maxReport.data);

  const averageData = rawTableData[rawTableData.length - 1];

  const theme = useTheme();

  useEffect(() => {
    let athletesData = {};
    let savedMaxValue = 0;

    const maxValue = (maxReport) && maxReport.rows.reduce((a, b) => {
      return Math.max(a.rawValue, b.rawValue);
    });

    savedMaxValue = maxValue;
    setMaxValue(savedMaxValue);

    (maxReport) && maxReport.rows.forEach((row, index) => {
      let athleteArray = [];
      let userId = 0;

      row.forEach((column, index) => {
        const { columnId } = column;

        if (columnId === 'userId') {
          userId = column.rawValue;
        }
        if (columnId === 'Average') {
          return;
        }
        if (columnId === 'userFirst' || columnId === 'userLast' || columnId === 'userId') {
          return;
        }

        const athleteRow = {
          name: column.valueDescription,
          'Athlete Value': column.rawValue,
          'Selection Average': averageData[column.columnId],
          selector: column.columnId,
        };

        athleteArray = [
          ...athleteArray,
          athleteRow,
        ];

        const newAthletesData = {
          ...athletesData,
          [userId]: athleteArray,
        };

        athletesData = newAthletesData;
      });

      if (index === 0) {
        setActiveAthlete(userId);
        Logger.debug('Loop User Id:', userId);
      }
      Logger.debug('Class Average Data', averageData);
      Logger.debug('Chart Data:', athletesData);

      return maxValue;
    });
    setAthletesChartData(athletesData);
    // setActiveAthlete(Object.keys(athletesData)[0]);
  }, []);

  Logger.debug(athletesChartData, activeAthlete);

  return (
    <MainContainer>
      <ChartFilterContainer>
        <MaxChartFilter
          activeAthlete={activeAthlete}
          setActiveAthlete={setActiveAthlete}
          tableData={rawTableData}
        />
      </ChartFilterContainer>
      {/* <ChartUnit>
        <HeaderText
          letterSpacing={1.2}
          fontWeight={900}
          fontSize={13}
        >
          Weight
        </HeaderText>
      </ChartUnit> */}
      <ResponsiveContainer height={layoutHeight <= 920 - 49 ? 200 : 300}>
        <BarChart
          // width={chartWidth}
          // height={300}
          data={athletesChartData[activeAthlete]}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey='name' stroke={theme.colors.lightText[1]} />
          <YAxis
            stroke={theme.colors.lightText[1]}
            // domain={['0', maxValue]} 
          />
          <Tooltip />
          <Legend stroke={theme.colors.text} />
          <Bar dataKey="Selection Average" fill={theme.colors.lightBlue} opacity='0.6' />
          <Bar dataKey="Athlete Value" fill={theme.colors.lightBlue} />
        </BarChart>
      </ResponsiveContainer>
    </MainContainer>
  );
};

MaxChart.propTypes = {
  chartWidth: PropTypes.number.isRequired,
};

export default MaxChart;
