/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setAboutDashboardModalShowing } from '../../ducks/sportCoachDashboardActions';
import AboutDashboardModal from './AboutDashboardModal';

const AboutDashboardModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const AboutDashboardModalContainer = () => {
  const dispatch = useDispatch();
  const aboutDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.aboutDashboardModalShowing,
  );

  return (
    <>
      <AboutDashboardModal />
      <AboutDashboardModalOverlay
        isActive={aboutDashboardModalShowing}
        onClick={
          () => dispatch(setAboutDashboardModalShowing(false))
          }
      />
    </>
  );
};

AboutDashboardModalContainer.propTypes = { };

export default AboutDashboardModalContainer;
