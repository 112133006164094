import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PanelHeader from '../presentational/PanelHeader';
import { closePanel, closeWorkout } from '../../ducks/panelActions';

const WeightroomPanelHeader = (ownProps) => {
  const activeAthlete = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].activeAthlete);
  const athleteAvatar = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].athleteAvatar);
  const isLoading = useSelector((state) => state.weightRoomView[ownProps.namespace].isLoading);

  const dispatch = useDispatch();

  const clsPanel = (namespace) => {
    dispatch(closePanel(namespace));
  };
  const clsWorkout = (namespace) => {
    dispatch(closeWorkout(namespace));
  };

  return (
    <PanelHeader
      activeAthlete={activeAthlete}
      athleteAvatar={athleteAvatar}
      isLoading={isLoading}
      closePanel={clsPanel}
      closeWorkout={clsWorkout}
      namespace={ownProps.namespace}
    />
  );
};

export default WeightroomPanelHeader;
