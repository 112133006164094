import React from 'react';
import PropTypes from 'prop-types';
import ReactToggle from 'react-toggle';

const Toggle = props => (
  <ReactToggle
    {...props}
  />
);

export default Toggle;
