// LOGIN

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';
export const LOGIN_AUTHORIZED = 'LOGIN_AUTHORIZED';
export const LOGOUT = 'LOGOUT';
export const SET_CURRENT_USER_START = 'SET_CURRENT_USER_START';
export const SET_CURRENT_USER_FULFILLED = 'SET_CURRENT_USER_FULFILLED';
export const SET_CURRENT_USER_REJECTED = 'SET_CURRENT_USER_REJECTED';
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_FULFILLED = 'FETCH_CURRENT_USER_FULFILLED';
export const FETCH_CURRENT_USER_REJECTED = 'FETCH_CURRENT_USER_REJECTED';
export const FETCH_INITIAL_FULFILLED = 'FETCH_INITIAL_FULFILLED';

// FETCH USERS

export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';

// WEIGHTROOM VIEW

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_FULFILLED = 'FETCH_USERS_FULFILLED';
export const FETCH_USERS_REJECTED = 'FETCH_USERS_REJECTED';

export const FETCH_USER_GROUPS_START = 'FETCH_USER_GROUPS_START';
export const FETCH_USER_GROUPS_FULFILLED = 'FETCH_USER_GROUPS_FULFILLED';
export const FETCH_USER_GROUPS_REJECTED = 'FETCH_USER_GROUPS_REJECTED';

export const FETCH_HISTORY_START = 'FETCH_HISTORY_START';
export const FETCH_HISTORY_FULFILLED = 'FETCH_HISTORY_FULFILLED';
export const FETCH_HISTORY_REJECTED = 'FETCH_HISTORY_REJECTED';

export const FETCH_MORE_HISTORY_START = 'FETCH_MORE_HISTORY_START';
export const FETCH_MORE_HISTORY_FULFILLED = 'FETCH_MORE_HISTORY_FULFILLED';
export const FETCH_MORE_HISTORY_REJECTED = 'FETCH_MORE_HISTORY_REJECTED';

export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_FULFILLED = 'FETCH_EXERCISES_FULFILLED';
export const FETCH_EXERCISES_REJECTED = 'FETCH_EXERCISES_REJECTED';

export const FETCH_SIM_EXERCISES_START = 'FETCH_SIM_EXERCISES_START';
export const FETCH_SIM_EXERCISES_FULFILLED = 'FETCH_SIM_EXERCISES_FULFILLED';
export const FETCH_SIM_EXERCISES_REJECTED = 'FETCH_SIM_EXERCISES_REJECTED';

export const FETCH_SIM_TYPE_EXERCISES_START = 'FETCH_SIM_TYPE_EXERCISES_START';
export const FETCH_SIM_TYPE_EXERCISES_FULFILLED = 'FETCH_SIM_TYPE_EXERCISES_FULFILLED';
export const FETCH_SIM_TYPE_EXERCISES_REJECTED = 'FETCH_SIM_TYPE_EXERCISES_REJECTED';

export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_FULFILLED = 'FETCH_DOCUMENT_FULFILLED';
export const FETCH_DOCUMENT_REJECTED = 'FETCH_DOCUMENT_REJECTED';

export const FETCH_WORKOUTS_START = 'FETCH_WORKOUTS_START';
export const FETCH_WORKOUTS_FULFILLED = 'FETCH_WORKOUTS_FULFILLED';
export const FETCH_WORKOUTS_REJECTED = 'FETCH_WORKOUTS_REJECTED';

export const FETCH_WORKOUT_START = 'FETCH_WORKOUT_START';
export const FETCH_WORKOUT_FULFILLED = 'FETCH_WORKOUT_FULFILLED';
export const FETCH_WORKOUT_REJECTED = 'FETCH_WORKOUT_REJECTED';

export const FETCH_CALENDAR_START = 'FETCH_CALENDAR_START';
export const FETCH_CALENDAR_FULFILLED = 'FETCH_CALENDAR_FULFILLED';
export const FETCH_CALENDAR_REJECTED = 'FETCH_CALENDAR_REJECTED';

export const FETCH_JOURNAL_START = 'FETCH_JOURNAL_START';
export const FETCH_JOURNAL_FULFILLED = 'FETCH_JOURNAL_FULFILLED';
export const FETCH_JOURNAL_REJECTED = 'FETCH_JOURNAL_REJECTED';

export const UPDATE_WORKOUTS_START = 'UPDATE_WORKOUTS_START';
export const UPDATE_WORKOUTS_FULFILLED = 'UPDATE_WORKOUTS_FULFILLED';
export const UPDATE_WORKOUTS_REJECTED = 'UPDATE_WORKOUTS_REJECTED';

export const UPDATE_SUBSTITUTE_WORKOUT = 'UPDATE_SUBSTITUTE_WORKOUT';

export const UPDATE_JOURNAL_START = 'UPDATE_JOURNAL_START';
export const UPDATE_JOURNAL_FULFILLED = 'UPDATE_JOURNAL_FULFILLED';
export const UPDATE_JOURNAL_REJECTED = 'UPDATE_JOURNAL_REJECTED';

export const SUBMIT_PIN_START = 'SUBMIT_PIN_START';
export const SUBMIT_PIN_FULFILLED = 'SUBMIT_PIN_FULFILLED';
export const SUBMIT_PIN_REJECTED = 'SUBMIT_PIN_REJECTED';

export const SEARCH_ATHLETE = 'SEARCH_ATHLETE';
export const SELECT_GROUP = 'SELECT_GROUP';
export const SELECT_ATHLETE = 'SELECT_ATHLETE';
export const SELECT_DATE = 'SELECT_DATE';
export const CLOSE_PANEL = 'CLOSE_PANEL';
export const SELECT_VERIFIED_ATHLETE = 'SELECT_VERIFIED_ATHLETE';

export const SELECT_WORKOUT = 'SELECT_WORKOUT';
export const CLOSE_WORKOUT = 'CLOSE_WORKOUT';
export const NEXT_WORKOUT = 'NEXT_WORKOUT';
export const PREV_WORKOUT = 'PREV_WORKOUT';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_PINPAD = 'OPEN_PINPAD';
export const CLOSE_PINPAD = 'CLOSE_PINPAD';
export const CANCEL_PINPAD = 'CANCEL_PINPAD';

export const COMPLETE_WORKOUT = 'COMPLETE_WORKOUT';

export const SELECT_QUESTION = 'SELECT_QUESTION';
export const CLOSE_QUESTION = 'CLOSE_QUESTION';
export const NEXT_QUESTION = 'NEXT_QUESTION';
export const PREV_QUESTION = 'PREV_QUESTION';
export const RESTART_QUESTIONS = 'RESTART_QUESTIONS';

export const TOGGLE_SCREENS = 'TOGGLE_SCREENS';
export const SET_SCREENS = 'SET_SCREENS';

export const STORE_PANEL_HEIGHT = 'STORE_PANEL_HEIGHT';

export const STORE_FORMIK_VALUES = 'STORE_FORMIK_VALUES';

export const STORE_WORKOUTS_BY_SUPERSET = 'STORE_WORKOUTS_BY_SUPERSET';
export const INCREASE_COMPLETED_SETS = 'INCREASE_COMPLETED_SETS';
export const DECREASE_COMPLETED_SETS = 'DECREASE_COMPLETED_SETS';
export const SET_IS_NAVIGATING_SUPERSETS = 'SET_IS_NAVIGATING_SUPERSETS';

export const OPEN_GLOBAL_MODAL = 'OPEN_GLOBAL_MODAL';
export const CLOSE_GLOBAL_MODAL = 'CLOSE_GLOBAL_MODAL';

export const TOGGLE_SUPERSETS = 'TOGGLE_SUPERSETS';

export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME';

export const TOGGLE_WEIGHT_VALUE = 'TOGGLE_WEIGHT_VALUE';

export const SET_ADDITIONAL_WORKOUTS_DATA = 'SET_ADDITIONAL_WORKOUTS_DATA';
export const CLEAR_ADDITIONAL_WORKOUTS_DATA = 'CLEAR_ADDITIONAL_WORKOUTS_DATA';
export const SET_IS_ADDITIONAL_WORKOUTS = 'SET_IS_ADDITIONAL_WORKOUTS';

export const SET_SHOW_SESSION_BREAK_ITEMS = 'SET_SHOW_SESSION_BREAK_ITEMS';

export const FETCH_DARKNESS_MODE_START = 'FETCH_DARKNESS_MODE_START';
export const FETCH_DARKNESS_MODE_FULFILLED = 'FETCH_DARKNESS_MODE_FULFILLED';
export const FETCH_DARKNESS_MODE_REJECTED = 'FETCH_DARKNESS_MODE_REJECTED';

export const SET_IS_CALENDAR_PROGRAM_VIEW = 'SET_IS_CALENDAR_PROGRAM_VIEW';

export const SET_IS_PROGRAM_LIST_MODAL = 'SET_IS_PROGRAM_LIST_MODAL';

export const SET_IS_RESET_PROGRAM_MODAL_SHOWING = 'SET_IS_RESET_PROGRAM_MODAL_SHOWING';

export const SET_IS_PROGRAM_INFO_MODAL_SHOWING = 'SET_IS_PROGRAM_INFO_MODAL_SHOWING';

export const SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING = 'SET_IS_COMPLETE_PROGRAM_MODAL_SHOWING';

export const SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING = 'SET_IS_COMPLETE_PROGRAM_WORKOUT_MODAL_SHOWING';

export const SET_IS_SKIP_WORKOUT_MODAL_SHOWING = 'SET_IS_SKIP_WORKOUT_MODAL_SHOWING';

export const CLEAR_PROGRAM_DATA = 'CLEAR_PROGRAM_DATA';

export const UPDATE_SUBSTITUTE_PROGRAM_WORKOUT = 'UPDATE_SUBSTITUTE_PROGRAM_WORKOUT';

export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_FULFILLED = 'FETCH_PROGRAMS_FULFILLED';
export const FETCH_PROGRAMS_REJECTED = 'FETCH_PROGRAMS_REJECTED';

export const FETCH_PROGRAM_WORKOUTS_START = 'FETCH_PROGRAM_WORKOUTS_START';
export const FETCH_PROGRAM_WORKOUTS_FULFILLED = 'FETCH_PROGRAM_WORKOUTS_FULFILLED';
export const FETCH_PROGRAM_WORKOUTS_REJECTED = 'FETCH_PROGRAM_WORKOUTS_REJECTED';

export const FETCH_NEXT_WORKOUT_SESSION_START = 'FETCH_NEXT_WORKOUT_SESSION_START';
export const FETCH_NEXT_WORKOUT_SESSION_FULFILLED = 'FETCH_NEXT_WORKOUT_SESSION_FULFILLED';
export const FETCH_NEXT_WORKOUT_SESSION_REJECTED = 'FETCH_NEXT_WORKOUT_SESSION_REJECTED';

export const FETCH_PROGRAM_WEEKS_START = 'FETCH_PROGRAM_WEEKS_START';
export const FETCH_PROGRAM_WEEKS_FULFILLED = 'FETCH_PROGRAM_WEEKS_FULFILLED';
export const FETCH_PROGRAM_WEEKS_REJECTED = 'FETCH_PROGRAM_WEEKS_REJECTED';

export const FETCH_PROGRAM_WEEK_START = 'FETCH_PROGRAM_WEEK_START';
export const FETCH_PROGRAM_WEEK_FULFILLED = 'FETCH_PROGRAM_WEEK_FULFILLED';
export const FETCH_PROGRAM_WEEK_REJECTED = 'FETCH_PROGRAM_WEEK_REJECTED';

export const RESET_PROGRAM_START = 'RESET_PROGRAM_START';
export const RESET_PROGRAM_FULFILLED = 'RESET_PROGRAM_FULFILLED';
export const RESET_PROGRAM_REJECTED = 'RESET_PROGRAM_REJECTED';

export const COMPLETE_PROGRAM_START = 'COMPLETE_PROGRAM_START';
export const COMPLETE_PROGRAM_FULFILLED = 'COMPLETE_PROGRAM_FULFILLED';
export const COMPLETE_PROGRAM_REJECTED = 'COMPLETE_PROGRAM_REJECTED';

export const FINISH_PROGRAM_WORKOUT_START = 'FINISH_PROGRAM_WORKOUT_START';
export const FINISH_PROGRAM_WORKOUT_FULFILLED = 'FINISH_PROGRAM_WORKOUT_FULFILLED';
export const FINISH_PROGRAM_WORKOUT_REJECTED = 'FINISH_PROGRAM_WORKOUT_REJECTED';

export const UPDATE_PROGRAM_WORKOUT_START = 'UPDATE_PROGRAM_WORKOUT_START';
export const UPDATE_PROGRAM_WORKOUT_FULFILLED = 'UPDATE_PROGRAM_WORKOUT_FULFILLED';
export const UPDATE_PROGRAM_WORKOUT_REJECTED = 'UPDATE_PROGRAM_WORKOUT_REJECTED';

export const FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_START = 'FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_START';
export const FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED = 'FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_FULFILLED';
export const FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_REJECTED = 'FETCH_PROGRAM_WEEK_VIA_NEXT_SESSION_REJECTED';

export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_START';
export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_FULFILLED';
export const FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED = 'FETCH_IMPORTED_DATA_INTEGRATIONS_BY_DATE_REJECTED';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_START = 'FETCH_SELECTED_INTEGRATION_BY_DATE_START';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED = 'FETCH_SELECTED_INTEGRATION_BY_DATE_FULFILLED';
export const FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED = 'FETCH_SELECTED_INTEGRATION_BY_DATE_REJECTED';

export const SET_SELECTED_INTEGRATION = 'SET_SELECTED_INTEGRATION';

export const SET_IS_INTEGRATION_MODAL_SHOWING = 'SET_IS_INTEGRATION_MODAL_SHOWING';

export const SET_IS_GROUP_ACCESS_MODAL_SHOWING = 'SET_IS_GROUP_ACCESS_MODAL_SHOWING';
