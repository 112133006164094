import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FieldArray, FastField } from 'formik';

import CompletionEntry from './WorkoutEntriesCompletion';
import { TransitionGlobal, ColorGreen } from '../../../../shared/GlobalStyles';

const WorkoutTableContainer = styled('div')``;

const WorkoutTableColumn = styled('div')``;

const EntryTitles = styled('div')`
  display: flex;
  height: 38px;
  align-items: center;
  min-width: 90px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: center;
`;

const FieldsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  .form-control {
  }
`;

const FieldsWrapper = styled('div')`
  display: flex;
`;

const SupersetIndicator = styled('div')`
  position: absolute;
  border: 1px solid ${ColorGreen};
  display: block;
  height: ${(props) => {
    const isEntryOrCompletion = props.completion
      ? `calc(${props.entriesViewHeight}px - 52px)`
      : `${props.entriesViewHeight + 5}px`;

    return props.entriesViewHeight ? isEntryOrCompletion : '144px';
  }};
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-top: 1px;
  border-radius: 0.25em;
  transition: ${TransitionGlobal};
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 139, 0.25);
  opacity: ${(props) => (props.activeSuperset ? '1' : '0')};
`;

const StaticText = styled('p')`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  margin: 0px;
  justify-content: center;
  align-items: center;
`;

const PercText = styled('span')`
  font-size: 12px;
  color: #6f6f6f;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

const WorkoutDataEntryTable = ({
  entriesViewHeight,
  entryRowRef,
  handleInfoClick,
  handleChange,
  handleSubmit,
  supersetWorkout,
  workout,
  formProps,
  isWrvSupersetsEnabled,
}) => {
  const handleAutoSave = (e) => {
    // Do not auth save if the value on the field blurring is not a number
    if (Number.isNaN(Number(e.target.value)) || e.target.value === '') {
      return;
    }

    // Stops negative numbers from being typed in and sent in request
    if (Number(e.target.value) < 0) {
      e.target.value = Math.abs(Number(e.target.value));
    }
    handleChange(e);
    // Using an immediate resolved promise with handleSubmit since its faster than
    // using setTimeout on iPads. Fixes dispappearing workouts when navigating supersets.
    Promise.resolve(1).then(() => {
      handleSubmit();
    });
  };

  const completion = workout.completionCheckbox;

  return (
    <WorkoutTableContainer>
      <div className='entry_row'>
        <FieldsWrapper>
          <FieldsContainer className='scrollable-field-container'>
            {/*
            Create a column of row labels based on the "columns" array.
            (they are really rows not columns)
          */}
            <WorkoutTableColumn>
              <div className='entry_row'>
                <EntryTitles>
                  <div className='title'>Set</div>
                </EntryTitles>
              </div>
              {workout.tableHeaders.map((column) => (
                <div className='entry_row'>
                  <EntryTitles>
                    <div className='title'>{column.valueDescription}</div>
                  </EntryTitles>
                </div>
              ))}
            </WorkoutTableColumn>

            {/*
            For the rest of the columns, loop through the data array for each column or set.
          */}
            {workout.tableData.map((set, i) => (
              <WorkoutTableColumn ref={entryRowRef}>
                <div className='entry_row'>
                  <div className='form-group'>
                    <SupersetIndicator
                      activeSuperset={
                        isWrvSupersetsEnabled &&
                        supersetWorkout &&
                        supersetWorkout.completed_sets === i
                      }
                      entriesViewHeight={entriesViewHeight}
                      completion={completion}
                    />
                    <StaticText>
                      {i + 1}
                      {set.percentage ? (
                        <PercText>{`(${set.percentage}%)`}</PercText>
                      ) : null}
                    </StaticText>
                  </div>
                </div>
                <FieldArray
                  name='values'
                  render={() =>
                    // Loop through the values array in each column
                    set.values.map((setValue, idx) => (
                      <div className='entry_row'>
                        <div className='form-group'>
                          {!setValue.readOnly ? (
                            <FastField
                              className='form-control'
                              name={`body.[${i}].${setValue.valueName}`}
                              onBlur={handleAutoSave}
                              placeholder={setValue.placeholder}
                              tabIndex={i + 1}
                              type='number'
                              step='1'
                            />
                          ) : (
                            <StaticText>{setValue.value}</StaticText>
                          )}
                        </div>
                      </div>
                    ))
                  }
                />
              </WorkoutTableColumn>
            ))}
          </FieldsContainer>
        </FieldsWrapper>
      </div>
      {workout.completionCheckbox && (
        <CompletionEntry
          handleInfoClick={handleInfoClick}
          handleSubmit={formProps.handleSubmit}
          info={workout.text}
          setFieldValue={formProps.setFieldValue}
          values={formProps.values}
        />
      )}
    </WorkoutTableContainer>
  );
};

WorkoutDataEntryTable.propTypes = {
  handleInfoClick: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  formProps: PropTypes.instanceOf(Object).isRequired,
  isWrvSupersetsEnabled: PropTypes.bool.isRequired,
  supersetWorkout: PropTypes.instanceOf(Object).isRequired,
};

export default WorkoutDataEntryTable;
