import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import Spinner from '../../../../../shared/components/Spinner/Spinner';

const SavingSpinner = styled('div')`
  display: flex;
  width: 30px;
  height: 30px;
  right: 30px;
  top: 70px;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const OrganizationSettingsSavingIndicator = () => {
  const isUpdateOrganizationSettingsLoading = useSelector(
    (state) => state.auth.ui.isUpdateOrganizationSettingsLoading,
  );

  if (isUpdateOrganizationSettingsLoading) {
    return (
      <SavingSpinner>
        <Spinner
          saving
          darkTheme
        />
      </SavingSpinner>
    );
  }
  return null;
};

export default OrganizationSettingsSavingIndicator;