import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring/web.cjs';

import useCalendars from '../hooks/useCalendars';
import DeleteModal from './DeleteModal';
import ArchiveModal from './ArchiveModal';
import NoHoverDeleteButton from './NoHoverDeleteButton';
import BigModal from './BigModal';
import ModalUpdateCalendar from './ModalUpdateCalendar';
import ModalCreateForm from './ModalCreateForm';
import ModalConfirmDelete from './ModalConfirmDelete';
import ModalConfirmArchive from './ModalConfirmArchive';
import ActionButton from '../../../../shared/components/ActionButton/ActionButton';

import {
  setActiveReduxModal,
  setSingleActionRedux,
} from '../../ducks/calendarsActions';

const CreateButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-right: 15px;
  }
`;

const DeleteButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 50px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-left: 15px;
  }

  .modal-button {
    color: white;
    display: flex;
    align-items: center;
    background-color: #f24540;
  }
`;

const ArchiveButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 175px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    margin-left: 125px;
  }

  .modal-button {
    color: white;
    display: flex;
    align-items: center;
    background-color: #428BCA;
  }
`;

const ModalHandler = ({
  activeCalendars,
  setSelectedRows,
  setSelectedChildRows,
  isArchivedShowing,
  childrenWithGenParents,
  activeSearchString,
}) => {
  const {
    handleCreateCalendar,
    handleDeleteCalendars,
    handleUpdateCalendar,
    handleUpdateChildCalendar,
    handleCreateChildCalendar,
    handleArchiveCalendars,
    handleUnarchiveCalendars,
  } = useCalendars();

  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const selectedRows = useSelector((state) => state.calendars.data.selectedRows);
  const selectedChildRows = useSelector((state) => state.calendars.data.selectedChildRows);
  const activeModal = useSelector((state) => state.calendars.data.activeModal);
  const currentRow = useSelector((state) => state.calendars.data.currentRow);
  const isSingleAction = useSelector((state) => state.calendars.data.isSingleAction);

  const [isDeleteCalendarButton, setIsDeleteCalendarButton] = useState(false);
  const [isArchiveCalendarButton, setIsArchiveCalendarButton] = useState(false);

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const setSingleAction = (bool) => {
    dispatch(setSingleActionRedux(bool));
  };

  useEffect(() => {
    if (((Object.keys(selectedRows).length > 0)
    || (Object.keys(selectedChildRows).length > 0))
    && !isSingleAction
    ) {
      setIsDeleteCalendarButton(true);
    } else {
      setIsDeleteCalendarButton(false);
    }
  }, [selectedRows, selectedChildRows]);

  useEffect(() => {
    let showButton = false;
    if (!isSingleAction) {
      const selectedRowsKeys = Object.keys(selectedRows);
      const selectedChildRowsKeys = Object.keys(selectedChildRows);
      if (isArchivedShowing) {
        if (selectedRowsKeys.length && !selectedChildRowsKeys.length) {
          showButton = true;
        } else if (selectedRowsKeys.length && selectedChildRowsKeys.length) {
          const childIdsGenParents = childrenWithGenParents.map((child) => child.id);
          let checker = true;
          for (let i = 0; i < selectedChildRowsKeys.length; i += 1) {
            const { parentId } = selectedChildRows[selectedChildRowsKeys[i]];
            const negativeParentId = -parentId;
            const parentIdString = parentId.toString();
            const negativeParentIdString = negativeParentId.toString();
            if (!selectedRowsKeys.includes(parentIdString)
            && !selectedRowsKeys.includes(negativeParentIdString)
            && !childIdsGenParents.includes(parseInt(selectedChildRowsKeys[i], 10))) {
              checker = false;
            }
          }
          if (checker) {
            showButton = true;
          } else {
            showButton = false;
          }
        } else if (!selectedRowsKeys.length && selectedChildRowsKeys.length) {
          let checker = true;
          const childIdsGenParents = childrenWithGenParents.map((child) => child.id);
          for (let i = 0; i < selectedChildRowsKeys.length; i += 1) {
            if (!childIdsGenParents.includes(selectedChildRows[selectedChildRowsKeys[i]].id)) {
              checker = false;
              break;
            }
          }
          if (checker) {
            showButton = true;
          } else {
            showButton = false;
          }
        }
      }
      if (!isArchivedShowing
        && (selectedRowsKeys.length
        || selectedChildRowsKeys.length
        )) {
        showButton = true;
      }
    }
    if (showButton) {
      setIsArchiveCalendarButton(true);
    } else {
      setIsArchiveCalendarButton(false);
    }
  }, [selectedRows, selectedChildRows]);

  const props1 = useSpring({
    to: { opacity: isDeleteCalendarButton ? 1 : 0, zIndex: 1 },
    from: { opacity: 0, zIndex: -1 },
  });

  const props2 = useSpring({
    to: { opacity: isArchiveCalendarButton ? 1 : 0, zIndex: 1 },
    from: { opacity: 0, zIndex: -1 },
  });

  const deleteCalendarsButton = () => (
    <animated.div style={props1}>
      <NoHoverDeleteButton
        bottom
        fullWidth
        cta='Delete'
        className='modal-button'
        large
        rounded
        noBorder
        primary
        noHover
        disabled={!isDeleteCalendarButton}
        onClick={() => {
          setActiveModal('delete_cals_modal');
        }}
      />
    </animated.div>
  );

  const archiveCalendarsButton = () => (
    <animated.div style={props2}>
      <NoHoverDeleteButton
        bottom
        fullWidth
        cta={isArchivedShowing ? 'Unarchive' : 'Archive'}
        className='modal-button'
        large
        noBorder
        primary
        noHover
        rounded
        disabled={!isArchiveCalendarButton}
        onClick={() => (setActiveModal('archive_cals_modal'))}
      />
    </animated.div>
  );

  // custom button for creating a single calendar that only shows
  // when in the active calendars view
  const createCalendarButton = () => {
    if (currentUser.admin && !isArchivedShowing && activeCalendars.length) {
      return (
        <ActionButton
          icon='plus'
          onClick={() => {
            setActiveModal('create_cal_modal');
          }}
        />
      );
    }
    return null;
  };

  const MainContainer = styled('main')`
  `;

  /**
   * Modal handler responds to what the activeModal hook is currently set to.
   * if the activeModal hook is set, it will display the appropriate modal
   */
  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'create_cal_modal') {
      returnModal = (
        <BigModal
          onRequestClose={closeModal}
          isOpen={!!activeModal}
          customButton
        >
          <ModalCreateForm
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            handleCreateCalendar={handleCreateCalendar}
            handleCreateChildCalendar={handleCreateChildCalendar}
            currentUser={currentUser}
            activeCalendars={activeCalendars}
          />
        </BigModal>
      );
    } else if (activeModal === 'update_cal_modal') {
      returnModal = (
        <BigModal
          onRequestClose={closeModal}
          isOpen={!!activeModal}
          customButton
        >
          <ModalUpdateCalendar
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            handleUpdateCalendar={handleUpdateCalendar}
            handleUpdateChildCalendar={handleUpdateChildCalendar}
            currentUser={currentUser}
            modalType={activeModal}
            calendar={currentRow}
            calendars={activeCalendars}
          />
        </BigModal>
      );
    } else if (activeModal === 'delete_cals_modal') {
      returnModal = (
        <DeleteModal
          setSingleAction={setSingleAction}
          onRequestClose={closeModal}
          isSingleAction={isSingleAction}
          setSelectedRows={setSelectedRows}
          setSelectedChildRows={setSelectedChildRows}
          isOpen={!!activeModal}
          customButton
        >
          <ModalConfirmDelete
            handleDeleteDocuments={handleDeleteCalendars}
            isArchivedShowing={isArchivedShowing}
            setActiveModal={setActiveModal}
            setSingleAction={setSingleAction}
            onRequestClose={closeModal}
            setSelectedRows={setSelectedRows}
            setSelectedChildRows={setSelectedChildRows}
            selectedRows={selectedRows}
            selectedChildRows={selectedChildRows}
            currentUser={currentUser}
            isSingleAction={isSingleAction}
            activeSearchString={activeSearchString}
          />
        </DeleteModal>
      );
    } else if (activeModal === 'archive_cals_modal') {
      returnModal = (
        <ArchiveModal
          onRequestClose={closeModal}
          setSingleAction={setSingleAction}
          isSingleAction={isSingleAction}
          isOpen={!!activeModal}
          setSelectedRows={setSelectedRows}
          setSelectedChildRows={setSelectedChildRows}
          customButton
        >
          <ModalConfirmArchive
            handleArchiveCalendars={handleArchiveCalendars}
            handleUnarchiveCalendars={handleUnarchiveCalendars}
            setActiveModal={setActiveModal}
            setSingleAction={setSingleAction}
            onRequestClose={closeModal}
            selectedRows={selectedRows}
            selectedChildRows={selectedChildRows}
            currentUser={currentUser}
            isSingleAction={isSingleAction}
            setSelectedRows={setSelectedRows}
            setSelectedChildRows={setSelectedChildRows}
            isArchivedShowing={isArchivedShowing}
            activeSearchString={activeSearchString}
          />
        </ArchiveModal>
      );
    }
    return returnModal;
  };

  return (
    <MainContainer>
      <CreateButtonWrapper>
        {createCalendarButton()}
      </CreateButtonWrapper>
      <DeleteButtonWrapper>
        {deleteCalendarsButton()}
      </DeleteButtonWrapper>
      <ArchiveButtonWrapper>
        {archiveCalendarsButton()}
      </ArchiveButtonWrapper>
      {modalHandler()}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
  singleArchive: PropTypes.bool.isRequired,
  activeCalendars: PropTypes.instanceOf(Array).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  setSelectedChildRows: PropTypes.func.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
};

export default ModalHandler;
