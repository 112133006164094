/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';
import IcomoonReact from 'icomoon-react';
import ImportedDataModal from '../../../../../shared/components/ImportedDataModal/ImportedDataModal';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

import { setIsIntegrationModalShowing } from '../../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../../shared/GlobalStyles';

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	border-radius: 24px;
	margin-top: 15px;
	padding: 20px;
	box-shadow: ${BoxShadow};
	background: white;
`;

const BoxTitle = styled('div')`
	display: flex;
	height: 25px;
  margin-bottom: 10px;

  svg {
    margin-top: 1px;
  }
`;

const BoxTitleText = styled('div')`
	font-size: 14px;
	font-weight: 700;
	margin-left: 5px;
	font-family: 'Nunito Sans';
`;

const TextBoxContainerGradient = styled('div')`
display: flex;
padding: 20px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 9px;
background: linear-gradient(270deg, #5B86E5 0%, #36D1DC 100%);
box-shadow: 0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 4px 8px 0px rgba(48, 49, 51, 0.10);
color: #fff;
font-family: Nunito Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
cursor: pointer;
`;

const TextBoxContainer = styled('div')`
display: flex;
padding: 20px 16px;
justify-content: space-between;
align-items: center;
align-self: stretch;
border-radius: 9px;
box-shadow: 0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 4px 8px 0px rgba(48, 49, 51, 0.10);
font-family: Nunito Sans;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 150%; /* 24px */
`;

const ShimmerBox = styled('div')`
  width: 100%;
  height: 65px;
  border-radius: 24px;
`;

const ImportedDataBox = () => {
  const importedDataIntegrations = useSelector((state) => state.workouts.data.importedDataIntegrations);
  const importedDataIntegrationsLoading = useSelector((state) => state.workouts.ui.importedDataIntegrationsLoading);
  const isIntegrationModalShowing = useSelector((state) => state.workouts.ui.isIntegrationModalShowing);

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    dispatch(setIsIntegrationModalShowing(true));
  };

  if (importedDataIntegrationsLoading) {
    return (
      <Container>
        <BoxTitle>
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='download'
            color='black'
          />
          <BoxTitleText>Imported Data</BoxTitleText>
        </BoxTitle>
        <ShimmerBox>
          <Shimmer>
            <div style={{ width: '100%', height: '100%', borderRadius: '24px' }} />
          </Shimmer>
        </ShimmerBox>
      </Container>
    );
  }
  return (
    <Container>
      <BoxTitle>
        <IcomoonReact
          iconSet={iconSet}
          size={12}
          icon='download'
          color='black'
        />
        <BoxTitleText>Imported Data</BoxTitleText>
      </BoxTitle>
      {importedDataIntegrations?.length > 0 ? (
        <TextBoxContainerGradient onClick={handleButtonClick}>
          View Imported Data
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='right-arrow'
            color='#fff'
          />
        </TextBoxContainerGradient>
      ) : (<TextBoxContainer>No Imported Data for this session.</TextBoxContainer>)}
      {isIntegrationModalShowing && <ImportedDataModal />}
    </Container>
  );
};

ImportedDataBox.propTypes = { };

export default ImportedDataBox;
