/* eslint-disable no-mixed-operators */
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import {
  setActiveReport,
  fetchActivityReport,
} from '../ducks/reportingActions';
import HeaderText from '../../../shared/components/HeaderText/HeaderText';
import Card from '../../../shared/components/Card/Card';
import Text from '../../../shared/components/Text/Text';
import { reportInteractionTracker } from '../../../shared/utils/amplitudeHelper';
import NewDashBanner from '../../../shared/_TBComponents/NewDashBanner';

const MainContainer = styled('main')`
  flex: 10;
  overflow: auto;
`;

const GridContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: 30px;
`;

const HeaderRow = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 40px;
  margin-left: 30px;
  h1 {
    text-transform: capitalize;
    margin-bottom: 8px;
  }
`;

const CardWrapper = styled('div')`
  padding-right: 20px;
`;

const ReportingGrid = ({ flags }) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sidebarFilter = useSelector(
    (state) => state.reporting.data.sidebarFilter,
  );
  const filterSelections = useSelector(
    (state) => state.reporting.data.filterSelections,
  );

  const dispatch = useDispatch();

  const handleReportClick = (value) => {
    dispatch(setActiveReport(value));
  };

  /*
  Activity Report first needs to check if any athletes/groups/cals were selected
  */
  const handleActivityReportClick = (value) => {
    if (sidebarFilter.filterIds.length === 0) {
      alert('Please select a filter first');

      // Amplitude tracker.
      reportInteractionTracker(
        'Report Error',
        'Activity',
        'Reporting Filter has not been selected.',
      );
    } else {
      dispatch(setActiveReport(value));
      dispatch(fetchActivityReport(sidebarFilter, currentUser.accountCode));

      // Amplitude event
      reportInteractionTracker(
        'Report Clicked',
        'Activity',
        '',
        sidebarFilter.filterIds.length,
        sidebarFilter.filterType.indicator,
      );
    }
  };

  const hasAccessToReport = (report) => {
    const accessLevel = currentUser.organizationAccessLevel;

    if (report === 'questionnaire') return true;
    if (accessLevel === 3) {
      if (
        report === 'completion'
        || report === 'workout_results'
        || report === 'progress'
        || report === 'max'
        || report === 'evaluation'
        || report === 'comparison'
      ) {
        return true;
      }
      return false;
    }
    if (
      report === 'activity'
      || report === 'completion'
      || report === 'workout_results'
    ) {
      return true;
    }
    if (report === 'max' || report === 'progress') {
      if (accessLevel > 1) {
        return true;
      }
      return false;
    }
    if (report === 'rep_load' || report === 'opt_out_notes') {
      if (accessLevel > 4) {
        return true;
      }
      return false;
    }
    if (report === 'raw_data' || report === 'assessment') {
      if (accessLevel > 5) {
        return true;
      }
      return false;
    }
    if (report === 'evaluation' || report === 'comparison') {
      if (accessLevel >= 4) {
        return true;
      }
      return false;
    }
    return false;
  };

  const onClickReport = (report) => {
    if (hasAccessToReport(report)) {
      handleReportClick(report);

      // Amplitude event
      reportInteractionTracker(
        'Report Clicked',
        report,
        '',
        sidebarFilter.filterIds.length,
        sidebarFilter.filterType.indicator,
      );
    }
  };

  const openNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onClickLegacyReport = (report) => {
    if (hasAccessToReport(report)) {
      let reportId;
      switch (report) {
        case 'completion':
          reportId = 1;
          break;
        case 'workout_results':
          reportId = 2;
          break;
        case 'progress':
          reportId = 3;
          break;
        case 'opt_out_notes':
          reportId = 5;
          break;
        case 'rep_load':
          reportId = 7;
          break;
        case 'comparison':
          reportId = 10;
          break;
        case 'assessment':
          reportId = 13;
          break;
        default:
          reportId = null;
          break;
      }

      const groupingType = sidebarFilter.filterType.value - 1;

      const groupingIds = sidebarFilter.filterIds.length
        ? sidebarFilter.filterIds.map((selectedItem) => selectedItem.id)
        : [];
      let groupingIdsString = '';
      if (groupingIds.length > 0) {
        groupingIds.forEach((groupingId) => {
          groupingIdsString += `&groupingIds[]=${groupingId}`;
        });
      }

      const queryString = `?reportId=${reportId}&groupingType=${groupingType}${groupingIdsString}`;

      const url = `${process.env.PHP_APP_URL
        || 'http://app.teambuildr.com'}/reporting${queryString}`;
      openNewTab(url);

      // Amplitude event
      reportInteractionTracker(
        'Legacy Report Clicked',
        report,
        '',
        sidebarFilter.filterIds.length,
        sidebarFilter.filterType.indicator,
      );
    }
  };

  return (
    <MainContainer>
      <NewDashBanner
        height='auto'
        fontSize='16px'
        message='🎉 The new Comparison Report is here! Enjoy the new design and a better experience starting today.'
      />
      <HeaderRow>
        <HeaderText fontWeight={300} letterSpacing='normal'>
          Reporting
        </HeaderText>
        <Text
          fontWeight={700}
          color='#FF0000'
          fontSize='16px'
          lineHeight='20px'
        >
          Athletes not in groups will not be visible in reporting.
        </Text>
        <Text
          fontWeight={300}
          fontSize='14px'
          lineHeight='20px'
          marginTop='10px'
        >
          *All non-legacy reports have been updated with enhanced functionality.
          As reports are updated, legacy status will be removed.
        </Text>
      </HeaderRow>
      {(sidebarFilter.filterType.value === 1
        && filterSelections.length === 0
        && currentUser.calAccess && currentUser.calAccess.length === 0)
      || currentUser.reporting === 0 ? (
        <HeaderText>No Access</HeaderText>
        ) : (
          <GridContainer>
            <CardWrapper>
              <Card
                title='Activity'
                icon='journal'
                description={
                'Table and charts summarizing each athlete\'s activity over the past 7 days'
              }
                onClick={() => handleActivityReportClick('activity')}
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Completion Report'
                description='Shows completion percentage of each athlete over time.'
                icon='checkmark'
                onClick={() => onClickLegacyReport('completion')}
                isLegacy
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Workout Results'
                description='Detailed list of workout results for a selected period of time'
                icon='list'
                onClick={() => (flags.workoutResultsReport
                  ? onClickReport('workout_results')
                  : onClickLegacyReport('workout_results'))}
                isLegacy={!flags.workoutResultsReport}
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Progress Report'
                description='Creates a one page report on progress for selected exercises.'
                icon='chart'
                onClick={() => onClickLegacyReport('progress')}
                locked={!hasAccessToReport('progress')}
                lockText='Silver'
                lockColor='#c0c0c0'
                isLegacy
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Max'
                description='Chart of each athlete and their
            maxes for selected exercises.'
                icon='trophy'
                onClick={() => onClickReport('max')}
                locked={!hasAccessToReport('max')}
                lockText='Silver'
                lockColor='#c0c0c0'
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Questionnaire'
                description={
                'Table and charts summarizing each athlete\'s fatigue state based on a series of questions.'
              }
                icon='science'
                onClick={() => onClickReport('questionnaire')}
                locked={!hasAccessToReport('questionnaire')}
                lockText='Gold'
                lockColor='#ff9700'
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Evaluation Report'
                description='Compare two evaluation periods to view group and individual changes'
                icon='note'
                onClick={() => onClickReport('evaluation')}
                locked={!hasAccessToReport('evaluation')}
                lockText='Gold'
                lockColor='#ff9700'
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Comparison Report'
                description='Compare athlete max results over time.'
                icon='history'
                onClick={() => onClickReport('comparison')}
                locked={!hasAccessToReport('comparison')}
                lockText='Gold'
                lockColor='#ff9700'
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Opt Out/Notes Report'
                description='Lists "Opt Out" information & notes for athletes over time'
                icon='warning'
                locked={!hasAccessToReport('opt_out_notes')}
                lockText='Platinum'
                lockColor='#e5e4e2'
                onClick={() => (flags.optOutReport
                  ? onClickReport('opt_out_notes')
                  : onClickLegacyReport('opt_out_notes'))}
                isLegacy={!flags.optOutReport}
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Rep & Load Report'
                description="Breaks down athlete's rep count and volume load over time."
                icon='chart-horizontal'
                onClick={() => onClickLegacyReport('rep_load')}
                locked={!hasAccessToReport('rep_load')}
                lockText='Platinum'
                lockColor='#e5e4e2'
                isLegacy
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Assessment Report'
                description='Find athlete strengths & weaknesses based on exercise tags.'
                icon='magnifying-glass'
                onClick={() => onClickLegacyReport('assessment')}
                locked={!hasAccessToReport('assessment')}
                lockText='Platinum'
                lockColor='#e5e4e2'
                isLegacy
              />
            </CardWrapper>
            <CardWrapper>
              <Card
                title='Raw Data'
                description='Download raw workout data into a CSV sheet.'
                icon='raw-data-report'
                onClick={() => onClickReport('raw_data')}
                locked={!hasAccessToReport('raw_data')}
                lockText='Platinum'
                lockColor='#e5e4e2'
              />
            </CardWrapper>
          </GridContainer>
        )}
    </MainContainer>
  );
};

ReportingGrid.propTypes = {
  flags: PropTypes.instanceOf(Object).isRequired,
};

export default withLDConsumer()(ReportingGrid);
