import styled from '@emotion/styled';
import {
  space, color, layout, flexbox,
} from 'styled-system';

const Wrapper = styled('div')`
  ${color}
  ${flexbox}
  ${layout}
  margin-top: 8px;
  position: relative;
  ${space}
`;

export default Wrapper;
