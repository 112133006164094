import { connect } from 'react-redux';
import PinPad from '../presentational/PinPad';
import { closePinPad, submitPinCode } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteId: state[ownProps.namespace].athleteId,
  isPinPadOpen: state[ownProps.namespace].isPinPadOpen,
  isPinLoading: state[ownProps.namespace].isPinLoading,
  isPinRejected: state[ownProps.namespace].isPinRejected,
});

const mapDispatchToProps = dispatch => ({
  closePinPad: (namespace) => {
    dispatch(closePinPad(namespace));
  },
  submitPinCode: (namespace, values) => {
    dispatch(submitPinCode(namespace, values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PinPad);
