import React from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';

import Spinner from './Spinner';

const AnimatedSaving = ({
  namespace,
}) => {
  const isWorkoutSaving = useSelector(state => state[namespace].isWorkoutSaving);

  const props = useSpring({
    to: { opacity: isWorkoutSaving ? 1 : 0 },
    from: { opacity: 0 },
  });

  return (
    <animated.div style={props}>
      <Spinner saving />
    </animated.div>
  );
};

AnimatedSaving.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default AnimatedSaving;
