import {
  FETCH_GROUPS_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_PERFORMANCE_OVERVIEW_START,
  FETCH_PERFORMANCE_OVERVIEW_FULFILLED,
  FETCH_PERFORMANCE_OVERVIEW_REJECTED,
  SET_IS_METRIC_INFO_MODAL_SHOWING,
  SET_IS_FP_LEGEND_INFO_MODAL_SHOWING,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  FETCH_METRIC_CARD_DATA_START,
  FETCH_METRIC_CARD_DATA_FULFILLED,
  FETCH_METRIC_CARD_DATA_REJECTED,
  SET_FORCE_PLATE_VIEW,
  FETCH_GROUP_ANALYSIS_START,
  FETCH_GROUP_ANALYSIS_FULFILLED,
  FETCH_GROUP_ANALYSIS_REJECTED,
  SET_SELECTED_ATHLETE_GROUP,
  SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING,
  SET_IS_METRIC_FILTERS_GROUP_SHOWING,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE,
  SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP,
  SET_FLAGGED_ATHLETES_VIEW,
} from './actionTypes';

const initialState = {
  forcePlateView: 'performance',
  infoModalMetric: '',
  isAthleteSelectedGroup: false,
  isAthleteSelectedPerformance: false,
  isFlaggedAthletesModalShowingGroup: false,
  isFlaggedAthletesModalShowingPerformance: false,
  isFlaggedAthletesView: false,
  isGroupAnalysisLoading: false,
  isGroupsLoading: false,
  isLegendInfoModalShowing: false,
  isMetricFiltersGroupShowing: false,
  isMetricFiltersPerformanceShowing: false,
  isMetricInfoModalShowing: false,
  isMetricLoading: false,
  isPerformanceLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_START: {
      return {
        ...state,
        isPerformanceLoading: true,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_FULFILLED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_PERFORMANCE_OVERVIEW_REJECTED: {
      return {
        ...state,
        isPerformanceLoading: false,
      };
    }
    case FETCH_METRIC_CARD_DATA_START: {
      return {
        ...state,
        isMetricLoading: true,
      };
    }
    case FETCH_METRIC_CARD_DATA_FULFILLED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_METRIC_CARD_DATA_REJECTED: {
      return {
        ...state,
        isMetricLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_START: {
      return {
        ...state,
        isGroupAnalysisLoading: true,
      };
    }
    case FETCH_GROUP_ANALYSIS_FULFILLED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_REJECTED: {
      return {
        ...state,
        isGroupAnalysisLoading: false,
      };
    }
    case SET_IS_METRIC_INFO_MODAL_SHOWING: {
      return {
        ...state,
        infoModalMetric: action.payload.metric,
        isMetricInfoModalShowing: action.payload.bool,
      };
    }
    case SET_IS_FP_LEGEND_INFO_MODAL_SHOWING: {
      return {
        ...state,
        isLegendInfoModalShowing: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        isAthleteSelectedPerformance: action.payload.bool,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        isAthleteSelectedGroup: action.payload.bool,
      };
    }
    case SET_FORCE_PLATE_VIEW: {
      return {
        ...state,
        forcePlateView: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_PERFORMANCE_SHOWING: {
      return {
        ...state,
        isMetricFiltersPerformanceShowing: action.payload,
      };
    }
    case SET_IS_METRIC_FILTERS_GROUP_SHOWING: {
      return {
        ...state,
        isMetricFiltersGroupShowing: action.payload,
      };
    }
    case SET_SHOW_FLAGGED_ATHLETES_MODAL_PERFORMANCE: {
      return {
        ...state,
        isFlaggedAthletesModalShowingPerformance: action.payload,
      };
    }
    case SET_SHOW_FLAGGED_ATHLETES_MODAL_GROUP: {
      return {
        ...state,
        isFlaggedAthletesModalShowingGroup: action.payload,
      };
    }
    case SET_FLAGGED_ATHLETES_VIEW: {
      return {
        ...state,
        isFlaggedAthletesView: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
