import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../images/teambuildr-selection.json';
import { navigationTracker } from '../../utils/amplitudeHelper';

export const DropdownListContainer = styled('div')`
  display: flex;
  /* flex-wrap: wrap; */
  position: relative;
  flex-direction: column;
  height: 300px;
  overflow-x: scroll;
  padding: 10px 0px;
  background-color: ${(props) => (props.customBackground ? props.customBackground : props.theme.backgroundColorL2)};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
  margin-left: 15px;
  overflow: hidden;
  & span {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

export const DropdownItem = styled('a')`
  min-width: 200px;
  font-family: 'Nunito Sans';
  font-size: ${(props) => (props.small ? '12px' : '13px')};
  font-weight: 900;
  letter-spacing: 1.1px;
  color: ${(props) => (props.light ? '#444444 !important' : '#FFFFFF')};
  cursor: pointer;
  text-transform: uppercase;
  padding: ${(props) => {
    const isExtraSmallPadding = props.extraSmallPadding ? '5px 10px' : '10px 20px';
    return props.smallPadding ? '8px 15px' : isExtraSmallPadding;
  }};
  transition: all 200ms ease-in-out;
  overflow: hidden;
  ${(props) => props.spacer && (
    'border-top: 1px solid #444444; border-bottom: 1px solid #444444;'
  )}
  ${(props) => ((props.isVisible === 0 || props.isVisible === false) && (
    'display: none;'
  ))}

  &:hover {
    background-color: ${(props) => (props.customHover ? props.customHover : props.theme.colorAccent)};
    opacity: 1;
    color: ${(props) => {
    if (props.customHoverTextColor) return `${props.customHoverTextColor} !important`;
    return props.theme.isAccentDarkTheme ? 'white' : '#444444';
  }};
    svg {
      path {
        fill: white;
      }
    }
  }

  &:first-of-type {
    border-top: 0px;
  }

  &:last-of-type {
    border-bottom: 0px;
  }

  svg {
    margin-right: 10px;
    path {
      fill: ${(props) => (props.light ? '#444444' : '#FFFFFF')};
      transition: all 200ms ease-in-out;
    }
  }
`;

class DropdownList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      data,
      componentClass,
      customBackground,
      customHover,
      customHoverTextColor,
      extraSmallPadding,
      handleActiveModal,
      handleLink,
      handleReactLink,
      handleLogOut,
      icons,
      light,
      small,
      smallPadding,
    } = this.props;

    return (
      <DropdownListContainer
        className={componentClass}
        customBackground={customBackground}
        small={small}
      >
        {/* <DropdownItem
          data-name=''
          onClick={this.handleDropdownClick}
        >
          All Athletes
        </DropdownItem> */}
        {data.map((group) => {
          const isLogOut = group.isLogOut
            ? (e) => (handleLogOut(e))
            : (e) => (handleLink(group.href));

          const isReactLink = group.isReactLink
            ? (e) => (handleReactLink(group.href, e), navigationTracker(group.name))
            : isLogOut;

          return (
            <DropdownItem
              key={group.id}
              className={`dropdown-link ${group.customClass}`}
              customHover={customHover}
              customHoverTextColor={customHoverTextColor}
              data-name={group.name}
              extraSmallPadding={extraSmallPadding}
              href={group.href}
              isVisible={group.isVisible}
              light={light}
              onClick={group.modal ? (e) => (handleActiveModal(group.name, e), navigationTracker(group.name)) : isReactLink}
              small={small}
              smallPadding={smallPadding}
              spacer={group.spacer}
            >
              {icons && (
                <IcomoonReact
                  iconSet={iconSet}
                  size={12}
                  icon={group.icon && group.icon}
                />
              )}
              {group.name}
            </DropdownItem>
          );
        })}
      </DropdownListContainer>
    );
  }
}

DropdownList.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleActive: PropTypes.instanceOf(Function).isRequired,
};

export default DropdownList;
