import {
  FETCH_ATHLETE_REPORT_CARD_START,
  FETCH_ATHLETE_REPORT_CARD_FULFILLED,
  FETCH_ATHLETE_REPORT_CARD_REJECTED,
  FETCH_UNSUBSCRIBE_START,
  FETCH_UNSUBSCRIBE_FULFILLED,
  FETCH_UNSUBSCRIBE_REJECTED,
  FETCH_REPORT_CARD_LINK_START,
  FETCH_REPORT_CARD_LINK_FULFILLED,
  FETCH_REPORT_CARD_LINK_REJECTED,
} from './actionTypes';

const initialState = {
  isAthleteReportCardLoading: true,
  isUnsubscribing: true,
  isFetchingShareLink: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATHLETE_REPORT_CARD_START: {
      return {
        ...state,
        isAthleteReportCardLoading: true,
      };
    }
    case FETCH_ATHLETE_REPORT_CARD_FULFILLED: {
      return {
        ...state,
        isAthleteReportCardLoading: false,
      };
    }
    case FETCH_ATHLETE_REPORT_CARD_REJECTED: {
      return {
        ...state,
        isAthleteReportCardLoading: false,
      };
    }
    case FETCH_UNSUBSCRIBE_START: {
      return {
        ...state,
        isUnsubscribing: true,
      };
    }
    case FETCH_UNSUBSCRIBE_FULFILLED: {
      return {
        ...state,
        isUnsubscribing: false,
      };
    }
    case FETCH_UNSUBSCRIBE_REJECTED: {
      return {
        ...state,
        isUnsubscribing: false,
      };
    }
    case FETCH_REPORT_CARD_LINK_START: {
      return {
        ...state,
        isFetchingShareLink: true,
      };
    }
    case FETCH_REPORT_CARD_LINK_FULFILLED: {
      return {
        ...state,
        isFetchingShareLink: false,
      };
    }
    case FETCH_REPORT_CARD_LINK_REJECTED: {
      return {
        ...state,
        isFetchingShareLink: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
