import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { componentWillAppendToBody } from 'react-append-to-body';
import { Global, css } from '@emotion/core';

import SubText from '../../../../shared/components/SubText/SubText';
import Table from '../../../../shared/components/Table/Table';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import useWindowSize from '../../../../shared/hooks/useWindowSize';

import {
  setSelectedChildRowsRedux,
  setSingleActionRedux,
  setSelectedChildrenRedux,
} from '../../ducks/calendarsActions';

const HomebrewCheckbox = styled('div')`
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.26);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const DropDownMenuWrapper = styled('div')`
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  color: #333333;
  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    span {
      color: #999999;
    }
    .drop-icon-text {
      margin-left: 10px;
      padding-top: 2px;
    }
    :hover {
      background: #006a65;
      color: white;
      span {
        color: white;
      }
      svg {
        fill: white;
      }
    }
  }
  .dropdown-menu {
    padding: 0px;
  }
  .dropdown-divider {
    margin: 0px;
  }
`;

const DropdownItemContainer = styled('div')`

`;

/**
   * this is a custom dropdown toggle for the dropdown menu
   * it causes the toggle to appear as the 3 dots instead of the defualt
   * graphic, which is some kind of button I believe
   */
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <Link
    href=''
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <IcomoonReact
      iconSet={iconSet}
      size={25}
      icon='dots'
      color='grey'
    />
  </Link>
));

const conditionalRowStyles = [
  {
    when: (row) => row,
    style: {
      paddingLeft: '28px',
      width: ('calc(100% - 30px)'),
      marginLeft: '30px',
    },
  },
];

const customTableStyle = {
  overflowX: 'hidden',
};

const UserCount = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    /* background: grey; */
    /* background-color: rgba(255, 0, 0, 0.6); */
  }
`;

const ChildTable = ({
  data,
  activeSearchString,
  isArchivedShowing,
  isChildChecksDisabled,
  selectedRows,
  setCurrentRow,
  setActiveModal,
  selectedChildRows,
}) => {
  const dispatch = useDispatch();
  const windowWidth = useWindowSize().width;

  const currentChildCalendars = {};
  data.newChildCalendars.forEach((childCalendar) => {
    currentChildCalendars[childCalendar.id] = childCalendar;
  });

  const isSingleAction = useSelector((state) => state.calendars.data.isSingleAction);

  const checkedResolver = (childCalendars) => [...childCalendars];

  const setSelectedChildRows = (rowSelection) => {
    dispatch(setSelectedChildRowsRedux(rowSelection));
  };

  const setSingleAction = (bool) => {
    dispatch(setSingleActionRedux(bool));
  };

  const selectableRowsComponentProps = {
    color: 'primary',
    size: 'small',
    disabled: isChildChecksDisabled,
    indeterminate: isChildChecksDisabled && !isSingleAction,
    indeterminateIcon:
  <HomebrewCheckbox>
    <IcomoonReact
      iconSet={iconSet}
      size={11}
      icon='checkmark'
      color='white'
    />
  </HomebrewCheckbox>,
  };

  /**
   * this function keeps track of which rows are selected, and reduces the selection
   * to an array of calendar IDs which can then be passed to archive or delete functions
   */
  const handleSelectedRowChange = useCallback((selections) => {
    const oldDictionary = selectedChildRows;
    const selectedChildren = {};
    selections.selectedRows.forEach((selection) => {
      selectedChildren[selection.id] = selection;
    });
    const finalDictionary = {};
    Object.keys(oldDictionary).forEach((id) => {
      if (!Object.keys(currentChildCalendars).includes(id.toString())) {
        finalDictionary[id] = oldDictionary[id];
      }
      if (Object.keys(currentChildCalendars).includes(id.toString())) {
        if (Object.keys(selectedChildren).includes(id.toString())) {
          finalDictionary[id] = oldDictionary[id];
        }
      }
    });
    Object.keys(selectedChildren).forEach((id) => {
      if (!Object.keys(finalDictionary).includes(id.toString())) {
        finalDictionary[id] = selectedChildren[id];
      }
    });
    setSelectedChildRows(finalDictionary);
  });

  const DropdownMenu = ({
    row,
  }) => (
    <>
      <Global
        styles={css`
          .dropdown-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            transition: none;
            span {
              color: #999999;
            }
            .drop-icon-text {
              margin-left: 10px;
              padding-top: 2px;
            }
            :hover {
              background: #00b371;
              color: white;
              span {
                color: white;
              }
              svg {
                fill: white;
              }
            }
          }
  
          .dropdown-menu {
            padding: 0px;
          }
  
          .dropdown-divider {
            margin: 0px;
          }
        `}
      />
      <Dropdown.Menu>
        {!isArchivedShowing ? (
          <Dropdown.Item
            className='dropdown-item'
            onClick={() => {
              setCurrentRow(row);
              setActiveModal('update_cal_modal');
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={12}
              icon='pencil'
            />
            <SubText
              className='drop-icon-text'
              fontSize={14}
            >
              Update
            </SubText>
          </Dropdown.Item>
        ) : null}
        <Dropdown.Divider />
        {!isArchivedShowing ? <Dropdown.Divider /> : null}
        <DropdownItemContainer>
          {!isArchivedShowing ? (
            <Dropdown.Item onClick={() => {
              const object = {};
              const rowId = row.id;
              object[rowId] = row;
              setSelectedChildRows(object);
              setSingleAction(true);
              setActiveModal('archive_cals_modal');
            }}
            >
              {isArchivedShowing ? (
                <>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    icon='visible'
                  />
                  <SubText
                    className='drop-icon-text'
                    fontSize={14}
                  >
                    Unarchive
                  </SubText>
                </>
              ) : (
                <>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={12}
                    icon='invisible'
                  />
                  <SubText
                    className='drop-icon-text'
                    fontSize={14}
                  >
                    Archive
                  </SubText>
                </>
              )}
            </Dropdown.Item>
          ) : null}
        </DropdownItemContainer>
        <Dropdown.Divider />
        <Dropdown.Item onClick={() => {
          const object = {};
          const rowId = row.id;
          object[rowId] = row;
          setSelectedChildRows(object);
          setSingleAction(true);
          setActiveModal('delete_cals_modal');
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={12}
            icon='trashcan'
          />
          <SubText
            className='drop-icon-text'
            fontSize={14}
          >
            Delete
          </SubText>
        </Dropdown.Item>
      </Dropdown.Menu>
    </>
  );

  DropdownMenu.propTypes = {
    row: PropTypes.instanceOf(Object).isRequired,
  };

  const AppendDropdownList = componentWillAppendToBody(DropdownMenu);

  const calendarUsersUrl = (calId) => `${process.env.PHP_APP_URL}/manage_users?calId=${calId}`;

  /**
   * cells for the table:
   * orientation arrow, icon, name, user count, created date, workout visability, dropdown toggle
   */
  const columns = useMemo(() => [
    {
      cell: (row) => {
        if (!row.parentId) {
          return (
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='calendar'
            />
          );
        }
        if (row.parentId) {
          return (
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='sub-arrow'
            />
          );
        }
        return null;
      },
      width: '65px',
      omit: windowWidth <= 768,
    },
    {
      cell: (row) => {
        if (row.parentName && activeSearchString.length) {
          return `${row.parentName}/${row.name}`;
        }
        return row.name;
      },
      name: 'Name',
      sortable: true,
      style: {
        'font-weight': 'bold',
      },
    },
    {
      name: 'User Count',
      cell: (row) => <UserCount onClick={() => window.open(calendarUsersUrl(row.id), '_blank')}>{row.userCount}</UserCount>,
      selector: 'userCount',
      sortable: true,
      style: {
        'text-align': 'center',
        'margin-right': '10px',
      },
      center: true,
      omit: windowWidth <= 768,
    },
    {
      name: 'Visability',
      selector: 'workoutVisibilityDescription',
      sortable: true,
      center: true,
      style: {
        'text-align': 'center',
        'margin-right': '10px',
      },
      omit: windowWidth <= 768,
    },
    {
      name: 'Date Created',
      selector: ((row) => (row.createdAt ? row.createdAt : '-')),
      sortable: true,
      style: {
        'text-align': 'center',
        'margin-right': '25px',
      },
      center: true,
      omit: windowWidth <= 768,
    },
    {
      cell: (row) => {
        // only shows dropdown toggle if the row isn't a generic calendar
        if (row.id && !row.generic) {
          /**
           * defines the dropdown with the correct options
           * (only shows update option if calendar is active)
           * swaps archive for unarchive if in archived view
           */
          return (
            <DropDownMenuWrapper>
              <Dropdown
                bsPrefix='trick-fix'
              >
                <Dropdown.Toggle as={CustomToggle} />
                <AppendDropdownList
                  row={row}
                  setSingleAction={setSingleAction}
                />
              </Dropdown>
            </DropDownMenuWrapper>
          );
        }
        return null;
      },
      right: true,
      width: '75px',
    },
  ]);

  columns.propTypes = {
    row: PropTypes.instanceOf(Object).isRequired,
  };

  return (
    <Table
      data={checkedResolver(data.newChildCalendars)}
      selectableRows
      onSelectedRowsChange={(rows) => handleSelectedRowChange(rows)}
      highlightOnHover
      pointerOnHover
      onRowClicked={(row) => {
        if (!isArchivedShowing) {
          setCurrentRow(row);
          setActiveModal('update_cal_modal');
        }
      }}
      noHeader
      selectableRowSelected={(row) => (
        row.id ? Object.keys(selectedChildRows).includes(row.id.toString())
        && !isSingleAction : null)}
      columns={columns}
      style={customTableStyle}
      conditionalRowStyles={conditionalRowStyles}
      noTableHead
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={selectableRowsComponentProps}
    />
  );
};

ChildTable.propTypes = {
  data: PropTypes.instanceOf(Object),
  activeSearchString: PropTypes.string.isRequired,
  isArchivedShowing: PropTypes.bool.isRequired,
  setCurrentRow: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  setIsUnarchivable: PropTypes.func.isRequired,
  childrenWithGenParents: PropTypes.instanceOf(Array).isRequired,
};

ChildTable.defaultProps = {
  data: undefined,
};

export default React.memo(ChildTable);
