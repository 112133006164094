/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsMetricInfoModalShowing } from '../../ducks/forcePlateDashboardActions';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -135px 0px 0px -225px;
  background-color: #FFFFFF;
  width: 500px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Header = styled('div')`
  font-size: 16px;
  color: #9E9E9E;
`;

const Title = styled('div')`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Description = styled('div')`
  font-size: 16px;
  white-space: pre-line;
`;

const MetricInfoModal = () => {
  const infoModalMetric = useSelector(
    (state) => state.forcePlateDashboard.ui.infoModalMetric,
  );

  const jumpDescription = 'Jump Height refers to the peak vertical displacement achieved by an athlete and can be determined using a variety of metrics and calculations. To better understand how Jump Height is estimated/calculated, please refer to your device\'s support guides.';
  const takeoffDescription = `Takeoff Velocity is the peak speed an athlete reached during the takeoff phase or beginning of a jump.
    
    This metric may also be referred to as "Vertical Velocity at Takeoff".`;
  const peakDescription = `Peak Power is the maximal power output achieved during the propulsive portion of a jump.
    
    This metric may also be referred to as "Peak Propulsive Power".`;
  const concentricDescription = `Concentric Impulse refers to the displacement between left and right limbs during the propulsive portion of a jump. A negative value (-) means the athlete demonstrates more displacement towards their left side, while a positive value (+) means the athlete demonstrates more displacement towards the right side.
    
    This metric may also be referred to as “L/R Propulsive Impulse Index” and “Concentric Impulse Asymmetry [% L,R]”.`;
  const mRSIDescription = `Modified Reactive Strength Index (mRSI) refers to the ratio of Jump Height and Time to Takeoff. 
    
    This metric may also be referred to as “Reactive Strength Index Modified”.`;

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsMetricInfoModalShowing(false, ''));
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <Header>Standardized Name</Header>
        <Button
          icon='remove'
          iconOnly
          onClick={handleClose}
        />
      </HeaderContainer>
      <Title>{infoModalMetric}</Title>
      <Description>
        {infoModalMetric === 'Jump Height' ? jumpDescription
          : infoModalMetric === 'Takeoff Velocity' ? takeoffDescription
            : infoModalMetric === 'Peak Power' ? peakDescription
              : infoModalMetric === 'Concentric Impulse (L/R)' ? concentricDescription
                : infoModalMetric === 'mRSI' ? mRSIDescription
                  : null}
      </Description>
    </ModalContainer>
  );
};

export default MetricInfoModal;
