import {
  FETCH_LATEST_SESSION_FULFILLED,
  FETCH_LATEST_SESSION_REJECTED,
  FETCH_MULTIDAY_SESSION_FULFILLED,
  FETCH_MULTIDAY_SESSION_REJECTED,
  FETCH_SESSIONS_FULFILLED,
  FETCH_SESSIONS_REJECTED,
  SET_SUB_ACTIVE_PROFILE_PIC_WEBCAM_CATURE_MODAL,
  IS_SKETCH_PICKER_POPUP_OPEN,
} from './actionTypes';

const initialState = {
  latestSession: {},
  me: {},
  sessions: [],
  activeSubModal: '',
  isSketchPickerPopupOpen: { colorCategory: '', bool: false },
  notificationSettings: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LATEST_SESSION_FULFILLED: {
      return {
        ...state,
        latestSession: action.payload,
        latestSessionError: null,
      };
    }
    case FETCH_LATEST_SESSION_REJECTED: {
      return {
        ...state,
        latestSession: null,
        latestSessionError: action.payload,
      };
    }
    case FETCH_MULTIDAY_SESSION_FULFILLED: {
      return {
        ...state,
        latestSession: action.payload,
        latestSessionError: null,
      };
    }
    case FETCH_MULTIDAY_SESSION_REJECTED: {
      return {
        ...state,
        latestSession: null,
        latestSessionError: action.payload,
      };
    }
    case FETCH_SESSIONS_FULFILLED: {
      return {
        ...state,
        sessions: action.payload,
        sessionsError: null,
      };
    }
    case FETCH_SESSIONS_REJECTED: {
      return {
        ...state,
        sessions: null,
        sessionsError: action.payload,
      };
    }
    case SET_SUB_ACTIVE_PROFILE_PIC_WEBCAM_CATURE_MODAL: {
      return {
        ...state,
        activeSubModal: action.payload,
      };
    }
    case IS_SKETCH_PICKER_POPUP_OPEN: {
      return {
        ...state,
        isSketchPickerPopupOpen: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
