import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  primeDeleteEvalId,
} from '../../ducks/evaluationsActions';
import DeleteConfirmationModal from './DeleteConfirmationModal';

const SelectionModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

const DeleteConfirmationModalContainer = () => {
  const primedDeleteEvalInfo = useSelector((state) => state.evaluations.data.primedDeleteEvalInfo);
  const dispatch = useDispatch();

  return (
    <>
      <SelectionModalOverlay
        isActive={Object.keys(primedDeleteEvalInfo).length}
        onClick={() => {
          dispatch(primeDeleteEvalId({}));
        }}
      />
      <DeleteConfirmationModal />
    </>
  );
};

DeleteConfirmationModalContainer.propTypes = { };

export default DeleteConfirmationModalContainer;
