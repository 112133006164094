import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { componentWillAppendToBody } from 'react-append-to-body';

import ButtonRound from '../../../modules/weightroom-view-new/components/presentational/ButtonRound';
import Title from '../../../modules/weightroom-view-new/components/presentational/Title';
import IconRound from '../../../modules/weightroom-view-new/components/presentational/IconRound';

const PayGateWrapper = styled('div')`
  display: flex;
  position: absolute;
  background-color: rgba(255,255,255,0.75);
  height: 100%;
  width: 100%;
  top: 0;
  justify-content: center;
  align-items: center;
`;

const PayGateContent = styled('div')`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  button {
    margin-top: 10px;
  }
  .title-icon {
    height: 44px;
    width: 44px;
    margin-bottom: 10px;
    svg {
      height: 22px;
      width: 22px;
    }
  }
`;

const AppendedPayGate = componentWillAppendToBody(PayGateWrapper);

const CloseButton = styled('button')`
  position: absolute;
  top: 12px;
  right: 12px;
  margin-top: 0px!important;
  border: 0;
  cursor: pointer;

  .icon {
    background: transparent;
  }
`;

const PayGate = ({
  accountRequired,
}) => {
  const handleUpgradeClick = () => {
    window.location.href = 'https://app.teambuildr.com/billing';
  };

  const handleCloseClick = () => {
    window.location.href = 'https://app.teambuildr.com/workouts';
  };

  return (
    <AppendedPayGate>
      <PayGateContent>
        <Title
          icon='lock'
          noMargin
        >
          {`${accountRequired} Account Required`}
        </Title>
        <ButtonRound
          complete
          cta='Upgrade Account'
          icon='alt-up-arrow'
          onClick={handleUpgradeClick}
        />
        <CloseButton
          onClick={handleCloseClick}
        >
          <IconRound
            noBorder
            icon='remove'
            size={15}
          />
        </CloseButton>
      </PayGateContent>
    </AppendedPayGate>
  );
};

PayGate.propTypes = {
  accountRequired: PropTypes.string.isRequired,
};

export default PayGate;
