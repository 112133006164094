import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useTransition, useSpring } from 'react-spring/web.cjs';

import BigModal from './components/presentational/BigModal';
import MediumModal from './components/presentational/MediumModal';
import CustomShareDocModal from './components/presentational/CustomShareDocModal';
import CustomCreateDocumentWrapModal from './components/presentational/CustomCreateDocumentWrapModal';
import BackwardsCustomCreateDocumentWrapModal from './components/presentational/BackwardsCustomCreateDocumentWrapModal';
import CustomModalConfirmCreate from './components/presentational/CustomModalConfirmCreate';
import DeleteModal from './components/presentational/DeleteModal';
import ModalShareDocument from './components/presentational/ModalShareDocument';
import ModalCreateDocument from './components/presentational/ModalCreateDocument';
import ModalConfirmDelete from './components/presentational/ModalConfirmDelete';
import ModalCreateForm from './components/presentational/ModalCreateForm';
import ModalUpdateDocument from './components/presentational/ModalUpdateDocument';
import ModalConfirmCreate from './components/presentational/ModalConfirmCreate';
import useDocuments from './components/hooks/useDocuments';
import NoHoverDeleteButton from './components/presentational/NoHoverDeleteButton';
import ActionButton from '../../shared/components/ActionButton/ActionButton';

import {
  setActiveReduxModal,
  setSelectedRowsRedux,
  setSingleActionRedux,
} from './ducks/documentsActions';

const CreateButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
`;

const DeleteButtonWrapper = styled('div')`
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 50px;
  margin-bottom: 100px;

  .modal-button {
    background-color: #f24540;
  }
`;

const MainContainer = styled('main')`
`;

const ModalHandler = ({
  currentFolder,
}) => {
  const {
    handleFetchDocuments,
    handleCreateDocument,
    handleDeleteDocuments,
    handleUpdateDocument,
    handleFetchUserGroups,
  } = useDocuments();

  const dispatch = useDispatch();

  const [createdDocumentType, setCreatedDocumentType] = useState('');
  const [deleteDocumentButton, setDeleteDocumentButton] = useState([]);

  const activeModal = useSelector((state) => state.documents.data.activeModal);
  const currentRow = useSelector((state) => state.documents.data.currentRow);
  const userGroups = useSelector((state) => state.documents.data.currentRow);
  const modalShareLink = useSelector((state) => state.documents.data.modalShareLink);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const selectedRows = useSelector((state) => state.documents.data.selectedRows);
  const isSingleAction = useSelector((state) => state.documents.data.isSingleAction);

  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined && !userGroups) {
      handleFetchUserGroups(accountCode);
    }
  }, [currentUser]);

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const setSelectedRows = (newSelectedRows) => {
    dispatch(setSelectedRowsRedux(newSelectedRows));
  };

  const setSingleAction = (bool) => {
    dispatch(setSingleActionRedux(bool));
  };

  useEffect(() => {
    if (selectedRows && selectedRows.length && !isSingleAction > 0) {
      setDeleteDocumentButton(<NoHoverDeleteButton
        bottom
        fullWidth
        cta='Delete'
        className='modal-button'
        large
        rounded
        noBorder
        primary
        noHover
        onClick={() => (setActiveModal('delete_docs_modal'))}
      />);
    } else {
      setDeleteDocumentButton([]);
    }
  }, [selectedRows]);

  const createDocumentButton = () => {
    if (currentUser.admin) {
      return (
        <ActionButton
          icon='plus'
          onClick={() => {
            setActiveModal('create_doc_modal');
          }}
        />
      );
    }
    return null;
  };

  const completeTransition = useTransition(deleteDocumentButton, (item) => item.key, {
    from: { position: 'fixed', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const deleteDocumentsButton = () => completeTransition.map(({ item, key, props }) => (
    <animated.div key={key} style={props}>
      {item}
    </animated.div>
  ));

  const modalHandler = () => {
    let returnModal = null;
    if (activeModal === 'share_modal') {
      returnModal = (
        <CustomShareDocModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalShareDocument
            link={modalShareLink}
            onRequestClose={closeModal}
          />
        </CustomShareDocModal>
      );
    } else if (activeModal === 'create_doc_modal') {
      returnModal = (
        <CustomCreateDocumentWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          currentFolder={currentFolder}
        >
          <ModalCreateDocument
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            onRequestClose={closeModal}
          />
        </CustomCreateDocumentWrapModal>
      );
    } else if (activeModal === 'backwards_create_doc_modal') {
      returnModal = (
        <BackwardsCustomCreateDocumentWrapModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalCreateDocument
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            onRequestClose={closeModal}
          />
        </BackwardsCustomCreateDocumentWrapModal>
      );
    } else if (activeModal === 'delete_docs_modal') {
      returnModal = (
        <DeleteModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
          setSingleAction={setSingleAction}
          isSingleAction={isSingleAction}
          setSelectedRows={setSelectedRows}
        >
          <ModalConfirmDelete
            handleDeleteDocuments={handleDeleteDocuments}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            currentUser={currentUser}
            isSingleAction={isSingleAction}
            setActiveModal={setActiveModal}
            onRequestClose={closeModal}
            setSingleAction={setSingleAction}
          />
        </DeleteModal>
      );
    } else if (activeModal === 'update_doc_modal') {
      returnModal = (
        <MediumModal
          isOpen={activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalUpdateDocument
            handleUpdateDocument={handleUpdateDocument}
            currentUser={currentUser}
            document={currentRow}
            onRequestClose={closeModal}
            handleFetchDocuments={handleFetchDocuments}
            currentFolder={currentFolder}
          />
        </MediumModal>
      );
    } else if (activeModal === 'create_folder_modal' || activeModal === 'upload_file_modal' || activeModal === 'add_link_modal') {
      returnModal = (
        <BigModal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
          customButton
        >
          <ModalCreateForm
            currentUser={currentUser}
            modalType={activeModal}
            createDocument={handleCreateDocument}
            onRequestClose={closeModal}
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            setCreatedDocumentType={setCreatedDocumentType}
          />
        </BigModal>
      );
    } else if (activeModal === 'confirm_create_modal') {
      returnModal = (
        <CustomModalConfirmCreate
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <ModalConfirmCreate
            setActiveModal={setActiveModal}
            currentFolder={currentFolder}
            documentType={createdDocumentType}
            onRequestClose={closeModal}
          />
        </CustomModalConfirmCreate>
      );
    }
    return returnModal;
  };

  return (
    <MainContainer>
      {modalHandler()}
      <CreateButtonWrapper>
        {createDocumentButton()}
      </CreateButtonWrapper>
      <DeleteButtonWrapper>
        {deleteDocumentsButton()}
      </DeleteButtonWrapper>
    </MainContainer>
  );
};

ModalHandler.propTypes = {
};

export default ModalHandler;
