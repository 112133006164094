import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// The parent avatar container uses overflow hidden now, so offset absolute positioned elements
// like the edit button were partially hidden. To fix we created a position relative wrapper,
// and a non relative parent with overflow hidden. So absolute elements will position based on
// the relative wrapper, and not be hidden.

const AthleteAvatarWrapper = styled('div')`
  position: relative;
  height: auto;
  width: auto;
`;

const AthleteAvatarContainer = styled('div')`
 border-radius: 50%;
 overflow: hidden;
 display: flex;
 justify-content: center;
 align-items: center;
 height: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    width: ${(props) => (
    props.size ? `${props.size}px` : '35px'
  )};
    border-width: ${(props) => (
    props.borderWidth ? `${props.borderWidth}px` : 'none'
  )};
    border-style: ${(props) => (
    props.borderWidth ? 'solid' : 'none'
  )};
    border-color: ${(props) => (
    props.borderColor ? `${props.borderColor}` : 'none'
  )};
    background: ${(props) => (
    props.backgroundColor ? `${props.backgroundColor}` : '#E5E5E5'
  )};

  img {
    ${(props) => (!props.scaled ? (
    `height: 100%;
      width: 100%;`
  ) : (
    'height: 70%;'
  ))}
    object-fit: cover;
  }
`;

const LinearGradientContainer = styled('div')`
  display: flex;
   background-image: ${(props) => (
    props.linearGradient ? `linear-gradient(45deg,${props.linearGradient[0]}, ${props.linearGradient[1]})` : 'none'
  )};
 
  height:${(props) => (props.size ? `${props.size + 10}px` : '95px')};
  width: ${(props) => (
    props.size ? `${props.size + 10}px` : '95px'
  )};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

const Avatar = ({
  avatar,
  alt,
  size,
  backgroundColor,
  borderWidth,
  borderColor,
  linearGradient,
  children,
  scaled,
}) => (
  <AthleteAvatarWrapper>
    {linearGradient ? (
      <LinearGradientContainer linearGradient={linearGradient} size={size}>
        <AthleteAvatarContainer size={size} borderWidth={borderWidth} borderColor={borderColor}>
          <img src={avatar} alt={alt} />
          {children}
        </AthleteAvatarContainer>
      </LinearGradientContainer>
    ) : (
      <AthleteAvatarContainer
        size={size}
        borderWidth={borderWidth}
        borderColor={borderColor}
        backgroundColor={backgroundColor || null}
        scaled={scaled}
      >
        <img src={avatar} alt='' />
        {children}
      </AthleteAvatarContainer>
    )}
  </AthleteAvatarWrapper>
);

Avatar.propTypes = {
  avatar: PropTypes.string.isRequired,
  alt: PropTypes.string,
  children: PropTypes.element,
  size: PropTypes.number,
  scaled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  borderWidth: PropTypes.number,
  borderColor: PropTypes.string,
  linearGradient: PropTypes.arrayOf(PropTypes.string),
};

Avatar.defaultProps = {
  alt: 'avatar',
  children: null,
  size: null,
  backgroundColor: null,
  borderWidth: null,
  borderColor: null,
  linearGradient: null,
  scaled: false,
};

export default Avatar;
