
export const CREATE_RESELLER_START = 'CREATE_RESELLER_START';
export const CREATE_RESELLER_FULFILLED = 'CREATE_RESELLER_FULFILLED';
export const CREATE_RESELLER_REJECTED = 'CREATE_RESELLER_REJECTED';

export const FETCH_RESELLER_START = 'FETCH_RESELLER_START';
export const FETCH_RESELLER_FULFILLED = 'FETCH_RESELLER_FULFILLED';
export const FETCH_RESELLER_REJECTED = 'FETCH_RESELLER_REJECTED';

export const CONNECT_STRIPE_START = 'CONNECT_STRIPE_START';
export const CONNECT_STRIPE_FULFILLED = 'CONNECT_STRIPE_FULFILLED';
export const CONNECT_STRIPE_REJECTED = 'CONNECT_STRIPE_REJECTED';

export const UPLOAD_MEDIA_START = 'UPLOAD_MEDIA_START';
export const UPLOAD_MEDIA_FULFILLED = 'UPLOAD_MEDIA_FULFILLED';
export const UPLOAD_MEDIA_REJECTED = 'UPLOAD_MEDIA_REJECTED';

export const CREATE_COUPON_START = 'CREATE_COUPON_START';
export const CREATE_COUPON_FULFILLED = 'CREATE_COUPON_FULFILLED';
export const CREATE_COUPON_REJECTED = 'CREATE_COUPON_REJECTED';

export const CREATE_PROGRAM_START = 'CREATE_PROGRAM_START';
export const CREATE_PROGRAM_FULFILLED = 'CREATE_PROGRAM_FULFILLED';
export const CREATE_PROGRAM_REJECTED = 'CREATE_PROGRAM_REJECTED';

export const FETCH_PROGRAMS_START = 'FETCH_PROGRAMS_START';
export const FETCH_PROGRAMS_FULFILLED = 'FETCH_PROGRAMS_FULFILLED';
export const FETCH_PROGRAMS_REJECTED = 'FETCH_PROGRAMS_REJECTED';

export const FETCH_USER_GROUPS_START = 'FETCH_USER_GROUPS_START';
export const FETCH_USER_GROUPS_FULFILLED = 'FETCH_USER_GROUPS_FULFILLED';
export const FETCH_USER_GROUPS_REJECTED = 'FETCH_USER_GROUPS_REJECTED';

export const UPDATE_PROGRAM_START = 'UPDATE_PROGRAM_START';
export const UPDATE_PROGRAM_FULFILLED = 'UPDATE_PROGRAM_FULFILLED';
export const UPDATE_PROGRAM_REJECTED = 'UPDATE_PROGRAM_REJECTED';

export const STRIPE_ADMIN_LOGIN_START = 'STRIPE_ADMIN_LOGIN_START';
export const STRIPE_ADMIN_LOGIN_FULFILLED = 'STRIPE_ADMIN_LOGIN_FULFILLED';
export const STRIPE_ADMIN_LOGIN_REJECTED = 'STRIPE_ADMIN_LOGIN_REJECTED';

export const FETCH_SUBSCRIBERS_START = 'FETCH_SUBSCRIBERS_START';
export const FETCH_SUBSCRIBERS_FULFILLED = 'FETCH_SUBSCRIBERS_FULFILLED';
export const FETCH_SUBSCRIBERS_REJECTED = 'FETCH_SUBSCRIBERS_REJECTED';

export const FETCH_CANCELLED_SUBSCRIBERS_START = 'FETCH_CANCELLED_SUBSCRIBERS_START';
export const FETCH_CANCELLED_SUBSCRIBERS_FULFILLED = 'FETCH_CANCELLED_SUBSCRIBERS_FULFILLED';
export const FETCH_CANCELLED_SUBSCRIBERS_REJECTED = 'FETCH_CANCELLED_SUBSCRIBERS_REJECTED';

export const FETCH_SUBSCRIBER_START = 'FETCH_SUBSCRIBER_START';
export const FETCH_SUBSCRIBER_FULFILLED = 'FETCH_SUBSCRIBER_FULFILLED';
export const FETCH_SUBSCRIBER_REJECTED = 'FETCH_SUBSCRIBER_REJECTED';

export const CANCEL_SUBSCRIPTION_START = 'CANCEL_SUBSCRIPTION_START';
export const CANCEL_SUBSCRIPTION_FULFILLED = 'CANCEL_SUBSCRIPTION_FULFILLED';
export const CANCEL_SUBSCRIPTION_REJECTED = 'CANCEL_SUBSCRIPTION_REJECTED';

export const UPDATE_RESELLER_ACCOUNT_START = 'UPDATE_RESELLER_ACCOUNT_START';
export const UPDATE_RESELLER_ACCOUNT_FULFILLED = 'UPDATE_RESELLER_ACCOUNT_FULFILLED';
export const UPDATE_RESELLER_ACCOUNT_REJECTED = 'UPDATE_RESELLER_ACCOUNT_REJECTED';

export const FETCH_CALENDARS_START = 'FETCH_CALENDARS_START';
export const FETCH_CALENDARS_FULFILLED = 'FETCH_CALENDARS_FULFILLED';
export const FETCH_CALENDARS_REJECTED = 'FETCH_CALENDARS_REJECTED';

export const FETCH_FIRST_WORKOUT_START = 'FETCH_FIRST_WORKOUT_START';
export const FETCH_FIRST_WORKOUT_FULFILLED = 'FETCH_FIRST_WORKOUT_FULFILLED';
export const FETCH_FIRST_WORKOUT_REJECTED = 'FETCH_FIRST_WORKOUT_REJECTED';

export const FETCH_ALL_CHARGES_START = 'FETCH_ALL_CHARGES_START';
export const FETCH_ALL_CHARGES_FULFILLED = 'FETCH_ALL_CHARGES_FULFILLED';
export const FETCH_ALL_CHARGES_REJECTED = 'FETCH_ALL_CHARGES_REJECTED';

export const REFUND_CHARGE_START = 'REFUND_CHARGE_START';
export const REFUND_CHARGE_FULFILLED = 'REFUND_CHARGE_FULFILLED';
export const REFUND_CHARGE_REJECTED = 'REFUND_CHARGE_REJECTED';

export const UPDATE_PAYMENT_START = 'UPDATE_PAYMENT_START';
export const UPDATE_PAYMENT_FULFILLED = 'UPDATE_PAYMENT_FULFILLED';
export const UPDATE_PAYMENT_REJECTED = 'UPDATE_PAYMENT_REJECTED';

export const REQUEST_STRIPE_CONNECT_LINK_START = 'REQUEST_STRIPE_CONNECT_LINK_START';
export const REQUEST_STRIPE_CONNECT_LINK_FULFILLED = 'REQUEST_STRIPE_CONNECT_LINK_FULFILLED';
export const REQUEST_STRIPE_CONNECT_LINK_REJECTED = 'REQUEST_STRIPE_CONNECT_LINK_REJECTED';

export const SET_ACTIVE_REDUX_MODAL = 'SET_ACTIVE_REDUX_MODAL';

export const FETCH_COUPONS_START = 'FETCH_COUPONS_START';
export const FETCH_COUPONS_FULFILLED = 'FETCH_COUPONS_FULFILLED';
export const FETCH_COUPONS_REJECTED = 'FETCH_COUPONS_REJECTED';

export const DELETE_COUPON_START = 'DELETE_COUPON_START';
export const DELETE_COUPON_FULFILLED = 'DELETE_COUPON_FULFILLED';
export const DELETE_COUPON_REJECTED = 'DELETE_COUPON_REJECTED';

export const CLEAR_COUPON_FETCH_ERROR = 'CLEAR_COUPON_FETCH_ERROR';

export const UPDATE_COUPON_START = 'UPDATE_COUPON_START';
export const UPDATE_COUPON_FULFILLED = 'UPDATE_COUPON_FULFILLED';
export const UPDATE_COUPON_REJECTED = 'UPDATE_COUPON_REJECTED';

export const ADD_SUBSCRIBER_TO_PROGRAM_START = 'ADD_SUBSCRIBER_TO_PROGRAM_START';
export const ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED = 'ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED';
export const ADD_SUBSCRIBER_TO_PROGRAM_REJECTED = 'ADD_SUBSCRIBER_TO_PROGRAM_REJECTED';

export const CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR = 'CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR';

export const SET_RESELLER_OVERLAY = 'SET_RESELLER_OVERLAY';
