import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

// import { namespaced } from 'redux-subspace';

// import { reducer as formReducer } from 'redux-form'

// import auth from './authReducer';
// import usersList from './usersReducer';

import createPanelReducer from '../modules/weightroom-view/ducks/panelReducer';
import weightRoomReducerOld from '../modules/weightroom-view/ducks/weightroomReducer';
import authReducer from '../modules/login/ducks';
import layoutReducer from '../shared/components/Layout/ducks';
import documentsReducer from '../modules/documents/ducks';
import calendarsReducer from '../modules/calendars/ducks';
import leaderboardReducer from '../modules/leaderboard/ducks';
import reportingReducer from '../modules/reporting/ducks';
import resellerReducer from '../modules/reseller/ducks';
import programsReducer from '../modules/programs/ducks';
import { conversationsReducer, notificationsReducer } from '../modules/conversations/ducks';
import wearablesDashboardReducer from '../modules/wearables-dashboard/ducks';
import loadMonitoringDashboardReducer from '../modules/load-monitoring-dashboard/ducks';
import socketReducer from '../shared/components/SocketTester/ducks';
import testPageReducer from '../modules/test-page/ducks';
import profileReducer from '../modules/profile/ducks';
import athleteReportCardReducer from '../modules/athleteReportCard/ducks';
import forgotReducer from '../modules/forgot-password/ducks';
import weightRoomReducer from '../modules/weightroom-view-new/ducks';
import sharedWorkoutReducer from '../modules/shared-workout/ducks';
import workoutsReducer from '../modules/workouts/ducks';
import sportsScienceReducer from '../modules/sports-science/ducks';
import evaluationsReducer from '../modules/evaluations/ducks';
import forcePlateDashboardReducer from '../modules/force-plate-dashboard/ducks';
import sportCoachDashboardReducer from '../modules/sport-coach-dashboard/ducks';
import recoveryRadarReducer from '../modules/recovery-radar/ducks';
import habitTrackingReducer from '../modules/habit-tracking/ducks';
import habitTrackingDashboardReducer from '../modules/habit-tracking-dashboard/ducks';

const weightroomPersistConfig = {
  key: 'weightRoomView',
  storage,
  blacklist: ['ui'],
};

// const wearablesPersistConfig = {
//   key: 'wearablesDashboard',
//   storage,
//   stateReconciler: hardSet,
// };

// const authPersistConfig = {
//   key: 'auth',
//   storage,
//   stateReconciler: hardSet,
// };

// const reducer = history => combineReducers({
//   auth: authReducer,
//   router: connectRouter(history),
//   panel1: createPanelReducer('panel1'),
//   panel2: createPanelReducer('panel2'),
//   panel3: createPanelReducer('panel3'),
//   panel4: createPanelReducer('panel4'),
//   settings: layoutReducer,
//   weightRoom: weightroomReducer,
// });

export default (history) => combineReducers({
  auth: authReducer,
  documents: documentsReducer,
  calendars: calendarsReducer,
  profile: profileReducer,
  athleteReportCard: athleteReportCardReducer,
  conversations: conversationsReducer,
  leaderboard: leaderboardReducer,
  notifications: notificationsReducer,
  reporting: reportingReducer,
  reseller: resellerReducer,
  router: connectRouter(history),
  // oldPanel1: createPanelReducer('oldPanel1'),
  // oldPanel2: createPanelReducer('oldPanel2'),
  // oldPanel3: createPanelReducer('oldPanel3'),
  // oldPanel4: createPanelReducer('oldPanel4'),
  programs: programsReducer,
  settings: layoutReducer,
  socketTester: socketReducer,
  sportsScience: sportsScienceReducer,
  testPage: testPageReducer,
  // oldWeightRoom: weightRoomReducerOld,
  // oldWeightRoom: persistReducer(weightroomPersistConfig, weightRoomReducerOld),
  weightRoomView: persistReducer(weightroomPersistConfig, weightRoomReducer),
  wearablesDashboard: wearablesDashboardReducer,
  loadMonitoringDashboard: loadMonitoringDashboardReducer,
  // wearablesDashboard: persistReducer(wearablesPersistConfig, wearablesDashboardReducer),
  forgot: forgotReducer,
  sharedWorkout: sharedWorkoutReducer,
  workouts: workoutsReducer,
  evaluations: evaluationsReducer,
  forcePlateDashboard: forcePlateDashboardReducer,
  sportCoachDashboard: sportCoachDashboardReducer,
  recoveryRadar: recoveryRadarReducer,
  habitTracking: habitTrackingReducer,
  habitTrackingDashboard: habitTrackingDashboardReducer,
});

// export default persistReducer(rootPersistConfig, reducer);
