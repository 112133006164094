import io from 'socket.io-client';

const socket = io(`${process.env.SOCKET_API_URL}messaging`, {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  randomizationFactor: 0.5,
  secure: true,
  rejectUnauthorized: false,
  withCredentials: false,
  transports: ['websocket'],
});

export default socket;
