/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import Spinner from '../../../../shared/components/Spinner/Spinner';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import EvaluationContentTable from './EvaluationContentTable';
import EvaluationsContentList from './EvaluationsContentList';

const EvaluationsContentWrapper = () => {
  const dispatch = useDispatch();
  const isEvaluationContentTable = useSelector(
    (state) => state.evaluations.ui.isEvaluationContentTable,
  );
  const isEvaluationsContentList = useSelector(
    (state) => state.evaluations.ui.isEvaluationsContentList,
  );

  return (
    <>
      {isEvaluationContentTable ? <EvaluationContentTable /> : null}
      {isEvaluationsContentList ? <EvaluationsContentList /> : null}
    </>
  );
};

EvaluationsContentWrapper.defaultProps = {

};

EvaluationsContentWrapper.propTypes = {

};

export default EvaluationsContentWrapper;
