/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import tinycolor from 'tinycolor2';
import { useLocation } from 'react-router';

import Button from '../../shared/components/Button/Button';
import HeaderText from '../../shared/components/HeaderText/HeaderText';
import ViewOnlyOverview from './components/presentational/ViewOnlyOverview';
import ViewOnlyAnalysis from './components/presentational/ViewOnlyAnalysis';
import LightLogo from '../../shared/images/poweredByTBWhite.png';
import DarkLogo from '../../shared/images/poweredByTBBlack.png';
import Spinner from '../../shared/components/Spinner/Spinner';
import AboutDashboardModalContainer from './components/presentational/AboutDashboardModalContainer';
import {
  fetchPublicSCDashData,
  setSCDashView,
  setAboutDashboardModalShowing,
} from './ducks/sportCoachDashboardActions';
import InvalidTokenModal from './components/presentational/InvalidTokenModal';
import SCLegendInfoModal from './components/presentational/SCLegendInfoModal';

const Container = styled('div')`
  width: 100%;
`;

const Wrapper = styled('div')`
  height: 100%;
`;

const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #F5F5F5;
`;

const DashboardBanner = styled('div')`
  background-color: ${(props) => (props.bgColor)};
  height: 100px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
`;

const UserLogo = styled('img')`
  height: 75px;
`;

const TBuildrLogo = styled('div')`
  background-image: ${(props) => (tinycolor(props.bgColor).isLight() ? `url(${DarkLogo})` : `url(${LightLogo})`)};
  background-repeat: no-repeat;
  height: 46px;
  width: 200px;
`;

const DashboardHeader = styled('div')`
  display: flex;
  width: 100%;
  background-color: #FFFFFF;
  padding-top: 30px;
  padding-left: 20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`;

const TitleAndInfo = styled('div')`
  display: flex;

  button {
    margin-left: 5px;
  }

  svg {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 3px;
  }
`;

const MenuContainer = styled('div')`
  display: flex;
`;

const MenuItem = styled('button')`
  border: none;
  margin-left: 10px;
  margin-right: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  border-bottom: ${(props) => (props.isActive ? '3px solid #1787FB!important' : '3px solid white')};
  color: ${(props) => (!props.isActive ? '#9E9E9E' : '#444444')};
`;

const ViewOnlySCDash = () => {
  const aboutDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.aboutDashboardModalShowing,
  );
  const dashBGColor = useSelector((state) => state.sportCoachDashboard.data.dashBGColor);
  const dashLogo = useSelector((state) => state.sportCoachDashboard.data.dashLogo);
  const dashTitle = useSelector((state) => state.sportCoachDashboard.data.dashTitle);
  const isLegendInfoModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isLegendInfoModalShowing,
  );
  const scDashData = useSelector((state) => state.sportCoachDashboard.data.scDashData);
  const scDashError = useSelector((state) => state.sportCoachDashboard.data.scDashError);
  const scDashView = useSelector((state) => state.sportCoachDashboard.ui.scDashView);

  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const dispatch = useDispatch();

  // Fetch data on page load with token if there's no data
  useEffect(() => {
    dispatch(fetchPublicSCDashData(token));
  }, []);

  const handleMenuClick = (view) => {
    if (view !== scDashView) {
      dispatch(setSCDashView(view));
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Sport Coach Dashboard | TeamBuildr</title>
      </Helmet>
      { scDashError && (
        <InvalidTokenModal />
      )}
      {!scDashData ? (
        <Wrapper>
          <Spinner />
        </Wrapper>
      ) : (
        <PageWrapper>
          {isLegendInfoModalShowing && (
          <SCLegendInfoModal />
          )}
          <DashboardBanner bgColor={dashBGColor}>
            <UserLogo src={dashLogo} alt='user logo' id='userLogo' />
            <TBuildrLogo bgColor={dashBGColor} />
          </DashboardBanner>
          <DashboardHeader>
            <TitleAndInfo>
              <HeaderText
                fontWeight='300'
                letterSpacing='normal'
              >
                {dashTitle}
              </HeaderText>
              <Button
                iconOnly
                icon='info'
                customColor='#00000000'
                noBorder
                onClick={() => dispatch(setAboutDashboardModalShowing(true))}
                rounded
              />
            </TitleAndInfo>
            <MenuContainer>
              <MenuItem
                isActive={scDashView === 'performance'}
                onClick={() => handleMenuClick('performance')}
              >
                Performance Overview
              </MenuItem>
              <MenuItem
                isActive={scDashView === 'group'}
                onClick={() => handleMenuClick('group')}
              >
                Group Analysis
              </MenuItem>
            </MenuContainer>
          </DashboardHeader>
          {scDashView === 'performance' && (
          <ViewOnlyOverview />
          )}
          {scDashView === 'group' && (
          <ViewOnlyAnalysis />
          )}
          {aboutDashboardModalShowing && (
            <AboutDashboardModalContainer />
          )}
        </PageWrapper>
      )}
    </Container>
  );
};

export default ViewOnlySCDash;
