import React, { useEffect, useState, useMemo } from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import Checkbox from 'rc-checkbox';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const TrWrapper = styled('div')`
  cursor: default;
  border: none;
  position: relative;
  display: flex;
  height: 60px;
  align-items: center;
  .firstElement {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &.helperContainerClass {
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    z-index: 1004;

    &:active {

    }
  }
`;

const IconRotator = styled('div')`
  transform: rotate(90deg);
  margin-left: ${(props) => (props.margin ? `${props.margin}px` : '0')};

  &:hover {
    cursor: pointer;
  }
`;

const RightInputValue = styled('div')`
  display: flex;
  height: 35px;
  border: 1px solid #eeeeee;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-family: 'Nunito Sans';
  background: ${(props) => (props.isActive ? '#77A7FF' : 'white')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  border-radius: 4px;
`;

const LeftInputValue = styled('div')`
  display: flex;
  height: 35px;
  border: 1px solid #eeeeee;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-family: 'Nunito Sans';
  background: ${(props) => (props.isActive ? '#77A7FF' : 'white')};
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  border-radius: 4px;
`;

const DisabledInputValue = styled('div')`
  display: flex;
  height: 35px;
  border: 1px solid #E6E6E6;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-family: 'Nunito Sans';
  border-radius: 4px;
  background: ${(props) => (props.highlighted ? '#77A7FF' : '#eeeeee')};
  color: ${(props) => (props.highlighted ? 'white' : '#9E9E9E')};
`;

const Handle = styled('div')`
  display: flex;
`;

const RightCheckboxContainer = styled('div')`
  border: 1px solid #eee;
  width: 50%;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  height: 38px;
  align-items: center;
  background: ${(props) => (props.disabled ? '#eeeeee' : 'none')};
`;

const FirstContainer = styled('div')`
  display: flex;
  width: ${(props) => `${props.width}%`};
  justify-content: space-between;
`;

const SecondContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: ${(props) => `${props.width}%`};
  .item-select {
    width: 100%;
  }
  .custom-item-input {
    width: 100%;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
  }
  @media only screen and (max-width : 768px) {
    min-width:300px;
    }
  
`;

const ThirdContainer = styled('div')`
  width: ${(props) => `${props.width}%`};
  @media only screen and (max-width : 768px) {
    min-width:150px;
    }
`;

const FourthContainer = styled('div')`
  width: ${(props) => `${props.width}%`};
  @media only screen and (max-width : 768px) {
    min-width:150px;
    }
`;

const FifthContainer = styled('div')`
  width: ${(props) => `${props.width}%`};
  display: flex;
  justify-content: center;
  @media only screen and (max-width : 768px) {
    min-width:50px;
    }
`;

const SixthContainer = styled('div')`
  width: ${(props) => `${props.width}%`};
  display: flex;
  justify-content: center;
  position: relative;
  right: 10px;
  @media only screen and (max-width : 768px) {
    min-width: 80px;
    }
`;

const DeleteButton = styled('div')`
  position: absolute;
  right: 5px;
  top: 30%;
  cursor: pointer;
`;

const InputValueBox = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const SelectValueBox = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  .unit-select {
    width: 49%;
  }
`;

const ItemInput = styled('input')`
  height: 35px;
`;

const HardCodeUnitBox = styled('div')`
  width: 49%;
  background-color: hsl(0,0%,95%);
  border-color: hsl(0,0%,90%);
  color: hsl(0,0%,50%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  font-size: 14px;
  font-family: 'Nunito Sans';
  padding: 10px;
`;
const HandleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const IconCircle = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px; 
  border-radius: 50%; 
  border: 1px solid #000;
  `;

const RowHandler = SortableHandle(() => (
  <Handle className='handle'>
    <HandleContainer>
      <IconRotator>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='dots'
        />
      </IconRotator>
      <IconRotator margin={-12}>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='dots'
        />
      </IconRotator>
    </HandleContainer>
  </Handle>
));
const EvaluationsTableRow = ({
  firstWidth,
  secondWidth,
  thirdWidth,
  fourthWidth,
  fifthWidth,
  sixthWidth,
  itemIndex,
  items,
  setItems,
  item,
  units,
  trackedExercises,
}) => {
  const [isLRChecked, setIsLRChecked] = useState(false);
  const [isLowerChecked, setIsLowerChecked] = useState(false);
  const [isSingleInputValue, setIsSingleInputValue] = useState(true);
  const [evalItemName, setEvalItemName] = useState('');
  const [selectItems, setSelectItems] = useState([]);
  const [secondMetric, setSecondMetric] = useState(null);
  const [firstMetric, setFirstMetric] = useState(null);
  const trackedItemUnits = useSelector((state) => state.evaluations.data.trackedItemUnits.data);

  const unitsWithOptions = useMemo(() => [
    { id: 'n/a', name: 'No Unit' }, // Represents the "No Unit" option
    ...units.data,
  ], [units.data]);

  const abbreviationToFullName = useMemo(() => trackedItemUnits.reduce((acc, unit) => {
    acc[unit.abbreviation.toLowerCase()] = unit.name;
    return acc;
  }, {}), [trackedItemUnits]);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') return string;
    return string.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  useEffect(() => {
    if (item.unit2) {
      setSecondMetric({ name: abbreviationToFullName[(item.unit2.toLowerCase())] || item.unit2.toLowerCase(), id: item.id });
    } else {
      setSecondMetric(null);
    }
    if (item.unit1) {
      setFirstMetric({ name: abbreviationToFullName[item.unit1.toLowerCase()] || item.unit1.toLowerCase(), id: item.id });
    } else {
      setFirstMetric(null);
    }
    if (item.isLowerNumberBetter) {
      setIsLowerChecked(true);
    }
  }, [item]);

  useEffect(() => {
    setSelectItems(trackedExercises.data);
  }, [trackedExercises]);

  const changeEvalItemName = () => {
    const newItem = { ...item };
    newItem.name = evalItemName;
    const newArray = [...items];
    newArray.splice(itemIndex, 1, newItem);
    setItems(newArray);
  };

  const changeInputNumber = (bool) => {
    if (bool) {
      setIsSingleInputValue(true);
      const newItem = { ...item };
      newItem.inputType = 1;
      newItem.unit2 = '';
      const newArray = [...items];
      newArray.splice(itemIndex, 1, newItem);
      setItems(newArray);
    } else {
      setIsSingleInputValue(false);
      const newItem = { ...item };
      newItem.inputType = 2;
      const newArray = [...items];
      newArray.splice(itemIndex, 1, newItem);
      setItems(newArray);
    }
  };

  const changeLR = (bool) => {
    setIsLRChecked(bool);
    const newItem = { ...item };
    const newArray = [...items];
    newItem.leftRight = bool;
    newArray.splice(itemIndex, 1, newItem);
    setItems(newArray);
  };

  const changeLower = (bool) => {
    setIsLowerChecked(bool);
    const newItem = { ...item };
    const newArray = [...items];
    newItem.isLowerNumberBetter = bool;
    newArray.splice(itemIndex, 1, newItem);
    setItems(newArray);
  };

  const CustomSingleValue = ({ children, data }) => (
    <div className='input-select'>
      <IconContainer className='input-select__single-value'>
        <IconCircle>
          <IcomoonReact
            iconSet={iconSet}
            size={16}
            icon='trophy'
            color='#000'
          />
        </IconCircle>
        <span>{children}</span>
      </IconContainer>
    </div>
  );

  return (
    <TrWrapper>
      <FirstContainer>
        <RowHandler />
      </FirstContainer>
      <SecondContainer width={secondWidth}>
        {
        item.tracked
          ? (
            <Select
              className='item-select'
              defaultValue={item}
              options={selectItems}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  display: 'flex',
                  flexWrap: 'nowrap',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }),
              }}
              getOptionLabel={(option) => option.name}
              components={{ SingleValue: CustomSingleValue }}
              getOptionValue={(option) => option.id}
              onChange={(option) => {
                setSelectItems([...selectItems]);
                const newItem = {
                  id: null,
                  name: option.name,
                  tracked: true,
                  columnType: option.prDataFormat.formType,
                  trackedExer: option,
                  unit1: option.prDataFormat.tableColumns[0].valueDescription,
                  exerLink: option.id,
                  inputType: option.prDataFormat.tableColumns.length,
                  isLowerNumberBetter: option.isLowerNumberBetterForMaxes,
                  leftRight: false,
                };
                if (option.prDataFormat.tableColumns[1]) {
                  newItem.unit2 = option.prDataFormat.tableColumns[1].valueDescription;
                }
                const newArray = [...items];
                newArray.splice(itemIndex, 1, newItem);
                setItems(newArray);
              }}
            />
          )
          : (
            <ItemInput
              onChange={(e) => {
                setEvalItemName(e.target.value);
              }}
              onBlur={() => {
                changeEvalItemName();
              }}
              className='custom-item-input'
            />
          )
        }
      </SecondContainer>
      <ThirdContainer width={thirdWidth}>
        <InputValueBox>
          {!item.tracked ? (
            <>
              <LeftInputValue
                onClick={() => changeInputNumber(true)}
                isActive={isSingleInputValue}
              >
                1
              </LeftInputValue>
              <RightInputValue
                onClick={() => changeInputNumber(false)}
                isActive={!isSingleInputValue}
              >
                2
              </RightInputValue>
            </>
          ) : (
            <>
              <DisabledInputValue highlighted={item.columnType === 'OneColumn'}>
                1
              </DisabledInputValue>
              <DisabledInputValue highlighted={item.columnType === 'TwoColumns'}>
                2
              </DisabledInputValue>
            </>
          )}
        </InputValueBox>
      </ThirdContainer>
      <FourthContainer width={fourthWidth}>
        <SelectValueBox>
          {!item.tracked ? (
            <Select
              options={unitsWithOptions}
              isDisabled={item.tracked}
              key={`${firstMetric}1`}
              value={firstMetric || null}
              defaultValue={firstMetric}
              className='unit-select'
              getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
              getOptionValue={(option) => option.id}
              onChange={(option) => {
                const newItem = { ...item };
                newItem.unit1 = option.name;
                const newArray = [...items];
                newArray.splice(itemIndex, 1, newItem);
                setItems(newArray);
              }}
              isOptionSelected={(option) => {
                if (option.name === item.unit1) {
                  return true;
                }
                return false;
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          ) : (
            <HardCodeUnitBox>
              {firstMetric ? capitalizeFirstLetter(firstMetric.name) : ''}
            </HardCodeUnitBox>
          )}
          {!item.tracked ? (
            <Select
              options={unitsWithOptions}
              isDisabled={isSingleInputValue}
              className='unit-select'
              key={`${secondMetric}2`}
              value={secondMetric || null}
              getOptionLabel={(option) => capitalizeFirstLetter(option.name)}
              getOptionValue={(option) => option.id}
              defaultValue={secondMetric}
              onChange={(option) => {
                const newItem = { ...item };
                newItem.unit2 = option.name;
                const newArray = [...items];
                newArray.splice(itemIndex, 1, newItem);
                setItems(newArray);
              }}
              isOptionSelected={(option) => {
                if (option.name === item.unit2) {
                  return true;
                }
                return false;
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          ) : (
            <HardCodeUnitBox>
              {secondMetric ? capitalizeFirstLetter(secondMetric.name) : ''}
            </HardCodeUnitBox>
          )}
        </SelectValueBox>
      </FourthContainer>
      <FifthContainer width={fifthWidth}>
        <RightCheckboxContainer disabled={item.tracked}>
          <Checkbox
            checked={item.tracked ? false : isLRChecked}
            onChange={(e) => changeLR(e.target.checked)}
            disabled={item.tracked}
          />
        </RightCheckboxContainer>
      </FifthContainer>
      <SixthContainer width={sixthWidth}>
        <RightCheckboxContainer disabled={item.tracked}>
          <Checkbox
            checked={isLowerChecked}
            onChange={(e) => changeLower(e.target.checked)}
            disabled={item.tracked}
          />
        </RightCheckboxContainer>
      </SixthContainer>
      <DeleteButton onClick={() => {
        const newArray = [...items];
        newArray.splice(itemIndex, 1);
        setItems(newArray);
      }}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={10}
          icon='remove'
          color='grey'
        />
      </DeleteButton>
    </TrWrapper>
  );
};

EvaluationsTableRow.propTypes = {
  firstWidth: PropTypes.number.isRequired,
  secondWidth: PropTypes.number.isRequired,
  thirdWidth: PropTypes.number.isRequired,
  fourthWidth: PropTypes.number.isRequired,
  fifthWidth: PropTypes.number.isRequired,
  sixthWidth: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  setItems: PropTypes.func.isRequired,
  item: PropTypes.instanceOf(Object).isRequired,
  units: PropTypes.instanceOf(Array).isRequired,
  trackedExercises: PropTypes.instanceOf(Object).isRequired,
};

export default EvaluationsTableRow;
