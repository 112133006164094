/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';

import { useTheme } from 'emotion-theming';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Badge from '../../../../shared/components/Badge/Badge';

import useNotifications from '../hooks/useNotifications';
import useConversations from '../hooks/useConversations';

const BadgeContainer = styled('div')`
  display: block;
  margin-left: 15px;
  margin-right: 5px;
  align-self: center;
`;

const NotificationSideBarItem = ({
  notificationObject,
  isCheckboxesOpen,
  handleSetActiveNotification,
  setSelectedNotifications,
}) => {
  const theme = useTheme();
  const activeNotification = useSelector((state) => state.notifications.data.activeNotification);
  const selectedNotifications = useSelector(
    (state) => state.notifications.data.selectedNotifications,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const {
    handleMarkNotificationsAsRead,
  } = useNotifications();

  const {
    handleSetIsMobileSidebarOpen,
  } = useConversations();

  const NotificationDiv = styled('div')`
    display: flex;
    width: 100%;
    height: 80px;
    min-height: 80px;
    align-items: center;
    border-bottom: 1px solid lightgrey;
    cursor: pointer;
    background: ${(activeNotification && notificationObject.id === activeNotification.id) ? 'lightgrey' : ''};

    /* @keyframes slideIn {
      0% {
        opacity: 0;
        width: 5%;
        margin-left: 0px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 19px;
        opacity: 1;
        width: 15%
      }
    }
    @keyframes slideOut {
      0% {
        opacity: 1;
        width: 15%;
        margin-left: 19px;
      }
      50% {
        opacity: 0;
      }
      100% {
        margin-left: 0px;
        opacity: 0;
        width: 5%
      }
    } */

    .left-wrapper {
      display: flex;
      align-items: center;
      width: 60%;

      .checkbox-div {
        margin-left: ${isCheckboxesOpen ? '19px' : '0px'};
        opacity: ${isCheckboxesOpen ? 1 : 0};
        width: ${isCheckboxesOpen ? '15%' : '5%'}
      }
      .checkbox-div:active {
        opacity: 1;
        width: 15%;
        margin-left: 19px;
      }

      .message-box {
        display: flex;
        width: 90%;
        flex-direction: row;
        color: #444444;
        align-items: center;
        padding: 9px;
        border-radius: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;

        .sender-latest-message {
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          .title {
            font-size: 12px;
            font-weight: bold;
          }
          .latest-message {
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .end-box {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .latest-time {
        margin-top: -10px;
        margin-right: 10px;
        color: #444444;
        font-size: 10px;
      }

      .arrow-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 10%;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  `;

  const titleCase = (str) => str?.split(' ').map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');

  const newMoment = moment.unix(notificationObject.timestamp)
    .tz(currentUser.userTimeZone || currentUser.userSettings.timeZone);
  const returnMoment = newMoment.format('MMMM D');
  return (
    <NotificationDiv
      onClick={(e) => {
        if (e.target.type !== 'checkbox') {
          if (!activeNotification || (activeNotification.id !== notificationObject.id)) {
            handleSetActiveNotification(notificationObject);
            handleSetIsMobileSidebarOpen(false);
            if (!notificationObject.read) {
              handleMarkNotificationsAsRead(currentUser.accountCode, [notificationObject.id]);
            }
          }
        }
      }}
    >
      <div className='left-wrapper'>
        {isCheckboxesOpen ? (
          <div className='checkbox-div'>
            <Checkbox
              value={notificationObject.id}
              checked={selectedNotifications[notificationObject.id]}
              onChange={(e) => {
                const newSelectedNotifications = { ...selectedNotifications };
                if (newSelectedNotifications[e.target.value]) {
                  delete newSelectedNotifications[e.target.value];
                } else {
                  newSelectedNotifications[e.target.value] = true;
                }
                setSelectedNotifications(newSelectedNotifications);
              }}
              color='primary'
              disabled={!isCheckboxesOpen}
            />
          </div>
        ) : (
          <>
            {!notificationObject.read && (
            <BadgeContainer>
              <Badge
                color={theme.colors.orange}
              />
            </BadgeContainer>
            )}
          </>
        )}
        <div className='message-box'>
          <div
            className='userpic'
          >
            <IcomoonReact
              iconSet={iconSet}
              size={notificationObject.icon === 'fist-bump-full-together' ? 25 : 20}
              icon={notificationObject.icon}
            />
          </div>
          <div className='sender-latest-message'>
            <div className='title'>{titleCase(notificationObject?.typeDescription.replace(new RegExp('_', 'g'), ' '))}</div>
            <div className='latest-message'>{notificationObject.title}</div>
          </div>
        </div>
      </div>
      <div className='end-box'>
        <div className='latest-time'>{returnMoment}</div>
        <div className='arrow-box'>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='altrightarrow'
            color='grey'
          />
        </div>
      </div>
    </NotificationDiv>
  );
};

NotificationSideBarItem.propTypes = {
  notificationObject: PropTypes.instanceOf(Object).isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
  handleSetActiveNotification: PropTypes.func.isRequired,
  setSelectedNotifications: PropTypes.func.isRequired,
};

export default NotificationSideBarItem;
