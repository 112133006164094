import {
  FETCH_EXERCISES_FULFILLED,
  FETCH_EXERCISES_REJECTED,
} from './actionTypes';

const initialState = {
  testData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        testData: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
