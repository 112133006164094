import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ButtonRound from './ButtonRound';
import Modal from './Modal';
import ModalVideo from './ModalVideo';
import ModalInfo from './ModalInfo';
import WorkoutHistoryModal from '../../../../shared/components/WorkoutHistoryModal/WorkoutHistoryModal';
import WeightroomModalNote from '../container/WeightroomModalNote';
import WeightroomModalOptOut from '../container/WeightroomModalOptOut';
import { fetchMoreHistory } from '../../ducks/panelActions';

const WeightroomModalDocument = React.lazy(() => import('../container/WeightroomModalDocument'));

const WorkoutModals = ({
  closeModal,
  data,
  exerName,
  namespace,
  workId,
  isModalOpen,
}) => {
  const activeModal = useSelector((state) => state.weightRoomView[namespace].activeModal);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const currentUser = useSelector((state) => state.weightRoomView.data.currentUser);
  const index = useSelector((state) => state.weightRoomView[namespace].activeWorkoutIndex);
  const isLoadingExercises = useSelector((
    state,
  ) => state.weightRoomView[namespace].isLoadingExercises);
  const isEndOfWorkoutHistoryList = useSelector((
    state,
  ) => state.weightRoomView[namespace].isEndOfWorkoutHistoryList);
  const isLoadingHistory = useSelector((state) => state.weightRoomView[namespace].isLoadingHistory);
  const workoutHistory = useSelector((state) => state.weightRoomView[namespace].workoutHistory);
  const workoutHistoryPage = useSelector((
    state,
  ) => state.weightRoomView[namespace].workoutHistoryPage);
  const isDarkThemeEnabled = useSelector((state) => state.weightRoomView.ui.isDarkThemeEnabled);

  const dispatch = useDispatch();

  const modalClose = () => {
    closeModal(namespace);
  };

  const fetchMoreHistoryFunction = () => {
    dispatch(fetchMoreHistory(
      namespace,
      currentUser.accountCode,
      data.exercise?.id,
      athleteId,
      workoutHistoryPage,
    ));
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={modalClose}
      isHistory={activeModal === 'history'}
    >
      { activeModal === 'note' && (
        <>
          <WeightroomModalNote
            exerName={exerName}
            index={index}
            namespace={namespace}
            workout={data}
            modalClose={modalClose}
          />
        </>
      )}
      { activeModal === 'video' && (
        <>
          <ModalVideo
            name={exerName}
            video={data.exercise.media[0].mediaUrl}
          />
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </>
      )}
      { activeModal === 'info' && (
        <>
          <ModalInfo
            name={exerName}
            index={index}
            instruction={data.exercise?.description}
          />
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </>
      )}
      { activeModal === 'history' && (
        <>
          <WorkoutHistoryModal
            closeModalFunction={modalClose}
            modalTitle={data.exercise?.name}
            isHistoryFetching={isLoadingHistory}
            reduxHistoryData={workoutHistory}
            isModalActive={isModalOpen}
            isDarkMode={isDarkThemeEnabled}
            fetchMoreHistoryFunction={fetchMoreHistoryFunction}
            isEndOfWorkoutHistoryList={isEndOfWorkoutHistoryList}
          />
        </>
      )}
      { activeModal === 'attachment' && (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <WeightroomModalDocument
              namespace={namespace}
            />
          </Suspense>
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </>
      )}
      { activeModal === 'opt-out' && (
        <>
          <WeightroomModalOptOut
            name='Opt Out'
            namespace={namespace}
            modalClose={modalClose}
            isLoadingExercises={isLoadingExercises}
            index={index}
            workId={workId}
            workout={data}
          />
        </>
      )}
    </Modal>
  );
};

WorkoutModals.propTypes = {
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  exerName: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  workId: PropTypes.number.isRequired,
};

export default WorkoutModals;
