/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import ResizeObserver from 'react-resize-observer';
import { setIsShareModalOpen, getReportCardLink } from '../../ducks/athleteReportCardActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const Header = ({ allowSharing }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState(0);
  const overview = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview);
  const userId = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.user?.id);
  const startDate = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.startDate);
  const endDate = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.endDate);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const formattedEndDate = moment(endDate).format('YYYY-MM-DD');
  const formattedStartDate = moment(startDate).format('YYYY-MM-DD');

  const handleShareReport = () => {
    dispatch(getReportCardLink(currentUser?.accountCode, userId, formattedStartDate, formattedEndDate));
    dispatch(setIsShareModalOpen(true));
  };

  const HeaderWrapper = styled('div')`
    display: flex;
    width: 100%;
    background-color: #1E212E;
    justify-content: center;
  `;

  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    position: relative;
    max-width: 1400px;
    background-color: #1E212E;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.containerWidth < 540 ? '20px' : '40px')};
    padding-top: ${allowSharing ? '40px' : '60px'};
    padding-bottom: ${(props) => (props.containerWidth < 540 ? '40px' : '60px')};
  `;
  const ImageAndNameContainer = styled('div')`
    display: flex;
    gap: 15px;
    margin-top: ${(props) => (props.containerWidth < 540 ? '20px' : '50px')};
    flex-direction: ${(props) => (props.containerWidth < 540 ? 'column' : 'row')};
    align-items: center;
    text-align: ${(props) => (props.containerWidth < 540 ? 'center' : '')};
  `;
  const Stats = styled('div')`
    display: flex;
    flex-direction: column;
    max-width: 350px;
  `;
  const AthleteAvatar = styled('img')`
  height: ${(props) => (props.containerWidth < 540 ? '60px' : '100px')};
  width: ${(props) => (props.containerWidth < 540 ? '60px' : '100px')};
  border-radius: 50%;
  `;

  const ShareButton = styled('div')`
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    align-self: ${(props) => (props.containerWidth < 540 ? 'center' : 'end')};
    min-width: 100px;
    align-items: center;
    border-radius: 4px;
    background: #F6F6F6;
    padding: 8px 10px 8px 16px;
    box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
    cursor: pointer;
  `;

  return (
    <HeaderWrapper>
      <HeaderContainer containerWidth={containerWidth}>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        {allowSharing && (
          <ShareButton containerWidth={containerWidth} onClick={() => handleShareReport()}>
            <Text color={theme.colors.text} fontSize='16px' fontWeight={700}>Share</Text>
            <IcomoonReact
              iconSet={iconSet}
              size={16}
              icon='share-alt'
              color={theme.colors.text}
            />
          </ShareButton>
        )}
        <Text fontSize={containerWidth < 540 ? '18px' : '36px'} fontWeight={900} color='white' style={{ textAlign: 'center' }}>Weekly Performance Assessment</Text>
        <Text style={{ textAlign: 'center' }} fontSize={containerWidth < 540 ? '12px' : '20px'} fontWeight={700} color='white'>{`Week of: ${overview?.startDate} - ${overview?.endDate}`}</Text>
        <ImageAndNameContainer containerWidth={containerWidth}>
          <AthleteAvatar src={overview?.user?.pic} alt='athlete avatar' containerWidth={containerWidth} />
          <Stats>
            <Text fontSize={containerWidth < 540 ? '20px' : '28px'} fontWeight={800} color='white'>{`${overview?.user?.first} ${overview?.user?.last}`}</Text>
            <Text fontSize={containerWidth < 540 ? '13px' : '20px'} fontWeight={600} color='white'>{overview?.user?.accountName}</Text>
            {overview?.user?.bodyWeight && (
            <Text fontSize={containerWidth < 540 ? '13px' : '28px'} fontWeight={800} color='white'>{overview?.user?.bodyWeight}</Text>
            )}
          </Stats>
        </ImageAndNameContainer>
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
