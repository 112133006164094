import {
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED,
  FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED,
  FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED,
  FETCH_SHARED_WORKOUT_PREVIEW_REJECTED,
} from './actionTypes';

const initialState = {
  programData: {},
  workoutData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_FULFILLED: {
      return {
        ...state,
        programData: action.payload,
        isProgramDataLoaded: true,
      };
    }
    case FETCH_SHARED_WORKOUT_PROGRAM_DETAILS_REJECTED: {
      return {
        ...state,
        isProgramDataLoaded: false,
        fetchProgramError: action.payload,
      };
    }
    case FETCH_SHARED_WORKOUT_PREVIEW_FULFILLED: {
      return {
        ...state,
        workoutData: action.payload,
      };
    }
    case FETCH_SHARED_WORKOUT_PREVIEW_REJECTED: {
      return {
        ...state,
        workoutDataError: action.payload.response.headers,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
