import {
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_FULFILLED,
  FETCH_ACCOUNTS_REJECTED,
  SWITCH_ACCOUNT_START,
  SWITCH_ACCOUNT_FULFILLED,
  SWITCH_ACCOUNT_REJECTED,
  FETCH_UNREAD_MESSAGES_START,
  FETCH_UNREAD_MESSAGES_FULFILLED,
  FETCH_UNREAD_MESSAGES_REJECTED,
  SET_LAYOUT_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  isAccountLoading: false,
  isAccountSwitched: false,
  isAccountSwitching: false,
  activeModal: '',
  isModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNTS_START: {
      return {
        ...state,
        isAccountsLoading: true,
      };
    }
    case FETCH_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        isAccountsLoading: false,
      };
    }
    case FETCH_ACCOUNTS_REJECTED: {
      return {
        ...state,
        isAccountLoading: false,
      };
    }
    case SWITCH_ACCOUNT_START: {
      return {
        ...state,
        isAccountSwitching: true,
      };
    }
    case SWITCH_ACCOUNT_FULFILLED: {
      return {
        ...state,
        isAccountSwitching: false,
        isAccountSwitched: true,
      };
    }
    case SWITCH_ACCOUNT_REJECTED: {
      return {
        ...state,
        isAccountLoading: false,
      };
    }
    case FETCH_UNREAD_MESSAGES_START: {
      return {
        ...state,
        isUnreadMessagesLoading: true,
      };
    }
    case FETCH_UNREAD_MESSAGES_FULFILLED: {
      return {
        ...state,
        isUnreadMessagesLoading: false,
      };
    }
    case FETCH_UNREAD_MESSAGES_REJECTED: {
      return {
        ...state,
        isUnreadMessagesLoading: false,
      };
    }
    case SET_LAYOUT_MODAL_OPEN: {
      return {
        ...state,
        activeModal: action.payload,
        // isModalOpen: action.payload.isModalOpen,
      };
    }
    case SUBMIT_SUPPORT_START: {
      return {
        ...state,
        isSupportSubmitting: true,
      };
    }
    case SUBMIT_SUPPORT_FULFILLED: {
      return {
        ...state,
        isSupportSubmitting: false,
        activeModal: '',
      };
    }
    case SUBMIT_SUPPORT_REJECTED: {
      return {
        ...state,
        isSupportSubmitting: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
