const mainTheme = {
  colors: {
    text: '#444444',
    lightText: [
      '#979797',
      '#686868',
      'rgba(68, 68, 68, 0.35)',
    ],
    background: [
      'white',
      'white',
      'white',
      'rgba(68, 68, 68, 0.15)',
      '#f5f5f5',
      '#f1f1f1',
    ],
    border: '#e4e7e7',
    buttonBorder: [
      '#979797',
      '#ACACAC',
    ],
    focus: [
      '#0dcc8a',
      '#ff6600',
    ],
    overlay: 'rgba(216, 216, 216, 0.5)',
    noBorderForm: 'hsl(0,0%,80%)',
    noBorderColor: '#979797',
    iconBorder: '#444444',
    overflowGradient: 'linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1))',
    gradient: 'linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%)',
    gradientOverlay: 'linear-gradient(-20deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 154, 68, .5) 100%)',
    darkBlue: '#1c212f',
    lightBlue: '#3fbfe1',
    blue: '#5b86e5',
    green: '#0dcc8a',
    red: '#ff0024',
    redBlend: '#D67F5A',
    orange: '#ff6600',
    yellow: '#FFCB47',
    yellowBlend: '#A1D174',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    black: '#444445',
    darkGray: '#616161',
  },
  headerFontSizes: [
    '24pt',
    '20pt',
    '18pt',
    '16pt',
  ],
  textFontSizes: [
    '10pt',
    '12pt',
    '14pt',
    '16pt',
  ],
};

// const theme = {
//   colorAccent: skin && skin.accent,
//   colorPrimary: skin && skin.primary,
//   colorSecondary: skin && skin.secondary,
//   isAccentDarkTheme: skin && skin.accentDarkTheme,
//   isDarkTheme: skin && skin.darkTheme,
//   isPrimaryDarkTheme: skin && skin.darknessMode,
//   isSecondaryDarkTheme: skin && skin.secondaryDarkTheme,
//   // ...defaultTheme,
//   ...mainTheme,
// };

export default mainTheme;

// export const defaultTheme = {
// };

// export const lightTheme = {
//   colors: {
//     text: '#444444',
//     lightText: [
//       '#979797',
//       '#686868',
//       'rgba(68, 68, 68, 0.35)',
//     ],
//     background: [
//       'white',
//       'white',
//       'white',
//     ],
//     border: '#e4e7e7',
//     buttonBorder: [
//       '#979797',
//       '#ACACAC',
//     ],
//     focus: [
//       '#0dcc8a',
//       '#ff6600',
//     ],
//     overlay: 'rgba(216, 216, 216, 0.5)',
//     noBorderForm: 'hsl(0,0%,80%)',
//     noBorderColor: '#979797',
//     iconBorder: '#444444',
//     overflowGradient: 'linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1))',
//     gradient: 'linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%)',
//     gradientOverlay: 'linear-gradient(-20deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 154, 68, .5) 100%)',
//     darkBlue: '#1c212f',
//   },
// };

// export const darkTheme = {
//   colors: {
//     text: 'rgba(255, 255, 255, 0.80)',
//     lightText: [
//       'rgba(255, 255, 255, 0.75)',
//       'rgba(255, 255, 255, 0.65)',
//       'rgba(255, 255, 255, 0.55)',
//     ],
//     background: [
//       'rgba(0, 0, 0, 0.87)',
//       'rgb(65, 65, 65)',
//       'rgb(95, 95, 95)',
//     ],
//     border: 'rgb(100, 100, 100)',
//     buttonBorder: [
//       'rgb(65, 65, 65)',
//       'rgb(65, 65, 65)',
//     ],
//     focus: [
//       '#0ab3cd',
//       '#ff6600',
//     ],
//     overlay: 'rgba(30, 30, 30, 0.6)',
//     noBorderForm: 'rgb(95, 95, 95)',
//     noBorderColor: 'rgba(30, 30, 30, 0.6)',
//     iconBorder: 'rgba(65, 65, 65)',
//     overflowGradient: 'linear-gradient(to right,rgba(36,36,36,0),rgba(36,36,36,1))',
//     gradient: 'linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%)',
//     gradientOverlay: 'linear-gradient(-20deg, rgba(252, 96, 118, 0.5) 0%, rgba(255, 154, 68, .5) 100%)',
//     darkBlue: '#1c212f',
//   },
// };

// const skin = {
//   accent: '#006a65',
//   accentDarkTheme: false,
//   background: '',
//   darkTheme: false,
//   darknessMode: true,
//   logo: 'https://s3.amazonaws.com/teambuildr-staging-assets/logos/-1548780431.png',
//   primary: '#472233',
//   secondary: '#ffffff',
//   secondaryDarkTheme: true,
//   exerciseIconDarkTheme: false,
//   exerciseIconInverted: false,
//   exerciseStatusDarkTheme: false,
//   actionColors: {
//     add: '#2ecc71',
//     edit: '#3498db',
//     view: '#3498db',
//     complete: '#27ae60',
//   },
//   exerciseColors: {
//     note: '#F9BF3B',
//     warmup: '#D64541',
//     cooldown: '#81CFE0',
//     lift: '#6C7A89',
//     tag: '#6C7A89',
//     circuit: '#F89406',
//     cardio: '#59ABE3',
//     health: '#F64747',
//     science: '#9b59b6',
//     video: '#e74c3c',
//     rest: '#34495e',
//     break: '#34495e',
//     progression: '#446CB3',
//   },
//   supersetColors: {
//     A: '#00d68b',
//     B: '#0ab3cd',
//     C: '#f36dab',
//     D: '#f3ea6d',
//     E: '#f3a96d',
//     F: '#f3716d',
//     G: '#6de1f3',
//     H: '#c389f5',
//     I: '#f5898b',
//     J: '#00d68b',
//     K: '#0ab3cd',
//     L: '#f36dab',
//     M: '#f3ea6d',
//     N: '#f3a96d',
//     O: '#f3716d',
//     P: '#6de1f3',
//     Q: '#c389f5',
//     R: '#f5898b',
//     S: '#00d68b',
//     T: '#0ab3cd',
//     U: '#f36dab',
//     V: '#f3ea6d',
//     W: '#f3a96d',
//     X: '#f3716d',
//     Y: '#6de1f3',
//     Z: '#c389f5',
//   },
//   entryColors: {
//     save: '#2dcc70',
//     journal: '#f9bf3a',
//     video: '#d8534f',
//     history: '#03c44f',
//     document: '#a5a5a5',
//     close: '#ff5844',
//     optOut: '#f9bf3a',
//   },
// };
