import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import {
  fetchExercises,
  fetchGroups,
  fetchLeaderboard,
} from '../../ducks/leaderboardActions';

const useLeaderboard = () => {
  const activeBoards = useSelector(state => state.leaderboard.ui.activeBoards);
  const currentUser = useSelector(state => state.auth.data.currentUser);
  const exercises = useSelector(state => state.leaderboard.data.exercises);
  const groups = useSelector(state => state.leaderboard.data.groups);
  const isLoadingExercises = useSelector(state => state.leaderboard.ui.isLoadingExercises);
  const isLoadingGroups = useSelector(state => state.leaderboard.ui.isLoadingGroups);
  const isLoadingLeaderboard = useSelector(state => state.leaderboard.ui.isLoadingLeaderboard);
  const leaderboards = useSelector(state => state.leaderboard.data.leaderboards);
  const leaderboardError = useSelector(state => state.leaderboard.data.leaderboardError);
  const dispatch = useDispatch();

  const handleFetchExercises = (accountCode, exerciseType) => {
    dispatch(fetchExercises(accountCode, exerciseType));
  };

  const handleFetchGroups = (accountCode) => {
    dispatch(fetchGroups(accountCode));
  };

  const handleFetchLeaderboard = (values, actionType, namespace) => {
    dispatch(fetchLeaderboard(values, actionType, namespace));
  };

  return {
    activeBoards,
    currentUser,
    exercises,
    groups,
    handleFetchExercises,
    handleFetchGroups,
    handleFetchLeaderboard,
    isLoadingExercises,
    isLoadingGroups,
    isLoadingLeaderboard,
    leaderboards,
    leaderboardError,
  };
};

export default useLeaderboard;
