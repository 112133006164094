/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
// import PropTypes from 'prop-types';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setAutoSaveInfoModal } from '../../ducks/evaluationsActions';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  scrollbar-width: thin;
  overflow: auto;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  min-width: 430px;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .print-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  max-width: 1000px;
`;

const RemoveDiv = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 1001;
  color: ${(props) => (props.isDarkMode ? 'white' : '#444444')};
	background: white;
  .remove-div-icon {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const PrimaryContent = styled('div')`
  margin-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  user-select: none;
  align-items: center;
`;

const ModalHeader = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const HeaderIconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  height: 52px;
  width: 52px;
  border-radius: 50%;
`;

const ModalHeaderText = styled('div')`
  font-size: 28px;
  font-family: 'Nunito Sans';
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
`;

const TextChunk = styled('div')`
  font-size: 16px;
  font-family: 'Nunito Sans';
  text-align: center;
  margin-top: 10px;
  font-weight: 400;
`;

const SmallTextChunk = styled('div')`
  font-size: 12px;
  font-family: 'Nunito Sans';
  margin-top: 5px;
  text-align: center;
`;

const Badge = styled('div')`
  box-shadow: ${BoxShadow};
  height: 30px;
  border-radius: 50px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const BadgeIndicator = styled('div')`
  background: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const BadgeText = styled('div')`
  font-size: 10px;
  font-family: 'Nunito Sans';
  font-weight: 800;
  margin-left: 5px;
  margin-top: 1px;
`;

const CoolButton = styled('div')`
  background: #0DCC8A;
  height: 40px;
  border-radius: 50px;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 600;
`;

const AutoSaveInfoModal = () => {
  const autoSaveInfoModalShowing = useSelector(
    (state) => state.evaluations.ui.autoSaveInfoModalShowing,
  );

  const dispatch = useDispatch();

  return (
    // the isActive parameter gets passed to the ModalContainer
    // the ModalContainer transitions based on the truth of isModalActive
    <ModalContainer isActive={autoSaveInfoModalShowing}>
      <RemoveDiv>
        <ModalHeader>
          <HeaderIconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='info'
            />
          </HeaderIconContainer>
          <ModalHeaderText>Evaluations Automatically Save</ModalHeaderText>
        </ModalHeader>
        {/**
         * remove icon fires the closeModalFunction
         */}
        <div
          className='remove-div-icon'
          onClick={() => {
            dispatch(setAutoSaveInfoModal(false));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </RemoveDiv>
      <ContentContainer>
        <PrimaryContent>
          <TextChunk>
            Your progress will automatically
            be saved. No need to worry about losing your work.
            Keep calm and carry on.
          </TextChunk>
          <Badge>
            <BadgeIndicator color='#0DCC8A' />
            <BadgeText>
              Last Saved
            </BadgeText>
          </Badge>
          <SmallTextChunk>
            Green indicator will display when connection
            is working well and provide a time stamp of the last auto-save time.
          </SmallTextChunk>
          <Badge>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='opt-out'
            />
            <BadgeText>
              Check Connection
            </BadgeText>
          </Badge>
          <SmallTextChunk>
            Check internet connection as soon as possible,
            saves will not be updated until connection is restored.
          </SmallTextChunk>
          <Badge>
            <BadgeIndicator color='#FFCB47' />
            <BadgeText>
              Slow Connection
            </BadgeText>
          </Badge>
          <SmallTextChunk>
            Yellow indicator means there will be potential delays or problems while
            filling out an evaluation.
            Most likely the changes you made to the evaluation were not saved.
          </SmallTextChunk>
          <TextChunk>
            If you’d like to revisit this information,
            click the saved indicator icon as shown above to review this pop up again.
          </TextChunk>
          <CoolButton onClick={() => {
            dispatch(setAutoSaveInfoModal(false));
          }}
          >
            Close
          </CoolButton>
        </PrimaryContent>
      </ContentContainer>
    </ModalContainer>
  );
};

AutoSaveInfoModal.defaultProps = {
  modalTitle: '',
  useEffectFunction: undefined,
  mediaDispatchFunction: undefined,
  isDarkMode: false,
};

AutoSaveInfoModal.propTypes = {

};

export default AutoSaveInfoModal;
