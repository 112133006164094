/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import ReactPlayer from 'react-player';

import Text from '../../../../../shared/components/Text/Text';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';

import { setActiveWorkoutsMedia } from '../../../ducks/workoutsActions';

const ModalWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1005;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const InnerWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const AnotherWrapper = styled('div')`
  position: relative;
  padding-top: 56.25%;
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;
  margin-bottom: 30px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const ImageLightBoxContainer = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const MediaModalContent = () => {
  const activeWorkoutsMedia = useSelector((state) => state.workouts.data.activeWorkoutsMedia);

  let mediaUrl;

  if (activeWorkoutsMedia.mediaUrl.includes('drive.google')) {
    const split = activeWorkoutsMedia.mediaUrl.split('/');
    const id = split[split.length - 2];
    mediaUrl = `https://drive.google.com/uc?export=download&id=${id}`;
  } else {
    mediaUrl = activeWorkoutsMedia.mediaUrl;
  }

  const dispatch = useDispatch();

  return (
    <ModalWrapper>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            dispatch(setActiveWorkoutsMedia({}));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
            color='white'
          />
        </Text>
      </NavigationWrapper>
      <InnerWrapper>
        {activeWorkoutsMedia.type !== 'IMAGE' && activeWorkoutsMedia.type !== 'EXTERNAL_URL' ? (
          <AnotherWrapper>
            <ReactPlayer
              className='react-player'
              url={mediaUrl}
              controls
              width='100%'
              height='100%'
              style={{ backgroundColor: 'black' }}
              muted
            />
          </AnotherWrapper>
        ) : <ImageLightBoxContainer src={mediaUrl} />}
      </InnerWrapper>
    </ModalWrapper>
  );
};

MediaModalContent.propTypes = {

};

export default MediaModalContent;
