import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Text from '../../../../../../shared/components/Text/Text';

const DropdownInput = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  top: 100%;
  margin-top: 15px;
  margin-left: 12px;
  height: max-content;
  width: 232px;
  border-radius: 12px;
  padding: 10px;
  .input-box {
    border: 2px solid #E0E0E0;
    border-radius: 6px;
    height: 40px;
    padding-left: 6px;
  }
  .input-save {
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
`;

const FilterDropdownProgramName = ({
  stringValue,
  setStringValue,
  setNameValue,
  inputRef,
  toggleFilterDropdown,
  updateFilter,
  overlayDispatchAction,
}) => {
  const onClickHandler = () => {
    if (stringValue !== '') {
      toggleFilterDropdown('Name');
      updateFilter(1);
      setNameValue(stringValue);
      setStringValue(stringValue);
      overlayDispatchAction(false);
    }
    return null;
  };

  return (
    <DropdownInput>
      <Text>contains</Text>
      <input
        className='input-box'
        type='text'
        id='contains'
        name='contains'
        value={stringValue}
        ref={inputRef}
        onChange={(e) => {
          setStringValue(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onClickHandler();
          }
        }}
      />
      <Text
        className='input-save'
        onClick={onClickHandler}
      >
        Save
      </Text>
    </DropdownInput>
  );
};

FilterDropdownProgramName.propTypes = {
  stringValue: PropTypes.string.isRequired,
  setStringValue: PropTypes.func.isRequired,
  setNameValue: PropTypes.func.isRequired,
  inputRef: PropTypes.instanceOf(Object).isRequired,
  toggleFilterDropdown: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  overlayDispatchAction: PropTypes.func.isRequired,
};

export default FilterDropdownProgramName;
