/* eslint-disable no-tabs */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  fetchWorkoutDocument,
} from '../../ducks/workoutsActions';

const DocumentButton = styled('div')`
	border: 1px solid lightgray;
	border-radius: 12px;
  background: white;
  width: 200px;
	cursor: pointer;
  padding: 10px;
`;

const Container = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	height: auto;
  margin-bottom: 15px;
`;

const DocumentText = styled('div')`
	font-family: 'Nunito Sans';
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Nunito Sans';
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconTextWrapper = styled('div')`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
    min-width: 15px;
    margin-top: 1px;
  }
  justify-content: center;
`;

const WorkoutDocumentComponent = ({ workoutObject }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const documentFetcher = (docId) => {
    dispatch(fetchWorkoutDocument(currentUser.accountCode, docId));
  };

  const getDocumentIconByType = (docType) => {
    if (docType.toLowerCase() === 'link') {
      return 'globe';
    }
    return 'file';
  };

  return (
    <Container>
      {workoutObject.documents.map((document) => (
        <DocumentButton onClick={() => {
          documentFetcher(document.id);
        }}
        >
          <IconTextWrapper>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon={getDocumentIconByType(document?.typeDescription)}
              color='#3FBFE1'
            />
            <DocumentText>{document.name}</DocumentText>
          </IconTextWrapper>
        </DocumentButton>
      ))}
    </Container>
  );
};

WorkoutDocumentComponent.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default WorkoutDocumentComponent;
