/* eslint-disable no-tabs */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import TBSelectBox from '../../../../shared/components/TBSelectBox/TBSelectBox';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  submitProgramWorkoutItem,
  submitWorkoutItem,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	width: 100%;
	display: flex;
  flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: ${BoxShadow};
	border-radius: 12px;
	flex-direction: column;
  background: white;
  padding-bottom: 20px;
  padding-top: 20px;
  height: auto;
`;

const ProgressionTitleText = styled('div')`
	width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  margin-bottom: 20px;
`;

const ProgressionAsteriskText = styled('div')`
	width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito Sans';
  font-size: 12px;
  margin-top: 20px;
  white-space: normal;
  margin-left: 25px;
`;

const CompleteButton = styled('button')`
  width: 140px;
	height: 37px;
	border-radius: 40px;
  box-shadow: ${BoxShadow};
	border: ${(props) => (props.isWorkoutComplete ? '1px solid #00b371;' : '1px solid lightgrey')};
	margin-top: 20px;
	background: ${(props) => (props.isWorkoutComplete ? '#00b371;' : 'white')};
  transition: all 0.1s ease;
`;

const InnerCompleteButton = styled('div')`
  display: flex;
	justify-content: center;
	align-items: center;
`;

const CompleteButtonText = styled('div')`
  font-size: 12px;
	margin-left: 10px;
	font-weight: 600;
	font-family: 'Nunito Sans';
	color: ${(props) => (props.isWorkoutComplete ? 'white' : 'black')};
`;

const ButtonContainer = styled('div')`
  width: 100%;
	display: flex;
	justify-content: center;
`;

const MASTERED = true;
const STILL_WORKING = false;

const masteredProgressionOption = {
  inputLabel: 'I have mastered this',
  label: 'I have mastered this',
  value: MASTERED,
};

const stillWorkingProgressionOption = {
  inputLabel: 'I am still working on this',
  label: 'I am still working on this',
  value: STILL_WORKING,
};

const progressionOptions = [
  masteredProgressionOption,
  stillWorkingProgressionOption,
];

const ProgressionCard = ({
  workoutObject, index, outerIndex, sessionIndex,
}) => {
  const [isWorkoutComplete, setIsWorkoutComplete] = useState(false);
  const [progressionValue, setProgressionValue] = useState({});

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const programDayIndex = useSelector(
    (state) => state.workouts.data.programDayIndex,
  );

  // Added default option to fix an issue where the correct option wasn't displaying on reload
  const defaultOption = workoutObject.progressionStepMastered
    ? masteredProgressionOption
    : stillWorkingProgressionOption;

  const dispatch = useDispatch();

  /**
   * Whenever the component loads, we need to
   * store the status of whether or not this step is mastered in Redux
   */
  useEffect(() => {
    if (workoutObject) {
      setProgressionValue(
        workoutObject.progressionStepMastered
          ? masteredProgressionOption
          : stillWorkingProgressionOption,
      );
    }
  }, [workoutObject]);

  const completeButton = (handleSubmit) => (
    <CompleteButton
      isWorkoutComplete={isWorkoutComplete}
      onClick={() => {
        handleSubmit();
      }}
      onMouseDown={() => {
        setIsWorkoutComplete(true);
      }}
      onMouseUp={() => {
        setTimeout(() => {
          setIsWorkoutComplete(false);
        }, 1250);
      }}
    >
      <InnerCompleteButton>
        <IcomoonReact
          iconSet={iconSet}
          size={15}
          icon='checkmark'
          color={!isWorkoutComplete ? 'black' : 'white'}
        />
        <CompleteButtonText
          isWorkoutComplete={isWorkoutComplete}
        >
          Complete
        </CompleteButtonText>
      </InnerCompleteButton>
    </CompleteButton>
  );

  const submitProgressionItem = () => {
    const payload = {
      level: workoutObject.progressionLevel,
      mastered: progressionValue.value === MASTERED,
    };
    if (workoutObject.assignedId) {
      dispatch(
        submitWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          activeWorkoutDate,
          workoutObject.assignedId,
          payload,
          index,
          outerIndex,
          sessionIndex,
        ),
      );
    } else {
      dispatch(
        submitProgramWorkoutItem(
          currentUser,
          sharedAthlete.id,
          currentUser.accountCode,
          workoutObject.saveDataId,
          payload,
          index,
          outerIndex,
          sessionIndex,
          currentSelectedProgram.dayBasedProgramId,
          programDayIndex,
        ),
      );
    }
  };

  return (
    <Container>
      <ProgressionTitleText>
        Select current status for this step
      </ProgressionTitleText>
      <TBSelectBox
        list={progressionOptions}
        label='label'
        selectItemFunction={setProgressionValue}
        defaultOption={defaultOption}
        headerWidthPercentage={80}
      />
      <ButtonContainer>
        {completeButton(submitProgressionItem)}
      </ButtonContainer>
      <ProgressionAsteriskText>
        *All Steps in a level must be completed to move on to the next level.
      </ProgressionAsteriskText>
    </Container>
  );
};

ProgressionCard.defaultProps = {
  sessionIndex: null,
};

ProgressionCard.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  outerIndex: PropTypes.number.isRequired,
  sessionIndex: PropTypes.number,
};

export default ProgressionCard;
