import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import { useDebouncedCallback } from 'use-debounce';
import Text from '../../../../../shared/components/Text/Text';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';

const PageManagementSettings = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);

  const [pageManagementSettings, setPageManagementSettings] = useState({
    teamFeed: Boolean(organizationSettings?.pageVisibility?.feedEnabled),
    leaderboard: Boolean(organizationSettings?.pageVisibility?.leaderboardEnabled),
    attendance: Boolean(organizationSettings?.pageVisibility?.attendanceEnabled),
    reporting: Boolean(organizationSettings?.pageVisibility?.reportingEnabled),
    messaging: Boolean(organizationSettings?.pageVisibility?.messagingEnabled),
    onlinePayments: Boolean(organizationSettings?.pageVisibility?.resellerEnabled),
  });

  const debouncedSetCommunicationSettings = useDebouncedCallback(
    (newSettings) => {
      console.log(newSettings);

      const valueObj = {
        pageVisibility: {
          feedEnabled: newSettings.teamFeed,
          leaderboardEnabled: newSettings.leaderboard,
          attendanceEnabled: newSettings.attendance,
          reportingEnabled: newSettings.reporting,
          resellerEnabled: newSettings.onlinePayments,
          messagingEnabled: newSettings.messaging,
        },
      };
      dispatch(updateOrganizationSettings(accountCode, valueObj));
    },
    1000,
  );

  const handleSwitches = (field, v) => {
    setPageManagementSettings((prev) => ({
      ...prev,
      [field]: v,
    }));
    debouncedSetCommunicationSettings({
      ...pageManagementSettings,
      [field]: v,
    });
  };

  const PageManagementSettingsWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;

        .react-toggle .react-toggle-track {
            background-color: #E9E9EA;
        }
        .react-toggle--checked .react-toggle-track {
            background-color: ${theme.colors.green};
        }
        .react-toggle-thumb {
            border: none;
        }

        .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: #E9E9EA;
        }
        
        .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
            background-color: ${theme.colors.green};
        }
`;

  const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    cursor: pointer;
`;

  const HeaderContainer = styled('div')`
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
`;

  return (
    <PageManagementSettingsWrapper>
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Page Management
        </Text>
        <Text fontSize={18} fontWeight='400'>Manage what pages your athletes and coaches have access to.</Text>
      </HeaderContainer>
      <>
        <Row>
          <Text fontSize={18} fontWeight='700'>
            Team Feed
          </Text>
          <Toggle
            checked={pageManagementSettings.teamFeed}
            icons={false}
            onChange={() => {
              handleSwitches('teamFeed', !pageManagementSettings.teamFeed);
            }}
          />
        </Row>

        <Row>
          <Text fontSize={18} fontWeight='700'>
            Leaderboard
          </Text>
          <Toggle
            checked={pageManagementSettings.leaderboard}
            icons={false}
            onChange={() => {
              handleSwitches('leaderboard', !pageManagementSettings.leaderboard);
            }}
          />
        </Row>

        <Row>
          <Text fontSize={18} fontWeight='700'>
            Attendance
          </Text>
          <Toggle
            checked={pageManagementSettings.attendance}
            icons={false}
            onChange={() => {
              handleSwitches('attendance', !pageManagementSettings.attendance);
            }}
          />
        </Row>

        <Row>
          <Text fontSize={18} fontWeight='700'>
            Reporting
          </Text>
          <Toggle
            checked={pageManagementSettings.reporting}
            icons={false}
            onChange={() => {
              handleSwitches('reporting', !pageManagementSettings.reporting);
            }}
          />
        </Row>
        <Row>
          <Text fontSize={18} fontWeight='700'>
            Conversations
          </Text>
          <Toggle
            checked={pageManagementSettings.messaging}
            icons={false}
            onChange={() => {
              handleSwitches('messaging', !pageManagementSettings.messaging);
            }}
          />
        </Row>
        <Row>
          <Text fontSize={18} fontWeight='700'>
            Online Payments
          </Text>
          <Toggle
            checked={pageManagementSettings.onlinePayments}
            icons={false}
            onChange={() => {
              handleSwitches('onlinePayments', !pageManagementSettings.onlinePayments);
            }}
          />
        </Row>
      </>
    </PageManagementSettingsWrapper>
  );
};

PageManagementSettings.propTypes = {
  containerWidth: PropTypes.number,
};

PageManagementSettings.defaultProps = {
  containerWidth: '',
};

export default PageManagementSettings;
