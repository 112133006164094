/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import RecordVideoModal from './modals/RecordWorkoutsVideoModal';
import RecordQuickVideoModal from './modals/RecordQuickVideoModal';

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const RecordWorkoutsVideoModalContainer = () => {
  const captureVideoModal = useSelector((state) => state.workouts.data.captureVideoModal);
  const quickVideoWorkoutsJournalModal = useSelector(
    (state) => state.workouts.data.quickVideoWorkoutsJournalModal,
  );
  if (captureVideoModal) {
    return <RecordVideoModal />;
  } if (quickVideoWorkoutsJournalModal) {
    return <RecordQuickVideoModal />;
  }
  return null;
};

RecordWorkoutsVideoModalContainer.propTypes = { };

export default RecordWorkoutsVideoModalContainer;
