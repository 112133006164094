import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '../../../../shared/components/Button/Button';
import {
  fetchMetricCardData,
  fetchPerformanceOverview,
  setIsMetricFiltersPerformanceShowing,
  setMetricFiltersPerformance,
} from '../../ducks/forcePlateDashboardActions';
import TBTooltip from '../../../../shared/components/TBTooltip/TBTooltip';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const FilterAndButtonContainer = styled('div')`
  position: relative;
  margin-top: 8px;
`;

const Badge = styled('span')`
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 100px;
  background-color: #444444;
  color: #FFFFFF;
  width: 16px;
  display: flex;
  justify-content: center;
  font-family: 'Nunito Sans';
  font-size: 11px;
  font-weight: bold;
`;

const FilterContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 38px;
  background-color: #FFFFFF;
  z-index: 1;
  flex-direction: column;
  width: 250px;
  height: 420px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: ${BoxShadow};
`;

const FilterHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Filters = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-weight: bold;
  overflow-y: auto;
`;

const FilterOption = styled('div')`
  display: flex;
  align-items: center;
  margin-left: -10px;

  h3 {
    font-weight: normal;
  }
`;

const OptionText = styled('div')`
  font-family: 'Nunito Sans';
  
  font-weight: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const FilterBottom = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 16px;
`;

const PerformanceFilter = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const endDateData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.endDateData);
  const group = useSelector((
    state,
  ) => state.forcePlateDashboard.data.group);
  const isMetricFiltersPerformanceShowing = useSelector((
    state,
  ) => state.forcePlateDashboard.ui.isMetricFiltersPerformanceShowing);
  const startDateData = useSelector((
    state,
  ) => state.forcePlateDashboard.data.startDateData);
  const metricFiltersPerformance = useSelector((
    state,
  ) => state.forcePlateDashboard.data.metricFiltersPerformance);

  const metricOptions = [
    'Peak Power',
    'Jump Height',
    'Takeoff Velocity',
    'mRSI',
    'Flight Time',
    'Stiffness',
    'Positive Impulse',
    'Braking RFD (Rate of Force Development)',
    'Countermovement Depth',
    'Peak Relative Power',
    'Peak Landing Force',
    'Braking Impulse',
    'L|R Braking Impulse',
    'L|R Landing Impulse',
    'L|R Landing RFD',
    'L|R Concentric Impulse',
    'Left - Average Propulsive Force',
    'Right - Average Propulsive Force',
    'Left - Average Landing Force',
    'Right - Average Landing Force',
  ];

  const dispatch = useDispatch();

  const handleFilter = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Filter', 'Filter clicked');
    dispatch(setIsMetricFiltersPerformanceShowing(!isMetricFiltersPerformanceShowing));
  };

  const handleClose = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Filter', 'Filter closed');
    dispatch(setIsMetricFiltersPerformanceShowing(false));
  };

  const handleUpdate = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Filter', 'Filters updated');
    batch(() => {
      dispatch(setIsMetricFiltersPerformanceShowing(false));
      dispatch(
        fetchPerformanceOverview(
          currentUser.accountCode,
          startDateData,
          endDateData,
          group.id,
          metricFiltersPerformance,
        ),
      );
      dispatch(
        fetchMetricCardData(
          currentUser.accountCode,
          startDateData,
          endDateData,
          group.id,
        ),
      );
    });
  };

  const handleChange = (metric) => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Filter', `Filter selected - ${metric}`);
    // Remove whitespace from metric to fit in request body
    const formattedMetric = metric.replace(/\s/g, '');
    // If metric is already in filters, remove it
    if (metricFiltersPerformance.includes(formattedMetric)) {
      const newMetrics = [...metricFiltersPerformance];
      const index = newMetrics.indexOf(formattedMetric);
      if (index > -1) {
        newMetrics.splice(index, 1);
      }
      dispatch(setMetricFiltersPerformance(newMetrics));
    // Else add it to the filters array
    } else {
      const newMetrics = [...metricFiltersPerformance];
      newMetrics.push(formattedMetric);
      // If there are already 2 filters, remove the first one
      // Can only filter a max of two metrics
      if (newMetrics.length > 2) {
        newMetrics.splice(0, 1);
      }
      dispatch(setMetricFiltersPerformance(newMetrics));
    }
  };

  const handleClear = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Performance Overview - Filter', 'Filters cleared');
    dispatch(setMetricFiltersPerformance([]));
  };

  return (
    <FilterAndButtonContainer>
      <Button
        icon='filter'
        cta='Filter'
        rounded
        onClick={handleFilter}
      />
      {metricFiltersPerformance.length > 0 && (
        <Badge>{metricFiltersPerformance.length}</Badge>
      )}
      {isMetricFiltersPerformanceShowing && (
      <FilterContainer>
        <FilterHeader>
          Filter
          <Button
            icon='remove'
            iconOnly
            onClick={handleClose}
          />
        </FilterHeader>
        <Filters>
          Metric(s)
          {metricOptions.map(
            (option) => (
              <FilterOption key={option}>
                <Checkbox
                  color='default'
                  checked={metricFiltersPerformance.includes(option.replace(/\s/g, ''))}
                  onChange={() => handleChange(option)}
                />
                {option.length > 20 ? (
                  <TBTooltip
                    textToDisplay={option}
                    fontSize='16px'
                    textContainerPadding='0px'
                    textContainerMaxWidth='170px'
                  />
                ) : (
                  <OptionText>{option}</OptionText>
                )}
              </FilterOption>
            ),
          )}
        </Filters>
        <FilterBottom>
          <Button
            cta='Clear'
            rounded
            onClick={handleClear}
          />
          <Button
            cta='Update'
            disabled={metricFiltersPerformance.length === 0}
            rounded
            onClick={handleUpdate}
          />
        </FilterBottom>
      </FilterContainer>
      )}
    </FilterAndButtonContainer>
  );
};

export default PerformanceFilter;
