import update from 'immutability-helper';

import {
  CREATE_CELL_VALUE_FULFILLED,
  DELETE_EVALUATION_FULFILLED,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_EVALUATIONS_REJECTED,
  FETCH_EVALUATION_FULFILLED,
  FETCH_EVALUATION_REJECTED,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_TRACKED_EXERCISES_FULFILLED,
  FETCH_TRACKED_ITEM_UNITS_FULFILLED,
  RESET_EVALUATION,
  SET_EDIT_EVAL_MODAL1,
  SET_EVAL_GROUP_OPTION,
  SET_EVAL_GROUP_TYPE_OPTION,
  SET_SELECTED_EVAL_DATE,
  PRIME_DELETE_EVAL_ID,
  FETCH_EVAL_FROM_EDIT_FULFILLED,
  SET_EDITED_ITEMS,
  EDIT_EVALUATION_FULFILLED,
} from './actionTypes';

const initialState = {
  evaluations: [],
  evaluation: {},
  editEvalModal1Object: {},
  evalGroupTypeOption: 'All Athletes',
  evalGroupOption: {},
  trackedItemUnits: [],
  primedDeleteEvalInfo: {},
  latestTimeStamp: 0,
  editedItems: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVALUATIONS_FULFILLED: {
      return update(
        state, {
          evaluations: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_EVALUATIONS_REJECTED: {
      return {
        ...state,
        evaluationsError: action.payload,
      };
    }
    case FETCH_EVALUATION_FULFILLED: {
      return update(
        state, {
          evaluation: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_EVAL_FROM_EDIT_FULFILLED: {
      return update(
        state, {
          evaluation: {
            $set: action.payload,
          },
          editEvalModal1Object: {
            $set: {},
          },
        },
      );
    }
    case RESET_EVALUATION: {
      return update(
        state, {
          evaluation: {
            $set: {},
          },
          evalGroupOption: {
            $set: {},
          },
          evalGroupTypeOption: {
            $set: 'All Athletes',
          },
          timestamps: {
            $set: 0,
          },
        },
      );
    }
    case CREATE_CELL_VALUE_FULFILLED: {
      let updateValue;
      if (action.payload.dataIdx === 0) {
        updateValue = action.payload.data.result1;
      } else if (action.payload.dataIdx === 1) {
        updateValue = action.payload.data.result2;
      } else if (action.payload.dataIdx === 2) {
        updateValue = action.payload.data.result3;
      } else if (action.payload.dataIdx === 3) {
        updateValue = action.payload.data.result4;
      }
      return update(
        state, {
          evaluation: {
            data: {
              [action.payload.athleteIdx]: {
                itemData: {
                  [action.payload.inputIdx]: {
                    [action.payload.data.itemId]: {
                      [action.payload.dataIdx]: {
                        $set: {
                          evalId: action.payload.data.evalId,
                          id: action.payload.data.id,
                          itemId: action.payload.data.itemId,
                          resultNumber: action.payload.data.resultNumber,
                          userId: action.payload.data.userId,
                          value: updateValue,
                        },

                      },
                    },
                  },

                },
              },
            },
          },
          latestTimeStamp: {
            $set: action.payload.data.timestamp,
          }
          ,
        },
      );
    }
    case FETCH_EVALUATION_REJECTED: {
      return {
        ...state,
        evaluationError: action.payload,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return update(
        state, {
          groups: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        evaluationError: action.payload,
      };
    }
    case FETCH_CALENDARS_FULFILLED: {
      return update(
        state, {
          calendars: {
            $set: action.payload,
          },
        },
      );
    }
    case FETCH_CALENDARS_REJECTED: {
      return {
        ...state,
        evaluationError: action.payload,
      };
    }
    case SET_SELECTED_EVAL_DATE: {
      return {
        ...state,
        selectedEvalDate: action.payload,
      };
    }
    case DELETE_EVALUATION_FULFILLED: {
      return update(
        state, {
          evaluations: {
            $splice: [[action.deleteIndex, 1]],
          },
          primedDeleteEvalInfo: {
            $set: {},
          },
        },
      );
    }
    case SET_EDIT_EVAL_MODAL1: {
      return {
        ...state,
        editEvalModal1Object: action.payload,
      };
    }
    case SET_EVAL_GROUP_TYPE_OPTION: {
      return {
        ...state,
        evalGroupTypeOption: action.payload,
      };
    }
    case SET_EVAL_GROUP_OPTION: {
      return {
        ...state,
        evalGroupOption: action.payload,
      };
    }
    case FETCH_TRACKED_ITEM_UNITS_FULFILLED: {
      return {
        ...state,
        trackedItemUnits: action.payload,
      };
    }
    case FETCH_TRACKED_EXERCISES_FULFILLED: {
      return {
        ...state,
        trackedExercises: action.payload,
      };
    }
    case PRIME_DELETE_EVAL_ID: {
      return {
        ...state,
        primedDeleteEvalInfo: action.payload,
      };
    }
    case SET_EDITED_ITEMS: {
      return {
        ...state,
        editedItems: [...state.editedItems, action.payload],
      };
    }
    case EDIT_EVALUATION_FULFILLED: {
      return update(
        state, {
          evaluations: {
            [action.payload.idx]: {
              $set: action.payload.eval,
            },
          },
        },
      );
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
