import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import {
  setConnected, setSocketAuthenticated, setReconnectAttempts,
} from '../../shared/components/SocketTester/ducks/socketActions';
import { submitRefreshToken } from '../login/ducks/loginActions';
import { setIncomingMessage, setIsTyping } from './ducks/conversationsActions';
import socket from '../../shared/utils/setSocketConfig';

const SocketHandler = () => {
  const isConnected = useSelector((state) => state.socketTester.ui.isConnected);
  const reconnectAttempts = useSelector((state) => state.socketTester.ui.reconnectAttempts);
  const wasConnected = useSelector((state) => state.socketTester.ui.wasConnected);

  const maxReconnectAttempts = 3;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isConnected && reconnectAttempts < maxReconnectAttempts) {
      // Only count this as a reconnect attempt if the socket has previously been connected.
      // Otherwise, this is the initial connection being made.
      if (wasConnected) {
        dispatch(setReconnectAttempts(reconnectAttempts + 1));
      }
      // Define event handler for connection event
      socket.on('connect', () => {
        dispatch(setConnected(true));
      });

      const currentDate = new Date().getTime();
      const isTokenExpired = Cookies.get('tokenExpiration') ? currentDate > Cookies.get('tokenExpiration') : true;

      // Only attempt to reconnect the sockets if we have a valid access token that isn't expired
      if (Cookies.get('accessToken') && !isTokenExpired) {
        socket.connect();
        socket.emit('authentication', { token: Cookies.get('accessToken') });
      } else {
        // Either the cookie doesn't exist or it is expired
        // need to re-fetch and then try to connect the sockets again
        dispatch(submitRefreshToken()).then((token) => {
          socket.connect();
          socket.emit('authentication', { token });
        });
      }
    }
    return () => {
      // socket.disconnect();
    };
  }, [isConnected]);

  /*
  Define all socket message handlers in a useEffect that gets run only once,
  in order to prevent duplicate messages
  */
  useEffect(() => {
    socket.on('message', (data) => {
      dispatch(setIncomingMessage(data));
    });

    socket.on('authenticated', () => {
      dispatch(setSocketAuthenticated(true));
    });

    socket.on('isTyping', (data) => {
      dispatch(setIsTyping(data));
    });

    socket.on('disconnect', () => {
      dispatch(setConnected(false));
      dispatch(setSocketAuthenticated(false));
    });
  }, []);

  return (<></>);
};

export default SocketHandler;
