import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import EvaluationReportGroupAnalysisTable from './EvaluationReportGroupAnalysisTable';
import EvaluationReportOverviewTable from './EvaluationReportOverviewTable';

const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  padding-right: 108px;

  @media screen and (max-width: 767px) {
    padding-right: 0px;
  }
`;

const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;

const EvaluationReportData = () => {
  const evaluationReportGAData = useSelector(
    (state) => state.reporting.data.evaluationReportGA.results,
  );
  const evaluationReportOverviewData = useSelector(
    (state) => state.reporting.data.evaluationReportOverview.results,
  );

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);

  return (
    <ReportContainer>
      <TableContainer>
        <EvaluationReportOverviewTable
          containerWidth={1000}
          dataRows={evaluationReportOverviewData}
          rowIsScrollable
          setColumnHeaderHeight={setColumnHeaderHeight}
        />
      </TableContainer>
      <TableContainer>
        <EvaluationReportGroupAnalysisTable
          containerWidth={1000}
          dataRows={evaluationReportGAData}
          rowIsScrollable
          setColumnHeaderHeight={setColumnHeaderHeight}
        />
      </TableContainer>
    </ReportContainer>
  );
};

export default EvaluationReportData;
