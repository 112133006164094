/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSortBy, useTable, usePagination } from 'react-table';
import { useSticky } from 'react-table-sticky';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { setIsMetricFiltersGroupShowing } from '../../ducks/forcePlateDashboardActions';
import { dashboardTracker } from '../../../../shared/utils/amplitudeHelper';

const Styles = styled.div`
  background: #FFFFFF;
  font-family: nunito sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  align-items: flex-start;

  .paginationContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .paginationButton {
    font-family: 'Nunito Sans';
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    border: none;
  }

  & .sticky {
    overflow-x: auto;
    margin: 25px 0px;
    width: 100%;

    tfoot {
      td:first-child {
         position: sticky;
         left: 0;
      }
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  .athleteColumnHeaderCell {
    border-bottom: none;
  }

  .noHoverCategoryColumnTitleCell {
    background-color: #BDBDBD1A;
    color: #444444;
    font-size: 16px;
    font-weight: bold;
    border-right: 1px solid #BDBDBD1A;
    min-width: 270px;
    max-width: 270px;
  }

  .rawValueTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: normal;
    background-color: #BDBDBD1A;
    color: #444444;
    text-align: left;
    min-width: 115px;
    max-width: 115px;
    cursor: pointer;
  }

  .athleteNameTitle {
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: #444444;
    min-width: 180px;
    max-width: 180px;
    position: relative;
  }
  // Fixes sticky athlete title column not hidding content from other columns
  .athleteNameTitle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #BDBDBD1A;
    border-top-left-radius: 8px;
    border-right: 1px solid #E0E0E0;
  }

  .footerCells {
    font-size: 16px;
    font-family: 'Nunito Sans';
    font-weight: normal;
    text-align: left;
    color: #444444;
  }

  td:has(div):has(.deviation-alert-red) {
    background-color: #FFCDCD;
  }

  td:has(div):has(.deviation-alert-green) {
    background-color: #CFF5E8;
  }

  table {
    border-spacing: 0;
    border-collapse: separate;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      background: white;
      padding: 15px;
      border-bottom: 1px solid #E0E0E0;
      border-right: 1px solid #BDBDBD1A;
      text-align: center;
    }

    tfoot {
      tr:first-child {
        td {
         border-top: 1px solid #E0E0E0;
         height: 61px;
         min-height: 61px;
         border-bottom: none;
         background: #FCFCFC;
         border-bottom-left-radius: 8px;
        }
      }
      td:first-child {
         color: #444444;
         font-size: 16px;
         font-weight: normal;
         text-align: left;
         font-family: 'Nunito Sans';
      }
      tr:last-child {
        display: none;
      }
    }
  }
`;

const Table = ({
  containerWidth,
  columns,
  data,
  getCellProps,
  getHeaderProps,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 5 },
  },
  useSticky,
  useSortBy,
  usePagination);

  const dispatch = useDispatch();

  const handleMetricClick = () => {
    // Amplitude tracking
    dashboardTracker('Force Plate Dashboard', 'Group Analysis - Filter', '+Add metric clicked');
    dispatch(setIsMetricFiltersGroupShowing(true));
  };

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 0; i < pageCount; i++) {
      buttons.push(
        <button
          className='paginationButton'
          type='button'
          key={i}
          onClick={() => gotoPage(i)}
          style={{ color: pageIndex === i ? '#FF6600' : '#616161' }}
        >
          {i + 1}
        </button>,
      );
    }
    return buttons;
  };

  return (
    <>
      <div id='reportTable' style={{ display: 'flex', zIndex: 0 }} className='sticky'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} data-a-wrap>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        className: column.className,
                      },
                      getHeaderProps(column),
                    ])}
                    data-cols-width='30'
                    data-a-wrap
                    data-f-bold
                    data-fill-color={column?.style?.fillColor || 'FF424242'}
                    data-b-a-s='thin'
                    data-b-a-c='FF000000'
                    data-f-color={column?.style?.fillColor ? 'FF000000;' : 'FFFFFFFF'}
                    data-a-h='center'
                    data-a-v='center'
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='down-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                          : (
                            <IcomoonReact
                              iconSet={iconSet}
                              size={10}
                              color='black'
                              icon='up-arrow'
                              style={{ marginLeft: '5px' }}
                            />
                          )
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps([
                        {
                          style: cell.column.style,
                        },
                        getCellProps(cell),

                      ])}
                      data-fill-color={getCellProps(cell)?.style?.fillColor || null}
                      data-f-color={getCellProps(cell)?.style?.fontColor}
                      data-b-a-s='thin'
                      data-b-a-c='FF000000'
                      data-a-wrap
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => <td data-a-wrap {...column.getFooterProps([{ className: 'footerCells' }])}>{column.render('Footer')}</td>)}
              </tr>
            ))}
          </tfoot>
        </table>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            background: '#5F8BDC1A',
            color: '#5F8BDC',
            border: '1px dashed #5F8BDC',
            borderRadius: '5px',
            width: '250px',
            minWidth: '150px',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'NunitoSans',
            fontSize: '16px',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}
          onClick={handleMetricClick}
        >
          <span>+Add Metric</span>
        </div>
      </div>
      <>
        {pageCount > 1 ? (
          <div className='paginationContainer'>
            <div className='pagination'>
              <button className='paginationButton' type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
                {'<'}
              </button>
              {' '}
              {renderPageButtons()}
              {' '}
              <button className='paginationButton' type='button' onClick={() => nextPage()} disabled={!canNextPage}>
                {'>'}
              </button>
            </div>
          </div>
        ) : (null)}
      </>
    </>
  );
};

const GroupAnalysisTable = ({
  containerWidth,
  dataRows,
  groupAverageData,
  setColumnHeaderHeight,
  rowIsScrollable,
}) => {
  const endDateData = useSelector((state) => state.forcePlateDashboard.data.endDateData);
  const formattedDate = moment(endDateData).format('MMM DD');

  const chartRef = useRef(null);
  const [headerAndSubHeaderHeight, setHeaderAndSubHeaderHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const handleScroll = (e) => {
    /** targetting the sticky container div scroll properties */
    const scrollWidth = chartRef.current?.childNodes[0]?.scrollWidth;
    const offsetWidth = chartRef.current?.childNodes[0]?.offsetWidth;
    const scrollLeft = chartRef.current?.childNodes[0]?.scrollLeft;
    setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
  };

  React.useEffect(() => {
    if (chartRef.current) {
      const scrollWidth = chartRef.current.childNodes[0]?.scrollWidth;
      const offsetWidth = chartRef.current.childNodes[0]?.offsetWidth;
      const scrollLeft = chartRef.current.childNodes[0]?.scrollLeft;
      setIsScrollable(offsetWidth + scrollLeft !== scrollWidth);
    }
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      setHeaderAndSubHeaderHeight(chartRef.current.childNodes[0].childNodes[0].childNodes[0].clientHeight);
      setColumnHeaderHeight(chartRef.current.childNodes[0].childNodes[0].childNodes[0].childNodes[0].clientHeight);
    }
  }, []);

  const athleteNameColumn = [
    {
      Header: '',
      Footer: () => null,
      id: 'name',
      sticky: 'left',
      className: 'athleteColumnHeaderCell',
      columns: [
        {
          Header: 'Athlete',
          accessor: 'fullName',
          Footer: 'Group Average',
          className: 'athleteNameTitle',
          style: {
            /** style for the whole column
             * className is style for just the header
            */
            borderRight: '1px solid #E0E0E0',
            height: '58px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
          Cell: ({ row }) => (
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  height: '35px',
                  marginRight: '5px',
                  overflow: 'hidden',
                  width: '35px',
                }}
              >
                <img
                  alt={`${row.original.fullName} icon`}
                  src={row.original.pic}
                  style={{ height: '100%' }}
                />
              </div>
              <div>
                {row.original.fullName}
              </div>
            </div>
          ),
        },
      ],
    },
  ];

  const formatColumns = () => {
    const formattedData = dataRows[0].testResults.map((metric, i) => ({
      Header: metric.units !== '' ? `${metric.metricName} (${metric.units})` : `${metric.metricName}`,
      Footer: () => null,
      id: metric.metricName,
      className: 'noHoverCategoryColumnTitleCell',
      columns: [
        {
          Header: `${formattedDate}`,
          accessor: (row) => row.testResults[i].value || row.testResults[i].dataForSelectedDate?.value,
          Cell: ({ row }) => {
            if (row.original.testResults[i].percentChange !== undefined) {
              if (row.original.testResults[i].percentChange < 0) {
                return (
                  <div className='deviation-alert-red'>
                    {row.original.testResults[i].value}
                  </div>
                );
              }
              return (
                <div>
                  {row.original.testResults[i].value}
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.alert || row.original.testResults[i].dataForSelectedDate?.deviations <= -1) {
              return (
                <div className='deviation-alert-red'>
                  {row.original.testResults[i].dataForSelectedDate?.value}
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.deviations >= 1) {
              return (
                <div className='deviation-alert-green'>
                  {row.original.testResults[i].dataForSelectedDate?.value}
                </div>
              );
            }
            return (
              <div>
                {row.original.testResults[i].dataForSelectedDate?.value || '-'}
              </div>
            );
          },
          Footer: () => {
            const total = groupAverageData.map((obj) => obj.value);
            if (total[i]) {
              return (
                <>
                  {total[i]}
                </>
              );
            }
            return (
              <>
                -
              </>
            );
          },
          id: `${metric.metricName} endDate`,
          className: 'rawValueTitle',
          style: {
            minWidth: '90px',
            maxWidth: '90px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
        },
        {
          Header: 'Avg',
          accessor: (row) => row.testResults[i].averageValue,
          Cell: ({ row }) => {
            if (row.original.testResults[i].percentChange !== undefined) {
              if (row.original.testResults[i].percentChange < 0) {
                return (
                  <div className='deviation-alert-red'>
                    {row.original.testResults[i].averageValue}
                  </div>
                );
              }
              return (
                <div>
                  {row.original.testResults[i].averageValue}
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.alert || row.original.testResults[i].dataForSelectedDate?.deviations <= -1) {
              return (
                <div className='deviation-alert-red'>
                  {row.original.testResults[i].averageValue}
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.deviations >= 1) {
              return (
                <div className='deviation-alert-green'>
                  {row.original.testResults[i].averageValue}
                </div>
              );
            }
            return (
              <div>
                {row.original.testResults[i].averageValue || '-'}
              </div>
            );
          },
          Footer: () => {
            const total = groupAverageData.map((obj) => obj.averageValue);
            if (total[i]) {
              return (
                <>
                  {total[i]}
                </>
              );
            }
            return (
              <>
                -
              </>
            );
          },
          id: `${metric.metricName} average`,
          className: 'rawValueTitle',
          style: {
            minWidth: '90px',
            maxWidth: '90px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
        },
        {
          Header: metric.percentChange !== undefined ? 'Percentage' : metric.units === '' ? '' : 'Deviation',
          accessor: (row) => row.testResults[i].percentChange || row.testResults[i].dataForSelectedDate?.deviations,
          Cell: ({ row }) => {
            if (row.original.testResults[i].percentChange !== undefined) {
              if (row.original.testResults[i].percentChange < 0) {
                return (
                  <div className='deviation-alert-red'>
                    {row.original.testResults[i].percentChange}
                  </div>
                );
              }
              return (
                <div>
                  {row.original.testResults[i].percentChange}
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.alert) {
              return (
                <div className='deviation-alert-red' style={{ display: 'flex', justifyContent: 'center' }}>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={20}
                    icon='warning'
                    color={row.original.testResults[i].dataForSelectedDate?.alertColor}
                  />
                </div>
              );
            }
            if (row.original.testResults[i].dataForSelectedDate?.deviations >= 1) {
              return (
                <div className='deviation-alert-green'>
                  {row.original.testResults[i].dataForSelectedDate?.deviations}
                </div>
              );
            } if (row.original.testResults[i].dataForSelectedDate?.deviations <= -1) {
              return (
                <div className='deviation-alert-red'>
                  {row.original.testResults[i].dataForSelectedDate?.deviations}
                </div>
              );
            }
            return (
              <div>
                {row.original.testResults[i].dataForSelectedDate?.deviations || '-'}
              </div>
            );
          },
          Footer: () => {
            const total = groupAverageData.map((obj) => obj.deviations);
            if (total[i]) {
              return (
                <>
                  {total[i]}
                </>
              );
            }
            return (
              <>
                -
              </>
            );
          },
          id: `${metric.metricName} deviation`,
          className: 'rawValueTitle',
          style: {
            minWidth: '90px',
            maxWidth: '90px',
            textAlign: 'left',
            fontFamily: 'Nunito Sans',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#444444',
          },
        },
      ],
    }));
    return formattedData;
  };

  const formattedMiddleColumns = formatColumns();

  const columns = [...athleteNameColumn, ...formattedMiddleColumns];

  const memoizedColumns = React.useMemo(
    () => columns,
    [dataRows, containerWidth],
  );
  const memoizedDataRows = React.useMemo(
    () => dataRows,
    [dataRows, containerWidth],
  );

  const handleHeaderClick = (headerInfo) => {
    if (headerInfo.Header !== '') {
      headerInfo.toggleSortBy();
    }
  };

  return (
    <>
      <Styles ref={chartRef} onScroll={handleScroll} isScrollable={isScrollable} rowIsScrollable={rowIsScrollable} headerAndSubHeaderHeight={headerAndSubHeaderHeight} containerWidth={containerWidth}>
        <Table
          containerWidth={containerWidth}
          columns={memoizedColumns}
          data={memoizedDataRows}
          getCellProps={(cellInfo) => ({
            style: {
              backgroundColor: 'FFFFFFFF',
              color: 'FF444444',
              fillColor: 'FFFFFFFF',
              fontColor: 'FF444444',
            },
          })}
          getHeaderProps={(headerInfo) => ({
            onClick: () => handleHeaderClick(headerInfo),
          })}
        />
      </Styles>
    </>
  );
};

export default GroupAnalysisTable;
