import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Text from '../../../shared/components/Text/Text';
import Button from '../../../shared/components/Button/Button';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import { sharedWorkoutModal } from '../ducks/sharedWorkoutActions';

const ModalContainer = styled('div')`
  position: absolute;
  z-index: 1001;
  width: 350px;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  animation: animatebottom 0.2s;
  flex-direction: column;
  justify-content: space-between;
  @keyframes animatebottom {
    from {
      opacity: 0;
      transform: translate(-50%, 100%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  overflow: auto;
  left: 50%;
  top: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
`;

const DownloadWrapper = styled('div')`
  position: relative;
  height: auto;
  width: 100%;
  border-radius: 6px;
  background: #ffffff;
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

const DownloadContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;  
  padding: 30px;
`;

const SelectContainer = styled('div')`
  margin: 20px 0;
  select:focus{
    outline: none;
  }
`;

const ButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  .button-download {
    width: 100%;
    background: #10cd8c;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 3px;
  }
`;

const ModalDownload = () => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(sharedWorkoutModal(''));
  };

  return (
    <ModalContainer>
      <DownloadWrapper>
        <IcomoonReact className='icon' iconSet={iconSet} size={15} icon='remove' onClick={closeModal} />
        <DownloadContainer>
          <Text>
            Download Exercises To
          </Text>
          <SelectContainer>
            <select style={{ borderColor: '#10cd8c', cursor: 'pointer' }}>
              <option value='Imported Exercises (Recommended)' style={{ fontFamily: 'Nunito Sans', fontSize: 14 }}>
                Imported Exercises (Recommended)
              </option>
              <option value='Master Database' style={{ fontFamily: 'Nunito Sans', fontSize: 14 }}>
                Master Database
              </option>
            </select>
          </SelectContainer>
          <ButtonContainer>
            <Button
              className='button-download'
              complete
              cta='Start Download'
            />
          </ButtonContainer>
        </DownloadContainer>
      </DownloadWrapper>
    </ModalContainer>
  );
};

export default ModalDownload;
