import React, { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';

import LiftEntries from '../container/WeightroomWorkoutEntriesLifts';
import SaqEntries from './WorkoutEntriesSAQ';
import DynamicSaqEntries from './WorkoutEntriesDynamicSAQ';
import CircuitEntries from './WorkoutEntriesCircuits';
import HealthEntries from './WorkoutEntriesHealth';

import { ColorPrimary } from '../../../../shared/GlobalStyles';

const WorkoutEntries = ({
  athleteId,
  activeDate,
  entriesContainerRef,
  entriesViewHeight,
  entriesViewRef,
  entryRowRef,
  formikRef,
  index,
  isWeightValueEnabled,
  handleInfoClick,
  namespace,
  supersetIndex,
  updateWorkout,
  workout,
  workoutsBySuperset,
  wrvSupersetsEnabled,
}) => {
  const isLiftEntries = workout.exer_type === 'lift';

  const isTagEntries = workout.exer_type === 'tag' && workout.result;

  const isSAQEntries = workout.exer_type === 'cardio';

  const isCircuitEntries = workout.exer_type === 'circuit';

  const supersetWorkout = (wrvSupersetsEnabled && workoutsBySuperset && workout.grouping !== null)
    ? workoutsBySuperset[workout.grouping][supersetIndex]
    : {};

  return (
    <div
      className='entry_reps'
      ref={entriesContainerRef}
      // isScrolling={workout.sets >= 4}
    >
      <div
        className='entries_view_wrapper'
        ref={entriesViewRef}
      >
        { ((isLiftEntries) || (isTagEntries)) && (
          <LiftEntries
            athleteId={athleteId}
            entriesViewHeight={entriesViewHeight}
            entriesViewRef={entriesViewRef}
            entryRowRef={entryRowRef}
            formikRef={formikRef}
            index={index}
            namespace={namespace}
            supersetIndex={supersetIndex}
            supersetWorkout={supersetWorkout}
            updateWorkout={updateWorkout}
            workout={workout}
            wrvSupersetsEnabled={wrvSupersetsEnabled}
          />
        )}

        { isSAQEntries && (
          <DynamicSaqEntries
            athleteId={athleteId}
            activeDate={activeDate}
            entriesViewHeight={entriesViewHeight}
            entriesViewRef={entriesViewRef}
            formikRef={formikRef}
            index={index}
            isWeightValueEnabled={isWeightValueEnabled}
            handleInfoClick={handleInfoClick}
            namespace={namespace}
            supersetIndex={supersetIndex}
            supersetWorkout={supersetWorkout}
            updateWorkout={updateWorkout}
            workout={workout}
            wrvSupersetsEnabled={wrvSupersetsEnabled}
          />
        )}

        { isCircuitEntries && (
          <CircuitEntries
            athleteId={athleteId}
            entriesViewHeight={entriesViewHeight}
            entriesViewRef={entriesViewRef}
            formikRef={formikRef}
            index={index}
            handleInfoClick={handleInfoClick}
            namespace={namespace}
            supersetIndex={supersetIndex}
            supersetWorkout={supersetWorkout}
            updateWorkout={updateWorkout}
            workout={workout}
            workoutsBySuperset={workoutsBySuperset}
            wrvSupersetsEnabled={wrvSupersetsEnabled}
          />
        )}
      </div>
    </div>
  );
};

WorkoutEntries.propTypes = {
  athleteId: PropTypes.number.isRequired,
  entriesContainerRef: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  entriesViewRef: PropTypes.instanceOf(Object).isRequired,
  entryRowRef: PropTypes.instanceOf(Object).isRequired,
  formikRef: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  handleInfoClick: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  supersetIndex: PropTypes.number,
  updateWorkout: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  workoutsBySuperset: PropTypes.instanceOf(Object).isRequired,
  wrvSupersetsEnabled: PropTypes.number.isRequired,
};

WorkoutEntries.defaultProps = {
  supersetIndex: 0,
};

export default memo(WorkoutEntries);
