/* eslint-disable react/jsx-filename-extension */
import styled from '@emotion/styled';

const NotificationInnerContent = styled('div')`
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export default NotificationInnerContent;
