import React from 'react';

import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import ActiveReportSidebar from '../ActiveReportSidebar';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import ActivityReportData from './ActivityReportData';
import ReportingModal from '../ReportingModal';
import ActivityReportDataLoading from './ActivityReportDataLoading';
import { BoxShadow } from '../../../../shared/GlobalStyles';

const MainContainer = styled('main')`
  flex: ${(props) => (props.isReportOpen ? 50 : 10)};
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  height: 100%;
  overflow-y: scroll;
  
  @media screen and (max-width: 540px) {
    width: 100%;
    padding: 0px 15px;
    margin: 0px;
  }
`;

const HeaderRow = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 40px;
  margin-left: 30px;

  h1 {
    text-transform: capitalize;
  }

  @media screen and (max-height: 920px) {
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;

const BodyRow = styled('div')`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 0;
  flex-basis: 100%;
`;

const MobileActiveMenu = styled('div')`
  position: fixed;
  width: 100px;
  height: 45px;
  bottom: 30px;
  left: 50%;
  margin-left: -50px;
  background-color: white;
  border-radius: 30px;
  display: none;
  box-shadow: ${BoxShadow};
  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const SubText = styled('div')`
  margin-top: 8px;
  font-size: 14px;
`;

const ActivityReport = () => {
  const isReportOpen = useSelector((state) => state.reporting.ui.isReportOpen);
  const activeModal = useSelector((state) => state.reporting.ui.activeModal);
  const isActivityReportLoading = useSelector(
    (state) => state.reporting.ui.isActivityReportLoading,
  );

  return (
    <MainContainer
      isReportOpen={isReportOpen || activeModal === 'downloadActivityReport'}
    >
      <ReportingModal />
      {isReportOpen && (
      <>
        <HeaderRow>
          <HeaderText
            fontWeight={300}
            letterSpacing='normal'
          >
            Activity Report
          </HeaderText>
          <SubText>Report includes results within past 45 days</SubText>
        </HeaderRow>
        <BodyRow>
          {isActivityReportLoading ? (
            <ActivityReportDataLoading />
          ) : (<ActivityReportData />
          )}
        </BodyRow>
        <MobileActiveMenu>
          <ActiveReportSidebar download />
        </MobileActiveMenu>
      </>
      )}
    </MainContainer>
  );
};

export default ActivityReport;
