/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import ResizeObserver from 'react-resize-observer';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';
import TotalWeekBreakdownOverviewGrid from './totalWeekBreakdownOverviewGrid';
import TotalWeekBreakdownBarChartsGrid from './totalWeekBreakdownBarChartsGrid';

const TotalWeekBreakdownPage = () => {
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(0);
  const athlete = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview.user);

  const PageWrapper = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
  `;
  const HeaderContainer = styled('div')`
    display: flex;
    width: 100%;
    max-width: 1400px;
    flex-wrap: wrap;
  `;
  const GridWrapper = styled('div')`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: ${containerWidth > 767 ? '#FDFBFB' : 'white'};
    align-items: center;
    flex-direction: column;
  `;
  const KeyContainerRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: ${containerWidth > 767 ? '20px' : '0px'};
  background-color: white;
  border-bottom: ${containerWidth > 767 && '1px solid #F2F2F2'};
  flex-wrap: wrap;
  border-top: 1px solid #F2F2F2;
  `;

  const SquareAndTextContainer = styled('div')`
    display: flex;
    gap: 20px;
    min-width: 200px;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  `;

  const Square = styled('div')`
    height: 20px;
    width: 20px;
    background: ${(props) => props.color};
  `;

  const IconAndTextContainer = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 25px;
    text-align: center;
    min-width: ${(props) => (props.containerWidth && props.containerWidth <= 400 ? '250px' : '400px')};
  `;
  const BreakDownContainer = styled('div')`
    display: flex;
    flex: 1;
    background-color: #FAFAFA;
    justify-content: center;
    align-items: center;
    min-width: ${(props) => (props.containerWidth && props.containerWidth <= 650 ? '250px' : '650px')};
    padding-left: 25px;
    padding-right: 25px;
  `;

  const IconContainer = styled('div')`
    height: 54px;
    width: 54px;
    background-color: #F3F3F3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

  return (
    <PageWrapper>
      <HeaderContainer>
        <ResizeObserver
          onResize={(rect) => {
            setContainerWidth(rect?.width);
          }}
        />
        <IconAndTextContainer containerWidth={containerWidth}>
          <IconContainer>
            <IcomoonReact
              iconSet={iconSet}
              size={34}
              icon='chart'
              color={theme.colors.orange}
            />
          </IconContainer>
          <Text fontSize={containerWidth < 540 ? '20px' : '28px'} fontWeight={800}>
            {`${athlete?.first}'s Training Breakdown`}
            {' '}
          </Text>
          <Text fontSize='15px' fontWeight={600} color={theme.colors.text}>
            {`Here is an overall breakdown of ${athlete?.first}'s total week. The graphs below show this week's totals compared to the previous week's totals.`}
          </Text>
        </IconAndTextContainer>
        <BreakDownContainer containerWidth={containerWidth}>
          <TotalWeekBreakdownOverviewGrid containerWidth={containerWidth} />
        </BreakDownContainer>
      </HeaderContainer>
      <GridWrapper>
        <KeyContainerRow>
          <SquareAndTextContainer>
            <Square color='linear-gradient(90deg, #667EEA 0%, #764BA2 100%)' />
            <Text fontSize='13px' fontWeight={700}>Total From Current Week</Text>
          </SquareAndTextContainer>
          <SquareAndTextContainer>
            <Square color='#A4A2A2' />
            <Text fontSize='13px' fontWeight={700}>Total From Previous Week</Text>
          </SquareAndTextContainer>
        </KeyContainerRow>
        <TotalWeekBreakdownBarChartsGrid />
      </GridWrapper>
    </PageWrapper>
  );
};
export default TotalWeekBreakdownPage;
