import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import documentsUiReducer from './documentsUiReducer';
import documentsDataReducer from './documentsDataReducer';

// const authDataPersistConfig = {
//   key: 'data',
//   storage,
//   stateReconciler: hardSet,
// };

export default combineReducers({
  ui: documentsUiReducer,
  data: documentsDataReducer,
});
