import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';

const IntegrationsSettingsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const Container = styled('div')`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `;

  return (

    <Container onClick={() => { dispatch(push('/settings/account')); }}>
      <p>integrations settings page</p>
    </Container>

  );
};

export default IntegrationsSettingsPage;
