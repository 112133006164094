/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import { setIsProgramInformationModalShowing } from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1004;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 225px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
    position: absolute;
  }
  .print-button {
    background: #10cd8c;
  }
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  background: white;
  overflow-y: auto;
`;

const ProgramInfo = styled('div')`
	display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ProgramTitle = styled('div')`
	font-size: 18px;
  font-family: 'Nunito Sans';
  font-weight: 800;
  margin-top: 10px;
`;

const ProgramDescription = styled('div')`
	font-size: 16px;
  font-family: 'Nunito Sans';
  margin-top: 10px;
`;

const ProgramLength = styled('div')`
	font-size: 12px;
  font-family: 'Nunito Sans';
  font-weight: 400;
`;

const ProgramBackground = styled('div')`
	display: flex;
  width: 100%;
  height: 300px;
  background-image: ${(props) => `url(${props.backgroundImage})}`};
  background-repeat: no-repeat;
  background-size: cover;
`;

const ProgramInformationModal = () => {
  const dispatch = useDispatch();

  const isProgramInformationModalShowing = useSelector(
    (state) => state.workouts.ui.isProgramInformationModalShowing,
  );
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );

  return (
    <ModalContainer
      isActive={isProgramInformationModalShowing}
    >
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        />
        <div className='remove-div-icon' onClick={() => dispatch(setIsProgramInformationModalShowing(false))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <ProgramBackground backgroundImage={currentSelectedProgram?.media?.mediaUrl || ''} />
        <ProgramInfo>
          <ProgramLength>
            {currentSelectedProgram?.numberOfWeeks}
            {' '}
            {currentSelectedProgram.numberOfWeeks === 1 ? 'Week' : 'Weeks'}
          </ProgramLength>
          <ProgramTitle>
            {currentSelectedProgram.programName}
          </ProgramTitle>
          <ProgramDescription>
            {currentSelectedProgram.description}
          </ProgramDescription>
        </ProgramInfo>
      </ContentContainer>
    </ModalContainer>
  );
};

export default ProgramInformationModal;
