import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { matchSorter } from 'match-sorter';

import PanelAthletes from '../presentational/PanelAthletes';
import {
  closePinPad,
  openPinPad,
  selectAthlete,
} from '../../ducks/panelActions';
// import users from '../../../stories/TestUserList.json';

const getData = state => state.weightRoom.users.result || [];
const getSearchFilter = (state, props) => state[props.namespace].athleteSearch;

const getVisibleUsers = createSelector(
  [getData, getSearchFilter],
  (data, searchFilter) => {
    const filterSearch = matchSorter(data, searchFilter, { keys: ['first', 'last'] });
    return filterSearch;
  },
);

const mapStateToProps = (state, ownProps) => ({
  activeScreens: state.weightRoom.activeScreens,
  data: getVisibleUsers(state, ownProps),
  isPinPadOpen: state[ownProps.namespace].isPinPadOpen,
  isDimensionSet: state[ownProps.namespace].isDimensionSet,
  panelDimensions: state[ownProps.namespace].panelDimensions,
  userGroups: state.weightRoom.userGroups,
  groupFilter: state.weightRoom.groupFilter,
});

const mapDispatchToProps = dispatch => ({
  closePinPad: (namespace) => {
    dispatch(closePinPad(namespace));
  },
  openPinPad: (name, id, avatar, namespace) => {
    dispatch(openPinPad(name, id, avatar, namespace));
  },
  selectAthlete: (name, id, avatar, namespace) => {
    dispatch(selectAthlete(name, id, avatar, namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelAthletes);
