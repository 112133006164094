/* eslint-disable no-nested-ternary */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const MaxTempoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 25%;
  color: ${(props) => props.theme.lightTextColor2};
`;

const CoachCopy = styled('p')`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 75%;
  color: ${(props) => props.theme.lightTextColor2};
  white-space: pre-wrap;
`;

const CoachNotes = ({
  info,
  isTrackingActive,
  label,
  max,
  tempo,
  restTime,
  optedOut,
  optedOutReason,
  substitutedExercise,
}) => (
  <div className='coach_notes'>
    {(isTrackingActive || restTime > 0 || tempo !== '') ? (
      <MaxTempoContainer>
        {isTrackingActive && (
          <span>
            {`${label}: ${max === 0 || max === null ? 'N/A' : max}`}
          </span>
        )}
        { tempo && (
          <span>
            {`Tempo: ${tempo}`}
          </span>
        )}
        { restTime ? (
          <span>
            {`Rest: ${restTime}s`}
          </span>
        ) : ('')}
      </MaxTempoContainer>
    ) : ('')}
    <CoachCopy>
      {(info === null) ? '' : (info.length <= 255 ? info : `${info.substr(0, 255)} ...`)}
      { optedOut && (
      <>
        <span style={{ color: '#FF0000' }}>
          {`Opted Out: ${optedOutReason}`}
        </span>
        <span style={{ color: '#FF0000' }}>
          {substitutedExercise && `Substituted From: ${substitutedExercise.name}`}
        </span>
      </>
      )}
    </CoachCopy>
  </div>
);

CoachNotes.propTypes = {
  info: PropTypes.string.isRequired,
  isTrackingActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  tempo: PropTypes.string.isRequired,
  restTime: PropTypes.number.isRequired,
  optedOut: PropTypes.bool.isRequired,
  optedOutReason: PropTypes.string.isRequired,
  substitutedExercise: PropTypes.instanceOf(Object).isRequired,
};

export default memo(CoachNotes);
