/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  fetchNextWorkoutProgramWeek,
  fetchNextWorkoutProgramWorkouts,
  skipWorkout,
  setIsSkipWorkoutModalShowing,
  setProgramDayIndex,
  setWeekDayIndex,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 225px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  transition: height 1s ease;
  width: 40vw;
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  .print-button {
    background: #10cd8c;
  }
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: auto;
  padding: 20px;
`;

const SkipWorkoutModal = () => {
  const dispatch = useDispatch();

  const isSkipWorkoutModalShowing = useSelector(
    (state) => state.workouts.ui.isSkipWorkoutModalShowing,
  );
  const programDayIndex = useSelector((state) => state.workouts.data.programDayIndex);
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const weekDayIndex = useSelector(
    (state) => state.workouts.data.weekDayIndex,
  );
  const workoutProgramWeek = useSelector(
    (state) => state.workouts.data.workoutProgramWeek,
  );
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);
  const workoutProgramWeeks = useSelector(
    (state) => state.workouts.data.workoutProgramWeeks,
  );
  const workoutProgramWeekIndex = useSelector(
    (state) => state.workouts.data.workoutProgramWeekIndex,
  );
  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );

  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  return (
    <ModalContainer
      isActive={isSkipWorkoutModalShowing}
    >
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <div style={{
            marginLeft: 10,
          }}
          >
            <ModalTextTop>Skip this Workout?</ModalTextTop>
          </div>
        </div>
        <div className='remove-div-icon' onClick={() => dispatch(setIsSkipWorkoutModalShowing(false))}>
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        This action cannot be undone and you will not be able to
        access this session until the program is completed or reset.
        You will still be able to access this program,
        this day will simply be marked as &quot;Skipped&quot; and
        you will be navigated to the next available day.
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='CONTINUE'
        className='print-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          const newCurrentDay = workoutProgramWeek[weekDayIndex + 1];
          if (newCurrentDay) {
            dispatch(skipWorkout(
              currentUser,
              currentSelectedProgram,
              programDayIndex,
              userId,
              weekDayIndex,
            ));
            if (weekDayIndex < workoutProgramWeek.length - 1 && !isWorkoutsListLoading) {
              const newIndex = newCurrentDay.trueDayNum;
              dispatch(setProgramDayIndex(newIndex));
              dispatch(setWeekDayIndex(weekDayIndex + 1));
              dispatch(fetchNextWorkoutProgramWorkouts(
                currentUser,
                currentSelectedProgram,
                newIndex,
                userId,
                newCurrentDay,
              ));
            }
          }
          if (!newCurrentDay) {
            if (workoutProgramWeeks[workoutProgramWeekIndex + 1] && !isWorkoutsListLoading) {
              dispatch(skipWorkout(
                currentUser,
                currentSelectedProgram,
                programDayIndex,
                userId,
                weekDayIndex,
              ));
              dispatch(fetchNextWorkoutProgramWeek(
                currentUser,
                currentSelectedProgram,
                workoutProgramWeekIndex + 1,
                userId,
              ));
            }
          }
          if (!newCurrentDay && workoutProgramWeeks[workoutProgramWeekIndex + 1] === undefined) {
            dispatch(skipWorkout(
              currentUser,
              currentSelectedProgram,
              programDayIndex,
              userId,
              weekDayIndex,
              true,
            ));
          }
        }}
      />
    </ModalContainer>
  );
};

export default SkipWorkoutModal;
