import {
  FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED,
  FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED,

  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED,

  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED,
  FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED,

  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED,
  FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED,
  // SAVE_SIDEBAR_FILTER,
  // CLEAR_REPORT,
  SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER,
  SET_CURRENT_DATE_IN_DASH_DATE_PICKER,
  SET_CURRENT_GROUP_IN_GROUP_PICKER,
  SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER,
  SET_ATHLETES_IN_CLICKED_ZONE,
  SET_CURRENT_CHECKED_BOXES,
  FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED,
  FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED,
  SET_SHOW_ALL_FOR_DOWNLOAD,
  SET_IS_CHART_SHOWING_ALL_DATA,
} from './actionTypes';

const initialState = {
  /** questionnaire state start */
  // reportableGroups: [],
  questionnairesForAccount: [],
  questionsForAccount: [],
  questionnaireReportData: {},
  questionnaireReportError: {},
  loadMonitoringFormValues: {},
  // currentQuestionInDonutChartQuestionnairePicker: '',
  // currentDateInDashDatePicker: '',
  // currentGroupInGroupPicker: '',
  // currentAthleteInAthletePicker: '',
  currentQuestionInDonutChartQuestionnairePicker: {},
  currentDateInDashDatePicker: {},
  currentGroupInGroupPicker: {},
  currentAthleteInAthletePicker: {},
  currentReportType: '',
  currentCheckedBoxes: [],
  athletesAssociatedWithCurrentGroup: [],
  athletesInClickedZone: [],
  loadMonitoringLineChartData: [],
  /** questionnaire state end */
  questionnairesForAccountError: {},
  questionsForAccountError: {},
  loadMonitoringLineChartDataError: {},
  showAllForDownload: false,
  isChartShowingAllData: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REPORTABLE_GROUPS_LM_DASH_FULFILLED: {
      return {
        ...state,
        // reportableGroups: [],
        reportableGroups: action.payload,
      };
    }
    case FETCH_REPORTABLE_GROUPS_LM_DASH_REJECTED: {
      return {
        ...state,
        reportableGroups: [],
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        questionnairesForAccount: action.payload,
        questionnairesForAccountError: {},
      };
    }
    case FETCH_QUESTIONNAIRES_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnairesForAccountError: action.payload,
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_FULFILLED: {
      return {
        ...state,
        questionsForAccount: action.payload,
        questionsForAccountError: {},
      };
    }
    case FETCH_QUESTIONS_FOR_ACCOUNT_LM_DASH_REJECTED: {
      return {
        ...state,
        questionsForAccountError: action.payload,
      };
    }
    case FETCH_LOAD_MONITORNING_LINE_CHART_FULFILLED: {
      return {
        ...state,
        loadMonitoringLineChartData: action.payload,
      };
    }
    case FETCH_LOAD_MONITORNING_LINE_CHART_REJECTED: {
      return {
        ...state,
        loadMonitoringLineChartData: [],
        loadMonitoringLineChartDataError: action.payload,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_FULFILLED: {
      const { dataRows } = action.payload;
      console.log('action.payload:', action.payload);
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
        */
        loadMonitoringFormValues: action.payload?.values,
        questionnaireReportError: {},
        currentReportType: 'singleDay',
        athletesAssociatedWithCurrentGroup: dataRows,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_SINGLE_DAY_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        athletesAssociatedWithCurrentGroup: [],
        questionnaireReportError: action.payload.response.headers,
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_FULFILLED: {
      return {
        ...state,
        /** questionnaire report reflects the return values sent by the api endpoint */
        questionnaireReportData: action.payload,
        /** questionnaire report form reflects the values sent in the form
         * we can use this for initial values in formik
        */
        loadMonitoringFormValues: action.payload.values,
        questionnaireReportError: {},
        currentReportType: 'multiDay',
      };
    }
    case FETCH_QUESTIONNAIRE_REPORT_MULTIDAY_LM_DASH_REJECTED: {
      return {
        ...state,
        questionnaireReportData: [],
        questionnaireReportError: action.payload.response.headers,
      };
    }
    // case CLEAR_REPORT: {
    //   return {
    //     ...state,
    //     questionnaireReportData: {},
    //     questionnaireReportError: {},
    //     loadMonitoringFormValues: {},
    //   };
    // }
    case SET_CURRENT_QUESTION_IN_DONUT_CHART_QUESTIONNAIRE_PICKER: {
      return {
        ...state,
        currentQuestionInDonutChartQuestionnairePicker: action.payload,
      };
    }
    case SET_CURRENT_DATE_IN_DASH_DATE_PICKER: {
      return {
        ...state,
        currentDateInDashDatePicker: action.payload,
      };
    }
    case SET_CURRENT_GROUP_IN_GROUP_PICKER: {
      return {
        ...state,
        currentGroupInGroupPicker: action.payload,
      };
    }
    case SET_CURRENT_ATHLETE_IN_ATHLETE_PICKER: {
      return {
        ...state,
        currentAthleteInAthletePicker: action.payload,
      };
    }
    case SET_ATHLETES_IN_CLICKED_ZONE: {
      return {
        ...state,
        athletesInClickedZone: action.payload,
      };
    }
    case SET_CURRENT_CHECKED_BOXES: {
      return {
        ...state,
        currentCheckedBoxes: action.payload,
      };
    }
    case SET_SHOW_ALL_FOR_DOWNLOAD: {
      return {
        ...state,
        showAllForDownload: action.payload,
      };
    }
    case SET_IS_CHART_SHOWING_ALL_DATA: {
      return {
        ...state,
        isChartShowingAllData: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
