import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setIsUnsavedDashModalShowing } from '../../ducks/sportCoachDashboardActions';
import UnsavedDashModal from './UnsavedDashModal';

const UnsavedDashModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const UnsavedDashModalContainer = () => {
  const dispatch = useDispatch();
  const isUnsavedDashModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.isUnsavedDashModalShowing,
  );

  return (
    <>
      <UnsavedDashModal />
      <UnsavedDashModalOverlay
        isActive={isUnsavedDashModalShowing}
        onClick={
          () => dispatch(setIsUnsavedDashModalShowing(false))
          }
      />
    </>
  );
};

UnsavedDashModalContainer.propTypes = { };

export default UnsavedDashModalContainer;
