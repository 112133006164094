import React from 'react';
import styled from '@emotion/styled';

const Container = styled('div')`
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Bubble = styled('div')`
    width: 10px;
    height: 10px;
    margin: 3px 1px 0;
    border-radius: 35px;
    background-color: ${(props) => props.backgroundColor};
`;

const BubbleAndLabel = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BubbleLabel = styled('div')`
    padding-left: 6px;
`;

const ScheduledWorkoutsChartLegend = () => (
  <Container>
    <BubbleAndLabel>
      <Bubble backgroundColor='#10cd8c' />
      <BubbleLabel>30+ days</BubbleLabel>
    </BubbleAndLabel>
    <BubbleAndLabel>
      <Bubble backgroundColor='#ffaf0e' />
      <BubbleLabel>15-29 days</BubbleLabel>
    </BubbleAndLabel>
    <BubbleAndLabel>
      <Bubble backgroundColor='#ff730e' />
      <BubbleLabel>7-14 days</BubbleLabel>
    </BubbleAndLabel>
    <BubbleAndLabel>
      <Bubble backgroundColor='#ff0024' />
      <BubbleLabel>0-6 days</BubbleLabel>
    </BubbleAndLabel>
  </Container>
);

export default ScheduledWorkoutsChartLegend;
