/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useRouteMatch,
  useLocation,
  useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import {
  fetchAthleteReportCard, fetchAthleteReportCardPublic, setIsShareModalOpen,
} from './ducks/athleteReportCardActions';
import { fetchUserData } from '../login/ducks/loginActions';
import WeeklyTrainingResultsHeaderSection from './components/presentational/weeklyTrainingResultsHeaderSection';
import WeeklyOverviewPage from './components/presentational/weeklyOverviewPage';
import NewMaxesAndPRsPage from './components/presentational/newMaxesAndPRsPage';
import TotalWeekBreakdownPage from './components/presentational/totalWeekBreakdownPage';
import Header from './components/presentational/header';
import SectionGradient from './components/presentational/sectionGradient';
import WeeklyTrainingResultsWorkoutCardsSection from './components/presentational/weeklyTrainingResultsWorkoutCardsSection';
import Unsubscribe from './components/presentational/unsubscribe';
import Spinner from '../../shared/components/Spinner/Spinner';
import ErrorMessageWarning from './components/presentational/errorMessageWarning';
import Modal from '../../shared/components/Modal';
import ShareModalContent from './components/shareModalContent';

export const AthleteReportCardRouteWrapper = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const isShareModalOpen = useSelector((state) => state.athleteReportCard?.data?.isShareModalOpen);
  const closeModal = () => {
    dispatch(setIsShareModalOpen(false));
  };

  return (
    <Switch>
      <Route path={`${path}/user/:userId`} exact>
        <AthleteReportCard allowSharing />
        <Modal
          isOpen={isShareModalOpen}
          onRequestClose={closeModal}
          customButton
        >
          <ShareModalContent />
        </Modal>
      </Route>
      <Route path={`${path}`} exact>
        <AthleteReportCard />
      </Route>
      <Route path={`${path}/unsubscribe`} exact>
        <Unsubscribe message='You have been successfully unsubscribed from athlete report cards.' />
      </Route>
    </Switch>
  );
};

export const AthleteReportCard = ({ allowSharing }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId } = useParams();
  const token = new URLSearchParams(location.search).get('token');
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const isAthleteReportCardLoading = useSelector((state) => state.athleteReportCard.ui.isAthleteReportCardLoading);
  const sessions = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.training?.sessions);
  const selectedWeekHasWorkoutItems = sessions?.some((session) => session?.workoutItems && session?.workoutItems.length > 0);
  const currentWeekNewMaxes = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.maxes?.newMaxes.currentWeek);
  const athleteReportCardErrorMessage = useSelector((state) => state.athleteReportCard?.data?.athleteReportCardError);
  const tokenExpired = athleteReportCardErrorMessage && athleteReportCardErrorMessage === 'Invalid token';
  const athleteReportCard = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard);

  useEffect(() => {
    if (token) {
      dispatch(fetchAthleteReportCardPublic(token));
    } else {
      dispatch(fetchUserData());
    }
  }, []);

  useEffect(() => {
    if ((Object.keys(currentUser).length) && userId && !token) {
      const endDate = moment().format('YYYY-MM-DD');
      const startDate = endDate ? moment(endDate).subtract(6, 'days').format('YYYY-MM-DD') : null;
      if (startDate && endDate) {
        dispatch(fetchAthleteReportCard(currentUser?.accountCode, userId, startDate, endDate));
      }
    }
  }, [currentUser, userId]);

  const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  align-items: center;
  `;
  const TokenExpiredWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: auto;
  `;

  return (
    <>
      <Helmet>
        <title>Weekly Performance Assessment | TeamBuildr</title>
      </Helmet>
      {isAthleteReportCardLoading ? (
        <Wrapper>
          <Spinner />
        </Wrapper>
      ) : (
        <>
          {tokenExpired ? (
            <TokenExpiredWrapper>
              <ErrorMessageWarning errorMessage='Oops! The token needed to view this report has expired. Contact your coach to resend this report.' />
            </TokenExpiredWrapper>
          ) : (
            <>
              {!(Object.keys(athleteReportCard).length) ? (
                <TokenExpiredWrapper>
                  <ErrorMessageWarning errorMessage='Oops! Something went wrong. We were not able to retrieve data for the athlete report card.' apiErrorMessage={athleteReportCardErrorMessage} />
                </TokenExpiredWrapper>
              ) : (
                <Wrapper>
                  <Header allowSharing={allowSharing} />
                  <SectionGradient customIcon='clipboard' title='Weekly Overview' />
                  <WeeklyOverviewPage />
                  <SectionGradient customIcon='steps' title='Weekly Training Results' />
                  <WeeklyTrainingResultsHeaderSection />
                  {selectedWeekHasWorkoutItems && (
                  <WeeklyTrainingResultsWorkoutCardsSection />
                  )}
                  {currentWeekNewMaxes > 0 && (
                  <>
                    <SectionGradient customIcon='trophy' title='New Maxes/PR Updates' />
                    <NewMaxesAndPRsPage />
                  </>
                  )}
                  <SectionGradient customIcon='chart-line' title='Total Week Breakdown' />
                  <TotalWeekBreakdownPage />
                </Wrapper>
              )}
            </>

          )}
        </>
      )}
    </>
  );
};
AthleteReportCard.propTypes = {
  allowSharing: PropTypes.bool,
};

AthleteReportCard.defaultProps = {
  allowSharing: false,
};
