import React from 'react';
import ReactPlayer from 'react-player';

const memoFunction = () => (1 === 2);

const CustomReactPlayer = () => (
  <ReactPlayer
    url='https://youtu.be/gLKF_6xva54'
    controls
  />
);

export default React.memo(CustomReactPlayer, memoFunction);
