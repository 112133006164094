import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import {
  setAutoSaveInfoModal,
} from '../../ducks/evaluationsActions';
import AutoSaveInfoModal from './AutoSaveInfoModal';

const AutoSaveInfoModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

const AutoSaveInfoModalContainer = () => {
  const autoSaveInfoModalShowing = useSelector(
    (state) => state.evaluations.ui.autoSaveInfoModalShowing,
  );
  const dispatch = useDispatch();

  return (
    <>
      <AutoSaveInfoModalOverlay
        isActive={autoSaveInfoModalShowing}
        onClick={() => {
          dispatch(setAutoSaveInfoModal(false));
        }}
      />
      <AutoSaveInfoModal />
    </>
  );
};

AutoSaveInfoModalContainer.propTypes = { };

export default AutoSaveInfoModalContainer;
