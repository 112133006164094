import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../../../shared/components/Text/Text';
import iconSet from '../../../../../../shared/images/teambuildr-selection.json';

const Dropdown = styled('div')`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 16px 24px rgba(48, 49, 51, 0.1);
  top: 100%;
  margin-top: 15px;
  margin-left: 12px;
  height: max-content;
  width: 232px;
  border-radius: 12px;
  padding-top: 8px;
`;

const DropdownOption = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;

const FilterDropdownProgramStatus = ({
  setStatusValue, toggleFilterDropdown, updateFilter, overlayDispatchAction,
}) => (
  <Dropdown>
    <DropdownOption onClick={() => {
      toggleFilterDropdown('Status');
      updateFilter(2);
      setStatusValue(1);
      overlayDispatchAction(false);
    }}
    >
      <Text>Active</Text>
      <IcomoonReact iconSet={iconSet} size={15} icon='right-arrow' color='#FF6600' />
    </DropdownOption>
    <DropdownOption onClick={() => {
      toggleFilterDropdown('Status');
      updateFilter(2);
      setStatusValue(0);
      overlayDispatchAction(false);
    }}
    >
      <Text>Inactive</Text>
      <IcomoonReact iconSet={iconSet} size={15} icon='right-arrow' color='#FF6600' />
    </DropdownOption>
    <DropdownOption onClick={() => {
      toggleFilterDropdown('Status');
      updateFilter(2);
      setStatusValue(2);
      overlayDispatchAction(false);
    }}
    >
      <Text>Archived</Text>
      <IcomoonReact iconSet={iconSet} size={15} icon='right-arrow' color='#FF6600' />
    </DropdownOption>
  </Dropdown>
);

FilterDropdownProgramStatus.propTypes = {
  toggleFilterDropdown: PropTypes.func.isRequired,
  setStatusValue: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  overlayDispatchAction: PropTypes.func.isRequired,
};

export default FilterDropdownProgramStatus;
