import { connect } from 'react-redux';
import QuestionList from '../presentational/QuestionList';
import { selectQuestion, storeFormikValues, updateWorkout } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeQuestionIndex: state[ownProps.namespace].activeQuestionIndex,
  athleteId: state[ownProps.namespace].athleteId,
});

const mapDispatchToProps = dispatch => ({
  selectQuestion: (id, index, namespace) => {
    dispatch(selectQuestion(id, index, namespace));
  },
  updateWorkout: (index, namespace, values) => {
    dispatch(updateWorkout(index, namespace, values));
  },
  storeFormikValues: (namespace, values) => {
    dispatch(storeFormikValues(namespace, values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(QuestionList);
