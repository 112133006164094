import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import WorkoutEntryView from '../presentational/WorkoutEntryView';
import {
  closeModal,
  fetchHistory,
  fetchDocument,
  fetchExercises,
  fetchSimilarExercises,
  openModal,
  updateWorkout,
  updateProgramWorkout,
} from '../../ducks/panelActions';

const WeightroomWorkoutEntryView = (ownProps) => {
  const data = useSelector((
    state,
  ) => state.weightRoomView[ownProps.namespace].workouts[ownProps.index]);

  const dispatch = useDispatch();

  const ftchHistory = (namespace, accountCode, exrId, userId) => {
    dispatch(fetchHistory(namespace, accountCode, exrId, userId));
  };
  const ftchDocument = (namespace, accountCode, docId) => {
    dispatch(fetchDocument(namespace, accountCode, docId));
  };
  const ftchExercises = (namespace, accountCode) => {
    dispatch(fetchExercises(namespace, accountCode));
  };
  const ftchSimilarExercises = (namespace, accountCode, exrId) => {
    dispatch(fetchSimilarExercises(namespace, accountCode, exrId));
  };
  const clsModal = (namespace) => {
    dispatch(closeModal(namespace));
  };
  const opnModal = (namespace, modal) => {
    dispatch(openModal(namespace, modal));
  };
  const updteWorkout = (index, namespace, values) => {
    dispatch(updateWorkout(index, namespace, values));
  };

  const updteProgramWorkout = (index, namespace, programId, dayNum, dayIdx, values) => {
    dispatch(updateProgramWorkout(index, namespace, programId, dayNum, dayIdx, values));
  };

  return (
    <WorkoutEntryView
      data={data}
      namespace={ownProps.namespace}
      entryRowRef={ownProps.entryRowRef}
      formikRef={ownProps.formikRef}
      entriesContainerRef={ownProps.entriesContainerRef}
      isWorkoutActive={ownProps.isWorkoutActive}
      nextButton={ownProps.nextButton}
      scienceRef={ownProps.scienceRef}
      scrollQuestions={ownProps.scrollQuestions}
      supersetIndex={ownProps.supersetIndex}
      fetchHistory={ftchHistory}
      fetchDocument={ftchDocument}
      fetchExercises={ftchExercises}
      fetchSimilarExercises={ftchSimilarExercises}
      closeModal={clsModal}
      openModal={opnModal}
      updateProgramWorkout={updteProgramWorkout}
      updateWorkout={updteWorkout}
    />
  );
};

export default WeightroomWorkoutEntryView;
