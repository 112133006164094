// authored by Joshua Beedle
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Tooltip } from 'react-tooltip';
import IcomoonReact from 'icomoon-react';

import Wrapper from './Wrapper';
import iconSet from '../../images/teambuildr-selection.json';

const Content = styled('a')`
  color: ${(props) => props.displayColor};
  cursor: ${(props) => props.cursor};
  font-family: 'Nunito Sans';
  font-size: ${(props) => props.displaySize};
  font-weight: ${(props) => props.displayWeight};

  :hover {
    color: ${(props) => props.displayColor};
  }
`;

/**
 *
 * @param {String} cursor controls what type of cursor to display when hovering
 * @param {String} displayColor controls the font color of the display text and ellipsis
 * @param {String} displaySize controls the font size of the display text
 * @param {String} displayText content to be displayed in componet w/o hovering
 * @param {String} displayWeight controls the font weight of the display text
 * @param {String} hoverText content to be displayed while hovering
 * @param {String} icon the name of the icomoon icon to be displayed
 * @param {Number} iconSize the display size of the icon
 * @param {String} id the name of the id to be used to connect the anchor tag and tooltip
 * @param {String} place determines the positioning of the tooltip (top, right, bottom, left)
 * @param {Object} styledPropsWrapper accepts an object with all optional styles
 * in accordance with the styled-system paradigm (https://github.com/styled-system/styled-system/blob/master/docs/api.md)
 * @param {Objec} tooltipStyles accepts an object with optional styles for tooltip
 * @returns
 */
const TBTooltip = ({
  cursor,
  displayColor,
  displaySize,
  displayText,
  displayWeight,
  hoverText,
  icon,
  iconSize,
  id,
  place,
  styledPropsWrapper,
  tooltipStyles,
}) => (
  <Wrapper color={displayColor} {...styledPropsWrapper}>
    <Content
      cursor={cursor}
      data-tooltip-content={hoverText}
      data-tooltip-id={id}
      displayColor={displayColor}
      displaySize={displaySize}
      displayWeight={displayWeight}
    >
      {icon
        ? <IcomoonReact icon={icon} iconSet={iconSet} size={iconSize} />
        : displayText}
    </Content>
    <Tooltip
      id={id}
      place={place}
      style={{ ...tooltipStyles }}
    />
  </Wrapper>
);

TBTooltip.propTypes = {
  cursor: PropTypes.string,
  displayColor: PropTypes.string,
  displaySize: PropTypes.string,
  displayText: PropTypes.string,
  displayWeight: PropTypes.string,
  hoverText: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  id: PropTypes.string.isRequired,
  place: PropTypes.string,
  styledPropsWrapper: PropTypes.instanceOf(Object),
  tooltipStyles: PropTypes.instanceOf(Object),
};

TBTooltip.defaultProps = {
  cursor: 'pointer',
  displayText: '',
  displayColor: '#444444',
  displaySize: '16px',
  displayWeight: 'normal',
  icon: null,
  iconSize: 15,
  place: 'top',
  styledPropsWrapper: {},
  tooltipStyles: {},
};

export default TBTooltip;
