import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Text from '../../../shared/components/Text/Text';
import Button from '../../../shared/components/Button/Button';
import useBoard from './hooks/useBoard';
import useLeaderboard from './hooks/useLeaderboard';

const DownloadBoard = ({
  closeModal,
  leaderboardId,
}) => {
  const leaderboardExercise = useSelector(
    (state) => state.leaderboard.data.leaderboards[leaderboardId].leaderboardExercise,
  );
  const leaderboardForm = useSelector(
    (state) => state.leaderboard.data.leaderboards[leaderboardId].leaderboardForm,
  );

  // const {
  //   leaderboardExercise,
  //   leaderboardForm,
  // } = useBoard(leaderboardId);

  const {
    handleFetchLeaderboard,
  } = useLeaderboard();

  const downloadBoard = () => {
    handleFetchLeaderboard(leaderboardForm, 'download', leaderboardId);
    closeModal();
  };

  return (
    <Fragment>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        {`Would you like to download the ${leaderboardExercise} leaderboard as a CSV File?`}
      </Text>
      <Button
        cta='Download Board'
        type='button'
        onClick={downloadBoard}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </Fragment>
  );
};

DownloadBoard.propTypes = {
  closeModal: PropTypes.func.isRequired,
  leaderboardId: PropTypes.string.isRequired,
};

export default DownloadBoard;
