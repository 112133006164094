import React, { Fragment, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { TransitionGlobal, Nunito } from '../../../../../shared/GlobalStyles';
import Button from '../../../../../shared/components/Button/Button';
import Text from '../../../../../shared/components/Text/Text';


const SidebarContainer = styled('aside')`
  height: 100%;
  flex: 2;
  left: 0px;
  background: ${props => props.theme.colorPrimary};
  align-self: flex-start;
  padding: 30px 0px;
  transition: ${TransitionGlobal};  

  h1 {
    color: ${props => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')}!important;
    letter-spacing: 1.3px;
    margin-bottom: 15px;
  }

  .sidebar-items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: left;
    flex-direction: column;
    .sidebar-item-text {
      color: ${(props) => (props.theme.isPrimaryDarkTheme ? 'white' : '#444444')};
      font-weight: 900;
      font-size: ${props => props.theme.textFontSizes[1]};
      font-family: ${Nunito};
      padding: 12px 20px;
      letter-spacing: 1.4px;

      &:hover, &.active-sidebar-item {
        background-color: ${props => props.theme.colorAccent};
        color: ${props => (props.theme.isAccentDarkTheme ? 'white' : '#444444')}
      }
    }
  }

  @media screen and (max-width: 540px) {
    position: absolute;
    width: 100%;
    transform: ${props => (props.isMobileSidebarActive ? 'translate(0px, -0%)' : 'translate(0px, -100%)')};
  }
`;

const ReportingSidebar = () => {
  const dispatch = useDispatch();
  const updateSidebarWidth = (width) => {
    // dispatch(setSidebarWidth(width));
  };
  const updateLayoutHeight = (height) => {
    // dispatch(setLayoutHeight(height));
  };

  return (
    <SidebarContainer>
      <ResizeObserver
        onResize={(rect) => {
          updateSidebarWidth(rect.width);
          updateLayoutHeight(rect.height);
        }}
      />
      <div className='sidebar-items'>
        <NavLink to='programs' activeClassName='active-sidebar-item' className='sidebar-item-text'>PROGRAMS</NavLink>
        <NavLink to='subscribers' activeClassName='active-sidebar-item' className='sidebar-item-text'>SUBSCRIBERS</NavLink>
        <NavLink to='coupons' activeClassName='active-sidebar-item' className='sidebar-item-text'>COUPONS</NavLink>
        <NavLink to='integrations' activeClassName='active-sidebar-item' className='sidebar-item-text'>INTEGRATIONS</NavLink>
        <NavLink to='settings' activeClassName='active-sidebar-item' className='sidebar-item-text'>SETTINGS</NavLink>
      </div>
    </SidebarContainer>
  );
};

ReportingSidebar.propTypes = {
};

export default ReportingSidebar;
