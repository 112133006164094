import React from 'react';
import styled from '@emotion/styled';
import Shimmer from 'react-shimmer-effect';

const ActivityBarWrapper = styled('div')`
    display: flex;
    flex: 1.5;
    min-height: 225px;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
    justify-content: space-between;
`;

const MainContent = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const SingleBar = styled('div')`
    background-color: ${(props) => (props.backgroundColor)};
    height: 5px;
    width: 100%;
`;

const BreakdownCard = styled('div')`
    box-shadow: 1px 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    margin-bottom: 10px;
    width: ${(props) => (props.extendWidth ? '80px' : '60px')};
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const CardAndBarWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    cursor: pointer;
`;

const BreakdownLabel = styled('div')`
    font-size: 12px;
`;

const BreakdownValue = styled('div')`
    font-size: 16px;
    font-weight: bold;
`;

const EmptyDiv = styled('div')`
`;

const Title = styled('div')`
    padding-top: 15px;
    font-size: 14px;
    font-weight: 900;
    font-family: Nunito Sans;
    letter-spacing: 1.18px;
`;

const TitleAndDescription = styled('div')`
    display: flex;
    flex-direction: column;
`;

const Description = styled('div')`
    font-size: 12px;
    padding-top: 5px;
`;

const ActivityBarLoading = () => (
  <ActivityBarWrapper>
    <TitleAndDescription>
      <Title>LATEST WORKOUT ACTIVITY</Title>
      <Description>
        Breakdown of latest completed workout for selected athletes into four date ranges.
        Click a range to filter table below.
      </Description>
    </TitleAndDescription>
    <MainContent>
      <CardAndBarWrapper>
        <BreakdownCard>
          <BreakdownLabel>
            no activity
          </BreakdownLabel>
          <BreakdownValue>0</BreakdownValue>
        </BreakdownCard>
        <Shimmer>
          <SingleBar backgroundColor='#444445' />
        </Shimmer>
      </CardAndBarWrapper>

      <CardAndBarWrapper>
        <BreakdownCard>
          <BreakdownLabel>
            30+
          </BreakdownLabel>
          <BreakdownValue>0</BreakdownValue>
        </BreakdownCard>
        <Shimmer>
          <SingleBar backgroundColor='#ff0024' />
        </Shimmer>
      </CardAndBarWrapper>

      <CardAndBarWrapper>
        <BreakdownCard>
          <BreakdownLabel>
            15-29
          </BreakdownLabel>
          <BreakdownValue>0</BreakdownValue>
        </BreakdownCard>
        <Shimmer>
          <SingleBar backgroundColor='#ff730e' />
        </Shimmer>
      </CardAndBarWrapper>

      <CardAndBarWrapper>
        <BreakdownCard>
          <BreakdownLabel>
            7-14
          </BreakdownLabel>
          <BreakdownValue>0</BreakdownValue>
        </BreakdownCard>
        <Shimmer>
          <SingleBar backgroundColor='#ffaf0e' />
        </Shimmer>
      </CardAndBarWrapper>

      <CardAndBarWrapper>
        <BreakdownCard>
          <BreakdownLabel>
            0-6
          </BreakdownLabel>
          <BreakdownValue>0</BreakdownValue>
        </BreakdownCard>
        <Shimmer>
          <SingleBar backgroundColor='#10cd8c' />
        </Shimmer>
      </CardAndBarWrapper>
    </MainContent>
    <EmptyDiv />
  </ActivityBarWrapper>
);

export default ActivityBarLoading;
