import React from 'react';
import { useDispatch } from 'react-redux';

import WorkoutList from '../presentational/WorkoutList';
import {
  completeWorkout,
  closeWorkout,
  decreaseCompletedSets,
  increaseCompletedSets,
  nextWorkout,
  prevWorkout,
  restartQuestions,
  selectQuestion,
  selectWorkout,
  setIsNavigatingSuperset,
  storeWorkoutsBySuperset,
  setShowSessionBreakItems,
} from '../../ducks/panelActions';

const WeightroomWorkoutList = (ownProps) => {
  const dispatch = useDispatch();

  const clsWorkout = (namespace) => {
    dispatch(closeWorkout(namespace));
  };
  const cmpltWorkout = (namespace) => {
    dispatch(completeWorkout(namespace));
  };
  const dcrseCompletedSets = (namespace, group, workoutIndex) => {
    dispatch(decreaseCompletedSets(namespace, group, workoutIndex));
  };
  const incrseCompletedSets = (namespace, group, workoutIndex) => {
    dispatch(increaseCompletedSets(namespace, group, workoutIndex));
  };
  const slctWorkout = (id, index, exerId, exerType, namespace) => {
    dispatch(selectWorkout(id, index, exerId, exerType, namespace));
  };
  const nxtWorkout = (id, index, exerId, exerType, namespace) => {
    dispatch(nextWorkout(id, index, exerId, exerType, namespace));
  };
  const prvWorkout = (id, index, exerId, exerType, namespace) => {
    dispatch(prevWorkout(id, index, exerId, exerType, namespace));
  };
  const rstrtQuestions = (namespace) => {
    dispatch(restartQuestions(namespace));
  };
  const slctQuestion = (id, index, namespace) => {
    dispatch(selectQuestion(id, index, namespace));
  };
  const stIsNavigatingSuperset = (namespace, value) => {
    dispatch(setIsNavigatingSuperset(namespace, value));
  };
  const strWorkoutsBySuperset = (namespace, workouts) => {
    dispatch(storeWorkoutsBySuperset(namespace, workouts));
  };
  const stShwSssnBrkItms = (namespace, index, value) => {
    dispatch(setShowSessionBreakItems(namespace, index, value));
  };

  return (
    <WorkoutList
      closeWorkout={clsWorkout}
      completeWorkout={cmpltWorkout}
      decreaseCompletedSets={dcrseCompletedSets}
      increaseCompletedSets={incrseCompletedSets}
      selectWorkout={slctWorkout}
      nextWorkout={nxtWorkout}
      prevWorkout={prvWorkout}
      restartQuestions={rstrtQuestions}
      selectQuestion={slctQuestion}
      setIsNavigatingSuperset={stIsNavigatingSuperset}
      storeWorkoutsBySuperset={strWorkoutsBySuperset}
      setShowSessionBreakItems={stShwSssnBrkItms}
      namespace={ownProps.namespace}
    />
  );
};

export default WeightroomWorkoutList;
