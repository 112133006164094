/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const SectionGradient = ({ customIcon, title }) => {
  const theme = useTheme();
  const accountSkin = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.overview?.user?.accountSkin);

  const getTextColor = () => {
    if (accountSkin?.useLightTextColor) {
      return 'white';
    }
    return theme.colors.text;
  };

  const Container = styled('div')`
    display: flex;
    min-height: 60px;
    width: 100%;
    background: ${(props) => props.background};
    justify-content: center;
    align-items: center;
    gap: 10px;

  `;

  return (
    <Container background={accountSkin?.primaryColor ? accountSkin.primaryColor : 'linear-gradient(270deg, #FC6076 0%, #FF9A44 100%)'}>
      <IcomoonReact
        iconSet={iconSet}
        size={36}
        icon={customIcon}
        color={accountSkin?.primaryColor ? getTextColor() : 'white'}
      />
      <Text fontSize='15px' fontWeight={800} color={accountSkin?.primaryColor ? getTextColor() : 'white'}>{title}</Text>
    </Container>
  );
};

SectionGradient.propTypes = {
  title: PropTypes.string.isRequired,
  customIcon: PropTypes.string.isRequired,
};

export default SectionGradient;
