import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import Text from './Text/Text';

const PRInfoCard = ({ name, value }) => {
  const theme = useTheme();

  const PRInfoCardWrapper = styled('div')`
    align-items: center;
    background-color: ${theme.colors.black};
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    height: 70px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 20px;
  `;

  return (
    <PRInfoCardWrapper>
      <Text color='white' fontSize={18} fontWeight='600' style={{ maxWidth: '70%' }}>
        {name}
      </Text>
      <Text color='white' fontSize={32} fontWeight='800'>
        {value}
      </Text>
    </PRInfoCardWrapper>
  );
};

PRInfoCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PRInfoCard;
