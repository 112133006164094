/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import AthleteParticipationModal from './AthleteParticipationModal';
import Modal from '../../../../shared/components/Modal/Modal';

import {
  setActiveReduxModal,
} from '../../ducks/wearablesDashboardActions';

const MainContainer = styled('main')`
`;

const ModalHandler = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const athleteParticipation = useSelector((state) => state.wearablesDashboard.data.athleteParticipation);
  const activeUserArray = athleteParticipation?.active?.users;
  const athletesNotSharingArray = athleteParticipation?.notSharing?.users;
  const athletesNotConnectedArray = athleteParticipation?.notConnected?.users;
  const activeModal = useSelector((state) => state.wearablesDashboard.data.activeModal);

  const closeModal = () => {
    dispatch(setActiveReduxModal(''));
  };

  const getAthleteList = () => {
    if (activeModal === 'athletes-connected') {
      return activeUserArray;
    }
    if (activeModal === 'athletes-not-sharing') {
      return athletesNotSharingArray;
    }
    if (activeModal === 'athletes-not-connected') {
      return athletesNotConnectedArray;
    }
    return [];
  };

  const modalHandler = () => {
    let returnModal = null;
    if ((activeModal === 'athletes-connected') || (activeModal === 'athletes-not-sharing') || (activeModal === 'athletes-not-connected')) {
      returnModal = (
        <Modal
          isOpen={!!activeModal}
          onRequestClose={closeModal}
        >
          <AthleteParticipationModal
            onRequestClose={closeModal}
            athleteList={getAthleteList()}
            containerWidth={containerWidth}
            // athleteList={activeModal === 'athletes-connected' ? activeUserArray : activeModal === 'athetes-not-sharing' ? athletesNotSharingArray : athletesNotConnectedArray}
          />
        </Modal>
      );
    }
    return returnModal;
  };

  return (
    <MainContainer>
      {modalHandler()}
    </MainContainer>
  );
};

ModalHandler.propTypes = {
};

export default ModalHandler;
