import { batch } from 'react-redux';
import Cookies from 'js-cookie';
import history from '../../../../services/History/history';
import { push } from 'connected-react-router';

import { axiosAuthed } from '../../../utils/setCommonHeaders';
import { authProvider, fetchUserData } from '../../../../modules/login/ducks/loginActions';

import {
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_FULFILLED,
  FETCH_ACCOUNTS_REJECTED,
  FETCH_UNREAD_MESSAGES_START,
  FETCH_UNREAD_MESSAGES_FULFILLED,
  FETCH_UNREAD_MESSAGES_REJECTED,
  SWITCH_ACCOUNT_START,
  SWITCH_ACCOUNT_FULFILLED,
  SWITCH_ACCOUNT_REJECTED,
  SET_LAYOUT_MODAL_OPEN,
} from './actionTypes';

authProvider();

export const submitSupport = (values) => (
  (dispatch) => {
    dispatch({ type: SUBMIT_SUPPORT_START });
    axiosAuthed.post('/support', values)
      .then((response) => {
        dispatch({
          type: SUBMIT_SUPPORT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SUBMIT_SUPPORT_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchAccounts = () => (
  (dispatch) => {
    dispatch({ type: FETCH_ACCOUNTS_START });
    axiosAuthed.get('/me/accounts')
      .then((response) => {
        dispatch({
          type: FETCH_ACCOUNTS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ACCOUNTS_REJECTED,
          payload: err.data,
        });
      });
  }
);

export const setModalOpen = (activeModal) => ({
  type: SET_LAYOUT_MODAL_OPEN,
  payload: activeModal,
});

export const switchAccounts = (accountCode) => (
  (dispatch) => {
    dispatch({ type: SWITCH_ACCOUNT_START });
    axiosAuthed.put(`/me/accounts/${accountCode}/current`)
      .then(() => {
        dispatch({ type: SWITCH_ACCOUNT_FULFILLED });
        Cookies.remove('accessToken', {
          secure: process.env.ENVIRONMENT !== 'LOCAL',
          domain: process.env.ENVIRONMENT === 'LOCAL' ? 'localhost' : '.teambuildr.com',
        });
        batch(() => {
          dispatch(fetchUserData());
          dispatch(setModalOpen(''));
        });
        history.go(0);
      })
      .catch((err) => {
        dispatch({
          type: SWITCH_ACCOUNT_REJECTED,
          payload: err.data,
        });
      });
  }
);

export const fetchUnreadMessages = () => (
  (dispatch) => {
    dispatch({ type: FETCH_UNREAD_MESSAGES_START });
    axiosAuthed.get('/me/conversations/unread')
      .then((response) => {
        dispatch({
          type: FETCH_UNREAD_MESSAGES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_UNREAD_MESSAGES_REJECTED,
          payload: err.data,
        });
      });
  }
);
