import moment from 'moment';
import update from 'immutability-helper';

import {
  FETCH_TEMPLATES_FULFILLED,
  FETCH_TEMPLATES_REJECTED,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_SCDASH_DATA_FULFILLED,
  FETCH_SCDASH_DATA_REJECTED,
  SET_GROUP,
  SET_SELECTED_ATHLETE_PERFORMANCE,
  SET_SELECTED_ATHLETE_GROUP,
  SET_START_DATE_CAL,
  SET_START_DATE_DATA,
  SET_END_DATE_CAL,
  SET_END_DATE_DATA,
  SET_DATE_TYPE,
  SET_EXER_FILTERS_PERFORMANCE,
  SET_EXER_FILTERS_GROUP,
  SET_MAX_TYPE,
  SET_EXERCISES,
  FETCH_EXERCISES_FULFILLED,
  DELETE_DASHBOARD_FULFILLED,
  FETCH_SHARE_DASH_LINK_FULFILLED,
  FETCH_SHARE_DASH_LINK_REJECTED,
  FETCH_PUBLIC_SCDASH_DATA_FULFILLED,
  FETCH_PUBLIC_SCDASH_DATA_REJECTED,
  SET_EDIT_DASHBOARD_MODAL,
  EDIT_DASHBOARD_FULFILLED,
  CLEAR_SHARE_DASH_LINK,
  SET_ACTIVE_TEMPLATE,
  SET_NEXT_LOCATION,
  SET_ABOUT_DASHBOARD_MODAL,
  SET_SHARE_DASHBOARD_MODAL,
  SET_TEMPLATE,
  SET_DUPLICATE_MODAL_SHOWING,
  CREATE_DASHBOARD_FULFILLED,
  CREATE_DASHBOARD_REJECTED,
  SET_GROUPS,
  CLEAR_SC_DASH_DATA,
  DUPLICATE_DASHBOARD_FULFILLED,
} from './actionTypes';

const initialState = {
  aboutTemplate: null,
  athleteList: [],
  availableExercises: [],
  cardData: [],
  chartData: [],
  createDashError: null,
  dashBGColor: null,
  dashCreator: null,
  dashDescription: null,
  dashLogo: null,
  dashTitle: null,
  dateType: 1,
  editTemplate: {},
  endDateCal: moment(),
  endDateData: moment().format('YYYY-MM-DD'),
  exerciseFiltersGroup: [],
  exerciseFiltersPerformance: [],
  exercises: [],
  group: null,
  groups: [],
  maxType: 1,
  nextLocation: null,
  scDashData: null,
  scDashError: null,
  selectedAthleteGroup: null,
  selectedAthletePerformance: null,
  shareDashLink: null,
  shareDashLinkError: null,
  shareTemplate: {},
  startDateCal: moment().subtract(3, 'months'),
  startDateData: moment().subtract(3, 'months').format('YYYY-MM-DD'),
  templates: [],
  template: null,
  weightType: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATES_FULFILLED: {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case FETCH_TEMPLATES_REJECTED: {
      return {
        ...state,
        templates: [],
      };
    }
    case FETCH_EXERCISES_FULFILLED: {
      return {
        ...state,
        availableExercises: action.payload,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groups: [],
      };
    }
    case FETCH_SCDASH_DATA_FULFILLED: {
      return {
        ...state,
        athleteList: action.payload.athleteList,
        cardData: action.payload.cardData,
        chartData: action.payload.chartData,
        scDashData: action.payload.scDashData,
        scDashError: null,
      };
    }
    case FETCH_SCDASH_DATA_REJECTED: {
      return {
        ...state,
        athleteList: [],
        cardData: [],
        chartData: [],
        scDashData: null,
        scDashError: action.payload,
      };
    }
    case SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE_PERFORMANCE: {
      return {
        ...state,
        selectedAthletePerformance: action.payload.athlete,
      };
    }
    case SET_SELECTED_ATHLETE_GROUP: {
      return {
        ...state,
        selectedAthleteGroup: action.payload.athlete,
      };
    }
    case SET_START_DATE_CAL: {
      return {
        ...state,
        startDateCal: action.payload,
      };
    }
    case SET_START_DATE_DATA: {
      return {
        ...state,
        startDateData: action.payload,
      };
    }
    case SET_END_DATE_CAL: {
      return {
        ...state,
        endDateCal: action.payload,
      };
    }
    case SET_END_DATE_DATA: {
      return {
        ...state,
        endDateData: action.payload,
      };
    }
    case SET_DATE_TYPE: {
      return {
        ...state,
        dateType: action.payload,
      };
    }
    case SET_EXER_FILTERS_PERFORMANCE: {
      return {
        ...state,
        exerciseFiltersPerformance: action.payload,
      };
    }
    case SET_EXER_FILTERS_GROUP: {
      return {
        ...state,
        exerciseFiltersGroup: action.payload,
      };
    }
    case SET_MAX_TYPE: {
      return {
        ...state,
        maxType: action.payload,
      };
    }
    case SET_EXERCISES: {
      return {
        ...state,
        exerciseFiltersGroup: [action.payload[0].name],
        exerciseFiltersPerformance: [action.payload[0]],
        exercises: action.payload,
      };
    }
    case DELETE_DASHBOARD_FULFILLED: {
      return update(
        state, {
          templates: {
            $splice: [[action.payload.index, 1]],
          },
        },
      );
    }
    case DUPLICATE_DASHBOARD_FULFILLED: {
      return update(
        state, {
          templates: {
            $push: [action.payload],
          },
        },
      );
    }
    case EDIT_DASHBOARD_FULFILLED: {
      return update(
        state, {
          templates: {
            [action.payload.index]: {
              $set: action.payload.data,
            },
          },
          editTemplate: {
            $set: {},
          },
        },
      );
    }
    case FETCH_SHARE_DASH_LINK_FULFILLED: {
      return {
        ...state,
        shareDashLink: action.payload,
        shareDashLinkError: null,
      };
    }
    case FETCH_SHARE_DASH_LINK_REJECTED: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: action.payload,
      };
    }
    case SET_EDIT_DASHBOARD_MODAL: {
      return {
        ...state,
        editTemplate: action.payload,
      };
    }
    case FETCH_PUBLIC_SCDASH_DATA_FULFILLED: {
      return {
        ...state,
        athleteList: action.payload.athleteList,
        cardData: action.payload.cardData,
        chartData: action.payload.chartData,
        dashBGColor: action.payload.scDashData.account.primaryColor,
        dashCreator: action.payload.scDashData.creator,
        dashDescription: action.payload.scDashData.dashboardDescription,
        dashLogo: action.payload.scDashData.account.logo,
        dashTitle: action.payload.scDashData.dashboardTitle,
        dateType: action.payload.scDashData.dateType,
        endDateCal: moment(action.payload.scDashData.endDate),
        exerciseFiltersGroup: [action.payload.scDashData.exercises[0].name],
        exerciseFiltersPerformance: [action.payload.scDashData.exercises[0]],
        exercises: action.payload.scDashData.exercises,
        group: action.payload.scDashData.group,
        scDashData: action.payload.scDashData,
        scDashError: null,
        startDateCal: moment(action.payload.scDashData.startDate),
        weightType: action.payload.scDashData.account.weightTypeDescription,
      };
    }
    case FETCH_PUBLIC_SCDASH_DATA_REJECTED: {
      return {
        ...state,
        athleteList: [],
        cardData: [],
        chartData: [],
        scDashData: null,
        scDashError: action.payload,
      };
    }
    case CLEAR_SHARE_DASH_LINK: {
      return {
        ...state,
        shareDashLink: null,
        shareDashLinkError: null,
      };
    }
    case SET_ACTIVE_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
        maxType: action.payload.maxType,
      };
    }
    case SET_NEXT_LOCATION: {
      return {
        ...state,
        nextLocation: action.payload,
      };
    }
    case SET_ABOUT_DASHBOARD_MODAL: {
      return {
        ...state,
        aboutTemplate: action.payload.template,
      };
    }
    case SET_SHARE_DASHBOARD_MODAL: {
      return {
        ...state,
        shareTemplate: action.payload.template,
      };
    }
    case SET_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case SET_DUPLICATE_MODAL_SHOWING: {
      return {
        ...state,
        dupTemplate: action.payload.template,
      };
    }
    case CREATE_DASHBOARD_FULFILLED: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case CREATE_DASHBOARD_REJECTED: {
      return {
        ...state,
        createDashError: action.payload,
      };
    }
    case CLEAR_SC_DASH_DATA: {
      return {
        ...state,
        template: null,
        athleteList: [],
        cardData: [],
        chartData: [],
        group: null,
        maxType: 1,
        scDashData: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
