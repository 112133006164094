// component that represents the column seen on the righthand side of the workout component
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment-timezone';

import SessionSummaryDetails from './SessionSummaryDetails';
import PRBox from './PRBox';
import ImportedDataBox from './integrations/ImportedDataBox';
import {
  fetchSessionByDate,
  fetchImportedIntegrationsByDate,
} from '../../ducks/workoutsActions';

const RightColumnWrapper = styled('div')`
	display: flex;
	width: 30%;
	flex-direction: column;
  min-width: 236px;
  max-width: 390px;
  @media only screen and (max-width : 820px) {
    display: none;
  }
`;

const WorkoutsRightColumn = () => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);

  const dispatch = useDispatch();

  useEffect(() => {
    const formattedDay = activeWorkoutDate || moment().format('YYYY-MM-DD');
    const userId = currentUser.admin ? sharedAthlete.id : currentUser.id;

    if (Object.keys(currentUser).length && !currentUser.admin) {
      dispatch(fetchSessionByDate(currentUser, currentUser.id, formattedDay, {}));
    } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
      dispatch(fetchSessionByDate(currentUser, sharedAthlete.id, formattedDay, {}));
    }
    if (Object.keys(currentUser).length && userId) {
      dispatch(fetchImportedIntegrationsByDate(currentUser, userId, formattedDay));
    }
  }, [currentUser, sharedAthlete, activeWorkoutDate]);

  return (
    <RightColumnWrapper>
      <SessionSummaryDetails />
      <ImportedDataBox />
      <PRBox />
    </RightColumnWrapper>
  );
};

WorkoutsRightColumn.propTypes = { };

export default WorkoutsRightColumn;
