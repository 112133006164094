/** @jsx jsx */
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import { animated, useSpring } from 'react-spring/web.cjs';
import { useSelector } from 'react-redux';

import ProgramMenu from './ProgramMenu';
import WeightroomDatePicker from '../container/WeightroomDatePicker';

const WorkoutPanelHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  @media screen and (orientation: portrait) {
    padding: 20px 20px;
  }
`;

const PanelHeaderWrapper = styled('div')`
  .panel-header-animation {
    overflow: hidden;
  }
`;

const AnimatedWorkoutHeader = ({
  namespace,
}) => {
  const isWorkoutActive = useSelector((state) => state.weightRoomView[namespace].isWorkoutActive);
  const isWorkoutComplete = useSelector((
    state,
  ) => state.weightRoomView[namespace].isWorkoutComplete);

  const props = useSpring({
    to: { height: isWorkoutActive || isWorkoutComplete ? 0 : 76 },
    from: { height: 76 },
  });

  return (
    <>
      <PanelHeaderWrapper>
        <animated.div className='panel-header-animation' style={props}>
          <WorkoutPanelHeader>
            <WeightroomDatePicker
              namespace={namespace}
            />
            <ProgramMenu
              namespace={namespace}
            />
          </WorkoutPanelHeader>
        </animated.div>
      </PanelHeaderWrapper>
    </>
  );
};

AnimatedWorkoutHeader.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default AnimatedWorkoutHeader;
