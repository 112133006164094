import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

import useBoard from './hooks/useBoard';
import useWindowSize from '../../../shared/hooks/useWindowSize';

import BoardPodiums from './BoardPodiums';
import BoardTableWrapper from './BoardTableWrapper';
import Title from '../../../shared/components/Title/Title';

const BlankBoardContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

const BoardData = ({
  activeBoards,
  id,
}) => {
  const leaderboardData = useSelector(
    (state) => state.leaderboard.data.leaderboards[id].leaderboardData,
  );

  const windowSize = useWindowSize();

  return (
    leaderboardData.length !== 0 ? (
      <Fragment>
        <BoardPodiums
          activeBoards={activeBoards}
          leaderboardData={leaderboardData}
          windowSize={windowSize}
        />
        <BoardTableWrapper
          activeBoards={activeBoards}
          id={id}
          windowSize={windowSize}
        />
      </Fragment>
    ) : (
      <BlankBoardContainer>
        <Title
          icon='trophy'
        >
          No Records Found
        </Title>
      </BlankBoardContainer>
    )
  );
};

BoardData.propTypes = {
  activeBoards: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default BoardData;
