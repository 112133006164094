/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-tabs */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import TBSelectBox from '../../../../shared/components/TBSelectBox/TBSelectBox';

import {
  fetchAllLiftExercisesForAccount,
  fetchSimilarExercisesByExerciseId,
  submitOptOut,
  setOptOutExerciseTypeFilter,
  setOptOutWorkout,
  submitOptOutNonDate,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 50vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  position: absolute; 
  border-radius: 12px;
  left: 50%;
  top: 50%;
  right: 50%;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: white;
    z-index: 1001;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  .opt-out-button {
    background: #10cd8c;
  }
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ModalTextTop = styled('div')`
  font-family: 'Nunito Sans';
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: white;
  overflow-y: scroll;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  scrollbar-width: thin;
  .print-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  .custom-form {
    padding: 30px;
  }
  .note-text-area {
    padding: 10px;
    line-height: 1.5;
    border-radius: 5px;
    border: 1px solid #ccc;
    min-height: 100px;
    width: 100%;
    margin-top: 5px;
  }
`;

const SelectBoxContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  width: 100%;
  margin-bottom: 10px;
`;

const SelectBoxText = styled('div')`
  font-weight: 700;
  font-family: 'Nunito Sans';
  width: 100%;
  display: flex;
  align-items: center;
  color: grey;
  font-size: 14px;
`;

const FlipSwitchContainer = styled('div')`
	font-size: 12px;
  display: flex;
  margin-top: 10px;
  width: 100%;
  border-top: 1px solid black;
  .switch-line {
    height: 40px;
    width: 1px;
    background: black;
  }
`;

const SwitchLeft = styled('div')`
	width: 50%;
  height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.substitution === 'similar' ? '#FF8C00' : 'lightgrey')};
  cursor: pointer;
`;

const SwitchRight = styled('div')`
  font-weight: 700;
  width: 50%;
  height: 40px;
  display: flex;
  color: ${(props) => (props.substitution === 'all' ? '#FF8C00' : 'lightgrey')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NoSimilarText = styled('div')`
  font-weight: 700;
  font-family: 'Nunito Sans';
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  font-size: 14px;
  height: 42px;
`;

const INJURY = 1;
const PROHIBITED = 2;
const LACK_OF_EQUIPMENT = 3;
const OTHER = 5;

const injuredOption = {
  inputLabel: 'Injury',
  label: 'Injury',
  value: INJURY,
};

const prohibitedOption = {
  inputLabel: 'Prohibited',
  label: 'Prohibited',
  value: PROHIBITED,
};
const lackOfEquipmentOption = {
  inputLabel: 'Lack of Equipment',
  label: 'Lack of Equipment',
  value: LACK_OF_EQUIPMENT,
};
const otherOption = {
  inputLabel: 'Other',
  label: 'Other',
  value: OTHER,
};

const progressionOptions = [
  injuredOption,
  prohibitedOption,
  lackOfEquipmentOption,
  otherOption,
];

const optOutValues = {
  INJURY: injuredOption,
  PROHIBITED: prohibitedOption,
  'LACK OF EQUIPMENT': lackOfEquipmentOption,
  OTHER: otherOption,
};

/**
 * @param {Object} optOutWorkout an object representing the workout containing
 * data about the workout to be opted out from
 * @returns the modal content that contains the form through which the user
 * can opt-out of a workout through interaction with various elements and forms,
 * including a switch, dropdowns, and a field
 */
const OptOutModal = ({ optOutWorkout }) => {
  const [notes, setNotes] = useState('');
  const [optOutReason, setOptOutReason] = useState({});
  const [optOutExercise, setOptOutExercise] = useState({});

  const optOutAllExercises = useSelector((state) => state.workouts.data.optOutAllExercises);
  const similarExercisesForOptOut = useSelector(
    (state) => state.workouts.data.similarExercisesForOptOut,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const activeWorkoutDate = useSelector((state) => state.workouts.data.activeWorkoutDate);
  const optOutExerciseTypeFilter = useSelector(
    (state) => state.workouts.data.optOutExerciseTypeFilter,
  );
  const currentSelectedProgram = useSelector(
    (state) => state.workouts.data.currentSelectedProgram,
  );
  const currentNonDateDay = useSelector((state) => state.workouts.data.currentNonDateDay);

  const dispatch = useDispatch();

  useEffect(() => {
    if (optOutWorkout && Object.keys(optOutWorkout).length) {
      if (optOutValues[optOutWorkout.workoutObject.optedOutReason]) {
        setOptOutReason(optOutValues[optOutWorkout.workoutObject.optedOutReason]);
      } else if (!optOutWorkout?.workoutObject?.optedOutReason) {
        setOptOutReason({});
      }
      if (
        optOutWorkout?.workoutObject?.journalEntries
        && optOutWorkout?.workoutObject?.journalEntries[0]
      ) {
        setNotes(optOutWorkout?.workoutObject?.journalEntries[0].body);
      } else if (!optOutWorkout.workoutObject.journalEntries.length) {
        setNotes('');
      }
    }
  }, [optOutWorkout]);

  /**
   * when the component loads, we want to fetch the list of exercises that exist
   * within the account.
   * Also fetch exercises that are similar to the current workout based on
   * its ID
   * These lists are used in the dropdown selectors that exist within the form
   */
  useEffect(() => {
    if (Object.keys(currentUser).length && Object.keys(optOutWorkout).length) {
      dispatch(fetchAllLiftExercisesForAccount(currentUser.accountCode));
      dispatch(fetchSimilarExercisesByExerciseId(
        optOutWorkout?.workoutObject?.exercise?.id, currentUser.accountCode,
      ));
    }
  }, [optOutWorkout]);

  /**
   * When the user submits the form, this function pings the backend api
   */
  const submitOptOutRequest = () => {
    if (Object.keys(optOutReason).length) {
      if (optOutWorkout.workoutObject.assignedId) {
        dispatch(
          submitOptOut(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            activeWorkoutDate,
            optOutWorkout.workoutObject.assignedId,
            optOutReason.value,
            notes || '',
            optOutExercise ? optOutExercise.id : null,
            optOutWorkout.index,
            optOutWorkout.sessionIndex,
          ),
        );
      } else if (optOutWorkout.workoutObject.saveDataId) {
        dispatch(
          submitOptOutNonDate(
            currentUser,
            sharedAthlete.id,
            currentUser.accountCode,
            optOutWorkout.workoutObject.saveDataId,
            optOutReason.value,
            notes || '',
            optOutExercise ? optOutExercise.id : null,
            optOutWorkout.index,
            optOutWorkout.sessionIndex,
            currentSelectedProgram.dayBasedProgramId,
            currentNonDateDay.trueDayNum,
          ),
        );
      }
      setOptOutExercise({});
    }
  };

  return (
    <ModalContainer isActive={Object.keys(optOutWorkout).length}>
      <div className='remove-div'>
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='opt-out'
            color='black'
          />
          <ModalTextTop>Opt Out</ModalTextTop>
        </div>
        <div
          className='remove-div-icon'
          onClick={() => {
            dispatch(setOptOutWorkout({}));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='remove'
            color='black'
          />
        </div>
      </div>
      <ContentContainer>
        <SelectBoxContainer>
          <div style={{
            width: '100%',
            marginBottom: 10,
          }}
          >
            <SelectBoxText>Select Reason for Opt Out</SelectBoxText>
          </div>
          <TBSelectBox
            list={progressionOptions}
            selectItemFunction={setOptOutReason}
            label='label'
            headerWidthPercentage={100}
            dropDownHeightPx={150}
            defaultOption={optOutReason}
          />
        </SelectBoxContainer>
        {optOutWorkout?.workoutObject?.substitutionEnabled ? (
          <SelectBoxContainer>
            <SelectBoxText>Substitute with Another Exercise (Optional)</SelectBoxText>
            <FlipSwitchContainer>
              <SwitchLeft onClick={() => dispatch(setOptOutExerciseTypeFilter('similar'))} substitution={optOutExerciseTypeFilter}>SIMILAR</SwitchLeft>
              <div className='switch-line' />
              <SwitchRight onClick={() => dispatch(setOptOutExerciseTypeFilter('all'))} substitution={optOutExerciseTypeFilter}>ALL EXERCISES</SwitchRight>
            </FlipSwitchContainer>
          </SelectBoxContainer>
        ) : null}
        {optOutWorkout?.workoutObject?.substitutionEnabled ? optOutExerciseTypeFilter === 'all' ? (
          <TBSelectBox
            list={optOutAllExercises}
            label='name'
            headerWidthPercentage={100}
            dropDownHeightPx={150}
            selectItemFunction={setOptOutExercise}
          />
        ) : (
          similarExercisesForOptOut?.length ? (
            <TBSelectBox
              list={similarExercisesForOptOut}
              label='name'
              headerWidthPercentage={100}
              dropDownHeightPx={150}
              selectItemFunction={setOptOutExercise}
            />
          ) : <NoSimilarText>No Similar Exercises Available</NoSimilarText>
        ) : null}
        <div style={{
          marginTop: 10,
          width: '100%',
        }}
        >
          <SelectBoxText>Note/Reason for Opt Out</SelectBoxText>
        </div>
        <textarea onChange={(e) => setNotes(e.target.value)} value={notes} className='note-text-area' />
      </ContentContainer>
      <Button
        bottom
        fullWidth
        cta='submit'
        className='opt-out-button'
        large
        square
        noBorder
        primary
        onClick={() => {
          submitOptOutRequest();
        }}
      />
    </ModalContainer>
  );
};

OptOutModal.propTypes = {
  optOutWorkout: PropTypes.instanceOf(Object).isRequired,
};

export default OptOutModal;
