export const FETCH_REPORTABLE_GROUPS_START = 'FETCH_REPORTABLE_GROUPS_START';
export const FETCH_REPORTABLE_GROUPS_FULFILLED = 'FETCH_REPORTABLE_GROUPS_FULFILLED';
export const FETCH_REPORTABLE_GROUPS_REJECTED = 'FETCH_REPORTABLE_GROUPS_REJECTED';

export const FETCH_ATHLETE_PARTICIPATION_START = 'FETCH_ATHLETE_PARTICIPATION_START';
export const FETCH_ATHLETE_PARTICIPATION_FULFILLED = 'FETCH_ATHLETE_PARTICIPATION_FULFILLED';
export const FETCH_ATHLETE_PARTICIPATION_REJECTED = 'FETCH_ATHLETE_PARTICIPATION_REJECTED';

export const SET_ACTIVE_WEARABLES_DASH_DATE = 'SET_ACTIVE_WEARABLES_DASH_DATE';
export const SET_ACTIVE_ATHLETE = 'SET_ACTIVE_ATHLETE';
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const SET_ACTIVE_METRIC_TYPE = 'SET_ACTIVE_METRIC_TYPE';
export const SET_FILTERED_SEARCH_LIST = 'SET_FILTERED_SEARCH_LIST';
export const SET_FILTER_INFO_FROM_PIE_CHART = 'SET_FILTER_INFO_FROM_PIE_CHART';
export const SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL = 'SET_ACTIVE_WEARABLES_DASH_REDUX_MODAL';

export const FETCH_ATHLETES_TO_WATCH_START = 'FETCH_ATHLETES_TO_WATCH_START';
export const FETCH_ATHLETES_TO_WATCH_FULFILLED = 'FETCH_ATHLETES_TO_WATCH_FULFILLED';
export const FETCH_ATHLETES_TO_WATCH_REJECTED = 'FETCH_ATHLETES_TO_WATCH_REJECTED';

export const FETCH_ATHLETE_AVERAGES_START = 'FETCH_ATHLETE_AVERAGES_START';
export const FETCH_ATHLETE_AVERAGES_FULFILLED = 'FETCH_ATHLETE_AVERAGES_FULFILLED';
export const FETCH_ATHLETE_AVERAGES_REJECTED = 'FETCH_ATHLETE_AVERAGES_REJECTED';

export const CLEAR_ATHLETE_AVERAGES = 'CLEAR_ATHLETE_AVERAGES';
export const CLEAR_ACTIVE_ATHLETE = 'CLEAR_ACTIVE_ATHLETE';
export const CLEAR_WEARABLES_DATA_CHART_REPORT = 'CLEAR_WEARABLES_DATA_CHART_REPORT';
export const CLEAR_REPORTABLE_GROUPS = 'CLEAR_REPORTABLE_GROUPS';

export const FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START = 'FETCH_WEARABLES_REPORT_FOR_DATA_CHART_START';
export const FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED = 'FETCH_WEARABLES_REPORT_FOR_DATA_CHART_FULFILLED';
export const FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED = 'FETCH_WEARABLES_REPORT_FOR_DATA_CHART_REJECTED';

export const DOWNLOAD_WEARABLES_REPORT_START = 'DOWNLOAD_WEARABLES_REPORT_START ';
export const DOWNLOAD_WEARABLES_REPORT_FULFILLED = 'DOWNLOAD_WEARABLES_REPORT_FULFILLED';
export const DOWNLOAD_WEARABLES_REPORT_REJECTED = 'DOWNLOAD_WEARABLES_REPORT_REJECTED';
