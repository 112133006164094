/* eslint-disable max-len */
import React, { useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Shimmer from 'react-shimmer-effect';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import AthleteAvatarWithColorGradient from './AthleteAvatarWithColorGradient';
import Text from '../../../../shared/components/Text/Text';
import {
  setActiveAthlete, setActiveMetricType, fetchAthleteAverages, fetchWearablesReportForDataChartByGroupOrAthleteId, setFilterInfoFromPieChart,
} from '../../ducks/wearablesDashboardActions';

// pass in value and userInfo object
const SharedAthleteTileCard = ({
  athleteId, value, userFirstName, userLastName, userPic, gradientColors, userEmail,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const activeWearablesDashDate = useSelector((state) => state.wearablesDashboard.data.activeWearablesDashDate);
  const isAthletesToWatchLoading = useSelector((state) => state.wearablesDashboard.ui.isAthletesToWatchLoading);
  const ref = React.createRef();
  const toolTipRef = React.createRef();

  const [addOverFlowStyleClass, setAddOverFlowSytleClass] = useState(null);
  const [isOverFlowing, setIsOverFlowing] = useState(null);

  const [isToolTipOnBottom, setIsToolTipOnBottom] = useState(false);

  const handleMouseEnter = () => {
    if (addOverFlowStyleClass && toolTipRef.current.getBoundingClientRect().top < 45) {
      setIsToolTipOnBottom(true);
    }
  };

  const handleMouseLeave = () => {
    if (addOverFlowStyleClass) {
      setIsToolTipOnBottom(false);
    }
  };

  const generateAthleteData = () => {
    dispatch(setFilterInfoFromPieChart());

    const defaultMetricType = {
      id: 1,
      name: 'Exertion Score',
      rawValue: 1,
    };

    const athleteObj = {
      id: athleteId,
      first: userFirstName,
      last: userLastName,
      email: userEmail,
      pic: userPic,
    };

    const filterType = 3;
    dispatch(setActiveAthlete(athleteObj));

    /** default averages back to exertion score data */
    const metricTypeRawValue = 1;
    dispatch(setActiveMetricType(defaultMetricType));

    dispatch(fetchAthleteAverages(accountCode, athleteId, activeWearablesDashDate, filterType, metricTypeRawValue));

    const filterTypeForWearablesRawReport = 1;
    const filterIds = [athleteId];
    const csv = false;
    dispatch(fetchWearablesReportForDataChartByGroupOrAthleteId(accountCode, filterIds, activeWearablesDashDate, filterTypeForWearablesRawReport, csv));
  };

  useLayoutEffect(() => {
    if (ref?.current) {
      const h3elementtextwidthFirstName = ref.current.children[0].children[1].children[0].clientWidth;
      const h3elementWidthIncludingContentNotVisibleFirstName = ref.current.children[0].children[1].children[0].scrollWidth;

      const h3elementtextwidthLastName = ref.current.children[0].children[2].children[0].clientWidth;
      const h3elementWidthIncludingContentNotVisibleLastName = ref.current.children[0].children[2].children[0].scrollWidth;

      if ((h3elementtextwidthFirstName < h3elementWidthIncludingContentNotVisibleFirstName) || (h3elementtextwidthLastName < h3elementWidthIncludingContentNotVisibleLastName)) {
        setIsOverFlowing(true);
      }
    }

    if (ref?.current.clientWidth < ref.current.scrollWidth) {
      setAddOverFlowSytleClass(true);
    }
  }, [ref]);

  const getBackgroundColor = () => {
    if (value >= 0 && value <= 2) {
      return '#3fbfe1';
    }
    if (value > 2 && value <= 4) {
      return '#0DCC8A';
    }
    if (value > 4 && value <= 6) {
      return '#FFCB47';
    }
    if (value > 6 && value <= 8) {
      return '#FF8533';
    }
    if (value > 8 && value <= 10) {
      return '#FF0505';
    }

    return '#FF0505';
  };

  const Wrapper = styled('div')`
    cursor: pointer;

    dislay: flex;
    width: 83px;
    height: 80px;

    .tooltip {
      position: relative;
      opacity: 1;
      z-index: 10;
    }

    .tooltip .tooltiptexthidden {
      visibility: hidden;
      display: block;
      width: 120px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
      color: #444444;
      font-family: 'Nunito Sans';
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      border-radius: 6px;
      padding: 8px;
      position: absolute;
      z-index: 1;
      bottom: 105%;
      left: 50%;
      margin-left: -60px;
    }
    
    .tooltip .tooltiptext {
      visibility: hidden;
      display: block;
      width: 120px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
      color: #444444;
      font-family: 'Nunito Sans';
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      border-radius: 6px;
      padding: 8px;
      position: absolute;
      z-index: 1;
      bottom: 105%;
      left: 50%;
      margin-left: -60px;
    }
    
    .tooltip .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: white transparent transparent transparent;
    }
    
    .tooltip:hover .tooltiptext {
      visibility: ${isOverFlowing ? 'visible' : 'hidden'};
    }
    .tooltiptextBottom {
      visibility: hidden;
      width: 120px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 1px rgb(48 49 51 / 5%), 0px 4px 8px rgb(48 49 51 / 10%);
      color: #444444;
      font-family: 'Nunito Sans';
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      border-radius: 6px;
      padding: 8px;
      position: absolute;
      z-index: 1;
      top: 105%;
      left: 50%;
      margin-left: -60px;
    }
    
     .tooltiptextBottom::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }
    
    :hover .tooltiptextBottom {
      visibility: visible;
    }
  `;
  const ColorBlock = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 83px;
    // height: 69px;
    height: 80px;
    background: ${getBackgroundColor()};
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 4px 8px rgba(48, 49, 51, 0.1);
    border-radius: 12px;
  `;

  const PlaceholderShimmerBlock = styled('div')`
    width: 83px;
    height: 69px;
    border-radius: 12px;
    `;

  const EllipseToolTipWrap = styled('div')`
      display: flex;
      justify-content: center;
      width: 75px;

      h3 {
        display: block;
        margin-bottom: 0;
        font-family: 'Nunito Sans';
        color: white;
        font-size: 11px;
        font-weight: 700;
        white-space: nowrap; 
        }
        .overFlow {
         overflow: hidden;
         text-overflow: ellipsis;
        }
        .overFlowEmpty {
        }

  `;

  return (
    <Wrapper ref={ref} onClick={() => generateAthleteData()}>
      {isAthletesToWatchLoading ? (
        <Shimmer>
          <PlaceholderShimmerBlock />
        </Shimmer>
      ) : (
        <ColorBlock className='tooltip' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <AthleteAvatarWithColorGradient avatar={userPic} size={18} gradientColors={gradientColors} />
          <EllipseToolTipWrap>
            <h3 style={{ paddingTop: '2px' }} className={addOverFlowStyleClass ? 'overFlow' : 'overFlowEmpty'}>{`${userFirstName}`}</h3>
          </EllipseToolTipWrap>
          <EllipseToolTipWrap>
            <h3 className={addOverFlowStyleClass ? 'overFlow' : 'overFlowEmpty'}>{`${userLastName}`}</h3>
          </EllipseToolTipWrap>
          <Text fontSize={14} color='white' fontWeight={700}>{value}</Text>
          <span style={{ visibility: 'hidden' }} className={isToolTipOnBottom ? 'tooltiptextBottom' : 'tooltiptexthidden'} ref={toolTipRef}>{`${userFirstName} ${userLastName}`}</span>
          {addOverFlowStyleClass && <span className={isToolTipOnBottom ? 'tooltiptextBottom' : 'tooltiptext'} ref={toolTipRef}>{`${userFirstName} ${userLastName}`}</span>}
        </ColorBlock>
      )}
    </Wrapper>
  );
};

SharedAthleteTileCard.propTypes = {
  athleteId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  userPic: PropTypes.string.isRequired,
  gradientColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  userEmail: PropTypes.string.isRequired,
};

SharedAthleteTileCard.defaultProps = {
};

export default SharedAthleteTileCard;
