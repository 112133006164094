/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../../shared/components/Text/Text';
import Avatar from '../../../../../shared/components/Avatar/Avatar';
import ProfilePicUploadButton from '../../../../../shared/components/ProfilePicUploadButton';
import Spinner from '../../../../../shared/components/Spinner/Spinner';

const AvatarAndTitleComponent = ({ containerWidth }) => {
  const dispatch = useDispatch();
  const isOrganizationSettingsLoading = useSelector((state) => state.auth.ui.isOrganizationSettingsLoading);
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const isLogoImageUpdateLoading = useSelector((state) => state.auth.ui.isLogoImageUpdating);
  const imageError = useSelector((state) => state.auth.ui.imageError);

  const isContentLoading = isOrganizationSettingsLoading || Object.keys(organizationSettings).length === 0;

  const AvatarContentContainerColumn = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
  const PageTitleContainer = styled('div')`
text-align: ${containerWidth && containerWidth < 400 ? 'center' : 'start'};
padding-bottom: 30px;
`;
  const IconContainer = styled('div')`
position: absolute;
top: 0px;
right: 0px;
  @media (min-width: 800px) {
    display: none;
  }
  @media (max-width: 800px) {
    top: 8px;
    right: 10px;
  }
`;

  return (
    <>
      {isContentLoading ? (<Spinner />) : (
        <>
          <IconContainer onClick={() => { dispatch(push('/settings')); }}>
            <IcomoonReact
              iconSet={iconSet}
              size={28}
              icon='settings'
              color='#444444'
            />
          </IconContainer>
          <PageTitleContainer>
            <Text fontSize={containerWidth && containerWidth < 400 ? 28 : 34} fontWeight='400'>Organization Settings</Text>
          </PageTitleContainer>
          <AvatarContentContainerColumn>
            {isLogoImageUpdateLoading ? (
              <div style={{ width: 150, height: 150 }}>
                <Spinner />
              </div>
            ) : (
              <Avatar avatar={organizationSettings?.skin?.logo} size={146} borderWidth={5} borderColor={organizationSettings?.skin?.primaryColor || '#E5E5E5'} backgroundColor={organizationSettings?.skin?.primaryColor || '#444444'} scaled>
                <div style={{ position: 'absolute', top: -21, right: 0 }}>
                  <ProfilePicUploadButton isLogo />
                </div>
              </Avatar>
            )}
            <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
              {`${organizationSettings?.organization?.name} `}
            </Text>
            <Text fontSize={18} fontWeight='400'>Organization Settings</Text>
            {imageError ? (
              <div className='error-message'>{imageError}</div>
            ) : null}
          </AvatarContentContainerColumn>
        </>

      )}
    </>
  );
};

AvatarAndTitleComponent.propTypes = {
  containerWidth: PropTypes.number,
};

AvatarAndTitleComponent.defaultProps = {
  containerWidth: '',
};

export default AvatarAndTitleComponent;
