import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setResellerOverlay } from '../../../ducks/resellerActions';

const Overlay = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
`;

const ResellerOverlay = () => {
  const overlayState = useSelector((state) => state.reseller.data.activeOverlay);
  const dispatch = useDispatch();
  const setOverlay = (boolean) => {
    dispatch(setResellerOverlay(boolean));
  };
  return (
    <>
      { overlayState && (
      <Overlay onClick={() => setOverlay(false)} />)}
    </>
  );
};

export default ResellerOverlay;
