import React from 'react'
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FieldArray, FastField } from 'formik';

import CompletionEntry from './WorkoutEntriesCompletion';
import { TransitionGlobal, ColorGreen } from '../../../../shared/GlobalStyles';

const WorkoutTableContainer = styled('div')`
`;

const WorkoutTableColumn = styled('div')`
`;

const EntryTitles = styled('div')`
  display: flex;
  height: 38px;
  align-items: center;
  min-width: 90px;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  align-self: center; 
`;

const FieldsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  .form-control {
  }
`;

const FieldsWrapper = styled('div')`
  display: flex;
`;

const SupersetIndicator = styled('div')`
  position: absolute;
  border: 1px solid ${ColorGreen};
  display: block;
  height: 
  ${(props) => {
    const isEntryOrCompletion = props.completion
      ? `calc(${props.entriesViewHeight + 5}px - 46px)`
      : (`${props.entriesViewHeight + 5}px`);

    return (
      props.entriesViewHeight
        ? isEntryOrCompletion
        : '144px'
    );
  }};
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-top: 1px;
  border-radius: 0.25em;
  transition: ${TransitionGlobal};
  box-shadow: 0 0 0 0.2rem rgba(0, 214, 139, 0.25);
  opacity: ${(props) => (props.activeSuperset ? '1' : '0')};
`;

const StaticText = styled('p')`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 38px;
  margin: 0px;
  justify-content: center;
  align-items: center;
`;

const WorkoutEntryTable = ({
  entriesViewHeight,
  formProps,
  handleInfoClick,
  isWrvSupersetsEnabled,
  supersetWorkout,
  workout,
}) => (
  <WorkoutTableContainer>
    <div className='entry_row'>
      <FieldsWrapper>
        <FieldsContainer
          className='scrollable-field-container'
        >
          {/*
            Create a column of row labels based on the "columns" array.
            (they are really rows not columns)
          */}
          <WorkoutTableColumn>
            <div className='entry_row'>
              <EntryTitles>
                <div className='title'>
                  Set
                </div>
              </EntryTitles>
            </div>
            {workout.new_version.columns.map((column) => (
              <div className='entry_row'>
                <EntryTitles>
                  <div className='title'>
                    {column.valueDescription}
                  </div>
                </EntryTitles>
              </div>
            ))}
          </WorkoutTableColumn>

          {/*
            For the rest of the columns, loop through the data array for each column or set.
          */}
          {workout.new_version.data.map((set, i) => (
            <WorkoutTableColumn>
              <div className='entry_row'>
                <div
                  className='form-group'
                >
                  <SupersetIndicator
                    activeSuperset={
                      (isWrvSupersetsEnabled && supersetWorkout)
                        && (supersetWorkout.completed_sets === i)
                    }
                    entriesViewHeight={entriesViewHeight}
                    // completion={completion}
                  />
                  <StaticText>
                    {/* {name === 'perc' && (`${set.assigned_perc}%`)} */}
                    {i + 1}
                  </StaticText>
                </div>
              </div>
              <FieldArray
                name='values'
                render={() => (
                  // Loop through the values array in each column
                  set.values.map((setValue, index) => (
                    <div className='entry_row'>
                      <div className='form-group'>
                        {!setValue.readOnly ? (
                          <FastField
                            className='form-control'
                            name={`result[${i}].values[${index}].value`}
                            // onBlur={handleBlur}
                            placeholder={setValue.placeholder}
                            tabIndex={i + 1}
                            type={setValue.valueType === 'STRING' ? 'text' : 'number'}
                            step={setValue.valueType === 'INTEGER' ? '1' : 'any'}
                          />
                        ) : (
                          <StaticText>
                            {setValue.value}
                          </StaticText>
                        )}
                      </div>
                    </div>
                  ))
                )}
              />
            </WorkoutTableColumn>
          ))}
        </FieldsContainer>
      </FieldsWrapper>
    </div>
    {workout.new_version.completionCheckbox && (
      <CompletionEntry
        handleInfoClick={handleInfoClick}
        handleSubmit={formProps.handleSubmit}
        info={workout.attributes.info}
        setFieldValue={formProps.setFieldValue}
        // subHeader={workout.sub_header}
        values={formProps.values}
      />
    )}
  </WorkoutTableContainer>
);

WorkoutEntryTable.propTypes = {
  handleInfoClick: PropTypes.func.isRequired,
  workout: PropTypes.instanceOf(Object).isRequired,
  entriesViewHeight: PropTypes.number.isRequired,
  formProps: PropTypes.instanceOf(Object).isRequired,
  isWrvSupersetsEnabled: PropTypes.bool.isRequired,
  supersetWorkout: PropTypes.instanceOf(Object).isRequired,
};

export default WorkoutEntryTable;
