import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';

import { ColorBlack, Nunito } from '../../../../shared/GlobalStyles';

const DateStackedContainer = styled('div')`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  
  span {
    line-height: 1.1em;
  }
`;

const LargeDate = styled('span')`
  font-size: 26px;
  font-family: ${Nunito};
  font-weight: 900;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
`;

const MediumDate = styled('span')`
  font-size: 14px;
  font-family: ${Nunito};
  font-weight: 900;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
  letter-spacing: 1.6px;
`;

const SmallDate = styled('span')`
  font-size: 12px;
  font-family: ${Nunito};
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
`;

const DateStacked = (props) => {
  const { date } = props;
  const day = moment(date).format('DD');
  const month = moment(date).format('MMM');
  const dayOfTheWeek = moment(date).format('ddd');

  return (
    <DateStackedContainer className='date-stacked'>
      <LargeDate>
        {day}
      </LargeDate>
      <MediumDate>
        {month}
      </MediumDate>
      <SmallDate>
        {dayOfTheWeek}
      </SmallDate>
    </DateStackedContainer>
  );
};

DateStacked.propTypes = {
  date: PropTypes.string.isRequired,
};

export default DateStacked;
