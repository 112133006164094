import React, { useRef, useEffect, useState } from 'react';
import { animated, useTransition } from 'react-spring';
import styled from '@emotion/styled';

import moment from 'moment';
import { useTheme } from 'emotion-theming';
import { useDispatch } from 'react-redux';
import IcomoonReact from 'icomoon-react';
import Logger from 'js-logger';
import Linkify from 'react-linkify';

import Button from '../../../../shared/components/Button/Button';
import optimizeImage from '../../../../shared/utils/imageOptimizer';
import Text from '../../../../shared/components/Text/Text';

import { TransitionGlobal } from '../../../../shared/GlobalStyles';

import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  openMessageVideo,
  openImageLightbox,
  setActiveReduxModal,
  setSupportModal,
} from '../../ducks/conversationsActions';

const imageOptimizer = (friendPic) => optimizeImage(
  friendPic,
  {
    resize: {
      width: 60,
      height: 60,
      fit: 'contain',
    },
  },
);

const UserMessage = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: ${(props) => `url(${props.userProfilePic})`};
    background-size: contain;
    margin-right: 10px;
  }
  .message-text {
    display: flex;
    flex-direction: column;
    color: white;
    background: #77A7FF;
    padding: 9px;
    border-radius: 20px;
    margin-right: 10px;
    overflow-wrap: anywhere;
    margin-left: 50px;
    max-width: 500px;
    white-space: break-spaces;

    a {
      font-size: 14px;
      color: white;
      text-decoration: underline
    }
  }
`;

const ActionButtonContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: ${TransitionGlobal};


  button {
    border-radius: 50%;
    background-color: #f1f1f1;
    padding: 6px;
    margin-right: 15px;
  }
`;

const MessageText = styled('div')`
  display: flex;
  align-items: center;
  color: transparent;
  background: ${(props) => (props.friend ? '#77A7FF' : '#ff6600')};
  padding: 9px;
  border-radius: 20px;
  margin-right: ${(props) => (props.friend ? '50px' : '10px')};
  overflow-wrap: anywhere;
  margin-left: ${(props) => (props.friend ? '10px' : '50px')};

  max-width: 500px;
  white-space: break-spaces;
`;

const UserPic = styled('div')`
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50%;
  background-image: ${(props) => `url(${props.userProfilePic})`};
  background-size: contain;
  ${(props) => (props.friend ? 'margin-left: 10px;' : 'margin-right: 10px;')}
`;

const FriendMessage = styled('div')`
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  overflow-wrap: normal;
  padding-bottom: min(7px);
  margin: 10px 0px;

  .userpic {
    height: 40px;
    width: 40px;
    min-width: 40px;
    border-radius: 50%;
    background-image: ${(props) => `url(${imageOptimizer(props.props.userPic)})`};
    background-size: contain;
    margin-left: 10px;
  }
  .message-text {
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.colors.text};
    background: ${(props) => props.theme.colors.background[5]};
    /* background: #77A7FF; */
    padding: 9px;
    border-radius: 20px;
    margin-left: 10px;
    overflow-wrap: anywhere;
    margin-right: 50px;
    max-width: 500px;
    white-space: break-spaces; 

    a {
      font-size: 14px;
      color: ${(props) => props.theme.colors.text};
      text-decoration: underline
    }
  }
`;

const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.friendMessage ? 'flex-start' : 'flex-end')};
  .latest-time {
    ${(props) => (props.friendMessage ? (
    'margin-left: 10px;'
  ) : (
    'margin-right: 10px;'
  ))}
    margin-top: 5px;
  }
  .action-button-container {
    opacity: 0;
  }
  &:hover {
    .action-button-container {
      opacity: 1;
    }
  }
`;

const MessageBoxWithButtons = styled('div')`
  display: block;
  position: relative;
  
  .action-button-container {
    opacity: 0;
  }

  &:hover {
    .action-button-container {
      opacity: 1;
    }
  }
`;

const UserMessageMedia = styled('div')`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* margin-top: ${(props) => {
    if (!props.props.messageBody) {
      return '-40px';
    }
    return '5px';
  }}; */
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const FriendMessageMedia = styled('div')`
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  height: 200px;
  width: 200px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  min-height: 30px;
  background-image: ${(props) => {
    if (props.props.media.type === 'VIDEO') {
      return `url(${props.props.media.thumbnailUrl})`;
    }
    return `url(${props.props.media.mediaUrl})`;
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #ced4da;
  cursor: pointer;
  border-radius: 10px;
`;

const StatusIcons = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  align-items: flex-end;

  svg {
    margin-left: 5px;
  }
`;

const MessagesByDay = ({
  currentUser,
  day,
  dayMessages,
  isInitialMessagesFetch,
}) => {
  const [userProfilePic, setUserProfilePic] = useState(null);

  const messagesRef = useRef([]);

  const dispatch = useDispatch();

  const theme = useTheme();

  useEffect(() => {
    if (Object.keys(currentUser).length) {
      const pic = optimizeImage(
        currentUser.pic,
        {
          resize: {
            width: 60,
            height: 60,
            fit: 'contain',
          },
        },
      );
      setUserProfilePic(pic);
    }
  }, [currentUser]);

  // To add the dynamic height of each message to the animation, we rebuild
  // the conversationMessages array with an index key value and use that to access
  // the correct message ref from the messagesRef array to get the clientHeight.
  // We also use async await next to make sure the clientHeight isn't undefined before
  // we access it. The refs are assigned in the currentMessageMapper.
  const textAnimation = useTransition(
    dayMessages.map((message, index) => ({ ...message, index })),
    (item) => (item.key || item.id),
    {
      from: {
        height: 0, opacity: 0, flexShrink: 0, margin: '10px 0px',
      },
      enter: ({ index }) => async (next) => (
        await next({
          height: messagesRef.current[index].clientHeight,
          opacity: 1,
          margin: '10px 0px',
        })
      ),
      leave: { height: 0, opacity: 0 },
    },
  );

  return (
    <>
      {textAnimation.map(({ item, key, props }, i) => {
        const newMoment = moment.tz(item.sentAt, currentUser.userTimeZone
          ? currentUser.userTimeZone
          : currentUser.userSettings.timeZone
        );
        const returnMoment = newMoment.format('hh:mm A');
        // messagesByDay[dayMessages].map(({ item, key, props }, index) => {
        if (item.userId === currentUser.id) {
          return (
          // Each message assigns their ref in the messagesRef array.
            <animated.div style={isInitialMessagesFetch ? null : props}>
              <UserMessage
                userProfilePic={item.userPic}
                ref={(el) => messagesRef.current[i] = el}
                props={item}
              >
                <MessageContainer>
                  {item.messageBody ? (
                    <Linkify componentDecorator={(decoratedHref, decoratedText, linkKey) => (
                      <a href={decoratedHref} key={linkKey} target='_blank' rel='noreferrer'>{decoratedText}</a>
                    )}
                    >
                      <div className='message-text'>{item.messageBody}</div>
                    </Linkify>
                  ) : null}
                  {item.media ? (
                    <UserMessageMedia
                      props={item}
                      onClick={() => {
                        if (item.media.type === 'IMAGE' || item.media.type === 'EXTERNAL_URL') {
                          dispatch(openImageLightbox(item.media.mediaUrl));
                        }
                      }}
                    >
                      {item.media.type === 'VIDEO' ? (
                        <div
                          onClick={() => {
                            dispatch(openMessageVideo(item.media.mediaUrl));
                          }}
                          onKeyPress={() => {
                            dispatch(openMessageVideo(item.media.mediaUrl));
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '50%',
                            width: '50%',
                            borderRadius: '50%',
                            cursor: 'pointer',
                            background: 'rgba(255, 255, 255, 0.4)',
                            paddingLeft: '5px',
                          }}
                          role='button'
                          tabIndex='0'
                        >
                          <IcomoonReact
                            iconSet={iconSet}
                            size={50}
                            icon='play-filled'
                            color={theme.colors.text}
                          />
                        </div>
                      ) : null}
                    </UserMessageMedia>
                  ) : null}
                  <StatusIcons>
                    {item.sendEmail ? (
                      <IcomoonReact
                        iconSet={iconSet}
                        size={15}
                        icon='envelope-closed'
                        color={theme.colors.lightText[0]}
                      />
                    ) : null}
                    {item.sendSMS ? (
                      <IcomoonReact
                        iconSet={iconSet}
                        size={15}
                        icon='phone'
                        color={theme.colors.lightText[0]}
                      />
                    ) : null}
                    <Text
                      color={theme.colors.lightText[0]}
                      fontSize={10}
                      className='latest-time'
                    >
                      {returnMoment}
                    </Text>
                  </StatusIcons>
                </MessageContainer>
                <div className='userpic' />
              </UserMessage>
            </animated.div>
          );
        }
        return (
          <animated.div style={isInitialMessagesFetch ? null : props}>
            <FriendMessage ref={(el) => messagesRef.current[i] = el} props={item}>
              <div className='userpic' />
              <MessageContainer friendMessage>

                <MessageBoxWithButtons>
                  {item.messageBody ? (
                    <>
                      <Linkify componentDecorator={(decoratedHref, decoratedText, linkKey) => (
                        <a href={decoratedHref} key={linkKey} target='_blank' rel='noreferrer'>{decoratedText}</a>
                      )}
                      >
                        <div className='message-text'>{item.messageBody}</div>
                      </Linkify>
                      <ActionButtonContainer className='action-button-container'>
                        <Button
                          icon='warning'
                          iconOnly
                          rounded
                          onClick={() => {
                            dispatch(setSupportModal(item.id));
                          }}
                        />
                      </ActionButtonContainer>
                    </>
                  ) : null}
                </MessageBoxWithButtons>

                {item.media ? (
                  <FriendMessageMedia
                    props={item}
                    onClick={() => {
                      if (item.media.type === 'IMAGE' || item.media.type === 'EXTERNAL_URL') {
                        dispatch(openImageLightbox(item.media.mediaUrl));
                      }
                    }}
                  >
                    {item.media.type === 'VIDEO' ? (
                      <div
                        onClick={() => {
                          dispatch(openMessageVideo(item.media.mediaUrl));
                        }}
                        onKeyPress={() => {
                          dispatch(openMessageVideo(item.media.mediaUrl));
                        }}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '50%',
                          width: '50%',
                          borderRadius: '50%',
                          cursor: 'pointer',
                          background: 'rgba(255, 255, 255, 0.4)',
                          paddingLeft: '5px',
                        }}
                        role='button'
                        tabIndex='0'
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={50}
                          icon='play-filled'
                          color={theme.colors.text}
                        />
                      </div>
                    ) : null}
                  </FriendMessageMedia>
                ) : null}
                <Text
                  color={theme.colors.lightText[0]}
                  fontSize={10}
                  className='latest-time'
                >
                  {`${item.userFirstName} ${item.userLastName} - ${returnMoment}`}
                </Text>
              </MessageContainer>
            </FriendMessage>
          </animated.div>
        );
      })}
    </>
  );
};

export default MessagesByDay;
