import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import moment from 'moment';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const ToolTip = styled('span')`
    visibility: hidden;
    width: 250px;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 12px;
    padding: 10px 0;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    display: flex;
    justify-content: center;
    
    ::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
    }
`;

const Wrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: center;
    :hover > ${ToolTip} {
      visibility: visible;
    }
`;

const IconWrapper = styled('div')`
  padding-left: 5px;
  cursor: pointer;
`;

const DateText = styled('div')`
  margin-top: 3px;
  margin-right: 5px;
`;

const LastWorkoutCell = ({
  date,
  userId,
}) => {
  const cellText = () => {
    if (!date) {
      return 'No workouts scheduled';
    }
    const diff = moment(date).startOf('day').diff(moment().startOf('day'), 'days');
    if (diff === 0) {
      return 'Today';
    }
    if (diff === 1) {
      return 'Tomorrow';
    }
    return `In ${diff} days`;
  };
  const toolTip = () => {
    if (!date) {
      return 'No workouts scheduled';
    }
    return moment(date).format('ddd. MMM. DD, YYYY');
  };
  return (
    <Wrapper data-tip={toolTip()}>
      <DateText>
        {cellText()}
      </DateText>
      {date && (
      <IconWrapper
        onClick={() => window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT === 'STAGING' ? 'staging.' : ''}app.teambuildr.com`}/calendar/A${userId}/${date}`, '_blank')}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={22}
          icon='circle-arrow'
        />
      </IconWrapper>
      )}
      <ToolTip>{toolTip()}</ToolTip>
    </Wrapper>
  );
};

LastWorkoutCell.propTypes = {
  date: PropTypes.instanceOf(String).isRequired,
  userId: PropTypes.instanceOf(Number).isRequired,
};

export default LastWorkoutCell;
