/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import {
  BoxShadow,
  ColorWhite,
} from '../../../shared/GlobalStyles';
import SelectBox from '../../../shared/_TBComponents/SelectBox';
import Filter from '../../../shared/_TBComponents/DashboardFilter';
import DatePicker from '../../../shared/_TBComponents/DatePicker';
import Button from '../../../shared/_TBComponents/Button';
import { dashboardTracker } from '../../../shared/utils/amplitudeHelper';
import {
  clearAthleteHistoryData,
  fetchGroupAnalysisData,
  fetchOverviewData,
  fetchUserHistoryData,
  fetchUsersAssociatedWithGroup,
  setAthleteRadioValue,
  setAthletesToReview,
  setFlaggedAthletesView,
  setGroup,
  setPrintCurrentPage,
  setRecoveryRadarActiveModal,
  setRecoveryRadarView,
  setSelectedAthlete,
  setSelectedDateCal,
  setSelectedDateData,
  setShowFlaggedAthletesModal,
} from '../ducks/RecoveryRadarActions';
import RROverviewChart from './RROverviewChart';
import CardsContainer from './CardsContainer';
import RROverviewTableNoData from './RROverviewTableNoData';
import FilterHelper from './filterHelper';
import BodyHeatMapWithButtonWrapper from './BodyHeatMapWithButtonWrapper';
import RROverviewTable from './RROverviewTable';
import FlaggedAthletesModal from '../../force-plate-dashboard/components/presentational/FlaggedAthletesModal';

const Container = styled('div')`
  display: flex;
  flex-direction: column;

  @media print {
    transform: scale(0.50);
    transform-origin: top left;
  }
`;

const ChartAndBodyContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
`;

const ChartContainer = styled('div')`
  background-color: ${ColorWhite};
  border-radius: 10px;
  box-shadow: ${BoxShadow};
  display: flex;
  flex: 1 1 60%;
  flex-direction: column;
  margin: 15px 15px 0px 15px;
  max-width: 100%;
  min-width: 610px;
  padding: 16px;
  position: relative;
`;

const OptionsHeader = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const MenuOptionsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const DateOptionsContainer = styled('div')`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  .printBtn {
    border: 1px solid #808080;
    border-radius: 50%;
    height: 35px;
    margin-left: 10px;
    padding: 3px;
    width: 35px;
  }
`;

const NoDataModal = styled('div')`
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: -45px 0px 0px -182px;
  height: 119px;
  width: 400px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  font-family: 'Nunito Sans';
  font-size: 19px;
  color: #424242;
  padding: 15px;
`;

const ModalTitle = styled('div')`
  font-weight: bold;
`;

const ModalMessage = styled('div')`
  font-weight: 300;
`;

const Overview = () => {
  const accountCode = useSelector((state) => state.auth.data.currentUser.accountCode);
  const activeFilters = useSelector((state) => state.recoveryRadar.data.activeFilters);
  const activeFiltersOverview = useSelector(
    (state) => state.recoveryRadar.data.activeFiltersOverview,
  );
  const activeTypeFilters = useSelector((state) => state.recoveryRadar.data.activeTypeFilters);
  const athletesToReview = useSelector((state) => state.recoveryRadar.data.athletesToReview);
  const chartData = useSelector((state) => state.recoveryRadar.data.chartData);
  const dateRange = useSelector((state) => state.recoveryRadar.data.dateRange);
  const group = useSelector((state) => state.recoveryRadar.data.group);
  const groups = useSelector((state) => state.recoveryRadar.data.groups);
  const heatMapData = useSelector((state) => state.recoveryRadar.data.heatMapData);
  const isOverviewDataLoading = useSelector(
    (state) => state.recoveryRadar.ui.isOverviewDataLoading,
  );
  const isFilterOpen = useSelector((state) => state.recoveryRadar.ui.isFilterOpen);
  const isFlaggedAthletesModalShowing = useSelector(
    (state) => state.recoveryRadar.ui.isFlaggedAthletesModalShowing,
  );
  const numOfAlertUsers = useSelector((state) => state.recoveryRadar.data.numOfAlertUsers);
  const overviewGroupAverageData = useSelector(
    (state) => state.recoveryRadar.data.overviewGroupAverageData,
  );
  const overviewTableData = useSelector((state) => state.recoveryRadar.data.overviewTableData);
  const printCurrentPage = useSelector((state) => state.recoveryRadar.data.printCurrentPage);
  const selectedAthlete = useSelector((state) => state.recoveryRadar.data.selectedAthlete);
  const selectedDateCal = useSelector((state) => state.recoveryRadar.data.selectedDateCal);
  const selectedDateData = useSelector((state) => state.recoveryRadar.data.selectedDateData);

  const hasChartData = chartData?.length > 0;
  const hasTableData = overviewTableData?.length > 0;

  // Pull out the active filter IDs
  const filterIds = activeFilters.map((obj) => obj.id);
  const typeIds = activeTypeFilters.map((obj) => obj.id);

  const {
    handleActionButton,
    handleChangeOverview,
    handleChangeType,
    handleClearOverview,
    handleClose,
    handleFilter,
    options,
    searchTerm,
    setSearchTerm,
    typeOptions,
  } = FilterHelper();

  const printRef = useRef();

  const dispatch = useDispatch();

  const setSelectedGroup = (option) => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Overview - Group Picker', 'Group selected');
    batch(() => {
      // Set group
      dispatch(setGroup(option));
      // Clear any athlete data
      dispatch(setSelectedAthlete(null));
      dispatch(clearAthleteHistoryData());
      // Fetch athlete list
      dispatch(fetchUsersAssociatedWithGroup(accountCode, option?.id));
      // Fetch all the data
      dispatch(fetchOverviewData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        option?.id,
        moment(selectedDateData).subtract(27, 'd').format('YYYY-MM-DD'),
        selectedDateData,
        typeIds,
        filterIds,
      ));
    });
  };

  const setDate = (date) => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Overview - Date Picker', 'Date selected');
    batch(() => {
      // Set dates
      dispatch(setSelectedDateCal(moment(date)));
      dispatch(setSelectedDateData(moment(date).format('YYYY-MM-DD')));
      // Fetch all the data
      dispatch(fetchOverviewData(
        accountCode,
        group?.id,
        moment(date).subtract(dateRange - 1, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        [],
        [],
      ));
      dispatch(fetchGroupAnalysisData(
        accountCode,
        group?.id,
        moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
        moment(date).format('YYYY-MM-DD'),
        typeIds,
        filterIds,
      ));
      if (selectedAthlete?.id) {
        dispatch(fetchUserHistoryData(
          accountCode,
          selectedAthlete?.id,
          moment(date).subtract(27, 'd').format('YYYY-MM-DD'),
          moment(date).format('YYYY-MM-DD'),
          typeIds,
          filterIds,
        ));
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
        .printable-container {
          transform: scale(0.50);
          transform-origin: top left;
        }
      }
    `,
  });

  useEffect(() => {
    if (athletesToReview?.length > 0) {
      dispatch(setShowFlaggedAthletesModal(true));
    } else {
      dispatch(setShowFlaggedAthletesModal(false));
    }
  }, [athletesToReview]);

  const handleDismiss = () => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Overview - Flagged Athletes', 'Flagged athletes dismissed');
    batch(() => {
      dispatch(setShowFlaggedAthletesModal(false));
      dispatch(setAthletesToReview([]));
      dispatch(setAthleteRadioValue('all'));
    });
  };

  // Pass along the flagged athletes array and any necessary data to display to group analysis view
  const handleReview = () => {
    // Amplitude tracking
    dashboardTracker('Recovery Radar', 'Overview - Flagged Athletes', 'Review flagged athletes clicked');
    batch(() => {
      dispatch(setSelectedAthlete(null));
      dispatch(setFlaggedAthletesView(true));
      dispatch(setAthleteRadioValue('alerts'));
      dispatch(setShowFlaggedAthletesModal(false));
      dispatch(setRecoveryRadarView('analysis'));
    });
  };

  useEffect(() => {
    if (printCurrentPage) {
      handlePrint();
      batch(() => {
        dispatch(setRecoveryRadarActiveModal(null));
        dispatch(setPrintCurrentPage(false));
      });
    }
  }, [printCurrentPage]);

  const [columnHeaderHeight, setColumnHeaderHeight] = useState(null);
  const containerWidth = 1000;

  return (
    <Container classname='printable-container' ref={printRef}>
      <ChartAndBodyContainer classname='chart-body-container'>
        <ChartContainer classname='chart-container'>
          <OptionsHeader>
            <MenuOptionsContainer>
              <SelectBox
                defaultValue={group}
                options={groups}
                onChangeFunction={setSelectedGroup}
                placeholder='Select a Group'
                removeSeparator
                styledProps={{
                  position: 'relative',
                  margin: '5px 10px 5px 0px',
                  height: null,
                }}
                width='175px'
              />
              <Filter
                actionButtonName='Update'
                activeFilters={activeFiltersOverview}
                activeTypeFilters={activeTypeFilters}
                disableOptions
                filterLimit={3}
                filterTitle='Type'
                handleActionButton={handleActionButton}
                handleChange={handleChangeOverview}
                handleChangeType={handleChangeType}
                handleClear={handleClearOverview}
                handleClose={handleClose}
                handleFilter={handleFilter}
                isArrayOfObjects
                isOpen={isFilterOpen}
                isSearchable
                options={options}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                styledPropsWrapper={{
                  marginTop: '6px',
                }}
                subheader='Area Selected'
                typeOptions={typeOptions}
              />
            </MenuOptionsContainer>
            <DateOptionsContainer>
              <DatePicker
                placeholderText='Select Date'
                setStartDate={setDate}
                startDate={selectedDateCal.toDate()}
              />
              <Button
                disabled={!hasChartData}
                icon='printer'
                iconOnly
                name='print btn'
                noHover={!hasChartData}
                onClick={() => {
                  dispatch(setRecoveryRadarActiveModal('PHI-warning'));
                  // Amplitude tracking
                  dashboardTracker('Recovery Radar', 'Overview - Print', 'Print button clicked');
                }}
                styledWrapperProps={{
                  height: '35px',
                  marginLeft: '10px',
                  padding: '3px',
                  width: '35px!important',
                }}
              />
            </DateOptionsContainer>
          </OptionsHeader>
          <RROverviewChart />
          {!hasChartData && !isOverviewDataLoading && (
          <NoDataModal>
            <ModalTitle>
              {!group && !hasChartData ? 'Select a Group' : 'No Data Available'}
            </ModalTitle>
            <ModalMessage>
              {!group && !hasChartData
                ? 'Select a group using the dropdown above to get started'
                : 'There is no data available, please adjust your selection above'}
            </ModalMessage>
          </NoDataModal>
          )}
        </ChartContainer>
        <BodyHeatMapWithButtonWrapper classname='heat-map' heatMapDisplayData={heatMapData} />
      </ChartAndBodyContainer>
      <FlaggedAthletesModal
        active={isFlaggedAthletesModalShowing}
        handleDismiss={handleDismiss}
        handleReview={handleReview}
        isRadar
        numOfAthletes={numOfAlertUsers}
      />
      <CardsContainer />
      {!hasTableData ? (
        <RROverviewTableNoData
          containerWidth={containerWidth}
          dataRows={['', '', '', '', '']}
          rowIsScrollable
          setColumnHeaderHeight={setColumnHeaderHeight}
        />
      ) : (
        <RROverviewTable
          averageData={overviewGroupAverageData}
          containerWidth={containerWidth}
          dataRows={overviewTableData}
          rowIsScrollable
          setColumnHeaderHeight={setColumnHeaderHeight}
        />
      )}
    </Container>
  );
};

export default Overview;
