/* eslint-disable max-len */
// component that receives a workout item and transcribes the associated info
/* eslint-disable no-tabs */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import { useTheme } from 'emotion-theming';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const WorkoutItemDiv = styled('div')`
	display: flex;
  align-items: start;
	cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  text-align: start;
`;

const IconTitle = styled('div')`
	display: flex;
  max-width: 100%;
  width: 100%;
  color: white;
`;

const TitleDesc = styled('div')`
  display: flex;
  flex-direction: column;
`;

const IconContainer = styled('div')`
	display: flex;
  position: relative;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	border: ${(props) => (`2px solid ${props.borderColor}`)};
	width: ${(props) => (props.containerWidth > 1045 ? '40px' : '20px')};
	height: ${(props) => (props.containerWidth > 1045 ? '40px' : '20px')};
  background: #7A8894;
`;

const IconAndLine = styled('div')`
	display: flex;
	flex-direction: column;
	margin-right: ${(props) => (props.containerWidth > 1045 ? '20px' : '10px')};
	align-items: center;
  height: 100%;
`;

const GroupingLetterContainer = styled('div')`
  display: flex;
  align-items: center;
  border-radius: 50px;
  border-Width: 1px;
  height: ${(props) => (props.containerWidth > 1045 ? '20px' : '10px')};
  width: ${(props) => (props.containerWidth > 1045 ? '20px' : '10px')};
  justify-content: center;
  padding: 1.5px;
  position: absolute;
  border: ${(props) => (`1px solid ${props.borderColor}`)};
  background: ${(props) => (props.borderColor)};
  right: ${(props) => (props.containerWidth > 1045 ? '-9px' : '-5px')};
  top: ${(props) => (props.containerWidth > 1045 ? '-9px' : '-5px')};
`;

const GroupingLetter = styled('div')`
	color: ${(props) => props.color};
  font-size: ${(props) => (props.containerWidth > 1045 ? '12px' : '8px')};
  font-weight: 600;
`;

const TrainingResultsWorkoutItem = ({
  workoutItem,
  showVerticalLine,
  containerWidth,
}) => {
  const theme = useTheme();

  return (
    <>
      <WorkoutItemDiv>
        <IconAndLine containerWidth={containerWidth}>
          <IconContainer borderColor={workoutItem?.groupingColorCode || 'grey'} containerWidth={containerWidth}>
            <IcomoonReact
              iconSet={iconSet}
              size={containerWidth > 1045 ? 24 : 12}
              icon={workoutItem.exerciseIcon}
              color='white'
            />
            {workoutItem.groupingColorCode && workoutItem.groupingLetter && (
            <GroupingLetterContainer borderColor={workoutItem?.groupingColorCode || 'black'} containerWidth={containerWidth}>
              <GroupingLetter
                containerWidth={containerWidth}
                color={theme.colors.text}
              >
                {workoutItem.groupingLetter}
              </GroupingLetter>
            </GroupingLetterContainer>
            )}
          </IconContainer>
          <div style={{
            display: 'flex',
            minHeight: '25px',
            flex: 1,
            width: showVerticalLine ? '2px' : '0px solid #444',
            background: 'lightgrey',
          }}
          />
        </IconAndLine>
        <IconTitle>
          <TitleDesc>
            <Text fontSize={containerWidth > 1045 ? '16px' : '12px'} fontWeight={600} color={theme.colors.text}>
              {workoutItem.exerciseName}
            </Text>
            <Text fontSize={containerWidth > 1045 ? '13px' : '8px'} fontWeight={600} color={theme.colors.lightText[0]}>
              {workoutItem?.subTitle}
            </Text>
          </TitleDesc>
        </IconTitle>
      </WorkoutItemDiv>
    </>
  );
};

TrainingResultsWorkoutItem.propTypes = {
  workoutItem: PropTypes.instanceOf(Object).isRequired,
  showVerticalLine: PropTypes.bool.isRequired,
  containerWidth: PropTypes.number.isRequired,
};

export default TrainingResultsWorkoutItem;
