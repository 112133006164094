import {
  SET_UPGRADE_MODAL,
  SET_BOOK_DEMO_MODAL,
  SET_INSTANT_PAYMENT_MODAL,
  SET_MARKETING_MODAL,
  SET_SIDE_BAR_OPEN,
  SET_IS_SIDE_BAR_EXPANDED,
  FETCH_SUBSCRIPTION_STATUS_START,
  FETCH_SUBSCRIPTION_STATUS_FULFILLED,
  FETCH_SUBSCRIPTION_STATUS_REJECTED,
  FETCH_SUBSCRIPTION_PACKAGE_START,
  FETCH_SUBSCRIPTION_PACKAGE_FULFILLED,
  FETCH_SUBSCRIPTION_PACKAGE_REJECTED,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_START,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED,
  ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED,
  SET_SELECTED_PACKAGE,
  SET_MODAL_OPEN,
  SET_ERROR_MODAL_OPEN,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

export const fetchSubscriptionStatus = (accountCode, packageId) => (
  (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_STATUS_START });
    axiosAuthed.get(`/accounts/${accountCode}/subscriptions/packages/${packageId}/subscription-status`)
      .then((response) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_STATUS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SUBSCRIPTION_STATUS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchSubscriptionPackage = (accountCode, packageId, addOnIds = null) => (
  (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTION_PACKAGE_START });
    axiosAuthed.get(`/accounts/${accountCode}/subscriptions/packages/${packageId}?${addOnIds ? `addOnIds=${addOnIds}` : ''}`)
      .then((response) => {
        console.log('response:', response);
        dispatch({
          type: FETCH_SUBSCRIPTION_PACKAGE_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log('subscription error:', err);
        dispatch({
          type: FETCH_SUBSCRIPTION_PACKAGE_REJECTED,
          payload: err,
        });
      });
  }
);

// export const fetchLoadMonitoringSubscriptionPackage = (accountCode, packageId) => (
//   (dispatch) => {
//     dispatch({ type: FETCH_SUBSCRIPTION_PACKAGE_START });
//     axiosAuthed.get(`/accounts/${accountCode}/subscriptions/packages/${packageId}`)
//       .then((response) => {
//         console.log('response:', response);
//         dispatch({
//           type: FETCH_SUBSCRIPTION_PACKAGE_FULFILLED,
//           payload: response.data,
//         });
//       })
//       .catch((err) => {
//         console.log('subscription error:', err);
//         dispatch({
//           type: FETCH_SUBSCRIPTION_PACKAGE_REJECTED,
//           payload: err,
//         });
//       });
//   }
// );

export const addOnPackage = (accountCode, body) => (
  (dispatch) => {
    dispatch({ type: ADD_ON_SPORTS_SCIENCE_PACKAGE_START });
    axiosAuthed.post(`/accounts/${accountCode}/subscriptions/packages`, body)
      .then((response) => {
        dispatch({
          type: ADD_ON_SPORTS_SCIENCE_PACKAGE_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ADD_ON_SPORTS_SCIENCE_PACKAGE_REJECTED,
          payload: err,
        });
        alert(err?.response?.headers?.message || 'Unable to complete purchase of the Sport Science Hub. Please contact support.');
      });
  }
);

export const setSelectedPackage = (packageDetails) => (
  {
    type: SET_SELECTED_PACKAGE,
    payload: packageDetails,
  }
);

export const setIsInstantPaymentModalShowing = (bool) => (
  {
    type: SET_INSTANT_PAYMENT_MODAL,
    payload: bool,
  }
);

export const setIsUpgradeModalShowing = (bool) => (
  {
    type: SET_UPGRADE_MODAL,
    payload: bool,
  }
);

export const setIsMarketingModalShowing = (bool) => (
  {
    type: SET_MARKETING_MODAL,
    payload: bool,
  }
);

export const setIsBookDemoModalShowing = (bool) => (
  {
    type: SET_BOOK_DEMO_MODAL,
    payload: bool,
  }
);

export const setSideBarOpen = (bool) => (
  {
    type: SET_SIDE_BAR_OPEN,
    payload: bool,
  }
);
export const setIsSideBarExpandedGlobal = (bool) => (
  {
    type: SET_IS_SIDE_BAR_EXPANDED,
    payload: bool,
  }
);
export const setModalOpen = (isModalOpen, activeModal) => ({
  type: SET_MODAL_OPEN,
  payload: {
    isModalOpen,
    activeModal,
  },
});
export const setErrorModalOpen = (isModalOpen, activeModal, errorMessage, dataObject) => ({
  type: SET_ERROR_MODAL_OPEN,
  payload: {
    isModalOpen,
    activeModal,
    errorMessage,
    dataObject,
  },
});
