/* eslint-disable no-nested-ternary */
/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {

} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50%;
	border-radius: 12px;
	padding: 5px;
	box-shadow: ${BoxShadow};
	background: #0dcc8a;
	color: white;
  position: absolute;
  bottom: -15px;
  z-index: 1;
  height: 30px;
`;

const QuestionnaireTotalDiv = styled('div')`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	font-family: 'Nunito Sans';
	font-weight: 500;
`;

const QuestionnaireTotal = ({ workoutObject }) => {
  const questionnaireTotal = useSelector((state) => state.workouts.data.questionnaireTotal);
  const adderFunction = (object) => {
    if (Object.values(object).length) {
      const valuesArray = Object.values(object);
      let totalValue = 0;
      valuesArray.forEach((value) => {
        totalValue += value;
      });
      return totalValue;
    }
    return 0;
  };

  /**
   * Renders the questionnaire total view if total enabled.
   */
  const renderQuestionnaireTotal = () => (
    <QuestionnaireTotalDiv>
      Total:
      {' '}
      {Object.keys(questionnaireTotal).length && (questionnaireTotal[workoutObject.assignedId]
      || questionnaireTotal[workoutObject.saveDataId])
        ? questionnaireTotal[workoutObject.assignedId]
          ? adderFunction(Object.values(questionnaireTotal[workoutObject.assignedId]))
          : adderFunction(Object.values(questionnaireTotal[workoutObject.saveDataId])) : 0}
    </QuestionnaireTotalDiv>
  );

  return (
    <Container>
      {renderQuestionnaireTotal()}
    </Container>
  );
};

QuestionnaireTotal.propTypes = {
  workoutObject: PropTypes.instanceOf(Object).isRequired,
};

export default QuestionnaireTotal;
