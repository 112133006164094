import moment from 'moment';

import {
  SET_GROUP,
  FETCH_REGION_STATUS_FULFILLED,
  FETCH_REGION_STATUS_REJECTED,
  SET_REGION_STATUS_FULFILLED,
  SET_REGION_STATUS_REJECTED,
  SET_SELECTED_ATHLETE,
  SET_SELECTED_DATE_CAL,
  SET_SELECTED_DATE_DATA,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  SET_DATE_RANGE,
  FETCH_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_GROUP_ANALYSIS_DATA_REJECTED,
  SET_ACTIVE_FILTERS,
  SET_ACTIVE_FILTERS_OVERVIEW,
  SET_ACTIVE_TYPE_FILTERS,
  SET_ATHLETE_RADIO_VALUE,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED,
  CLEAR_USER_HISTORY_DATA,
  CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA,
  SET_RECOVERY_RADAR_ACTIVE_MODAL,
  SET_PRINT_CURRENT_PAGE,
  FETCH_OVERVIEW_DATA_FULFILLED,
  FETCH_OVERVIEW_DATA_REJECTED,
  SET_ATHLETES_TO_REVIEW,
} from './actionTypes';

const initialState = {
  activeFilters: [],
  activeFiltersOverview: [],
  activeModal: null,
  activeTypeFilters: [{ id: 1, name: 'Soreness' }, { id: 2, name: 'Pain' }],
  alertAverageData: {},
  areaSelectedAverageData: {},
  areaSelectedTableData: [],
  athleteList: [],
  athleteRadioValue: 'all',
  athletesAssociatedWithCurrentGroup: [],
  athletesToReview: [],
  historyAverageData: {},
  historyHeatMapData: [],
  historyTableData: [],
  cardData: [],
  chartData: [],
  dateRange: 7,
  group: null,
  groupAverageData: {},
  groups: [],
  heatMapData: [],
  modalData: {},
  numOfAlertUsers: null,
  overviewGroupAverageData: {},
  overviewTableData: [],
  printCurrentPage: false,
  regionStatusError: null,
  regionStatusToggle: null,
  selectedAthlete: null,
  selectedDateCal: moment(),
  selectedDateData: moment().format('YYYY-MM-DD'),
  tableData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        groups: [],
      };
    }
    case SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case SET_SELECTED_ATHLETE: {
      return {
        ...state,
        selectedAthlete: action.payload.athlete,
      };
    }
    case SET_SELECTED_DATE_CAL: {
      return {
        ...state,
        selectedDateCal: action.payload,
      };
    }
    case SET_SELECTED_DATE_DATA: {
      return {
        ...state,
        selectedDateData: action.payload,
      };
    }
    case SET_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: action.payload,
      };
    }
    case SET_ACTIVE_FILTERS_OVERVIEW: {
      return {
        ...state,
        activeFiltersOverview: action.payload,
      };
    }
    case SET_ACTIVE_TYPE_FILTERS: {
      return {
        ...state,
        activeTypeFilters: action.payload,
      };
    }
    case SET_ATHLETE_RADIO_VALUE: {
      return {
        ...state,
        athleteRadioValue: action.payload,
      };
    }
    case SET_RECOVERY_RADAR_ACTIVE_MODAL: {
      return {
        ...state,
        activeModal: action.payload.name,
        modalData: action.payload.data,
      };
    }
    case SET_PRINT_CURRENT_PAGE: {
      return {
        ...state,
        printCurrentPage: action.payload,
      };
    }
    // OVERVIEW
    case FETCH_OVERVIEW_DATA_FULFILLED: {
      return {
        ...state,
        activeFiltersOverview: action.payload.defaultFilters,
        cardData: action.payload.cardData,
        chartData: action.payload.chartData,
        heatMapData: action.payload.heatMapData,
        overviewGroupAverageData: action.payload.overviewGroupAverageData,
        overviewTableData: action.payload.overviewTableData,
      };
    }
    case FETCH_OVERVIEW_DATA_REJECTED: {
      return {
        ...state,
        chartData: [],
        cardData: [],
        heatMapData: [],
        overviewGroupAverageData: {},
        overviewTableData: [],
      };
    }
    case SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      };
    }
    case SET_ATHLETES_TO_REVIEW: {
      return {
        ...state,
        athletesToReview: action.payload,
      };
    }
    // ANALYSIS
    case FETCH_GROUP_ANALYSIS_DATA_FULFILLED: {
      return {
        ...state,
        alertAverageData: action.payload.alertAverageData,
        athletesToReview: action.payload.athletesToReview,
        groupAverageData: action.payload.groupAverageData,
        numOfAlertUsers: action.payload.numOfAlertUsers,
        tableData: action.payload.tableData,
      };
    }
    case FETCH_GROUP_ANALYSIS_DATA_REJECTED: {
      return {
        ...state,
        alertAverageData: {},
        athletesToReview: [],
        groupAverageData: {},
        numOfAlertUsers: null,
        tableData: [],
      };
    }
    // HISTORY
    case FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED: {
      return {
        ...state,
        athleteList: action.payload,
      };
    }
    case FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED: {
      return {
        ...state,
        athleteList: [],
      };
    }
    case CLEAR_USERS_ASSOCOATED_WITH_GROUP_DATA: {
      return {
        ...state,
        athleteList: [],
      };
    }
    case FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED: {
      return {
        ...state,
        areaSelectedAverageData: action.payload.areaSelectedAverageData,
        areaSelectedTableData: action.payload.areaSelectedTableData,
        historyAverageData: action.payload.historyAverageData,
        historyHeatMapData: action.payload.historyHeatMapData,
        historyTableData: action.payload.historyTableData,
      };
    }
    case FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED: {
      return {
        ...state,
        areaSelectedAverageData: {},
        areaSelectedTableData: [],
        historyAverageData: {},
        historyHeatMapData: [],
        historyTableData: [],
      };
    }
    case CLEAR_USER_HISTORY_DATA: {
      return {
        ...state,
        areaSelectedAverageData: {},
        areaSelectedTableData: [],
        historyAverageData: {},
        historyHeatMapData: [],
        historyTableData: [],
      };
    }
    // SETTINGS
    case FETCH_REGION_STATUS_FULFILLED: {
      return {
        ...state,
        regionStatusError: null,
        regionStatusToggle: action.payload,
      };
    }
    case FETCH_REGION_STATUS_REJECTED: {
      return {
        ...state,
        regionStatusError: action.payload,
        regionStatusToggle: null,
      };
    }
    case SET_REGION_STATUS_FULFILLED: {
      return {
        ...state,
        regionStatusError: null,
        regionStatusToggle: action.payload,
      };
    }
    case SET_REGION_STATUS_REJECTED: {
      return {
        ...state,
        regionStatusError: action.payload,
        regionStatusToggle: null,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
