/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'emotion-theming';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import Text from '../../../../shared/components/Text/Text';

const WeeklyTrainingResultsBarChart = () => {
  const theme = useTheme();
  const sessions = useSelector((state) => state.athleteReportCard?.data?.athleteReportCard?.training?.sessions);

  const formattedSessions = sessions?.map((session) => {
    const dateStr = session.date;
    const dayOfWeek = moment(dateStr).format('ddd');
    const label = session.sessionTime === 'N/A' ? null : session.sessionTime;

    return {
      day: dayOfWeek.toUpperCase(),
      time: session.sessionDurationInSeconds,
      label,
    };
  });

  /** styles */
  const Wrapper = styled('div')`
    display: flex;
    min-width: 250px;
    width: 100%;
    max-width: 800px;
    min-height: 300px;
    flex-direction: column;
    text-align: center;
    gap: 20px;
  `;

  return (
    <Wrapper>
      <Text color='#7E7A7A' fontSize='15px' fontWeight={700} style={{ marginLeft: 120 }}>Total Workout Time By Day:</Text>
      <ResponsiveContainer height={300}>
        {formattedSessions?.length && (
          <BarChart
            layout='vertical'
            data={formattedSessions}
            margin={{
              top: 5,
              right: 60,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis type='number' dataKey='time' hide padding={{ left: 10 }} />
            <YAxis
              dataKey='day'
              type='category'
              tickLine={false}
              axisLine={{ stroke: 'rgb(242, 241, 241)', strokeWidth: 2 }}
              tick={{
                fill: theme.colors.text,
                fontSize: 14,
                fontFamily: 'nunito sans',
                fontWeight: 900,
              }}
            />
            <defs>
              <linearGradient id='purpleGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
                <stop offset='0%' style={{ stopColor: '#687EE9', stopOpacity: 1 }} />
                <stop offset='100%' style={{ stopColor: '#764DA4', stopOpacity: 1 }} />
              </linearGradient>
            </defs>
            <Bar
              dataKey='time'
              fill='url(#purpleGradient)'
              background={{ fill: 'transparent' }}
              barSize={15}
            >
              <LabelList dataKey='label' fill='#898989' fontSize={14} position='right' fontFamily='nunito sans' fontWeight={600} />
            </Bar>
          </BarChart>
        )}
      </ResponsiveContainer>
    </Wrapper>
  );
};

export default WeeklyTrainingResultsBarChart;
