import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Form, Field } from 'formik';
import moment from 'moment';
import { useTheme } from 'emotion-theming';
import Logger from 'js-logger';
import Shimmer from 'react-shimmer-effect';

import {
  fetchStripeDetails,
  fetchCouponDetails,
  clearCouponError,
  setCouponError,
  clearCoupon,
  setPaymentLoading,
} from '../../ducks/programsActions';

import NoHoverButton from '../../../reseller/components/presentational/programs/NoHoverButton';
import Text from '../../../../shared/components/Text/Text';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import InfoCard from './InfoCard';
import PaymentCard from './PaymentCard';

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  input[type="checkbox"] {
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    border-radius: 0px !important;
  }
  label {
    width: 100%;
  }
  button {
    margin-bottom: 20px;
    color: white;
  }
  .easy-join {
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  .spinner-container {
    min-height: 60px;
  }
  
  &.prices {
    display: flex;
    justify-content: space-between;
    margin-top: -5px;
  }

  .coupon-container {
    width: 75%;
  }

  .apply-container {
    margin-top: 25px;
    width: 20%;
  }

  .apply-button {
    height: 38px;
    margin-top: 2px;
  }

  &.card-entry {
    margin: 15px 0px 0px 0px;
  }

  .checkbox-input {
    margin-right: 10px;
    margin-top: 2px;
    width: 55px;
  }
  
  .StripeElement {
    font-size: 18px;
  }

  &.d-flex {
    margin-bottom: 10px;
  }

  &.less-margin {
    margin-bottom: -10px;
  }

  &.prices-wrapper {
    padding-top: 20px;
  }

  .update-button {
    margin-top: 10px;
    align-self: flex-end;
    margin-left: auto;
  }
  
  .Shimmer-shimmer-0-1-1 {
    color: transparent;
  }

  z-index: 1;
  @media screen and (max-width: 600px) {
    a {
      font-size: 11px;
    }
  }
`;

const FieldError = styled('div')`
  margin: 10px 0px;
  font-size: 14px;
  color: #ff6600;
`;

const LargePrice = styled('span')`
  font-size: 40px;
  font-weight: 900;
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
`;

const SmallPrice = styled('span')`
  font-size: 12px;
  color: ${(props) => props.theme.textColor};
  text-transform: uppercase;
`;

const PriceContainer = styled('span')`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const DefaultPaymentWrapper = styled('div')`
  background-color: transparent;

  .card-info-box {
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }

  .StripeElement {
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    padding-top: 8px;
  }
`;

const ButtonPadding = styled('div')`
  @media screen and (max-width : 768px) {
    padding-bottom: 50px;
  }
`;

const InfoDetailsWrapper = styled('div')`
`;

const FormCheckout = ({
  elements,
  externalCheckout,
  formProps,
  stripe,
}) => {
  const [isUsingSavedCard, setIsUsingSavedCard] = useState(false);
  const [timeout, setT] = useState(null);
  const programData = useSelector((state) => state.programs.data.programData);
  const paymentDetails = useSelector((state) => state.programs.data.paymentDetails);
  const paymentError = useSelector((state) => state.programs.data.paymentError);
  const isLoadingPaymentDetails = useSelector((state) => state.programs.ui.isLoadingPaymentDetails);
  const isLoadingPayment = useSelector((state) => state.programs.ui.isLoadingPayment);
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const coupon = useSelector((state) => state.programs.data.coupon);
  const couponError = useSelector((state) => state.programs.data.couponError);
  const isLoadingCoupon = useSelector((state) => state.programs.ui.isLoadingCoupon);

  const dispatch = useDispatch();

  const theme = useTheme();

  const {
    accountCode,
    resellerSubscriptions,
    stripeId,
    organizationName,
  } = currentUser;

  // On form load, let's check if the user has an associated stripeId.
  // If they do, fetch the stripe details from account subscription endpoint.
  useEffect(() => {
    if (stripeId) {
      Logger.debug('Payment Details:', paymentDetails);
      if (Object.entries(paymentDetails).length === 0) {
        dispatch(fetchStripeDetails(accountCode));
      }
    }
  }, []);

  // Once we retrieve payment details, check if they have a data source,
  // if they do, set the state to show saved card view and set the right form fields.
  useEffect(() => {
    if (Object.entries(paymentDetails).length !== 0) {
      if (paymentDetails.sources.data.length > 0) {
        setIsUsingSavedCard(true);
        Logger.debug('Set Saved Card Condition');
      }
    } else {
      setIsUsingSavedCard(false);
    }
  }, [paymentDetails]);

  const {
    currencySymbol,
    programName,
    programPrice,
    resellerName,
    trialPeriod,
    billingFrequency,
    billingFrequencyDescription,
    placeSymbolAfterCurrency,
    numBillingCycles,
  } = programData;

  const {
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    values,
  } = formProps;

  const getProgramEndDate = () => {
    if (numBillingCycles && numBillingCycles > 0) {
      // Handle yearly programs here
      if (billingFrequency === 12) {
        return `* Subscription will end on ${moment().add(numBillingCycles, 'years').subtract(1, 'days').format('MMM D, YYYY')}`;
      }
      const numMonthsUntilExpires = numBillingCycles * billingFrequency;
      return `* Subscription will end on ${moment().add(numMonthsUntilExpires, 'months').subtract(1, 'days').format('MMM D, YYYY')}`;
    }
    return '* Subscription will be ongoing';
  };

  const { resellerSlug } = useParams();

  const price = programPrice / 100;
  let total = price;
  if (coupon) {
    if (coupon.amountOff) {
      if (coupon.currencySymbol !== currencySymbol) {
        dispatch(setCouponError('Coupon currency must match Program currency'));
      } else {
        total = (programPrice / 100) - (coupon.amountOff / 100);
      }
    } else if (coupon.percentOff && coupon.percentOff > 0) {
      if (coupon.percentOff >= 100) {
        total = 0;
      } else {
        total = (programPrice / 100) * (1 - (coupon.percentOff / 100));
      }
    }
  }

  total = total < 0 ? 0 : parseFloat(total).toFixed(2);

  // const spinnerAnimation = useSpring({ opacity: isLoginLoading ? 1 : 0, zIndex: 0 });
  // const completeAnimation = useSpring({ opacity: isAuthenticated ? 1 : 0 });

  const handleStripeSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    // Use Elements to get a reference to the Card Element mounted somewhere
    // in your <Elements> tree. Elements will know how to find your Card Element
    // because only one is allowed.
    // See our getElement documentation for more:
    // https://stripe.com/docs/stripe-js/reference#elements-get-element
    const cardElement = elements.getElement('card');

    // From here we can call createPaymentMethod to create a PaymentMethod
    // See our createPaymentMethod documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
    stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name: values.name },
    })
      .then(({ paymentMethod }) => {
        Logger.debug('Received Stripe PaymentMethod:', paymentMethod);
      });

    // You can also use createToken to create tokens.
    // See our tokens documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    // With createToken, you will not need to pass in the reference to
    // the Element. It will be inferred automatically.
    stripe.createToken({ type: 'card', name: values.name })
      .then((token) => {
        Logger.debug('Stripe Token:', token);
        setFieldValue('stripeToken', token.token.id);
        handleSubmit();
      })
      .catch((err) => {
        Logger.debug('Stripe Token Error:', err);
        dispatch(setPaymentLoading(false));
      });
    // token type can optionally be inferred if there is only one Element
    // with which to create tokens
    // stripe.createToken({name: 'Jenny Rosen'});
  };

  const handleCheckoutSubmit = (e) => {
    // Use timeout and setT from useState to clear and set timeouts to
    // prevent multiple functions triggers and debounce the submission.
    if (timeout) {
      clearTimeout(timeout);
    }

    /* If a coupon hasn't been validated yet, or the coupon they entered wasn't valiid
    Then remove couponId from values to send to API and still allow the request to go through */
    if (!coupon || (coupon && couponError)) {
      delete values.couponId;
    }
    Logger.debug('Are we using a saved Card?', isUsingSavedCard);
    if (isUsingSavedCard) {
      // Update name field with a blank space to remove validation error,
      // it prevents the form from submitting if we don't fill out the card fields.
      setFieldValue('name', ' ');
      setT(setTimeout(() => handleSubmit(), 750));
    } else {
      setT(setTimeout(() => handleStripeSubmit(e), 750));
      dispatch(setPaymentLoading(true));
    }
    Logger.debug('Should be submitting');
  };

  const handleApplyCoupon = () => {
    dispatch(fetchCouponDetails(resellerSlug, values.couponId));
  };

  const handleUpdateButton = () => {
    setIsUsingSavedCard(!isUsingSavedCard && true);
  };

  const getPriceAndCurrency = () => {
    if (coupon.amountOff) {
      if (coupon.placeSymbolAfterCurrency) {
        return `-${parseFloat(coupon.amountOff / 100).toFixed(2)}${coupon.currencySymbol}`;
      }
      return `-${coupon.currencySymbol}${parseFloat(coupon.amountOff / 100).toFixed(2)}`;
    }
    if (coupon.placeSymbolAfterCurrency) {
      return `-${parseFloat(price * (coupon.percentOff / 100)).toFixed(2)}${currencySymbol}`;
    }
    return `-${currencySymbol}${parseFloat(price * (coupon.percentOff / 100)).toFixed(2)}`;
  };

  // const accountCode = currentUser ? currentUser.accountCode : 0;

  // Check if the user is already subscribed to a reseller.
  // Check if the user is an admin.
  // Check if the user account is already associated with this account.
  // If true, show error and disable checkout button.
  const isAlreadySubscribed = resellerSubscriptions !== null && resellerSubscriptions.length > 0;
  const isAdmin = currentUser && currentUser.admin !== 0;
  const isNotWithThisAccount = accountCode !== programData.resellerAccountCode;

  // Default fields for credit card entry.
  const defaultCardFields = () => (
    <>
      <DefaultPaymentWrapper>
        <FormGroup>
          <label>
            <Text
              fontSize={theme.textFontSizes[0]}
            >
              Name on Card
            </Text>
            <Field
              className='form-control'
              id='name'
              type='text'
              name='name'
              placeholder='First and Last Name'
            />
          </label>
          {errors.name && touched.name && <FieldError className='text-center'>{errors.name}</FieldError>}
        </FormGroup>

        {/* Use Stripe Elements to create card inputs. */}
        <FormGroup className='card-entry form-group less-margin'>
          <label>
            <Text
              fontSize={theme.textFontSizes[0]}
            >
              Card Information
            </Text>
            <CardElement style={{ base: { fontSize: '18px' } }} className='form-control' />
          </label>
          {stripeId && (
          <NoHoverButton
            className='update-button'
            cta='Use Saved Card'
            customColor='#3fbfe1'
            noBorder
            rounded
            primary
            onClick={handleUpdateButton}
          />
          )}
        </FormGroup>
      </DefaultPaymentWrapper>
    </>
  );

  // If we're using a saved card, show saved card view,
  // otherwise show default fields.
  const savedCardOrFields = () => {
    if (isUsingSavedCard) {
      const defaultCard = (card) => card.id === paymentDetails.default_source;
      const defaultCardIndex = paymentDetails.sources.data.findIndex(defaultCard);

      return (
        <FormGroup className='less-margin'>
          <label>
            <Text
              fontSize={theme.textFontSizes[0]}
            >
              Saved Payment
            </Text>
            <PaymentCard
              brand={paymentDetails && paymentDetails.sources.data[defaultCardIndex].brand}
              last4={paymentDetails && paymentDetails.sources.data[defaultCardIndex].last4}
              isLoading={isLoadingPaymentDetails}
              handleUpdateButton={handleUpdateButton}
            />
            <NoHoverButton
              className='update-button'
              cta='Use A New Card'
              customColor='#3fbfe1'
              noBorder
              rounded
              primary
              onClick={handleUpdateButton}
            />
          </label>
        </FormGroup>
      );
    }
    return defaultCardFields();
  };

  // Saved card view that shows a loading view if we're loading
  // more payment details after checking for a stripeId.
  const savedCardView = () => (
    (isLoadingPaymentDetails ? (
      <>
        <FormGroup className='form-group'>
          <label>
            <Shimmer>
              <Text
                fontSize={theme.textFontSizes[0]}
              >
                Saved Payment
              </Text>
            </Shimmer>
            <PaymentCard
              isLoading={isLoadingPaymentDetails}
            />
            <Shimmer>
              <Text
                fontSize={theme.textFontSizes[0]}
                style={{ marginTop: 15 }}
              >
                Saved Payment
              </Text>
            </Shimmer>
          </label>
        </FormGroup>
      </>
    ) : (
      savedCardOrFields()
    ))
  );

  return (
    <Form
      name='checkout'
      id='checkout'
    >
      {/* If logged in, show checking out as notification. */}

      {/* This is the program summary info card. */}
      {/* {!externalCheckout && ( */}
      <InfoCard
        InfoDetails={(
          // Left side program details
          <InfoDetailsWrapper>
            <Text
              className='coach-name'
              fontSize={12}
            >
              {resellerName}
            </Text>
            <Text
              fontWeight='600'
            >
              {programName}
            </Text>
          </InfoDetailsWrapper>
        )}
        InfoSideDetails={(
          // Right side program details
          <PriceContainer>
            <LargePrice>{placeSymbolAfterCurrency ? `${price}${currencySymbol}` : `${currencySymbol}${price}`}</LargePrice>
            <SmallPrice>
              /
              {billingFrequencyDescription}
            </SmallPrice>
          </PriceContainer>
        )}
      />
      {/* )} */}

      {/* Show program error after hitting a 400 error. */}
      {paymentError && <FieldError className='text-center'>{paymentError.message ? paymentError.message : 'Something went wrong, please try again. If the problem persists, try again later.'}</FieldError>}

      {/*
          If already subscribed to a program, is admin, or is not with this account? Show error,
          otherwise show form and allow checkout.
      */}
      {isAlreadySubscribed || isAdmin || isNotWithThisAccount ? (
        <>
          {isAlreadySubscribed && (
            <FieldError className='text-center' style={{ marginTop: 30 }}>
              This account is already associated with a subscription.
              Please complete the current subscription or sign in to a
              different account to purchase this program.
            </FieldError>
          )}
          {(isAdmin && !isNotWithThisAccount) && (
            <FieldError className='text-center' style={{ marginTop: 30 }}>
              You are currently signed in to a coach account. To purchase this program,
              please sign in or create an athlete account.
            </FieldError>
          )}
          {(isNotWithThisAccount && !isAdmin) && (
            <FieldError className='text-center' style={{ marginTop: 30 }}>
              This account is currently associated with
              {' '}
              {organizationName}
              .
              If you would like to buy this program, you will need to deactivate this account
              or create a new account with a different email address.
            </FieldError>
          )}
          {(isNotWithThisAccount && isAdmin) && (
            <FieldError className='text-center' style={{ marginTop: 30 }}>
              You are currently signed in to a coach account and this account is associated with
              {' '}
              {organizationName}
              .
              If you would like to buy this program, you will need to deactivate this account
              or create a new athlete account with a different email address.
            </FieldError>
          )}
        </>
      ) : (
        <>
          {/*
            If the logged in user has a stripeId, we'll fetch the paymentDetails and
            check if the source is greater than 0. If true we'll show the default payment
            method instead of the card entry view.
          */}
          {stripeId ? (
            savedCardView()
          ) : (
            defaultCardFields()
          )}
          <FormGroup className='form-group prices prices-wrapper'>
            <FormGroup className='form-group coupon-container'>
              <label>
                <Text
                  fontSize={theme.textFontSizes[0]}
                >
                  Coupon Code
                </Text>
                <Field
                  className='form-control'
                  id='coupon-field'
                  type='text'
                  name='couponId'
                  placeholder='Coupon Code'
                  onChange={(e) => {
                    setFieldValue('couponId', e.target.value);
                    /* Once the user starts to type,
                    Clear any previous coupons or couponErrors
                    // that had been fetched */
                    if (coupon) {
                      dispatch(clearCoupon());
                    }
                    if (couponError) {
                      dispatch(clearCouponError());
                    }
                  }}
                />
              </label>
              {couponError ? (
                <FieldError className='error-text coupon-error'>{couponError}</FieldError>
              ) : null}
            </FormGroup>
            <FormGroup className='form-group apply-container'>
              <NoHoverButton
                cta='Apply'
                customColor='#10CD8C'
                disabled={isLoadingCoupon || !values.couponId}
                isLoading={isLoadingCoupon}
                name='apply'
                id='apply'
                onClick={handleApplyCoupon}
                large
                noBorder
                rounded
                primary
                fullWidth
                type='button'
                className='apply-button'
              />
            </FormGroup>
          </FormGroup>
          {coupon && !couponError ? (
            <FormGroup className='form-group prices'>
              <Text
                fontWeight={600}
                fontSize={theme.textFontSizes[1]}
              >
                Price
              </Text>
              <Text
                fontWeight={600}
                fontSize={theme.textFontSizes[1]}
              >
                {placeSymbolAfterCurrency ? `${price.toFixed(2)}${currencySymbol}` : `${currencySymbol}${price.toFixed(2)}`}
              </Text>
            </FormGroup>
          ) : null}

          {coupon && !couponError ? (
            <FormGroup className='form-group prices'>
              <Text
                fontWeight={600}
                fontSize={theme.textFontSizes[1]}
                color={theme.colors.green}
              >
                {coupon.name.toUpperCase()}
              </Text>
              <Text
                fontWeight={600}
                fontSize={theme.textFontSizes[1]}
              >
                {getPriceAndCurrency()}
              </Text>
            </FormGroup>
          ) : null}

          <FormGroup className='form-group prices'>
            <Text
              fontWeight={600}
              fontSize={theme.textFontSizes[1]}
            >
              Total
            </Text>
            <Text
              fontWeight={600}
              fontSize={theme.textFontSizes[1]}
            >
              {placeSymbolAfterCurrency ? `${total}${currencySymbol}` : `${currencySymbol}${total}`}
            </Text>
          </FormGroup>
          <FormGroup className='d-flex'>
            <label className='d-flex'>
              <input
                className='checkbox-input'
                type='checkbox'
                name='agreementData'
                onChange={() => {
                  setFieldValue('agreementData', !values.agreementData && true);
                  Logger.debug(values);
                }}
                checked={values.agreementData}
              />
              <Text fontSize={theme.textFontSizes[0]}>
                I understand that all tools and information presented on this site are intended for informational and educational purposes. Any diet or exercise programs and advice are NOT intended as medical diagnosis or treatment. You should consult your doctor before beginning any diet or exercise program.
              </Text>
            </label>
          </FormGroup>
          {errors.agreementData && touched.agreementData && <FieldError className='text-center'>Must Accept Terms Before Subscribing.</FieldError>}

          {(numBillingCycles !== null && numBillingCycles > 0) && (
            <FormGroup className='d-flex'>
              <Text fontSize={theme.textFontSizes[0]}>
                {getProgramEndDate()}
              </Text>
            </FormGroup>
          )}

          <FormGroup className='text-center'>
            {isLoadingPayment ? (
              <Spinner saving darkTheme />
            ) : (
              <ButtonPadding>
                <NoHoverButton
                  cta='Confirm Payment'
                  customColor='#10CD8C'
                  disabled={
                  !touched
                  || errors.password
                  || errors.name
                  || errors.agreementData
                  || isAlreadySubscribed
                  || isLoadingCoupon
                }
                  name='submit'
                  id='submit'
                  onClick={handleCheckoutSubmit}
                  fullWidth
                  large
                  noBorder
                  rounded
                  primary
                  type='button'
                />
              </ButtonPadding>
            )}

          </FormGroup>

          {/* If trial period is greater than 0 days, show this message. */}
          {(trialPeriod !== null && trialPeriod > 0) && (
            <FormGroup className='d-flex'>
              <Text fontSize={theme.textFontSizes[0]}>
                {`* Your card will not be charged at time of purchase. The amount of the program will be charged on this card after ${trialPeriod} days.`}
              </Text>
            </FormGroup>
          )}
        </>
      )}
    </Form>
  );
};

export default injectStripe(FormCheckout);
