// component for displaying PRs, if they exist
/* eslint-disable no-nested-ternary */
/* eslint-disable no-tabs */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import Shimmer from 'react-shimmer-effect';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {

} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const Container = styled('div')`
	display: flex;
	width: 100%;
	border-radius: 24px;
	margin-top: 30px;
	padding: 20px;
	box-shadow: ${BoxShadow};
	background: white;
	flex-direction: column;
`;

const BoxTitle = styled('div')`
	display: flex;
	height: 25px;
  margin-bottom: 10px;
`;

const BoxTitleText = styled('div')`
	font-size: 14px;
	font-weight: 700;
	margin-left: 5px;
	font-family: 'Nunito Sans';
`;

const PRSlab = styled('div')`
	display: flex;
	justify-content: space-between;
	color: white;
	height: 60px;
	background: #444444;
	margin-top: 10px;
	border-radius: 12px;
	font-family: 'Nunito Sans';
	padding-left: 30px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 30px;
	align-items: center;
	font-size: 14px;
`;

const SlabContainer = styled('div')`
	display: flex;
	flex-direction: column;
`;

const NoPRText = styled('div')`
	font-size: 12px;
	font-family: 'Nunito Sans';
`;

const PRText = styled('div')`
	font-size: 12px;
	font-family: 'Nunito Sans';
`;

const NoWorkoutDiv = styled('div')`
  display: flex;
  justify-content: center;
  font-family: Nunito Sans;
  font-weight: 700;
`;

const PRBox = () => {
  const workoutSessionData = useSelector((state) => state.workouts.data.workoutSessionData);
  const isWorkoutsListLoading = useSelector((state) => state.workouts.ui.isWorkoutsListLoading);
  const sharedAthlete = useSelector(
    (state) => state.workouts.data.sharedAthlete,
  );

  const slabMapper = (prArray) => prArray.map((pr) => (
    <PRSlab>
      <PRText>{pr.exerciseName}</PRText>
      <PRText>{pr.maxValue}</PRText>
    </PRSlab>
  ));

  const PRCruncher = () => {
    if (Object.keys(sharedAthlete).length && workoutSessionData?.newPRs?.length) {
      if (!isWorkoutsListLoading) {
        return (
          <SlabContainer>
            {slabMapper(workoutSessionData.newPRs)}
          </SlabContainer>
        );
      }
      return (
        <Shimmer>
          <PRSlab />
        </Shimmer>
      );
    } if (Object.keys(sharedAthlete).length && !workoutSessionData?.newPRs?.length) {
      return <NoPRText>No PRs for this Session</NoPRText>;
    }
    return null;
  };

  return (
    <Container>
      <BoxTitle>
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='trophy'
          color='black'
        />
        <BoxTitleText>New PR&apos;s</BoxTitleText>
      </BoxTitle>
      {!Object.keys(sharedAthlete).length ? (
        <NoWorkoutDiv>No Athlete Selected</NoWorkoutDiv>
      ) : null}
      {PRCruncher()}
    </Container>
  );
};

PRBox.propTypes = { };

export default PRBox;
