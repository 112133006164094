/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import IcomoonReact from 'icomoon-react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import Shimmer from 'react-shimmer-effect';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const ChartContainer = styled('div')`
  background: white;
  box-shadow: 0px 0px 1px rgba(48,49,51,0.05), 0px 8px 16px rgba(48,49,51,0.1);
  border-radius: 24px;
  width: 100%;
  max-height: 800px;
  min-width: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-left: ${(props) => props.containerWidth < 424 && '0px'};
  padding-right: ${(props) => props.containerWidth < 424 && '0px'};
  align-items: center;

.recharts-tooltip-wrapper {
  z-index: 9999;
}
    .custom-tooltip {
      background-color: #f5f5f5;
      box-shadow: 0px 0px 1px rgba(48,49,51,0.05), 0px 8px 16px rgba(48,49,51,0.1);
      padding: 15px;
      border-radius: 24px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-width: 250px;
      justify-content: center;
      align-self: center;
      font-family: 'Nunito Sans';
      font-size: 13px;
      font-weight: 400;
    }
`;

const TextContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

`;

const CustomXAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={16} textAnchor='end' fill='#666' fontSize={10} fontWeight={700}/** transform='rotate(-35)' */>{payload.value}</text>
  </g>
);

const CustomYAxisTickLeft = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={0} y={0} dy={0} textAnchor='end' fill='#666' fontSize={11} fontWeight={700}>{payload.value}</text>
  </g>
);

const CustomYAxisTickRight = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text x={25} y={0} dy={0} textAnchor='end' fill='#666' fontSize={11} fontWeight={700}>{payload.value}</text>
  </g>
);
const CustomTooltip = ({
  active, payload, label, checkboxFilterDataArray,
}) => {
  const filteredPayload = payload.filter((payloadObject) => (checkboxFilterDataArray?.some((obj) => obj.metricTitle === payloadObject.dataKey && obj.checkboxValue === true)));

  if (active && payload) {
    return (
      <div className='custom-tooltip'>
        <p style={{ fontWeight: 'bold' }}>{label}</p>
        {filteredPayload.map((dataPoint) => (
          <p key={dataPoint.name} style={{ color: dataPoint.name !== 'Volume Load' ? dataPoint.color : '#ffa500' }}>
            {`${dataPoint.name}: ${dataPoint.value}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const LoadMonitoringComposedChart = ({ containerWidth, lineChartData }) => {
  const isDataLoading = useSelector((state) => state.loadMonitoringDashboard.ui.isLoadingLineChartData);
  const currentReportType = useSelector((state) => state.loadMonitoringDashboard.data.currentReportType);
  const currentAthleteInAthletePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentAthleteInAthletePicker);
  const reportableGroups = useSelector((state) => state.loadMonitoringDashboard.data.reportableGroups);

  /** set null values to zero */

  const noNullLineChartData = lineChartData.map((dateObject) => {
    const newFilteredDateObjectWithNoNullValuesInValuesArray = {
      ...dateObject,
      values: dateObject?.values?.map((valueObject) => ({ ...valueObject, value: valueObject.value === null ? 0 : valueObject.value })),

    };
    return newFilteredDateObjectWithNoNullValuesInValuesArray;
  });

  const groupAveragesData = noNullLineChartData;

  const checkboxFilterDataArray = useSelector((state) => state.loadMonitoringDashboard.data.currentCheckedBoxes);

  const colorsArray = [
    '#BFB9FA', /** light purple */
    '#00FF00', /** lime green */
    '#0000FF', /** blue */
    '#FFC0CB', /** pink */
    '#FF6600', /** orange */
    '#800080', /** purple */
    '#FF0000', /** red */
    '#FFD700', /** gold */
    '#00FFFF', /** cyan */
    '#00C49F', /** minty teal green */
    '#008000', /** dark green */
    '#FF00FF', /** magenta */
  ];

  /** asign color to all quesitons */
  const groupAveragesDatawithColors = groupAveragesData.map((dateObject) => {
    const newFilteredDateObjectWithCheckedValuesOnly = {
      ...dateObject,
      values: dateObject?.values?.map((value, i) => ({ ...value, color: value.metricId === 'volumeLoad' ? '#ffa500' : colorsArray[i] })),

    };
    return newFilteredDateObjectWithCheckedValuesOnly;
  });

  /** make a copy of the array because .reverse will mutate it an cause an
   * initial flip of the data in the chart, we can avoid this by creating a copy so
   * the original data doesn't get mutated
   */

  /** const reversedArray = [...groupAveragesData]?.reverse(); */
  /** const reversedArray = [...groupAveragesDatawithColors]?.reverse(); */
  /** no longer need to reverse because endpoint was updated but keeping name the same */
  const reversedArray = groupAveragesDatawithColors;

  const filteredAndReversedArray = reversedArray.map((dateObject) => {
    const newFilteredDateObjectWithCheckedValuesOnly = {
      ...dateObject,
      values: dateObject?.values?.filter((value) => checkboxFilterDataArray?.some((obj) => obj.metricId === value.metricId && obj.checkboxValue === true)),

    };
    return newFilteredDateObjectWithCheckedValuesOnly;
  });

  const formattedDataForLineChart = reversedArray.map((dateObject, index) => {
    const valuesObj = reversedArray[index].values.reduce((acc, curr) => {
      acc[curr.metricTitle] = curr.value;
      return acc;
    }, {});

    const obj1 = {
      date: moment(dateObject.date).format('MMM DD, YYYY'),
    };

    const lengthObject = {
      numberOfQuestions: Object.keys(valuesObj).length,
    };

    const questionsObject = {
      questions: dateObject.values,
    };

    const combinedObj = {
      ...obj1, ...valuesObj, ...questionsObject, ...lengthObject,
    };

    return combinedObj;
  });

  const formattedDataForLineChartPayload = filteredAndReversedArray.map((dateObject, index) => {
    const valuesObj = reversedArray[index].values.reduce((acc, curr) => {
      acc[curr.metricTitle] = curr.value;
      return acc;
    }, {});

    const obj1 = {
      date: moment(dateObject.date).format('MMM DD, YYYY'),
    };

    const lengthObject = {
      numberOfQuestions: Object.keys(valuesObj).length,
    };

    const questionsObject = {
      questions: dateObject.values,
    };

    const combinedObj = {
      ...obj1, ...valuesObj, ...questionsObject, ...lengthObject,
    };

    return combinedObj;
  });

  const getPayloadArray = () => {
    /** const payloadArray = formattedDataForLineChart[0].questions?.filter((question) => question.metricTitle !== 'Volume Load').map((question, index) => ({ */
    const payloadArray = formattedDataForLineChartPayload[0].questions?.filter((question) => question.metricTitle !== 'Volume Load').map((question) => ({
      value: question.metricTitle, type: 'circle', color: question.color, id: question.metricId,
    }));

    const payloadWithVolumeLoad = formattedDataForLineChartPayload[0].questions?.filter((question) => question.metricId === 'volumeLoad').length ? [...payloadArray, ...[{
      value: 'Volume Load', type: 'square', color: '#ffa500', id: 'volumeLoad',
    }]] : [...payloadArray];
    return payloadWithVolumeLoad;
  };

  return (
    <>
      {isDataLoading ? (
        <Shimmer>
          <ChartContainer containerWidth={containerWidth} style={{ minHeight: '320px' }} />
        </Shimmer>
      ) : (
        <>
          {!lineChartData?.length ? (
            <ChartContainer
              containerWidth={containerWidth}
              style={{
                minHeight: '320px',
                backgroundImage: 'linear-gradient(rgb(255, 102, 0), rgb(252, 96, 118))',
                padding: '15px',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                gap: '20px',
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={40}
                icon='warning'
                color='white'
              />
              {!reportableGroups.length ? (
                <Text style={{ maxWidth: '400px' }} fontSize={12} fontWeight={700} color='white'>
                  Opps Something went wrong! No data found.
                </Text>
              ) : (
                <Text style={{ maxWidth: '400px' }} fontSize={12} fontWeight={700} color='white'>
                  {`Opps Something went wrong! We were not able to find load monitoring data for the current ${currentAthleteInAthletePicker?.userId ? 'Athlete' : 'Group'} before the selected date`}
                  .
                </Text>
              )}
            </ChartContainer>
          ) : (
            <ChartContainer containerWidth={containerWidth}>
              <Legend
                wrapperStyle={{
                  fontSize: 12, fontWeight: 'bold', paddingBottom: '15px', position: 'static',
                }}
                iconType='circle'
                iconSize={8}
                layout='horizontal'
                align='center'
                verticalAlign='top'
                payload={getPayloadArray()}
              />
              <ResponsiveContainer width='100%' height='100%' minWidth={280} maxWidth={800} minHeight={320} maxHeight={350}>
                <ComposedChart
                  data={formattedDataForLineChart}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='date' tick={<CustomXAxisTick /> /** interval={0} */} />
                  <YAxis yAxisId='left' tick={<CustomYAxisTickLeft />} />
                  <YAxis yAxisId='right' orientation='right' tick={<CustomYAxisTickRight />} />
                  {formattedDataForLineChart[0].questions?.filter((question) => question.metricTitle !== 'Volume Load').map((question) => (
                    <Line
                      yAxisId='left'
                      type='monotone'
                      key={question.metricTitle}
                      dataKey={question.metricTitle}
                      stroke={question.color}
                      dot={{ fill: question.color }}
                      activeDot={false}
                      isAnimationActive={false}
                      strokeWidth={2}
                      style={{ display: checkboxFilterDataArray?.some((obj) => obj.metricId === question.metricId && obj.checkboxValue === true) ? '' : 'none' }}
                    />
                  ))}
                  <defs>
                    <linearGradient id='transparentOrangeGradient' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='0%' stopColor='#FF6600' stopOpacity={0.5} />
                      <stop offset='100%' stopColor='#FF6600' stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Bar
                    yAxisId='right'
                    dataKey='Volume Load'
                    barSize={90}
                    fill='url(#transparentOrangeGradient)'
                    background={{ fill: 'transparent' }}
                    style={{ display: checkboxFilterDataArray?.some((obj) => obj.metricId === 'volumeLoad' && obj.checkboxValue === true) ? 'block' : 'none' }}
                  />
                  <Tooltip content={<CustomTooltip checkboxFilterDataArray={checkboxFilterDataArray} />} />
                </ComposedChart>
              </ResponsiveContainer>
              {currentReportType === 'multiDay' ? (
                <TextContainer style={{ padding: '10px' }}>
                  <Text fontSize='11px' fontWeight={700}>Last 7 days that data was found for current athlete before selected date</Text>
                </TextContainer>
              ) : null}
            </ChartContainer>

          )}
        </>
      )}
    </>
  );
};

export default LoadMonitoringComposedChart;
