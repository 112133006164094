/**
 * This component is the general wrapper for all notifications.
 * It receives information about the notification through the
 * notification details, and displays general information about the
 * component
 */

/* eslint-disable no-tabs */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled from '@emotion/styled';

import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import NotificationWrapper from './shared/NotificationWrapper';
import NotificationTopBar from './shared/NotificationTopBar';
import NotificationOuterContent from './shared/NotificationOuterContent';
import NotificationInnerContent from './shared/NotificationInnerContent';
import Button from '../../../../../shared/components/Button/Button';
import BossNotificationComponent from './BossNotificationComponent';
import Text from '../../../../../shared/components/Text/Text';

import useConversations from '../../hooks/useConversations';
import useNotifications from '../../hooks/useNotifications';
import { useTheme } from 'emotion-theming';

const IconContainer = styled('div')`
	width: 60px;
	height: 60px;
	margin: 0 0 20.3px 0;
	border: solid 2.2px #444445;
	border-radius: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TitleContainer = styled('div')`
	font-family: Nunito Sans;
	line-height: 1.04;
	font-size: 24px;
  text-align: center;
`;

const HeaderDivider = styled('div')`
	width: 100%;
	height: 0.1px;
	margin: 19.7px 0 30.9px;
	border: solid 1px #F5F5F5;
`;

const NotificationComponentWrapper = styled('div')`
	margin-bottom: 33px;
	font-size: 18px;
  padding: 15px;
`;

const HeaderContainer = styled('div')`
	display: flex;
	padding-top: 30px;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`;

const ButtonDiv = styled('div')`
	margin-top: 30px;
`;

const NoNotificationError = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: 'Nunito Sans';
  background-color: white;
  z-index: 100;
  text-align: center;

  p {
    padding: 15px;
  }

  button {
    max-width: 220px;
  }
  
  .topbar-back-button {
    align-self: flex-start;
    margin-top: 20px;
    margin-left: 10px;
    display: none;
    
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
`;

const AlertTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    margin-left: -35px;
  }
  
`;

/**
 *
 * @param {Object} activeNotification the user-selected notification
 * @returns a component representing the general, outer notification details
 */

const NotificationItemContainer = ({
  activeNotification,
}) => {
  // the broader notification details about the selected notification that has been fetched
  const notificationAsset = useSelector((state) => state.notifications.data.notificationAsset);
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const theme = useTheme();

  const {
    handleSetIsMobileSidebarOpen,
  } = useConversations();
  const {
    handleDeleteNotifications,
    handleSetActiveNotification,
  } = useNotifications();

  const handleDeleteNotificationButton = () => {
    const values = [parseInt(activeNotification.id, 10)];
    handleSetActiveNotification({});
    handleSetIsMobileSidebarOpen(true);
    handleDeleteNotifications(currentUser.accountCode, values);
  };

  const handleBackButton = () => {
    handleSetIsMobileSidebarOpen(true);
    setTimeout(() => {
      handleSetActiveNotification({});
    }, 500);
  };

  /**
   * checks to see if the fetched notification details has length. If so, returns the component
   * else, returns a message that the notification doesn't exist
   */
  if (Object.keys(notificationAsset).length) {
    return (
      <NotificationWrapper>
        <NotificationOuterContent>
          {/**
           * The details of the notification in the top part of the panel
           */}
          <NotificationTopBar activeNotification={activeNotification} />
          <NotificationInnerContent>
            <HeaderContainer>
              {/**
               * The notification icon based on the icon prop of the notification details
               */}
              <IconContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={notificationAsset.icon === 'fist-bump-full-together' ? 33 : 20}
                  icon={notificationAsset.icon}
                />
              </IconContainer>
              <TitleContainer>
                {activeNotification?.title}
              </TitleContainer>
            </HeaderContainer>
            {/**
             * The title of the notification from the notification details
             */}

            <HeaderDivider />
            <NotificationComponentWrapper>
              {/**
               * The inner component that carries more specific details about
               * the fetched notification
               */}
              <BossNotificationComponent activeNotification={activeNotification} />
              {/**
               * returns a butten in the case that a button should be displayed
               * i.e. if button text exists for the fetched notification.
               * If no button text exists, then no butten is displayed (null is returned)
               */}
              {notificationAsset.actionButtonText ? (
                <ButtonDiv>
                  <Button
                    noHover
                    bottom
                    fullWidth
                    cta={notificationAsset.actionButtonText}
                    className='modal-button'
                    borderColor='#ff6600'
                    customColor='white'
                    customTextColor='#ff6600'
                    customBorderRadius='100px'
                    rounded
                    onClick={() => {
                      if (notificationAsset.webAppDeepLink) {
                        window.open(
                          notificationAsset.webAppDeepLink, '_blank',
                        );
                      }
                    }}
                    // onClick={() => { window.open(`${process.env.PHP_APP_URL || `https://${process.env.ENVIRONMENT !== 'PRODUCTION' ? 'staging.app.' : 'app.'}teambuildr.com`}/feed?postId=${notificationAsset.postId}`, '_blank'); }}
                  />
                </ButtonDiv>
              ) : null}
            </NotificationComponentWrapper>
          </NotificationInnerContent>
        </NotificationOuterContent>
      </NotificationWrapper>
    );
  }
  return (
    <NoNotificationError>
      <Button
        iconOnly
        icon='left-arrow'
        size='80'
        className='topbar-back-button'
        onClick={() => handleBackButton()}
      />
      <AlertTextContainer>
        <Text>
          Sorry, that notification doesn&lsquo;t seem to exist ¯\_(ツ)_/¯
        </Text>
        <Button
          customColor={theme.colors.red}
          primary
          noBorder
          cta='Delete Notification'
          onClick={() => handleDeleteNotificationButton()}
        />
      </AlertTextContainer>
    </NoNotificationError>
  );
};

NotificationItemContainer.propTypes = {
  activeNotification: PropTypes.instanceOf(Object).isRequired,
};

export default NotificationItemContainer;
