// a relatively complex component that displays a different modal depending on which
// document type the user intends to create

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';

import iconSet from '../../../shared/images/teambuildr-selection.json';
import useConversations from '../components/hooks/useConversations';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  margin-top: 25px;
  .modal-button {
    display: flex;
    align-items: center;
  }
  .note {
    text-align: center;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
  }
  .message-number {
    margin-top: 30px;
  }
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 250px;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const CreateProgramSlide3 = ({
  formProps,
  selectedOptions,
}) => {
  const {
    handleUploadMedia,
  } = useConversations();

  const dispatch = useDispatch();

  const messageFlipper = () => {
    if (selectedOptions.sms && selectedOptions.em) {
      return 'The email and text messages were sent';
    } if (selectedOptions.sms && !selectedOptions.em) {
      return 'The text messages were sent';
    } if (selectedOptions.sms && selectedOptions.em) {
      return 'The emails were sent';
    }
  };

  return (
    <ModalWrapper>
      <IconWrapper>
        {selectedOptions.em ? (
          <IcomoonReact
            iconSet={iconSet}
            size={100}
            icon='envelope-closed'
          />
        ) : null}
        {selectedOptions.sms ? (
          <IcomoonReact
            iconSet={iconSet}
            size={100}
            icon='phone'
          />
        ) : null}
      </IconWrapper>
      {messageFlipper()}
    </ModalWrapper>
  );
};

CreateProgramSlide3.propTypes = {
  formProps: PropTypes.instanceOf(Object).isRequired,
  selectedOptions: PropTypes.instanceOf(Object).isRequired,
};

export default CreateProgramSlide3;
