/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

import WeightroomWorkout from '../presentational/WorkoutContainer';
import SessionBreak from '../presentational/SessionBreak';

const SessionBreakDiv = styled('div')`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.isExpanded ? '1px solid #34495E' : null)};
  margin-top: 10px;
  border-radius: 13px;
  overflow: hidden;
`;

const ExpandSection = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.isExpanded ? '100%' : '0px')};
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease;
  -moz-transition: max-height 0.5s ease;
  transition: max-height 0.5s ease;
  margin-left: 15px;
  padding-bottom: 9px;
`;

const ItemNumberContainer = styled('div')`
  height: 50px;
  display: flex;
  align-items: center;
`;

const ItemNumber = styled('div')`
  display: flex;
  width: 60px;
  display: flex;
  justify-content: center;
  border: 1px solid grey;
  margin-left: 10px;
  border-radius: 10px;
  font-size: 11px;
  font-family: 'Nunito Sans';
  color: grey;
`;

const WeightroomSessionBreakContainer = ({
  sessionItems,
  sessionBreak,
  activeWorkoutIndex,
  entriesContainerRef,
  entriesViewHeight,
  entryRowRef,
  formikRef,
  handleActiveWorkout,
  index,
  namespace,
  nextButton,
  questionListRef,
  scrollQuestions,
  supersetIndex,
  workoutWrapperRef,
  workoutInnerRef,
  workoutRef,
}) => {
  let showSessionBreakItems = useSelector((
    state,
  ) => state.weightRoomView[namespace].workouts[index].showSessionBreakItems);
  const sessionLength = sessionItems.length;
  const emptyFunction = useCallback(() => {}, []);

  if (sessionLength === 0) {
    showSessionBreakItems = 0;
  }

  const [isExpanded, setIsExpanded] = useState(showSessionBreakItems);

  useEffect(() => {
    setIsExpanded(showSessionBreakItems);
  }, [showSessionBreakItems]);

  // Checks for fullyCompleted on completeableItems in the sessionItems array
  // Only gets marked fullyCompleted if there is at least one item with completeableItems
  // set to true in the array this way empty breaks/etc. don't get shown as completed
  const completed = sessionItems.filter((session) => session.completeableItem)
    .every((completeableItem) => completeableItem.fullyCompleted);

  const hasTrueCompleteableItems = sessionItems.some(
    (session) => session.completeableItem,
  );

  const fullyCompleted = hasTrueCompleteableItems && completed;

  return (
    <SessionBreakDiv isExpanded={isExpanded}>
      <SessionBreak
        index={index}
        setIsExpanded={sessionLength === 0 ? emptyFunction : setIsExpanded}
        session={sessionBreak}
        isExpanded={isExpanded}
        disabled={sessionLength === 0}
        showSessionBreakItems={showSessionBreakItems}
        namespace={namespace}
        fullyCompleted={fullyCompleted}
      />
      <ItemNumberContainer>
        <ItemNumber>
          {sessionLength === 1 ? '1 item' : `${sessionLength} items`}
        </ItemNumber>
      </ItemNumberContainer>
      <ExpandSection isExpanded={isExpanded}>
        {sessionItems.map((workout, i) => (
          <WeightroomWorkout
            activeWorkoutIndex={activeWorkoutIndex}
            entriesContainerRef={entriesContainerRef[workout.originalIndex]}
            entryRowRef={entryRowRef[workout.originalIndex]}
            formikRef={formikRef[workout.originalIndex]}
            handleActiveWorkout={handleActiveWorkout}
            index={workout.originalIndex}
            key={workout.assignedId || workout.saveDataId}
            namespace={namespace}
            nextButton={nextButton}
            questionListRef={questionListRef[workout.originalIndex]}
            scrollQuestions={scrollQuestions}
            sessionItem={workout}
            supersetIndex={supersetIndex}
            workoutRef={workoutRef[workout.originalIndex]}
            workoutWrapperRef={workoutWrapperRef[workout.originalIndex]}
            workoutInnerRef={workoutInnerRef[workout.originalIndex]}
          />
        ))}
      </ExpandSection>
    </SessionBreakDiv>
  );
};

export default WeightroomSessionBreakContainer;
