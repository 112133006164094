/* eslint-disable no-tabs */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import RestTimerModal from './RestTimerModal';

import { setTimerModalOpen } from '../../ducks/workoutsActions';

const RestTimerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.5);
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
  z-index: 1002;
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const RestTimerModalContainer = () => {
  const isTimerModalOpen = useSelector((state) => state.workouts.data.isTimerModalOpen);
  const reduxTimerDuration = useSelector(
    (state) => state.workouts.data.timerDuration,
  );
  const [isPlaying, setIsPlaying] = useState(true);
  const [localTimerDuration, setLocalTimerDuration] = useState(0);
  const [key, setKey] = useState(0);

  const dispatch = useDispatch();

  return (
    <>
      <RestTimerOverlay
        isActive={isTimerModalOpen}
        onClick={() => {
          setKey((prevKey) => prevKey + 1);
          setLocalTimerDuration(reduxTimerDuration);
          setIsPlaying(false);
          dispatch(setTimerModalOpen(false));
        }}
      />
      <RestTimerModal
        setTimerModalOpen={setTimerModalOpen}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        localTimerDuration={localTimerDuration}
        setLocalTimerDuration={setLocalTimerDuration}
        key={key}
        setKey={setKey}
      />
    </>
  );
};

RestTimerModalContainer.propTypes = { };

export default RestTimerModalContainer;
