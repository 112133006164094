import React, {
  Fragment,
  memo,
  useRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import smoothscroll from 'smoothscroll-polyfill';
import findLastIndex from 'lodash.findlastindex';

import WeightroomWorkout from './WorkoutContainer';
import ArrowButton from './ButtonArrow';
import { HeaderText } from './Theme';
import { TransitionGlobal } from '../../../../shared/GlobalStyles';

smoothscroll.polyfill();

const MessageContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex: 1;
  height: 100%;
  padding: 0px 15px;
  h2 {
    line-height: normal;
  }
`;

const WorkoutsContainer = styled('div')`
  overflow: auto;
  flex: 1;
  padding: 0px 20px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  transition: ${TransitionGlobal};
  z-index: 1;
  &.active {
    /* overflow-y: ${props => (props.isScrolling ? 'auto' : 'hidden')}; */
    padding-bottom: 30px;
    .not-active {
      opacity: .2;
    }
    .active {
      ::before {
        opacity: .2;
        height: 32px;
        margin-top: -29px;
      }
      .exercise_sets_reps {
        opacity: 1;
        /* justify-content: space-between; */
      }
    }
  }

  @media screen and (orientation: portrait) {
    padding: 0px 10px;
  }

  button {
    &.prev {
      right: 100px
    }
  }
`;

const WorkoutNav = styled('nav')`
  /* display: flex;
  height: 100%;
  width: 40px;
  position: absolute;
  top: 0px;
  right: 0px;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 0px 50px 0px;
  margin: 30px 20px 0px 0px; */
  z-index: 100;

  button {
    position: absolute;
    right: 30px;
    &:first-of-type {
      top: 60px;
    }
    &:last-of-type {
      bottom: 30px;
    }
  }
`;

const WorkoutList = ({
  activeExerType,
  activeQuestionIndex,
  activeScreens,
  activeWorkout,
  activeWorkoutIndex,
  completeWorkout,
  closeWorkout,
  data,
  decreaseCompletedSets,
  increaseCompletedSets,
  isNavigatingSuperset,
  isWorkoutActive,
  namespace,
  panelDimensions,
  restartQuestions,
  selectQuestion,
  selectWorkout,
  settings,
  setIsNavigatingSuperset,
  workoutsError,
  workoutsBySuperset,
}) => {
  const formik = Array.from({ length: `${data.length}` }, () => useRef(null));
  const questionList = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutWrapperRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutInner = Array.from({ length: `${data.length}` }, () => useRef(null));
  const entriesContainerRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const entryRowRef = Array.from({ length: `${data.length}` }, () => useRef(null));
  const workoutsContainer = useRef(null);

  const [navigationDirection, setNavigationDirection] = useState('');

  const wrvSupersetsEnabled = settings.wrv_supersets_enabled;

  // WORKOUT SCROLLING FUNCTIONS

  const scrollEntries = (activeEntryIndex, workoutIndex) => {
    const entryPos = entryRowRef[workoutIndex].current
      ? (entryRowRef[workoutIndex].current.entryFieldRef[activeEntryIndex].offsetLeft)
      : 0;

    if (entriesContainerRef[workoutIndex].current) {
      // console.log('Scrolled!', entryPos, entryRowRef, workoutIndex);
      entriesContainerRef[workoutIndex].current.scroll({ top: 0, left: entryPos - 40, behavior: 'smooth' });
    }
  };

  const scrollWorkout = (workoutIndex) => {
    const workoutPos = workoutWrapperRef[workoutIndex].current
      && (workoutWrapperRef[workoutIndex].current.offsetTop);
    const workoutHeight = workoutWrapperRef[workoutIndex].current
      && (workoutWrapperRef[workoutIndex].current.getBoundingClientRect().height);

    const newPos = workoutHeight + 30 < panelDimensions.height ? (
      // console.log('Workout Height is Small'),
      workoutPos - (((panelDimensions.height - workoutHeight) / 2)
      //  + 35
      )
    ) : (
      // console.log('Workout Height is Large'),
      workoutPos - 30
    );

    if (workoutsContainer.current) {
      workoutsContainer.current.scroll({ top: newPos, left: 0, behavior: 'smooth' });
    }
  };

  const scrollQuestions = useCallback((questionIndex) => {
    const questionPos = questionList[activeWorkoutIndex].current
      && (questionList[activeWorkoutIndex].current.question[questionIndex].offsetTop);
    const questionHeight = questionList[activeWorkoutIndex].current
      && questionList[activeWorkoutIndex].current.question[questionIndex].scrollHeight;

    const newPos = questionHeight < panelDimensions.height ? (
      // console.log('Question Height is Small'),
      questionPos - (((panelDimensions.height - questionHeight) / 2) - 160)
    ) : (
      // console.log('Question Height is Large'),
      questionPos
    );
    if (workoutsContainer.current) {
      workoutsContainer.current.scroll({ top: newPos, left: 0, behavior: 'smooth' });
    }
  }, [activeWorkoutIndex, panelDimensions.height]);

  // WORKOUT CONDITIONS

  const isFormik = (
    activeExerType === 'lift'
    || activeExerType === 'circuit'
    || activeExerType === 'cardio'
    || activeExerType === 'science'
    || activeExerType === 'health'
    || activeExerType === 'progression'
  );

  const isScience = (
    activeExerType === 'science'
  );

  const isLastQuestion = (
    isScience
      ? activeQuestionIndex === (data[activeWorkoutIndex] && (data[activeWorkoutIndex].sets - 1))
      : false
  );

  const isInSuperset = (
    data[activeWorkoutIndex] && (
      data[activeWorkoutIndex].grouping !== null
    )
  );

  const isSupersetWorkoutActive = supersetWorkout => (
    supersetWorkout.work_id === data[activeWorkoutIndex].work_id
  );

  const isSilkBrowser = /\bSilk\b/.test(navigator.userAgent);

  const activeSuperset = data[activeWorkoutIndex] && (data[activeWorkoutIndex].grouping);

  const activeSupersetArray = workoutsBySuperset && (workoutsBySuperset[activeSuperset]);

  const activeSupersetWorkoutIndex = (isInSuperset && wrvSupersetsEnabled)
    ? activeSupersetArray.findIndex(isSupersetWorkoutActive)
    : null;


  // NAVIGATE SUPERSET LOGIC

  const navigateSuperset = () => {
    if (navigationDirection === 'forward') {
    /*
      If navigation is forward, loop through the superset array starting after the active superset
      workout index, and find the next incomplete workout.
    */
      const offset = activeSupersetWorkoutIndex + 1;
      for (let i = 0; i < activeSupersetArray.length; i += 1) {
        const pointer = (i + offset) % activeSupersetArray.length;
        // Check if the superset workout is incomplete
        if (activeSupersetArray[pointer].completed_sets !== activeSupersetArray[pointer].sets) {
          // If the next incomplete workout is the current one, don't do anything
          if (activeSupersetArray[pointer].work_id === data[activeWorkoutIndex].work_id) {
            setTimeout(() => scrollEntries(
              activeSupersetArray[pointer].completed_sets,
              activeWorkoutIndex,
            ), 10);
            break;
          } else {
            // If the next incomplete workout is not the current one, go to that workout
            console.log('Go to this workout', activeSupersetArray[pointer]);
            const nextIncompleteWorkout = activeSupersetArray[pointer];
            const isNextWorkout = workout => (
              nextIncompleteWorkout.work_id === workout.work_id
            );
            const nextIncompleteWorkoutIndex = data.findIndex(isNextWorkout);
            selectWorkout(
              nextIncompleteWorkout.work_id,
              nextIncompleteWorkoutIndex,
              nextIncompleteWorkout.exer_id,
              nextIncompleteWorkout.exer_type,
              namespace,
            );
            setTimeout(() => scrollWorkout(nextIncompleteWorkoutIndex), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              nextIncompleteWorkout.completed_sets,
              nextIncompleteWorkoutIndex,
            ), 400);
            break;
          }
        } else if (i + 1 === activeSupersetArray.length) {
          console.log('This workout is complete', pointer, i, activeSupersetArray[pointer]);
          /*
            If all superset workouts are complete, and it's not the last superset,
            then go to the next workout outside of the superset
          */
          const isLastSupersetWorkoutIndex = workout => (
            activeSupersetArray[activeSupersetArray.length - 1].work_id === workout.work_id
          );
          const lastSupersetWorkoutIndex = data.findIndex(isLastSupersetWorkoutIndex);
          const lastSuperset = Object.keys(workoutsBySuperset).reverse()[0];

          /*
            If it is the last superset, and there isn't a workout
            after the last workout in the superset, complete that day's workout
          */
          if (
            (activeSuperset === lastSuperset)
              && (data[lastSupersetWorkoutIndex + 1] === undefined)
          ) {
            console.log('Workout after superset', data[lastSupersetWorkoutIndex + 1]);
            // if (data[lastSupersetWorkoutIndex + 1] === undefined) {
            console.log('No Workout!');
            completeWorkout(namespace);
          } else {
            const nextWorkoutOutsideSuperset = data[lastSupersetWorkoutIndex + 1];

            selectWorkout(
              nextWorkoutOutsideSuperset.work_id,
              lastSupersetWorkoutIndex + 1,
              nextWorkoutOutsideSuperset.exer_id,
              nextWorkoutOutsideSuperset.exer_type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              lastSupersetWorkoutIndex + 1,
            ), isSilkBrowser ? 1200 : 800);
            // console.log('Go to the first workout outside of the group');
          }
        }
      }
      setIsNavigatingSuperset(namespace, false);
    } else {
      /*
        If navigation direction is backwards, find the workout with the largest
        completed sets in the current superset, and calculate wether the largest two are equal.
      */
      const allEqual = arr => (
        arr.every(
          v => v.completed_sets === arr[0].completed_sets,
        )
      );
      const descendingActiveSupersetArray = Array.from(activeSupersetArray).sort(
        (a, b) => (b.completed_sets - a.completed_sets),
      );
      const slicedDescendingSupersetArray = descendingActiveSupersetArray.slice(0, 2);
      const isEqual = allEqual(slicedDescendingSupersetArray);

      /*
       If the largest completed sets is 0, and it's not the first workout in the superset
       then just go to the previous workout.
      */
      if (slicedDescendingSupersetArray[0].completed_sets === 0
        && activeSupersetArray[0].work_id !== data[activeWorkoutIndex].work_id) {
        const prevWorkoutIndex = activeWorkoutIndex - 1;
        const prevWorkout = data[activeWorkoutIndex];
        console.log('All completed sets are 0!', prevWorkoutIndex, prevWorkout, slicedDescendingSupersetArray[0]);

        selectWorkout(
          prevWorkout.work_id,
          prevWorkoutIndex,
          prevWorkout.exer_id,
          prevWorkout.exer_type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);

      /*
        If the current workout is the first superset workout,
        and the completed sets is 0, find the previous workout to go to.
      */
      } else if ((activeSupersetArray[0].work_id === data[activeWorkoutIndex].work_id)
        && (activeSupersetArray[activeSupersetWorkoutIndex].completed_sets <= 0)
      ) {
        const isFirstSupersetWorkoutIndex = workout => (
          activeSupersetArray[0].work_id === workout.work_id
        );
        const firstSupersetWorkoutIndex = data.findIndex(isFirstSupersetWorkoutIndex);
        const previousWorkoutOutsideSuperset = data[firstSupersetWorkoutIndex - 1];

        /*
          If the previous workout outside the superset is in a superset,
          find the previous superset's largest completed_sets
        */
        if (previousWorkoutOutsideSuperset.grouping !== null) {
          console.log('Previous Workout is in Superset');
          const previousSuperset = previousWorkoutOutsideSuperset.grouping;
          const previousSupersetArray = workoutsBySuperset[previousSuperset];
          const descendingPreviousSupersetArray = Array.from(previousSupersetArray).sort(
            (a, b) => (b.completed_sets - a.completed_sets),
          );
          const slicedDescendingPreviousSupersetArray = descendingPreviousSupersetArray.slice(0, 2);
          const isPreviousEqual = allEqual(slicedDescendingPreviousSupersetArray);

          /*
            If there is only one superset workout with the
            largest completed sets in the previous superset,
            go to the largest superset workout in the previous superset.
          */
          if (!isPreviousEqual) {
            console.log('1st, Not Equal');
            const largestSupersetWorkout = descendingPreviousSupersetArray[0];
            const isLargestWorkout = workout => (
              descendingPreviousSupersetArray[0].work_id === workout.work_id
            );
            const largestWorkoutIndex = data.findIndex(isLargestWorkout);
            const largestSupersetWorkoutIndex = previousSupersetArray.findIndex(isLargestWorkout);
            console.log('Previous is not equal', previousSuperset, largestSupersetWorkoutIndex, previousSupersetArray);

            decreaseCompletedSets(namespace, previousSuperset, largestSupersetWorkoutIndex);
            selectWorkout(
              largestSupersetWorkout.work_id,
              largestWorkoutIndex,
              largestSupersetWorkout.exer_id,
              largestSupersetWorkout.exer_type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              largestWorkoutIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              largestSupersetWorkout.completed_sets - 1,
              largestWorkoutIndex,
            ), 10);

          /*
            If there are multiple superset workouts with the
            largest completed sets in the previous superset,
            find the last superset workout index with the same completed sets and go to it
          */
          } else if (isPreviousEqual) {
            console.log('1st, Equal', isPreviousEqual);
            const lastIndexOfSameSets = findLastIndex(descendingPreviousSupersetArray, ['completed_sets', slicedDescendingPreviousSupersetArray[0].completed_sets]);
            const isLastSupersetWorkoutOfSameSets = workout => (
              descendingPreviousSupersetArray[lastIndexOfSameSets].work_id === workout.work_id
            );
            const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
            const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
            const lastSupersetWorkoutOfSameSetsIndex = (
              previousSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
            );
            const lastSupersetWorkoutOfSameSets = (
              previousSupersetArray[lastSupersetWorkoutOfSameSetsIndex]
            );

            console.log('Previous is equal', previousWorkoutOutsideSuperset, previousSuperset, lastSupersetWorkoutOfSameSetsIndex, lastWorkoutOfSameSets, activeSupersetArray);
            console.log('entry variables', lastSupersetWorkoutOfSameSets.completed_sets - 1, lastSupersetWorkoutOfSameSets, lastWorkoutOfSameSetsIndex);

            decreaseCompletedSets(
              namespace,
              previousSuperset,
              lastSupersetWorkoutOfSameSetsIndex === -1 ? 0 : lastSupersetWorkoutOfSameSetsIndex,
            );
            selectWorkout(
              lastWorkoutOfSameSets.work_id,
              lastWorkoutOfSameSetsIndex,
              lastWorkoutOfSameSets.exer_id,
              lastWorkoutOfSameSets.exer_type,
              namespace,
            );
            setTimeout(() => scrollWorkout(
              lastWorkoutOfSameSetsIndex,
            ), isSilkBrowser ? 1200 : 800);
            setTimeout(() => scrollEntries(
              lastSupersetWorkoutOfSameSets.completed_sets - 1,
              lastWorkoutOfSameSetsIndex,
            ), 10);
          }

        /*
          If the previous workout outside the superset is not a superset,
          go directly to the previous workout.
        */
        } else {
          console.log('Previous Workout Not in Superset');
          selectWorkout(
            previousWorkoutOutsideSuperset.work_id,
            firstSupersetWorkoutIndex - 1,
            previousWorkoutOutsideSuperset.exer_id,
            previousWorkoutOutsideSuperset.exer_type,
            namespace,
          );
          setTimeout(() => scrollWorkout(
            firstSupersetWorkoutIndex - 1,
          ), isSilkBrowser ? 1200 : 800);
        }

      /*
        If the largest completed superset workout is the current workout,
        just decrease the completed sets.
      */
      } else if (
        (descendingActiveSupersetArray[0].work_id === data[activeWorkoutIndex].work_id)
        && (!isEqual)
      ) {
        console.log('Its the current workout!', activeSupersetWorkoutIndex);
        decreaseCompletedSets(namespace, activeSuperset, activeSupersetWorkoutIndex);

      /*
        If there are multiple workouts with the largest completed sets,
        find the last index of those multiple workouts and go to it.
      */
      } else if (isEqual) {
        console.log('How Many Equal?', allEqual(descendingActiveSupersetArray));
        const lastIndexOfSameSets = findLastIndex(descendingActiveSupersetArray, ['completed_sets', slicedDescendingSupersetArray[0].completed_sets]);
        console.log('Last workout with highest number of sets', lastIndexOfSameSets, slicedDescendingSupersetArray[0].completed_sets);
        const isLastSupersetWorkoutOfSameSets = workout => (
          descendingActiveSupersetArray[lastIndexOfSameSets].work_id === workout.work_id
        );
        const lastWorkoutOfSameSetsIndex = data.findIndex(isLastSupersetWorkoutOfSameSets);
        const lastWorkoutOfSameSets = data[lastWorkoutOfSameSetsIndex];
        const lastSupersetWorkoutOfSameSetsIndex = (
          activeSupersetArray.findIndex(isLastSupersetWorkoutOfSameSets)
        );

        decreaseCompletedSets(namespace, activeSuperset, lastSupersetWorkoutOfSameSetsIndex);
        selectWorkout(
          lastWorkoutOfSameSets.work_id,
          lastWorkoutOfSameSetsIndex,
          lastWorkoutOfSameSets.exer_id,
          lastWorkoutOfSameSets.exer_type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          lastWorkoutOfSameSetsIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          lastWorkoutOfSameSets.completed_sets - 1,
          lastWorkoutOfSameSetsIndex,
        ), 10);

      /*
        If there are no multiple workouts with the largest completed sets,
        and it's not the current workout, go to the largest workout.
      */
      } else {
        const isWorkoutWithLargestCompletedSets = workout => (
          descendingActiveSupersetArray[0].work_id === workout.work_id
        );
        const prevWorkoutIndex = data.findIndex(isWorkoutWithLargestCompletedSets);
        const prevWorkout = descendingActiveSupersetArray[0];
        const prevWorkoutSupersetIndex = (
          activeSupersetArray.findIndex(isWorkoutWithLargestCompletedSets)
        );
        console.log('Am I actually running?', prevWorkoutIndex, prevWorkout, prevWorkoutSupersetIndex);
        decreaseCompletedSets(namespace, activeSuperset, prevWorkoutSupersetIndex);
        selectWorkout(
          prevWorkout.work_id,
          prevWorkoutIndex,
          prevWorkout.exer_id,
          prevWorkout.exer_type,
          namespace,
        );
        setTimeout(() => scrollWorkout(
          prevWorkoutIndex,
        ), isSilkBrowser ? 1200 : 800);
        setTimeout(() => scrollEntries(
          prevWorkout.completed_sets - 1,
          prevWorkoutIndex,
        ), 10);
      }
      setIsNavigatingSuperset(namespace, false);
    }
  };

  /*
    Run superset navigation logic if workoutsBySuperset was updated after
    incremented completed sets, and isNavigatingSuperset is true
  */
  useEffect(() => {
    if (isNavigatingSuperset) {
      navigateSuperset();
    }
  }, [isNavigatingSuperset]);


  const handleSupersetNavigation = (direction) => {
    console.log('Navigation Direction:', direction);
    if (direction === 'forward') {
      increaseCompletedSets(namespace, activeSuperset, activeSupersetWorkoutIndex);
      console.log('increased');
    }
    setIsNavigatingSuperset(namespace, true);
  };

  const handleActiveWorkout = (workoutId, index, exerId, exerType) => {
    const isWorkoutActive = activeWorkoutIndex === index;
    const saveWorkout = new Promise((resolve) => {
      if ((isFormik) && (activeWorkoutIndex !== null)) {
        if (formik[activeWorkoutIndex].current) {
          formik[activeWorkoutIndex].current.submitForm();
        } else {
          // console.log('This is why theres no current:', formik);
        }
        setTimeout(resolve('saved'), 9000);
      }
    });
    console.log(`Active Workout Is ${workoutId}, ${index}, ${exerId}, ${exerType}, ${isWorkoutActive}`);
    if (isWorkoutActive && isFormik) {
      saveWorkout.then(() => { closeWorkout(namespace); });
    } else if (isWorkoutActive && !isFormik) {
      closeWorkout(namespace);
    } else {
      saveWorkout.then(selectWorkout(workoutId, index, exerId, exerType, namespace));
    }
    setTimeout(() => scrollWorkout(index), isSilkBrowser ? 1200 : 800);
  };

  // NEXT BUTTON

  const nextButton = () => {
    const isBeforeLastQuestion = (
      isScience
        ? activeQuestionIndex < (questionList[activeWorkoutIndex].current
          && (questionList[activeWorkoutIndex].current.props.workout.sets - 1))
        : false
    );

    const isLastWorkout = activeWorkoutIndex === (data.length - 1);

    if (isFormik) {
      if (formik[activeWorkoutIndex].current) {
        formik[activeWorkoutIndex].current.submitForm();
      }
    }

    if ((isScience) && (isBeforeLastQuestion)) {
      if (questionList[activeWorkoutIndex].current) {
        selectQuestion(
          parseInt(
            questionList[activeWorkoutIndex]
              .current.props.data[activeQuestionIndex + 1].question_id, 10,
          ),
          activeQuestionIndex + 1,
          namespace,
        );
      }
      setTimeout(() => scrollQuestions(activeQuestionIndex + 1), isSilkBrowser ? 1200 : 800);
    } else {
      if ((isScience) && (isLastQuestion)) {
        restartQuestions(namespace);
        setTimeout(() => scrollWorkout(activeWorkoutIndex + 1), isSilkBrowser ? 1200 : 800);
      }

      if (wrvSupersetsEnabled && isInSuperset) {
        setNavigationDirection('forward');
        setTimeout(handleSupersetNavigation('forward'), 100);
      } else if (!isLastWorkout) {
        if (workoutRef[activeWorkoutIndex + 1].current) {
          selectWorkout(
            parseInt(workoutRef[activeWorkoutIndex + 1].current.props.id, 10),
            parseInt(workoutRef[activeWorkoutIndex + 1].current.props.index, 10),
            parseInt(workoutRef[activeWorkoutIndex + 1].current.props.exerId, 10),
            workoutRef[activeWorkoutIndex + 1].current.props.exerType,
            namespace,
          );
          setTimeout(() => scrollWorkout(activeWorkoutIndex + 1), isSilkBrowser ? 1200 : 800);
        }
      } else if (isLastWorkout) {
        completeWorkout(namespace);
      }
    }
  };

  // PREVIOUS BUTTON

  const prevButton = () => {
    const isAfterFirstQuestion = (
      isScience
        ? activeQuestionIndex > 0
        : ''
    );

    if (isFormik) {
      if (formik[activeWorkoutIndex].current) {
        formik[activeWorkoutIndex].current.submitForm();
      }
    }
    if ((isScience) && (isAfterFirstQuestion)) {
      if (questionList[activeWorkoutIndex].current) {
        selectQuestion(
          parseInt(
            questionList[activeWorkoutIndex]
              .current.props.data[activeQuestionIndex - 1].question_id, 10,
          ),
          activeQuestionIndex - 1,
          namespace,
        );
      }
      setTimeout(() => scrollQuestions(activeQuestionIndex - 1), isSilkBrowser ? 1200 : 800);
    } else if (wrvSupersetsEnabled && isInSuperset) {
      setNavigationDirection('backwards');
      setTimeout(handleSupersetNavigation('backwards'), 100);
    } else if (workoutRef[activeWorkoutIndex - 1].current) {
      selectWorkout(
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.id, 10),
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.index, 10),
        parseInt(workoutRef[activeWorkoutIndex - 1].current.props.exerId, 10),
        workoutRef[activeWorkoutIndex - 1].current.props.exerType,
        namespace,
      );
      setTimeout(() => scrollWorkout(activeWorkoutIndex - 1), isSilkBrowser ? 1200 : 800);
    }
  };

  // VARIABLES FOR JSX

  const workoutList = data;
  const hasWorkouts = workoutList.length > 0;
  const isWorkoutInactive = activeWorkout === null;
  const emptyFunction = useCallback(() => {}, []);
  const isLastWorkout = activeWorkoutIndex === (data.length - 1);
  const isLastSuperset = ((wrvSupersetsEnabled && isInSuperset)
    && (activeSupersetArray[activeSupersetWorkoutIndex].completed_sets
      >= activeSupersetArray[activeSupersetWorkoutIndex].sets - 1));

  // Ternary Conditions for navigation arrow buttons

  const completeIfIsLastSuperset = wrvSupersetsEnabled && isInSuperset
    ? isLastSuperset
    : true;

  const completeIfIsLastQuestion = isScience
    ? isLastQuestion
    : completeIfIsLastSuperset;

  const faceDownIfIsNotLastSuperset = wrvSupersetsEnabled && isInSuperset
    ? !isLastSuperset
    : false;

  const faceDownIfIsNotLastQuestion = isScience
    ? !isLastQuestion
    : faceDownIfIsNotLastSuperset;

  const checkmarkIfIsLastSuperset = isLastSuperset
    ? 'checkmark'
    : 'alt-up-arrow';

  const checkIfSuperset = (wrvSupersetsEnabled && isInSuperset)
    ? checkmarkIfIsLastSuperset
    : 'checkmark';

  const checkmarkIfIsLastQuestion = isLastQuestion
    ? 'checkmark'
    : 'alt-up-arrow';

  const checkmarkIfIsScienceandLastQuestion = isScience
    ? checkmarkIfIsLastQuestion
    : checkIfSuperset;

  return (
    <Fragment>
      <WorkoutsContainer
        activeScreens={activeScreens}
        id='workouts-container'
        // isScrolling={isWorkoutTall}
        ref={workoutsContainer}
        className={`${activeWorkout ? 'active' : ''}`}
        fullHeight={isWorkoutInactive}
      >
        {
          hasWorkouts ? (
            workoutList.map((workout, i) => (
              <WeightroomWorkout
                activeWorkoutIndex={activeWorkoutIndex}
                entriesContainerRef={entriesContainerRef[i]}
                entryRowRef={entryRowRef[i]}
                formikRef={formik[i]}
                handleActiveWorkout={handleActiveWorkout}
                index={i}
                key={workout.work_id}
                namespace={namespace}
                nextButton={workout.exer_type === 'rest' ? nextButton : emptyFunction}
                questionListRef={questionList[i]}
                scrollQuestions={workout.exer_type === 'science' ? scrollQuestions : emptyFunction}
                supersetIndex={activeSupersetWorkoutIndex}
                workoutRef={workoutRef[i]}
                workoutWrapperRef={workoutWrapperRef[i]}
                workoutInnerRef={workoutInner[i]}
              />
            ))
          ) : (
            <MessageContainer>
              <HeaderText>{workoutsError === '' ? 'No Workouts for Selected Date' : workoutsError}</HeaderText>
            </MessageContainer>
          )
        }
      </WorkoutsContainer>

      { isWorkoutActive && (
        <WorkoutNav>
          <ArrowButton
            handleArrowClick={prevButton}
            icon='alt-up-arrow'
            disabled={
              ((!wrvSupersetsEnabled && activeExerType !== 'science') && activeWorkoutIndex === 0)
              || ((!wrvSupersetsEnabled && activeWorkoutIndex === 0) && (activeExerType === 'science' && activeQuestionIndex === 0))
              || ((wrvSupersetsEnabled && isInSuperset)
                  && (activeWorkoutIndex === 0 && activeSupersetArray[0].completed_sets === 0))
              || ((wrvSupersetsEnabled && !isInSuperset && activeExerType !== 'science') && activeWorkoutIndex === 0)
              || ((wrvSupersetsEnabled && activeWorkoutIndex === 0) && (activeExerType === 'science' && activeQuestionIndex === 0))
            }
            large
            light
          />
          <ArrowButton
            complete={
              isLastWorkout ? (
                completeIfIsLastQuestion
              ) : (
                false
              )
            }
            down={
              isLastWorkout ? (
                faceDownIfIsNotLastQuestion
              ) : (
                true
              )
            }
            handleArrowClick={nextButton}
            icon={
              isLastWorkout ? (
                checkmarkIfIsScienceandLastQuestion
              ) : (
                'alt-up-arrow'
              )
            }
            large
            light
          />
        </WorkoutNav>
      )}
    </Fragment>
  );
};

WorkoutList.propTypes = {
  activeExerType: PropTypes.string.isRequired,
  activeWorkout: PropTypes.number,
  activeWorkoutIndex: PropTypes.number,
  activeScreens: PropTypes.number.isRequired,
  activeQuestionIndex: PropTypes.number.isRequired,
  completeWorkout: PropTypes.func.isRequired,
  closeWorkout: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  decreaseCompletedSets: PropTypes.func.isRequired,
  increaseCompletedSets: PropTypes.func.isRequired,
  isNavigatingSuperset: PropTypes.bool.isRequired,
  isWorkoutActive: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  panelDimensions: PropTypes.instanceOf(Object).isRequired,
  restartQuestions: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  selectWorkout: PropTypes.func.isRequired,
  settings: PropTypes.instanceOf(Object).isRequired,
  setIsNavigatingSuperset: PropTypes.func.isRequired,
  workoutsBySuperset: PropTypes.instanceOf(Object).isRequired,
  workoutsError: PropTypes.string.isRequired,
};

WorkoutList.defaultProps = {
  activeWorkout: null,
  activeWorkoutIndex: undefined,
};

// const dataPropsAreEqual = (prevProps, nextProps) => (
//   prevProps.data !== nextProps.data
//    && prevProps.activeWorkoutIndex !== nextProps.activeWorkoutIndex
//    && prevProps.workoutsBySuperset === nextProps.workoutsBySuperset
// );

export default memo(WorkoutList);
