/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../../shared/components/Spinner/Spinner';
import LayoutWithSidebarNav from '../../../../../shared/components/Layout/LayoutWithSidebarNav';
import { generalSettingsSideBarNavArrayOfObjects } from '../../../../../shared/constants';
import OrganizationInformation from './OrganizationInformation';
import PageManagementSettings from './PageManagementSettings';
import WeightRoomViewGlobalSettings from './WeightRoomViewGlobalSettings';
import OrganizationPreferences from './OrganizationPreferences';
import BetaSettings from './BetaSettings';
import { getOrganizationSettings } from '../../../../login/ducks/loginActions';
import AvatarAndTitleComponent from './AvatarAndTitleComponent';
import OrganizationSettingsSavingIndicator from './OrganizationSettingsSavingIndicator';
import CustomLoginPage from './CustomLoginPage';


const OrganizationSettingsPage = () => {
  const dispatch = useDispatch();

  const [containerWidth, setContainerWidth] = useState(null);

  const accountCode = useSelector((state) => state.auth.data.currentUser?.accountCode);

  const isOrganizationSettingsLoading = useSelector((state) => state.auth.ui.isOrganizationSettingsLoading);

  const helmetText = 'Account Settings';

  const navLinkArrayOfObjects = generalSettingsSideBarNavArrayOfObjects;

  useEffect(() => {
    if (accountCode) {
      dispatch(getOrganizationSettings(accountCode));
    }
  }, [accountCode]);

  const Container = styled('div')`
      position: relative;
      input:focus {
        outline: 5px dotted green;
      }
        .error-message {
        margin-top: 10px;
        font-size: 14px;
        color: #ff6600;
      }
      @media (max-width: 800px) {
        padding-top: 50px;
      }
`;

  const SpinnerWrapper = styled('div')`
  display: flex;
  height: 100vh;
  `;

  return (
    <LayoutWithSidebarNav
      helmetText={helmetText}
      navLinkArrayOfObjects={navLinkArrayOfObjects}
      sideBarTitle='Settings'
      sideBarTitleIcon='settings'
    >
      <OrganizationSettingsSavingIndicator />
      {isOrganizationSettingsLoading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          <Container>
            <ResizeObserver
              onResize={(rect) => {
                setContainerWidth(rect.width);
              }}
            />
            <AvatarAndTitleComponent containerWidth={containerWidth} />
            <OrganizationInformation />
            <OrganizationPreferences />
            <WeightRoomViewGlobalSettings containerWidth={containerWidth} />
            <CustomLoginPage containerWidth={containerWidth} />
            <BetaSettings containerWidth={containerWidth} />
            <PageManagementSettings containerWidth={containerWidth} />
          </Container>
        </>
      )}
    </LayoutWithSidebarNav>
  );
};
export default OrganizationSettingsPage;
