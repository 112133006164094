/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentQuestionInQuestionnairePicker } from '../../ducks/loadMonitoringDashboardActions';
import DonutChart from './SingleDayGridDataDonutChart';

const Wrapper = styled('main')`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.containerWidth < 850 && '100%')};
`;

const PickerAndDonutChart = ({ containerWidth }) => {
  const dispatch = useDispatch();

  const dataRows = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData?.dataRows);
  const formResponses = useSelector((state) => state.loadMonitoringDashboard.data.loadMonitoringFormValues);
  const showTotals = formResponses?.showTotal;

  /** props and logic for donut chart */
  const donutChartData = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.donutChartData);
  const totalScoresDonutChartDataGreen = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataGreen);
  const totalScoresDonutChartDataYellow = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataYellow);
  const totalScoresDonutChartDataRed = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataRed);
  const totalScoresDonutChartDataGreenCount = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataGreen)?.length;
  const totalScoresDonutChartDataYellowCount = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataYellow)?.length;
  const totalScoresDonutChartDataRedCount = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData.totalScoresDonutChartDataRed)?.length;
  const questionToFilterBy = useSelector((state) => state.loadMonitoringDashboard.data.currentQuestionInDonutChartQuestionnairePicker?.displayText);
  const redCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.red.length || 0;
  const greenCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.green?.length || 0;
  const yellowCountForSelectedQuestion = donutChartData?.filter((questionObject) => (questionObject.title === questionToFilterBy))[0]?.yellow?.length || 0;

  const preFilteredlistOfQuestionsForPicker = donutChartData?.map((questionGroup) => ({
    id: questionGroup.id,
    displayText: questionGroup.title,
  }));

  const listOfQuestionsForPicker = preFilteredlistOfQuestionsForPicker?.filter((question) => ((question.id !== 'volumeLoad' && question.id !== 'exertionScore')));

  const totalScoresObjectToAddToPickerQuestionsArray = [{ id: 'Total Scores', displayText: 'Total Scores?' }];

  useEffect(() => {
    /** on page load set the first question in the picker
     * to load donut chart data for the first quesiton in the array instead
     * of lading empty placeholder donut chart
     */
    if (listOfQuestionsForPicker?.length) {
      dispatch(setCurrentQuestionInQuestionnairePicker(listOfQuestionsForPicker[0]));
    }
  }, [donutChartData]);

  const reduxFunction = (item) => {
    dispatch(setCurrentQuestionInQuestionnairePicker(item));
  };

  const arrayToPassToDonutChartForSelectedQuestion = [{
    name: 'red',
    value: redCountForSelectedQuestion,
  },
  {
    name: 'green',
    value: greenCountForSelectedQuestion,

  },
  {
    name: 'yellow',
    value: yellowCountForSelectedQuestion,
  }];

  const totalsArrayToPassToDonutChartIfQuestionSelected = [{
    name: 'red',
    value: totalScoresDonutChartDataRedCount,
  },
  {
    name: 'green',
    value: totalScoresDonutChartDataGreenCount,

  },
  {
    name: 'yellow',
    value: totalScoresDonutChartDataYellowCount,
  }];

  const yellowAthletesArrayForChosenQuestion = [];
  const redAthletesArrayForChosenQuestion = [];
  const greenAthletesForChosenQuestion = [];

  const pushAthletesToColorArrayBasedOnCurrentQuestion = () => {
    dataRows?.map((athlete) => athlete.questions.map((question) => {
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#FFCB47') {
        yellowAthletesArrayForChosenQuestion.push(athlete);
      }
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#FF3737') {
        redAthletesArrayForChosenQuestion.push(athlete);
      }
      if (question.title === questionToFilterBy && question.response.backgroundColor === '#0DCC8A') {
        greenAthletesForChosenQuestion.push(athlete);
      }
    }));
  };

  pushAthletesToColorArrayBasedOnCurrentQuestion();

  return (
    <Wrapper containerWidth={containerWidth}>
      <DonutChart
        containerWidth={containerWidth}
        arrayForDonutChartCount={questionToFilterBy === 'Total Scores?' ? totalsArrayToPassToDonutChartIfQuestionSelected : arrayToPassToDonutChartForSelectedQuestion}
        greenAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataGreen : greenAthletesForChosenQuestion}
        redAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataRed : redAthletesArrayForChosenQuestion}
        yellowAthletesForCurrentQuestion={questionToFilterBy === 'Total Scores?' ? totalScoresDonutChartDataYellow : yellowAthletesArrayForChosenQuestion}
        /** props for picker inside donut chart */
        showTotals={showTotals}
        listOfQuestionsForPicker={listOfQuestionsForPicker}
        totalScoresObjectToAddToPickerQuestionsArray={totalScoresObjectToAddToPickerQuestionsArray}
        reduxFunction={reduxFunction}
        visibleContainerItem={questionToFilterBy}
      />
    </Wrapper>
  );
};

export default PickerAndDonutChart;
