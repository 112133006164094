import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';

import ButtonRound from './ButtonRound';
import Modal from './Modal';
import ModalVideo from './ModalVideo';
import ModalInfo from './ModalInfo';
import ModalOptOut from './ModalOptOut';
import WeightroomModalHistory from '../container/WeightroomModalHistory';
import WeightroomModalNote from '../container/WeightroomModalNote';
// import WeightroomModalDocument from '../container/WeightroomModalDocument';
import WeightroomModalOptOut from '../container/WeightroomModalOptOut';

const WeightroomModalDocument = React.lazy(() => import('../container/WeightroomModalDocument'));

const WorkoutModals = ({
  athleteId,
  activeModal,
  closeModal,
  data,
  exerId,
  exerName,
  fetchDocument,
  fetchHistory,
  index,
  isLoadingExercises,
  isModalOpen,
  namespace,
  openModal,
  workId,
}) => {
  const hasResult = data.result !== null;
  const attributes = (hasResult && data.result.substitution > 0)
    ? data.result.sub_attributes
    : data.attributes;

  const modalClose = () => {
    closeModal(namespace);
  };

  const modalOpen = (modal) => {
    openModal(namespace, modal);
    if (modal === 'history') {
      const exerciseId = (hasResult && data.result.substitution > 0)
        ? data.result.substitution
        : exerId;
      fetchHistory(namespace, exerciseId, athleteId);
      console.log('History Opened');
    }
    if (modal === 'attachment') {
      fetchDocument(namespace, attributes.doc);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={modalClose}
    >
      { activeModal === 'note' && (
        <Fragment>
          <WeightroomModalNote
            exerName={exerName}
            index={index}
            namespace={namespace}
            workout={data}
            modalClose={modalClose}
          />
        </Fragment>
      )}
      { activeModal === 'video' && (
        <Fragment>
          <ModalVideo
            name={exerName}
            video={attributes.video}
          />
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </Fragment>
      )}
      { activeModal === 'info' && (
        <Fragment>
          <ModalInfo
            name={exerName}
            index={index}
            instruction={attributes.instruction}
          />
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </Fragment>
      )}
      { activeModal === 'history' && (
        <Fragment>
          <WeightroomModalHistory
            name={exerName}
            namespace={namespace}
          />
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </Fragment>
      )}
      { activeModal === 'attachment' && (
        <Fragment>
          <Suspense fallback={<div>Loading...</div>}>
            <WeightroomModalDocument
              namespace={namespace}
            />
          </Suspense>
          <ButtonRound
            bottom
            fullWidth
            cta='Close'
            large
            noBorder
            primary
            square
            onClick={modalClose}
          />
        </Fragment>
      )}
      { activeModal === 'opt-out' && (
        <Fragment>
          <WeightroomModalOptOut
            name='Opt Out'
            namespace={namespace}
            modalClose={modalClose}
            isLoadingExercises={isLoadingExercises}
            index={index}
            workId={workId}
            workout={data}
          />
        </Fragment>
      )}
    </Modal>
  );
};

WorkoutModals.propTypes = {
  athleteId: PropTypes.number.isRequired,
  activeModal: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  exerId: PropTypes.number.isRequired,
  exerName: PropTypes.string.isRequired,
  fetchDocument: PropTypes.func.isRequired,
  fetchHistory: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isLoadingExercises: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  workId: PropTypes.number.isRequired,
};

export default WorkoutModals;
