/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import Button from '../../../../shared/components/Button/Button';
import { BoxShadow } from '../../../../shared/GlobalStyles';
import { setIsLegendInfoModalShowing } from '../../ducks/forcePlateDashboardActions';
import LegendSVG from '../../../../shared/images/fp-dash-metrics-legend.svg';

const ModalContainer = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -135px 0px 0px -265px;
  background-color: #FFFFFF;
  width: 590px;
  padding: 24px;
  border-radius: 8px;
  font-family: 'Nunito Sans';
  box-shadow: ${BoxShadow};
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Header = styled('div')`
  font-size: 16px;
  color: #9E9E9E;
`;

const Title = styled('div')`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const DescriptionImage = styled('div')`
  background-image: url(${LegendSVG});
  background-repeat: no-repeat;
  height: 225px;
  width: 545px;
`;

const LegendInfoModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setIsLegendInfoModalShowing(false));
  };

  return (
    <ModalContainer>
      <HeaderContainer>
        <Header>Legend</Header>
        <Button
          icon='remove'
          iconOnly
          onClick={handleClose}
        />
      </HeaderContainer>
      <Title>Key Performance Metrics</Title>
      <DescriptionImage />
    </ModalContainer>
  );
};

export default LegendInfoModal;
