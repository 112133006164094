/* eslint-disable no-console */
import {
  FETCH_LATEST_SESSION_START,
  FETCH_LATEST_SESSION_FULFILLED,
  FETCH_LATEST_SESSION_REJECTED,
  FETCH_MULTIDAY_SESSION_START,
  FETCH_MULTIDAY_SESSION_FULFILLED,
  FETCH_MULTIDAY_SESSION_REJECTED,
  FETCH_SESSIONS_START,
  FETCH_SESSIONS_FULFILLED,
  FETCH_SESSIONS_REJECTED,
  // UPDATE_NOTIFICATION_SETTINGS_START,
  // UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
  // UPDATE_NOTIFICATION_SETTINGS_REJECTED,
  SET_SUB_ACTIVE_PROFILE_PIC_WEBCAM_CATURE_MODAL,
  IS_SKETCH_PICKER_POPUP_OPEN,
  // FETCH_ORGANIZATION_SETTINGS_START,
  // FETCH_ORGANIZATION_SETTINGS_FULFILLED,
  // FETCH_ORGANIZATION_SETTINGS_REJECTED,
  // UPDATE_ORGANIZATION_SETTINGS_START,
  // UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
  // UPDATE_ORGANIZATION_SETTINGS_REJECTED,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';

// export const getOrganizationSettings = (accountCode) => (dispatch) => {
//   dispatch({ type: FETCH_ORGANIZATION_SETTINGS_START });
//   axiosAuthed
//     .get(`/accounts/${accountCode}/organization/settings`)
//     .then((response) => {
//       dispatch({
//         payload: response.data,
//         type: FETCH_ORGANIZATION_SETTINGS_FULFILLED,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         payload: err,
//         type: FETCH_ORGANIZATION_SETTINGS_REJECTED,
//       });
//     });
// };

// export const updateOrganizationSettings = (accountCode, values) => (dispatch) => {
//   dispatch({ type: UPDATE_ORGANIZATION_SETTINGS_START });
//   axiosAuthed
//     .put(`/accounts/${accountCode}/organization/settings`, values)
//     .then((response) => {
//       dispatch({
//         payload: response.data,
//         type: UPDATE_ORGANIZATION_SETTINGS_FULFILLED,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         payload: err,
//         type: UPDATE_ORGANIZATION_SETTINGS_REJECTED,
//       });
//     });
// };

// export const updateNotificationSettings = (values) => (dispatch) => {
//   dispatch({ type: UPDATE_NOTIFICATION_SETTINGS_START });
//   axiosAuthed
//     .put('/me/settings/notifications', values)
//     .then((response) => {
//       dispatch({
//         payload: response.data,
//         type: UPDATE_NOTIFICATION_SETTINGS_FULFILLED,
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         payload: err,
//         type: UPDATE_NOTIFICATION_SETTINGS_REJECTED,
//       });
//     });
// };

export const connectAppleAccount = (values) => () => {
  axiosAuthed
    .post('/me/auth/apple/connect', values)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err.response.headers.message);
    });
};

export const connectGoogleAccount = (values) => () => {
  axiosAuthed
    .post('/me/auth/google/connect', values)
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
      console.log(err.response.headers.message);
    });
};

export const disconnectAppleAccount = () => () => {
  axiosAuthed
    .post('/me/auth/apple/disconnect')
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err.response.headers.message);
    });
};

export const disconnectGoogleAccount = () => () => {
  axiosAuthed
    .post('/me/auth/google/disconnect')
    .then((response) => {
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err.response.headers.message);
    });
};

export const fetchLatestSession = (accountCode) => (dispatch) => {
  dispatch({ type: FETCH_LATEST_SESSION_START });
  axiosAuthed
    .get(`me/accounts/${accountCode}/workouts/summary/latest`)
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_LATEST_SESSION_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_LATEST_SESSION_REJECTED,
      });
    });
};

export const fetchMultidaySessions = (accountCode, multiday) => (dispatch) => {
  dispatch({ type: FETCH_MULTIDAY_SESSION_START });
  console.log('fetch multiday session fired', multiday);
  axiosAuthed
    .get(`me/accounts/${accountCode}/workouts/summary/multiday`, {
      params: {
        numDays: multiday,
      },
    })
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_MULTIDAY_SESSION_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_MULTIDAY_SESSION_REJECTED,
      });
    });
};

export const fetchSessions = (
  accountCode,
  dateRangeStart = null,
  dateRangeEnd = null,
) => (dispatch) => {
  dispatch({ type: FETCH_SESSIONS_START });
  axiosAuthed
    .get(
      `me/accounts/${accountCode}/workouts/summary?dateRangeStart=${dateRangeStart}&dateRangeEnd=${dateRangeEnd}`,
    )
    .then((response) => {
      dispatch({
        payload: response.data,
        type: FETCH_SESSIONS_FULFILLED,
      });
    })
    .catch((err) => {
      dispatch({
        payload: err,
        type: FETCH_SESSIONS_REJECTED,
      });
    });
};
export const setSubActiveReduxModal = (activeSubModal) => (
  {
    type: SET_SUB_ACTIVE_PROFILE_PIC_WEBCAM_CATURE_MODAL,
    payload: activeSubModal,
  }
);
export const setIsSketchPickerPopupOpen = ({ colorCategory, bool }) => (
  {
    type: IS_SKETCH_PICKER_POPUP_OPEN,
    payload: { colorCategory, bool },
  }
);
