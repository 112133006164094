import React, { Fragment, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { animated, useTransition, useSpring } from 'react-spring/web.cjs';

// import WeightroomDatePicker from '../container/WeightroomDatePicker';
import AnimatedWorkoutHeader from './AnimatedWorkoutHeader';
import Spinner from './Spinner';
import WeightroomPanelHeader from '../container/WeightroomPanelHeader';
import WeightroomWorkoutList from '../container/WeightroomWorkoutList';
import Complete from './Complete';

// const WorkoutPanelHeader = styled('div')`
//   display: block;
//   padding: 20px 30px;
//   @media screen and (orientation: portrait) {
//     padding: 20px 20px;
//   }
// `;

// const PanelHeaderWrapper = styled('div')`
//   .panel-header-animation {
//     overflow: hidden;
//   }
// `;

export const SpinnerContainer = styled('div')`
  display: flex;
  height: 100%;
  /* ${props => (
    props.activeScreens === 4
      ? 'height: calc(50vh - 137px);'
      : 'height: calc(100vh - 137px);'
  )} */
  flex: 1;
  position: relative;

  /* @media screen and (orientation: portrait) {
    ${props => (
    props.activeScreens <= 2 && `
      height: calc(50vh - 137px);
    `
  )}
  }

  @media screen and (orientation: landscape) {
    ${props => (
    props.activeScreens <= 2 && `
      height: calc(100vh - 137px);
    `
  )}
  } */
`;

const PanelWorkouts = ({
  activeScreens,
  // activeWorkoutIndex,
  closePanel,
  closeWorkout,
  isLoading,
  isWorkoutComplete,
  namespace,
}) => {
  // const PanelHeaderRef = useRef();

  const handleClosePanel = () => {
    closePanel(namespace);
  };

  const handleCloseWorkout = () => {
    closeWorkout(namespace);
  };

  // const isWorkoutActive = activeWorkoutIndex !== null;
  // const isWorkoutActiveOrComplete = isWorkoutActive || isWorkoutComplete;

  // const transitions = useTransition(!isWorkoutActiveOrComplete, null, {
  //   from: { height: 0 },
  //   enter: { height: 76 },
  //   leave: { height: 0 },
  // });

  const completeTransition = useTransition(isWorkoutComplete, null, {
    from: { opacity: 0, height: '100%' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  // const props = useSpring({
  //   to: { opacity: isWorkoutComplete ? 1 : 0 },
  //   from: { opacity: 0 },
  // });
  
  return (
    <Fragment>
      <WeightroomPanelHeader
        namespace={namespace}
      />
      {/* {transitions.map(({ item, key, props }) => (
        item && (
          <PanelHeaderWrapper key={key}>
            <animated.div className='panel-header-animation' style={props}>
              <WorkoutPanelHeader
                ref={PanelHeaderRef}
              >
                <WeightroomDatePicker
                  namespace={namespace}
                />
              </WorkoutPanelHeader>
            </animated.div>
          </PanelHeaderWrapper>
        )
      ))} */}
      {/* <animated.div style={props}>
        <Complete
          handleCloseWorkout={handleCloseWorkout}
          handleClosePanel={handleClosePanel}
        />
      </animated.div> */}

      <AnimatedWorkoutHeader
        namespace={namespace}
      />
      {isLoading ? (
        <SpinnerContainer
          activeScreens={activeScreens}
        >
          <Spinner />
        </SpinnerContainer>
      ) : (
        <WeightroomWorkoutList
          namespace={namespace}
        />
      )}

      {completeTransition.map(({ item, key, props }) => (
        item && (
          <animated.div key={key} style={props}>
            <Complete
              handleCloseWorkout={handleCloseWorkout}
              handleClosePanel={handleClosePanel}
            />
          </animated.div>
        )
      ))}
    </Fragment>
  );
};

PanelWorkouts.propTypes = {
  activeScreens: PropTypes.number.isRequired,
  closePanel: PropTypes.instanceOf(Function).isRequired,
  closeWorkout: PropTypes.instanceOf(Function).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isWorkoutComplete: PropTypes.bool.isRequired,
  namespace: PropTypes.string.isRequired,
};

export default memo(PanelWorkouts);
