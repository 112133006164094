import { connect } from 'react-redux';
import WorkoutList from '../presentational/WorkoutList';
import {
  completeWorkout,
  closeWorkout,
  decreaseCompletedSets,
  increaseCompletedSets,
  nextWorkout,
  prevWorkout,
  restartQuestions,
  selectQuestion,
  selectWorkout,
  setIsNavigatingSuperset,
  storeWorkoutsBySuperset,
} from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  activeExerType: state[ownProps.namespace].activeExerType,
  activeQuestionIndex: state[ownProps.namespace].activeQuestionIndex,
  activeScreens: state.weightRoom.activeScreens,
  activeWorkout: state[ownProps.namespace].activeWorkout,
  activeWorkoutIndex: state[ownProps.namespace].activeWorkoutIndex,
  settings: state.weightRoom.currentUser.settings,
  isNavigatingSuperset: state[ownProps.namespace].isNavigatingSuperset,
  isWorkoutActive: state[ownProps.namespace].isWorkoutActive,
  panelDimensions: state[ownProps.namespace].panelDimensions,
  data: state[ownProps.namespace].workouts,
  workoutsError: state[ownProps.namespace].workoutsError,
  workoutsBySuperset: state[ownProps.namespace].workoutsBySuperset,
});

const mapDispatchToProps = dispatch => ({
  closeWorkout: (namespace) => {
    dispatch(closeWorkout(namespace));
  },
  completeWorkout: (namespace) => {
    dispatch(completeWorkout(namespace));
  },
  decreaseCompletedSets: (namespace, group, workoutIndex) => {
    dispatch(decreaseCompletedSets(namespace, group, workoutIndex));
  },
  increaseCompletedSets: (namespace, group, workoutIndex) => {
    dispatch(increaseCompletedSets(namespace, group, workoutIndex));
  },
  selectWorkout: (id, index, exerId, exerType, namespace) => {
    dispatch(selectWorkout(id, index, exerId, exerType, namespace));
  },
  nextWorkout: (id, index, exerId, exerType, namespace) => {
    dispatch(nextWorkout(id, index, exerId, exerType, namespace));
  },
  prevWorkout(id, index, exerId, exerType, namespace) {
    dispatch(prevWorkout(id, index, exerId, exerType, namespace));
  },
  restartQuestions(namespace) {
    dispatch(restartQuestions(namespace));
  },
  selectQuestion: (id, index, namespace) => {
    dispatch(selectQuestion(id, index, namespace));
  },
  setIsNavigatingSuperset: (namespace, value) => {
    dispatch(setIsNavigatingSuperset(namespace, value));
  },
  storeWorkoutsBySuperset: (namespace, workouts) => {
    dispatch(storeWorkoutsBySuperset(namespace, workouts));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkoutList);
