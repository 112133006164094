// modal for confirming deletion of selected documents

import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import DatePicker from 'react-datepicker';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import NoHoverDeleteButton from './NoHoverDeleteButton';
import Text from '../../../../../shared/components/Text/Text';
import HeaderText from '../../../../../shared/components/HeaderText/HeaderText';
import Toggle from '../../../../../shared/components/Toggle/Toggle';
import iconSet from '../../../../../shared/images/teambuildr-selection.json';
import useReseller from '../../hooks/useReseller';

const ModalWrapper = styled('div')`
  display: flex;
  width: 100%;

  .modal-button {
    background-color: #f24540;
    color: white;
  }
`;

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const SubscriptionTextToggleWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  text-align: center;
  
  .inner-text {
    display: flex;
    width: 350px;
    justify-content: space-evenly;
    margin-top: 10px;
  }
`;

const WorkoutTextToggleWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  .inner-text {
    display: flex;
    width: 350px;
    justify-content: space-evenly;
    margin-top: 10px;
    align-items: center;

    .react-datepicker__input-container {
      input {
        width: 120px;
      }
    }
    
    p {
      margin-right: 10px;
    }

    .custom-date-text {
      width: 250px;
      margin-right: 0px;
    }
    .react-toggle {
      margin-right: 10px;
    }
  }
`;

const OptionsWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DatePickerWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ModalHeader = styled('div')`
  position: absolute;
  top: 0;
  padding: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalConfirmCancel = ({
  currentSubscriber,
  onRequestClose,
  currentSubscription,
  setActiveModal,
  setCurrentSubscriber,
}) => {
  const {
    handleCancelSubscription,
    currentUser,
    subscribers,
  } = useReseller();

  const [isSubscriptionToggleFlipped, setIsSubscriptionToggleFlipped] = useState(false);
  const [isWorkoutToggleFlipped, setIsWorkoutToggleFlipped] = useState(false);
  const [isRefundToggleFlipped, setIsRefundToggleFlipped] = useState(true);
  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = (
    values,
  ) => {
    const isCurrentSubscriber = (subscriber) => subscriber.id === currentSubscriber.id;
    const subscriberIndex = subscribers.findIndex(isCurrentSubscriber);
    const newMoment = moment(startDate);
    const submitValues = {
      accountCode: currentUser.accountCode,
      subscriberId: currentSubscriber.id,
      subscriptionId: currentSubscription.id,
      body: {
        cancelAtPeriodEnd: isSubscriptionToggleFlipped,
        endCalendarAssignmentNow: isWorkoutToggleFlipped,
        prorate: isRefundToggleFlipped,
      },
      subscriberIndex,
    };
    if (!isWorkoutToggleFlipped) {
      submitValues.body.endCalendarDate = newMoment.format('YYYY-MM-DD');
    }
    handleCancelSubscription(submitValues);
    setCurrentSubscriber(null);
    onRequestClose();
  };

  return (
    <Formik
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleSubmit(values);
          setSubmitting(false);
        });
      }}
      render={({
        submitForm,
      }) => (
        <ModalWrapper>
          <NavigationWrapper>
            <Text
              className='arrow-div'
              onClick={() => {
                setActiveModal('backwards_subscriber_detail_modal');
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={15}
                icon='left-arrow'
              />
            </Text>
            <Text
              className='close arrow-div'
              onClick={() => {
                onRequestClose();
                setCurrentSubscriber(null);
              }}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={13}
                icon='remove'
              />
            </Text>
          </NavigationWrapper>
          <OptionsWrapper>
            <ModalHeader>
              <HeaderText fontWeight={300}>Cancel Subscription</HeaderText>
            </ModalHeader>
            <SubscriptionTextToggleWrapper>
              <Text
                fontWeight='600'
                fontSize={16}
              >
                When would you like to cancel the
                {' '}
                {currentSubscription.programName}
                ?
              </Text>
              <div className='inner-text'>
                <Text fontSize={14} fontWeight='600'>Now</Text>
                <Toggle
                  checked={isSubscriptionToggleFlipped}
                  icons={false}
                  onChange={() => {
                    setIsSubscriptionToggleFlipped(!isSubscriptionToggleFlipped);
                  }}
                />
                <Text fontSize={14} fontWeight='600'>At the end of the current period</Text>
              </div>
            </SubscriptionTextToggleWrapper>
            {!isSubscriptionToggleFlipped ? (
              <WorkoutTextToggleWrapper>
                <Text
                  fontWeight='600'
                  fontSize={16}
                >
                  Refund remaining time on subscription?
                </Text>
                <div className='inner-text'>
                  <Text fontSize={14} fontWeight='600'>No Refund</Text>
                  <Toggle
                    checked={isRefundToggleFlipped}
                    icons={false}
                    onChange={() => {
                      setIsRefundToggleFlipped(!isRefundToggleFlipped);
                    }}
                  />
                  <Text fontSize={14} fontWeight='600'>Refund Subscription</Text>
                </div>
              </WorkoutTextToggleWrapper>
            ) : null}
            <WorkoutTextToggleWrapper>
              <Text
                fontWeight='600'
                fontSize={16}
              >
                When should workout access end?
              </Text>
              <div className='inner-text'>
                <Text fontSize={14} fontWeight='600'>Now</Text>
                <Toggle
                  checked={isWorkoutToggleFlipped}
                  icons={false}
                  onChange={() => {
                    setIsWorkoutToggleFlipped(!isWorkoutToggleFlipped);
                  }}
                />
                <Text fontSize={14} fontWeight='600' className='custom-date-text'>Custom Date</Text>
                <DatePickerWrapper>
                  {/* <Text>Custom Date</Text> */}
                  <DatePicker
                    disabled={!isWorkoutToggleFlipped}
                    onChange={(date) => setStartDate(date)}
                    selected={startDate}
                  />
                </DatePickerWrapper>
              </div>
            </WorkoutTextToggleWrapper>
          </OptionsWrapper>
          <NoHoverDeleteButton
            bottom
            fullWidth
            cta='Cancel Subscription'
            className='modal-button'
            large
            noBorder
            primary
            square
            onClick={() => {
              submitForm();
            }}
          />
        </ModalWrapper>
      )}
    />
  );
};

ModalConfirmCancel.propTypes = {

};

export default ModalConfirmCancel;
