/**
 * The options box is the component that rests above the list of conversations and notifications.
 * In contains components for entering into a bulk editable list that allows the user
 * to select multiple notifications/conversations, and to create a new messadge (in the case
 * that the user is in the conversations component) and a dropdown to open a filter modal drop-
 * down that allows the user to filter the notifications by type
 */

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import useConversations from '../hooks/useConversations';
import useNotifications from '../hooks/useNotifications';
import Text from '../../../../shared/components/Text/Text';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import FilterDropdown from '../../../../shared/components/FilterDropdown/FilterDropdown';
import AccordionFilters from './AccordionFilters';

import {
  setActiveReduxModal,
} from '../../ducks/conversationsActions';

import {
  clearActiveNotificationFilters,
  setLocalNotificationFilters,
  setLocalToActiveNotificationFilters,
  setActiveToLocalNotificationFilters,
  fetchNotifications,
  setIsFilterDropdownShowing,
} from '../../ducks/notificationsActions';

const OptionsBox = ({
  sidebarSwitchConvos,
  setSidebarSwitchConvos,
  isCheckboxesOpen,
  setIsCheckboxesOpen,
  setSelectedConvos,
  setSelectedNotifications,
}) => {
  const {
    handleMarkAsRead,
    handleDeleteConversations,
  } = useConversations();

  const {
    handleMarkNotificationsAsRead,
    handleDeleteNotifications,
  } = useNotifications();

  const dispatch = useDispatch();

  const setActiveModal = (activeModalString) => {
    dispatch(setActiveReduxModal(activeModalString));
  };

  const selectedConvos = useSelector((state) => state.conversations.data.selectedConvos);
  const activeFilter = useSelector((state) => state.notifications.data.notificationFilter);
  const selectedNotifications = useSelector(
    (state) => state.notifications.data.selectedNotifications,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const { userSettings } = currentUser;
  const unreadNotificationsNumber = useSelector(
    (state) => state.notifications.data.unreadNotificationsNumber,
  );
  const unreadConversationsNumber = useSelector(
    (state) => state.conversations.data.unreadConversationsNumber,
  );

  const notificationTypes = useSelector(
    (state) => state.notifications.data.notificationTypes,
  );

  // const [isDropdown, setIsDropdown] = useState(false);

  const isFilterDropdownShowing = useSelector(
    (state) => state.notifications.data.isFilterDropdownShowing,
  );
  const notificationFilterCount = useSelector(
    (state) => state.notifications.data.notificationFilterCount,
  );

  const OptionsBoxContainer = styled('aside')`
    height: 75px;
    width: 100%;
    background: #F5F5F5;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;

    .arrow-box {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      opacity: ${isCheckboxesOpen ? '0' : '1'};
      cursor: ${isCheckboxesOpen ? 'default' : 'pointer'};
      :hover {
        background: rgba(0,0,0,0.1);
      }
    }

    .arrow-box1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
      :hover {
        background: rgba(0,0,0,0.1);
      }
    }

    .arrow-box2 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
      :hover {
        background: rgba(0,0,0,0.1);
      }
    }

    .view-box {
      min-width: 120px;
      padding: 8px;
      border-radius: 3px;
      cursor: ${isCheckboxesOpen ? 'default' : 'pointer'};
      opacity: ${isCheckboxesOpen && (!Object.keys(selectedConvos).length && Object.keys(selectedNotifications).length) ? '0' : '1'};
      background: ${sidebarSwitchConvos ? 'rgba(0,0,0,0.1)' : ''};
      .notification-dot-conversations {
        width: 12px;
        height: 12px;
        background: #FF6600;
        position: absolute;
        margin-bottom: 30px;
        margin-left: 120px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 7px;
        font-family: 'Nunito Sans';
        font-weight: bold;
      }
    }

    .view-box1 {
      min-width: 120px;
      padding: 8px;
      border-radius: 3px;
      cursor: ${isCheckboxesOpen ? 'default' : 'pointer'};
      opacity: ${isCheckboxesOpen && (!Object.keys(selectedConvos).length && !Object.keys(selectedNotifications).length) ? '0' : '1'};
      background: ${!sidebarSwitchConvos ? 'rgba(0,0,0,0.1)' : ''};
      .notification-dot-notifications {
        width: 12px;
        height: 12px;
        background: #FF6600;
        position: absolute;
        margin-bottom: 30px;
        margin-left: 115px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 7px;
        font-family: 'Nunito Sans';
        font-weight: bold;
      }
    }

    .delete-box {
      min-width: 120px;
      padding: 8px;
      border-radius: 3px;
      cursor: ${isCheckboxesOpen && (Object.keys(selectedConvos).length || Object.keys(selectedNotifications).length) ? 'pointer' : 'default'};
      opacity: ${isCheckboxesOpen && (!Object.keys(selectedConvos).length && !Object.keys(selectedNotifications).length) ? '0' : '1'};
      background: rgba(0,0,0,0.1);
      :hover {
        background: rgba(0,0,0,0.3);
      }
    }

    .read-box {
      min-width: 120px;
      padding: 8px;
      border-radius: 3px;
      cursor: ${isCheckboxesOpen && (Object.keys(selectedConvos).length || Object.keys(selectedNotifications).length) ? 'pointer' : 'default'};
      opacity: ${isCheckboxesOpen && (!Object.keys(selectedConvos).length && !Object.keys(selectedNotifications).length) ? '0' : '1'};
      background: rgba(0,0,0,0.1);
      :hover {
        background: rgba(0,0,0,0.3);
      }
    }
  `;

  const FilterContainer = styled('div')`
    display: flex;
    user-select: none;
    .notification-dot-filters {
        width: 12px;
        height: 12px;
        background: #FF6600;
        position: absolute;
        margin-top: -1px;
        margin-left: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 7px;
        font-family: 'Nunito Sans';
        font-weight: bold;
      }
  `;

  const getIconSize = () => {
    if (activeFilter === 'FIST_BUMP') {
      return 35;
    }
    return 20;
  };

  const clearAllFilters = () => {
    dispatch(clearActiveNotificationFilters({}));
    batch(() => {
      dispatch(setLocalNotificationFilters({}));
      dispatch(fetchNotifications(currentUser.accountCode, true));
      dispatch(setIsFilterDropdownShowing(false));
    });
  };

  const filterSubmitAction = () => {
    dispatch(setLocalToActiveNotificationFilters());
    batch(() => {
      dispatch(fetchNotifications(currentUser.accountCode, true));
      dispatch(setIsFilterDropdownShowing(false));
    });
  };

  const closeFilterDropdown = () => {
    dispatch(setIsFilterDropdownShowing(false));
    dispatch(setActiveToLocalNotificationFilters());
  };

  /**
   * @returns a dropdown component that does the following on load:
   * creates a dictionary by iterating the notification types.  The key/value pair of the dictionary
   * describes: key - the broad definition of the type, and the value, interior elements that fall
   * under that broad definition.  So, filter types are separated into groups, such as feed,
   * journal, wearables, etc.
   * - The visible drowdown component consists of a filter container icon that dispatches a function
   * to determine whether the dropdown is showing.  If that div is pressed, the global state
   * 'isFilterDropdownShowing is set to true.
   * That global boolean is passed to the FilterDropdown component, which governs the filter
   * dropdow itself (see the FilterDrowdown for more information)
   */

  const notificationDropdown = () => {
    const collapsibleTypesDictionary = {};
    if (!sidebarSwitchConvos) {
      notificationTypes.forEach((notificationType) => {
        if (notificationType.isFilterable) {
          if (!collapsibleTypesDictionary[notificationType.notificationGroupName]) {
            collapsibleTypesDictionary[notificationType.notificationGroupName] = [];
          }
          collapsibleTypesDictionary[notificationType.notificationGroupName].push(notificationType);
        }
      });
      return (
        <FilterContainer>
          <div
            className='arrow-box'
            onClick={() => {
              dispatch(setIsFilterDropdownShowing(!isFilterDropdownShowing));
            }}>
            <IcomoonReact
              iconSet={iconSet}
              size={getIconSize()}
              icon='filter'
              color='grey'
            />
          </div>
          <FilterDropdown
            headerActionButtonText='Clear all'
            headerAction={clearAllFilters}
            closeFilterDropdown={closeFilterDropdown}
            isShowing={isFilterDropdownShowing}
            headerActionIcon='remove'
            filterHeaderText='Notification Filters'
            filterSubmitAction={filterSubmitAction}
            submitActionText='Update Filters'
            >
            <AccordionFilters collapsibleTypesDictionary={collapsibleTypesDictionary} />
          </FilterDropdown>
          {!isCheckboxesOpen ? notificationFilterCount ? (
            <div className='notification-dot-filters'>
              {notificationFilterCount < 10 ? notificationFilterCount : '9+'}
            </div>
          ) : null : null}
        </FilterContainer>
      );
    }
    return (
      currentUser.userSettings !== undefined && (currentUser.userSettings.messaging !== 0) ? (
        <div
          onClick={() => {
            if (!isCheckboxesOpen) {
              setActiveModal('create-conversation-modal');
            }
          }}
          className='arrow-box'
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='send-alt'
            color='grey'
          />
        </div>
      ) : null
    );
  };

  return (
    <OptionsBoxContainer>
      <div
        onClick={() => {
          setIsCheckboxesOpen(!isCheckboxesOpen);
          setSelectedConvos({});
          setSelectedNotifications({});
        }}
        className='arrow-box1'
        style={{
          marginBottom: '4px',
        }}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={isCheckboxesOpen ? 15 : 20}
          icon={isCheckboxesOpen ? 'remove' : 'pencil'}
          color='grey'
        />
      </div>
      {userSettings?.messaging || isCheckboxesOpen ? (
        <div
          className={isCheckboxesOpen ? 'read-box' : 'view-box'}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => {
            if (isCheckboxesOpen) {
              const values = Object.values(selectedConvos);
              if (sidebarSwitchConvos) {
                handleMarkAsRead({ readArray: values, accountCode: currentUser.accountCode });
                setIsCheckboxesOpen(!isCheckboxesOpen);
                setSelectedConvos({});
                setSelectedNotifications({});
              } else {
                handleMarkNotificationsAsRead(
                  currentUser.accountCode, Object.keys(
                    selectedNotifications,
                  ).map((id) => parseInt(id, 10)),
                );
              }
            } else {
              setSidebarSwitchConvos(true);
            }
          }}
        >
          {!isCheckboxesOpen ? (
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='message-bubbles'
            />
          ) : null}
          <Text
            fontSize={14}
            style={{
              marginLeft: '5px',
            }}
          >
            {!isCheckboxesOpen ? 'Conversations' : 'Mark As Read'}
          </Text>
          {!isCheckboxesOpen ? unreadConversationsNumber ? (
            <div className='notification-dot-conversations'>
              {unreadConversationsNumber < 10 ? unreadConversationsNumber : '9+'}
            </div>
          ) : null : null}
        </div>
      ) : null}
      <div
        className={isCheckboxesOpen ? 'delete-box' : 'view-box1'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          if (isCheckboxesOpen) {
            const values = Object.values(selectedConvos);
            if (sidebarSwitchConvos) {
              handleDeleteConversations(
                { readArray: values, accountCode: currentUser.accountCode },
              );
              setIsCheckboxesOpen(!isCheckboxesOpen);
              setSelectedConvos({});
              setSelectedNotifications({});
            } else {
              handleDeleteNotifications(
                currentUser.accountCode, Object.keys(
                  selectedNotifications,
                ).map((id) => parseInt(id, 10)),
              );
            }
          } else {
            setSidebarSwitchConvos(false);
          }
        }}
      >
        {!isCheckboxesOpen ? (
          <IcomoonReact
            iconSet={iconSet}
            size={15}
            icon='alert-on'
            color='grey'
          />
        ) : null}
        <Text
          fontSize={14}
          style={{
            marginLeft: '5px',
          }}
        >
          {!isCheckboxesOpen ? 'Notifications' : 'Delete'}
        </Text>
        {/**
         * The unread notifications number is a piece of state that keeps track of,
         * you guessed it, how many notifications are currently marked as 'unread'.
         * if the checkboxes aren't open, two options are possible: 1. the number
         * of unread notifications is less than 9, in which case we desplay the
         * value itself, otherwise we display the string '9+'
         */}
        {!isCheckboxesOpen ? unreadNotificationsNumber ? (
          <div className='notification-dot-notifications'>
            {unreadNotificationsNumber < 10 ? unreadNotificationsNumber : '9+'}
          </div>
        ) : null : null}
      </div>
      {notificationDropdown()}
    </OptionsBoxContainer>
  );
};

OptionsBox.propTypes = {
  setSidebarSwitchConvos: PropTypes.func.isRequired,
  sidebarSwitchConvos: PropTypes.bool.isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
  setIsCheckboxesOpen: PropTypes.func.isRequired,
  setSelectedNotifications: PropTypes.func.isRequired,
  setSelectedConvos: PropTypes.func.isRequired,
};

export default OptionsBox;
