/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import {
  PieChart, Pie, Cell,
} from 'recharts';
import { useTheme } from 'emotion-theming';
import Text from '../../../../shared/components/Text/Text';
import Picker from '../../../../shared/components/SportsHub/Picker';
import { setAthletesInClickedZone } from '../../ducks/loadMonitoringDashboardActions';

const DonutChart = ({
  arrayForDonutChartCount,
  greenAthletesForCurrentQuestion,
  yellowAthletesForCurrentQuestion,
  redAthletesForCurrentQuestion,
  containerWidth,
  /** props for picker */
  showTotals,
  listOfQuestionsForPicker,
  totalScoresObjectToAddToPickerQuestionsArray,
  reduxFunction,
  visibleContainerItem,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const arrayOfAllQuestions = useSelector((state) => state.loadMonitoringDashboard.data.questionnaireReportData?.arrayOfAllQuestions);
  const idOfCurrentQuestionInQuestionnairePicker = useSelector((state) => state.loadMonitoringDashboard.data.currentQuestionInDonutChartQuestionnairePicker?.id);
  const abbreviationForCurrentQuestion = arrayOfAllQuestions.filter((question) => question.id === idOfCurrentQuestionInQuestionnairePicker)[0]?.abbr;
  const formattedAbbreviationOfCurrentQuestionInPicker = idOfCurrentQuestionInQuestionnairePicker === 'Total Scores' ? 'Total Scores' : abbreviationForCurrentQuestion?.toUpperCase();

  const handleColorClick = (entry) => {
    if (entry.name === 'red') {
      dispatch(setAthletesInClickedZone(redAthletesForCurrentQuestion));
    }
    if (entry.name === 'yellow') {
      dispatch(setAthletesInClickedZone(yellowAthletesForCurrentQuestion));
    }
    if (entry.name === 'green') {
      dispatch(setAthletesInClickedZone(greenAthletesForCurrentQuestion));
    }
  };

  const DonutChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    width: ${(props) => (props.containerWidth <= 850 ? '100%' : '300px')};
    min-height: 350px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1);
    border-radius: 24px;
    padding-top: 24px;

    .chart-cell {
      transition: opacity 0.3s ease;
    }
    
    .chart-cell:hover {
      opacity: 0.3;
    }
  `;

  const PieChartContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
  `;

  const TextContainer = styled('span')`
    display: flex:
    justify-content: center;
    align-items: center;
    width: 225px;
    text-align: center;
`;

  const CustomLabel = styled('div')`
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.08);
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    display: flex;
    border-radius: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

  const LabelText = styled('div')`
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: bold;
`;

  const data02 = arrayForDonutChartCount;

  const sumOfArray = () => {
    let sum = 0;
    // eslint-disable-next-line no-return-assign
    arrayForDonutChartCount.map((objectInArray) => sum += objectInArray.value);
    return sum;
  };

  const defaultData = [
    { name: 'A1', value: 1 },
    { name: 'A2', value: 1 },
    { name: 'B1', value: 1 },
    { name: 'B2', value: 1 },

  ];

  /** red, green, yellow] */
  const COLORS = ['#FF3737', '#0DCC8A', '#FFCB47'];

  const renderCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, value, fill,
  // eslint-disable-next-line consistent-return
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    if (value !== 0) {
      return (
        <foreignObject style={{ pointerEvents: 'none' }} x={x} y={y} width='50' height='50'>
          <CustomLabel>
            <LabelText>
              {value}
            </LabelText>
          </CustomLabel>
        </foreignObject>
      );
    }
  };

  return (
    <DonutChartContainer containerWidth={containerWidth}>
      {arrayForDonutChartCount?.length && sumOfArray() !== 0 ? (
        <>
          <PieChartContainer>
            {/* <Picker list={!showTotals ? listOfQuestionsForPicker : [...listOfQuestionsForPicker, ...totalScoresObjectToAddToPickerQuestionsArray]} reduxFunction={reduxFunction} visibleContainerItem={visibleContainerItem} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' small minWidth={175} itemPickerContainerMaxWidth={containerWidth < 430 && 200} /> */}
            <Picker list={!showTotals ? listOfQuestionsForPicker : [...listOfQuestionsForPicker]} reduxFunction={reduxFunction} visibleContainerItem={visibleContainerItem} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' small minWidth={175} itemPickerContainerMaxWidth={containerWidth < 430 && 200} />
            <Text fontSize={12} fontWeight={700} style={{ marginTop: '15px' }}>Athletes In Each Threshold Zone</Text>
            <Text fontSize={12} fontWeight={700}>{formattedAbbreviationOfCurrentQuestionInPicker}</Text>
            <PieChart
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              width={200}
              height={200}
            >
              <Pie
                isAnimationActive={false}
                data={data02}
                dataKey='value'
                cx='50%'
                cy='50%'
                innerRadius={35}
                outerRadius={60}
                fill='#82ca9d'
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {data02.map((entry, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} onClick={() => handleColorClick(entry)} className='chart-cell' style={{ cursor: 'pointer' }} />
                ))}
              </Pie>
            </PieChart>
          </PieChartContainer>
        </>
      ) : (
        <>
          <PieChartContainer>
            {/* <Picker list={!showTotals ? listOfQuestionsForPicker : [...listOfQuestionsForPicker, ...totalScoresObjectToAddToPickerQuestionsArray]} reduxFunction={reduxFunction} visibleContainerItem={visibleContainerItem} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' small minWidth={195} /> */}
            <Picker list={!showTotals ? listOfQuestionsForPicker : [...listOfQuestionsForPicker]} reduxFunction={reduxFunction} visibleContainerItem={visibleContainerItem} icon={{ name: 'swap', size: 16, color: 'white' }} pickerButtonColor='#444444' pickerFontColor='white' small minWidth={195} itemPickerContainerMaxWidth={containerWidth < 430 && 200} />
            <Text fontSize={12} fontWeight={700} style={{ marginTop: '15px' }}>Athletes In Each Threshold Zone</Text>
            <Text fontSize={12} fontWeight={700}>{formattedAbbreviationOfCurrentQuestionInPicker}</Text>
            <PieChart width={200} height={200} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Pie data={defaultData} dataKey='value' cx='50%' cy='50%' innerRadius={35} outerRadius={60} fill='grey' />
            </PieChart>
            <TextContainer>
              <Text fontSize={12} fontWeight={700} color={theme.colors.lightText[0]}>No Status</Text>
            </TextContainer>
          </PieChartContainer>
        </>
      )}
    </DonutChartContainer>
  );
};

export default DonutChart;
