import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useSelector, useDispatch, batch } from 'react-redux';
import moment from 'moment';
import tz from 'moment-timezone';

import {
  setActiveReduxModal,
  setScheduledMessageRedux,
} from '../../ducks/conversationsActions';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import Text from '../../../../shared/components/Text/Text';

const OptionsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;

  p {
    font-size: 12px;
    color: ${(props) => props.theme.colors.lightText[0]};
  }
  @media screen and (min-width: 768px) and (max-width: 850px) {
    .options-label {
      display: none;
    }
  }

  @media screen and (max-width: 550px) {
    .options-label {
      display: none;
    }
  }
`;

const Option = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 165px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;

  p {
    color: ${(props) => props.theme.colors.text};
  }


  @media screen and (max-width: 1100px) {
    margin-left: 5px;

    &:nth-child(2) {
      margin-left: 5px;
    }
  }

  @media screen and (max-width: 960px) {
    width: 140px;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media screen and (max-width: 540px) {
    width: 120px;
    min-width: 70px;
    border: 0px;
    background: transparent;
    padding: 0px;
    margin: 0px 0px 0px 5px;
    box-shadow: none;
    justify-content: start;
    .main-icon {
      display: none!important;
    }
    &:nth-child(3) {
      width: 120px;
    }
  }
`;

const Option2 = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  width: 154px;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;

  p {
    color: ${(props) => props.theme.colors.text};
  }
  @media screen and (max-width: 1100px) {
    margin-left: 5px;
  }
  @media screen and (max-width: 960px) {
    width: 120px;
    padding-left: 5px;
    padding-right: 5px;
  }

  @media screen and (max-width: 540px) {
    width: 100px;
    min-width: 80px;
    border: 0px;
    background: transparent;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
  }
`;

const ScheduleSet = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: auto;
  height: 40px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 15px;
`;

const MessagesSendAsRow = ({
  currentUser,
  setSelectedOptions,
  selectedOptions,
  scheduledMessage,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='lower-box'>
      <OptionsWrapper>
        {/* <Text 
          className='options-label'
          style={{
            minWidth: '65px',
          }}
        >
          Also send as
        </Text> */}
        <Option
          onClick={() => {
            const newSelectedOptions = { ...selectedOptions };
            newSelectedOptions.sms = !selectedOptions.sms;
            setSelectedOptions(newSelectedOptions);
          }}
          style={{
            borderColor: selectedOptions.sms ? '#0dcc8a' : null,
            borderWidth: selectedOptions.sms ? '1px' : null,
            borderStyle: selectedOptions.sms ? 'solid' : null,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              background: !selectedOptions.sms ? '' : '#10cd8c',
              border: !selectedOptions.sms ? '1px solid black' : '1px solid #10cd8c',
              margin: '5px',
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={8}
              icon='checkmark'
              color='white'
            />
          </div>
          <IcomoonReact
            className='main-icon'
            iconSet={iconSet}
            size={15}
            icon='phone'
          />
          <Text style={{
            marginLeft: '5px',
          }}
          >
            Send as SMS
          </Text>
        </Option>
        <Option
          onClick={() => {
            const newSelectedOptions = { ...selectedOptions };
            newSelectedOptions.em = !selectedOptions.em;
            setSelectedOptions(newSelectedOptions);
          }}
          style={{
            borderColor: selectedOptions.em ? '#0dcc8a' : null,
            borderWidth: selectedOptions.em ? '1px' : null,
            borderStyle: selectedOptions.em ? 'solid' : null,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              background: !selectedOptions.em ? '' : '#10cd8c',
              border: !selectedOptions.em ? '1px solid black' : '1px solid #10cd8c',
              margin: '5px',
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={8}
              icon='checkmark'
              color='white'
            />
          </div>
          <IcomoonReact
            className='main-icon'
            iconSet={iconSet}
            size={15}
            icon='envelope-closed'
          />
          <Text style={{
            marginLeft: '5px',
          }}
          >
            Send as Email
          </Text>
        </Option>
        {!Object.keys(scheduledMessage).length ? (
          <Option2
            onClick={() => {
              dispatch(setActiveReduxModal('schedule-modal'));
            }}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='clock'
            />
            <Text style={{
              marginLeft: '10px',
            }}
            >
              Schedule
            </Text>
          </Option2>
        ) : (
          <ScheduleSet
            onClick={() => {
              dispatch(setScheduledMessageRedux({}));
            }}
          >
            <Text style={{
              marginLeft: '5px',
            }}
            >
              {moment(scheduledMessage).tz(currentUser.userTimeZone || currentUser.userSettings.timeZone).format('MMMM Do, h:mm a')}
            </Text>
          </ScheduleSet>
        ) }
      </OptionsWrapper>
    </div>
  );
};

MessagesSendAsRow.propTypes = {
  currentUser: PropTypes.instanceOf(Object).isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  selectedOptions: PropTypes.instanceOf(Array).isRequired,
  scheduledMessage: PropTypes.instanceOf(Object).isRequired,
};

export default MessagesSendAsRow;
