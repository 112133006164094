/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setSaveDashboardModal } from '../../ducks/sportCoachDashboardActions';
import SaveDashboardModal from './SaveDashboardModal';

const SaveDashboardModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const SaveDashboardModalContainer = () => {
  const dispatch = useDispatch();
  const saveDashboardModalShowing = useSelector(
    (state) => state.sportCoachDashboard.ui.saveDashboardModalShowing,
  );

  return (
    <>
      <SaveDashboardModal />
      <SaveDashboardModalOverlay
        isActive={saveDashboardModalShowing}
        onClick={
          () => dispatch(setSaveDashboardModal(false))
          }
      />
    </>
  );
};

SaveDashboardModalContainer.propTypes = { };

export default SaveDashboardModalContainer;
