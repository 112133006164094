import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import {
  Route, Switch, useRouteMatch, Redirect,
} from 'react-router-dom';

import CustomNav from './CustomNav';
import SharedProgramDetails from './SharedProgramDetails';
import SharedWorkoutPreview from './SharedWorkoutPreview';
import ModalHandler from './ModalHandler';
import { fetchUser } from '../../login/ducks/loginActions';

const Container = styled('div')`
`;

const ProgramContainer = styled('div')`
`;

const SharedWorkout = () => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Shared Workout | TeamBuildr</title>
      </Helmet>
      <Switch>
        <Route
          exact
          path={path}
          render={() => (
            <Redirect to='/login' />
          )}
        />
        <Switch>
          <Route path={`${path}/:programSlug`}>
            <CustomNav />
            <ProgramContainer>
              <SharedProgramDetails />
              <SharedWorkoutPreview />
            </ProgramContainer>
            <ModalHandler />
          </Route>
        </Switch>
      </Switch>
    </Container>
  );
};

export default SharedWorkout;
