/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { fetchProgramWeeks, finishProgramWorkout, setIsSkipWorkoutModalShowing } from '../../ducks/panelActions';
import SkipProgramWorkoutModal from '../../../../shared/components/ProgramModals/SkipProgramWorkoutModal';

const SkipProgramWorkoutModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1003;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const WeightroomSkipProgramWorkoutModal = ({ namespace }) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);
  const isSkipWorkoutModalShowing = useSelector(
    (state) => state.weightRoomView[namespace].isSkipWorkoutModalShowing,
  );

  const dispatch = useDispatch();

  const skipModalShowing = () => {
    dispatch(setIsSkipWorkoutModalShowing(namespace, false));
  };

  const skipWorkoutClick = () => {
    batch(() => {
      dispatch(finishProgramWorkout(
        accountCode,
        athleteId,
        namespace,
        currentSelectedProgram.dayBasedProgramId,
        currentNonDateDay.trueDayNum,
        'Skipped',
      ));
      // Adding this fetch here to update the week statuses since that data
      // is not included in the return data for skip/complete workout
      setTimeout(() => {
        dispatch(fetchProgramWeeks(
          accountCode,
          athleteId,
          namespace,
          currentSelectedProgram,
          currentSelectedProgram.progamIdx,
        ));
      }, 500);
    });
  };

  return (
    <>
      <SkipProgramWorkoutModal
        isModalActive={isSkipWorkoutModalShowing}
        skipModalShowing={skipModalShowing}
        skipWorkoutClick={skipWorkoutClick}
      />
      <SkipProgramWorkoutModalOverlay
        isActive={isSkipWorkoutModalShowing}
        onClick={
          () => dispatch(setIsSkipWorkoutModalShowing(namespace, false))
          }
      />
    </>
  );
};

WeightroomSkipProgramWorkoutModal.propTypes = {
  namespace: PropTypes.string.isRequired,
};

export default WeightroomSkipProgramWorkoutModal;
