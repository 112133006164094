import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ActivityReportTable from './ActivityReportTable';
import SevenDaySnapshot from './SevenDaySnapshot';
import NextWorkoutCell from './NextWorkoutCell';
import ActivityBar from './ActivityBar';
import FilterIndicator from './FilterIndicator';
import ScheduledWorkoutsPieChart from './ScheduledWorkoutsPieChart';
import LatestActivityCell from './LatestActivityCell';
import LastWorkoutCell from './LastWorkoutCell';

const TableContainer = styled('div')`
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  margin-top: ${(props) => props.marginTop};
  @media screen and (max-height: 867px) {
    overflow: unset;
  }
`;

const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  overflow: visible;
  flex-grow: 1;
  min-height: 0;
  width: 100%;
`;

const ChartsContainer = styled('div')`
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  @media screen and (max-width : 1224px) {
    flex-direction: column;
  }
  @media screen and (min-width : 1225px) {
    flex-direction: row;
  }
`;
const PieChartContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
`;

const FilterContainer = styled('div')`
  display: flex;
  flex-direction: row;
`;

const ActivityReportData = () => {
  const activityReport = useSelector((state) => state.reporting.data.activityReport.data);
  const latestActivityFilter = useSelector((state) => state.reporting.data.latestActivityFilter);
  const scheduledWorkoutFilter = useSelector(
    (state) => state.reporting.data.scheduledWorkoutFilter,
  );

  /*
  Determines which component to render for cell contents based on the column.
  */
  const renderCell = (column, row) => {
    if (column.columnId === 'lastSevenDaysSnapshot') {
      return (
        <SevenDaySnapshot days={row.lastSevenDaysSnapshot} />
      );
    }
    if (column.columnId === 'lastWorkoutActivity') {
      return (
        <LatestActivityCell
          date={row[column.columnId]}
          daysSinceJoined={row.daysSinceJoined}
          userId={row.userId}
        />
      );
    }
    if (column.columnId === 'nextScheduledWorkout') {
      return (
        <NextWorkoutCell date={row[column.columnId]} userId={row.userId} />
      );
    }
    if (column.columnId === 'lastScheduledWorkout') {
      return (
        <LastWorkoutCell date={row[column.columnId]} userId={row.userId} />
      );
    }
    return (
      <div>{row[column.columnId]}</div>
    );
  };

  // Using useCallback to memoize the tableColumns and tableData array so it only forces the table
  // component to re-render if activityReport changes.

  // Table Columns Prep
  const tableColumns = useCallback(activityReport
    && activityReport.columns.filter((col) => !['userId', 'daysSinceJoined'].includes(col.columnId)).map((column, index) => ({
      name: column.valueDescription,
      selector: column.columnId,
      sortable: true,
      sortFunction: (rowA, rowB, direction) => {
        // Sort first and last name
        if (column.columnId === 'first' || column.columnId === 'last') {
          const a = rowA[column.columnId].toLowerCase();
          const b = rowB[column.columnId].toLowerCase();
          if (a < b) {
            return -1;
          } if (a > b) {
            return 1;
          }
          return 0;
        } if ( // Sort the date columns
          column.columnId === 'lastWorkoutActivity'
          || column.columnId === 'nextScheduledWorkout'
          || column.columnId === 'lastScheduledWorkout'
        ) {
          const a = rowA[column.columnId];
          const b = rowB[column.columnId];
          // Handle nulls
          if (!a && !b) {
            return 0;
          }
          if (!b) {
            return -1;
          }
          if (!a) {
            return 1;
          }
          if (moment(a).isBefore(moment(b))) {
            return 1;
          } if (moment(a).isAfter(moment(b))) {
            return -1;
          }
          return 0;
        }
        // Sort the last 7 day snapshot column
        const a = rowA[column.columnId];
        const b = rowB[column.columnId];
        const numCompletedA = a.filter((obj) => obj.result === 2 || obj.result === 4).length;
        const numMissedA = a.filter((obj) => obj.result === 3).length;
        const numCompletedB = b.filter((obj) => obj.result === 2 || obj.result === 4).length;
        const numMissedB = b.filter((obj) => obj.result === 3).length;
        if (numCompletedA < numCompletedB) {
          return -1;
        } if (numCompletedA > numCompletedB) {
          return 1;
        }
        if (numMissedA < numMissedB) {
          return -1;
        }
        if (numMissedA > numMissedB) {
          return 1;
        }
        return 0;
      },
      style: {
        fontWeight: column.columnId === 'first' || column.columnId === 'last' ? '700' : '400',
      },
      cell: (row) => renderCell(column, row),
      center: !(column.columnId === 'first' || column.columnId === 'last'),
      grow: column.columnId === 'first' || column.columnId === 'last' ? 1 : 2,
    })), [activityReport]);

  const activityDatesBreakdown = {
    '30+': 0,
    '15-29': 0,
    '7-14': 0,
    '0-6': 0,
    'no activity': 0,
  };

  const lastScheduledWorkoutBreakdown = {
    '30+': {
      count: 0,
      color: '#10cd8c',
    },
    '15-29': {
      count: 0,
      color: '#ffaf0e',
    },
    '7-14': {
      count: 0,
      color: '#ff730e',
    },
    '0-6': {
      count: 0,
      color: '#ff0024',
    },
  };

  let totalActivity = 0;

  const today = moment();

  // Table Data Prep
  const tableData = [];
  (activityReport) && activityReport.rows.forEach((row) => {
    const tableRow = {};
    let passesActivityFilter = true;
    let passesScheduledWorkoutFilter = true;
    row.forEach((column) => {
      const {
        columnId,
        displayValue,
        rawValue,
      } = column;
      // Use raw values for date cells and the 7 day snapshot
      if (['lastWorkoutActivity', 'nextScheduledWorkout', 'lastScheduledWorkout', 'lastSevenDaysSnapshot'].includes(columnId)) {
        tableRow[columnId] = column.rawValue;
      } else {
        tableRow[columnId] = column.displayValue;
      }

      if (columnId === 'lastWorkoutActivity') {
        // Build data structure for graph of latest workout activity
        totalActivity += 1;
        if (rawValue !== null) {
          const diff = today.startOf('day').diff(moment(displayValue).startOf('day'), 'days');
          if (diff >= 30) {
            activityDatesBreakdown['30+'] += 1;
          } else if (diff >= 15) {
            activityDatesBreakdown['15-29'] += 1;
          } else if (diff >= 7) {
            activityDatesBreakdown['7-14'] += 1;
          } else {
            activityDatesBreakdown['0-6'] += 1;
          }
        } else {
          activityDatesBreakdown['no activity'] += 1;
        }

        if (latestActivityFilter) {
          if (!rawValue && latestActivityFilter === 'no activity') {
            passesActivityFilter = true;
          } else {
            const latestActivity = moment(displayValue);
            const diff = today.startOf('day').diff(latestActivity.startOf('day'), 'days');
            if (latestActivityFilter === '30+' && diff >= 30) {
              passesActivityFilter = true;
            } else if (latestActivityFilter === '15-29' && diff >= 15 && diff < 30) {
              passesActivityFilter = true;
            } else if (latestActivityFilter === '7-14' && diff >= 7 && diff < 15) {
              passesActivityFilter = true;
            } else if (latestActivityFilter === '0-6' && diff >= 0 && diff < 7) {
              passesActivityFilter = true;
            } else {
              passesActivityFilter = false;
            }
          }
        }
      }

      if (columnId === 'lastScheduledWorkout') {
        // Build data structure for pie chart of last scheduled workouts
        if (rawValue !== null) {
          const diff = moment(displayValue).startOf('day').diff(today.startOf('day'), 'days');
          if (diff >= 30) {
            lastScheduledWorkoutBreakdown['30+'].count += 1;
          } else if (diff >= 15) {
            lastScheduledWorkoutBreakdown['15-29'].count += 1;
          } else if (diff >= 7) {
            lastScheduledWorkoutBreakdown['7-14'].count += 1;
          } else {
            lastScheduledWorkoutBreakdown['0-6'].count += 1;
          }
        }
        if (scheduledWorkoutFilter) {
          if (!rawValue) {
            passesScheduledWorkoutFilter = false;
          } else {
            const lastScheduledWorkout = moment(displayValue);
            const diff = lastScheduledWorkout.startOf('day').diff(today.startOf('day'), 'days');
            if (scheduledWorkoutFilter === '30+' && diff >= 30) {
              passesScheduledWorkoutFilter = true;
            } else if (scheduledWorkoutFilter === '15-29' && diff >= 15 && diff < 30) {
              passesScheduledWorkoutFilter = true;
            } else if (scheduledWorkoutFilter === '7-14' && diff >= 7 && diff < 15) {
              passesScheduledWorkoutFilter = true;
            } else if (scheduledWorkoutFilter === '0-6' && diff >= 0 && diff < 7) {
              passesScheduledWorkoutFilter = true;
            } else {
              passesScheduledWorkoutFilter = false;
            }
          }
        }
      }
    });
    if (passesActivityFilter && passesScheduledWorkoutFilter) {
      tableData.push(tableRow);
    }
  });

  return (
    <ReportContainer>
      {activityReport.rows.length > 0 && (
        <div>
          <ChartsContainer>
            <ActivityBar activityDatesBreakdown={activityDatesBreakdown} total={totalActivity} />
            <PieChartContainer>
              <ScheduledWorkoutsPieChart
                lastScheduledWorkoutBreakdown={lastScheduledWorkoutBreakdown}
              />
            </PieChartContainer>
          </ChartsContainer>
          <FilterContainer>
            {latestActivityFilter && (
            <FilterIndicator filter={latestActivityFilter} useLatestActivityFilter />
            )}
            {scheduledWorkoutFilter && (
            <FilterIndicator filter={scheduledWorkoutFilter} />
            )}
          </FilterContainer>
        </div>
      )}
      <TableContainer marginTop={latestActivityFilter || scheduledWorkoutFilter ? '-10px' : '30px'}>
        <ActivityReportTable
          tableColumns={tableColumns}
          tableData={tableData}
        />
      </TableContainer>
    </ReportContainer>
  );
};

export default ActivityReportData;
