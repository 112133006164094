/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment-timezone';

import {
  fetchInitialWorkoutProgramWorkouts,
  fetchSessionByDate,
  fetchWorkoutProgramWeek,
  fetchWorkoutProgramWeeks,
  getWorkoutItemsForUserByDate,
  setActiveWorkoutDate,
  setIsDayCarousel,
  setIsProgramListBoxModalShowing,
  setWeekDayIndex,
  setIsResetProgramModalShowing,
  setIsProgramInformationModalShowing,
  setIsCompleteProgramModalShowing,
  fetchWorkoutProgramWeekViaNext,
  fetchNextWorkoutSession,
} from '../../ducks/workoutsActions';

import ProgramListBoxModal from '../../../../shared/components/ProgramListBoxModal/ProgramListBoxModal';

const ProgramListBoxModalContainerOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

/**
 * @returns a container that passes an object to a lower level modal
 * so that the object doesn't have to be imported on the top-level and
 * therefore trigger state updates to all lower-level components
 */
const ProgramListBoxModalContainer = () => {
  const isProgramListBoxShowing = useSelector(
    (state) => state.workouts.ui.isProgramListBoxShowing,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const sharedAthlete = useSelector((state) => state.workouts.data.sharedAthlete);
  const currentSelectedProgram = useSelector((state) => state.workouts.data.currentSelectedProgram);
  const nextWorkoutSession = useSelector((state) => state.workouts.data.nextWorkoutSession);
  const workoutPrograms = useSelector((state) => state.workouts.data.workoutPrograms);
  const workoutProgramWeeks = useSelector((state) => state.workouts.data.workoutProgramWeeks);
  const workoutProgramWeek = useSelector((state) => state.workouts.data.workoutProgramWeek);
  const isProgramListItemsLoading = useSelector(
    (state) => state.workouts.ui.isProgramListItemsLoading,
  );
  const isDayCarousel = useSelector((state) => state.workouts.ui.isDayCarousel);
  const userId = currentUser.admin ? sharedAthlete?.id : currentUser.id;

  const dispatch = useDispatch();

  const setCurrentDate = () => {
    dispatch(setIsProgramListBoxModalShowing(false));
    const newDate = moment().format('YYYY-MM-DD');
    if (newDate) {
      dispatch(setActiveWorkoutDate(newDate));
      if (Object.keys(currentUser).length) {
        if ((currentUser.admin && Object.keys(sharedAthlete).length) || !currentUser.admin) {
          if (currentUser.admin) {
            dispatch(fetchSessionByDate(currentUser, userId, newDate, {}));
          } else {
            dispatch(fetchSessionByDate(currentUser, userId, newDate, {}));
          }
        }
      }
      if (Object.keys(currentUser).length && !currentUser.admin) {
        // fetch if the user isn't an admin and currentUser exists
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            currentUser.id,
            newDate,
            false,
          ),
        );
      } else if (Object.keys(currentUser).length && Object.keys(sharedAthlete).length) {
        // Fetch if the current user is an coach, but only if an athlete is selected
        dispatch(
          getWorkoutItemsForUserByDate(
            currentUser,
            sharedAthlete.id,
            newDate,
            false,
          ),
        );
      }
    }
  };

  const programCardClickFunction = (program, index) => {
    dispatch(fetchWorkoutProgramWeeks(currentUser, program, sharedAthlete.id, index));
  };

  const weekCardClickFunction = (programWeek, idx) => {
    dispatch(fetchWorkoutProgramWeek(
      currentUser,
      currentSelectedProgram,
      idx,
      sharedAthlete.id,
    ));
  };

  const dayCardClickFunction = (programWeekDay, idx, zeroBasedDayNum) => {
    dispatch(fetchInitialWorkoutProgramWorkouts(
      currentUser,
      currentSelectedProgram,
      zeroBasedDayNum,
      sharedAthlete.id,
      programWeekDay,
    ));
    dispatch(setWeekDayIndex(idx));
    dispatch(setIsDayCarousel(true));
    dispatch(setIsProgramListBoxModalShowing(false));
  };

  const nextWorkoutClick = (nextSession) => {
    dispatch(fetchInitialWorkoutProgramWorkouts(
      currentUser,
      currentSelectedProgram,
      nextWorkoutSession.trueDayNum - 1,
      sharedAthlete.id,
      nextSession,
    ));
    dispatch(fetchWorkoutProgramWeekViaNext(
      currentUser,
      currentSelectedProgram,
      nextSession.trueWeekNum - 1,
      sharedAthlete.id,
      nextSession.zeroBasedDayNum,
    ));
    dispatch(setIsDayCarousel(true));
    dispatch(setIsProgramListBoxModalShowing(false));
  };

  const resetProgram = () => {
    dispatch(setIsResetProgramModalShowing(true));
  };

  const showInfoModal = () => {
    dispatch(setIsProgramInformationModalShowing(true));
  };

  const completeProgramModal = () => {
    dispatch(setIsCompleteProgramModalShowing(true));
  };

  const onLoadFunc = () => {
    if (Object.keys(currentSelectedProgram).length && isProgramListBoxShowing) {
      dispatch(fetchNextWorkoutSession(currentUser, currentSelectedProgram, userId));
    }
  };

  return (
    <>
      <ProgramListBoxModal
        isModalActive={isProgramListBoxShowing}
        defaultProgramImage={currentUser?.userSkin?.logo}
        viewScheduledWorkoutsClickFunction={setCurrentDate}
        sharedAthlete={sharedAthlete}
        programCardClickFunction={programCardClickFunction}
        programWeekCardClickFunction={weekCardClickFunction}
        programWeekDayCardClickFunction={dayCardClickFunction}
        currentSelectedProgram={currentSelectedProgram}
        nextSession={nextWorkoutSession}
        workoutProgramsList={workoutPrograms}
        workoutProgramWeeksList={workoutProgramWeeks}
        workoutProgramDaysList={workoutProgramWeek}
        nextWorkoutDayClickFunction={nextWorkoutClick}
        showNextSessionOption={currentSelectedProgram?.status !== 'Completed'}
        resetProgramButtonClickFunction={resetProgram}
        infoButtonClickFunction={showInfoModal}
        completeButtonClickFunction={completeProgramModal}
        isItemsLoading={isProgramListItemsLoading}
        showInfoButton={currentSelectedProgram.description}
        isAllowedReset={currentUser.admin || currentUser.allowDayBasedProgramRestarts}
        isNonDateProgramsActive={isDayCarousel}
        onLoadFunction={onLoadFunc}
      />
      <ProgramListBoxModalContainerOverlay
        isActive={isProgramListBoxShowing}
        onClick={
          () => dispatch(setIsProgramListBoxModalShowing(false))
        }
      />
    </>
  );
};

ProgramListBoxModalContainer.propTypes = { };

export default ProgramListBoxModalContainer;
