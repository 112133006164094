import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const BadgeContainer = styled('span')`
  display: block;
  width: ${props => props.small ? '8px' : '13px'};
  height: ${props => props.small ? '8px' : '13px'};
  border-radius: 100%;
  background-color: ${props => props.color ? props.color : 'red'};
  color: white;
  font-size: 6pt;
  font-weight: 600;
  text-align: center;
  ${props => props.topRight && (
    'position: absolute; top: 14px; right: 11px;'
  )}

  /* background-color: ${props => props.theme.colorAccent}; */
  /* color: ${props => (props.theme.isAccentDarkTheme ? '#444444' : '#FFFFFF')}; */
  /* font-size: 8px; */
  /* height: 13px;
  width: 13px; */
  /* border-radius: 10px; */
  /* display: block;
  text-align: center; */
`;

const Badge = ({
  children,
  color,
  small,
  topRight,
  darkTheme,
}) => (
  <BadgeContainer
    color={color}
    darkTheme={darkTheme}
    small={small}
    topRight={topRight}
  >
    {children}
  </BadgeContainer>
);

Badge.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string.isRequired,
  small: PropTypes.bool,
  topRight: PropTypes.bool,
  darkTheme: PropTypes.bool,
};

Badge.defaultProps = {
  children: undefined,
  small: false,
  topRight: false,
  darkTheme: false,
};

export default Badge;
