/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';
import { animated, useSpring } from 'react-spring';
import iconSet from '../../../shared/images/teambuildr-selection.json';
import Text from '../../../shared/components/Text/Text';
import Spinner from '../../../shared/components/Spinner/Spinner';
import { setIsShareModalOpen } from '../ducks/athleteReportCardActions';

const ShareModalContent = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [copySuccess, setCopySuccess] = useState(false);

  const isFetchingShareLink = useSelector((state) => state.athleteReportCard?.ui?.isFetchingShareLink);
  const link = useSelector((state) => state.athleteReportCard?.data?.reportCardLink?.url);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const copyAnimation = useSpring({ opacity: copySuccess ? 1 : 0 });

  const PageWrapper = styled('div')`
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    flex-direction: column;
  `;
  const OuterWrapper = styled('div')`
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  `;
  const Container = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 30px;
`;

  const ErrorContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
  const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

  const LinkContainer = styled('div')`
    display: flex;
    overflow: auto;
    overflow-wrap: anywhere;
    white-space: normal;
    max-height: 250px;
    flex-direction: column;
  `;

  const CopyLinkButton = styled('div')`
  display: flex;
  gap: 10px;
  min-width: 100px;
  align-items: center;
  border-radius: 4px;
  background: ${theme.colors.green};
  padding: 8px 16px 8px 16px;
  box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
  cursor: pointer;
`;

  const ButtonAndCopiedContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  `;

  return (
    <OuterWrapper>
      <IcomoonReact
        iconSet={iconSet}
        size={16}
        icon='remove'
        color={theme.colors.text}
        style={{ position: 'absolute', top: -20, right: -20 }}
        onClick={() => dispatch(setIsShareModalOpen(false))}
      />
      {isFetchingShareLink ? (
        <PageWrapper>
          <Spinner />
        </PageWrapper>
      ) : (
        <PageWrapper>
          <>
            {link ? (
              <>
                <Text style={{ marginBottom: 20 }} fontSize='22px'> Share Athlete Report Card</Text>
                <Container>
                  <TextContainer>
                    <Text fontSize='16px' fontWeight={700}>Access:</Text>
                    <Text fontSize='12px'>Anyone with link can view. Token will expire after 7 days!</Text>
                  </TextContainer>
                  <TextContainer>
                    <Text fontSize='16px' fontWeight={700}>Shareable Url:</Text>
                    <LinkContainer>
                      <Text fontSize='12px'>{link}</Text>
                    </LinkContainer>
                  </TextContainer>
                  <ButtonAndCopiedContainer>
                    <CopyLinkButton
                      onClick={copyToClipboard}
                    >
                      <IcomoonReact
                        iconSet={iconSet}
                        size={16}
                        icon='link'
                        color='white'
                      />
                      <Text color='white' fontSize='16px' fontWeight={700}>Copy Link</Text>
                    </CopyLinkButton>
                    <animated.div style={copyAnimation}>
                      <Text fontSize={18} style={{ marginTop: '18px', marginLeft: '15px' }}>Copied!</Text>
                    </animated.div>
                  </ButtonAndCopiedContainer>
                </Container>
              </>
            ) : (
              <ErrorContainer>
                <IcomoonReact
                  iconSet={iconSet}
                  size={40}
                  icon='warning'
                  color={theme.colors.orange}
                />
                <Text> Opps! Something went wrong. We were not able to generate a shareable link. Please contact your administrator.</Text>
              </ErrorContainer>
            )}
          </>
        </PageWrapper>
      )}
    </OuterWrapper>
  );
};

export default ShareModalContent;
