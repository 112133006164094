import React from 'react';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';

import SubText from '../../../../shared/components/SubText/SubText';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import NotificationWrapper from './notifications/shared/NotificationWrapper';

const NoRecordWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const IconMessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const IconContainer = styled('div')`
  margin-bottom: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectANotification = () => (
  <NoRecordWrapper>
    <NotificationWrapper>
      <IconMessageContainer>
        <IconContainer>
          <IcomoonReact
            iconSet={iconSet}
            size={100}
            icon='alert-on'
            color='black'
          />
          <SubText
            style={{
              marginTop: '20px',
            }}
            fontSize={22}
          >
            Select a Notification
          </SubText>
        </IconContainer>
      </IconMessageContainer>
    </NotificationWrapper>
  </NoRecordWrapper>
);

export default SelectANotification;
