/* eslint-disable no-tabs */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { setDeleteDashboardModal } from '../../ducks/sportCoachDashboardActions';
import DeleteDashboardModal from './DeleteDashboardModal';

const DeleteDashboardModalOverlay = styled('div')`
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(216, 216, 216, 0.8);
  z-index: 1002;
  pointer-events: ${(props) => (props.isActive ? 'auto' : 'none')};
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isActive ? 0.5 : 0)};
`;

const DeleteDashboardModalContainer = () => {
  const dispatch = useDispatch();
  const deleteDashboardModal = useSelector(
    (state) => state.sportCoachDashboard.ui.deleteDashboardModal,
  );

  return (
    <>
      <DeleteDashboardModal />
      <DeleteDashboardModalOverlay
        isActive={Object.keys(deleteDashboardModal).length}
        onClick={
          () => dispatch(setDeleteDashboardModal({}))
          }
      />
    </>
  );
};

DeleteDashboardModalContainer.propTypes = { };

export default DeleteDashboardModalContainer;
