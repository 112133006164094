import {
  FETCH_GROUP_ANALYSIS_DATA_FULFILLED,
  FETCH_GROUP_ANALYSIS_DATA_REJECTED,
  FETCH_GROUP_ANALYSIS_DATA_START,
  FETCH_GROUPS_FULFILLED,
  FETCH_GROUPS_REJECTED,
  FETCH_GROUPS_START,
  SET_RECOVERY_RADAR_VIEW,
  SET_IS_FILTER_OPEN,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_START,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED,
  FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_START,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED,
  FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED,
  FETCH_OVERVIEW_DATA_START,
  FETCH_OVERVIEW_DATA_FULFILLED,
  FETCH_OVERVIEW_DATA_REJECTED,
  SET_SHOW_FLAGGED_ATHLETES_MODAL,
  SET_ONBOARDING_MODAL_OPEN,
} from './actionTypes';

const initialState = {
  isAnalysisDataLoading: false,
  isAthleteHistoryDataLoading: false,
  isAthletesAssociatedWithGroupLoading: false,
  isFilterOpen: false,
  isFlaggedAthletesModalShowing: false,
  isGroupsLoading: false,
  isOnboardingModalShowing: false,
  isOverviewDataLoading: false,
  recoveryRadarView: 'overview',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_START: {
      return {
        ...state,
        isGroupsLoading: true,
      };
    }
    case FETCH_GROUPS_FULFILLED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case FETCH_GROUPS_REJECTED: {
      return {
        ...state,
        isGroupsLoading: false,
      };
    }
    case SET_RECOVERY_RADAR_VIEW: {
      return {
        ...state,
        recoveryRadarView: action.payload,
      };
    }
    case SET_IS_FILTER_OPEN: {
      return {
        ...state,
        isFilterOpen: action.payload,
      };
    }
    // OVERVIEW
    case FETCH_OVERVIEW_DATA_START: {
      return {
        ...state,
        isOverviewDataLoading: true,
      };
    }
    case FETCH_OVERVIEW_DATA_FULFILLED: {
      return {
        ...state,
        isOverviewDataLoading: false,
      };
    }
    case FETCH_OVERVIEW_DATA_REJECTED: {
      return {
        ...state,
        isOverviewDataLoading: false,
      };
    }
    case SET_SHOW_FLAGGED_ATHLETES_MODAL: {
      return {
        ...state,
        isFlaggedAthletesModalShowing: action.payload,
      };
    }
    // ANALYSIS
    case FETCH_GROUP_ANALYSIS_DATA_START: {
      return {
        ...state,
        isAnalysisDataLoading: true,
      };
    }
    case FETCH_GROUP_ANALYSIS_DATA_FULFILLED: {
      return {
        ...state,
        isAnalysisDataLoading: false,
      };
    }
    case FETCH_GROUP_ANALYSIS_DATA_REJECTED: {
      return {
        ...state,
        isAnalysisDataLoading: false,
      };
    }
    // HISTORY
    case FETCH_USERS_ASSOCIATED_WITH_GROUP_START: {
      return {
        ...state,
        isAthletesAssociatedWithGroupLoading: true,
      };
    }
    case FETCH_USERS_ASSOCIATED_WITH_GROUP_FULFILLED: {
      return {
        ...state,
        isAthletesAssociatedWithGroupLoading: false,
      };
    }
    case FETCH_USERS_ASSOCIATED_WITH_GROUP_REJECTED: {
      return {
        ...state,
        isAthletesAssociatedWithGroupLoading: false,
      };
    }
    case FETCH_USER_HISTORY_HEAT_MAP_DATA_START: {
      return {
        ...state,
        isAthleteHistoryDataLoading: true,
      };
    }
    case FETCH_USER_HISTORY_HEAT_MAP_DATA_FULFILLED: {
      return {
        ...state,
        isAthleteHistoryDataLoading: false,
      };
    }
    case FETCH_USER_HISTORY_HEAT_MAP_DATA_REJECTED: {
      return {
        ...state,
        isAthleteHistoryDataLoading: false,
      };
    }
    // SETTINGS
    case SET_ONBOARDING_MODAL_OPEN: {
      return {
        ...state,
        isOnboardingModalShowing: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
