import styled from '@emotion/styled';
import { typography, color } from 'styled-system';

const SubText = styled('span')`
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-weight: 900;
  color: #444444;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  ${typography}
  ${color}
`;

export default SubText;
