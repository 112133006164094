import { connect } from 'react-redux';
import AthleteSearch from '../presentational/AthleteSearch';
import { searchAthlete } from '../../ducks/panelActions';

const mapStateToProps = (state, ownProps) => ({
  athleteSearch: state[ownProps.namespace].athleteSearch,
});

const mapDispatchToProps = dispatch => ({
  searchAthlete: (athlete, namespace) => {
    dispatch(searchAthlete(athlete, namespace));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AthleteSearch);
