import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import TableToExcel from '@linways/table-to-excel';
import IcomoonReact from 'icomoon-react';

import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import { reportViewTracker } from '../../../../shared/utils/amplitudeHelper';

const NavigationWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin-bottom: -20px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 20px;

  .arrow-div {
    cursor: pointer;

    &.back {
      justify-self: flex-start;
    }
    &.close {
      justify-self: flex-end;
      margin-left: auto;
    }
  }
`;

const QuestionnaireDownloadReport = ({
  closeModal,
}) => {
  const theme = useTheme();

  const exportFile = () => {
    // Amplitude tracking
    reportViewTracker('Questionnaire Report', true);
    const tb = document.getElementById('betaQuestionnaireReportTable');
    TableToExcel.convert(tb, {
      name: 'questionnaire-report.xlsx',
      sheet: {
        name: 'Sheet 1',
      },
    });
    closeModal();
  };

  return (
    <>
      <NavigationWrapper>
        <Text
          className='close arrow-div'
          onClick={() => {
            closeModal();
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={13}
            icon='remove'
          />
        </Text>
      </NavigationWrapper>
      <Text
        fontSize='18px'
        textAlign='center'
      >
        Would you like to download this questionnaire report as an excel file?
      </Text>
      <Button
        cta='Download Report'
        className='modal-button'
        type='button'
        customColor={theme.colors.green}
        onClick={exportFile}
        fullWidth
        noBorder
        large
        square
        primary
        bottom
      />
    </>
  );
};

QuestionnaireDownloadReport.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default QuestionnaireDownloadReport;
