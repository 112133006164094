import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import { useDispatch, useSelector } from 'react-redux';
import iconSet from '../../../../shared/images/teambuildr-selection.json';
import {
  setIsCalendarProgramView,
  fetchPrograms,
  fetchWorkouts,
  setIsProgramListModalShowing,
  setIsProgramInfoModalShowing,
  setIsCompleteProgramWorkoutModalShowing,
  setIsSkipWorkoutModalShowing,
  setIsIntegrationModalShowing,
} from '../../ducks/panelActions';

const ProgramMenuListContainer = styled('div')`
  display: flex;
  position: absolute;
  flex-direction: column;
  overflow: auto;
  background-color: ${(props) => props.theme.backgroundColorL2};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  & span {
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
`;

const ProgramMenuItem = styled('div')`
  min-width: 250px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: ${(props) => props.theme.textColor};
  cursor: pointer;
  padding: 20px 15px;
  transition: all 200ms ease-in-out;

  :hover {
    background: #f5f5f5;
  }

  & svg {
    margin-right: 15px;
  }
`;

const ProgramMenuList = ({
  namespace,
  setActiveProgramMenu,
}) => {
  const accountCode = useSelector((state) => state.weightRoomView.data.currentUser.accountCode);
  const activeDate = useSelector((state) => state.weightRoomView[namespace].activeDate);
  const athleteId = useSelector((state) => state.weightRoomView[namespace].athleteId);
  const currentNonDateDay = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentNonDateDay);
  const isProgramView = useSelector((state) => state.weightRoomView[namespace].isProgramView);
  const currentSelectedProgram = useSelector((
    state,
  ) => state.weightRoomView[namespace].currentSelectedProgram);

  const importedDataIntegrations = useSelector((state) => state.weightRoomView[namespace].importedDataIntegrations);

  const isComplete = currentNonDateDay.status === 'Completed' || currentNonDateDay.status === 'Skipped';
  const hasDescription = currentSelectedProgram.description;

  const dispatch = useDispatch();

  const handleProgramClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(setIsProgramListModalShowing(namespace, true));
      dispatch(fetchPrograms(accountCode, athleteId, namespace));
    }, 500);
  };

  const handleCalendarClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(fetchWorkouts(accountCode, athleteId, namespace, activeDate));
      dispatch(setIsCalendarProgramView(namespace, 'calendar'));
    }, 500);
  };

  const handleInfoClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(setIsProgramInfoModalShowing(namespace, true));
    }, 500);
  };

  const handleCompleteClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(setIsCompleteProgramWorkoutModalShowing(namespace, true));
    }, 500);
  };

  const handleSkipClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(setIsSkipWorkoutModalShowing(namespace, true));
    }, 500);
  };

  const handleIntegrationClick = () => {
    setActiveProgramMenu(false);
    setTimeout(() => {
      dispatch(setIsIntegrationModalShowing(namespace, true));
    }, 500);
  };

  // *** Some icons are not correct image and most still need correct color ***

  return (
    <ProgramMenuListContainer>
      <ProgramMenuItem
        onClick={handleProgramClick}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='folder'
        />
        View Program Library
      </ProgramMenuItem>
      {importedDataIntegrations?.length > 0 && (
      <ProgramMenuItem
        onClick={handleIntegrationClick}
      >
        <IcomoonReact
          iconSet={iconSet}
          size={20}
          icon='download'
        />
        View Imported Data
      </ProgramMenuItem>
      )}

      {isProgramView && (
        <>
          <ProgramMenuItem
            onClick={handleCalendarClick}
          >
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='calendar'
            />
            View Scheduled Workouts
          </ProgramMenuItem>
          {!isComplete && (
            <>
              <ProgramMenuItem
                onClick={handleCompleteClick}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                // Not the right icon
                  icon='checkmark'
                />
                Mark as Complete
              </ProgramMenuItem>
              <ProgramMenuItem
                onClick={handleSkipClick}
              >
                <IcomoonReact
                  iconSet={iconSet}
                  size={20}
                  icon='skip'
                />
                Skip Workout
              </ProgramMenuItem>

            </>
          )}
          {hasDescription && (
            <ProgramMenuItem
              onClick={handleInfoClick}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={20}
                icon='info'
              />
              Info
            </ProgramMenuItem>
          )}
        </>
      )}
    </ProgramMenuListContainer>
  );
};

ProgramMenuList.propTypes = {
  namespace: PropTypes.string.isRequired,
  setActiveProgramMenu: PropTypes.instanceOf(Function).isRequired,
};

export default ProgramMenuList;
