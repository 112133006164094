// imports action types and dispatches action to the back end for processessing and db

import {
  CREATE_RESELLER_START,
  CREATE_RESELLER_FULFILLED,
  CREATE_RESELLER_REJECTED,
  FETCH_RESELLER_START,
  FETCH_RESELLER_FULFILLED,
  FETCH_RESELLER_REJECTED,
  CONNECT_STRIPE_START,
  CONNECT_STRIPE_FULFILLED,
  CONNECT_STRIPE_REJECTED,
  UPLOAD_MEDIA_START,
  UPLOAD_MEDIA_FULFILLED,
  UPLOAD_MEDIA_REJECTED,
  CREATE_PROGRAM_START,
  CREATE_PROGRAM_FULFILLED,
  CREATE_PROGRAM_REJECTED,
  FETCH_PROGRAMS_START,
  FETCH_PROGRAMS_FULFILLED,
  FETCH_PROGRAMS_REJECTED,
  FETCH_USER_GROUPS_START,
  FETCH_USER_GROUPS_FULFILLED,
  FETCH_USER_GROUPS_REJECTED,
  UPDATE_PROGRAM_START,
  UPDATE_PROGRAM_FULFILLED,
  UPDATE_PROGRAM_REJECTED,
  STRIPE_ADMIN_LOGIN_START,
  STRIPE_ADMIN_LOGIN_FULFILLED,
  STRIPE_ADMIN_LOGIN_REJECTED,
  FETCH_SUBSCRIBERS_START,
  FETCH_SUBSCRIBERS_FULFILLED,
  FETCH_SUBSCRIBERS_REJECTED,
  FETCH_CANCELLED_SUBSCRIBERS_START,
  FETCH_CANCELLED_SUBSCRIBERS_FULFILLED,
  FETCH_CANCELLED_SUBSCRIBERS_REJECTED,
  FETCH_SUBSCRIBER_START,
  FETCH_SUBSCRIBER_FULFILLED,
  FETCH_SUBSCRIBER_REJECTED,
  CANCEL_SUBSCRIPTION_START,
  CANCEL_SUBSCRIPTION_FULFILLED,
  CANCEL_SUBSCRIPTION_REJECTED,
  UPDATE_RESELLER_ACCOUNT_START,
  UPDATE_RESELLER_ACCOUNT_FULFILLED,
  UPDATE_RESELLER_ACCOUNT_REJECTED,
  FETCH_CALENDARS_START,
  FETCH_CALENDARS_FULFILLED,
  FETCH_CALENDARS_REJECTED,
  FETCH_FIRST_WORKOUT_START,
  FETCH_FIRST_WORKOUT_FULFILLED,
  FETCH_FIRST_WORKOUT_REJECTED,
  FETCH_ALL_CHARGES_START,
  FETCH_ALL_CHARGES_FULFILLED,
  FETCH_ALL_CHARGES_REJECTED,
  REFUND_CHARGE_START,
  REFUND_CHARGE_FULFILLED,
  REFUND_CHARGE_REJECTED,
  UPDATE_PAYMENT_START,
  UPDATE_PAYMENT_FULFILLED,
  UPDATE_PAYMENT_REJECTED,
  SET_ACTIVE_REDUX_MODAL,
  REQUEST_STRIPE_CONNECT_LINK_START,
  REQUEST_STRIPE_CONNECT_LINK_FULFILLED,
  REQUEST_STRIPE_CONNECT_LINK_REJECTED,
  FETCH_COUPONS_START,
  FETCH_COUPONS_FULFILLED,
  FETCH_COUPONS_REJECTED,
  CREATE_COUPON_START,
  CREATE_COUPON_FULFILLED,
  CREATE_COUPON_REJECTED,
  DELETE_COUPON_START,
  DELETE_COUPON_FULFILLED,
  DELETE_COUPON_REJECTED,
  CLEAR_COUPON_FETCH_ERROR,
  UPDATE_COUPON_START,
  UPDATE_COUPON_FULFILLED,
  UPDATE_COUPON_REJECTED,
  ADD_SUBSCRIBER_TO_PROGRAM_START,
  ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED,
  ADD_SUBSCRIBER_TO_PROGRAM_REJECTED,
  CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR,
  SET_RESELLER_OVERLAY,
} from './actionTypes';

import { axiosAuthed } from '../../../shared/utils/setCommonHeaders';
import { authProvider } from '../../login/ducks/loginActions';

authProvider();

export const createReseller = (values) => (
  (dispatch) => {
    dispatch({ type: CREATE_RESELLER_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller`, values.body)
      .then((response) => {
        dispatch({
          type: CREATE_RESELLER_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: 'verify_reseller_modal',
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_RESELLER_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchReseller = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_RESELLER_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller`)
      .then((response) => {
        dispatch({
          type: FETCH_RESELLER_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_RESELLER_REJECTED,
          payload: err,
        });
      });
  }
);

export const connectStripeAccount = (values) => (
  (dispatch) => {
    dispatch({ type: CONNECT_STRIPE_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/integrations/stripe`, values)
      .then((response) => {
        dispatch({
          type: CONNECT_STRIPE_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: CONNECT_STRIPE_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const uploadMedia = (values) => (
  (dispatch) => {
    const formData = new FormData();
    formData.append('image', values.acceptedFile[0]);
    dispatch({ type: UPLOAD_MEDIA_START });
    axiosAuthed.post('/media/upload/image', formData, {
      headers: {
        'Upload-Location': 'resellers/headers',
      },
    }).then((response) => {
      dispatch({
        type: UPLOAD_MEDIA_FULFILLED,
        payload: response.data,
      });
    })
      .catch((err) => {
        dispatch({
          type: UPLOAD_MEDIA_REJECTED,
          payload: err,
        });
      });
  }
);

export const createProgram = (values) => (
  (dispatch) => {
    const body = values.newProgram;
    dispatch({ type: CREATE_PROGRAM_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/programs`, body)
      .then((response) => {
        dispatch({
          type: CREATE_PROGRAM_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_PROGRAM_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchPrograms = (accountCode, filters = {}) => (
  (dispatch) => {
    const {
      programName,
      programStatus,
    } = filters;

    const programNameQuery = `${programName ? `&programName=${programName}` : ''}`;
    const programStatusQuery = `${programStatus || programStatus === 0 ? `&programStatus=${programStatus}` : ''}`;

    dispatch({ type: FETCH_PROGRAMS_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/programs?${programNameQuery}${programStatusQuery}`)
      .then((response) => {
        dispatch({
          type: FETCH_PROGRAMS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_PROGRAMS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchUserGroups = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_USER_GROUPS_START });
    axiosAuthed.get(`/accounts/${accountCode}/groups`)
      .then((response) => {
        dispatch({
          type: FETCH_USER_GROUPS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_USER_GROUPS_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateProgram = (values) => (
  (dispatch) => {
    const body = values.newProgram;
    dispatch({ type: UPDATE_PROGRAM_START });
    axiosAuthed.put(`/accounts/${values.accountCode}/reseller/programs/${values.programId}`, body)
      .then((response) => {
        dispatch({
          type: UPDATE_PROGRAM_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PROGRAM_REJECTED,
          payload: err,
        });
      });
  }
);

export const stripeAdminLogin = (accountCode) => (
  (dispatch) => {
    dispatch({ type: STRIPE_ADMIN_LOGIN_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/integrations/stripe/dashboard`)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: STRIPE_ADMIN_LOGIN_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: STRIPE_ADMIN_LOGIN_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchSubscribers = (accountCode, filters = {}) => (
  (dispatch) => {
    const {
      activeProgramId,
      subscriberName,
      subscriptionStatus,
    } = filters;

    const activeProgramIdQuery = `${activeProgramId ? `&activeProgramId=${activeProgramId}` : ''}`;
    const subscriberNameQuery = `${subscriberName ? `&subscriberName=${subscriberName}` : ''}`;
    const subscriptionStatusQuery = `${subscriptionStatus ? `&subscriptionStatus=${subscriptionStatus}` : ''}`;

    dispatch({ type: FETCH_SUBSCRIBERS_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/subscribers?${activeProgramIdQuery}${subscriberNameQuery}${subscriptionStatusQuery}`)
      .then((response) => {
        dispatch({
          type: FETCH_SUBSCRIBERS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SUBSCRIBERS_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const fetchCancelledSubscribers = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CANCELLED_SUBSCRIBERS_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/subscribers`, {
      params: {
        subscriptionStatus: 3,
      },
    })
      .then((response) => {
        dispatch({
          type: FETCH_CANCELLED_SUBSCRIBERS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CANCELLED_SUBSCRIBERS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchSubscriber = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIBER_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/reseller/subscribers/${values.subscriberId}`)
      .then((response) => {
        dispatch({
          type: FETCH_SUBSCRIBER_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_SUBSCRIBER_REJECTED,
          payload: err,
        });
      });
  }
);

export const cancelSubscription = (values) => (
  (dispatch) => {
    dispatch({ type: CANCEL_SUBSCRIPTION_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/subscribers/${values.subscriberId}/subscriptions/${values.subscriptionId}/cancel`, values.body)
      .then((response) => {
        dispatch({
          type: CANCEL_SUBSCRIPTION_FULFILLED,
          payload: { data: response.data, index: values.subscriberIndex },
        });
      })
      .catch((err) => {
        dispatch({
          type: CANCEL_SUBSCRIPTION_REJECTED,
          payload: err,
        });
      });
  }
);

export const updateResellerAccount = (accountCode, values) => (
  (dispatch) => {
    dispatch({ type: UPDATE_RESELLER_ACCOUNT_START });
    axiosAuthed.put(`/accounts/${accountCode}/reseller`, values)
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: UPDATE_RESELLER_ACCOUNT_FULFILLED,
            payload: response.data,
          });
        }, 800);
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_RESELLER_ACCOUNT_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchCalendars = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_CALENDARS_START });
    axiosAuthed.get(`/accounts/${accountCode}/calendars`, {
      params: {
        archived: 0,
      },
    })
      .then((response) => {
        dispatch({
          type: FETCH_CALENDARS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_CALENDARS_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchFirstWorkout = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_FIRST_WORKOUT_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/calendars/${values.calendarId}/first-workout`, {
      params: {
        archived: 0,
      },
    })
      .then((response) => {
        dispatch({
          type: FETCH_FIRST_WORKOUT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_FIRST_WORKOUT_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchAllCharges = (values) => (
  (dispatch) => {
    dispatch({ type: FETCH_ALL_CHARGES_START });
    axiosAuthed.get(`/accounts/${values.accountCode}/reseller/subscribers/${values.subscriberId}/charges`, {
      params: {
        archived: 0,
      },
    })
      .then((response) => {
        dispatch({
          type: FETCH_ALL_CHARGES_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_ALL_CHARGES_REJECTED,
          payload: err,
        });
      });
  }
);

export const refundCharge = (values) => (
  (dispatch) => {
    dispatch({ type: REFUND_CHARGE_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/subscribers/${values.subscriberId}/charges/${values.chargeId}/refund`, values.body)
      .then((response) => {
        dispatch({
          type: REFUND_CHARGE_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: REFUND_CHARGE_REJECTED,
          payload: err,
        });
      });
  }
);

export const updatePayment = (values) => (
  (dispatch) => {
    dispatch({ type: UPDATE_PAYMENT_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/subscribers/${values.subscriberId}/payment-method`, values.body)
      .then((response) => {
        dispatch({
          type: UPDATE_PAYMENT_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PAYMENT_REJECTED,
          payload: err,
        });
      });
  }
);

export const setActiveReduxModal = (activeModal) => (
  {
    type: SET_ACTIVE_REDUX_MODAL,
    payload: activeModal,
  }
);

export const requestStripeConnectLink = (accountCode, accountType = 'express') => (
  (dispatch) => {
    dispatch({ type: REQUEST_STRIPE_CONNECT_LINK_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/integrations/stripe/onboarding?accountType=${accountType}`)
      .then((response) => {
        dispatch({
          type: REQUEST_STRIPE_CONNECT_LINK_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: REQUEST_STRIPE_CONNECT_LINK_REJECTED,
          payload: err,
        });
      });
  }
);

export const fetchCoupons = (accountCode) => (
  (dispatch) => {
    dispatch({ type: FETCH_COUPONS_START });
    axiosAuthed.get(`/accounts/${accountCode}/reseller/coupons`)
      .then((response) => {
        dispatch({
          type: FETCH_COUPONS_FULFILLED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: FETCH_COUPONS_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const createCoupon = (values) => (
  (dispatch) => {
    dispatch({ type: CREATE_COUPON_START });
    axiosAuthed.post(`/accounts/${values.accountCode}/reseller/coupons`, values.couponValues)
      .then((response) => {
        dispatch({
          type: CREATE_COUPON_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_COUPON_REJECTED,
          payload: err.response?.headers.message || err,
        });
      });
  }
);

export const setCouponModal = (modal) => (
  (dispatch) => {
    dispatch({
      type: SET_ACTIVE_REDUX_MODAL,
      payload: modal,
    });
  }
);

export const clearCouponsFetchError = () => (
  (dispatch) => {
    dispatch(
      {
        type: CLEAR_COUPON_FETCH_ERROR,
      },
    );
  }
);

export const deleteCoupon = (accountCode, couponId) => (
  (dispatch) => {
    dispatch({ type: DELETE_COUPON_START });
    axiosAuthed.delete(`/accounts/${accountCode}/reseller/coupons/${couponId}`)
      .then((response) => {
        dispatch({
          type: DELETE_COUPON_FULFILLED,
          payload: couponId,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: DELETE_COUPON_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const updateCoupon = (accountCode, couponId, newCoupon) => (
  (dispatch) => {
    dispatch({ type: UPDATE_COUPON_START });
    axiosAuthed.put(`/accounts/${accountCode}/reseller/coupons/${couponId}`, newCoupon)
      .then((response) => {
        dispatch({
          type: UPDATE_COUPON_FULFILLED,
          payload: response.data,
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_COUPON_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const addSubscriberToProgram = (accountCode, subscriberId, programId, disableCalendarAndGroups) => (
  (dispatch) => {
    const payload = {
      resellerProgramId: programId,
      disableNewGroupAssignments: disableCalendarAndGroups,
      disableNewCalAssignments: disableCalendarAndGroups,
    };
    dispatch({ type: ADD_SUBSCRIBER_TO_PROGRAM_START });
    axiosAuthed.post(`/accounts/${accountCode}/reseller/subscribers/${subscriberId}/subscriptions`,
      payload)
      .then(() => {
        dispatch({
          type: ADD_SUBSCRIBER_TO_PROGRAM_FULFILLED,
          payload: {
            subscriberId,
          },
        });
        dispatch({
          type: SET_ACTIVE_REDUX_MODAL,
          payload: '',
        });
      })
      .catch((err) => {
        dispatch({
          type: ADD_SUBSCRIBER_TO_PROGRAM_REJECTED,
          payload: err.response.headers.message,
        });
      });
  }
);

export const clearAddSubscriberToProgramError = () => (
  (dispatch) => {
    dispatch({ type: CLEAR_ADD_SUBSCRIBER_TO_PROGRAM_ERROR });
  }
);

export const setResellerOverlay = (boolean) => ({
  type: SET_RESELLER_OVERLAY,
  payload: boolean,
});
