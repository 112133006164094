/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  setAdditionalInfoWorkout,
  setActiveWorkoutsMedia,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  min-width: 300px;
  max-width: 500px;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: absolute; 
  border-radius: 12px;
  overflow: auto;
  left: 50%;
  top: 50%;
  right: 50%;
  width: 50vw;
  @media only screen and (max-width : 768px) {
    width: 90vw;
  }
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 1000%)')};
  height: auto;
  max-height: 70vh;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
	padding: 10px;
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    .remove-div-icon {
      cursor: pointer;
    }
  }
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    :hover {
      overflow-y: scroll;
    }
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    :hover {
      overflow-y: scroll;
    }
  }
`;

const StyleContainer = styled('div')`
  width: 100%;
	height: 100%;
	padding: 30px;
	display: flex;
	flex-direction: column;
`;

const AdditionalInfoHeader = styled('div')`
  width: 100%;
	display: flex;
	font-family: 'Nunito Sans';
	font-size: 20px;
	font-weight: 900;
	text-transform: uppercase;
`;

const AddInfoDesc = styled('div')`
  width: 100%;
	display: flex;
	font-family: 'Nunito Sans';
	font-size: 16px;
	font-weight: 400;
  margin-top: 15px;
  white-space: pre-wrap;
`;

const MediaContainer = styled('div')`
	background-image: ${(props) => `url(${props.background})`};
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	height: 200px;
	width: 200px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const MediaWrapper = styled('div')`

`;

const PlayButton = styled('div')`
  width: 33px;
  height: 33px;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 2px;
    margin-top: 1px;
  }
`;

/**
 *
 * @returns this is a modal that I repurposed from Kristy's journal
 * endpoint.  It's not quite as complex because it doesn't need to respond
 * to new entries - the submission is sent to the journal endpoint and that's that
 *
 */
const AdditionalInfoModal = ({ additionalInfoWorkout }) => {
  const dispatch = useDispatch();
  return (
    <ModalContainer isActive={Object.keys(additionalInfoWorkout).length}>
      <ContentContainer>
        <div className='remove-div'>
          <div className='remove-div-icon' onClick={() => dispatch(setAdditionalInfoWorkout({}))}>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='remove'
              color='black'
            />
          </div>
        </div>
        <StyleContainer>
          <AdditionalInfoHeader>{additionalInfoWorkout?.exercise?.name}</AdditionalInfoHeader>
          <AddInfoDesc>{additionalInfoWorkout?.exercise?.description}</AddInfoDesc>
          {additionalInfoWorkout?.exercise?.media && additionalInfoWorkout?.exercise?.media.length ? (
            <MediaWrapper>
              <MediaContainer
                onClick={() => dispatch(setActiveWorkoutsMedia(additionalInfoWorkout?.exercise?.media[0]))}
                background={additionalInfoWorkout?.exercise?.media[0]?.thumbnailUrl}
              >
                <PlayButton>
                  <IcomoonReact
                    iconSet={iconSet}
                    size={15}
                    icon='play-filled'
                    color='white'
                  />
                </PlayButton>
              </MediaContainer>
            </MediaWrapper>
          ) : null}
        </StyleContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

AdditionalInfoModal.propTypes = {
  additionalInfoWorkout: PropTypes.instanceOf(Object).isRequired,
};

export default AdditionalInfoModal;
