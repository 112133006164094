import React from 'react';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import {
  setLatestActivityFilter,
  setScheduledWorkoutFilter,
} from '../../ducks/reportingActions';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

const OuterContainer = styled('div')`
    padding-top: 30px;
    padding-left: 25px;
`;

const BubbleContainer = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const BubbleOutline = styled('div')`
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    background-color: #ffffff;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 10px;
    padding-right: 10px;
`;

const Bubble = styled('div')`
    width: 10px;
    height: 10px;
    margin: 3px 1px 0;
    border-radius: 35px;
    background-color: ${(props) => props.backgroundColor};
`;

const FilterDescription = styled('div')`
    margin-left: 10px;
    text-align: center;
    margin-right: 5px;
    font-size: 14px;
`;

const IconContainer = styled('div')`
    padding-left: 20px;
    cursor: pointer;
`;

const FilterLabel = styled('div')`
  font-size: 12px;
  margin-bottom: 8px;
  font-family: Nunito Sans;
  font-weight: bold;
`;

const FilterIndicator = ({
  useLatestActivityFilter,
  filter,
}) => {
  const dispatch = useDispatch();

  const clearFilter = () => {
    if (useLatestActivityFilter) {
      dispatch(setLatestActivityFilter(null));
    } else {
      dispatch(setScheduledWorkoutFilter(null));
    }
  };
  const determineBackgroundColor = () => {
    if (useLatestActivityFilter) {
      if (filter === '30+') {
        return '#ff0024';
      } if (filter === '15-29') {
        return '#ff730e';
      } if (filter === '7-14') {
        return '#ffaf0e';
      } if (filter === 'no activity') {
        return '#444445';
      }
      return '#0dcc8a';
    }
    if (filter === '30+') {
      return '#0dcc8a';
    } if (filter === '15-29') {
      return '#ffaf0e';
    } if (filter === '7-14') {
      return '#ff730e';
    }
    return '#ff0024';
  };

  return (
    <OuterContainer>
      <FilterLabel>{useLatestActivityFilter ? 'Latest Workout Activity' : 'Last Scheduled Workout'}</FilterLabel>
      <BubbleContainer>
        <BubbleOutline>
          <Bubble backgroundColor={determineBackgroundColor()} />
          <FilterDescription>{filter !== 'no activity' ? `${filter} days` : filter}</FilterDescription>
          <IconContainer onClick={clearFilter}>
            <IcomoonReact
              iconSet={iconSet}
              size={10}
              icon='remove'
            />
          </IconContainer>
        </BubbleOutline>
      </BubbleContainer>
    </OuterContainer>
  );
};

FilterIndicator.propTypes = {
  useLatestActivityFilter: PropTypes.instanceOf(Boolean).isRequired,
  filter: PropTypes.instanceOf(String).isRequired,
};

export default FilterIndicator;
