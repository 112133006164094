import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useSpring, animated } from 'react-spring';
import { Formik, Form, Field } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { useTheme } from 'emotion-theming';
import IcomoonReact from 'icomoon-react';

import Button from '../../../../shared/components/Button/Button';
import Checkmark from '../../../../shared/components/Checkmark/Checkmark';
import Text from '../../../../shared/components/Text/Text';
import Spinner from '../../../../shared/components/Spinner/Spinner';
import { MessageContainer } from './ForgotPasswordForm';
import useReset from '../hooks/useReset';
import getAllUrlParams from '../../../../shared/utils/getAllUrlParams';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

const FormGroup = styled('div')`
  p {
    margin-bottom: 8px;
  }
  label {
    width: 100%;
  }
  .button {
    margin-top: 35px;
  }
  z-index: 1;
`;

const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

const OptionsWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
`;

const Option = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 122px;
  height: 122px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  cursor: pointer;
  border-width: 1px;
`;

const ValidationError = styled('div')`
  margin-top: 40px;
  color: #ff6600;
  @media screen and (max-width: 600px) {
    margin-top: 20px;
    font-size: 14px;
  }
`;

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password Required'),
  confirm: Yup.string()
    .required('Confirm Password Required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

const { DEEPLINK_URL_SCHEME } = process.env;

const ResetPasswordForm = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  const [isWebReset, setIsWebReset] = useState(false);

  const {
    handleReset,
    handleResetLogOut,
    handleValidate,
  } = useReset();

  const isComplete = useSelector((state) => state.forgot.ui.isComplete);
  const isDisabled = useSelector((state) => state.forgot.ui.isDisabled);
  const isSubmitting = useSelector((state) => state.forgot.ui.isSubmitting);
  const resetError = useSelector((state) => state.forgot.ui.resetError);
  const validateError = useSelector((state) => state.forgot.ui.validateError);

  const formAnimation = useSpring({
    opacity: isSubmitting || isComplete ? 0 : 1,
    zIndex: 1,
  });

  const spinnerAnimation = useSpring({ opacity: isSubmitting ? 1 : 0, zIndex: 0 });
  const completeAnimation = useSpring({ opacity: isComplete ? 1 : 0 });

  const theme = useTheme();

  // Use getAllUrlParams in order to grab the token and validate it
  const url = getAllUrlParams(window.location.href, false);
  const tokenString = url.token;
  const deepLink = `${DEEPLINK_URL_SCHEME}://reset-password/`;

  // Validate token on initial page load
  useEffect(() => {
    handleResetLogOut();
    handleValidate({ token: tokenString });
  }, []);

  const handleWeb = () => {
    setIsWebReset(true);
  };

  return (
    <Formik
      initialValues={{
        grant_type: 'password',
        password: '',
        confirm: '',
        token: tokenString,
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
        scope: 'offline_access',
      }}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          handleReset({ token: tokenString, password: values.password });
          setSubmitting(false);
        });
      }}
      validationSchema={PasswordSchema}
      render={({
        errors,
        touched,
        handleSubmit,
      }) => (
        <>
          <animated.div style={formAnimation}>
            <Form
              name='reset'
              id='reset'
            >
              {/* Token will be validated, if there is an error this error message will display */}
              { validateError
                ? (
                  <FormGroup className='form-group'>
                    <ValidationError className='text-center'>Invalid token, please try again</ValidationError>
                  </FormGroup>
                )
                : (
                  <>
                    {/* If on mobile, the options to reset on web or in the app will display */}
                    {isMobile && !isWebReset
                      ? (
                        <>
                          <p
                            style={
                              {
                                fontSize: '18px',
                                textAlign: 'center',
                                marginBottom: '25px',
                              }
                            }
                          >
                            How would you like to reset your password?
                          </p>
                          <OptionsWrapper>
                            <Option
                              onClick={handleWeb}
                            >
                              <IcomoonReact
                                iconSet={iconSet}
                                size={35}
                                icon='globe'
                              />
                              <Text
                                style={{ fontSize: '15px', marginTop: '5px' }}
                              >
                                On the Web
                              </Text>
                            </Option>
                            <a href={deepLink + tokenString} target='_blank' rel='noreferrer'>
                              <Option>
                                <IcomoonReact
                                  iconSet={iconSet}
                                  size={35}
                                  icon='phone'
                                />
                                <Text
                                  style={{ fontSize: '15px', marginTop: '5px' }}
                                >
                                  In the App
                                </Text>
                              </Option>
                            </a>
                          </OptionsWrapper>
                        </>
                      )
                      : (
                        <>
                          {/* If not on mobile or web option selected, reset form will display */}
                          <FormGroup className='form-group'>
                            <label>
                              <Text
                                fontSize={theme.textFontSizes[0]}
                              >
                                New Password
                              </Text>
                              <Field
                                className='form-control'
                                id='new-password-field'
                                type='password'
                                name='password'
                                placeholder='Password'
                                disabled={isDisabled}
                              />
                            </label>
                            {errors.password && touched.password && <FieldError className='text-center'>{errors.password}</FieldError>}
                          </FormGroup>
                          <FormGroup className='form-group'>
                            <label>
                              <Text
                                fontSize={theme.textFontSizes[0]}
                              >
                                Confirm Password
                              </Text>
                              <Field
                                className='form-control'
                                id='confirm-password-field'
                                type='password'
                                name='confirm'
                                placeholder='Password'
                                disabled={isDisabled}
                              />
                            </label>
                            {errors.confirm && touched.confirm && <FieldError className='text-center'>{errors.confirm}</FieldError>}
                            {resetError && <FieldError className='text-center'>Something went wrong, please try again</FieldError>}
                          </FormGroup>
                          <FormGroup className='text-center'>
                            <Button
                              cta='Submit'
                              customColor='#ff6600'
                              customTextColor='#ffffff'
                              name='submit'
                              id='submit'
                              onClick={handleSubmit}
                              fullWidth
                              large
                              noBorder
                              rounded
                              primary
                              className='button'
                              isLoading={isSubmitting}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                        </>
                      )}
                  </>
                )}
            </Form>
          </animated.div>
          <animated.div style={spinnerAnimation}>
            <MessageContainer>
              <Spinner />
            </MessageContainer>
          </animated.div>
          <animated.div style={completeAnimation}>
            <MessageContainer>
              <Checkmark />
            </MessageContainer>
          </animated.div>
        </>
      )}
    />
  );
};

export default ResetPasswordForm;
