/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import CircularProgressbar from 'react-circular-progressbar';

import Button from '../../../../shared/components/Button/Button';
import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  setExertionScoreModalShowing,
} from '../../ducks/workoutsActions';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import { useTheme } from 'emotion-theming';

const ModalContainer = styled('div')`
	display: flex;
	background: white;
  z-index: 1003;
  box-shadow: ${BoxShadow};
  justify-content: center;
  min-width: 300px;
  min-height: 270px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  position: absolute; 
  border-radius: 12px;
  overflow: auto;
  left: 50%;
  top: 50%;
  right: 50%;
  transition: all 0.3s linear;
  transform: ${(props) => (props.isActive ? 'translate(-50%,-50%)' : 'translate(-50%, 300%)')};
  height: auto;
  max-height: 70vh;
`;

const ModalText = styled('div')`
  margin-top: 10px;
  font-family: 'Nunito Sans';
  font-size: 14px;
  text-align: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;

const ContentContainer = styled('div')`
	display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-height: 410px;
  padding-bottom: 50px;
  .opt-out-button {
    position: absolute;
    bottom: 0;
    background: #10cd8c;
  }
  .remove-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    .remove-div-icon {
      cursor: pointer;
    }
  }
`;

const StyleContainer = styled('div')`
  width: 100%;
`;

const ScoreContainer = styled('div')`
  height: 150px;
  display: flex;
  justify-content: center;
`;

/**
 *
 * @returns this is a modal that I repurposed from Kristy's journal
 * endpoint.  It's not quite as complex because it doesn't need to respond
 * to new entries - the submission is sent to the journal endpoint and that's that
 *
 */
const ExertionScoreModal = () => {
  const dispatch = useDispatch();
  const exertionScore = useSelector((state) => state.workouts.data.exertionScore);
  const exertionScoreModalShowing = useSelector(
    (state) => state.workouts.data.exertionScoreModalShowing,
  );

  const theme = useTheme();

  const handleExertionColor = () => {
    if (exertionScore?.score <= 2) {
      return theme.colors.green;
    }
    if (exertionScore?.score > 2 && exertionScore?.score <= 4) {
      return theme.colors.yellowBlend;
    }
    if (exertionScore?.score > 4 && exertionScore?.score <= 6) {
      return theme.colors.yellow;
    }
    if (exertionScore?.score > 6 && exertionScore?.score <= 8) {
      return theme.colors.redBlend;
    }
    return theme.colors.red;
  };

  return (
    <ModalContainer isActive={exertionScoreModalShowing}>
      <ContentContainer>
        <div className='remove-div'>
          <div className='remove-div-icon' onClick={() => dispatch(setExertionScoreModalShowing(false))}>
            <IcomoonReact
              iconSet={iconSet}
              size={15}
              icon='remove'
              color='black'
            />
          </div>
        </div>
        <StyleContainer>
          <ScoreContainer>
            <CircularProgressbar
              value={exertionScore?.score ? exertionScore.score * 10 : 0}
              percentage={exertionScore?.score ? exertionScore.score * 10 : 0}
              text={`${exertionScore?.score ? exertionScore.score : 0}`}
              strokeWidth={12}
              styles={{
                path: { stroke: handleExertionColor() },
                root: {
                  width: '65%',
                },
                text: {
                  fontFamily: 'Nunito Sans',
                  fill: theme.colors.text,
                },
              }}
            />
          </ScoreContainer>
          <ModalText>
            The Exertion score is 0-10 and calculated by time spent within certain heart rate zones throughout the day. The more time you spend in higher zones, the more physiological stress is put on the body. Over time and with enough information, your score will start to change with the regularity of exercise. The more consistent you are with your training, your score will scale to fit your current level of fitness.
          </ModalText>
          <Button
            bottom
            fullWidth
            cta='Done'
            className='opt-out-button'
            large
            square
            noBorder
            primary
            onClick={() => {
              dispatch(setExertionScoreModalShowing(false));
            }}
          />
        </StyleContainer>
      </ContentContainer>
    </ModalContainer>
  );
};

export default ExertionScoreModal;
