/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import IcomoonReact from 'icomoon-react';
import Webcam from 'react-webcam';
import atob from 'atob';

import Spinner from '../../../../shared/components/Spinner/Spinner';
import Text from '../../../../shared/components/Text/Text';
import NoHoverButton from './NoHoverButton';
import { setActiveReduxModal } from '../../ducks/conversationsActions';

const WebcamContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 26px;
    font-weight: 300;
  }
  .capture-button {
    width: 180px;
    background-color: ${(props) => props.theme.colors.green};
    color: white;

    &:hover {
      opacity: .7;
    }
  }
`;

const SpinnerWrapper = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 395px;
  `;

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user',
};
const WebcamCaptureImage = ({
  handleUploadMedia,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [isCaptured, setIsCaptured] = useState(false);

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const uploadMedia = useSelector((state) => state.conversations.data.uploadMedia);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      const imageBuff = Buffer.from(imageSrc.replace(/^data:image\/\w+;base64,/, ''), 'base64');
      const image = new File([imageBuff], 'jpg', {
        type: 'image/jpeg',
      });
      const imgArray = [image];
      setIsCaptured(true);
      handleUploadMedia({ accountCode: currentUser.accountCode, acceptedFile: imgArray, type: 'image' });
    },
    [webcamRef],
  );

  const buttonMasher = () => {
    if (!isCaptured) {
      return (
        <NoHoverButton
          className='modal-button'
          onClick={capture}
          cta='Take Photo'
          customColor='#10cd8c'
          noHover
          noBorder
          bottom
          fullWidth
          large
          primary
          square
        />
      );
    }
    return (
      <NoHoverButton
        bottom
        fullWidth
        cta='Done'
        customColor='#10cd8c'
        className='modal-button'
        onClick={() => dispatch(setActiveReduxModal(''))}
        large
        noBorder
        primary
        square
        noHover
      />
    );
  };

  const playerMasher = () => {
    if (isCaptured) {
      if (uploadMedia && uploadMedia.mediaUrl) {
        return (
          <div style={{
            width: '700px',
            height: '395px',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <img
              alt=''
              src={uploadMedia.mediaUrl}
            />
          </div>
        );
      }
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }
    return (
      <Webcam
        audio={false}
        width={700}
        height={395}
        ref={webcamRef}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
        screenshotQuality={1}
      />
    );
  };

  return (
    <WebcamContainer>
      {playerMasher()}
      {buttonMasher()}
    </WebcamContainer>
  );
};

WebcamCaptureImage.propTypes = {
  handleUploadMedia: PropTypes.func.isRequired,
};

export default WebcamCaptureImage;
