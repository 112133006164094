import React from 'react';
import Select from 'react-select';
import styled from '@emotion/styled';
import Toggle from 'react-toggle';
import { useSelector } from 'react-redux';

import Modal from './Modal';
import { ModalContentWrapper } from './ModalNote';
import Title from './Title';
import ButtonRound from './ButtonRound';
import useWeightroomSettings from '../hooks/useWeightroomSettings';

const SettingsRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .select__value-container {
    padding: 2px 16px 2px 12px;
  }

  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
`;

const TextLabel = styled('p')`
  font-weight: 600;
  margin-bottom: 0px;
  color: ${(props) => props.theme.textColor};
`;

const SubText = styled('span')`
  font-size: 12px;
  margin-top: 5px;
  color: ${(props) => props.theme.textColor};
`;

const PanelSettings = () => {
  const {
    modalClose,
    setScreenAmount,
    toggleDark,
    toggleSuperset,
    toggleWeight,
  } = useWeightroomSettings();

  const isDarkThemeEnabled = useSelector((state) => state.weightRoomView.ui.isDarkThemeEnabled);
  const isSupersetsEnabled = useSelector((state) => (
    state.weightRoomView.data.currentUser.userSettings.wrvSupersetsEnabled
  ));
  const isWeightValueEnabled = useSelector((state) => state.weightRoomView.ui.isWeightValueEnabled);
  const isGlobalModalOpen = useSelector((state) => state.weightRoomView.ui.isGlobalModalOpen);
  const activeScreens = useSelector((state) => state.weightRoomView.ui.activeScreens);

  const screensValue = {
    value: activeScreens, label: `${activeScreens}`,
  };

  return (
    <Modal
      isOpen={isGlobalModalOpen}
      onRequestClose={modalClose}
    >
      <ModalContentWrapper>
        <Title
          icon='settings'
        >
          Settings
        </Title>
        <SettingsRow>
          <TextContainer>
            <TextLabel>
              Number of Screens
            </TextLabel>
            <SubText>
              Change the number of screens available at the same time.
            </SubText>
          </TextContainer>
          <Select
            classNamePrefix='select'
            onChange={(options) => { setScreenAmount(options.value); }}
            options={[
              { value: 1, label: 1 },
              { value: 2, label: 2 },
              { value: 4, label: 4 },
            ]}
            value={screensValue}
          />
        </SettingsRow>
        <SettingsRow>
          <TextContainer>
            <TextLabel>
              Turn On Supersets
            </TextLabel>
            <SubText>
              In a superset, next and previous buttons will toggle by set and not exercise.
              To update the default setting, go to organization settings within the settings page.
            </SubText>
          </TextContainer>
          <Toggle
            defaultChecked={isSupersetsEnabled}
            icons={false}
            onChange={toggleSuperset}
          />
        </SettingsRow>
        <SettingsRow>
          <TextContainer>
            <TextLabel>
              Dark Mode
            </TextLabel>
            <SubText>
              In dark mode, weightroom view uses a darker color palette.
            </SubText>
          </TextContainer>
          <Toggle
            defaultChecked={isDarkThemeEnabled}
            icons={false}
            onChange={toggleDark}
          />
        </SettingsRow>
        <SettingsRow>
          <TextContainer>
            <TextLabel>
              Automatically input prescribed / suggested weights.
            </TextLabel>
            <SubText>
              When enabled, suggested and prescribed weights will be automatically filled
              in and saved when the next button or the workout is closed. If disabled,
              the weight values will show up as a placeholder and athletes will have to enter
              their weights manually before saving. To update the default setting, go to
              organization settings within the settings page.
            </SubText>
          </TextContainer>
          <Toggle
            defaultChecked={isWeightValueEnabled}
            icons={false}
            onChange={toggleWeight}
          />
        </SettingsRow>
        <ButtonRound
          bottom
          fullWidth
          cta='Close'
          className='modal-button'
          large
          noBorder
          primary
          square
          onClick={modalClose}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

export default PanelSettings;
