/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import ResizeObserver from 'react-resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../shared/components/Text/Text';
import FormLabel from '../../../../../shared/components/FormLabel/FormLabel';
import SketchPickerElement from './SketchPickerElement';
import { setIsSketchPickerPopupOpen } from '../../../../profile/ducks/profileActions';
import { updateOrganizationSettings } from '../../../../login/ducks/loginActions';

// Schema for yup
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Organiztion name is required'),
});

const OrganizationInformation = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const accountCode = currentUser?.accountCode;
  const organizationSettings = useSelector((state) => state.auth.data.organizationSettings);
  const organizationSettingsErrorMessage = useSelector((state) => state.auth.data.organizationSettingsErrorMessage);
  const organizationName = organizationSettings?.organization?.name;
  const organizationPrimaryColor = organizationSettings?.skin?.primaryColor;
  const organizationSecondaryColor = organizationSettings?.skin?.secondaryColor;
  const organizationAccentColor = organizationSettings?.skin?.accentColor;
  const defaultColor = '#B1BABF';

  const [containerWidth, setContainerWidth] = useState(null);

  const Container = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
  `;

  const RowWrapper = styled('div')`
    display: flex;
    flex-direction: ${containerWidth && containerWidth < 375 ? 'column' : 'row'};
    gap: 15px;
    padding-top: 15px;
    flex-wrap: wrap;
  `;

  const OrganizationNameRowWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding-top: 15px;
  `;

  const ColumnWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    .error-message {
      margin-top: 10px;
      font-size: 14px;
      color: #ff6600;
    }
  `;

  const ColorColumnWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: ${containerWidth && containerWidth < 375 ? '100%' : '48%'};
  `;

  const CustomInput = styled('input')`
    font-weight: 400;
    border: 2px solid #E0E0E0;
    border-radius: 6px;
    padding-left: 16px;
    padding-right: 16px;
    height: 56px;
  `;

  const HeaderContainer = styled('div')`
    padding-top: 60px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: #E0E0E0 1px solid;
  `;

  const WarningContainer = styled('div')`
    display: flex;
    flex-direction: row;
    /* align-itmes: center; */
  `;

  return (
    <Container>
      <ResizeObserver
        onResize={(rect) => {
          setContainerWidth(rect.width);
        }}
      />
      <HeaderContainer>
        <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
          Organization Information
        </Text>
        <Text fontSize={18} fontWeight='400'>
          {'Manage your organization\'s account information and color scheme.'}
        </Text>
      </HeaderContainer>
      <Formik
        initialValues={{
          name: organizationName,
          primaryColor: organizationPrimaryColor,
          secondaryColor: organizationSecondaryColor,
          accentColor: organizationAccentColor,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // When button submits form and form is in the process of submitting,
          // submit button is disabled
          setSubmitting(true);
          const valueObj = {
            organization: { name: values.name },
            skin: {
              primaryColor: values.primaryColor, secondaryColor: values.secondaryColor, accentColor: values.accentColor,
            },
          };
          dispatch(updateOrganizationSettings(accountCode, valueObj));
          setSubmitting(false);
        }}
      >
        {/* Callback function containing Formik state and
            helpers that handle common form actions... destructured from props */}
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <OrganizationNameRowWrapper>
              <ColumnWrapper>
                <FormLabel size={16}>
                  Organization Name
                </FormLabel>
                <CustomInput
                  key='user_name_key'
                  id='name'
                  name='name'
                  type='text'
                  autoCapitalize='none'
                  placeholder='Organization Name'
                  value={values.name}
                  onBlur={() => {
                    values.name !== organizationName && handleSubmit();
                  }}
                  onChange={handleChange}
                />
                {touched.name && errors.name ? (
                  <div className='error-message'>{errors.name}</div>
                ) : null}
              </ColumnWrapper>
            </OrganizationNameRowWrapper>
            <RowWrapper>
              <ColorColumnWrapper>
                <SketchPickerElement
                  defaultColor={organizationPrimaryColor || defaultColor}
                  colorCategory='primary'
                  values={values}
                  handleSubmit={handleSubmit}
                />
                <FormLabel size={16}>
                  Primary Color
                </FormLabel>
                <CustomInput
                  id='primaryColor'
                  name='primaryColor'
                  autoCapitalize='none'
                  placeholder={organizationPrimaryColor || defaultColor}
                  value={values.primaryColor}
                  readOnly
                  onClick={() => dispatch(setIsSketchPickerPopupOpen({ colorCategory: 'primary', bool: true }))}
                />
              </ColorColumnWrapper>
              <ColorColumnWrapper>
                <SketchPickerElement
                  defaultColor={organizationSecondaryColor || defaultColor}
                  colorCategory='secondary'
                  values={values}
                  handleSubmit={handleSubmit}
                  chosenColorIsTooBrightErrorMessage={(organizationSettingsErrorMessage && organizationSettingsErrorMessage === 'The secondary color submitted is too bright') && organizationSettingsErrorMessage}
                />
                <FormLabel size={16}>
                  Secondary Color
                </FormLabel>
                <CustomInput
                  id='secondaryColor'
                  name='secondaryColor'
                  autoCapitalize='none'
                  placeholder={organizationSecondaryColor || defaultColor}
                  value={values.secondaryColor}
                  readOnly
                  onClick={() => dispatch(setIsSketchPickerPopupOpen({ colorCategory: 'secondary', bool: true }))}
                />
                {(organizationSettingsErrorMessage && organizationSettingsErrorMessage === 'The secondary color submitted is too bright') && (
                  <WarningContainer>
                    <Text color='#ff6600'>{`${organizationSettingsErrorMessage}. Please choose another color.`}</Text>
                  </WarningContainer>
                )}
              </ColorColumnWrapper>
              <ColorColumnWrapper>
                <SketchPickerElement
                  defaultColor={organizationAccentColor || defaultColor}
                  colorCategory='accent'
                  values={values}
                  handleSubmit={handleSubmit}
                  chosenColorIsTooBrightErrorMessage={(organizationSettingsErrorMessage && organizationSettingsErrorMessage === 'The accent color submitted is too bright') && organizationSettingsErrorMessage}
                />
                <FormLabel size={16}>
                  Accent Color
                </FormLabel>
                <CustomInput
                  id='accentColor'
                  name='accentColor'
                  autoCapitalize='none'
                  placeholder={organizationAccentColor || defaultColor}
                  value={values.accentColor}
                  readOnly
                  onClick={() => dispatch(setIsSketchPickerPopupOpen({ colorCategory: 'accent', bool: true }))}
                />
                {(organizationSettingsErrorMessage && organizationSettingsErrorMessage === 'The accent color submitted is too bright') && (
                <WarningContainer>
                  <Text color='#ff6600'>{`${organizationSettingsErrorMessage}. Please choose another color.`}</Text>
                </WarningContainer>
                )}
              </ColorColumnWrapper>
            </RowWrapper>
          </form>
        )}
      </Formik>
    </Container>
  );
};
export default OrganizationInformation;
