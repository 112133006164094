import {
  SUBMIT_SUPPORT_START,
  SUBMIT_SUPPORT_FULFILLED,
  SUBMIT_SUPPORT_REJECTED,
  FETCH_ACCOUNTS_START,
  FETCH_ACCOUNTS_FULFILLED,
  FETCH_ACCOUNTS_REJECTED,
  FETCH_UNREAD_MESSAGES_FULFILLED,
  FETCH_UNREAD_MESSAGES_REJECTED,
} from './actionTypes';

const initialState = {
  currentUserAccounts: [],
  unreadMessages: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_UNREAD_MESSAGES_FULFILLED: {
      return {
        ...state,
        unreadMessages: action.payload,
      };
    }
    case FETCH_UNREAD_MESSAGES_REJECTED: {
      return {
        ...state,
        unreadMessages: action.payload,
      };
    }
    case FETCH_ACCOUNTS_FULFILLED: {
      return {
        ...state,
        currentUserAccounts: action.payload,
      };
    }
    case FETCH_ACCOUNTS_REJECTED: {
      return {
        ...state,
        accountsError: action.payload,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
