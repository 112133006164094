import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';
import Logger from 'js-logger';
import Shimmer from 'react-shimmer-effect';
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';

import HeaderText from '../../../../shared/components/HeaderText/HeaderText';
import SubText from '../../../../shared/components/SubText/SubText';
import Text from '../../../../shared/components/Text/Text';
import Button from '../../../../shared/components/Button/Button';
import Workout from './Workout';

const WorkoutPreviewContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px;
  width: 100%;
  background: ${props => props.theme.colors.background[4]};
  position: relative;

  h1 {
    margin-bottom: 15px;
  }
`;

const PreviewSlider = styled('div')`
  display: flex;
  padding: 30px 0px;
  align-items: center;
  overflow: hidden;
`;

const PreviewSlide = styled('div')`
  display: flex;
  flex-direction: column;
  width: 270px;
  min-width: 270px;
  height: 310px;
  margin-right: 30px;
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background[1]};
  box-shadow: ${props => props.theme.colors.boxShadow};
  overflow: hidden;
  ${props => props.workoutDataLength === 1 ? 'margin: 0 auto;' : ''}
`;

const SlideTitle = styled('div')`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  padding: 10px;
  text-align: center;
`;

const SlideContent = styled('div')`
  flex: 1;
  overflow: auto;
`;

const SliderNav = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  padding: 0px 60px;

  .next-button {
    align-self: flex-end;
    margin-left: auto;
  }

  .prev-button {}

  @media screen and (max-width: 540px) {
    padding: 0px 15px;
  }
`;

const WorkoutList = styled('div')`
  padding: 10px;
  margin-bottom: 15px;
  .workout-container {
    padding: 8px 10px;
      
    :first-of-type {
      :before {
        display: none;
      }
    }
  }
`;

const WorkoutsMessage = styled('div')`
  width: 100%;
  display: flex;
  padding: 30px;
  justify-content: center;
`;

const WorkoutPreview = ({
  dispatch,
  fetchWorkoutPreview,
  resellerSlug,
  programSlug,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideDirection, setSlideDirection] = useState('forward');
  const [slideLocation, setSlideLocation] = useState(0);

  const workoutData = useSelector(state => state.programs.data.workoutData);
  // const workoutData = [];

  const slideContainerStyles = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  };

  const slideAnimation = useSpring({ transform: `translate3d(${slideLocation}px,0,0)` });

  const workouts = [
    { day: 'Monday', content: 'test' },
    { day: 'Tuesday', content: 'test' },
    { day: 'Wednesday', content: 'test' },
    { day: 'Thursday', content: 'test' },
    { day: 'Friday', content: 'test' },
    { day: 'Saturday', content: 'test' },
    { day: 'Sunday', content: 'test' },
  ];

  const nextSlide = () => {
    setActiveSlide(activeSlide === workouts.length - 1 ? 0 : activeSlide + 1);
    setSlideDirection('forward');
    setSlideLocation(slideLocation - 300);
  };

  const prevSlide = () => {
    setActiveSlide(activeSlide === 0 ? workouts.length - 1 : activeSlide - 1);
    setSlideDirection('backwards');
    setSlideLocation(slideLocation + 300);
  };

  const visibleSlides = 3;

  useEffect(() => {
    // Fetch program data using the accountName and programName
    // fetchProgramData(team, program);

    dispatch(fetchWorkoutPreview(resellerSlug, programSlug));

    // setTimeout(() => {
    //   setIsLoadingProgram(false);
    // }, 1500);
  }, []);

  return (
    <WorkoutPreviewContainer>
      <div className='container'>
        <HeaderText textAlign='center'>Workout Preview</HeaderText>
        <PreviewSlider>
          <animated.div style={{ ...slideContainerStyles, ...slideAnimation }}>
            {workoutData.map((workout) => {
              return (
                <PreviewSlide workoutDataLength={workoutData.length}>
                  <SlideTitle>
                    <SubText textAlign='center'>
                      {`Day ${workout.dayNum}`}
                    </SubText>
                  </SlideTitle>
                  <SlideContent>
                    {/* {workout.content} */}
                    <WorkoutList>
                      {workout.workoutItems.map(workoutItem => (
                        <Workout
                          icon={workoutItem.exerciseIcon}
                          name={workoutItem.exerciseName}
                          grouping={workoutItem.groupingLetter}
                        />
                      ))}
                    </WorkoutList>
                  </SlideContent>
                </PreviewSlide>
              );
            })}
            {workoutData.length === 0 && (
              <WorkoutsMessage>
                <Text fontSize={20}>No workout preview available.</Text>
              </WorkoutsMessage>
            )}
          </animated.div>
        </PreviewSlider>
      </div>
      {workoutData.length > 1 && (
        <SliderNav>
          <Button
            className='prev-button'
            disabled={activeSlide === 0}
            icon='left-arrow'
            iconOnly
            onClick={prevSlide}
            primary
            noBorder
          />
          <Button
            className='next-button'
            disabled={activeSlide === workouts.length - 1}
            icon='right-arrow'
            iconOnly
            onClick={nextSlide}
            primary
            noBorder
          />
        </SliderNav>
      )}

    </WorkoutPreviewContainer>
  );
};

export default WorkoutPreview;
