/* eslint-disable max-len */
import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList,
} from 'recharts';

const MiniBarChart = ({
  title, currentWeekValue, previousWeekValue, currentWeekLabel, previousWeekLabel, containerWidth,
}) => {
  const data = [
    {
      title,
      currentWeek: currentWeekValue,
      previousWeek: previousWeekValue,
      currentWeekLabel,
      previousWeekLabel,
    },
  ];

  /** styles */
  const Wrapper = styled('div')`
    display: flex;
    width: ${(currentWeekValue + previousWeekValue) === 0 ? '100px' : '90%'};
    min-width: 100px;
    min-height: ${containerWidth < 400 ? '200' : '270px'};
    align-items: center;
  `;

  return (
    <Wrapper>
      <ResponsiveContainer height={170}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 60,
            left: -40,
            bottom: 5,
          }}
          layout='vertical'
          barGap={20}
        >
          <XAxis type='number' hide padding={{ left: 10 }} />
          <YAxis
            dataKey='title'
            type='category'
            tickLine={false}
            tick={null}
            axisLine={{ stroke: 'rgb(242, 241, 241)', strokeWidth: 2 }}
          />
          <defs>
            <linearGradient id='purpleGradient' x1='0%' y1='0%' x2='100%' y2='0%'>
              <stop offset='0%' style={{ stopColor: '#687EE9', stopOpacity: 1 }} />
              <stop offset='100%' style={{ stopColor: '#764DA4', stopOpacity: 1 }} />
            </linearGradient>
          </defs>

          <Bar
            dataKey='currentWeek'
            fill='url(#purpleGradient)'
            background={{ fill: 'transparent' }}
            barSize={30}
          >
            <LabelList dataKey='currentWeekLabel' type='number' fill='#898989' fontSize={14} position='right' fontFamily='nunito sans' fontWeight={600} />
          </Bar>
          <Bar dataKey='previousWeek' fill='#A4A2A2' barSize={30}>
            <LabelList dataKey='previousWeekLabel' fill='#898989' fontSize={14} position='right' fontFamily='nunito sans' fontWeight={600} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  );
};

MiniBarChart.propTypes = {
  title: PropTypes.string.isRequired,
  currentWeekValue: PropTypes.number.isRequired,
  previousWeekValue: PropTypes.number.isRequired,
  currentWeekLabel: PropTypes.string.isRequired,
  previousWeekLabel: PropTypes.string.isRequired,
  containerWidth: PropTypes.number.isRequired,
};

export default MiniBarChart;
