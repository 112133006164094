import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import IcomoonReact from 'icomoon-react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import iconSet from '../../../../shared/images/teambuildr-selection.json';

import {
  BoxShadow,
} from '../../../../shared/GlobalStyles';
import {
  primeDeleteEvalId, setEditEvalModal1, setManageEvaluationsScreenOff, setEditSlideIndex,
} from '../../ducks/evaluationsActions';
import HeaderText from '../../../../shared/components/HeaderText/HeaderText';

const lastUsedColumnWidth = '150px';
const dateAddedColumnWidth = '150px';
const evalOptionsColumnWidth = '75px';
const columnTitleMarginLeft = '40px';

const Wrapper = styled('div')`
	display: flex;
  width: 100%;
  height: 100%;
  padding: 40px;
  flex-direction: column;
  overflow: auto;
  font-family: 'Nunito Sans';
`;

const TopRow = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const TitleAndBackButton = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const BackButton = styled('div')`
  cursor: pointer;
`;

const TableWrapper = styled('div')`
  width: 100%;
  margin-top: 20px;
`;

const TitleBox = styled('div')`
  width: 100%;
  margin-left: ${columnTitleMarginLeft};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
`;

const EvaluationRow = styled('div')`
  width: 100%;
  height: 68px;
  background: white;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: ${BoxShadow};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;
  &:hover {
    background: #f5f5f5;
  }
`;

const RightBox = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const TableHeader = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Nunito Sans';
  height: 78px;
`;

const TableHeaderRightBox = styled('div')`
  height: 100%;
  display: flex;
  justify-content: flex-end;
`;

const NameColumn = styled('div')`
  display: flex;
  justify-content: center;
  margin-left: ${columnTitleMarginLeft};
  @media only screen and (max-width : 768px) {
    font-size: 13px;  
    margin-left: 25px;
    }
`;

const EvalOptionsColumn = styled('div')`
  display: flex;
  justify-content: center;
  min-width: ${evalOptionsColumnWidth};
  align-items: center;
  font-weight: 700;

  @media only screen and (max-width : 768px) {
    min-width: unset;
    font-size: 13px;  
    }
`;

const LastUsedColumn = styled('div')`
  display: flex;
  min-width: ${lastUsedColumnWidth};
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width: unset;
    font-size: 13px;  
    }
`;

const DateAddedColumn = styled('div')`
  display: flex;
  min-width: ${dateAddedColumnWidth};
  align-items: center;
  @media only screen and (max-width : 768px) {
    min-width: unset;
    font-size: 13px;
    margin-left:20px;
  }
  @media only screen and (max-width : 400px) {
    min-width: unset;
    font-size: 13px;
  }
`;

const RelativeContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled('div')`
  transform: rotate(90deg);
  cursor: pointer;
`;

const ViewProgramsMenu = styled('div')`
  position: absolute;
  width: 250px;
  background: white;
  box-shadow: ${BoxShadow};
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  top: 30px;
`;

const ViewProgramsMenuOption = styled('div')`
  width: 100%;
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: ${(props) => (props.addBorderBottom ? '1px solid black' : 'none')};
  cursor: pointer;
  :hover {
    background: #f5f5f5;
  }
`;

const ViewProgramsMenuText = styled('div')`
  font-size: 14px;
  font-family: 'Nunito Sans';
  margin-left: 15px;
`;

const SearchContainer = styled('input')`
  display: flex;
  height: 40px;
  border: none;
  background: none;
  padding-left: 5px;
`;

const SearchAndIcon = styled('div')`
  display: flex;
  align-items: center;
  border: ${(props) => (props.isFocused ? '1px solid   #5F8BDC' : '1px solid #EEEEEE')};
  border-radius: 50px;
  background: white;
  transition: max-width 0.5s ease;
  max-width: ${(props) => (props.isFocused ? '250px' : '40px')};
  overflow: hidden;
`;

const SearchIconContainer = styled('div')`
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
`;

const DropdownContainer = ({
  evaluation,
  dispatch,
  idx,
  dropdownManager,
  setDropdownManager,
}) => (
  <RelativeContainer id='dropdown-container'>
    <IconContainer
      id='dropdown-container'
      onClick={() => {
        const oldValue = dropdownManager[evaluation.id];
        const newId = evaluation.id;
        const newObject = { };
        newObject[newId] = !oldValue;
        setDropdownManager(newObject);
      }}
    >
      <IcomoonReact
        iconSet={iconSet}
        size={25}
        id='dropdown-container'
        icon='dots'
        color='grey'
        onClick={() => {
          const oldValue = dropdownManager[evaluation.id];
          const newId = evaluation.id;
          const newObject = { };
          newObject[newId] = !oldValue;
          setDropdownManager(newObject);
        }}
      />
    </IconContainer>
    {dropdownManager[evaluation.id] ? (
      <ViewProgramsMenu id='dropdown-programs-menu'>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => {
            dispatch(setEditEvalModal1(evaluation));
            dispatch(setEditSlideIndex(1));
          }}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='select-all'
            color='black'
          />
          <ViewProgramsMenuText>
            View Evaluation
          </ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => dispatch(setEditEvalModal1(evaluation))}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='pencil'
            color='black'
          />
          <ViewProgramsMenuText>
            Edit
          </ViewProgramsMenuText>
        </ViewProgramsMenuOption>
        <ViewProgramsMenuOption
          id='dropdown-programs-menu-option'
          onClick={() => dispatch(primeDeleteEvalId({ id: evaluation.id, index: idx }))}
        >
          <IcomoonReact
            iconSet={iconSet}
            size={20}
            icon='trashcan'
            color='black'
          />
          <ViewProgramsMenuText>
            Delete
          </ViewProgramsMenuText>
        </ViewProgramsMenuOption>
      </ViewProgramsMenu>
    ) : null}
  </RelativeContainer>
);

const EvaluationsContentList = () => {
  const dispatch = useDispatch();

  const evaluations = useSelector((state) => state.evaluations.data.evaluations);
  const currentUser = useSelector((state) => state.auth.data.currentUser);

  const [searchedList, setSearchedList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [dropdownManager, setDropdownManager] = useState({});

  useEffect(() => {
    const searchedArray = [];
    evaluations.forEach((evaluation) => {
      if (evaluation.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        searchedArray.push(evaluation);
      }
    });
    if (searchedArray.length) {
      setSearchedList(searchedArray);
    } else {
      setSearchedList(['No results to show']);
    }
  }, [searchTerm]);

  return (
    <Wrapper onClick={(e) => {
      if (e.target.id !== 'dropdown-container') {
        setDropdownManager({});
      }
      if (
        (
          e.target.id !== 'search-container'
          && e.target.id !== 'search-icon-container'
          && e.target.id !== 'search-container-input'
          && e.target.id !== 'mag-icon'
        )
        && isSearchFocused
        && searchTerm.length === 0
      ) {
        setIsSearchFocused(false);
      }
    }}
    >
      <TopRow>
        <TitleAndBackButton>
          <BackButton onClick={() => dispatch(setManageEvaluationsScreenOff())}>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='left-arrow'
            />
          </BackButton>
          <HeaderText>
            Manage Evaluations
          </HeaderText>
        </TitleAndBackButton>
        <SearchAndIcon id='search-container' onClick={() => setIsSearchFocused(true)} isFocused={isSearchFocused}>
          <SearchIconContainer id='search-icon-container'>
            <IcomoonReact
              iconSet={iconSet}
              size={20}
              icon='magnifying-glass-alt'
              id='mag-icon'
            />
          </SearchIconContainer>
          <SearchContainer
            placeholder='Search Evaluations'
            id='search-container-input'
            value={searchTerm}
            onFocus={() => setIsSearchFocused(true)}
            onChange={(inputValue) => {
              setSearchTerm(inputValue.target.value);
            }}
          />
        </SearchAndIcon>
      </TopRow>
      <TableWrapper>
        <TableHeader>
          <NameColumn>
            EVALUATION NAME
          </NameColumn>
          <TableHeaderRightBox>
            <LastUsedColumn>
              LAST USED
            </LastUsedColumn>
            <DateAddedColumn>
              DATE ADDED
            </DateAddedColumn>
            <EvalOptionsColumn />
          </TableHeaderRightBox>
        </TableHeader>
        {searchTerm.length ? searchedList.map((evaluation, idx) => (
          <EvaluationRow onClick={(e) => {
            if (
              e.target.id !== 'dropdown-container-div'
              && e.target.id !== 'dropdown-container'
              && e.target.id !== 'dropdown-programs-menu-option'
              && e.target.localName !== 'path'
            ) {
              dispatch(setEditEvalModal1(evaluation));
            }
          }}
          >
            <TitleBox>
              {evaluation.title}
            </TitleBox>
            <RightBox>
              <LastUsedColumn>
                {evaluation.lastUsed ? moment.unix(evaluation.lastUsed).format('ll') : ''}
              </LastUsedColumn>
              <DateAddedColumn>
                {moment.unix(evaluation.addTime).format('ll')}
              </DateAddedColumn>
              <EvalOptionsColumn id='dropdown-container-div'>
                <DropdownContainer
                  dispatch={dispatch}
                  evaluation={evaluation}
                  currentUser={currentUser}
                  idx={idx}
                  dropdownId='dropdown-container'
                  dropdownManager={dropdownManager}
                  setDropdownManager={setDropdownManager}
                />
              </EvalOptionsColumn>
            </RightBox>
          </EvaluationRow>
        )) : evaluations.map((evaluation, idx) => (
          <EvaluationRow onClick={(e) => {
            if (
              e.target.id !== 'dropdown-container-div'
              && e.target.id !== 'dropdown-container'
              && e.target.id !== 'dropdown-programs-menu-option'
              && e.target.localName !== 'path'
            ) {
              dispatch(setEditEvalModal1(evaluation));
            }
          }}
          >
            <TitleBox>
              {evaluation.title}
            </TitleBox>
            <RightBox>
              <LastUsedColumn>
                {evaluation.lastUsed ? moment.unix(evaluation.lastUsed).format('ll') : ''}
              </LastUsedColumn>
              <DateAddedColumn>
                {moment.unix(evaluation.addTime).format('ll')}
              </DateAddedColumn>
              <EvalOptionsColumn id='dropdown-container-div'>
                <DropdownContainer
                  dispatch={dispatch}
                  evaluation={evaluation}
                  idx={idx}
                  dropdownId='dropdown-container'
                  dropdownManager={dropdownManager}
                  setDropdownManager={setDropdownManager}
                />
              </EvalOptionsColumn>
            </RightBox>
          </EvaluationRow>
        ))}
      </TableWrapper>
    </Wrapper>
  );
};

EvaluationsContentList.propTypes = {

};

DropdownContainer.propTypes = {
  evaluation: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  idx: PropTypes.number.isRequired,
  dropdownManager: PropTypes.instanceOf(Object).isRequired,
  setDropdownManager: PropTypes.func.isRequired,

};

export default EvaluationsContentList;
