/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Conversation from './Conversation';
import useConversations from '../hooks/useConversations';
import SelectAllBox from './SelectAllBox';

const Container = styled('div')`
`;

const EmptyMessage = styled('div')`
  text-align: center;
  padding-top: 15px;
`;

const ConversationBoxContent = ({
  setSelectedConvos,
  isCheckboxesOpen,
}) => {
  const {
    handleSetActiveConversation,
    handleMarkAsRead,
  } = useConversations();
  const isConversationsLoading = useSelector(
    (state) => state.conversations.ui.isConversationsLoading,
  );
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const conversations = useSelector((state) => state.conversations.data.conversations);

  const renderEmptyMessage = () => {
    if (isConversationsLoading) {
      return (<div />);
    }
    return (
      <EmptyMessage>There are no conversations to display.</EmptyMessage>
    );
  };

  const conversationMapper = (conversationsArray) => {
    const newArray = [];
    if (isCheckboxesOpen) {
      newArray.push(
        <SelectAllBox
          isCheckboxesOpen={isCheckboxesOpen}
          setSelectionFunction={setSelectedConvos}
          objects={conversations}
        />,
      );
    }
    conversationsArray.forEach((conversationObject) => {
      newArray.push(
        <Conversation
          conversationObject={conversationObject}
          isCheckboxesOpen={isCheckboxesOpen}
          setSelectedConvos={setSelectedConvos}
          handleSetActiveConversation={handleSetActiveConversation}
          handleMarkAsRead={handleMarkAsRead}
          timeZone={currentUser.userTimeZone
            ? currentUser.userTimeZone
            : currentUser.userSettings.timeZone}
        />,
      );
    });
    return newArray;
  };

  return (
    <Container>
      {conversations.length ? conversationMapper(conversations) : renderEmptyMessage()}
    </Container>
  );
};

ConversationBoxContent.propTypes = {
  setSelectedConvos: PropTypes.func.isRequired,
  isCheckboxesOpen: PropTypes.bool.isRequired,
};

export default ConversationBoxContent;
