export const FETCH_EXERCISES_START = 'FETCH_EXERCISES_START';
export const FETCH_EXERCISES_FULFILLED = 'FETCH_EXERCISES_FULFILLED';
export const FETCH_EXERCISES_REJECTED = 'FETCH_EXERCISES_REJECTED';

export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_FULFILLED = 'FETCH_GROUPS_FULFILLED';
export const FETCH_GROUPS_REJECTED = 'FETCH_GROUPS_REJECTED';

export const FETCH_LEADERBOARD_START = 'FETCH_LEADERBOARD_START';
export const FETCH_LEADERBOARD_FULFILLED = 'FETCH_LEADERBOARD_FULFILLED';
export const FETCH_LEADERBOARD_REJECTED = 'FETCH_LEADERBOARD_REJECTED';

export const EDIT_LEADERBOARD_START = 'EDIT_LEADERBOARD_START';
export const EDIT_LEADERBOARD_FULFILLED = 'EDIT_LEADERBOARD_FULFILLED';
export const EDIT_LEADERBOARD_REJECTED = 'EDIT_LEADERBOARD_REJECTED';

export const FETCH_LEADERBOARD_CSV_START = 'FETCH_LEADERBOARD_CSV_START';
export const FETCH_LEADERBOARD_CSV_FULFILLED = 'FETCH_LEADERBOARD_CSV_FULFILLED';
export const FETCH_LEADERBOARD_CSV_REJECTED = 'FETCH_LEADERBOARD_CSV_REJECTED';

export const FETCH_NEXT_PAGE_START = 'FETCH_NEXT_PAGE_START';
export const FETCH_NEXT_PAGE_FULFILLED = 'FETCH_NEXT_PAGE_FULFILLED';
export const FETCH_NEXT_PAGE_REJECTED = 'FETCH_NEXT_PAGE_REJECTED';

export const CLOSE_BOARD = 'CLOSE_BOARD';

export const UPDATE_SETTING = 'UPDATE_SETTING';

export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
