import React from 'react';
import { useDispatch } from 'react-redux';

import ModalOptOut from '../presentational/ModalOptOut';
import {
  closeModal,
  optOutProgramWorkout,
  optOutWorkout,
} from '../../ducks/panelActions';

const WeightroomModalOptOut = (ownProps) => {
  const dispatch = useDispatch();

  const clseModal = (namespace) => {
    dispatch(closeModal(namespace));
  };
  const optOutPrgramWrkt = (namespace, accountCode, programId, dayNum, values) => {
    dispatch(optOutProgramWorkout(namespace, accountCode, programId, dayNum, values));
  };
  const optOutWrkt = (namespace, accountCode, values) => {
    dispatch(optOutWorkout(namespace, accountCode, values));
  };

  return (
    <ModalOptOut
      closeModal={clseModal}
      optOutProgramWorkout={optOutPrgramWrkt}
      optOutWorkout={optOutWrkt}
      index={ownProps.index}
      name={ownProps.name}
      namespace={ownProps.namespace}
      workId={ownProps.workId}
      workout={ownProps.workout}
    />
  );
};

export default WeightroomModalOptOut;
