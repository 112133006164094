import React, { useEffect } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';

import Layout from '../../shared/components/Layout/Layout';
import ConversationsPanel from './ConversationsPanel';
import ModalHandler from './ModalHandler';

import {
  fetchGroups,
  fetchCalendars,
  fetchUsers,
} from './ducks/conversationsActions';

const Conversations = ({ match }) => {
  const currentUser = useSelector((state) => state.auth.data.currentUser);
  const dispatch = useDispatch();

  // re-fetches calendars whenever currentUser changes
  useEffect(() => {
    const accountCode = currentUser !== undefined && currentUser.accountCode;
    if (accountCode !== undefined) {
      batch(() => {
        dispatch(fetchGroups(accountCode));
        dispatch(fetchCalendars(accountCode));
        dispatch(fetchUsers(accountCode));
      });
    }
  }, [currentUser]);

  return (
    <Layout
      fullWidth
    >
      <Helmet>
        <title>Conversations | TeamBuildr</title>
      </Helmet>
      <Route path={match.path}>
        <ConversationsPanel />
      </Route>
      <ModalHandler />
    </Layout>
  );
};

Conversations.propTypes = { match: PropTypes.instanceOf(Object).isRequired };
export default Conversations;
