/* eslint-disable max-len */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ResizeObserver from 'react-resize-observer';
import { useDispatch } from 'react-redux';
import Text from '../../../../../shared/components/Text/Text';
import { updateCurrentUserProfile } from '../../../../login/ducks/loginActions';
import FormLabel from '../../../../../shared/components/FormLabel/FormLabel';
// import ProfilePicUploadButton from '../../../../../shared/components/ProfilePicUploadButton';

const PasswordValidationSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('Please enter your password'),
  // .matches(
  //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //   'Password must contain at least 8 characters, one uppercase, one number and one special case character',
  // ),
  confirmPassword: Yup
    .string()
    .required('Please confirm your password')
    .when('password', {
      is: (password) => (!!(password && password.length > 0)),
      then: Yup.string().oneOf([Yup.ref('password')], 'Password doesn\'t match'),
    }),
});

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const [containerWidth, setContainerWidth] = useState(null);

  const Container = styled('div')`
      .form-control {
        border: 2px solid #E0E0E0;
        border-radius: 6px;
        padding-left: 7px;
        padding-right: 7px;
        height: 56px;
      }
      .form-control:focus {
        box-shadow: none;
      }
`;

  const SaveButton = styled('button')`
  background-color:  ${(props) => (
    props.theme.colors.green
  )};;
  color: white;
  width:  ${containerWidth && containerWidth < 450 ? '100%' : '49%'};
  height: 50px;
  border-style: none;
  border-radius: 4px;
`;

  const RowWrapper = styled('div')`
  display: flex;
  flex-direction: ${containerWidth && containerWidth < 450 ? 'column' : 'row'};
  gap: 15px;
  padding-top: 15px;
`;

  const ColumnWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width:  ${containerWidth && containerWidth < 450 ? '100%' : '50%'};
`;

  const HeaderColumnWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
`;

  const FieldError = styled('div')`
  margin-top: 10px;
  font-size: 14px;
  color: #ff6600;
`;

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={PasswordValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // When button submits form and form is in the process of submitting,
        // submit button is disabled
        setSubmitting(true);
        console.log('update password');
        // dispatch(updateCurrentUserProfile(values));
        const { password } = values;
        dispatch(updateCurrentUserProfile({ password }));
        console.log('values to submit:', values);
        setSubmitting(false);
      }}
    >
      {/* Callback function containing Formik state and
            helpers that handle common form actions... destructured from props */}
      {({
        // values,
        errors,
        touched,
        // setFieldValue,
        // handleChange,
        // handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Container>
          <ResizeObserver
            onResize={(rect) => {
              setContainerWidth(rect.width);
            }}
          />
          <form onSubmit={handleSubmit}>
            <HeaderColumnWrapper>
              <Text fontSize={containerWidth && containerWidth < 450 ? 24 : 28} fontWeight='700'>
                Reset Password?
              </Text>
            </HeaderColumnWrapper>
            <RowWrapper>
              <ColumnWrapper>
                <FormLabel size={16}>
                  Password
                </FormLabel>
                <Field
              // autoFocus
                  className='form-control'
                  type='password'
                  name='password'
                  placeholder='Password'
                />
                {touched.password && errors.password && <FieldError className='text-center'>{errors.password}</FieldError>}
              </ColumnWrapper>
              <ColumnWrapper>
                <FormLabel size={16}>
                  Confirm
                </FormLabel>
                <Field
              // autoFocus
                  className='form-control'
                  type='password'
                  name='confirmPassword'
                  placeholder='Confirm your password'
                />
                {touched.confirmPassword && errors.confirmPassword && <FieldError className='text-center'>{errors.confirmPassword}</FieldError>}
              </ColumnWrapper>
            </RowWrapper>
            <RowWrapper>
              <SaveButton type='submit' disabled={isSubmitting}>
                Click to Reset Password
              </SaveButton>
            </RowWrapper>
          </form>
        </Container>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
